import { Button } from "antd";
import BaseModal from "components/Shared/BaseModal";
import { AnalyticsService } from "helpers/analyticsService";
import { FROM_DEMO_URL_PREFIX } from "helpers/constants";
import { EnrichmentsService } from "helpers/enrichmentsService";
import { IObjectKeys } from "helpers/globalTypes";
import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const content: IObjectKeys = {
  lead: "qualify your leads in minutes? ",
  landlord: "track all your landlord interactions?",
  listing:
    "add and keep track of all your listings and their property details?",
  team:
    "start managing your team? Activate your free account and start saving time.",
  followUp: "add your follow-ups?",
  default:
    "automat your Leads, Listings Landlords? Activate your free account and start saving time.",
};

const ActivateModal = ({
  toggle = false,
  setToggle,
  type = "default",
}: {
  toggle?: boolean;
  setToggle: Function;
  type?: string;
}) => {
  const handleClick = () => {
    EnrichmentsService.setEnrichments(FROM_DEMO_URL_PREFIX);
    AnalyticsService.reportEvent("activate_modal_cta_click");
    handleCancel();
  };

  const handleCancel = () => {
    setToggle(false);
  };

  return (
    <>
      <BaseModal
        isModalVisible={toggle}
        modalFooter={null}
        width={303}
        closeIcon=" "
        onCancel={handleCancel}
        classname="activate-modal"
      >
        <div className="flex flex-column align-center">
          <span className="font-36" role="img" aria-label="stop">
            👋
          </span>
          <h3 className="fw-700 pb-3">Activate Your Free Account</h3>
          <p className="font-16 text-center">
            Want to {content[type] || content.default}
          </p>
          <Button className="activate-modal-button" onClick={handleClick}>
            <Link to="/sign-up" target="_blank">
              Get Started
            </Link>
          </Button>
        </div>
      </BaseModal>
    </>
  );
};

export default ActivateModal;
