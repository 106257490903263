import React, {useEffect, useState} from "react";
import Lottie from "react-lottie";
import animationData from "assets/animation/treking.json";
import {useWindowSize} from "../../../hooks/useWindowSize";


const Page404Loading = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        }
    };
    const {width} = useWindowSize()
    const [iconWidth, setIconWidth] = useState(350)
    const [iconHeight, setIconHeight] = useState(250)
    useEffect(() => {
        // @ts-ignore
        if (width <= 520) {
            setIconWidth(300)
            setIconHeight(200)
        }
        // @ts-ignore
        if (width <= 425) {
            setIconWidth(220)
            setIconHeight(140)
        }
        // @ts-ignore
        if (width <= 350) {
            setIconWidth(200)
        }
    }, [width])
    return (
        <div className={"global-loading"}>
            <Lottie
                options={defaultOptions}
                width={iconWidth}
                height={iconHeight}
            />
        </div>
    );
};

export default Page404Loading;
