import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Tooltip, Typography } from "antd";
import { EditSquareIcon, TrashIcon } from "../../icons";
import "../styles.scss";
import BaseButton from "../../Shared/BaseButton";
import { useDispatch, useSelector } from "react-redux";
import BaseTable from "../../Shared/BaseTable";
import { fieldDto, IObjectKeys } from "../../../helpers/globalTypes";
import { isOwner, phoneMaskOnString } from "../../../helpers/globalFunctions";
import { timeDifference } from "../../../helpers/globalFunctions";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { debounce } from "lodash";
import {
  defaultInviteSuccess,
  removeAgentFromTeam,
  getTeamMembers,
  resendConfirmationLink,
  setQueryAndPagination
} from "../../../store/teams/action";
import {
  removeAgentFromTeamLoadingSelector,
  removeAgentFromTeamSuccessSelector,
  queryAndPaginationSelector,
  resetInviteAgentSuccessSelector,
  teamMembersLoadingSelector,
  teamMembersSelector,
  teamMembersTotalSelector,
} from "../../../store/teams/selectors";
import { userSelector } from "../../../store/user/selectors";
import BaseModal from "../../Shared/BaseModal";
import Message from "../../Shared/Message";
import { getLoggedInUser } from "../../../store/user/action";
import ActivateSection from "components/Shared/ActivateSection";
import { useDemoContext } from "libs/contextLib";
import TeamNameHeader from "./TeamNameHeader";
import { formatDate } from '../../../helpers/dateHelper';

export interface ITeamMembersQuery {
  page?: number;
  current?: number;
  pageSize?: number;
  sortField?: string;
  sortOrder?: number;
  searchValue?: string;
}

const TeamMember = () => {
  const dispatch = useDispatch();
  const [showMessage, setShowModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const team = useSelector(teamMembersSelector);
  const total = useSelector(teamMembersTotalSelector);
  const teamMembersLoading = useSelector(teamMembersLoadingSelector);
  const removeAgentFromTeamSuccess = useSelector(removeAgentFromTeamSuccessSelector);
  const removeAgentFromTeamLoading = useSelector(removeAgentFromTeamLoadingSelector);
  const userData = useSelector(userSelector);
  const queryAndPagination: ITeamMembersQuery = useSelector(queryAndPaginationSelector);
  const { isDemo, demoAction } = useDemoContext();
  const teamMembers = team.filter((el: any) => el?.name && el?.role);

  useEffect(() => {
    return () => {
      dispatch(defaultInviteSuccess());
    };
  });

  useEffect(() => {
    setConfirmModal(false);
    setDeletedItem("");
  }, [removeAgentFromTeamSuccess, total]);

  const handleTableChange = (paginationOpt: TablePaginationConfig, _: any, sorter: any): void => {
    dispatch(
      setQueryAndPagination({
        page: paginationOpt.current,
        pageSize: paginationOpt.pageSize,
        sortField: sorter.field,
        sortOrder: sorter.order,
      })
    );
  };

  const delayGetTeamMembers = useRef(
    debounce((filters: ITeamMembersQuery) => {
      dispatch(getTeamMembers(filters));
    }, 200)
  );

  const handleResendLink = (_id: string) => {
    dispatch(resendConfirmationLink({ _id }));
  };
  // Removing an agent from a team!
  const handleRemoveAgentFromTeam = (agent_id: string) => {
    dispatch(removeAgentFromTeam({ agent_id }));
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    delayGetTeamMembers?.current(queryAndPagination);
  }, [queryAndPagination]);

  useEffect(() => {
    return () => {
      dispatch(
        setQueryAndPagination({
          page: 1,
          current: 1,
          pageSize: 10,
          sortField: "created_at",
          sortOrder: -1,
          searchValue: "",
        })
      );
    };
  }, [dispatch]);

  const columns = [
    {
      dataIndex: "_id",
      key: "name",
      title: "Name",
      render: (_id: string, rowData: IObjectKeys) => {
        return {
          children: <span>{rowData?.name}</span>,
          props: {
            "data-test-id": "name",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "email",
      title: "Email",
      sorter: false,
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return {
          children: <span>{rowData?.email}</span>,
          props: {
            "data-test-id": "email",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "phone",
      title: "Phone",
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return {
          children: <span>{rowData?.phone ? phoneMaskOnString(rowData?.phone) : ""}</span>,
          props: {
            "data-test-id": "phone",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "role",
      title: "Role",
      render: (_id: string, rowData: IObjectKeys) => {
        return {
          children: <span>{rowData?.role}</span>,
          props: {
            "data-test-id": "role",
          },
        };
      },
    },
    {
      dataIndex: "created_at",
      key: "status",
      title: "Status",
      render: (_id: string, rowData: IObjectKeys) => {
        return {
          children: (
            <div className={`${rowData?.account_verified ? "green" : "orange"} status`}>
              {rowData?.account_verified ? (
                <span>
                  <span>Accepted. </span>
                  <span className={"time-info"}>
                    {formatDate(rowData?.created_at)}
                  </span>
                </span>
              ) : (
                <span>
                  <span>Pending. </span>
                  <span className={"time-info"}>{`Sent ${timeDifference(rowData?.created_at)}`}</span>
                </span>
              )}
            </div>
          ),
          props: {
            "data-test-id": "status",
          },
        };
      },
    },
  ];

  if (isOwner(userData)) {
    columns.push(
      {
        dataIndex: "_id",
        key: "reset-invite",
        title: "",
        render: (_: any, rowData: IObjectKeys) => {
          return {
            children: !rowData?.account_verified ? (
              <span
                className={"untitled"}
                onClick={() => (isDemo ? demoAction("team") : handleResendLink(rowData?._id))}
              >
                <a href="#">Resend Invite</a>
              </span>
            ) : (
              <div />
            ),
            props: {
              "data-test-id": "reset-invite",
            },
          };
        },
      },
      {
        dataIndex: "_id",
        key: "trash",
        title: "",
        render: (_id: string, rowData: IObjectKeys) => {
          if (_id === userData._id) {
            return {
              children: <div className="empty-td" />,
              props: {
                "data-test-id": "empty-td",
              },
            };
          }
          return {
            children: (
              <span
                className={"trash"}
                onClick={() => {
                  isDemo ? demoAction("team") : setConfirmModal(true);
                  setDeletedItem(rowData?._id);
                }}
              >
                <TrashIcon />
              </span>
            ),
            props: {
              "data-test-id": "trash",
            },
          };
        },
      }
    );
  }

  return (
    <>
      <div className={"team-members-content team-members-activities"}>
        {isDemo && <ActivateSection type="team" className="mb-25" />}
        <div className={`team-table-wrapper  ${isOwner(userData) ? "admin-table-view" : ""}`}>
          <TeamNameHeader />
          <BaseTable
            classnames="activities-table"
            bordered={false}
            columns={columns}
            dataSource={teamMembers}
            rowKey={(record) => record?._id}
            onChange={handleTableChange}
            dataTestId="team-table"
            pagination={{
              current: queryAndPagination.page,
              pageSize: queryAndPagination.pageSize,
              total: total,
              position: ["bottomCenter"],
            }}
            loading={teamMembersLoading}
          />
        </div>
      </div>
      <BaseModal
        classname={"confirm-modal"}
        isModalVisible={confirmModal}
        dataTestId="delete-team-modal"
        onCancel={() => {
          setConfirmModal(false);
        }}
        modalTitle={"Confirm"}
        modalFooter={[
          <BaseButton
            dataTestId="cancel-button"
            onClick={() => {
              setConfirmModal(false);
            }}
            classnames={"rb-secondary-btn sm"}
          >
            Cancel
          </BaseButton>,
          <BaseButton
            dataTestId="ok-button"
            onClick={() => {
              handleRemoveAgentFromTeam(deletedItem);
              setTimeout(() => {
                dispatch(getLoggedInUser());
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                delayGetTeamMembers?.current(queryAndPagination);
              }, 1500);
            }}
            classnames={"rb-primary-btn sm"}
            loading={removeAgentFromTeamLoading}
          >
            Ok
          </BaseButton>,
        ]}
      >
        <Typography.Text>Are you sure you want to delete this member?</Typography.Text>
      </BaseModal>
      {showMessage && <Message text={"👍 Invite Sent"} messageClassName="alert-new" type="noIcon" />}
    </>
  );
};

export default TeamMember;
