import { IS_ASAP_CLICKED } from "./types";
import { RootStateOrAny } from "react-redux";

export const listingDataSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingData;
export const algoliaSearchSelector = (state: RootStateOrAny) =>
  state.listingReducer.algoliaSearch;
export const listingLoadingSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingLoading;
export const listingModalLoadingSelector = (state: RootStateOrAny) => state.listingReducer.listingModalLoading;
export const listingErrorSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingError;
export const listingAddSuccessSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingAddSuccess;
export const isOpenListingModalAfterSaveSelector = (state: RootStateOrAny) =>
  state.listingReducer.isOpenListingModalAfterSave;
export const listingUpdateSuccessSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingUpdateSuccess;
export const listingSaveClickedSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingSaveClicked;
export const listingsListSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingsList;
export const listingsTotalSelector = (state: RootStateOrAny) =>
  state.listingReducer.total;
export const agencyListingsTotalSelector = (state: RootStateOrAny) =>
  state.listingReducer.agencyListingsTotal;

export const listingNoteErrorSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingNotesError;
export const listingNoteAddSuccessSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingNoteAddSuccess;
export const listingNotesSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingNotes;
export const listingNotesLoadingSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingNotesLoading;
export const listingFollowUpsSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingFollowUps;
export const listingFollowUpsLoadingSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingFollowUpsLoading;
export const listingImageLoadingSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingImageLoading;
export const getListingLastQuerySelector = (state: RootStateOrAny) =>
  state.listingReducer.listingsLastQuery;
export const getListingCsvErrorsSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingsCsvErrors;
export const downloadCsvTemplateLoadingSelector = (state: RootStateOrAny) =>
  state.listingReducer.downloadCsvTemplateLoading;
export const stepClickedSelector = (state: RootStateOrAny) =>
  state.listingReducer.stepClicked;

export const listingsViewTypeSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingsViewType;
export const filterOptionsSelector = (state: RootStateOrAny) =>
  state.listingReducer.filterOptions;
export const paginationValuesSelector = (state: RootStateOrAny) =>
  state.listingReducer.paginationValues;
export const routerSelector = (state: RootStateOrAny) => state.router;
export const currentStepSelector = (state: RootStateOrAny) =>
  state.listingReducer.currentStep;
export const CurrentImageIndexSelector = (state: RootStateOrAny) =>
  state.listingReducer.listingImagesModalCurrentImage;
export const mustHavesSwitchInputValuesSelector = (state: RootStateOrAny) =>
  state.listingReducer.mustHavesSwitchInputValues;
export const listingDetailsSwitchInputValuesSelector = (
  state: RootStateOrAny,
) => state.listingReducer.listingDetailsSwitchInputValues;
export const isCurrentFormChangedSelector = (state: RootStateOrAny) =>
  state.listingReducer.isCurrentFormChanged;
export const isAsapClickedSelector = (state: RootStateOrAny) =>
  state.listingReducer.isAsapClicked;
export const individualListingHistorySelector = (state: RootStateOrAny) =>
  state.listingReducer.individualListingHistory;
export const listingChangeLogHistorySelector = (state: RootStateOrAny) =>
  state.listingReducer.listingChangelogHistory;
export const isPublicListingSelector = (state: RootStateOrAny) =>
  state.listingReducer.isPublicListing;
export const leadListingLoadedSuccessSelector = (state: RootStateOrAny) =>
  state.listingReducer.leadListingLoadedSuccess;
