import React, { useEffect } from "react";
import { Col, Row } from "antd";
import TeamMembers from "components/TeamMembers";
import { useDemoContext } from "libs/contextLib";
import { useHistory } from "react-router-dom";

const Team = () => {
  const history = useHistory();
  const { isDemo } = useDemoContext();

  useEffect(() => {
    if (isDemo) history.push("/demo/teamsMembers");
  }, [history, isDemo]);
  return (
    <Row className="flex-centered">
      <Col span={24}>
        <TeamMembers />
      </Col>
    </Row>
  );
};
export default Team;
