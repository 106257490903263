import { RuleRender } from "antd/es/form";
import moment from "moment";
import { sanitizePhoneNumber } from "./globalFunctions";
import { getDateDifference } from "./dateHelper";

export const CHARACTERS_VALIDATION = {
  pattern: /^[^\?$;%|<>=`^{}\[\]:\\&*]*$/im,
  message:
    "The field contains one or more forbidden characters: ? $ ; % | < > = ` ^ { } [ ] : \\ & *",
};

export const SHAREABLE_NAME_VALIDATION = {
  required: true,
  min: 2,
  max: 50,
  pattern: /^[a-zA-Z,'.\-\s]+$/,
  message:
    "Name can only contain letters, commas, periods, spaces, single quotes, hyphens and must be between 2 and 50 characters long.",
};

export const SHAREABLE_NAME_NOT_REQUIRED_VALIDATION = {
  min: 2,
  max: 50,
  pattern: /^[a-zA-Z,'.\-\s]+$/,
  message:
    "Name can only contain letters, commas, periods, spaces, single quotes, hyphens and must be between 2 and 50 characters long.",
};

export const SHAREABLE_PHONE_NUMBER = {
  required: true,
  pattern: /^\d{10,15}$/,
  message: "Please enter a valid phone number.",
};

export const SHAREABLE_ADDRESS_1_VALIDATION = {
  required: true,
  min: 3,
  max: 200,
  message: "Address should be at least 3 or max 200 characters.",
};

export const SHAREABLE_LOCALITY_VALIDATION = {
  required: true,
  min: 2,
  max: 30,
  message: "City should be at least 2 characters.",
};

export const SHAREABLE_STREET_NAME_VALIDATION = {
  required: true,
  min: 2,
  message: "Street name should be at least 2 characters.",
};

export const SHAREABLE_STREET_NUMBER_VALIDATION = {
  required: true,
  min: 1,
  message: "Street number should be at least 1 characters.",
};

export const SHAREABLE_UNIT_NUMBER_VALIDATION = {
  required: true,
  min: 1,
  message: "Unit number should be at least 1 characters.",
};

export const SHAREABLE_REGION_VALIDATION = {
  required: true,
  min: 2,
  pattern: /^(AL|AK|AZ|AR|CA|CO|CT|DE|DC|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|PR|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY)$/im,
  message: "Please use a valid two-letter state abbreviations.",
};

export const SHAREABLE_STATE_VALIDATION = {
  required: true,
  min: 2,
  message: "State should be at least 2 characters.",
};

export const SHAREABLE_POSTAL_CODE_VALIDATION = {
  required: true,
  min: 5,
  pattern: /^[\d]{5}$/im,
  message: "Please use a valid postal code.",
};

export const SHAREABLE_INCOME_VALIDATION = {
  required: true,
  min: 0,
  max: 2147483647,
  pattern: /^[\d]+/im,
  message: "Please enter a valid income number.",
};

export const SHAREABLE_ASSETS_VALIDATION = {
  min: 0,
  max: 2147483647,
  pattern: /^[\d]+/im,
  message: "Please enter a valid income number.",
};

export const SHAREABLE_AGE_VALIDATION: RuleRender = ({ getFieldValue }) => ({
  validator(_, value) {
    const minAge = 18;
    const maxAge = 125;
    const age = getDateDifference(value, "years");
    if (age < minAge) {
      return Promise.reject(`Your age must be between ${minAge} - ${maxAge}.`);
    } else if (age > maxAge) {
      return Promise.reject(`Your age must be between ${minAge} - ${maxAge}.`);
    } else {
      return Promise.resolve();
    }
  },
});

export const CONFIRM_SSN_VALIDATION: RuleRender = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue("socialSecurityNumber") === value) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("The SSN confirmation does not match the SSN")
    );
  },
});

export const EMAIL_VALIDATION = {
  required: true,
  pattern: /^(([^<>!~#%$?"'=`^&*()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Entered value does not match email format",
};
export const NOT_REQUIRED_EMAIL_VALIDATION = {
  pattern: /^(([^<>!~#%$?"'=`^&*()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Entered value does not match email format",
};
export const NOT_REQUIRED_PHONE = {
  pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  message: "Please enter a valid phone number",
};

export const REQUIRED_EMAIL_VALIDATION = {
  required: true,
  pattern: /^(([^<>!~#%$?"'=`^&*()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Value entered does not match email format",
  transform: (value: string) => value?.trim(),
};

export const SSN_VALIDATION = {
  required: true,
  pattern: /^[\d]{9}$/im,
  message: "Please enter a valid SSN number",
};

export const REQUIRED_PHONE = {
  required: true,
  pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  message: "Please enter a valid phone number",
};

export const STRONG_PASSWORD_VALIDATION = [
  { required: true, message: "Password is required" },
  {
    pattern: new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    ),
    message:
      "Password must be at least 8 characters and contain at least one special character, number, and uppercase letter",
  },
];

export const PHONE_NUMBER_VALIDATION: RuleRender = ({ getFieldValue }) => ({
  validator(_, value) {
    const sanitizedPhone = sanitizePhoneNumber(value);
    if (sanitizedPhone.length === 10) {
      return Promise.resolve();
    }
    return Promise.reject("Please input valid phone number");
  },
});

export const SETTINGS_PHONE_NUMBER_VALIDATION: RuleRender = ({
  getFieldValue,
}) => ({
  validator(_, value) {
    if (value?.length && value !== "(___) ___-____") {
      const sanitizedPhone = sanitizePhoneNumber(value);
      if (sanitizedPhone?.length === 10) {
        return Promise.resolve();
      }
      return Promise.reject("Please input valid phone number");
    }
    return Promise.resolve();
  },
});

export const REQUIRED_PHONE_NUMBER_VALIDATION: RuleRender = ({
  getFieldValue,
}) => ({
  validator(_, value) {
    const sanitizedPhone = sanitizePhoneNumber(value);
    if (sanitizedPhone.length === 10) {
      return Promise.resolve();
    }
    return Promise.reject("Please input valid phone number");
  },
  required: true,
});
export const NOT_REQUIRED_PHONE_VALIDATION: RuleRender = ({
  getFieldValue,
}) => ({
  validator(_, value) {
    const sanitizedPhone = sanitizePhoneNumber(value);
    if (sanitizedPhone.length === 10 || sanitizedPhone.length === 0) {
      return Promise.resolve();
    }
    return Promise.reject("Please input valid phone number");
  },
});
export const PHONE_NUMBER_MASK = "(999) 999-9999";
export const PHONE_NUMBER_PLACEHOLDER = "Ex. (___) ___-____";
export const DATE_PICKER_DEFAULT_PLACEHOLDER = "Choose a date";
export const DATE_PICKER_PLACEHOLDER = "Start date";
export const END_DATE_PICKER_PLACEHOLDER = "End date";
export const EMAIL_PLACEHOLDER = "Ex. john@doe.com";
export const REQUIRED_NAME_VALIDATION = {
  required: true,
  min: 3,
  message: "Min length is 3 characters.",
};
export const REQUIRED_TITLE_VALIDATION = {
  required: true,
  min: 3,
  max: 200,
  message: "Title should be at least 3 or max 200 characters.",
};
export const REQUIRED_DESCRIPTION_VALIDATION = {
  required: true,
  min: 3,
  max: 200,
  message: "Min length is 3 characters.",
};
export const NOT_REQUIRED_NAME_VALIDATION = {
  min: 4,
  message: "Min length is 4 characters.",
};
export const REQUIRED = { required: true, message: "This field is required" };
export const REQUIRED_WITH_MESSAGE = (fieldName: string) => ({
  required: true,
  message: `${fieldName} is required`,
});
export const REQUIRED_POSITIVE_WITH_MESSAGE = {
  required: true,
  pattern: new RegExp("^\\d*$"),
  message: "This Field is required . Only positive numbers allowed",
};
export const POSITIVE_WITH_MESSAGE = {
  required: false,
  pattern: new RegExp("^\\d*$"),
  message: "Only positive numbers allowed",
};
export const NOT_ZERO_IN_START = {
  pattern: new RegExp("^[^0]"),
  message: "Field Must Not Equal to 0",
};
export const NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE = {
  pattern: new RegExp("^\\d*$"),
  message: "Only positive numbers allowed",
};

export const REQUIRED_BUDGET_POSITIVE_VALUE = {
  required: true,
  pattern: new RegExp("^(\\$)?\\d+(\\.?|\\,|\\d)+?(k)?$", "i"),
  message: "This Field is required. Only positive numbers allowed",
};
