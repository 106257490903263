import React, { useEffect } from "react";
import { push } from "connected-react-router";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAppContext, useDemoContext } from "libs/contextLib";
import GuestLayout from "layouts/guest/GuestLayout";
import { authLoadingSelector, newUserIsComingSelector, userSelector } from "../../store/user/selectors";
import { getAuthToken, getRefreshToken } from "../../helpers/authUtils";
import SignInOrUpLayout from "../../layouts/signIn-or-up-layout/SignInOrUpLayout";
import LeadFlowLayout from "../../layouts/leadFlow/LeadFlowLeadFlowLayout";
import { leadAccountLinkSelector } from "../../store/leads/selectors";
import { getLoggedInUser } from "../../store/user/action";
import { urlQueryToObject } from "../../components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import qs from "query-string";
import { IObjectKeys } from "helpers/globalTypes";
import { saveEmailScreeningLinkData } from "store/shareable/action";
export interface GuestRouteProps extends RouteProps {
  component: React.JSXElementConstructor<any>;
}

const GuestRoute: React.FC<GuestRouteProps> = ({ component: Component, ...rest }) => {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const user = useSelector(userSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const authLoading = useSelector(authLoadingSelector);
  const leadAccountLink = useSelector(leadAccountLinkSelector);
  const isNewUserPasswordSetted = useSelector(newUserIsComingSelector);
  const isUserLoggedIn = getAuthToken() && getRefreshToken();
  const _newURLSearchParams = new URLSearchParams(history.location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const { isDemo } = useDemoContext();
  const guestPages =
    location.pathname.includes("requirement/edit") ||
    location.pathname.includes("requirement/create") ||
    location.pathname.includes("verifyLead") ||
    location.pathname.includes("forceResetPassword") ||
    location.pathname.includes("listings/") ||
    location.pathname.includes("shareable_terms_and_conditions") ||
    location.pathname.includes("policy") ||
    location.pathname.includes("share-listing") ||
    location.pathname.includes("terms");

  const authPages = location.pathname.includes("/requirements");

  useEffect(() => {
    function onLoad() {
      if ((user.email || getAuthToken()) && !guestPages) {
        userHasAuthenticated(true);

        if (user.role === "Renter" && !authPages) {
          if (leadAccountLink?.accountLink) {
            setTimeout(() => {
              dispatch(push(leadAccountLink?.accountLink));
            }, 50);
          }
        } else if (!authPages) {
          setTimeout(() => {
            dispatch(push(""));
          }, 50);
        }
      } else {
        userHasAuthenticated(false);
      }
    }

    onLoad();
  }, [dispatch, guestPages, leadAccountLink, location.pathname, user.email, user.role, userHasAuthenticated]);

  useEffect(() => {
    if (
      (isNewUserPasswordSetted || isUserLoggedIn) &&
      Object.keys(user).length === 0 &&
      location.pathname.includes("forceResetPassword") &&
      !isDemo
    ) {
      dispatch(getLoggedInUser());
    }
  }, [isNewUserPasswordSetted, isUserLoggedIn]);

  useEffect(() => {
    if (Object.keys(user).length && !authLoading && location.pathname.includes("forceResetPassword")) {
      if (!location.search.includes("step=1") || !location.search.includes("step=2")) {
        if (location.search.includes("step=2")) {
          queryObject.step = "2";
          dispatch(
            push({
              search: qs.stringify(queryObject),
            })
          );
        } else {
          queryObject.step = "1";
          dispatch(
            push({
              search: qs.stringify(queryObject),
            })
          );
        }
      }
    }
  }, [authLoading, user]);

  const render = (renderProps: IObjectKeys) => {
    if (location.pathname.includes("lead-flow") || location.pathname.includes("verifyLead") || location.pathname.includes("leadAccount")) {
      if (queryObject.screening === "true") {
        dispatch(
          saveEmailScreeningLinkData({
            screening: "true",
            screeningrequest: queryObject.screeningrequest,
            agentName: queryObject.agentName,
          })
        );
      }
      return <LeadFlowLayout>{<Component {...renderProps} />}</LeadFlowLayout>;
    } else if (!location.pathname.includes("sign-") && !location.pathname.includes("requirements")) {
      return (
        <GuestLayout>
          <Component {...renderProps} />
        </GuestLayout>
      );
    } else {
      return (
        <SignInOrUpLayout>
          <Component {...renderProps} />
        </SignInOrUpLayout>
      );
    }
  };

  if (!isAuthenticated || location.pathname.includes("requirements")) {
    return <Route {...rest} render={render} />;
  } else if (authPages) {
    return <Route {...rest} render={(...renderProps) => <GuestLayout>{<Component {...renderProps} />}</GuestLayout>} />;
  } else {
    return null;
  }
};
export default GuestRoute;
