import React from "react";

const PurpleLocation = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99996 0C4.2042 0 1.92969 2.27451 1.92969 5.07024C1.92969 8.53983 6.46708 13.6334 6.66027 13.8485C6.84172 14.0506 7.15852 14.0503 7.33965 13.8485C7.53283 13.6334 12.0702 8.53983 12.0702 5.07024C12.0702 2.27451 9.79569 0 6.99996 0ZM6.99996 7.62122C5.59334 7.62122 4.449 6.47686 4.449 5.07024C4.449 3.66362 5.59337 2.51929 6.99996 2.51929C8.40655 2.51929 9.55088 3.66365 9.55088 5.07027C9.55088 6.47689 8.40655 7.62122 6.99996 7.62122Z"
        fill="#5806B9"
      />
    </svg>
  );
};

export default PurpleLocation;
