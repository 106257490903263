import React, { useState } from "react";
import { Spin, Upload } from "antd";
import { ACCEPTED_MEDIA_FILES, DEFAULT_USER_AVATAR } from "../../../helpers/constants";
import { getAuthToken } from "../../../helpers/authUtils";
import BaseButton from "../../Shared/BaseButton";
import { beforeUploadCheck } from "../../../helpers/fileUtils";
import { UploadFile } from "antd/es/upload/interface";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { deleteUserImage, uploadUserImage } from "../../../store/user/action";
import { useDispatch } from "react-redux";
import UsersApi from "../../../api/Users";
import Avatar from "components/RenterPortal/Components/Avatar";
interface IUploadUserImage {
  type: string;
  userData: any;
  setImageData: any;
}

const UploadUserImage = (props: IUploadUserImage) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [image, setImage] = useState({});
  const dispatch = useDispatch();

  const onChange = ({ file, fileList: currentFiles }: UploadChangeParam) => {
    setFileList(currentFiles.filter((file) => !!file.status));
    setLoadingImage(true);
    if (file.status === "done") {
      setLoadingImage(false);
      props.setImageData(file);
      // @ts-ignore
      setImage(file);
      dispatch(uploadUserImage(file));
    }
  };

  const deleteImageHandler = (id: string) => {
    UsersApi.deleteUserImage(id);
  };

  const getUserImageURL = (user: any) => {
    // Check for the default image from MLS and ignore
    return user?.image?.url && user.image.url.indexOf("themls") === -1 ? user.image.url : DEFAULT_USER_AVATAR;
  };

  return (
    <>
      <div className={"settings-image-container"}>
        <Spin spinning={loadingImage}>
          <Avatar url={getUserImageURL(props.userData)} />
          {/* <img className="inline-block h-18 w-18 rounded-full object-center object-cover" src={} alt="" /> */}
        </Spin>
        <div className={"items-center flex justify-start gap-2"}>
          <Upload
            showUploadList={false}
            accept={`${ACCEPTED_MEDIA_FILES}`}
            method={"PUT"}
            name={"image"}
            multiple={true}
            action={`${process.env.REACT_APP_BASE_URL}/users/media/agent/${props.userData?._id}/image`}
            headers={{
              Authorization: "Bearer " + getAuthToken(),
            }}
            onChange={onChange}
            id="upload-image-input"
            beforeUpload={(file) => beforeUploadCheck(file, fileList)}
          >
            <BaseButton
              size="sm"
              dataTestId={
                (props.userData.image && props?.userData?.image !== null) || props?.userData?.image !== null
                  ? "change-image-btn"
                  : "upload-image-btn"
              }
            >
              {(props.userData.image && props?.userData?.image !== null) || props?.userData?.image !== null ? "Change" : "Upload"}
            </BaseButton>
          </Upload>
          <BaseButton
            size="sm"
            disabled={(props.userData.image && props?.userData?.image !== null) || props?.userData?.image !== null ? false : true}
            dataTestId="remove-profile-image"
            onClick={() => {
              dispatch(deleteUserImage());
              deleteImageHandler(props.userData?._id);
              setImage({});
            }}
          >
            Remove
          </BaseButton>
        </div>
      </div>
    </>
  );
};
export default UploadUserImage;
