import React from "react";
import {  Form, Radio, Space } from "antd";
import { useSelector } from "react-redux";
import { REQUIRED } from "helpers/validations";
import { shareableVerificationExamEntitySelector } from "store/shareable/selectors";
import { IObjectKeys } from "helpers/globalTypes";

const Exam = ({
  form,
  failedMessage,
  showFailedMessage,
  setShowFailedMessage
}: {
  form: any;
  failedMessage: any;
  showFailedMessage: boolean;
  setShowFailedMessage: any;
}) => {
  const shareableVerificationExamEntity = useSelector(shareableVerificationExamEntitySelector);

  const itemStyle = { marginBottom: "10px" };

  return (
    <>
      {/* VERIFICATION EXAM */}
      <div className={"listing-add-edit-modal--header"}></div>
      <h3 style={{ fontWeight: "bold", fontSize: "25px" }}>Identity Verification Exam:</h3>
      {showFailedMessage && <p style={{ fontWeight: "bold", color: "red" }}>{failedMessage()}</p>}
      {shareableVerificationExamEntity?.authenticationQuestions?.map((question: IObjectKeys) => (
        <Form.Item
          key={question.questionKeyName}
          label={question.questionDisplayName}
          name={question.questionKeyName}
          style={itemStyle}
          rules={[REQUIRED]}>
          <Radio.Group data-test-id={"radio_group"} className="new-exists-radio-group">
            <Space direction="vertical" style={{ marginTop: "10px", marginLeft: "10px", marginBottom: "10px" }}>
              {question.choices.map((choice: IObjectKeys) => (
                <Radio
                  key={choice.choiceKeyName}
                  data-test-id={"authentication_questions"}
                  value={choice.choiceKeyName}>
                  {choice.choiceDisplayName}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      ))}
    </>
  );
};

export default Exam;