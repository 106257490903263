import BaseButton from "components/Shared/BaseButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getLeadAccountLink, getLeadData } from "store/leads/action";
import { leadAccountLinkSelector } from "store/leads/selectors";
import "./styles.scss";
import { AnalyticsService } from "helpers/analyticsService";
import { userSelector } from "../../../../store/user/selectors";
import { getLoggedInUser } from "../../../../store/user/action";
import ExistingLeadButtons from "./existing-lead-btns/ExistingLeadButtons";
import "./styles.scss";
import { TOKEN_ENUM } from "helpers/authUtils";

const RequiementsLeadAuth = ({ showContent }: { showContent: boolean }) => {
  const dispatch = useDispatch();
  const leadAccountLink = useSelector(leadAccountLinkSelector);
  const [isSharePopupVisible, setIsSharePopupVisible] = useState(false);
  const userData = useSelector(userSelector);
  const [showShareBtns, setShowShareBtns] = useState(false);
  const params: any = useParams();
  const isUserLoggedIn = localStorage.getItem(TOKEN_ENUM.ACCESS_TOKEN) && localStorage.getItem(TOKEN_ENUM.REFRESH_TOKEN);

  useEffect(() => {
    dispatch(getLoggedInUser());
  }, [dispatch]);

  useEffect(() => {
    if (userData) {
      if (userData.role) {
        if (userData.role === "Renter") {
          setShowShareBtns(true);
          dispatch(getLeadData(leadAccountLink.requirementId));
        } else setShowShareBtns(false);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (!leadAccountLink) dispatch(getLeadAccountLink());
  }, [dispatch, leadAccountLink]);

  const onBtnClickHandler = () => {
    if (!isUserLoggedIn) {
      setIsSharePopupVisible(true);
      AnalyticsService.reportRequirementsFunnel({
        action: "requirements_start_page_cta_click",
        label: "share_your_requirements",
      });
    } else {
      AnalyticsService.reportRequirementsFunnel({
        action: "requirements_start_page_cta_click",
        label: "preview_the_questionnaire",
      });
    }
  };

  return (
    <>
      <div className="req-share">
        {!showShareBtns ? (
          <BaseButton variant="primary" onClick={onBtnClickHandler} dataTestId="start-here">
            {!isUserLoggedIn ? (
              "Share your requirements"
            ) : (
              <Link to={`/requirement/create/${params.agent_id}?previewMode=true&reqStep=2`}>Preview the Questionnaire</Link>
            )}
          </BaseButton>
        ) : (
          <ExistingLeadButtons showContent={showContent} />
        )}
      </div>
    </>
  );
};

export default RequiementsLeadAuth;
