export const sidebarClassNames = (type: string, collapsed: boolean, isLoading: boolean) => {
    if (type === "desktop") {
        if (isLoading) {
            if (collapsed) return "navigation-bar-desktop navigation-bar-desktop-collapsed navigation-bar-desktop-loading bg-purple-50";
            else return "navigation-bar-desktop navigation-bar-desktop-loading bg-purple-50";
        } else {
            if (collapsed) return "navigation-bar-desktop navigation-bar-desktop-collapsed bg-purple-50";
            else return "navigation-bar-desktop bg-purple-50";
        }
    } else {
        if (collapsed) return "navigation-bar-mobile navigation-bar-mobile-collapsed bg-purple-50";
        else return "navigation-bar-mobile bg-purple-50";
    }
};
