import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { PHONE_NUMBER_MASK, PHONE_NUMBER_VALIDATION, REQUIRED } from "../../../../../../../helpers/validations";
import MaskedInput from "../../../../../../Shared/MaskedPhoneInput";
import { VerifyButton } from "../../../shared-components";
import SeparetedInputs from "../../../../../../Shared/SeparetedInputs";
import { getLeadPhoneVerificationCode, verifyLeadPhoneNumber } from "../../../../../../../store/user/action";
import { useDispatch, useSelector } from "react-redux";
import "./PhoneVerifyTab.scss";
import { sanitizePhoneNumber } from "../../../../../../../helpers/globalFunctions";
import { AnalyticsService } from "helpers/analyticsService";
import {
  getLeadVerificationCodeLoadingSelector,
  getLeadVerificationCodeSuccessSelector,
  verifyLeadCodeLoadingSelector,
} from "store/user/selectors";

interface IPhoneVerifyTab {
  form: any;
  existingPhoneNumber: string;
}

const PhoneVerifyTab = ({ form, existingPhoneNumber }: IPhoneVerifyTab) => {
  const [activeStepNumber, setActiveStepNumber] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const [buttonContent, setButtonContent] = useState("Verify");
  const dispatch = useDispatch();
  const getLeadVerificationCodeSuccess = useSelector(getLeadVerificationCodeSuccessSelector);
  const getLeadVerificationCodeLoading = useSelector(getLeadVerificationCodeLoadingSelector);
  const verifyLeadCodeLoading = useSelector(verifyLeadCodeLoadingSelector);

  useEffect(() => {
    if (getLeadVerificationCodeSuccess) {
      if (activeStepNumber === 1) {
        setActiveStepNumber(2);
      } else {
        setActiveStepNumber(1);
      }
    }
  }, [getLeadVerificationCodeSuccess]);

  const verifyPhoneNumberHandler = (code: any) => {
    dispatch(verifyLeadPhoneNumber(code));
  };
  useEffect(() => {
    if (activeStepNumber === 2) {
      setIsDisabled(true);
      setButtonContent("Submit");
    }
  }, [activeStepNumber]);

  useEffect(() => {
    if (isFinished) setButtonContent("Submit");
  }, [isFinished]);

  useEffect(() => {
    if (existingPhoneNumber) form.setFieldsValue({ phone: existingPhoneNumber });
    setIsDisabled(false);
  }, [existingPhoneNumber, form]);
  return (
    <div className={"verify-phone-tab"}>
      {activeStepNumber !== 2 && (
        <Form
          className={"phone-verify-form-leads-page"}
          form={form}
          onFieldsChange={() => {
            //@ts-ignore
            setIsDisabled(!!form.getFieldsError().filter(({ errors }) => errors.length).length);
          }}
          onFinish={(data) => {
            if (activeStepNumber === 1) {
              const formdata: any = {
                phone: sanitizePhoneNumber(data.phone),
              };
              dispatch(getLeadPhoneVerificationCode(formdata));
            }
          }}
        >
          <Form.Item
            name={"phone"}
            rules={[REQUIRED, PHONE_NUMBER_VALIDATION]}
            hasFeedback
            initialValue={existingPhoneNumber}
          >
            <MaskedInput inputMode="numeric" mask={PHONE_NUMBER_MASK} placeholder={"Enter number here"} />
          </Form.Item>
        </Form>
      )}
      {activeStepNumber === 2 && (
        <SeparetedInputs
          inputCount={6}
          form={form}
          onSubmit={verifyPhoneNumberHandler}
          setIsOpenSubmitButton={setIsDisabled}
          setIsFinished={setIsFinished}
        />
      )}
      <VerifyButton
        classname="verify-button"
        disabled={isDisabled}
        data-test-id="lead-verify-email-btn"
        onClick={() => {
          !isFinished && AnalyticsService.reportRequirementsFunnel({ action: "verify" });
          form.submit();
        }}
        dataTestId={"verify-lead-phone-btn"}
        placement={"center"}
        loading={getLeadVerificationCodeLoading || verifyLeadCodeLoading}
      >
        {buttonContent}
      </VerifyButton>
    </div>
  );
};
export default PhoneVerifyTab;
