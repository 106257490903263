import React from "react";
import TableView from "../../../components/Shared/TableView/TableView";
import { useWindowSize } from "./../../../hooks/useWindowSize";
import TenantGridView from "./grid-view/TenantGridView";
import { TENANT_SCREENING_REQUESTS_COLUMNS } from "./columns";

const TenantScreeningsWrapper = ({
  allPaginationValues,
  allData,
  allRequestsTotal,
  setShowingCount,
  showingCount,
}: any) => {
  const { width } = useWindowSize();
  const isMobile = (width as number) <= 768;

  return (
    <>
      {isMobile ? (
        <TenantGridView
          paginationValues={allPaginationValues}
          tableValues={allData}
          total={allRequestsTotal}
          showingCount={showingCount}
          setShowingCount={setShowingCount}
        />
      ) : (
        <div className="leads-part-dashboard hide-scroll-bar">
          <TableView
            id="leadsDashboardTableId"
            paginationValues={allPaginationValues}
            tableValues={allData}
            total={allRequestsTotal}
            columns={TENANT_SCREENING_REQUESTS_COLUMNS()}
            tableClassName="landlords-table"
            setShowingCount={() =>
              setShowingCount(+(localStorage.getItem("screening_req_showing_count") as string) || 24)
            }
            showingCount={showingCount}
          />
        </div>
      )}
    </>
  );
};

export default TenantScreeningsWrapper;
