import { RootStateOrAny } from 'react-redux';

export const shareableLandlordEntitySelector = (state: RootStateOrAny) => state.shareableReducer.shareableLandlordEntity;
export const createShareableLandlordLoadingSelector = (state: RootStateOrAny) =>
  state.shareableReducer.createShareableLandlordLoading;
export const shareablePropertyEntitySelector = (state: RootStateOrAny) => state.shareableReducer.shareablePropertyEntity;
export const createShareablePropertyLoadingSelector = (state: RootStateOrAny) =>
  state.shareableReducer.createShareablePropertyLoading;
export const createShareablePropertyEntityErrorSelector = (state: RootStateOrAny) =>
  state.shareableReducer.createShareablePropertyEntityError;
export const updateShareablePropertyLoadingSelector = (state: RootStateOrAny) =>
  state.shareableReducer.updateShareablePropertyLoading;
export const updateShareablePropertyEntitySuccessSelector = (state: RootStateOrAny) =>
  state.shareableReducer.updateShareablePropertyEntitySuccess;
export const updateShareablePropertyEntityErrorSelector = (state: RootStateOrAny) =>
  state.shareableReducer.updateShareablePropertyEntityError;
export const getShareablePropertyEntitySuccessSelector = (state: RootStateOrAny) =>
  state.shareableReducer.getShareablePropertyEntitySuccess;
export const shareableLoadingSelector = (state: RootStateOrAny) => state.shareableReducer.shareableLoading;
export const shareableScreeningEntitySelector = (state: RootStateOrAny) => state.shareableReducer.shareableScreeningEntity;
export const createScreeningRequestLoadingSelector = (state: RootStateOrAny) =>
  state.shareableReducer.createScreeningRequestLoading;
export const shareableRenterEntitySelector = (state: RootStateOrAny) =>
    state.shareableReducer.shareableRenterEntity;
export const createShareableRenterEntityErrorSelector = (state: RootStateOrAny) =>
  state.shareableReducer.createShareableRenterEntityError;
export const createShareableRenterEntitySuccessSelector = (state: RootStateOrAny) =>
  state.shareableReducer.createShareableRenterEntitySuccess;
export const shareableVerificationExamEntitySelector = (state: RootStateOrAny) => state.shareableReducer.shareableVerificationExamEntity;
export const createShareableVerificationExamEntityErrorSelector = (state: RootStateOrAny) =>
  state.shareableReducer.createShareableVerificationExamEntityError;
export const createShareableVerificationExamEntitySuccessSelector = (state: RootStateOrAny) =>
  state.shareableReducer.createShareableVerificationExamEntitySuccess;
export const shareableVerificationExamAnswersSelector = (state: RootStateOrAny) =>
  state.shareableReducer.shareableVerificationExamAnswers;
export const postShareableVerificationExamAnswersErrorSelector = (state: RootStateOrAny) =>
  state.shareableReducer.postShareableVerificationExamAnswersError;
export const postShareableVerificationExamAnswersSuccessSelector = (state: RootStateOrAny) =>
  state.shareableReducer.postShareableVerificationExamAnswersSuccess;
export const validateRenterSelector = (state: RootStateOrAny) =>
  state.shareableReducer.validateRenter;
export const validateRenterFailSelector = (state: RootStateOrAny) =>
  state.shareableReducer.validateRenterFail;
export const validateRenterSuccessSelector = (state: RootStateOrAny) =>
  state.shareableReducer.validateRenterSuccess;
export const renterScreeningsNotificationsSuccessSelector = (state: RootStateOrAny) =>
  state.shareableReducer.renterScreeningsNotifications;
export const renterScreeningsNotificationsFailSelector = (state: RootStateOrAny) =>
  state.shareableReducer.renterScreeningsNotificationsFail;
export const shareableRenterLinkDataSelector = (state: RootStateOrAny) => state.shareableReducer.shareableRenterLinkData;
