import {notification} from "antd";
import {ACCEPTED_FILE_SIZE, ACCEPTED_MEDIA_FILES} from "./constants";
import {UploadFile} from "antd/es/upload/interface";

export const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

export const beforeUploadCheck = (file: File, fileList: UploadFile[]) => {
    let fileExists = false
    // if (fileList && fileList.length) {
    //     fileExists = fileList.findIndex(item => item.name === file.name) > -1;
    //     if (fileExists){
    //         notification.error({message: 'File already exists!'});
    //     }
    // }

    const isImageOrVideo = ACCEPTED_MEDIA_FILES.indexOf(file.type) > -1;
    if (!isImageOrVideo) {
        notification.error({message: 'You can only upload images and Videos'});
    }
    const isLt5M = file.size < ACCEPTED_FILE_SIZE;
    if (!isLt5M) {
        notification.error({message: 'Uploaded file must be smaller than 10 mb.'});
    }
    return isImageOrVideo && isLt5M && !fileExists;
}

export const dummyRequest = ({onSuccess}: any) => {
    setTimeout(() => {
        //@ts-ignore
        onSuccess('ok');
    }, 0);
};

export const generateNewFiles = (file: UploadFile) => {
    const newFiles = [];
    for (const currFile of file.response.successfully) {
        const fileNameArr = currFile.imgUrl.split('/');
        const fileName = fileNameArr[fileNameArr.length - 1];
        newFiles.push({
            uid: currFile.id,
            name: fileName,
            status: 'done',
            url: currFile.imgUrl,
            mimeType: currFile.mimeType
        })
    }
    return newFiles;
}

export const getImageMimeType = (file: { name: string }) => {
    const splitName = file.name.split('.');
    return `image/${splitName[splitName.length - 1]}`;
}

export function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const downloadFile: any = (data: any, fileName: string) => {
    const url: any = window.URL.createObjectURL(new Blob([data]));
    const link: any = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
};
