import React from "react";

interface ISelectOutputOption {
  label: string;
  value: string;
}

interface IPropsSelect {
  field: { inputFormat: any; options: ISelectOutputOption[]; label?: string; key: string };
  value: any;
  label?: string;
  options: any[];
}

const SelectOutput = ({ field, value, label }: IPropsSelect) => {
  return (
    <>
      <div>
        <label className="block text-base font-medium ">{label || field.label}</label>
        <div className="mt-2 ">
          {value
            ? field.options
                ?.filter((option: ISelectOutputOption) => option.value === value)
                .map((option: ISelectOutputOption) => {
                  return <div key={option.label}>{option.label}</div>;
                })
            : "--"}
        </div>
      </div>
    </>
  );
};

export default SelectOutput;
