import BaseButton from "components/Shared/BaseButton";
import BaseModal from "components/Shared/BaseModal";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getReferralAgentInfo } from "store/user/action";
import { referralAgentNameSelector } from "store/user/selectors";
import PartyIcon from "../../icons/PartyIcon";
import "./ReferralsModal.scss";

interface IReferralsModal {
  toggle: boolean;
  setToggle: (value: boolean) => void;
}

const ReferralsModal = ({ toggle, setToggle }: IReferralsModal) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const referralAgentName = useSelector(referralAgentNameSelector);

  useEffect(() => {
    dispatch(getReferralAgentInfo(queryObject.referring_agent));
  }, []);

  const handleClose = () => {
    setToggle(false);
  };
  return (
    <>
      {referralAgentName && (
        <BaseModal
          isModalVisible={toggle}
          modalFooter={null}
          width={561}
          onCancel={handleClose}
          modalTitle=""
          centered={true}
        >
          <div className="referrals-modal">
            <PartyIcon />
            <h2>Welcome to RentBase!</h2>
            <p>
              You were invited by {referralAgentName} to RentBase, the future of
              rental business management
            </p>

            <BaseButton
              classnames="rb-primary-btn md mt-15"
              onClick={handleClose}
            >
              Get Started
            </BaseButton>
          </div>
        </BaseModal>
      )}
    </>
  );
};

export default ReferralsModal;
