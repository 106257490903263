import React from "react";

export const AvatarSuccess = () => {
  return (
    <svg
      width="58"
      height="55"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58 28.9993C58 31.8683 52.8599 33.8895 52.0194 36.4784C51.1476 39.1616 54.0878 43.8126 52.462 46.0452C50.8209 48.3011 45.4784 46.9337 43.2225 48.5756C40.9891 50.2007 40.648 55.7122 37.9656 56.5807C35.3768 57.4212 31.8722 53.1898 28.9999 53.1898C26.1276 53.1898 22.6262 57.4212 20.0342 56.5807C17.3509 55.7089 17.0099 50.1975 14.7765 48.5756C12.5215 46.9346 7.1789 48.3019 5.537 46.046C3.91199 43.8126 6.85142 39.1616 5.98044 36.4792C5.14007 33.8904 0 31.8691 0 29.0001C0 26.1311 5.14007 24.1099 5.9806 21.521C6.85159 18.8384 3.91216 14.1875 5.53716 11.9542C7.17907 9.69837 12.5216 11.0657 14.7775 9.4246C17.0101 7.7996 17.3511 2.2872 20.0344 1.41956C22.6232 0.579029 26.1277 4.81047 29.0001 4.81047C31.8722 4.81047 35.3738 0.579029 37.9658 1.41956C40.6484 2.29055 40.9901 7.80278 43.2227 9.4246C45.4785 11.0665 50.8211 9.6992 52.463 11.9542C54.088 14.1877 51.1477 18.8386 52.0195 21.521C52.8599 24.1089 58 26.1301 58 28.9993V28.9993Z"
        fill="#D5C1ED"
      />
      <path
        d="M26.4677 38.859C25.6185 38.8602 24.8038 38.5228 24.2039 37.9216L17.0649 30.7827C15.8143 29.5323 15.8139 27.5049 17.0641 26.2542C18.3144 25.0035 20.3419 25.0032 21.5926 26.2534L26.4676 31.1277L36.4139 21.1822C37.6644 19.9316 39.692 19.9314 40.9427 21.1819C42.1934 22.4324 42.1935 24.46 40.943 25.7107L28.7321 37.9216C28.1322 38.523 27.3172 38.8603 26.4677 38.859Z"
        fill="#5806B9"
      />
    </svg>
  );
};
