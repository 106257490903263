import Api from "./api";
import { IObjectKeys } from "../helpers/globalTypes";

export default class PaymentApi {
  static getPaymentLink({ pricing, currentLoc }: IObjectKeys) {
    const api = new Api("payment/stripe/create-checkout-session?");
    return api.get("", { params: { price_id: pricing, currentLoc } });
  }

  static getSubscriptions(id: any) {
    const api = new Api("payment/stripe/subscriptions");
    return api.get(id, { params: {} });
  }

  static getUpcomingInvoice() {
    const api = new Api("payment/stripe/upcoming-invoice");
    return api.get("", {});
  }

  static getInvoices() {
    const api = new Api("payment/stripe/invoices");
    return api.get("", {});
  }

  static updateSubscription({priceId, currentLoc}: IObjectKeys) {
    const api = new Api("payment/stripe/update-checkout-session");
    return api.create({ data: { priceId: priceId, currentLoc } });
  }

  static deleteSubscription() {
    const api = new Api("payment/stripe/delete-checkout-session");
    return api.destroy("");
  }

  static getStripePortalURL() {
    const api = new Api("payment/stripe/get-stripe-portal-url");
    return api.get("", {});
  }

  static getScreeningPaymentLink(bundleId: any) {
    const api = new Api(`payment/stripe/shareable-screening/${bundleId}`);
    return api.get("", {});
  }
}


