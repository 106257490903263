import { CheckSquareFilled, CloseSquareFilled } from "@ant-design/icons";
import { Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTeamName } from "store/teams/action";
import { updateTeamNameLoadingSelector, updateTeamNameSuccessSelector } from "store/teams/selectors";
import { userSelector } from "../../../store/user/selectors";
import { EditSquareIcon } from "../../icons";
import { isOwner } from "helpers/globalFunctions";

const TeamNameHeader = () => {
  const [editMode, setEditMode] = useState(false);
  const [teamName, setTeamName] = useState("");
  const userData = useSelector(userSelector);
  const dispatch = useDispatch();
  const teamupdateNameSuccess = useSelector(updateTeamNameSuccessSelector);
  const loading = useSelector(updateTeamNameLoadingSelector);

  const handleTeamNameUpdate = async () => {
    dispatch(updateTeamName({ params: { name: teamName } }));
  };

  const handleNameChange = (event: any) => {
    const { value = "" } = event.target;
    setTeamName(value);
  };

  const handleCancelNameChange = () => {
    setTeamName(userData.agency?.agents_group_name || "");
    setEditMode(false);
  };

  useEffect(() => {
    setTeamName(userData.agency?.agents_group_name || "");
  }, [userData.agency]);

  useEffect(() => {
    if (teamupdateNameSuccess) {
      setEditMode(false);
    }
  }, [teamupdateNameSuccess]);

  return (
    <div>
      <div className={"team-page-name"}>
        <h2>
          <b>Team Name:</b>
          {editMode ? (
            <>
              <Input
                autoFocus
                autoComplete={"off"}
                data-test-id="name-input"
                placeholder={"Enter Name"}
                value={teamName}
                onChange={handleNameChange}
                style={{ minWidth: "250px" }}
              />
            </>
          ) : (
            teamName || "-"
          )}
        </h2>
        <Spin spinning={loading}>
          {isOwner(userData) && <div className="team-name-actions">
            {!editMode ? (
              <span
                onClick={() => {
                  setEditMode(true);
                }}
                className="icon-btn"
              >
                <EditSquareIcon />
              </span>
            ) : (
              <>
                <span className="icon-btn" onClick={handleTeamNameUpdate}>
                  <CheckSquareFilled />
                </span>
                <span className="icon-btn" onClick={handleCancelNameChange}>
                  <CloseSquareFilled width={48} height={48} />
                </span>
              </>
            )}
          </div>}
        </Spin>
      </div>
    </div>
  );
};

export default TeamNameHeader;
