import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import Location from "./single-location-select-item/Location";
import { IlocationItem } from "./types/types";
import { IObjectKeys } from "helpers/globalTypes";
import { v4 as uuidv4 } from "uuid";
import { FormInstance } from "antd/es/form";
import { useSelector } from "react-redux";
import { singleLeadDataSelector } from "store/leads/selectors";
import { useLocation } from "react-router";
import {requirementAgentDataSelector, requirementFormDataSelector} from "store/requirement/selectors";
import FormStepsTitle from "../../form-steps-title/FormStepsTitle";
import NeighborhoodsApi from "api/Neighborhoods";

interface ILocationsWrapperProps {
  setParentsLocations: React.Dispatch<React.SetStateAction<IlocationItem[]>>;
  form: FormInstance<any>;
  disableFreeForms?: boolean;
}

const LocationsWrapper = ({
  setParentsLocations,
  form,
  disableFreeForms
}: ILocationsWrapperProps) => {
  const [locations, setLocations] = useState<IlocationItem[]>([]);
  const leadData = useSelector(singleLeadDataSelector);
  const requirementAgentData = useSelector(requirementAgentDataSelector);
  const requirementFormData = useSelector(requirementFormDataSelector)
  const { pathname } = useLocation();
  const isEditMode = pathname.includes('edit');
  useEffect(() => {
    // if (isSubmitForm)
      setParentsLocations(locations);
  }, [locations]);

  useEffect(() => {
    if (requirementFormData?.area?.length ) {
      const locationsForSet = requirementFormData.area.map((loc: IObjectKeys) => {
        return ({
          area: loc.area,
          location: [...loc.location],
          _id: uuidv4(),
          separated_fields: {
            town: loc?.town,
            state: loc?.state,
          },
          isFreeText: loc.isFreeText,
        })
      });
      if(isEditMode){
        setLocations( [...locationsForSet]);
      }else {
        setLocations(prev => [...prev, ...locationsForSet]);
      }
    }
  }, [requirementFormData,isEditMode]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        !locations.length && !requirementFormData?.area?.length &&
        (
          (leadData?.requirement?.area && !leadData?.requirement?.area?.length) ||
          (Object.keys(requirementAgentData).length && !isEditMode && !requirementFormData?.area?.length))
      ) {
        try {
          const neighborhoodsList: any = await NeighborhoodsApi.getNeighborhoodsList({
            city: requirementAgentData?.defaultCity,
            state: requirementAgentData?.defaultState
          });
  
          const firstLocation = {
            area: requirementAgentData?.defaultCity && requirementAgentData?.defaultState ?
              `${requirementAgentData?.defaultCity || ""}${requirementAgentData?.defaultCity ? "," : ""} ${requirementAgentData?.defaultState || ""}` : "",
            location: [],
            _id: uuidv4(),
            separated_fields: {
              town: requirementAgentData?.defaultCity || "",
              state: requirementAgentData?.defaultState || "",
            },
            isFreeText: false,
            neighborhoods_list: neighborhoodsList,
          };
  
          setLocations(prev => [...prev, firstLocation]);
        } catch (error) {
          console.error('Error fetching neighborhoods:', error);
        }
      }
    };
  
    fetchData(); // Call the asynchronous function
  }, [requirementAgentData, leadData.requirement,requirementFormData.area,isEditMode]);
  const changeLocation = useCallback((updateedArea: IObjectKeys) => {
    setLocations((prev) => {
    const idx = prev.findIndex((loc) => loc._id === updateedArea._id);
      const copyPrev = [...prev];
      copyPrev[idx] = {
        ...copyPrev[idx],
        ...updateedArea,
      };
      return [...copyPrev];
    });
  }, []);
  const addNewArea = useCallback(() => {
    const newLocation = {
      area: "",
      location: [],
      _id: uuidv4(),
      separated_fields: {},
      isFreeText: true,
      neighborhoods_list: [],
    }
    setLocations((prev) => [...prev, newLocation]);
  }, []);
  const deleteLocationsHandler = useCallback((id: any) => {
    setLocations((prev) => {
      const copyPrev = prev.filter((item) => item._id !== id);
      return [...copyPrev];
    });
  }, []);

  return (
    <div className="locations_wrapper">
      <FormStepsTitle text="Do you have specific locations or neighborhoods in mind?"/>
      {locations.map((location: IlocationItem, index: number) => {
        return (
          <Location
            key={location._id}
            num={index}
            fromPage={"requirements"}
            externalLocation={location}
            changeLocation={changeLocation}
            form={form}
            disableFreeForms={disableFreeForms}
            deleteLocationsHandler={deleteLocationsHandler}
          />
        );
      })}
      <div className="add-area-wrapper">
        <span
          className="add-area"
          data-test-id="addAnotherArea"
          onClick={() => addNewArea()}
        >
          + Add another area
        </span>
      </div>
    </div>
  );
};

export default LocationsWrapper;
