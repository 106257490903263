import React, { useCallback, useEffect, useState } from "react";
import { push } from "connected-react-router";
import { Form, Input, Modal } from "antd";
import { StepFormComponent } from "./types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListingUpdateState,
  deleteListingImage,
  fillListingData,
  setIsCurrentFormChange,
  updateListing,
} from "../../../store/listings/action";
import {
  isCurrentFormChangedSelector,
  listingDataSelector,
  listingUpdateSuccessSelector,
  stepClickedSelector,
} from "../../../store/listings/selectors";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "../../../helpers/globalFunctions";
import Title from "antd/es/typography/Title";
import StepsActionButtons from "./StepsActionButtons";
import ListingImagesEmptyState from "../ListingImagesSection/ListingImagesEmptyState";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { UploadFile } from "antd/es/upload/interface";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IImage } from "../../Listings/helpers/mockListings";
import ScrollToTopWrapper from "../../Shared/ScrollToTopWrapper";

const { TextArea } = Input;
const { confirm } = Modal;
const PhotosAndVR = ({ form }: StepFormComponent) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { lId } = getQueryParams(search);
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const listingData = useSelector(listingDataSelector);
  const stepClicked = useSelector(stepClickedSelector);
  const isCurrentFormChanged = useSelector(isCurrentFormChangedSelector);
  // const [loadingImages, setLoadingImages] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onSubmit = (data: any) => {
    dispatch(fillListingData({ ...data, description: data?.description?.trim() }));
    dispatch(updateListing({ lId, ...data, step: "photos_ant_vr_step", description: data?.description?.trim() }));
  };

  useEffect(() => {
    dispatch(setIsCurrentFormChange(false));
  }, []);

  useEffect(() => {
    const { virtual_tour_link, description } = form.getFieldsValue();
    if (lId) {
      form.setFieldsValue({
        virtual_tour_link: listingData.virtual_tour_link || virtual_tour_link,
        description: listingData.description || description,
      });
    }
  }, [listingData]);

  useEffect(() => {
    if (!stepClicked && updateListingSuccess) {
      dispatch(push(`/listings/${listingData?.public_id}`));
    }
    return () => {
      dispatch(clearListingUpdateState());
    };
  }, [updateListingSuccess]);

  useEffect(() => {
    if (lId && listingData?.images) {
      const imgList = listingData?.images?.map((el: any) => {
        el.status = "done";
        el.uid = el.id;
        return el;
      });
      setFileList([...imgList]);
    }
  }, [lId]);

  const onChange = useCallback(
    ({ file, fileList: currentFiles, event }: UploadChangeParam) => {
      setFileList(currentFiles);
    },
    [],
  );

  const onFileRemove = (file: any) => {
    dispatch(deleteListingImage(file?.response?._id || file._id));
    setFileList(
      fileList.filter((f: any) => {
        if (!file._id) {
          return f?.response?._id !== file?.response?._id;
        }
        return f.uid !== file._id;
      }),
    );
    dispatch(
      fillListingData({
        images: [
          ...listingData.images.filter((f: IImage) => {
            return f._id !== file._id;
          }),
        ],
      }),
    );
    // setLoadingImages(false);
    return Promise.resolve(false);
  };

  const showConfirm = (file: UploadFile) => {
    confirm({
      title: "Do you Want to delete these image?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        // setLoadingImages(true);
        onFileRemove(file);
      },
      onCancel() { },
    });
  };

  const onPreview = () => {
    return;
  };

  return (
    <ScrollToTopWrapper>
      <Title className={"mb-50"} level={4}>
        Marketing Material
      </Title>
      <Form
        layout={"vertical"}
        className="landlord-core-details-form"
        form={form}
        onFinish={onSubmit}
        onFieldsChange={() => {
          if (!isCurrentFormChanged) dispatch(setIsCurrentFormChange(true));
        }}
      >
        <Form.Item name="description" label="Marketing Description">
          <TextArea placeholder="Ex. A well lit 2 bed 2 bath in the heart of Boston..." />
        </Form.Item>
        <Form.Item name="virtual_tour_link" label="Virtual Tour Link">
          <Input
            autoComplete={"off"}
            placeholder="Ex.  www.3dvirtualtour.com/apartment"
          />
        </Form.Item>
        <ListingImagesEmptyState
          addEditModeClassname={"listing-add-edit-mode"}
          addEditMode={true}
          showConfirm={showConfirm}
          setFileList={setFileList}
          onChange={onChange}
          onPreview={onPreview}
          enablePreview={true}
          fileList={fileList}
          colViewConfig={{
            xs: 24,
            md: 8,
          }}
          imageCardClassname={"uploaded-image-card add-edit-image-card-wrapper"}
          showConfirmation={showConfirm}
          canEdit={true}
        />
      </Form>
      <StepsActionButtons form={form} />
    </ScrollToTopWrapper>
  );
};
export default PhotosAndVR;
