import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { areasFacetsSelector } from "../../../../../../../../store/areas/selectors";
import { FormInstance } from "antd/es/form";
import "./LocationFilterSelect.scss";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import ExpandOpenIcon from "../../../../icons/ExpandOpenIcon";
import ExpandCloseIcon from "../../../../icons/ExpandCloseIcon";
import HalfCheckedIcon from "../../../../icons/HalfCheckedIcon";
import UncheckedIcon from "../../../../icons/UncheckedIcon";
import CheckedIcon from "../../../../icons/CheckedIcon";
import { urlQueryToObject } from "../../../../../helpers/listingsCreateGetSearchQuery";
import { useLocation } from "react-router";
import {
  generateFacetData,
  setSelectedSingleLocationInMultiselect,
  simplifyFilterLocationsData
} from "../../../../../helpers/filterFormHelper";


interface ILocationFilterSelect {
  form: FormInstance;
  setLocation: React.Dispatch<React.SetStateAction<any[]>>;
  defaultSelectedValues: any;
  setDefaultSelectedValues:any;
  isClearAllClicked:boolean
}
const LocationFilterSelect = ({
  form,
  setLocation,
  defaultSelectedValues,
  setDefaultSelectedValues,
  isClearAllClicked
}: ILocationFilterSelect) => {
  const facetAreas = useSelector(areasFacetsSelector);
  const locations = simplifyFilterLocationsData(generateFacetData(facetAreas));
  const [checked,setChecked] = useState<any>()
  const [expanded, setExpanded] = useState<any>([]);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  useEffect(() => {
    if(defaultSelectedValues.length){
      setChecked(defaultSelectedValues)
      setDefaultSelectedValues([])
    }else {
      if (queryObject.locations) {
        if(queryObject.locations.length !== 1){
            const checkedArrayToSet = queryObject.locations;
            setChecked(checkedArrayToSet);
        }else {
          setChecked(setSelectedSingleLocationInMultiselect(queryObject.locations[0],locations))
        }
      }
    }

  }, []);

  useEffect(()=>{
    if(isClearAllClicked){
      setChecked([])
      setDefaultSelectedValues([])
    }
  },[isClearAllClicked])
  return (
    <div className={"location-filter-select-wrapper"}>
      <CheckboxTree
        expandOnClick={true}
        nativeCheckboxes={true}
        iconsClass="fa5"
        nodes={locations}
        checked={checked}
        expanded={expanded}
        icons={{
          expandOpen: <ExpandCloseIcon />,
          expandClose: <ExpandOpenIcon />,
          check: <CheckedIcon />,
          uncheck: <UncheckedIcon />,
          halfCheck: <HalfCheckedIcon />,
        }}
        onCheck={(checkedArray) => {
          setChecked(checkedArray);
          setLocation(checkedArray);
          form.submit();
          localStorage.removeItem("single_selected_location_object")
        }}
        onExpand={(expandedArray) => setExpanded(expandedArray)}
      />
    </div>
  );
};

export default memo(LocationFilterSelect);
