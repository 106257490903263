import React from "react";
import moment from "moment";
import DropdownPencilEdit from "../../../../icons/DropdownPencilEdit";
import { DeleteIcon } from "../../../../icons";
import "./IndividualNoteWrapper.scss";
import EditIcon from "./icons/EditIcon";
import TrashIconNotes from "./icons/TrashIconNotes";
import { formatDate } from '../../../../../helpers/dateHelper';

interface IIndividualNoteWrapper {
    note: any;
    handleOpenNoteModal:any;
    setVisible:any;
    setDeleteConfirmProps:any;
    setSingleNoteVisible:any
}

const IndividualNoteWrapper = ({
  note,
  handleOpenNoteModal,
  setVisible,
  setDeleteConfirmProps,
  setSingleNoteVisible }:
  IIndividualNoteWrapper) => {
    return (
        <div className={"individual-note-wrapper"}>
            <span className={"individual-note-wrapper--date"}>
                {formatDate(note.created_at)}{" "}
                by {note.created_by?.name}
            </span>
            <p className={"individual-note-wrapper--title"}>
                {note.title}
            </p>
            <span className={"individual-note-wrapper--description"}>
                {note?.description}
            </span>
            <div
                className={
                    "single-listing-page-all-note--notesWrapper--item--wrapper--noCollapse--headerIconsActive"
                }
            >
                <div
                    onClick={() => {
                        setSingleNoteVisible(false);
                        handleOpenNoteModal(note);
                        setVisible(false);
                    }}
                >
                    <EditIcon />
                </div>
                <div
                    onClick={() => {
                        setSingleNoteVisible(false);
                        if (note._id) {
                            setDeleteConfirmProps({
                                visible: true,
                                noteId: note._id,
                            });
                            setVisible(false);
                        }
                    }}
                >
                    <TrashIconNotes />
                </div>
            </div>
        </div>
    );
};

export default IndividualNoteWrapper;