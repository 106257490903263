import { RootStateOrAny } from 'react-redux';

export const agentsActivitiesSelector = (state: RootStateOrAny) => state.activitiesReducer.agentsActivities;
export const agentsActivitiesTotalSelector = (state: RootStateOrAny) => state.activitiesReducer.total;
export const agentsActivitiesErrorSelector = (state: RootStateOrAny) => state.activitiesReducer.agentsActivitiesError;
export const agentsActivitiesLoadingSelector = (state: RootStateOrAny) => state.activitiesReducer.agentsActivitiesLoading;

export const currentAgentForActivitiesSelector = (state: RootStateOrAny) => state.activitiesReducer.currentAgentForActivities;
export const currentAgentDataSelector = (state: RootStateOrAny) => state.activitiesReducer.agentData;
export const queryAndPaginationSelector = (state: RootStateOrAny) =>
    state.activitiesReducer.queryAndPagination;