import React, { useEffect, useState } from "react";
import BaseButton from "../../../../../Shared/BaseButton";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { AnalyticsService } from "../../../../../../helpers/analyticsService";
import { useForm } from "antd/es/form/Form";
import AddFollowUpModal from "../../../../../FollowUps/add-follow-up-modal/AddFollowUpModal";
import AddFollowUpModalMobile from "../../../../../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import PhoneVerifyModalFollowUps from "../../../../../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import { getFollowUpsList } from "../../../../../../store/followUps/action";
import { useDispatch, useSelector } from "react-redux";
import {
  addFollowUpSuccessSelector,
  editFollowUpSuccessSelector,
  queryAndPaginationSelector,
} from "../../../../../../store/followUps/selectors";
import { windowSelector } from "../../../../../../store/app/selectors";

const initialModalValue = { visible: false, editModal: false };

const AuthHeaderFollowUpAction = () => {
  const [form] = useForm();
  const { width }: any = useWindowSize();
  const [modalProps, setModalProps] = useState(initialModalValue);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const addFollowUpSuccess = useSelector(addFollowUpSuccessSelector);
  const editFollowUpSuccess = useSelector(editFollowUpSuccessSelector);
  const queryAndPagination = useSelector(queryAndPaginationSelector);
  const { type } = useSelector(windowSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (addFollowUpSuccess && !modalProps.visible) {
      dispatch(getFollowUpsList(queryAndPagination));
    }
    if (editFollowUpSuccess) {
      dispatch(getFollowUpsList(queryAndPagination));
    }
  }, [addFollowUpSuccess, editFollowUpSuccess, modalProps]);

  const handleOpenAddNoteModal = () => {
    setModalProps({ visible: true, editModal: false });
    form.resetFields();
    AnalyticsService.reportAddFollowUpClick();
  };

  return (
    <div className={"header-part-right-landlordActions"}>
      <BaseButton onClick={() => handleOpenAddNoteModal()} modalType="followUp" variant="primary">
        <span>{type === "mobile" ? "Add" : " Add Follow-Up"}</span>
      </BaseButton>
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={modalProps.visible}
            setModalProps={setModalProps}
            form={form}
            // listing={listing}
            modalProps={modalProps}
            isDescShown={false}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={modalProps.visible}
            setModalProps={setModalProps}
            form={form}
            // listing={listing}
            modalProps={modalProps}
            isDescShown={false}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        )
      }
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={() => setPhoneVerifyModalVisible(false)} />
    </div>
  );
};

export default AuthHeaderFollowUpAction;
