import React, { useState } from "react";
import { useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../store/enums/selectors";
import { getChipCheckbox } from "../../../../../../../helpers/globalFunctions";
import { EnumInterface } from "../../../../../../../helpers/globalTypes";
import "./MustHavesRequirement.scss";
import CollapseBottomIcon from "../../icons/pets-icons/CollapseBottomIcon";
import CollapseTopIcon from "../../icons/pets-icons/CollapseTopIcon";

const MustHavesRequirement = ({ heading }: any) => {
  const requirementEnums = useSelector(requirementEnumsSelector);
  const [isAll, setIsAll] = useState(true);
  return (
    <div className={"must-haves-requirements"}>
      {heading && <h2 className="text-2xl font-semibold my-6 text-center">{heading}</h2>}
      {requirementEnums?.length
        ? getChipCheckbox(
            requirementEnums.find((item: EnumInterface) => item.key === "must_have"),
            "column",
            "",
            "",
            isAll
          )
        : ""}
      <div className={"req-show-all-wrapper"}>
        <span
          onClick={() => {
            setIsAll((prevState) => !prevState);
          }}
        >
          <span>Show {isAll ? "All" : "Less"} Likes</span>
          {isAll ? <CollapseBottomIcon /> : <CollapseTopIcon />}
        </span>
      </div>
    </div>
  );
};

export default MustHavesRequirement;
