import React from "react";

const Success = ({ msg }: any) => {
  return (
    <>
      <p style={{ fontWeight: "bold", textAlign: "center", padding: "40px 0" }}>
        {msg ? msg : "Your screening request was sent successfully"}
      </p>
    </>
  );
};

export default Success;
