import React, { useState } from "react";
import { Layout, Spin } from "antd";
import PageFooter from "../../components/Footer";
import GuestHeader from "../../components/Header/GuestHeader";
import "./GuestLayout.scss";
import GlobalLoading from "components/Shared/GlobalLoading";
import { useSelector } from "react-redux";
import { createRequirementLoadingSelector, loadingRequirementAgentDataSelector } from "store/requirement/selectors";
import { leadLoadingSelector } from "store/leads/selectors";
import { useLocation } from "react-router-dom";
import SignInHeader from "../../components/Header/SignInHeader/SignInHeader";
import { googleMapsApiKey, googleMapsApiLibrariesArray } from "../../helpers/constants";
import { LoadScriptNext } from "@react-google-maps/api";
import { listingLoadingSelector } from "../../store/listings/selectors";
import { authLoadingSelector, verifyCodeLoadingSelector } from "../../store/user/selectors";

export interface GuestLayoutProps {
  children: React.ReactNode;
}

const { Content, Footer } = Layout;
const GuestLayout: React.FC<GuestLayoutProps> = ({ children }) => {
  const [isFooterCollapsed, setIsFooterCollapsed] = useState(false);
  const leadLoading = useSelector(leadLoadingSelector);
  const location = useLocation();
  const loadingRequirement = useSelector(loadingRequirementAgentDataSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const authLoading = useSelector(authLoadingSelector);
  const verifyCodeLoading = useSelector(verifyCodeLoadingSelector);
  const createReqLoading = useSelector(createRequirementLoadingSelector);

  const collapseFooter = () => {
    setIsFooterCollapsed((prevState) => !prevState);
  };

  return (
    <LoadScriptNext id="script-loader" googleMapsApiKey={googleMapsApiKey} libraries={googleMapsApiLibrariesArray}>
      <Layout className={`layout guest-layout-wrapper-all`}>
        <>
          {location.pathname.includes("forceResetPassword") ||
          location.pathname.includes("public/listings") ||
          location.pathname.includes("requirement") ? (
            <SignInHeader />
          ) : (
            <GuestHeader />
          )}
          <Content
            className={
              location.pathname.includes("forceResetPassword") || location.pathname.includes("resetPassword")
                ? "guest-layout-wrapper-force"
                : "guest-layout-wrapper"
            }
          >
            {children}
          </Content>
          {/* <Footer
            className={`site-layout-footer guest-layout-footer ${isFooterCollapsed ? "collapsed" : ""
              }`}
          >
            <PageFooter
              isGuest={true}
              isFooterCollapsed={isFooterCollapsed}
              collapseFooter={collapseFooter}
            />
          </Footer> */}
        </>
      </Layout>
    </LoadScriptNext>
  );
};

export default GuestLayout;
