import React from 'react';
const Flag = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.53417 15.9803L4.82837 15.8981C5.11134 15.819 5.27663 15.5255 5.19756 15.2425L1.83397 3.20554C1.78651 3.03567 1.6735 2.89161 1.51982 2.80506C1.36614 2.71851 1.18437 2.69655 1.0145 2.74402L0.976506 2.75463C0.62275 2.85347 0.416098 3.22037 0.514933 3.57413L3.87861 15.6111C3.95768 15.8941 4.25118 16.0594 4.53417 15.9803Z" fill="white" />
            <path d="M16.3799 7.56857C15.3132 7.03671 14.3264 6.35783 13.4484 5.55179C13.3746 5.48423 13.3457 5.38052 13.374 5.28454C13.8091 3.63866 14.1322 1.9652 14.3411 0.27565C14.3741 -0.00537665 14.2226 -0.0880316 14.0628 0.10483C10.9274 3.85461 5.50249 -0.581206 2.27344 2.82693C2.31221 2.89952 2.3427 2.97622 2.36436 3.05561L4.54645 10.8693C7.78101 7.50245 13.1866 11.9107 16.3165 8.1692C16.4763 7.97634 16.5066 7.62368 16.3799 7.56857Z" fill="white" />
        </svg>
    );
}

export default Flag;