import React, { useEffect, useState } from "react";
import LeadEnterPassword from "../../components/Requirement/requirement-form/components/lead-enter-password";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setLeadAccessToken, verifyLeadEmail } from "store/leads/action";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import { leadAccessTokenSelector, leadAccountLinkSelector } from "store/leads/selectors";
import routes from "routes/routesCode";
import { push } from "connected-react-router";
import ExpireLink from "./ExpireLink/ExpireLink";
import { TOKEN_ENUM } from "helpers/authUtils";

const VerifyLead = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const _newURLSearchParams = new URLSearchParams(location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const leadAccountLink = useSelector(leadAccountLinkSelector);
  const leadAccessToken = useSelector(leadAccessTokenSelector);
  const token: string = queryObject.token;
  const isAuth = localStorage.getItem(TOKEN_ENUM.ACCESS_TOKEN) && localStorage.getItem(TOKEN_ENUM.REFRESH_TOKEN);
  const [isExpiredComponent, setIsExpiredComponent] = useState(false);

  useEffect(() => {
    if (isAuth) {
      dispatch(push("/"));
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (token && !isAuth) {
      dispatch(setLeadAccessToken(token ?? ""));
      localStorage.setItem(TOKEN_ENUM.ACCESS_TOKEN, token);
    }
  }, [dispatch, isAuth, token]);

  useEffect(() => {
    if (leadAccessToken && token && !isAuth) dispatch(verifyLeadEmail({ renterToken: token ?? "" }));
  }, [dispatch, isAuth, leadAccessToken, token]);

  useEffect(() => {
    if (leadAccountLink?.accountLink) {
      dispatch(push(routes.leadAccount.path));
    }
  }, [dispatch, leadAccountLink]);

  if (isExpiredComponent) return <ExpireLink />;

  return <LeadEnterPassword setIsExpiredComponent={setIsExpiredComponent} />;
};
export default VerifyLead;
