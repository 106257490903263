import React from "react";
import BaseCollapse from "../../Shared/BaseCollapse";
import { useSelector } from "react-redux";
import { isPublicListingSelector } from "../../../store/listings/selectors";
import { listingChangeLogHistorySelector } from "../../../store/listings/selectors";
import './style.scss';
import ListingHistoryLogCollapseItems from "./components/ListingChangelogCollapseItems";
import SectionHeading from "components/Shared/Section/SectionHeading";

const ListingChangelogCollapse = () => {
  const isPublicListing = useSelector(isPublicListingSelector);
  const listingHistoryChangelogData = useSelector(listingChangeLogHistorySelector);

  return (
    <BaseCollapse
      header={<SectionHeading heading="Listing Changelog" />}
      collapse={true}
    >
      {!isPublicListing
        && <>
          {
            listingHistoryChangelogData?.map((e: any, i: number) => {
              return (
                <div key={i}>
                  <ListingHistoryLogCollapseItems historyData={e} keyProp={i} />
                </div>
              );
            })
          }
        </>
      }
    </BaseCollapse>
  );
};

export default ListingChangelogCollapse;