import { Col, Form, Row } from "antd";
import { push } from "connected-react-router";
import { openNotification } from "helpers/globalFunctions";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inviteAgentSuccessSelector } from "../../store/teams/selectors";
import BaseButton from "../Shared/BaseButton";
import { CheckedIcon } from "../icons";
import AddTeamModal from "./AddTeamModal";
import "./styles.scss";
import { setInviteAgentToFalse } from "store/teams/action";

//not used component
const CreateTeam = () => {
  const [addTeamModalVisible, setAddTeamModalVisible] = useState(false);
  const [form] = Form.useForm();
  const getInviteTeamsSuccess = useSelector(inviteAgentSuccessSelector);
  const dispatch = useDispatch();

  const handleAddTeamBtnClick = useCallback(() => {
    setAddTeamModalVisible(true);
    form.resetFields();
  }, []);

  useEffect(() => {
    if (getInviteTeamsSuccess) {
      setAddTeamModalVisible(false);
      openNotification("success", "👍 Invite sent successfully");
      dispatch(setInviteAgentToFalse());
      setTimeout(() => {
        dispatch(push(`/teamsMembers`));
      }, 2000);
    }
  }, [getInviteTeamsSuccess]);

  return (
    <>
      <div className={"create-team-content"}>
        <Row>
          <Col span={24} xs={24}>
            <div className={"title"}>
              Introducing, <span>Teams</span> from <span>RentBase</span>
            </div>
            <div className={"subtitle mb-20"}>Teams grow faster, together</div>
            <div className={"info"}>
              Start building your dream team! Choose a permission setting and
              enter your first team members email to begin
            </div>
            <div className={"select-content mb-30 mt-40"}>
              <BaseButton
                classnames={"rb-primary-btn md"}
                onClick={handleAddTeamBtnClick}
              >
                Add
              </BaseButton>
            </div>
            <div className={"list"}>
              <div>
                <CheckedIcon />
                Listing updates are synced in real-time to all your agents
              </div>
              <div>
                <span>
                  <CheckedIcon />
                  Faster on-boarding and training for new agents
                </span>
              </div>
              <div>
                <span>
                  <CheckedIcon />
                  Save time and close more deals
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <AddTeamModal
          form={form}
          visible={addTeamModalVisible}
          onClose={() => setAddTeamModalVisible(false)}
        />
      </div>
    </>
  );
};
export default CreateTeam;
