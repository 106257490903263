import React, {useEffect, useState} from "react";
import DashboardFollowUpModal from "../follow-up-dashboard-modal";
import {useForm} from "antd/es/form/Form";
import {ListingNote} from "../../Listings/helpers/mockListings";
import AddFollowUpModal from "../add-follow-up-modal/AddFollowUpModal";
import {urlQueryToObject} from "../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getFUById, setSingleFuToBlank} from "../../../store/followUps/action";
import {singleFuSelector} from "../../../store/followUps/selectors";
import {push} from "connected-react-router";

const SingleFUWrapper = () =>{
    const dispatch = useDispatch()
    const location = useLocation()
    const [followUpModalProps, setFollowUpModalProps] = useState({visible: false, editModal: false})
    const [singleFollowUpData, setSingleFollowUpData] = useState({})
    const [snoozeBodyVisible, setSnoozeBodyVisible] = useState(false);
    const [isDescShown, setIsDescShown] = useState(false)
    const [isFUVisible,setIsFuVisible] = useState(false)
    const [isSnoozePickerVisible, setIsSnoozePickerVisible] = useState(false);
    const [setPhoneVerifyModalVisible] = useState(false)
    const [form] = useForm()
    const _newURLSearchParams = new URLSearchParams(location.search);
    const queryObject = urlQueryToObject(_newURLSearchParams);
    const singleFu = useSelector(singleFuSelector)

    useEffect(()=>{
        if(Object.keys(singleFu).length){
            setSingleFollowUpData(singleFu)
        }
    },[singleFu])

    useEffect(()=>{
        if(Object.keys(singleFollowUpData).length){
            setIsFuVisible(true)
        }
    },[singleFollowUpData])
    useEffect(()=>{
        if(queryObject.id){
            dispatch(getFUById(queryObject.id))
        }
    },[])

    const closeFuModal = () => {
        setFollowUpModalProps({visible: false, editModal: false})
        dispatch(push(location.pathname))
        setSingleFollowUpData({})
        dispatch(setSingleFuToBlank())
    };

    const handleOpenEditFollowUpModal = (note: ListingNote) => {
        setIsFuVisible(false)
        setFollowUpModalProps({visible: true, editModal: true});
        form.setFieldsValue({
            _id: note._id,
            title: note.title,
            description: note.description,
            todo_at: note.todo_at
        });
        if (note.description && note.description.length > 0) {
            setIsDescShown(true);
        } else {
            setIsDescShown(false);
        }
    };

    useEffect(()=>{
        if(followUpModalProps.visible){
            setSingleFollowUpData(false)
        }
    },[followUpModalProps])
    return(
        <>
            <DashboardFollowUpModal
                // setSingleFollowUpData={setSingleFollowUpData}
                isFuPage={true}
                setSnoozeBodyVisible={setSnoozeBodyVisible}
                setIsFuVisible={setIsFuVisible}
                setIsSnoozePickerVisible={setIsSnoozePickerVisible}
                isFUVisible={isFUVisible}
                singleFollowUpData={singleFollowUpData}
                snoozeBodyVisible={snoozeBodyVisible}
                setFollowUpModalProps={setFollowUpModalProps}
                backToFollowUps={closeFuModal}
                handleOpenEditFollowUpModal={handleOpenEditFollowUpModal}
                isSnoozePickerVisible={isSnoozePickerVisible}
            />
            <AddFollowUpModal
                isSingle={true}
                visible={followUpModalProps.visible}
                setModalProps={setFollowUpModalProps}
                form={form}
                modalProps={followUpModalProps}
                isDescShown={isDescShown}
                landlordInfo={null}
                isFollowUpEditable={true}
                setIsDescShown={setIsDescShown}
                setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
                isFromDashboard={true}
            />
        </>
    )
}

export default SingleFUWrapper
