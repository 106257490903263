import React from "react";
import Avatar from "antd/es/avatar/avatar";
import {UserOutlined} from "@ant-design/icons";
import {AvatarSuccess} from "../../icons/AvatarSuccess";
import {useWindowSize} from "../../../hooks/useWindowSize";
import "./SuccessAgentAvatar.scss"

const SuccessAgentAvatar = ({url}:{url:string}) =>{
    const { width }: any = useWindowSize();
    return(
        <div className="success-agent-avatar">
            <Avatar
                src={url}
                icon={<UserOutlined />}
                size={width < 600 ? 90 : 135}
            />
            <span className={"success-agent-avatar-successIcon"}>
              <AvatarSuccess />
            </span>
        </div>
    )
}

export default SuccessAgentAvatar