import React, {useEffect, useState} from "react";
import {Form,Radio} from "antd"

const initialValuesArray = ["1+", "1.5+", "2+", "3+", "4+", "5+"]

interface IFilterRadioButtonGroup {
    type: string,
    handleChange: (e: any) => void,
    defaultValue: any,
    checked?: boolean,
}

const FilterRadioButtonGroup = ({type, handleChange, defaultValue, checked}: IFilterRadioButtonGroup) => {
    const [valuesArray, setValuesArray] = useState(initialValuesArray)

    useEffect(() => {
        if (checked && type === "Bedrooms") {
            setValuesArray(["Studio", "1", "1.5", "2", "3", "4", "5"])
        } else {
            setValuesArray(initialValuesArray)
        }
    }, [checked])
    return (
        <Form.Item name={type==="Bedrooms" ? "bedrooms_count":"bathrooms_count"} className={"bed-bath-wrapper-radioGroup-antItem"}>
            <div onClick={(e) => e.stopPropagation()} className="bed-bath-wrapper-radioGroup">
                <Radio.Group
                    onChange={handleChange}
                    defaultValue={
                        defaultValue && defaultValue !== "0" ? defaultValue : "0"
                    }
                    value={defaultValue && defaultValue !== "0" ? defaultValue : "0"}
                    className="bed-bath-wrapper-radioGroup-wrapper"
                >
                    {
                        valuesArray.map((number) =>
                            <Radio.Button
                                className={`bed-bath-wrapper-radioGroup-wrapper-radio ${number === "Studio" ? "bed-bath-wrapper-radioGroup-wrapper-radioStudio" : ""}`}
                                value={number.includes("+") ? number.replace("+", "") : number}
                                key={number}
                            >
                                {number}
                            </Radio.Button>
                        )
                    }
                </Radio.Group>
            </div>
        </Form.Item>

    );
};

export default React.memo(FilterRadioButtonGroup);
