import React from "react";
import AuthForm from "./sign-in-form/AuthForm";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {AuthFormInterface} from "../../helpers/authTypes";
import {signIn} from "../../store/user/action";
import SignInIntro from "../Shared/sign-in-intro/SignInIntro";
import './styles.scss'

const SignIn = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const onSubmit = async (data: AuthFormInterface) => {
        dispatch(signIn(data, location.search));
        localStorage.removeItem("leadsPageRequested");
    };
    return (
        <div className="sign-in-wrapper w-100">
            <SignInIntro isSignIn={true}/>
            <AuthForm onSubmit={onSubmit}/>
        </div>
    )
}

export default SignIn