import React from 'react';

export const RightArrow = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.5"
        cy="16.5"
        r="16"
        transform="rotate(-180 16.5 16.5)"
        fill="white"
        stroke="#BFBFBF"
      />
      <path
        d="M21.7129 16.6931L15.6931 22.7127C15.3102 23.0958 14.6894 23.0958 14.3066 22.7127C13.9238 22.3299 13.9238 21.7091 14.3066 21.3263L19.6332 15.9999L14.3068 10.6737C13.924 10.2907 13.924 9.66997 14.3068 9.2872C14.6895 8.90427 15.3104 8.90427 15.6933 9.2872L21.7131 15.3069C21.9045 15.4984 22.0001 15.7491 22.0001 15.9999C22.0001 16.2508 21.9043 16.5017 21.7129 16.6931Z"
        fill="#333333"
      />
    </svg>
  );
};
