import { Dropdown } from "antd";
import { DropdownDotsIcon } from "components/icons";
import Triangle from "components/icons/triangle/Triangle";
import BaseButton from "components/Shared/BaseButton";
import { IObjectKeys } from "helpers/globalTypes";
import React, { memo, useState } from "react";
import "./styles.scss";
import ContactModal from "../../../../Shared/ContactModal/ContactModal";
import { AnalyticsService } from "helpers/analyticsService";
import { DEFAULT_USER_AVATAR } from "helpers/constants";
import Section from "components/Shared/Section/Section";
import { AGENT_CONNECTION_STATUS } from "../ConnectedAgents";

interface LeadPermissionItemProps {
  agent: any;
  requirementId: string;
  handleAction: ({
    agent,
    requirementId,
    actionName,
  }: {
    agent: any;
    requirementId: string;
    actionName: string;
  }) => void;
}

const LeadPermissionItem = ({ agent, requirementId, handleAction }: LeadPermissionItemProps) => {
  const [isOpenContactModal, setIsOpenContactModal] = useState(false);
  const statusClassName = ["col_content text"];

  switch (agent.status) {
    case "Accepted":
      statusClassName.push("accepted");
      break;
    case "Pending":
      statusClassName.push("pending");
  }

  const getAgentImageURL = (agent: any) => {
    // Check for the default image from MLS and ignore
    return agent?.imagesOfAgent?.url && agent.imagesOfAgent.url.indexOf("themls") === -1
      ? agent.imagesOfAgent.url
      : DEFAULT_USER_AVATAR;
  };

  return (
    <>
      <Section shadow>
        <div className="flex flex-wrap justify-between">
          <div className="flex justify-start">
            <div className="mr-4 flex-shrink-0">
              <span className="relative inline-block">
                <img className="h-24 w-24 rounded-lg object-cover" src={getAgentImageURL(agent)} alt="" />
              </span>
            </div>
            <div>
              <h4 className="text-base font-bold flex items-center">
                {agent.name}
                <div className="ml-4 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-200">
                  <svg className="h-1.5 w-1.5 fill-purple-500" viewBox="0 0 6 6" aria-hidden="true">
                    <circle cx={3} cy={3} r={3} />
                  </svg>
                  {agent.status}
                </div>
              </h4>
              <div>{agent.phone}</div>
              <div>{agent.email}</div>
            </div>
          </div>
          <div className="flex flex-shrink-0 items-center gap-2">
            <BaseButton
              dataTestId={"landlord-quick-contact-modal-open-from-listing"}
              variant="primary"
              onClick={() => {
                !isOpenContactModal && AnalyticsService.reportEvent("contact_agent_modal_opened");
                setIsOpenContactModal(true);
              }}
            >
              Contact
            </BaseButton>
            {agent.status === "Pending" ? (
              <>
                <BaseButton
                  className="permission-menu-item"
                  onClick={() => handleAction({ agent, requirementId, actionName: AGENT_CONNECTION_STATUS.APPROVE })}
                  variant="primary"
                >
                  {AGENT_CONNECTION_STATUS.APPROVE}
                </BaseButton>
                <BaseButton
                  className="permission-menu-item"
                  data-action-name="Decline"
                  onClick={() => handleAction({ agent, requirementId, actionName: AGENT_CONNECTION_STATUS.DECLINE })}
                >
                  {AGENT_CONNECTION_STATUS.DECLINE}
                </BaseButton>
              </>
            ) : (
              <BaseButton
                className="permission-menu-item"
                data-action-name="Revoke"
                onClick={() => handleAction({ agent, requirementId, actionName: AGENT_CONNECTION_STATUS.REVOKE })}
              >
                {AGENT_CONNECTION_STATUS.REVOKE}
              </BaseButton>
            )}
          </div>
        </div>
      </Section>
      <ContactModal
        currentLeadData={agent}
        showModal={isOpenContactModal}
        onClose={setIsOpenContactModal}
        currentLandlordData={null}
      />
    </>
  );
};
export default memo(LeadPermissionItem);
