import React, { useEffect, useState } from "react";
import { DatePicker, Form } from "antd";
import moment from "moment";
// @ts-ignore
import TimePicker from "react-time-picker/dist/entry.nostyle";
// import { US_DATE_FORMAT_FOLLOWUPS } from "../../../../helpers/constants";
import { CalendarIcon } from "../../../icons";
import { getBrowser, getBrowserDateFormat } from "../../../../helpers/globalFunctions";
import MozTimePicker from "./moz/MozTimePicker";
import { addTimeAndFormatDate, formatDate } from "../../../../helpers/dateHelper";
const AddFollowUpModalDatePicker = ({
  setDatePickerValue,
  modalProps,
  datePickerData,
  datePickerDateSaved,
  timeData,
  setTimeData,
}: any) => {
  const [showingData, setShowingData] = useState(false);
  const [calendarDay, setCalendarDay] = useState(
    addTimeAndFormatDate(null, { amount: 1, unit: "days" }, undefined, getBrowserDateFormat())
  );

  useEffect(() => {
    setDatePickerValue(
      new Date(
        `${addTimeAndFormatDate(null, { amount: 1, unit: "days" }, undefined, getBrowserDateFormat())} ${timeData}`
      )
    );
  }, []);
  useEffect(() => {
    if (modalProps.editModal && datePickerData.length > 0) {
      setCalendarDay(formatDate(datePickerData, getBrowserDateFormat()));
    }
  }, [datePickerData]);
  useEffect(() => {
    if (showingData && datePickerDateSaved) {
      setDatePickerValue(new Date(`${formatDate(calendarDay, getBrowserDateFormat())} ${timeData}`));
    }
  }, [calendarDay, timeData, datePickerDateSaved]);
  const onCalendarTimeChangeHandler = (timeValue: any) => {
    setShowingData(true);
    setTimeData(timeValue);
  };

  useEffect(() => {
    if (modalProps.editModal && datePickerData.length > 0) {
      setShowingData(datePickerData);
    }
  }, [modalProps]);
  return (
    <div className={"pick-follow-up-time-modal-wrapper"}>
      <p className={"snooze-dropdown-title"}>When would you like to be reminded?</p>
      <div className={"pick-follow-up-time-modal-wrapper--picker"}>
        <div className={"snooze-dropdown-wrapper"}>
          <Form.Item>
            <DatePicker
              getPopupContainer={(triggerNode: { parentNode: any }) => triggerNode.parentNode}
              defaultOpenValue={
                modalProps.editModal || datePickerData.length > 0 ? moment(datePickerData) : moment().add(1, "days")
              }
              defaultValue={
                modalProps.editModal || datePickerData.length > 0 ? moment(datePickerData) : moment().add(1, "days")
              }
              defaultPickerValue={
                modalProps.editModal || datePickerData.length > 0 ? moment(datePickerData) : moment().add(1, "days")
              }
              onChange={(date: any) => {
                setShowingData(true);
                setCalendarDay(formatDate(date, getBrowser()));
              }}
              open={true}
              showToday={false}
              dropdownClassName={"ant-picker-for-followups"}
              className="w-full"
              placeholder={""}
              format={`YYYY-MM-DD HH:mm:ss`}
            />
          </Form.Item>
        </div>
      </div>
      {navigator.userAgent.indexOf("Firefox") != -1 ? (
        <MozTimePicker setTimeData={setTimeData} setShowingData={setShowingData} />
      ) : (
        <TimePicker
          onChange={onCalendarTimeChangeHandler}
          value={timeData}
          disableClock={true}
          format={"hh:mm a"}
          clearIcon={<></>}
        />
      )}
    </div>
  );
};

export default AddFollowUpModalDatePicker;
