import React from "react";
import { CopyLink, SettingsEmailIcon } from "../icons";

const ShareShowingProcedureModalContent = () => {
  return (
    <div className={"share-showing-procedure-modaL-content"}>
      <h3>Share Showing Procedure</h3>
      <div className={"share-wrapper"}>
        <div className={"link-email-procedure"}>
          <SettingsEmailIcon />
          <p>Share By Email</p>
        </div>
        <div className={"link-copy-procedure"}>
          <CopyLink />
          <p>Copy Showing Procedure</p>
        </div>
      </div>
    </div>
  );
};

export default ShareShowingProcedureModalContent;
