import cookies from "js-cookie";
import { shortAppUrl } from "./constants";

interface EnrichmentsObject {
  fbclid?: string;
  uqaid?: string;
  utm_source?: string;
  from_demo?: string;
  experimentName?: string;
  activeVariant?: string;
  selected_plan?: string;
  [key: string]: any;
}


export const EnrichmentsService = {
  setEnrichments: function (query: string) {
    const isEnrichments = query.match(/utm|fbclid|uqaid|from_demo/);

    if (isEnrichments) {
      // GETTING THE ENRICHMENTS PARAMS FROM THE URL
      const urlSearchParams = new URLSearchParams(query);
      const newParams = Object.fromEntries(urlSearchParams.entries());

      // GETTING THE CURRENT ENRICHMENTS AND IF EXISTING, COMBINING WITH THE NEW
      const currentEnrichmentObject = this.getEnrichmentsObject();
      const newEnrichmentsObj = currentEnrichmentObject ? this.setKeysWithLast(newParams, currentEnrichmentObject) : this.setKeysWithLast(newParams);

      const paramsString = JSON.stringify(newEnrichmentsObj);

      localStorage.setItem("query_object", paramsString);
      cookies.set("enrichments", paramsString, { expires: 90, domain: shortAppUrl });
    }
  },

  setKeysWithLast: function (newParams: EnrichmentsObject, currentEnrichmentObject: EnrichmentsObject = {}) {
    const enrichmentsWithLastKeys = currentEnrichmentObject;

    Object.entries(newParams).forEach(([key, value]) => {
      if (currentEnrichmentObject[key]) {
        enrichmentsWithLastKeys[`last_${key}`] = newParams[key];
      } else {
        enrichmentsWithLastKeys[key] = newParams[key];
        enrichmentsWithLastKeys[`last_${key}`] = newParams[key];
      }
    });

    return enrichmentsWithLastKeys;
  },

  getEnrichmentsObjectWithReferralSource: function () {
    const enrichmentsObj = this.getEnrichmentsObject();

    if (!enrichmentsObj) return undefined;

    if ("fbclid" in enrichmentsObj || "uqaid" in enrichmentsObj) enrichmentsObj.referralSource = "facebook";
    if ("utm_medium" in enrichmentsObj && enrichmentsObj["utm_medium"] === "email") enrichmentsObj.referralSource = "email";

    return enrichmentsObj;
  },

  getEnrichmentsObject: function () {
    const enrichmentsString = cookies.get("enrichments");

    if (!enrichmentsString) return undefined;

    return JSON.parse(enrichmentsString);
  }
};
