import { GOOGLE_AUTH_NOT_FOUND_ERROR, GOOGLE_AUTH_REDIRECT_ERROR, GOOGLE_LOGIN_LINK } from "./constants";
import { notification } from "antd";
import { AnalyticsService } from "./analyticsService";
import { getLoggedInUser } from "../store/user/action";
import AuthApi from "../api/Auth";
import { Dispatch } from "redux";
import { TOKEN_ENUM } from "./authUtils";

export const GoogleAuthHelper = (dispatch: Dispatch, history: any) => {

  if (window.location.hash === GOOGLE_AUTH_NOT_FOUND_ERROR) {
    notification.error({ message: "The user does not Exist!Please change your gmail account" });

    AnalyticsService.reportEvent("google_signin", {
      category: "App",
      label: "error",
      errorMessage: "The user does not Exist"
    });
  }
  if (window.location.hash.includes(GOOGLE_AUTH_REDIRECT_ERROR)) {
    window.location.assign(GOOGLE_LOGIN_LINK);
    AnalyticsService.reportEvent("google_signin", {
      category: "App",
      label: "error",
      errorMessage: "GOOGLE_AUTH_REDIRECT_ERROR"
    });
  }
  if (window.location.hash.includes(TOKEN_ENUM.ACCESS_TOKEN)) {
    const tokens = window.location.hash.split("&");
    // @ts-ignore
    const accessToken = tokens?.find((item) => item.includes(TOKEN_ENUM.ACCESS_TOKEN))?.split("=")[1];
    // @ts-ignore
    const refreshToken = tokens?.find((item) => item.includes("id_token"))?.split("=")[1];
    if (accessToken && refreshToken) {
      try {
        localStorage.setItem(TOKEN_ENUM.ACCESS_TOKEN, accessToken);
        localStorage.setItem("refresh_token", refreshToken);
        dispatch(getLoggedInUser(accessToken));
        AuthApi.googleIdToken(refreshToken);
        if (localStorage.getItem("leadsPageRequested")) {
          history.push(`${localStorage.getItem("leadsPageRequested")}`);
        } else {
          history.push("/listings");
        }
        AnalyticsService.reportEvent("google_signin", {
          category: "App",
          label: "success"
        });
      } catch {
        AnalyticsService.reportEvent("google_signin", {
          category: "App",
          label: "error",
          errorMessage: "error in server"
        });
      }
    }
  }
};