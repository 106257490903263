import React from "react";
import HouseIcon from "../icons/HouseIcon";
import NoteIcon from "../icons/NoteIcon";
import { Link } from "react-router-dom";
import QuickContact from "components/Shared/TableView/QuickContact/QuickContact";
import { useDemoContext } from "libs/contextLib";
interface ILandlordsItemCard {
  elem: any;
  setLandlordActionModalVisible: any;
}
const LandlordsItemCard = ({ elem, setLandlordActionModalVisible }: ILandlordsItemCard) => {
  const { isDemo } = useDemoContext();
  return (
    <div className={"landlords-mobile-list-item"}>
      <Link to={`/${isDemo ? "demo/landlords" : "landlords"}/${elem?._id}`}>
        <div className={"landlords-mobile-list-item--title"}>
          <h4>{elem?.name}</h4>
        </div>
      </Link>
      <div className={"landlords-mobile-list-item--bottom"}>
        <div className={"landlords-mobile-list-item--bottom--left"}>
          <div className={"landlords-mobile-list-item--bottom--left--listings"}>
            <HouseIcon />
            <p>{elem?.listingsOfLandlordSize}</p>
          </div>
          <div className={"landlords-mobile-list-item--bottom--left--notes"}>
            <NoteIcon />
            <p>{elem?.notesOfLandlord?.length}</p>
          </div>
        </div>
        <QuickContact
          rowData={elem}
          setLandlordActionModalVisible={setLandlordActionModalVisible}
          page={"landlords"}
        />
      </div>
    </div>
  );
};

export default LandlordsItemCard;
