import React, { useEffect } from "react";
import { Button, Steps } from "antd";
import { StepFormComponent } from "../ListingForm/types";
import { useDispatch, useSelector } from "react-redux";
import {
  listingDataSelector,
  listingLoadingSelector,
  listingUpdateSuccessSelector,
} from "../../../store/listings/selectors";
import BasicsStep from "./BasicsStep";
import FeesStep from "./FeesStep";
import ShowingProcedure from "../ListingForm/ShowingProcedure/ShowingProcedure";
import { clearListingUpdateState, getOneListing } from "../../../store/listings/action";
import { FormInstance } from "antd/es/form";
import BaseButton from "components/Shared/BaseButton";
const { Step } = Steps;
interface IMakeListingAvailable {
  closeModal: () => void;
  step: number;
  setStep: (step: number) => void;
  form: FormInstance;
}

const steps = ({ form, next }: StepFormComponent) => [
  {
    title: "Basics",
    content: <BasicsStep form={form} next={next} />,
  },
  {
    title: "Fees",
    content: <FeesStep form={form} next={next} />,
  },
  {
    title: "Showing Procedure",
    content: <ShowingProcedure form={form} statusChangeForm={true} />,
  },
];

const MakeListingAvailable = ({ closeModal, setStep, step, form }: IMakeListingAvailable) => {
  const dispatch = useDispatch();
  const loading = useSelector(listingLoadingSelector);
  const listingData = useSelector(listingDataSelector);
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const next = () => {
    setStep(step + 1);
  };
  const prev = () => {
    setStep(step - 1);
  };

  const formSteps = steps({ form, next, prev });

  useEffect(() => {
    if (updateListingSuccess && step === 2) {
      closeModal();
      dispatch(getOneListing(listingData._id));
      setStep(0);
    }
    return () => {
      dispatch(clearListingUpdateState());
    };
  }, [updateListingSuccess]);

  return (
    <>
      <Steps current={step}>
        {formSteps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content mt-6">{formSteps[step].content}</div>
      <div className="steps-action">
        {step === 0 && <BaseButton onClick={() => closeModal()}>Cancel</BaseButton>}
        {step > 0 && (
          <BaseButton
            onClick={() => {
              prev();
              form.setFieldsValue({
                procedure: [],
              });
            }}
          >
            Previous
          </BaseButton>
        )}
        {step < formSteps.length - 1 && (
          <BaseButton
            type="submit"
            variant="primary"
            onClick={() => {
              form.submit();
            }}
            loading={loading}
          >
            Next
          </BaseButton>
        )}
        {step === formSteps.length - 1 && (
          <BaseButton variant="primary" loading={loading} type="submit" onClick={() => form.submit()}>
            Done
          </BaseButton>
        )}
      </div>
    </>
  );
};
export default MakeListingAvailable;
