import React, { useEffect, useRef, useState } from "react";
import { Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useDispatch } from "react-redux";
import { openNotification } from "../../../../../helpers/globalFunctions";
import {
  getPhoneVerificationCode,
  setUpdatedUserSettingsSuccess,
  verifyPhoneNumber
} from "../../../../../store/user/action";
import BaseButton from "../../../../Shared/BaseButton";
import "./EnterNumber.scss";
import SmartPhoneIcon from "../../../../FollowUps/all-follow-ups-modal/components/phone-verify-modal/components/icons/SmartPhoneIcon";
import { AnalyticsService } from "helpers/analyticsService";
//Must Fix Code Duplication
const EnterNumber = ({ backToSignUpHandler }: { backToSignUpHandler: () => void; }) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const refedInput = useRef();
  const dummyRef = useRef();
  const [isDisabled, setIsDisabled] = useState(true);

  const [verifyArray, setVerifyArray] = useState<any>([
    { focused: true, refedInput: refedInput },
    { focused: false },
    { focused: false },
    { focused: false },
    { focused: false },
    { focused: false }
  ]);
  const handleFormChange = () => {
    setIsDisabled(
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0
    );
  };

  useEffect(() => {
    // @ts-ignore
    if (refedInput.current) {
      // @ts-ignore
      refedInput.current.focus();
    }
  }, [verifyArray]);
  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    return () => {
      dispatch(setUpdatedUserSettingsSuccess());
    };
  }, []);

  return (
    <div className={"enter-code-wrapper"}>
      <SmartPhoneIcon />
      <div className={"enter-code-wrapper--text"}>
        <span>Please enter the code sent</span>
        <span>to your phone number</span>
      </div>
      <Form
        form={form}
        onFinishFailed={() => {
          openNotification("error", "Please fill all the fields");
        }}
        onFieldsChange={(e) => {
          handleFormChange();
          setVerifyArray(
            verifyArray.map((elem: any, index: any) => {
              // @ts-ignore
              if (index === +e[0].name[0]) return { focused: true, refedInput: refedInput };
              return { focused: false };
            })
          );
        }}
        onFinish={(data) => {
          dispatch(verifyPhoneNumber(Object.values(data).join("")));
        }}
      >
        {verifyArray.map((element: any, index: number) => {
          return (
            <Form.Item
              rules={[{ required: true, message: "" }, { max: 1 }]}
              name={index + 1}
              key={index}
            // @ts-ignore
            >
              <Input
                maxLength={1}
                autoFocus={element.focused}
                ref={element.refedInput || dummyRef}
              />
            </Form.Item>
          );
        })}
      </Form>
      <BaseButton
        onClick={() => {
          form.submit();
          AnalyticsService.reportSignUpProcess({ action: "enter_phone_code_page_click", label: "verify" });
        }}
        disabled={isDisabled}
        classnames={"rb-primary-btn md"}
      >
        Verify
      </BaseButton>
      <p className={"enter-code-wrapper--resend"}>
        Didn’t get a code?
        <span
          onClick={() => {
            dispatch(getPhoneVerificationCode());
            AnalyticsService.reportSignUpProcess({ action: "enter_phone_code_page_click", label: "resend" });
          }}
        >
          {" "}
          Resend code.
        </span>
      </p>
      <p className={"sign-up-verify-msg-wrapper--back"} onClick={backToSignUpHandler}>
        {"<"} Back
      </p>
    </div>
  );
};

export default EnterNumber;
