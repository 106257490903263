import React, { useEffect, useState } from "react";
import { Form, Spin } from "antd";
import ListingInfo from "./ListingInfo";
import ListingNotes from "./ListingNotes";
import LandlordInfo from "./LandlordInfo";
import ShowingProcedure from "./ShowingProcedure";
import { getEnums } from "../../store/enums/action";
import { useDispatch, useSelector } from "react-redux";
import { deleteListingNote, fillListingData, updateListing } from "../../store/listings/action";
import {
  isPublicListingSelector,
  listingDataSelector,
  listingLoadingSelector,
  listingNotesLoadingSelector,
} from "../../store/listings/selectors";
import { landlordNotesSelector, currentLandlordSelector } from "store/landlords/selectors";
import LandlordNoteModal from "components/Landlord/LandlordNoteModal";
import ContactModal from "components/Shared/ContactModal/ContactModal";
import { addManualFollowUp, clearLandlordNotes, resetSingleLandlordData } from "store/landlords/action";
import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { getListingFollowUps } from "../../store/followUps/action";
import AfterCallModal from "../Shared/AfterCallModal/AfterCallModal";
import AddFollowUpModal from "../FollowUps/add-follow-up-modal/AddFollowUpModal";
import { useForm } from "antd/es/form/Form";
import PhoneVerifyModalFollowUps from "../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import { userSelector } from "store/user/selectors";
import ListingNoteModal from "./add-listing-note-modal/ListingNoteModal";
import AddFollowUpModalMobile from "../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import { useWindowSize } from "../../hooks/useWindowSize";
import AllListingNotesModal from "./all-listing-notes-modal/AllListingNotesModal";
import { ListingNote } from "../Listings/helpers/mockListings";
import moment from "moment";
import { Document } from "@contentful/rich-text-types";
import NoteDeleteConfirmModal from "../Shared/NoteDeleteConfirmModal";
import { IObjectKeys } from "../../helpers/globalTypes";
import { getDeleteNoteConfirmContent } from "../../contentful/api";
import LeadingAgentInfoSection from "./leading-agent-info-section/LeadingAgentInfoSection";
import PremiumUpgradeModal from "../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { LISTING_STATUS_OPTIONS, TRIGGER_BUTTON_LABEL } from "helpers/constants";
import BaseModal from "components/Shared/BaseModal";
import LandlordDetails from "./ListingForm/LandlordDetails";
import TenantInfo from "./TenantInfo";
import TenantsPart from "./ListingForm/ShowingProcedure/components/tenants-part/TenantsPart";
import { openNotification, sanitizePhoneNumber } from "helpers/globalFunctions";
import GlobalLoading from "components/Shared/GlobalLoading";
import { isShareListingPage } from "helpers/clientRoutes";
import ListingMapSection from "./ListingMapSection";
import ListingHistoryCollapse from "./ListingHistory";
import ListingChangelogCollapse from "./ListingChanglog";
import ListingFeaturesCollapse from "./ListingFeaturesCollapse";
import ListingFeesAndTypesCollapse from "./ListingFeesAndTypes";
import ListingDescriptionCollapse from "./ListingDescriptionSection";
import ListingHeader from "./ListingHeader";
import { isAgent, isRenter } from "helpers/userHelpers";
import { AnalyticsService } from "helpers/analyticsService";
import { EVENT_NAME } from "helpers/analytics/eventEnums";
const confirmDeleteValue = { visible: false, noteId: "" };

export interface IListingViewProps {
  canViewListingAgents?: boolean;
  handleShowContactAgentModal?: Function;
  config?: {
    stickyHeader?: boolean;
    forceShowApplyButton?: boolean;
    hideShareButton?: boolean;
  };
  listingData?: {
    images: any;
    history?: any;
    agentOfList: any;
  };
}

const Listing = ({ listingData, config }: IListingViewProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const loadingListing = useSelector(listingLoadingSelector);
  const userData = useSelector(userSelector);
  const initialModalValue = { visible: false, editModal: false };
  const currentLandlord = useSelector(currentLandlordSelector);
  const [landlordActionModalVisible, setLandlordActionModalVisible] = useState(false);
  const listingHistory = useHistory();
  const history = listingData ? listingData?.history : listingHistory;
  const listingToUse = useSelector(listingDataSelector);
  const listing = listingData ? listingData : listingToUse;
  const listingNotesLoading = useSelector(listingNotesLoadingSelector);
  const [form] = useForm();
  const [deleteConfirmProps, setDeleteConfirmProps] = useState(confirmDeleteValue);
  const [deleteConfirmContent, setDeleteConfirmContent] = useState<Document | undefined>(undefined);
  const [afterCallModalProps, setAfterCallModalProps] = useState(false);
  const [followUpModalProps, setFollowUpsModalProps] = useState(initialModalValue);
  const [noteModalProps, setNoteModalProps] = useState(initialModalValue);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const { width } = useWindowSize();
  const isPrivateLandlord =
    listing.landlordOfList?.is_private && listing.landlordOfList?.created_by !== userData.agent_id;
  const [notesModalProps, setNotesModalProps] = useState(initialModalValue);
  const landlordNotesSelectorVariable: any = useSelector(landlordNotesSelector);
  const [selectedNote, setSelectedNote] = useState({});
  const isPublicListing = useSelector(isPublicListingSelector);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const [action, setAction] = useState("");
  const [addLandlordModal, setAddLandlordModal] = useState(false);
  const [agentToContact, setAgentToContact] = useState(null);
  const isCoListingAgent = listing.co_list_agents?.includes(userData.agent_id);
  const hasCoListingAgent = listing.co_list_agents?.length > 0;
  const agentOfList = listingData?.agentOfList ?? listing.agentOfList;
  const isListingAgent = listing.agent_id === userData.agent_id;
  const isAnAgentOfListing = isCoListingAgent || isListingAgent;
  const hasListingAgent = !!listing.agent_id;
  const isListingRented = listing?.status === LISTING_STATUS_OPTIONS[2].status;
  const [addTenantModal, setAddTenantModal] = useState(false);
  const closeAfterCallModalHandler = () => {
    setAfterCallModalProps(false);
  };
  const isShareListing = isShareListingPage(location);

  const handleOpenNoteModal = (note: ListingNote) => {
    const todo_at = note.todo_at ? moment(note.todo_at) : null;
    setNoteModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at,
    });
  };

  useEffect(() => {
    if (!isEmpty(userData)) {
      history?.replace({
        search: "",
      });
    }
  }, [history, userData]);

  useEffect(() => {
    if (followUpModalProps.visible || noteModalProps.visible) {
      setAfterCallModalProps(false);
    }
  }, [noteModalProps, followUpModalProps]);

  useEffect(() => {
    if (Object.keys(listing).length) {
      if (!listingData && !isPublicListing && listing._id) {
        dispatch(getListingFollowUps(listing._id));
      }
    }
  }, [dispatch, isPublicListing, listing]);

  useEffect(() => {
    return () => {
      resetSingleLandlordData();
      addManualFollowUp([]);
      dispatch(clearLandlordNotes());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEnums());
    return () => {
      dispatch(fillListingData({}));
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const entries: IObjectKeys = await getDeleteNoteConfirmContent();
      if (entries) {
        setDeleteConfirmContent(entries.fields.modalDescription);
      }
    })();
  }, [landlordNotesSelectorVariable]);

  useEffect(() => {
    if (!afterCallModalProps) localStorage.removeItem("contact_email_selected");
  }, [afterCallModalProps]);

  const isClearNotes = !location.pathname.includes("landlords");
  useEffect(() => {
    return () => {
      if (isClearNotes) {
        dispatch(clearLandlordNotes());
      }
    };
  }, [dispatch, isClearNotes]);

  useEffect(() => {
    if (listing?._id) {
      // Send a pageview event with custom data
      AnalyticsService.reportListingEvent(EVENT_NAME.LISTING_VIEW, listing);
    }
  }, [listing]);

  const handleShowContactAgentModal = (agent: any) => {
    if (agent) {
      setAgentToContact(agent);
      setLandlordActionModalVisible(true);
    } else {
      setAgentToContact(null);
      setLandlordActionModalVisible(false);
    }
  };

  const onSubmit = (formData: IObjectKeys) => {
    formData.status = "Off-market";
    // eslint-disable-next-line no-restricted-globals
    for (let i = 0; i < formData?.tenants?.length; i++) {
      let tenant = formData.tenants[i];
      if (!tenant.name && !tenant.email && !tenant.phone) {
        formData.tenants = [];
      } else if (!tenant.email && !tenant.phone) {
        return openNotification("error", "At least you should mention tenant email or phone!");
      }
      if (tenant?.phone) {
        tenant.phone = sanitizePhoneNumber(tenant.phone);
      }
    }
    return dispatch(
      updateListing({
        lId: listing._id,
        procedure: {
          ...listing.listingShowingOfList?.procedure,
          call_tenants: formData.tenants.length,
        },
        ...formData,
        step: "showing_procedure",
      })
    );
  };

  let canViewListingAgents =
    isAgent(userData) && (!isPublicListing || hasListingAgent || hasCoListingAgent || isListingAgent || isShareListing); // TODO; update to PERSONAS.OWNER

  if (isShareListing) {
    canViewListingAgents = true;
  }

  return (
    <Spin spinning={loadingListing} className={"global-loading"} indicator={<GlobalLoading />}>
      <div className={`w-full mb-4 relative`}>
        <div className={`${config?.stickyHeader && "sticky top-0"}`}>
          <ListingHeader
            forceShowApplyButton={config?.forceShowApplyButton}
            hideShareButton={config?.hideShareButton}
            listingData={listing}
            isListingAgent={isListingAgent}
            canViewListingAgents={canViewListingAgents}
            handleShowContactAgentModal={handleShowContactAgentModal}
          />
        </div>
        <ListingInfo isAnAgentOfListing={isAnAgentOfListing} listingData={listing} />
        <div className="pb-8">
          <ListingDescriptionCollapse listingData={listing} />
        </div>
        <div className="sm:flex flex-wrap">
          <div className="w-full sm:w-1/2">
            <div className="flex flex-col gap-6">
              <ListingFeaturesCollapse listingData={listing} />
              <ListingFeesAndTypesCollapse listingData={listing} />
            </div>
          </div>
          <div className="w-full sm:w-1/2 pb-6">
            <ListingMapSection listingData={listing} />
            {canViewListingAgents && (
              <div className="pt-8">
                <LeadingAgentInfoSection
                  agent={agentOfList}
                  coListAgents={listing.coAgentOfList}
                  handleButtonClick={handleShowContactAgentModal}
                />
              </div>
            )}
          </div>
        </div>

        {!isPublicListing && (
          <div className="flex flex-col gap-6">
            {isAnAgentOfListing && (
              <LandlordInfo
                listing={listing}
                isCoListingAgent={isCoListingAgent}
                handleViewLandlord={handleShowContactAgentModal}
                handleAddLandlord={setAddLandlordModal}
              />
            )}
            {isAnAgentOfListing && <TenantInfo listing={listing} setAddTenantModal={setAddTenantModal} />}
            {!isListingRented && isAnAgentOfListing && <ShowingProcedure />}
            {isCoListingAgent && <ListingNotes />}
          </div>
        )}

        <div className="flex flex-col gap-6 mt-8">
          <ListingHistoryCollapse canViewListingAgents={canViewListingAgents} />
          {!isPublicListing && isAnAgentOfListing && <ListingChangelogCollapse />}
        </div>
      </div>
      <ContactModal
        setModalProps={() => {
          return;
        }}
        currentLandlordData={agentToContact}
        isPublicListing={isPublicListing}
        showModal={landlordActionModalVisible}
        onClose={setLandlordActionModalVisible}
        setAfterCallModalVisible={setAfterCallModalProps}
        setNoteModalVisible={setNotesModalProps}
        setAction={setAction}
      />
      {addLandlordModal && (
        <BaseModal
          classname={"add-edit-landlord-modal"}
          cancelButtonProps={{
            className:
              "p-3 px-5 text-base rounded-md hover:bg-purple-100 ring-1 ring-inset ring-purple-400 hover:text-purple-800 text-purple-900 h-12",
          }}
          okButtonProps={{
            className:
              "p-3 px-5 h-12 text-base rounded-md hover:text-purple-50 text-white bg-purple-700 focus:bg-purple-600 active:bg-purple-800",
          }}
          modalTitle={"Add a Landlord"}
          isModalVisible={addLandlordModal}
          onOk={() => {
            form.submit();
            setAddLandlordModal(false);
          }}
          onCancel={() => {
            setAddLandlordModal(false);
            form.resetFields();
          }}
        >
          <LandlordDetails form={form} fromListing={true} listingId={listing._id} />
        </BaseModal>
      )}
      {addTenantModal && (
        <BaseModal
          classname={"add-edit-landlord-modal"}
          cancelButtonProps={{
            className:
              "p-3 px-5 text-base rounded-md hover:bg-purple-100 ring-1 ring-inset ring-purple-400 hover:text-purple-800 text-purple-900 h-12",
          }}
          okButtonProps={{
            className:
              "p-3 px-5 h-12 text-base rounded-md hover:text-purple-50 text-white bg-purple-700 focus:bg-purple-600 active:bg-purple-800",
          }}
          modalTitle={"Add a Tenants"}
          isModalVisible={addTenantModal}
          onOk={() => {
            form.submit();
            setAddTenantModal(false);
          }}
          onCancel={() => {
            setAddTenantModal(false);
            form.resetFields();
          }}
        >
          <Form
            form={form}
            onFinish={onSubmit}
            initialValues={{
              call_tenants: listing?.listingShowingOfList?.procedure?.call_tenants,
              lease_start_date: listing?.lease_start_date ? moment(listing?.lease_start_date) : moment(),
            }}
          >
            <TenantsPart form={form} statusChangeForm={true} fromListingView={true} />
          </Form>
        </BaseModal>
      )}
      <AllListingNotesModal
        visible={notesModalProps.visible}
        setVisible={setNotesModalProps}
        handleOpenAddNoteModal={() => {
          setNoteModalProps({ visible: true, editModal: false });
          setLandlordActionModalVisible(false);
        }}
        notes={landlordNotesSelectorVariable}
        handleOpenNoteModal={handleOpenNoteModal}
        selectedNote={selectedNote}
        setSelectedNote={setSelectedNote}
        setDeleteConfirmProps={setDeleteConfirmProps}
        setContactModalVisible={setLandlordActionModalVisible}
      />
      <NoteDeleteConfirmModal
        deleteConfirmProps={deleteConfirmProps}
        setDeleteConfirmProps={setDeleteConfirmProps}
        modalContent={deleteConfirmContent}
        onOkayClick={() => {
          dispatch(deleteListingNote(deleteConfirmProps.noteId || ""));
        }}
      />
      <AfterCallModal
        visible={afterCallModalProps}
        onClose={closeAfterCallModalHandler}
        setFollowUpModalVisible={setFollowUpsModalProps}
        setContactModalVisible={() => {
          return;
        }}
        setNoteModalVisible={setNoteModalProps}
        setPremiumModalVisible={setPremiumModalVisible}
      />
      {
        //@ts-ignore
        width > 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            // listing={listing}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={!isPrivateLandlord ? currentLandlord : null}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={isPrivateLandlord ? "" : action + " landlord"}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            // listing={listing}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={!isPrivateLandlord ? currentLandlord : null}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={isPrivateLandlord ? "" : action + " landlord"}
          />
        )
      }
      {!isPrivateLandlord && (
        <LandlordNoteModal
          modalProps={noteModalProps}
          setModalProps={setNoteModalProps}
          landlordInfo={currentLandlord}
          landlord_id={currentLandlord.landlord_id}
          initialModalValue={initialModalValue}
          form={form}
          defaultTitle={action + " landlord"}
        />
      )}
      {isPrivateLandlord && (
        <ListingNoteModal
          modalProps={noteModalProps}
          listingNotesLoading={listingNotesLoading}
          form={form}
          setModalProps={setNoteModalProps}
          initialModalValue={initialModalValue}
          listing={listing}
        />
      )}
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={() => setPhoneVerifyModalVisible(false)} />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_AFTER_CALL_MODAL}
        id={currentLandlord.landlord_id}
      />
    </Spin>
  );
};

export default Listing;
