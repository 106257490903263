import React from "react";
import { useSelector } from "react-redux";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { getReqCommunicationEnumInputs } from "../../../../../../../../helpers/globalFunctions";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
// import HasCosigner from "../has-co-signer/HasCosigner";
import "./StudentOrEmployed.scss";

interface IStudentOrEmployedProps {
  studentOrEmployed: string | undefined;
}
const StudentOrEmployed = (props: IStudentOrEmployedProps) => {
  const requirementEnums = useSelector(requirementEnumsSelector);
  return (
    <div className={"student-or-employed"}>
      {getReqCommunicationEnumInputs(
        requirementEnums.find((item: EnumInterface) => item.key === "student_or_employed"),
        "inline",
        [],
        ""
      )}
      {/* {props.studentOrEmployed && props.studentOrEmployed !== "employed" && (
        <HasCosigner />
      )} */}
    </div>
  );
};

export default StudentOrEmployed;
