import { useDemoContext } from "libs/contextLib";
import React from "react";
import { Link } from "react-router-dom";
import { IObjectKeys } from "../../../helpers/globalTypes";

const ActivitiesEntity = ({
  _id,
  rowData,
}: {
  _id: string | undefined;
  rowData: IObjectKeys;
  }) => {
  const { isDemo } = useDemoContext();
  const blank = isDemo ? "" : "_blank";
  if (rowData?.entity?.includes("not") || rowData?.entity?.includes("follow")) {
    return (
      <>
        <div>{rowData?.body?.title}</div>
        <div className={"pt-3"}>
          {rowData?.response_data?.listingOfNote ? (
            <Link
              to={`/${isDemo ? "demo/listings" : "listings"}/${
                rowData?.response_data.listing_id
              }?note_id=${rowData?.response_data._id}`}
              target={blank}
            >
              {`Listing: ${rowData?.response_data?.listingOfNote?.addr || ""}`}
            </Link>
          ) : (
            ""
          )}
          {rowData?.response_data?.landlordOfNote ? (
            <Link
              to={`/${isDemo ? "demo/landlords" : "landlords"}/${
                rowData?.response_data.landlord_id
              }`}
              target={blank}
            >
              {`Landlord: ${
                rowData?.landlordOfNote?.response_data?.name ||
                rowData?.response_data?.landlordOfNote?.name ||
                ""
              }`}
            </Link>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  if (rowData?.entity?.includes("listing")) {
    return (
      <div>
        <Link
          to={`/${isDemo ? "demo/listings" : "listings"}/${
            rowData?.response_data.listing_id || rowData?.response_data._id
          }`}
          target={blank}
        >
          {rowData?.response_data?.addr
            ? `Address: ${rowData?.response_data?.addr || " "}`
            : ""}
          {rowData?.response_data?.type ? " Added Photo " : " "}
        </Link>
        {rowData?.response_data?.landlordOfListing?._id && (
          <Link
            to={`/${isDemo ? "demo/landlords" : "landlords"}/${
              rowData?.response_data?.landlordOfListing?._id
            }`}
            target={blank}
          >
            Landlord: {rowData?.response_data?.landlordOfListing?.name || ""}{" "}
          </Link>
        )}
      </div>
    );
  }

  if (rowData?.entity?.includes("user")) {
    if (rowData.response_data.agent_id || rowData?.original_url?.indexOf("invite-agent") > -1) {
        return (
            <div>
                <span>
                    Agent: {rowData?.response_data?.name || ""}
                </span>
            </div>
        );
    }
    return (
      <div>
        <Link
          to={`/${isDemo ? "demo/landlords" : "landlords"}/${
            rowData?.response_data?._id
          }`}
          target={blank}
        >
          <div>Landlord: {rowData?.response_data?.name || ""} </div>
        </Link>
      </div>
    );
  }

  return <span></span>;
};

export default ActivitiesEntity;
