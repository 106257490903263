import React from "react";

export const MenuOptionsIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9C0 4.02944 4.02944 0 9 0H39C43.9706 0 48 4.02944 48 9V40C48 44.9706 43.9706 49 39 49H9C4.02944 49 0 44.9706 0 40V9Z"
        fill="black"
      />
      <circle cx="23.7759" cy="15.7759" r="2.77586" fill="white" />
      <circle cx="23.7759" cy="24.5" r="2.77586" fill="white" />
      <circle cx="23.7759" cy="33.2241" r="2.77586" fill="white" />
    </svg>
  );
};
