import * as types from "../requirement/types";

const initialState = {
  requirementAgentData: {},
  requirementFormData: {},
  requirementLoading: false,
  requirementError: null,
  requirementSuccess: null,
  isFormSubmitted: false,
  requirementFormNotSubmittedData: {},
  leadActionsLoading: false,
  leadSearchLoading: false,
  leadActionsError: null,
  leadActionsSuccess: false,
  leadApproveSuccess: false,
  editRequirementSuccess: false,
  editRequirementError: null,
  leadRequirementAccessSuccess: "",
  renterStatusData: null,
  renterStatusDataLoader: null,
  createdReqEmail: "",
  updatedReqSuccess: false,
  createRequirementLoading: false,
  leadChangeStatusSuccess: false,
  notifyAgentsSuccess: false,
};

export default function requirementReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.GET_REQUIREMENT_AGENT:
      return {
        ...state,
        requirementLoading: true,
        requirementError: null,
      };
    case types.GET_REQUIREMENT_AGENT_SUCCESS:
      return {
        ...state,
        requirementLoading: false,
        requirementAgentData: { ...action.payload },
      };
    case types.GET_REQUIREMENT_AGENT_FAIL:
      return {
        ...state,
        listingLoading: false,
        requirementError: action.payload,
      };
    case types.FILL_REQUIREMENT_FORM_DATA:
      return {
        ...state,
        requirementFormData: {
          ...state.requirementFormData,
          ...action.payload,
        },
      };

    case types.CREATE_REQUIREMENT_FORM:
      return {
        ...state,
        requirementLoading: true,
        createdReqEmail: action.payload.email,
        createRequirementLoading: true,
      };
    case types.CREATE_REQUIREMENT_FORM_SUCCESS:
      return {
        ...state,
        isFormSubmitted: true,
        requirementLoading: false,
        requirementError: null,
        requirementSuccess: action.payload,
        createRequirementLoading: false,
      };
    case types.CREATE_REQUIREMENT_FORM_FAIL:
      return {
        ...state,
        isFormSubmitted: false,
        requirementLoading: false,
        requirementError: action.payload,
        createRequirementLoading: false,
      };
    case types.CHECK_RENTER_STATUS:
      return {
        ...state,
        renterStatusData: null,
        renterStatusDataLoader: true,
      };
    case types.CHECK_RENTER_STATUS_SUCCESS:
      return {
        ...state,
        renterStatusData: action.payload,
        renterStatusDataLoader: false,
      };
    case types.CHECK_RENTER_STATUS_FAIL:
      return {
        ...state,
        renterStatusData: null,
        renterStatusDataLoader: null,
        requirementError: action.payload,
      };
    case types.CREATE_RENTER_DRAFT:
      return {
        ...state,
        renterStatusData: null,
        renterStatusDataLoader: true,
      };
    case types.CREATE_RENTER_DRAFT_SUCCESS:
      return {
        ...state,
        renterStatusData: action.payload,
        renterStatusDataLoader: false,
        requirementFormData: action.payload.createdDraft,
      };
    case types.CREATE_RENTER_DRAFT_FAIL:
      return {
        ...state,
        renterStatusData: {
          account_verified: false,
          exists: true,
        },
        renterStatusDataLoader: null,
        requirementError: action.payload,
      };
    case types.EDIT_REQUIREMENT_FORM:
      return {
        ...state,
        requirementLoading: true,
        editRequirementError: null,
        editRequirementSuccess: false,
      };
    case types.EDIT_REQUIREMENT_FORM_SUCCESS:
      return {
        ...state,
        requirementLoading: false,
        editRequirementSuccess: true,
        editRequirementError: null,
      };
    case types.EDIT_REQUIREMENT_FORM_ERROR:
      return {
        ...state,
        editRequirementError: action.payload,
        requirementLoading: false,
        editRequirementSuccess: false,
      };
    case types.EDIT_REQUIREMENT_FORM_INFO:
      return {
        ...state,
        requirementFormNotSubmittedData: {
          ...state.requirementFormNotSubmittedData,
          ...action.payload,
        },
      };
    case types.EDIT_REQUIREMENT_FORM_PETS_INFO:
      return {
        ...state,
        //@ts-ignore
        requirementFormNotSubmittedData: {
          ...state.requirementFormNotSubmittedData,
          [action.payload.name]: action.payload.value,
        },
      };
    case types.START_LEAD_ANY_ACTION:
      return {
        ...state,
        leadActionsLoading: true,
        leadActionsError: null,
        leadActionsSuccess: false,
        leadApproveSuccess: false,
        leadChangeStatusSuccess: false,
        notifyAgentsSuccess: false,
      };
    case types.START_LEAD_STATUS_ACTION:
      return {
        ...state,
        leadSearchLoading: true,
        leadActionsError: null,
        leadChangeStatusSuccess: false,
      };
    case types.LEAD_ACTION_SUCCESS:
      return {
        ...state,
        leadActionsLoading: false,
        leadSearchLoading: false,
        leadActionsError: null,
        leadActionsSuccess: true,
      };
    case types.LEAD_APPROVE_SUCCESS:
      return {
        ...state,
        leadActionsLoading: false,
        leadActionsError: null,
        leadApproveSuccess: true,
      };

    case types.LEAD_ACTION_ERROR:
      return {
        ...state,
        leadActionsLoading: false,
        leadActionsError: action.payload,
        leadActionsSuccess: false,
        leadApproveSuccess: false,
        notifyAgentsSuccess: false,
      };

    case types.LEAD_ACCESS_REQUIREMENT_SUCCESS:
      return {
        ...state,
        leadActionsLoading: false,
        leadActionsError: null,
        leadActionsSuccess: false,
        leadApproveSuccess: false,
      };

    case types.CLEAR_RENTER_STATUS:
      return {
        ...state,
        renterStatusData: null,
      };
    case types.GET_REQUIREMENT_DRAFT:
      return {
        ...state,
        // updatedReqSuccess: false
      };
    case types.GET_REQUIREMENT_DRAFT_SUCCESS:
      return {
        ...state,
        // updatedReqSuccess: true,
        requirementFormData: action.payload,
      };
    case types.GET_REQUIREMENT_DRAFT_FAIL:
      return {
        ...state,
        // updatedReqSuccess: false,
        requirementError: action.payload,
      };
    case types.UPDATE_RENTER_DRAFT:
      return {
        ...state,
        updatedReqSuccess: false,
      };
    case types.UPDATE_RENTER_DRAFT_SUCCESS:
      return {
        ...state,
        updatedReqSuccess: true,
        requirementFormData: {
          ...state.requirementFormData,
          ...action.payload,
        },
      };
    case types.UPDATE_RENTER_DRAFT_FAIL:
      return {
        ...state,
        updatedReqSuccess: false,
        requirementError: action.payload,
      };
    case types.CLEAR_REQUIREMENTS_DATA:
      return {
        ...initialState,
      };
    case types.SET_UPDATE_RENTER_DRAFT_SUCCESS:
      return {
        ...state,
        updatedReqSuccess: action.payload,
      };
    case types.SET_REQUIREMENT_FORM_DATA_VALUES:
      return {
        ...state,
        requirementFormData: action.payload,
      };
    case types.EDIT_REQ_SUCCESS_SELECTOR:
      return {
        ...state,
        editRequirementSuccess: action.payload,
      };
    case types.LEAD_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        leadChangeStatusSuccess: true,
        leadActionsLoading: false,
        leadSearchLoading: false,
      };
    case types.SET_LEAD_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        leadChangeStatusSuccess: false,
        editRequirementSuccess: action.payload,
      };
    case types.SET_REQUIREMENT_ERROR_TO_BLANK:
      return {
        ...state,
        requirementError: null,
      };
    case types.NOTIFY_AGENTS:
      return {
        ...state,
        notifyAgentsLoading: true,
        notifyAgentsSuccess: false,
      };
    case types.NOTIFY_AGENTS_SUCCESS:
      return {
        ...state,
        notifyAgentsLoading: false,
        notifyAgentsSuccess: true,
      };
    case types.SET_NOTIFY_AGENTS_SUCCESS:
      return {
        ...state,
        notifyAgentsSuccess: false,
      };
    case types.CLEAR_REQ_FORM_SUBMITTED:
      return {
        ...state,
        isFormSubmitted: false
      }
    default:
      return state;
  }
}
