import React from "react";
import LogoSection from "./logo-section/LogoSection";
import SidebarArrow from "./sidebar-arrow/SidebarArrow";
import './SideBarLogoAndArrowSection.scss'
import {useSelector} from "react-redux";
import {windowSelector} from "../../../../store/app/selectors";

interface ISideBarLogoAndArrowSection {
    toggleSidebar: any;
    setVisible:boolean
}

const SideBarLogoAndArrowSection = ({toggleSidebar,setVisible}: ISideBarLogoAndArrowSection) => {
    const {type,collapsed} = useSelector(windowSelector)
    return (
        <div className={`sidebar-logo-wrapperAll ${!setVisible ? "sidebar-logo-wrapperAllVisibleIcon" : ""}`}>
            <SidebarArrow
                toggleSidebar={toggleSidebar}
            />
            <LogoSection toggleSidebar={()=>{
                if (type !== "desktop" && !collapsed) {
                    toggleSidebar();
                }
            }}/>
        </div>
    )
}

export default SideBarLogoAndArrowSection;
