import { RootStateOrAny } from "react-redux";

export const leadDataSelector = (state: RootStateOrAny) =>
  state.leadReducer.leadsList;
export const leadLoadingSelector = (state: RootStateOrAny) =>
  state.leadReducer.leadLoading;
export const leadErrorSelector = (state: RootStateOrAny) =>
  state.leadReducer.leadError;
export const leadsTotalSelector = (state: RootStateOrAny) =>
  state.leadReducer.total;
export const allLeadsTotalSelector = (state: RootStateOrAny) =>
  state.leadReducer.allLeadsTotal;
export const filterOptionsSelector = (state: RootStateOrAny) =>
  state.leadReducer.filterOptions;

export const singleLeadDataSelector = (state: RootStateOrAny) =>
  state.leadReducer.leadData;
export const leadsLastQuerySelector = (state: RootStateOrAny) =>
  state.leadReducer.lastLeadsQuery;
export const paginationValuesSelector = (state: RootStateOrAny) =>
  state.leadReducer.paginationValues;
export const leadNoteErrorSelector = (state: RootStateOrAny) => state.leadReducer.leadNotesError;
export const leadNoteAddSuccessSelector = (state: RootStateOrAny) => state.leadReducer.leadNoteAddSuccess;
export const leadNotesSelector = (state: RootStateOrAny) => state.leadReducer.leadNotes;
export const leadNotesLoadingSelector = (state: RootStateOrAny) => state.leadReducer.leadNotesLoading;

export const leadVerifyEmailErrorSelector = (state: RootStateOrAny) => state.leadReducer.leadEmailVerifyError;
export const leadVerifyEmailSuccessSelector = (state: RootStateOrAny) => state.leadReducer.leadEmailVerifySuccess;
export const leadEmailVerifyCodeSelector = (state: RootStateOrAny) => state.leadReducer.leadEmailVerifyCode;
export const leadConfirmPasswordSuccessSelector = (state: RootStateOrAny) => state.leadReducer.leadConfirmPasswordSuccess;
export const leadConfirmPasswordErrorSelector = (state: RootStateOrAny) => state.leadReducer.leadConfirmPasswordError;
export const leadAccessTokenSelector = (state: RootStateOrAny) => state.leadReducer.leadAccessToken;
export const leadAccountLinkSelector = (state: RootStateOrAny) => state.leadReducer.leadAccountLink;
export const leadSmsSentSuccessSelector = (state:RootStateOrAny) => state.leadReducer.sendLeadInfoLoading;

export const leadUpdateTagsSuccessSelector = (state: RootStateOrAny) =>
  state.leadReducer.leadUpdateTagsSuccess;
export const leadUpdateTagsErrorSelector = (state: RootStateOrAny) =>
  state.leadReducer.leadUpdateTagsError;
export const leadRemoveTagSuccessSelector = (state:RootStateOrAny) => state.leadReducer.leadRemoveTagSuccess;
export const leadRemoveTagErrorSelector = (state: RootStateOrAny) => state.leadReducer.leadRemoveTagError;
export const leadUpdateTagsLoadingSelector = (state: RootStateOrAny) =>
  state.leadReducer.leadUpdateTagsLoading;
