import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { leadLoadingSelector, singleLeadDataSelector } from "../../../../store/leads/selectors";
import BaseButton from "../../../Shared/BaseButton";
import "./LeadAvailabilities.scss";
import AvailabilityInfoSection from "./components/availability-info-section/AvailabilityInfoSection";
import AvailabilityInfoSectionMobile from "./components/availability-info-section-mobile/AvailabilityInfoSectionMobile";
import { IObjectKeys } from "helpers/globalTypes";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import FollowUpModalIcon from "../../../icons/FollowUpModalIcon";
import {
  addFollowUpSuccessSelector,
  editedFollowUpSelector,
  editFollowUpSuccessSelector,
  followUpsListSelector,
} from "../../../../store/followUps/selectors";
import AllFollowUpsModal from "../../../FollowUps/all-follow-ups-modal/AllFollowUpsModal";
import { ListingNote } from "../../../Listings/helpers/mockListings";
import AddFollowUpModal from "../../../FollowUps/add-follow-up-modal/AddFollowUpModal";
import { getLeadFollowUps } from "../../../../store/followUps/action";
import PhoneVerifyModalFollowUps from "../../../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import AddFollowUpModalMobile from "../../../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import EditSquareIconSingleLead from "../../../icons/EditSquareIconSingleLead";
import IndividualLeadSharePopup from "../../../Shared/IndividualLeadSharePopup/IndividualLeadSharePopup";
import TagDropdown from "./components/tag-dropdown";
import { useDemoContext } from "libs/contextLib";
import { AnalyticsService } from "helpers/analyticsService";
import NotifyAgentsModal from "./components/lead-search-status/components/notify-agents-modal/NotifyAgentsModal";
import { push } from "connected-react-router";
import { followUpModalsHandler } from "../../../../helpers/globalFunctions";
import PremiumUpgradeModal from "../../../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import SearchStatus from "./components/lead-search-status/index";
import "./style.scss";
import { tags } from "../../shared/constants";
import LeadRequestScreeningDialog from "components/Lead/renter-lead-page/RequestScreeningModal/LeadRequestScreeningModal";
import { userSelector } from "store/user/selectors";
import { getShareableLandlordEntity } from "store/shareable/action";
import { windowSelector } from "store/app/selectors";
import ShareIcon from "components/Sidebar/components/referrals-unlocked/icons/ShareIcon";
import formatDate from "helpers/dateHelper";
import { EVENT_NAME } from "helpers/analytics/eventEnums";

interface ILeadAvailabilities {
  setMatchingListings: Function;
  isMatchingListings: boolean;
}

const LeadAvailabilities = (props: ILeadAvailabilities) => {
  const initialModalValue = { visible: false, editModal: false };
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowSize();
  const [form] = Form.useForm();
  const leadFlow = location.pathname.includes("lead-flow");
  const leadData = useSelector(singleLeadDataSelector);
  const [showShareProfileModal, setShowShareProfileModal] = useState(false);
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const leadLoading = useSelector(leadLoadingSelector);
  const userData = useSelector(userSelector);

  const move_in_date_type = leadData?.requirement?.move_in_date_start
    ? "move_in_date_start"
    : leadData?.requirement?.move_in_date_end
      ? "move_in_date_end"
      : "";

  const move_in_date = formatDate(leadData?.requirement?.[move_in_date_type]);
  const [leadAvailabilityDate, setLeadAvailabilityDate] = useState("");
  const addFollowUpSuccess = useSelector(addFollowUpSuccessSelector);
  const editFollowUpSuccess = useSelector(editFollowUpSuccessSelector);
  const name = Object.keys(leadData.renter ?? {}).length
    ? leadData.renter?.name
    : Object.keys(leadData.agent ?? {}).length
      ? `${leadData.agent?.name}'s Lead`
      : "Pending Lead";
  const followUps = useSelector(followUpsListSelector);
  const [allFollowUpsModalVisible, setAllFollowUpsModalVisible] = useState(initialModalValue);
  const openAllFollowUpsModalHandler = () => {
    setAllFollowUpsModalVisible({ visible: true, editModal: false });
  };
  const [followUpModalProps, setFollowUpModalProps] = useState(initialModalValue);
  const [isDescShown, setIsDescShown] = useState(false);
  const [isFollowUpEditable, setIsFollowUpEditable] = useState(false);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const editedFollowUp = useSelector(editedFollowUpSelector);
  const { isDemo, demoAction } = useDemoContext();
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const [leadScreeningModalVisible, setLeadScreeningModalVisible] = useState(false);
  const { type } = useSelector(windowSelector);
  const isMobile = type === "mobile";

  useEffect(() => {
    if (userData.agent_id) {
      dispatch(getShareableLandlordEntity(userData.agent_id));
    }
  }, [userData.agent_id]);

  const handleOpenAddNoteModal = () => {
    setFollowUpModalProps({ visible: true, editModal: false });
    form.resetFields();
    setIsDescShown(false);
    setIsFollowUpEditable(false);
  };
  const closeVerifyModalHandler = () => {
    setPhoneVerifyModalVisible(false);
  };
  const handleOpenFollowUpModal = (note: ListingNote) => {
    setFollowUpModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at: note.todo_at,
    });
    setIsFollowUpEditable(true);
    if (note.description && note.description.length > 0) {
      setIsDescShown(true);
    } else {
      setIsDescShown(false);
    }
  };
  const onCloseSharePopup = () => {
    setShowShareProfileModal(false);
  };
  const onOpenShareOpoup = () => {
    AnalyticsService.reportRenterPageEvent("share_rental_profile");
    setShowShareProfileModal(true);
  };

  useEffect(() => {
    if (leadData) {
      if (leadData?.requirement?.move_in_date_end) {
        setLeadAvailabilityDate(
          `${formatDate(leadData?.requirement?.move_in_date_start)} - ${formatDate(
            leadData?.requirement?.move_in_date_end
          )}`
        );
      } else {
        setLeadAvailabilityDate(`${formatDate(leadData?.requirement?.move_in_date_start)}`);
      }
      if (leadData?.requirement?.move_in_date_end === leadData?.requirement?.move_in_date_start) {
        setLeadAvailabilityDate(`${formatDate(leadData?.requirement?.move_in_date_start)}`);
      }
    }
  }, [leadData]);

  useEffect(() => {
    if (addFollowUpSuccess && followUpModalProps.visible === false && leadData.renter) {
      dispatch(getLeadFollowUps(leadData.renter._id));
    }
  }, [addFollowUpSuccess, dispatch, editFollowUpSuccess, followUpModalProps, leadData]);
  useEffect(() => {
    if (Object.keys(editedFollowUp).length > 0) {
      followUpModalsHandler({
        setFollowUpModalProps,
        setAllFollowUpsModalVisible,
        editedFollowUp,
        dispatch,
      });
    }
  }, [editedFollowUp]);

  useEffect(() => {
    if (location.search.includes("?searchStatus=true")) {
      setIsNotifyModalOpen(true);
      dispatch(push(location.pathname));
    }
  }, [location]);

  return (
    <Row className={"mt-6"}>
      <Col className="single-listing-info-wrapper " span={24}>
        <Col className="display-flex flex-between lead-top-info-wrapper">
          <Col className="listing-address-section-wrapper lead-name-tag-wrapper">
            <p className="text-4xl font-bold pr-4">{name}</p>
            {!leadFlow && (
              <TagDropdown
                id={leadData?.requirement?._id}
                existingTags={leadData?.tags || []}
                tags={tags}
                type="lead"
              />
            )}
          </Col>
          {leadFlow &&
            //@ts-ignore
            width > 768 && (
              <div className="lead-actions-wrapper">
                <SearchStatus />
                <Button
                  className="wrapper-naked-button wrapper-naked-button-share"
                  dat-test-id="edit-requirement-button"
                >
                  <Link
                    to={`/requirement/edit/${leadData?.requirement?.agentUser_ids?.[0]}?reqStep=2&requirement_id=${leadData?.requirement?._id}`}
                    onClick={() => AnalyticsService.reportRenterPageEvent("edit_rental_profile")}
                  >
                    <EditSquareIconSingleLead />
                  </Link>
                </Button>
                <IndividualLeadSharePopup visible={showShareProfileModal} onClose={onCloseSharePopup} />
              </div>
            )}
        </Col>
        <AvailabilityInfoSection
          move_in_date={move_in_date}
          leadData={leadData}
          leadAvailabilityDate={leadAvailabilityDate}
        />
        <Col className="lead-availibilities-col">
          <div className={"lead-desirable-locations-wrapper"}>
            {leadData?.requirement?.area?.map((loc: IObjectKeys, index: number) => {
              if (!loc.location.length) {
                return (
                  <span key={index}>
                    <p className={"area-of-location"}>
                      {Object.keys(loc)
                        .filter((location) => ["town", "state"].includes(location))
                        .reverse()
                        .map((location) => loc[location])
                        .join(", ") || loc.area}
                    </p>
                  </span>
                );
              }
              return loc.location.map((e: any, i: number) => {
                return (
                  <span key={i}>
                    <p>{e}</p>
                    <p className={"area-of-location"}>
                      {Object.keys(loc)
                        .filter((location) => ["town", "state"].includes(location))
                        .reverse()
                        .map((location) => loc[location])
                        .join(", ")}
                    </p>
                  </span>
                );
              });
            })}
          </div>
          {!leadFlow && (
            <Col className={"lead-top-info-wrapper--button"}>
              {
                //@ts-ignore
                leadData.renter?._id && width > 599 ? (
                  <div className={"single-listing-actions-wrapper--followUp"}>
                    <div className={"single-listing-actions-wrapper--followUp--circle"}>
                      <span>{followUps.length}</span>
                    </div>
                    <FollowUpModalIcon
                      openModalHandler={() => (isDemo ? demoAction("followUp") : openAllFollowUpsModalHandler())}
                      classNames="lead-follow-up"
                    />
                  </div>
                ) : null
              }
              {leadData.renter?._id && (
                <Col>
                  <div style={width && width > 599 ? { display: "block" } : { marginBottom: "0" }}>
                    <BaseButton
                      classnames="rb-primary-btn lead-action-btn md h-25"
                      onClick={() => {
                        setLeadScreeningModalVisible(true);
                        AnalyticsService.reportClickEvent(EVENT_NAME.REQUEST_SCREENING_CLICK, "request_screening_button", "");
                      }}
                    >
                      <div className="btn-content">
                        <span className="btn-text">{isMobile ? "Screen" : "Request Screening"}</span>
                      </div>
                    </BaseButton>
                  </div>
                </Col>
              )}
              <Col>
                <BaseButton
                  classnames="rb-primary-btn md h-25"
                  onClick={() => {
                    props.setMatchingListings((isMatching: boolean) => !isMatching);
                    AnalyticsService.reportClickEvent(EVENT_NAME.REQUEST_MATCHING_LISTINGS, "matching_listings_button", "");
                  }}
                >
                  <div className="btn-content">
                    <span className="btn-text">{isMobile ? "Matching Listings" : "See Matching Listings"}</span>
                  </div>
                </BaseButton>
              </Col>
            </Col>
          )}
        </Col>
      </Col>
      <AllFollowUpsModal
        visible={allFollowUpsModalVisible.visible}
        setVisible={setAllFollowUpsModalVisible}
        handleOpenAddFollowUpModal={handleOpenAddNoteModal}
        notes={followUps}
        handleOpenFollowUpEditModal={handleOpenFollowUpModal}
        setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
      />
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            lead={leadData}
            isDescShown={isDescShown}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            lead={leadData}
            isDescShown={isDescShown}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        )
      }
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={closeVerifyModalHandler} />
      {isNotifyModalOpen && !leadLoading && (
        <NotifyAgentsModal toggle={isNotifyModalOpen} setToggle={setIsNotifyModalOpen} />
      )}
      <LeadRequestScreeningDialog
        visible={leadScreeningModalVisible}
        setVisible={setLeadScreeningModalVisible}
        renter={{
          renter_id: leadData.renter?.renter_id,
          name: leadData.renter?.name,
        }}
      />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_ALL_FOLLOW_UPS_MODAL}
      />
    </Row>
  );
};

export default LeadAvailabilities;
