import React, { useEffect, useState } from "react";
import { urlQueryToObject } from "../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { useHistory } from "react-router-dom";
import NewUserVerification from "../verify-phone";
import SignupSettingsForm from "../settings-form";
import "./styles.scss";
import SignUpSettingFormBottom from "../sign-up-settings-form-bottom/SignUpSettingFormBottom";
import { useDispatch } from "react-redux";
import { getLoggedInUser } from "store/user/action";
import { push } from "connected-react-router";
import qs from "qs";

const SignUpFormSteps = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [step, setStep] = useState<String | undefined>();
  const _newURLSearchParams = new URLSearchParams(history.location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  const handlePhoneVerificatoin = () => {
    setStep("2");
    queryObject.step = "2";
    dispatch(
      push({
        pathname: history.location.pathname,
        search: qs.stringify(queryObject),
      })
    );
    dispatch(getLoggedInUser());
  };

  useEffect(() => {
    setStep(queryObject.step);
    window.scrollTo(0, 0);
  }, [queryObject]);

  return (
    <div className={"sign-up-form-steps"}>
      <div className={"sign-up-form-steps-wrapper"}>
        <div>
          {step === "1" ? (
            <NewUserVerification onPhoneVerification={handlePhoneVerificatoin} />
          ) : (
            <SignupSettingsForm />
          )}
        </div>
        <SignUpSettingFormBottom />
      </div>
    </div>
  );
};

export default SignUpFormSteps;
