import React from "react";
import SmartPhoneIcon from "./icons/SmartPhoneIcon";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import {
    PHONE_NUMBER_MASK,
    PHONE_NUMBER_VALIDATION,
    REQUIRED
} from "../../../../../../helpers/validations";
import MaskedInput from "../../../../../Shared/MaskedPhoneInput";
import "./VerifyPhoneForm.scss";
import {useDispatch, useSelector} from "react-redux";
import {getPhoneVerificationCode, updateUserSettings} from "../../../../../../store/user/action";
import {userSelector} from "../../../../../../store/user/selectors";
import {sanitizePhoneNumber} from "../../../../../../helpers/globalFunctions";

const VerifyPhoneForm = () =>{
    const [form] = useForm();
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const updateUserSettingsHandler =async (number:any) =>{
        dispatch(
            updateUserSettings({
                address: user.address,
                defaultCity: user.defaultCity,
                defaultState: user.defaultState,
                email: user.email,
                emailNotifications: user.emailNotifications,
                name: user.name,
                phone: number,
                _id: user._id,
                smsNotifications: false,
            }));
    };
    return(
        <div className={"verify-phone-modal-from-followUps--formWrapper"}>
            <SmartPhoneIcon/>
            <h3>Verify your phone</h3>
            <span>
                Unlock SMS reminders
                and follow-ups
            </span>
            <p>Enter your phone number</p>
            <Form onFinish={async (data)=>{
                updateUserSettingsHandler(sanitizePhoneNumber(data.number)).then(()=>setTimeout(()=>{
                    dispatch(getPhoneVerificationCode());
                },2000));
            }}>
                <Form.Item name={"number"} rules={[REQUIRED, PHONE_NUMBER_VALIDATION]}>
                    <MaskedInput
                        inputMode="numeric"
                        mask={PHONE_NUMBER_MASK}
                        placeholder={"000-0000-0000"}
                    />
                </Form.Item>
                <button onClick={()=>{
                    form.submit();
                }}>Send verification code</button>
            </Form>

        </div>
    );
};

export default VerifyPhoneForm;
