import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { push } from "connected-react-router";
import {
  followUpsListSelector,
  followUpsLoadingSelector,
  followUpsListTotalSelector,
  queryAndPaginationSelector,
  addFollowUpSuccessSelector,
  editFollowUpSuccessSelector,
} from "store/followUps/selectors";
import { getFollowUpsList, clearFollowUpsList, setFollowUpsQueryAndPagination } from "store/followUps/action";

import FollowUpsList from "./FollowUpsList";
import "./styles.scss";
import { routerSelector } from "../../store/listings/selectors";
import { IFollowUpsQuery, TodoStatusEnum } from "./types";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useForm } from "antd/es/form/Form";
import SwitchButton from "../../components/ListingView/ListingForm/SwitchButton";
import { scrollToTop } from "../../helpers/globalFunctions";
import { AnalyticsService } from "helpers/analyticsService";
import AddFollowUpModal from "./add-follow-up-modal/AddFollowUpModal";
import AddFollowUpModalMobile from "./add-follow-up-modal-mobile/AddFollowUpModalMobile";
import PhoneVerifyModalFollowUps from "./all-follow-ups-modal/components/phone-verify-modal";
import ActivateSection from "components/Shared/ActivateSection";
import { useDemoContext } from "libs/contextLib";
import SingleFUWrapper from "./single-fu-wrapper/SingleFUWrapper";
import PremiumUpgradeModal from "../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { ACTIONS, TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import Section from "components/Shared/Section/Section";

const initialModalValue = { visible: false, editModal: false };

const FollowUps = () => {
  const [isHideSystemFollowUps, setIsHideSystemFollowUps] = useState(false);
  const dispatch = useDispatch();
  const router = useSelector(routerSelector);
  const initialLoad = useRef(true);
  const followUpsList = useSelector(followUpsListSelector);
  const followUpsLoading = useSelector(followUpsLoadingSelector);
  const total = useSelector(followUpsListTotalSelector);
  const [hasMore, setHasMore] = useState(true);
  const [visibility, setVisibility] = useState(false);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const addFollowUpSuccess = useSelector(addFollowUpSuccessSelector);
  const editFollowUpSuccess = useSelector(editFollowUpSuccessSelector);
  const [form] = useForm();
  const [modalProps, setModalProps] = useState(initialModalValue);
  const { width }: any = useWindowSize();
  const _newURLSearchParams = new URLSearchParams(router.location.search);
  const { isDemo } = useDemoContext();
  const queryAndPagination = useSelector(queryAndPaginationSelector);
  const urlParam: any = _newURLSearchParams.get("view");
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  useEffect(() => {
    if (queryObject?.action === ACTIONS.ADD_FOLLOW_UP) {
      setModalProps({ visible: true, editModal: false });
    }
  }, [router.location.search]);

  const delayGetFollowUpsList = useRef(
    debounce((queryAndPagination: IFollowUpsQuery) => {
      dispatch(getFollowUpsList(queryAndPagination));
    }, 500)
  );

  useEffect(() => {
    let _todo_status = TodoStatusEnum.in_progress;

    if (urlParam === TodoStatusEnum.completed || urlParam === TodoStatusEnum.in_progress) {
      _todo_status = urlParam;
    }
    dispatch(
      setFollowUpsQueryAndPagination({
        ...queryAndPagination,
        todo_status: _todo_status,
        searchValue: queryObject.searchValue,
        sortField: queryObject.sortField,
        sortOrder: queryObject.sortOrder,
      })
    );
  }, []);

  useEffect(() => {
    if (queryAndPagination.todo_status) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      delayGetFollowUpsList?.current({ ...queryAndPagination, ...queryObject });
    }
    //Scroll to top after any change
    const followUpsPageWrapper: HTMLElement | null = document.querySelector(".follow-ups-page-wrapper");
    scrollToTop();
    scrollToTop(followUpsPageWrapper);
  }, [queryAndPagination]);

  useEffect(() => {
    if (total && total < 1) changeStatusDone();
  }, [total]);

  useEffect(() => {
    if (isHideSystemFollowUps) {
      localStorage.setItem("isHydeSystemFollowUps", JSON.stringify(isHideSystemFollowUps));
      dispatch(
        setFollowUpsQueryAndPagination({
          ...queryAndPagination,
          isHideSystem: isHideSystemFollowUps,
        })
      );
    } else {
      dispatch(
        setFollowUpsQueryAndPagination({
          ...queryAndPagination,
          isHideSystem: undefined,
        })
      );
    }
  }, [isHideSystemFollowUps]);

  useEffect(() => {
    if (localStorage.getItem("isHydeSystemFollowUps")) {
      setIsHideSystemFollowUps(true);
    }
  }, [localStorage.getItem("isHydeSystemFollowUps")]);
  useEffect(() => {
    return () => {
      dispatch(clearFollowUpsList());
      dispatch(
        setFollowUpsQueryAndPagination({
          page: 1,
          pageSize: 20,
          sortField: "todo_at",
          sortOrder: 1,
          fillAll: true,
          searchValue: "",
        })
      );
    };
  }, []);

  const changeStatusDone = () => {
    dispatch(
      setFollowUpsQueryAndPagination({
        ...queryAndPagination,
        page: 1,
        //@ts-ignore
        pageSize: 20,
        todo_status: TodoStatusEnum.completed,
      })
    );

    dispatch(
      push({
        pathname: isDemo ? "/demo/followUps" : "/followUps",
        search: `?view=${TodoStatusEnum.completed}${
          queryObject.searchValue ? `&searchValue=${queryObject.searchValue}` : ""
        }`,
      })
    );
    dispatch(clearFollowUpsList());
  };
  const changeStatusTodo = () => {
    dispatch(
      setFollowUpsQueryAndPagination({
        ...queryAndPagination,
        page: 1,
        pageSize: 20,
        todo_status: TodoStatusEnum.in_progress,
      })
    );
    dispatch(
      push({
        pathname: isDemo ? "/demo/followUps" : "/followUps",
        search: `?view=${TodoStatusEnum.in_progress}${
          queryObject.searchValue ? `&searchValue=${queryObject.searchValue}` : ""
        }`,
      })
    );
    dispatch(clearFollowUpsList());
  };

  const toggleChangeStatus = () => {
    if (queryAndPagination.todo_status === TodoStatusEnum.in_progress) {
      changeStatusDone();
      AnalyticsService.reportFilterValues("to-do status", "done");
    } else {
      changeStatusTodo();
      AnalyticsService.reportFilterValues("to-do status", "to do");
    }
  };

  const toggleShowSystemFollowUps = () => {
    setIsHideSystemFollowUps((prev) => !prev);
  };

  const handleInfiniteOnLoad = () => {
    hasMore &&
      dispatch(
        setFollowUpsQueryAndPagination({
          ...queryAndPagination,
          page: queryAndPagination.page + 1,
        })
      );
  };
  useEffect(() => {
    if (addFollowUpSuccess && !modalProps.visible) {
      dispatch(getFollowUpsList(queryAndPagination));
    }
    if (editFollowUpSuccess) {
      dispatch(getFollowUpsList(queryAndPagination));
    }
  }, [addFollowUpSuccess, editFollowUpSuccess, modalProps]);

  return (
    <div>
      <div>
        {/* <Switch
            onChange={toggleChangeStatus}
            checked={
              queryAndPagination.todo_status === TodoStatusEnum.completed
            }
          /> */}
        <SwitchButton
          onChange={toggleChangeStatus}
          disabled={false}
          checked={queryAndPagination.todo_status === TodoStatusEnum.completed}
          classnames={{
            switcher: "switcher-followups-page",
            switchButtonSecion: "switcher-section-follow-ups",
          }}
        />
      </div>
      {isDemo && (
        <Section border>
          <ActivateSection type="followUp" className="mb-20 mt-25 mobile-activate-modal" />
        </Section>
      )}
      <Section border>
        <fieldset>
          <div className="relative flex items-start">
            <div className="flex h-6 items-center mt-3">
              <input
                id="candidates"
                aria-describedby="candidates-description"
                name="candidates"
                type="checkbox"
                checked={isHideSystemFollowUps}
                className="h-8 w-8 rounded-lg border-gray-300 text-purple-600 focus:ring-purple-600"
                onClick={() => toggleShowSystemFollowUps()}
              />
            </div>
            <div className="ml-3">
              <label htmlFor="candidates" className="font-medium text-gray-900 text-base">
                Hide system follow-ups
              </label>{" "}
              <div id="candidates-description" className="text-gray-500 text-sm">
                System follow-ups are created automatically throughout the rental cycle. Learn more in settings.
              </div>
            </div>
          </div>
        </fieldset>
        {/* <Dropdown
          onVisibleChange={(visible) => {
            setVisibility(visible);
          }}
          visible={visibility}
          trigger={["click"]}
          getPopupContainer={() => {
            return document.querySelector(
              `.follow-ups-sort-btn`
            ) as HTMLElement;
          }}
          overlayClassName={"filter-follow-ups-dropdown"}
          overlay={
            <FollowUpSortNewOverlay
              setVisibility={setVisibility}
              setQueryAndPagination={setFollowUpsQueryAndPagination}
              queryAndPagination={queryAndPagination}
              queryObject={queryObject}
            />
          }
          placement="bottomLeft"
        >
          <Button
            onClick={() => setVisibility(true)}
            className="filter-icon-btn follow-ups-sort-btn"
            data-test-id="sort-followups-btn"
          >
            <SortIcon />
          </Button>
        </Dropdown> */}
      </Section>
      <SingleFUWrapper />
      <FollowUpsList
        followUpsLoading={followUpsLoading}
        hasMore={hasMore}
        setHasMore={setHasMore}
        followUpsList={followUpsList}
        total={total}
        initialLoad={initialLoad}
      />
      {total > 20 && followUpsList.length < total ? (
        <div className={"follow-ups-page-wrapper--loadMoreWrapper"}>
          <button onClick={handleInfiniteOnLoad}>Load more</button>
        </div>
      ) : null}
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={modalProps.visible}
            setModalProps={setModalProps}
            form={form}
            // listing={listing}
            modalProps={modalProps}
            isDescShown={false}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={modalProps.visible}
            setModalProps={setModalProps}
            form={form}
            modalProps={modalProps}
            isDescShown={false}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        )
      }
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={() => setPhoneVerifyModalVisible(false)} />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_TOP_BUTTON}
      />
    </div>
  );
};

export default FollowUps;
