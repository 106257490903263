import { RootStateOrAny } from "react-redux";

export const requirementAgentDataSelector = (state: RootStateOrAny) =>
  state.requirementReducer.requirementAgentData;

export const requirementFormDataSelector = (state: RootStateOrAny) =>
  state.requirementReducer.requirementFormData;

export const isRequirementFormSubmittedSelector = (state: RootStateOrAny) =>
  state.requirementReducer.isFormSubmitted;

export const loadingRequirementAgentDataSelector = (state: RootStateOrAny) =>
  state.requirementReducer.requirementLoading;

export const requirementFormErrorSelector = (state: RootStateOrAny) =>
  state.requirementReducer.requirementError;

export const requirementFormSuccessSelector = (state: RootStateOrAny) =>
  state.requirementReducer.requirementSuccess;

export const requirementFormEdittingSelector = (state: RootStateOrAny) =>
  state.requirementReducer.requirementFormNotSubmittedData;

export const leadAnyActionLoadingSelector = (state: RootStateOrAny) =>
  state.requirementReducer.leadActionsLoading;
  
export const leadSearchLoadingSelector = (state: RootStateOrAny) =>
  state.requirementReducer.leadSearchLoading;

export const leadAnyActionErrorSelector = (state: RootStateOrAny) =>
  state.requirementReducer.leadActionsError;

export const leadAnyActionSuccessSelector = (state: RootStateOrAny) =>
  state.requirementReducer.leadActionsSuccess;
export const leadApproveSuccessSelector = (state: RootStateOrAny) =>
  state.requirementReducer.leadApproveSuccess;
export const leadChangeStatusSuccessSelector = (state: RootStateOrAny) =>
  state.requirementReducer.leadChangeStatusSuccess;
export const editRequirementErrorSelector = (state: RootStateOrAny) =>
  state.requirementReducer.editRequirementError;
export const editRequirementSuccessSelector = (state: RootStateOrAny) =>
  state.requirementReducer.editRequirementSuccess;

export const leadRequirementAccessSuccessSelector = (state: RootStateOrAny) =>
  state.requirementReducer.leadRequirementAccessSuccess;

export const renterStatusSelector = (state: RootStateOrAny) =>
  state.requirementReducer.renterStatusData;
export const renterStatusLoadingSelector = (state: RootStateOrAny) =>
  state.requirementReducer.renterStatusDataLoader;

export const createdEmailFromReqFormSelector = (state:RootStateOrAny) =>
    state.requirementReducer.createdReqEmail

export const updatedReqSuccessSelector = (state:RootStateOrAny) =>
    state.requirementReducer.updatedReqSuccess
export const createRequirementLoadingSelector = (state:RootStateOrAny) =>
    state.requirementReducer.createRequirementLoading

export const notifyAgentsSuccessSelector = (state: RootStateOrAny) =>
  state.requirementReducer.notifyAgentsSuccess;

export const notifyAgentsLoadingSelector = (state: RootStateOrAny) =>
  state.requirementReducer.notifyAgentsLoading;