import React, { useCallback, useEffect, useState } from "react";
import { ListingNote } from "../../helpers/mockListings";
import { useDispatch, useSelector } from "react-redux";
import { listingNotesLoadingSelector } from "../../../../store/listings/selectors";
import { IObjectKeys } from "../../../../helpers/globalTypes";
import { currentLandlordSelector, landlordNotesSelector } from "../../../../store/landlords/selectors";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import ContactModal from "components/Shared/ContactModal/ContactModal";
import { clearLandlordNotes, resetSingleLandlordData } from "store/landlords/action";
import AddFollowUpModal from "components/FollowUps/add-follow-up-modal/AddFollowUpModal";
import { useForm } from "antd/lib/form/Form";
import LandlordNoteModal from "../../../Landlord/LandlordNoteModal";
import AfterCallModal from "../../../Shared/AfterCallModal/AfterCallModal";
import PhoneVerifyModalFollowUps from "../../../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import { userSelector } from "store/user/selectors";
import ListingNoteModal from "components/ListingView/add-listing-note-modal/ListingNoteModal";
import AddFollowUpModalMobile from "../../../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import AllListingNotesModal from "../../../ListingView/all-listing-notes-modal/AllListingNotesModal";
import moment from "moment";
import { deleteListingNote } from "../../../../store/listings/action";
import NoteDeleteConfirmModal from "../../../Shared/NoteDeleteConfirmModal";
import { Document } from "@contentful/rich-text-types";
import { getDeleteNoteConfirmContent } from "../../../../contentful/api";
import ListingsViewWrapper from "./components/listings-view-wrapper/ListingsViewWrapper";
import PremiumUpgradeModal from "../../../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { getAreas } from "../../../../store/areas/action";
import { getEnums } from "../../../../store/enums/action";
import LandlordDetails from "components/ListingView/ListingForm/LandlordDetails";
import BaseModal from "components/Shared/BaseModal";

const confirmDeleteValue = { visible: false, noteId: "" };

export interface IPaginationValues {
  pageSize: number;
  current: number;
}

interface IListingsView {
  showingCount: number;
  setShowingCount: any;
}

const initialModalValue = { visible: false, editModal: false };

const ListingsView = React.memo(({ showingCount, setShowingCount }: IListingsView) => {
  const dispatch = useDispatch();
  const [agentToContact, setAgentToContact] = useState(null);
  const [landlordActionModalVisible, setLandlordActionModalVisible] = useState(false);
  const [deleteConfirmProps, setDeleteConfirmProps] = useState(confirmDeleteValue);
  const [deleteConfirmContent, setDeleteConfirmContent] = useState<Document | undefined>(undefined);
  const [form] = useForm();
  const currentLandlord = useSelector(currentLandlordSelector);
  const listingNotesLoading = useSelector(listingNotesLoadingSelector);
  const { width } = useWindowSize();
  const location = useLocation();
  const [afterCallModalProps, setAfterCallModalProps] = useState(false);
  const [followUpModalProps, setFollowUpsModalProps] = useState(initialModalValue);
  const [noteModalProps, setNoteModalProps] = useState(initialModalValue);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const [checkedListing, setCheckedListing] = useState<IObjectKeys>({});
  const userData = useSelector(userSelector);
  const [notesModalProps, setNotesModalProps] = useState(initialModalValue);
  const landlordNotesSelectorVariable: any = useSelector(landlordNotesSelector);
  const [selectedNote, setSelectedNote] = useState({});
  const isClearNotes = !location.pathname.includes("landlords");
  const setCheckedListingCallback = useCallback(setCheckedListing, []);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const [action, setAction] = useState("");
  const [addLandlordModal, setAddLandlordModal] = useState(false);
  const [listingId, setListingId] = useState("");
  const isPrivateLandlord =
    (currentLandlord?.is_private && currentLandlord?.created_by !== userData.agent_id) ||
    !currentLandlord.created_by ||
    !currentLandlord.landlord_id;

  const closeAfterCallModalHandler = () => {
    setAfterCallModalProps(false);
  };

  const handleOpenNoteModal = (note: ListingNote) => {
    const todo_at = note.todo_at ? moment(note.todo_at) : null;
    setNoteModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at,
    });
  };

  useEffect(() => {
    if (followUpModalProps.visible || noteModalProps.visible) {
      setAfterCallModalProps(false);
    }
  }, [noteModalProps, followUpModalProps]);

  useEffect(() => {
    return () => {
      if (!location.pathname.includes("landlords/")) {
        dispatch(resetSingleLandlordData());
      }
      dispatch(clearLandlordNotes());
    };
  }, [location]);

  useEffect(() => {
    dispatch(getAreas({ limit: "all" }));
    dispatch(getEnums());

    return () => {
      if (isClearNotes) {
        dispatch(clearLandlordNotes());
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      const entries: IObjectKeys = await getDeleteNoteConfirmContent();
      if (entries) {
        setDeleteConfirmContent(entries.fields.modalDescription);
      }
    })();
  }, [landlordNotesSelectorVariable]);

  useEffect(() => {
    if (!afterCallModalProps) localStorage.removeItem("contact_email_selected");
  }, [afterCallModalProps]);

  return (
    <>
      <ListingsViewWrapper
        showingCount={showingCount}
        setShowingCount={setShowingCount}
        setAgentToContact={setAgentToContact}
        setLandlordActionModalVisible={setLandlordActionModalVisible}
        setCheckedListing={setCheckedListingCallback}
        setAddLandlordModal={setAddLandlordModal}
        setListingId={setListingId}
      />
      <>
        {landlordActionModalVisible && (
          <ContactModal
            setModalProps={() => {
              return;
            }}
            currentLandlordData={agentToContact}
            showModal={true}
            onClose={setLandlordActionModalVisible}
            setAfterCallModalVisible={setAfterCallModalProps}
            setNoteModalVisible={setNotesModalProps}
            setAction={setAction}
          />
        )}
        {addLandlordModal && (
          <BaseModal
            classname={"add-edit-landlord-modal"}
            cancelButtonProps={{
              className: "rb-secondary-btn sm add-landlord-cancel-btn",
            }}
            okButtonProps={{
              className: "rb-primary-btn sm add-landlord-ok-btn",
            }}
            modalTitle={"Add a Landlord"}
            isModalVisible={addLandlordModal}
            onOk={() => {
              form.submit();
              setAddLandlordModal(false);
            }}
            onCancel={() => {
              setAddLandlordModal(false);
              form.resetFields();
            }}
          >
            <LandlordDetails form={form} fromListing={true} listingId={listingId} />
          </BaseModal>
        )}
        <AllListingNotesModal
          visible={notesModalProps.visible}
          setVisible={setNotesModalProps}
          handleOpenAddNoteModal={() => {
            setNoteModalProps({ visible: true, editModal: false });
            setLandlordActionModalVisible(false);
          }}
          notes={landlordNotesSelectorVariable}
          handleOpenNoteModal={handleOpenNoteModal}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          setDeleteConfirmProps={setDeleteConfirmProps}
          setContactModalVisible={setLandlordActionModalVisible}
        />
        <NoteDeleteConfirmModal
          deleteConfirmProps={deleteConfirmProps}
          setDeleteConfirmProps={setDeleteConfirmProps}
          modalContent={deleteConfirmContent}
          onOkayClick={() => {
            dispatch(deleteListingNote(deleteConfirmProps.noteId || ""));
          }}
        />
        <AfterCallModal
          visible={afterCallModalProps}
          onClose={closeAfterCallModalHandler}
          setFollowUpModalVisible={setFollowUpsModalProps}
          setContactModalVisible={() => {
            return;
          }}
          setNoteModalVisible={setNoteModalProps}
          setPremiumModalVisible={setPremiumModalVisible}
        />
        {width && width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={!isPrivateLandlord ? currentLandlord : null}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={isPrivateLandlord ? "" : action + " landlord"}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={!isPrivateLandlord ? currentLandlord : null}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={isPrivateLandlord ? "" : action + " landlord"}
          />
        )}
        <PhoneVerifyModalFollowUps
          visible={phoneVerifyModalVisible}
          onCancel={() => setPhoneVerifyModalVisible(false)}
        />
        {!isPrivateLandlord && (
          <LandlordNoteModal
            modalProps={noteModalProps}
            setModalProps={setNoteModalProps}
            landlordInfo={currentLandlord}
            landlord_id={currentLandlord.landlord_id}
            initialModalValue={initialModalValue}
            form={form}
            defaultTitle={action + " landlord"}
          />
        )}
        {isPrivateLandlord && (
          <ListingNoteModal
            modalProps={noteModalProps}
            listingNotesLoading={listingNotesLoading}
            form={form}
            setModalProps={setNoteModalProps}
            initialModalValue={initialModalValue}
            listing={checkedListing}
          />
        )}
      </>
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_AFTER_CALL_MODAL}
        id={currentLandlord.landlord_id}
      />
    </>
  );
});

export default ListingsView;
