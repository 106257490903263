import React from "react";
import { LogoName, RBLogo } from "../../icons";
import "./LeadFlowHeader.scss";
import { useDispatch } from "react-redux";
import { signOut } from "../../../store/user/action";
import { useAppContext } from "libs/contextLib";
const LeadFlowHeader = () => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();
  return (
    <header className={"lead-flow-header-wrapper"}>
      <div className={"logo-wrapper"}>
        <RBLogo />
        <LogoName />
      </div>
      {
        isAuthenticated && <button
          onClick={() => {
            dispatch(signOut(window.location?.search?.substring(1) || ""));
            localStorage.removeItem("leadAccountLinkObj")
          }}
        >
          Sign-out
        </button>
      }
    </header>
  );
};

export default LeadFlowHeader;
