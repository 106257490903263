import React from "react";
import BaseButton from "../../Shared/BaseButton";
import { Upload } from "antd";
import { ACCEPTED_MEDIA_FILES } from "../../../helpers/constants";
import { getAuthToken } from "../../../helpers/authUtils";
import { beforeUploadCheck } from "../../../helpers/fileUtils";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { UploadFile } from "antd/es/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import { isPublicListingSelector, listingDataSelector } from "../../../store/listings/selectors";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import { useDemoContext } from "libs/contextLib";
import { AnalyticsService } from "helpers/analyticsService";

interface IListingImageBottomUploadSectionMobile {
  onChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined;
  setPreviewModalAllPhotosVisible: Function;
  fileList: any;
}

const ListingImageBottomUploadSectionMobile = (props: IListingImageBottomUploadSectionMobile) => {
  const listingData = useSelector(listingDataSelector);
  const { _id } = useSelector(listingDataSelector);
  const { lId } = useParams();
  const dispatch = useDispatch();
  const isPublicListing = useSelector(isPublicListingSelector);
  const { isDemo, demoAction } = useDemoContext();

  return (
    <div className={"listing-image-mobile-bottom-wrapper"}>
      {listingData?.images?.length > 6 && (
        <BaseButton
          onClick={() => {
            if (!isPublicListing) {
              dispatch(push(`/allimages/${lId}?lId=${listingData.agent_id}`));
            } else {
              props.setPreviewModalAllPhotosVisible(true);
            }
          }}
          classnames={"rb-secondary-btn grey"}
        >
          All Photos
        </BaseButton>
      )}
      {listingData?.images?.length > 0 &&
        !isPublicListing &&
        (isDemo ? (
          <BaseButton
            className="max-w-54"
            onClick={() => {
              AnalyticsService.reportSingleListingPageClick("add_photos");
              demoAction("listing");
            }}
          >
            Add Photos
          </BaseButton>
        ) : (
          <Upload
            showUploadList={false}
            accept={`${ACCEPTED_MEDIA_FILES}`}
            method={"PUT"}
            name={"image"}
            multiple={true}
            action={`${process.env.REACT_APP_BASE_URL}/listing/media/${_id}`}
            headers={{
              Authorization: "Bearer " + getAuthToken(),
            }}
            onChange={props.onChange}
            beforeUpload={(file) => beforeUploadCheck(file, props.fileList)}
          >
            <BaseButton classnames={"rb-primary-btn add-photo-btn mr-10 ml-10"}>
              <div onClick={() => AnalyticsService.reportSingleListingPageClick("add_photos")}>Add Photos</div>
            </BaseButton>
          </Upload>
        ))}
    </div>
  );
};

export default ListingImageBottomUploadSectionMobile;
