import React from "react";
import { BedroomIconLead, BathroomIconLead } from "../../../../../icons";
import { formatCurrency } from "helpers/currencyHelper";
import { TbCurrencyDollar } from "react-icons/tb";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";

interface IOAvailabilityInfoSection {
  move_in_date: any;
  leadData: any;
  leadAvailabilityDate: any;
}

const AvailabilityInfoSection = ({ move_in_date, leadData, leadAvailabilityDate }: IOAvailabilityInfoSection) => {
  const bedroomsValue = leadData?.requirement?.bedrooms_min;
  const bathroomsValue = leadData?.requirement?.bathrooms_min;

  return (
    <div className="listing-availability-info text-lg my-8 flex flex-wrap justify-between items-center">
      <div className="flex flex-wrap justify-start gap-4 items-center listing-availability-info-section">
        <div className="items-center flex flex-wrap justify-start gap-4 leading-1 text-lg">
          <div className={"flex flex-nowrap gap-1 whitespace-nowrap items-center"} data-test-id="budget-test-id">
            <TbCurrencyDollar className="h-6 w-6 text-purple-600" />
            <div>
              {leadData?.requirement?.hasOwnProperty("budget_min")
                ? formatCurrency(leadData?.requirement?.budget_min) + " - "
                : 0}
              {leadData?.requirement?.hasOwnProperty("budget_max")
                ? formatCurrency(leadData?.requirement?.budget_max)
                : 0}
            </div>
          </div>
          <div className="flex gap-1" data-test-id="bedrooms-test-id">
            <BedroomIconLead className="h-6 w-6 text-purple-600" />
            <div>{`${bedroomsValue === 0 ? "Studio" : bedroomsValue}`} beds</div>
            <div className="lead-occupmants-text" data-test-id="lead-occupmants-test-id">
              {leadData?.requirement?.number_of_tenants
                ? `(${leadData?.requirement?.number_of_tenants} Occupants)`
                : ""}
            </div>
          </div>
          <div className="flex gap-1" data-test-id="lead-bathrooms-test-id">
            <BathroomIconLead className="h-6 w-6 text-purple-600" />
            <div>{`${bathroomsValue ?? ""} bath`}</div>
          </div>
        </div>

        {move_in_date && (
          <div className="whitespace-nowrap flex gap-1 items-center">
            <CalendarDaysIcon className="h-6 w-6 text-purple-600" />
            <div>Move-In Date</div>
            <div>{leadAvailabilityDate}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailabilityInfoSection;
