import React from "react";

const ListingAddFollowUpIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M8 16.6045C3.58862 16.6045 0 13.0159 0 8.60449C0 4.19312 3.58862 0.604492 8 0.604492C12.4114 0.604492 16 4.19312 16 8.60449C16 13.0159 12.4114 16.6045 8 16.6045ZM8 1.60449C4.14001 1.60449 1 4.74451 1 8.60449C1 12.4645 4.14001 15.6045 8 15.6045C11.86 15.6045 15 12.4645 15 8.60449C15 4.74451 11.86 1.60449 8 1.60449Z"
          fill="black"
        />
        <path
          d="M11.5 9.10449H4.5C4.224 9.10449 4 8.88049 4 8.60449C4 8.32849 4.224 8.10449 4.5 8.10449H11.5C11.776 8.10449 12 8.32849 12 8.60449C12 8.88049 11.776 9.10449 11.5 9.10449Z"
          fill="black"
        />
        <path
          d="M8 12.6045C7.724 12.6045 7.5 12.3805 7.5 12.1045V5.10449C7.5 4.82849 7.724 4.60449 8 4.60449C8.276 4.60449 8.5 4.82849 8.5 5.10449V12.1045C8.5 12.3805 8.276 12.6045 8 12.6045Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.604492)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ListingAddFollowUpIcon;
