import React, { Fragment, useEffect, useState } from "react";
import { Form, Input } from "antd";
import "./AuthForm.scss";
import { AuthFormInterface, AuthFormProps } from "../../../helpers/authTypes";
import { useDispatch, useSelector } from "react-redux";
import { authLoadingSelector } from "../../../store/user/selectors";
import BaseButton from "../../Shared/BaseButton";
import GoogleIcon from "../../icons/GoogleIcon";
import { push } from "connected-react-router";
import {
  GOOGLE_AUTH_NOT_FOUND_ERROR,
  GOOGLE_AUTH_REDIRECT_ERROR,
  GOOGLE_LOGIN_LINK,
} from "../../../helpers/constants";
import { useHistory } from "react-router";
import { AnalyticsService } from "helpers/analyticsService";
import { EMAIL_VALIDATION } from "../../../helpers/validations";
import { GoogleAuthHelper } from "../../../helpers/GoogleAuthHelper";
import { clearReqError } from "../../../store/requirement/action";
import TailwindSpinner from "../../icons/TailwindSpinner";

const AuthForm = (props: AuthFormProps) => {
  const loading = useSelector(authLoadingSelector);
  const [isRemembered, setIsRemembered] = useState(false);
  // const [isOpenGoogleButton, setIsOpenGoogleButton] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onFinish = (values: AuthFormInterface) => {
    const data = {
      email: values.email?.trim(),
      password: values.password,
      remember_me: isRemembered,
    };
    props.onSubmit(data);
    props.isFromReqPage && localStorage.setItem("log_in_from_req", "true");
  };
  const onChange = (e: any) => {
    setIsRemembered(e.target.checked);
  };

  useEffect(() => {
    GoogleAuthHelper(dispatch, history);
    dispatch(clearReqError());
  }, [dispatch]);
  return (
    <div className="sign-in-form-wrapper">
      <div className="sign-in-form mb-4">
        <Form className="login-form" onFinish={onFinish}>
          <label htmlFor="email">Email</label>
          <Form.Item name="email" rules={[EMAIL_VALIDATION]}>
            <Input id="email" size="large" placeholder="E-mail" />
          </Form.Item>
          <label htmlFor="password">Password</label>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password",
              },
            ]}
          >
            <Input
              size="large"
              id="password"
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <div className="sign-in-form-remember">
            <Form.Item>
              <div className={"sign-in-form-remember-left"}>
                <label className="container w-4">
                  <input
                    type="checkbox"
                    id={"remember-me"}
                    onChange={(e) => onChange(e)}
                  />
                  <span className="checkmark"></span>
                </label>
                <label htmlFor="remember-me" className="checkbox-text">
                  Remember Me
                </label>
              </div>
            </Form.Item>
          </div>
          <Form.Item className={"sign-in-form-submitWrapper"}>
            <div className="sign-in-submit">
              <div
                onClick={() => {
                  AnalyticsService.reportEvent("sign_in_click", {
                    category: "App",
                    label: "sign_in_with_email",
                  });
                }}
              >
                <BaseButton
                  type="submit"
                  variant="primary"
                  loading={loading}
                >
                  Log in
                </BaseButton>
              </div>
              <div className={"sign-in-form-submitWrapper-lineSection"}>
                <div></div>
                <span>Or</span>
                <div></div>
              </div>
              <BaseButton
                type="button"
                classnames="rb-primary-btn md  rb-primary-btn-google"
                loading={false}
                onClick={() => {
                  AnalyticsService.reportEvent("sign_in_click", {
                    category: "App",
                    label: "google_sign_in",
                  });
                }}
                icon={<GoogleIcon />}
                iconPosition={"left"}
                // href={isOpenGoogleButton ? GOOGLE_LOGIN_LINK : "javascript:void(0)"}
                href={GOOGLE_LOGIN_LINK}
              // disabled={!isOpenGoogleButton}
              >
                <div className="w-full text-center">Log in with Google</div>
              </BaseButton>
            </div>
          </Form.Item>
        </Form >
      </div >
      <BaseButton
        variant={"link"}
        onClick={() => {
          dispatch(push("/resetPassword"));
        }}
      >
        Forgot password?
      </BaseButton>
    </div >
  );
};

export default AuthForm;
