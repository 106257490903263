import React from "react";
import ServicesSearch from "./Components/ServicesSearch";

const ThumbtackPowered = () => {
  return (
    <div className="flex items-center gap-1" style={{ color: "#009fd9" }}>
      <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor">
        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0"></path>
        <path fill="#FFF" d="M8.973 10.385a3.71 3.71 0 0 1-.564 1.957L8 13l-.409-.658a3.704 3.704 0 0 1-.564-1.957v-3.14C7.51 6.62 8.231 6.4 8.973 6.4v3.985zM4 5.69V4h8v1.69H4z"></path>
      </svg>
      <div className="text-gray-700">Powered by Thumbtack</div>
    </div>
  );
};

const RentalPortalServices = () => {
  return (
    <div>
      <div className="py-10 flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-purple-900">Find Services</h1>
        <ThumbtackPowered />
      </div>
      <ServicesSearch />
    </div>
  );
};

export default RentalPortalServices;
