import { FlatfileButton, FlatfileResults } from "@flatfile/react";
import { Alert, Col, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import PremiumUpgradeModal from "components/Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { useAppContext } from "libs/contextLib";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import TeamsApi from "../../../../../../api/teams";
import { flatFileIoKey, isProduction } from "../../../../../../helpers/constants";
import {
  beautifiedKeyStylingDashAndFirstUpper,
  divideToChunks,
  isOwner,
  openNotification,
} from "../../../../../../helpers/globalFunctions";
import { IObjectKeys } from "../../../../../../helpers/globalTypes";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { windowSelector } from "../../../../../../store/app/selectors";
import { inviteAgentSuccessSelector } from "../../../../../../store/teams/selectors";
import { userSelector } from "../../../../../../store/user/selectors";
import AddTeamModal from "../../../../../CreateTeam/AddTeamModal";
import LockIcon from "../../../../../Leads/icons/LockIcon";
import BaseButton from "../../../../../Shared/BaseButton";
import BaseModal from "../../../../../Shared/BaseModal";
import { urlQueryToObject } from "../../../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import {
  BulkUploadTeamMembersJsonDto,
  FIELD_HOOKS_TEAM_IMPORT,
  IMPORT_BY_JSON_TEAM_MEMBER_FIELDS,
} from "../../../../../TeamMembers/Introducing/teamUploadInfo";
import { setInviteAgentToFalse } from "store/teams/action";

const AuthHeaderTeamMembersAction = () => {
  const userData = useSelector(userSelector);
  const [form] = useForm();
  const { search } = useLocation();
  const { width }: any = useWindowSize();
  const [addTeamModalVisible, setAddTeamModalVisible] = useState(false);
  const [isOpenImportButton, setIsOpenImportButton] = useState<boolean>(false);
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const [errors, setErrors] = useState<IObjectKeys[]>([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const getInviteTeamsSuccess = useSelector(inviteAgentSuccessSelector);
  const { type } = useSelector(windowSelector);
  const { isNotPremiumUser } = useAppContext();
  const { free_subscription_limits } = userData.agency;
  const showPremium = isNotPremiumUser && !Boolean(free_subscription_limits.team_members > 0);
  const dispatch = useDispatch();

  const handleAddTeamBtnClick = useCallback(() => {
    if (showPremium) {
      setPremiumModalVisible(true);
    } else {
      setAddTeamModalVisible(true);
      form.resetFields();
    }
  }, [form, showPremium]);

  const closeModal = () => {
    setErrors([]);
    setErrorModalVisible(false);
  };
  const onDataConvert = async (results: FlatfileResults) => {
    const mapData = results.data;

    try {
      let listings_json: BulkUploadTeamMembersJsonDto[] = mapData;
      if (!listings_json.length) {
        return "No listing to import.";
      }
      let jsonChunks = divideToChunks(listings_json, 50);
      let successfullyImportedCount = 0;
      let failedToImportCount = 0;
      const failedToImport = [];
      for await (const jsonChunk of jsonChunks) {
        const result: any = await TeamsApi.inviteAgentsByJson(jsonChunk);
        successfullyImportedCount += result.filter((item: IObjectKeys) => item.success).length;
        const failed = result.filter((item: IObjectKeys) => !item.success);
        failedToImport.push(failed);
        failedToImportCount += failed.length;
      }

      let message = "";
      if (successfullyImportedCount) {
        message += `Successfully imported - ${successfullyImportedCount}.\n\n`;
      }
      if (failedToImportCount) {
        message += `Failed to import - ${failedToImportCount}.`;
        setErrors(failedToImport.flat().map((l_error: IObjectKeys) => l_error));
        setErrorModalVisible(true);
      }

      return message;
    } catch (err) {
      //@ts-ignore
      return beautifiedKeyStylingDashAndFirstUpper(err.message);
    }
  };
  const handleShowMessage = () => {
    setAddTeamModalVisible(false);
    openNotification("success", "👍 Invite sent successfully");
    dispatch(setInviteAgentToFalse());
  };

  useEffect(() => {
    if (queryObject?.importMode) {
      setIsOpenImportButton(true);
    }
  }, [search]);

  useEffect(() => {
    if (getInviteTeamsSuccess) {
      handleShowMessage();
    }
  }, [getInviteTeamsSuccess]);

  return (
    <div className={"header-part-right-teamActions"}>
      {isOwner(userData) && (
        <Col className={"add-team-btn"}>
          <BaseButton variant="primary" dataTestId="team-button" onClick={handleAddTeamBtnClick} modalType="team">
            <>
              {showPremium && <LockIcon />}
              {type === "mobile" ? " Add" : " Add Team Member"}
            </>
          </BaseButton>
          {userData?.agency?._id && isOpenImportButton && width >= 920 && (
            <FlatfileButton
              licenseKey={flatFileIoKey}
              customer={{
                companyId: userData?.agency?._id,
                companyName: userData?.office_name,
                email: userData.email,
                name: userData.name,
                userId: userData?._id,
              }}
              settings={{
                type: "Team",
                devMode: !isProduction,
                managed: true,
                allowInvalidSubmit: false,
                fields: IMPORT_BY_JSON_TEAM_MEMBER_FIELDS,
                title: "Team Import",
              }}
              fieldHooks={FIELD_HOOKS_TEAM_IMPORT}
              onData={onDataConvert}
              render={(_importer, launch) => {
                return (
                  <BaseButton dataTestId={"import-team-btn"} classnames={"rb-primary-btn md"} onClick={launch}>
                    Import Team
                  </BaseButton>
                );
              }}
            />
          )}
        </Col>
      )}
      {addTeamModalVisible && (
        <AddTeamModal form={form} visible={addTeamModalVisible} onClose={() => setAddTeamModalVisible(false)} />
      )}
      <BaseModal
        modalTitle={"Import Listings Errors"}
        okButtonProps={{
          className: "rb-primary-btn sm",
        }}
        cancelButtonProps={{
          className: "rb-secondary-btn sm",
        }}
        isModalVisible={errorModalVisible}
        onCancel={closeModal}
        onOk={closeModal}
      >
        <Row
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          {errors && Array.isArray(errors) && errors.length ? (
            <>
              {errors.map((error, i) => {
                return (
                  <div style={{ margin: "5px 0", width: "100%" }} key={i}>
                    <Alert
                      className={"listingCsvErrors"}
                      key={i}
                      message={`Error: `}
                      description={error?.error?.message}
                      type="error"
                    />
                  </div>
                );
              })}
            </>
          ) : (
            ""
          )}
        </Row>
      </BaseModal>
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_TEAM_MEMBER}
      />
    </div>
  );
};

export default AuthHeaderTeamMembersAction;
