export const CREATE_SHAREABLE_LANDLORD_ENTITY = "CREATE_SHAREABLE_LANDLORD_ENTITY";
export const CREATE_SHAREABLE_LANDLORD_ENTITY_SUCCESS = "CREATE_SHAREABLE_LANDLORD_ENTITY_SUCCESS";
export const CREATE_SHAREABLE_LANDLORD_ENTITY_FAIL = "CREATE_SHAREABLE_LANDLORD_ENTITY_FAIL";

export const GET_SHAREABLE_LANDLORD_ENTITY = "GET_SHAREABLE_LANDLORD_ENTITY";
export const GET_SHAREABLE_LANDLORD_ENTITY_SUCCESS = "GET_SHAREABLE_LANDLORD_ENTITY_SUCCESS";
export const GET_SHAREABLE_LANDLORD_ENTITY_FAIL = "GET_SHAREABLE_LANDLORD_ENTITY_FAIL";

export const CREATE_SHAREABLE_PROPERTY_ENTITY = "CREATE_SHAREABLE_PROPERTY_ENTITY";
export const CREATE_SHAREABLE_PROPERTY_ENTITY_SUCCESS = "CREATE_SHAREABLE_PROPERTY_ENTITY_SUCCESS";
export const CREATE_SHAREABLE_PROPERTY_ENTITY_FAIL = "CREATE_SHAREABLE_PROPERTY_ENTITY_FAIL";

export const UPDATE_SHAREABLE_PROPERTY_ENTITY = "UPDATE_SHAREABLE_PROPERTY_ENTITY";
export const UPDATE_SHAREABLE_PROPERTY_ENTITY_SUCCESS = "UPDATE_SHAREABLE_PROPERTY_ENTITY_SUCCESS";
export const UPDATE_SHAREABLE_PROPERTY_ENTITY_FAIL = "UPDATE_SHAREABLE_PROPERTY_ENTITY_FAIL";

export const GET_SHAREABLE_PROPERTY_ENTITY = "GET_SHAREABLE_PROPERTY_ENTITY";
export const GET_SHAREABLE_PROPERTY_ENTITY_SUCCESS = "GET_SHAREABLE_PROPERTY_ENTITY_SUCCESS";
export const GET_SHAREABLE_PROPERTY_ENTITY_FAIL = "GET_SHAREABLE_PROPERTY_ENTITY_FAIL";

export const CLEAR_SHAREABLE_PROPERTY_ENTITY = "CLEAR_SHAREABLE_PROPERTY_ENTITY";

export const CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY = "CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY";
export const CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY_SUCCESS = "CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY_SUCCESS";
export const CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY_FAIL = "CREATE_SCREENING_REQUEST_ENTITY_FAIL";
export const CLEAR_SHAREABLE_SCREENING_ENTITY = "CLEAR_SHAREABLE_SCREENING_ENTITY";

export const SEND_SCREENING_REQUEST_TO_RENTER = "SEND_SCREENING_REQUEST_TO_RENTER";
export const SEND_SCREENING_REQUEST_TO_RENTER_SUCCESS = "SEND_SCREENING_REQUEST_TO_RENTER_SUCCESS";
export const SEND_SCREENING_REQUEST_TO_RENTER_FAIL = "SEND_SCREENING_REQUEST_TO_RENTER_FAIL";

export const CREATE_SHAREABLE_RENTER_ENTITY = "CREATE_SHAREABLE_RENTER_ENTITY";
export const CREATE_SHAREABLE_RENTER_ENTITY_SUCCESS = "CREATE_SHAREABLE_RENTER_ENTITY_SUCCESS";
export const CREATE_SHAREABLE_RENTER_ENTITY_FAIL = "CREATE_SHAREABLE_RENTER_ENTITY_FAIL";
export const CLEAR_SHAREABLE_RENTER_ENTITY = "CLEAR_SHAREABLE_RENTER_ENTITY";

export const CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY = "CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY";
export const CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY_SUCCESS = "CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY_SUCCESS";
export const CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY_FAIL = "CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY_FAIL";
export const CLEAR_SHAREABLE_VERIFICATION_EXAM_ENTITY = "CLEAR_SHAREABLE_VERIFICATION_EXAM_ENTITY";


export const POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS = "POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS";
export const POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS_SUCCESS = "POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS_SUCCESS";
export const POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS_FAIL = "POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS_FAIL";
export const CLEAR_SHAREABLE_VERIFICATION_EXAM_ANSWERS = "CLEAR_SHAREABLE_VERIFICATION_EXAM_ANSWERS";

export const VALIDATE_RENTER = "VALIDATE_RENTER";
export const VALIDATE_RENTER_SUCCESS = "VALIDATE_RENTER_SUCCESS";
export const VALIDATE_RENTER_FAIL = "VALIDATE_RENTER_FAIL";
export const CLEAR_VALIDATE_RENTER = "CLEAR_VALIDATE_RENTER";

export const SAVE_EMAIL_SCREENING_LINK_DATA = "SAVE_EMAIL_SCREENING_LINK_DATA";
export const CLEAR_EMAIL_SCREENING_LINK_DATA = "CLEAR_EMAIL_SCREENING_LINK_DATA";