import React, { memo, useEffect, useState } from "react";
import BaseModal from "../../../Shared/BaseModal";
import "./PremiumUpgradeModal.scss";
import BaseButton from "../../../Shared/BaseButton";
import agent1 from "assets/images/MikeSwan.png";
import agent2 from "assets/images/Nadgla.png";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentLink } from "../../../../store/payment/action";
import {
  paymentLinkLoadingSelector,
  paymentLinkSelector,
  updatedSubscriptionLoadingSelector,
  updatedSubscriptionSelector,
} from "../../../../store/payment/selectors";
import { stripeMonthlyPaymentId, stripeYearlyPaymentId } from "../../../../helpers/constants";
import { userSelector } from "store/user/selectors";
import { AnalyticsService } from "helpers/analyticsService";
import { getTextForPremium, saveLink } from "helpers/globalFunctions";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { push } from "connected-react-router";
import qs from "qs";

interface IPremiumUpgradeModal {
  visible: boolean;
  setVisible: any;
  triggerButton: string;
  id?: string;
}

const PremiumUpgradeModal = ({ visible, setVisible, triggerButton, id }: IPremiumUpgradeModal) => {
  const { width }: any = useWindowSize();
  const dispatch = useDispatch();
  const paymentLink = useSelector(paymentLinkSelector);
  const paymentLinkLoading = useSelector(paymentLinkLoadingSelector);
  const [monthlyLoading, setMonthlyLoading] = useState(false);
  const [yearlyLoading, setYearlyLoading] = useState(false);
  const { search, pathname } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const userData = useSelector(userSelector);
  const updatedSubscription = useSelector(updatedSubscriptionSelector);
  const updateSubscriptionLoading = useSelector(updatedSubscriptionLoadingSelector);
  const premiumHeader = getTextForPremium(triggerButton, "premiumModal").title;
  const isReferred = userData.enrichments?.referring_agent;

  useEffect(() => {
    if (paymentLink) {
      window.location.href = paymentLink;
    }
  }, [paymentLink]);
  const onPremiumClick = (pricing: string | undefined, type: string) => {
    AnalyticsService.reportUpgradeModalClick({ label: type, triggerButton });
    saveLink(triggerButton, id);
    dispatch(getPaymentLink({ pricing: pricing, currentLoc: window.location.href }));
    switch (type) {
      case "year":
        setYearlyLoading(true);
        break;
      case "month":
        setMonthlyLoading(true);
        break;
    }
  };

  useEffect(() => {
    if (visible) {
      AnalyticsService.reportEvent("upgrade_modal_open", {
        category: "premium",
        triggerButton,
      });

      localStorage.setItem("hide_fab_btn", "true");
    } else {
      localStorage.removeItem("hide_fab_btn");
    }
  }, [visible]);

  const closeModalHandler = () => {
    setVisible(false);
    if (queryObject.open_upgrade_modal) {
      delete queryObject.open_upgrade_modal;
      dispatch(
        push({
          pathname: pathname,
          search: qs.stringify(queryObject),
        })
      );
    }
  };

  useEffect(() => {
    if (Object.keys(updatedSubscription).length) {
      closeModalHandler();
    }
  }, [updatedSubscription]);

  return (
    <BaseModal
      classname={"upgrade-modal"}
      isModalVisible={visible}
      onCancel={closeModalHandler}
      modalTitle={null}
      modalFooter={null}
      width={width < 600 ? 320 : 830}
    >
      <div className={"upgrade-modal-body"}>
        <div className={"upgrade-modal-body-desc"}>
          <h2>
            {`${premiumHeader.split(".")[0]}.`}
            <br />
            {`${premiumHeader.split(".")[1]}.`}
          </h2>
          <p>{getTextForPremium(triggerButton, "premiumModal").description}</p>
          <div className={"upgrade-modal-body-desc-actions"}>
            <BaseButton
              variant="primary"
              onClick={() => {
                onPremiumClick(stripeYearlyPaymentId, "year");
                localStorage.setItem("payment_package_selected", "yearly");
              }}
              disabled={monthlyLoading}
              loading={(paymentLinkLoading && yearlyLoading) || (updateSubscriptionLoading && yearlyLoading)}
            >
              <span className="text-sm">Go Yearly ($24/mo.)</span>
            </BaseButton>
            <BaseButton
              variant="primary"
              onClick={() => {
                onPremiumClick(stripeMonthlyPaymentId, "month");
                localStorage.setItem("payment_package_selected", "monthly");
              }}
              disabled={yearlyLoading}
              loading={(paymentLinkLoading && monthlyLoading) || (updateSubscriptionLoading && monthlyLoading)}
            >
              <span className="text-sm">Go Monthly ($40/mo.)</span>
            </BaseButton>
          </div>
          <div className="upgrade-modal-body-desc-description">
            <p className="m-0">✓ Zero risk - Cancel anytime</p>
            <p className="m-0">✓ Prices are per agent per month</p>
          </div>
        </div>
        <div className={"upgrade-modal-body-opinion"}>
          <div className={"upgrade-modal-body-opinion-item upgrade-modal-body-opinion-itemFirst"}>
            <div className={"upgrade-modal-body-opinion-item-img"}>
              <img src={agent1} alt={agent1} />
            </div>
            <p className={"upgrade-modal-body-opinion-item-text"}>
              "With RentBase Premium, I was able to close a $6,800 rental in my first week."
            </p>
            <p className={"upgrade-modal-body-opinion-item-name"}>Mike Swann</p>
            <p className={"upgrade-modal-body-opinion-item-agency"}>Keller Williams Realty</p>
          </div>
          <div className={"upgrade-modal-body-opinion-item upgrade-modal-body-opinion-secondItem"}>
            <div className={"upgrade-modal-body-opinion-item-img"}>
              <img src={agent2} alt={agent2} />
            </div>
            <p className={"upgrade-modal-body-opinion-item-text"}>
              “RentBase takes away the parts of my job that feel most monotonous and time consuming, and has given me
              back so much of my time.”{" "}
            </p>
            <p className={"upgrade-modal-body-opinion-item-name"}>Nadgla Anilus</p>
            <p className={"upgrade-modal-body-opinion-item-agency"}>Boston Property Group</p>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default memo(PremiumUpgradeModal);
