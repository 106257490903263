import React, { useEffect, useRef, useState } from "react";
import { IFilterOptionsOptionalKeys } from "./helpers/mockListings";
import { push } from "connected-react-router";
import { debounce, isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ListingsWrapper, { IPaginationValues } from "./components/listings-wrapper";
import {
  filterOptionsSelector,
  listingsViewTypeSelector,
  paginationValuesSelector,
  routerSelector,
  listingsTotalSelector,
  listingLoadingSelector,
  listingsListSelector,
  getListingLastQuerySelector,
} from "../../store/listings/selectors";
import {
  getListingsFromAlgolia,
  setListingsFilterOptions,
  setListingsLastQuery,
  setListingsPaginationValues,
  setListingsViewType,
} from "../../store/listings/action";
import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import { IObjectKeys } from "../../helpers/globalTypes";
import { getListingsViewType, scrollToTop } from "../../helpers/globalFunctions";
import { clearLandlordNotes, resetSingleLandlordData } from "../../store/landlords/action";
import { userSelector } from "store/user/selectors";
import EmptyListingWrapper from "../Empty-page-wrapper/components/empty-listing-wrapper";
import EmptyPageLayout from "../../layouts/empty/EmptyPageLayout";
import {
  initialFilterOptionsFromSearchQuery,
  listingTypeFromSearchQuery,
  paginationValuesFromSearchQuery,
  urlQueryToObject,
} from "../Shared/filters/helpers/listingsCreateGetSearchQuery";

import { getAllFlatfileEnums } from "store/flatfileEnums/action";
import FilteredBySection from "../Shared/filters/filtered-by-section";
import ListingSortDropdown from "./components/listing-sort-dropdown/ListingSortDropdown";
import ListTypeBtn from "./components/list-type-button/ListTypeBtn";
import { isCLAWUser } from "helpers/userHelpers";
import ConfirmSwitchTeamModal from "components/CreateTeam/confirmSwitchTeamModal";
import { Spin } from "antd";
import GlobalLoading from "components/Shared/GlobalLoading";
import Header from "components/RenterPortal/Components/Header";

const initialState = {
  isLead: false,
  isLandlord: false,
  isListing: false,
  isTeam: false,
};

const Listings = ({ heading }: { heading?: string }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const history = useHistory();
  const { landlordId } = useParams<IObjectKeys>();
  const router = useSelector(routerSelector);
  const listingsViewType = useSelector(listingsViewTypeSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const listingsFilterOptions = useSelector(filterOptionsSelector);
  const paginationValues = useSelector(paginationValuesSelector);
  const total = useSelector(listingsTotalSelector);
  const listingLastQueryUrl = useSelector(getListingLastQuerySelector);
  const _newURLSearchParams = new URLSearchParams(router.location.search);
  const [listingsCurrentQuery, setListingsCurrentQuery] = useState("");
  const [isViewTypeButtonClicked, setIsViewTypeButtonClicked] = useState(false);
  const listingsList = useSelector(listingsListSelector);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const [firstRender, setFirstRender] = useState(true);
  const [showingCount, setShowingCount] = useState(24);
  const [showSwitchTeamModal, setShowSwitchTeamModal] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");
  const [newAgencyId, setNewAgencyId] = useState("");

  const delayedQueryCall = useRef(
    debounce((query: string) => {
      const urlObject: IFilterOptionsOptionalKeys = urlQueryToObject(new URLSearchParams(query));
      if (landlordId) {
        urlObject.landlord_id = landlordId;
      }
      const count = localStorage.getItem("listing_showing_count");
      if (count) {
        urlObject.size = +count;
      }
      const defaultStatus =
        urlObject.status ||
        (localStorage.getItem("availabilityFilterState") as string) ||
        (isCLAWUser(user) ? "Available" : "Show-all");
      urlObject.status = defaultStatus;
      dispatch(getListingsFromAlgolia(urlObject));
      dispatch(setListingsLastQuery(query));
    }, 500)
  );

  useEffect(() => {
    if (localStorage.getItem("listing_showing_count")) {
      const countOfData = JSON.parse(localStorage.getItem("listing_showing_count") as string);
      setShowingCount(countOfData);
      if (!isViewTypeButtonClicked) {
        const query = `?listingType=grid&page=1&size=${localStorage.getItem("listing_showing_count")}`;
        delayedQueryCall?.current(router.location.search || listingLastQueryUrl || query);
      }
    }
    return () => {
      dispatch(resetSingleLandlordData());
      dispatch(clearLandlordNotes());
      dispatch(setListingsFilterOptions({}));
      dispatch(setListingsPaginationValues({ current: 1, pageSize: 24 }));
      dispatch(setListingsViewType(getListingsViewType()));
    };
  }, []);

  useEffect(() => {
    if (
      !isEqual(
        urlQueryToObject(new URLSearchParams(router.location.search)),
        urlQueryToObject(new URLSearchParams(listingsCurrentQuery))
      ) ||
      firstRender
    ) {
      const initialFilterOptionsGenerator = () => {
        if (router.location.search) {
          return initialFilterOptionsFromSearchQuery(queryObject);
        } else {
          return {};
        }
      };
      const initialPaginationValues: IPaginationValues = paginationValuesFromSearchQuery(queryObject);
      const listingsViewTypeValue = listingTypeFromSearchQuery(queryObject);
      const initialFilterOptionsToSet = initialFilterOptionsGenerator();
      dispatch(setListingsFilterOptions(initialFilterOptionsToSet));
      dispatch(setListingsPaginationValues(initialPaginationValues));
      dispatch(setListingsViewType(listingsViewTypeValue));
      if (firstRender) setFirstRender(false);
      if (!isViewTypeButtonClicked) {
        const query = `?listingType=grid&page=1&size=${localStorage.getItem("listing_showing_count")}`;
        delayedQueryCall?.current(router.location.search || listingLastQueryUrl || query);
      }
      setListingsCurrentQuery(listingLastQueryUrl || router.location.search);
      setIsViewTypeButtonClicked(false);
      const listingWrapperRef: HTMLElement | null = document.getElementById("listingsWrapperList");
      scrollToTop();
      scrollToTop(listingWrapperRef);
    }
  }, [router.location.search]);

  useEffect(() => {
    dispatch(getAllFlatfileEnums());
  }, [dispatch]);

  useEffect(() => {
    const searchUrl = localStorage.getItem("switchTeamSearchParams");
    if (searchUrl) {
      const urlParams = new URLSearchParams(searchUrl);
      const switchQuery = urlQueryToObject(urlParams);
      if (switchQuery.switch_team && !localStorage.getItem("switchTeamSnoozed")) {
        setShowSwitchTeamModal(true);
        if (switchQuery.agency_name && typeof switchQuery.agency_name === "string") {
          setNewTeamName(switchQuery.agency_name);
        }
        if (switchQuery.agency_id && typeof switchQuery.agency_id === "string") {
          setNewAgencyId(switchQuery.agency_id);
        }
      }
    }
  }, [router]);

  const handleSubmit = (searchStateString: string) => {
    dispatch(
      push({
        pathname: history.location.pathname,
        search: `${searchStateString}${queryObject.search ? `&search=${queryObject.search}` : ""}`,
      })
    );
  };

  const handleConfirmSwitchTeamModalCancel = () => {
    setShowSwitchTeamModal(false);
    localStorage.setItem("switchTeamSnoozed", "true");
  };

  if ((!isCLAWUser(user) && listingsList.length === 0) || history.location.search.includes("fab=true")) {
    return (
      <EmptyPageLayout>
        <EmptyListingWrapper initialState={initialState} isEmptyState={true} />
        <ConfirmSwitchTeamModal
          visible={showSwitchTeamModal}
          onCancel={() => handleConfirmSwitchTeamModalCancel()}
          newTeamName={newTeamName}
          newAgencyId={newAgencyId}
        />
      </EmptyPageLayout>
    );
  }

  if (listingLoading) {
    return <Spin spinning={listingLoading} className={"global-loading"} indicator={<GlobalLoading />} />;
  }

  return (
    <>
      <div className="flex justify-between items-center w-full mb-15 pl-15">
        <div className="flex justify-between items-center w-full mb-15 pl-15">
          {heading && (
            <div className="text-2xl bold">
              <Header title={heading} />
            </div>
          )}
          <FilteredBySection />
        </div>
        <div className="flex justify-end items-center w-auto mb-15 pl-15">
          <div className={"p-4 flex gap-1"}>
            <ListingSortDropdown
              filterOptions={listingsFilterOptions}
              pagination={paginationValues}
              handleSubmit={handleSubmit}
              viewType={listingsViewType}
            />
            <ListTypeBtn setIsViewTypeButtonClicked={setIsViewTypeButtonClicked} />
          </div>
        </div>
      </div>
      <ListingsWrapper showingCount={showingCount} setShowingCount={() => setShowingCount(24)} />
      <ConfirmSwitchTeamModal
        visible={showSwitchTeamModal}
        onCancel={() => handleConfirmSwitchTeamModalCancel()}
        newTeamName={newTeamName}
        newAgencyId={newAgencyId}
      />
    </>
  );
};

export default Listings;
