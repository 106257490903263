import React, { useState, useRef } from "react";
import moment from "moment";
import "./styles.scss";
import { VerticalMenuDots } from "../../icons/VerticalMenuDots";
import BaseDropdown from "components/Shared/BaseDropdown";
import FollowUpMobileActionsMenu from "../FollowUpMobileActionsMenu";
import useOnClickOutside from "hooks/useOnClickOutside";
import FollowUpDetails from "../FollowUpDetails";
import { AddToGoogleCalendar } from "../AddToCalendar";
import NameWrapperFUCard from "../Shared/NameWrapperFUCard";
import { useSelector } from "react-redux";
import { queryAndPaginationSelector } from "../../../store/followUps/selectors";
import { addTimeAndFormatDate, formatDate } from "../../../helpers/dateHelper";

export const FollowUpsListItemMobile = ({
  followUp,
  // queryAndPagination,
  dueContent,
  leftOrAgoGenerator,
  isFromLandlordPage,
  setDropdownVisibility,
  setUpdates,
  setDismissFollowUpModalVisible,
  setLandlordActionModalVisible,
  setCurrentFollowUp,
  handleDelete,
  user,
}: any) => {
  const queryAndPagination = useSelector(queryAndPaginationSelector);
  const FollowUpsActionsDropDownOverlayRef = useRef(null);
  const FollowUpsActionsDropDownButtonRef = useRef(null);
  const [isVisibleDetails, setisVisibleDetails] = useState<boolean>(false);
  const _event = {
    title: followUp.title || "",
    description: followUp.description || "",
    startTime: followUp.todo_at,
    location: "",
    endTime: addTimeAndFormatDate(
      followUp.todo_at,
      { amount: 1, unit: "hour" },
      undefined,
      "toISOString"
    ),
  };
  const [
    isMobileDropdownVisible,
    setIsMobileDropdownVisible,
  ] = useState<boolean>(false);

  useOnClickOutside(
    FollowUpsActionsDropDownOverlayRef,
    (e: any) => setIsMobileDropdownVisible(false),
    FollowUpsActionsDropDownButtonRef
  );
  return (
    <div className="follow-up-infos-wrapper  follow-ups-card-row">
      <div className="follow-ups-card-row--top">
        <div className="follow-ups-card-row--top--col--left">
          <span
            onClick={() => setisVisibleDetails((prev) => !prev)}
            className="follow-ups-card-row--top--col--left--heading"
          >
            {followUp?.title}
          </span>
          <span
            className={`follow-ups-card-row--top--col--left--bottomm ${
              queryAndPagination?.past_due ? "follow-up-past-due" : ""
            }`}
          >
            {followUp?.todo_at && (
              <>
                Due:{" "}
                <span>{formatDate(followUp?.todo_at, "MMMM Do")} &nbsp;</span>
              </>
            )}
            {queryAndPagination?.past_due ? (
              <span>{dueContent(followUp?.todo_at, "late")}</span>
            ) : followUp?.todo_at ? (
              <span>
                {dueContent(
                  followUp?.todo_at,
                  leftOrAgoGenerator(followUp?.todo_at)
                )}
              </span>
            ) : (
              ""
            )}
            <div className="mobile-fus-item-name-container">
              <NameWrapperFUCard
                name={user.name}
                follow_up_type={followUp?.follow_up_type}
              />
            </div>
          </span>
        </div>
        <div className="follow-ups-card-row--top--col--right">
          <div className={"follow-ups-mobile-dropdown-wrapper position-rel"}>
            <BaseDropdown
              menu={
                <FollowUpMobileActionsMenu
                  hideMobileDropdown={() => setIsMobileDropdownVisible(false)}
                  followUp={followUp}
                  isFromLandlordPage={isFromLandlordPage}
                  setDropdownVisibility={setDropdownVisibility}
                  queryAndPagination={queryAndPagination}
                  setUpdates={setUpdates}
                  setDismissFollowUpModalVisible={
                    setDismissFollowUpModalVisible
                  }
                  ref={FollowUpsActionsDropDownOverlayRef}
                  setLandlordActionModalVisible={setLandlordActionModalVisible}
                  setCurrentFollowUp={setCurrentFollowUp}
                  handleDelete={handleDelete}
                />
              }
              visible={isMobileDropdownVisible}
              placement={"bottomLeft"}
              classnames={"followup-actions-dropdown"}
              dataTestId={"followup-actions-dropdown"}
              trigger={["click"]}
            >
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMobileDropdownVisible((prevState) => !prevState);
                }}
                data-test-id="followup-actions-dropdown-btn"
                className={"followup-actions-dropdown-btn"}
                ref={FollowUpsActionsDropDownButtonRef}
              >
                {/* <DropdownDotsIcon /> */}
                <VerticalMenuDots />
              </span>
            </BaseDropdown>
          </div>
        </div>
      </div>
      {isVisibleDetails && (
        <div className="follow-ups-card-row--bottom">
          <p className="follow-ups-card-row--bottom--description">
            {followUp?.description}
          </p>
          <FollowUpDetails followUp={followUp} />
          <div className="follow-ups-card-row--bottom--calendar">
            <AddToGoogleCalendar event={_event} />
          </div>
        </div>
      )}
    </div>
  );
};
