import React, { useEffect, useState } from "react";
import Routes from "./routes";
import { AppContext, DemoContext, BannerContext, RenterPortalContext } from "./libs/contextLib";
import { useSelector } from "react-redux";
import { userSelector } from "store/user/selectors";
import { FilterTeamService } from "helpers/filterTeamService";
import { EnrichmentsService } from "helpers/enrichmentsService";
import ScrollToTop from "hocs/ScrollToTop";
import ActivateModal from "components/Shared/ActivateModal";
import cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { AnalyticsService } from "helpers/analyticsService";
import { setUserPropertiesForAnalytics } from "helpers/globalFunctions";
import HeaderTags from "components/Header/HeaderTags";
import { DOCUMENT_TITLE_DEFAULT, META_DESCRIPTION_DEFUALT, SUBSCRIPTION_TYPES, shortAppUrl } from "./helpers/constants";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState<boolean>(false);
  const location = useLocation();
  const isDemo = location.pathname.includes("demo");
  const [toggleModal, setToggleModal] = useState(false);
  const [type, setType] = useState("");
  const userData = useSelector(userSelector);
  const loc = window.location.href;
  const queryString = window.location.search;
  const isRbTeam = loc.includes("working-at-rentbase-is-awesome");
  const [dashboardBannerVisible, setDashboardBannerVisible] = useState(false);
  const [isNotPremiumUser, setIsNotPremiumUser] = useState(false);

  useEffect(() => {
    cookies.set("isLoggedIn", isAuthenticated.toString(), { expires: 9000, domain: shortAppUrl });
  }, [isAuthenticated]);

  useEffect(() => {
    FilterTeamService.setIsTeam(isRbTeam);
    EnrichmentsService.setEnrichments(queryString);
  }, []);

  useEffect(() => {
    const isAllSet = setUserPropertiesForAnalytics(userData.role, userData.id, userData.agency);
    isAllSet && localStorage.setItem("shouldUpdateProfile", "true");
  }, [userData.role, userData.id, userData.agency]);

  useEffect(() => {
    const { subscription_level } = userData?.agency || {};
    setIsNotPremiumUser(subscription_level === SUBSCRIPTION_TYPES.free);
  }, [userData]);

  const demoAction = (actionType = "") => {
    setToggleModal(true);
    AnalyticsService.reportEvent("open_activate_modal");
    setType(actionType);
  };

  return (
    <>
      <HeaderTags title={DOCUMENT_TITLE_DEFAULT} description={META_DESCRIPTION_DEFUALT} />
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, isNotPremiumUser, setIsNotPremiumUser }}>
        <DemoContext.Provider value={{ isDemo, demoAction }}>
          <BannerContext.Provider value={{ dashboardBannerVisible, setDashboardBannerVisible }}>
            <RenterPortalContext.Provider value={{}}>
              <ScrollToTop />
              <Routes />
              <ActivateModal type={type} toggle={toggleModal} setToggle={setToggleModal} />
            </RenterPortalContext.Provider>
          </BannerContext.Provider>
        </DemoContext.Provider>
      </AppContext.Provider>
    </>
  );
}

export default App;
