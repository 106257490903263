import { ShareLinkIcon } from "components/icons/ShareLink";
import CopyTextInput from "components/Shared/CopyTextInput";
import React from "react";
import { Link } from "react-router-dom";
import "./SuccessLeadView.scss";
import ShareReqIcon from "../../../../../../icons/share-req-icon/ShareReqIcon";
import { AnalyticsService } from "helpers/analyticsService";
import BaseButton from "components/Shared/BaseButton";
import routes from "routes/routesCode";

interface ISuccessLeadViewProps {
  header: string;
  Icon?: JSX.Element;
  descriptionOne: string;
  descriptionTwo: string | boolean;
  placement?: "center" | "left" | "right";
  leadAccountLink?: string;
  shareLink?: string;
}

const SuccessLeadView = ({
  header,
  Icon,
  descriptionOne,
  placement,
  leadAccountLink,
  shareLink,
  descriptionTwo,
}: ISuccessLeadViewProps) => {
  return (
    <div className={`success-lead-view-wrapper ${placement ?? ""}`}>
      <div className="success-lead-view-wrapper--icon-row">
        <div className="success-lead-view-wrapper--icon-row--icon-wrapper">{Icon}</div>
      </div>
      <h3 className="text-2xl font-bold my-4">{header}</h3>
      <p className="pb-8 text-lg">
        You're all set and ready to go! Verify your information below or continue to your Rental Passport.
      </p>
      <div className="flex justify-center">
        <BaseButton
          variant="primary"
          href={routes.renterPortalDashboard.path}
          onClick={() => {
            AnalyticsService.reportRequirementsFunnel({ action: "view_your_requirements" });
          }}
        >
          Go to your Rental Passport
        </BaseButton>
      </div>
    </div>
  );
};

export default SuccessLeadView;
