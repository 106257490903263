import React from "react";

const CollapseBottomIcon = () =>{
    return(
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.3293 7.86234L11.8643 1.84946C12.0455 1.65245 12.0452 1.3335 11.8634 1.13682C11.6815 0.9403 11.3869 0.940807 11.2055 1.13784L5.99998 6.79282L0.794437 1.13763C0.613032 0.940629 0.318634 0.940121 0.13676 1.13662C0.0455885 1.23521 2.84464e-06 1.36437 2.83899e-06 1.49353C2.83336e-06 1.62236 0.0452833 1.75102 0.135822 1.84943L5.67068 7.86234C5.75782 7.95723 5.87642 8.01047 5.99998 8.01047C6.12354 8.01047 6.24199 7.95707 6.3293 7.86234Z" fill="white"/>
        </svg>
    )
}

export default CollapseBottomIcon