import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Form, List } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteListingNote,
  getListingNotes,
  setListingNotesSuccess,
} from "../../store/listings/action";
import {
  isPublicListingSelector,
  listingDataSelector,
  listingNoteAddSuccessSelector,
  listingNotesLoadingSelector,
  listingNotesSelector,
} from "../../store/listings/selectors";
import { ListingNote } from "../Listings/helpers/mockListings";
import moment from "moment";
import BaseButton from "../Shared/BaseButton";
import BaseCollapse from "../Shared/BaseCollapse";
import BaseEmptyState from "../BaseEmptyState";
import qs from "query-string";
import NoteDeleteConfirmModal from "../Shared/NoteDeleteConfirmModal";
import { IObjectKeys } from "../../helpers/globalTypes";
import { Document } from "@contentful/rich-text-types";
import { getDeleteNoteConfirmContent } from "../../contentful/api";
import ListingNotesListItem from "./listing-notes-elements/ListingNotesListItem";
import { windowSelector } from "../../store/app/selectors";
import ListingNoteModal from "./add-listing-note-modal/ListingNoteModal";
import { useWindowSize } from "../../hooks/useWindowSize";
import AllListingNotesModal from "./all-listing-notes-modal/AllListingNotesModal";
import Message from "../Shared/Message";
import { landlordNoteAddSuccessSelector } from "../../store/landlords/selectors";
import { setLandlordNoteAddSuccess } from "../../store/landlords/action";
import { useDemoContext } from "libs/contextLib";
import { AnalyticsService } from "helpers/analyticsService";
import {
  getDateDifference,
  getStartOfUnitFormatted,
  currentDate,
  dateToMoment,
  formatDate,
} from "../../helpers/dateHelper";

const confirmDeleteValue = { visible: false, noteId: "" };
const initialModalValue = { visible: false, editModal: false };

const ListingNotes = () => {
  // @ts-ignore
  const { location } = useHistory();
  const dispatch = useDispatch();
  const listingNotes = useSelector(listingNotesSelector);
  const listingNotesLoading = useSelector(listingNotesLoadingSelector);
  const addListingNoteSuccess = useSelector(listingNoteAddSuccessSelector);
  const [form] = Form.useForm();
  const listing = useSelector(listingDataSelector);
  const [notesToSee, setNotesToSee] = useState<ListingNote[]>([]);
  const { width } = useWindowSize();
  // const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [modalProps, setModalProps] = useState(initialModalValue);
  const [seeAllNotesModalVisible, setSeeAllNotesModalVisible] = useState(false);
  const [notesCollapsed, setNotesCollapsed] = useState(false);
  const [deleteConfirmProps, setDeleteConfirmProps] = useState(
    confirmDeleteValue
  );
  const landlordAddSuccess = useSelector(landlordNoteAddSuccessSelector);
  const [selectedNote, setSelectedNote] = useState({});
  const [deleteConfirmContent, setDeleteConfirmContent] = useState<
    Document | undefined
  >(undefined);
  const isPublicListing = useSelector(isPublicListingSelector);
  const { isDemo, demoAction } = useDemoContext();

  useEffect(() => {
    if (allLoaded) {
      setNotesToSee([...listingNotes]);
    } else {
      setNotesToSee(
        listingNotes.length > 4
          ? [...listingNotes?.slice(0, 4)]
          : listingNotes.length <= 4 && listingNotes.length >= 1
            ? [...listingNotes]
            : []
      );
    }
  }, [listingNotes, allLoaded]);

  useEffect(() => {
    if (Object.keys(listing).length) {
      if (!isPublicListing && listing?._id) {
        dispatch(getListingNotes(listing?._id));
      }
    }
    (async () => {
      const entries: IObjectKeys = await getDeleteNoteConfirmContent();
      if (entries) {
        setDeleteConfirmContent(entries.fields.modalDescription);
      }
    })();
  }, [isPublicListing, listing]);
  const listingNoteAddSuccess = useSelector(listingNoteAddSuccessSelector);
  useEffect(() => {
    addListingNoteSuccess && setModalProps(initialModalValue);
    if (listingNoteAddSuccess) {
      setTimeout(() => {
        dispatch(setListingNotesSuccess(false));
      }, 2000);
    }
  }, [addListingNoteSuccess]);

  const onLoadMore = () => {
    AnalyticsService.reportSingleListingPageClick("see_all");
    if (isDemo) {
      demoAction("followUp");
    } else {
      setSeeAllNotesModalVisible(true);
      setTimeout(() => { }, 700);
    }
  };

  const handleOpenAddNoteModal = () => {
    setModalProps({ visible: true, editModal: false });
    form.resetFields();
  };

  const handleOpenNoteModal = (note: ListingNote) => {
    const todo_at = note.todo_at ? moment(note.todo_at) : null;
    setModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at,
    });
  };
  useEffect(() => {
    if (listingNotes.length && location.search) {
      const { note_id } = qs.parse(window.location.search);
      if (note_id) {
        setNotesCollapsed(true);
        // const current_note = listingNotes.find((note: ListingNote) => {
        //   return note._id === note_id;
        // });
        // if (current_note) {
        //   handleOpenNoteModal(current_note);
        // }
      }
    }
  }, [location.search, listingNotes]);

  const notesEmptyStateProps = {
    title: "Listing Notes",
    description: "No notes for this listing yet.",
    linkText: "Add Notes",
    openWithModal: false,
    modalType: "followUp",
    handleAddBtnClick: handleOpenAddNoteModal,
    modalProps: {
      isModalVisible: false,
      children: "",
      modalTitle: "",
    },
  };
  const leftOrAgoGenerator = (date: Date) => {
    return new Date(date).getTime() < new Date().getTime() ? "ago" : "left";
  };
  const dueContent = (todo_at: string, str: string, item: any) => {
    let daysCount = 0;
    let dayOrDays = "days";
    if (
      getDateDifference(item.todo_at, "days") === 0 &&
      formatDate(item.todo_at) < getStartOfUnitFormatted(currentDate(), "day")
    ) {
      daysCount = 1;
    } else {
      daysCount = getDateDifference(item.todo_at, "days");
      if (daysCount === 0) {
        const hoursCount = getDateDifference(item.todo_at, "hours");
        let hoursOrHours = "hours";
        if (hoursCount <= 1) {
          hoursOrHours = "hour";
        }

        return `(${hoursCount} ${hoursOrHours} ${str})`;
      }
    }

    if (daysCount <= 1) {
      dayOrDays = "day";
    }

    return `(${daysCount} ${dayOrDays} ${str})`;
  };
  const { collapsed } = useSelector(windowSelector);

  useEffect(() => {
    if (landlordAddSuccess) {
      setTimeout(() => {
        dispatch(setLandlordNoteAddSuccess(false));
      }, 2000);
    }
  }, [landlordAddSuccess]);

  return (
    <>
      {notesToSee && notesToSee.length > 0 ? (
        <BaseCollapse
          header={
            <div className={"notes-collapse-header"}>
              <span className={"collapse-header-txt"}>Listing Notes</span>
            </div>
          }
          classname={"listing-notes-collapse listing-notes-collapse-wrapperAll"}
          collapse={notesCollapsed}
        >
          {
            //@ts-ignore
            width > 767 && (
              <div className="notes-btn-wrapper">
                <BaseButton
                  onClick={onLoadMore}
                  classnames={"rb-secondary-btn md"}
                  modalType="listing"
                >
                  See All
                </BaseButton>
                <BaseButton
                  classnames={"rb-primary-btn md"}
                  onClick={() => {
                    AnalyticsService.reportSingleListingPageClick("add_a_note");
                    handleOpenAddNoteModal();
                  }}
                  modalType="followUp"
                >
                  Add a Note
                </BaseButton>
              </div>
            )
          }
          <List
            className={`notes-loadmore-list single-listing-notes-skeleton-wrapper
             ${collapsed && "single-listing-notes-skeleton-wrapperCollapsed"} `}
            itemLayout="horizontal"
            header={
              <span className="notes-total-text">
                Showing {notesToSee?.length} of {listingNotes?.length} notes
              </span>
            }
            // loadMore={loadMore}
            dataSource={notesToSee}
            renderItem={(item: ListingNote) => (
              <ListingNotesListItem
                handleOpenNoteModal={handleOpenNoteModal}
                item={item}
                onShowNote={onLoadMore}
                setDeleteConfirmProps={setDeleteConfirmProps}
                listingNotesLoading={listingNotesLoading}
                dueContent={dueContent}
                leftOrAgoGenerator={leftOrAgoGenerator}
                setSelectedNote={setSelectedNote}
              />
            )}
          />
          {
            //@ts-ignore
            width <= 768 && (
              <div className="notes-btn-wrapper">
                {listingNotes?.length > 3 && (
                  <BaseButton
                    onClick={onLoadMore}
                    classnames={"rb-secondary-btn md"}
                    modalType="listing"
                  >
                    See All
                  </BaseButton>
                )}
                <BaseButton
                  classnames={"rb-primary-btn md"}
                  onClick={() => {
                    AnalyticsService.reportSingleListingPageClick("add_a_note");
                    handleOpenAddNoteModal();
                  }}
                  modalType="followUp"
                >
                  Add a Note
                </BaseButton>
              </div>
            )
          }
        </BaseCollapse>
      ) : (
        <div className={"empty-listing-notes"}>
          <BaseEmptyState {...notesEmptyStateProps} />
        </div>
      )}
      <ListingNoteModal
        modalProps={modalProps}
        listingNotesLoading={listingNotesLoading}
        form={form}
        setModalProps={setModalProps}
        initialModalValue={initialModalValue}
        listing={listing}
      />
      <NoteDeleteConfirmModal
        deleteConfirmProps={deleteConfirmProps}
        setDeleteConfirmProps={setDeleteConfirmProps}
        modalContent={deleteConfirmContent}
        onOkayClick={() => {
          dispatch(deleteListingNote(deleteConfirmProps.noteId || ""));
        }}
      />
      <AllListingNotesModal
        visible={seeAllNotesModalVisible}
        setVisible={setSeeAllNotesModalVisible}
        handleOpenAddNoteModal={handleOpenAddNoteModal}
        notes={listingNotes}
        handleOpenNoteModal={handleOpenNoteModal}
        setDeleteConfirmProps={setDeleteConfirmProps}
        setSelectedNote={setSelectedNote}
        selectedNote={selectedNote}
      />
      {listingNoteAddSuccess && <Message text={"Note Added Successfully"} />}
      {landlordAddSuccess && <Message text={"Note Added Successfully"} />}
    </>
  );
};
export default ListingNotes;
