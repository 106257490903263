import React, { MouseEventHandler } from "react";
import "./ShareOrSeeRequirement.scss";
import BaseButton from "../../../../../../Shared/BaseButton";

const ShareOrSeeRequirement = ({
  onActionBtnsClick,
  showShareBtns,
}: {
  onActionBtnsClick: MouseEventHandler<HTMLButtonElement> | undefined;
  showShareBtns?: boolean;
}) => {
  return (
    <div className="share-or-see-requirements-section">
      <div className="flex justify-center my-8">
        <BaseButton variant="primary" dataTestId="seeRequirements" onClick={onActionBtnsClick}>
          <span data-test-id={"seeRequirements"}>Go To My Rental Passport</span>
        </BaseButton>
        {/* <BaseButton classnames="rb-secondary-btn" dataTestId="shareRequirements" onClick={onActionBtnsClick}>
          <span data-test-id={"shareRequirements"}>Share my Rental Passport</span>
        </BaseButton> */}
      </div>
      {!showShareBtns ? (
        <div className={"share-or-see-requirements-section-email-verification-message"}>
          <p>
            An email verification was sent.
            <br />
            Please verify your account to share your requirements.
          </p>
        </div>
      ) : null}
    </div>
  );
};
export default ShareOrSeeRequirement;
