import React from "react";
import { DatePicker, Form } from "antd";
import { NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE, REQUIRED } from "../../../../helpers/validations";
import { US_DATE_FORMAT } from "../../../../helpers/constants";
import { getListingInput } from "../../../../helpers/globalFunctions";
import { InfoIcon } from "../../../icons";
const Basics = () => {
  return (
    <div className={"availability-step"}>
      {getListingInput(
        "asked_rent",
        "Rent:",
        [NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE, REQUIRED],
        "Ex. $1200",
        "",
        "",
        true
      )}
      {getListingInput(
        "current_rent",
        "Previous year's rent (Optional)",
        [NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE],
        "Ex. $1200",
        "",
        "",
        true
      )}
      <Form.Item
        data-test-id={"availability_date"}
        name="availability_date"
        label="Lease Start Date"
        labelCol={{ span: 24 }}
        rules={[{ required: false, message: " Selecting a date " }]}
      >
        <DatePicker className="w-36" placeholder={"Ex. 01-21-2023"} format={`${US_DATE_FORMAT}`} />
      </Form.Item>
    </div>
  );
};
export default Basics;
