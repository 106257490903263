import React, { useEffect } from "react";
import FilterFormTitle from "../filter-form-title/FilterFormTitle";
import { MultiSelectTagsCheckboxes } from "../../../../../../helpers/globalFunctions";
import { FormInstance } from "antd/es/form";
import "./TagsFilter.scss"
import { useLocation } from "react-router";
import { urlQueryToObject } from "../../../helpers/listingsCreateGetSearchQuery";
import { tags, landlordTags } from "components/Lead/shared/constants";

interface ITagsFilter {
    form: FormInstance
}
const TagsFilter = ({ form }: ITagsFilter) => {
    const { search, pathname } = useLocation();
    const _newURLSearchParams = new URLSearchParams(search);
    const queryObject = urlQueryToObject(_newURLSearchParams);

    useEffect(() => {
        if (queryObject.tags) {
            form.setFieldsValue({ tags: queryObject.tags })
        }
    }, [])
    return (
        <div className={"tags-filter-wrapper"}>
            <FilterFormTitle title={"Tags"} />
            {
                MultiSelectTagsCheckboxes(
                    pathname === "/" ? tags : landlordTags,
                    "tags",
                    form,
                    "tags-filter-wrapper-checkboxes"
                )
            }
        </div>
    )
}


export default TagsFilter
