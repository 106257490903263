import React, { useEffect, useState, useRef, useCallback } from "react";
import { Form, Input, Radio, Select, Spin, Typography, Button } from "antd";
import {
  EMAIL_PLACEHOLDER,
  NOT_REQUIRED_EMAIL_VALIDATION,
  NOT_REQUIRED_PHONE,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
  REQUIRED,
} from "../../../helpers/validations";
import { StepFormComponent } from "./types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListingUpdateState,
  fillListingData,
  setCurrentSteps,
  updateListing,
  setIsCurrentFormChange,
  addListing,
} from "../../../store/listings/action";
import {
  currentStepSelector,
  listingDataSelector,
  listingLoadingSelector,
  listingUpdateSuccessSelector,
  stepClickedSelector,
  isCurrentFormChangedSelector,
} from "../../../store/listings/selectors";
import { IObjectKeys } from "../../../helpers/globalTypes";
import {
  alphabeticalSort,
  getQueryParams,
  openNotification,
  sanitizePhoneNumber,
  usersFreeSubscriptionLimits,
} from "../../../helpers/globalFunctions";
import { userSelector } from "../../../store/user/selectors";
import { useLocation } from "react-router-dom";
import {
  isLandlordExistSelector,
  landlordLoadingSelector,
  landlordsListSelector,
} from "../../../store/landlords/selectors";
import MaskedInput from "../../Shared/MaskedPhoneInput";
import { InfoIcon } from "../../icons";
import StepsActionButtons from "./StepsActionButtons";
import SwitchButton from "./SwitchButton";
import { windowSelector } from "../../../store/app/selectors";
import { debounce } from "lodash";
import { checkExistingLandlordSuccess, getLandlordsListLP } from "store/landlords/action";
import { useAppContext } from "libs/contextLib";
import LandlordApi from "api/Landlord";

const { Title } = Typography;
const { Option } = Select;
const LandlordDetails = ({ form, fromListing, listingId }: StepFormComponent) => {
  const { isNotPremiumUser } = useAppContext();
  const dispatch = useDispatch();
  const { search } = useLocation();
  let { lId, step } = getQueryParams(search);
  lId = lId || listingId;
  const userData = useSelector(userSelector);
  const landlordList = useSelector(landlordsListSelector);
  const listingData = useSelector(listingDataSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const stepClicked = useSelector(stepClickedSelector);
  const [addNewLandlord, setAddNewLandlord] = useState(true);
  const [landlordPrivate, setLandlordPrivate] = useState(false);
  const current = useSelector(currentStepSelector);
  const window = useSelector(windowSelector);
  const isCurrentFormChanged = useSelector(isCurrentFormChangedSelector);
  const landlordLoading = useSelector(landlordLoadingSelector);
  const quickSelectLandlords: Array<any> = landlordList.slice(0, 4);
  const landlordFilters = { page: 1, pageSize: 20 };
  const isLandlordExists = useSelector(isLandlordExistSelector);
  const [submitData, setSubmitData] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [existingLandlordChecked, setExistingLandlordChecked] = useState(false);
  const usersFreeSubscriptionLimit: any = usersFreeSubscriptionLimits({ userData, isNotPremiumUser });
  const delaySearchLandlords = useRef(
    debounce((value) => {
      dispatch(getLandlordsListLP({ ...landlordFilters, searchValue: value }));
    }, 800)
  );

  const next = useCallback(() => {
    dispatch(setCurrentSteps(current + 1));
  }, [current, dispatch]);

  const onSubmit = async (data: IObjectKeys) => {
    setIsFormSubmitted(true);
    if (!data.landlord_phone && !data.landlord_email) {
      return openNotification("error", "An email or phone number is required!");
    }
    if (data.landlord_phone) {
      data.landlord_phone = sanitizePhoneNumber(data.landlord_phone);
    }
    const checkExistingLandlordData = {
      email: data.landlord_email,
      phone: sanitizePhoneNumber(data.landlord_phone)
    };
    if (!existingLandlordChecked) {
      const resp: any = await LandlordApi.checkExistingLandlord(checkExistingLandlordData);
      if (resp?.isExists) {
        openNotification("error", 'Landlord already exists with that email or phone number.');
        return;
      }
    }
    dispatch(fillListingData({ ...data, agent_id: userData.agent_id }));
    if (lId) {
      dispatch(updateListing({ lId, ...data, step: "landlord_step" }));
    } else {
      dispatch(addListing({ ...data, ...listingData }));
      if (form.getFieldValue("landlord_exists") === true || lId) {
        next && next();
      } else {
        setSubmitData({ ...data });
      }
    }

  };

  const handleLandlordFieldChange = (value: string, fieldName: string) => {
    const landlord =
      fieldName === "name"
        ? landlordList.find((l: IObjectKeys) => l.name === value)
        : landlordList.find((l: IObjectKeys) => l.email === value);
    if (landlord) {
      setExistingLandlordChecked(true);
      form.setFieldsValue({
        landlord_name: landlord.name,
        landlord_phone: landlord.phone ?? "",
        landlord_email: landlord.email ?? "",
      });
    } else {
      form.setFieldsValue({
        landlord_name: "",
        landlord_phone: "",
        landlord_email: "",
      });
    }
  };

  const delaySearchLandlordList = (value: string) => {
    return delaySearchLandlords?.current(value);
  };

  useEffect(() => {
    if (isLandlordExists) {
      setIsFormSubmitted(false);
      openNotification("error", "Landlord already exists");
      setSubmitData({});
      setTimeout(() => {
        dispatch(checkExistingLandlordSuccess(null));
      }, 500);
    } else {
      dispatch(fillListingData({ ...submitData, agent_id: userData.agent_id }));
      setIsFormSubmitted(true);
      setTimeout(() => {
        if (isFormSubmitted) {
          next && next();
        }
      }, 200);
    }
  }, [isLandlordExists]);

  useEffect(() => {
    return () => {
      setIsFormSubmitted(false);
      dispatch(checkExistingLandlordSuccess(false));
    };
  }, []);

  useEffect(() => {
    dispatch(setIsCurrentFormChange(false));
  }, [dispatch]);

  useEffect(() => {
    if (listingData.landlordOfList) {
      setExistingLandlordChecked(true);
    }
    if (
      listingData.landlordOfList?.is_private &&
      listingData.landlordOfList.created_by !== userData.agent_id &&
      (+step === 0 || current === 0) &&
      !step
    ) {
      dispatch(setCurrentSteps(current + 1));
    }
    if (listingData.hasOwnProperty("landlord_exists")) {
      setExistingLandlordChecked(true);
      form.setFieldsValue({
        landlord_exists: listingData.landlord_exists,
        landlord_name: listingData.landlord_name,
        landlord_email: listingData.landlord_email ?? "",
        landlord_phone: listingData.landlord_phone ?? "",
      });
      setAddNewLandlord(!listingData.landlord_exists);
    } else if (listingData.landlordOfList) {
      form.setFieldsValue({
        landlord_exists: true,
        landlord_name: listingData.landlordOfList.name,
        landlord_email: listingData.landlordOfList.email ?? "",
        landlord_phone: listingData.landlordOfList.phone ?? "",
        is_private: listingData.landlordOfList.is_private,
      });
      setAddNewLandlord(false);
    }
    if (listingData.hasOwnProperty("landlord_private")) {
      setLandlordPrivate(listingData.landlord_private);
      form.setFieldsValue({
        landlord_private: listingData.landlord_private,
      });
    }
  }, [current, dispatch, form, listingData, step, userData.agent_id]);

  useEffect(() => {
    if (!stepClicked && updateListingSuccess) {
      next && next();
    }
    return () => {
      dispatch(clearListingUpdateState());
    };
  }, [dispatch, next, stepClicked, updateListingSuccess]);

  const handlePrivateTypeChange = () => {
    setLandlordPrivate(!landlordPrivate);
  };

  const handleQuickSelect = (landlordInfo: IObjectKeys) => {
    const changeFields: IObjectKeys = {};
    if (landlordInfo.name) changeFields["landlord_name"] = landlordInfo.name;
    if (landlordInfo.name) changeFields["landlord_email"] = landlordInfo.email ?? "";
    if (landlordInfo.name) changeFields["landlord_phone"] = landlordInfo.phone ?? "";
    form.setFieldsValue({ ...changeFields });
    setExistingLandlordChecked(true);
  };

  useEffect(() => {
    if (usersFreeSubscriptionLimit?.landlords) {
      setAddNewLandlord(!usersFreeSubscriptionLimit?.landlords);
    }
  }, [usersFreeSubscriptionLimit?.landlords]);

  return (
    <>
      <Spin tip="Submitting..." spinning={listingLoading}>
        <Title level={4}>Landlord Details</Title>
        <Form
          layout={"vertical"}
          className={"landlord-core-details-form"}
          data-test-id={"landlord-core-details-form"}
          form={form}
          onFinish={onSubmit}
          initialValues={{ landlord_exists: usersFreeSubscriptionLimit?.landlords }}
          onFieldsChange={(changedFields) => {
            if (!isCurrentFormChanged) dispatch(setIsCurrentFormChange(true));
            if (changedFields[0]?.value === true && changedFields.length === 1) {
              form.setFieldsValue({
                landlord_name: "",
                landlord_phone: "",
                landlord_email: "",
                landlord_private: false,
              });
            }
            if (
              // eslint-disable-next-line no-restricted-globals
              Array.isArray(changedFields[0]?.name) &&
              // eslint-disable-next-line no-restricted-globals
              changedFields[0]?.name?.indexOf("landlord_exists") > -1 &&
              changedFields[0]?.value !== undefined
            ) {
              if (changedFields[0]?.value === true) {
                setExistingLandlordChecked(true);
              } else {
                setExistingLandlordChecked(false);
              }
              setAddNewLandlord(!changedFields[0]?.value);
              if (!changedFields[0]?.value && !addNewLandlord) {
                form.setFieldsValue({
                  landlord_name: "",
                  landlord_phone: "",
                  landlord_email: "",
                  landlord_private: false,
                });
                setLandlordPrivate(false);
              }
            }
          }}
        >
          <Form.Item name="landlord_exists" data-test-id={"landlord_exists"} rules={[REQUIRED]}>
            <Radio.Group data-test-id={"radio_group"} className="new-exists-radio-group">
              <Radio data-test-id={"new_landlord"} value={false} disabled={usersFreeSubscriptionLimit?.landlords}>
                New Landlord
              </Radio>
              <Radio data-test-id={"existing_landlord"} value={true}>
                Existing Landlord
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="landlord_name"
            label={window.type !== "mobile" || addNewLandlord ? "Full Name" : "Search Landlord Name or Email"}
            data-test-id={"landlord_name"}
            rules={[REQUIRED]}
          >
            {addNewLandlord ? (
              <Input autoComplete={"off"} placeholder="Full Name" data-test-id={"landlord_input"} />
            ) : (
              <Select
                showSearch
                placeholder="Full Name"
                defaultActiveFirstOption={false}
                data-test-id={"landlord_name"}
                filterOption={(input, option) => {
                  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={(value: string) => handleLandlordFieldChange(value, "name")}
                notFoundContent={landlordLoading ? <Spin size="small" /> : null}
                onSearch={(value: string) => {
                  delaySearchLandlordList(value);
                }}
              >
                {landlordList.sort(alphabeticalSort).map((landlord: IObjectKeys) => (
                  // eslint-disable-next-line no-restricted-globals
                  <Option
                    key={landlord.landlord_id}
                    // eslint-disable-next-line no-restricted-globals
                    value={landlord?.name}
                    // eslint-disable-next-line no-restricted-globals
                    data-test-id={landlord?.name}
                  >
                    {
                      // eslint-disable-next-line no-restricted-globals
                      landlord?.name
                    }
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            data-test-id={"landlord_phone"}
            name="landlord_phone"
            label="Phone Number"
            rules={[NOT_REQUIRED_PHONE]}
            validateTrigger={"onBlur"}
            className={`${window.type === "mobile" && !addNewLandlord ? "display-none" : ""}`}
            tooltip={{
              icon: (
                <span className={"mb--5"}>
                  <InfoIcon />
                </span>
              ),
              title: "Either phone or e-mail is required.",
            }}
          >
            <MaskedInput
              inputMode="numeric"
              mask={PHONE_NUMBER_MASK}
              placeholder={PHONE_NUMBER_PLACEHOLDER}
              readOnly={!addNewLandlord}
            />
          </Form.Item>
          <Form.Item
            rules={[NOT_REQUIRED_EMAIL_VALIDATION]}
            validateTrigger={"onBlur"}
            className={`${window.type === "mobile" && !addNewLandlord ? "display-none" : ""}`}
            name="landlord_email"
            label={"Landlord Email"}
            data-test-id={"landlord_email"}
            tooltip={{
              icon: (
                <span className={"mb--5"}>
                  <InfoIcon />
                </span>
              ),
              title: "Either phone or e-mail is required.",
            }}
          >
            {addNewLandlord ? (
              <Input autoComplete={"off"} placeholder={EMAIL_PLACEHOLDER} data-test-id={"input_email"} />
            ) : (
              <Select
                showSearch
                data-test-id={"select_email"}
                placeholder={"Enter Full Name or Email"}
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={(input, option) => {
                  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={(value: string) => handleLandlordFieldChange(value, "email")}
                notFoundContent={null}
              >
                {landlordList.map(
                  (landlord: IObjectKeys) =>
                    landlord.email && (
                      <Option key={landlord.landlord_id} value={landlord?.email} data-test-id={landlord?.email}>
                        {landlord?.email}
                      </Option>
                    )
                )}
              </Select>
            )}
          </Form.Item>

          {!addNewLandlord && window.type === "mobile" && (
            <div className="quick-select-landlord-wrapper">
              <h3>Quick Select</h3>
              <div className="quick-select-landlords_wrapper">
                {quickSelectLandlords.map((land: IObjectKeys) => {
                  return (
                    <Button
                      className="primary-btn-link bg-transparent quick-select-land-btn"
                      key={land._id}
                      onClick={() => handleQuickSelect(land)}
                    >
                      <span>+ {land.name}</span>
                    </Button>
                  );
                })}
              </div>
            </div>
          )}
          {addNewLandlord && (
            <Form.Item name="landlord_private" data-test-id={"landlord_private"}>
              <SwitchButton
                onChange={handlePrivateTypeChange}
                checked={landlordPrivate}
                label={"Set Landlord as Private"}
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
      {!fromListing && <StepsActionButtons form={form} isFirstStep={true} />}
    </>
  );
};

export default LandlordDetails;
