import { IObjectKeys } from './../../helpers/globalTypes';
import * as types from "./types";
import { AuthError } from "../../types/user";

export const getAreas = (payload: IObjectKeys) => ({
  type: types.GET_AREAS,
  payload,
});
export const getAreasSuccess = (payload: { Areas: IObjectKeys[] }) => ({
  type: types.GET_AREAS_SUCCESS,
  payload,
});
export const getAreasFail = (payload: AuthError) => ({
  type: types.GET_AREAS_FAIL,
  payload,
});
export const getAreasFacets = () => ({
  type: types.GET_AREAS_FACETS,
})
export const getAreasFacetsSuccess = (payload: IObjectKeys) => ({
  type: types.GET_AREAS_FACETS_SUCCESS,
  payload
})
export const getAreasFacetsFail = (payload: any) => ({
  type: types.GET_AREAS_FACETS_FAIL,
  payload
})
