import React, { useState } from "react";
import moment from "moment";
import "./AddFollowUpModalMobileDateSection.scss";
import { useDispatch, useSelector } from "react-redux";
import { addFollowUp, editFollowUp } from "../../../../store/followUps/action";
import Calendar from "../../all-follow-ups-modal/components/follow-up-modal-single-item/icons/Calendar";
import AddFollowUpModalMobileDatePicker from "../date-picker-section/AddFollowUpModalMobileDatePicker";
import {
  getBrowser,
  getBrowserDateFormat,
} from "../../../../helpers/globalFunctions";
import { addDashboardFollowUp } from "../../../../store/dashboard/action";
import { updateLeadTags } from "store/leads/action";
import { singleLeadDataSelector } from "store/leads/selectors";
import { addTimeAndFormatDate, currentDate } from "helpers/dateHelper";
import { momentSet8Am } from "helpers/constants";

interface IAddFollowUpModalMobileDateSection {
  isFollowUpEditable: boolean;
  modalProps: any;
  submitData: any;
  submitDataId: any;
  setModalProps: any;
  initialModalValue: any;
  listing: any;
  landlordInfo: any;
  lead: any;
  isDatePickerVisible: boolean;
  setIsDatePickerVisible: any;
  setNextStepVisible: any;
  setTodoAt: any;
  isFromDashboard?: boolean;
  fromScoring?: boolean;
}

const AddFollowUpModalMobileDateSection = ({
  isFollowUpEditable,
  modalProps,
  submitData,
  submitDataId,
  setModalProps,
  initialModalValue,
  listing,
  landlordInfo,
  lead,
  isDatePickerVisible,
  setIsDatePickerVisible,
  setTodoAt,
  setNextStepVisible,
  isFromDashboard,
  fromScoring,
}: IAddFollowUpModalMobileDateSection) => {
  const dispatch = useDispatch();
  const leadData = useSelector(singleLeadDataSelector);
  const dateArray = [
    {
      date: addTimeAndFormatDate(
        currentDate(),
        { amount: 1, unit: "days" },
        momentSet8Am,
        getBrowser()
      ),
      title: "Tomorrow",
    },
    {
      date: moment()
        .weekday(6)
        .set({ hour: 8, minute: 0 })
        .format(getBrowser()),
      title: "This Weekend",
    },
    {
      date: moment()
        .add(1, "weeks")
        .weekday(1)
        .set({ hour: 8, minute: 0 })
        .format(getBrowser()),
      title: "Next Week",
    },
    {
      date: moment()
        .add(1, "weeks")
        .weekday(6)
        .set({ hour: 8, minute: 0 })
        .format(getBrowser()),
      title: "Next Weekend",
    },
  ];
  const addEditFollowUpHandler = (todoAt: any) => {
    const modalData: any = {
      title: submitData.title,
      description: submitData.description,
      agent_id: submitData.agent_id,
      todo_at: todoAt,
      todo_status: "in_progress",
    };
    if (listing && Object.entries(listing).length) {
      modalData.listing_id = listing._id;
    }
    if (landlordInfo && Object.entries(landlordInfo).length) {
      modalData.landlord_id = landlordInfo.landlord_id;
    }
    if (lead && Object.entries(lead).length) {
      modalData.requirements_id = lead.requirement.id;
      modalData.renter_id = lead.renter
        ? lead.renter._id
        : lead.renterOfRequirement._id;
    }
    if (isFollowUpEditable || modalProps.editModal) {
      dispatch(editFollowUp(modalData, submitDataId));
      setModalProps(initialModalValue);
    } else {
      if (isFromDashboard) dispatch(addDashboardFollowUp(modalData));
      else {
        dispatch(addFollowUp(modalData));
        if (fromScoring) {
          dispatch(
            updateLeadTags({
              requirements_id: leadData?.requirement?._id,
              tags: leadData?.tags || [],
              step: 4,
            })
          );
        }
      }
    }
  };
  return (
    <>
      {!isDatePickerVisible ? (
        <div className={"add-follow-up-modal-dateSection"}>
          {dateArray.map((e, i) => {
            return (
              <div
                key={i}
                className={"add-follow-up-modal-dateSection--items"}
                onClick={() => {
                  addEditFollowUpHandler(new Date(e.date).toISOString());
                }}
              >
                <span>{e.title}</span>{" "}
                <span>
                  {moment(e.date, getBrowserDateFormat())
                    .set({ hour: 8, minute: 0 })
                    .format("MM.DD.YYYY HH:mm A")}
                </span>
              </div>
            );
          })}
          <div
            className={"add-follow-up-modal-dateSection--itemPicker"}
            onClick={() => setIsDatePickerVisible(true)}
          >
            <Calendar />
            <span>Pick date and time</span>
          </div>
        </div>
      ) : (
        <AddFollowUpModalMobileDatePicker
          submitData={submitData}
          listing={listing}
          landlordInfo={landlordInfo}
          lead={lead}
          isFollowUpEditable={isFollowUpEditable}
          modalProps={modalProps}
          setModalProps={setModalProps}
          initialModalValue={initialModalValue}
          submitDataId={submitDataId}
          setTodoAt={setTodoAt}
          setIsDatePickerVisible={setIsDatePickerVisible}
          setNextStepVisible={setNextStepVisible}
          fromScoring={fromScoring}
        />
      )}
    </>
  );
};

export default AddFollowUpModalMobileDateSection;
