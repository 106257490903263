import React, { memo, useEffect, useState } from "react";
import FilterFormTitle from "../filter-form-title/FilterFormTitle";
import { FormInstance } from "antd";
import "./LocationFilter.scss";
import { useSelector } from "react-redux";
import { areasFacetsSelector } from "../../../../../../store/areas/selectors";
import LocationSearch from "./components/location-search/LocationSearch";
import { useLocation } from "react-router";
import {createArrayWithSelectedItemsForMultiselect, generateFacetData} from "../../../helpers/filterFormHelper";

const LocationFilter = ({
  form,
  setLocation,
  isLocationsSelectVisible,
  setIsLocationsSelectVisible,
  checkedLocationsArray,
  setCheckedLocationsArray,
  isClearAllClicked,
}: {
  form: FormInstance;
  setLocation: React.Dispatch<React.SetStateAction<any>>;
  isLocationsSelectVisible: boolean;
  setIsLocationsSelectVisible: React.Dispatch<React.SetStateAction<boolean>>;
  checkedLocationsArray: any;
  setCheckedLocationsArray: any;
  isClearAllClicked: boolean;
}) => {
  const facetAreas = useSelector(areasFacetsSelector);
  const areas = generateFacetData(facetAreas);
  const [quickSelectAreas, setQuickSelectAreas] = useState<any>([]);
  const [quickSelectedValue, setQuickSelectedValue] = useState("");
  const showAllLocationsHandler = () => {
    setIsLocationsSelectVisible(true);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    if (areas && !quickSelectAreas.length) {
      setQuickSelectAreas(areas.slice(0, 3));
    }
  }, [areas, quickSelectAreas]);

  return (
    <div className={"location-filter-wrapper"}>
      <FilterFormTitle title={"Location"} />
      <div className={"location-filter-wrapper-search"}>
        <LocationSearch
          locations={areas}
          setLocation={setLocation}
          form={form}
          showAllLocationsHandler={showAllLocationsHandler}
          isLocationsSelectVisible={isLocationsSelectVisible}
          checkedLocationsArray={checkedLocationsArray}
          setCheckedLocationsArray={setCheckedLocationsArray}
          isClearAllClicked={isClearAllClicked}
          quickSelectedValue={quickSelectedValue}
        />
        {pathname !== "/landlords" ? (
          <p
            className={"location-filter-wrapper-search-seeAll"}
            onClick={showAllLocationsHandler}
          >
            See All
          </p>
        ) : (
          ""
        )}
      </div>

      <div className={"location-filter-wrapper-quickSelectWrapper"}>
        {quickSelectAreas.map((item: any, index: number) => {
          return (
            <span
              key={index}
              onClick={() => {
                createArrayWithSelectedItemsForMultiselect(
                  item,
                  setCheckedLocationsArray
                );
                setLocation([item.label]);
                form.submit();
                setQuickSelectedValue(item.label);
              }}
            >
              {`${item.label}${
                index !== quickSelectAreas.length - 1 ? "," : ""
              }`}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default memo(LocationFilter);
