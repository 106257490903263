import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { getAuthToken, getGuestAccessToken, setAccessToken, setGuestAccessToken } from "../helpers/authUtils";
import { push } from "connected-react-router";
import { getQueryParams } from "../helpers/globalFunctions";
import { useDispatch, useSelector } from "react-redux";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";

import SignInPage from "pages/signIn/SignIn";
import AuthRoute from "./Auth/AuthRoute";
import GuestRoute from "./Guest/GuestRoute";
import routes from "./routesCode";
import Landlords from "../pages/landlords";
import Listings from "../pages/listings";
import LandlordView from "../pages/landlordView";
import FollowUps from "../pages/followUps";
import ListingView from "../pages/listingView";
import Settings from "../pages/settings";
import Team from "../pages/teamMembers";
import TeamDashboard from "../pages/teamDashboard";
import ManageListing from "../pages/manageListing";
import ForceResetPassword from "../pages/forceResetPassword";
import ResetPassword from "../pages/resetPassword";
import PrivacyPolicy from "../pages/privacyPolicy";
import CreateTeamView from "../pages/CreateTeamView";
import NotFoundPage from "../pages/NotFound";
import Leads from "../components/Leads";
import SingleLeadPage from "../pages/Lead";
import RequirementAuthPage from "../pages/requirementAuth";
import RequirementFormPage from "../pages/requirementForm";
import LeadsSingleRequirement from "../pages/LeadsSingleRequirement";
import ListingAllPhotosMobile from "../components/ListingView/ListingImagesSection/listing-all-photos-mobile/ListingAllPhotosMobile";
// import LeadRequirements from '../pages/LeadRequirements';
import VerifyLead from "pages/VerifyLead";
import LeadAccount from "pages/LeadAccount";
import LeadAuthRoute from "./LeadAuth/LeadAuthRoute";
import SignUpPage from "../pages/sign-up/SignUpPage";
import { userSelector } from "../store/user/selectors";
import SuccessAgentView from "components/Requirement/requirement-form/components/success-agent-view";
import DummyRoute from "./Dummy/DummyRoute";
import ChangePassword from "pages/change-password/ChangePassword";
import AgentAllScreening from "components/TenantScreenings";
import ScreeningReports from "components/ScreeningReports";
import ClawCallback from "components/ClawCallback";
import ClawSAMLAuth from "components/ClawSAMLAuth";
import ClawCognitoLogout from "components/ClawCognitoLogout";
import ShareListing from "pages/shareListing";
import AuthApi from "api/Auth";
import { IObjectKeys } from "helpers/globalTypes";
import RenterPortalRoute from "./RenterPortal/RenterPortalRoute";
import RenterPortalDashboard from "../pages/RenterPortal/RenterPortalDashboard";
import RenterPortalPassport from "../pages/RenterPortal/RenterPortalPassport";
import RenterPortalSearch from "../pages/RenterPortal/RenterPortalSearch";
import RenterPortalAgents from "pages/RenterPortal/RenterPortalAgents";
import RenterPortalSettings from "components/RenterPortal/RenterPortalSettings";
import { AnalyticsService } from "helpers/analyticsService";
import { EXTERNAL_SOURCES, SOURCE_ENUM } from "helpers/constants";
import RenterPortalServices from "pages/RenterPortal/RenterPortalServices";
// import RenterPortalApplications from "pages/RenterPortal/RenterPortalApplications";
// import RenterPortalHomes from "pages/RenterPortal/RenterPortalHomes";

/* uncomment when need more performance */
// const Listings = lazy(() => import("pages/listings"));
// const ListingView = lazy(() => import("pages/listingView"));
// const FollowUps = lazy(() => import("pages/followUps"));
// const LandlordView = lazy(() => import("pages/landlordView"));
// const Settings = lazy(() => import("pages/settings"));
// const Landlords = lazy(() => import("pages/landlords"));
// const TeamDashboard = lazy(() => import("pages/teamDashboard"));
// const Team = lazy(() => import("pages/teamMembers"));

// Authorized pages start
// const HomePage = lazy(() => import('pages/home/Home'));

// Authorized pages end

const Routes: React.FC<any> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { search } = location;
  const { signInAsToken } = getQueryParams(search);
  const guestAccessToken = getGuestAccessToken();
  const accessToken = getAuthToken();
  const isAuthenticated = Boolean(!!accessToken);
  const user = useSelector(userSelector);

  const pageViewEvent = () => {
    // Breakup the URL
    const splitPath = window.location.pathname.split("/");
    let resourceId;
    // page_type is segment one and should be the resource category, ie. /listings, /leads, /landlords
    let page_type = splitPath[1];
    // "page type singular": slice off "s"
    const page_type_key = page_type.substring(page_type.length - 1) === "s" ? page_type.slice(0, -1).toLowerCase() : page_type;
    if (splitPath[2]) {
      // Dynamic page_type based on if the URL is for a resource
      page_type = `${page_type_key}_view`;
      // Kinda dirty but we can generally assume that segment 2 is the resource ID
      resourceId = splitPath[2];
    }
    let eventPayload = {
      page_type,
      user_email: user.email,
      user_phone: user?.phone,
      office_name: user?.office_name,
      user_source: user.source?.name ?? SOURCE_ENUM.RENTBASE,
      phone_verified: user?.phone_verified,
      email_verified: user?.account_verified,
    };
    if (resourceId) {
      eventPayload = {
        ...eventPayload,
        [`${page_type_key}_id`]: resourceId,
      };
    }
    AnalyticsService.reportPageViewEvent(eventPayload);
  };

  useEffect(() => {
    function checkForGuestAccessToken() {
      if (!guestAccessToken) {
        AuthApi.getGuestAccessToken()
          .then((resp: IObjectKeys) => {
            const { token } = resp;
            setGuestAccessToken(token || null);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    checkForGuestAccessToken();
  }, []);

  useEffect(() => {
    localStorage.setItem("textForBackTo", window.location.pathname);
    const urlParams = new URLSearchParams(window.location.search);
    const queryObject = urlQueryToObject(urlParams);
    if (queryObject.switch_team === "true") {
      localStorage.setItem("switchTeamSearchParams", window.location.search);
    }
    pageViewEvent();
  }, [location.pathname]);

  useEffect(() => {
    function checkForSignInAs() {
      if (signInAsToken) {
        setAccessToken(signInAsToken);
        dispatch(push(routes.listings.path));
      }
    }
    checkForSignInAs();
  }, [dispatch, signInAsToken]);

  return (
    <Switch>
      <AuthRoute exact path={routes.teams.path} component={Team} />
      <AuthRoute exact path={routes.leadView.path} component={SingleLeadPage} />
      <AuthRoute exact path={routes.createTeam.path} component={CreateTeamView} />
      {/* <AuthRoute
        exact
        //@ts-ignore
        path={routes?.teams?.children[0]?.path}
        component={TeamDashboard}
      /> */}
      <AuthRoute exact path={routes.home.path} component={Listings} />
      <AuthRoute exact path={routes.leads.path} component={Leads} />
      <AuthRoute exact path={routes.listings.path} component={Listings} />
      <AuthRoute exact path={routes.landlords.path} component={Landlords} />
      <AuthRoute exact path={routes.followUps.path} component={FollowUps} />
      {isAuthenticated && user.role !== "Renter" && <AuthRoute exact path={routes.listing.path} component={ListingView} />}
      {(!isAuthenticated || (isAuthenticated && user.role === "Renter")) && (
        <GuestRoute exact path={routes.listing.path} component={ListingView} />
      )}
      <AuthRoute exact path={routes.listingImages.path} component={ListingAllPhotosMobile} />
      <AuthRoute exact path={routes.settings.path} component={Settings} />
      <AuthRoute exact path={routes.privacyPolicyAuth.path} component={PrivacyPolicy} />
      <AuthRoute exact path={routes.manageListing.path} component={ManageListing} />
      <AuthRoute exact path={routes.landlordView.path} component={LandlordView} />
      <AuthRoute exact path={routes.requirementSuccess.path} component={SuccessAgentView} />
      {/* <AuthRoute exact path={routes.changePassword.path} component={ChangePassword} /> */}
      <AuthRoute exact path={routes.agentAllScreening.path} component={AgentAllScreening} />
      <AuthRoute exact path={routes.screeningReports.path} component={ScreeningReports} />

      <RenterPortalRoute exact path={routes.renterPortalScreeningReports.path} component={ScreeningReports} />
      <RenterPortalRoute exact path={routes.renterPortalChangePassword.path} component={ChangePassword} />
      <RenterPortalRoute exact path={routes.renterPortalDashboard.path} component={RenterPortalDashboard} />
      <RenterPortalRoute exact path={routes.renterPortalPassport.path} component={RenterPortalPassport} />
      <RenterPortalRoute exact path={routes.renterPortalSearch.path} component={RenterPortalSearch} />
      <RenterPortalRoute exact path={routes.renterPortalServices.path} component={RenterPortalServices} />
      {/* <RenterPortalRoute exact path={routes.renterPortalApplications.path} component={RenterPortalApplications} /> */}
      {/* <RenterPortalRoute exact path={routes.renterPortalHomes.path} component={RenterPortalHomes} /> */}
      <RenterPortalRoute exact path={routes.renterPortalAgents.path} component={RenterPortalAgents} />
      <RenterPortalRoute exact path={routes.renterPortalSettings.path} component={RenterPortalSettings} />

      <LeadAuthRoute exact path={routes.leadsSingleRequirement.path} component={LeadsSingleRequirement} />
      <LeadAuthRoute exact path={routes.leadAccount.path} component={LeadAccount} />

      <GuestRoute exact path={routes.changePassword.path} component={ChangePassword} />
      <GuestRoute exact path={routes.clawCognitoSAMLAuth.path} component={ClawSAMLAuth} />
      <GuestRoute exact path={routes.clawCognitoLogout.path} component={ClawCognitoLogout} />
      <GuestRoute exact path={routes.clawcallback.path} component={ClawCallback} />
      <GuestRoute exact path={routes.requirements.path} component={RequirementAuthPage} />
      <GuestRoute exact path={routes.requirementCreateUpdate.path} component={RequirementFormPage} />
      <GuestRoute exact path={routes.signIn.path} component={SignInPage} />
      <GuestRoute exact path={routes.google.path} component={SignInPage} />
      <GuestRoute exact path={routes.signUp.path} component={SignUpPage} />
      <GuestRoute exact path={routes.verifyLead.path} component={VerifyLead} />
      <GuestRoute exact path={routes.forceResetPassword.path} component={ForceResetPassword} />
      <GuestRoute exact path={routes.public.path} component={ListingView} />
      <GuestRoute exact path={routes.shareListing.path} component={ShareListing} />
      <GuestRoute exact path={routes.resetPassword.path} component={ResetPassword} />
      <GuestRoute exact path={routes.privacyPolicy.path} component={PrivacyPolicy} />

      <DummyRoute exact path={routes.demo.path} component={Leads} />
      <DummyRoute exact path={routes.demoListings.path} component={Listings} />
      <DummyRoute exact path={routes.demoLandlords.path} component={Landlords} />
      <DummyRoute exact path={routes.demoTeam.path} component={Team} />
      <DummyRoute exact path={routes.demoTeamDashboard.path} component={TeamDashboard} />
      <DummyRoute exact path={routes.demoFollowUps.path} component={FollowUps} />
      <DummyRoute exact path={routes.demoSignleLead.path} component={SingleLeadPage} />
      <DummyRoute exact path={routes.demoSingleLandlord.path} component={LandlordView} />
      <DummyRoute exact path={routes.demoSingleListing.path} component={ListingView} />
      <DummyRoute exact path={routes.demoPrivacyPolicy.path} component={PrivacyPolicy} />
      <Route path={"*"}>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default Routes;
