import { Input, List, Spin } from "antd";
import ListingApi from "api/Listing";
import { IObjectKeys } from "helpers/globalTypes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createShareablePropertyEntity } from "store/shareable/action";
import { shareableLandlordEntitySelector } from "store/shareable/selectors";
import "../LeadRequestScreeningModal.scss";

const AttachProperty = ({
  form,
  screeningData,
  setScreeningData,
}: {
  form: any;
  screeningData: IObjectKeys;
  setScreeningData: any;
}) => {
  const dispatch = useDispatch();
  const shareableLandlordEntity = useSelector(shareableLandlordEntitySelector);
  const [checkedListing, setCheckedListing] = useState({ id: "", address: "" });
  const [selectedValue, setSelectedValue] = useState(null);
  const [query, setQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [listings, setListings] = useState<any>(null);

  const SEARCH_RESULTS_MAX = 8;

  const handleClick = (id: any) => {
    const listing = listings.find((listing: any) => listing._id === id);
    setSelectedValue(listing?.addr);
    setCheckedListing({
      id: listing?._id,
      // @ts-ignore
      addressLine1: listing?.addr,
      locality: listing?.town,
      street_name: listing?.street_name,
      street_number: listing?.street_number,
      postalCode: listing?.zip_code,
      unit: listing?.unit,
      state: listing?.state,
    });
    setListings([]);
    dispatch(
      createShareablePropertyEntity({
        shareableLandlordId: shareableLandlordEntity.id,
        listingId: listing._id,
      })
    );
  };

  useEffect(() => {
    if (query?.length >= 3) {
      setIsSearching(true);
      setListings([]);
      const timeoutId = setTimeout(async () => {
        ListingApi.getListingsFromAlgolia({
          search: query,
          filter_by_agency: false,
          size: SEARCH_RESULTS_MAX,
          page: 1,
          isModalSearch: false,
          status: "Available",
        }).then((results: any) => {
          const { hits } = results.result;
          setListings(hits);
          setIsSearching(false);
        });
      }, 250);
      return () => clearTimeout(timeoutId);
    } else {
      setListings([]);
      setIsSearching(false);
    }
  }, [query]);

  useEffect(() => {
    setScreeningData({ ...screeningData, property_details: checkedListing });
    form.setFieldsValue(checkedListing);
  }, [checkedListing]);

  return (
    <React.Fragment>
      <Input placeholder="Search Listings" value={selectedValue || query} onChange={(e) => setQuery(e.target.value)} />
      <div className="listing-wrapper">
        {isSearching && <Spin className={"listing-spinner"} />}
        {listings?.length > 0 && (
          <List
            data-test-id=""
            dataSource={listings}
            renderItem={(e: any, i: any) => (
              <List.Item key={e._id} onClick={() => handleClick(e._id)}>
                {e.addr}
              </List.Item>
            )}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default AttachProperty;
