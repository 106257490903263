import { GET_FLATFILE_ENUMS } from "./types";
import { getAllFlatfileEnumsError, getAllFlatfileEnumsSuccess } from "./action";
import  EnumsApi  from "api/Enums";
import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import {
    generateErrorMessage,
    openNotification,
} from "../../helpers/globalFunctions";

function* getFlatfileEnumsSaga() {
    try {
        const response = yield call(EnumsApi.getFlatfileEnums);
        yield put(
            getAllFlatfileEnumsSuccess(response),
        );
    } catch (e) {
        yield put(getAllFlatfileEnumsError(e));
        openNotification("error", generateErrorMessage(e));
    }
}


function* watchgetFlatfileEnumsSaga() {
    yield takeLatest(GET_FLATFILE_ENUMS, getFlatfileEnumsSaga);
}


export default function* rootSaga() {
    yield all([fork(watchgetFlatfileEnumsSaga)]);
}
