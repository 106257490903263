import Api from "./api";
import { IObjectKeys } from "../helpers/globalTypes";

export default class RequirementApi {
  static getAgentInfo(leadId: string) {
    const api = new Api("requirements/agent");
    return api.get(leadId, {});
  }

  static createRequirement(requirementData: IObjectKeys) {
    const api = new Api("requirements");
    return api.create({ data: requirementData });
  }

  static inviteForLead(payload: IObjectKeys) {
    const api = new Api("requirements/inviteLead");
    return api.create({ data: payload });
  }

  static checkRenterStatus(requirementData: IObjectKeys) {
    const api = new Api("renter/check-renter-status");
    return api.create({ data: requirementData });
  }

  static sendPermissionRequest(requirementData: IObjectKeys) {
    const api = new Api(
      `requirements/request-for-access/${requirementData.requirements_id}`
    );
    return api.create({});
  }

  static getRentersOneRequirement(requirementData: IObjectKeys) {
    const api = new Api("requirements/renter/requirement");
    return api.get(requirementData.requirements_id, {});
  }

  static acceptForRequirement(requirementData: IObjectKeys) {
    const api = new Api(
      `requirements/accept-request/${requirementData.requirements_id}`
    );
    return api.create({
      data: { agent_id: requirementData.agent_id },
    });
  }

  static declineForRequirement(requirementData: IObjectKeys) {
    const api = new Api(
      `requirements/decline-request/${requirementData.requirements_id}`
    );
    return api.create({
      data: { agent_id: requirementData.agent_id },
    });
  }

  static revokeForRequirement(requirementData: IObjectKeys) {
    const api = new Api(
      `requirements/remove-agent/${requirementData.requirements_id}`
    );
    return api.create({
      data: { agent_id: requirementData.agent_id },
    });
  }

  static editRequirement(requirementData: IObjectKeys) {
    const api = new Api("requirements");
    return api.put(requirementData.requirements_id, {
      data: requirementData.requirement,
    });
  }

  static createRequirementDraft(requirementData: IObjectKeys) {
    const api = new Api("draft/renter_requirement");
    return api.create({ data: requirementData });
  }

  static updateRequirementDraft(requirementData: IObjectKeys) {
    const api = new Api("draft");
    return api.putDraftRenter("", { data: requirementData });
  }

  static getRequirementDraft() {
    const api = new Api("draft");
    return api.getDraft("", {});
  }

  static changeRenterStatus(data: IObjectKeys) {
    const api = new Api(`renter/${data.renterId}/rented-listing`);
    return api.put("", { data: data.search_active_data });
  }

  static notifyAgents(agents: Array<string>) {
    const api = new Api("renter/notify-agents-search-on");
    return api.create({ data: { agentUserIds: agents } });
  }
}
