import React from "react";

const FollowUpDeleteIcon = () =>{
    return(
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="47" height="47" rx="4.5" fill="white"/>
                <rect x="0.5" y="0.5" width="47" height="47" rx="4.5" fill="url(#paint0_linear)"/>
                <rect x="0.5" y="0.5" width="47" height="47" rx="4.5" stroke="#141414"/>
                <path
                    d="M30.6594 18.3407C27.5379 15.2198 22.4616 15.2198 19.3401 18.3407C16.22 21.4615 16.22 26.5393 19.3401 29.6602C20.9008 31.2203 22.9504 32 25.0001 32C27.0497 32 29.0987 31.2203 30.6594 29.6602C33.7802 26.5394 33.7802 21.4615 30.6594 18.3407ZM28.3009 26.3586C28.5617 26.6194 28.5617 27.0409 28.3009 27.3017C28.1709 27.4318 28.0001 27.4971 27.8294 27.4971C27.6586 27.4971 27.4879 27.4318 27.3578 27.3017L25.0001 24.9432L22.6429 27.301C22.5122 27.4311 22.3415 27.4965 22.1714 27.4965C22.0006 27.4965 21.8299 27.4311 21.6998 27.301C21.439 27.0402 21.439 26.618 21.6998 26.3579L24.0569 24.0001L21.6992 21.6423C21.4384 21.3815 21.4384 20.9593 21.6992 20.6992C21.9593 20.4384 22.3815 20.4384 22.6423 20.6992L25 23.057L27.3578 20.6992C27.6186 20.4384 28.0401 20.4384 28.3009 20.6992C28.5617 20.9593 28.5617 21.3815 28.3009 21.6423L25.9431 24.0001L28.3009 26.3586Z"
                    fill="#141414"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1" stopColor="white" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
    )
}

// @ts-ignore
export default FollowUpDeleteIcon