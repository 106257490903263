import queryString from "query-string";
import { EnumInterface, IObjectKeys } from "./globalTypes";
import { REQUIRED } from "./validations";
import { Checkbox, Col, Form, Input, notification, Popover, Radio, Row, Typography } from "antd";
import React, { ReactNode, useMemo } from "react";
import { ACTIONS, ENUM_TYPES, FULL_MONTHS_OF_YEAR, ROLE_TYPES, SHORT_MONTHS_OF_YEAR, TRIGGER_BUTTON_LABEL, US_TIME_FORMAT } from "./constants";
import { NotificationPlacement } from "antd/lib/notification";
import { NotificationI } from "../store/notifications/reducer";
import { HomeFilled, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import _, { isEqual } from "lodash";
import { SliderLeftArrowIcon, SliderRightArrowIcon } from "../components/icons";
import { Listing } from "../components/Listings/helpers/mockListings";
import qs from "qs";
import FormStepsTitle from "../components/Requirement/requirement-form/components/requirement-form-steps/components/form-steps-title/FormStepsTitle";
import { clearAllFuModal } from "../store/followUps/action";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { FormInstance } from "antd/es/form";
import { isListingRoute } from "./clientRoutes";
import { addTimeAndFormatDate, currentDate, formatDate, getStartOfUnitFormatted } from "./dateHelper";
import { US_DATE_FORMAT } from "./constants";
import BaseButton from "components/Shared/BaseButton";

const { Title } = Typography;
export const getQueryParams: Function = (query: string) => {
  return queryString.parse(query);
};
export const configureNotifications = (placement: NotificationPlacement, duration = 3, margin = { top: 90 }) => {
  return notification.config({
    placement,
    ...margin,
    duration,
    rtl: false,
  });
};

export const alphabeticalSort = (a: IObjectKeys, b: IObjectKeys) => {
  if (a.user_id?.name < b.user_id?.name) {
    return -1;
  }
  if (b.user_id?.name < a.user_id?.name) {
    return 1;
  }
  return 0;
};

export const nth = function (d: number) {
  if (d > 3 && d < 21) return d + "th";
  switch (d % 10) {
    case 1:
      return d + "st";
    case 2:
      return d + "nd";
    case 3:
      return d + "rd";
    default:
      return d + "th";
  }
};
export const getListingInput = (name: string, label: string, rules?: any, placeholder?: string, dataScrollId?: string, form?: string, isNumeric?: boolean) => {
  return (
    <Form.Item name={name} label={label} labelCol={{ span: 24 }} rules={rules} data-scroll-id={dataScrollId ?? ""} validateTrigger={form && form === "req" ? "onSubmit" : "onChange"}>
      <Input inputMode={isNumeric ? "numeric" : "text"} autoComplete={"off"} data-test-id={name} placeholder={placeholder ?? ""} className="rounded-lg" />
    </Form.Item>
  );
};

export const getFormTextarea = (name: string, label: string, rules?: any, placeholder?: string, rows?: number) => {
  return (
    <Form.Item name={name} label={label} labelCol={{ span: 24 }} rules={rules}>
      <Input.TextArea rows={rows ?? 4} data-test-id={name} placeholder={placeholder ?? ""} />
    </Form.Item>
  );
};

export const getBedroomsOrBathroomsRadioBtnGroup = (name: string, label: string, rules?: any, classnames?: string, radioBtnclassnames?: string, dataScrollId?: string, type?: string) => {
  return (
    <Form.Item name={name} label={label} rules={rules} className={classnames} data-test-id={name} data-scroll-id={dataScrollId ?? ""}>
      <Radio.Group className={radioBtnclassnames ? radioBtnclassnames : ""}>
        {name === "bedrooms" && (
          <Radio.Button data-test-id={"radio-button"} value={0}>
            Studio
          </Radio.Button>
        )}

        {(name === "listings_applied" || name === "listings_viewed") && (
          <Radio.Button data-test-id={"radio-button"} value={0}>
            0
          </Radio.Button>
        )}
        {_.range(5).map((item) => {
          let n = item;
          if (type !== "employed") {
            n = ++item;
          }
          return (
            <Radio.Button key={n} value={n} data-test-id={n}>
              {n}
            </Radio.Button>
          );
        })}
        <Radio.Button value={type == "employed" ? 5 : 6}>{type === "employed" ? "5+" : "6+"}</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

export const getChipCheckbox = (enumObj: EnumInterface, inlineOrColumn: string, radioGroupClassnames?: string, dataScrollId?: string, isReq?: boolean) => {
  if (!enumObj) return;
  const items = enumObj.options.map((opt: IObjectKeys) => Object.entries(opt));
  const getLabel = () => {
    if (enumObj.label === "Outdoor Space") {
      return "Outdoor Space Type";
    } else {
      return enumObj.label + (enumObj.key === "deal_breaker" ? "? (Select all that apply)" : " (Select all that apply)");
    }
  };
  return (
    <Form.Item
      labelCol={{ span: inlineOrColumn === "inline" ? 24 : 6 }}
      name={enumObj.key}
      label={inlineOrColumn === "inline" && enumObj.label}
      rules={enumObj.key === "storage" ? [REQUIRED] : [!enumObj.allow_null ? REQUIRED : { required: false }]}
      className={"chip-checkbox"}
      data-test-id={"chip-checkbox"}
      data-scroll-id={dataScrollId ?? ""}
    >
      <Checkbox.Group className={radioGroupClassnames ? radioGroupClassnames : ""}>
        {inlineOrColumn === "column" && (
          <Title className={"mt-4 pt-0"} level={5}>
            {getLabel()}
          </Title>
        )}
        <Row className="mt-6">
          {isReq
            ? items.slice(0, 8).map((item, i) => (
                <Col key={i}>
                  <div className="flex-between checkbox">
                    <Checkbox value={item[0][0]} data-test-id={item[0][1]}>
                      {firstLetterUppercaseOnly(item[0][1])}
                    </Checkbox>
                  </div>
                </Col>
              ))
            : items.map((checkboxItem, index) => (
                <Col key={index}>
                  <div className="flex-between checkbox">
                    <Checkbox value={checkboxItem[0][0]} data-test-id={checkboxItem[0][1]}>
                      {firstLetterUppercaseOnly(checkboxItem[0][1])}
                    </Checkbox>
                  </div>
                </Col>
              ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
};
export const getReqCommunicationEnumInputs = (enumObj: EnumInterface, inlineOrColumn: string, iconsArray?: any[], radiogroupClassnames?: string, dataScrollId?: string) => {
  if (!enumObj) return;
  const items = enumObj.options.map((opt: IObjectKeys) => Object.entries(opt));
  return (
    <div>
      <FormStepsTitle text={enumObj.label} classname={`${!enumObj.allow_null ? null : "required-not-visible"}`} />
      <Form.Item name={enumObj.key} rules={[!enumObj.allow_null ? REQUIRED : {}]} labelCol={{ span: 24 }} className={"chip-checkbox enum_input"} data-scroll-id={dataScrollId ?? ""}>
        <Radio.Group className={radiogroupClassnames ? radiogroupClassnames : ""}>
          {/*{inlineOrColumn === "column" && (*/}
          {/*)}*/}
          {items.map((item, i) => (
            <Radio key={i} value={item[0][0]}>
              <div key={i} className={"communication-radio-btn"}>
                {iconsArray ? iconsArray[i] : null}
                <span className={"communication-radio-btn-text"}>{item[0][1]}</span>
              </div>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};
export const getEnumInputs = (enumObj: EnumInterface, inlineOrColumn: string, radiogroupClassnames?: string, dataScrollId?: string) => {
  if (!enumObj) return;
  const items = enumObj.options.map((opt: IObjectKeys) => Object.entries(opt));
  if (enumObj.type === ENUM_TYPES.SINGLE) {
    return (
      <Form.Item name={enumObj.key} rules={[!enumObj.allow_null ? REQUIRED : {}]} label={inlineOrColumn === "inline" && enumObj.label} labelCol={{ span: 24 }} className={"chip-checkbox enum_input"} data-scroll-id={dataScrollId ?? ""}>
        <Radio.Group className={radiogroupClassnames ? radiogroupClassnames : ""}>
          {inlineOrColumn === "column" && <Title level={5}>{enumObj.label}</Title>}
          {items.map((item, i) => (
            <Row key={i}>
              <Col className={"text-left mobile-radio-button-col"} span={24}>
                <Radio key={i} value={item[0][0]}>
                  {item[0][1]}
                </Radio>
              </Col>
            </Row>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  } else if (enumObj.type === ENUM_TYPES.MULTIPLE) {
    return (
      <Form.Item labelCol={{ span: inlineOrColumn === "inline" ? 24 : 6 }} name={enumObj.key} label={inlineOrColumn === "inline" && enumObj.label} rules={[!enumObj.allow_null ? REQUIRED : { required: false }]} style={{ maxWidth: "450px" }}>
        <Checkbox.Group>
          {inlineOrColumn === "column" && <Title level={5}>{enumObj.label}</Title>}
          <Row className="mt-6">
            {items.map((item, i) => (
              <Col span={inlineOrColumn === "column" ? 12 : 24} style={{ textAlign: "left" }} key={i}>
                <div className="flex-between">
                  <Checkbox style={{ lineHeight: "32px" }} value={item[0][0]}>
                    {" "}
                    {item[0][1]}{" "}
                  </Checkbox>
                </div>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    );
  } else if (enumObj.type === ENUM_TYPES.BOOLEAN) {
    return (
      <Form.Item name={enumObj.key} rules={[!enumObj.allow_null ? REQUIRED : {}]}>
        <Radio.Group>
          {items.map((item, i) => (
            <Radio key={i} value={item[0][0]}>
              {item[0][1]}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  }
};

export const makeMultiEnumOption = (enumArr: string[]) => {
  const enumMultiObj: IObjectKeys = {};
  Array.isArray(enumArr) && enumArr.map((item) => (enumMultiObj[item] = true));
  return enumMultiObj;
};

export const parseMultiEnumOptions = (enumObj: IObjectKeys) => {
  return Object.keys(enumObj).filter((key) => enumObj[key]);
};

export const deleteUndefinedKeys = (obj: IObjectKeys) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
};

export const uppercaseEveryFirstLetter = (str: string = "") => {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(" ");
};

export const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const replace_WithSpaceAndCapitalizeFirstLetter = (s: string = "") => {
  let str = s.replaceAll("_", " ").replaceAll(",", ", ");
  str = uppercaseEveryFirstLetter(str);
  return str;
};

export const beautifiedKeyStyling = (str: any) => {
  if (str === true) return "Yes";
  if (str === false) return "No";
  if (!str?.length) return "NA";
  return str
    .split("_")
    .map((item: any) => capitalizeFirstLetter(item))
    .join(" ");
};

export const beautifiedKeyStylingListingsViewed = (str: string = "") => {
  const listViewed = {
    none_seen: "None",
    one_to_three: "1-3",
    four_to_six: "4-6",
    seven_to_nine: "7-9",
    ten_plus: "10+",
  };

  // @ts-ignore
  return listViewed[str];
};
export const replaceSnakeCaseToKebabCase = (str: string = "") => {
  return capitalizeFirstLetter(str).replaceAll("_", "-");
};

export const beautifiedKeyStylingDashAndUpper = (str: string) => {
  return str
    .split("-")
    .map((item) => item.toUpperCase())
    .join(" ");
};

export const beautifiedKeyStylingDashAndFirstUpper = (str: string) => {
  return str
    .split("-")
    .map((item) => {
      return item[0].toUpperCase() + item.slice(1);
    })
    .join("-");
};
export const getDifferenceOfTwoDates = (measurement: "years" | "months" | "weeks" | "days" | "hours" | "minutes" | "seconds", date1: Date, date2 = new Date()) => {
  const now = moment([+new Date(date2).getFullYear(), +new Date(date2).getMonth(), +new Date(date2).getDate()]);
  const expireDate = moment([+new Date(date1).getFullYear(), new Date(date1).getMonth(), +new Date(date1).getDate()]);
  return expireDate.diff(now, measurement);
};

export const getFirstDayOfMonthStrByFutureMonth = (futureMonth: number) => {
  const startOfNextMonth = moment().add(futureMonth, "M").startOf("month").format("MMMM");
  return `${startOfNextMonth} 1st`;
};

export const getFirstDayOfMonthByFutureMonth = (futureMonth?: number | string) => {
  let startOfNextMonth;
  if (futureMonth !== 0) {
    startOfNextMonth = getStartOfUnitFormatted(addTimeAndFormatDate(currentDate(), { amount: futureMonth, unit: "M" }), "month", US_DATE_FORMAT);
  } else {
    startOfNextMonth = currentDate();
  }
  return startOfNextMonth;
};

export const generateFullDate = (fullDate?: Date, displayTime: boolean = false, shortMonth: boolean = false) => {
  let availableDate = "",
    date = new Date(fullDate ?? "");
  const month = shortMonth ? SHORT_MONTHS_OF_YEAR[date.getMonth()] : FULL_MONTHS_OF_YEAR[date.getMonth()];
  availableDate += month + " " + nth(date.getDate()) + ", " + date.getFullYear();
  if (displayTime) {
    availableDate += " " + formatDate(date, `${US_TIME_FORMAT}`);
    return availableDate;
  }

  return availableDate;
};

export const openNotification = (type: string = "success", message: string | ReactNode, description?: string | ReactNode) => {
  const options = {
    message,
    description,
  };
  if (type === "success") {
    return notification.success(options);
  } else {
    return notification.error(options);
  }
};

export const generateErrorMessage = (message: string | IObjectKeys) => {
  if (typeof message === "string") return message;
  return Object.keys(message)
    .map((key) => {
      return `${beautifiedKeyStyling(key)} :: ${message[key]}`;
    })
    .join(", ");
};

export const makeArrStringToObj = (arrString: string[]) => {
  const obj: IObjectKeys = {};
  if (arrString) {
    let _ =
      arrString.map((item) => {
        obj[item] = true;
      }) || {};
  }
  return obj;
};

export const getAddressComponentByType = (place: IObjectKeys, fieldName: string, longOrShort: string = "long_name"): undefined | IObjectKeys => {
  const townCatchingWords = ["locality", "sublocality", "sublocality_level_1"];
  const adr_comp = place?.address_components?.find((adr_comp: IObjectKeys) => {
    if (fieldName === "locality") {
      return adr_comp.types.find((item: string) => {
        return townCatchingWords.includes(item);
      });
    }
    return adr_comp.types.indexOf(fieldName) > -1;
  });
  if (adr_comp) {
    return adr_comp[longOrShort];
  }
  return undefined;
};

export const sanitizePhoneNumber = (str: string) => str.replace(/[^0-9]/g, "");

export function timeDifference(givenTime: Date) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number: number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num: number) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export const titleCase = (str: string) => {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const getNotificationIconByType = (notification: NotificationI) => {
  if (notification.notifiable_type === "landlord_note") {
    return <UserOutlined />;
  } else if (notification.notifiable_type === "listing_note") {
    return <HomeFilled />;
  }
};

function isObject(object: any) {
  return object != null && typeof object === "object";
}

export function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function deepEqual(object1: IObjectKeys, object2: IObjectKeys) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!isEqual(val1.sort(), val2.sort())) {
        return false;
      }
    } else {
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !isEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }
  }

  return true;
}

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SliderRightArrowIcon />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SliderLeftArrowIcon />
    </div>
  );
}

export const listingCardSliderSettings = {
  arrows: true,
  dots: false,
  effects: "fade",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

export function phoneMaskOnString(phoneNumber: string) {
  if (!phoneNumber) return "";
  const x: RegExpMatchArray | null = phoneNumber.replace(/\D/g, "").match(/(\d{3})(\d{3})(\d{4})/);
  return "(" + x?.[1] + ") " + x?.[2] + "-" + x?.[3];
}

export function firstLetterUppercaseOnly(text: string) {
  if (text.includes("Outdoor space")) {
    const chunks = text.split("(");
    return (
      <>
        {chunks[0]} <br /> ({chunks[1]}
      </>
    );
  }
  if (text !== "A/C") {
    return text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
  } else return text;
}

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }: any) => <span {...props}>{children}</span>;

export const SLIDER_SETTINGS = {
  nextArrow: (
    <SlickButtonFix>
      <SliderRightArrowIcon />
    </SlickButtonFix>
  ),
  prevArrow: (
    <SlickButtonFix>
      <SliderLeftArrowIcon />
    </SlickButtonFix>
  ),
  arrows: true,
  effects: "fade",
  dots: false,
};

export const stringToHslColor = (str: string, s: any, l: any) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};

const generateSubAreasPart = (listItem: Listing) => {
  let sub_areas_part = "";
  if (listItem.sub_area) {
    sub_areas_part += " (";
    sub_areas_part += `${listItem.sub_area}`;
    sub_areas_part += listItem.sub_sub_area ? ` - ${listItem.sub_sub_area}` : "";
    sub_areas_part += ")";
  }
  return sub_areas_part;
};

export const generateFullAddress = (listItem: Listing, breakAddress?: boolean) => {
  if (!listItem?.street_name && !listItem?.street_number && !listItem.town && !listItem.state && listItem.addr) {
    return listItem.addr;
  }
  /*Don't break string it always must be in one line */
  return `${listItem.street_number ?? ""} ${listItem.street_name && listItem.unit ? `${listItem.street_name}` : listItem.street_name && !listItem.unit ? `${listItem.street_name},` : ""} ${listItem.unit ? `#${listItem.unit},` : ""} ${
    breakAddress ? "\n" : ""
  } ${listItem.town ?? ""} ${listItem.state ?? ""} ${listItem.sub_area ? generateSubAreasPart(listItem) : ""} ${listItem.zip_code ?? ""}`;
};

export const generateFullAddressHTML = (listItem: Listing, breakAddress?: boolean) => {
  if (!listItem?.street_name && !listItem?.street_number && !listItem.town && !listItem.state && listItem.addr) {
    return listItem.addr;
  }
  /*Don't break string it always must be in one line */
  return (
    <span>
      <strong>
        {listItem.street_number ?? ""} {listItem.street_name && listItem.unit ? `${listItem.street_name}` : listItem.street_name && !listItem.unit ? `${listItem.street_name},` : ""}
      </strong>
      {listItem.unit ? `#${listItem.unit},` : ""} {breakAddress ? "\n" : ""} {listItem.town ?? ""} {listItem.state ?? ""} {listItem.sub_area ? generateSubAreasPart(listItem) : ""}
      {
        // @ts-ignore
        listItem?.zip_code?.replaceAll(", USA", "").replaceAll("Massachusetts", "") ?? ""
      }
    </span>
  );
};
export const httpMethodNameTo = (str: string) => {
  switch (str) {
    case "POST":
      return "Create";

    case "PUT":
      return "Edit";

    case "DELETE":
      return "Delete";

    default:
      return "";
  }
};

export const responseToEntityNameUi = (original_url: string) => {
  if (original_url.includes("notification") || original_url.includes("note")) {
    return "Note";
  }
  if (original_url.includes("notification") || original_url.includes("followUp")) {
    return "Follow-up";
  }
  if (original_url.includes("landlord")) {
    return "Landlord";
  }
  if (original_url.includes("listing")) {
    return "Listing";
  }

  if (original_url.includes("landlord")) {
    return "Landlord";
  }

  if (original_url.includes("users")) {
    return "Users";
  }
  if (original_url.includes("requirement")) {
    return "Requirement";
  }
};

export const isOwner = (userData: any) => {
  return userData && (userData?.role?.toLowerCase() === ROLE_TYPES.OWNER.toLowerCase() || userData?.role?.toLowerCase() === ROLE_TYPES.ADMIN.toLowerCase());
};

export const numberFormatter = (num: number, digits = 3) => {
  const value = Number(num);
  const lookup = [
    { value: 1, symbol: "" },
    // { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return value >= item.value;
    });
  return item ? (value / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

export const LandlordNameWrapper = ({
  landlordOfList,
  handleLandlordLinkClick,
  setCheckedListing,
  listItem,
  isListingOwner,
  icon,
}: {
  landlordOfList: IObjectKeys | undefined;
  handleLandlordLinkClick: Function | undefined;
  setCheckedListing: Function | undefined;
  listItem: Listing;
  isListingOwner: boolean;
  icon?: any;
}) => {
  return (
    <BaseButton
      variant="link"
      onClick={(e) => {
        e.stopPropagation();
        handleLandlordLinkClick && handleLandlordLinkClick(landlordOfList ?? {}, listItem ?? {});
        setCheckedListing && setCheckedListing();
      }}
    >
      <span className="flex gap-1 items-center">
        {landlordOfList?.name && icon && <span className="mr-1">{icon}</span>}
        {landlordOfList?.name ? landlordOfList?.name : isListingOwner ? "Add a Landlord" : ""}
      </span>
    </BaseButton>
  );
};

export const getIsLastOneWeek = (date: any) => {
  if (date) {
    const date1 = new Date(date);
    const date2 = new Date();
    const difference = date1.getTime() - date2.getTime();
    const days = Math.ceil(difference / (1000 * 3600 * 24));
    return days <= 7;
  }
};
export const customDot = (dot: any, { index, title }: { index: any; title: any }) => (
  <Popover
    getPopupContainer={() => {
      return document.querySelector(`[data-id=step_${index}]`) as HTMLElement;
    }}
    trigger={"hover"}
    overlayClassName={"create-listing-steps-popover-wrapper"}
    content={<span className={"create-listing-steps-popover-title-wrapper"}>{title}</span>}
  >
    <span>{index + 1}</span>
  </Popover>
);

export const getDataFromLocalStorageByKey = (key = "") => {
  let json = localStorage.getItem(key);
  let obj;
  if (json) {
    obj = JSON.parse(json);
  } else {
    obj = {};
  }

  return {
    obj,
    json,
  };
};

export const divideToChunks = (arr: any[], chunkSize: number) => {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
};

export const createURL = (state: IObjectKeys) => `?${qs.stringify(state)}`;

export const searchStateToUrl = (pathname: string, searchState: IObjectKeys) => (searchState ? `${pathname}${createURL(searchState)}` : "");

export const urlToSearchState = (url: string) => qs.parse(url.slice(1));

export const scrollToFirstErrorField = () => {
  const input: HTMLDivElement | null = document.querySelector(".ant-form-item-has-error");
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  input?.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "nearest",
  });
};
export const scrollToTop = (domElement?: HTMLElement | null) => {
  const layout = domElement ?? document.querySelector(".page-content-layout");
  setTimeout(() => {
    if (layout) layout.scrollTop = 0;
  }, 500);
};

export const getRadioBtnGroup = (name: string, label: string, rules?: any, classnames?: string, radioBtnclassnames?: string, options?: Array<IObjectKeys>, dataScrollId?: string) => {
  return (
    <Form.Item name={name} label={label} rules={rules} className={classnames} data-test-id={name} data-scroll-id={dataScrollId}>
      <Radio.Group className={radioBtnclassnames ? radioBtnclassnames : ""}>
        {options?.map((option: IObjectKeys, index) => {
          const optionEntries = Object.entries(option)[0];
          if (optionEntries[0] !== "none_seen") {
            return (
              <Radio.Button data-test-id={"radio-button"} value={optionEntries[0]} key={index}>
                {optionEntries[1]}
              </Radio.Button>
            );
          } else {
            return (
              <Radio.Button data-test-id={"radio-button"} value={optionEntries[0]} key={index}>
                {0}
              </Radio.Button>
            );
          }
        })}
      </Radio.Group>
    </Form.Item>
  );
};

export const urlValidator = (url: string) => {
  const regexp = new RegExp(
    "^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$",
    "i"
  );
  return regexp.test(url);
};

export const scrollToTheElement = (domElement?: HTMLElement | null, afterTime: number = 500, block: any = "center") => {
  const layout = domElement ?? document.querySelector(".page-content-layout");
  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    layout?.scrollIntoView({ behavior: "smooth", block: block });
  }, afterTime);
};

export const getDateWithWords = (date: string) => formatDate(date, "MMMM Do , YYYY");

export const sanitizeBudgetNumber = (str: string) => {
  return str.replace(/\,/g, ".").replace(/k/gi, "000").replace(/\D/gi, "");
};

export const getListingsViewType = (): "list" | "grid" => {
  const type = localStorage.getItem("listingViewType");
  if (type === "grid" || type === "list") {
    return type;
  } else {
    localStorage.setItem("listingViewType", "grid");
    return "grid";
  }
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>!~#%$?"'=`^&*()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const beautifyLinkToText = (link: string) => {
  let textWithSLetter = link.split("/", 2).join("/").replace("/", "");
  if (window.location.pathname.includes("/demo")) return "Landlords";
  return textWithSLetter.charAt(0).toUpperCase() + textWithSLetter.slice(1);
};

export const generateAvailabilityDate = (date: any) => {
  const today = new Date().getTime();
  const checkDate = new Date(date).getTime();
  if (today <= checkDate) {
    return date;
  } else return " Now";
};

export const generateDateToDisplay = (listingAvailabilityStatus: any, dateToDisplay: any) => {
  if (listingAvailabilityStatus === "Available " && dateToDisplay) {
    if (generateAvailabilityDate(dateToDisplay) === " Now") return formatDate(dateToDisplay);
    else return ` ${formatDate(dateToDisplay)}`;
  } else if (listingAvailabilityStatus !== "Available " && dateToDisplay) {
    return ` ${formatDate(dateToDisplay)}`;
  } else return "";
};

export const trimString = (string: string) => string.trim().replaceAll("\t", "");

export const getBrowser = () => {
  if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "YYYY-MM-DD HH:mm:ss";
  } else if (navigator.userAgent.indexOf("Opera") != -1) {
    return "YYYY-MM-DD HH:mm:ss";
  } else if (navigator.userAgent.indexOf("MSIE") != -1) {
    return "YYYY-MM-DD HH:mm:ss";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "YYYY-MM-DD HH:mm:ss";
  } else if (navigator.userAgent.indexOf("Safari")) {
    return "YYYY/MM/DD HH:mm:ss";
  } else {
    return "unknown";
  }
};

export const getBrowserDateFormat = () => {
  if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "YYYY-MM-DD";
  } else if (navigator.userAgent.indexOf("Opera") != -1) {
    return "YYYY-MM-DD";
  } else if (navigator.userAgent.indexOf("MSIE") != -1) {
    return "YYYY-MM-DD";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "YYYY-MM-DD";
  } else if (navigator.userAgent.indexOf("Safari")) {
    return "YYYY/MM/DD";
  } else {
    return "unknown";
  }
};
export const getBrowserDateBeutifiedFormat = () => {
  if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "MM-DD-YYYY";
  } else if (navigator.userAgent.indexOf("Opera") != -1) {
    return "MM-DD-YYYY";
  } else if (navigator.userAgent.indexOf("MSIE") != -1) {
    return "MM-DD-YYYY";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "MM-DD-YYYY";
  } else if (navigator.userAgent.indexOf("Safari")) {
    return "MM/DD/YYYY";
  } else {
    return "unknown";
  }
};

export const getSignUpVerificationFlowStep = () => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const step = searchParams.get("step");

  switch (step?.toString()) {
    case "1":
      return "verify_your_phone_page";
    case "2":
      return "more_information_page";
    default:
      return "";
  }
};

export const errorArrayToString = (arr: [any]) => {
  return arr.map((item) => `${item.name} field: ${item.errors.join(", ")}`).join(" | ");
};

// EXAMPLE: Move-in & Location => move-in_&_location
export const removeSpaceAndCapitalizationFromString = (s: string | undefined) => {
  if (!s) {
    return undefined;
  }

  return s.replaceAll(" ", "_").toLowerCase();
};

// EXAMPLE: backButton => back_button
export const camelCaseToLowerCaseWith_ = (s: string | undefined) => {
  if (!s) {
    return undefined;
  }

  return s
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
};
interface IFollowUpModalsHandler {
  setFollowUpModalProps: any;
  setAllFollowUpsModalVisible: any;
  editedFollowUp: any;
  dispatch: any;
}
export const followUpModalsHandler = (props: IFollowUpModalsHandler) => {
  props.setFollowUpModalProps({ visible: false, editModal: false });
  if (props.editedFollowUp.todo_status === "completed") {
    props.setAllFollowUpsModalVisible({ visible: false, editModal: false });
    props.dispatch(clearAllFuModal(props.editedFollowUp));
  } else {
    setTimeout(() => {
      props.setAllFollowUpsModalVisible({ visible: true, editModal: false });
    }, 300);
  }
};

export const manuallyAddingLeadHandler = (dispatch: Dispatch<any>, userData: IObjectKeys) => {
  if (userData.agent_id) {
    return dispatch(push(`/requirement/create/${userData.agent_id}?reqStep=1&is_manual=true`));
  }
};

export const getTextForPremium = (type: string, modalType: string) => {
  const text: IObjectKeys = {
    statusModal: {
      follow_up: " Add Follow-Up",
      lead: "Add Lead",
      listing_renewals: "Get renewal notifications",
      default: "Get Started",
    },
    premiumModal: {
      follow_up: {
        title: "Add follow-ups. Turn leads into lifelong clients.",
        description: "Whether it’s renewals, following up about a listing, or remembering a birthday, you can add any upcoming task or deadline and receive automated notifications when it's time to take an action.",
      },
      lead: {
        title: "Automate qualification. Capture higher quality leads.",
        description: "Save 10-15 hours a week with the magic link to qualify and match new leads. Filter leads by quality, relevance, and potential sales. Discover and nurture future deals with automated follow-ups and notifications.",
      },
      listing_renewals: {
        title: "Get lease renewal notifications. Close more deals. ",
        description: "Convert rentals into additional rentals and sales with automated notifications each time a lease renewal date approaches. ",
      },
      default: {
        title: "Streamline your workflow. Scale your business.",
        description: "Save 10-15 hours a week with the magic link to qualify and match leads for you. Discover future deals through automated follow-ups, dashboards and advanced CRM tools. ",
      },
    },
  };

  if (type?.includes("follow_up")) {
    return text[modalType].follow_up;
  } else if (type?.includes("lead")) {
    return text[modalType].lead;
  } else if (type?.includes("listing_renewals")) {
    return text[modalType].listing_renewals;
  } else {
    return text[modalType].default;
  }
};

export const saveLink = (type: string, id?: string) => {
  if (type.includes("lead")) {
    localStorage.setItem("link", JSON.stringify({ path: "/", action: ACTIONS.ADD_LEAD }));
  } else if (type === TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_ALL_FOLLOW_UPS_MODAL) {
    localStorage.setItem(
      "link",
      JSON.stringify({
        path: window.location.pathname,
        action: ACTIONS.ADD_INDIVIDUAL_FOLLOW_UP,
      })
    );
  } else if (type === TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_AFTER_CALL_MODAL) {
    const { pathname } = window.location;
    const path = pathname.includes("listings") || pathname.includes("landlords") ? "/landlords" : "leads";
    localStorage.setItem(
      "link",
      JSON.stringify({
        path: path,
        action: ACTIONS.ADD_INDIVIDUAL_FOLLOW_UP,
        id: id,
      })
    );
  } else if (type.includes("follow_up")) {
    localStorage.setItem("link", JSON.stringify({ path: "/followUps", action: ACTIONS.ADD_FOLLOW_UP }));
  } else if (type === TRIGGER_BUTTON_LABEL.LISTING_RENEWALS) {
    localStorage.setItem("link", JSON.stringify({ path: "/settings", action: ACTIONS.LISTING_RENEWALS }));
  } else if (type === TRIGGER_BUTTON_LABEL.UPGRADE_NOW_BILLING) {
    localStorage.setItem("link", JSON.stringify({ path: "/settings", action: ACTIONS.UPGRADE_NOW_BILLING }));
  } else if (type === "expiration_message") {
    localStorage.removeItem("link");
  }
};

export const generateLink = () => {
  const data = JSON.parse(localStorage.getItem("link") as string);
  if (data) {
    if (data.id) {
      return data.path + "/" + data.id + "?action=" + data.action;
    }
    return data.path + "?action=" + data.action;
  }
  return "/";
};
export const FiltersCheckboxGroup = (items: any, name: string, form: FormInstance, setIsOtherSelected?: React.Dispatch<React.SetStateAction<boolean>>, isOtherSelected?: boolean, classnames?: string, setIsQuickOptionsSelected?: any) => {
  return useMemo(() => {
    return (
      <>
        {items.map((item: any, i: number) => {
          return (
            <div className={`${classnames}-checkbox ${i > 2 ? `${classnames}-checkbox12` : ""}`} key={i}>
              <Checkbox
                value={
                  new Date(
                    moment()
                      .add(i + 1, "M")
                      .startOf("month")
                      .format(getBrowserDateFormat())
                  )
                }
                onClick={() => {
                  setIsOtherSelected && setIsOtherSelected(false);
                  setIsQuickOptionsSelected && setIsQuickOptionsSelected(true);
                  form.submit();
                }}
              >
                {getFirstDayOfMonthStrByFutureMonth(i + 1)}
              </Checkbox>
            </div>
          );
        })}
      </>
    );
  }, []);
};
export const multiSelectDateCheckboxes = (
  items: any,
  name: string,
  form: FormInstance<any>,
  setIsOtherSelected: React.Dispatch<React.SetStateAction<boolean>>,
  isOtherSelected: boolean,
  classnames?: string,
  setIsQuickOptionsSelected?: any
) => {
  return (
    <Form.Item name={name} className={classnames} data-test-id={"chip-checkbox"}>
      <Checkbox.Group>
        {FiltersCheckboxGroup(items, name, form, setIsOtherSelected, isOtherSelected, classnames, setIsQuickOptionsSelected)}
        <button
          type={"button"}
          className={isOtherSelected ? `${classnames}-checkbox-btn-active` : ""}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsOtherSelected((prevState) => !prevState);
          }}
        >
          Other
        </button>
      </Checkbox.Group>
    </Form.Item>
  );
};

export const setUserPropertiesForAnalytics = (role: string | undefined, id: string | undefined, agency: any | undefined) => {
  if (role && id) {
    localStorage.setItem("user_role", role);
    localStorage.setItem("user_id", id);
    if (agency) {
      localStorage.setItem("agency_id", agency.agency_id);
      localStorage.setItem("plan", agency.subscription_level);
    }
    return true;
  }
  return false;
};

export const detectSearchbarPlaceholder = (path: string) => {
  let returnValue = "";
  if (isListingRoute(path)) {
    returnValue = "Search Listings";
  } else if (path.includes("/landlord/")) {
    returnValue = "Search Landlords";
  } else {
    switch (path) {
      case "/listings":
      case "/":
        returnValue = "Search Listings";
        break;
      case "/landlords":
        returnValue = "Search Landlords";
        break;
      case "/followUps":
        returnValue = "Search Follow-ups";
        break;
      case "/teamsMembers":
        returnValue = "Search Team Members";
        break;
      case "/leads":
        returnValue = "Search Leads";
        break;
      default:
        returnValue = "Search";
        break;
    }
  }
  return returnValue;
};

export const MultiSelectTagsCheckboxes = (items: any, name: string, form: FormInstance, classnames?: string) => {
  return useMemo(() => {
    return (
      <>
        <Form.Item name={name} className={classnames}>
          <Checkbox.Group>
            {items.map((item: any, i: number) => {
              return (
                <div className={`${classnames}-checkbox`} key={i}>
                  <Checkbox
                    value={item.name}
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    {item.label}
                  </Checkbox>
                </div>
              );
            })}
          </Checkbox.Group>
        </Form.Item>
      </>
    );
  }, []);
};

export const getSrRenterIdFromQuery = (query: string) => {
  if (query) {
    const _newURLSearchParams = new URLSearchParams(query);
    const obj: any = Object.fromEntries(_newURLSearchParams);
    if (obj?.completed && obj?.reportavailable && obj?.srrid) {
      return obj.srrid;
    }
    return null;
  }
};

export const usersFreeSubscriptionLimits = ({ userData, isNotPremiumUser }: any) => {
  if (!userData.agency) return null;
  const { free_subscription_limits } = userData.agency;
  const landlords = isNotPremiumUser && !Boolean(free_subscription_limits.landlords > 0);
  const listings = isNotPremiumUser && !Boolean(free_subscription_limits.listings > 0);
  return { landlords, listings };
};

export const renderNeighborhood = (listing: any) => {
  if (!listing.neighbourhood && !listing.neighborhood) return null;
  const hood = listing.neighbourhood || listing.neighborhood;
  if (hood.toLocaleLowerCase() === listing.town.toLocaleLowerCase()) return null;

  return <div className="bg-purple-100 rounded-full inline-block text-xs px-3 py-1">{_.startCase(hood.toLowerCase())}</div>;
};
