import React from "react";
import LeadIcon from "../icons/LeadIcon";
import "./RenterNameWrapper.scss";

const RenterNameWrapper = ({ name, prefix }: { name: string, prefix?: string; }) => {
    return (
        <div className={"lead-name-wrapper"}>
            {prefix && prefix}
            <LeadIcon />
            <span>{name}</span>
        </div>
    );
};

export default RenterNameWrapper;
