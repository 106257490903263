import React, { useEffect } from "react";
import { useState } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const SearchComboBox = ({ defaultQuery, defaultSuggestions, onChange, onReset }: any) => {
  const [query, setQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    setQuery(defaultQuery);
  }, [defaultQuery]);

  const handleOnChange = (category: string) => {
    setSelectedCategory(category);
    setQuery(category);
    onChange(category);
  };

  const handleReset = () => {
    setQuery("");
    setSelectedCategory("");
    onReset();
  };

  return (
    <Combobox as="div" value={selectedCategory} onChange={handleOnChange}>
      <div className="relative">
        <Combobox.Button className="w-full">
          <Combobox.Input
            className="relative block w-full rounded-l-full border-0 p-4 pl-12 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 text-xl sm:leading-6"
            onChange={(event) => handleOnChange(event.target.value)}
            value={query || ""}
            displayValue={(selectedCategory: string) => selectedCategory}
            placeholder="Example: Plumber or Dog Walker"
          />
        </Combobox.Button>
        <div className="absolute inset-y-0 left-2 flex items-center rounded-r-md px-2 focus:outline-none">
          {selectedCategory?.length ? (
            <div
              onClick={() => {
                handleReset();
              }}
            >
              <XMarkIcon className="h-6 w-6 text-purple-400 hover:cursor-pointer" aria-hidden="true" />
            </div>
          ) : (
            <MagnifyingGlassIcon className="h-6 w-6 text-purple-400" aria-hidden="true" />
          )}
        </div>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>
        {defaultSuggestions?.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
            {defaultSuggestions?.map((cat: any) => (
              <Combobox.Option key={cat.category} value={cat.category} className={({ active }) => classNames("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-purple-600 text-white cursor-pointer" : "text-gray-900")}>
                {({ selected }) => (
                  <>
                    <span className={classNames("block truncate", selected && "font-semibold")}>{cat.category}</span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default SearchComboBox;
