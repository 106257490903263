import React from "react";
import EmptyPageAddOrImportSelect from "../EmptyPageAddOrImportSelect/EmptyPageAddOrImportSelect";
import EmptyPageListingHeader from "./empty-page-listing-header/EmptyPageListingHeader";
import { useLocation } from "react-router";

const EmptyListingWrapper = ({
  initialState,
  setIsActionSelected,
  setSelectedPage,
  isEmptyState,
  isFromDashboard,
}: {
  initialState: any;
  setIsActionSelected?: any;
  setSelectedPage?: any;
  isEmptyState?: boolean;
  isFromDashboard?: boolean;
}) => {
  const location = useLocation();

  return (
    <div className={"empty-page-listing-wrapper empty-state-landlord-wrapper"}>
      <EmptyPageListingHeader isEmptyState={isEmptyState} />
      {
        <EmptyPageAddOrImportSelect
          setIsSingle={(e: boolean | any) => {
            return e;
          }}
          text={"listing"}
        />
      }
      {!location.pathname.includes("listings")}
    </div>
  );
};

export default EmptyListingWrapper;
