import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { Dropdown } from "antd";
import BaseButton from "../Shared/BaseButton";
import { TodoStatusEnum } from "./types";
import EditFollowUp from "./EditFollowUp";
import { useDispatch, useSelector } from "react-redux";
import { EditIcon } from "../icons";
import { SnoozeOptions } from "./SnoozeFollowUp";
import ArchiveFollowUpModal from "./ArchiveFollowUpModal";
import { deleteFollowUpSuccessSelector } from "../../store/followUps/selectors";
import { Document } from "@contentful/rich-text-types";
import { IObjectKeys } from "../../helpers/globalTypes";
import { getDeleteNoteConfirmContent } from "../../contentful/api";
import { editFollowUp } from "../../store/followUps/action";
import { CrossDeleteIcon } from "components/icons/CrossDelete";
import { useDemoContext } from "libs/contextLib";
import { currentDate, formatDate } from "../../helpers/dateHelper";

interface IFollowUpsActions {
  handleEdit: Function;
  handleDelete: Function;
  followUp: any;
  queryAndPagination: any;
  setUpdates: React.Dispatch<any>;
  isFromLandlordPage?: boolean;
}

const FollowUpsActions = (props: IFollowUpsActions) => {
  const [loading, setLoading] = useState(false);
  const [archiveFollowUpModalVisible, setArchiveFollowUpModalVisible] = useState(false);
  const [dropdownVisibility, setDropdownVisibility] = useState({
    edit: false,
    snooze: false,
  });
  const dispatch = useDispatch();
  const [deleteConfirmContent, setDeleteConfirmContent] = useState<Document | undefined>(undefined);
  const deleteFollowUpSuccess = useSelector(deleteFollowUpSuccessSelector);
  const { isDemo } = useDemoContext();

  useEffect(() => {
    if (deleteFollowUpSuccess) {
      setArchiveFollowUpModalVisible(false);
    }
  }, [deleteFollowUpSuccess]);

  useEffect(() => {
    (async () => {
      const entries: IObjectKeys = await getDeleteNoteConfirmContent();
      if (entries) {
        setDeleteConfirmContent(entries.fields.modalDescription);
      }
    })();
  }, []);

  const handleDelete = () => {
    if (props.isFromLandlordPage) {
      props.handleDelete();
    } else {
      setArchiveFollowUpModalVisible((prevState) => !prevState);
    }
  };

  const deleteFollowUp = () => {
    props.setUpdates({
      ...props.followUp,
      archive: true,
      queryAndPagination: props.queryAndPagination,
    });
    // dispatch(
    //   deleteFollowUp({
    //     ...props.followUp,
    //     archive: true,
    //     queryAndPagination: props.queryAndPagination,
    //   }),
    // );
  };

  const markAsDoneHandler = () => {
    setLoading(true);
    props.setUpdates({
      ...props.followUp,
      todo_status: TodoStatusEnum.completed,
      queryAndPagination: props.queryAndPagination,
    });
    dispatch(
      editFollowUp(
        {
          ...props.followUp,
          todo_at: null,
          todo_status: TodoStatusEnum.completed,
        },
        props.followUp._id
      )
    );
  };

  return (
    <div className={"flex justify-end gap-1"}>
      {props.followUp?.todo_status !== "completed" &&
        (props.isFromLandlordPage ? (
          <BaseButton
            dataTestId={"edit-followUp-btn"}
            onClick={() => props.handleEdit}
            classnames={"follow-ups-btn-icon mr-10"}
          >
            <EditIcon />
          </BaseButton>
        ) : (
          <Dropdown
            getPopupContainer={() => {
              return document.getElementById(`edit-follow-up-btn-${props.followUp._id}`) as HTMLElement;
            }}
            disabled={isDemo}
            trigger={["click"]}
            overlayClassName={"follow-ups-dropdown"}
            placement="bottomCenter"
            overlay={
              <EditFollowUp
                setUpdates={props.setUpdates}
                followUp={props.followUp}
                setDropdownVisibility={setDropdownVisibility}
                queryAndPagination={props.queryAndPagination}
              />
            }
          >
            <div id={`edit-follow-up-btn-${props.followUp._id}`}>
              <BaseButton
                dataTestId={"edit-followUp-btn"}
                classnames={`follow-ups-btn-icon mr-5 `}
                modalType="followUp"
                onClick={() => {
                  setDropdownVisibility((prevState: any) => {
                    return {
                      ...prevState,
                      snooze: false,
                      edit: !prevState.edit,
                    };
                  });
                }}
              >
                <EditIcon />
              </BaseButton>
            </div>
          </Dropdown>
        ))}

      <BaseButton
        dataTestId={"delete-followUp-btn"}
        onClick={handleDelete}
        modalType="followUp"
        classnames={"follow-ups-btn-icon"}
      >
        <CrossDeleteIcon />
      </BaseButton>
      {!props.isFromLandlordPage && props.followUp?.todo_status !== "completed" && (
        <Dropdown
          getPopupContainer={() => {
            return document.querySelector(`.snooze-follow-up-btn-${props.followUp._id}`) as HTMLElement;
          }}
          disabled={isDemo}
          overlayClassName={"follow-ups-dropdown"}
          trigger={["click"]}
          placement="bottomCenter"
          overlay={
            <SnoozeOptions
              setUpdates={props.setUpdates}
              followUp={props.followUp}
              setDropdownVisibility={setDropdownVisibility}
              queryAndPagination={props.queryAndPagination}
            />
          }
        >
          {formatDate(props.followUp.todo_at) === currentDate() ||
          new Date(props.followUp.todo_at).getTime() < new Date().getTime() ? (
            <div className={`snooze-follow-up-btn-${props.followUp._id}`}>
              <BaseButton
                dataTestId={"snooze-followUp-btn"}
                classnames={"rb-secondary-btn sm rb-secondary-btn-without-shadow ml-5"}
                modalType="followUp"
                onClick={() =>
                  setDropdownVisibility((prevState: any) => {
                    return {
                      ...prevState,
                      edit: false,
                      snooze: !prevState.snooze,
                    };
                  })
                }
              >
                Snooze
              </BaseButton>
            </div>
          ) : (
            <p></p>
          )}
        </Dropdown>
      )}
      {!props.isFromLandlordPage && props.followUp?.todo_status !== "completed" && (
        <BaseButton
          loading={loading}
          dataTestId={"mark-as-done-followUp-btn"}
          onClick={markAsDoneHandler}
          modalType="followUp"
        >
          Mark As Done
        </BaseButton>
      )}
      <ArchiveFollowUpModal
        visible={archiveFollowUpModalVisible}
        onClose={() => setArchiveFollowUpModalVisible(false)}
        onSubmit={deleteFollowUp}
        modalContent={deleteConfirmContent}
      />
    </div>
  );
};

export default React.memo(FollowUpsActions);
