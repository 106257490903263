import React from "react";
import { Button, Col, Dropdown, Row } from "antd";
import { SelectDownArrowIcon } from "../icons";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { clearFollowUpsList } from "../../store/followUps/action";
import AgentsActivitiesTable from "./AgentsActivitiesTable";
import { userSelector } from "../../store/user/selectors";
import { ROLE_TYPES } from "../../helpers/constants";
import { setQueryAndPagination } from "../../store/activities/action";
import { queryAndPaginationSelector } from "../../store/activities/selectors";

export interface IActivitiesQuery {
  page?: number;
  current?: number;
  pageSize?: number;
  sortField?: string;
  sortOrder?: number;
  view?: string | null;
  timeInterval?: {
    label: string;
    value: string;
  };
}

const TeamDashboard = () => {
  const userData = useSelector(userSelector);
  const dropdownOptions = [
    {
      label: "This Week",
      value: "this_week"
    },
    {
      label: "Last Week",
      value: "last_week"
    },
    {
      label: "This Month",
      value: "this_month"
    },
    {
      label: "Last Month",
      value: "last_month"
    },
    {
      label: "This Year",
      value: "this_year"
    },
    {
      label: "Last 30 Days",
      value: "last_30_days"
    },
    {
      label: "Last 60 Days",
      value: "last_60_days"
    },
    {
      label: "Last 90 Days",
      value: "last_90_days"
    }
  ];

  const queryAndPagination = useSelector(queryAndPaginationSelector);

  const dispatch = useDispatch();
  return (
    <div className={"team-members-content team-members-activities"}>
      <Row
        align={"middle"}
        justify={"space-between"}
        style={{ marginBottom: "30px" }}
        className={"team-members-headline-row"}
      >
        <Col className={`follow-up-sort-btn`}>
          <Dropdown
            trigger={["click"]}
            className="more-dropdown-wrapper team-dashboard-wrapper-dropdown due-in-filter"
            placement="bottomLeft"
            //@ts-ignore
            getPopupContainer={() => {
              return document.querySelector(".team-dashboard-wrapper-dropdown");
            }}
            overlay={
              <div className="filter-dropdown-wrapper due-in-filter team-members-filter-btn">
                {dropdownOptions
                  .filter((dfo) => dfo.value !== queryAndPagination.timeInterval?.value)
                  .map((option, idx) => {
                    return (
                      <div
                        key={idx}
                        className="price-inputs-wrapper due-in-filter-dropdown"
                        onClick={(e) => {
                          dispatch(
                            setQueryAndPagination({
                              timeInterval: option
                            })
                          );
                          dispatch(clearFollowUpsList());
                        }}
                      >
                        <span>{option.label}</span>
                      </div>
                    );
                  })}
              </div>
            }
          >
            <Button className={`more-btn-dropdown`}>
              <div className="more-btn-dropdown-txt due-in-filter-btn">
                {queryAndPagination.timeInterval?.label}
              </div>
              <span className="more-btn-dropdown-icon-wrapper">
                <SelectDownArrowIcon />
              </span>
            </Button>
          </Dropdown>
        </Col>
      </Row>

      <AgentsActivitiesTable
        queryAndPagination={queryAndPagination}
        setQueryAndPagination={setQueryAndPagination}
      />
    </div>
  );
};

export default TeamDashboard;
