import { InfoOkIcon } from "components/icons/InfoOkIcon";
import BaseModal from "components/Shared/BaseModal";
import { IObjectKeys } from "helpers/globalTypes";
import { useWindowSize } from "hooks/useWindowSize";
import "./InfoModal.scss";
import React from "react";

const InfoModal = ({
  toggle = false,
  setToggle,
  header,
  text,
}: IObjectKeys) => {
  const { width } = useWindowSize();
  const handleCancel = () => {
    setToggle(false);
  };

  return (
    <>
      <BaseModal
        isModalVisible={toggle}
        modalFooter={null}
        width={width && width < 600 ? 290 : 388}
        classname="thanksModal"
        onCancel={handleCancel}
        modalTitle=""
      >
        <div className="info-modal flex flex-column align-center p-20 pb-20">
          <span>
            <InfoOkIcon />
          </span>
          <h2>{header}</h2>
          <p className="text-center font-16 mb-0">{text} </p>
        </div>
      </BaseModal>
    </>
  );
};

export default InfoModal;
