import React, { useEffect } from 'react';
import { COGNITO_CLAW_AUTHORIZE_ENDPOINT } from '../../helpers/constants';
import { Spin } from 'antd';

function ClawSAMLAuth() {
  useEffect(() => {
    window.location.href = COGNITO_CLAW_AUTHORIZE_ENDPOINT;
  }, []);

  return (
    <Spin />
  );
}

export default ClawSAMLAuth;