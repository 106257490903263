import React from "react";
import { TABS_DESCRIPTIONS } from "../../../../helpers/dictionary";

export const tabsSteps = [
  {
    selector: '[data-tour="step-Dashboard"]',
    content: <span dangerouslySetInnerHTML={{ __html: TABS_DESCRIPTIONS.dashboard }}></span>
  },
  {
    selector: '[data-tour="step-Listings"]',
    content: <span dangerouslySetInnerHTML={{ __html: TABS_DESCRIPTIONS.listings }}></span>
  },
  {
    selector: '[data-tour="step-Leads"]',
    content: <span dangerouslySetInnerHTML={{ __html: TABS_DESCRIPTIONS.leads }}></span>
  },
  {
    selector: '[data-tour="step-Landlords"]',
    content: <span dangerouslySetInnerHTML={{ __html: TABS_DESCRIPTIONS.landlords }}></span>
  },
  {
    selector: '[data-tour="step-Follow-ups"]',
    content: <span dangerouslySetInnerHTML={{ __html: TABS_DESCRIPTIONS.followUps }}></span>
  },
  {
    selector: '[data-tour="step-My Teams"]',
    content: <span dangerouslySetInnerHTML={{ __html: TABS_DESCRIPTIONS.myTeams }}></span>
  },
  {
    selector: '[data-tour="step-Screening"]',
    content: <span dangerouslySetInnerHTML={{ __html: TABS_DESCRIPTIONS.screenings }}></span>
  }
];
