import { List } from "antd";
import React from "react";

import TenantScreeningItem from "./grid-item/TenantScreeningItem";

const TenantGridView = ({ paginationValue = {}, tableValues, total = 0 }: any) => {
  return (
    <List
      data-test-id="tenant-screening-container"
      className="tenant-screening-wrapper"
      id="tenantScreeningList"
      grid={{
        gutter: 16,
        column: 1,
      }}
      pagination={{
        current: paginationValue.current || 1,
        showSizeChanger: false,
        pageSize: paginationValue?.pageSize || 24,
        total: total,
        hideOnSinglePage: true,
      }}
      dataSource={tableValues}
      renderItem={(item) => (
        <TenantScreeningItem
          item={item}
        />
      )}
      locale={{ emptyText: "No screening requests available that match the filter criteria." }}
    />
  );
};

export default TenantGridView;
