import React from "react";
import PrivacyPolicyWrapper from "../../components/PrivacyPolicy";
import { useHistory } from "react-router-dom";
import "../../components/PrivacyPolicy/styles.scss";

const PrivacyPolicy = () => {
  const history = useHistory();

  return (
    <div
      className={`scrollbar ${
        history.location.pathname === "/policy"
          ? "privacy_policy"
          : "privacy_policy_auth"}`
      }
    >
      <PrivacyPolicyWrapper />
    </div>
  );
};
export default PrivacyPolicy;
