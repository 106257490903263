import React from "react";

const LandlordDashboardIcon = ({ width, height }: { width?: string, height?: string }) =>{
    const widthS = width || "20";
    const heightS = height || "17";
    return(
        <svg width={widthS} height={heightS} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3686 2.2352C12.2972 2.2352 8.98462 5.54776 8.98462 9.61839C8.98462 10.5194 9.152 11.3904 9.46562 12.2192L2.48137 19.3196C2.33187 19.4716 2.24818 19.6763 2.24818 19.8892V22.9515C2.24818 23.4 2.61137 23.764 3.06068 23.764H7.9885C8.43781 23.764 8.801 23.4 8.801 22.9515V21.0844H10.6681C11.1174 21.0844 11.4806 20.7204 11.4806 20.2719V18.2609H13.8263C14.2756 18.2609 14.6388 17.8969 14.6388 17.4484V16.7879C15.2019 16.9236 15.7787 16.9999 16.3686 16.9999C20.4392 16.9999 23.751 13.6882 23.751 9.61676C23.751 5.54532 20.4392 2.2352 16.3686 2.2352V2.2352ZM10.6104 9.61839C10.6104 6.44314 13.1942 3.8602 16.3694 3.8602C19.5439 3.8602 22.1268 6.44314 22.1268 9.61839C22.1268 12.7936 19.5439 15.3766 16.3694 15.3766C15.5976 15.3766 14.8501 15.2271 14.1456 14.9321C14.0481 14.8907 13.9401 14.8671 13.8271 14.8671C13.3778 14.8671 13.0146 15.2311 13.0146 15.6796V16.6376H10.6689C10.2196 16.6376 9.85643 17.0016 9.85643 17.4501V19.461H7.98931C7.54 19.461 7.17681 19.825 7.17681 20.2735V22.1406H3.874V20.2231L10.8599 13.1203C10.8599 13.1203 11.4116 12.6693 11.0378 11.7991C10.5974 10.7738 10.6104 9.61839 10.6104 9.61839V9.61839ZM17.7816 5.29751C16.1647 5.29751 14.8492 6.61376 14.8492 8.23064C14.8492 9.84751 16.1647 11.1638 17.7816 11.1638C19.4001 11.1638 20.7163 9.84751 20.7163 8.23064C20.7163 6.61376 19.4001 5.29751 17.7816 5.29751V5.29751ZM17.7816 9.53957C17.0601 9.53957 16.4742 8.95295 16.4742 8.23145C16.4742 7.50995 17.0609 6.92332 17.7816 6.92332C18.5039 6.92332 19.0913 7.50995 19.0913 8.23145C19.0913 8.95295 18.5031 9.53957 17.7816 9.53957V9.53957Z" fill="black"/>
        </svg>
    )
}

export default LandlordDashboardIcon
