import BaseButton from "components/Shared/BaseButton";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { windowSelector } from "store/app/selectors";

const AuthHeaderTenantScreening = () => {
  const { width }: any = useWindowSize();
  const { type } = useSelector(windowSelector);
  const history = useHistory();

  return (
    <div className="flex justify-end w-full">
      <BaseButton
        variant="primary"
        onClick={() => history.push("/tenant-screenings?add=true")}
        modalType="Send Screening Request"
      >
        <span>{type === "mobile" ? "Add" : "Send Screening Request"}</span>
      </BaseButton>
    </div>
  );
};

export default AuthHeaderTenantScreening;
