import React from "react";

import { BLOCKS, MARKS, Document } from "@contentful/rich-text-types";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";

const Bold = ({ children }: any) => (
  <span className="text-bold">{children}</span>
);
const Underline = ({ children }: any) => (
  <span className="text-underlined">{children}</span>
);
const Text = ({ children }: any) => <p className="align-center">{children}</p>;

export const options: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.UNDERLINE]: (text) => <Underline>{text}</Underline>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
};

export const generateHtmlFromDocument = (richTextDocument: Document) => {
  return documentToReactComponents(richTextDocument, options);
};
