import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "assets/styles/index.scss"; // Tailwind before antd because we need to defer to ant most of the time :()
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history, persistor } from "store";
import { PersistGate } from 'redux-persist/integration/react';
import App from "App";
import { configureAxios } from "./services";
import * as serviceWorker from "serviceWorker";
import { configureNotifications } from "./helpers/globalFunctions";

configureAxios();
configureNotifications("topRight");
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
