import React from "react";
import "./styles.scss";
import { TablePaginationConfig } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { routerSelector } from "../../../store/listings/selectors";
import { IObjectKeys } from "../../../helpers/globalTypes";
import BaseLeadsTable from "components/BaseLeadsTable";
import { push } from "connected-react-router";
import { createURL } from "../../../helpers/globalFunctions";
import { IFilterOptionsOptionalKeys } from "../../Listings/helpers/mockListings";
import { setLandlordsQueryParams } from "../../../store/landlords/action";
import { scrollToTop } from "../../../helpers/globalFunctions";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../filters/helpers/listingsCreateGetSearchQuery";
interface ITableView {
  paginationValues: any;
  filterOptions?: IFilterOptionsOptionalKeys;
  total: number;
  tableValues: Array<IObjectKeys>;
  viewType?: string;
  columns: Array<IObjectKeys>;
  tableClassName?: string;
  showingCount?: number | null;
  setShowingCount?: any;
  onRow?: (record: any, rowIndex: any) => any;
  id?: string;
}

const TableView = ({
  paginationValues,
  filterOptions,
  total,
  tableValues,
  viewType,
  columns,
  tableClassName,
  showingCount,
  setShowingCount,
  onRow,
  id,
}: ITableView) => {
  const dispatch = useDispatch();
  const router = useSelector(routerSelector);
  const pageOrCurrent: string = router?.location?.pathname?.includes("/listings") ? "current" : "page";
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObjectMain = urlQueryToObject(_newURLSearchParams);

  const handleTableChange = (paginationOpt: TablePaginationConfig, _: any, sorter: any): void => {
    if (router?.location?.pathname?.includes("/listings")) {
      const queryObject = {
        listingType: viewType,
        ...router.location.query,
        ...filterOptions,
        ...{ ...paginationValues, page: paginationOpt.current },
      };
      const newSearchQuery = createURL(queryObject);
      dispatch(
        push({
          pathname: router.location.pathname,
          search: newSearchQuery,
        })
      );
    } else if (router?.location?.pathname?.includes("/landlords")) {
      queryObjectMain.page = paginationOpt.current;
      const newSearchQuery = createURL(queryObjectMain);
      dispatch(push({ search: newSearchQuery }));
      dispatch(
        setLandlordsQueryParams({
          ...paginationValues,
          page: paginationOpt.current,
        })
      );
    }
    const tableRef = document.getElementById("tableViewId");
    if (tableRef) scrollToTop(tableRef);
    setShowingCount && setShowingCount();
  };

  const pagination = Object.keys(paginationValues).length
    ? {
        current: paginationValues[pageOrCurrent],
        pageSize: showingCount !== 24 ? showingCount : paginationValues.pageSize,
        total,
        position: ["bottomCenter"],
        showSizeChanger: false,
      }
    : false;

  return (
    <div className="listings-table-view-container">
      <BaseLeadsTable
        id={id}
        classnames={`shared-table ${tableClassName || ""}`}
        bordered={false}
        columns={columns}
        dataSource={tableValues}
        rowKey={(record) => record._id}
        onChange={handleTableChange}
        dataTestId="table"
        pagination={pagination}
        onRow={onRow}
      />
    </div>
  );
};

export default TableView;
