import React, { memo, useEffect, useMemo, useState } from "react";
import { Input } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { urlQueryToObject } from "../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  filterOptionsSelector,
  listingsViewTypeSelector,
  paginationValuesSelector,
  routerSelector,
} from "../../../store/listings/selectors";
import { paginationValuesSelector as leadsPaginationValues } from "../../../store/leads/selectors";
import { landlordsQueryParamsSelector } from "../../../store/landlords/selectors";
import { queryAndPaginationSelector as teamsQueryAndPaginationSelector } from "../../../store/teams/selectors";
import { queryAndPaginationSelector as activitiesQueryAndPaginationSelector } from "../../../store/activities/selectors";
import { queryAndPaginationSelector as followUpsQueryAndPaginationSelector } from "../../../store/followUps/selectors";
import { detectSearchbarPlaceholder } from "../../../helpers/globalFunctions";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { SearchIcon } from "../../icons";
import SearchCloseIcon from "../../icons/SearchCloseIcon";
import { delayedSearchForSearchBarHandler } from "../../Shared/filters/helpers/filterFormHelper";

interface ISearchBarProps {
  setIsSearchFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchFocus: boolean;
}

const SearchBar = ({ setIsSearchFocus, isSearchFocus }: ISearchBarProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const router = useSelector(routerSelector);
  const { width } = useWindowSize();
  const { lId } = useParams();
  const _newURLSearchParams = new URLSearchParams(history.location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const filterOptions = useSelector(filterOptionsSelector);
  const listingsViewType = useSelector(listingsViewTypeSelector);
  const leadsPagination = useSelector(leadsPaginationValues);
  const listingsPagination = useSelector(paginationValuesSelector);
  const landlordsQueryParams = useSelector(landlordsQueryParamsSelector);
  const teamsQueryAndPagination = useSelector(teamsQueryAndPaginationSelector);
  const filterOptionsWithoutSortOption = { ...filterOptions };
  const queryObjectWithoutSortOption = { ...queryObject };
  const activitiesQueryAndPagination = useSelector(activitiesQueryAndPaginationSelector);
  const [value, setValue] = useState("");
  const followUpsQueryAndPagination = useSelector(followUpsQueryAndPaginationSelector);

  useEffect(() => {
    if (queryObject.search) {
      setValue(queryObject.search);
    } else if (queryObject.searchValue) {
      setValue(queryObject.searchValue);
    } else {
      setValue("");
    }
  }, [queryObject.search]);

  const delayedSearch = (value: string) => {
    delayedSearchForSearchBarHandler({
      value,
      router,
      filterOptionsWithoutSortOption,
      queryObject,
      queryObjectWithoutSortOption,
      lId,
      listingsViewType,
      listingsPagination,
      dispatch,
      landlordsQueryParams,
      teamsQueryAndPagination,
      activitiesQueryAndPagination,
      followUpsQueryAndPagination,
      leadsPagination,
    });
  };

  const searchPlaceholder = useMemo(() => {
    const path = history.location.pathname;
    return detectSearchbarPlaceholder(path);
  }, [history.location.pathname]);

  useEffect(() => {
    if (value.length) {
      const timeoutId = setTimeout(() => delayedSearch(value), 400);
      return () => clearTimeout(timeoutId);
    }
  }, [value]);

  const clearSearchValue = () => {
    setValue("");
    delayedSearch("");
  };

  const onChangeSearchValueHandler = (value: string) => {
    setValue(value);
    if (!value.length) {
      delayedSearch("");
    }
  };

  const onBlurAction = () => {
    if (
      // @ts-ignore
      !document.querySelector(".header-search-input").contains(document.querySelector(".search-close-icon-cont"))
    ) {
      setIsSearchFocus(false);
    }
  };

  const onFocusAction = () => {
    setIsSearchFocus(true);
  };

  return (
    <Input
      autoComplete={"off"}
      value={value}
      prefix={value.length ? <SearchCloseIcon handleOnclick={clearSearchValue} /> : <SearchIcon />}
      placeholder={width && width > 599 ? searchPlaceholder : "Search"}
      onChange={(event) => onChangeSearchValueHandler(event.target.value)}
      data-test-id="search-header-input"
      className="h-12 w-full shadow-sm ring-1 ring-inset ring-purple-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 rounded-full"
      style={{ borderRadius: "3em" }}
    />
  );
};

export default memo(SearchBar);
