import { US_STATES } from "helpers/constants";
import { IObjectKeys } from "helpers/globalTypes";
import stateToAbbr from "helpers/stateToAbbr";

const isState = (str: string) => US_STATES.hasOwnProperty(str.replace(",", ""));

const titleCase = (str: string) =>
  str
    .split(" ")
    .map((str) => {
      const word = isState(str) ? str.toUpperCase() : str.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");

export const generateListingAddress = (listing: IObjectKeys) => {
  // Hack - remove the "(<neighborhood>)" from generated addy
  const ga = listing.generatedAddr.replace(/\s*\(.*?\)\s*/g, "");
  const address = titleCase(listing.addr) || ga;
  const shortStateName = stateToAbbr(listing.state);

  return address?.replace(listing?.state, shortStateName);
};
