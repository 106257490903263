import React, { useState, useEffect } from "react";
import Listing from "components/ListingView";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { setListing } from "store/listings/action";
import "../../components/ListingView/style.scss";
import { getAuthToken, getGuestAccessToken } from "helpers/authUtils";
import { getLoggedInUser } from "store/user/action";
import { push } from "connected-react-router";
import routes from "routes/routesCode";
import { Spin } from "antd";
import GlobalLoading from "components/Shared/GlobalLoading";

const ShareListing: React.FC<any> = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const { shareListingId } = useParams();
  const apiUrl = `${process.env.REACT_APP_BASE_URL}/share-listing/${shareListingId}`;
  const config = {
    headers: {
      Authorization: `Bearer ${getGuestAccessToken()}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (getAuthToken()) {
      dispatch(getLoggedInUser());
    }
    fetchListingData();
  }, [shareListingId]);

  const fetchListingData = () => {
    axios
      .get(apiUrl, config)
      .then((res: any) => {
        if (!res.listingDetails.listing) {
          dispatch(push(routes.home.path));
          return;
        }
        const isPublic = res.listingDetails.isPublic;
        const agentOfList = res.agentOfList;
        const history = res.listingDetails.history;
        const payload = {
          ...res.listingDetails.listing,
          isPublic,
          agentOfList,
          history,
        };
        setLoading(false);
        dispatch(setListing(payload));
      })
      .catch((error) => {
        console.error("Error fetching listing data:", error);
      });
  };

  return (
    <Spin spinning={loading} className="global-loading" indicator={<GlobalLoading />}>
      <div className="single-listing-view-wrapper m-0-auto">
        <Listing />
      </div>
    </Spin>
  );
};

export default ShareListing;
