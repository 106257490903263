import React from "react";

const CollapseTopIcon = () =>{
    return(
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.6707 1.13766L0.1357 7.15054C-0.0455414 7.34755 -0.0452367 7.6665 0.136638 7.86318C0.318488 8.0597 0.613073 8.05919 0.794455 7.86216L6.00002 2.20718L11.2056 7.86237C11.387 8.05937 11.6814 8.05988 11.8632 7.86338C11.9544 7.76479 12 7.63563 12 7.50647C12 7.37763 11.9547 7.24898 11.8642 7.15057L6.32932 1.13766C6.24218 1.04277 6.12358 0.98953 6.00002 0.98953C5.87646 0.98953 5.75801 1.04293 5.6707 1.13766Z" fill="white"/>
        </svg>
    )
}

export default CollapseTopIcon