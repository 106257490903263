import BaseModal from "components/Shared/BaseModal";
import React, {useEffect} from "react";
import FollowUpModalSingleItem
    from "../all-follow-ups-modal/components/follow-up-modal-single-item/FollowUpModalSingleItem";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";
import {
    dashboardFollowUpSnoozeSuccessSelector
} from "../../../store/dashboard/selectors";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {setDashboardFUSnoozeSuccessToFalse} from "../../../store/dashboard/action";
import {setSingleFuToBlank} from "../../../store/followUps/action";

interface IDashboardFollowUpModal {
    isFuPage?: boolean | any,
    setSnoozeBodyVisible: any,
    setIsFuVisible: any,
    setIsSnoozePickerVisible: any,
    isFUVisible: any,
    singleFollowUpData: any,
    snoozeBodyVisible: any,
    setFollowUpModalProps: any,
    backToFollowUps: any,
    handleOpenEditFollowUpModal: any,
    isSnoozePickerVisible: boolean

}

const DashboardFollowUpModal = (props: IDashboardFollowUpModal) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const snoozeSuccessSelector = useSelector(dashboardFollowUpSnoozeSuccessSelector)
    const {width}:any = useWindowSize()

    useEffect(()=>{
        return ()=>{
            dispatch(setDashboardFUSnoozeSuccessToFalse())
        }
    },[])

    return (
        <BaseModal
            classname={"snooze-followUp-modal dashboard-fu-modal aaaaaaaaa aaa aa "}
            onCancel={() => {
                props.setSnoozeBodyVisible(false)
                props.setIsFuVisible(false)
                props.setIsSnoozePickerVisible(false)
                dispatch(setSingleFuToBlank())
                if (props.isFuPage) {
                    dispatch(push(location.pathname))
                }
            }}
            modalFooter={null}
            dataTestId={"fu-modal"}
            isModalVisible={props.isFUVisible}
            width={props.snoozeBodyVisible && !snoozeSuccessSelector ? 570 : snoozeSuccessSelector && width > 599 ? 400 : props.snoozeBodyVisible && width <= 599 ? 290 : width < 599 && width > 375 ? 400 : width < 375 ? 290 : 655}

        >
            <FollowUpModalSingleItem
                followUp={props.singleFollowUpData}
                snoozeVisible={props.snoozeBodyVisible}
                setSnoozeVisible={props.setSnoozeBodyVisible}
                setAllVisible={null}
                setSingleFollowUpVisible={props.setFollowUpModalProps}
                backToFollowUps={props.backToFollowUps}
                handleOpenEditFollowUpModal={props.handleOpenEditFollowUpModal}
                setIsSnoozePickerVisible={props.setIsSnoozePickerVisible}
                isSnoozePickerVisible={props.isSnoozePickerVisible}
                isFromDashboard={true}
                closeModal={() => {
                    props.setSnoozeBodyVisible(false)
                    props.setIsFuVisible(false)
                    props.setIsSnoozePickerVisible(false)
                }}
            />
        </BaseModal>
    );
};

export default DashboardFollowUpModal;

