import axios from "axios";
import store from "store";
import AuthApi from "../api/Auth";
import { push } from "connected-react-router";
import { setAuthLoadingToFalse, signOut } from "../store/user/action";
import { getRefreshToken } from "../helpers/authUtils";
import getUnauthorized, { setUnauthorized } from "./unauthorized";

const axiosApiInstance = axios.create();

export const configureAxios = () => {
  let retryRefreshTokenRequest = true;
  axios.interceptors.request.use(
    function (config) {
      // @ts-ignore
      config.metadata = { startTime: new Date() };
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      const { data, config } = response;
      // @ts-ignore
      // config.metadata.endTime = new Date();
      // @ts-ignore
      // response.duration = response.config.metadata.endTime - response.config.metadata.startTime;
      if (config?.url?.includes("/auth/get-user")) {
        // @ts-ignore
        setTimeout(() => {
          store.dispatch(setAuthLoadingToFalse());
        }, 2500);
      }

      if (data instanceof Blob) {
        return data;
      }
      return data;
    },

    function (error) {
      const originalRequest = error.config;
      // error.config.metadata.endTime = new Date();
      // error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
      if (error.response.status === 401 && retryRefreshTokenRequest && !["login", "forceResetPassword"].includes(originalRequest.url) && getRefreshToken()) {
        retryRefreshTokenRequest = false;
        return (async () => {
          try {
            const access_token = await AuthApi.refreshToken();
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
            return axiosApiInstance(originalRequest);
          } catch (e) {
            if (!getUnauthorized()) {
              store.dispatch(signOut({ redirectUrl: sessionStorage.getItem("force_logout_last_page") || "" }));
              setUnauthorized(true);
            }
          }
        })();
      }

      // NOTE: commenting this out for now because I'm not sure it's going what we want,
      // ie for a GET request on a resource, if it doesnt exists for some reason, we
      // probably don't want to redirect the entire page to 404. Instead, alert the user
      // that the resource isn't found or whatever. -JL
      //
      // * handle redirection based on API response
      // const dontRedirectOn404 = error.response?.hasOwnProperty("data")
      //   ? error.response?.data?.data?.redirect === false
      //   : false;
      // if (error.response.status === 404 && !dontRedirectOn404) {
      //   store.dispatch(push("/page_not_found"));
      // }

      if (error.response.status === 422 && error.response?.data && error.response?.data?._id && error.response?.data?._id === "must be a mongodb id") {
        store.dispatch(push("/page_not_found"));
      }
      if (error.response.status === 401) {
        if (!sessionStorage.getItem("force_logout_last_page")) {
          sessionStorage.setItem("force_logout_last_page", window.location.pathname.substring(1) + window.location.search);
        }
        // if(getUnauthorized()){
        store.dispatch(signOut({ redirectUrl: sessionStorage.getItem("force_logout_last_page") || "" }));
        setUnauthorized(true);
      }
      if (error.response) {
        const {
          response: { data },
        } = error;
        return Promise.reject({ ...data });
      }
      return Promise.reject(error);
    }
  );
};
