import { SSO_SOURCES, SOURCE_ENUM } from "./constants";

interface IUser {
  role: string;
  source:
    | {
        name: string | undefined;
      }
    | undefined;
}

export const isCLAWUser = (user: IUser) => (user?.source?.name && SSO_SOURCES.includes(`${user.source.name}`)) || false;

export const isRenter = (user: IUser) => user?.role === "Renter" || false;
export const isAgent = (user: IUser) => (user?.role && ["Agent", "Owner"].includes(user.role)) || false;

export const isUserSource = (user: IUser, source: String | undefined) => {
  if (!user.source?.name && source !== SOURCE_ENUM.RENTBASE) return false;
  if (!user.source?.name && source === SOURCE_ENUM.RENTBASE) return true;
  return (user?.source?.name && user.source.name === source) || isCLAWUser(user);
};
