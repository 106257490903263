import React, { useEffect, useState } from "react";
import { Card, Form, Radio } from "antd";
import OffMarketForm from "../ListingForm/AvailabilityPart/OffMarketForm";
import { StepFormComponent } from "../ListingForm/types";
import { useDispatch, useSelector } from "react-redux";
import { listingDataSelector, listingUpdateSuccessSelector } from "../../../store/listings/selectors";
import { IObjectKeys } from "../../../helpers/globalTypes";
import { clearListingUpdateState, getOneListingFromPublicId, updateListing } from "../../../store/listings/action";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";

const OffMarketStep = ({ form, next }: StepFormComponent) => {
    const dispatch = useDispatch();
    const listingData = useSelector(listingDataSelector);
    const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
    const [showOtherRenewal, setShowOtherRenewal] = useState(listingData.renewal_date === -1);
    const { lId } = useParams();
    const { pathname } = useLocation();
    const leaseStartDefaultDate = moment(
      listingData.availability_date
    ).isBefore(moment())
      ? moment()
      : moment(listingData.availability_date);

    const onSubmit = (data: IObjectKeys) => {
        delete data.status;
        dispatch(updateListing({ lId: listingData._id, other_renewal_date:data.other_renewal_date,...data }));
    };
    useEffect(() => {
        if (updateListingSuccess) {
            if(pathname.includes(`/listings/${lId}`)){
                dispatch(getOneListingFromPublicId(lId));
            }
            next && next();
        }
        return () => {
            dispatch(clearListingUpdateState());
        };
    }, [dispatch, lId, next, pathname, updateListingSuccess]);

    return (
        <Form
            form={form}
            onFinish={onSubmit}
            initialValues={{
                asked_rent: listingData.asked_rent,
                lease_start_date: listingData.availability_date ? leaseStartDefaultDate : moment()
            }}
            onFieldsChange={(changedFieldInfo) => {
                // eslint-disable-next-line no-restricted-globals
                if (Array.isArray(changedFieldInfo[0]?.name)) {
                    // eslint-disable-next-line no-restricted-globals
                  if (changedFieldInfo[0]?.name?.indexOf("renewal_date") > -1) {
                    setShowOtherRenewal(changedFieldInfo[0]?.value === -1);
                  }
                }
            }}
        >
            <Form.Item name="status" className="flex-centered">
                <Radio.Group size="large">
                    <Card>
                        <Radio.Button value="Available" disabled>Available</Radio.Button>
                        <Radio.Button value="Off-market">Rented</Radio.Button>
                    </Card>
                </Radio.Group>
            </Form.Item>
            <OffMarketForm showOtherRenewal={showOtherRenewal} form={form}/>
        </Form>
    );
};
export default OffMarketStep;
