import { DatePicker, Form, FormInstance, Input } from "antd";
import { US_DATE_FORMAT } from "helpers/constants";
import { EMAIL_PLACEHOLDER, PHONE_NUMBER_MASK, PHONE_NUMBER_PLACEHOLDER, REQUIRED } from "helpers/validations";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import BaseCollapse from "../../../../../Shared/BaseCollapse";
import MaskedInput from "../../../../../Shared/MaskedPhoneInput";
import { DeleteIcon } from "../../../../../icons";
import { getPhoneEmailInputRules, getTenantEmailInputRules } from "../../helpers";

const TenantsPart = ({
  form,
  statusChangeForm,
  fromListingView,
}: {
  form: FormInstance;
  statusChangeForm?: boolean;
  fromListingView?: boolean;
}) => {
  const buttonRef = useRef(null);
  const contentRef = useRef(null);
  const [formListArray, setFormListArray] = useState([{ tenant: undefined, collapsed: false }]);

  const scrollToLastTenantSection = () => {
    if (contentRef && contentRef.current) {
      //@ts-ignore
      contentRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };
  const changeCollapsedHandler = (index: any) => {
    let newArr = [...formListArray];
    newArr[index].collapsed = !newArr[index].collapsed;
    // // @ts-ignore
    setFormListArray(newArr);
  };

  const startDateSelectHandler = (value: any) => {
    if (value) {
      // @ts-ignore
      form.setFieldsValue({
        lease_ending_date: moment(value).add(1, "y").subtract(1, "day"),
      });
    } else {
      form.setFieldsValue({
        lease_ending_date: moment(form.getFieldValue("lease_start_date")).add(1, "y").subtract(1, "day") || null,
      });
    }
  };

  useEffect(() => {
    if (form.getFieldsValue()?.tenants && form.getFieldsValue().tenants?.filter(Boolean).length === 0) {
      if (buttonRef && buttonRef.current) {
        // @ts-ignore
        buttonRef.current.click();
      }
    }
  }, [form]);

  useEffect(() => {
    if (statusChangeForm) {
      form.setFieldsValue({
        tenants: [undefined],
      });
    }
  }, [form, statusChangeForm]);

  const arr: any = [];
  useEffect(() => {
    if (form.getFieldValue("tenants") && form.getFieldValue("tenants").length) {
      form.getFieldValue("tenants").forEach((e: any) => {
        arr.push({ tenant: e, collapsed: false });
      });
      setFormListArray(arr);
    }
    form.setFieldsValue({
      lease_ending_date: moment(form.getFieldValue("lease_start_date")).add(1, "y").subtract(1, "day"),
    });
  }, []);

  return (
    <div>
      {fromListingView && (
        <div className="date-picker-buttons">
          <Form.Item name="lease_start_date" label="Lease Start Date" rules={[REQUIRED]} labelCol={{ span: 24 }}>
            <DatePicker
              format={US_DATE_FORMAT}
              className="w-full"
              placeholder={"Ex. 01-21-2023"}
              onChange={startDateSelectHandler}
            />
          </Form.Item>
          <Form.Item
            name="lease_ending_date"
            label="Lease End Date"
            rules={[{ required: false }]}
            labelCol={{ span: 24 }}
          >
            <DatePicker
              format={US_DATE_FORMAT}
              className="w-full"
              placeholder={"Ex. 01-01-2021"}
              disabledDate={(value) => {
                let endDate = moment(form?.getFieldValue("lease_start_date"));
                return value < endDate;
              }}
            />
          </Form.Item>
        </div>
      )}
      <Form.List name="tenants">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, idx) => {
                return (
                  <div
                    ref={fields.length - 1 === idx ? contentRef : null}
                    key={`${+new Date()}-${field.key}-${idx}-${field.name}`}
                  >
                    <div>
                      <div className={"add-tenants-wrapper"}>
                        <h3 className="text-2x mb-2 font-semibold">
                          {form.getFieldsValue()?.tenants?.length && form.getFieldsValue()?.tenants[idx]?.name}
                        </h3>
                        <Form.Item
                          {...field}
                          name={[field.name, "name"]}
                          fieldKey={[`${field.fieldKey}-${+new Date()}-${idx}`, "name"]}
                          label={"Tenant Name"}
                          key={`${field.fieldKey}-${+new Date()}-${idx}`}
                          labelCol={{ span: 24 }}
                          rules={!statusChangeForm ? [REQUIRED] : []}
                        >
                          <Input autoComplete={"off"} placeholder="Name" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "phone"]}
                          key={`${field.fieldKey}-${+new Date()}-${idx + 1}`}
                          fieldKey={[`${field.fieldKey}-${+new Date()}-${idx}`, "phone"]}
                          rules={statusChangeForm ? [] : getPhoneEmailInputRules(field, form)}
                          label={"Tenant Phone Number"}
                          labelCol={{ span: 24 }}
                          validateTrigger={"onBlur"}
                        >
                          <MaskedInput
                            inputMode="numeric"
                            mask={PHONE_NUMBER_MASK}
                            placeholder={PHONE_NUMBER_PLACEHOLDER}
                            onChange={() => form.validateFields([["tenants", field.name, "email"]])}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "email"]}
                          label={"Tenant Email"}
                          labelCol={{ span: 24 }}
                          fieldKey={[`${field.fieldKey}-${+new Date()}-${idx}`, "email"]}
                          rules={statusChangeForm ? [] : getTenantEmailInputRules(field, form)}
                        >
                          <Input
                            autoComplete={"off"}
                            placeholder={EMAIL_PLACEHOLDER}
                            onChange={() => form.validateFields([["tenants", field.name, "phone"]])}
                          />
                        </Form.Item>
                        <span
                          className="tenant-fields-delete-icon-wrapper flex-centered"
                          onClick={() => {
                            remove(field.name);
                            setFormListArray(formListArray.filter((item, index) => index !== idx));
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
              <Form.Item className={"text-center mt-25"}>
                <span onClick={(e) => e.stopPropagation()}>
                  <button
                    ref={buttonRef}
                    className={"ant-btn primary-btn-link light text-hover-underlined"}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      e.nativeEvent.stopImmediatePropagation();
                      add();
                      setTimeout(() => {
                        scrollToLastTenantSection();
                      }, 0);
                      setFormListArray([...formListArray, { tenant: undefined, collapsed: false }]);
                    }}
                  >
                    <span className={"text-hover-underlined"}>+ Add {fields.length === 0 ? "" : "another"} tenant</span>
                  </button>
                </span>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </div>
  );
};
export default TenantsPart;
