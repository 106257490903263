import React from "react";

export const NotPrivateEyeIcon = () => {
  return (
    <svg
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3023 0C8.18704 0 2.86082 3.84089 0 9.535C2.86082 15.2302 8.18704 19.07 14.3023 19.07C20.4176 19.07 25.7438 15.2302 28.6047 9.535C25.7438 3.84089 20.4176 0 14.3023 0ZM14.3023 15.4944C11.0106 15.4944 8.34291 12.8267 8.34291 9.535C8.34291 6.2433 11.0106 3.57558 14.3023 3.57558C17.594 3.57558 20.2618 6.2433 20.2618 9.535C20.2618 12.8267 17.594 15.4944 14.3023 15.4944Z"
        fill="black"
      />
      <path
        d="M14.3021 13.1106C16.2769 13.1106 17.8777 11.5098 17.8777 9.53506C17.8777 7.56032 16.2769 5.95947 14.3021 5.95947C12.3274 5.95947 10.7266 7.56032 10.7266 9.53506C10.7266 11.5098 12.3274 13.1106 14.3021 13.1106Z"
        fill="black"
      />
    </svg>
  );
};
