import React from "react";

const EditIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#141414"/>
            <path
                d="M22.5331 8.82516L21.1738 7.46579C20.5528 6.84472 19.5423 6.84475 18.9213 7.46579C18.3365 8.05058 9.40519 16.9826 8.80833 17.5795C8.74475 17.6431 8.70216 17.7275 8.68698 17.8102L8.00735 21.4805C7.98101 21.6228 8.02636 21.769 8.1287 21.8713C8.23115 21.9737 8.37734 22.019 8.51946 21.9927L12.1895 21.313C12.2743 21.2971 12.3576 21.2541 12.4202 21.1916L22.5331 11.0779C23.1556 10.4554 23.1557 9.44775 22.5331 8.82516ZM8.98792 21.012L9.39904 18.7918L11.208 20.6008L8.98792 21.012ZM12.1094 20.2593L9.74052 17.8903L18.3893 9.24086L20.7582 11.6099L12.1094 20.2593ZM21.9116 10.4564L21.3796 10.9885L19.0107 8.61938L19.5427 8.08732C19.8211 7.80894 20.274 7.80891 20.5524 8.08732L21.9116 9.4467C22.1907 9.72575 22.1907 10.1773 21.9116 10.4564Z"
                fill="white"/>
        </svg>
    );
};

export default EditIcon;