import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DatePicker, Form, Input } from "antd";
import { REQUIRED } from "../../helpers/validations";
import { US_DATE_FORMAT_WITH_TIME, US_TIME_FORMAT, momentSet8Am } from "../../helpers/constants";
import { IFollowUpsQuery, INote } from "./types";
import BaseButton from "../Shared/BaseButton";
import { useDispatch } from "react-redux";
import { editFollowUp } from "../../store/followUps/action";
import { addTimeAndFormatDate, currentDate, dateToMoment, formatDate } from "../../helpers/dateHelper";

export interface IEditFollowUp {
  setUpdates: Dispatch<SetStateAction<any>>;
  followUp: INote | null;
  setDropdownVisibility?: Dispatch<SetStateAction<any>>;
  queryAndPagination?: IFollowUpsQuery;
  isSnoozeModal?: boolean;
}

const EditFollowUp = ({ setUpdates, followUp, setDropdownVisibility, queryAndPagination }: IEditFollowUp) => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (followUp && Object.keys(followUp).length) {
      form.setFieldsValue({
        _id: followUp._id,
        title: followUp.title,
        description: followUp.description,
        todo_at: followUp.todo_at ? dateToMoment(followUp.todo_at) : null,
        todo_status: "in_progress",
      });
    }
  }, [followUp]);
  const dispatch = useDispatch();
  return (
    <div className="bg-white shadow-lg p-6 border border-purple-300 rounded-lg">
      <Form
        onFinish={(data) => {
          setUpdates({ ...data, queryAndPagination });
          if (setDropdownVisibility) {
            setDropdownVisibility((prevState: any) => {
              return {
                ...prevState,
                edit: !prevState.edit,
              };
            });
          }
          dispatch(editFollowUp(data, followUp?._id || ""));
        }}
        onFieldsChange={(changedFieldInfo: any) => {
          if (changedFieldInfo[0]?.name?.indexOf("title") > -1) {
            const title = form.getFieldValue("title");
            form.setFieldsValue({ title });
          }

          if (changedFieldInfo[0]?.name?.indexOf("todo_at") > -1) {
            const todo_at = form.getFieldValue("todo_at");
            form.setFieldsValue({ todo_at });
          }

          if (form.getFieldValue("title") && form.getFieldValue("todo_at")) {
            setDisable(false);
          } else {
            setDisable(true);
          }
        }}
        form={form}
      >
        <Form.Item name="_id" style={{ display: "none" }}>
          <Input type={"hidden"} />
        </Form.Item>
        <Form.Item name="title" rules={[REQUIRED]}>
          <Input autoComplete={"off"} placeholder={"Title"} />
        </Form.Item>
        <Form.Item name="description">
          <Input autoComplete={"off"} placeholder={"Description (optional)"} />
        </Form.Item>
        <Form.Item name="todo_status" hidden></Form.Item>
        <Form.Item
          name="todo_at"
          rules={[
            {
              required: true,
              message: "Please select a date",
            },
          ]}
        >
          <DatePicker
            placeholder={"Set Reminder Date"}
            onOpenChange={(state) => {
              const todoAtValueIsSet = form.getFieldValue(["todo_at"]);
              if (state && !todoAtValueIsSet) {
                form.setFieldsValue({
                  todo_at: addTimeAndFormatDate(
                    currentDate(),
                    { amount: 1, unit: "day" },
                    momentSet8Am,
                    US_TIME_FORMAT
                  ),
                });
              }
            }}
            format={US_DATE_FORMAT_WITH_TIME}
            showTime
            disabledDate={(value) => {
              return value && value < dateToMoment(currentDate("YYYY-MM-DD"));
            }}
          />
        </Form.Item>
        <Form.Item>
          <BaseButton
            classnames={"rb-primary-btn md"}
            type="submit"
            onClick={() => {
              let elem = document.getElementById(`edit-follow-up-btn-${followUp?._id}`);
              if (elem) {
                elem.click();
              }
            }}
            disabled={disable}
          >
            Submit
          </BaseButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditFollowUp;
