import React, { useState } from "react";
import Carousel from "./Carousel";
import { formatCurrency } from "helpers/currencyHelper";
import Modal from "components/Shared/Modal";
import ListingsView from "components/ListingView";
import { AnalyticsService } from "helpers/analyticsService";
import availableHousePlaceholder from "components/../assets/images/listing-ph.png";
import BaseButton from "components/Shared/BaseButton";
import { BathroomIconLead, BedroomIconLead } from "components/icons";
import { IImage } from "components/Listings/helpers/mockListings";
import { EVENT_NAME } from "helpers/analytics/eventEnums";
import ListingAvailability from "components/Shared/ListingAvailability";
import { generateListingAddress } from "components/Listings/helpers/helperFunctions";
import { renderNeighborhood } from "helpers/globalFunctions";

export interface IListingGridItem {
  listing: any;
  useCarousel?: boolean;
}

export const ImageWrapper = ({
  url,
  alt,
  onClick,
  loading,
}: {
  url: string;
  alt: string;
  onClick?: any;
  loading: "eager" | "lazy" | undefined;
}) => {
  const [orientation, setOrientation] = useState("w-full");

  const onLoad = ({ target: img }: { target: any; }) => {
    const { offsetHeight, offsetWidth } = img;
    if (offsetHeight < 220) {
      setOrientation("h-full");
    }
  };

  return (
    <div className="overflow-hidden h-56 rounded-t-lg bg-gray-50" onClick={() => onClick()}>
      <img
        onLoad={onLoad}
        // onClick={() => onClick()}
        src={url}
        alt={alt}
        className={`object-cover object-center group-hover:opacity-75 ${orientation} cursor-pointer`}
        loading={loading}
      />
    </div>
  );
};

const ListingGridItem = ({ listing, useCarousel }: IListingGridItem) => {
  const [showListingModal, setShowListingModal] = useState(false);
  const handleOnClick = (eventLocation: string) => {
    setShowListingModal(true);
    AnalyticsService.reportClickEvent(EVENT_NAME.LISTING_CLICK, eventLocation, "");
  };

  const getImageUrl = (imgs: IImage[]) => {
    for (let i of imgs) {
      if (i.url) return i.url;
    }
    return availableHousePlaceholder;
  };

  return (
    <div className="max-w-54 border bg-white border-gray-100 shadow-lg rounded-lg hover:bg-gray-50 hover:cursor-pointer  ">
      {useCarousel ? (
        <Carousel listing={listing} />
      ) : (
        <ImageWrapper
          url={getImageUrl(listing?.images)}
          alt={listing?.images[0]?.name}
          onClick={() => handleOnClick("listing_image")}
          loading="eager"
        />
      )}
      <div className="p-4">
        <BaseButton variant="link" onClick={() => handleOnClick("listing_address")}>
          <span className="text-lg">
            {generateListingAddress(listing)} {listing.neighbourhood && renderNeighborhood(listing)}
          </span>
        </BaseButton>
        <div className="my-1 text-base text-gray-700 gap-3 flex justify-normal">
          <div className="flex gap-1 items-center">{formatCurrency(listing.asked_rent)}</div>
          <div className="flex gap-1 items-center">
            <BedroomIconLead /> {listing.bedrooms} Bed
          </div>
          <div className="flex gap-1 items-center">
            <BathroomIconLead /> {listing.bathrooms} Bath
          </div>
        </div>
        <ListingAvailability listing={listing} />
      </div>
      <Modal show={showListingModal} onClose={setShowListingModal}>
        <>
          <ListingsView
            listingData={listing}
            config={{
              stickyHeader: true, // Doesnt work yet
              forceShowApplyButton: false,
              hideShareButton: true,
            }}
          />
        </>
      </Modal>
    </div>
  );
};

export default ListingGridItem;
