import React from "react";
import { useSelector } from "react-redux";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { REQUIRED_WITH_MESSAGE } from "../../../../../../../../helpers/validations";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import { getBedroomsOrBathroomsRadioBtnGroup, getRadioBtnGroup } from "../../../../../../../../helpers/globalFunctions";
import "./ListingsSeenAndApplied.scss"
import FormStepsTitle from "../../form-steps-title/FormStepsTitle";

const ListingsSeenAndApplied = () => {
  const requirementEnums = useSelector(requirementEnumsSelector);

  const listingsViewed = requirementEnums?.find(
    (item: EnumInterface) => item?.key === "listings_viewed",
  );

  const listingsApplied = requirementEnums?.find(
    (item: EnumInterface) => item?.key === "listings_applied",
  );

  return (
    <div className={"listings-seen-and-applied"}>
      <FormStepsTitle text={"How many rental properties have you toured so far?"}/>
      {getRadioBtnGroup(
        listingsViewed?.key,
        "",
        [REQUIRED_WITH_MESSAGE("Field ")],
        "purple-radiobutton-group bedrooms-radio-buttons parking-buttons",
        "parent-labels-to-center",
        listingsViewed?.options,
        "listing_viewed"
      )}
      <FormStepsTitle text={"How many rental properties have you applied to so far?"}/>
      {getBedroomsOrBathroomsRadioBtnGroup(
        listingsApplied?.key,
        "",
        [REQUIRED_WITH_MESSAGE("Field ")],
        "purple-radiobutton-group bedrooms-radio-buttons parking-buttons",
        "parent-labels-to-center",
        "listing_applied"

      )}
    </div>
  );
};

export default ListingsSeenAndApplied;
