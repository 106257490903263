import React from "react";
import { push } from "connected-react-router";
import qs from "query-string";
import { IObjectKeys } from "../../../../../../../helpers/globalTypes";
import { FormInstance } from "antd/es/form";
import { useDispatch } from "react-redux";
import BaseButton from "../../../../../../Shared/BaseButton";
import "./StepFormBottom.scss";

interface IStepFormBottom {
  isEditMode: boolean;
  queryObject: IObjectKeys;
  form: FormInstance<any>;
  setIsEditFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  reportStepActionToAnalytics: any;
}
const StepFormBottom = ({
  isEditMode,
  queryObject,
  form,
  setIsEditFormSubmitted,
  reportStepActionToAnalytics,
}: IStepFormBottom) => {
  const dispatch = useDispatch();
  return (
    <div>
      {isEditMode || (+queryObject.reqStep !== 1 && !isEditMode) ? (
        <div className={"req-form-steps-submit-buttons"}>
          {isEditMode && +queryObject.reqStep !== 1 && (
            <BaseButton
              classnames={"step-form-bottom"}
              variant="primary"
              type="button"
              onClick={() => {
                form.submit();
                setIsEditFormSubmitted(true);
              }}
            >
              Save and Close
            </BaseButton>
          )}
          {isEditMode && +queryObject.reqStep < 11 && +queryObject.reqStep !== 1 && (
            <BaseButton
              classnames={"step-form-bottom"}
              type={"submit"}
              onClick={() => {
                form.submit();
                window.scrollTo(0, 0);
              }}
            >
              {+queryObject.reqStep === 11 ? "Submit" : "Next"}
            </BaseButton>
          )}
          {!isEditMode && (
            <BaseButton
              classnames={"step-form-bottom"}
              type={"submit"}
              onClick={() => {
                form.submit();
                window.scrollTo(0, 0);
              }}
            >
              {+queryObject.reqStep === 11 ? "Submit" : "Next"}
            </BaseButton>
          )}
          {(+queryObject.reqStep !== 1 && !!!queryObject.previewMode) ||
          (+queryObject.reqStep > 2 && queryObject.previewMode) ? (
            <button
              type={"button"}
              className={"step-back"}
              onClick={() => {
                window.scrollTo(0, 0);
                reportStepActionToAnalytics("requirements_step_back_btn_click");
                queryObject.reqStep = +queryObject.reqStep - 1;
                dispatch(
                  push({
                    search: qs.stringify(queryObject),
                  })
                );
              }}
            >
              {"<"} Back
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default StepFormBottom;
