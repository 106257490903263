import React from "react";

export const TrashIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66797 0.96582H9.34204V1.41577H10.3077V0.902832C10.3079 0.405029 9.90307 0 9.40551 0H6.60449C6.10693 0 5.70215 0.405029 5.70215 0.902832V1.41577H6.66797V0.96582Z"
        fill="black"
      />
      <path
        d="M12.5382 5.24219H3.47224C3.22382 5.24219 3.02827 5.4541 3.04829 5.70178L3.80622 15.0739C3.84846 15.597 4.28486 16 4.80915 16H11.2011C11.7254 16 12.1618 15.597 12.204 15.0737L12.962 5.70178C12.9821 5.4541 12.7866 5.24219 12.5382 5.24219ZM5.66743 15.0004C5.65729 15.001 5.64716 15.0013 5.63715 15.0013C5.38398 15.0013 5.17145 14.8042 5.15571 14.5481L4.68073 6.85413C4.66437 6.58789 4.86689 6.35876 5.133 6.34241C5.39826 6.32629 5.62836 6.52832 5.64472 6.79468L6.11957 14.4886C6.13605 14.7549 5.93354 14.9839 5.66743 15.0004ZM8.49348 14.5184C8.49348 14.785 8.27729 15.0012 8.01057 15.0012C7.74384 15.0012 7.52766 14.785 7.52766 14.5184V6.82434C7.52766 6.55762 7.74384 6.34143 8.01057 6.34143C8.27717 6.34143 8.49348 6.55762 8.49348 6.82434V14.5184ZM11.3297 6.85278L10.8762 14.5468C10.8612 14.8033 10.6483 15.0012 10.3946 15.0012C10.3851 15.0012 10.3754 15.001 10.3658 15.0005C10.0996 14.9847 9.89643 14.7562 9.91218 14.49L10.3655 6.7959C10.3812 6.52966 10.609 6.32654 10.8759 6.34229C11.1422 6.35791 11.3453 6.58655 11.3297 6.85278Z"
        fill="black"
      />
      <path
        d="M14.2077 3.7522L13.8906 2.80151C13.807 2.5509 13.5724 2.38184 13.3081 2.38184H2.70152C2.43736 2.38184 2.20262 2.5509 2.11912 2.80151L1.80199 3.7522C1.74083 3.93555 1.82042 4.12256 1.96898 4.21582C2.02952 4.25378 2.10118 4.27661 2.17992 4.27661H13.8298C13.9086 4.27661 13.9803 4.25378 14.0408 4.2157C14.1893 4.12244 14.2689 3.93543 14.2077 3.7522Z"
        fill="black"
      />
    </svg>
  );
};
