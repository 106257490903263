import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import qs from "query-string";
import { urlQueryToObject } from "../../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { useLocation, useParams } from "react-router-dom";
import {
  editRequirementSuccessSelector,
  renterStatusSelector,
  requirementFormDataSelector,
  requirementFormEdittingSelector,
  updatedReqSuccessSelector,
} from "../../../../../store/requirement/selectors";
import { useForm } from "antd/es/form/Form";
import Form from "antd/es/form";
import {
  clearRenterStatus,
  getRenterRequirement,
  getRequirementAgentInfo,
  setRequirementEditFormData,
} from "../../../../../store/requirement/action";
import { IObjectKeys } from "../../../../../helpers/globalTypes";
import { IlocationItem } from "./components/move-in-date-and-area/areas-section/types/types";
import { getRequirementsSubmitData } from "../../helpers";
import StepProgress from "./components/step-progress/StepProgress";
import { IpetsListItem } from "./components/pets/PetsInfo";
import { TOKEN_ENUM, getDraftRenterToken } from "../../../../../helpers/authUtils";
import {
  changeToNextStepAfterSubmit,
  checkRenterInfoStatusAndRedirect,
  handleSubmitRenterForm,
  setFieldValuesForEditRequirement,
  setRequirementFormFields,
} from "../../../helpers/requirementFunctions";
import { singleLeadDataSelector } from "../../../../../store/leads/selectors";
import { userSelector } from "../../../../../store/user/selectors";
import { steps } from "../../../helpers/steps";
import StepFormBottom from "./components/step-form-bottom/StepFormBottom";
import { getRequirementEnums } from "../../../../../store/enums/action";
import "./index.scss";
import { AuthFormInterface } from "../../../../../helpers/authTypes";
import { signIn } from "../../../../../store/user/action";
import { removeSpaceAndCapitalizationFromString, errorArrayToString } from "helpers/globalFunctions";
import { AnalyticsService } from "helpers/analyticsService";
import moment from "moment";
import { formatDate } from "../../../../../helpers/dateHelper";
import { renterPortalRoute } from "helpers/clientRoutes";

const RequirementFormSteps = () => {
  const user = useSelector(userSelector);
  const isUserLoggedIn =
    localStorage.getItem(TOKEN_ENUM.ACCESS_TOKEN) && localStorage.getItem(TOKEN_ENUM.REFRESH_TOKEN) && user.role !== "Renter";
  const previewPrefix = isUserLoggedIn ? "preview_" : "";
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [form] = useForm();
  const leadData = useSelector(singleLeadDataSelector);
  const { agent_id } = useParams();
  const requirement_id = new URLSearchParams(window.location.search).get("requirement_id");
  const renterExists = getDraftRenterToken();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const edittedData = useSelector(requirementFormEdittingSelector);
  const isEditMode = window.location.pathname.includes("edit");
  const [isNewRenter, setIsNewRenter] = useState(false);
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(null);
  const [showExistError, setShowExistError] = useState(false);
  const renterStatus = useSelector(renterStatusSelector);
  const communicationMWrapperRef = useRef<HTMLElement>();
  const reqUpdatedSuccess = useSelector(updatedReqSuccessSelector);
  const requirementFormData = useSelector(requirementFormDataSelector);
  const [startDateValue, setStartDateValue] = useState(null);
  const renterToken = getDraftRenterToken();
  const [isSubmitForm] = useState(false);
  const [locations, setLocations] = useState<IlocationItem[]>([]);
  const [petsList, setPetsList] = useState<Array<IpetsListItem>>([]);
  const [isEditFormSubmitted, setIsEditFormSubmitted] = useState(false);
  const [usefulFieldValues, setUsefulFeildValues] = useState({
    student_or_employed: undefined,
  });
  const editRequirementSuccess = useSelector(editRequirementSuccessSelector);

  const changeStep = (reqStep: number) => {
    queryObject.reqStep = reqStep;
    dispatch(
      push({
        search: qs.stringify(queryObject),
      })
    );
  };

  const loginFormSubmit = async (data: AuthFormInterface) => {
    dispatch(signIn(data, ""));
  };

  const requirementSteps = steps({
    edittedData,
    isEditMode,
    isNewRenter,
    form,
    setIsNewRenter,
    setIsLoginFormVisible,
    showExistError,
    setShowExistError,
    changeStep,
    communicationMWrapperRef,
    setStartDateValue,
    isSubmitForm,
    setLocations,
    usefulFieldValues,
    setPetsList,
    isLoginFormVisible,
    loginFormSubmit,
    isManual: queryObject.is_manual,
  });

  const reportStepActionToAnalytics = (action: string = "requirements_step_submit_click", data: any = {}) => {
    const editModePrefix = isEditMode ? "edit_mode_" : "";
    const thisStep = requirementSteps.filter((step) => step.stepNumber.toString() === queryObject.reqStep.toString());
    const label = removeSpaceAndCapitalizationFromString(thisStep[0].name);
    if (label === "contact") {
      delete data["name"];
      delete data["phone"];
      delete data["email"];
    }
    if (label === "pets") {
      data.pets = petsList;
    }
    if (label === "move-in_&_location") {
      const response = data.response;
      data = {
        response,
        area: locations,
        start_date: startDateValue && formatDate(startDateValue),
        move_in_date_start: data["move_in_date_start"] && formatDate(data["move_in_date_start"]),
        move_in_date_end: data["move_in_date_end"] && formatDate(data["move_in_date_end"]),
      };
    }
    data.step_index = queryObject.reqStep;

    AnalyticsService.reportRequirementsFunnel({
      action: `${editModePrefix}${action}`,
      label,
      stepData: data,
    });
  };

  const handleSubmitErrorForAnalytics = (data: IObjectKeys) => {
    data.values.response = "error";
    data.values.errorMessage = errorArrayToString(data.errorFields);
    reportStepActionToAnalytics("requirements_step_submit_click_ERROR", data.values);
  };

  const handleSubmitRequirementsData = (data: IObjectKeys) => {
    reportStepActionToAnalytics("requirements_step_submit_click", {
      response: "success",
      ...data,
    });

    const formData: any = getRequirementsSubmitData({
      data,
      locations,
      isEditMode,
      requirementFormData,
      startDateValue,
      petsList,
      agent_id,
      stepNumber: queryObject.reqStep,
    });

    handleSubmitRenterForm({
      isEditMode,
      queryObject,
      formData,
      dispatch,
      requirementSteps,
      requirementFormData,
      data,
      previewPrefix,
      requirement_id,
      isManual: queryObject?.is_manual === "true",
    });
  };

  useEffect(() => {
    if (requirementFormData.pets && requirementFormData.pets.length) {
      setPetsList(requirementFormData.pets);
    }
  }, [requirementFormData.pets]);

  useEffect(() => {
    if (!queryObject.previewMode) {
      checkRenterInfoStatusAndRedirect({
        dispatch,
        queryObject,
        renterExists,
        isEditMode,
      });
    } else {
      if (+queryObject.reqStep === 1) {
        queryObject.reqStep = 2;
        dispatch(push(qs.stringify(queryObject)));
      }
    }
  }, [renterExists, queryObject]);

  useEffect(() => {
    if (isEditMode && Object.keys(leadData).length) {
      setIsNewRenter(true);
      setUsefulFeildValues({
        student_or_employed: leadData.requirement.student_or_employed,
      });
      const fieldsToSetRenterData = {
        name: leadData.renter.name,
        phone: leadData.renter.phone,
        email: leadData.renter.email,
      };
      dispatch(
        setRequirementEditFormData({
          ...fieldsToSetRenterData,
          ...leadData.requirement,
        })
      );
    }
  }, [isEditMode, leadData]);

  useEffect(() => {
    if (renterStatus) {
      if (renterStatus.token && renterStatus.createdDraft) {
        localStorage.setItem("renterDraft_token", renterStatus.token);
        queryObject.reqStep = 2;
        dispatch(
          push({
            search: qs.stringify(queryObject),
          })
        );
        dispatch(clearRenterStatus());
      }
    }
  }, [renterStatus]);

  useEffect(() => {
    changeToNextStepAfterSubmit({
      reqUpdatedSuccess,
      editRequirementSuccess,
      dispatch,
      queryObject,
      requirementSteps,
      isEditFormSubmitted,
    });
  }, [reqUpdatedSuccess, editRequirementSuccess]);

  useEffect(() => {
    if (isEditMode) {
      if (Object.keys(leadData).length) {
        const fieldsToSet: IObjectKeys = setFieldValuesForEditRequirement(leadData, setUsefulFeildValues);
        const fieldsToSetRenterData = {
          name: leadData.renter.name,
          phone: leadData.renter.phone,
          email: leadData.renter.email,
          student_or_employed: usefulFieldValues.student_or_employed
            ? usefulFieldValues.student_or_employed
            : leadData.requirement.student_or_employed,
        };
        form.setFieldsValue({ ...fieldsToSet, ...fieldsToSetRenterData });
      }
    }
  }, [leadData]);

  useEffect(() => {
    if (!isEditMode) {
      setRequirementFormFields(requirementFormData, form, dispatch, renterToken || "");
      if (requirementFormData.student_or_employed) {
        setUsefulFeildValues({
          student_or_employed: requirementFormData.student_or_employed,
        });
      }
    }
  }, [isEditMode, requirementFormData, renterToken]);

  useEffect(() => {
    dispatch(getRequirementAgentInfo(agent_id));
  }, []);

  useMemo(() => {
    dispatch(getRequirementEnums());
    isEditMode && dispatch(getRenterRequirement({ requirements_id: requirement_id }));
  }, [isEditMode]);

  useEffect(() => {
    if (isEditFormSubmitted && editRequirementSuccess) {
      // dispatch(push(`/lead-flow/requirement/${requirement_id}`));
      dispatch(push(renterPortalRoute("")));
      setIsEditFormSubmitted(false);
    }
  }, [isEditFormSubmitted, editRequirementSuccess]);

  return (
    <div className={"requirement-form-steps-wrapper text-sm"}>
      <Form
        form={form}
        onFinish={handleSubmitRequirementsData}
        onFinishFailed={handleSubmitErrorForAnalytics}
        className={"requirement-form-steps-wrapper-form"}
        onChange={() => {
          const values = form.getFieldsValue();
          if (values["student_or_employed"]) {
            setUsefulFeildValues({
              ...usefulFieldValues,
              student_or_employed: values["student_or_employed"],
            });
          }
        }}
      >
        {requirementSteps.map((item, stepNumber) => {
          return (
            <React.Fragment key={stepNumber}>
              {+queryObject.reqStep === item.stepNumber && (
                <>
                  {!isLoginFormVisible && (
                    <StepProgress stepNumber={item.stepNumber} name={item.name} totalSteps={requirementSteps.length} />
                  )}
                  {item.component}
                </>
              )}
            </React.Fragment>
          );
        })}
      </Form>
      <StepFormBottom
        isEditMode={isEditMode}
        queryObject={queryObject}
        form={form}
        setIsEditFormSubmitted={setIsEditFormSubmitted}
        reportStepActionToAnalytics={reportStepActionToAnalytics}
      />
    </div>
  );
};

export default memo(RequirementFormSteps);
