import { Button, Dropdown, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Triangle from "../../../../../icons/triangle/Triangle";
import useOnClickOutside from "hooks/useOnClickOutside";
import "./styles.scss";
import { TagIcon } from "components/icons/Tag";
import { useDispatch, useSelector } from "react-redux";
import { leadUpdateTagsErrorSelector, singleLeadDataSelector } from "store/leads/selectors";
import { getLeadData, updateLeadTags } from "store/leads/action";
import Message from "components/Shared/Message";
import { useDemoContext } from "libs/contextLib";
import SingleTag from "../single-tag/SingleTag";
import AddTag from "../../icons/AddTag";
import { updateLandlordTags } from "store/landlords/action";

const TagDropdown = ({
  id,
  existingTags,
  tags,
  type,
}: {
  id: string;
  existingTags: string[];
  tags: Array<{ label: string; name: string; icon: string }>;
  type: string;
}) => {
  const dispatch = useDispatch();
  const updateTagsError = useSelector(leadUpdateTagsErrorSelector);
  const [isVisible, setIsVisible] = useState(false);
  const [tagMessage, setTagMessage] = useState("");
  const overlayRef = useRef(null);
  const overlayButtonRef = useRef(null);
  const { isDemo, demoAction } = useDemoContext();
  const [currentTags, setCurrentTags] = useState(tags);

  useEffect(() => {
    setCurrentTags(tags.filter((tag) => existingTags?.includes(tag.name)));
  }, [existingTags]);

  const handleClickTag = async (e: any) => {
    if (isDemo) {
      demoAction("lead");
    } else {
      const targetItem = e.target.closest(".tag-menu--list--item");
      if (!targetItem) return;
      if (targetItem.dataset.name) {
        let tags = [...existingTags, targetItem.dataset.name];
        if (existingTags.includes(targetItem.dataset.name)) {
          tags = existingTags.filter((tag) => tag !== targetItem.dataset.name);
        }
        if (type === "lead") {
          dispatch(
            updateLeadTags({
              tags,
              requirements_id: id,
            })
          );
          dispatch(getLeadData(id));
        } else if (type === "landlord") {
          dispatch(
            updateLandlordTags({
              tags,
              landlord_id: id,
            })
          );
        }
      }
      setIsVisible(false);
    }
  };

  useOnClickOutside(overlayRef, () => setIsVisible(false), overlayButtonRef);

  const menu = (
    <div className="tag-menu" onClick={(e) => e.stopPropagation()} ref={overlayRef}>
      <Triangle customClass={"triangle-icon"} />
      <ul className="tag-menu--list" onClick={handleClickTag}>
        {tags.map((tag, index) => {
          return (
            <li
              className={existingTags?.includes(tag.name) ? "tag-menu--list--item active-tag" : "tag-menu--list--item"}
              data-name={tag.name}
              key={index}
            >
              <div className={"tag-menu--list--item--wrapper"}>
                <span className="tag">{`${tag.icon} ${tag.label}`}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div className="flex items-center gap-2">
      {currentTags.map((tag, index) => (
        <SingleTag
          icon={tag.icon}
          label={tag.label}
          name={tag.name}
          classnames={`tag-wrapper-${index}-child lead-page`}
          hover={true}
          key={index}
          type={type}
        />
      ))}
      <Dropdown
        className={tags.length ? "tag-dropdown transparet-button move-left" : "tag-dropdown transparet-button"}
        trigger={["click"]}
        placement={"bottomCenter"}
        data-test-id="openLandlordContactOverlay"
        overlayClassName="tag-dropdown-overlay"
        overlay={menu}
        visible={isVisible}
        onVisibleChange={() => setIsVisible((prev) => !prev)}
        getPopupContainer={() => {
          return document.getElementById("tag-dropdown-button") as HTMLElement;
        }}
      >
        <Tooltip overlayClassName={"fu-page tags-tooltip"} placement="top" title="Add/Remove Tag" color="white">
          <Button id="tag-dropdown-button" ref={overlayButtonRef}>
            <div className="add-icons">
              <TagIcon />
              <AddTag color={isVisible ? "#AB82DC" : ""} />
            </div>
          </Button>
        </Tooltip>
      </Dropdown>
      {tagMessage && <Message messageClassName={"alert-new"} text={`💰 Potential Sale ${tagMessage}`} type="noIcon" />}
    </div>
  );
};

export default TagDropdown;
