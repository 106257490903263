import React from "react";
import "./MatchingListingsEmptyState.scss";
import BaseButton from "../../../BaseButton";
import { AnalyticsService } from "helpers/analyticsService";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import routes from "../../../../../routes/routesCode";

const MatchingListingsEmptyState = ({ canAddListing = true, children }: { canAddListing: boolean; children?: any; }) => {
  const dispatch = useDispatch();

  const addListingHandler = () => {
    AnalyticsService.reportAddListingClick();
    dispatch(push(routes.manageListing.path));
  };

  return (
    <div className={"matching-listings-empty-state"}>
      <p className={"matching-listings-empty-state-icon"}>😔</p>
      <p className={"matching-listings-empty-state-title"}>No relevant listings found</p>
      <span className={"matching-listings-empty-state-desc"}>
        {children ?? ` We didn't find any relevant matches to this lead. Add more listings for better chances to match to it`}
      </span>
      {canAddListing && <BaseButton classnames={"rb-primary-btn"} onClick={addListingHandler}>
        Add a Listing
      </BaseButton>}
    </div>
  );
};

export default MatchingListingsEmptyState;
