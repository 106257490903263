import React, { memo, useEffect, useState } from "react";
import FilterFormTitle from "../filter-form-title/FilterFormTitle";
import "./MoveInDateFilter.scss";
import { FormInstance } from "antd/es/form";
import FilterDatePicker from "./filter-date-picker/FilterDatePicker";
import moment from "moment";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../../../helpers/listingsCreateGetSearchQuery";

const MoveInDateFilter = ({
  form,
  isClearAllClicked,
}: {
  form: FormInstance;
  isClearAllClicked: boolean;
}) => {
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [isQuickOptionsSelected, setIsQuickOptionsSelected] = useState(false);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  useEffect(() => {
    if (!isOtherSelected) {
      form.resetFields(["availability_date_to"]);
    } else {
      form.resetFields(["availability_dates"]);
      form.submit();
    }
  }, [isOtherSelected]);

  useEffect(() => {
    if (queryObject.availability_date_to && !isQuickOptionsSelected) {
      form.setFieldsValue({
        availability_date_to: moment(queryObject.availability_date_to),
      });
      setIsOtherSelected(true);
    }
  }, [queryObject, isQuickOptionsSelected]);

  useEffect(() => {
    if (isClearAllClicked) {
      setIsOtherSelected(false);
    }
  }, [isClearAllClicked]);
  return (
    <div className={"move-in-date-filter-wrapper"}>
      <FilterFormTitle title={"Move-In Date"} />
      <div className={"move-in-date-filter-wrapper-options"}>
        {<FilterDatePicker form={form} />}
      </div>
    </div>
  );
};

export default memo(MoveInDateFilter);
