import { numberFormatter } from "./globalFunctions";

export function formatCurrency(amount: number | string | null , digits: number = 3, suffix: string = ''): string {
  try {
    if (amount === null || amount === '') {
      return 'NA';
    }

    const parsedAmount = typeof amount === "string" ? parseFloat(amount) : amount;

    if (isNaN(parsedAmount)) {
      return 'NA';
    }

    const threshold = 1000000;

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

    if (parsedAmount >= threshold) {
      return `$${numberFormatter(parsedAmount, digits)}${suffix}`;
    } else {
      return `${formatter.format(parsedAmount)}${suffix}`;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return 'NA'; // Return 'NA' in case of any unexpected error or invalid data.
  }
}