import React, { useEffect } from "react";
import { IObjectKeys } from "../../helpers/globalTypes";
import { getPrivacyPolicyContent } from "../../contentful/api";
import {
  fillPrivacyPolicyData,
  loadingTermsOrPrivacyData,
  loadingTermsOrPrivacyDataSuccess,
} from "../../store/privacyPolicy/action";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { generateHtmlFromDocument } from "../../contentful/RichTextRenderer";
import {
  privacyMainContentSelector,
  privacyTitleSelector,
  termsAndPrivacyLoadingSelector,
} from "../../store/privacyPolicy/selectors";
import { Col, Row, Spin } from "antd";
import BackToPreviousSearchButton from "../Landlord/BackToPreviousSearch";

const PrivacyPolicyWrapper = () => {
  const dispatch = useDispatch();
  const privacyTitle = useSelector(privacyTitleSelector);
  const loading = useSelector(termsAndPrivacyLoadingSelector);
  const privacyMainContent = useSelector(privacyMainContentSelector);

  useEffect(() => {
    try {
      (async () => {
        dispatch(loadingTermsOrPrivacyData());
        const entries: IObjectKeys = await getPrivacyPolicyContent();
        if (entries?.fields) {
          dispatch(fillPrivacyPolicyData(entries?.fields));
        }
        dispatch(loadingTermsOrPrivacyDataSuccess());
      })();
    } catch (e) {
      dispatch(loadingTermsOrPrivacyDataSuccess());
    }
  }, []);

  return (
    <Row>
      <Col xs={24}>
        <Spin spinning={loading}>
          <div>
            <h1 className={"privacy-policy-title"}>{privacyTitle}</h1>
            {privacyMainContent && !isEmpty(privacyMainContent)
              ? generateHtmlFromDocument(privacyMainContent)
              : "RentBase Privacy Policy"}
          </div>
        </Spin>
      </Col>
    </Row>
  );
};

export default PrivacyPolicyWrapper;
