import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import {
    GET_NOTIFICATIONS, GET_READ_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS,
    GET_UNREAD_NOTIFICATIONS_COUNT,
    MAKE_NOTIFICATIONS_AS_READ,
    MAKE_NOTIFICATIONS_AS_UNREAD,
    MARK_ALL_READ,
} from "./types";
import {
    getNotificationsListFail,
    getNotificationsListSuccess, getReadNotificationsListFail, getReadNotificationsListSuccess,
    getUnreadNotificationsCountFail,
    getUnreadNotificationsCountSuccess, getUnreadNotificationsListFail, getUnreadNotificationsListSuccess,
    makeNotificationAsReadFail,
    makeNotificationAsReadSuccess,
    makeNotificationAsUnreadFail,
    makeNotificationAsUnreadSuccess,
    markAllReadSagaFail,
    markAllReadSagaSuccess,
} from "./action";
import NotificationsApi from "../../api/Notifications";

function* getNotificationsListSaga({ payload }: any) {
    try {
        const response = yield call(
            NotificationsApi.getNotifications, payload
        );
        yield put(getNotificationsListSuccess({ ...response, fillAll: payload.fillAll }));
    } catch (e) {
        yield put(getNotificationsListFail(e));
    }
}
function* getUnreadNotificationsListSaga({ payload }: any) {
    try {
        const response = yield call(
            NotificationsApi.getNotifications, payload
        );
        yield put(getUnreadNotificationsListSuccess({ ...response, replaceExistingData: payload.replaceExistingData }));
    } catch (e) {
        yield put(getUnreadNotificationsListFail(e));
    }
}
function* getReadNotificationsListSaga({ payload }: any) {
    try {
        const response = yield call(
            NotificationsApi.getNotifications, payload
        );
        yield put(getReadNotificationsListSuccess({ ...response, replaceExistingData: payload.replaceExistingData }));
    } catch (e) {
        yield put(getReadNotificationsListFail(e));
    }
}

function* getUnreadNotificationsCountSaga({ payload }: any) {
    try {
        const response = yield call(
            NotificationsApi.getUnreadNotificationsCount, payload
        );
        yield put(getUnreadNotificationsCountSuccess(response?.count || 0));
    } catch (e) {
        yield put(getUnreadNotificationsCountFail(e));
    }
}

function* makeNotificationsAsReadSaga({ payload }: any) {
    try {
        const response = yield call(
            NotificationsApi.makeNotificationAsRead, payload
        );
        yield put(makeNotificationAsReadSuccess(response ));
    } catch (e) {
        yield put(makeNotificationAsReadFail(e));
    }
}
function* makeNotificationsAsUnreadSaga({ payload }: any) {
    try {
        const response = yield call(
            NotificationsApi.makeNotificationAsUnread, payload
        );
        yield put(makeNotificationAsUnreadSuccess(response ));
    } catch (e) {
        yield put(makeNotificationAsUnreadFail(e));
    }
}

function* markAllReadSaga({ payload }: any) {
    try {
        const response = yield call(
            NotificationsApi.markAllReadSaga, payload
        );
        yield put(markAllReadSagaSuccess(response));
    } catch (e) {
        yield put(markAllReadSagaFail(e));
    }
}

function* watchGetListingsListSaga() {
    yield takeLatest(GET_NOTIFICATIONS, getNotificationsListSaga);
}
function* watchGetReadNotificationsListSaga() {
    yield takeLatest(GET_READ_NOTIFICATIONS, getReadNotificationsListSaga);
}
function* watchGetUnreadNotificationsListSaga() {
    yield takeLatest(GET_UNREAD_NOTIFICATIONS, getUnreadNotificationsListSaga);
}

function* watchGetUnreadNotificationsCountSaga() {
    yield takeLatest(GET_UNREAD_NOTIFICATIONS_COUNT, getUnreadNotificationsCountSaga);
}

function* watchMakeNotificationAsReadSaga() {
    yield takeLatest(MAKE_NOTIFICATIONS_AS_READ, makeNotificationsAsReadSaga);
}

function* watchMakeNotificationAsUnreadSaga() {
    yield takeLatest(MAKE_NOTIFICATIONS_AS_UNREAD, makeNotificationsAsUnreadSaga);
}
function* watchMarkAllReadSaga() {
    yield takeLatest(MARK_ALL_READ, markAllReadSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetListingsListSaga),
        fork(watchGetUnreadNotificationsCountSaga),
        fork(watchMakeNotificationAsReadSaga),
        fork(watchMakeNotificationAsUnreadSaga),
        fork(watchMarkAllReadSaga),
        fork(watchGetReadNotificationsListSaga),
        fork(watchGetUnreadNotificationsListSaga),
    ]);
}
