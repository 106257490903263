import React, { useEffect } from "react";
import { Col, Row } from "antd";
import LandlordsList from "components/Landlords/LandlrodsList";
import { useHistory } from "react-router-dom";
import { useDemoContext } from "libs/contextLib";
const Landlords = () => {
  const history = useHistory();
  const { isDemo } = useDemoContext();
    useEffect(() => {
    if (isDemo) history.push("/demo/landlords");
  }, [history, isDemo]);
  return (
    <div>
      <Row className="flex-centered">
        <Col span={24}>
          <LandlordsList />
        </Col>
      </Row>
    </div>
  );
};

export default Landlords;
