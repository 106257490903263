export const GET_DASHBOARD_LISTINGS = "GET_DASHBOARD_LISTINGS";
export const GET_DASHBOARD_LISTINGS_SUCCESS = "GET_DASHBOARD_LISTINGS_SUCCESS";
export const GET_DASHBOARD_LISTINGS_ERROR = "GET_DASHBOARD_LISTINGS_ERROR";

export const GET_DASHBOARD_LANDLORDS = "GET_DASHBOARD_LANDLORDS";
export const GET_DASHBOARD_LANDLORDS_SUCCESS = "GET_DASHBOARD_LANDLORDS_SUCCESS";
export const GET_DASHBOARD_LANDLORDS_ERROR = "GET_DASHBOARD_LANDLORDS_ERROR";

export const GET_DASHBOARD_LEADS = "GET_DASHBOARD_LEADS";
export const GET_DASHBOARD_LEADS_SUCCESS = "GET_DASHBOARD_LEADS_SUCCESS";
export const GET_DASHBOARD_LEADS_ERROR = "GET_DASHBOARD_LEADS_ERROR";

export const GET_DASHBOARD_FOLLOW_UPS = "GET_DASHBOARD_FOLLOW_UPS";
export const GET_DASHBOARD_FOLLOW_UPS_SUCCESS = "GET_DASHBOARD_FOLLOW_UPS_SUCCESS";
export const GET_DASHBOARD_FOLLOW_UPS_ERROR = "GET_DASHBOARD_FOLLOW_UPS_ERROR";

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_ERROR = "GET_DASHBOARD_DATA_SUCCESS";

export const ADD_DASHBOARD_FOLLOW_UP = "ADD_DASHBOARD_FOLLOW_UP";
export const ADD_DASHBOARD_FOLLOW_UP_SUCCESS = "ADD_DASHBOARD_FOLLOW_UP_SUCCESS";
export const ADD_DASHBOARD_FOLLOW_UP_FAIL = "ADD_DASHBOARD_FOLLOW_UP_FAIL";
export const SET_DASHBOARD_FOLLOW_UP_SUCCESS = "SET_DASHBOARD_FOLLOW_UP_SUCCESS";

export const EDIT_DASHBOARD_FOLLOW_UP = "EDIT_DASHBOARD_FOLLOW_UP";
export const EDIT_DASHBOARD_FOLLOW_UP_SUCCESS = "EDIT_DASHBOARD_FOLLOW_UP_SUCCESS";
export const EDIT_DASHBOARD_FOLLOW_UP_SUCCESS_CLEAR = "EDIT_DASHBOARD_FOLLOW_UP_SUCCESS_CLEAR";
export const EDIT_DASHBOARD_FOLLOW_UP_FAIL = "EDIT_DASHBOARD_FOLLOW_UP_FAIL";

export const SNOOZE_DASHBOARD_FOLLOW_UP = "SNOOZE_DASHBOARD_FOLLOW_UP";
export const SNOOZE_DASHBOARD_FOLLOW_UP_SUCCESS = "SNOOZE_DASHBOARD_FOLLOW_UP_SUCCESS";
export const SNOOZE_DASHBOARD_FOLLOW_UP_FAIL = "SNOOZE_DASHBOARD_FOLLOW_UP_FAIL";

export const DELETE_DASHBOARD_FOLLOW_UP = "DELETE_DASHBOARD_FOLLOW_UP";
export const DELETE_DASHBOARD_FOLLOW_UP_SUCCESS = "DELETE_DASHBOARD_FOLLOW_UP_SUCCESS";
export const DELETE_DASHBOARD_FOLLOW_UP_FAIL = "DELETE_DASHBOARD_FOLLOW_UP_FAIL";

export const SET_SNOOZE_SUCCESS_TO_FALSE = "SET_SNOOZE_SUCCESS_TO_FALSE"






