import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SignUpForm from "./sign-up-form/SignUpForm";
import { SignUpFormInterface } from "../../helpers/authTypes";
import SignInIntro from "../Shared/sign-in-intro/SignInIntro";
import './index.scss';
import { signUpAgent } from "../../store/user/action";
import { agentSignUpSuccessSelector } from "../../store/user/selectors";
import SignUpVerifyMsg from "./sign-up-verify-msg/SignUpVerifyMsg";
import { useLocation } from "react-router-dom";
import ReferralsModal from "components/Sidebar/components/referrals-unlocked/components/referrals-modal/ReferralsModal";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";

const SignUp = () => {
    const dispatch = useDispatch();
    const agentSignedInSuccess = useSelector(agentSignUpSuccessSelector);
    const { search } = useLocation();
    const _newURLSearchParams = new URLSearchParams(search);
    const queryObject = urlQueryToObject(_newURLSearchParams);
    const [referralModalVisible, setReferralModalVisible] = useState(false);

    const onSubmit = async (data: SignUpFormInterface) => {
        if (queryObject?.referring_agent) {
            data = {
                ...data,
                enrichments: { referring_agent: queryObject?.referring_agent },
            };
        }
        dispatch(signUpAgent(data));
        localStorage.removeItem("leadsPageRequested");
    };

    useEffect(() => {
        if (agentSignedInSuccess) {
            localStorage.setItem("new-member-from-sign-up", "true");
        }
    }, [agentSignedInSuccess]);

    useEffect(() => {
        if (search.includes("referring_agent")) {
            setReferralModalVisible(true);
        }
    }, [search]);

    return (
        <>
            {
                !agentSignedInSuccess ?
                    <div className="sign-up-wrapper w-100">
                        <SignInIntro isSignIn={false} />
                        <SignUpForm onSubmit={onSubmit} />
                    </div> :
                    <SignUpVerifyMsg />
            }
            {
                <ReferralsModal toggle={referralModalVisible} setToggle={setReferralModalVisible} />
            }
        </>
    );
};

export default SignUp;
