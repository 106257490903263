import React, { useEffect } from "react";
import "./styles.scss";
import { TOKEN_ENUM } from "helpers/authUtils";

interface ILeadFlowWrapperProps {
    children: JSX.Element | string;
    classNames?: string;
}

const LeadFlowWrapper = ({
    children, classNames
}: ILeadFlowWrapperProps) => {
    useEffect(() => {
        localStorage.removeItem(TOKEN_ENUM.GUEST_ACCESS_TOKEN);
    }, []);
    return (
        <div className={`requirement-auth-section-wrapper success-page ${classNames}`}>
            {children}
        </div>
    );
};

export default LeadFlowWrapper;
