import React from "react";
import Basics from "./Basics";
import { IFeesProps } from "./types";
import Fees from "./Fees";

const AvailableForm = ({ showDepositAmount, showOtherBFInput, showPetDepositAmount }: IFeesProps) => {
  return (
    <div className={"available"}>
      <Basics />
      <Fees
        showDepositAmount={showDepositAmount}
        showOtherBFInput={showOtherBFInput}
        showPetDepositAmount={showPetDepositAmount}
      />
    </div>
  );
};
export default AvailableForm;
