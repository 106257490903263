import React from "react";

export const EditIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4708 2.06852L14.9303 0.527892C14.2265 -0.175981 13.0813 -0.175947 12.3774 0.527892C11.7147 1.19066 1.59255 11.3136 0.916105 11.9901C0.844054 12.0621 0.795776 12.1579 0.778577 12.2515L0.00833116 16.4112C-0.0215185 16.5725 0.02988 16.7382 0.145858 16.8541C0.26197 16.9702 0.427653 17.0215 0.588722 16.9917L4.74804 16.2213C4.84417 16.2034 4.93866 16.1547 5.00952 16.0838L16.4708 4.6216C17.1763 3.91611 17.1764 2.77412 16.4708 2.06852ZM1.11964 15.8803L1.58558 13.364L3.63574 15.4143L1.11964 15.8803ZM4.65737 15.0272L1.97259 12.3423L11.7745 2.53964L14.4593 5.22461L4.65737 15.0272ZM15.7665 3.91727L15.1636 4.52027L12.4788 1.8353L13.0817 1.2323C13.3972 0.9168 13.9105 0.916767 14.226 1.2323L15.7665 2.77292C16.0828 3.08918 16.0828 3.60097 15.7665 3.91727Z"
        fill="black"
      />
    </svg>
  );
};
