import React from "react";

const ReadBeforeApply = ({ renterName, agentName }: { renterName: string; agentName: string }) => {
  return (
    <>
      <div className="mb-8 pb-4 border-b border-gray-200">
        <h3 className="font-bold text-2xl">Please read before continuing</h3>
      </div>
      <div>
        <h4 className="text-xl">{"Requested reports:"}</h4>
        <p>TransUnion Credit, Criminal & Eviction Reports</p>
      </div>
      <br />
      <div>
        <h4 className="text-xl">{"Application Fee: $36"}</h4>
        <p>{"(You won't be charged until you submitted reports.)"}</p>
      </div>
      <div className="p-4 mt-4 rounded-lg bg-orange-100">
        <p className="font-semibold">Important! Please read</p>
        <ul>
          <li>
            Each applicant must use his/her own email address to apply. The reports are linked to the email address that
            you provide.
          </li>
          <li>If you have questions regarding the property, please contact {agentName} directly.</li>
          <li>
            You will receive a copy of any TransUnion ShareAble screening report submitted after {agentName} pulls them.
          </li>
        </ul>
      </div>
    </>
  );
};

export default ReadBeforeApply;
