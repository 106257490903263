import React, { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import {
  DATE_PICKER_PLACEHOLDER,
  END_DATE_PICKER_PLACEHOLDER,
  REQUIRED,
} from "../../../../../../../../helpers/validations";
import moment from "moment";
import { useSelector } from "react-redux";
import { CalendarIcon } from "../../../../../../../icons";
import BaseButton from "../../../../../../../Shared/BaseButton";
import { Form, DatePicker } from "antd";
import { US_DATE_FORMAT, momentSet8Am } from "../../../../../../../../helpers/constants";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import { getFirstDayOfMonthStrByFutureMonth } from "../../../../../../../../helpers/globalFunctions";
import { FormInstance } from "antd/es/form";
import "./MoveInDate.scss";
import { requirementFormDataSelector } from "../../../../../../../../store/requirement/selectors";
import FormStepsTitle from "../../form-steps-title/FormStepsTitle";
import {
  addTimeAndFormatDate,
  getStartOfUnitFormatted,
  currentDate,
  formatDate,
} from "../../../../../../../../helpers/dateHelper";

const MoveInDate = ({ form, setStartDateValue }: { form: FormInstance<any>; setStartDateValue: any }) => {
  const requirementEnums = useSelector(requirementEnumsSelector);
  const requirement = useSelector(requirementFormDataSelector);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateObj, setStartDateObj] = useState({});
  const [endDateObj, setEndDateObj] = useState({});
  const [otherBtnClicked, setOtherBtnClicked] = useState(false);
  const [showDatePickers, setShowDatePickers] = useState(false);

  const startDateEnum = requirementEnums.find((item: EnumInterface) => item.key === "move_in_date_start");
  const endDateEnum = requirementEnums.find((item: EnumInterface) => item.key === "move_in_date_end");

  const toggleDatePickers = () => {
    setShowDatePickers((prevState) => !prevState);
  };

  const handleFromDateChange = useCallback((date: any, dateString: any) => {
    setStartDateObj(date);
    setStartDate(dateString);
  }, []);

  const handleToDateChange = useCallback((date: any, dateString: any) => {
    setEndDateObj(date);
    setEndDate(dateString);
  }, []);

  const onClick = useCallback(
    (moveInDate) => {
      const moveInDateStr = moveInDate.format(US_DATE_FORMAT);
      if (showDatePickers) {
        setShowDatePickers(false);
      }

      setStartDateValue(moveInDate);
      setStartDate(moveInDateStr);
      setStartDateObj(moveInDate);
    },
    [otherBtnClicked, showDatePickers]
  );
  useEffect(() => {
    if (showDatePickers) setStartDateValue(null);
  }, [showDatePickers]);

  useEffect(() => {
    if (requirement?.move_in_date_start && !requirement?.move_in_date_end) {
      [1, 2, 3, 4, 5].some((el) => {
        if (
          new Date(
            getStartOfUnitFormatted(addTimeAndFormatDate(null, { amount: el, unit: "M" }), "month", "")
          ).toDateString() === new Date(requirement.move_in_date_start).toDateString()
        ) {
          setTimeout(() => {
            setStartDate(formatDate(requirement.move_in_date_start));
            setShowDatePickers(false);
          }, 200);
        } else {
          setTimeout(() => {
            form.setFieldsValue({
              move_in_date_start: moment(requirement?.move_in_date_start),
              move_in_date_end: null,
            });
            setStartDate("");
            setShowDatePickers(true);
          }, 200);
        }
        return (
          new Date(
            getStartOfUnitFormatted(addTimeAndFormatDate(null, { amount: el, unit: "M" }), "month", "")
          ).toDateString() === new Date(requirement.move_in_date_start).toDateString()
        );
      });
    } else if (requirement?.move_in_date_start && requirement?.move_in_date_end) {
      form.setFieldsValue({
        move_in_date_start: moment(requirement?.move_in_date_start),
        move_in_date_end: moment(requirement?.move_in_date_end),
      });
      setShowDatePickers(true);
    }
  }, [requirement]);

  return (
    <div className={"check-move-in-date"}>
      <FormStepsTitle text={"When are you looking to move in? "} />
      <Form.Item rules={[!startDate && !showDatePickers ? REQUIRED : {}]} name="move-in-date">
        <div className={"check-move-in-date-btns"}>
          {[1, 2, 3, 4, 5].map((el, idx) => {
            const moveInDate = moment().add(el, "M").startOf("month");
            const moveInDateStr = moveInDate.format(US_DATE_FORMAT);
            return (
              <BaseButton
                key={idx}
                variant={!otherBtnClicked && startDate === moveInDateStr ? "primary" : ""}
                className={`req-checkbox-btn ${
                  !otherBtnClicked && startDate === moveInDateStr ? "req-checkbox-btn-active" : ""
                }`}
                onClick={() => {
                  setOtherBtnClicked(false);
                  onClick(moveInDate);
                  form.setFieldsValue({
                    move_in_date_start: moveInDate,
                    move_in_date_end: null,
                  });
                }}
              >
                {getFirstDayOfMonthStrByFutureMonth(el)}
              </BaseButton>
            );
          })}
          <BaseButton
            className={`req-checkbox-btn ${showDatePickers ? "req-checkbox-btn-active" : ""}`}
            onClick={() => {
              setOtherBtnClicked((prevState) => !prevState);
              setStartDate("");
              setStartDateObj({});

              setEndDate("");
              setEndDateObj({});

              form.setFieldsValue({
                move_in_date_start: null,
                move_in_date_end: null,
              });
              toggleDatePickers();
            }}
          >
            Other
          </BaseButton>
        </div>
        {showDatePickers && (
          <div className={"check-move-in-date-btns move-in-date-pickers-req mt-4"}>
            <div onClick={(e) => e.stopPropagation()} className="start-datepicker-wrapper">
              <Form.Item
                name={"move_in_date_start"}
                rules={[!startDateEnum?.allow_null ? REQUIRED : {}]}
                label={startDateEnum?.label}
                labelCol={{ span: 24 }}
                className={"chip-checkbox enum_input move-in-date req-date-item"}
              >
                <DatePicker
                  getPopupContainer={() => {
                    return document.querySelector(".start-datepicker-wrapper") as HTMLElement;
                  }}
                  inputReadOnly={true}
                  showToday={false}
                  dropdownClassName="requirements-datepicker"
                  suffixIcon={<CalendarIcon />}
                  format={`${US_DATE_FORMAT}`}
                  placeholder={DATE_PICKER_PLACEHOLDER}
                  value={!isEmpty(startDateObj) ? moment(startDateObj) : null}
                  onChange={handleFromDateChange}
                  disabledDate={(value) => {
                    let endDate = moment(form.getFieldValue("move_in_date_end"));
                    let customDate = currentDate();
                    if (!endDate.isValid()) return value && value < moment(customDate, US_DATE_FORMAT);
                    return endDate && value > endDate;
                  }}
                />
              </Form.Item>
            </div>
            <div className={"end-datepicker-wrapper"}>
              <Form.Item
                name={"move_in_date_end"}
                label={endDateEnum?.label}
                labelCol={{ span: 24 }}
                className={"chip-checkbox enum_input move-in-date req-date-item"}
              >
                <DatePicker
                  getPopupContainer={() => {
                    return document.querySelector(".end-datepicker-wrapper") as HTMLElement;
                  }}
                  inputReadOnly={true}
                  showToday={false}
                  dropdownClassName="requirements-datepicker"
                  suffixIcon={<CalendarIcon />}
                  format={`${US_DATE_FORMAT}`}
                  placeholder={END_DATE_PICKER_PLACEHOLDER}
                  value={!isEmpty(endDateObj) ? moment(endDateObj) : null}
                  onChange={handleToDateChange}
                  disabledDate={(value) => {
                    let customDate = addTimeAndFormatDate(
                      currentDate(),
                      { amount: 1, unit: "days" },
                      momentSet8Am,
                      US_DATE_FORMAT
                    );
                    let startDate = moment(form.getFieldValue("move_in_date_start")).add(1, "days");
                    if (!startDate.isValid()) return value && value < moment(customDate, US_DATE_FORMAT);
                    return startDate && value < startDate;
                  }}
                />
              </Form.Item>
            </div>
          </div>
        )}
      </Form.Item>
    </div>
  );
};

export default MoveInDate;
