import { getListingsViewType } from "./../../helpers/globalFunctions";
import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";
import {
  ListingArr,
  Listing,
  ListingNote,
  IFilterOptionsOptionalKeys,
} from "../../components/Listings/helpers/mockListings";
import { IPaginationValues } from "../../components/Listings/components/listings-wrapper";
import { getAuthToken } from "helpers/authUtils";

interface IListingsReducer {
  listingData: Listing;
  listingsList: ListingArr | null;
  total: number;
  agencyListingsTotal: number | null;
  listingLoading: boolean | null;
  leadListingLoadedSuccess: boolean;
  listingAddSuccess: boolean;
  isOpenListingModalAfterSave: boolean;
  listingUpdateSuccess: boolean;
  listingSaveClicked: boolean;
  listingError: IObjectKeys | string | null;
  listingNotes: ListingNote[];
  listingNotesLoading: boolean;
  listingFollowUps: ListingNote[];
  listingFollowUpsLoading: boolean;
  listingNoteAddSuccess: boolean;
  listingImageLoading: boolean;
  listingNotesError: IObjectKeys | string | null;
  listingsLastQuery: string;
  listingsCsvLoading: boolean;
  listingsCsvErrors: IObjectKeys[] | IObjectKeys | null;
  downloadCsvTemplateLoading: boolean;
  stepClicked: boolean;
  algoliaSearch: boolean;
  listingImagesModalCurrentImage?: number;
  paginationValues: IPaginationValues;
  listingsViewType: "grid" | "list";
  filterOptions: IFilterOptionsOptionalKeys;
  mustHavesSwitchInputValues: IObjectKeys;
  listingDetailsSwitchInputValues: IObjectKeys;
  currentStep: number;
  isCurrentFormChanged?: boolean;
  isAsapClicked?: boolean;
  individualListingHistory: Array<any>;
  listingChangelogHistory: Array<any>;
  isPublicListing: null | boolean;
}
const initialState: IListingsReducer = {
  listingData: {},
  listingsList: null,
  total: 0,
  agencyListingsTotal: null,
  listingLoading: null,
  listingError: null,
  listingAddSuccess: false,
  isOpenListingModalAfterSave: false,
  listingUpdateSuccess: false,
  listingSaveClicked: false,
  listingNotes: [],
  listingNotesLoading: false,
  listingFollowUps: [],
  listingFollowUpsLoading: false,
  listingNoteAddSuccess: false,
  listingNotesError: null,
  listingImageLoading: false,
  listingsLastQuery: "?",
  listingsCsvLoading: false,
  listingsCsvErrors: null,
  downloadCsvTemplateLoading: false,
  stepClicked: false,
  algoliaSearch: false,
  paginationValues: { current: 1, pageSize: 24 },
  listingsViewType: getListingsViewType(),
  filterOptions: {},
  currentStep: 0,
  listingImagesModalCurrentImage: 0,
  mustHavesSwitchInputValues: {},
  listingDetailsSwitchInputValues: {},
  isCurrentFormChanged: false,
  isAsapClicked: false,
  individualListingHistory: [],
  listingChangelogHistory: [],
  isPublicListing: false,
  leadListingLoadedSuccess: false
};

export default function listingReducer(
  state = initialState,
  action: { type: string; payload: any; }
) {
  switch (action.type) {
    case types.FILL_LISTING_DATA:
      return {
        ...state,
        listingData: {
          ...state.listingData,
          ...action.payload,
        },
      };
    case types.SET_STEP_CLICKED:
      return {
        ...state,
        stepClicked: action.payload,
      };
    case types.TRIGGER_AFTER_LISTING_SAVE_MODAL:
      return {
        ...state,
        isOpenListingModalAfterSave: action.payload,
      };
    case types.START_FILL_LISTING_DATA_IMAGES:
      return {
        ...state,
        listingImageLoading: true,
      };
    case types.FILL_LISTING_DATA_IMAGES:
      const newImages = state.listingData.images || [];
      return {
        ...state,
        listingImageLoading: false,
        listingData: {
          ...state.listingData,
          images: [...newImages, action.payload],
        },
      };
    case types.CLEAR_LISTING_DATA:
      return {
        ...state,
        listingData: {},
        listingDetailsSwitchInputValues: {},
        mustHavesSwitchInputValues: {},
      };
    case types.CLEAR_LISTING_ADD_STATE:
      return {
        ...state,
        listingAddSuccess: false,
      };
    case types.CLEAR_LISTING_UPDATE_STATE:
      return {
        ...state,
        listingUpdateSuccess: false,
      };
    case types.SET_LISTING_SAVE_CLICKED:
      return {
        ...state,
        listingSaveClicked: action.payload,
      };
    case types.GET_ONE_LISTING:
      return {
        ...state,
        listingLoading: true,
        listingError: null,
        // isPublicListing:true,
      };
    case types.GET_ONE_LISTING_FROM_PUBLIC_ID:
      return {
        ...state,
        listingLoading: true,
        listingError: null,
        // isPublicListing:true,
      };
    case types.SET_LISTING:
      return {
        ...state,
        isPublicListing: action.payload.isPublic,
        listingData: {
          ...action.payload,
          // eslint-disable-next-line no-restricted-globals
          images: action.payload?.images?.length
            ? action.payload?.images
            : [],
        },
        individualListingHistory: action.payload?.history,
        listingChangelogHistory: action.payload?.changelog,
        listingLoading: false,
      };
    case types.SET_LISTINGS:
      return {
        ...state,
        listingLoading: false,
        listingsList: [...action.payload],
        total: action.payload.length,
      };

    case types.GET_ONE_LISTING_SUCCESS:
      return {
        ...state,
        isPublicListing: action.payload.isPublic,
        listingData: {
          ...action.payload.listing,
          // eslint-disable-next-line no-restricted-globals
          images: action.payload?.listing?.images?.length
            ? action.payload?.listing?.images
            : [],
        },
        individualListingHistory: action.payload.history,
        listingChangelogHistory: action.payload.changelog,
        listingLoading: false,
      };
    case types.GET_ONE_LISTING_FAIL:
      return {
        ...state,
        listingLoading: false,
        listingError: action.payload,
      };
    case types.ADD_LISTING:
      return {
        ...state,
        listingLoading: true,
        listingAddSuccess: false,
        listingError: null,
      };
    case types.ADD_LISTING_SUCCESS:
      return {
        ...state,
        listingLoading: false,
        // isOpenListingModalAfterSave: true,
        listingAddSuccess: true,
      };
    case types.ADD_LISTING_FAIL:
      return {
        ...state,
        listingLoading: false,
        listingError: action.payload,
      };
    case types.UPDATE_LISTING:
      return {
        ...state,
        listingLoading: true,
        listingUpdateSuccess: false,
        listingError: null,
      };
    case types.UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        listingLoading: false,
        listingUpdateSuccess: true,
        listingData: {
          ...state.listingData,
          asked_rent: action.payload.asked_rent,
          availability_date: action.payload.availability_date,
          current_rent: action.payload.current_rent,
        },
      };
    case types.UPDATE_LISTING_FAIL:
      return {
        ...state,
        listingLoading: false,
        listingError: action.payload,
      };
    case types.GET_LISTINGS:
      return {
        ...state,
        listingLoading: true,
        listingError: null,
      };
    case types.GET_LISTINGS_SUCCESS:
      return {
        ...state,
        listingLoading: false,
        listingsList: [...action.payload.data],
        total: action.payload.metadata[0].total,
        agencyListingsTotal: action.payload.metadata[0].agencyListingsTotal,
      };
    case types.GET_LISTINGS_FAIL:
      return {
        ...state,
        listingLoading: false,
        listingError: action.payload,
      };
    case types.ALGOLIA_SEARCH_TOGGLE:
      return {
        ...state,
        algoliaSearch: action.payload,
      };
    case types.GET_LISTINGS_FORM_ALGOLIA:
      return {
        ...state,
        listingLoading: !action.payload.isModalSearch,
        listingModalLoading: action.payload.isModalSearch,
        listingError: null
      };
    case types.GET_LISTINGS_FORM_ALGOLIA_SUCCESS:
      return {
        ...state,
        listingLoading: false,
        listingModalLoading: false,
        listingsList: [...action.payload.result.hits],
        total: action.payload.result.nbHits,
        agencyListingsTotal: action.payload.agencyListingsTotal,
        paginationValues: {
          current: action.payload.result.page + 1,
          pageSize: action.payload.result.hitsPerPage,
        },
        leadListingLoadedSuccess: true
      };
    case types.CLEAR_LISTINGS_FORM_ALGOLIA:
      return {
        ...state,
        listingsList: []
      };
    case types.GET_LISTINGS_FORM_ALGOLIA_FAIL:
      return {
        ...state,
        listingLoading: false,
        listingModalLoading: false,
        listingError: action.payload
      };
    case types.ADD_LISTING_NOTE:
      return {
        ...state,
        listingNotesLoading: true,
        listingNoteAddSuccess: false,
      };
    case types.ADD_LISTING_NOTE_SUCCESS:
      return {
        ...state,
        listingNotes: [action.payload, ...state.listingNotes],
        listingNotesLoading: false,
        listingNoteAddSuccess: true,
      };
    case types.GET_LISTING_NOTES:
      return {
        ...state,
        listingNotesLoading: true,
      };
    case types.GET_LISTING_NOTES_SUCCESS:
      return {
        ...state,
        listingNotesLoading: false,
        listingNotes: action.payload,
      };
    case types.DELETE_LISTING_NOTE:
      return {
        ...state,
        listingNotesLoading: true,
      };
    case types.DELETE_LISTING_NOTE_SUCCESS:
      // const notes =
      return {
        ...state,
        listingNotes: state.listingNotes.filter(
          (note) => note._id !== action.payload
        ),
        listingNotesLoading: false,
      };
    case types.UPDATE_LISTING_NOTE:
      return {
        ...state,
        listingNoteAddSuccess: false,
        listingNotesLoading: true,
      };
    case types.UPDATE_LISTING_NOTE_SUCCESS:
      let updatedNotes = state.listingNotes.map((item: ListingNote) => {
        if (item._id === action.payload._id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        listingNotes: updatedNotes,
        listingNoteAddSuccess: true,
        listingNotesLoading: false,
      };
    case types.UPDATE_LISTING_NOTE_FAIL:
    case types.GET_LISTING_NOTES_FAIL:
    case types.ADD_LISTING_NOTE_FAIL:
    case types.DELETE_LISTING_NOTE_FAIL:
      return {
        ...state,
        listingNotesError: action.payload,
      };
    case types.UPDATE_LISTING_PUBLISH_STATE:
      return {
        ...state,
        listingPublishStateUpdateSuccess: false,
        listingPublishStateUpdateLoading: true
      };
    case types.UPDATE_LISTING_PUBLISH_STATE_SUCCESS:
      console.log("==> in UPDATE_LISTING_PUBLISH_STATE_SUCCESS: ", action);
      return {
        ...state,
        listingPublishStateUpdate: action.payload,
        listingPublishStateUpdateSuccess: true,
        listingPublishStateUpdateLoading: false,
        listingData: {
          ...state.listingData,
          published_rdt: action.payload.published_rdt
        }
      };
    case types.UPDATE_LISTING_PUBLISH_STATE_FAIL:
      return {
        ...state,
        listingPublishStateUpdateLoading: false,
        listingPublishStateUpdateError: action.payload
      };
    case types.DELETE_LISTING:
      return {
        ...state,
        listingError: null,
        listingLoading: true
      };
    case types.DELETE_LISTING_SUCCESS:
      return {
        ...state,
        listingLoading: false
      };
    case types.DELETE_LISTING_FAIL:
      return {
        ...state,
        listingLoading: false,
        listingError: action.payload
      };
    case types.DELETE_LISTING_IMAGE:
      return {
        ...state,
        listingError: null,
        listingImageLoading: true,
      };
    case types.DELETE_LISTING_IMAGE_SUCCESS:
      return {
        ...state,
        listingImageLoading: false,
      };
    case types.DELETE_LISTING_IMAGE_FAIL:
      return {
        ...state,
        listingImageLoading: false,
        listingError: action.payload,
      };

    case types.SET_LISTINGS_LAST_QUERY:
      return {
        ...state,
        listingsLastQuery: action.payload,
      };

    case types.UPLOAD_CSV_LISTINGS:
      return {
        ...state,
        listingError: null,
        listingsCsvErrors: null,
        listingLoading: true,
      };
    case types.UPLOAD_CSV_LISTINGS_SUCCESS:
      let uploadErrors = null;
      // eslint-disable-next-line no-restricted-globals
      if (action.payload?.errors?.length) {
        uploadErrors = action.payload.errors;
      }
      return {
        ...state,
        listingLoading: false,
        listingsCsvErrors: uploadErrors,
      };
    case types.UPLOAD_CSV_LISTINGS_FAIL:
      return {
        ...state,
        listingLoading: false,
        listingsCsvErrors: action.payload,
      };
    case types.DOWNLOAD_CSV_TEMPLATE:
      return {
        ...state,
        downloadCsvTemplateLoading: true,
        listingError: null,
      };
    case types.DOWNLOAD_CSV_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadCsvTemplateLoading: false,
      };
    case types.DOWNLOAD_CSV_TEMPLATE_FAIL:
      return {
        ...state,
        downloadCsvTemplateLoading: false,
        listingError: action.payload,
      };
    case types.CLEAR_UPLOAD_CSV_ERRORS:
      return {
        ...state,
        listingLoading: false,
        listingsCsvErrors: null,
      };

    case types.CLEAR_PARTICULAR_CSV_ERROR:
      const _listingsCsvErrors = state.listingsCsvErrors?.filter(
        (error: IObjectKeys) => {
          return error.addr !== action.payload.addr;
        }
      );
      return {
        ...state,
        listingsCsvErrors: _listingsCsvErrors,
      };

    case types.SET_LISTINGS_LOADING_TO_TRUE:
      return {
        ...state,
        listingLoading: true,
      };
    case types.SET_LISTINGS_LOADING_TO_FALSE:
      return {
        ...state,
        listingLoading: false,
      };
    case types.SET_LISTINGS_VIEW_TYPE:
      return {
        ...state,
        listingsViewType: action.payload,
      };
    case types.SET_LISTINGS_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: {
          ...action.payload,
        },
      };
    case types.SET_LISTINGS_PAGINATION_VALUES:
      return {
        ...state,
        paginationValues: {
          ...action.payload,
        },
      };
    case types.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case types.SET_LISTING_IMAGE_MODAL_CURRENT_IMAGE_INDEX:
      return {
        ...state,
        listingImagesModalCurrentImage: action.payload,
      };

    case types.SET_MUST_HAVES_SWITCH_INPUT_VALUES:
      return {
        ...state,
        mustHavesSwitchInputValues: {
          ...state.mustHavesSwitchInputValues,
          ...action.payload,
        },
      };
    case types.SET_LISTING_DETAILS_SWITCH_INPUT_VALUES:
      return {
        ...state,
        listingDetailsSwitchInputValues: {
          ...state.listingDetailsSwitchInputValues,
          ...action.payload,
        },
      };
    case types.IS_CURRENT_FORM_CHANGED:
      return {
        ...state,
        isCurrentFormChanged: action.payload,
      };
    case types.IS_ASAP_CLICKED:
      return {
        ...state,
        isAsapClicked: action.payload,
      };
    case types.SET_LISTING_NOTES_SUCCESS:
      return {
        ...state,
        listingNoteAddSuccess: action.payload,
      };
    default:
      return state;
  }
}
