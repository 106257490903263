import { TrashIcon } from "@heroicons/react/24/outline";
import BaseButton from "components/Shared/BaseButton";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import React, { useState } from "react";

interface IPropsAreaAutocomplete {
  field: any;
  value: any;
  onChange?: any;
}

const AreaAutocomplete = ({ onChange, field, value }: IPropsAreaAutocomplete) => {
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);

  const handleOnAddressChange = (place: any) => {
    const area = {
      ...place,
      area: place.formatted_address,
      geoloc: place.location,
      town: place.separated_fields.town,
      state: place.separated_fields.state,
      location: [],
    };
    onChange({ field, value: [...value, area] });
  };

  const handleRemoveValueIndex = (value: any, idx: number) => {
    value.splice(idx, 1);
    onChange({ field, value });
  };

  return (
    <div className="mb-6 w-full">
      {value?.length &&
        value.map((area: any, idx: number) => {
          return (
            <div key={area.area} className="py-2 flex justify-between">
              {area.area}
              <BaseButton size="sm" onClick={() => handleRemoveValueIndex(value, idx)}>
                <TrashIcon className="h-4 w-4 text-purple-400" aria-hidden="true" />
              </BaseButton>
            </div>
          );
        })}
      <div className="mt-6">
        <PlacesAutocomplete
          placeholder={"Enter City/Town"}
          defaultAddress={value?.area}
          onAddressChange={handleOnAddressChange}
          resetPlacesAutocomplete={resetPlacesAutocomplete}
          setResetPlacesAutocomplete={setResetPlacesAutocomplete}
          showRegion={true}
          disableFreeForms={true}
        />
      </div>
    </div>
  );
};

export default AreaAutocomplete;
