import React from "react";
import RenterPortalSearchComp from "components/RenterPortal/RentalPortalSearch";

const RenterPortalSearch = ({ user }: { user: any }) => {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <RenterPortalSearchComp />
    </div>
  );
};
export default RenterPortalSearch;
