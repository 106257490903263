import React, { useState } from "react";
import moment from "moment";
import "moment-timezone";
import { DatePicker, TimePicker } from "antd";
import "./styles.scss";
import { IEditFollowUp } from "./EditFollowUp";
import { momentSet8Am, US_DATE_FORMAT, US_DATE_FORMAT_WITH_TIME } from "../../helpers/constants";
import { CalendarIcon, LeftArrow } from "../icons";
import BaseButton from "../Shared/BaseButton";
import { editFollowUp } from "../../store/followUps/action";
import { useDispatch } from "react-redux";
import {
  formatDate,
  getStartOfUnitFormatted,
  getEndOfWeekPlusTwoDaysFormatted,
  addTimeAndFormatDate,
  getNextOccurrence,
  currentDate,
  dateToMoment,
} from "../../helpers/dateHelper";
import { openNotification } from "helpers/globalFunctions";

export const SnoozeOptions = ({
  setUpdates,
  followUp,
  setDropdownVisibility,
  queryAndPagination,
  isSnoozeModal,
}: IEditFollowUp) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showingData, setShowingData] = useState(getStartOfUnitFormatted(currentDate(), "year", "MMM D,YYYY"));
  const dispatch = useDispatch();
  const [timeData, setTimeData] = useState("");
  const [alert, setAlert] = useState<any>(false);
  const [calendarDay, setCalendarDay] = useState("");
  const snoozeClass = isSnoozeModal ? "modalView" : "";

  const snoozeDate = (date: any) => {
    setUpdates({ ...followUp, todo_at: date, queryAndPagination });
    if (setDropdownVisibility) {
      setDropdownVisibility((prevState: any) => {
        return {
          ...prevState,
          edit: false,
          snooze: !prevState.snooze,
        };
      });
      openNotification("success", `Follow-Up snoozed until ${date}`);
    }
    dispatch(editFollowUp({ ...followUp, todo_at: date }, followUp?._id || ""));
  };

  const handleSubmit = () => {
    // @ts-ignore
    const tz = moment.tz.guess();
    snoozeDate(moment(`${calendarDay} ${timeData}`).tz(tz).format());
  };

  return (
    <div className={"snooze-followUp-wrapper bg-white shadow-lg p-6 border border-purple-300 rounded-lg"}>
      {showDatePicker && (
        <div onClick={() => setShowDatePicker(false)} className={"snooze-modal-back-btn"}>
          <LeftArrow />
        </div>
      )}
      {!showDatePicker ? (
        <div className={"snooze-followUp-content-wrapper"}>
          <p className={`text-lg font-bold snooze-title ${snoozeClass}`}>Snooze until...</p>
          <BaseButton
            className={"snooze-dates mb-1"}
            onClick={() =>
              snoozeDate(
                addTimeAndFormatDate(
                  currentDate(),
                  {
                    amount: 1,
                    unit: "day",
                  },
                  momentSet8Am
                )
              )
            }
          >
            <div className={`snooze-date-label-wrapper ${snoozeClass}`}>
              <span className={"daysUpdate"}>Tomorrow </span>
              <span>
                {" "}
                {addTimeAndFormatDate(currentDate(), { amount: 1, unit: "day" }, momentSet8Am)} at{" "}
                {addTimeAndFormatDate(currentDate(), { amount: 1, unit: "day" }, momentSet8Am, "hh:mm A ")}
              </span>
            </div>
          </BaseButton>

          <BaseButton className={"snooze-dates mb-1"} onClick={() => snoozeDate(getNextOccurrence("saturday"))}>
            <div className={`snooze-date-label-wrapper ${snoozeClass}`}>
              <span className={"daysUpdate"}>This weekend</span>
              <span>
                {" "}
                {getNextOccurrence("saturday", US_DATE_FORMAT)} at {getNextOccurrence("saturday", "hh:mm A")}
              </span>
            </div>
          </BaseButton>

          <BaseButton
            className={"snooze-dates mb-1"}
            onClick={() => snoozeDate(getEndOfWeekPlusTwoDaysFormatted(2, "day"))}
          >
            <div className={`snooze-date-label-wrapper ${snoozeClass}`}>
              <span className={"daysUpdate"}>Next Week</span>
              <span>
                {" "}
                {getEndOfWeekPlusTwoDaysFormatted(2, "day", US_DATE_FORMAT)} at{" "}
                {getEndOfWeekPlusTwoDaysFormatted(2, "day", "hh:mm A")}
              </span>
            </div>
          </BaseButton>

          <BaseButton className={"snooze-dates mb-1"} onClick={() => snoozeDate(getNextOccurrence("thursday"))}>
            <div className={`snooze-date-label-wrapper ${snoozeClass}`}>
              <span className={"daysUpdate"}>Next Weekend</span>
              <span>
                {" "}
                {formatDate(
                  dateToMoment().add(1, "weeks").weekday(6).set({ hour: 8, minute: 0 }),
                  "MM.DD.YYYY"
                )} at {formatDate(dateToMoment().add(1, "weeks").weekday(6).set({ hour: 8, minute: 0 }), "hh:mm A")}
              </span>
            </div>
          </BaseButton>

          <div className={"mt-4 snooze-dates mb-1"} onClick={() => setShowDatePicker(true)}>
            <div className={`snooze-date-picker-btn-wrapper ${snoozeClass}`}>
              <span>Or pick date and time</span>
            </div>
          </div>

          <div className={`snooze-dates snooze-date-picker-wrapper ${snoozeClass}`}>
            <DatePicker
              placeholder={"  Choose date and time"}
              suffixIcon={<CalendarIcon />}
              format={US_DATE_FORMAT_WITH_TIME}
              showTime
              onChange={(date: any) => {
                snoozeDate(date);
              }}
              disabledDate={(value) => {
                return value && value < dateToMoment(currentDate(US_DATE_FORMAT));
              }}
            />
          </div>
        </div>
      ) : (
        <div className={"snooze-dropdown-wrapper"}>
          <p className={"snooze-dropdown-title"}>Pick date & time</p>
          <DatePicker
            getPopupContainer={(triggerNode: { parentNode: any }) => triggerNode.parentNode}
            onChange={(date: any) => {
              setShowingData(formatDate(date, "MMM D,YYYY"));
              setCalendarDay(formatDate(date));
            }}
            open={true}
            showToday={false}
            className="w-full"
            placeholder={"Ex. 01/01/2024"}
            format={`${US_DATE_FORMAT_WITH_TIME}`}
            renderExtraFooter={() => (
              <div className={"time-picker-footer-body"}>
                <div className={"date-def"}>{showingData}</div>
                <TimePicker
                  defaultValue={moment(formatDate("HH:mm:ss"), "HH:mm:ss")}
                  onChange={(date: any) => {
                    setTimeData(formatDate(date, "HH:mm:ss"));
                  }}
                />
                <BaseButton
                  //@ts-ignore
                  onClick={handleSubmit}
                  classnames={"base-btn sidebar-mobile-btn"}
                  modalType="followUp"
                >
                  Submit
                </BaseButton>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};
