import { IObjectKeys } from "helpers/globalTypes";
import * as types from "./types";

interface renterRequestReducer {
  renterRequestData: IObjectKeys[];
  renterRequestLoading: boolean;
  renterRequestError: string | IObjectKeys | null;
}
const initialState: renterRequestReducer = {
  renterRequestData: [],
  renterRequestLoading: false,
  renterRequestError: null,
};

export default function renterRequestReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.GET_RENTERS_SCREENING_REQUEST_SUCCESS:
      return {
        ...state,
        renterRequestLoading: false,
        renterRequestData: action.payload || [],
      };
    case types.GET_RENTERS_SCREENING_REQUEST_FAIL:
      return {
        ...state,
        renterRequestLoading: false,
        renterRequestError: action.payload,
      };
    default:
      return state;
  }
}
