import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Tooltip } from "antd";
import "../styles.scss";
import { IObjectKeys } from "helpers/globalTypes";
import { createLandlord, editLandlord } from "store/landlords/action";
import { FormInstance } from "antd/es/form";
import { currentLandlordSelector } from "../../../store/landlords/selectors";
import {
  EMAIL_PLACEHOLDER,
  NOT_REQUIRED_EMAIL_VALIDATION,
  NOT_REQUIRED_PHONE,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
} from "../../../helpers/validations";
import MaskedInput from "../../Shared/MaskedPhoneInput";
import { sanitizePhoneNumber } from "../../../helpers/globalFunctions";
import { InfoIcon } from "../../icons";
import { SwitchToggle } from "components/RenterPortal/Components/Form/CheckBoxGroupInput";

const ManageLandlordForm = ({ form }: { form: FormInstance }) => {
  const [isPrivate, setIsPrivate] = useState(false);
  const dispatch = useDispatch();
  const currentLandlord = useSelector(currentLandlordSelector);
  const onSubmit = (data: IObjectKeys) => {
    if (!data.phone && !data.email) {
      // form.err
    }
    if (data.phone) {
      data.phone = sanitizePhoneNumber(data.phone);
    }
    const formData: any = {
      _id: currentLandlord.landlord_id,
      is_private: isPrivate,
      name: data.name.trim(),
    };
    if (data.email && data.email.length > 0) {
      formData.email = data.email;
    }
    if (data.phone?.length > 0) {
      formData.phone = data.phone;
    }
    if (currentLandlord.landlord_id) {
      const formData: any = {
        _id: currentLandlord.landlord_id,
        is_private: isPrivate,
        name: data.name.trim(),
      };
      if (data.email && data.email.length > 0) {
        formData.email = data.email;
      }
      if (data.phone?.length > 0) {
        formData.phone = data.phone;
      }
      return dispatch(editLandlord(formData));
    }
    dispatch(createLandlord(formData));
  };
  useEffect(() => {
    if (currentLandlord && Object.keys(currentLandlord).length) {
      form.setFieldsValue({
        name: currentLandlord.name,
        phone: currentLandlord.phone,
        email: currentLandlord.email,
        landlord_description: currentLandlord.landlord_description,
      });
      setIsPrivate(currentLandlord.is_private);
    }
  }, [currentLandlord]);

  const handleChangePrivacy = useCallback((e: any) => {
    setIsPrivate((prevState) => !prevState);
  }, []);

  return (
    <Form
      className={"manage-landlord-modal-form"}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onSubmit}
      layout={"vertical"}
    >
      <Form.Item
        label="Landlord Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input landlord name!",
          },
          {
            validator: (_, value) =>
              value.trim() ? Promise.resolve() : Promise.reject(new Error("No spaces allowed")),
          },
        ]}
      >
        <Input autoComplete={"off"} data-test-id="add-landLoard-fullName" placeholder={"Full Name"} />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="phone"
        rules={[
          NOT_REQUIRED_PHONE,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && !getFieldValue("email")) {
                return Promise.reject(new Error("Phone or email is required!"));
              }
              return Promise.resolve();
            },
          }),
        ]}
        validateTrigger={"onBlur"}
      >
        <MaskedInput
          data-test-id="add-landLoard-Phone"
          inputMode="numeric"
          mask={PHONE_NUMBER_MASK}
          placeholder={PHONE_NUMBER_PLACEHOLDER}
        />
      </Form.Item>
      <Form.Item
        label="Landlord Email"
        name="email"
        rules={[
          NOT_REQUIRED_EMAIL_VALIDATION,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && !getFieldValue("phone")) {
                return Promise.reject(new Error("Email or phone is required!"));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input autoComplete={"off"} data-test-id="add-landLoard-email" placeholder={EMAIL_PLACEHOLDER} />
      </Form.Item>
      <Form.Item
        className={"set-private-section flex items-center"}
        label={
          <span className="flex gap-2">
            Set Landlord as Private{" "}
            <Tooltip
              overlayClassName={"set-private-text-tooltip"}
              placement="top"
              title={"Only you will see Landlords details"}
            >
              <span>
                <InfoIcon />
              </span>
            </Tooltip>
          </span>
        }
        name="is_private"
      >
        <div>
          <SwitchToggle
            option={{ value: "landlord", label: "" }}
            onChange={handleChangePrivacy}
            value={{ landlord: isPrivate }}
          />
        </div>
      </Form.Item>
    </Form>
  );
};
export default ManageLandlordForm;
