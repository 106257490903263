import { IObjectKeys } from "helpers/globalTypes";
import Api from "./api";
export default class Hubspot {
  
  static updateHubspotContact(payload: IObjectKeys) {
    const api = new Api(`hubspot/update`);
    return api.create({ data: payload });
  }
}

