import { Checkbox, Form, Input, Radio, Spin } from "antd";
import MaskedInput from "components/Shared/MaskedPhoneInput";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import { AddressChangeParams } from "components/Shared/PlacesAutocomplete/types";
import { SHAREABLE_PHONE_TYPES } from "helpers/constants";
import { IObjectKeys } from "helpers/globalTypes";
import {
  CHARACTERS_VALIDATION,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
  REQUIRED,
  SETTINGS_PHONE_NUMBER_VALIDATION,
  SHAREABLE_ADDRESS_1_VALIDATION,
  SHAREABLE_LOCALITY_VALIDATION,
  SHAREABLE_NAME_VALIDATION,
  SHAREABLE_POSTAL_CODE_VALIDATION,
  SHAREABLE_REGION_VALIDATION,
} from "helpers/validations";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { shareableLoadingSelector } from "store/shareable/selectors";
import { userSelector } from "store/user/selectors";

const AgentDetails = ({
  form,
  agentDetails,
  setAgentDetails,
}: {
  form: any;
  agentDetails: any;
  setAgentDetails: any;
}) => {
  const userData = useSelector(userSelector);
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);
  const shareableLoading = useSelector(shareableLoadingSelector);

  useEffect(() => {
    if (userData) {
      const contryName: any = userData.address?.addr?.split(",");

      let setFieldValues: IObjectKeys = {
        emailAddress: userData.email,
        firstName: userData?.name?.split(" ")[0],
        lastName: userData?.name?.split(" ")[1] || "",
        phoneNumber: userData.phone || "",
        phoneType: userData?.phonetype || "",
        businessName: userData?.office_name || "",
        addr: userData.address?.addr || "",
        streetNumber: userData.address?.street_number || "",
        streetName: userData.address?.street_name || "",
        unit: userData.address?.unit || "",
        locality: userData.address?.city || "",
        state: userData.address?.state || "",
        postalCode: userData.address?.zipCode || "",
        country: userData.address?.country ? userData.address.country : contryName && contryName[contryName?.length - 1] ? contryName && contryName[contryName?.length - 1] : "",
      };
      form.setFieldsValue(setFieldValues);
    }
  }, [userData]);

  const itemStyle = { marginBottom: "10px" };

  const onAddressChange = (address: AddressChangeParams) => {
    let setFieldValues: IObjectKeys = {
      addr: address.formatted_address,
    };

    const contryName: any = address?.formatted_address?.split(",");

    setFieldValues.locality = address.separated_fields?.town ? address.separated_fields?.town : "";
    setFieldValues.state = address.separated_fields?.short_state ? address.separated_fields?.short_state : "";
    setFieldValues.postalCode = address.separated_fields?.postal_code ? address.separated_fields?.postal_code : "";
    setFieldValues.country =
      contryName && contryName[contryName?.length - 1] ? contryName && contryName[contryName?.length - 1] : "";
    setFieldValues.streetName = address.separated_fields?.street_name ? address.separated_fields?.street_name : "";
    setFieldValues.streetNumber = address.separated_fields?.street_number
      ? address.separated_fields?.street_number
      : "";
    form.setFieldsValue(setFieldValues);
  };

  return shareableLoading ? (
    <div className="flex justify-center">
      <Spin />
    </div>
  ) : (
    <>
      <Form.Item name="emailAddress">
        <Input hidden />
      </Form.Item>
      <Form.Item label="First name" name="firstName" style={itemStyle} rules={[SHAREABLE_NAME_VALIDATION]}>
        <Input placeholder={"John"} />
      </Form.Item>
      <Form.Item label="Last name" name="lastName" required style={itemStyle} rules={[SHAREABLE_NAME_VALIDATION]}>
        <Input placeholder={"Doe"} />
      </Form.Item>
      <div style={{ marginBottom: "10px" }}>
        <Form.Item name="phoneNumber" label="Phone Number" rules={[SETTINGS_PHONE_NUMBER_VALIDATION, REQUIRED]}>
          <MaskedInput inputMode="numeric" mask={PHONE_NUMBER_MASK} placeholder={PHONE_NUMBER_PLACEHOLDER} />
        </Form.Item>
      </div>
      <Form.Item label="Phone type" name="phoneType" style={itemStyle} rules={[REQUIRED]}>
        <Radio.Group data-test-id={"radio_group"} className="new-exists-radio-group" defaultValue={userData?.phonetype}>
          {SHAREABLE_PHONE_TYPES.map((type) => (
            <Radio key={type.value} data-test-id={"new_landlord"} value={type.value}>
              {type.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Business name" name="businessName" style={itemStyle} rules={[CHARACTERS_VALIDATION, REQUIRED]}>
        <Input placeholder={"Doe Realty"} defaultValue={userData?.office_name} />
      </Form.Item>
      <p style={{ fontWeight: "bold" }}>Business address:</p>
      <div style={{ marginBottom: "10px" }}>
        <Form.Item
          name="addr"
          label="Address"
          className="area_field"
          rules={[CHARACTERS_VALIDATION, SHAREABLE_ADDRESS_1_VALIDATION]}
        >
          <PlacesAutocomplete
            defaultAddress={userData?.address?.addr || ''}
            onAddressChange={onAddressChange}
            resetPlacesAutocomplete={resetPlacesAutocomplete}
            setResetPlacesAutocomplete={setResetPlacesAutocomplete}
            placeholder={"Enter your business address"}
          />
        </Form.Item>
      </div>
      <Form.Item label="Street Number" name="streetNumber" style={itemStyle}>
        <Input placeholder={"123 Main St"} />
      </Form.Item>
      <Form.Item label="Street Name" name="streetName" style={itemStyle} rules={[REQUIRED]}>
        <Input placeholder={"Main St"} />
      </Form.Item>
      <Form.Item label="Unit Number" name="unit" style={itemStyle}>
        <Input placeholder={"123"} />
      </Form.Item>
      <Form.Item label="City" name="locality" style={itemStyle} rules={[SHAREABLE_LOCALITY_VALIDATION]}>
        <Input />
      </Form.Item>
      <Form.Item label="State" name="state" style={itemStyle} rules={[SHAREABLE_REGION_VALIDATION]}>
        <Input placeholder={"NY"} />
      </Form.Item>
      <Form.Item label="Postal Code" name="postalCode" style={itemStyle} rules={[SHAREABLE_POSTAL_CODE_VALIDATION]}>
        <Input placeholder={"12345"} />
      </Form.Item>
      <Form.Item label="Country" name="country" required style={itemStyle} rules={[REQUIRED]}>
        <Input placeholder={"USA"} />
      </Form.Item>
      <Form.Item
        name="acceptedTermsAndConditions"
        required
        style={{ ...itemStyle, marginTop: "10px" }}
        rules={[REQUIRED]}
        className="sticky-footer-item"
      >
        <Checkbox.Group>
          <Checkbox value={true}>Accepted</Checkbox>
          <Link to="/shareable_terms_and_conditions" target={"_blank"}>
            terms and conditions.
          </Link>
          <br />
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default AgentDetails;
