import Api from './api';
import { IObjectKeys } from "../helpers/globalTypes";
export default class EnumsApi {
    static getAreas(query: IObjectKeys) {
        const api = new Api('areas');
        return api.get('', { params: query });
    }
    static getFacetAreas(query: IObjectKeys) {
        const api = new Api('areas');
        return api.get('facet', { params: query });
    }
    static getFacetAreasForLeads(query: IObjectKeys) {
        const api = new Api('areas');
        return api.get('facet/leads', { params: query });
    }
}

