import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import ShareableApi from "../../api/Shareable";
import {
  getRentersScreeningRequestFail,
  getRentersScreeningRequestSuccess,
} from "./action";
import { GET_RENTERS_SCREENING_REQUEST } from "./types";

function* getRentersScreeningRequestSaga({ payload }: any): any {
  try {
    const response = yield call(ShareableApi.getRentersScreeningRequest, payload);
    yield put(getRentersScreeningRequestSuccess(response));
  } catch (e) {
    yield put(getRentersScreeningRequestFail(e));
  }
}

function* watchGetRentersScreeningRequestSaga() {
  yield takeLatest(
    GET_RENTERS_SCREENING_REQUEST,
    getRentersScreeningRequestSaga
  );
}

export default function* rootSaga() {
  yield all([fork(watchGetRentersScreeningRequestSaga)]);
}
