import React from "react";
import GoogleMapComponent from "../Shared/GoogleMapComponent";
import queryString from "query-string";
import { push } from "connected-react-router";
import BaseEmptyState from "../BaseEmptyState";
import { useDispatch } from "react-redux";
import { useWindowSize } from "../../hooks/useWindowSize";

const ListingMapSection = ({ listingData }: any) => {
  const dispatch = useDispatch();
  // const [map, setMap] = React.useState(null);
  const { width } = useWindowSize();
  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // console.log(bounds, 'bounds');
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    // setMap(null);
  }, []);

  const handleAddBtnClick = () => {
    const parsed = {
      // landlordId:
      lId: listingData._id,
      step: 1,
    };
    const stringified = queryString.stringify(parsed);
    dispatch(push(`/manageListing?${stringified}`));
  };

  const locationEmptyStateProps = {
    classname: "location-empty-state",
    title: "Location",
    description: "No location for this listing yet.",
    linkText: "+ Add Location",
    openWithModal: false,
    handleAddBtnClick,
    modalProps: {
      isModalVisible: false,
      children: "",
      modalTitle: "",
    },
  };
  return (
    <div className="listing-page-map-wrapper">
      {!listingData.lng && !listingData.lat ? (
        <div className={"listing-page-map-empty-state-wrapper"}>
          <BaseEmptyState {...locationEmptyStateProps} />
        </div>
      ) : null}
      {Object.keys(listingData).length > 0 &&
          <GoogleMapComponent
              containerStyle={{
                marginTop: "25px",
                width: "inherit",
                height:
                    width && width > 599
                        ? listingData.lng && listingData.lat
                            ? "500px"
                            : "280px"
                        : "280px",
              }}
              center={{
                lng: listingData.lng || -73.93,
                lat: listingData.lat || 40.73,
              }}
              zoom={13}
              onLoad={onLoad}
              onUnmount={onUnmount}
          />
      }
    </div>
  );
};

export default React.memo(ListingMapSection);
