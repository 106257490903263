import React from "react";
import SuccessLeadView from "./success-view/SuccessLeadView";
import { IObjectKeys } from "../../../../../../helpers/globalTypes";
import VerifyEmailSuccessIcon from "../../../../../icons/verify-email-icons/VerifyEmailSuccessIcon";
import "./VerifyEmail.scss";
import AccVerifiedIcon from "../../../../../icons/verify-email-icons/AccVerifiedIcon";

interface IVerifyEmail {
  isPhoneVerified: boolean;
  leadAccountLink: IObjectKeys;
}

const VerifyEmail = ({ isPhoneVerified, leadAccountLink }: IVerifyEmail) => {
  return (
    <div className={"renter-email-verified-wrapper"}>
      <SuccessLeadView
        Icon={!isPhoneVerified ? <VerifyEmailSuccessIcon /> : <AccVerifiedIcon />}
        header={`${isPhoneVerified ? "Account verified!" : "Email verified!"}`}
        descriptionOne={`${
          isPhoneVerified ? "You can now share your Rental Passport with anyone." : "Here’s your personal link:"
        }`}
        descriptionTwo={isPhoneVerified ? false : "You can now share your Rental Passport with any agent."}
        leadAccountLink={leadAccountLink?.accountLink}
        shareLink={leadAccountLink?.shareLink}
      />
    </div>
  );
};

export default VerifyEmail;
