import React, { useEffect } from "react";
import routes, { PERSONAS } from "routes/routesCode";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { useAppContext } from "libs/contextLib";
import { Route, RouteProps } from "react-router-dom";
import { getAuthToken } from "../../helpers/authUtils";
import { getLoggedInUser, signOut } from "store/user/action";
import RenterAuthLayout from "layouts/auth/RenterAuthLayout";
// Experimental
// import RenterAuthLayout from "layouts/tailwind/RenterAuthLayout";
import { LoadScriptNext } from "@react-google-maps/api";
import { googleMapsApiKey, googleMapsApiLibrariesArray } from "helpers/constants";
import { userSelector } from "../../store/user/selectors";
import { fetchPassport } from "api/RenterPortal";
import { setRenterPassport } from "store/renterPassport/action";

export interface RenterPortalRouteProps extends RouteProps {
  component: React.JSXElementConstructor<any>;
}

const RenterPortalRoute: React.FC<RenterPortalRouteProps> = ({ component: Component, ...rest }) => {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const fetchRenterPassport = async () => {
    try {
      const data = await fetchPassport();
      const { passport, agent, renter } = data;
      dispatch(setRenterPassport({ passport, agent, renter }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    function onLoad() {
      try {
        if (getAuthToken()) {
          dispatch(getLoggedInUser());
          userHasAuthenticated(true);
          fetchRenterPassport();
        } else {
          dispatch(signOut({ redirectUrl: "", email: user.email }));
          userHasAuthenticated(false);
        }
      } catch (e) {
        dispatch(push(routes.listings.path));
      }
    }
    onLoad();
  }, [dispatch]);

  return isAuthenticated && user?.role && user.role.toLowerCase() === PERSONAS.RENTER ? (
    <Route
      {...rest}
      render={(...renderProps) => (
        <LoadScriptNext id="script-loader" googleMapsApiKey={googleMapsApiKey} libraries={googleMapsApiLibrariesArray}>
          <RenterAuthLayout user={user}>{<Component {...renderProps} user={user} />}</RenterAuthLayout>
        </LoadScriptNext>
      )}
    />
  ) : null;
};

export default RenterPortalRoute;
