import React from "react";

interface IPropsTextAreaInput {
  field: { inputFormat: any };
  value: any;
  onChange?: any;
}

const TextAreaInput = ({ onChange, field, value }: IPropsTextAreaInput) => {
  return (
    <textarea
      className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-700 shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
      value={!!field.inputFormat ? field.inputFormat(value) : value}
      onChange={(e) => onChange({ field, value: e.target.value })}
    >
      {!!field.inputFormat ? field.inputFormat(value) : value}
    </textarea>
  );
};

export default TextAreaInput;
