import React, {memo, useState} from "react";
import {ACCEPTED_MEDIA_FILES} from "../../../../../helpers/constants";
import {getAuthToken} from "../../../../../helpers/authUtils";
import {beforeUploadCheck} from "../../../../../helpers/fileUtils";
import {Avatar, Spin, Upload} from "antd";
import {UploadFile} from "antd/es/upload/interface";
import {UploadChangeParam} from "antd/lib/upload/interface";
import {UserAvatarIcon} from "../../../../icons";
import "./SignupUserImage.scss";
import { AnalyticsService } from "helpers/analyticsService";
import { getSignUpVerificationFlowStep } from "helpers/globalFunctions";

interface ISignupUserImage {
    type: string;
    userData: any;
    setImageData: any;
}
const SignupUserImage = (props:ISignupUserImage) =>{
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loadingImage, setLoadingImage] = useState(false);
    const [image, setImage] = useState({});
    const onChange = ({ file, fileList: currentFiles }: UploadChangeParam) => {
        setFileList(currentFiles.filter((file) => !!file.status));
        setLoadingImage(true);
        if (file.status === "done") {
            setLoadingImage(false);
            props.setImageData(file);
            // @ts-ignore
            setImage(file);
        }
    };

    return (
      <div className={"sign-up-settings-upload"}>
        <Upload
          showUploadList={false}
          accept={`${ACCEPTED_MEDIA_FILES}`}
          method={"PUT"}
          name={"image"}
          multiple={true}
          action={`${process.env.REACT_APP_BASE_URL}/users/media/agent/${props.userData?._id || props.userData?._doc?._id}/image`}
          headers={{
            Authorization: "Bearer " + getAuthToken()
          }}
          onChange={onChange}
          id="upload-image-input"
          beforeUpload={(file) => beforeUploadCheck(file, fileList)}>
          <div className={"sign-up-settings-upload-wrapper"}>
            <div className={"sign-up-settings-upload-wrapper-text"}>
              <p>Set profile image</p>
              <span className={"sign-up-settings-upload-wrapper-text-desc"}>Agents with photos are 84% more likely to have rental leads work with them</span>
              <span className={"sign-up-settings-upload-wrapper-text-actionTxt"}>Click or drag image here.</span>
            </div>
            <div
              className={"sign-up-settings-upload-wrapper-image"}
              onClick={() => {
                AnalyticsService.reportSignUpProcess({ action: `sign_up_verification_flow_${getSignUpVerificationFlowStep()}_click`, label: "change_image" });
              }}>
              <div className={"sign-up-settings-upload-wrapper-image-avatar"}>
                <Spin spinning={loadingImage}>
                  <Avatar
                    size={props.type !== "desktop" ? 46 : 56}
                    data-test-id="avatar-image"
                    icon={<UserAvatarIcon />}
                    src={
                      props.userData?.image?.url
                        ? props.userData?.image?.url
                        : // @ts-ignore
                          image?.response?.url
                    }
                  />
                </Spin>
              </div>
              <span className={"sign-up-settings-upload-wrapper-image-text"}>Change</span>
            </div>
          </div>
        </Upload>
      </div>
    );
}

export default memo(SignupUserImage)