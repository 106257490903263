import React from "react";
import BaseModal from "../BaseModal";

const CallRentBaseModal: React.FC<{
  visible: boolean;
  setVisible: (visible: boolean) => void;
}> = ({ visible, setVisible }) => {
  return (
    <BaseModal
      isModalVisible={visible}
      modalTitle="Contact RentBase"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      cancelText="Close"
      cancelButtonProps={{
        className: "ant-btn ant-btn rb-secondary-btn rb-secondary-btn-without-shadow",
      }}
      okButtonProps={{
        className: "ant-btn rb-primary-btn",
      }}
      width={400}
      centered={true}
    >
      Please write to us at <a href="mailto:support@therentbase.com">support@therentbase.com</a> to resolve this issue.
    </BaseModal>
  );
};

export default CallRentBaseModal;
