import React from "react";

export const EditSquareIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9C0 4.02944 4.02944 0 9 0H39C43.9706 0 48 4.02944 48 9V40C48 44.9706 43.9706 49 39 49H9C4.02944 49 0 44.9706 0 40V9Z"
        fill="black"
      />
      <path
        d="M33.3152 15.6769L31.3216 13.6832C30.4107 12.7723 28.9287 12.7723 28.0178 13.6832C27.1602 14.5409 14.0609 27.6411 13.1855 28.5166C13.0923 28.6098 13.0298 28.7337 13.0076 28.8549L12.0108 34.2381C11.9722 34.4468 12.0387 34.6611 12.1888 34.8112C12.339 34.9615 12.5534 35.0279 12.7619 34.9893L18.1445 33.9923C18.2689 33.9691 18.3912 33.9061 18.4829 33.8143L33.3152 18.9809C34.2282 18.0679 34.2283 16.59 33.3152 15.6769ZM13.4489 33.5509L14.0519 30.2946L16.7051 32.9479L13.4489 33.5509ZM18.0272 32.447L14.5528 28.9724L27.2376 16.2866L30.712 19.7613L18.0272 32.447ZM32.4037 18.0694L31.6234 18.8498L28.149 15.3751L28.9293 14.5947C29.3375 14.1864 30.0018 14.1864 30.4101 14.5947L32.4037 16.5885C32.813 16.9978 32.813 17.6601 32.4037 18.0694Z"
        fill="white"
      />
    </svg>
  );
};
