import React from "react";
import {getReqCommunicationEnumInputs} from "../../../../../../../../helpers/globalFunctions";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import "./CreditScore.scss"

const CreditScore = () => {
  const requirementEnums = useSelector(requirementEnumsSelector);

  return (
    <div className={`credit-score-section `}>
      {getReqCommunicationEnumInputs(
        requirementEnums.find(
          (item: EnumInterface) => item.key === "credit_score",
        ),
        "inline",
        [],
          "",
        "credit_score"
      )}
    </div>
  )
};


export default CreditScore
