import React, { useEffect } from "react";
import "../styles.scss";
import { notification } from "antd";
import { useSelector } from "react-redux";
import {
  isRequirementFormSubmittedSelector,
  requirementFormErrorSelector,
  requirementFormSuccessSelector
} from "../../../store/requirement/selectors";
import { googleMapsApiKey, googleMapsApiLibrariesArray } from "../../../helpers/constants";
import RenterCreatedSuccess from "./components/renter-created-success";
import GlobalLoading from "../../Shared/GlobalLoading";
import {
  generateErrorMessage,
} from "../../../helpers/globalFunctions";
import { LoadScriptNext } from "@react-google-maps/api";
import RequirementFormSteps from "./components/requirement-form-steps";
import { TOKEN_ENUM, getAuthToken, getRefreshToken } from "../../../helpers/authUtils";
import { useLocation } from "react-router";


const RequirementForm = () => {
  const isRequirementFormSubmitted = useSelector(isRequirementFormSubmittedSelector);
  const requirementError = useSelector(requirementFormErrorSelector);
  const requirementSuccess = useSelector(requirementFormSuccessSelector);
  const isUserLoggedIn = !!(getAuthToken() && getRefreshToken());
  const location = useLocation();
  useEffect(() => {
    if (isUserLoggedIn) {
      localStorage.removeItem("renterDraft_token");
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (requirementSuccess) {
      if (requirementSuccess.token) {
        localStorage.setItem(TOKEN_ENUM.GUEST_ACCESS_TOKEN, requirementSuccess.token);
      }
    }
  }, [requirementSuccess]);

  useEffect(() => {
    if (requirementError) {
      notification.error({ message: generateErrorMessage(requirementError) });
    }
  }, [requirementError]);

  useEffect(() => {
    if (isRequirementFormSubmitted) {
      localStorage.removeItem("renterDraft_token");
    }
  }, [isRequirementFormSubmitted]);

  if (isRequirementFormSubmitted || location.search.includes("isSuccess=true")) {
    return <RenterCreatedSuccess />;
  }

  return (
    <LoadScriptNext
      googleMapsApiKey={googleMapsApiKey}
      libraries={googleMapsApiLibrariesArray}
      loadingElement={
        <div className={"lead-req-loading-spinner-wrapper"}>
          <GlobalLoading />
        </div>
      }
    >
      <div className={"create-requirement-page-wrapper"}>
        <RequirementFormSteps />
      </div>
    </LoadScriptNext>
  );
};

export default React.memo(RequirementForm);
