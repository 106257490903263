import React from "react";
import BaseModal from "../../Shared/BaseModal";
import "./ExportDataSuccessModal.scss";
import BaseButton from "../../Shared/BaseButton";

const ExportDataSuccessModal = (props: any) => {
    return (
        <BaseModal
            modalTitle={""}
            isModalVisible={props.isModalVisible}
            modalFooter={null}
            onCancel={props.closeModal}
            classname={"export-agent-success-modal"}
            width={335}
        >
            <span className={"export-agent-success-modal-successIcon"}>👍</span>
            <p>
                Your data have been successfully exported
                and sent to your email.
            </p>
            <BaseButton
                classnames={"rb-primary-btn"}
                onClick={props.closeModal}
            >
                Ok
            </BaseButton>
            {/*<span>Please check your email</span>*/}
        </BaseModal>
    );
};

export default ExportDataSuccessModal;