import React from "react";
import RenterPortalDashboardComp from "components/RenterPortal/RentalPortalDashboard";

const RenterPortalDashboard = () => {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <RenterPortalDashboardComp />
    </div>
  );
};
export default RenterPortalDashboard;
