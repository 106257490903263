import { Alert, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { IObjectKeys } from "helpers/globalTypes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateListing } from "store/listings/action";
import { listingLoadingSelector, listingUpdateSuccessSelector } from "store/listings/selectors";
import {
  clearShareablePropertyEntity,
  clearShareableScreeningEntity,
  createShareableLandlordEntity,
  createShareablePropertyEntity,
  createShareableScreeningRequestEntity,
  sendScreeningRequestToRenter,
} from "store/shareable/action";
import {
  createShareablePropertyEntityErrorSelector,
  renterScreeningsNotificationsSuccessSelector,
  shareableLandlordEntitySelector,
  shareableLoadingSelector,
  shareablePropertyEntitySelector,
  shareableScreeningEntitySelector,
} from "store/shareable/selectors";
import { updateUserSettings } from "store/user/action";
import { userSelector } from "store/user/selectors";
import { windowSelector } from "store/app/selectors";
import { setTimeout } from "timers";
import { BUTTON_BACK_TEXT } from "../../../../helpers/constants";
import BaseButton from "../../../Shared/BaseButton";
import BaseModal from "../../../Shared/BaseModal";
import AgentDetails from "./components/AgentDetails";
import AttachProperty from "./components/AttachProperty";
import ChooseBundling from "./components/ChooseBundling";
import MissingPropertyData from "./components/MissingPropertyData";
import Success from "./components/Success";
import SelectRenter from "components/TenantScreenings/add-modal/select-renter";

interface IAlert {
  should_show?: boolean;
  message?: string;
  description?: string;
  type?: "success" | "error" | "info" | "warning" | undefined;
}

const LeadRequestScreeningDialog = ({
  visible,
  setVisible,
  renter,
  forTenantScreening,
  refreshList,
}: {
  visible: boolean;
  setVisible: Function;
  renter?: IObjectKeys;
  forTenantScreening?: boolean;
  refreshList?: Function;
}) => {
  const { type } = useSelector(windowSelector);
  const isMobile = type === "mobile";
  const userData = useSelector(userSelector);
  const shareableLandlordEntity = useSelector(shareableLandlordEntitySelector);
  const shareablePropertyEntity = useSelector(shareablePropertyEntitySelector);
  const shareableScreeningEntity = useSelector(shareableScreeningEntitySelector);
  const shareableLoading = useSelector(shareableLoadingSelector);
  const createShareablePropertyEntityError = useSelector(createShareablePropertyEntityErrorSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const listingUpdateSuccess = useSelector(listingUpdateSuccessSelector);
  const renterScreeningsNotificationsSuccess = useSelector(renterScreeningsNotificationsSuccessSelector);
  const [step, setStep] = useState<number>(forTenantScreening ? 0 : shareableLandlordEntity?.id ? 2 : 1);
  const [screeningData, setScreeningData] = useState({
    renter_id: "",
    renter_name: "",
    property_details: {
      id: "",
      addressLine1: "",
      locality: "",
      street_name: "",
      street_number: "",
      unit: "",
      state: "",
    },
    bundling: ["Credit, Criminal & Eviction Reports ($36)"],
  });
  const [agentDetails, setAgentDetails] = useState({ firstName: "" });
  const [renterDetails, setRenterDetails] = useState<any>();
  const [alert, setAlert] = useState<IAlert>({
    should_show: false,
    message: "",
    description: "",
    type: undefined,
  });
  const [form] = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    if (renterDetails?.renter_id) {
      setScreeningData({
        ...screeningData,
        renter_id: renterDetails.renter_id,
        renter_name: renterDetails.name,
      });
      forTenantScreening && setStep(shareableLandlordEntity?.id ? 2 : 1);
    }
  }, [renterDetails]);

  // clearing step to zero while in tenant screening page
  useEffect(() => {
    if (visible) {
      forTenantScreening && setStep(0);
    }
  }, [visible]);

  useEffect(() => {
    if (step === 1 && shareableLandlordEntity?.id) {
      setStep(2); // choose property step
    }
  }, [userData, userData.shareableLandlordId, shareableLandlordEntity, shareableLandlordEntity.id]);

  useEffect(() => {
    if ((step === 2 || step === 3) && shareablePropertyEntity.propertyId) {
      setStep(4); // bundle step
    }
  }, [shareablePropertyEntity]);

  useEffect(() => {
    if (step === 2 && createShareablePropertyEntityError && createShareablePropertyEntityError.data?.errors?.length) {
      setStep(3); // missing property data step
    }
  }, [createShareablePropertyEntityError]);

  useEffect(() => {
    if (step === 3 && listingUpdateSuccess) {
      handlePropertySubmit(); // creating a shareableProperty
    }
  }, [listingUpdateSuccess]);

  useEffect(() => {
    if (step === 4 && shareableScreeningEntity.screeningRequestId) {
      dispatch(
        sendScreeningRequestToRenter({
          data: {
            renter_id: renterDetails.renter_id,
            agent_id: userData.agent_id,
            screening_request_id: shareableScreeningEntity.screeningRequestId.toString(),
          },
        })
      );
    }
  }, [shareableScreeningEntity]);

  useEffect(() => {
    if (step === 4) {
      setStep(5);
      setTimeout(() => {
        handleCancel();
      }, 2000);
    }
  }, [renterScreeningsNotificationsSuccess]);

  useEffect(() => {
    if (step === 3 && shareableScreeningEntity.screeningRequestId) {
      setStep(4);
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [shareableScreeningEntity]);

  useEffect(() => {
    if (alert.should_show) {
      const timeOut = setTimeout(() => {
        setAlert({ ...alert, should_show: false });
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    if (renter) {
      setRenterDetails(renter);
    } else {
      setStep(0);
    }
  }, [renter]);

  const handleCancel = () => {
    if (shareableLandlordEntity?.id) {
      setStep(2);
    } else {
      setStep(1);
    }
    handleClose();
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleSkip = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    if (step === 4) {
      // not showing MissingPropertyData component
      setStep(2);
    } else if (step === 2) {
      setStep(0);
    } else {
      setStep(step - 1);
    }
    dispatch(clearShareablePropertyEntity());
  };

  const handleClose = () => {
    setVisible(false);
    dispatch(clearShareablePropertyEntity());
    dispatch(clearShareableScreeningEntity());
    forTenantScreening && refreshList && refreshList();
    forTenantScreening && setRenterDetails(null);
    forTenantScreening && window.history.replaceState(null, document.title, window.location.pathname);
  };

  const handleSubmit = (data: IObjectKeys) => {
    form.submit();
  };

  const handleAgentDetailsSubmit = async (values: IObjectKeys) => {
    const regex = /\(|\)|\s|-/g;
    const phoneNumber = values.phoneNumber.replace(regex, "");

    const formattedData = {
      emailAddress: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber,
      phoneType: values.phoneType,
      businessName: values.businessName,
      businessAddress: {
        addressLine1: `${values.streetNumber} ${values.streetName}`,
        addressLine2: values.unit || "",
        locality: values.locality,
        region: values.state,
        postalCode: values.postalCode,
        country: values.country,
      },
      acceptedTermsAndConditions: true,
    };

    const userFormattedData = {
      id: userData._id,
      email: userData.email,
      phone: phoneNumber,
      phoneType: values.phoneType,
      name: values.firstName + " " + values.lastName,
      office_name: values.businessName,
      address: {
        addr: values.addr,
        street_number: values.streetNumber,
        street_name: values.streetName,
        unit: values.unit,
        city: values.locality,
        zipCode: values.postalCode,
        state: values.state,
        country: values.country,
      },
      addressType: "Business", //  Agent address is default business address
    };

    await dispatch(updateUserSettings({ _id: userData._id, ...userFormattedData }));
    await dispatch(createShareableLandlordEntity({ agentId: userData.agent_id, data: formattedData }));
  };

  const handlePropertySubmit = async () => {
    await dispatch(
      createShareablePropertyEntity({
        shareableLandlordId: shareableLandlordEntity.id,
        listingId: screeningData.property_details.id,
      })
    );
  };

  const handlePropertyDetailsUpdateSubmit = (values: IObjectKeys) => {
    const formattedData = {
      addr: values.generatedAddr,
      town: values.locality,
      zip_code: values.postalCode,
      state: values.state,
      street_name: values.street_name,
      street_number: values.street_number,
      unit: values.unit,
      generatedAddr: values.generatedAddr,
    };

    dispatch(
      updateListing({
        lId: screeningData.property_details.id,
        ...formattedData,
      })
    );
  };

  const handleScreeningRequestSubmit = async () => {
    await dispatch(
      createShareableScreeningRequestEntity({
        shareableLandlordId: shareableLandlordEntity.id,
        shareablePropertyId: shareablePropertyEntity.propertyId,
        data: { renter_id: renterDetails.renter_id, renter_role: "Applicant" },
      })
    );
  };

  const onFinish = (values: IObjectKeys) => {
    switch (true) {
      case step === 1:
        handleAgentDetailsSubmit(values);
        break;
      case step === 2:
        break;
      case step === 3:
        handlePropertyDetailsUpdateSubmit(values);
        break;
      case step === 4:
        handleScreeningRequestSubmit();
      default:
        break;
    }
  };

  const stepsContent = [
    // STEP 0
    {
      label: "renter details",
      component: <SelectRenter setRenter={setRenterDetails} />,
      header: "Select Renter",
      mainAction: undefined,
      secondaryAction: undefined,
    },
    // STEP 1
    {
      label: "agent property details",
      component: <AgentDetails form={form} agentDetails={agentDetails} setAgentDetails={setAgentDetails} />,
      header: "Provide your business information",
      mainAction: { title: "Next", action: (data: any) => handleSubmit(data) },
      secondaryAction: undefined,
    },
    // STEP 2
    {
      label: "attach_property",
      component: <AttachProperty form={form} screeningData={screeningData} setScreeningData={setScreeningData} />,
      header: "Enter Property Address for Screening",
      mainAction: { title: "Next", action: (data: any) => handleSubmit(data) },
      secondaryAction: { title: "Skip", action: () => handleSkip() },
    },
    // STEP 3
    {
      label: "missing_property_data",
      component: <MissingPropertyData form={form} listing={screeningData.property_details} />,
      header: "Missing property information",
      mainAction: { title: "Next", action: (data: any) => handleSubmit(data) },
      secondaryAction: undefined,
    },
    // STEP 4
    {
      label: "choose_bundling",
      component: <ChooseBundling renterName={renterDetails?.name} />,
      header: (
        <div>
          <div className="text-sm">Send your screening request for</div>
          <div className="text-2lg font-semibold mt-2 text-purple-700">{shareablePropertyEntity.propertyName}</div>
        </div>
      ),
      mainAction: {
        title: "Send",
        type: "submit",
        action: (data: any) => handleSubmit(data),
      },
      secondaryAction: undefined,
    },
    // STEP 5
    {
      label: "success",
      component: <Success msg={renterScreeningsNotificationsSuccess.message} />,
      mainAction: {},
      secondaryAction: undefined,
    },
  ];

  return (
    <div>
      <BaseModal
        classname={`with-header single-listing-page-add-note listing-add-edit-modal request-modal ${
          step === 5 ? "footerDisable" : ""
        } ${step === 0 ? " with-pagination" : ""} ${step === 2 ? " with-fix-height" : ""}`}
        removeBodyStyle={step !== 0}
        cancelButtonProps={{
          className: "rb-secondary-btn sm",
        }}
        okButtonProps={{
          className: "rb-primary-btn sm",
        }}
        width={isMobile ? undefined : 800}
        closeIcon={step !== stepsContent.length - 1 ? "X" : " "}
        modalTitle={stepsContent[step].header}
        isModalVisible={visible}
        onCancel={handleCancel}
        centered
        modalFooter={[
          (forTenantScreening ? step >= 2 : step > 2) && step !== stepsContent.length - 1 && (
            <div className={"secondary-button"}>
              <BaseButton onClick={handleBack}>{BUTTON_BACK_TEXT}</BaseButton>
            </div>
          ),
          stepsContent[step].mainAction && (
            <BaseButton
              loading={shareableLoading || listingLoading}
              disabled={shareableLoading || listingLoading}
              classnames={"rb-primary-btn md br-16 w-100"}
              onClick={stepsContent[step].mainAction?.action}
              type="submit"
              variant="primary"
            >
              {stepsContent[step].mainAction?.title}
            </BaseButton>
          ),
        ]}
      >
        {alert.should_show && (
          <div className="my-4">
            <Alert message={alert.message} description={alert.description} type={alert.type} showIcon closable />
          </div>
        )}
        {/* AGENT DETAILS FORM */}
        <Form
          form={form}
          layout={"vertical"}
          className={"add-note-modal-form-wrapper" + (step === 2 ? " attach-property-form" : "")}
          onFinish={onFinish}
        >
          <Form.Item>{stepsContent[step].component}</Form.Item>
        </Form>
      </BaseModal>
    </div>
  );
};

export default LeadRequestScreeningDialog;
