import React from "react";
import { Form, Modal } from "antd";
import MakeListingAvailable from "./MakeListingAvailable";
import MakeListingOffMarket from "./MakeListingOffMarket";

interface IChangeStatusModalProps {
  visible: boolean;
  setVisible: (state: boolean) => void;
  isAvailable: boolean;
}

const ChangeStatusModal = ({ visible, setVisible, isAvailable }: IChangeStatusModalProps) => {
  const [availableStep, setAvailableStep] = React.useState(0);
  const [offMarketStep, setOffMarketStep] = React.useState(0);
  const [form] = Form.useForm();

  return (
    <Modal
      className={"change-listing-status-modal"}
      title={`Change Listing Status to ${isAvailable ? "Off-Market" : "Active"}`}
      visible={visible}
      width={550}
      onCancel={() => {
        setVisible(false);
        setOffMarketStep(0);
        setAvailableStep(0);
        form.resetFields();
      }}
      maskClosable={false}
      footer={null}
    >
      <div className="mt-5">
        {isAvailable ? (
          <MakeListingOffMarket
            form={form}
            closeModal={() => setVisible(false)}
            step={offMarketStep}
            setStep={(step) => setOffMarketStep(step)}
          />
        ) : (
          <MakeListingAvailable
            form={form}
            closeModal={() => setVisible(false)}
            step={availableStep}
            setStep={(step) => setAvailableStep(step)}
          />
        )}
      </div>
    </Modal>
  );
};

export default ChangeStatusModal;
