import { Radio } from 'antd';
import React from 'react';
import './styles.scss';
import DogIcon from "../../../../icons/pets-icons/DogIcon";
import CatIcon from "../../../../icons/pets-icons/CatIcon";

interface ISelectPetsTypeProps {
    onChange: (name: string, value: string) => void,
    species: string
}

const SelectPetsType = ({
    onChange,
    species
}: ISelectPetsTypeProps
) => {
    return (
        <Radio.Group
            className={"requirement-select-pets-type"}
            name="species"
            //@ts-ignore
            onChange={(e) => onChange(e.target.name, e.target.value)}
            // value={species}
            defaultValue={species}
        >
            <Radio value={"dog"} >
                <div className={"communication-radio-btn"}>
                    <DogIcon/>
                    <span className={"communication-radio-btn-text"}>
                        Yes, I have a dog
                    </span>
                </div>
            </Radio>
            <Radio value={"cat"} >
                <div className={"communication-radio-btn"}>
                    <CatIcon/>
                    <span className={"communication-radio-btn-text"}>
                        Yes, I have a cat
                    </span>
                </div>
            </Radio>
        </Radio.Group>
    );

}

export default SelectPetsType;