import React, {useState} from "react";
import "./PetsCollapseItem.scss"
import PetsSelectListItem from "../pets-select-list-item/PetsSelectListItem";
import BaseCollapse from "../../../../../../../../Shared/BaseCollapse";
import {IpetsListItem} from "../../PetsInfo";
interface IPetsCollapseItem{
    petsItem:IpetsListItem,
    handleDeletePetsItem:(id: string) => void,
    setPetsList:React.Dispatch<React.SetStateAction<IpetsListItem[]>>,
    index:number
}
const PetsCollapseItem = (props:IPetsCollapseItem) =>{
    const [collapsed,setCollapsed] = useState(false)
    return(
        <div>
            <BaseCollapse
                collapse={false}
                classname={"req-pets-collapse-item"}
                onDetectCollapsedFunction={(value:any)=>{
                    setCollapsed(value)
                }}
                header={
                    <>
                        {collapsed ?
                            <span className="req-pets-collapse-item-headerTxt">
                                {props.petsItem.amount}{" "}
                                <span>
                                    {props.petsItem.species.charAt(0).toUpperCase() + props.petsItem.species.slice(1)}
                                </span>
                                {props.petsItem.amount > 1 ? "s" : ""}
                            </span>:
                            <span className="req-pets-collapse-item-headerTxt">Pet {props.index+1}</span>
                        }
                    </>
                }
            >
                <div className="pets-allowed-requirement-collapse-wrapper">
                    <PetsSelectListItem
                        petsItem={props.petsItem}
                        setPetsList={props.setPetsList}
                        handleDeletePetsItem={props.handleDeletePetsItem}
                        dataScrollId={props.petsItem.id}
                    />
                </div>

            </BaseCollapse >
        </div>
    )
}

export default PetsCollapseItem