import React from "react";
import BaseButton from "../Shared/BaseButton";
import "./styles.scss";
import BaseModal, { IBaseModal } from "../Shared/BaseModal";
import { useSelector } from "react-redux";
import { isPublicListingSelector } from "../../store/listings/selectors";
import { userSelector } from "store/user/selectors";
import { isRenter } from "helpers/userHelpers";

interface IBaseEmptyState {
  title?: string;
  linkText?: string;
  forceRenderLink?: boolean;
  description?: string;
  openWithModal?: boolean;
  modalProps: IBaseModal;
  handleAddBtnClick: Function;
  classname?: string;
  modalType?: string;
}

const BaseEmptyState = ({
  title,
  description,
  openWithModal,
  linkText,
  forceRenderLink,
  modalProps,
  handleAddBtnClick,
  classname,
  modalType,
}: IBaseEmptyState) => {
  const isPublicListing = useSelector(isPublicListingSelector);
  const user = useSelector(userSelector);

  return (
    <div className={`empty-state-wrapper ${classname ? classname : ""}`}>
      <div className={"empty-state-wrapper--left"}>
        {description && <span className={"empty-state-description mr-2"}>{description}</span>}
      </div>
      {title === "Listing Notes" || title === "Listing Follow-ups" ? (
        <BaseButton
          onClick={() => handleAddBtnClick()}
          classnames={"ant-btn rb-secondary-btn add-photo-btn mr-10 ml-10 add-note-button"}
          modalType={modalType}
        >
          {title === "Listing Notes" ? "Add Note" : " Add Follow-Up"}
        </BaseButton>
      ) : (
        !isRenter(user) &&
        (!isPublicListing || forceRenderLink) && (
          <BaseButton
            onClick={() => handleAddBtnClick()}
            classnames={"rb-secondary-btn add-photo-btn ml-10"}
            modalType={modalType}
          >
            {"\u00A0" + linkText}
          </BaseButton>
        )
      )}

      {openWithModal && <BaseModal {...modalProps} />}
    </div>
  );
};

export default React.memo(BaseEmptyState);
