import { setLeadsLoadingToTrue } from "./../leads/action";
import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import RequirementApi from "../../api/Requirement";
import {
  CREATE_REQUIREMENT_FORM,
  GET_REQUIREMENT_AGENT,
  GET_RENTERS_REQUIREMENT,
  LEAD_REVOKE_REQUIREMENT,
  LEAD_ACCESS_REQUIREMENT,
  EDIT_REQUIREMENT_FORM,
  DECLINE_FOR_REQUIREMENT,
  CHECK_RENTER_STATUS, CREATE_RENTER_DRAFT, UPDATE_RENTER_DRAFT, GET_REQUIREMENT_DRAFT, LEAD_STATUS_REQUIREMENT, NOTIFY_AGENTS,
} from "./types";
import {
  checkRenterStatusFail,
  checkRenterStatusSuccess, createDraftRenterFail, createDraftRenterSuccess,
  createRequirementFormFail,
  createRequirementFormSuccess,
  editRequirementFaild,
  editRequirementSuccess, getDraftRenterFail, getDraftRenterSuccess,
  getRequirementAgentFail,
  getRequirementAgentSuccess,
  leadActionError,
  leadActionSuccess,
  startLeadAnyAction, updateDraftRenterFail, updateDraftRenterSuccess,
  leadApproveSuccess,
  leadChangeStatusSuccess,
  startLeadStatusAction,
  notifyAgentsSuccess,
} from "./action";
import { getLeadDataFail, getLeadDataSuccess } from "store/leads/action";

function* getRequirementAgentSaga({ payload }: any) {
  try {
    //@ts-ignore
    const response = yield call(RequirementApi.getAgentInfo, payload);
    yield put(getRequirementAgentSuccess(response));
  } catch (e) {
    //@ts-ignore
    yield put(getRequirementAgentFail(e));
  }
}

function* createRequirementSaga({ payload }: any) {
  try {
    //@ts-ignore
    const response = yield call(RequirementApi.createRequirement, payload);
    yield put(createRequirementFormSuccess(response));
  } catch (e) {
    //@ts-ignore
    yield put(createRequirementFormFail(e));
  }
}
function* checkRenterStatusSaga({ payload }: any) {
  try {
    //@ts-ignore
    const response = yield call(RequirementApi.checkRenterStatus, payload);
    yield put(checkRenterStatusSuccess(response));
  } catch (e) {
    //@ts-ignore
    yield put(checkRenterStatusFail(e));
  }
}

function* createDraftRenterSaga({ payload }: any) {
  try {
    //@ts-ignore
    const response = yield call(RequirementApi.createRequirementDraft, payload);
    yield put(createDraftRenterSuccess(response));
  } catch (e) {
    //@ts-ignore
    yield put(createDraftRenterFail(e));
  }
}

function* notifyAgentsSaga({ payload }: any) {
  try {
    //@ts-ignore
    const response = yield call(RequirementApi.notifyAgents, payload);
    yield put(notifyAgentsSuccess(response));

  } catch (e) {
    //@ts-ignore
    yield put(leadActionError(e));
  }
}

function* getRequirmementRenterSaga({ payload }: any) {
  try {
    yield put(setLeadsLoadingToTrue());
    //@ts-ignore
    const response = yield call(
      RequirementApi.getRentersOneRequirement,
      payload
    );
    yield put(getLeadDataSuccess(response));
  } catch (e) {
    //@ts-ignore
    yield put(getLeadDataFail(e));
  }
}

function* acceptForRequirementSaga({ payload }: any) {
  try {
    yield put(startLeadAnyAction());
    //@ts-ignore
    yield call(RequirementApi.acceptForRequirement, payload);
    if (payload.type === "fromDropdown") {
      //@ts-ignore
      const response = yield call(RequirementApi.getRentersOneRequirement, {
        requirements_id: payload.requirements_id,
      });
      yield put(getLeadDataSuccess(response));
    }
    yield put(leadActionSuccess());
    yield put(leadApproveSuccess());
  } catch (e) {
    //@ts-ignore
    yield put(leadActionError(e));
  }
}
function* declineForRequirementSaga({ payload }: any) {
  try {
    yield put(startLeadAnyAction());
    yield call(RequirementApi.declineForRequirement, payload);
    //@ts-ignore
    const response = yield call(RequirementApi.getRentersOneRequirement, {
      requirements_id: payload.requirements_id,
    });
    yield put(getLeadDataSuccess(response));
    yield put(leadActionSuccess());
  } catch (e) {
    //@ts-ignore
    yield put(leadActionError(e));
  }
}
function* revokeRequirementSaga({ payload }: any) {
  try {
    yield put(startLeadAnyAction());
    yield call(RequirementApi.revokeForRequirement, payload);
    yield put(leadActionSuccess());
  } catch (e) {
    //@ts-ignore
    yield put(getLeadDataFail(e));
  }
}
function* editRequirementSaga({ payload }: any) {
  try {
    //@ts-ignore
    yield call(RequirementApi.editRequirement, payload);
    yield put(editRequirementSuccess());
  } catch (e) {
    //@ts-ignore
    yield put(editRequirementFaild(e));
  }
}

function* updateDraftRequirementSaga({ payload }: any) {
  try {
    //@ts-ignore
    yield call(RequirementApi.updateRequirementDraft, payload);
    yield put(updateDraftRenterSuccess(payload));
  } catch (e) {
    //@ts-ignore
    yield put(updateDraftRenterFail(e));
  }
}

function* getDraftRequirementSaga() {
  try {
    const response = yield call(RequirementApi.getRequirementDraft);
    //@ts-ignore
    yield put(getDraftRenterSuccess(response));
  } catch (e) {
    //@ts-ignore
    yield put(getDraftRenterFail(e));
  }
}

function* changeRenterStatusSaga({ payload }: any) {
  try {
    yield put(startLeadStatusAction());
    //@ts-ignore
    yield call(RequirementApi.changeRenterStatus, payload);
    yield put(leadChangeStatusSuccess());
  } catch (e) {
    //@ts-ignore
    yield put(leadChangeStatusError(e));
  }
}

function* watchGetDraftRequirementSaga() {
  yield takeLatest(GET_REQUIREMENT_DRAFT,getDraftRequirementSaga)
}
function* watchUpdateDraftRequirementSaga() {
  yield takeLatest(UPDATE_RENTER_DRAFT, updateDraftRequirementSaga);
}
function* watchGetRequirementAgentSaga() {
  yield takeLatest(GET_REQUIREMENT_AGENT, getRequirementAgentSaga);
}

function* watchCreateRequirementSaga() {
  yield takeLatest(CREATE_REQUIREMENT_FORM, createRequirementSaga);
}
function* watchCheckRenterStatusSaga() {
  yield takeLatest(CHECK_RENTER_STATUS, checkRenterStatusSaga);
}

function* watchCreateDraftRenterSaga() {
  yield takeLatest(CREATE_RENTER_DRAFT, createDraftRenterSaga);
}

function* watchNotifyAgentsSaga() {
  yield takeLatest(NOTIFY_AGENTS, notifyAgentsSaga);
}

function* watchGetRequirmementRenterSaga() {
  yield takeLatest(GET_RENTERS_REQUIREMENT, getRequirmementRenterSaga);
}

function* watchAcceptForRequirementSaga() {
  yield takeLatest(LEAD_ACCESS_REQUIREMENT, acceptForRequirementSaga);
}

function* watchRevokeForRequirementSaga() {
  yield takeLatest(LEAD_REVOKE_REQUIREMENT, revokeRequirementSaga);
}
function* watchEditRequirementSaga() {
  yield takeLatest(EDIT_REQUIREMENT_FORM, editRequirementSaga);
}
function* watchDeclineForRequirementSagaSaga() {
  yield takeLatest(DECLINE_FOR_REQUIREMENT, declineForRequirementSaga);
}
function* watchChangeRenterStatusSaga() {
  yield takeLatest(LEAD_STATUS_REQUIREMENT, changeRenterStatusSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetRequirementAgentSaga)]);
  yield all([fork(watchCreateRequirementSaga)]);
  yield all([fork(watchGetRequirmementRenterSaga)]);
  yield all([fork(watchAcceptForRequirementSaga)]);
  yield all([fork(watchRevokeForRequirementSaga)]);
  yield all([fork(watchEditRequirementSaga)]);
  yield all([fork(watchDeclineForRequirementSagaSaga)]);
  yield all([fork(watchCheckRenterStatusSaga)]);
  yield all([fork(watchCreateDraftRenterSaga)]);
  yield all([fork(watchUpdateDraftRequirementSaga)]);
  yield all([fork(watchGetDraftRequirementSaga)]);
  yield all([fork(watchChangeRenterStatusSaga)]);
  yield all([fork(watchNotifyAgentsSaga)]);
}
