import React from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Avatar from "./Avatar";

interface IRenterInfo {
  renter: any;
}

const RenterInfo = ({ renter }: IRenterInfo) => {
  return (
    <div className="flex items-center">
      {renter?.imagesOfAgent?.url && (
        <div className="mr-4">
          <Avatar url={renter?.imagesOfAgent?.url} alt={`${renter.name} image`} />
        </div>
      )}
      <div className="flex flex-wrap items-center justify-start">
        <h1 className="text-4xl font-bold mr-4">{renter.name}</h1>
        {renter.account_verified ? (
          <>
            <CheckCircleIcon className="h-5 w-5 stroke-green-600  mr-1 fill-none" />
            Account Verified
          </>
        ) : (
          <>
            <QuestionMarkCircleIcon className="h-5 w-5 stroke-red-500 mr-1 fill-none" /> Pending Verification
          </>
        )}
      </div>
    </div>
  );
};

export default RenterInfo;
