import { FormInstance, Input ,Form } from "antd";
import React, {  useEffect, useRef, useState } from "react";
import "./styles.scss";
import { FieldData } from "rc-field-form/lib/interface";

interface ISeparetedInputsProps {
    inputCount: number;
    formClassName?: string;
    form: FormInstance<any>;
    onSubmit: any;
    setIsOpenSubmitButton: React.Dispatch<React.SetStateAction<boolean>>;
    setIsFinished?: React.Dispatch<React.SetStateAction<boolean>>;
}
interface ISepareteField {
    focused: boolean;
    refedInput?: React.MutableRefObject<undefined>;


}
const SeparetedInputs = ({
    inputCount,
    formClassName,
    form,
    onSubmit,
    setIsOpenSubmitButton,
    setIsFinished
}: ISeparetedInputsProps) => {
    const dummyRef = useRef();
    const refedInput = useRef();
    const fields: ISepareteField[] = [{ focused: true, refedInput: refedInput }];

    for (let i = 2; i <= inputCount; i++)
        fields.push({ focused: false });
    const [verifyArray, setVerifyArray] = useState<any>(fields);


    const onFieldsChange = (
        changedFields: FieldData[],
        allFields: FieldData[]
    ) => {
        // @ts-ignore
        if (changedFields[0].name[0] === 6) setIsFinished(true)
        setVerifyArray(verifyArray.map((elem: ISepareteField, index: number) => {
            // @ts-ignore
            if (index === +changedFields[0].name[0]) return { focused: true, refedInput: refedInput };
            return { focused: false };
        }));
        if (
            Object.keys(form.getFieldsValue(true)).length === inputCount &&
            Object.values(form.getFieldsValue(true)).filter(value => value !== "").length > inputCount-1
        ) {
            inputCount === 6 ? setIsOpenSubmitButton(false) : setIsOpenSubmitButton(true);
        } else {
            inputCount === 6 ? setIsOpenSubmitButton(true) : setIsOpenSubmitButton(false);
        }
    };

    useEffect(() => {
        // @ts-ignore
        if (refedInput.current) {
            // @ts-ignore
            refedInput.current.focus();
        }
    }, [verifyArray]);
    useEffect(() => {
        form.resetFields();
    }, [form]);

    return (
        <Form
            form={form}
            onFieldsChange={onFieldsChange}
            className={`${formClassName ?? ""} separated-input-fields`}
            onFinish={(data)=>{
                onSubmit(Object.values(data).join(""));
            }}
        >
            {
                verifyArray.map((element: any, index: number) => {
                    return (
                        <Form.Item
                            rules={[{ required: true, message: "" }, { max: 1 }]}
                            name={index + 1}
                            key={index}
                        >
                            <Input maxLength={1}
                                autoFocus={element.focused}
                                ref={element.refedInput || dummyRef}
                            />
                        </Form.Item>
                    );
                })
            }

        </Form>
    );
};

export default SeparetedInputs;