import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AnalyticsService } from "../../../helpers/analyticsService";
import { TbBroadcast, TbBroadcastOff } from 'react-icons/tb';
import BaseButton from "../BaseButton";
import BaseModal from "../BaseModal";

import "./style.scss";
import { EVENT_NAME } from "helpers/analytics/eventEnums";
//Must Fix Code Duplication
const SyndicateListingModal = ({
  visible,
  handleAction,
  onClose,
  listing,
  isDemo,
}: {
  visible: boolean;
  isDemo: boolean,
  onClose: () => void;
  handleAction: (value: string) => void;
  listing?: any;
}) => {
  const dispatch = useDispatch();
  const SYNDICATION_CHANNEL = 'Realtor.com';

  const inviteHandler = () => {
    AnalyticsService.shareLeadModal({ label: "share_by_email" });
  };

  useEffect(() => {

  }, []);

  return (
    <>
      <BaseModal
        classname={"syndicate-listing-modal"}
        isModalVisible={visible}
        dataTestId="syndicate-listing-modal"
        modalTitle={""}
        onCancel={() => {
          onClose();
        }}
        modalFooter={[]}
      >
        <div className={"syndicate-listing-modal-body"}>
          <h2>Listing Syndication</h2>
          <p>{`Publish this listing to sites like ${SYNDICATION_CHANNEL} to maximize visibility and improve standing in the marketplace.`}</p>
          <div className="pt-8">
            {listing?.published_rdt?.published_at

              ?
              (
                <BaseButton
                  classnames={"rb-secondary-btn onboard-lead-copy-text-wrapper--emailInviteBtn sm"}
                  onClick={() => {
                    AnalyticsService.reportClickEvent(EVENT_NAME.SYNDICATE_UNPUBLISH_CLICK, "syndicate_modal", "");
                    handleAction("unpublish");
                  }}
                >
                  <><TbBroadcastOff /> {`Un-Syndicate from ${SYNDICATION_CHANNEL}`}</>
                </BaseButton>
              )
              : (
                <BaseButton
                  classnames={"rb-secondary-btn onboard-lead-copy-text-wrapper--emailInviteBtn sm"}
                  onClick={() => {
                    AnalyticsService.reportClickEvent(EVENT_NAME.SYNDICATE_PUBLISH_CLICK, "syndicate_modal", "");
                    handleAction("publish");
                  }}
                >
                  <>
                    <TbBroadcast /> {`Syndicate to ${SYNDICATION_CHANNEL}`}
                  </>
                </BaseButton>
              )
            }
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default SyndicateListingModal;
