import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Form } from "antd";
import TimePicker from "react-time-picker";
import "./AddFollowUpModalMobileDatePicker.scss";
import { useDispatch, useSelector } from "react-redux";
import { CalendarIcon } from "../../../icons";
import { US_DATE_FORMAT } from "../../../../helpers/constants";
import { addFollowUp, editFollowUp } from "../../../../store/followUps/action";
import { getBrowser, getBrowserDateFormat } from "../../../../helpers/globalFunctions";
import MozTimePicker from "../../add-follow-up-modal/date-picker-section/moz/MozTimePicker";
import { updateLeadTags } from "store/leads/action";
import { singleLeadDataSelector } from "store/leads/selectors";
import { addTimeAndFormatDate, currentDate, formatDate } from "../../../../helpers/dateHelper";

const format = "HH:mm A";
interface IAddFollowUpModalMobileDatePicker {
  submitData: any;
  listing: any;
  landlordInfo: any;
  lead: any;
  isFollowUpEditable: boolean;
  modalProps: any;
  setModalProps: any;
  initialModalValue: any;
  submitDataId: any;
  setTodoAt: any;
  setIsDatePickerVisible: any;
  setNextStepVisible: any;
  fromScoring?: boolean;
}
const AddFollowUpModalMobileDatePicker = ({
  submitData,
  listing,
  landlordInfo,
  lead,
  modalProps,
  isFollowUpEditable,
  setIsDatePickerVisible,
  setNextStepVisible,
  setTodoAt,
  fromScoring,
}: IAddFollowUpModalMobileDatePicker) => {
  const dispatch = useDispatch();
  const [timeData, setTimeData] = useState("08:00");
  const [calendarDay, setCalendarDay] = useState(
    addTimeAndFormatDate(currentDate(), { amount: 1, unit: "days" }, undefined, getBrowser())
  );
  const leadData = useSelector(singleLeadDataSelector);

  const onCalendarTimeChangeHandler = (timeValue: any) => {
    // setShowingData(true);
    setTimeData(timeValue);
  };
  const addEditFollowUpHandler = () => {
    const modalData: any = {
      title: submitData.title,
      description: submitData.description,
      agent_id: submitData.agent_id,
      todo_at: new Date(`${formatDate(calendarDay, getBrowserDateFormat())} ${timeData}`).toISOString(),
      todo_status: "in_progress",
    };
    if (listing && Object.entries(listing).length) {
      modalData.listing_id = listing._id;
    }
    if (landlordInfo && Object.entries(landlordInfo).length) {
      modalData.landlord_id = landlordInfo.landlord_id;
    }
    if (lead && Object.entries(lead).length) {
      modalData.requirements_id = lead.requirement.id;
      modalData.renter_id = lead.renter ? lead.renter._id : lead.renterOfRequirement._id;
    }
    if (isFollowUpEditable || modalProps.editModal) {
      setTodoAt(new Date(`${formatDate(calendarDay, getBrowserDateFormat())} ${timeData}`));
      setIsDatePickerVisible(false);
      setNextStepVisible(false);
    } else {
      dispatch(addFollowUp(modalData));
      if (fromScoring) {
        dispatch(
          updateLeadTags({
            requirements_id: leadData?.requirement?._id,
            tags: leadData?.tags || [],
            step: 4,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isFollowUpEditable || modalProps.editModal) {
      setTimeData(
        formatDate(new Date(moment(submitData.todo_at).set({ hour: 8, minute: 0 }).format(getBrowser())), "HH:mm")
      );
    }
  }, [isFollowUpEditable, modalProps]);
  return (
    <div className={"pick-follow-up-time-modal-wrapper"}>
      <div className={"pick-follow-up-time-modal-wrapper--picker"}>
        <div className={"snooze-dropdown-wrapper"}>
          <Form.Item>
            <DatePicker
              suffixIcon={<CalendarIcon />}
              getPopupContainer={(triggerNode: { parentNode: any }) => triggerNode.parentNode}
              defaultOpenValue={
                modalProps.editModal || submitData.length > 0
                  ? moment(submitData.todo_at)
                  : addTimeAndFormatDate(currentDate(), {
                      amount: 1,
                      unit: "days",
                    })
              }
              defaultValue={
                modalProps.editModal || submitData.length > 0
                  ? moment(submitData.todo_at)
                  : addTimeAndFormatDate(currentDate(), {
                      amount: 1,
                      unit: "days",
                    })
              }
              defaultPickerValue={
                modalProps.editModal || submitData.length > 0
                  ? moment(submitData.todo_at)
                  : addTimeAndFormatDate(currentDate(), {
                      amount: 1,
                      unit: "days",
                    })
              }
              onChange={(date: any) => {
                setCalendarDay(formatDate(date, getBrowser()));
              }}
              open={true}
              showToday={false}
              dropdownClassName={"ant-picker-for-followups"}
              className="w-full"
              placeholder={"Ex. 01/01/2024"}
              format={`${US_DATE_FORMAT}`}
            />
          </Form.Item>
        </div>
      </div>
      {navigator.userAgent.indexOf("Firefox") != -1 ? (
        <MozTimePicker setTimeData={setTimeData} />
      ) : (
        <TimePicker
          onChange={onCalendarTimeChangeHandler}
          value={timeData}
          locale={"en-US"}
          disableClock={true}
          clearIcon={<></>}
        />
      )}
      <button
        className={"pick-follow-up-snooze-date-btn"}
        onClick={() => {
          addEditFollowUpHandler();
        }}
      >
        Done
      </button>
    </div>
  );
};

export default AddFollowUpModalMobileDatePicker;
