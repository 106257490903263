export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const GET_UNREAD_NOTIFICATIONS = "GET_UNREAD_NOTIFICATIONS";
export const GET_UNREAD_NOTIFICATIONS_SUCCESS = "GET_UNREAD_NOTIFICATIONS_SUCCESS";
export const GET_UNREAD_NOTIFICATIONS_FAIL = "GET_UNREAD_NOTIFICATIONS_FAIL";

export const GET_READ_NOTIFICATIONS = "GET_READ_NOTIFICATIONS";
export const GET_READ_NOTIFICATIONS_SUCCESS = "GET_READ_NOTIFICATIONS_SUCCESS";
export const GET_READ_NOTIFICATIONS_FAIL = "GET_READ_NOTIFICATIONS_FAIL";

export const GET_UNREAD_NOTIFICATIONS_COUNT = "GET_UNREAD_NOTIFICATIONS_COUNT";
export const GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS = "GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS";
export const GET_UNREAD_NOTIFICATIONS_COUNT_FAIL = "GET_UNREAD_NOTIFICATIONS_COUNT_FAIL";

export const MAKE_NOTIFICATIONS_AS_READ = "MAKE_NOTIFICATIONS_AS_READ";
export const MAKE_NOTIFICATIONS_AS_READ_SUCCESS = "MAKE_NOTIFICATIONS_AS_READ_SUCCESS";
export const MAKE_NOTIFICATIONS_AS_READ_FAIL = "MAKE_NOTIFICATIONS_AS_READ_FAIL";

export const MAKE_NOTIFICATIONS_AS_UNREAD = "MAKE_NOTIFICATIONS_AS_UNREAD";
export const MAKE_NOTIFICATIONS_AS_UNREAD_SUCCESS = "MAKE_NOTIFICATIONS_AS_UNREAD_SUCCESS";
export const MAKE_NOTIFICATIONS_AS_UNREAD_FAIL = "MAKE_NOTIFICATIONS_AS_UNREAD_FAIL";

export const MARK_ALL_READ = "MARK_ALL_READ";
export const MARK_ALL_READ_SUCCESS = "MARK_ALL_READ_SUCCESS";
export const MARK_ALL_READ_FAIL = "MARK_ALL_READ_FAIL";

export const CLEAR_READ_NOTIFICATIONS_STATES = "CLEAR_READ_NOTIFICATIONS_STATES";
export const CLEAR_UNREAD_NOTIFICATIONS_STATES = "CLEAR_UNREAD_NOTIFICATIONS_STATES";

