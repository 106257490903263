import { Form, Input } from "antd";
import BaseButton from "components/Shared/BaseButton";
import BaseModal from "components/Shared/BaseModal";
import React, { useEffect, useState } from "react";
import "./NotLookingModal.scss";
import { IObjectKeys } from "helpers/globalTypes";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import {
  NOT_REQUIRED_NAME_VALIDATION,
  NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE,
} from "helpers/validations";
import { AddressChangeParams } from "components/Shared/PlacesAutocomplete/types";
import InfoModal from "../info-modal/InfoModal";
import NumberOfRoomsBathrooms from "../number-of-rooms-bathrooms/NumberOfRoomsBathrooms";
import { useDispatch, useSelector } from "react-redux";
import {
  leadStatusRequirement,
  setLeadChangeStatusSuccess,
} from "store/requirement/action";
import { singleLeadDataSelector } from "store/leads/selectors";
import { leadChangeStatusSuccessSelector, leadSearchLoadingSelector } from "store/requirement/selectors";
import { useWindowSize } from "hooks/useWindowSize";
import { AnalyticsService } from "helpers/analyticsService";
import { openNotification } from "helpers/globalFunctions";

const NotLookingModal = ({ toggle = false, setToggle }: IObjectKeys) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);
  const leadData = useSelector(singleLeadDataSelector);
  const leadSearchLoading = useSelector(leadSearchLoadingSelector);
  const leadChangeStatusSuccess = useSelector(leadChangeStatusSuccessSelector);
  const { width } = useWindowSize();
  useEffect(() => {
    if (leadChangeStatusSuccess && toggle) {
      setIsSuccess(true);
      handleCancel();
      dispatch(setLeadChangeStatusSuccess());
    }
  }, [leadChangeStatusSuccess]);

  useEffect(() => {
    if (isSuccess) {
      handleCancel();
      form.resetFields();
    }
  }, [isSuccess]);

  const handleCancel = () => {
    setToggle(false);
    dispatch(setLeadChangeStatusSuccess());
  };

  const handleApprove = () => {
    if (!form.isFieldsTouched()) { return openNotification("error", "Please fill the fields"); };
    AnalyticsService.reportHaveNewHomeModalClick({
      label: "submit",
      leadFormData: form.getFieldsValue(),
    });
    dispatch(
      leadStatusRequirement({
        renterId: leadData.renter.renter_id,
        search_active_data: {
          is_search_active: false,
          rented_listing: form.getFieldsValue(),
        },
        requirements_id: leadData.requirement.id,
      })
    );
  };

  const onAddressChange = (address: AddressChangeParams) => {
    let setFieldValues: IObjectKeys = {
      address: address.formatted_address,
    };
    setFieldValues.defaultState = address.separated_fields?.state
      ? address.separated_fields?.state
      : "";

    setFieldValues.defaultCity = address.separated_fields?.town
      ? address.separated_fields?.town
      : "";
    form.setFieldsValue(setFieldValues);
  };

  return (
    <>
      <BaseModal
        isModalVisible={toggle}
        modalFooter={null}
        width={width && width < 600 ? 311 : 670}
        onCancel={() => {
          AnalyticsService.reportHaveNewHomeModalClick({ label: "cancel" });
          handleCancel();
        }}
        modalTitle=""
        classname="not-looking-wrapper">
        <div className="not-looking-modal flex flex-column align-center mb-20">
          <h3 className="fw-600 pb-3 p-20">Did you find your new home?</h3>
          <p className="font-16 text-center not-looking-modal-text">
            Tell us about your listing so we can stay up to date on your
            preferences!
          </p>
          <div className="not-looking-modal-form">
            <Form
              layout={"vertical"}
              form={form}
              initialValues={{
                layout: "vertical",
              }}
            >
              <Form.Item
                name="address"
                label="Which city is it in?"
                rules={[NOT_REQUIRED_NAME_VALIDATION]}
                className="modal-input"
              >
                <PlacesAutocomplete
                  onAddressChange={onAddressChange}
                  resetPlacesAutocomplete={resetPlacesAutocomplete}
                  setResetPlacesAutocomplete={setResetPlacesAutocomplete}
                  placeholder="Enter location"
                  type="not-looking-modal"
                />
              </Form.Item>
              <Form.Item
                name="price"
                label="What is the price?"
                rules={[NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE]}
                className="modal-input"
              >
                <Input placeholder="Example $4,400" />
              </Form.Item>
              <NumberOfRoomsBathrooms />
              <div className="agent-modal-buttons align-buttons">
                <BaseButton
                  classnames="secondary-small-btn font-16"
                  type="button"
                  loading={leadSearchLoading}
                  onClick={handleApprove}
                >
                  Submit
                </BaseButton>
                <BaseButton
                  classnames="secondary-small-btn font-16"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </BaseButton>
              </div>
            </Form>
          </div>
        </div>
      </BaseModal>
      <InfoModal
        header="Thank you!"
        text="We'll keep this information in mind for your next move!"
        setToggle={setIsSuccess}
        toggle={isSuccess}
      />
    </>
  );
};

export default NotLookingModal;
