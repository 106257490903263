import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import "../styles.scss";
import { TodoStatusEnum } from "../types";
import { useDispatch, useSelector } from "react-redux";
import DismissFollowModal from "../DismissFollowUpModal";
import { deleteFollowUp } from "store/followUps/action";
import { queryAndPaginationSelector, updateFollowUpSuccessSelector } from "../../../store/followUps/selectors";
import { FollowUpsListItemMobile } from "../FollowUpsListItemMobile/FollowUpsListItemMobile";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { userSelector } from "../../../store/user/selectors";
import FollowUpsListItemDesktop from "./ListItemFUDesktop/FollowUpsListItemDesktop";
import { windowSelector } from "../../../store/app/selectors";
import { getDateDifference, getStartOfUnitFormatted, currentDate, formatDate } from "../../../helpers/dateHelper";
import { ISO_STRING } from "../../../helpers/constants";
import Section from "components/Shared/Section/Section";

//Must Fix Code Duplication
const FollowUpsListItem = ({
  followUp,
  isFromLandlordPage,
  handleEdit,
  handleDelete,
  setLandlordActionModalVisible,
}: any) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { width } = useWindowSize();
  const [dismissFollowUpModalVisible, setDismissFollowUpModalVisible] = useState(false);
  const [updates, setUpdates] = useState<any>({});
  const [isMobileDropdownVisible, setIsMobileDropdownVisible] = useState<boolean>(false);
  const updateFollowUpSuccess = useSelector(updateFollowUpSuccessSelector);
  const setIsMobileDropdownVisibleCallback = useCallback(setIsMobileDropdownVisible, []);
  const setIsDismissFollowUpModalVisibleCallback = useCallback(setDismissFollowUpModalVisible, []);
  const setUpdatesCallback = useCallback(setUpdates, []);
  const queryAndP = useSelector(queryAndPaginationSelector);
  const queryAndPagination = useMemo(() => {
    return queryAndP;
  }, []);
  const leftOrAgoGenerator = useCallback((date: Date) => {
    return new Date(date).getTime() < new Date().getTime() ? "ago" : "left";
  }, []);
  useEffect(() => {
    if (updateFollowUpSuccess) {
      setDismissFollowUpModalVisible(false);
    }
  }, [updateFollowUpSuccess]);

  useEffect(() => {
    if (Object.keys(updates).length) {
      const _updateToDoAtString = typeof (updates?.todo_at === "object")
        ? formatDate(updates?.todo_at, ISO_STRING)
        : updates?.todo_at;
      if (
        followUp.todo_status === TodoStatusEnum.completed &&
        updates.todo_status === TodoStatusEnum.completed &&
        _updateToDoAtString !== followUp.todo_at
      ) {
        updates.todo_status = TodoStatusEnum.in_progress;
      }
      if (updates.archive) {
        dispatch(deleteFollowUp(updates));
      }
    }
  }, [updates]);

  const dueContent = useCallback((todo_at: string, str: string) => {
    let daysCount = 0;
    let dayOrDays = "days";
    if (
      getDateDifference(followUp.todo_at, "days") === 0 &&
      formatDate(followUp.todo_at) < getStartOfUnitFormatted(currentDate(), "day")
    ) {
      daysCount = 1;
    } else {
      daysCount = getDateDifference(followUp.todo_at, "days");
      if (daysCount === 0) {
        const hoursCount = getDateDifference(followUp.todo_at, "hours");
        let hoursOrHours = "hours";
        if (hoursCount <= 1) {
          hoursOrHours = "hour";
        }

        return `(${hoursCount} ${hoursOrHours} ${str})`;
      }
    }

    if (daysCount <= 1) {
      dayOrDays = "day";
    }

    return `(${daysCount} ${dayOrDays} ${str})`;
  }, []);

  const dismissFollowUp = () => {
    setUpdates({ ...followUp, dismiss: true, queryAndPagination });
  };

  const handleSetCurrentFollowUpCallback = useCallback(() => {}, []);

  return (
    <Section
      shadow
      data-test-id={followUp._id}
      key={`${followUp._id} ${followUp.todo_at}`}
      // actions={[]}
    >
      {width && width <= 991 ? (
        <FollowUpsListItemMobile
          followUp={followUp}
          // queryAndPagination={queryAndPagination}
          dueContent={dueContent}
          leftOrAgoGenerator={leftOrAgoGenerator}
          setIsMobileDropdownVisible={setIsMobileDropdownVisibleCallback}
          isFromLandlordPage={isFromLandlordPage}
          setDropdownVisibility={() => {}}
          setUpdates={setUpdatesCallback}
          isMobileDropdownVisible={isMobileDropdownVisible}
          setDismissFollowUpModalVisible={setIsDismissFollowUpModalVisibleCallback}
          setLandlordActionModalVisible={setLandlordActionModalVisible}
          setCurrentFollowUp={handleSetCurrentFollowUpCallback}
          user={user}
          handleDelete={handleDelete}
        />
      ) : (
        <FollowUpsListItemDesktop
          // queryAndPagination={queryAndPagination}
          followUp={followUp}
          dueContent={dueContent}
          leftOrAgoGenerator={leftOrAgoGenerator}
          isFromLandlordPage={isFromLandlordPage}
          setDismissFollowUpModalVisible={setIsDismissFollowUpModalVisibleCallback}
          user={user}
          setUpdates={setUpdatesCallback}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          setCurrentFollowUp={handleSetCurrentFollowUpCallback}
          setLandlordActionModalVisible={setLandlordActionModalVisible}
          setIsMobileDropdownVisible={setIsMobileDropdownVisibleCallback}
          setDropdownVisibility={() => {}}
          isMobileDropdownVisible={isMobileDropdownVisible}
        />
      )}
      <DismissFollowModal
        visible={dismissFollowUpModalVisible}
        onClose={() => setDismissFollowUpModalVisible(false)}
        onSubmit={dismissFollowUp}
      />
    </Section>
  );
};

export default memo(FollowUpsListItem);
