import BaseCollapse from "components/Shared/BaseCollapse";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";
import { phoneMaskOnString } from "../../helpers/globalFunctions";
import { IObjectKeys } from "../../helpers/globalTypes";
import BaseEmptyState from "../BaseEmptyState";
import BaseButton from "components/Shared/BaseButton";
import SectionHeading from "components/Shared/Section/SectionHeading";

const TenantInfo = ({ listing, setAddTenantModal }: any) => {
  const tenants = listing?.rentersOfListing;
  const { width } = useWindowSize();

  const handleAddBtnClick = () => {
    setAddTenantModal(true);
  };

  const tenantInfoEmptyStateProps = {
    title: "Current Tenants",
    description: "No tenants set yet.",
    linkText: "Add them now",
    openWithModal: false,
    handleAddBtnClick,
    modalType: "tenant",
    modalProps: {
      isModalVisible: false,
      children: "",
      modalTitle: "",
    },
  };

  return (
    <BaseCollapse header={<SectionHeading heading="Current Tenants" />} collapse={false}>
      {tenants?.length ? (
        <div className="mb-10">
          {tenants?.map((tenant: IObjectKeys, i: number) => {
            return (
              <>
                <div className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 sm:flex-nowrap" key={i}>
                  <div className="flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <span>{tenant?.name}</span>
                  </div>
                  <div className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4">
                    {tenant?.email && (
                      <div className="flex flex-nowrap items-center gap-2">
                        <label>Email: </label>
                        <BaseButton variant="link" href={`mailto:${tenant?.email}`} target={"_blank"}>
                          {tenant?.email}
                        </BaseButton>
                      </div>
                    )}
                    {tenant?.phone && (
                      <div className="flex flex-nowrap items-center gap-2">
                        <label>Phone: </label>
                        <BaseButton variant="link" href={`tel:${tenant?.phone}`}>
                          {phoneMaskOnString(tenant?.phone)}
                        </BaseButton>
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <BaseEmptyState {...tenantInfoEmptyStateProps} />
      )}
    </BaseCollapse>
  );
};

export default TenantInfo;
