import React from "react";

export const MarkAsDoneIcon = ({ backgroundColor }: { backgroundColor?: string }) => {
  const bgColor = backgroundColor ?? "#5806B9";
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0C4.93477 0 0 4.93435 0 11C0 17.0657 4.93477 22 11 22C17.0652 22 22 17.0657 22 11C22 4.93435 17.0652 0 11 0ZM17.1325 7.3315L10.3633 14.9469C10.1966 15.1343 9.96431 15.2308 9.73035 15.2308C9.54462 15.2308 9.35804 15.1698 9.20192 15.0455L4.97115 11.6608C4.60646 11.3693 4.54723 10.8367 4.83915 10.4716C5.13065 10.1065 5.66373 10.0472 6.02842 10.3392L9.63177 13.2216L15.8675 6.20654C16.1772 5.85708 16.7128 5.82577 17.0618 6.13631C17.4113 6.44727 17.443 6.98204 17.1325 7.3315Z"
        fill={bgColor}
      />
    </svg>
  );
};
