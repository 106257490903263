import { RootStateOrAny } from "react-redux";

export const paymentLinkSelector = (state: RootStateOrAny) =>
    state.paymentReducer.paymentLink;

export const paymentLinkLoadingSelector = (state: RootStateOrAny) =>
    state.paymentReducer.paymentLinkLoading;

export const subscriptionsSelector = (state: RootStateOrAny) =>
    state.paymentReducer.subscriptions;

export const subscriptionsLoadingSelector = (state: RootStateOrAny) =>
    state.paymentReducer.subscriptionsLoading;

export const subscriptionsErrorSelector = (state: RootStateOrAny) =>
    state.paymentReducer.subscriptionsError;

export const upcomingInvoicesSelector = (state: RootStateOrAny) =>
    state.paymentReducer.upcomingInvoices;

export const upcomingInvoicesLoadingSelector = (state: RootStateOrAny) =>
    state.paymentReducer.upcomingInvoicesLoading;

export const upcomingInvoicesErrorSelector = (state: RootStateOrAny) =>
    state.paymentReducer.upcomingInvoicesError;

export const invoicesSelector = (state: RootStateOrAny) =>
    state.paymentReducer.invoices;

export const invoicesLoadingSelector = (state: RootStateOrAny) =>
    state.paymentReducer.invoicesLoading;

export const invoicesErrorSelector = (state: RootStateOrAny) =>
    state.paymentReducer.invoicesError;

export const updatedSubscriptionSelector = (state: RootStateOrAny) =>
    state.paymentReducer.updatedSubscription;

export const updatedSubscriptionLoadingSelector = (state: RootStateOrAny) =>
    state.paymentReducer.updateSubscriptionLoading;

export const updatedSubscriptionErrorSelector = (state: RootStateOrAny) =>
    state.paymentReducer.updateSubscriptionError;

export const cancelSubscriptionLoadingSelector = (state: RootStateOrAny) =>
    state.paymentReducer.canceledSubscriptionLoading;

export const screeningPaymentLinkSelector = (state: RootStateOrAny) => state.paymentReducer.screeningPaymentLink;
export const screeningPaymentLinkErrorSelector = (state: RootStateOrAny) => state.paymentReducer.screeningPaymentLinkError;
export const screeningPaymentLinkLoadingSelector = (state: RootStateOrAny) => state.paymentReducer.screeningPaymentLinkLoading;
