import React from "react";
import { Table } from "antd";
import "./styles.scss";
import { IObjectKeys } from "helpers/globalTypes";
import { TablePaginationConfig } from "antd/lib/table/interface";

interface IBaseLeadsTable {
  classnames?: string;
  bordered?: boolean;
  columns: any[];
  dataSource: any[];
  rowKey?: string | ((arg0: any) => string);
  onChange: (paginationOpt: TablePaginationConfig, _: any, sorter: any) => void;
  pagination?: IObjectKeys | false;
  loading?: boolean;
  dataTestId?: string | undefined;
  scroll?: IObjectKeys;
  onRow?: (record: any, rowIndex: any) => any;
  antdProps?: IObjectKeys;
  id?: string;
}

const IBaseLeadsTable = ({
  columns,
  bordered,
  classnames,
  dataSource,
  loading,
  onChange,
  pagination,
  rowKey,
  dataTestId,
  scroll,
  onRow,
  antdProps,
  id,
}: IBaseLeadsTable) => {
  return (
    <Table
      bordered={bordered}
      className={`base-leads-table ${classnames} text-base`}
      dataSource={dataSource}
      loading={loading}
      onChange={onChange}
      pagination={pagination}
      rowKey={rowKey}
      columns={columns}
      data-test-id={dataTestId}
      scroll={scroll}
      id={id ? id : "tableViewId"}
      onRow={onRow}
      {...antdProps}
    />
  );
};

export default React.memo(IBaseLeadsTable);
