import React from "react";

const LeadBedroomIcon = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.02424 4.68907H4.04742C3.92281 4.68907 3.80544 4.63357 3.72858 4.53902C3.65169 4.44447 3.62498 4.32098 3.65495 4.20375L3.90665 3.23235C4.04181 2.70951 4.52654 2.34454 5.08415 2.34454H7.68921C8.3585 2.34454 8.90313 2.87048 8.90313 3.51681V4.2983C8.90313 4.514 8.72184 4.68907 8.49848 4.68907H10.9263C10.7029 4.68907 10.5217 4.514 10.5217 4.2983V3.51681C10.5217 2.87048 11.0663 2.34454 11.7356 2.34454H14.3406C14.8982 2.34454 15.383 2.70951 15.5181 3.23235L15.7698 4.20375C15.8006 4.32021 15.7731 4.44447 15.6962 4.53902C15.6193 4.63357 15.502 4.68907 15.3774 4.68907H17.4005C17.6239 4.68907 17.8052 4.514 17.8052 4.2983V1.95377C17.8052 0.876857 16.8972 0 15.782 0H3.64281C2.52764 0 1.61963 0.876857 1.61963 1.95377V4.2983C1.61963 4.514 1.80088 4.68907 2.02424 4.68907Z"
        fill="#5806B9"
      />
      <path
        d="M17.3995 5.4707H2.02318C0.908009 5.4707 0 6.34756 0 7.42447V12.895C0 13.1107 0.181291 13.2858 0.404651 13.2858C0.628012 13.2858 0.809303 13.1107 0.809303 12.895V11.7228H18.6134V12.895C18.6134 13.1107 18.7947 13.2858 19.018 13.2858C19.2414 13.2858 19.4227 13.1107 19.4227 12.895V7.42447C19.4227 6.34756 18.5147 5.4707 17.3995 5.4707ZM18.6134 10.9413H0.809265V10.1598H18.6134V10.9413H18.6134Z"
        fill="#5806B9"
      />
    </svg>
  );
};
export default LeadBedroomIcon;
