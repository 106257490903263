import React, { useEffect, useState } from "react";
import { EnumInterface, IObjectKeys } from "../../../../../../../helpers/globalTypes";
import { useDispatch, useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../store/enums/selectors";
import SwitchButton from "../../../../../../ListingView/ListingForm/SwitchButton";
import { getBedroomsOrBathroomsRadioBtnGroup } from "../../../../../../../helpers/globalFunctions";
import { REQUIRED_WITH_MESSAGE } from "../../../../../../../helpers/validations";
import { fillRequirementFormData } from "../../../../../../../store/requirement/action";
import { requirementFormDataSelector, requirementFormEdittingSelector } from "../../../../../../../store/requirement/selectors";
import { singleLeadDataSelector } from "store/leads/selectors";
import "./DesignatedParking.scss";
import FormStepsTitle from "../form-steps-title/FormStepsTitle";
import NoParkingSpot from "../../icons/parking/NoParkingSpot";

const DesignatedParking = () => {
  const dispatch = useDispatch();
  const [switchInputData, setSwitchInputData] = useState<IObjectKeys>({});
  const requirementEnums = useSelector(requirementEnumsSelector);
  const { requirement } = useSelector(singleLeadDataSelector);
  const hasParking = requirementEnums?.find((item: EnumInterface) => item?.key === "parking");
  const requirementFormData = useSelector(requirementFormDataSelector);
  const numberOfParking = requirementEnums?.find((item: EnumInterface) => item?.key === "number_of_parking");
  const isEditMode = window.location.pathname.includes("edit");
  const handleChange = (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => {
    e.persist();
    const { checked } = e.target;
    setSwitchInputData((state) => ({
      ...state,
      [e.target.name]: !checked,
    }));
    dispatch(
      fillRequirementFormData({
        ...requirementFormData,
        [hasParking?.key]: !checked,
      })
    );
  };
  const editedData = useSelector(requirementFormEdittingSelector);

  useEffect(() => {
    if (isEditMode) {
      if (editedData.number_of_parking || requirement?.number_of_parking) {
        if (editedData.number_of_parking > 0 || requirement?.number_of_parking > 0) {
          setSwitchInputData((state) => ({
            ...state,
            [hasParking?.key]: true,
          }));
        }
      }
    }
  }, [requirement, isEditMode]);
  useEffect(() => {
    if (!isEditMode) {
      if (requirementFormData.number_of_parking) {
        if (requirementFormData.number_of_parking > 0) {
          setSwitchInputData((state) => ({
            ...state,
            [hasParking?.key]: requirementFormData.parking,
          }));
        }
      }
    }
  }, [requirementFormData, isEditMode]);
  return (
    <div className={"has-parking-section-wrapper"}>
      <div className={"has-parking-section"}>
        <FormStepsTitle text={"Do you need a designated parking spot?"} />
        <SwitchButton required={true} name={hasParking?.key} label={""} onChange={handleChange} checked={switchInputData[hasParking?.key]} />
      </div>
      {switchInputData[hasParking?.key]
        ? getBedroomsOrBathroomsRadioBtnGroup(
            numberOfParking?.key,
            "How many designated parking spots do you need?",
            [REQUIRED_WITH_MESSAGE("Field ")],
            "purple-radiobutton-group bedrooms-radio-buttons parking-buttons",
            "parent-labels-to-center",
            "parking_spots"
          )
        : null}
    </div>
  );
};

export default DesignatedParking;
