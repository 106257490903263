import React from "react";
import {LeftArrow, RightArrow} from "../../../../icons";
import {useSelector} from "react-redux";
import {windowSelector} from "../../../../../store/app/selectors";
import SidebarTabletArrow from "../../../../icons/sidebar-tablet-arrow/SidebarTabletArrow";
interface ISidebarArrow {
    toggleSidebar:()=>void;
}
const SidebarArrow = ({toggleSidebar}:ISidebarArrow) =>{
    const {type,collapsed} = useSelector(windowSelector)
    const sidebarArrowDetecor = () =>{
        if(type === "desktop"){
            if(collapsed) return <RightArrow />
            else return <LeftArrow/>
        }else return <SidebarTabletArrow/>
    }
    return(
        <span
            onClick={() => toggleSidebar()}
            className={`toggle-icon-wrapper trigger`}
            data-test-id={collapsed ? "right-arrow" : "left-arrow"}
        >
            {sidebarArrowDetecor()}
        </span>
    )
}

export default SidebarArrow