import Api from './api';
import {IObjectKeys} from "../helpers/globalTypes";

export default class Activities {
    static getTeamActivities (params: IObjectKeys) {
        const api = new Api('activities/team');
        return api.get('', {params})
    }
}

