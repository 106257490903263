import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseCollapse from "../Shared/BaseCollapse";
import { CheckRoundedIcon } from "../icons";
import { listingDataSelector } from "../../store/listings/selectors";
import { beautifiedKeyStyling, nth } from "../../helpers/globalFunctions";
import BaseEmptyState from "../BaseEmptyState";
import queryString from "query-string";
import { push } from "connected-react-router";
import PurpleCheckedIcon from "../icons/PurpleCheckedIcon";
import SectionHeading from "components/Shared/Section/SectionHeading";
import { formatCurrency } from "helpers/currencyHelper";

const ListingFeesAndTypesCollapse = ({ listingData }: any) => {
  const dispatch = useDispatch();

  const handleAddBtnClick = () => {
    const parsed = {
      // landlordId:
      lId: listingData?._id,
      step: 3,
    };
    const stringified = queryString.stringify(parsed);
    dispatch(push(`/manageListing?${stringified}`));
  };

  const listingFeesAndTypesEmptyStateProps = {
    title: "Move-In Fees & Listing Type",
    description: "No details for this listing yet.",
    linkText: "Add them now",
    openWithModal: false,
    handleAddBtnClick,
    modalType: "listing",
    modalProps: {
      isModalVisible: false,
      children: "",
      modalTitle: "",
    },
  };

  const LISTING_FIELDS = {
    AREAS: {
      key: "area",
      label: "Area",
      format: (v: []) => v,
      input: "places",
    },
  };

  return (
    <>
      <BaseCollapse header={<SectionHeading heading="Move-In Fee" />} collapse={false}>
        <div className="mb-8">
          <ul className="list-disc ml-5">
            {listingData.move_in_fees?.security_deposit && listingData.security_deposit_amount && (
              <li className="py-1">
                Security Deposit -{" "}
                <span className="font-semibold">{formatCurrency(listingData.security_deposit_amount)}</span>
              </li>
            )}
            {listingData.move_in_fees?.pet_deposit && listingData.pet_deposit_amount && (
              <li className="py-1">
                Pet Deposit - <span className="font-semibold">{formatCurrency(listingData.pet_deposit_amount)}</span>
              </li>
            )}
            {listingData.move_in_fees?.first_month && <li className="py-1">First Month</li>}
            {listingData.move_in_fees?.last_month && <li className="py-1">Last Month</li>}
            {!listingData?.other_broker_fee && listingData.broker_fees && (
              <li className="py-1">
                Broker Fees - <span className="font-semibold">{beautifiedKeyStyling(listingData.broker_fees)}</span>
              </li>
            )}
            {listingData.other_broker_fee && (
              <li className="py-1">
                Broker Fees -{" "}
                <span className="font-semibold">{beautifiedKeyStyling(listingData.other_broker_fee)}</span>
              </li>
            )}
          </ul>
        </div>
      </BaseCollapse>
      <BaseCollapse header={<SectionHeading heading="Commissions" />} collapse={false}>
        <div className="pb-8">
          <ul className="list-disc ml-5">
            {listingData.buyer_agency_compensation && (
              <li className="py-1">
                Buyer Commission -{" "}
                <span className="font-semibold">
                  {listingData.buyer_agency_compensation_type === "$" && (
                    <>
                      {" $"}
                      {}
                    </>
                  )}
                  {listingData.buyer_agency_compensation_type === "%" && (
                    <>
                      {beautifiedKeyStyling(listingData.buyer_agency_compensation)}
                      {"%"}
                    </>
                  )}
                  {listingData.buyer_agency_compensation_remarks && (
                    <>
                      {" ("}
                      {beautifiedKeyStyling(listingData.buyer_agency_compensation_remarks)}
                      {")"}
                    </>
                  )}
                </span>
              </li>
            )}
          </ul>
        </div>
      </BaseCollapse>
    </>
  );
};

export default React.memo(ListingFeesAndTypesCollapse);
