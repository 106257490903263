import React from "react";
import { IObjectKeys } from "helpers/globalTypes";
import DailyRecaps from "./DailyRecaps";
import AutomationReminders from "./AutomationReminders";
import Section from "components/Shared/Section/Section";

interface INotificationsPartProps {
  remindersCollapse: boolean;
  handleChange: (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => void;
  switchInputData: IObjectKeys;
  setVerifyPhoneModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationsPart = ({ remindersCollapse, handleChange, switchInputData }: INotificationsPartProps) => {
  return (
    <Section shadow>
      <h2 className={"notifications-title"}>Notifications</h2>
      <DailyRecaps
        remindersCollapse={remindersCollapse}
        handleChange={handleChange}
        switchInputData={switchInputData}
      />
      <AutomationReminders switchInputData={switchInputData} handleChange={handleChange} />
    </Section>
  );
};

export default NotificationsPart;
