import { Form, Input, List, Spin, Tooltip } from "antd";
import ListingApi from "api/Listing";
import RequirementApi from "api/Requirement";
import { NOT_REQUIRED_EMAIL_VALIDATION, NOT_REQUIRED_PHONE, PHONE_NUMBER_MASK, PHONE_NUMBER_PLACEHOLDER } from "helpers/validations";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { requirementAgentDataSelector } from "store/requirement/selectors";
import AuthApi from "../../../api/Auth";
import { AnalyticsService } from "../../../helpers/analyticsService";
import { appUrl } from "../../../helpers/constants";
import { beautifiedKeyStyling, openNotification } from "../../../helpers/globalFunctions";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { windowSelector } from "../../../store/app/selectors";
import { leadAccountLinkSelector } from "../../../store/leads/selectors";
import { userSelector } from "../../../store/user/selectors";
import BaseButton from "../BaseButton";
import BaseModal from "../BaseModal";
import MaskedInput from "../MaskedPhoneInput";
import Message from "../Message";
import "./IndividualLeadSharePopup.scss";
import axios from "axios";
import ShareListing from "api/ShareListing";
import { InfoIcon } from "components/icons";
import { renterPortalRoute } from "helpers/clientRoutes";
import { EVENT_NAME } from "helpers/analytics/eventEnums";
//Must Fix Code Duplication

const IndividualLeadSharePopup = ({ visible, onClose, isFromListingsPage, isLeadPage = false }: { visible: boolean; onClose: () => void; isFromListingsPage?: boolean; isLeadPage?: boolean; }) => {
  const userData = useSelector(userSelector);
  const { lead_id, lId } = useParams();
  const [showSuccesMessage, setShowSuccessMessage] = useState(false);
  const agentName = userData.name;
  const { width } = useWindowSize();
  const [detectedLink, setDetectedLink] = useState("");
  const requirementLink = detectedLink;
  const { type } = useSelector(windowSelector);
  const leadAccountLink = useSelector(leadAccountLinkSelector);
  const [mailtext, setMailText] = useState(
    `mailto:?subject=Following up regarding your rental request&body= Hi,%0D%0A%0D%0A Thank you for reaching out regarding my rental listing.%0D%0A%0D%0AWhen you have a chance can you please fill out this brief questionnaire and afterward I'd be happy to answer any questions you might have and schedule a showing:%0D%0A${requirementLink}%0D%0A%0D%0AThanks and looking forward to working with you!%0D%0A${agentName}`
  );
  const [isNativeShare, setIsNativeShare] = useState<any>(false);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [query, setQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [listings, setListings] = useState<any>(null);

  const detectFromWhichPageIsShating = () => {
    if (isFromListingsPage) {
      return {
        title: "Found a listing that might be a great for you",
        text: `View it and see if it matches your needs\n\n Hi,\n here's a listing that might work well for your needs:\n ${requirementLink}\n Would love to know what do you think. \n Thank you, ${agentName}`,
      };
    } else {
      return {
        title: "View my rental requirements",
        text: `Connect with me on RentBase: ${requirementLink} .\n\nMy RentBase profile holds my rental requirements and contact details. It will also sync with future changes in my requirements or search status.\n\nLooking forward to connect,\n\n ${agentName}`,
      };
    }
  };
  const navigatorMailText = detectFromWhichPageIsShating();
  const location = useLocation();
  const requirementAgentData = useSelector(requirementAgentDataSelector);
  const isPreviewMode = location.search.includes("previewMode=true");
  const isRentalPage = location.pathname.includes(renterPortalRoute(""));
  const [form] = Form.useForm();
  const inviteHandler = () => {
    if (navigator.share) {
      navigator
        .share({
          title: navigatorMailText.title,
          text: navigatorMailText.text,
        })
        .then(() => {
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 2000);
        })
        .catch(console.error);
    } else {
      // @ts-ignore
      document.querySelector(".share-by-email").click();
    }

    AnalyticsService.shareLeadModal({ label: "share_by_email" });
  };
  const handleDesktopShare = async () => {
    try {
      await AuthApi.shareApi(requirementLink);
      openNotification("success", "Message sent successfully");
      AnalyticsService.shareLeadModal({ label: "send_to_my_mobile" });
    } catch (err) {
      openNotification("error", `${beautifiedKeyStyling(err.message)}`);
      AnalyticsService.shareLeadModal({
        label: "send_to_my_mobile",
        response: "error",
        errorMessage: err.message,
      });
    }
  };

  const handleShare = () => {
    form.validateFields().then((values) => {
      setSendingInvite(true);
      const url = process.env.REACT_APP_BASE_URL;
      const pathnameParts = location.pathname.split("/");
      const publicId = pathnameParts[pathnameParts.length - 1];
      const requestData = {
        listing_public_id: publicId,
        agent_id: userData.agent_id,
        email: values.email,
        phone: values.phone,
      };
      ShareListing.shareListing(requestData)
        .then((response: any) => {
          if (response.listing_id) {
            openNotification("success", "Listing was successfully shared!");
            AnalyticsService.reportClickEvent(EVENT_NAME.SHARE_LISTING, "share_listing_modal", requestData);
            onClose();
            form.resetFields(); // Reset form field after share.
          } else {
            openNotification("error", "Something went wrong when trying to share the listing. Please try again.");
          }
        })
        .catch((err) => {
          openNotification("error", "Oops! Something went wrong. We are working on the issue.");
        })
        .finally(() => {
          // Reset the sendingInvite state
          setSendingInvite(false);
        });
    });
  };

  useEffect(() => {
    // setting up the email content for sharing a listing
    if (isFromListingsPage) {
      setDetectedLink(`${appUrl}/listings/${lId}`);
      setMailText(
        `mailto:?subject=Found a listing that might be a great for you&body= Hi,%0D%0A%0D%0A here's a listing that might work well for your needs:%0D%0A%0D%0A${requirementLink}%0D%0A%0D%0AWould love to know what do you think.%0D%0A%0D%0AThank you,%0D%0A${agentName}`
      );
      return;
    }

    // setting up the email content for sharing a magic link
    if (isLeadPage) {
      if (userData.agent_id || requirementAgentData.agent_id) {
        setDetectedLink(`${appUrl}/requirements/${userData.agent_id || requirementAgentData.agent_id}`);
      }
      setMailText(
        `mailto:?subject=Following up regarding your rental request&body= Hi,%0D%0A%0D%0A Thank you for reaching out regarding my rental listing.%0D%0A%0D%0AWhen you have a chance can you please fill out this brief questionnaire and afterward I'd be happy to answer any questions you might have and schedule a showing:%0D%0A${requirementLink}%0D%0A%0D%0AThanks and looking forward to working with you!%0D%0A${agentName}`
      );
      return;
    }

    // setting up the email content for connecting with an agent
    if (leadAccountLink) {
      if (Object.keys(leadAccountLink).length) {
        if (leadAccountLink.shareLink) {
          setDetectedLink(`${leadAccountLink.shareLink}?shared=true`);
        }
      }
    } else {
      const shareLink = localStorage.getItem("leadAccountLinkObj") ? JSON.parse(localStorage.getItem("leadAccountLinkObj") || "") : "";
      setDetectedLink(`${lead_id ? `${appUrl}/leads/${lead_id}?shared=true` : `${shareLink.shareLink}?shared=true`}`);
    }
    setMailText(
      `mailto:?subject=View my rental requirements&body=Connect with me on RentBase: ${requirementLink} .%0D%0A%0D%0AMy RentBase profile holds my rental requirements and contact details. It will also sync with future changes in my requirements or search status.%0D%0A%0D%0ALooking forward to connect,%0D%0A%0D%0A${agentName}`
    );
  }, [isFromListingsPage, isLeadPage, userData, detectedLink, leadAccountLink, requirementAgentData]);

  useEffect(() => {
    navigator["share"] ? setIsNativeShare(true) : setIsNativeShare(false);
  }, []);

  useEffect(() => {
    if (query?.length >= 1) {
      setIsSearching(true);
      setListings([]);
      const timeoutId = setTimeout(async () => {
        ListingApi.getListingsFromAlgolia({
          search: query,
          filter_by_agency: false,
          size: 10,
          page: 1,
          status: "Available",
        }).then((results: any) => {
          const { hits } = results.result;
          setListings(hits);
          setIsSearching(false);
        });
      }, 250);
      return () => clearTimeout(timeoutId);
    } else {
      setListings([]);
      setIsSearching(false);
    }
  }, [query]);

  const handleSend = async () => {
    form.validateFields().then(async (values) => {
      try {
        setSendingInvite(true);
        const res = await RequirementApi.inviteForLead({
          ...values,
          name: values.name.trim(),
          link: requirementLink,
        });
        openNotification("success", "Lead has been invited successfully.");
        AnalyticsService.reportClickEvent(EVENT_NAME.PASSPORT_REQUEST, "passport_request_modal", values);
        form.resetFields();
        setSendingInvite(false);
        onClose();
      } catch (error) {
        openNotification("error", error.message);
        onClose();
        setSendingInvite(false);
      } finally {
        setSendingInvite(false);
      }
    });
  };

  const handleClick = (id: any) => {
    form.setFieldsValue({ listingId: id });
    const listing: any = listings.find((listing: any) => listing._id === id);
    setSelectedValue(listing?.addr);
    setListings([]);
  };

  return (
    <>
      <BaseModal
        classname={"share-lead-modal"}
        isModalVisible={visible}
        dataTestId="share-lead-modal"
        modalTitle={""}
        onCancel={() => {
          onClose();
        }}
        width={
          //@ts-ignore
          width >= 600 ? 500 : 300
        }
        modalFooter={[]}
      >
        <div className={"share-lead-modal-body pt-4"}>
          <div className="flex justify-start gap-2 items-center mb-4">
            <h3 className="">{isLeadPage ? `Automatically Pre-qualify Leads` : `Share ${isFromListingsPage ? "Listing" : "Your Rental Passport"}`}</h3>
            {isLeadPage && (
              <Tooltip placement="top" title={<div className="p-2">RentBase will help guide your renter in the creation of their Renter Passport, which ensures a tailored, qualified match to all the rentals that match their needs.</div>}>
                <div className="pro-tip-content">
                  <InfoIcon />
                </div>
              </Tooltip>
            )}
          </div>
          {isFromListingsPage && (
            <>
              <p>Send or email your listing</p>
            </>
          )}
          {!isLeadPage && (
            <>
              <div className={"add-lead-form-content"}>
                <Form
                  form={form}
                  // onFinish={onSubmit}
                  layout={"vertical"}
                  data-test-id="add-form"
                >
                  <Form.Item
                    label="Phone Number "
                    name="phone"
                    rules={[
                      NOT_REQUIRED_PHONE,
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value && !getFieldValue("email")) {
                            return Promise.reject(new Error("Please provide either an email or phone number in order to proceed!"));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    validateTrigger={"onBlur"}
                  >
                    <MaskedInput inputMode="numeric" data-test-id="add-landLoard-Phone" mask={PHONE_NUMBER_MASK} placeholder={PHONE_NUMBER_PLACEHOLDER} />
                  </Form.Item>
                  <div className="text-center">or</div>
                  <Form.Item
                    data-test-id="email-item"
                    label="Email"
                    name="email"
                    rules={[
                      NOT_REQUIRED_EMAIL_VALIDATION,
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value && !getFieldValue("phone")) {
                            return Promise.reject(new Error("Please provide either an email or phone number in order to proceed!"));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input autoComplete={"off"} data-test-id="email-input" placeholder="Email" />
                  </Form.Item>
                </Form>
              </div>
              <div className="text-right btn-row">
                <BaseButton classnames={"rb-primary-btn sm send-btn"} onClick={handleShare} disabled={sendingInvite} loading={sendingInvite}>
                  Share
                </BaseButton>
              </div>
            </>
          )}
          {!!isLeadPage && (
            <div className={"add-lead-form-content"}>
              <Form
                form={form}
                // onFinish={onSubmit}
                layout={"vertical"}
                data-test-id="add-form"
              >
                <Form.Item
                  data-test-id="name-item"
                  label="Lead's Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the name of your lead to proceed!",
                    },
                    {
                      validator: (_, value) => (value.trim() ? Promise.resolve() : Promise.reject(new Error("No spaces allowed"))),
                    },
                  ]}
                >
                  <Input autoComplete={"off"} data-test-id="name-input" placeholder="Enter Lead’s Name" />
                </Form.Item>
                <Form.Item
                  label="Lead's Phone Number "
                  name="phone"
                  rules={[
                    NOT_REQUIRED_PHONE,
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value && !getFieldValue("email")) {
                          return Promise.reject(new Error("Please provide either an email or phone number in order to proceed!"));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  validateTrigger={"onBlur"}
                >
                  <MaskedInput inputMode="numeric" data-test-id="add-landLoard-Phone" mask={PHONE_NUMBER_MASK} placeholder={PHONE_NUMBER_PLACEHOLDER} />
                </Form.Item>
                <div className="text-center">or</div>
                <Form.Item
                  data-test-id="email-item"
                  label="Email"
                  name="email"
                  rules={[
                    NOT_REQUIRED_EMAIL_VALIDATION,
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value && !getFieldValue("phone")) {
                          return Promise.reject(new Error("Please provide either an email or phone number in order to proceed!"));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input autoComplete={"off"} data-test-id="email-input" placeholder="Email" />
                </Form.Item>
                <Form.Item data-test-id="listing-item" label="Listing (Optional)" name="listingId">
                  <Input placeholder="Search Listings" value={selectedValue || query} onChange={(e) => setQuery(e.target.value)} />
                  <div
                    className="bg-white shadow-lg"
                    style={{
                      padding: "0 10px",
                      position: "absolute",
                      width: "100%",
                      zIndex: 999,
                    }}
                  >
                    {isSearching && <Spin className="listing-spinner" />}
                    {listings?.length > 0 && (
                      <List
                        className="listing-data"
                        data-test-id=""
                        dataSource={listings}
                        renderItem={(e: any, i: any) => (
                          <List.Item key={e._id} onClick={() => handleClick(e._id)}>
                            {e.addr}
                          </List.Item>
                        )}
                      />
                    )}
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
          {isLeadPage ? (
            <>
              <div className="text-right btn-row">
                <BaseButton variant="primary" onClick={handleSend} disabled={sendingInvite} loading={sendingInvite}>
                  Send
                </BaseButton>
              </div>
            </>
          ) : null}
        </div>
      </BaseModal>
      {showSuccesMessage && <Message text={"Thanks for sharing"} />}
    </>
  );
};

export default IndividualLeadSharePopup;
