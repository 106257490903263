import React from "react";
import BaseCollapse from "../../Shared/BaseCollapse";
import { useSelector } from "react-redux";
import { individualListingHistorySelector } from "../../../store/listings/selectors";
import ListingHistoryTable from "./components/ListingHistoryTable";
import './style.scss';
import SectionHeading from "components/Shared/Section/SectionHeading";

const ListingHistoryCollapse = ({ canViewListingAgents }: { canViewListingAgents?: boolean; }) => {
  const listingHistoryData = useSelector(individualListingHistorySelector);
  const listingHistory = { ...listingHistoryData };

  return (
    <BaseCollapse
      header={<SectionHeading heading="Listing History" />}
      collapse={false}
    >
      <ListingHistoryTable listingHistory={listingHistory} canViewListingAgents={canViewListingAgents} />
    </BaseCollapse>
  );
};

export default ListingHistoryCollapse;