import React, { useEffect, useState } from "react";
import "./FollowUpModalSingleItem.scss";
import moment from "moment";
import MarkAsDone from "./icons/MarkAsDone";
import Snooze from "./icons/Snooze";
import SnoozeOptions from "./sonnze-options/SnoozeOptions";
import { useDispatch, useSelector } from "react-redux";
import { editFollowUp, setFollowUpEditSucces } from "../../../../../store/followUps/action";
import { TodoStatusEnum } from "../../../types";
import FollowUpDeleteIcon from "./icons/FollowUpDeleteIcon";
import FollowUpEditIcon from "./icons/FollowUpEditIcon";
import { Button, Tooltip } from "antd";
import DeleteFollowUpModal from "../delete-follow-up-modal/DeleteFollowUpModal";
import { getBrowserDateFormat } from "../../../../../helpers/globalFunctions";
import {
  dashboardFollowUpEditSuccessSelector,
  dashboardFollowUpSnoozeSuccessSelector,
} from "../../../../../store/dashboard/selectors";
import { editDashboardFollowUp } from "../../../../../store/dashboard/action";
import { push } from "connected-react-router";
import { useLocation } from "react-router";
import RelatedEntity from "./shared/RelatedEntity";
import { US_DATE_FORMAT } from "helpers/constants";
import { dateToMoment, formatDate } from "../../../../../helpers/dateHelper";
import Section from "components/Shared/Section/Section";

interface IFollowUpModalSingleItem {
  followUp: any;
  snoozeVisible: any;
  setSnoozeVisible: any;
  backToFollowUps: any;
  setAllVisible: any;
  setSingleFollowUpVisible: any;
  handleOpenEditFollowUpModal: any;
  setIsSnoozePickerVisible: any;
  isSnoozePickerVisible: boolean;
  isFromDashboard?: boolean;
  closeModal?: any;
}

const FollowUpModalSingleItem = ({
  followUp,
  snoozeVisible,
  setSnoozeVisible,
  backToFollowUps,
  isSnoozePickerVisible,
  setAllVisible,
  setSingleFollowUpVisible,
  handleOpenEditFollowUpModal,
  setIsSnoozePickerVisible,
  isFromDashboard,
  closeModal,
}: IFollowUpModalSingleItem) => {
  const dispatch = useDispatch();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const editSuccess = useSelector(dashboardFollowUpEditSuccessSelector);
  const snoozeSuccess = useSelector(dashboardFollowUpSnoozeSuccessSelector);
  const location = useLocation();
  useEffect(() => {
    if (!snoozeSuccess && editSuccess) {
      setSingleFollowUpVisible(false);
      dispatch(setFollowUpEditSucces(false));
    }
    if ((snoozeSuccess || editSuccess) && location.pathname.includes("followUps")) {
      if (closeModal) {
        closeModal();
        dispatch(push(location.pathname));
      }
    }
  }, [editSuccess, snoozeSuccess]);
  return (
    <>
      {snoozeVisible ? (
        <SnoozeOptions
          setSnoozeVisible={setSnoozeVisible}
          followUp={followUp}
          setIsSnoozePickerVisible={setIsSnoozePickerVisible}
          isSnoozePickerVisible={isSnoozePickerVisible}
          isFromDashboard={isFromDashboard}
        />
      ) : (
        <div className={"follow-up-modal-single-item"}>
          <div className={"follow-up-modal-single-item--header"}>
            <div className={"follow-up-modal-single-item--header--left"}>
              <span
                className={`follow-up-modal-single-item--header--left--date mb-6 ${
                  new Date(followUp.todo_at).getTime() < new Date().getTime() &&
                  "follow-up-modal-single-item--header--left--datePast"
                }`}
              >
                Due: {formatDate(dateToMoment(followUp.todo_at), getBrowserDateFormat())}{" "}
                {new Date(followUp.todo_at).getTime() < new Date().getTime() && "(Past Due)"}
              </span>
              <p className={"follow-up-modal-single-item--header--left--title"}>{followUp.title}</p>
            </div>
            <div className={"follow-up-modal-single-item--header--right"}>
              <Tooltip
                placement="top"
                title={<p>Delete Follow-up</p>}
                getPopupContainer={() => {
                  return document.querySelector(".follow-up-modal-single-item--header--right") as HTMLElement;
                }}
              >
                <Button
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                  }}
                >
                  <FollowUpDeleteIcon />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                title={<p>Edit Follow-up</p>}
                getPopupContainer={() => {
                  return document.querySelector(".follow-up-modal-single-item--header--right") as HTMLElement;
                }}
              >
                <Button
                  onClick={() => {
                    if (setAllVisible) {
                      setAllVisible(false);
                    }
                    if (setSingleFollowUpVisible) {
                      setSingleFollowUpVisible(false);
                    }
                    handleOpenEditFollowUpModal(followUp);
                  }}
                >
                  <FollowUpEditIcon />
                </Button>
              </Tooltip>
            </div>
          </div>
          <RelatedEntity followUp={followUp} classNames="mt-6" />
          {followUp?.description && (
            <Section shadow className="mt-2">
              {followUp.description}
            </Section>
          )}
          <div className={"follow-up-modal-single-item--dateCreatedByCreated"}>
            <span className={"follow-up-modal-single-item--dateCreatedByCreated--dateCreated"}>
              <strong>Created:</strong> {formatDate(followUp.created_at, US_DATE_FORMAT)}
            </span>
            <span className={"follow-up-modal-single-item--dateCreatedByCreated--dateCreated"}>
              <strong>By:</strong> {followUp?.created_by?.name ? followUp?.created_by?.name : null}
            </span>
          </div>
          <div className={"follow-up-modal-single-item--bottom"}>
            {new Date(followUp.todo_at).getTime() < new Date().getTime() && (
              <button
                className={"follow-up-modal-single-item--bottom--snooze"}
                onClick={() => {
                  setSnoozeVisible(true);
                }}
              >
                <Snooze /> Snooze
              </button>
            )}
            <button
              className={"follow-up-modal-single-item--bottom--markAsDone"}
              onClick={() => {
                if (isFromDashboard) {
                  dispatch(
                    editDashboardFollowUp(
                      {
                        ...followUp,
                        todo_at: null,
                        todo_status: TodoStatusEnum.completed,
                      },
                      followUp._id
                    )
                  );
                } else {
                  dispatch(
                    editFollowUp(
                      {
                        ...followUp,
                        todo_at: null,
                        todo_status: TodoStatusEnum.completed,
                      },
                      followUp._id
                    )
                  );
                }
              }}
            >
              <MarkAsDone />
              Mark as Done
            </button>
          </div>
        </div>
      )}
      <DeleteFollowUpModal
        isModalVisible={isDeleteModalVisible}
        setIsModalVisible={setIsDeleteModalVisible}
        width={653}
        followUp={followUp}
        backToFollowUps={backToFollowUps}
        isFromDashboard={isFromDashboard}
      />
    </>
  );
};

export default FollowUpModalSingleItem;
