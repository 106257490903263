import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogoName, RBLogo } from "../../../../icons";
import { windowSelector } from "../../../../../store/app/selectors";
import { push } from "connected-react-router";
import "./LogoSection.scss";
import { useDemoContext } from "libs/contextLib";
import RBPremiumLogo from "../../../../icons/RBPremiumLogo";
import { userSelector } from "../../../../../store/user/selectors";
import { SUBSCRIPTION_TYPES } from "helpers/constants";

const LogoSection = ({ toggleSidebar }: { toggleSidebar: () => void }) => {
  const { collapsed } = useSelector(windowSelector);
  const dispatch = useDispatch();
  const { isDemo } = useDemoContext();
  const userData = useSelector(userSelector);

  const logoHandler = () => {
    if (isDemo) {
      dispatch(push("/demo"));
    } else {
      dispatch(push("/"));
      toggleSidebar();
    }
  };
  return (
    <div className={`sidebar-logo-wrapper logo`}>
      <span className={"sidebar-logo-wrapper--logo"} onClick={() => logoHandler()}>
        <span
          className={`sidebar-logo-wrapper--logo--wrapper logo-section 
                  ${collapsed && "sidebar-logo-wrapper--logo--wrapperCollapsed"}`}
        >
          <span className={`sidebar-logo-wrapper--logo--wrapper--icon`}>
            {userData.agency?.subscription_level !== SUBSCRIPTION_TYPES.free ? <RBPremiumLogo /> : <RBLogo />}
          </span>
          <span className={`sidebar-logo-wrapper--logo--wrapper--text logo-txt`}>
            {" "}
            <LogoName />
          </span>
        </span>
      </span>
    </div>
  );
};

export default React.memo(LogoSection);
