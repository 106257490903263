import React from "react";
import { fieldDto, IObjectKeys } from "../../helpers/globalTypes";
import QuickContact from "components/Shared/TableView/QuickContact/QuickContact";
import QuickScreening from "components/Shared/TableView/QuickScreening/QuickScreening";
import stateToAbbr from "../../helpers/stateToAbbr";
import SingleTag from "../Lead/shared/lead-availabilities/components/single-tag/SingleTag";
import { tags } from "../Lead/shared/constants";
import { timeSince } from "helpers/dateHelper";
import { formatCurrency } from "helpers/currencyHelper";
import { formatDate } from "../../helpers/dateHelper";

export const LEADS_COLUMNS = [
  {
    dataIndex: "_id",
    key: "name",
    title: "Name",
    render: (_id: string, rowData: IObjectKeys) => {
      const isAnonymous = !rowData.agency_ids?.includes(rowData.userData?.agency?._id);
      let name = "Pending Lead";
      if (rowData?.renterOfRequirement?.name) {
        name = rowData?.renterOfRequirement?.name;
      } else if (!isAnonymous && Array.isArray(rowData?.agentsOfRequirement)) {
        name = `${rowData?.agentsOfRequirement?.[0]?.name}'s Lead`;
      }
      //@ts-ignore
      let leadTags = [];
      if (rowData?.tags) {
        leadTags = Object.values(rowData.tags) || [];
      }
      //@ts-ignore
      const newTags = tags.filter((tag) => leadTags.includes(tag.name));
      return {
        children: (() => {
          return (
            <div className="flex justify-start gap-2">
              <div className="color-black text-bold">{name}</div>
              <div className="flex justify-start gap-1">
                {newTags.map((tag, index) => (
                  <SingleTag
                    icon={tag.icon}
                    label={tag.label}
                    name={tag.name}
                    classnames={`tag-wrapper-${index}-child`}
                    type={"lead"}
                    key={index}
                  />
                ))}
              </div>
            </div>
          );
        })(),
        props: {
          "data-test-id": "name",
        },
      };
    },
  },
  {
    dataIndex: "bedrooms_max",
    key: "bedrooms_max",
    title: "Bed",
    sorter: (a: IObjectKeys, b: IObjectKeys) => a.bedrooms_max - b.bedrooms_max,
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      return {
        children: <span className="pl-4">{rowData?.bedrooms_max === 0 ? "Studio" : rowData?.bedrooms_max}</span>,
        props: {
          "data-test-id": "bed",
        },
      };
    },
  },
  {
    dataIndex: "bathrooms_max",
    key: "bathrooms_max",
    title: "Bath",
    sorter: (a: IObjectKeys, b: IObjectKeys) => a.bathrooms_max - b.bathrooms_max,
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      return {
        children: <span className="pl-4">{rowData?.bathrooms_max}</span>,
        props: {
          "data-test-id": "bath",
        },
      };
    },
  },
  {
    dataIndex: "budget_min",
    key: "price",
    title: "Price",
    sorter: (a: IObjectKeys, b: IObjectKeys) => a.budget_min - b.budget_min,
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      return {
        children: <span>{`${formatCurrency(rowData.budget_min)} - ${formatCurrency(rowData.budget_max)}`}</span>,
        props: {
          "data-test-id": "price",
          className: "price-cell",
        },
      };
    },
  },
  {
    dataIndex: "_id",
    key: "moveInDate",
    title: "Move-in Date",
    sorter: (a: IObjectKeys, b: IObjectKeys) => a.move_in_date_start_timestamp - b.move_in_date_start_timestamp,
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      return {
        children: <span>{formatDate(rowData?.move_in_date_start)}</span>,
        props: {
          "data-test-id": "move_in_date_start",
        },
      };
    },
  },
  {
    dataIndex: "_id",
    key: "location",
    title: "Location",
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      // eslint-disable-next-line no-restricted-globals
      if (Array.isArray(rowData?.location)) {
        return {
          children: (
            <span>
              {rowData.area.map((elem: any, key: any) => {
                return (
                  <span key={key} className={"mr-5"}>
                    {!!!elem.location.length ? (
                      <>
                        {elem?.town
                          ? `${elem.town} ${stateToAbbr(elem.state)}${rowData.area.length > 1 ? "," : ""}`
                          : elem.area}
                      </>
                    ) : (
                      elem.location.map((item: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            {index < elem?.location?.length - 1
                              ? `${item},`
                              : ` ${item}${rowData.area.length - 1 === key ? "" : ","}`}
                          </React.Fragment>
                        );
                      })
                    )}
                  </span>
                );
              })}
            </span>
          ),
          props: {
            "data-test-id": "location",
            className: "location-cell",
          },
        };
      }
      return {
        children: (
          <span>
            {
              // eslint-disable-next-line no-restricted-globals
              rowData?.location
            }
          </span>
        ),
        props: {
          "data-test-id": "location",
        },
      };
    },
  },
  {
    dataIndex: "_id",
    key: "created",
    title: "Created",
    sorter: (a: IObjectKeys, b: IObjectKeys) => a.created_at_timestamp - b.created_at_timestamp,
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      return {
        children: <span>{timeSince(rowData?.created_at)}</span>,
        props: {
          "data-test-id": "created_at",
        },
      };
    },
  },
  {
    dataIndex: "_id",
    key: "screening",
    title: "",
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      return {
        children: (
          <div className="landlord-table-actions-wrapper">
            <QuickScreening rowData={{ ...rowData, renterOfRequirement: rowData }} />
          </div>
        ),
        props: {
          "data-test-id": "menu",
        },
      };
    },
  },
  {
    dataIndex: "_id",
    key: "menu",
    title: "",
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      const renterOfRequirement =
        rowData.userData?._id === rowData.agentsOfRequirement?.[0]?._id
          ? rowData?.renterOfRequirement
          : rowData.agentsOfRequirement[0];
      return {
        children: (
          <div className="landlord-table-actions-wrapper">
            <QuickContact rowData={{ ...rowData, renterOfRequirement: rowData }} />
          </div>
        ),
        props: {
          "data-test-id": "menu",
        },
      };
    },
  },
];
