import React from "react";

interface ITestimonialProps {
  avatarSrc?: string;
  content: string;
  author: string;
};

const Testimonial = ({ avatarSrc, content, author }: ITestimonialProps) => {
  return (
    <div className="flex gap-4 justify-start w-full sm:w-1/2 pt-8">
      <img
        className="inline-block h-18 w-18 rounded-full bg-purple-300"
        src={avatarSrc}
        alt=""
      />
      <p className="text-base">
        “{content}” - <strong>{author}</strong>{" "}
      </p>
    </div>
  );
};

export default Testimonial;