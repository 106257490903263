import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { List, Row } from "antd";
import { IObjectKeys } from "../../helpers/globalTypes";
import { useDispatch, useSelector } from "react-redux";
import EmptyFollowUpListView from "./FollowUpsListItemMobile/EmptyFollowUpListView";
import ContactModal from "components/Shared/ContactModal/ContactModal";
import LandlordNoteModal from "components/Landlord/LandlordNoteModal";
import { singleLandlordDataSelector } from "store/landlords/selectors";
import AfterCallModal from "../Shared/AfterCallModal/AfterCallModal";
import AddFollowUpModal from "./add-follow-up-modal/AddFollowUpModal";
import PhoneVerifyModalFollowUps from "./all-follow-ups-modal/components/phone-verify-modal";
import { useForm } from "antd/es/form/Form";
import AddFollowUpModalMobile from "./add-follow-up-modal-mobile/AddFollowUpModalMobile";
import { useWindowSize } from "../../hooks/useWindowSize";
import FollowUpsListItem from "./ListItemFU/FollowUpsListItem";
import { getLoggedInUser } from "store/user/action";
import { useDemoContext } from "libs/contextLib";
import PremiumUpgradeModal from "../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { editFollowUpSuccessSelector, editedFollowUpSelector } from "store/followUps/selectors";
import { openNotification } from "helpers/globalFunctions";
import { TodoStatusEnum } from "./types";
import Section from "components/Shared/Section/Section";

interface IFollowUpsProps {
  followUpsLoading: boolean;
  hasMore?: boolean;
  setHasMore: Dispatch<SetStateAction<any>>;
  followUpsList: IObjectKeys[];
  total: number;
  initialLoad: any;
}

const FollowUpsList = ({ followUpsLoading, setHasMore, followUpsList, total, initialLoad }: IFollowUpsProps) => {
  const initialModalValue = { visible: false, editModal: false };
  const currentLandlord = useSelector(singleLandlordDataSelector);
  const [landlordActionModalVisible, setLandlordActionModalVisible] = useState(false);
  const [afterCallModalProps, setAfterCallModalProps] = useState(false);
  const [followUpModalProps, setFollowUpsModalProps] = useState(initialModalValue);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const [noteModalProps, setNoteModalProps] = useState(initialModalValue);
  const { width } = useWindowSize();
  const [form] = useForm();
  const { isDemo } = useDemoContext();
  const setLandlordActionModalVisibleCallback = useCallback(setLandlordActionModalVisible, []);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const editFollowUpSuccess = useSelector(editFollowUpSuccessSelector);
  const updatedFollowUp = useSelector(editedFollowUpSelector);

  const closeAfterCallModalHandler = () => {
    setAfterCallModalProps(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDemo) dispatch(getLoggedInUser());
  }, []);

  useEffect(() => {
    if (!initialLoad.current) {
      setHasMore(total > followUpsList?.length);
    } else {
      initialLoad.current = false;
    }
  }, [followUpsList]);

  useEffect(() => {
    if (!afterCallModalProps)
      localStorage.removeItem("contact_email_selected");
  }, [afterCallModalProps]);

  useEffect(() => {
    if (editFollowUpSuccess && updatedFollowUp.todo_status === TodoStatusEnum.completed) {
      openNotification("success", "Follow-up marked as done");
    }
  }, [editFollowUpSuccess]);

  return (
    <>
      <div className={"w-full"}>
        {followUpsList.length > 0 ? (
          followUpsList.map((followUp) => {
            return <FollowUpsListItem
              followUp={followUp}
              setLandlordActionModalVisible={setLandlordActionModalVisibleCallback}
            />;
          })
        ) : (
          <EmptyFollowUpListView />
        )}
      </div>

      {landlordActionModalVisible && (
        <ContactModal
          setModalProps={(e: any) => { }}
          currentLandlordData={currentLandlord}
          showModal={true}
          onClose={setLandlordActionModalVisibleCallback}
          setAfterCallModalVisible={setAfterCallModalProps}
        />
      )}
      <AfterCallModal
        visible={afterCallModalProps}
        onClose={closeAfterCallModalHandler}
        key={1}
        setFollowUpModalVisible={setFollowUpsModalProps}
        setContactModalVisible={setAfterCallModalProps}
        setNoteModalVisible={setNoteModalProps}
        setPremiumModalVisible={setPremiumModalVisible}
      />
      <LandlordNoteModal modalProps={noteModalProps} setModalProps={setNoteModalProps} landlordInfo={currentLandlord} landlord_id={currentLandlord.landlord_id} initialModalValue={initialModalValue} form={form} />
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={currentLandlord}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={currentLandlord}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        )
      }
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={() => setPhoneVerifyModalVisible(false)} />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_AFTER_CALL_MODAL}
        id={currentLandlord.landlord_id}
      />
    </>
  );
};

export default FollowUpsList;
