import React from "react";

const TextIcon = () =>{
    return(
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={"communication-radio-btn-icon"}>
            <g clipPath="url(#clip0_1_1615)">
                <path d="M2.79263 17.9389C1.99644 17.9389 1.21859 17.7428 0.513633 17.3607C0.150923 17.1804 0.0104391 16.7293 0.227121 16.3899C0.947057 15.2795 1.20703 13.922 0.877405 12.6455C0.535749 11.322 0.0414021 10.2074 0.0449408 8.80637C0.057208 3.89103 4.29561 -0.0938024 9.38257 0.0015544C14.296 0.097082 18.3453 4.08396 18.3453 8.828C18.3454 15.0238 11.6186 19.3738 5.67998 16.9812C4.86144 17.6014 3.83258 17.9389 2.79263 17.9389ZM1.82517 16.4262C2.94385 16.7616 4.20201 16.4937 5.06467 15.6886C5.27723 15.4902 5.59517 15.4403 5.86187 15.5634C10.9461 17.9099 16.9299 14.232 16.9298 8.828C16.9298 4.79547 13.5313 1.44876 9.35409 1.36758C5.04922 1.28503 1.47077 4.65047 1.46039 8.80967C1.45703 10.1404 1.99491 11.2039 2.28885 12.4645C2.59854 13.7929 2.43541 15.1977 1.82517 16.4262Z" fill="white"/>
                <path d="M21.1242 22.9999C20.0843 22.9999 19.0554 22.6623 18.2369 22.0422C15.0787 23.3145 11.322 22.7351 8.73246 20.5765C8.43663 20.3298 8.40396 19.8985 8.65939 19.6129C8.91488 19.3274 9.36175 19.2957 9.65758 19.5424C11.9543 21.4569 15.3178 21.8876 18.055 20.6244C18.3216 20.5013 18.6395 20.5512 18.8522 20.7496C19.7148 21.5546 20.973 21.8227 22.0917 21.4872C21.3718 20.0378 21.2721 18.3773 21.83 16.8401C21.835 16.8263 21.8405 16.8127 21.8463 16.7991C22.2538 15.872 22.4591 14.8867 22.4565 13.8706C22.452 12.0792 21.8347 10.422 20.671 9.07819C20.4204 8.78865 20.4603 8.35787 20.7602 8.11592C21.0601 7.87391 21.5065 7.91251 21.7571 8.20199C23.1157 9.77102 23.8668 11.783 23.872 13.8673C23.875 15.0612 23.635 16.2198 23.1588 17.3115C22.6585 18.7093 22.8886 20.2059 23.6897 21.4508C23.9065 21.7902 23.7659 22.2414 23.4031 22.4218C22.6983 22.8037 21.9202 22.9998 21.1242 22.9999Z" fill="white"/>
                <path d="M9.19506 9.94255C9.81354 9.94255 10.3149 9.45884 10.3149 8.86215C10.3149 8.26545 9.81354 7.78174 9.19506 7.78174C8.57657 7.78174 8.0752 8.26545 8.0752 8.86215C8.0752 9.45884 8.57657 9.94255 9.19506 9.94255Z" fill="white"/>
                <path d="M5.06908 9.94255C5.68756 9.94255 6.18894 9.45884 6.18894 8.86215C6.18894 8.26545 5.68756 7.78174 5.06908 7.78174C4.4506 7.78174 3.94922 8.26545 3.94922 8.86215C3.94922 9.45884 4.4506 9.94255 5.06908 9.94255Z" fill="white"/>
                <path d="M13.3201 9.94255C13.9385 9.94255 14.4399 9.45884 14.4399 8.86215C14.4399 8.26545 13.9385 7.78174 13.3201 7.78174C12.7016 7.78174 12.2002 8.26545 12.2002 8.86215C12.2002 9.45884 12.7016 9.94255 13.3201 9.94255Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_1_1615">
                    <rect width="23.8273" height="23" fill="white" transform="translate(0.0439453)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default TextIcon