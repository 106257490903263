import React, { memo, useCallback, useEffect, useState } from "react";
import { FormInstance } from "antd/es/form";
import FilterFormTitle from "../filter-form-title/FilterFormTitle";
import FilterRadioButtonGroup from "./button-group/FilterRadioButtonGroup";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import "./BedroomsBathrommsFilter.scss";
import { IObjectKeys } from "../../../../../../helpers/globalTypes";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../../../helpers/listingsCreateGetSearchQuery";
import {setBedroomsAndBathroomsFormData} from "../../../helpers/filterFormHelper";

interface IBedroomsBathroomsFilter {
  form: FormInstance;
  bedRoomsCount: IObjectKeys;
  setBedRoomsCount: React.Dispatch<React.SetStateAction<IObjectKeys>>;
  bathRoomsCount: IObjectKeys;
  setBathRoomsCount: React.Dispatch<React.SetStateAction<IObjectKeys>>;
  isClearAllClicked: boolean;
}

const BedroomsBathroomsFilter = ({
  form,
  bedRoomsCount,
  setBedRoomsCount,
  bathRoomsCount,
  setBathRoomsCount,
  isClearAllClicked,
}: IBedroomsBathroomsFilter) => {
  const [checked, setChecked] = useState(false);
  const defaultCheckedValue = localStorage.getItem(
    "use_exact_numbers_selected"
  );
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  const handleBedroomCheck = useCallback((e) => {
    const value =
      e.target.value === "Studio" ? "Studio" : +(e.target.value || 0);
    setBedRoomsCount((prevState: any) => ({
      ...prevState,
      from: value,
      to:
        checked
          ?  (value !== "Studio" ? value + 0.5
            : 0.5)
          : 20,
    }));
    form.submit();
  }, [checked]);

  const handleBathroomCheck = useCallback((e) => {
    const value = +(e.target.value || 0);
    setBathRoomsCount((prevState: any) => ({
      ...prevState,
      from: value,
      to: 20,
    }));
    form.submit();
  }, []);

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
    if (bedRoomsCount.from) {
      form.submit();
    }
  };

  useEffect(() => {
    if (checked) {
      localStorage.setItem("use_exact_numbers_selected", "true");
      setBedRoomsCount((prevState: any) => ({
        ...prevState,
        from: prevState.from,
        to: prevState.from + 0.5,
      }));
    } else {
      localStorage.removeItem("use_exact_numbers_selected");
      setBedRoomsCount((prevState: any) => ({
        from: prevState.from === "Studio" ? 1 : prevState.from,
        to: 20,
      }));
    }
  }, [checked]);

  useEffect(() => {
    if (defaultCheckedValue) {
      setChecked(true);
    }
  }, [defaultCheckedValue]);

  useEffect(() => {
    if (
      queryObject.bathrooms_from ||
      queryObject.bathrooms_to ||
      queryObject.bedrooms_from ||
      queryObject.bedrooms_to
    ) {
      setBedroomsAndBathroomsFormData(
        queryObject,
        setBathRoomsCount,
        setBedRoomsCount,
        checked
      );
    }
  }, []);

  useEffect(() => {
    if (isClearAllClicked) {
      setChecked(false);
    }
  }, [isClearAllClicked]);
  return (
    <div className={"bed-bath-wrapper"}>
      <FilterFormTitle title={"Bedrooms"} />
      <FilterRadioButtonGroup
        type={"Bedrooms"}
        handleChange={handleBedroomCheck}
        defaultValue={`${bedRoomsCount.from}`}
        checked={checked}
      />
      <Checkbox
        onChange={onCheckboxChange}
        checked={checked}
        className={"bed-bath-wrapper-checkbox"}
      >
        Use Exact Numbers
      </Checkbox>
      <FilterFormTitle title={"Bathrooms"} />
      <FilterRadioButtonGroup
        type={"Bathrooms"}
        handleChange={handleBathroomCheck}
        defaultValue={`${bathRoomsCount.from}`}
      />
    </div>
  );
};

export default memo(BedroomsBathroomsFilter);
