import React from "react";

const DropdownPencilEdit = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.502 2.5484L14.0521 1.0984C13.3896 0.435934 12.3118 0.435965 11.6493 1.0984C11.0256 1.72218 1.49887 11.2496 0.862216 11.8863C0.794404 11.9541 0.748966 12.0443 0.732779 12.1324L0.00784109 16.0474C-0.0202527 16.1992 0.0281223 16.3551 0.137279 16.4642C0.24656 16.5735 0.402497 16.6218 0.554091 16.5937L4.46875 15.8687C4.55922 15.8518 4.64815 15.8059 4.71484 15.7392L15.502 4.9513C16.166 4.2873 16.1661 3.21249 15.502 2.5484ZM1.05378 15.5477L1.49231 13.1794L3.42187 15.1091L1.05378 15.5477ZM4.3834 14.7448L1.85656 12.2178L11.0819 2.9918L13.6087 5.51883L4.3834 14.7448ZM14.8391 4.28839L14.2716 4.85593L11.7447 2.3289L12.3122 1.76137C12.6091 1.46443 13.0922 1.4644 13.3892 1.76137L14.8391 3.21137C15.1367 3.50902 15.1367 3.99071 14.8391 4.28839Z"
        fill="black"
      />
    </svg>
  );
};

export default DropdownPencilEdit;
