import React from "react";

export const DeleteNewIcon = ({ backgroundColor }: { backgroundColor?: string }) => {
  const bgColor = backgroundColor ?? "#5806B9";

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7817 3.21833C14.4897 -1.07278 7.50963 -1.07278 3.21762 3.21833C-1.07254 7.50944 -1.07254 14.4913 3.21762 18.7824C5.36363 20.9275 8.18185 21.9995 11.0001 21.9995C13.8184 21.9995 16.6357 20.9274 18.7817 18.7824C23.0728 14.4913 23.0728 7.50944 18.7817 3.21833ZM15.5388 14.2427C15.8974 14.6013 15.8974 15.1809 15.5388 15.5395C15.36 15.7183 15.1252 15.8082 14.8904 15.8082C14.6556 15.8082 14.4208 15.7183 14.242 15.5395L11.0001 12.2966L7.75906 15.5386C7.57932 15.7174 7.34451 15.8073 7.11065 15.8073C6.87589 15.8073 6.64109 15.7174 6.46225 15.5386C6.10368 15.18 6.10368 14.5995 6.46225 14.2418L9.70327 10.9999L6.46135 7.75796C6.10278 7.39939 6.10278 6.81887 6.46135 6.4612C6.81902 6.10263 7.39954 6.10263 7.75811 6.4612L11 9.70312L14.242 6.4612C14.6005 6.10263 15.1801 6.10263 15.5387 6.4612C15.8973 6.81887 15.8973 7.39939 15.5387 7.75796L12.2968 10.9999L15.5388 14.2427Z"
        fill={bgColor}
      />
    </svg>
  );
};
