import React from "react";
import NoteWithoutDescription from "./components/NoteWithoutDescription";

interface IListingNotesModalNote {
  note: any;
  handleOpenNoteModal: any;
  setVisible: any;
  setDeleteConfirmProps: any;
  handleShowSingleNote:any;
  setSingleNoteVisible:any;
}

const ListingNotesModalNote = ({
  note,
  handleOpenNoteModal,
  setVisible,
  setDeleteConfirmProps,
  handleShowSingleNote,
  setSingleNoteVisible
}: IListingNotesModalNote) => {
  return (
    <div
      className={"single-listing-page-all-note--notesWrapper--item--wrapper"}
      onClick={()=>handleShowSingleNote(note)}
    >
      <NoteWithoutDescription
        note={note}
        handleOpenNoteModal={handleOpenNoteModal}
        setVisible={setVisible}
        setDeleteConfirmProps={setDeleteConfirmProps}
        setSingleNoteVisible={setSingleNoteVisible}
      />
    </div>
  );
};

export default ListingNotesModalNote;
