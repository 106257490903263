import React, {memo} from "react";
import "./FilterFormTitle.scss"

const FilterFormTitle = ({title}:{title:string}) => {
    return (
        <p className={"filter-form-title"}>
            {title}
        </p>
    )
}

export default memo(FilterFormTitle)
