import React from "react";

const SuccessIcon = () => {
  return (
    <svg
      width="72"
      height="71"
      viewBox="0 0 72 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 71C55.8823 71 72 55.1061 72 35.5C72 15.8939 55.8823 0 36 0C16.1178 0 0 15.8939 0 35.5C0 55.1061 16.1178 71 36 71Z"
        fill="#5807B8"
      />
      <path
        d="M26.8013 51.5142L45.3123 69.7681C60.6436 65.7365 72 51.9621 72 35.5C72 35.164 72 34.828 72 34.4921L57.4637 21.2776L26.8013 51.5142Z"
        fill="#450C88"
      />
      <path
        d="M36.9086 43.4512C38.4985 45.019 38.4985 47.7067 36.9086 49.2745L33.6152 52.5222C32.0253 54.09 29.2998 54.09 27.7099 52.5222L13.2872 38.1878C11.6973 36.6199 11.6973 33.9322 13.2872 32.3644L16.5805 29.1168C18.1704 27.549 20.896 27.549 22.4859 29.1168L36.9086 43.4512Z"
        fill="white"
      />
      <path
        d="M49.5145 18.702C51.1044 17.1341 53.83 17.1341 55.4199 18.702L58.7133 21.9496C60.3032 23.5174 60.3032 26.2051 58.7133 27.7729L33.7291 52.2982C32.1392 53.866 29.4136 53.866 27.8237 52.2982L24.5303 49.0505C22.9404 47.4827 22.9404 44.795 24.5303 43.2272L49.5145 18.702Z"
        fill="white"
      />
    </svg>
  );
};

export default SuccessIcon;
