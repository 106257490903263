import { Button } from "antd";
import { AnalyticsService } from "helpers/analyticsService";
import { IObjectKeys } from "helpers/globalTypes";
import { useWindowSize } from "hooks/useWindowSize";
import { useDemoContext } from "libs/contextLib";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLandlord, getLandlordNotes, getLandlordsListLP, setSingleLandlordData } from "store/landlords/action";
import { setContactLeadData } from "store/leads/action";
import { userSelector } from "store/user/selectors";

import "../styles.scss";
import { useLocation } from "react-router-dom";
import BaseButton from "components/Shared/BaseButton";

const QuickContact = (props: { rowData: IObjectKeys; page?: string; setLandlordActionModalVisible?: any }) => {
  const userData = useSelector(userSelector);
  const renter = props.rowData?.renterOfRequirement;
  const landlord = props.rowData?.landlordOfList;
  const name = renter ? renter?.name : landlord?.name;
  const _id = renter ? renter?._id : landlord?.landlord_id;
  const isPrivateLandlord =
    props.rowData?.landlordOfList?.is_private && props.rowData?.landlordOfList?.created_by !== userData?.agent_id;
  const show = props.page === "landlords" || props.page === "lead-flow";
  const dispatch = useDispatch();
  const [isShowText, setIsShowText] = useState(!renter);
  const { isDemo, demoAction } = useDemoContext();
  const { width } = useWindowSize();
  const location = useLocation();
  useEffect(() => {
    if (!renter) setIsShowText(true);
  }, [renter]);

  const contactHandler = () => {
    //@ts-ignore
    if (isDemo && width < 767) {
      demoAction("landlord");
    } else if (renter) {
      _id && dispatch(setContactLeadData(props.rowData));
    } else if (props.page === "landlords") {
      dispatch(setSingleLandlordData(props.rowData));
      if (!isPrivateLandlord) dispatch(getLandlordNotes(props.rowData._id));
      props.setLandlordActionModalVisible && props.setLandlordActionModalVisible(true);
    } else if (landlord) {
      if (!isPrivateLandlord) dispatch(getLandlordNotes(props.rowData.landlordOfList._id));
      dispatch(
        getLandlord({
          _id: !isPrivateLandlord ? _id : props.rowData.agentOfList._id,
        })
      );
    }
    AnalyticsService.reportEvent("contact_clicked", {
      category: props.page,
      label: "",
    });
  };

  const addLandlordHandler = () => {
    dispatch(getLandlordsListLP({ page: 1, pageSize: 20 }));
    AnalyticsService.reportEvent("landlord_added", {
      category: props.page,
      label: "",
    });
  };

  return (
    <div>
      {location.pathname.includes("listings") && name ? (
        <BaseButton data-test-id="listing-quick-contact-button" id="listingQuickContactButton" onClick={contactHandler}>
          <>Contact {isShowText && <span className="landlord_name">{name}</span>}</>
        </BaseButton>
      ) : (
        location.pathname.includes("listings") && (
          <BaseButton
            className="add-landlord-button"
            data-test-id="listing-add-landlord-button"
            id="listingAddLandlordButton"
            onClick={addLandlordHandler}
          >
            Add Landlord
          </BaseButton>
        )
      )}
      {!location.pathname.includes("listings") && (
        <BaseButton data-test-id="listing-quick-contact-button" id="listingQuickContactButton" onClick={contactHandler}>
          Contact
        </BaseButton>
      )}
    </div>
  );
};

export default QuickContact;
