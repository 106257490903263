import React from "react";

const VerifiedInfoIcon = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.95801 2.79183H6.04134V3.87516H4.95801V2.79183ZM4.95801 4.9585H6.04134V8.2085H4.95801V4.9585ZM5.49967 0.0834961C2.50967 0.0834961 0.0830078 2.51016 0.0830078 5.50016C0.0830078 8.49016 2.50967 10.9168 5.49967 10.9168C8.48967 10.9168 10.9163 8.49016 10.9163 5.50016C10.9163 2.51016 8.48967 0.0834961 5.49967 0.0834961ZM5.49967 9.8335C3.11092 9.8335 1.16634 7.88891 1.16634 5.50016C1.16634 3.11141 3.11092 1.16683 5.49967 1.16683C7.88842 1.16683 9.83301 3.11141 9.83301 5.50016C9.83301 7.88891 7.88842 9.8335 5.49967 9.8335Z"
        fill="#4F4F4F"
      />
    </svg>
  );
};

export default VerifiedInfoIcon;
