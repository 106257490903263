export const SET_CURRENT_LANDLORD = "SET_CURRENT_LANDLORD";
export const RESET_CURRENT_LANDLORD = "RESET_CURRENT_LANDLORD";

export const CREATE_LANDLORD = "CREATE_LANDLORD";
export const CREATE_LANDLORD_SUCCESS = "CREATE_LANDLORD_SUCCESS";
export const CREATE_LANDLORD_FAIL = "CREATE_LANDLORD_FAIL";
export const SET_CREATE_LANDLORD_SUCCESS = "SET_CREATE_LANDLORD_SUCCESS"

export const EDIT_LANDLORD = "EDIT_LANDLORD";
export const EDIT_LANDLORD_SUCCESS = "EDIT_LANDLORD_SUCCESS";
export const EDIT_LANDLORD_FAIL = "EDIT_LANDLORD_FAIL";

export const GET_LANDLORDS_LIST = "GET_LANDLORDS_LIST";
export const GET_LANDLORDS_LIST_SUCCESS = "GET_LANDLORDS_LIST_SUCCESS";
export const GET_LANDLORDS_LIST_FAIL = "GET_LANDLORDS_LIST_FAIL";

export const GET_LANDLORDS_LIST_LANDLORD_PAGE = "GET_LANDLORDS_LIST_LANDLORD_PAGE";
export const GET_LANDLORDS_LIST_LANDLORD_PAGE_SUCCESS = "GET_LANDLORDS_LIST_LANDLORD_PAGE_SUCCESS";
export const GET_LANDLORDS_LIST_LANDLORD_PAGE_FAIL = "GET_LANDLORDS_LIST_LANDLORD_PAGE_FAIL";

export const SET_SINGLE_LANDLORD_DATA = "SET_SINGLE_LANDLORD_DATA";
export const RESET_SINGLE_LANDLORD_DATA = "RESET_SINGLE_LANDLORD_DATA";
export const ADD_LISTING_FOR_LANDLORD = "ADD_LISTING_FOR_LANDLORD";

export const GET_LANDLORD = "GET_LANDLORD";
export const GET_LANDLORD_SUCCESS = "GET_LANDLORD_SUCCESS";
export const GET_LANDLORD_FAIL = "GET_LANDLORD_FAIL";

export const GET_LISTINGS_OF_LANDLORD = "GET_LISTINGS_OF_LANDLORD";
export const GET_LISTINGS_OF_LANDLORD_SUCCESS = "GET_LISTINGS_OF_LANDLORD_SUCCESS";
export const GET_LISTINGS_OF_LANDLORD_FAIL = "GET_LISTINGS_OF_LANDLORD_FAIL";

export const ADD_LANDLORD_NOTE = "ADD_LANDLORD_NOTE";
export const ADD_LANDLORD_NOTE_SUCCESS = "ADD_LANDLORD_NOTE_SUCCESS";
export const ADD_LANDLORD_NOTE_FAIL = "ADD_LANDLORD_NOTE_FAIL";
export const CLEAR_LANDLORD_NOTES = "CLEAR_LANDLORD_NOTES";



export const UPDATE_LANDLORD_NOTE = "UPDATE_LANDLORD_NOTE";
export const UPDATE_LANDLORD_NOTE_SUCCESS = "UPDATE_LANDLORD_NOTE_SUCCESS";
export const UPDATE_LANDLORD_NOTE_FAIL = "UPDATE_LANDLORD_NOTE_FAIL";

export const DELETE_LANDLORD_NOTE = "DELETE_LANDLORD_NOTE";
export const DELETE_LANDLORD_NOTE_SUCCESS = "DELETE_LANDLORD_NOTE_SUCCESS";
export const DELETE_LANDLORD_NOTE_FAIL = "DELETE_LANDLORD_NOTE_FAIL";

export const GET_LANDLORD_NOTES = "GET_LANDLORD_NOTES";
export const GET_LANDLORD_NOTES_SUCCESS = "GET_LANDLORD_NOTES_SUCCESS";
export const GET_LANDLORD_NOTES_FAIL = "GET_LANDLORD_NOTES_FAIL";

export const GET_LANDLORD_FOLLOW_UPS = "GET_LANDLORD_FOLLOW_UPS";
export const GET_LANDLORD_FOLLOW_UPS_SUCCESS = "GET_LANDLORD_FOLLOW_UPS_SUCCESS";
export const GET_LANDLORD_FOLLOW_UPS_FAIL = "GET_LANDLORD_FOLLOW_UPS_FAIL";

export const SEND_LANDLORD_INFO_BY_SMS = "SEND_LANDLORD_INFO_BY_SMS";
export const SEND_LANDLORD_INFO_BY_SMS_SUCCESS = "SEND_LANDLORD_INFO_BY_SMS_SUCCESS";
export const SEND_LANDLORD_INFO_BY_SMS_FAIL = "SEND_LANDLORD_INFO_BY_SMS_FAIL";

export const ADD_MANUAL_FOLLOW_UP = "ADD_MANUAL_FOLLOW_UP";

export const UPLOAD_XLSX_LANDLORDS = "UPLOAD_XLSX_LANDLORDS";
export const UPLOAD_XLSX_LANDLORDS_SUCCESS = "UPLOAD_XLSX_LANDLORDS_SUCCESS";
export const UPLOAD_XLSX_LANDLORDS_FAIL = "UPLOAD_XLSX_LANDLORDS_FAIL";
export const SET_UPLOAD_XLSX_LANDLORD_SUCCESS = "SET_UPLOAD_XLSX_LANDLORD_SUCCESS";

export const DOWNLOAD_XLSX_LANDLORDS_TEMPLATE = "DOWNLOAD_XLSX_LANDLORDS_TEMPLATE";
export const DOWNLOAD_XLSX_LANDLORDS_TEMPLATE_SUCCESS = "DOWNLOAD_XLSX_LANDLORDS_TEMPLATE_SUCCESS";
export const DOWNLOAD_XLSX_LANDLORDS_TEMPLATE_FAIL = "DOWNLOAD_XLSX_LANDLORDS_TEMPLATE_FAIL";

export const CLEAR_UPLOAD_XLSX_LANDLORDS_ERRORS = "CLEAR_UPLOAD_XLSX_LANDLORDS_ERRORS";
export const CLEAR_PARTICULAR_UPLOAD_XLSX_LANDLORDS_ERROR = "CLEAR_PARTICULAR_UPLOAD_XLSX_LANDLORDS_ERROR";

export const SET_LANDLORDS_QUERY_PARAMS = "SET_LANDLORDS_QUERY_PARAMS";
export const SET_LANDLORD_LISTINGS_VIEW_TYPE = "SET_LANDLORD_LISTINGS_VIEW_TYPE";
export const SET_LANDLORD_LISTINGS_FILTER_OPTIONS = "SET_LANDLORD_LISTINGS_FILTER_OPTIONS";
export const SET_LANDLORD_LISTINGS_PAGINATION_VALUES = "SET_LANDLORD_LISTINGS_PAGINATION_VALUES";

export const SET_LANDLORD_LISTINGS_LAST_QUERY = "SET_LANDLORD_LISTINGS_LAST_QUERY";
export const SET_LANDLORD_LISTINGS_LOADING_TO_TRUE = "SET_LANDLORD_LISTINGS_LOADING_TO_TRUE";
export const TOGGLE_LANDLORD_PRIVACY = "TOGGLE_LANDLORD_PRIVACY";
export const TOGGLE_LANDLORD_PRIVACY_SUCCESS = "TOGGLE_LANDLORD_PRIVACY_SUCCESS";
export const TOGGLE_LANDLORD_PRIVACY_FAIL = "TOGGLE_LANDLORD_PRIVACY_FAIL";

export const SET_LANDLORD_LOADING_TO_TRUE = "SET_LANDLORD_LOADING_TO_TRUE";

export const SET_LANDLORDS_VIEW_TYPE = "SET_LANDLORDS_VIEW_TYPE";
export const SET_LANDLORD_NOTE_ADD_SUCCESS = "SET_LANDLORD_NOTE_ADD_SUCCESS";

export const CHECK_EXISTING_LANDLORD = "CHECK_EXISTING_LANDLORD";
export const CHECK_EXISTING_LANDLORD_SUCCESS = "CHECK_EXISTING_LANDLORD_SUCCESS";
export const CHECK_EXISTING_LANDLORD_FAIL = "CHECK_EXISTING_LANDLORD_FAIL";

export const UPDATE_LANDLORD_TAGS = "UPDATE_LANDLORD_TAGS";
export const UPDATE_LANDLORD_TAGS_SUCCESS = "UPDATE_LANDLORD_TAGS_SUCCESS";
export const UPDATE_LANDLORD_TAGS_FAIL = "UPDATE_LANDLORD_TAGS_FAIL";


