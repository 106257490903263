import React, { useEffect, useState } from "react";
import LeadBedroomIcon from "../../icons/LeadBedroomIcon";
import LeadBathroomIcon from "../../icons/LeadBathroomIcon";
import PurpleLocation from "../../icons/PurpleLocation";
import PurpleDateIcon from "../../icons/PurpleDateIcon";
import UserWithPlusIcon from "../../icons/UserWithPlus";
import moment from "moment";
import "./LeadMobileItem.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "store/user/selectors";
import { useDemoContext } from "libs/contextLib";
import SingleTag from "../../Lead/shared/lead-availabilities/components/single-tag/SingleTag";
import { tags } from "../../Lead/shared/constants";
import { IObjectKeys } from "helpers/globalTypes";
import { formatDate, timeSince } from '../../../helpers/dateHelper';
import { US_DATE_FORMAT } from "helpers/constants";

interface IOLeadMobileItem {
  elem: any;
}
const LeadMobileItem = ({ elem }: IOLeadMobileItem) => {
  const userData = useSelector(userSelector);
  const { isDemo } = useDemoContext();
  const name =
    userData?._id === elem?.agentsOfRequirement[0]?._id
      ? elem?.renterOfRequirement?.name
      : `${elem?.agentsOfRequirement[0]?.name}'s Lead`;
  const [leadTags, setLeadTags] = useState([] as Array<IObjectKeys>);

  useEffect(() => {
    const newTags = tags.filter((tag) => elem.tags?.includes(tag.name));
    setLeadTags(newTags);
  }, [elem]);

  return (
    <Link to={`/${isDemo ? "demo/leads" : "leads"}/${elem._id}`}>
      <div className={"lead-single-item"}>
        <h5 className={"lead-single-item--title mb-0"}>{name}</h5>
        <div className="flex">
          {leadTags.map((tag, index) => (
            <SingleTag
              icon={tag.icon}
              label={tag.label}
              name={tag.name}
              classnames={`tag-wrapper-${index}-child`}
              key={index}
              type="lead"
            />
          ))}
        </div>
        <div className={"lead-single-item__middle"}>
          <p className={"lead-single-item__middle--budget"}>
            {elem.budget_min && "$" + elem.budget_min}{" "}
            {elem.budget_max &&
              elem.budget_max !== elem.budget_min &&
              "- $" + elem.budget_max}
          </p>
          <div className={"lead-single-item__middle__bathbed"}>
            <LeadBedroomIcon />
            <p>
              {elem.bedrooms_min ? elem.bedrooms_min : 0}{" "}
              {elem.bedrooms_max && elem.bedrooms_max > elem.bedrooms_min
                ? "- " + elem.bedrooms_max
                : null}
            </p>
          </div>
          <div className={"lead-single-item__middle__bathbed"}>
            <LeadBathroomIcon />
            <p>
              {elem.bathrooms_min ? elem.bathrooms_min : 0}{" "}
              {elem.bathrooms_max && elem.bathrooms_max > elem.bathrooms_min
                ? "- " + elem.bathrooms_max
                : null}
            </p>
          </div>
        </div>
        <div className={"lead-single-item__bottom"}>
          <PurpleLocation />
          {elem.location.length > 0 ? (
            typeof elem.location !== "string" ? (
              <p>
                {elem?.location?.slice(0, 3).map((e: any, i: any) => {
                  return (
                    <span key={i}>
                      {e}
                      {elem?.location?.slice(0, 3).length > 1 &&
                        i !== elem?.location?.slice(0, 3).length - 1
                        ? ", "
                        : ""}
                    </span>
                  );
                })}
              </p>
            ) : (
              <p>{elem.location}</p>
            )
          ) : (
            elem?.area.map((el: any, key: number) => {
              return (
                <p key={key}>
                  <span className={"mr-5"}>
                    {key < elem?.area?.length - 1
                      ? `${el.area.split(",")[0]},`
                      : `${el.area.split(",")[0]}`}
                  </span>
                </p>
              );
            })
          )}
        </div>
        <div className="lead-single-item__middle">
          <div className={"lead-single-item__date"}>
            <PurpleDateIcon />
            <p>{formatDate(elem.move_in_date_start, US_DATE_FORMAT)}</p>
          </div>
          <div className={"lead-single-item__date"}>
            <UserWithPlusIcon />
            <p>{timeSince(elem.created_at)}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default LeadMobileItem;
