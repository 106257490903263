import React from "react";

const NoteIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.62691 16.0871C3.17088 16.0871 3.61186 15.6462 3.61186 15.1022C3.61186 14.5582 3.17088 14.1172 2.62691 14.1172C2.08294 14.1172 1.64197 14.5582 1.64197 15.1022C1.64197 15.6462 2.08294 16.0871 2.62691 16.0871Z"
        fill="black"
      />
      <path
        d="M2.62691 11.8191C3.17088 11.8191 3.61186 11.3782 3.61186 10.8342C3.61186 10.2902 3.17088 9.84924 2.62691 9.84924C2.08294 9.84924 1.64197 10.2902 1.64197 10.8342C1.64197 11.3782 2.08294 11.8191 2.62691 11.8191Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90961 6.89455H11.8192C12.0005 6.89455 12.1475 6.74757 12.1475 6.56623C12.1475 6.38489 12.0005 6.23792 11.8192 6.23792H5.90961C5.72831 6.23792 5.5813 6.38489 5.5813 6.56623C5.5813 6.74757 5.72831 6.89455 5.90961 6.89455Z"
        fill="black"
      />
      <path
        d="M2.62691 7.55113C3.17088 7.55113 3.61186 7.11015 3.61186 6.56618C3.61186 6.02221 3.17088 5.58124 2.62691 5.58124C2.08294 5.58124 1.64197 6.02221 1.64197 6.56618C1.64197 7.11015 2.08294 7.55113 2.62691 7.55113Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4608 7.87947V7.94217C13.6727 7.90037 13.8882 7.87936 14.1042 7.87947H14.1108H14.1174C14.3379 7.87954 14.5578 7.90152 14.774 7.94515V7.87947C14.774 7.51683 14.48 7.22284 14.1174 7.22284C13.7547 7.22284 13.4608 7.51683 13.4608 7.87947Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4909 3.61138H13.3245L10.1777 0.464539V2.29816C10.1776 3.02344 10.7656 3.61138 11.4909 3.61138Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.68177 14.8685C5.61753 14.9292 5.58119 15.0138 5.5813 15.1023C5.5813 15.2836 5.72827 15.4306 5.90961 15.4306H9.89827C10.0139 15.2171 10.1186 14.998 10.2121 14.7739H5.90961C5.82397 14.7731 5.74167 14.8072 5.68177 14.8685Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1108 17.4005C14.1085 17.4005 14.1065 17.4005 14.1042 17.4005H13.1324C13.1324 17.9444 13.5734 18.3854 14.1174 18.3854C14.6613 18.3854 15.1023 17.9444 15.1023 17.4005H14.1174C14.1151 17.4005 14.1131 17.4005 14.1108 17.4005Z"
        fill="black"
      />
      <path
        d="M9.83617 17.4004C9.59414 17.4053 9.37097 17.2703 9.26295 17.0537C9.14636 16.8136 9.17627 16.5281 9.34012 16.3173C9.39593 16.2431 9.44451 16.1637 9.49705 16.0875H5.90957C5.36562 16.0875 4.92462 15.6466 4.92462 15.1026C4.92767 14.5599 5.36687 14.1207 5.90957 14.1176H10.4501C10.6753 13.3717 10.7968 12.5984 10.8112 11.8195H5.90957C5.36562 11.8195 4.92462 11.3785 4.92462 10.8345C4.92767 10.2918 5.36687 9.85262 5.90957 9.84956H11.0969C11.4315 9.08765 12.0411 8.47993 12.8041 8.14759V7.87935C12.8063 7.28236 13.211 6.76197 13.789 6.61273V4.26795H11.4909C10.4034 4.26688 9.52207 3.38556 9.521 2.2981V0H1.31322C0.587937 0 0 0.587973 0 1.31322V17.0721C0 17.7974 0.587937 18.3854 1.31322 18.3854H12.4758C12.5786 18.3845 12.6809 18.3716 12.7808 18.3469C12.5828 18.071 12.4762 17.74 12.4758 17.4004H9.83617ZM5.90957 5.58129H11.8191C12.3631 5.58129 12.8041 6.02225 12.8041 6.56623C12.8041 7.11018 12.3631 7.55118 11.8191 7.55118H5.90957C5.36562 7.55118 4.92462 7.11022 4.92462 6.56623C4.92466 6.02225 5.36562 5.58129 5.90957 5.58129ZM0.98491 1.31322C0.98491 1.13192 1.13188 0.98491 1.31322 0.98491H2.62645C2.80775 0.98491 2.95476 1.13188 2.95476 1.31322C2.95476 1.49456 2.80779 1.64154 2.62645 1.64154H1.64154V2.62649C1.64154 2.80779 1.49456 2.9548 1.31322 2.9548C1.13188 2.9548 0.98491 2.80783 0.98491 2.62649V1.31322ZM2.62649 16.7438C1.71989 16.7438 0.984946 16.0089 0.984946 15.1023C0.984946 14.1957 1.71989 13.4607 2.62649 13.4607C3.53308 13.4607 4.26803 14.1957 4.26803 15.1023C4.26695 16.0084 3.53261 16.7427 2.62649 16.7438ZM2.62649 12.4758C1.71989 12.4758 0.984946 11.7409 0.984946 10.8343C0.984946 9.92767 1.71989 9.19272 2.62649 9.19272C3.53308 9.19272 4.26803 9.92767 4.26803 10.8343C4.26695 11.7404 3.53261 12.4747 2.62649 12.4758ZM2.62649 8.20774C1.71989 8.20774 0.984946 7.47279 0.984946 6.5662C0.984946 5.6596 1.71989 4.92466 2.62649 4.92466C3.53308 4.92466 4.26803 5.6596 4.26803 6.5662C4.26695 7.47236 3.53261 8.20666 2.62649 8.20774Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.68171 10.6005C5.61747 10.6612 5.58113 10.7458 5.58124 10.8343C5.58124 11.0156 5.72821 11.1626 5.90955 11.1626H10.8211C10.8211 10.9421 10.8432 10.7221 10.8867 10.5059H5.90955C5.82391 10.505 5.74161 10.5392 5.68171 10.6005Z"
        fill="black"
      />
      <path
        d="M16.7439 11.5828V11.1626C16.7412 9.71313 15.5668 8.5388 14.1174 8.53607C14.1151 8.53607 14.1131 8.53607 14.1108 8.53607C14.1085 8.53607 14.1065 8.53607 14.1042 8.53607C13.822 8.53636 13.5416 8.58229 13.2739 8.67199C13.2606 8.6777 13.2469 8.68251 13.2329 8.68642C12.1825 9.05675 11.4793 10.0487 11.4777 11.1625V11.5828C11.4818 12.5931 11.3179 13.597 10.9928 14.5537C10.99 14.5637 10.9865 14.5736 10.9823 14.5832C10.827 15.0308 10.6321 15.4638 10.3999 15.8768C10.3934 15.8913 10.3856 15.9053 10.3766 15.9184C10.3244 16.0104 10.2883 16.0757 10.1763 16.2537C10.112 16.355 10.1081 16.4833 10.1661 16.5883C10.2241 16.6934 10.3347 16.7584 10.4547 16.758L12.7982 16.7488C12.8118 16.7479 12.8254 16.7479 12.8389 16.7488L14.1043 16.7438H14.1108H14.1174H17.7548C17.8743 16.7436 17.9844 16.6784 18.0419 16.5736C18.0995 16.4687 18.0955 16.3409 18.0315 16.2399C17.1693 14.841 16.7227 13.2258 16.7439 11.5828ZM14.1174 9.84933C13.3921 9.84933 12.8042 10.4373 12.8042 11.1626C12.8042 11.3439 12.6572 11.4909 12.4759 11.4909C12.2946 11.4909 12.1476 11.3439 12.1476 11.1626C12.1486 10.0751 13.0299 9.19378 14.1174 9.1927C14.2987 9.1927 14.4457 9.33968 14.4457 9.52102C14.4457 9.70232 14.2987 9.84933 14.1174 9.84933Z"
        fill="black"
      />
    </svg>
  );
};

export default NoteIcon;
