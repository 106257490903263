import React from 'react';

export const TransparentHomeIcon = ({ width, height }: { width?: string, height?: string }) => {
    const widthS = width || "20";
    const heightS = height || "17";
    
    return (
        <svg width={widthS} height={heightS} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2.69L15 7.19V15H13V9H7V15H5V7.19L10 2.69ZM10 0L0 9H3V17H9V11H11V17H17V9H20L10 0Z" fill="#323232" />
        </svg>
    );
};
