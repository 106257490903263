import React, { useEffect, useState, useCallback } from "react";
import { Layout } from "antd";
import "./styles.scss";
import { push } from "connected-react-router";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RouteInterface, RoutesArrInterface } from "../../routes/routesCode";
import { windowSelector } from "../../store/app/selectors";
import { userSelector } from "../../store/user/selectors";
import { getSrRenterIdFromQuery, isOwner } from "../../helpers/globalFunctions";
import { setListingsLoadingToTrue } from "../../store/listings/action";
import { setLandlordLoadingToTrue } from "../../store/landlords/action";
import { setFollowupsLoaderToTrue } from "../../store/followUps/action";
import { setTeamMembersLoaderToFalse, setTeamMembersLoaderToTrue } from "../../store/teams/action";
import { setActivitiesLoaderToTrue } from "../../store/activities/action";
// import { setLeadsLoadingToTrue } from "../../store/leads/action";
import { useTour } from "@reactour/tour";
import { AnalyticsService } from "helpers/analyticsService";
import { IObjectKeys } from "helpers/globalTypes";
import { getTabsWalkthroughContent } from "contentful/api";
import { getContentFromArray } from "contentful/getContentFromArray";
import SideBarLogoAndArrowSection from "./components/sidebar-logo-and-arrow-section/SideBarLogoAndArrowSection";
import { sidebarClassNames } from "./helpers/SidebarHelpers";
import BottomMenu from "./components/bottom-menu/BottomMenu";
import SidebarTopPagesMenu from "./components/sidebar-top-pages-menu/SidebarTopPagesMenu";
import { useAppContext, useDemoContext } from "libs/contextLib";
import { PERSONAS } from "../../routes/routesCode";

const { Sider } = Layout;

const PageSidebar = ({
  menuItems,
  toggleSidebar,
  setVisibility,
  isLoading = false,
}: {
  menuItems?: RoutesArrInterface;
  toggleSidebar: Function;
  setVisibility: boolean;
  isLoading?: boolean;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isNotPremiumUser } = useAppContext();
  const { setIsOpen, setSteps } = useTour();
  const { isDemo } = useDemoContext();
  const location = useLocation();

  const userData = useSelector(userSelector);
  const { type, collapsed } = useSelector(windowSelector);

  const [activeMenuItem, setActiveMenuItem] = useState("/");
  const { pathname, search } = useLocation();

  let showPremium = false;
  if (userData?.agency) {
    const { free_subscription_limits } = userData?.agency;
    showPremium = isNotPremiumUser && free_subscription_limits && !Boolean(free_subscription_limits.team_members > 0);
  }

  useEffect(() => {
    const splitLocation: string[] = pathname.split("/");
    if (isDemo) {
      setActiveMenuItem("/" + (splitLocation[2] ?? ""));
    } else {
      if (getSrRenterIdFromQuery(search)) {
        setActiveMenuItem("/screening-reports");
      } else {
        setActiveMenuItem(pathname);
      }
    }
  }, [pathname]);

  const handleMenuClick = useCallback(
    (item) => {
      if (!history.location.pathname.includes(item.path)) {
        if (item.path === "/listings") {
          dispatch(setListingsLoadingToTrue());
        } else if (item.path === "/") {
          dispatch(setListingsLoadingToTrue());
        } else if (item.path === "/landlords") {
          dispatch(setLandlordLoadingToTrue());
        } else if (item.path === "/followUps") {
          dispatch(setFollowupsLoaderToTrue());
        } else if (item.path === "/teamsMembers") {
          if (!showPremium) {
            dispatch(setTeamMembersLoaderToTrue());
            if (userData?.agency?.agent_ids?.length <= 1 && userData?.agent_id === userData?.agency?.agent_ids[0]) {
              setTimeout(() => {
                dispatch(setTeamMembersLoaderToFalse());
              }, 400);
            }
          } else {
            dispatch(push("/teamDashboard"));
          }
        } else if (item.path === "/teamDashboard") {
          dispatch(setActivitiesLoaderToTrue());
        }
      }
      AnalyticsService.reportTabChanged(item.path);

      if (type !== "desktop" && !collapsed) {
        toggleSidebar();
      }
      if (isDemo) {
        dispatch(push("/demo" + item.path));
      } else {
        dispatch(push(item.path));
      }
    },
    [type, collapsed, history.location.pathname]
  );

  const handleOpenMenu = (key: any) => {};

  useEffect(() => {
    const isQa = search.includes("tour=true"); // ADD TO URL: ?tour=true
    const shouldShow = localStorage.getItem("tabs walkthrough") === "first" && !location.pathname.includes("privacy");
    const rightScreenAndState = type === "desktop" && !collapsed;
    (async () => {
      const entries: IObjectKeys = await getTabsWalkthroughContent();
      if (entries?.fields) {
        const tourContent = (tourStep: string) => {
          return <div>{getContentFromArray(entries?.fields?.tourSteps, tourStep)}</div>;
        };
        setSteps([
          {
            selector: '[data-tour="step-Listings"]',
            content: tourContent("step-Listings"),
          },
          {
            selector: '[data-tour="step-Leads"]',
            content: tourContent("step-Leads"),
          },
          {
            selector: '[data-tour="step-Tenant Screenings"]',
            content: tourContent("step-Tenant Screenings"),
          },
          {
            selector: '[data-tour="step-Landlords"]',
            content: tourContent("step-Landlords"),
          },
          {
            selector: '[data-tour="step-Follow-ups"]',
            content: tourContent("step-Follow-ups"),
          },
          {
            selector: '[data-tour="step-My Teams"]',
            content: tourContent("step-My Teams"),
          },
          // { selector: ".new-place-2", content: "New place 2" }
        ]);
      }
    })();

    (isQa || shouldShow) &&
      rightScreenAndState &&
      setTimeout(function () {
        setIsOpen(true);
      }, 2000);
  }, [collapsed, type, localStorage.getItem("tabs walkthrough"), location.pathname]);

  const currentUserPersona = userData.role.toLowerCase() === PERSONAS.RENTER ? PERSONAS.RENTER : PERSONAS.AGENT;

  return (
    <>
      <Sider
        data-test-id="main-sider"
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={type === "desktop" ? 120 : 0}
        className={sidebarClassNames(type, collapsed, isLoading)}
      >
        <SideBarLogoAndArrowSection toggleSidebar={toggleSidebar} setVisible={setVisibility} />
        <div className={"sidebar-menu-wrapperAll"}>
          {menuItems &&
            Object.values(menuItems)
              .filter((item: RouteInterface) => !item.hide)
              .filter((item: RouteInterface) => item.persona === currentUserPersona)
              .map((item: RouteInterface, key: number) => {
                return (
                  <React.Fragment key={key}>
                    <SidebarTopPagesMenu
                      handleMenuClick={handleMenuClick}
                      handleOpenMenu={handleOpenMenu}
                      activeMenuItem={activeMenuItem}
                      setActiveMenuItem={setActiveMenuItem}
                      collapsed={collapsed}
                      item={item}
                    />
                  </React.Fragment>
                );
              })}
          <BottomMenu toggleSidebar={toggleSidebar} />
        </div>
      </Sider>
    </>
  );
};

export default React.memo(PageSidebar);
