import React, { useEffect, useRef } from "react";

const ScrollToTopWrapper = ({ children }: any) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      //@ts-ignore
      contentRef.current.scrollIntoView();
    }
  }, []);
  return <div ref={contentRef}>{children}</div>;
};

export default ScrollToTopWrapper;
