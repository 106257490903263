export const GET_REQUIREMENT_AGENT = "GET_REQUIREMENT_AGENT";
export const GET_REQUIREMENT_AGENT_SUCCESS = "GET_REQUIREMENT_AGENT_SUCCESS";
export const GET_REQUIREMENT_AGENT_FAIL = "GET_REQUIREMENT_AGENT_FAIL";

export const FILL_REQUIREMENT_FORM_DATA = "FILL_REQUIREMENT_FORM_DATA";

export const CREATE_REQUIREMENT_FORM = "CREATE_REQUIREMENT_FORM";
export const CREATE_REQUIREMENT_FORM_SUCCESS =
  "CREATE_REQUIREMENT_FORM_SUCCESS";
export const CREATE_REQUIREMENT_FORM_FAIL = "CREATE_REQUIREMENT_FORM_FAIL";
export const EDIT_REQUIREMENT_FORM = "EDIT_REQUIREMENT_FORM";
export const EDIT_REQUIREMENT_FORM_SUCCESS = "EDIT_REQUIREMENT_FORM_SUCCESS";
export const EDIT_REQUIREMENT_FORM_ERROR = "EDIT_REQUIREMENT_FORM_ERROR";
export const EDIT_REQUIREMENT_FORM_INFO = "EDIT_REQUIREMENT_FORM_INFO";
export const EDIT_REQUIREMENT_FORM_PETS_INFO =
  "EDIT_REQUIREMENT_FORM_PETS_INFO";
export const EDIT_REQ_SUCCESS_SELECTOR = "EDIT_REQ_SUCCESS_SELECTOR"
export const GET_RENTERS_REQUIREMENT = "GET_RENTERS_REQUIREMENT";
export const START_LEAD_ANY_ACTION = "START_LEAD_SOME_ACTION";
export const START_LEAD_STATUS_ACTION = "START_LEAD_STATUS_ACTION";
export const LEAD_ACTION_ERROR = "LEAD_ACTION_ERROR";
export const LEAD_ACTION_SUCCESS = "LEAD_ACTION_SUCCESS";
export const LEAD_APPROVE_SUCCESS = "LEAD_APPROVE_SUCCESS";
export const LEAD_ACCESS_REQUIREMENT = "LEAD_ACCESS_REQUIREMENT";
export const LEAD_ACCESS_REQUIREMENT_SUCCESS =
  "LEAD_ACCESS_REQUIREMENT_SUCCESS";
export const LEAD_REVOKE_REQUIREMENT = "LEAD_REVOKE_REQUIREMENT";
export const LEAD_REVOKE_REQUIREMENT_SUCCESS =
  "LEAD_REVOKE_REQUIREMENT_SUCCESS";
export const LEAD_STATUS_REQUIREMENT = "LEAD_STATUS_REQUIREMENT";
export const LEAD_CHANGE_STATUS_SUCCESS = "LEAD_CHANGE_STATUS_SUCCESS";
export const SET_LEAD_CHANGE_STATUS_SUCCESS = "SET_LEAD_CHANGE_STATUS_SUCCESS";

export const DECLINE_FOR_REQUIREMENT = "DECLINE_FOR_REQUIREMENT";
export const CLEAR_REQUIREMENTS_DATA = "CLEAR_REQUIREMENTS_DATA";

export const CHECK_RENTER_STATUS = "CHECK_RENTER_STATUS";
export const CHECK_RENTER_STATUS_SUCCESS = "CHECK_RENTER_STATUS_SUCCESS";
export const CHECK_RENTER_STATUS_FAIL = "CHECK_RENTER_STATUS_FAIL";

export const CLEAR_RENTER_STATUS = "CLEAR_RENTER_STATUS";

export const CREATE_RENTER_DRAFT = "CREATE_RENTER_DRAFT";
export const CREATE_RENTER_DRAFT_SUCCESS = "CREATE_RENTER_DRAFT_SUCCESS";
export const CREATE_RENTER_DRAFT_FAIL = "CREATE_RENTER_DRAFT_FAIL";

export const UPDATE_RENTER_DRAFT = "UPDATE_RENTER_DRAFT"
export const UPDATE_RENTER_DRAFT_SUCCESS = "UPDATE_RENTER_DRAFT_SUCCESS"
export const UPDATE_RENTER_DRAFT_FAIL = "UPDATE_RENTER_DRAFT_FAIL"
export const SET_UPDATE_RENTER_DRAFT_SUCCESS = "SET_UPDATE_RENTER_DRAFT_SUCCESS"

export const GET_REQUIREMENT_DRAFT = "GET_REQUIREMENT_DRAFT"
export const GET_REQUIREMENT_DRAFT_SUCCESS = "GET_REQUIREMENT_DRAFT_SUCCESS"
export const GET_REQUIREMENT_DRAFT_FAIL = "GET_REQUIREMENT_DRAFT_FAIL"
export const SET_REQUIREMENT_FORM_DATA_VALUES = "SET_REQUIREMENT_FORM_DATA_VALUES"

export const SET_REQUIREMENT_ERROR_TO_BLANK = "SET_REQUIREMENT_ERROR_TO_BLANK"

export const NOTIFY_AGENTS = "NOTIFY_AGENTS";
export const NOTIFY_AGENTS_SUCCESS = "NOTIFY_AGENTS_SUCCESS";
export const SET_NOTIFY_AGENTS_SUCCESS = "SET_NOTIFY_AGENTS_SUCCESS";

export const CLEAR_REQ_FORM_SUBMITTED = "CLEAR_REQ_FORM_SUBMITTED"
