import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../../store/user/selectors";
import "./BillingAndInvoices.scss";
import PremiumUpgradeModal from "../../../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import moment from "moment";
import {
  invoicesSelector,
  paymentLinkSelector,
  subscriptionsSelector,
  upcomingInvoicesSelector,
  updatedSubscriptionSelector,
} from "../../../../store/payment/selectors";
import {
  cancelSubscription,
  getStripePortalURL,
  getInvoices,
  getUpcomingInvoices,
  setUpdatedSubscriptionToBlank,
  updateSubscription,
} from "../../../../store/payment/action";
import { stripeYearlyPaymentId, CHANGE_TO_YEARLY, FREE_PLAN_LIMITS } from "../../../../helpers/constants";
import BillingConfirmationModal from "./billing-confirmation-modal/BillingConfirmationModal";
import BillingAndInvoicesModal from "./billing-and-invoices-modal/BillingAndInvoicesModal";
import { getLoggedInUser } from "../../../../store/user/action";
import { AnalyticsService } from "helpers/analyticsService";
import { useAppContext } from "libs/contextLib";
import { formatDate } from '../../../../helpers/dateHelper';

const BillingAndInvoices = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelector);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const subscriptions = useSelector(subscriptionsSelector);
  const upcomingInvoices = useSelector(upcomingInvoicesSelector);
  const invoices = useSelector(invoicesSelector);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [invoicesModalVisible, setInvoicesModalVisible] = useState(false);
  const [billingConfirmModalVisible, setBillingConfirmModalVisible] = useState(false);
  const [actionType, setActionType] = useState("update");
  const updatedSubscription = useSelector(updatedSubscriptionSelector);
  const paymentLink = useSelector(paymentLinkSelector);
  const { isNotPremiumUser } = useAppContext();

  const onRenewClick = useCallback(() => {
    switch (actionType) {
      case "update":
        AnalyticsService.reportPremiumEvents("confirm", {
          label: "billing_confirmation_modal",
        });
        dispatch(updateSubscription({ stripeYearlyPaymentId, currentLoc: window.location.href }));
        break;
      case "cancel":
        AnalyticsService.reportPremiumEvents("confirm", {
          label: "billing_cancelation_modal",
        });
        dispatch(cancelSubscription());
        break;
      default:
        break;
    }
  }, [selectedPlan, actionType]);

  const openInvoiceModalHandler = () => {
    AnalyticsService.reportPremiumEvents("see_all_invoices");
    setInvoicesModalVisible(true);
  };

  const cancelSubscriptionHandler = () => {
    AnalyticsService.reportPremiumEvents("cancel_plan");
    setActionType("cancel");
    setBillingConfirmModalVisible(true);
  };

  const openBillingConfirmModalHandler = () => {
    AnalyticsService.reportPremiumEvents("change_plan_to_yearly", {
      label: CHANGE_TO_YEARLY,
    });
    setActionType("update");
    setBillingConfirmModalVisible(true);
  };
  useEffect(() => {
    if (Object.keys(upcomingInvoices).length) {
      if (upcomingInvoices?.lines?.data[0]?.plan?.interval === "year") {
        setSelectedPlan("year");
      } else {
        setSelectedPlan("month");
      }
    }
  }, [upcomingInvoices]);

  const FreePlanWrapper = useMemo(() => {
    if (Object.keys(userData).length) {
      const { free_subscription_limits } = userData.agency;
      const { landlords, listings, team_members, leads } = free_subscription_limits;
      return (
        <div className={"settings-billing-free"}>
          <span>Member Since</span>
          <p>{formatDate(userData.created_at,"ll")}</p>
          <div className="limit-section">
            <label className="">
              You are using Free Plan. To have unlimited access to RentBase{" "}
              <button
                type={"button"}
                onClick={() => {
                  AnalyticsService.reportPremiumEvents("upgrade_now");
                  setPremiumModalVisible(true);
                }}
              >
                Upgrade now
              </button>
            </label>
            <div>
              <p className="limit-section-item">
                Listings Created:{" "}
                <span>
                  {FREE_PLAN_LIMITS.listings - listings} out of {FREE_PLAN_LIMITS.listings}
                </span>
              </p>
              <p className="limit-section-item">
                Landlords Created:{" "}
                <span>
                  {FREE_PLAN_LIMITS.landlords - landlords} out of {FREE_PLAN_LIMITS.landlords}
                </span>
              </p>
              <p className="limit-section-item">
                Team Member Created:{" "}
                <span>
                  {FREE_PLAN_LIMITS.team_members - team_members} out of {FREE_PLAN_LIMITS.team_members}
                </span>
              </p>
              <p className="limit-section-item">
                Leads Created:{" "}
                <span>
                  {FREE_PLAN_LIMITS.leads - leads} out of {FREE_PLAN_LIMITS.leads}
                </span>
              </p>
            </div>
          </div>
        </div>
      );
    }
  }, [userData]);

  useEffect(() => {
    if (Object.keys(updatedSubscription).length) {
      setBillingConfirmModalVisible(false);
      dispatch(getLoggedInUser());
      if (!updatedSubscription.cancel) {
        dispatch(getInvoices());
        dispatch(getUpcomingInvoices());
        dispatch(setUpdatedSubscriptionToBlank());
      }
    }
  }, [updatedSubscription]);

  useEffect(() => {
    if (paymentLink) {
      window.location.href = paymentLink;
    }
  }, [paymentLink]);

  const changeSubscriptionHandler = () => {
    dispatch(getStripePortalURL());
  };

  const PremiumPlanWrapper = useMemo(() => {
    if (Object.keys(subscriptions).length && Object.keys(upcomingInvoices).length && invoices.length) {
      return (
        <div className={"settings-billing-premium"}>
          <div className={"settings-billing-premium-item"}>
            <span>⭐ Premium Since</span>
            <p>{moment(subscriptions?.created * 1000).format("ll")}</p>
            <button type={"button"} onClick={cancelSubscriptionHandler}>
              Cancel
            </button>
          </div>
          <div className={"settings-billing-premium-item"}>
            <span>Renewal date</span>
            <p>{moment(subscriptions?.current_period_end * 1000).format("ll")}</p>
            {selectedPlan !== "year" ? (
              <button type={"button"} onClick={openBillingConfirmModalHandler}>
                {CHANGE_TO_YEARLY}
              </button>
            ) : null}
          </div>
          <div className={"settings-billing-premium-item"}>
            <span>Invoice total</span>
            <p>${upcomingInvoices?.lines?.data[0]?.amount / 100}</p>
            <button type={"button"} onClick={openInvoiceModalHandler}>
              See all invoices
            </button>
          </div>
          <div className={"settings-billing-premium-item"}>
            <span>Invoice sent to</span>
            <div className={"settings-billing-premium-item-emails"}>
              {invoices.slice(0, 1).map((elem: any, index: number) => {
                return <p key={index}>{elem?.customer_email}</p>;
              })}
            </div>
          </div>
        </div>
      );
    }
  }, [subscriptions, upcomingInvoices, invoices, selectedPlan]);

  return (
    <div className={"settings-billing"}>
      {userData?.agency?.subscription_level === "partner" ? (
        <h3>{`You are on Premium Plan courtesy of ${userData.source?.name.toUpperCase()} MLS`}</h3>
      ) : (
        <h3>Subscription Plan</h3>
      )}
      {isNotPremiumUser ? FreePlanWrapper : PremiumPlanWrapper}
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={"upgrade_now_billing"}
      />
      <BillingConfirmationModal
        visible={billingConfirmModalVisible}
        setVisible={setBillingConfirmModalVisible}
        onRenewClick={onRenewClick}
        type={actionType}
      />
      <BillingAndInvoicesModal
        visible={invoicesModalVisible}
        setVisible={setInvoicesModalVisible}
        invoices={invoices}
      />
    </div>
  );
};

export default memo(BillingAndInvoices);
