import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { enumsSelector } from "../../../../../../store/enums/selectors";
import { CheckboxChangeEvent, CheckboxOptionType } from "antd/es/checkbox";
import { Checkbox } from "antd";
import { FormInstance } from "antd/es/form";
import "./PetsFilter.scss";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../../../helpers/listingsCreateGetSearchQuery";
import {petsEffect} from "../../../helpers/filterFormHelper";
interface IPetsFilter {
  setAllowedPets: any;
  form: FormInstance;
  isClearAllClicked:boolean;
}
const PetsFilter = ({ setAllowedPets, form, isClearAllClicked }: IPetsFilter) => {
  const enums = useSelector(enumsSelector);
  const [petsCheckboxOptions, setPetsCheckboxOptions] = useState<
    CheckboxOptionType[]
  >();
  const [checked, setChecked] = useState(false);
  const [petsArray, setPetsArray] = useState<string[]>([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  const handleChange = useCallback((checkedValues: any) => {
    setPetsArray(checkedValues);
    setIsBtnActive(false);
    form.submit();
  }, []);

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setAllowedPets(
        petsCheckboxOptions?.map((elem) => {
          return elem.value;
        })
      );
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
    form.submit();
  };

  useEffect(() => {
    petsEffect(enums, setPetsCheckboxOptions);
  }, [enums]);

  useEffect(() => {
    if (!checked) {
      setPetsArray([]);
      setAllowedPets([]);
    }
  }, [checked]);

  useEffect(() => {
    if (petsArray.length) {
      if (petsArray.length === 3) {
        setAllowedPets(petsArray);
        setPetsArray([]);
        setIsBtnActive(true);
      } else {
        setAllowedPets(petsArray);
      }
    }
  }, [petsArray]);

  useEffect(() => {
    if (queryObject.pets_allowed && queryObject.pets_allowed.length) {
      setChecked(true);
      if (queryObject.pets_allowed.length === 3) {
        setAllowedPets(
          petsCheckboxOptions?.map((elem) => {
            return elem.value;
          })
        );
        setIsBtnActive((prevState) => !prevState);
        setPetsArray([]);
      } else {
        setAllowedPets(queryObject.pets_allowed)
        setPetsArray(queryObject.pets_allowed);
      }
    }
  }, []);

  useEffect(()=>{
    if(isClearAllClicked){
      setPetsArray([])
      setChecked(false)
      setAllowedPets([])
    }
  },[isClearAllClicked])
  return (
    <div className={"pets-filter-wrapper"}>
      <Checkbox
        onChange={onCheckboxChange}
        checked={checked}
        className={"pets-filter-wrapper-checkbox"}
      >
        Pet Friendly
      </Checkbox>
      {checked && (
        <div className={"pets-filter-wrapper-options"}>
          <Checkbox.Group
            className={"pets-filter-wrapper-options-checkbox-group"}
            options={petsCheckboxOptions}
            value={petsArray}
            defaultValue={petsArray}
            onChange={handleChange}
          />
          <button
            type={"button"}
            className={`pets-filter-wrapper-options-anyBtn ${
              isBtnActive ? "pets-filter-wrapper-options-anyBtnChecked" : ""
            }`}
            onClick={() => {
              setAllowedPets(
                petsCheckboxOptions?.map((elem) => {
                  return elem.value;
                })
              );
              setIsBtnActive((prevState) => !prevState);
              setPetsArray([]);
              form.submit();
            }}
          >
            Any
          </button>
        </div>
      )}
    </div>
  );
};

export default PetsFilter;
