import React from "react";
import { getBedroomsOrBathroomsRadioBtnGroup } from "../../../../../../../../helpers/globalFunctions";
import { REQUIRED_WITH_MESSAGE } from "../../../../../../../../helpers/validations";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import "./NumberOfLivingPeople.scss";
import FormStepsTitle from "../../form-steps-title/FormStepsTitle";

const NumberOfLivingPeople = () => {
  const requirementEnums = useSelector(requirementEnumsSelector);
  const numberOfTenants = requirementEnums?.find((item: EnumInterface) => item?.key === "number_of_tenants");
  const employedTenants = requirementEnums?.find((item: EnumInterface) => item?.key === "employed_tenants");

  return (
    <div className={"req-number-of-people-section"}>
      <FormStepsTitle text={"How many total occupants will there be (including yourself)?"} />
      {getBedroomsOrBathroomsRadioBtnGroup(
        numberOfTenants?.key,
        "",
        !numberOfTenants?.allow_null ? [REQUIRED_WITH_MESSAGE("This Field ")] : [{ required: false }],
        "purple-radiobutton-group bedrooms-radio-buttons",
        "parent-labels-to-center",
        "people_living_with_you"
      )}
      <FormStepsTitle text={"How many people living with you are employed?"} />
      {getBedroomsOrBathroomsRadioBtnGroup(
        employedTenants?.key,
        "",
        !employedTenants?.allow_null ? [REQUIRED_WITH_MESSAGE("This Field ")] : [{ required: false }],
        "purple-radiobutton-group_bathrooms bathroom-radio-buttons",
        "parent-labels-to-center",
        "employed_people",
        "employed"
      )}
    </div>
  );
};

export default NumberOfLivingPeople;
