import React from "react";
import isNil from "lodash/isNil";
import { Collapse } from "antd";
import ListingHistoryOpenCollapseIcon from "../../../icons/listing-history-icons/ListingHistoryOpenCollapseIcon";
import ListingHistoryCloseCollapseIcon from "../../../icons/listing-history-icons/ListingHistoryCloseCollapseIcon";
import ListingHistoryPriceIcon from "../../../icons/listing-history-icons/ListingHistoryPriceIcon";
import { beautifiedKeyStyling} from "../../../../helpers/globalFunctions";
import './style.scss';
import ListingHistoryPanelEllipse from "../../../icons/listing-history-icons/ListingHistoryPanelEllipse";
import { dateToMoment, formatDate } from "helpers/dateHelper";

const { Panel } = Collapse;

interface IListingHistoryCollapseItems {
  historyData: any,
  keyProp: number,
}

const ListingHistoryCollapseItems = ({ historyData, keyProp }: IListingHistoryCollapseItems) => {
  const DATE_FIELDS = ['lease_ending_date', 'lease_start_date', 'availability_date'];

  const renderChangeValue = (key: string, value: any): any => {
    if (!value) return "not set";
    if (DATE_FIELDS.includes(key) && !isNil(value)) {
      return formatDate(dateToMoment(value));
    }
    if (value instanceof Object) {
      return Object.keys(value).map((key) => beautifiedKeyStyling(String(value[key]))).join(', ');
    }
    if (value instanceof Array) {
      return value.map((v) => beautifiedKeyStyling(String(v))).join(', ');
    }
    return beautifiedKeyStyling(String(value));
  };
  const checkPriceIconHandler = (element: any) => {
    if (element.from < element.to) return <ListingHistoryPriceIcon className={"grow-up"} />;
    else return <ListingHistoryPriceIcon className={"grow-down"} />;
  };
  return (
    <div key={keyProp} className={"history-wrapper"}>
      {historyData.map((history: any, idx: number) => {
        return (
          <div key={idx}>
            <Collapse
              defaultActiveKey={["1"]}
              className={"history-wrapper--baseCollapse"}
              expandIcon={({ isActive }) =>
                <div className={"history-wrapper--baseCollapse--expandIcon"}>
                  {isActive
                    ? <ListingHistoryCloseCollapseIcon />
                    : <ListingHistoryOpenCollapseIcon />}
                </div>}
            >
              <Panel
                header={
                  <span>
                    {`Changes on ${formatDate(dateToMoment(history.historyData[0].createdAt))}, by`}
                    {" "}
                    <span>{history?.agent?.name}</span>
                  </span>
                }
                key={"1"}
                className={"history-wrapper--baseCollapse--panel"}
              >
                <div className={"history-wrapper--baseCollapse--panel--wrapper"}>
                  <div
                    className={"history-wrapper--baseCollapse--panel--wrapper--leftLine"}
                  >
                    <ListingHistoryPanelEllipse />
                    <div>{" "}</div>
                    <ListingHistoryPanelEllipse />
                  </div>
                  <div className={"history-wrapper--baseCollapse--panel--wrapper--body"}>
                    {history.historyData.filter((element: any) => !['image'].includes(element.changed_field)).map((element: any, index: number) => {
                      return (
                        <div key={index} className={"history-wrapper--baseCollapse--panel--wrapper--body--item"}>
                          {element.change_type === "price"
                            ? <div className={"history-wrapper--baseCollapse--panel--wrapper--body--item--price"}>
                              <span>
                                Updated {beautifiedKeyStyling(element.changed_field)} to  ${element.to}{" "}
                                <span>
                                  Price change {" "}
                                </span>
                                {checkPriceIconHandler(element)}
                              </span>
                            </div>
                            :
                            <div className={"history-wrapper--baseCollapse--panel--wrapper--body--item--other"}>
                              Changed {element.change_type === "landlord" ? "Landlord" : null}{" "}
                              {beautifiedKeyStyling(element.changed_field)} from <span className="text-bold">{renderChangeValue(element.changed_field, element.from)}</span> to <span className="text-bold">{renderChangeValue(element.changed_field, element.to)}</span>
                            </div>
                          }
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

export default ListingHistoryCollapseItems;