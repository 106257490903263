import Section from "components/Shared/Section/Section";
import React from "react";

const ChooseBundling = ({ renterName }: { renterName: string }) => {
  const bundle_options = [
    {
      value: "Credit, Criminal & Eviction Reports",
      label: "Credit, Criminal & Eviction Reports",
      extra: "*Includes Applicant Identity Verification",
    },
    { value: "Criminal Background Check", label: "Criminal Background Check" },
    { value: "Eviction Related Proceedings", label: "Eviction Related Proceedings" },
  ];

  return (
    <div className="-mt-6">
      <p className="font-semibold text-base">
        {`${renterName} will get your screening request and it will cost them $36`}
      </p>
      <div>
        <h4 className="text-xl my-4">What's included in the bundle:</h4>
        {bundle_options.map((option, index) => {
          return (
            <div key={option.value} className="text-base mb-4">
              <p className="font-semibold">{`${index + 1}. ${option.value}`}</p>
              {option.extra && (
                <div className="ml-2 mb-2">
                  <p className="text-sm text-gray-600">{option.extra}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <p className="text-xs text-gray-500 mt-6">
        *The National Criminal Report is subject to federal, state and local laws that may limit or restrict ShareAble's
        ability to return some records. Criminal results are not available to return for Delaware, Hawaii, Kentucky,
        Massachusetts, New Jersey, South Dakota, Wyoming, and Cook County, Illinois. <br />
        ** Eviction related proceedings won't be generated for rentals in New York State or Miami-Dade County, FL.{" "}
        <br />
        *** reports by TransUnion
      </p>
    </div>
  );
};

export default ChooseBundling;
