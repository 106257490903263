import React, { useEffect, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { Input } from "antd";
import { AddressChangeParams, IPlacesAutocomplete } from "./types";
import { getAddressComponentByType } from "../../../helpers/globalFunctions";
import { address_fields } from "../../../helpers/constants";
import { IObjectKeys } from "../../../helpers/globalTypes";
import LocationIcon from "../filters/filter-modal/icons/LocationIcon";

const PlacesAutocomplete = ({
  defaultAddress,
  onAddressChange,
  resetPlacesAutocomplete,
  setResetPlacesAutocomplete,
  placeholder,
  type,
  suffix,
  form,
  isQuickActionSelected,
  setIsQuickActionSelected,
  showRegion,
  disableFreeForms,
}: IPlacesAutocomplete) => {
  const [autocompleteState, setAutocompleteState] = useState<any>(null);
  const [addressWithLocation, setAddressWithLocation] = useState<AddressChangeParams>({
    formatted_address: defaultAddress,
    separated_fields: {},
    location: {},
  });
  const [inputWasTouched, setInputWasTouched] = useState(false);
  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAddressWithLocation({ formatted_address: value, location: {} });
  };

  const onPlacesChanged = () => {
    // @ts-ignore
    const foundedPlace = autocompleteState?.getPlace();
    if (foundedPlace?.formatted_address) {
      const fieldsToSet: IObjectKeys = {};
      for (const [key, value] of Object.entries(address_fields)) {
        fieldsToSet[key] = getAddressComponentByType(
          foundedPlace,
          value,
          key.includes("short") ? "short_name" : "long_name"
        );
      }

      setAddressWithLocation({
        formatted_address: foundedPlace.formatted_address,
        separated_fields: fieldsToSet,
        location: {
          lng: foundedPlace.geometry.location.lng(),
          lat: foundedPlace.geometry.location.lat(),
        },
      });
    }
  };

  const onLoadSearch = (places: any) => {
    setAutocompleteState(places);
  };

  const returnAddressValue = () => {
    if (!inputWasTouched) {
      return addressWithLocation.formatted_address || defaultAddress;
    }
    return addressWithLocation.formatted_address;
  };

  const inputSearchHandler = (e: any) => {
    inputOnChange(e);
    if (!inputWasTouched) {
      setInputWasTouched(true);
    }
  };

  useEffect(() => {
    if (!defaultAddress) {
      if (type !== "not-looking-modal") {
        setAddressWithLocation({ formatted_address: "", location: {} });
      }
      setResetPlacesAutocomplete(false);
    }
  }, [defaultAddress]);

  useEffect(() => {
    if (disableFreeForms) {
      addressWithLocation.separated_fields?.state && onAddressChange(addressWithLocation);
    } else {
      onAddressChange(addressWithLocation);
    }
  }, [addressWithLocation]);

  useEffect(() => {
    if (resetPlacesAutocomplete) {
      setAddressWithLocation({ formatted_address: "", location: {} });
      setResetPlacesAutocomplete(false);
    }
  }, [resetPlacesAutocomplete]);

  return (
    <Autocomplete
      onLoad={onLoadSearch}
      onPlaceChanged={onPlacesChanged}
      types={[showRegion ? "(regions)" : "address"]}
      restrictions={{ country: "us" }}
    >
      <input
        data-test-id={"listing-address-input"}
        type="text"
        value={isQuickActionSelected ? defaultAddress : returnAddressValue()}
        placeholder={placeholder ?? "Enter Address"}
        autoComplete=""
        className={`block w-full shadow rounded-md border-0 py-1.5 px-4 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-base sm:text-lg sm:leading-6`}
        onKeyPress={() => {}}
        onBlur={(e) => {
          if (disableFreeForms && !addressWithLocation.separated_fields?.state) {
            setAddressWithLocation({
              formatted_address: defaultAddress,
              separated_fields: {},
              location: {},
            });
          }
          if (form) {
            setTimeout(() => {
              form.submit();
            }, 500);
          }
        }}
        onChange={(e) => {
          inputSearchHandler(e);
          setIsQuickActionSelected && setIsQuickActionSelected(false);
        }}
        onFocus={(e) => {
          e.target.setAttribute("autocomplete", "newpassword");
          setIsQuickActionSelected && setIsQuickActionSelected(false);
        }}
      />
    </Autocomplete>
  );
};

export default PlacesAutocomplete;
