import { IObjectKeys } from 'helpers/globalTypes';
import React from 'react';
import BaseCollapse from '../../../Shared/BaseCollapse';
import { getDateWithWords, beautifiedKeyStyling } from '../../../../helpers/globalFunctions';
import './styles.scss';
interface IOneNoteProps {
    note?: IObjectKeys
}

const OneNote = (props: IOneNoteProps) => {

    return (
        <BaseCollapse
            classname="one-note-collapse"
            collapse={true}
            header={
                <div className="one-note-collapse--header">
                    <h3 className="one-note-collapse--header--heading-3">
                        <span>
                            {beautifiedKeyStyling(props?.note?.title)}
                        </span>
                    </h3>
                    <span className="one-note-collapse-header--date">
                        {getDateWithWords(props.note?.created_at)}
                    </span>
                </div>
            }
        >
            <div className="one-note">
                {beautifiedKeyStyling(props?.note?.description ? props?.note?.description : "" )}
            </div>

        </BaseCollapse>
    );
}

export default OneNote;