import React, {useState} from "react";
import './BaseTooltip.scss'
import Triangle from "../../icons/triangle/Triangle";
interface IBaseWhiteTooltip {
    children: JSX.Element[] | JSX.Element
    text:string,
    classnames?:string
}
const BaseTooltip = (props:IBaseWhiteTooltip) =>{
    const [isTooltipVisible,setIsTooltipVisible] = useState(false)
    const handleHideTooltip = () =>{
        setIsTooltipVisible(false)
    }
    const handleShowTooltip = () =>{
        setIsTooltipVisible(true)
    }
    return(
        <div className={"base-tooltip-wrapper"}>
            <div
                className={"base-tooltip-wrapper--hoverComponent"}
                onMouseOver={handleShowTooltip}
                onMouseLeave={handleHideTooltip}
            >
                {props.children}
            </div>
            {
                isTooltipVisible ?
                    <div className={"base-tooltip-wrapper--tooltip"}>
                        <div className={"base-tooltip-wrapper--tooltip--body"}>
                            <span className={"base-tooltip-wrapper--tooltip--body--text"}>
                                {props.text}
                            </span>
                            <Triangle/>
                        </div>
                    </div>
            : null
            }
        </div>
    )
}

export default BaseTooltip
