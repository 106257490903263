import React from "react";

const Snooze = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66309 9.00151C1.66309 12.8591 4.80378 15.9998 8.66704 15.9998C12.5303 15.9998 15.671 12.8591 15.671 9.00151C15.671 5.13822 12.5303 1.99756 8.66704 1.99756C4.80378 1.99756 1.66309 5.13822 1.66309 9.00151ZM10.0667 10.9758C10.5219 10.9758 10.8917 11.3456 10.8917 11.8008C10.8917 12.256 10.5219 12.6258 10.0667 12.6258H7.26737C6.98289 12.6258 6.71549 12.4779 6.56186 12.2332C6.41393 11.9886 6.40257 11.687 6.52772 11.431L8.72962 7.02149H7.26737C6.81221 7.02149 6.4424 6.65169 6.4424 6.19649C6.4424 5.74132 6.81221 5.37152 7.26737 5.37152H10.0667C10.3512 5.37152 10.6186 5.51945 10.7722 5.7641C10.9201 6.00875 10.9315 6.31029 10.8063 6.56633L8.60446 10.9758H10.0667Z"
        fill="#5806B9"
      />
      <path
        d="M3.45896 0.113649C2.21417 0.84062 1.14792 1.81551 0.28868 3.01136C0.0230193 3.38183 0.107436 3.89804 0.47794 4.16419C0.623652 4.26851 0.79194 4.31903 0.959187 4.31903C1.2162 4.31903 1.46946 4.19968 1.63077 3.97492C2.35347 2.96885 3.24873 2.1494 4.29241 1.53964C4.68603 1.3095 4.81882 0.804053 4.58869 0.409937C4.35856 0.015269 3.85204 -0.117561 3.45896 0.113649Z"
        fill="#5806B9"
      />
      <path
        d="M15.7066 3.97492C15.8684 4.19968 16.1212 4.31903 16.3782 4.31903C16.5454 4.31903 16.7137 4.26851 16.8594 4.16419C17.2299 3.89804 17.3144 3.38183 17.0487 3.01136C16.1895 1.81551 15.1232 0.84062 13.8784 0.113649C13.4848 -0.117561 12.9783 0.015269 12.7487 0.409937C12.5185 0.804053 12.6513 1.3095 13.045 1.53963C14.0886 2.1494 14.9839 2.96885 15.7066 3.97492Z"
        fill="#5806B9"
      />
    </svg>
  );
};

export default Snooze;
