import React from "react";
import SuccessIcon from "../icons/SuccessIcon";
import { AddToGoogleCalendar } from "../../AddToCalendar";
import "./FollowUpAddedSuccessModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { addedFollowUpSelector } from "../../../../store/followUps/selectors";
import moment from "moment";
import { userSelector } from "../../../../store/user/selectors";
import NoticeIcon from "../../all-follow-ups-modal/components/follow-up-modal-single-item/icons/NoticeIcon";
import { getPhoneVerificationCode, updateUserSettings } from "../../../../store/user/action";
import { dashboardFollowUpsSelector } from "../../../../store/dashboard/selectors";
import { addTimeAndFormatDate, formatDate } from "../../../../helpers/dateHelper";
import { ISO_STRING } from "../../../../helpers/constants";
import BaseButton from "components/Shared/BaseButton";
interface IFollowUpAddedSuccessModal {
  setPhoneVerifyModalVisible: any;
  setFollowUpSuccessed?: any;
  setModalProps?: any;
  initialModalValue?: any;
  isFromDashboard?: boolean;
}
const FollowUpAddedSuccessModal = ({
  setPhoneVerifyModalVisible,
  setFollowUpSuccessed,
  setModalProps,
  initialModalValue,
  isFromDashboard,
}: IFollowUpAddedSuccessModal) => {
  const addedFollowUpSelectorVar = useSelector(addedFollowUpSelector);
  const dashboardFollowUpsSelectorVar = useSelector(dashboardFollowUpsSelector)?.followUps[0];
  const followUp = !isFromDashboard ? addedFollowUpSelectorVar : dashboardFollowUpsSelectorVar;
  const _event = {
    title: followUp.title || "",
    description: followUp.description || "",
    startTime: followUp.todo_at,
    location: "",
    endTime: addTimeAndFormatDate(followUp.todo_at, { amount: 1, unit: "hour" }, undefined, ISO_STRING),
  };
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const verifyPhoneView = () => {
    return (
      <div className={"snooze-follow-up-container"}>
        <div className={"flex justify-start items-top gap-2 snooze-follow-up-notice"}>
          <div className="pt-2">
            <NoticeIcon />
          </div>
          <div className="text-red-500">
            Please verify your phone number in order to receive a text notification when a follow-up is due.
          </div>
        </div>

        <BaseButton
          className={"mt-4 snooze-follow-up-success--phoneVerifySection"}
          variant="primary"
          onClick={() => {
            setPhoneVerifyModalVisible(true);
            // setAllFollowUpsModalVisible(false);
            setModalProps(initialModalValue);
            setFollowUpSuccessed(false);
            user.phone && dispatch(getPhoneVerificationCode());
          }}
        >
          Verify now
        </BaseButton>
      </div>
    );
  };

  const undoSMSActivation = () => {
    return (
      <div className={"snooze-follow-up-success--phoneVerifySection--verified"}>
        <p className={"undo-snooze-verify"}>
          Text messages are activated!{" "}
          <span
            onClick={() => {
              dispatch(
                updateUserSettings({
                  address: user.address,
                  defaultCity: user.defaultCity,
                  defaultState: user.defaultState,
                  email: user.email,
                  emailNotifications: user.emailNotifications,
                  name: user.name,
                  phone: user.phone,
                  _id: user._id,
                  smsNotifications: false,
                })
              );
            }}
          >
            undo
          </span>
        </p>
      </div>
    );
  };

  const activateSMS = () => {
    return (
      <div className={"snooze-follow-up-success--phoneVerifySection--verified"}>
        <div className={"snooze-follow-up-success--phoneVerifySection--notVerified"}>
          <p className={"snooze-follow-up-success--phoneVerifySection--notVerified"}>
            <span>
              <strong>Pro tip:</strong> Turn on text notifications to be reminded when a follow-up is due.
            </span>
          </p>
          <p
            className={"snooze-follow-up-success--phoneVerifySection--notVerified--btn"}
            onClick={() => {
              dispatch(
                updateUserSettings({
                  address: user.address,
                  defaultCity: user.defaultCity,
                  defaultState: user.defaultState,
                  email: user.email,
                  emailNotifications: user.emailNotifications,
                  name: user.name,
                  phone: user.phone,
                  _id: user._id,
                  smsNotifications: true,
                })
              );
            }}
          >
            Activate Text Messages
          </p>
        </div>
      </div>
    );
  };

  const showTextByPhoneSettingStatus = () => {
    if (!user.phone_verified) {
      return verifyPhoneView();
    }

    if (user.smsNotifications) {
      return undoSMSActivation();
    }

    return activateSMS();
  };
  return (
    <div className={"add-follow-up-success-section flex flex-col justify-start gap-2"}>
      <div className="flex justify-center">
        <SuccessIcon />
      </div>
      <h3>Follow-up Saved!</h3>
      <p>A reminder will be sent via email on:</p>
      <p>
        <strong>{formatDate(followUp.todo_at)}</strong> at <strong>{formatDate(followUp.todo_at, "hh:mm A")}</strong>
      </p>
      <div className={"snooze-follow-up-success--phoneVerifySection mb-4"}>{showTextByPhoneSettingStatus()}</div>
      <AddToGoogleCalendar event={_event} />
    </div>
  );
};

export default FollowUpAddedSuccessModal;
