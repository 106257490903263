import { useScreeningReport } from "hooks/useScreeningReport";
import React, { useEffect } from "react";
import BaseModal from "../BaseModal";
import SRReportTabs from "./ReportTabs";

interface IReportModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  srrId: string;
  refreshData?: any;
}

const ReportModal = ({ visible, setVisible, srrId }: IReportModalProps) => {
  const { loading, handleTabChange, handlePrintReport, getSRReport } = useScreeningReport({
    srrId,
  });

  useEffect(() => {
    if (srrId) {
      getSRReport();
    }
  }, [srrId]);

  return (
    <BaseModal
      isModalVisible={visible}
      modalTitle="View Screening Reports"
      onOk={handlePrintReport}
      onCancel={() => setVisible(false)}
      okText="Print"
      cancelText="Close"
      width={827}
      centered
      removeBodyStyle
      cancelButtonProps={{
        className:
          "p-3 px-5 text-base rounded-md hover:bg-purple-100 ring-1 ring-inset ring-purple-400 hover:text-purple-800 text-purple-900 h-12",
      }}
      okButtonProps={{
        className:
          "p-3 px-5 h-12 text-base rounded-md hover:text-purple-50 text-white bg-purple-700 focus:bg-purple-600 active:bg-purple-800",
      }}
    >
      <SRReportTabs
        loading={loading}
        showActions={false}
        handleTabChange={handleTabChange}
        handlePrintReport={handlePrintReport}
      />
    </BaseModal>
  );
};

export default ReportModal;
