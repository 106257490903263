import React, { useEffect } from "react";
import BaseModal from "../Shared/BaseModal";
import { useWindowSize } from "../../hooks/useWindowSize";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import BaseButton from "components/Shared/BaseButton";
import { updateUserAfterSwitch } from "store/user/action";
import { updatedUserLoadingSelector, userSelector, userUpdateSuccessSelector } from "store/user/selectors";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

interface ISwitchTeamModal {
  visible: boolean;
  onCancel: () => void;
  newTeamName: string | undefined;
  newAgencyId: string | undefined;
}

const ConfirmSwitchTeamModal = ({ visible, onCancel, newTeamName, newAgencyId }: ISwitchTeamModal) => {
  const userData = useSelector(userSelector);
  const updatedUserLoading = useSelector(updatedUserLoadingSelector);
  const userUpdateSuccess = useSelector(userUpdateSuccessSelector);
  const { width }: any = useWindowSize();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userUpdateSuccess === true) {
      onCancel();
      history.replace(location.pathname);
    }
  }, [userUpdateSuccess]);

  const cleanLocalStorage = () => {
    localStorage.removeItem("switchTeamSearchParams");
    localStorage.removeItem("switchTeamSnoozed");
  };

  const handleConfirmClick = () => {
    cleanLocalStorage();
    dispatch(
      updateUserAfterSwitch({
        id: userData._id,
        email: userData.email,
        groupOfAgent: newAgencyId,
      })
    );
  };

  const handleDecline = () => {
    onCancel();
    cleanLocalStorage();
    history.replace(location.pathname);
  };

  return (
    <BaseModal
      classname={"switch-team-modal"}
      isModalVisible={visible}
      onCancel={onCancel}
      modalTitle={null}
      modalFooter={null}
      width={width < 600 ? 299 : 561}
    >
      <div className={"premium-upgrade-status-modal-body"}>
        <h2 className="text-2xl font-bold mb-4">Join your new team</h2>
        <p className="text-xl font-semibold my-4">{`You've been invited to join ${newTeamName}.`}</p>
        <p className="text-base">
          When joining your new team, all of your assets, listings, leads and landlords will be associated with the new
          team.
        </p>
        <p className="text-lg my-4">{`Please confirm if you wish to join ${newTeamName}`}</p>
        <div className="text-center gap-2">
          <BaseButton
            className="mb-4"
            onClick={handleConfirmClick}
            loading={updatedUserLoading}
            disabled={updatedUserLoading}
          >
            <span>Confirm</span>
          </BaseButton>
          <a className={"premium-upgrade-status-modal-body-schedule"} onClick={handleDecline}>
            Decline
          </a>
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfirmSwitchTeamModal;
