import React from "react";

interface IPropsTextOutput {
  field: { format: any; key: string; inputFormat: any; prefix?: string; suffix?: string; label?: string };
  value: any;
  label?: string;
}

const TextOutput = ({ field, value, label }: IPropsTextOutput) => {
  return (
    <>
      <div>
        <label className="block text-base font-medium ">{label || field.label}</label>
        <div className="mt-2 ">{value ? field.format(value) : "--"}</div>
      </div>
    </>
  );
};

export default TextOutput;
