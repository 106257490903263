import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../store/user/selectors";
import ManageYourTeam from "./ManageYourTeam";
import { getLoggedInUser } from "../../store/user/action";
import "./styles.scss";
import EmptyPageAddTeamMember from "../Empty-page-wrapper/components/empty-page-add-team-member/EmptyPageAddTeamMember";
import EmptyPageLayout from "../../layouts/empty/EmptyPageLayout";
import {useLocation} from "react-router";

const TeamMember = () => {
  const location = useLocation()
  const userData = useSelector(userSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoggedInUser());
  }, [dispatch]);


  return userData?.agency?.agent_ids?.length <= 1 &&
    userData?.agent_id === userData?.agency?.agent_ids[0] || location.search.includes("fab=true")? (
    <EmptyPageLayout>
       <div className={"create-team-page"}>
            <h2>Create a Team for <span>{userData.agency?.agents_group_name || "-"}</span></h2>
       </div>
      <EmptyPageAddTeamMember />
    </EmptyPageLayout>
  ) : (
    <ManageYourTeam />
  );
};

export default TeamMember;
