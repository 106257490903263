import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_AREAS,
} from './types';
import AreasApi from 'api/Areas';
import { getAreasFail, getAreasSuccess, getAreasFacetsSuccess, getAreasFacets, getAreasFacetsFail } from './action';

function* getAreasSaga({ payload }: any) {
  try {
    const response = yield call(
      AreasApi.getAreas, payload
    );
    if (payload.fromPage !== "requirements") {
      let facetResponse = undefined;
      if (payload.fromPage === "leads") {
        payload.myLeads = true;
        facetResponse = yield call(
          AreasApi.getFacetAreasForLeads, payload
        );
        yield put(getAreasFacets());
        yield put(getAreasFacetsSuccess(facetResponse?.facets ?? {}));
      } else {
        delete payload.fromPage;
        facetResponse = yield call(
          AreasApi.getFacetAreas, payload
        );
        yield put(getAreasFacets());
        yield put(getAreasFacetsSuccess(facetResponse?.facets ?? {}));
      }
    }
    yield put(getAreasSuccess(response.areas));

  } catch (e) {
    yield put(getAreasFail(e));
    yield put(getAreasFacetsFail(e));
  }
}

function* watchGetAreas() {
  yield takeLatest(GET_AREAS, getAreasSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetAreas)]);
}
