import React from "react";
import BaseCollapse from "components/Shared/BaseCollapse";
import { Card, Row, Space } from "antd";
import Col from "antd/es/grid/col";
import { SettingsEmailIcon, SettingsSmsIcon } from "components/icons";
import { useDispatch, useSelector } from "react-redux";
import { getPhoneVerificationCode } from "store/user/action";
import Form from "antd/es/form";
import { userSelector } from "store/user/selectors";
import { IObjectKeys } from "helpers/globalTypes";
import BaseSwitchButton from "components/Shared/BaseSwitchButton";
import Section from "components/Shared/Section/Section";
import BaseButton from "components/Shared/BaseButton";

interface IDailyRecapsProps {
  remindersCollapse: boolean;
  handleChange: (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => void;
  switchInputData: IObjectKeys;
}

const DailyRecaps = ({ remindersCollapse, handleChange, switchInputData }: IDailyRecapsProps) => {
  const userData = useSelector(userSelector);
  return (
    <>
      <h3 className="text-base">Receive Daily Follow-up Recaps</h3>
      <h4 className="text-sm">Where would you like to receive these notifications?</h4>
      <div>
        <div>
          <div className="flex flex-wrap justify-between items-center">
            <div className="flex justify-start items-center gap-2">
              <SettingsSmsIcon />
              <span>SMS</span>
              {!userData.phone_verified && (
                <span className={"please-verify text-red-500 text-sm"} data-test-id="verify-phone-number-text">
                  Verify your number above
                </span>
              )}
            </div>
            <div>
              <div data-test-id={"smsNotifications"}>
                <BaseSwitchButton
                  name="smsNotifications"
                  onChange={handleChange}
                  checked={switchInputData["smsNotifications"]}
                  disabled={!userData.phone_verified}
                  dataTestId="sms-note-btn"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-between items-center">
            <div>
              <Space direction={"horizontal"} size={10}>
                <SettingsEmailIcon />
                <span>Email</span>
              </Space>
            </div>
            <div>
              <div data-test-id={"emailNotifications"}>
                <BaseSwitchButton
                  name="emailNotifications"
                  onChange={handleChange}
                  checked={switchInputData["emailNotifications"]}
                  dataTestId="email-note-btn"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyRecaps;
