import React from "react";
import Lottie from "react-lottie";
import animationData from "assets/images/loadtingLottie.json";

const GlobalLoading = (
  { isStopped }:
    { isStopped?: boolean; }
) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      progressiveLoad: true,
    },
  };
  return (
    <div className={`global-loading`}>
      <Lottie
        options={defaultOptions}
        isStopped={isStopped}
        width={250}
        height={250}
      />
    </div>
  );
};

export default GlobalLoading;
