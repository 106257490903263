import * as types from "./types";

export function getAgentSRRequests(payload: {
  renterId: string;
  agentId: string;
}) {
  return {
    type: types.GET_AGENTS_SCREENING_REQUEST,
    payload,
  };
}

export function getAgentSrRequestSuccess(reqData: object) {
  return {
    type: types.GET_AGENTS_SCREENING_REQUEST_SUCCESS,
    payload: reqData,
  };
}

export function getAgentSrRequestFail(error: any) {
  return {
    type: types.GET_AGENTS_SCREENING_REQUEST_FAIL,
    payload: error,
  };
}

export function setAgentsScreeningRequestQueryParams(payload: object) {
  return {
    type: types.SET_AGENTS_SCREENING_REQS_QUERY_PARAMS,
    payload,
  };
}

export function getAgentAllSrRequest(id: string) {
  return {
    type: types.GET_TENANT_SCREENING_REQUESTS,
    payload: id,
  };
}

export function getAgentAllSrRequestSuccess(reqData: object) {
  return {
    type: types.GET_TENANT_SCREENING_REQUESTS_SUCCESS,
    payload: reqData,
  };
}

export function getAgentAllSrRequestFail(error: any) {
  return {
    type: types.GET_TENANT_SCREENING_REQUESTS_FAIL,
    payload: error,
  };
}

export function setAgentsAllScreeningRequestQueryParams(payload: object) {
  return {
    type: types.SET_AGENTS_ALL_SCREENING_REQS_QUERY_PARAMS,
    payload,
  };
}
