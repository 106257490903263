import React, { useEffect, useState } from "react";
import "./styles.scss";
import LandlordNotes from "./LandlordNotes";
import LandlordActions from "./LandlordActions";
import Listings from "../Listings";
import BackToPreviousSearchButton from "./BackToPreviousSearch";
import ContactModal from "../../components/Shared/ContactModal/ContactModal";
import { useDispatch, useSelector } from "react-redux";
import { landlordNotesSelector, singleLandlordDataSelector } from "../../store/landlords/selectors";
import { userSelector } from "store/user/selectors";
// import LandlordContacts from "./LandlordContacts/LandlordContacts";
import LandlordNoteModal from "./LandlordNoteModal";
// import LandlordPageBodyMobile from "./landlord-page-body-mobile";
import MoreDropdownMobile from "./landlord-page-body-mobile/components/more-dropdown-mobile/MoreDropdownMobile";
import { useForm } from "antd/es/form/Form";
import AfterCallModal from "../Shared/AfterCallModal/AfterCallModal";
import AddFollowUpModal from "../FollowUps/add-follow-up-modal/AddFollowUpModal";
import PhoneVerifyModalFollowUps from "../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import AddFollowUpModalMobile from "../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import { useWindowSize } from "../../hooks/useWindowSize";
import AllListingNotesModal from "../ListingView/all-listing-notes-modal/AllListingNotesModal";
import { ListingNote } from "../Listings/helpers/mockListings";
import moment from "moment";
import { deleteListingNote } from "../../store/listings/action";
import NoteDeleteConfirmModal from "../Shared/NoteDeleteConfirmModal";
import { Document } from "@contentful/rich-text-types";
import { getLandlordNotes } from "../../store/landlords/action";
import { IObjectKeys } from "../../helpers/globalTypes";
import { getDeleteNoteConfirmContent } from "../../contentful/api";
import { useParams } from "react-router-dom";
import { beautifyLinkToText } from "../../helpers/globalFunctions";
import ActivateSection from "components/Shared/ActivateSection";
import { useDemoContext } from "libs/contextLib";
import PremiumUpgradeModal from "../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import Section from "components/Shared/Section/Section";

const confirmDeleteValue = { visible: false, noteId: "" };

const Landlord: React.FC = () => {
  const dispatch = useDispatch();
  const { landlordId } = useParams();
  const initialModalValue = { visible: false, editModal: false };
  const user = useSelector(userSelector);
  const currentLandlord = useSelector(singleLandlordDataSelector);
  const { width } = useWindowSize();
  const [landlordActionModalVisible, setLandlordActionModalVisible] = useState(false);
  const [form] = useForm();
  const [modalProps, setModalProps] = useState(initialModalValue);
  const [afterCallModalProps, setAfterCallModalProps] = useState(false);
  const [followUpModalProps, setFollowUpsModalProps] = useState(initialModalValue);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const [seeAllNotesModalVisible, setSeeAllNotesModalVisible] = useState(false);
  const landlordNotes = useSelector(landlordNotesSelector);
  const [selectedNote, setSelectedNote] = useState({});
  const { isDemo } = useDemoContext();
  const [deleteConfirmContent, setDeleteConfirmContent] = useState<Document | undefined>(undefined);
  const [deleteConfirmProps, setDeleteConfirmProps] = useState(confirmDeleteValue);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const [action, setAction] = useState("");

  useEffect(() => {
    dispatch(getLandlordNotes(`${landlordId}`));
    (async () => {
      const entries: IObjectKeys = await getDeleteNoteConfirmContent();
      if (entries) {
        setDeleteConfirmContent(entries.fields.modalDescription);
      }
    })();
  }, [landlordId]);

  const closeAfterCallModalHandler = () => {
    setAfterCallModalProps(false);
  };

  const handleOpenAddNoteModal = () => {
    setModalProps({ visible: true, editModal: false });
    form.resetFields();
  };

  const handleOpenNoteModal = (note: ListingNote) => {
    const todo_at = note.todo_at ? moment(note.todo_at) : null;
    setModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at,
    });
  };

  const canManageLandlord = (currentLandlord: any, user: any) => user.agent_id === currentLandlord.created_by;

  useEffect(() => {
    localStorage.setItem("current_page", "landlord page");
    localStorage.setItem("listingViewType", "grid");
    return () => {
      localStorage.setItem("current_page", "null");
    };
  }, []);

  useEffect(() => {
    if (!afterCallModalProps) localStorage.removeItem("contact_email_selected");
  }, [afterCallModalProps]);

  return (
    <>
      <div className={"landlords-individual-container"}>
        <div>
          <div className="mb-10">
            <BackToPreviousSearchButton
              backText={`Back to ${beautifyLinkToText(localStorage.getItem("textForBackTo") || "")}`}
            />
            {
              //@ts-ignore
              width < 767 ? (
                <MoreDropdownMobile
                  canManageLandlord={canManageLandlord(currentLandlord, user)}
                  modalProps={modalProps}
                  setModalProps={setModalProps}
                />
              ) : null
            }
          </div>
        </div>
        {isDemo && <ActivateSection type="landlord" className="w-100 mb-20 mt-0 mobile-activate-modal" />}
        <LandlordActions canManageLandlord={canManageLandlord(currentLandlord, user)} />
      </div>
      <div>
        <Section shadow>
          <LandlordNotes />
        </Section>
        <Section shadow>
          <Listings heading="Landlord Listings" />
        </Section>
      </div>

      <ContactModal
        setAfterCallModalVisible={setAfterCallModalProps}
        setModalProps={setModalProps}
        currentLandlordData={currentLandlord}
        showModal={landlordActionModalVisible}
        onClose={setLandlordActionModalVisible}
        setNoteModalVisible={setSeeAllNotesModalVisible}
        setAction={setAction}
      />

      {modalProps.visible && (
        <LandlordNoteModal
          modalProps={modalProps}
          setModalProps={setModalProps}
          landlordInfo={currentLandlord}
          landlord_id={currentLandlord.landlord_id}
          initialModalValue={initialModalValue}
          form={form}
          defaultTitle={action + " landlord"}
        />
      )}

      <AfterCallModal
        visible={afterCallModalProps}
        onClose={closeAfterCallModalHandler}
        key={1}
        setFollowUpModalVisible={setFollowUpsModalProps}
        setContactModalVisible={setAfterCallModalProps}
        setNoteModalVisible={setModalProps}
        setPremiumModalVisible={setPremiumModalVisible}
      />
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={currentLandlord}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={action + " landlord"}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={currentLandlord}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={action + " landlord"}
          />
        )
      }
      <AllListingNotesModal
        visible={seeAllNotesModalVisible}
        setVisible={setSeeAllNotesModalVisible}
        handleOpenAddNoteModal={handleOpenAddNoteModal}
        notes={landlordNotes}
        handleOpenNoteModal={handleOpenNoteModal}
        setDeleteConfirmProps={setDeleteConfirmProps}
        selectedNote={selectedNote}
        setSelectedNote={setSelectedNote}
        setContactModalVisible={setLandlordActionModalVisible}
      />
      <NoteDeleteConfirmModal
        deleteConfirmProps={deleteConfirmProps}
        setDeleteConfirmProps={setDeleteConfirmProps}
        modalContent={deleteConfirmContent}
        onOkayClick={() => {
          dispatch(deleteListingNote(deleteConfirmProps.noteId || ""));
        }}
      />
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={() => setPhoneVerifyModalVisible(false)} />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_AFTER_CALL_MODAL}
        id={currentLandlord.landlord_id}
      />
    </>
  );
};

export default Landlord;
