import { useContext, createContext, Context } from 'react';

export const AppContext: Context<any> = createContext(null);
export function useAppContext() {
  return useContext(AppContext);
}

export const DemoContext: Context<any> = createContext(null);
export function useDemoContext() {
  return useContext(DemoContext);
}

export const BannerContext: Context<any> = createContext(null);
export function useBannerContext() {
  return useContext(BannerContext);
}

// TODO: maybe use the context to fetch and set the passport?
export const RenterPortalContext: Context<any> = createContext(null);
export function useRenterPortalContext() {
  return useContext(RenterPortalContext);
}
