import Api from "./api";
import { IObjectKeys } from "../helpers/globalTypes";
import { ListingNote } from "../components/Listings/helpers/mockListings";
import axios from "axios";
import { getAuthToken } from "../helpers/authUtils";

export default class LandlordApi {
  static getLandlord(data: IObjectKeys) {
    const api = new Api("landlord");
    const { _id } = data.payload;
    return api.get(`${_id}`, {});
  }

  static makeLandlordPrivate(landlordData: IObjectKeys) {
    const api = new Api("landlord/toggleprivacy");
    return api.put(landlordData.landlord_id || "", {
      data: { is_private: landlordData.is_private },
    });
  }

  static getListingsOfLandlord(params: IObjectKeys) {
    const api = new Api("listing/portal");
    return api.create({ data: params });
  }

  static addLandlordNote(listingNoteData: ListingNote) {
    const api = new Api("notes");
    return api.create({ data: listingNoteData });
  }

  static updateLandlordNote(landlordNoteData: ListingNote) {
    const api = new Api("notes");
    return api.put(landlordNoteData._id || "", { data: landlordNoteData });
  }

  static deleteLandlordNote(noteId: string) {
    const api = new Api("notes");
    return api.destroy(noteId);
  }
  static getLandlordNotes(landlordId: string) {
    const api = new Api("notes/allReference");
    return api.get(landlordId, {});
  }

  static getLandlordFollowUps(landlordId: string) {
    const api = new Api("followUp/allReference");
    return api.get(landlordId, {});
  }

  static sendLandlordIndoBySms(landlordId: string) {
    const api = new Api("landlord/get_landlord_data_by_sms");
    return api.put(landlordId, {});
  }

  static async checkExistingLandlord(data: any) {
    const api = new Api("landlord/isExists");
    return await api.create({ data: data }).then((resp) => resp);
  }

  static updateLandlordTags(data: { tags: Array<string>, landlord_id: string; }) {
    const api = new Api("landlord-tag");
    return api.put("", { data });
  }
}
