import { RootStateOrAny } from "react-redux";

export const termsAndPrivacyLoadingSelector = (state: RootStateOrAny) =>
  state.termsAndPolicyReducer.loading;

export const privacyTitleSelector = (state: RootStateOrAny) =>
  state.termsAndPolicyReducer.title;

export const privacyMetaTitleSelector = (state: RootStateOrAny) =>
  state.termsAndPolicyReducer.metaTitle;

export const privacyMetaDescriptionSelector = (state: RootStateOrAny) =>
  state.termsAndPolicyReducer.metaDescription;

export const privacyMainContentSelector = (state: RootStateOrAny) =>
  state.termsAndPolicyReducer.mainContent;


export const termsTitleSelector = (state: RootStateOrAny) =>
  state.termsAndPolicyReducer.termsTitle;

export const termsMetaTitleSelector = (state: RootStateOrAny) =>
  state.termsAndPolicyReducer.termsMetaTitle;

export const termsMetaDescriptionSelector = (state: RootStateOrAny) =>
  state.termsAndPolicyReducer.termsMetaDescription;

export const termsMainContentSelector = (state: RootStateOrAny) =>
  state.termsAndPolicyReducer.termsMainContent;
