import React, { memo, useEffect, useState } from "react";
import { EnumInterface, IObjectKeys } from "../../../../../../../helpers/globalTypes";
import { useDispatch, useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../store/enums/selectors";
import SwitchButton from "../../../../../../ListingView/ListingForm/SwitchButton";
import { editRequirementFormPetsInfo, fillRequirementFormData } from "../../../../../../../store/requirement/action";
import PetsCollapse from "./pets-collapse";
import { v4 as uuidv4 } from "uuid";
import { requirementFormDataSelector, requirementFormEdittingSelector } from "../../../../../../../store/requirement/selectors";
import { singleLeadDataSelector } from "store/leads/selectors";
import "./PetsInfo.scss";
import PetEmptyStateIcon from "../../icons/pets-icons/PetEmptyStateIcon";
import FormStepsTitle from "../form-steps-title/FormStepsTitle";

export interface IpetsListItem {
  id: string;
  species: "dog" | "cat";
  amount: number;
  breed?: string;
}
interface IPetsInfoProps {
  setPetsListForParent: React.Dispatch<React.SetStateAction<IpetsListItem[]>>;
}

const PetsInfo = ({ setPetsListForParent }: IPetsInfoProps) => {
  const requirementFormData = useSelector(requirementFormDataSelector);
  const [petsList, setPetsList] = useState<Array<IpetsListItem>>([]);
  const dispatch = useDispatch();
  const [switchInputData, setSwitchInputData] = useState<IObjectKeys>({});
  const requirementEnums = useSelector(requirementEnumsSelector);
  const editedData = useSelector(requirementFormEdittingSelector);
  const { requirement } = useSelector(singleLeadDataSelector);
  const pets = requirementEnums?.find((item: EnumInterface) => item?.key === "pets");

  const handleChange = (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => {
    e.persist();
    const { checked } = e.target;
    if (checked) {
      setPetsList([]);
    } else {
      setPetsList([{ species: "dog", amount: 1, id: uuidv4() }]);
    }

    setSwitchInputData((state) => ({
      ...state,
      [e.target.name]: !checked,
    }));
    dispatch(
      fillRequirementFormData({
        [pets?.key]: !checked,
      })
    );
  };

  useEffect(() => {
    if (petsList.length === 0) {
      setSwitchInputData((state) => ({
        ...state,
        [pets?.key]: false,
      }));
      dispatch(
        fillRequirementFormData({
          [pets?.key]: false,
        })
      );
    }
    dispatch(editRequirementFormPetsInfo({ name: "pets", value: petsList }));
  }, [petsList]);
  useEffect(() => {
    if (editedData.pets) {
      if (editedData.pets.length > 0) {
        setSwitchInputData((state) => ({
          ...state,
          [pets.key]: true,
        }));
        setPetsList([...editedData.pets]);
      }
    }
  }, []);
  useEffect(() => {
    // if (isSubmitForm)
    setPetsListForParent(petsList);
  }, []);
  useEffect(() => {
    if (requirementFormData?.pets?.length) {
      setSwitchInputData((state) => ({
        pets: true,
      }));
      const petsArray = requirementFormData.pets.map((pet: IObjectKeys) => ({
        ...pet,
        id: uuidv4(),
      }));
      setPetsList(petsArray);
    }
  }, [requirement, requirementFormData.pets]);

  useEffect(() => {
    setPetsListForParent(petsList);
  }, [petsList]);

  return (
    <div className={"req-pets-wrapper pb-6"}>
      <div className={"req-pets-wrapper-switch"}>
        <FormStepsTitle text={"Will there be any pets in your household?"} />
        <SwitchButton required={true} name={pets?.key} label={""} onChange={handleChange} checked={switchInputData[pets?.key]} />
      </div>
      {switchInputData?.pets ? <PetsCollapse petsList={petsList} setPetsList={setPetsList} /> : null}
    </div>
  );
};

export default memo(PetsInfo);
