import React, { useEffect, useState } from "react";
import BaseModal from "../../Shared/BaseModal";
import { Form, Input, Radio } from "antd";
import { addFollowUp, editFollowUp, followUpAddedAndModalClosed } from "../../../store/followUps/action";
import { REQUIRED_TITLE_VALIDATION } from "../../../helpers/validations";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { listingNotesLoadingSelector } from "../../../store/listings/selectors";
import { userSelector } from "../../../store/user/selectors";
import "./AddFollowUpModal.scss";
import { CalendarIcon } from "../../icons";
import AddFollowUpModalDatePicker from "./date-picker-section/AddFollowUpModalDatePicker";
import BaseButton from "../../Shared/BaseButton";
import { addFollowUpSuccessSelector } from "../../../store/followUps/selectors";
import FollowUpAddedSuccessModal from "./success-section/FollowUpAddedSuccessModal";
import GoBackArrow from "./icons/GoBackArrow";
import { getBrowser, getBrowserDateFormat } from "../../../helpers/globalFunctions";
import { addDashboardFollowUp, editDashboardFollowUp } from "../../../store/dashboard/action";
import { push } from "connected-react-router";
import { useLocation } from "react-router";
import { dashboardFuAddedSuccessSelector } from "../../../store/dashboard/selectors";
import { updateLeadTags } from "store/leads/action";
import { singleLeadDataSelector } from "store/leads/selectors";
import { dateToMoment, formatDate } from "../../../helpers/dateHelper";
const initialModalValue = { visible: false, editModal: false };
const { TextArea } = Input;

const AddFollowUpModal = ({
  visible,
  setModalProps,
  form,
  listing,
  modalProps,
  landlordInfo,
  isDescShown,
  isFollowUpEditable,
  setPhoneVerifyModalVisible,
  lead,
  isFromDashboard,
  isSingle,
  defaultTitle,
  fromScoring,
}: any) => {
  const listingNotesLoading = useSelector(listingNotesLoadingSelector);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [loading, setLoading] = useState(false);
  const [isDescriptionAdded, setIsDescriptionAdded] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [isDatePickerDateSelected, setIsDatePickerDateSelected] = useState(true);
  const [datePickerData, setDatePickerData] = useState("");
  const addFollowUpSuccess = useSelector(addFollowUpSuccessSelector);
  const addDashboardFollowUpSuccess = useSelector(dashboardFuAddedSuccessSelector);
  const [followUpSuccessed, setFollowUpSuccessed] = useState(false);
  const [datePickerDateSaved, setDatePickerDateSaved] = useState(false);
  const dateFormat = getBrowser();
  const location = useLocation();
  const [timeData, setTimeData] = useState("08:00");
  const leadData = useSelector(singleLeadDataSelector);

  const dateArray = [
    {
      date: formatDate(dateToMoment().add(1, "day").set({ hour: 8, minute: 0 }), "YYYY-MM-DD HH:mm:ss"),
      title: "Tomorrow",
    },
    {
      date: formatDate(dateToMoment().weekday(6).set({ hour: 8, minute: 0 }), "YYYY-MM-DD HH:mm:ss"),
      title: "This Weekend",
    },
    {
      date: formatDate(dateToMoment().add(1, "weeks").weekday(1).set({ hour: 8, minute: 0 }), "YYYY-MM-DD HH:mm:ss"),
      title: "Next Week",
    },
    {
      date: formatDate(dateToMoment().add(1, "weeks").weekday(6).set({ hour: 8, minute: 0 }), "YYYY-MM-DD HH:mm:ss"),
      title: "Next Weekend",
    },
  ];

  useEffect(() => {
    form.setFieldsValue({ title: defaultTitle });
  }, [form, defaultTitle, visible]);

  useEffect(() => {
    if (isDescShown) {
      setIsDescriptionAdded(true);
    } else {
      setIsDescriptionAdded(false);
    }
  }, [modalProps, form, isDescShown]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setIsDescriptionAdded(false);
    }
  }, [form, visible]);

  const formSubmit = () => {
    if (!datePickerVisible) {
      form.submit();
      setDatePickerDateSaved(false);
      if (fromScoring) {
        dispatch(
          updateLeadTags({
            requirements_id: leadData?.requirement?._id,
            tags: leadData?.tags || [],
            step: 4,
          })
        );
      }
    } else {
      setLoading(true);
      setDatePickerDateSaved(true);
      setTimeout(() => {
        setLoading(false);
        setDatePickerVisible(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (datePickerData !== "") {
      setIsDatePickerDateSelected(false);
      form.resetFields(["todo_at"]);
    }
  }, [datePickerData, form]);

  useEffect(() => {
    if ((addFollowUpSuccess || addDashboardFollowUpSuccess) && visible) {
      setFollowUpSuccessed(true);
    } else {
      setFollowUpSuccessed(false);
    }
  }, [addFollowUpSuccess, addDashboardFollowUpSuccess, visible]);

  useEffect(() => {
    if (!visible) {
      dispatch(followUpAddedAndModalClosed());
      setFollowUpSuccessed(false);
      setDatePickerData("");
    }
    return () => {
      setTimeData("08:00");
    };
  }, [dispatch, visible]);

  useEffect(() => {
    if (modalProps.editModal) {
      setDatePickerData(formatDate(dateToMoment(form.getFieldValue("todo_at"))));
    }
  }, [modalProps]);

  return (
    <BaseModal
      classname={`single-listing-page-add-note add-follow-ups-modal-wrapper-all ${
        addFollowUpSuccess && "add-follow-ups-modal-success-wrapper-all"
      } ${datePickerVisible && "add-follow-ups-modal-success-wrapper-all-date-picker"}`}
      cancelButtonProps={{
        className: "rb-secondary-btn sm",
      }}
      okButtonProps={{
        className: "rb-primary-btn sm",
      }}
      modalTitle={modalProps.editModal ? "Edit Follow-up" : " Add Follow-Up"}
      isModalVisible={visible}
      modalFooter={[
        !addFollowUpSuccess && !addDashboardFollowUpSuccess ? (
          <BaseButton
            loading={loading}
            variant={datePickerVisible ? "" : "primary"}
            onClick={() => {
              formSubmit();
            }}
          >
            {datePickerVisible ? "Select Date" : "Save Follow-Up"}
          </BaseButton>
        ) : null,
      ]}
      confirmLoading={listingNotesLoading}
      onCancel={() => {
        setDatePickerDateSaved(false);
        setModalProps(initialModalValue);
        setDatePickerData("");
        setLoading(false);
        setDatePickerVisible(false);
        isSingle && dispatch(push(location.pathname));
      }}
      width={460}
    >
      {!followUpSuccessed ? (
        <Form
          className={`landlord-core-details-form ${datePickerVisible && "date-picker-visible-wrapper"}`}
          onFinish={(data) => {
            setLoading(true);
            const modalData: any = {
              title: data.title,
              description: data.description,
              agent_id: user._id,
              todo_at: data.todo_at ? data.todo_at : datePickerData,
              todo_status: "in_progress",
            };
            if (listing && Object.entries(listing).length) {
              modalData.listing_id = listing._id;
            }
            if (landlordInfo && Object.entries(landlordInfo).length) {
              modalData.landlord_id = landlordInfo.landlord_id;
            }
            if (lead && Object.entries(lead).length) {
              modalData.requirements_id = lead?.requirement?.id;
              modalData.renter_id = lead.renter ? lead.renter._id : lead?.renterOfRequirement?.renterOfRequirement?._id;
            }

            if (isFollowUpEditable || modalProps.editModal) {
              if (!isFromDashboard) {
                dispatch(editFollowUp(modalData, data._id));
              } else {
                if (modalProps.editModal) {
                  dispatch(editDashboardFollowUp(modalData, data._id));
                  setModalProps(initialModalValue);
                }
              }
            } else {
              if (!isFromDashboard) {
                dispatch(addFollowUp(modalData));
              } else {
                dispatch(addDashboardFollowUp(modalData));
              }
            }
          }}
          form={form}
        >
          {datePickerVisible ? (
            <>
              <GoBackArrow setDatePickerVisible={setDatePickerVisible} />
              <AddFollowUpModalDatePicker
                datePickerData={datePickerData}
                setDatePickerValue={setDatePickerData}
                form={form}
                modalProps={modalProps}
                datePickerDateSaved={datePickerDateSaved}
                timeData={timeData}
                setTimeData={setTimeData}
              />
            </>
          ) : (
            <>
              <Form.Item name={"_id"} className={"add-note-modal-form-wrapper--notVisible"}>
                not visible
              </Form.Item>
              <span className={"follow-up-title-modal-item"}>What needs to be done?</span>
              <Form.Item name="title" rules={[REQUIRED_TITLE_VALIDATION]}>
                <Input autoComplete={"off"} placeholder={"Title"} />
              </Form.Item>
              {isDescriptionAdded ? (
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: isDatePickerDateSelected,
                      message: "Please type description",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    autoFocus={isDescriptionAdded}
                    autoComplete={"off"}
                    placeholder={"Description (optional)"}
                  />
                </Form.Item>
              ) : (
                <span
                  className={"follow-ups-add-a-description"}
                  onClick={() => {
                    setIsDescriptionAdded(true);
                  }}
                >
                  + Add description
                </span>
              )}
              <span className={"follow-up-title-modal-item"}>Set Reminder</span>
              {!modalProps.editModal && (
                <Form.Item
                  name="todo_at"
                  rules={[
                    {
                      required: datePickerData === "",
                      message: "Please select a date",
                    },
                  ]}
                >
                  <Radio.Group
                    className="new-exists-radio-group add-follow-ups-modal-radio-section"
                    onChange={() => {
                      setDatePickerData("");
                      setIsDatePickerDateSelected(false);
                    }}
                  >
                    {dateArray.map((e: any, i: number) => {
                      return (
                        <Radio
                          value={new Date(moment(e.date).set({ hour: 8, minute: 0 }).format(dateFormat)).toISOString()}
                          key={i}
                        >
                          <div className={"add-follow-ups-modal-radio-section--items"}>
                            <span>{e.title}</span>{" "}
                            <span>
                              {moment(e.date, getBrowserDateFormat())
                                .set({ hour: 8, minute: 0 })
                                .format("MM/DD/YYYY HH:mm A")}
                            </span>
                          </div>
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>
              )}
              <div
                className={`choose-date-and-follow-up-time-btn ${
                  datePickerData !== "" && "choose-date-and-follow-up-time-btn-selected"
                }`}
                onClick={() => {
                  setDatePickerVisible(true);
                }}
              >
                <span>{datePickerData == "" ? "Choose date and time" : formatDate(dateToMoment(datePickerData))}</span>
                <CalendarIcon />
              </div>
            </>
          )}
        </Form>
      ) : (
        <FollowUpAddedSuccessModal
          setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          setFollowUpSuccessed={setFollowUpSuccessed}
          setModalProps={setModalProps}
          initialModalValue={initialModalValue}
          isFromDashboard={isFromDashboard}
        />
      )}
    </BaseModal>
  );
};
export default AddFollowUpModal;
