import React from "react";
import { Listing } from "../../../../../helpers/mockListings";
import ListCardItem from "./list-card-item/ListCardItem";
import { IListingGridView } from "../../../../../types/ListingWrapperTypes";
import { useWindowSize } from "../../../../../../../hooks/useWindowSize";
import { useSelector } from "react-redux";
import { routerSelector } from "store/listings/selectors";
import Pagination from "components/Shared/Pagination";
import { Spin } from "antd";
import GlobalLoading from "components/Shared/GlobalLoading";

const ListingsGridView = ({
  listingsViewType,
  pushByQueryChange,
  pagination,
  listingsFilterOptions,
  queryObject,
  total,
  loading,
  listingsList,
  handleLandlordLinkClick,
  setCheckedListing,
  collapsed,
  type,
}: IListingGridView) => {
  const { width } = useWindowSize();
  const router = useSelector(routerSelector);

  const columnFunction = () => {
    if (collapsed && listingsViewType === "grid" && type !== "mobile" && width && width > 1440) {
      return 4;
    } else if (listingsViewType === "grid" && type !== "mobile") {
      if (width && width < 1000 && width > 700) {
        return 2;
      } else {
        return 3;
      }
    } else return 1;
  };
  // This function updates url to show page number and size of page
  // Also updates states of Pagination component.
  const handlePageChange = (page: number) => {
    pushByQueryChange(
      listingsViewType,
      { ...router.location.query, ...listingsFilterOptions },
      { ...pagination, current: page },
      queryObject
    );
  };

  return (
    <Spin spinning={loading} indicator={<GlobalLoading />} className="global-loading">
      <div
        data-test-id="listings-container"
        className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-6"
      >
        {listingsList?.map((listItem: Listing) => {
          return (
            <div key={listItem.public_id}>
              <ListCardItem
                listItem={listItem}
                handleLandlordLinkClick={handleLandlordLinkClick}
                setCheckedListing={setCheckedListing}
              />
            </div>
          );
        })}
      </div>
      <div className="my-8">
        <Pagination total={total} pagination={pagination} onPageChange={handlePageChange} />
      </div>
    </Spin>
  );
};

export default ListingsGridView;
