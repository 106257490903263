import React, { useEffect, useState } from "react";
import "./styles.scss";
import BaseButton from "../../Shared/BaseButton";
import { useForm } from "antd/es/form/Form";
import AddFollowUpModal from "../add-follow-up-modal/AddFollowUpModal";
import { useDispatch, useSelector } from "react-redux";
import { addFollowUpSuccessSelector, queryAndPaginationSelector } from "../../../store/followUps/selectors";
import AddFollowUpModalMobile from "../add-follow-up-modal-mobile/AddFollowUpModalMobile";
import { useWindowSize } from "../../../hooks/useWindowSize";
import PhoneVerifyModalFollowUps from "../all-follow-ups-modal/components/phone-verify-modal";
import { getFollowUpsList } from "../../../store/followUps/action";
import { urlQueryToObject } from "../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { useLocation } from "react-router";
import { SearchWithShadow } from "components/icons/SearchWithShadow";
import agent2 from "assets/images/Nadgla.png";
import Section from "components/Shared/Section/Section";
import Testimonial from "components/Shared/Testimonial";

const initialModalValue = { visible: false, editModal: false };
export enum TodoStatusEnum {
  completed = "completed",
  in_progress = "in_progress",
}
const EmptyFollowUpListView = () => {
  const [form] = useForm();
  const { width }: any = useWindowSize();
  const location = useLocation();
  const [modalProps, setModalProps] = useState(initialModalValue);
  const addFollowUpSuccess = useSelector(addFollowUpSuccessSelector);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const dispatch = useDispatch();
  const _newURLSearchParams = new URLSearchParams(location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const queryAndPagination = useSelector(queryAndPaginationSelector);
  const [isTodo, setIsTodo] = useState(true);
  const handleOpenAddNoteModal = () => {
    setModalProps({ visible: true, editModal: false });
    form.resetFields();
  };

  useEffect(() => {
    if (!modalProps.visible) {
      dispatch(getFollowUpsList(queryAndPagination));
    }
  }, [modalProps]);

  useEffect(() => {
    if (queryObject.view === "completed") setIsTodo(queryAndPagination.todo_status === TodoStatusEnum.completed);
    else setIsTodo(false);
  }, [queryObject]);

  return (
    <Section shadow className="w-full">
      <div className="empty-follow-ups-wrapper">
        <div className="w-full flex justify-center p-8">
          <SearchWithShadow />
        </div>
        <div className="empty-follow-ups-text-section-wrapper">
          <span className="mb-30">Streamline your client relationships</span>
          {!isTodo ? (
            <span className="add-description">
              Add a follow-up to stay on top of tasks, build client relationships, and convert your rentals into sales.
            </span>
          ) : (
            <span> Looks like you don't have completed follow-ups yet.</span>
          )}
          {/*<span> Adding a follow-up is easy.</span>*/}
        </div>
        <div>
          <BaseButton variant="primary" onClick={handleOpenAddNoteModal}>
            <span className={"add-fu-btn-main-empty"}> Add Follow-Up</span>
          </BaseButton>
        </div>
        <Testimonial
          avatarSrc={agent2}
          author="Nadgla Anilus"
          content="RentBase's Follow-up tools have really helped me work smarter, not harder, and allowed me to close more deals in less time. What's better than that?!"
        />
        {modalProps.visible &&
          (width >= 600 ? (
            <AddFollowUpModal
              visible={modalProps.visible}
              setModalProps={setModalProps}
              form={form}
              modalProps={modalProps}
              isDescShown={false}
              isFollowUpEditable={false}
              setIsDescShown={(e: any) => {
                console.log(e);
              }}
              setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            />
          ) : (
            <AddFollowUpModalMobile
              visible={modalProps.visible}
              setModalProps={setModalProps}
              form={form}
              modalProps={modalProps}
              isDescShown={false}
              isFollowUpEditable={false}
              setIsDescShown={(e: any) => {
                console.log(e);
              }}
              setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            />
          ))}
        <PhoneVerifyModalFollowUps
          visible={phoneVerifyModalVisible}
          onCancel={() => {
            setPhoneVerifyModalVisible(false);
          }}
        />
      </div>
    </Section>
  );
};

export default EmptyFollowUpListView;
