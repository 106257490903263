import TagManager from "react-gtm-module";
import { gtmKey, isProduction, gtm_auth, gtm_preview, envPrefix, SOURCE_ENUM, isProdEnv } from "./constants";
import { EnrichmentsService } from "./enrichmentsService";
import md5 from "md5";
import { removeSpaceAndCapitalizationFromString } from "./globalFunctions";
import { TOKEN_ENUM } from "./authUtils";
import { EventListingInfoDto } from "./analytics/eventdata";
import { EVENT_NAME, EXTERNAL_SOURCES } from "./analytics/eventEnums";
import AnalyticsApi from "api/Analytics";
const isUserLoggedIn = localStorage.getItem(TOKEN_ENUM.ACCESS_TOKEN) && localStorage.getItem(TOKEN_ENUM.REFRESH_TOKEN);

const tagManagerArgs = {
  gtmId: gtmKey,
  auth: gtm_auth,
  preview: gtm_preview,
  dataLayer: {},
};

const isTeam = localStorage.getItem("isTeam") === "true";
!isTeam && TagManager.initialize(tagManagerArgs); // initialize google tag manager container

interface EventPayload {
  category?: string;
  event?: string;
  label?: string;
  value?: string;
  [key: string]: any;
}

interface ScoringStepData {
  label?: string;
  stepData?: { step_index: number; value: string };
  [key: string]: any;
}
interface FilterValues {
  asked_rent_from?: number;
  asked_rent_to?: number;
  availability_date_to?: Date;
  bathrooms_from?: number;
  bathrooms_to?: number;
  bedrooms_from?: number;
  bedrooms_to?: number;
  listingType?: string;
  locations?: string[];
  page?: number;
  parking_type?: true;
  pets_allowed?: string[];
  size?: number;
  status?: string;
  [key: string]: any;
}

const setDataLayer = (params: object) => {
  const page = window.location.href; // get the current page to enrich the data layer
  const userId = localStorage.getItem("user_id");
  const agencyId = localStorage.getItem("agency_id");
  const plan = localStorage.getItem("plan");

  const enrichedDataLayerArgs = {
    gtmId: gtmKey,
    gtm_auth,
    gtm_preview,
    dataLayer: {
      ...tagManagerArgs.dataLayer,
      ...params,
      page,
      userId: userId && md5(userId),
      agencyId: agencyId && md5(agencyId),
      plan,
      env: isProdEnv ? "production" : envPrefix,
    },
  };

  TagManager.dataLayer(enrichedDataLayerArgs); // set the datalayer with the extra context
};

// AnalyticsService - a service to unify all reporting in the system
export const AnalyticsService = {
  reportEvent: function (action: string, eventParams?: EventPayload) {
    const role = localStorage.getItem("user_role");
    const enrichmentsObj = EnrichmentsService.getEnrichmentsObjectWithReferralSource();
    const demoPrefix = isDemoPage() ? "[DEMO]-" : "";

    let fullParams = {
      action: envPrefix + demoPrefix + action,
      category: eventParams?.category?.toLowerCase() || getCurrentTab(),
      ...eventParams,
      label: removeSpaceAndCapitalizationFromString(eventParams?.label?.toLowerCase()),
      event: `[RB]-${action.toLowerCase()}`,
      eventName: action.toLowerCase(),
      value: eventParams?.value?.toLowerCase(),
      role,
      currentPage: getCurrentTab(),
      triggerButton: eventParams?.triggerButton?.toLowerCase(),
      enrichments: enrichmentsObj,
      filtersFormData: eventParams?.filtersFormData,
    };

    isTeam ? console.log(fullParams) : setDataLayer(fullParams);
  },

  // V2 EVENT IMPLEMENTATION START
  // ReportEvent V2 version to add addtional data to events and send the events to internal datastore.
  reportEventV2: function (event: EVENT_NAME, eventParams?: EventPayload) {
    const enrichmentsObj = EnrichmentsService.getEnrichmentsObjectWithReferralSource();
    //const demoPrefix = isDemoPage() ? "[DEMO]-" : "";
    const eventName = event.toLowerCase();
    const role = localStorage.getItem("user_role");
    const page = window.location.href; // get the current page to enrich the data layer
    const userId = eventParams?.user_id ?? localStorage.getItem("user_id");
    const agencyId = eventParams?.agency_id ?? localStorage.getItem("agency_id");
    const plan = localStorage.getItem("plan");
    const env = isProdEnv ? "production" : envPrefix;

    // Create GTM DataLayer Object
    let dataLayer = {
      event: `[RB]-${eventName}`,
      // Legacy Data
      action: event,
      ...eventParams,
      page_type: eventParams?.page_type || getCurrentTab(),
      eventName: eventName,
      // Common Data
      page,
      userId: userId && md5(userId),
      agencyId: agencyId && md5(agencyId),
      plan,
      env,
      role,
      currentPage: getCurrentTab(),
      enrichments: enrichmentsObj,
    };
    const enrichedTagManagerArgs = {
      gtmId: gtmKey,
      gtm_auth,
      gtm_preview,
      dataLayer: {
        ...tagManagerArgs.dataLayer,
        ...dataLayer,
      },
    };

    TagManager.dataLayer(enrichedTagManagerArgs); // set the datalayer with the extra context

    // Create Internal Analytics Data Object.
    const backEndEvent = { user_id: userId, event_name: dataLayer.eventName, properties: dataLayer };
    if (userId && shouldSendEventToBackEnd(event)) {
      delete backEndEvent["properties"].userId;
      delete (backEndEvent["properties"] as any).user_id;
      delete (backEndEvent["properties"] as any).agency_id;
      delete (backEndEvent["properties"] as any).eventName;
      backEndEvent["properties"].agencyId = agencyId;
      AnalyticsApi.track(backEndEvent);
    }
  },

  reportPageViewEvent: function (eventProperties: {}) {
    const event_pay_load = {
      ...eventProperties,
    };
    this.reportEventV2(EVENT_NAME.PAGE_VIEW, event_pay_load);
  },

  reportListingEvent: function (eventName: EVENT_NAME, listing: any) {
    // Create ListingEvent Payload
    let listing_pay_load: EventListingInfoDto;
    listing_pay_load = {
      listing_id: listing._id,
      listing_agent: listing.agent_id,
      listing_source: listing?.source || SOURCE_ENUM.RENTBASE,
      landlord_id: listing?.landlord_id,
      tenant_ids: listing.tenant_ids,
      co_listing_agents: listing.co_list_agents,
      listing_status: listing.status,
      asked_rent: listing?.asked_rent,
      listing_publish_source: listing.published_rdt ? EXTERNAL_SOURCES.realtorapi : "",
    };
    this.reportEventV2(eventName, listing_pay_load);
  },

  reportClickEvent: function (eventName: EVENT_NAME, eventLocation: string, eventPayLoad: any) {
    const click_pay_load = {
      label: eventLocation,
      event_location: eventLocation,
      clickEventData: eventPayLoad,
    };
    this.reportEventV2(eventName, click_pay_load);
  },

  reportTabChanged: function (nextTab: string) {
    let currentTab = getCurrentTab();
    currentTab = currentTab.replace("/", "");
    nextTab = nextTab.replace("/", "");
    if (nextTab.startsWith("renter-portal") && !currentTab.startsWith("renter-portal")) {
      currentTab = "rental-portal/" + currentTab;
    }
    if (nextTab === "") {
      nextTab = "dashboard";
    }

    AnalyticsService.reportEventV2(EVENT_NAME.TAB_CHANGE, {
      label: removeSpaceAndCapitalizationFromString(`${currentTab} -> ${nextTab}`),
      current_tab: currentTab,
      next_tab: nextTab,
    });
  },
  // V2 EVENT IMPLEMENTATION END

  reportSignUpProcess: function (data: EventPayload) {
    const { action, label, formFieldsFilled } = data;

    if (formFieldsFilled) {
      AnalyticsService.reportEvent(action, {
        category: "Signup",
        label,
        ...formFieldsFilled,
      });
    } else {
      AnalyticsService.reportEvent(action, {
        category: "Signup",
        label,
      });
    }
  },

  reportRequirementsFunnel: function (data: EventPayload) {
    const { action, label, errorMessage, formData, response, value, stepData = {} } = data;
    const role = localStorage.getItem("user_role");
    let reqPrefix = "";
    if (isUserLoggedIn && role !== "Renter" && isPreviewMode()) {
      reqPrefix = "AGENT_PREVIEW_";
    }
    if (isManualMode()) {
      reqPrefix = "manual_";
    }

    if (formData) {
      // Reporting requirements_submitted with all form data
      const leadFormData = {
        preferred_method_of_contact: formData.contact_preference,
        min_budget: formData.budget_min,
        max_budget: formData.budget_max,
        bd: formData.bedrooms_req?.length === 1 ? "single" : "multi",
        ba: formData.bathrooms_min_req?.length === 1 ? "single" : "multi",
        bathrooms_max: formData.bathrooms_max,
        bathrooms_min: formData.bathrooms_min,
        bedrooms_max: formData.bedrooms_max,
        bedrooms_min: formData.bedrooms_min,
        pets: formData.have_pets,
        must_haves: formData.must_have && Object.keys(formData.must_have),
        deal_breakers: formData.deal_breaker && Object.keys(formData.deal_breaker),
        "employed/student": formData.student_or_employed,
        lease_status: formData.lease_status,
        number_of_people: formData.number_of_tenants,
        number_employed: formData.employed_tenants,
        credit_score: formData.credit_score,
        parking: formData.parking,
        parking_count: formData.number_of_parking,
        how_many_have_you_seen: formData.listings_viewed,
        how_many_applied: formData.listings_applied,
        areas: Array.isArray(formData.area) && formData.area.map((loc: any) => loc.area),
        // parking_types - string array
        // pet_count - integer
        // pet_types  - array
      };

      AnalyticsService.reportEvent(`${reqPrefix}${action}`, {
        category: "renter",
        label,
        leadFormData,
        response,
      });
      return;
    }
    AnalyticsService.reportEvent(`${reqPrefix}${action}`, {
      category: "renter",
      label,
      value,
      errorMessage,
      response,
      stepData,
    });
  },

  shareLeadModal: function (data: EventPayload) {
    AnalyticsService.reportEvent("add_lead_modal_open", data);
  },

  // When clicking on an ` Add Follow-Up` button
  reportAddFollowUpClick: function (label?: any) {
    AnalyticsService.reportEvent("add_follow_up_button_click", { label });
  },

  // When clicking on an `Add Listing` button
  reportAddListingClick: function (label?: any) {
    AnalyticsService.reportEvent("add_listing_button_click", { label });
  },

  reportFollowUpClick: function (label?: string) {
    AnalyticsService.reportEntityClick("follow_up_click", label);
  },

  reportLeadClick: function (label?: string) {
    AnalyticsService.reportEntityClick("lead_click", label);
  },

  reportAddLeadClick: function (label?: string) {
    AnalyticsService.reportEntityClick("add_a_lead_click", label);
  },

  reportManuallyAddLeadClick: function (label?: string) {
    AnalyticsService.reportEntityClick("manually_add_a_lead_click", label);
  },

  reportLandlordClick: function (label?: string) {
    AnalyticsService.reportEntityClick("landlord_click", label);
  },

  reportEntityClick: function (action: string, label?: string) {
    if (!label) {
      label = `${getCurrentTab()}_page`;
    }
    AnalyticsService.reportEvent(action, { label });
  },

  reportBackButtonClicked: function (nextPath?: string) {
    const currentPage = localStorage.getItem("current_page");
    AnalyticsService.reportEvent("back_button", {
      label: `${currentPage !== "null" ? currentPage : getCurrentTab()} -> ${nextPath ? nextPath : "unknown"}`,
    });
    localStorage.setItem("current_page", "null");
  },

  reportFilterOpen: function (filterLabel: string) {
    AnalyticsService.reportEvent("filter_open", {
      label: filterLabel,
    });
  },

  reportFiltersModalOpen: function (filterLabel: string) {
    AnalyticsService.reportEvent("filters_modal_open", {
      label: filterLabel,
    });
  },

  reportFiltersModalClose: function (filtersFormData: FilterValues) {
    if (filtersFormData.size) delete filtersFormData.size;
    if (filtersFormData.page) delete filtersFormData.page;
    if (filtersFormData.listingType) delete filtersFormData.listingType;

    AnalyticsService.reportEvent("filters_modal_close", {
      filtersFormData,
    });
  },

  reportFilterValues: function (filterLabel: string, filters: object | string) {
    if (filters === "") {
      AnalyticsService.reportEvent("filter_unselected", {
        label: filterLabel,
      });
    } else
      AnalyticsService.reportEvent("filter_selected", {
        label: filterLabel,
        filterValues: filters,
      });
  },

  reportRenterIdentify: function () {
    const agents_connected = localStorage.getItem("agents_connected");
    AnalyticsService.reportEvent("set_renter_id", { agents_connected });
  },

  reportSingleListingPageClick: function (label: string) {
    AnalyticsService.reportEvent("single_listing_page_click", { label, category: "listing_page" });
  },

  reportRenterPageEvent: function (action: string) {
    AnalyticsService.reportEvent(action, { category: "renter" });
  },

  reportEditRequirementsBeforeAlertingAgentsClick: function (label: string) {
    AnalyticsService.reportEvent("edit_requirements_before_alerting_agents_modal_click", { label });
  },

  reportHaveNewHomeModalClick: function (data: { [key: string]: any }) {
    AnalyticsService.reportEvent("have_new_home_modal_click", { ...data });
  },

  reportAfterPaymentModalFailure: function (action: string) {
    AnalyticsService.reportEvent(action, { label: "after_payment_modal_failure", category: "premium" });
  },

  reportPremiumEvents: function (action: string, data: EventPayload = {}) {
    const { label } = data;

    AnalyticsService.reportEvent(action, {
      category: "premium",
      label,
    });
  },

  reportUpgradeModalClick: function (data: EventPayload) {
    const { label, triggerButton } = data;
    AnalyticsService.reportEvent("upgrade_modal_click", {
      category: "premium",
      label,
      triggerButton,
    });
  },

  reportReferralModalOpen: function (label: string) {
    AnalyticsService.reportEvent("referral_modal_open", {
      label,
    });
  },

  reportReferralModalClose: function (label: string) {
    AnalyticsService.reportEvent("referral_modal_close", {
      label,
    });
  },

  reportRemoveReferralBanner: function (label: string) {
    AnalyticsService.reportEvent("removed_referral_banner", {
      label,
    });
  },

  reportShareLinkCopied: function (action: string) {
    AnalyticsService.reportEvent(`${action}_link_copied`, { label: "sharing_modal" });
  },

  reportImportListings: function (label: string) {
    AnalyticsService.reportEvent("import_listings_button_click", {
      label,
    });
  },

  reportScoringStepClick: function (label: string, stepData: ScoringStepData) {
    if (stepData.button_label === "add") stepData.button_label = "yes";
    if (stepData.button_label === "delete") stepData.button_label = "no";
    AnalyticsService.reportEvent("lead_scoring_question_answered", { label, stepData });
  },

  addPageAsLabelAndReport: function (action: string) {
    const label = getCurrentTab();
    AnalyticsService.reportEvent(action, { label });
  },

  getValuesString: function (values: any) {
    const stringsArray = [];
    for (let key in values) {
      if (values[key] !== null && values[key] !== "") {
        stringsArray.push(`${key}: ${values[key]}`);
      }
    }

    return `${stringsArray.join(", ")}`;
  },

  setDataLayer: setDataLayer,
};

const getCurrentTab = () => {
  let currentHref = window.location.href;
  if (window.location.href.includes("/demo")) {
    currentHref = currentHref.replace("/demo", "");
  }

  const urlMatch = currentHref.match(/.{1,100}(\/\w{1,15})\//) ?? currentHref.match(/.{1,100}(\/\w*[-a-z]{1,15})/);
  if (urlMatch && (urlMatch[1] === "/localhost" || urlMatch[1] === "/dev" || urlMatch[1] === "/staging" || urlMatch[1] === "/app")) {
    urlMatch[1] = "dashboard";
  }
  let currentTab = urlMatch !== null ? urlMatch[1] : currentHref;
  currentTab = currentTab.replace("/", "");
  return currentTab;
};

const isDemoPage = () => {
  const match = window.location.href.match(/\/demo/);
  return !!(match && match[0]);
};

const isPreviewMode = () => {
  const match = window.location.href.match(/previewMode=true/);
  return !!(match && match[0]);
};

const isManualMode = () => {
  const match = window.location.href.match(/is_manual=true/);
  return !!(match && match[0]);
};

function shouldSendEventToBackEnd(eventName: EVENT_NAME): boolean {
  // We need to add selected events
  const allowed_mix_panel_events_list = [
    EVENT_NAME.RB_SESSION_START,
    EVENT_NAME.LISTING_VIEW,
    EVENT_NAME.SERVICES_SEARCH,
    EVENT_NAME.SERVICES_LIST_CLICK,
    EVENT_NAME.REQUEST_MATCHING_LISTINGS,
    EVENT_NAME.PASSPORT_REQUEST,
    EVENT_NAME.LOG_OUT,
  ];
  return allowed_mix_panel_events_list.indexOf(eventName) !== -1;
}
