import { Row } from "antd";
import { push } from "connected-react-router";
import { LISTING_STATUS_OPTIONS } from "helpers/constants";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { phoneMaskOnString } from "../../helpers/globalFunctions";
import { IObjectKeys } from "../../helpers/globalTypes";
import { useWindowSize } from "../../hooks/useWindowSize";
import { listingDataSelector } from "../../store/listings/selectors";
import BaseEmptyState from "../BaseEmptyState";
import BaseButton from "../Shared/BaseButton";
import BaseCollapse from "../Shared/BaseCollapse";
import BaseModal from "../Shared/BaseModal";
import { CheckedIcon } from "../icons";
import ShareShowingProcedureModalContent from "./ShareShowingProcedureModalContent";
import Section from "components/Shared/Section/Section";
import SectionHeading from "components/Shared/Section/SectionHeading";

const ShowingProcedure = () => {
  const dispatch = useDispatch();
  const listing = useSelector(listingDataSelector);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const handleAddBtnClick = () => {
    const parsed = {
      // landlordId:
      lId: listing._id,
      step: 4,
    };
    const stringified = queryString.stringify(parsed);
    dispatch(push(`/manageListing?${stringified}`));
  };

  const onClose = () => {
    setIsShareModalVisible(false);
  };
  const onOpen = () => {
    setIsShareModalVisible(true);
  };

  const showingProcedureEmptyStateProps = {
    title: "Showing Procedure",
    description: "No showing procedure set yet.",
    linkText: "Add them now",
    openWithModal: false,
    modalType: "listing",
    handleAddBtnClick,
    modalProps: {
      isModalVisible: false,
      children: "",
      modalTitle: "",
    },
  };

  const isCollapsed = useMemo(() => {
    return !!(
      listing?.listingShowingOfList?.lockbox_code ||
      listing?.rentersOfListing?.length > 0 ||
      listing?.listingShowingOfList?.procedure?.call_manager ||
      listing?.listingShowingOfList?.procedure?.call_landlord ||
      (listing.listingShowingOfList?.procedure?.other && listing?.listingShowingOfList?.ls_description) ||
      listing?.listingShowingOfList?.key_number
    );
  }, [
    listing?.listingShowingOfList?.lockbox_code,
    listing?.rentersOfListing?.length,
    listing?.listingShowingOfList?.procedure?.call_manager,
    listing?.listingShowingOfList?.procedure?.other,
    listing?.listingShowingOfList?.ls_description,
    listing?.listingShowingOfList?.key_number,
  ]);

  return (
    <BaseCollapse header={<SectionHeading heading="Showing Procedures" />} collapse={true}>
      {isCollapsed ? (
        <div className="pb-8">
          {listing.listingShowingOfList?.procedure?.call_landlord && (
            <div className={"showing-procedure-section-wrapper flex justify-start gap-2 items-center"}>
              <span className="showing-procedure-check-icon-wrapper">
                <CheckedIcon />
              </span>
              <span>
                <span className="font-16 mr-10">Contact Landlord</span>
              </span>
            </div>
          )}

          {listing.listingShowingOfList?.procedure?.call_tenants && (
            <div className={"showing-procedure-section-wrapper flex justify-start gap-2 items-center"}>
              <span className="showing-procedure-check-icon-wrapper">
                <CheckedIcon />
              </span>
              <span>
                <span className="font-16 mr-10">Contact Tenants</span>
              </span>
            </div>
          )}

          {listing.listingShowingOfList?.procedure?.call_manager && (
            <div className="showing-procedure-section-wrapper">
              <div className={"contact-manager-procedure-section"}>
                <div className="flex justify-start gap-2 items-center">
                  <span className="showing-procedure-check-icon-wrapper">
                    <CheckedIcon />
                  </span>
                  <span className="font-16 mr-10 text-no-wrap">Property Manager:</span>
                </div>
                <div className={"contact-manager-mob-desk"}>
                  <strong className="showing-procedure-tenant-name">
                    {`${listing?.listingShowingOfList?.property_manager_name || "-"}`}
                  </strong>{" "}
                  {listing?.listingShowingOfList?.property_manager_phone && (
                    <a
                      className="primary-link light fz-16 mr-5"
                      href={`tel:${listing.listingShowingOfList.property_manager_phone}`}
                    >
                      {phoneMaskOnString(listing.listingShowingOfList.property_manager_phone)}
                    </a>
                  )}
                  {`${listing?.listingShowingOfList?.property_manager_email || ""}`}
                </div>
              </div>
            </div>
          )}

          {listing?.listingShowingOfList?.lockbox_code && (
            <div className="showing-procedure-section-wrapper flex justify-start gap-2 items-center">
              <span className="showing-procedure-check-icon-wrapper">
                <CheckedIcon />
              </span>
              {listing?.listingShowingOfList?.lockbox_code && (
                <span className="font-16">
                  <span className={"mr-5"}>Lockbox:</span>
                  <strong>{listing?.listingShowingOfList?.lockbox_code || "-"}</strong>
                </span>
              )}
            </div>
          )}

          {listing.listingShowingOfList?.key_number && (
            <Row>
              <div className="showing-procedure-section-wrapper flex justify-start gap-2 items-center">
                <span className="showing-procedure-check-icon-wrapper">
                  <CheckedIcon />
                </span>
                <span className="font-16 mr-5">Use Key:</span>
                <span className="font-16">
                  <strong>{listing.listingShowingOfList.key_number || "-"}</strong>
                </span>
              </div>
            </Row>
          )}

          {listing.listingShowingOfList?.procedure?.other && listing.listingShowingOfList?.ls_description && (
            <div className="showing-procedure-section-wrapper flex justify-start gap-2 items-center">
              <span className="showing-procedure-check-icon-wrapper">
                <CheckedIcon />
              </span>
              {listing?.listingShowingOfList?.ls_description && (
                <span className="showing-procedure-desc-wrapper">{listing?.listingShowingOfList?.ls_description}</span>
              )}
            </div>
          )}
          <div className={"showing-procedure-share-btn"} style={{ display: "none" }}>
            <BaseButton onClick={onOpen} classnames={"base-btn sidebar-mobile-btn"}>
              Share Procedure
            </BaseButton>
          </div>
        </div>
      ) : (
        <div className={"showing-procedure-empty-state pb-6"}>
          <BaseEmptyState {...showingProcedureEmptyStateProps} />
        </div>
      )}

      <BaseModal
        classname={"add-team-modal"}
        isModalVisible={isShareModalVisible}
        dataTestId="add-team-modal"
        modalTitle={"Add Team"}
        onCancel={() => {
          onClose();
        }}
        modalFooter={null}
      >
        <ShareShowingProcedureModalContent />
      </BaseModal>
    </BaseCollapse>
  );
};

export default ShowingProcedure;
