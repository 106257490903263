import React from "react";
import PinIcon from "./icons/PinIcon";
import DropdownPencilEdit from "../../../../icons/DropdownPencilEdit";
import { DeleteIcon } from "../../../../icons";
import EditIcon from "./icons/EditIcon";
import TrashIconNotes from "./icons/TrashIconNotes";
import { formatDate } from "helpers/dateHelper";
interface INoteWithoutDescription {
  note: any;
  handleOpenNoteModal: any;
  setVisible: any;
  setDeleteConfirmProps: any;
  setSingleNoteVisible:any;
}

const NoteWithoutDescription = ({
  note,
  handleOpenNoteModal,
  setVisible,
  setDeleteConfirmProps,
  setSingleNoteVisible
}: INoteWithoutDescription) => {
  return (
    <div
      className={
        "single-listing-page-all-note--notesWrapper--item--wrapper--noCollapse"
      }
    >
      <div
        className={
          "single-listing-page-all-note--notesWrapper--item--wrapper--noCollapse--titleAndCreatedAt"
        }
      >
        <span>{formatDate(note.created_at)} by : {note.created_by.name}</span>
        <p>{note.title}</p>
      </div>
      <div
        className={
          "single-listing-page-all-note--notesWrapper--item--wrapper--noCollapse--headerIconsActive"
        }
      >
        <div
          onClick={() => {
            handleOpenNoteModal(note);
            setVisible(false);
            setSingleNoteVisible(false)
          }}
        >
          <EditIcon />
        </div>
        <div
          onClick={() => {
            if (note._id) {
              setDeleteConfirmProps({
                visible: true,
                noteId: note._id,
              });
              setVisible(false);
              setSingleNoteVisible(false);
            }
          }}
        >
          <TrashIconNotes />
        </div>
      </div>
    </div>
  );
};

export default NoteWithoutDescription;
