import Api from "./api";
import { IObjectKeys } from "../helpers/globalTypes";
import { SCREENING_REPORT_TYPES } from "helpers/constants";

export default class ShareableApi {
  static createShareableLandlordEntity(params: IObjectKeys) {
    const api = new Api(`screening/shareableLandlords/agent/${params.agentId.toString()}`);
    return api.create({ data: { ...params.data } });
  }

  static getShareableLandlordEntity(agentId: string) {
    const api = new Api(`screening/shareableLandlords/agent/${agentId}`);
    return api.get("", {});
  }

  static createShareablePropertyEntity(params: IObjectKeys) {
    const api = new Api(
      `screening/shareableLandlords/${params.shareableLandlordId.toString()}/properties/${params.listingId.toString()}`
    );
    return api.create({ data: { ...params.data } });
  }

  static updateShareablePropertyEntity(params: IObjectKeys) {
    const api = new Api(
      `screening/shareableLandlords/${params.shareableLandlordId.toString()}/shareableProperties/${params.listingId.toString()}`
    );
    return api.put("", { data: { ...params.data } });
  }

  static getShareablePropertyEntity(params: IObjectKeys) {
    const api = new Api(
      `screening/shareableLandlords/${params.shareableLandlordId.toString()}/properties/${params.listingId.toString()}`
    );
    return api.get("", {});
  }

  static createShareableScreeningRequestEntity(params: IObjectKeys) {
    const api = new Api(
      `screening/screeningRequests/${params.shareableLandlordId.toString()}/add/${params.shareablePropertyId.toString()}`
    );
    return api.create({ data: { ...params.data } });
  }

  static getRentersScreeningRequest(screeningRequesRentertId: string) {
    const api = new Api(`screening/screeningRequestRenters/${screeningRequesRentertId}/screeningRequests`);
    return api.get("", {});
  }

  static getAgentsScreeningRequest({ renterId, agentId }: { renterId: string; agentId: string }) {
    const api = new Api(
      `screening/shareableLandlords/screeningRequestRenters/${agentId}/Renters/${renterId}/screeningRequests`
    );
    return api.get("", {});
  }

  static getShareableRenter(renterId: string) {
    const api = new Api(`screening/shareableRenters/renter/${renterId}`);
    return api.get("", {});
  }

  static getTenantScreeningRequests(agentId: string) {
    const api = new Api(`screening/shareableLandlords/screeningRequestRenters/${agentId}/screeningRequests`);
    return api.get("", {});
  }

  static getScreeningReportForRenter(
    reportType: string = SCREENING_REPORT_TYPES.CREDIT,
    screeningRequestRenterId: string
  ) {
    const api = new Api(`screening/screeningRequestRenters/${screeningRequestRenterId}/renterReport/${reportType}`);
    return api.get("", {});
  }

  static getScreeningReportForAgent(
    reportType: string = SCREENING_REPORT_TYPES.CREDIT,
    screeningRequestRenterId: string
  ) {
    const api = new Api(`screening/screeningRequestRenters/${screeningRequestRenterId}/agentReport/${reportType}`);
    return api.get("", {});
  }

  static sendScreeningRequestReminder(payload: IObjectKeys) {
    const api = new Api(`renter/sendScreeningRequest`);
    return api.create({ data: payload });
  }

  static createScreeningRequestReport(params: IObjectKeys) {
    const api = new Api(`screening/screeningRequestRenters/${params.screeningRequestId}/reports`);
    return api.create({ data: { ...params.data } });
  }

  static sendScreeningRequestUpdateNotification(params: IObjectKeys) {
    const api = new Api("renter/sendScreeningRequest");
    return api.create({ data: { ...params.data } });
  }

  static createRenterAndMapScreeningRequest(params: IObjectKeys) {
    const api = new Api(`screening/screeningRequests/${params.srId.toString()}/renter/${params.renterId.toString()}`);
    return api.create({ data: { ...params.data } });
  }

  static createVerificationExam(params: IObjectKeys) {
    const api = new Api(`screening/screeningRequestRenters/${params.srrId.toString()}/exams`);
    return api.create({ data: { ...params.data } });
  }

  static postVerificationExamAnswers(params: IObjectKeys) {
    const api = new Api(
      `screening/screeningRequestRenters/${params.srrId.toString()}/exams/${params.examId.toString()}/answers`
    );
    return api.create({ data: { answers: params.data } });
  }

  static validateRenter(params: IObjectKeys) {
    const api = new Api(`screening/screeningRequestRenters/${params.srrId.toString()}/validate`);
    return api.create({ data: { ...params.data } });
  }
}
