import React, { useEffect, useState } from "react";
import BaseModal from "../Shared/BaseModal";
import { deleteListingImage, fillListingData, fillListingDataImages } from "../../store/listings/action";
import { Modal, Row, Spin } from "antd";
import { IImage } from "../Listings/helpers/mockListings";
import { getBase64 } from "../../helpers/fileUtils";
import { UploadFile } from "antd/es/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { isPublicListingSelector, listingDataSelector } from "../../store/listings/selectors";
import ListingImagesList from "./ListingImagesSection/ListingImagesList";
import ListingMainImageSlider from "./ListingImagesSection/ListingMainImageSlider";
import ListingImagesEmptyState from "./ListingImagesSection/ListingImagesEmptyState";
import ListingImagesMetaSection from "./ListingImagesSection/ListingImagesMetaSection";
import ListingImageVirtualTourMobile from "./ListingImagesSection/ListingImageVirtualTourMobile";
import ListingImageBottomUploadSectionMobile from "./ListingImagesSection/ListingImageBottomUploadSectionMobile";
import { ACCEPTED_FILE_SIZE, ROLE_TYPES } from "helpers/constants";
import { IListingViewProps } from ".";
import { userSelector } from "store/user/selectors";
import Section from "components/Shared/Section/Section";
import { windowSelector } from "store/app/selectors";

const { confirm } = Modal;

const ListingInfo = ({
  listingData,
  isAnAgentOfListing,
}: {
  listingData?: IListingViewProps;
  isAnAgentOfListing: boolean;
}) => {
  // @ts-ignore
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [previewModalAllPhotosVisible, setPreviewModalAllPhotosVisible] = useState(false);
  const [firstFilesRender, setFirstFilesRender] = useState(true);
  const [currentImage, setCurrentImage] = useState<{
    title?: string;
    image?: any;
  }>({});
  const window = useSelector(windowSelector);
  const [loadingImages, setLoadingImages] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const dispatch = useDispatch();
  const { role } = useSelector(userSelector);
  const isRenter = role === ROLE_TYPES.RENTER;
  const listingDataStore = useSelector(listingDataSelector);
  const listing = listingData ?? listingDataStore;
  const isPublicListing = useSelector(isPublicListingSelector);

  const isMobile = window.type === "mobile";

  useEffect(() => {
    if (listing.images?.length && firstFilesRender) {
      setFileList(
        listing.images.map((file: any) => ({
          uid: file._id,
          name: file.name,
          status: "done",
          url: file.url,
        }))
      );
      setFirstFilesRender(false);
    }
    if (listing.images?.length === 0) {
      setFirstFilesRender(false);
    }
  }, [listing.images]);

  useEffect(() => {
    if (listing?.images?.length === 0) {
      setFileList([]);
    }
  }, [listing.images]);

  const onChange = ({ file, fileList: currentFiles }: UploadChangeParam) => {
    setLoadingImages(true);
    if (file.status === "done") {
      setLoadingImages(false);
      dispatch(fillListingDataImages(file.response));
    }
    setFileList(currentFiles.filter((file) => !!file.status));
    if (file.size > ACCEPTED_FILE_SIZE) {
      setLoadingImages(false);
    }
  };

  const onFileRemove = (file: any) => {
    dispatch(deleteListingImage(file?.response?._id || file._id));
    setFileList(fileList.filter((f: any) => f.uid !== file._id));
    dispatch(
      fillListingData({
        images: [...listing.images.filter((f: IImage) => f._id !== file._id)],
      })
    );
    setLoadingImages(false);
    return Promise.resolve(false);
  };

  const showConfirm = (file: UploadFile) => {
    confirm({
      title: "Do you Want to delete these image?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setLoadingImages(true);
        onFileRemove(file);
      },
      onCancel() {},
    });
  };

  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setCurrentImage({
      title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      image: file.url || file.preview,
    });
    setPreviewModalVisible(true);
  };

  return (
    <>
      <div className="pb-6 sm:pb-10">
        {(!listing?.images?.length || !fileList.length) && isPublicListing ? (
          <div></div>
        ) : (
          <Spin spinning={loadingImages}>
            <ListingImagesMetaSection
              fileList={fileList}
              setPreviewModalAllPhotosVisible={setPreviewModalAllPhotosVisible}
              onChange={onChange}
            />
            <ListingImageVirtualTourMobile />
            <Row
              className={`${
                listing?.images?.length > 0 || fileList?.length > 0
                  ? "listing-pictures"
                  : "listing-pictures listing-blank-pictures"
              }`}
            >
              {listing?.images?.length > 0 || fileList?.length > 0 ? (
                <div className="flex flex-wrap w-full">
                  <div className="w-full sm:w-1/2">
                    <ListingMainImageSlider listingData={listing} />
                  </div>
                  {!isMobile && (
                    <div className="w-full sm:w-1/2">
                      <ListingImagesList
                        colViewConfig={{
                          xs: 24,
                          md: 8,
                        }}
                        enablePreview={true}
                        fileList={listing?.images?.slice(0, 6)}
                        showConfirm={showConfirm}
                        onPreview={onPreview}
                        setPreviewModalAllPhotosVisible={setPreviewModalAllPhotosVisible}
                        canEdit={isAnAgentOfListing}
                      />
                    </div>
                  )}
                </div>
              ) : !isRenter ? (
                <ListingImagesEmptyState
                  onChange={onChange}
                  setFileList={setFileList}
                  showConfirm={showConfirm}
                  showConfirmation={showConfirm}
                  onPreview={onPreview}
                  enablePreview={true}
                  colViewConfig={{
                    xs: 24,
                    md: 8,
                  }}
                  imageCardClassname={"uploaded-image-card"}
                  canEdit={isAnAgentOfListing}
                />
              ) : (
                <Section shadow className="w-full">
                  No images available for this listing
                </Section>
              )}
            </Row>
            <ListingImageBottomUploadSectionMobile
              fileList={fileList}
              setPreviewModalAllPhotosVisible={setPreviewModalAllPhotosVisible}
              onChange={onChange}
            />
          </Spin>
        )}
      </div>
      <BaseModal
        classname={"single-image-preview-modal"}
        isModalVisible={previewModalVisible}
        modalTitle={""}
        modalFooter={null}
        onCancel={() => {
          setCurrentImage({});
          setPreviewModalVisible(false);
        }}
      >
        <img alt="example" src={currentImage.image} className="mt-20" />
      </BaseModal>
      <BaseModal
        classname={"all-images-preview-modal"}
        isModalVisible={previewModalAllPhotosVisible}
        modalTitle={"All photos"}
        modalFooter={null}
        onCancel={() => {
          setPreviewModalAllPhotosVisible(false);
        }}
      >
        <div className="all-photos-content-wrapper scrollbar">
          <div>
            <ListingMainImageSlider isFromModal={true} listingData={listing} />
          </div>
          <div>
            <ListingImagesList
              colViewConfig={{
                span: 4.5,
              }}
              wrapperClassName="modal-all-photos-list"
              fileList={listing?.images}
              showConfirm={showConfirm}
              onPreview={onPreview}
              enablePreview={false}
              canEdit={isAnAgentOfListing}
            />
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default ListingInfo;
