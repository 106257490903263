import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Landlord from "components/Landlord";
import { useParams } from "react-router-dom";
import routes from "../../routes/routesCode";
import { push } from "connected-react-router";
import { getEnums } from "../../store/enums/action";
import { getLandlord } from "../../store/landlords/action";
import { clearListingData } from "../../store/listings/action";
import {useWindowSize} from "../../hooks/useWindowSize";

const LandlordView: React.FC<any> = () => {
  const { landlordId } = useParams();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  useEffect(() => {
    dispatch(getEnums());
  }, [dispatch]);

  useEffect(() => {
    if (!landlordId) {
      dispatch(push(routes.listings.path));
    }
    dispatch(getLandlord({ _id: landlordId }));
    return () => {
      dispatch(clearListingData());
    };
  }, [landlordId,dispatch]);

  return (
    <div
        className={`single-landlord-page-wrapper`}
    >
      <Landlord />
    </div>
  );
};

export default LandlordView;
