import React, { useEffect, useState } from "react";
import BaseModal from "../../Shared/BaseModal";
import BaseButton from "../../Shared/BaseButton";
import FollowUpModalItem from "./components/follow-up-modal-items/FollowUpModalItem";
import "./AllFollowUpsModal.scss";
import FollowUpModalSingleItem from "./components/follow-up-modal-single-item/FollowUpModalSingleItem";
import FollowUpsEmptyState from "./components/follow-up-modal-single-item/empty-state/FollowUpsEmptyState";
import { useDispatch, useSelector } from "react-redux";
import { editedFollowUpSelector, followUpSnoozeSuccessSelector } from "../../../store/followUps/selectors";
import SnoozeSuccess from "./components/follow-up-modal-single-item/sonnze-options/SnoozeSuccess";
import { clearEditedFollowUp, setFollowUpSnoozeSucces } from "../../../store/followUps/action";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { AnalyticsService } from "helpers/analyticsService";

interface IAllFollowUpsModal {
  visible: boolean;
  setVisible: any;
  handleOpenAddFollowUpModal: any;
  notes: any;
  setDeleteConfirmProps?: any;
  handleOpenFollowUpEditModal: any;
  setPhoneVerifyModalVisible: any;
}

const AllFollowUpsModal = ({
  visible,
  setVisible,
  handleOpenAddFollowUpModal,
  notes,
  handleOpenFollowUpEditModal,
  setPhoneVerifyModalVisible,
}: IAllFollowUpsModal) => {
  const dispatch = useDispatch();
  const [singleFollowUpVisible, setSingleFollowUpVisible] = useState(false);
  const [singleFollowUpItem, setSingleFollowUpItem] = useState({});
  const editedFollowUp = useSelector(editedFollowUpSelector);
  const backToFollowUps = () => {
    setSingleFollowUpVisible(false);
    setSingleFollowUpItem({});
  };
  const openSingleFollowUp = (followUp: any) => {
    setSingleFollowUpVisible(true);
    setSingleFollowUpItem(followUp);
  };
  const [snoozeBodyVisible, setSnoozeBodyVisible] = useState(false);
  const snoozeSuccessSelector = useSelector(followUpSnoozeSuccessSelector);
  const { width } = useWindowSize();
  const [isSnoozePickerVisible, setIsSnoozePickerVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      if (Object.keys(editedFollowUp).length > 0) {
        dispatch(clearEditedFollowUp());
      }
      setSingleFollowUpItem({});
      setSingleFollowUpVisible(false);
    }
  }, [visible]);

  useEffect(() => {
    if (Object.keys(editedFollowUp).length > 0) {
      setSingleFollowUpVisible(true);
      setSingleFollowUpItem(editedFollowUp);
    }
  }, [editedFollowUp]);

  return (
    <BaseModal
      classname={`single-listing-page-all-note single-listing-page-all-followUps add-follow-ups-modal-wrapper-all
                 ${singleFollowUpVisible && "add-follow-ups-modal-wrapper-singleFollowUp"} 
                 ${snoozeSuccessSelector && "add-follow-ups-modal-wrapper-all-snooze"} 
                 ${snoozeBodyVisible && "follow-ups-modal-snooze-options"} 
                 ${isSnoozePickerVisible && "follow-ups-modal-snooze-picker"}
                 ${snoozeSuccessSelector && "follow-ups-modal-snoozed-successifully"}`}
      cancelButtonProps={{
        className: "rb-secondary-btn sm",
      }}
      okButtonProps={{
        className: "rb-primary-btn sm",
      }}
      modalTitle=""
      isModalVisible={visible}
      onCancel={() => {
        setVisible(false);
        setSingleFollowUpVisible(false);
        setSnoozeBodyVisible(false);
        dispatch(setFollowUpSnoozeSucces(false));
      }}
      modalFooter={[null]}
      //@ts-ignore
      width={
        width !== null &&
        (snoozeBodyVisible && !snoozeSuccessSelector
          ? 570
          : snoozeSuccessSelector && width > 599
          ? 400
          : snoozeBodyVisible && width <= 599
          ? 290
          : width < 599 && width > 375
          ? 400
          : width < 375
          ? 290
          : 655)
      }
    >
      {!snoozeSuccessSelector && (
        <div
          className={`single-listing-page-all-note--headPart all-followUps-modal-header ${
            singleFollowUpVisible && "single-followUps-modal-header"
          }`}
        >
          {singleFollowUpVisible ? (
            <p
              className={`all-followUps-modal-header--goBack 
                            ${snoozeBodyVisible && "all-followUps-modal-header--goBackSnooze"}
                        `}
              onClick={() => {
                snoozeBodyVisible && !isSnoozePickerVisible
                  ? setSnoozeBodyVisible(false)
                  : snoozeBodyVisible && isSnoozePickerVisible
                  ? setIsSnoozePickerVisible(false)
                  : backToFollowUps();
                dispatch(setFollowUpSnoozeSucces(false));
              }}
            >
              {
                //@ts-ignore
                snoozeBodyVisible && !isSnoozePickerVisible && width < 600 ? (
                  <p>
                    <span>{"<"}</span>
                    <span>When would you like to be reminded?</span>
                  </p>
                ) : //@ts-ignore
                isSnoozePickerVisible && width < 600 ? (
                  <p>
                    <span>{"<"}</span>
                    <span>Snooze until...</span>
                  </p>
                ) : (
                  "< Back"
                )
              }
            </p>
          ) : (
            <>
              <p className={"all-followUps-modal-header--title"}>All Follow-ups</p>
              {notes.length > 0 && (
                <BaseButton
                  classnames={"ant-btn ant-btn rb-secondary-btn rb-secondary-btn-without-shadow"}
                  onClick={() => {
                    handleOpenAddFollowUpModal();
                    AnalyticsService.reportAddFollowUpClick("all_follow_ups_modal_CTA");
                    setVisible(false);
                  }}
                >
                  Add Follow-Up
                </BaseButton>
              )}
            </>
          )}
        </div>
      )}
      <div className={"single-listing-page-all-note--notesWrapper scrollbar"}>
        {snoozeSuccessSelector ? (
          <SnoozeSuccess
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            setAllFollowUpsModalVisible={setVisible}
          />
        ) : (
          <>
            {singleFollowUpVisible ? (
              <FollowUpModalSingleItem
                followUp={singleFollowUpItem}
                snoozeVisible={snoozeBodyVisible}
                setSnoozeVisible={setSnoozeBodyVisible}
                setAllVisible={setVisible}
                setSingleFollowUpVisible={setSingleFollowUpVisible}
                backToFollowUps={backToFollowUps}
                handleOpenEditFollowUpModal={handleOpenFollowUpEditModal}
                setIsSnoozePickerVisible={setIsSnoozePickerVisible}
                isSnoozePickerVisible={isSnoozePickerVisible}
              />
            ) : (
              <>
                {notes.length > 0 ? (
                  notes
                    .filter((item: any) => item.todo_at && item.todo_status !== "completed")
                    .map((e: any, i: number) => {
                      return (
                        <div key={i} className={"single-listing-page-all-note--notesWrapper--item"}>
                          <FollowUpModalItem
                            followUp={e}
                            openSingleFollowUp={openSingleFollowUp}
                            setAllVisible={setVisible}
                            handleOpenEditFollowUpModal={handleOpenFollowUpEditModal}
                          />
                        </div>
                      );
                    })
                ) : (
                  <FollowUpsEmptyState handleOpenAddNoteModal={handleOpenAddFollowUpModal} setVisible={setVisible} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </BaseModal>
  );
};

export default AllFollowUpsModal;
