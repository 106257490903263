import React from "react";
import AddImportListingsSection
    from "../../../../../Listings/components/header/components/add-import-listngs/AddImportListingsSection";

const AuthHeaderListingActions = () => {
    return (
        <AddImportListingsSection />
    );
};

export default AuthHeaderListingActions;
