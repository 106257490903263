
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Form, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  currentStepSelector,
  isOpenListingModalAfterSaveSelector, isPublicListingSelector,
  listingAddSuccessSelector,
  listingDataSelector,
  listingLoadingSelector,
  listingSaveClickedSelector,
  listingUpdateSuccessSelector,
} from "../../../store/listings/selectors";
import {
  customDot,
  getQueryParams,
  openNotification,
} from "../../../helpers/globalFunctions";
import {
  fillListingData,
  getOneListing,
  setCurrentSteps,
  setListingSaveClicked,
  setStepClicked,
} from "../../../store/listings/action";
import "./styles.scss";
import {
  getLandlordsListLP,
  resetSingleLandlordData,
} from "../../../store/landlords/action";
import { push } from "connected-react-router";
import { steps } from "./formSteps";
import ListingCreatedModal from "../ListingCreatedModal";
import { singleLandlordDataSelector } from "../../../store/landlords/selectors";
import { userSelector } from "../../../store/user/selectors";
import { windowSelector } from "../../../store/app/selectors";
import { isCurrentFormChangedSelector } from '../../../store/listings/selectors';
import { loadingEnumsSelector } from "../../../store/enums/selectors";

const { Step } = Steps;

const ListingForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { type, collapsed } = useSelector(windowSelector);

  const { search } = useLocation();
  const { lId } = getQueryParams(search);
  const loading = useSelector(listingLoadingSelector);
  const listingSaveClicked = useSelector(listingSaveClickedSelector);
  const listingUpdateSuccess = useSelector(listingAddSuccessSelector);
  const listingAddSuccess = useSelector(listingUpdateSuccessSelector);
  const listingData = useSelector(listingDataSelector);
  const landlordData = useSelector(singleLandlordDataSelector);
  const userData = useSelector(userSelector);
  const visible = useSelector(isOpenListingModalAfterSaveSelector);
  const current = useSelector(currentStepSelector);
  const isCurrentFormChanged = useSelector(isCurrentFormChangedSelector);
  const isPublicListing = useSelector(isPublicListingSelector);
  const enumsLoading = useSelector(loadingEnumsSelector);
  // useEffect(()=>{
  //   isPublicListing && dispatch(push(lId))
  // },[isPublicListing])
  useEffect(() => {
    if (Object.keys(landlordData).length > 0) {
      dispatch(
        fillListingData({
          landlord_exists: true,
          landlord_name: landlordData.name,
          landlord_email: landlordData.email,
          landlord_phone: landlordData.phone,
          agent_id: userData.agent_id,
        }),
      );
    }
  }, [landlordData]);

  useEffect(() => {
    return () => {
      dispatch(resetSingleLandlordData());
      dispatch(setCurrentSteps(0));
    };
  }, []);

  const formSteps = useMemo(() => steps({ form, currentStep: current }), [current, form]);

  useEffect(() => {
    dispatch(getLandlordsListLP({ page: 1, pageSize: 20 }));
    if (lId) {
      dispatch(getOneListing(lId));
    }
  }, [lId]);

  /*Scroll to top*/
  useEffect(() => {
    window.scroll(0, 0);
  }, [current]);

  useEffect(() => {
    if (listingSaveClicked && (listingUpdateSuccess || listingAddSuccess)) {
      dispatch(push(`listings/${listingData.public_id}`));
    }
    dispatch(setListingSaveClicked(false));
  }, [listingUpdateSuccess, listingAddSuccess]);



  return (
    <div className={"create-listings"}>
      <Steps
        // direction={width && width > 991 ? "horizontal" : "vertical"}
        className={type === "desktop" && !collapsed ? "steps-with-sidebar-open" : "steps-with-sidebar-close"}
        current={current}
        progressDot={customDot}
        onChange={(step) => {
          if (loading) return;
          if (lId) {
            dispatch(setStepClicked(true));
            (async () => {
              try {
                if (isCurrentFormChanged)
                  form.submit();
                setTimeout(() => dispatch(setCurrentSteps(+step)), 0);
              } catch (e) {
                openNotification("error", "Please fill valid data.");
              }
            })();
          }
        }}
      >
        {formSteps.map((item, index) => (
          <Step
            key={index}
            title={item.title}
            data-id={`step_${index}`}
          />
        ))}
      </Steps>
      <div className={"step-content-wrapper pt-4"}>
        <div className={`steps-content ${formSteps[current].title === "Landlord Details" ? "" : ""}`}>{formSteps[current]?.content}</div>
      </div>
      <ListingCreatedModal visible={visible} />
    </div>
  );
};

export default ListingForm;
