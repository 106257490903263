import React from "react";

const UnCheckedRoundedIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7828 2.48738C11.442 -0.828476 6.00891 -0.828476 2.6681 2.48738C-0.671269 5.80324 -0.671269 11.1983 2.6681 14.5141C4.33851 16.1717 6.53216 17.0001 8.72583 17.0001C10.9195 17.0001 13.1124 16.1717 14.7828 14.5141C18.1229 11.1983 18.1229 5.80324 14.7828 2.48738ZM12.2586 11.0062C12.5378 11.2833 12.5378 11.7312 12.2586 12.0083C12.1194 12.1465 11.9367 12.2159 11.7539 12.2159C11.5712 12.2159 11.3884 12.1465 11.2492 12.0083L8.7258 9.50244L6.20306 12.0076C6.06316 12.1457 5.88039 12.2152 5.69836 12.2152C5.51562 12.2152 5.33286 12.1457 5.19366 12.0076C4.91455 11.7305 4.91455 11.2819 5.19366 11.0055L7.7164 8.5004L5.19295 5.99527C4.91385 5.7182 4.91385 5.26961 5.19295 4.99323C5.47135 4.71616 5.92322 4.71616 6.20232 4.99323L8.72577 7.49835L11.2492 4.99323C11.5283 4.71616 11.9795 4.71616 12.2586 4.99323C12.5377 5.26961 12.5377 5.7182 12.2586 5.99527L9.73514 8.5004L12.2586 11.0062Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default React.memo(UnCheckedRoundedIcon);
