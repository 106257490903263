import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { ExclamationMarkIcon } from "components/icons/ExclamationMark";
import LeadFlowWrapper from "components/Requirement/requirement-form/components/lead-flow-wrapper";
import LeadsApi from "api/Leads";
import { generateErrorMessage, openNotification } from "helpers/globalFunctions";
import Message from "components/Shared/Message";
import "./ExpireLink.scss";
import BaseButton from "../../../components/Shared/BaseButton";

const ExpireLink = () => {
    const [sendEmailVerifyCodeSuccess, setSendEmailVerifyCodeSuccess] = useState(false);
    const [sendEmailVerifyCodeError, setSendEmailVerifyCodeError] = useState(null);


    const sendVerifyEmailAgain = async () => {
        try {
            const response: any = await LeadsApi.sendLeadVerifyEmailCode();
            if (response.success) {
                setSendEmailVerifyCodeSuccess(true);
                setTimeout(() => {
                    setSendEmailVerifyCodeSuccess(false);
                }, 2000);
            }
        } catch (err) {
            setSendEmailVerifyCodeError(err);
        }
    };

    useEffect(() => {

        if (sendEmailVerifyCodeError) {
            openNotification("error", generateErrorMessage(sendEmailVerifyCodeError ?? ""));
        }

    }, [sendEmailVerifyCodeError]);

    return (
        <LeadFlowWrapper>
            <div className={"expired-lead-link"}>
                <div className={"avatar-wrapper transparent"}>
                    <Avatar
                        src={undefined}
                        icon={<ExclamationMarkIcon />}
                    />
                </div>
                <div className={"content-title-wrapper"}>
                    <h1>Looks like your link has expired.</h1>
                </div>
                <div className={"content-description-wrapper"}>
                    <span>
                        Lets verify your account so you can start using your link.
                    </span>
                </div>
                <BaseButton
                    onClick={sendVerifyEmailAgain}
                    classnames="mt-25 rb-secondary-btn"
                >
                    Get new link
                </BaseButton>
                {sendEmailVerifyCodeSuccess && <Message text={"Email Sent"} />}
            </div>

        </LeadFlowWrapper>

    );
};

export default ExpireLink;