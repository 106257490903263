import { getBedroomsOrBathroomsRadioBtnGroup } from "helpers/globalFunctions";
import React from "react";
import "./NumberOfRoomsBathrooms.scss";

const NumberOfRoomsBathrooms = () => {
  return (
    <div className={"req-number-of-people-section"}>
      <div className="form-steps-title">
        <p className="fw-400 m-0">How many rooms does it have?</p>
      </div>
      {getBedroomsOrBathroomsRadioBtnGroup(
        "rooms",
        "",
        [{ required: false }],
        "purple-radiobutton-group bedrooms-radio-buttons",
        "parent-labels-to-center",
        "people_living_with_you"
      )}
      <div className="form-steps-title">
        <p className="fw-400 m-0">How many bathrooms does it have?</p>
      </div>
      {getBedroomsOrBathroomsRadioBtnGroup(
        "bathrooms",
        "",
        [{ required: false }],
        "purple-radiobutton-group_bathrooms bathroom-radio-buttons",
        "parent-labels-to-center",
        "employed_people"
      )}
    </div>
  );
};

export default NumberOfRoomsBathrooms;
