import {trimString, urlValidator} from "helpers/globalFunctions";
import {FLATFILE_DATA_KEY_LOCAL_STORAGE, IMPORT_BY_JSON_FIELDS} from "./constants";
import {IObjectKeys} from "../globalTypes";
import {IField} from "@flatfile/adapter/build/main/interfaces/obj.settings";
import {FieldHookCallback} from "@flatfile/adapter/build/main/interfaces/obj.hooks";
import moment from "moment";
import {BulkUploadListingsJsonDto} from "../../components/Listings/helpers/mockListings";

const unitNumberCondition = (flatfileRecordsArray: any, record: any, idx: number): boolean => {
    
    const sameRecord = flatfileRecordsArray.filter((item: any) =>item.index !== idx &&
      trimString(item.street_name) === trimString(record.street_name) &&
      item.street_number === record.street_number &&
      trimString(item.town) === trimString(record.town) &&
      trimString(item.state) === trimString(record.state)  
    );

    return sameRecord.length && sameRecord[0].unit === record.unit;
  };
export const fieldsHookValidationForUnit =  (record: any, index: any) => {
    const flatfileRecordsJSON = localStorage.getItem(FLATFILE_DATA_KEY_LOCAL_STORAGE);
    const flatfileRecordsArray = JSON.parse(flatfileRecordsJSON || JSON.stringify([]));

    if (!flatfileRecordsArray.length)
      return;
    const condition = unitNumberCondition(flatfileRecordsArray, record, index);
    if (condition)
      return {
        unit: {
          value: "",
          info: [{ message: "You must change the unit number", level: "error" }]
        }
      };
  };

export const importByJsonFields = (flatfileEnums?: IObjectKeys[], fields?: IField[]) => {
    if (!fields) fields = IMPORT_BY_JSON_FIELDS;
    if (!flatfileEnums) return fields;
    const flatfileEnumsKeys = flatfileEnums?.map((flatfileEnum: IObjectKeys) => {
        return flatfileEnum.key;
    });
    const fieldKeysNeedChanges = fields.filter(field => flatfileEnumsKeys.includes(field.key));
    fieldKeysNeedChanges.forEach(field => {
        const targetEnum = flatfileEnums.find(fEnum => fEnum.key === field.key);
        if (field.type === "select")
            field.options = targetEnum?.options;
    });

    return IMPORT_BY_JSON_FIELDS;
}

const fieldsHookValidationFoNumbersRegex: FieldHookCallback = (values) =>
    values.map(([item, index]) =>
        [{value: item ? +item.toString().replace(/\D/g, "") : ""}, index]
    );

const fieldsHookValidationFoDates: FieldHookCallback = (values) => {
    let validatedValues: any = [];
    values.forEach((item: any) => {
        if (moment(item).isValid()) {
            validatedValues.push([{value: item[0] || ""}, item[1]]);
        } else if (item[0]) {
            validatedValues.push([
                {
                    info: [
                        {
                            message: "Not Valid Date.",
                            level: "error"
                        }
                    ]
                },
                item[1]
            ]);
        }
    });

    return validatedValues;
};
const fieldsHookValidationFoImages: FieldHookCallback = (values) => {
    let validatedValues: any = [];
    values.forEach((item: any, idx) => {
        if (urlValidator(item[0]) || item[0] === "") {
            const imagesArray = item[0].length ? item[0].split(",").map((i: string) => i.trim()) : [];
            validatedValues.push([{value: imagesArray.length ? imagesArray : []}, item[1]]);
        } else {
            validatedValues.push([
                {
                    info: [
                        {
                            message: "Not Valid Url",
                            level: "error"
                        }
                    ]
                },
                item[1]
            ]);
        }
    });

    return validatedValues;
};

const fieldsHookValidationFoAnyTypeValues: FieldHookCallback = (values) => {
    const regexp = new RegExp("^(?:0|n(?:o)?|f(?:alse)?|off)$", "i");
    let validatedValues: any = [];
    values.forEach((item: any, idx) => {
        const isTrue = !(!item[0] || regexp.test(item[0].trim()));
        validatedValues.push([{value: isTrue}, item[1]]);
    });
    return validatedValues;
};

export const FIELD_HOOKS = {
    street_number: (values: any[]) => values.map(([item, index]) => [{value: Number(item) || ""}, index]),
    asked_rent: fieldsHookValidationFoNumbersRegex,
    bedrooms: fieldsHookValidationFoNumbersRegex,
    bathrooms: fieldsHookValidationFoNumbersRegex,
    availability_date: fieldsHookValidationFoDates,
    lease_ending_date: fieldsHookValidationFoDates,
    images: fieldsHookValidationFoImages,
    move_in_fees: fieldsHookValidationFoAnyTypeValues,
    parking_type: fieldsHookValidationFoAnyTypeValues,
    floor_number: fieldsHookValidationFoNumbersRegex,
    zip_code: fieldsHookValidationFoNumbersRegex,
    living_area_size: fieldsHookValidationFoNumbersRegex,
};

export const mapFlatfileData = (data: BulkUploadListingsJsonDto[]) => {
    if (data[0].available) {
        data[0].available = data[0].available === "Off-market" ? false : data[0].available === "Available" ? true : data[0].available;
    }

    return data;
};
