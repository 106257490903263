import React from "react";
import { useDispatch } from "react-redux";
import BaseCollapse from "../Shared/BaseCollapse";
import BaseEmptyState from "../BaseEmptyState";
import queryString from "query-string";
import { push } from "connected-react-router";
import SectionHeading from "components/Shared/Section/SectionHeading";

const ListingDescriptionSection = ({ listingData }: any) => {
  const dispatch = useDispatch();

  const handleAddBtnClick = () => {
    const parsed = {
      // landlordId:
      lId: listingData._id,
      step: 6,
    };
    const stringified = queryString.stringify(parsed);
    dispatch(push(`/manageListing?${stringified}`));
  };

  const descriptionEmptyStateProps = {
    title: "Description",
    description: "No description for this listing yet.",
    linkText: "+ Add Description",
    openWithModal: false,
    handleAddBtnClick,
    modalType: "lead",
    modalProps: {
      isModalVisible: false,
      children: "",
      modalTitle: "",
    },
  };

  return (
    <BaseCollapse header={<SectionHeading heading="Description" />} collapse={false}>
      {listingData && listingData.description ? (
        <div className="text-base">{listingData.description}</div>
      ) : (
        <BaseEmptyState {...descriptionEmptyStateProps} />
      )}
    </BaseCollapse>
  );
};

export default React.memo(ListingDescriptionSection);
