import React from "react";
import { useSelector } from "react-redux";
import BaseCollapse from "../Shared/BaseCollapse";
import { enumsSelector } from "../../store/enums/selectors";
import SectionHeading from "components/Shared/Section/SectionHeading";
import { beautifiedKeyStyling } from "helpers/globalFunctions";
import PassportFieldsEnum from "components/Shared/Fields/PassportFieldsEnum";
import isNil from "lodash/isNil";

const ListingFeaturesSection = ({ listingData }: any) => {
  const enums = useSelector(enumsSelector);

  const renderFieldValue = (value: any): any => {
    if (value instanceof Array) {
      return value.map((item: any) => renderFieldValue(item));
    }
    if (value instanceof Object) {
      if (value.area) {
        return <span key={`item-value-${value.area}`}>{value.area}</span>;
      }
      if (value.species) {
        return (
          <span key={`item-value-${value.species}`}>
            {value.species} x{value.amount} {value.breed && <>({value.breed})</>}
          </span>
        );
      }
      return Object.keys(value)
        .map((key) => (value[key] === true ? `${key}`.replaceAll("_", " ") : `${value[key]}`))
        .join(", ");
    }

    return <span>{beautifiedKeyStyling(value)}</span>;
  };

  const prepareFeatureFields = () => {
    const preparedFields = PassportFieldsEnum.filter(
      (field: any) => !["unit_type", "source", "move_in_fees", "status"].includes(field.key)
    )
      .filter((field: any) => !!listingData[field.key])
      .sort((a: any, b: any) => {
        if (isNil(a.order)) return 1;
        if (isNil(b.order)) return -1;
        return a.order > b.order ? 1 : -1;
      })
      .map((field: any) => {
        return {
          ...field,
          value: listingData[field.key],
        };
      });
    return preparedFields;
  };

  if (!listingData) return null;

  return (
    <BaseCollapse header={<SectionHeading heading="Features" />} collapse={false}>
      <div className="mb-2">
        <ul className="list-disc ml-5">
          {prepareFeatureFields().map((field: any) => {
            return (
              <li className="py-1">
                {field.label} - <span className="font-semibold">{renderFieldValue(field.value)}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </BaseCollapse>
  );
};

export default React.memo(ListingFeaturesSection);
