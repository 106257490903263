import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { XCircleIcon } from "@heroicons/react/20/solid";
import GlobalLoading from "components/Shared/GlobalLoading";
import { useDispatch, useSelector } from "react-redux";
import { routerSelector } from "store/listings/selectors";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "helpers/globalFunctions";
import algoliasearch from "algoliasearch/lite";
import { SearchResponse } from "@algolia/client-search";
import ThumbtackApi from "api/Thumbtack";
import ThumbtackProsGrid from "./ThumbtackProsGrid";
import SearchComboBox from "./Form/SearchComboBox";
import { MapPinIcon } from "@heroicons/react/24/outline";
import RenterPortal from "api/RenterPortal";
import BaseButton from "components/Shared/BaseButton";

// Algolia configuration
const algoliaClient = algoliasearch("Q4NFZKK3PM", "b16feb35b2176198a33b7afdac69af24");
const algoliaIndex = algoliaClient.initIndex("dev_thumbtack_categories");

const Alert = ({ error }: { error: string }) => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{error}</h3>
        </div>
      </div>
    </div>
  );
};

const ServicesSearch = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [suggestions, setSuggestions] = useState<SearchResponse["hits"]>([]);
  const [zipCode, setZipCode] = useState<any>(localStorage.getItem("zipCode") || "");
  const [pros, setPros] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fetchingZipCode, setFetchingZipCode] = useState<boolean>(false);

  const router = useSelector(routerSelector);

  const { search } = useLocation();
  const queryParams = getQueryParams(search);

  useEffect(() => {
    if (category && category.length >= 3 && zipCode?.length) {
      // Fetch options from Algolia when searchText is provided and has a minimum length of 3
      doSearch({ category, zipCode });
      fetchAlgoliaCategories(category);
    } else {
      // Fetch options from Thumbtack API when searchText is not provided or is less than 3 chars
    }
  }, [category]);

  const fetchAlgoliaCategories = async (category: string) => {
    const { hits } = await algoliaIndex.search(category, {
      hitsPerPage: 5,
    });
    setSuggestions(hits);
  };

  const doSearch = async ({ category, zipCode }: { category: string; zipCode: string }) => {
    setError(null);
    if (!category || !zipCode) return false;
    dispatch(
      push({
        pathname: router.location.pathname,
        search: `zipCode=${zipCode}&category=${category}`,
      })
    );

    try {
      setIsSearching(true);
      const results: any = await ThumbtackApi.getProsList(category, zipCode);
      setIsSearching(false);
      setPros(results);
    } catch (error) {
      setIsSearching(false);
      setPros([]);
      setError(`Uh oh! There was an issue when trying to find ${category} pros near ${zipCode}. Check your zip code and try again!`);
      console.error("Error fetching pros:", error);
    }
  };

  const resetSearch = () => {
    dispatch(
      push({
        pathname: router.location.pathname,
        search: ``,
      })
    );
    setError(null);
    setIsSearching(false);
    setCategory("");
    setSuggestions([]);
    setPros([]);
  };

  // Function to fetch options from Thumbtack API based on user input
  const fetchThumbtackFixedCategories = async () => {
    try {
      const fc = await ThumbtackApi.getCategoriesList().then((results: any) => {
        setSuggestions(results);
      });
    } catch (error) {
      setError(error);
      console.error("Error fetching Thumbtack categories:", error);
    }
  };

  // On mount, init search from url
  useEffect(() => {
    fetchThumbtackFixedCategories();
    if (queryParams?.category && queryParams.zipCode) {
      setCategory(queryParams.category);
      setZipCode(queryParams.zipCode);
      doSearch(queryParams);
    }
    if (localStorage.getItem("zipCode")) {
      setZipCode(localStorage.getItem("zipCode"));
    }
    return () => {};
  }, []);

  const getZipCodeFromLatLng = async ({ lat, lng }: { lat: number; lng: number }) => {
    setFetchingZipCode(false);
    const zipData: any = await RenterPortal.getZipCodeFromLatLnt({ lat, lng });
    if (zipData?.zipcode) {
      setZipCode(zipData.zipcode);
      localStorage.setItem("zipCode", zipData.zipcode);
    }
  };

  useEffect(() => {
    if (!zipCode && navigator.geolocation) {
      setFetchingZipCode(true);
      navigator.geolocation.getCurrentPosition(function (position) {
        const { latitude, longitude } = position.coords;
        getZipCodeFromLatLng({ lat: latitude, lng: longitude });
      });
    }
  }, [navigator.geolocation]);

  return (
    <div>
      <div className="relative mt-2 rounded-md shadow-sm mb-8">
        {error && (
          <div className="mb-4">
            <Alert error={error} />
          </div>
        )}
        <div className="flex -space-x-px">
          <div className="w-full min-w-0">
            <SearchComboBox onChange={setCategory} onReset={resetSearch} defaultQuery={category} defaultSuggestions={suggestions} />
          </div>
          <div className="relative w-50">
            <input
              autoComplete={"off"}
              value={zipCode}
              placeholder="Zip Code"
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  doSearch({ category, zipCode });
                }
              }}
              className="w-44 border-0 p-4 pl-12 pr-10 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 text-xl sm:leading-6"
            />
            <div className="absolute inset-y-0 left-2 flex items-center rounded-r-md px-2 focus:outline-none">
              <MapPinIcon className="h-6 w-6 text-purple-400" aria-hidden="true" />
            </div>
            {!!zipCode && (
              <div
                className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none cursor-pointer"
                onClick={() => {
                  setZipCode("");
                  localStorage.removeItem("zipCode");
                }}
              >
                <XCircleIcon className="h-5 w-5 text-gray-200" aria-hidden="true" />
              </div>
            )}
            {fetchingZipCode && (
              <div className="absolute rounded-full px-2 py-1 ml-4 bg-purple-100 -bottom-3 mx-auto text-center text-xs text-gray-400">
                Fetching zip code...
              </div>
            )}
          </div>
          <div
            placeholder="Zip Code"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doSearch({ category, zipCode });
              }
            }}
            onClick={() => doSearch({ category, zipCode })}
            className="w-40 rounded-r-full border-0 p-4 px-10 text-white bg-purple-600 hover:bg-purple-700 shadow-sm  text-xl sm:leading-6 cursor-pointer"
          >
            Search
          </div>
        </div>
      </div>
      <Spin spinning={isSearching} className={"global-loading"} indicator={<GlobalLoading />}>
        <div className="pb-10">{!isSearching && <ThumbtackProsGrid services={pros} />}</div>
      </Spin>
    </div>
  );
};

export default ServicesSearch;
