import React, { useCallback, useEffect, useState } from "react";
import { Form, Input } from "antd";
import {
  NOT_REQUIRED_EMAIL_VALIDATION,
  NOT_REQUIRED_PHONE,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
} from "../../../../../helpers/validations";
import MaskedInput from "../../../../Shared/MaskedPhoneInput";
import { useForm } from "antd/es/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { currentLandlordSelector, landlordCreateSuccessSelector } from "../../../../../store/landlords/selectors";
import { IObjectKeys } from "../../../../../helpers/globalTypes";
import { sanitizePhoneNumber } from "../../../../../helpers/globalFunctions";
import { createLandlord, editLandlord, setLandlordCreatedSuccessToFalse } from "../../../../../store/landlords/action";
import BaseButton from "../../../../Shared/BaseButton";
import { push } from "connected-react-router";

const EmptyPageAddLandlordForm = () => {
  const [form] = useForm();
  const [isPrivate, setIsPrivate] = useState(false);
  const dispatch = useDispatch();
  const currentLandlord = useSelector(currentLandlordSelector);
  const landlordAddedSuccess = useSelector(landlordCreateSuccessSelector);

  useEffect(() => {
    if (landlordAddedSuccess) {
      setTimeout(() => {
        dispatch(push("/landlords"));
      }, 1500);
    }
  }, [landlordAddedSuccess]);
  useEffect(() => {
    return () => {
      dispatch(setLandlordCreatedSuccessToFalse());
    };
  }, []);
  const onSubmit = (data: IObjectKeys) => {
    const submitData: IObjectKeys = {
      ...data,
      email: data.email || undefined,
      phone: data.phone || undefined,
    };

    if (submitData.phone) {
      submitData.phone = sanitizePhoneNumber(data.phone);
    }

    if (currentLandlord.landlord_id) {
      const formData: any = {
        _id: currentLandlord.landlord_id,
        is_private: isPrivate,
        name: submitData.name.trim(),
      };
      if (submitData.email && submitData.email.length > 0) {
        formData.email = submitData.email;
      }
      if (submitData.phone?.length > 0) {
        formData.phone = submitData.phone;
      }

      return dispatch(editLandlord(formData));
    }
    dispatch(createLandlord({ ...submitData, is_private: isPrivate }));
  };
  useEffect(() => {
    if (currentLandlord && Object.keys(currentLandlord).length) {
      form.setFieldsValue({
        name: currentLandlord.name,
        phone: currentLandlord.phone,
        email: currentLandlord.email,
        landlord_description: currentLandlord.landlord_description,
      });
      setIsPrivate(currentLandlord.is_private);
    }
  }, [currentLandlord]);

  const handleChangePrivacy = useCallback((e: any) => {
    e.persist();
    setIsPrivate((prevState) => !prevState);
  }, []);
  return (
    <>
      <div className="flex justify-center text-center">
        <h1>Add a Landlord </h1>
      </div>
      <Form className={"manage-landlord-modal-form"} form={form} initialValues={{ remember: true }} onFinish={onSubmit} layout={"vertical"}>
        <Form.Item
          label="Landlord Full Name"
          name="name"
          rules={[
            { required: true, message: "Please input landlord name!" },
            {
              validator: (_, value) => (value.trim() ? Promise.resolve() : Promise.reject(new Error("No spaces allowed"))),
            },
          ]}
        >
          <Input autoComplete={"off"} data-test-id="add-landLoard-fullName" placeholder={"Enter landlord full name"} />
        </Form.Item>
        <Form.Item
          label="Landlord Phone Number "
          name="phone"
          rules={[
            NOT_REQUIRED_PHONE,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value && !getFieldValue("email")) {
                  return Promise.reject(new Error("Phone or email is required!"));
                }
                return Promise.resolve();
              },
            }),
          ]}
          validateTrigger={"onBlur"}
        >
          <MaskedInput
            inputMode="numeric"
            data-test-id="add-landLoard-Phone"
            mask={PHONE_NUMBER_MASK}
            placeholder={PHONE_NUMBER_PLACEHOLDER}
          />
        </Form.Item>
        <Form.Item
          label="Landlord E-mail *"
          name="email"
          rules={[
            NOT_REQUIRED_EMAIL_VALIDATION,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value && !getFieldValue("phone")) {
                  return Promise.reject(new Error("Email or phones   is required!"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input autoComplete={"off"} data-test-id="add-landLoard-email" placeholder={"Enter landlords email"} />
        </Form.Item>
        <Form.Item
          className={"set-private-section"}
          label={
            <div className={"set-private-section--text"}>
              <span>Set Landlord as Private?</span>
              <span>Only you will see Landlords details</span>
            </div>
          }
          name="is_private"
        >
          <div className="switch-btn-section">
            <div className="switch-btn-wrapper">
              <span className="switcher switcher-1">
                <Input
                  name="is_private"
                  onChange={handleChangePrivacy}
                  checked={isPrivate}
                  type="checkbox"
                  id="switcher-1"
                  data-test-id="add-landLoard-isPrivate"
                />
                <label htmlFor="switcher-1" className={"switcher-purple"} />
              </span>
            </div>
          </div>
        </Form.Item>
        <BaseButton dataTestId="ok-button" classnames={"rb-secondary-btn sm"} type={"submit"}>
          Submit
        </BaseButton>
      </Form>
    </>
  );
};

export default EmptyPageAddLandlordForm;
