import { useSelector } from "react-redux";
import { listingDataSelector } from "store/listings/selectors";
import { userSelector } from "store/user/selectors";

const ListingGuard = ({ condition = true, listing, children }: { agentId?: string; listing?: any; condition?: boolean; children: any; }) => {
  const userData = useSelector(userSelector);
  const userAgencyId = userData?.agency?.id;
  const listingData = listing ?? useSelector(listingDataSelector);
  const listingAgencyId = listingData?.agency_id;
  const isCoListingAgent = listingData?.co_list_agents?.includes(userData.agent_id);
  const isRenter = userData?.role === "Renter";

  if ((userAgencyId === listingAgencyId || isCoListingAgent) && !isRenter) {
    return children;
  }

  return null;
};

export default ListingGuard;
