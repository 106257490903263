import React from "react";
import BaseButton from "../../Shared/BaseButton";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { List } from "antd";
import { useWindowSize } from "../../../hooks/useWindowSize";
import DesktopSkeleton from "./desktop-elements/DesktopSkeleton";
import MobileSkeleton from "./mobile-elements/MobileSkeleton";
import { useDemoContext } from "libs/contextLib";

interface IListingNotesListItemProps {
  handleOpenNoteModal: Function;
  item: {} | any;
  setDeleteConfirmProps: Function;
  listingNotesLoading: any;
  dueContent: any;
  leftOrAgoGenerator: any;
  onShowNote?:any;
  setSelectedNote?:any
}

const ListingNotesListItem = ({
  handleOpenNoteModal,
  item,
  setDeleteConfirmProps,
  listingNotesLoading,
  dueContent,
  leftOrAgoGenerator,
  onShowNote,
  setSelectedNote
}: IListingNotesListItemProps) => {
  const { isDemo, demoAction } = useDemoContext();
  const handleClick = () => {
    if (isDemo) {
      demoAction("landlord");
    } else {
      onShowNote();
      setSelectedNote(item);
    }
  }

  return (
    <List.Item
      className={"listing-single-note"}
      onClick={handleClick}
    >
          <MobileSkeleton
            dueContent={dueContent}
            listingNotesLoading={listingNotesLoading}
            item={item}
            leftOrAgoGenerator={leftOrAgoGenerator}
          />
    </List.Item>
  );
};

export default ListingNotesListItem;
