import React from "react";
import SignIn from "../../components/SignIn/index";

const SignInPage: React.FC = () => {
  return (
      <SignIn/>
  );
};

export default SignInPage;
