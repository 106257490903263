import React, { useEffect } from "react";
import { Avatar, Button, Modal, Spin, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { push } from "connected-react-router";
import { openNotification, phoneMaskOnString } from "../../../helpers/globalFunctions";
import { sendLandlordInfoBySms, resetSingleLandlordData } from "../../../store/landlords/action";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../store/user/selectors";
import {
  landlordLoadingSelector,
  landlordNotesLoadingSelector,
  landlordNotesSelector,
  sendLandlordInfoLoadingSelector,
} from "../../../store/landlords/selectors";
import "./styles.scss";
import BaseButton from "../../Shared/BaseButton";
import ShowNotesCM from "./ShowNotesCM/ShowNotesCM";
import { IObjectKeys } from "helpers/globalTypes";
import { AnalyticsService } from "helpers/analyticsService";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { ROLE_TYPES } from "helpers/constants";
import { useLocation } from "react-router-dom";
import { sendLeadInfoBySms } from "../../../store/leads/action";
import { windowSelector } from "../../../store/app/selectors";
import { UserOutlined } from "@ant-design/icons";
import { useAppContext } from "libs/contextLib";
import { EnvelopeOpenIcon, PhoneIcon } from "@heroicons/react/24/outline";
const { Text } = Typography;

interface ILandlordModal {
  setModalProps?: any;
  currentLandlordData: any;
  showModal: any;
  onClose: any;
  currentLeadData?: IObjectKeys;
  setAfterCallModalVisible?: any;
  setNoteModalVisible?: any;
  isPublicListing?: any;
  setAction?: (data: string) => void;
}

const ContactModal = ({
  currentLandlordData,
  showModal,
  onClose,
  currentLeadData,
  setAfterCallModalVisible,
  setNoteModalVisible,
  isPublicListing,
  setAction,
}: ILandlordModal) => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelector);
  const landlordLoading = useSelector(landlordLoadingSelector);
  const sendLandlordInfoLoading = useSelector(sendLandlordInfoLoadingSelector);
  const landlordNotes = useSelector(landlordNotesSelector);
  const landlordNotesLoading = useSelector(landlordNotesLoadingSelector);
  const contactData = currentLeadData || currentLandlordData;
  const { width } = useWindowSize();
  const { type } = useSelector(windowSelector);
  const location = useLocation();
  const { isAuthenticated } = useAppContext();

  if (!showModal) return null;

  const isLandlord =
    (userData?.agent_id !== currentLandlordData?.created_by && currentLandlordData?.is_private) ||
    !currentLandlordData?.landlord_id ||
    !currentLandlordData?.created_by;
  const isPrivate =
    currentLandlordData?.is_private &&
    userData?.agent_id !== currentLandlordData?.created_by &&
    userData.role !== ROLE_TYPES.OWNER;

  const title = `Contact ${
    currentLeadData?.agentUser_ids?.includes(userData?.agent_id)
      ? "Lead"
      : currentLeadData || isPublicListing
      ? "Agent"
      : "Landlord"
  }`;

  const isContactEmail =
    contactData.email ||
    contactData.renterOfRequirement?.email ||
    (title === "Contact Agent" &&
      currentLeadData?.agentsOfRequirement &&
      currentLeadData?.agentsOfRequirement[0]?.email);
  const isContactPhone =
    contactData.phone ||
    contactData.renterOfRequirement?.phone ||
    (title === "Contact Agent" &&
      currentLeadData?.agentsOfRequirement &&
      currentLeadData?.agentsOfRequirement[0]?.phone);

  return (
    <Modal
      wrapClassName="landlord-modal-wrapper quick-contact-modal"
      visible={showModal}
      onCancel={() => onClose(false)}
      footer={null}
      closeIcon={
        <span className="modal-close-btn">
          <CloseOutlined />
          Close
        </span>
      }
      width={
        //@ts-ignore
        width <= 599 ? 298 : 367
      }
      title={title}
    >
      {landlordLoading || landlordNotesLoading ? (
        <div className="flex justify-center">
          <Spin />
        </div>
      ) : (
        <>
          <div className="landlord-info-wrapper">
            {isPublicListing && (
              <div className="mb-15">
                <Avatar src={contactData?.imagesOfAgent?.url} icon={<UserOutlined />} size={56} />
              </div>
            )}
            <div className="landlord-name-link">
              {!isPrivate && !currentLeadData ? (
                !isPublicListing ? (
                  <BaseButton
                    variant="link"
                    onClick={() => {
                      dispatch(push(`/landlords/${currentLandlordData?._id}`));
                      AnalyticsService.reportEvent("contact_card_clicks", {
                        category: "General",
                        label: "landlord link",
                      });
                    }}
                  >
                    <span className="text-xl font-semibold">{currentLandlordData.name}</span>
                  </BaseButton>
                ) : (
                  <span className="text-xl font-semibold">{currentLandlordData.name}</span>
                )
              ) : (
                <span className="ant-typography">
                  {currentLeadData?.name ||
                    currentLeadData?.renterOfRequirement?.name ||
                    currentLandlordData?.name ||
                    currentLeadData?.agentsOfRequirement[0]?.name}
                </span>
              )}
              {currentLandlordData?.listingsCount ? (
                <div className="listings-count">
                  <span>
                    ({currentLandlordData?.listingsCount}{" "}
                    {currentLandlordData?.listingsCount === 1 ? "Listing" : "Listings"} )
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="contact-info-wrapper">
              <div className="contact-info-wrapper--right">
                <span>
                  {isContactPhone && (
                    <Text className=" size-16">
                      {phoneMaskOnString(
                        contactData?.phone ||
                          contactData?.renterOfRequirement?.phone ||
                          currentLeadData?.agentsOfRequirement[0]?.phone
                      )}
                    </Text>
                  )}
                </span>
                <span>
                  {isContactEmail && (
                    <Text className="size-16">
                      {contactData.email ||
                        contactData?.renterOfRequirement?.email ||
                        currentLeadData?.agentsOfRequirement[0]?.email}
                    </Text>
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="modal-btn-wrapper mt-6">
            {isContactPhone ? (
              <BaseButton
                classnames="rb-secondary-btn md rb-secondary-btn-without-shadow contact-button"
                href={`tel:${
                  contactData.phone?.replaceAll(/[^0-9]/g, "") ||
                  contactData?.renterOfRequirement?.phone.replaceAll(/[^0-9]/g, "") ||
                  currentLeadData?.agentsOfRequirement[0]?.phone?.replaceAll(/[^0-9]/g, "")
                }`}
                target="_blank"
                onClick={() => {
                  onClose(false);
                  if (!isPublicListing) {
                    if (currentLandlordData?.landlord_id || currentLeadData) {
                      setAction && setAction("Called");
                    }
                    setAfterCallModalVisible && setAfterCallModalVisible(true);
                  }
                  AnalyticsService.reportEvent("contact_card_clicks", {
                    category: "General",
                    label: "phone",
                  });
                }}
                icon={<PhoneIcon className="h-4 w-4" />}
              >
                Call Now
              </BaseButton>
            ) : (
              ""
            )}
            {isContactEmail ? (
              <BaseButton
                classnames="rb-secondary-btn rb-secondary-btn-without-shadow md ml-15 contact-button"
                href={`mailto:${
                  contactData.email ||
                  contactData?.renterOfRequirement?.email ||
                  currentLeadData?.agentsOfRequirement[0]?.email
                }`}
                target="_blank"
                onClick={() => {
                  onClose(false);
                  localStorage.setItem("contact_email_selected", "Yes");
                  if (!isPublicListing) {
                    if (currentLandlordData?.landlord_id || currentLeadData) {
                      setAction && setAction("Emailed");
                    }
                    setAfterCallModalVisible && setAfterCallModalVisible(true);
                  }
                  AnalyticsService.reportEvent("contact_card_clicks", {
                    category: "General",
                    label: "email",
                  });
                }}
                icon={<EnvelopeOpenIcon className="h-4 w-4" />}
              >
                Email
              </BaseButton>
            ) : (
              ""
            )}
          </div>
          {isAuthenticated && isContactPhone && type !== "mobile" ? (
            <div className="send-btn-wrapper flex-centered">
              <Button
                type={"link"}
                loading={sendLandlordInfoLoading}
                className="mt-6"
                onClick={() => {
                  if (!userData.phone) {
                    AnalyticsService.reportEvent("contact_card_clicks", {
                      category: "General",
                      label: "send to my mobile - no phone",
                    });
                    return openNotification("error", "You didn't set phone number.");
                  }
                  if (!userData.phone_verified) {
                    AnalyticsService.reportEvent("contact_card_clicks", {
                      category: "General",
                      label: "send to my mobile - not verified",
                    });
                    return openNotification("error", "Your phone is not verified.");
                  }
                  if (location.pathname === "/") {
                    dispatch(sendLeadInfoBySms(currentLeadData?.renter_id));
                  } else {
                    dispatch(sendLandlordInfoBySms(currentLandlordData?._id));
                  }
                  AnalyticsService.reportEvent("contact_card_clicks", {
                    category: "General",
                    label: "send to my phone",
                  });
                }}
              ></Button>
            </div>
          ) : (
            ""
          )}
          {landlordNotes.length && !isLandlord ? <ShowNotesCM notes={landlordNotes} /> : ""}
          {landlordNotes.length && !isLandlord ? (
            <span
              className={"landlord-modal-wrapper--seeAllNotes"}
              onClick={() => {
                localStorage.setItem("allNotesClicked", "clicked");
                setNoteModalVisible({ visible: true, editModal: false });
                onClose(false);
              }}
            >
              See All Notes
            </span>
          ) : (
            ""
          )}
        </>
      )}
    </Modal>
  );
};

export default React.memo(ContactModal);
