import Message from "components/Shared/Message";
import React from "react";
import { Link } from "react-router-dom";
import routes from "routes/routesCode";

interface IgetNoteComponentProps {
  notification: string;
}

export const getNoteComponent = ({ notification }: IgetNoteComponentProps, isRenter?: boolean) => {
  switch (notification) {
    case "usersPhoneVerified":
      return (
        <Message
          type="noIcon"
          text="⚠️ &nbsp;You haven't validated your phone yet"
          messageClassName={`alert-new`}
          close={true}
        >
          <span className="location-text">
            &nbsp;Go to <Link to={isRenter ? routes.renterPortalSettings.path : routes.settings.path}>settings.</Link>
          </span>
        </Message>
      );
  }
};
