import { TrashIcon } from "@heroicons/react/24/outline";
import BaseButton from "components/Shared/BaseButton";
import React, { useState, useRef } from "react";

const PET_SPECIES = [
  { key: "dog", label: "Dog" },
  { key: "cat", label: "Cat" },
  { key: "other", label: "Other" },
];

interface IPropsPetsInput {
  field: any;
  value: any;
  onChange?: any;
}

interface newPet {
  species: string;
  amount: number;
  breed?: string;
}

const PetsInput = ({ onChange, field, value }: IPropsPetsInput) => {
  const speciesRef = useRef<HTMLSelectElement>(null);
  const amountRef = useRef<HTMLSelectElement>(null);
  const breedRef = useRef<HTMLSelectElement>(null);
  const [newPet, setNewPet] = useState<any>({});

  const handleUpdateNewPet = (key: string, val: any) => {
    setNewPet({ ...newPet, [key]: val });
  };
  const handleAddNewPet = () => {
    if (!newPet) return;
    onChange({ field, value: [...value, newPet] });
  };
  const handleRemoveValueIndex = (value: any, idx: number) => {
    value.splice(idx, 1);
    onChange({ field, value });
  };

  const breedPlaceholder = newPet.species !== "other" ? `Type of ${newPet.species}?` : `Type of pet?`;

  return (
    <div>
      {value.map((pet: any, idx: number) => {
        return (
          <div key={`${pet.species}${idx}`} className="py-2 flex justify-between">
            {pet.species} x{pet.amount} {pet.breed && <>({pet.breed})</>}
            <BaseButton size="sm" onClick={() => handleRemoveValueIndex(value, idx)}>
              <TrashIcon className="h-4 w-4 text-purple-400" aria-hidden="true" />
            </BaseButton>
          </div>
        );
      })}
      <div className="flex justify-start mt-6">
        <select
          ref={speciesRef}
          className="mr-2 block w-full rounded-md border-0 py-1.5 text-gray-700 shadow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-lg sm:text-base sm:leading-6"
          onChange={(e) => handleUpdateNewPet("species", e.target.value)}
        >
          <option>Choose type of pet</option>
          {PET_SPECIES.map((pet: any) => {
            return (
              <option value={pet.key} key={`${pet.key}-${pet.label}`}>
                {pet.label}
              </option>
            );
          })}
        </select>
        <select
          ref={amountRef}
          onChange={(e) => handleUpdateNewPet("amount", e.target.value)}
          className="mr-2 block w-full shadow rounded-md border-0 py-1.5 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-lg sm:text-base sm:leading-6"
        >
          <option>Number of pets</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="many">Many</option>
        </select>
      </div>
      <div className="flex justify-start mt-6 gap-2">
        <input
          placeholder={breedPlaceholder}
          onChange={(e) => handleUpdateNewPet("breed", e.target.value)}
          className={`block rounded-md shadow border-0 py-1.5 p-3 text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6`}
        />
        <BaseButton onClick={() => handleAddNewPet()} disabled={!newPet.species || !newPet.amount} size="sm">
          <div className="whitespace-nowrap">Add Pet</div>
        </BaseButton>
      </div>
    </div>
  );
};

export default PetsInput;
