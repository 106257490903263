import React from "react";

const SidebarTabletArrow = () => {
    return (
        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.431744 9.91089L9.48345 0.451532C10.0593 -0.150509 10.9928 -0.150509 11.5683 0.451532C12.1439 1.05304 12.1439 2.02862 11.5683 2.63007L3.55896 11.0002L11.5681 19.37C12.1437 19.9717 12.1437 20.9472 11.5681 21.5487C10.9925 22.1504 10.059 22.1504 9.48322 21.5487L0.43151 12.0892C0.143727 11.7883 -9.80173e-07 11.3943 -9.45716e-07 11.0002C-9.11243e-07 10.6059 0.144008 10.2116 0.431744 9.91089Z"
                fill="#333333"/>
        </svg>
    )
}
export default SidebarTabletArrow