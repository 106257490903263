import { RootStateOrAny } from "react-redux";

export const inviteAgentFailSelector = (state: RootStateOrAny) =>
  state.teamsReducer.inviteAgentFail;
export const agentErrorSelector = (state: RootStateOrAny) =>
  state.teamsReducer.agentError;
export const inviteAgentSuccessSelector = (state: RootStateOrAny) =>
  state.teamsReducer.inviteAgentSuccess;
export const resetInviteAgentSuccessSelector = (state: RootStateOrAny) =>
  state.teamsReducer.resetInviteAgentSuccess;
export const removeAgentFromTeamSuccessSelector = (state: RootStateOrAny) =>
  state.teamsReducer.removeAgentFromTeamSuccess;
export const teamMembersSelector = (state: RootStateOrAny) =>
  state.teamsReducer.teamMembers;
export const teamMembersTotalSelector = (state: RootStateOrAny) =>
  state.teamsReducer.total;
export const TeamMembersErrorSelector = (state: RootStateOrAny) =>
  state.teamsReducer.teamMembersError;
export const teamMembersLoadingSelector = (state: RootStateOrAny) =>
  state.teamsReducer.teamMembersLoading;
export const inviteAgentLoadingSelector = (state: RootStateOrAny) =>
  state.teamsReducer.inviteAgentLoading;
export const removeAgentFromTeamLoadingSelector = (state: RootStateOrAny) =>
  state.teamsReducer.removeAgentFromTeamLoading;
export const queryAndPaginationSelector = (state: RootStateOrAny) =>
  state.teamsReducer.queryAndPagination;
export const updateTeamNameLoadingSelector = (state: RootStateOrAny) =>
  state.teamsReducer.updateTeamNameLoading;
export const updateTeamNameSuccessSelector = (state: RootStateOrAny) =>
  state.teamsReducer.updateTeamNameSuccess;