import * as types from "./types";

export function getRentersScreeningRequest(id: string) {
  return {
    type: types.GET_RENTERS_SCREENING_REQUEST,
    payload: id,
  };
}

export function getRentersScreeningRequestSuccess(reqData: object[]) {
  return {
    type: types.GET_RENTERS_SCREENING_REQUEST_SUCCESS,
    payload: reqData,
  };
}

export function getRentersScreeningRequestFail(error: any) {
  return {
    type: types.GET_RENTERS_SCREENING_REQUEST_FAIL,
    payload: error,
  };
}

export function setRentersScreeningRequestQueryParams(payload: object) {
  return {
    type: types.SET_SCREENING_REQS_QUERY_PARAMS,
    payload,
  };
}
