import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { MinusCircleIcon } from "@heroicons/react/24/outline";
import isNil from "lodash/isNil";
interface IPropsCheckboxGroupOutput {
  field: any;
  inputComponent?: any;
  label?: string;
  value?: any;
  isEditing?: boolean;
  onChange?: any;
  editLink?: string;
  className?: string;
}

interface ICheckBoxOption {
  value: string;
  label: string;
}

const CheckboxGroupOutput = ({ field, value, label }: IPropsCheckboxGroupOutput) => {
  const optionIsChecked = (option: ICheckBoxOption, value: any) => {
    return value && !isNil(value[option.value]);
  };

  const icon =
    field.key === "deal_breaker" ? (
      <MinusCircleIcon className="h-6 w-6 stroke-red-600" />
    ) : (
      <CheckCircleIcon className="h-6 w-6 stroke-purple-600" />
    );

  const bgColor = field.key === "deal_breaker" ? "red" : "purple";

  return (
    <>
      <div>
        <label className="block text-base font-medium ">{label || field.label}</label>
        <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4">
          {field.options
            ?.filter((option: { value: any; label: string }) => optionIsChecked(option, value))
            .map((option: { value: any; label: string }) => {
              return (
                <div
                  key={`option-${option.value}`}
                  className={`flex gap-1 px-3 py-2 rounded-full   
                ${optionIsChecked(option, value) ? `text-${bgColor}-700` : "  "} 
                bg-${optionIsChecked(option, value) ? `${bgColor}` : "gray"}-50`}
                >
                  {optionIsChecked(option, value) ? icon : null}
                  {option.label}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default CheckboxGroupOutput;
