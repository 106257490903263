import React, { useCallback, useState } from "react";
import { Col } from "antd";
import BaseButton from "../../../../../Shared/BaseButton";
import IndividualLeadSharePopup from "../../../../../Shared/IndividualLeadSharePopup/IndividualLeadSharePopup";
import { AnalyticsService } from "../../../../../../helpers/analyticsService";
import { useSelector } from "react-redux";
import { windowSelector } from "../../../../../../store/app/selectors";
import LockIcon from "../../../../../Leads/icons/LockIcon";
import PremiumUpgradeModal from "components/Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { useAppContext } from "libs/contextLib";
import { userSelector } from "store/user/selectors";

const AuthHeaderLeadAction = () => {
  const [addLeadModalVisible, setAddLeadModalVisible] = useState(false);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const userData = useSelector(userSelector);
  const { type } = useSelector(windowSelector);
  const { isNotPremiumUser } = useAppContext();
  const { free_subscription_limits = {} } = userData.agency || {};

  const handleAddLeadBtnClick = () => {
    AnalyticsService.shareLeadModal({ label: "add_lead_button" });
    if (isNotPremiumUser && !(free_subscription_limits.leads > 0)) {
      setPremiumModalVisible(true);
    } else {
      setAddLeadModalVisible(true);
    }
  };

  return (
    <div className="flex justify-end w-full">
      <BaseButton variant="primary" dataTestId="team-button" onClick={() => handleAddLeadBtnClick()} modalType="lead">
        <span>
          {isNotPremiumUser && !(free_subscription_limits.leads > 0) ? (
            <span className={"mr-5"}>
              <LockIcon />
            </span>
          ) : null}
          {type === "mobile" ? "Add" : "Add a Lead"}
        </span>
      </BaseButton>
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_LEAD}
      />
      <IndividualLeadSharePopup
        visible={addLeadModalVisible}
        onClose={() => setAddLeadModalVisible(false)}
        isLeadPage={true}
      />
    </div>
  );
};

export default AuthHeaderLeadAction;
