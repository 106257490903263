import React from "react";

interface IAvatar {
  url: string;
  height?: number;
  width?: number;
  alt?: string;
}

export default function Avatar({ url, height, width, alt }: IAvatar) {
  const DEFAULT_H_W = "24";

  return (
    <div className="flex-shrink-0">
      <span className="relative inline-block">
        <img
          className={`h-${height ?? DEFAULT_H_W} w-${width ?? DEFAULT_H_W} rounded-full object-center object-cover`}
          src={url}
          alt={alt ?? "user image"}
        />
      </span>
    </div>
  );
}
