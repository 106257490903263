import React, { useEffect, useRef, useState } from "react";
import BaseModal from "../BaseModal";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { singleLeadDataSelector } from "../../../store/leads/selectors";
import { useLocation } from "react-router";
import { Spin } from "antd";
import { clearListingsFromAlgolia, setListings } from "../../../store/listings/action";
import ListingApi from "api/Listing";
import { debounce } from "lodash";
import RenterNameWrapper from "./components/renter-name-wrapper/RenterNameWrapper";
import { windowSelector } from "../../../store/app/selectors";
import { listingsListSelector } from "../../../store/listings/selectors";
import MatchingListingsEmptyState from "./components/empty-state/MatchingListingsEmptyState";
import GlobalLoading from "../GlobalLoading";
import { IObjectKeys } from "helpers/globalTypes";
import DetectableOverflow from "react-detectable-overflow";
import { listingRoute } from "helpers/clientRoutes";
import BaseButton from "../BaseButton";
import { push } from "connected-react-router";
import routes from "routes/routesCode";

interface IMatchingListingsModal {
  isModalVisible: boolean;
  closeModalHandler: () => void;
}

const matchingListingTableHeaderValues = ["Address", "Location", "Price", "Bed", "Bath", "Availability"];

const ListingsTable = (listingsList: any) => {
  const { listingsList: listings } = listingsList;
  return (
    <>
      <ul className={"matching-listings-desktop-view-header"}>
        {matchingListingTableHeaderValues.map((elem: string, index: number) => {
          return (
            <li
              key={index}
              className={`matching-listings-desktop-view-header-item matching-listings-desktop-view-header-item${elem}`}
            >
              <h4>{elem}</h4>
            </li>
          );
        })}
      </ul>
      <ul className={"matching-listings-desktop-view-body"}>
        {listings?.length &&
          listings.map((listing: IObjectKeys) => {
            return (
              <li key={listing._id} className={"matching-listings-desktop-view-body-item"}>
                <DetectableOverflow
                  style={{ height: "54px", width: "290px" }}
                  className={"matching-listings-desktop-view-body-item-addr"}
                >
                  <a href={listingRoute(listing)} target="_blank">
                    {listing.generatedAddr}
                  </a>
                </DetectableOverflow>

                <div className={"matching-listings-desktop-view-body-item-town"}>
                  {listing.town ? listing.town : listing.neighbourhood}
                </div>
                <div className={"matching-listings-desktop-view-body-item-price"}>${listing?.asked_rent}</div>
                <div className={"matching-listings-desktop-view-body-item-bed"}>{listing?.bedrooms}</div>
                <div className={"matching-listings-desktop-view-body-item-bath"}>{listing?.bathrooms}</div>
                <div className={"matching-listings-desktop-view-body-item-status"}>
                  <div
                    className={`${
                      listing?.status === "Available"
                        ? "matching-listings-desktop-view-body-item-status-circleGreen"
                        : "matching-listings-desktop-view-body-item-status-circleRed"
                    }`}
                  />
                  {listing?.status === "Available" ? "Now" : "Off-Market"}
                </div>
              </li>
            );
          })}
      </ul>
    </>
  );
};

const MatchingListingsModal = ({ isModalVisible, closeModalHandler }: IMatchingListingsModal) => {
  const dispatch = useDispatch();
  const leadData = useSelector(singleLeadDataSelector);
  const listingsList = useSelector(listingsListSelector);
  const requirement = leadData?.requirement;
  const { search } = useLocation();
  const { type } = useSelector(windowSelector);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);

  useEffect(() => {
    dispatch(clearListingsFromAlgolia());
  }, []);

  const delayedQueryCall = useRef(
    debounce(() => {
      ListingApi.getRequirementListingsFromAlgolia({
        requirement_id: requirement._id,
      }).then((results: any) => {
        const { hits: listings } = results.result;
        setIsLoading(false);
        if (listings.length) {
          dispatch(setListings(listings));
        }
      });
    }, 250)
  );

  useEffect(() => {
    delayedQueryCall?.current();
  }, [search]);

  const handleSendRecommendationsEmailToRenter = ({ requirement_id }: { requirement_id: string }) => {
    setSendingEmail(true);
    ListingApi.postSendEmailToRenterForMatchedListings({
      requirement_id: requirement._id,
    }).then((results: any) => {
      setSendingEmail(false);
      setIsLoading(false);
    });
  };

  return (
    <BaseModal
      isModalVisible={isModalVisible}
      onCancel={closeModalHandler}
      modalFooter={null}
      modalTitle={
        <>
          <div className="mb-10">
            <RenterNameWrapper name={leadData?.renter?.name} prefix="Matched listings based on requirements for " />
            {listingsList?.length > 0 && (
              <BaseButton
                onClick={() => handleSendRecommendationsEmailToRenter({ requirement_id: leadData.requirement_id })}
                className="mt-4"
                loading={sendingEmail}
              >
                <>Email this list to {leadData?.renter?.name}</>
              </BaseButton>
            )}
          </div>
          {/* <FilteredBySection isMatchingListings={true} /> */}
        </>
      }
      width={type !== "mobile" ? 1037 : 310}
      classname={"matching-listings-modal"}
    >
      <Spin spinning={isLoading} indicator={<GlobalLoading />} className={"matching-listings-spinner"}>
        {isLoading && <div style={{ height: "50vh" }}> </div>}
        {!isLoading && listingsList.length ? <ListingsTable listingsList={listingsList} /> : null}

        {!isLoading && !listingsList.length ? (
          <MatchingListingsEmptyState canAddListing={false}>
            <p className="mb-4">We didn't find any relevant matches for this lead.</p>
            <BaseButton variant="primary" onClick={() => dispatch(push(routes.listings.path))}>
              Search Listings
            </BaseButton>
          </MatchingListingsEmptyState>
        ) : null}
      </Spin>
    </BaseModal>
  );
};

export default MatchingListingsModal;
