import Api from "./api";
import { TOKEN_ENUM, getRefreshToken, setAccessToken, setGuestAccessToken, setRefreshToken } from "../helpers/authUtils";
import { IObjectKeys } from "../helpers/globalTypes";
import { AxiosPromise } from "axios";
import { AuthError } from "../types/user";

export default class AuthApi {
  static logIn(data: { email: string; password: string; }) {
    const api = new Api("auth/login");
    return api.create({ data });
  }

  static logOut(data: { email: string; }) {
    const api = new Api("auth/logout");
    return api.create({ data });
  }

  static signUp(data: { email: string; name: string; phone?: string; enrichments?: object; }) {
    const api = new Api("users");
    return api.create({ data });
  }

  static googleIdToken(id: string) {
    const api = new Api("auth/google/addId");
    return api.create({
      data: {
        id_token: id
      }
    });
  }

  static shareApi(link: string) {
    const api = new Api("requirements/sendLinkMe");
    return api.create({
      data: {
        link: link
      }
    });
  }

  static forceResetPassword(data: { email: string; agentId: string; newPassword: string; }) {
    const api = new Api("auth/forceResetPassword");
    return api.create({ data });
  }

  static resetPassword({ email }: { email: string; }) {
    const api = new Api("auth/resetUserPassword");
    return api.create({ data: { email } });
  }

  static confirmResetPassword(data: { code: string; email: string; newPassword: string; }) {
    const api = new Api("auth/resetPasswordConfirm");
    return api.create({ data });
  }

  static getLoggedInUser() {
    const api = new Api("auth");
    return api.get("get-user", {});
  }

  static updateUser(data: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api("users/portal/settings");
    return api.put("", { data: data.data, params: data.params });
  }

  static verifyPhoneNumber(phone_verification_code: string): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api("auth/verify_phone_number");
    return api.create({ data: { phone_verification_code } });
  }

  static getVerificationCode(): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api("auth/get_new_phone_verification_code");
    return api.create({});
  }

  static async refreshToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const api = new Api("auth/refreshToken");
        const tokens: IObjectKeys = await api.create({
          data: { refreshToken: getRefreshToken() }
        });
        setAccessToken(tokens[TOKEN_ENUM.ACCESS_TOKEN] || "");
        setRefreshToken(tokens[TOKEN_ENUM.REFRESH_TOKEN] || "");
        resolve(tokens[TOKEN_ENUM.ACCESS_TOKEN]);
      } catch (e) {
        reject(e);
      }
    });
  }

  static getGuestAccessToken() {
    const api = new Api("auth");
    return api.get('guestAccessToken', { data: { lets: 'go' } });
  }
}
