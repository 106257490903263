import React from 'react';
import { IconPropsInterface } from './iconPropsInterface';

export const KeyIcon = (props: IconPropsInterface) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.classname}
    >
      <path
        d="M13.5864 1.41362C11.7015 -0.471226 8.6348 -0.471197 6.74995 1.41365C5.4625 2.7011 5.01531 4.5984 5.57277 6.31974L0.12876 11.7638C0.0463477 11.8462 0 11.9578 0 12.0745V14.5606C0 14.8035 0.196553 15 0.439454 15H2.92553C3.04225 15 3.15384 14.9537 3.23623 14.8713L3.85764 14.2494C3.95248 14.1546 3.99883 14.0216 3.98339 13.8881L3.90613 13.2203L4.83139 13.1332C5.04168 13.1134 5.20776 12.9474 5.22751 12.7371L5.31464 11.8118L5.9824 11.8895C6.10642 11.9062 6.23131 11.8646 6.3253 11.7813C6.41884 11.6977 6.47248 11.5783 6.47248 11.453V10.6348H7.27586C7.39258 10.6348 7.50417 10.5885 7.58656 10.506L8.71352 9.39399C10.4344 9.95189 12.2989 9.53839 13.5864 8.25006C15.4712 6.36524 15.4712 3.29847 13.5864 1.41362ZM12.3431 4.52115C11.829 5.03528 10.993 5.03528 10.4789 4.52115C9.96477 4.00701 9.96477 3.17103 10.4789 2.6569C10.993 2.14276 11.829 2.14276 12.3431 2.6569C12.8573 3.17103 12.8573 4.00701 12.3431 4.52115Z"
        fill="#B7B7B7"
      />
    </svg>
  );
};
