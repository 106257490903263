import { Skeleton, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import AddFollowUpModal from "components/FollowUps/add-follow-up-modal/AddFollowUpModal";
import ActivateSection from "components/Shared/ActivateSection";
import ContactModal from "components/Shared/ContactModal/ContactModal";
import { push } from "connected-react-router";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { useDemoContext } from "libs/contextLib";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { leadSearchLoadingSelector } from "store/requirement/selectors";
import { userSelector } from "store/user/selectors";
import { useWindowSize } from "../../../hooks/useWindowSize";
import routes from "../../../routes/routesCode";
import { getEnums } from "../../../store/enums/action";
import { getLeadFollowUps } from "../../../store/followUps/action";
import { clearLeadData, getLeadData } from "../../../store/leads/action";
import { leadLoadingSelector, singleLeadDataSelector } from "../../../store/leads/selectors";
import AddFollowUpModalMobile from "../../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import PhoneVerifyModalFollowUps from "../../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import PremiumUpgradeModal from "../../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import AfterCallModal from "../../Shared/AfterCallModal/AfterCallModal";
import MatchingListingsModal from "../../Shared/matching-listings-modal";
import LeadAvailabilities from "../shared/lead-availabilities/LeadAvailabilities";
import LeadDescription from "../shared/LeadDescription";
import LeadPersonalInfo from "../shared/LeadPersonalInfo";
import LeadGoBackAction from "./lead-go-back-action-wrapper/LeadGoBackAction";
import LeadContactSection from "./LeadContactSection";
import LeadNoteModal from "./LeadNoteModal";
import LeadNotes from "./LeadNotes";
import AgentLeadSRs from "./screening-requests";
import GlobalLoading from "components/Shared/GlobalLoading";

const LeadView = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const leadData = useSelector(singleLeadDataSelector);
  const leadLoading = useSelector(leadLoadingSelector);
  const [isMatchingListings, setMatchingListings] = useState<boolean>(false);
  const { lead_id }: { lead_id: string } = useParams();
  const initialModalValue = { visible: false, editModal: false };
  const currentLandlord = leadData;
  const { width } = useWindowSize();
  const [landlordActionModalVisible, setLandlordActionModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState(initialModalValue);
  const location = useLocation();
  const [afterCallModalProps, setAfterCallModalProps] = useState(false);
  const [followUpModalProps, setFollowUpsModalProps] = useState(initialModalValue);
  const [noteModalProps, setNoteModalProps] = useState(initialModalValue);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const { isDemo } = useDemoContext();
  const userData = useSelector(userSelector);
  const leadSearchLoading = useSelector(leadSearchLoadingSelector);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const [action, setAction] = useState("");

  const closeAfterCallModalHandler = () => {
    setAfterCallModalProps(false);
  };

  useEffect(() => {
    localStorage.setItem("current_page", "lead page");
    dispatch(getEnums());
  }, [dispatch]);

  useEffect(() => {
    if (!isMatchingListings && location.search && !location.search.includes("match_to_listings=true")) {
      dispatch(
        push({
          pathname: location.pathname,
          search: "",
        })
      );
    }
  }, [dispatch, isMatchingListings, location.pathname, location.search]);

  useEffect(() => {
    if (Object.keys(leadData).length > 0 && leadData.renter?._id) dispatch(getLeadFollowUps(leadData.renter?._id));
  }, [dispatch, leadData]);

  useEffect(() => {
    if (!lead_id) {
      dispatch(push(routes.listings.path));
    }
    dispatch(getLeadData(lead_id));

    return () => {
      dispatch(clearLeadData());
    };
  }, [dispatch, lead_id]);

  useEffect(() => {
    if (!afterCallModalProps) localStorage.removeItem("contact_email_selected");
  }, [afterCallModalProps]);

  useEffect(() => {
    localStorage.removeItem("requested_agent_id");
  }, []);

  useEffect(() => {
    if (location.search.includes("match_to_listings=true")) {
      setMatchingListings((isMatching: boolean) => !isMatching);
    }
  }, [location]);

  const { renter_id: renterId } = leadData.renter || {};
  const { agent_id } = userData;

  if (leadLoading) {
    return <Spin spinning={leadLoading} indicator={<GlobalLoading />} className="global-loading"></Spin>;
  }

  return (
    <div>
      <div className={"lead-individual-go-back"}>
        <LeadGoBackAction />
      </div>
      {isDemo && <ActivateSection type="lead" className="mt-15" />}
      <LeadAvailabilities setMatchingListings={setMatchingListings} isMatchingListings={isMatchingListings} />
      <div className="listing-page-body lead-page-body">
        <LeadContactSection leadData={leadData} setLandlordActionModalVisible={setLandlordActionModalVisible} />
        {leadData.renter?._id && <LeadNotes />}
        <AgentLeadSRs renterId={renterId} agentId={agent_id} />
        <Skeleton title={false} loading={leadSearchLoading} active />
        {leadData?.requirement && (
          <div className="py-4">
            <LeadDescription requirement={leadData?.requirement} collapseTitle={"Renter Snapshot"} />
          </div>
        )}
        <LeadPersonalInfo />
        {isMatchingListings && (
          <MatchingListingsModal
            isModalVisible={isMatchingListings}
            closeModalHandler={() => setMatchingListings(false)}
          />
        )}
      </div>
      {landlordActionModalVisible && (
        <ContactModal
          setModalProps={setModalProps}
          currentLeadData={currentLandlord.agent ?? currentLandlord.renter}
          showModal={true}
          onClose={setLandlordActionModalVisible}
          currentLandlordData={null}
          setAfterCallModalVisible={setAfterCallModalProps}
          setAction={setAction}
        />
      )}
      {leadData.renter?._id && (
        <AfterCallModal
          visible={afterCallModalProps}
          onClose={closeAfterCallModalHandler}
          key={1}
          setFollowUpModalVisible={setFollowUpsModalProps}
          setContactModalVisible={setAfterCallModalProps}
          setNoteModalVisible={setNoteModalProps}
          setPremiumModalVisible={setPremiumModalVisible}
        />
      )}
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            lead={leadData}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={action + " lead"}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            lead={leadData}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={action + " lead"}
          />
        )
      }
      <LeadNoteModal
        modalProps={noteModalProps}
        setModalProps={setNoteModalProps}
        initialModalValue={initialModalValue}
        form={form}
        defaultTitle={action + " lead"}
      />
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={() => setPhoneVerifyModalVisible(false)} />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_AFTER_CALL_MODAL}
        id={leadData._id}
      />
    </div>
  );
};

export default LeadView;
