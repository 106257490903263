import BaseCollapse from "components/Shared/BaseCollapse";
import { IObjectKeys } from "helpers/globalTypes";
import React from "react";
import CollapseToggleButton from "../../SharedComponents/CollapseToggleButton";

const AutomationReminders = ({
  switchInputData,
  handleChange,
}: {
  switchInputData: IObjectKeys;
  handleChange: (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => void;
}) => {
  return (
    <div>
      <h3 className="font-semibold text-lg">Select which notifications you'd like to receive.</h3>
      <CollapseToggleButton
        description={"Listing Renewals"}
        descriptionPlaceholder={"Receive a notification when a lease renewal date is approaching."}
        inputName={"listingsRenewalNotifications"}
        inputValue={switchInputData["listingsRenewalNotifications"]}
        handleChange={handleChange}
      />
    </div>
  );
};

export default AutomationReminders;
