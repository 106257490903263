import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";
import { AuthError } from "../../types/user";
import { IFilterOptionsOptionalKeys, IGetListingsQuery, Listing } from "../../components/Listings/helpers/mockListings";
import { UploadFile } from "antd/es/upload/interface";
import { IPaginationValues } from "../../components/Listings/components/listings-wrapper";

export const fillListingData = (payload: IObjectKeys) => ({
  type: types.FILL_LISTING_DATA,
  payload,
});
export const triggerListingAfterSaveModal = (payload: boolean) => ({
  type: types.TRIGGER_AFTER_LISTING_SAVE_MODAL,
  payload,
});
export const startFillListingDataImages = () => ({
  type: types.START_FILL_LISTING_DATA_IMAGES,
});
export const fillListingDataImages = (payload: UploadFile) => ({
  type: types.FILL_LISTING_DATA_IMAGES,
  payload,
});
export const setListingSaveClicked = (payload: boolean) => ({
  type: types.SET_LISTING_SAVE_CLICKED,
  payload,
});
export const clearListingData = () => ({
  type: types.CLEAR_LISTING_DATA,
});
export const clearListingAddState = () => ({
  type: types.CLEAR_LISTING_ADD_STATE,
});
export const clearListingUpdateState = () => ({
  type: types.CLEAR_LISTING_UPDATE_STATE,
});

export const addListing = (payload: IObjectKeys) => ({
  type: types.ADD_LISTING,
  payload,
});
export const addListingSuccess = (payload: IObjectKeys) => ({
  type: types.ADD_LISTING_SUCCESS,
  payload,
});
export const addListingFail = (payload: AuthError) => ({
  type: types.ADD_LISTING_FAIL,
  payload,
});

export const updateListing = (payload: IObjectKeys) => ({
  type: types.UPDATE_LISTING,
  payload,
});

export const updateListingSuccess = (payload: IObjectKeys) => ({
  type: types.UPDATE_LISTING_SUCCESS,
  payload,
});

export const updateListingFail = (payload: AuthError) => ({
  type: types.UPDATE_LISTING_FAIL,
  payload,
});

export function getListingsList(getListingsQuery: IGetListingsQuery) {
  return {
    type: types.GET_LISTINGS,
    payload: getListingsQuery,
  };
}

export function getListingsListSuccess(users: object) {
  return { type: types.GET_LISTINGS_SUCCESS, payload: users };
}

export function getListingsListFail(error: object) {
  return { type: types.GET_LISTINGS_FAIL, payload: error };
}

export function getListingsFromAlgolia(payload: IGetListingsQuery) {
  return {
    type: types.GET_LISTINGS_FORM_ALGOLIA,
    payload: payload,
  };
}

export function getListingsFromAlgoliaSuccess(payload: Listing[]) {
  return { type: types.GET_LISTINGS_FORM_ALGOLIA_SUCCESS, payload };
}

export function clearListingsFromAlgolia() {
  return { type: types.CLEAR_LISTINGS_FORM_ALGOLIA };
}

export function getListingsFromAlgoliaFail(error: object) {
  return { type: types.GET_LISTINGS_FORM_ALGOLIA_FAIL, payload: error };
}

export function setAlgoliaSearch(payload: boolean) {
  return {
    type: types.ALGOLIA_SEARCH_TOGGLE,
    payload,
  };
}

export function getOneListing(payload: string) {
  return {
    type: types.GET_ONE_LISTING,
    payload,
  };
}

export function setListing(payload: string) {
  return {
    type: types.SET_LISTING,
    payload,
  };
}

export function setListings(payload: string) {
  return {
    type: types.SET_LISTINGS,
    payload,
  };
}

export function getOneListingFromPublicId(payload: string) {
  return {
    type: types.GET_ONE_LISTING_FROM_PUBLIC_ID,
    payload,
  };
}

export function getOneListingSuccess(payload: IObjectKeys) {
  return { type: types.GET_ONE_LISTING_SUCCESS, payload };
}

export function getOneListingFail(error: object) {
  return { type: types.GET_ONE_LISTING_FAIL, payload: error };
}

export const addListingNote = (payload: IObjectKeys) => ({
  type: types.ADD_LISTING_NOTE,
  payload,
});

export const addListingNoteSuccess = (payload: IObjectKeys) => ({
  type: types.ADD_LISTING_NOTE_SUCCESS,
  payload,
});

export const addListingNoteFail = (payload: AuthError) => ({
  type: types.ADD_LISTING_NOTE_FAIL,
  payload,
});

export const deleteListingNote = (payload: string) => ({
  type: types.DELETE_LISTING_NOTE,
  payload,
});

export const deleteListingNoteSuccess = (payload: IObjectKeys) => ({
  type: types.DELETE_LISTING_NOTE_SUCCESS,
  payload,
});

export const deleteListingNoteFail = (payload: AuthError) => ({
  type: types.DELETE_LISTING_NOTE_FAIL,
  payload,
});

export const updateListingNote = (payload: IObjectKeys) => ({
  type: types.UPDATE_LISTING_NOTE,
  payload,
});

export const updateListingNoteSuccess = (payload: IObjectKeys) => ({
  type: types.UPDATE_LISTING_NOTE_SUCCESS,
  payload,
});

export const updateListingNoteFail = (payload: AuthError) => ({
  type: types.UPDATE_LISTING_NOTE_FAIL,
  payload,
});

export const getListingNotes = (payload: IObjectKeys) => ({
  type: types.GET_LISTING_NOTES,
  payload,
});

export const getListingNotesSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LISTING_NOTES_SUCCESS,
  payload,
});

export const getListingNotesFail = (payload: AuthError) => ({
  type: types.GET_LISTING_NOTES_FAIL,
  payload,
});

export const updateListingPublishState = (payload: IObjectKeys) => ({
  type: types.UPDATE_LISTING_PUBLISH_STATE,
  payload,
});

export const updateListingPublishStateSuccess = (payload: IObjectKeys) => ({
  type: types.UPDATE_LISTING_PUBLISH_STATE_SUCCESS,
  payload,
});

export const updateListingPublishStateFail = (payload: AuthError) => ({
  type: types.UPDATE_LISTING_PUBLISH_STATE_FAIL,
  payload,
});

export const deleteListing = (payload: IObjectKeys) => ({
  type: types.DELETE_LISTING,
  payload,
});

export const deleteListingSuccess = (payload: IObjectKeys) => ({
  type: types.DELETE_LISTING_SUCCESS,
  payload,
});

export const deleteListingFail = (payload: AuthError) => ({
  type: types.DELETE_LISTING_FAIL,
  payload,
});

export const deleteListingImage = (payload: string) => ({
  type: types.DELETE_LISTING_IMAGE,
  payload,
});

export const deleteListingImageSuccess = (payload: IObjectKeys) => ({
  type: types.DELETE_LISTING_IMAGE_SUCCESS,
  payload,
});

export const deleteListingImageFail = (payload: AuthError) => ({
  type: types.DELETE_LISTING_IMAGE_FAIL,
  payload,
});

export const setListingsLastQuery = (payload: string) => ({
  type: types.SET_LISTINGS_LAST_QUERY,
  payload,
});

export const uploadCsvListings = (payload: any) => ({
  type: types.UPLOAD_CSV_LISTINGS,
  payload,
});

export const uploadCsvListingsSuccess = (payload: IObjectKeys) => ({
  type: types.UPLOAD_CSV_LISTINGS_SUCCESS,
  payload,
});

export const uploadCsvListingsFail = (payload: AuthError) => ({
  type: types.UPLOAD_CSV_LISTINGS_FAIL,
  payload,
});

export const downloadCsvTemplate = () => ({
  type: types.DOWNLOAD_CSV_TEMPLATE,
});

export const downloadCsvTemplateSuccess = (payload: IObjectKeys) => ({
  type: types.DOWNLOAD_CSV_TEMPLATE_SUCCESS,
  payload,
});

export const downloadCsvTemplateFail = (payload: AuthError) => ({
  type: types.DOWNLOAD_CSV_TEMPLATE_FAIL,
  payload,
});

export const setCurrentSteps = (payload: number) => ({
  type: types.SET_CURRENT_STEP,
  payload,
});

export const setListingImageModalCurrentImageStep = (payload: number) => ({
  type: types.SET_LISTING_IMAGE_MODAL_CURRENT_IMAGE_INDEX,
  payload,
});

export const clearUploadCsvListingsErrors = () => ({
  type: types.CLEAR_UPLOAD_CSV_ERRORS,
});

export const clearParticularCsvError = (payload: IObjectKeys) => ({
  type: types.CLEAR_PARTICULAR_CSV_ERROR,
  payload,
});

export const setStepClicked = (payload: boolean) => ({
  type: types.SET_STEP_CLICKED,
  payload,
});

export const setListingsViewType = (payload: string) => ({
  type: types.SET_LISTINGS_VIEW_TYPE,
  payload,
});

export const setListingsFilterOptions = (payload: IFilterOptionsOptionalKeys) => ({
  type: types.SET_LISTINGS_FILTER_OPTIONS,
  payload,
});

export const setListingsPaginationValues = (payload: IPaginationValues) => ({
  type: types.SET_LISTINGS_PAGINATION_VALUES,
  payload,
});

export const setListingsLoadingToTrue = () => ({
  type: types.SET_LISTINGS_LOADING_TO_TRUE,
});

export const setListingsLoadingToFalse = () => ({
  type: types.SET_LISTINGS_LOADING_TO_FALSE,
});

export const setMustHavesSwitchInputValues = (payload: IObjectKeys) => ({
  type: types.SET_MUST_HAVES_SWITCH_INPUT_VALUES,
  payload,
});

export const setListingDetailsSwitchInputValues = (payload: IObjectKeys) => ({
  type: types.SET_LISTING_DETAILS_SWITCH_INPUT_VALUES,
  payload,
});

export const setIsCurrentFormChange = (payload: boolean) => ({
  type: types.IS_CURRENT_FORM_CHANGED,
  payload,
});

export const setIsAsapClicked = (payload: boolean) => ({
  type: types.IS_ASAP_CLICKED,
  payload,
});

export const setListingNotesSuccess = (payload: boolean) => ({
  type: types.SET_LISTING_NOTES_SUCCESS,
  payload,
});
