import { EnumInterface, IObjectKeys } from "../../../../helpers/globalTypes";
import {
  bathrooms_from,
  is_search_active, pets_allowed,
  status,
} from "../../../Listings/helpers/mockListings";
import {
  beautifiedKeyStyling,
  createURL,
  getBrowserDateFormat,
  urlToSearchState,
} from "../../../../helpers/globalFunctions";
import { pickBy, isUndefined, isEqual } from "lodash";
import { Dispatch } from "redux";
import {
  createFilterQuery,
  urlQueryToObject,
} from "./listingsCreateGetSearchQuery";
import { push } from "connected-react-router";
import React from "react";
import { AnalyticsService } from "../../../../helpers/analyticsService";
import routes from "../../../../routes/routesCode";
import { setListingsFilterOptions as setFilterOptions } from "../../../../store/listings/action";
import { setLandlordsQueryParams } from "../../../../store/landlords/action";
import { setQueryAndPagination as setTeamsQueryAndPagination } from "../../../../store/teams/action";
import { setQueryAndPagination as setActivitiesQueryAndPagination } from "../../../../store/activities/action";
import { setFollowUpsQueryAndPagination } from "../../../../store/followUps/action";
import { CheckboxOptionType } from "antd/es/checkbox";
import { tags } from "components/Lead/shared/constants";
import { isLeadsRoute, isListingRoute } from "helpers/clientRoutes";
import { formatCurrency } from "helpers/currencyHelper";
import { ISO_STRING } from "helpers/constants";
import { formatDate } from "helpers/dateHelper";

const initialBedBathObj = { from: 0, to: 20 };
interface IgenerateFilterFormData {
  data: IObjectKeys;
  location: any;
  bedRoomsCount: IObjectKeys;
  bathRoomsCount: IObjectKeys;
  allowedPets: string[];
  hasParking: boolean;
  justMyListings: boolean;
  dispatch: Dispatch<any>;
  paginationValues: any;
  listingsViewType?: any;
  filterOptions: any;
  queryObject: any;
  type: string;
}
export const generateFilterFormData = (props: IgenerateFilterFormData) => {
  //is_search_active
  let formattedData = pickBy(props.data, (v) => v !== undefined);
  if (props.data.tags && props.data.tags.length) {
    formattedData.tags = props.data.tags;
  }
  if (props.queryObject.searchValue) {
    formattedData.searchValue = props.queryObject.searchValue;
  }
  if (isUndefined(props.data.asked_rent_from)) {
    if (props.queryObject.asked_rent_from) {
      formattedData.asked_rent_from = props.queryObject.asked_rent_from;
    }
  }
  if (isUndefined(props.data.asked_rent_to)) {
    if (props.queryObject.asked_rent_to) {
      formattedData.asked_rent_to = props.queryObject.asked_rent_to;
    }
  }
  if (props.queryObject.search) {
    formattedData.search = props.queryObject.search;
  }
  if (props.location) {
    if (props.location.length) {
      formattedData.locations = props.location;
    } else {
      formattedData.locations = [];
    }
  }
  if (props.hasParking) {
    formattedData.parking_type = props.hasParking;
  } else {
    formattedData.parking_type = false;
  }

  if (props.justMyListings) {
    formattedData.filter_by_agent = props.justMyListings;
  } else {
    formattedData.filter_by_agent = false;
  }
  if (props.allowedPets) {
    if (props.allowedPets.length) {
      formattedData.pets_allowed = props.allowedPets;
    } else {
      formattedData.pets_allowed = [];
    }
  }
  if (props.bedRoomsCount) {
    if (props.bedRoomsCount.from) {
      if (!isEqual(props.bedRoomsCount, initialBedBathObj)) {
        if (props.bedRoomsCount.from !== "Studio") {
          formattedData.bedrooms_from = props.bedRoomsCount.from;
          formattedData.bedrooms_to = props.bedRoomsCount.to;
        } else {
          formattedData.bedrooms_from = 0;
          formattedData.bedrooms_to = 0.5;
        }
      }
    }
  }
  if (
    props.bathRoomsCount &&
    !isEqual(props.bathRoomsCount, initialBedBathObj)
  ) {
    formattedData.bathrooms_from = props.bathRoomsCount.from;
    formattedData.bathrooms_to = props.bathRoomsCount.to;
  }
  if (props?.data?.availability_dates?.length) {
    formattedData.availability_date_to = new Date(
      Math.max(...props.data.availability_dates)
    ).toISOString();
    formattedData.availability_dates = props.data.availability_dates.map(
      (element: string) => {
        return new Date(element).toISOString();
      }
    );
  } else {
    if (
      !props.data.availability_date_to
    ) {
      delete formattedData.availability_date_to;
      delete formattedData.availability_dates;
    }
  }
  if (props.data.availability_date_to) {
    formattedData.availability_date_to = formatDate(props.data.availability_date_to, ISO_STRING);
  }
  if (props.queryObject.algoliaSortField) {
    formattedData.algoliaSortField = props.queryObject.algoliaSortField;
  }

  const formattedDataQuery = { ...formattedData };
  if (props.type === "leads") {
    delete formattedDataQuery.status;
    if (props.data.status === "All") {
      delete formattedDataQuery.is_search_active;
    } else {
      formattedDataQuery.is_search_active = props.data.status;
    }
  } else {
    if (props.type !== "landlords") {
      formattedDataQuery.status = props.data.status
        ? props.data.status
        : localStorage.getItem("availabilityFilterState");
    } else {
      delete formattedDataQuery.status;
    }
  }

  const newSearchQuery = createFilterQuery(
    props.listingsViewType,
    formattedDataQuery,
    {
      ...props.paginationValues,
      current: 1,
      page: 1,
    },
    props.queryObject,
    null,
    window.location.pathname.substring(1)
  );

  if (props.data.status) {
    if (props.type === "listings") {
      localStorage.setItem("availabilityFilterState", props.data.status);
    } else {
      localStorage.setItem("availabilityFilterStateLeads", props.data.status);
    }
  }

  props.dispatch(
    push({
      pathname: window.location.pathname,
      search: newSearchQuery,
    })
  );
  return formattedData;
};

export const setBedroomsAndBathroomsFormData = (
  queryObject: IObjectKeys,
  setBathrooms: React.Dispatch<React.SetStateAction<IObjectKeys>>,
  setBedrooms: React.Dispatch<React.SetStateAction<IObjectKeys>>,
  checked: boolean
) => {
  if (queryObject.bathrooms_from && queryObject.bathrooms_to) {
    queryObject.bathrooms_to &&
      setBathrooms((prevState: any) => ({
        ...prevState,
        from: queryObject.bathrooms_from,
        to: 20,
      }));
  }
  if (queryObject.bedrooms_to) {
    setBedrooms((prevState: any) => ({
      ...prevState,
      from:
        +queryObject.bedrooms_from === 0 || !queryObject.bedrooms_from
          ? "Studio"
          : +queryObject.bedrooms_from,
      to: checked ? queryObject.bedrooms_from + 0.5 : 20,
    }));
  }
};

export const separateFacetData = (facetData: any) => {
  let townArray = Object.keys(facetData?.town_level).map((e: any) => {
    return e;
  });

  let subAreaArray: any = facetData?.sub_area_level
    ? Object.keys(facetData?.sub_area_level).map((e: any) => {
      return e;
    })
    : [];

  const subSubAreaArray = facetData?.sub_sub_area_level
    ? Object.keys(facetData?.sub_sub_area_level).map((e) => {
      return e;
    })
    : [];

  return {
    towns: townArray,
    subAreas: subAreaArray,
    subSubAreas: subSubAreaArray,
  };
};

export const generateFacetData = (facetData: any) => {
  const obj = separateFacetData(facetData);
  const data: any = [];

  for (let i = 0; i < obj.towns.length; i++) {
    const parent: any = {
      value: obj.towns[i],
      label: obj.towns[i],
    };
    for (let j = 0; j < obj.subAreas.length; j++) {
      let subArea = obj.subAreas[j].split(" > ");
      if (subArea.length === 2 && subArea[0] == parent.value) {
        const subAreaItem: any = {
          value: obj.subAreas[j],
          label: obj.subAreas[j],
        };
        if (!parent.hasOwnProperty("children")) {
          parent.children = [];
        }
        for (let k = 0; k < obj.subSubAreas.length; k++) {
          let subSubArea = obj.subSubAreas[k].split(" > ");
          if (
            subSubArea.length === 3 &&
            subSubArea[0] + " > " + subSubArea[1] === obj.subAreas[j]
          ) {
            if (!subAreaItem.hasOwnProperty("children")) {
              subAreaItem.children = [];
            }
            subAreaItem.children.push({
              value: obj.subSubAreas[k],
              label: obj.subSubAreas[k],
            });
          }
        }
        parent.children.push(subAreaItem);
      }
    }
    data.push(parent);
  }

  return data;
};

export const simplifyFilterLocationsData = (locations: any) => {
  return locations?.map((item: any) => {
    if (item?.children?.length === 1) {
      delete item.children;
    }
    const index = item?.children?.findIndex((object: any) => {
      return object.value.includes("other");
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    item?.children?.push(item?.children?.splice(item?.children[index], 1)[0]);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    item?.children?.map((subItem: any) => {
      if (subItem?.children?.length === 1) {
        if (subItem?.children[0]?.value.includes("other")) {
          delete subItem?.children;
        }
      }
    });
    return item;
  });
};

export const createArrayWithSelectedItemsForMultiselect = (
  parsedValue: any,
  setArray: any
) => {
  let checkedArray: any = [];
  const checkValue = simplifyFilterLocationsData(new Array(parsedValue));
  checkValue.map((chk: any) => {
    if (chk.children) {
      if (chk.children) {
        chk.children.map((element: any) => {
          if (element.children) {
            element.children.map((sub_sub: any) => {
              checkedArray.push(sub_sub.value);
            });
          } else {
            checkedArray.push(element.value);
          }
        });
      }
    } else {
      if (chk.value) {
        checkedArray.push(chk.value);
      }
    }
  });
  setArray(checkedArray);
};

export const convertObjectToArray = (
  object: IObjectKeys,
  queryObject: IObjectKeys,
  isMatchingListings: boolean | undefined,
  isExternalSourceUser: boolean | undefined,
) => {
  const res: any = [];
  const obj = { ...object };
  delete obj.listingType;
  delete obj.page;
  delete obj.size;
  delete obj.pageSize;
  delete obj.myLeads;

  if (obj.status === "Show-all") {
    delete obj.status;
  }
  if (obj.is_search_active === "All") {
    delete obj.is_search_active;
  }
  if (
    !obj.status
    && !localStorage.getItem("availabilityFilterState")
    && isExternalSourceUser
  ) {
    obj.status = "Available";
  }
  if (
    !obj.status
    && localStorage.getItem("availabilityFilterState")
    && localStorage.getItem("availabilityFilterState") !== "Show-all"
    && !isMatchingListings
  ) {
    obj.status = localStorage.getItem("availabilityFilterState");
  }
  if (window.location.pathname === "/demo") {
    delete obj.status;
  }
  if (isMatchingListings) {
    delete obj.status;
  }
  if (obj.is_search_active && !isMatchingListings) {
    obj.is_search_active = isStringified(
      localStorage.getItem("availabilityFilterStateLeads")
    );
  } else {
    if (
      localStorage.getItem("availabilityFilterStateLeads") &&
      localStorage.getItem("availabilityFilterStateLeads") !== "All"
    ) {
      obj.is_search_active = isStringified(
        localStorage.getItem("availabilityFilterStateLeads")
      );
    }
  }
  if (isListingRoute(window.location.pathname)) {
    delete obj.is_search_active;
  }
  if (window.location.pathname === "/landlords") {
    delete obj.status;
  }
  if (!queryObject.availability_date_to) {
    delete obj.availability_date_to;
  }
  if (queryObject.availability_date_to) {
    delete obj.availability_date_to;
    obj.availability_dates = `${formatDate(
      queryObject.availability_date_to
      , "MMMM Do")}`;
  }

  if (queryObject.bedrooms_from || queryObject.bedrooms_to) {
    obj.bedrooms = `${queryObject.bedrooms_from ?? queryObject.bedrooms_to}+ Beds`;
  }
  delete obj.bedrooms_from;
  delete obj.bedrooms_to;

  if (queryObject.bathrooms_from || queryObject.bathrooms_to) {
    obj.bathrooms = `${queryObject.bathrooms_from ?? queryObject.bathrooms_to}+ Bath`;
  }
  delete obj.bathrooms_from;
  delete obj.bathrooms_to;


  if (queryObject.locations) {
    let locationsArray = queryObject.locations;
    if (locationsArray.length > 1) {
      obj.locations = `${locationsArray.length} Locations`;
    } else {
      obj.locations = locationsArray[0];
    }
  } else {
    delete obj.locations;
  }
  if (obj.tags && queryObject.tags) {
    let tagsArray = queryObject.tags;
    if (tagsArray.length > 1) {
      obj.tags = `${tagsArray.length} Tags`;
    } else {
      obj.tags = tagsArray[0];
    }
  }
  if (obj.pets_allowed && queryObject.pets_allowed) {
    if (queryObject.pets_allowed.length === 1) {
      obj.pets_allowed = beautifiedKeyStyling(queryObject.pets_allowed[0]);
    } else {
      obj.pets_allowed = `${queryObject.pets_allowed.length} Pets`;
    }
  }
  if (obj.parking_type) {
    obj.parking_type = "Has Parking";
  }
  if (queryObject.asked_rent_from && queryObject.asked_rent_to) {
    obj.pricing = `${formatCurrency(obj.asked_rent_from)} - ${formatCurrency(obj.asked_rent_to)}`;
    delete obj.asked_rent_to;
    delete obj.asked_rent_from;
  }
  if (!queryObject.asked_rent_from) {
    delete obj.asked_rent_from;
  }
  if (!queryObject.asked_rent_to) {
    delete obj.asked_rent_to;
  }
  if (queryObject.asked_rent_from && !queryObject.asked_rent_to) {
    obj.pricing = formatCurrency(obj.asked_rent_from);
    delete obj.asked_rent_from;
  }
  if (!queryObject.asked_rent_from && queryObject.asked_rent_to) {
    obj.pricing = formatCurrency(obj.asked_rent_to);
    delete obj.asked_rent_to;
  }
  if (!queryObject.asked_rent_from && !queryObject.asked_rent_to) {
    delete obj.asked_rent_from;
    delete obj.asked_rent_to;
  }
  if (queryObject.filter_by_agent && queryObject.filter_by_agent === "true") {
    obj.filter_by_agent = "Just my listings";
  }
  if (obj.search) {
    delete obj.search;
  }

  const keys = Object.keys(obj);
  keys.forEach((key) => {
    res.push({
      [key]: obj[key],
    });
  });
  return res;
};

export const filterOptionTextAndValueGenerator = (option: IObjectKeys) => {
  const _newURLSearchParams = new URLSearchParams(window.location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  if (option.pets_allowed && option.pets_allowed.includes("Pets")) {
    return {
      name: Object.entries(option)[0][0],
      value: Object.entries(option)[0][1],
      showTooltip: true,
      content: queryObject.pets_allowed,
      isTextFixed: true,
    };
  }
  if (option.locations && option.locations.includes("Locations")) {
    return {
      name: Object.entries(option)[0][0],
      value: Object.entries(option)[0][1],
      showTooltip: true,
      content: queryObject.locations,
    };
  }
  if (option.tags) {
    if (option.tags.includes("Tags")) {
      return {
        name: Object.entries(option)[0][0],
        value: Object.entries(option)[0][1],
        showTooltip: true,
        content: queryObject.tags,
      };
    }
    return {
      name: Object.entries(option)[0][0],
      value: tags.find(tag => tag.name === Object.values(option)[0])?.label,
      showTooltip: false,
      content: queryObject.tags,
    };
  }

  return {
    name: Object.entries(option)[0][0],
    value: Object.entries(option)[0][1],
  };
};

export const removeFilterFromOptions = (
  option: any,
  dispatch: any,
  listingsViewType: any,
  paginationValues: any
) => {
  const _newURLSearchParams = new URLSearchParams(window.location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  if (option.name === "status") {
    localStorage.setItem("availabilityFilterState", "Show-all");
  } else {
    delete queryObject[option.name];
  }
  if (option.name === "is_search_active") {
    localStorage.setItem("availabilityFilterStateLeads", "All");
  } else {
    delete queryObject[option.name];
  }
  if (option.name === "pricing") {
    delete queryObject["asked_rent_from"];
    delete queryObject["asked_rent_to"];
  }
  if (option.name === "availability_dates") {
    delete queryObject["availability_date_to"];
  }

  if (["bathrooms", "bathrooms_from", "bathrooms_to"].includes(option.name)) {
    delete queryObject["bathrooms_from"];
    delete queryObject["bathrooms_to"];
    delete queryObject["bathrooms"];
  }
  if (["bedrooms", "bedrooms_from", "bedrooms_to"].includes(option.name)) {
    delete queryObject["bedrooms_from"];
    delete queryObject["bedrooms_to"];
    delete queryObject["bedrooms"];
  }

  const filterOptions: any = {
    ...queryObject,
    [is_search_active]: localStorage.getItem("availabilityFilterStateLeads"),
  };

  if (!window.location.pathname.includes('landlords')) {
    filterOptions[status] = localStorage.getItem("availabilityFilterState");
  }

  const newSearchQuery = createFilterQuery(
    listingsViewType,
    filterOptions,
    paginationValues,
    queryObject,
    "",
    window.location.pathname.substring(1)
  );
  dispatch(
    push({
      pathname: window.location.pathname,
      search: newSearchQuery,
    })
  );
};

export const setSelectedSingleLocationInMultiselect = (
  selectValue: string,
  locations: any
) => {
  const [town, sub_area, sub_sub_area] = selectValue.split(">");
  let checkLocationsArray: any = [];
  let checkedItem: any = locations.find(
    (item: IObjectKeys) => item.value === town.trim()
  );
  if (town && !sub_area && !sub_sub_area) {
    setTownsArray(checkedItem, town, checkLocationsArray);
  } else if (town && sub_area && !sub_sub_area) {
    setSubAreas(checkedItem, sub_area, checkLocationsArray);
  } else {
    setSubSubArea(checkedItem, sub_area, sub_sub_area, checkLocationsArray);
  }

  return checkLocationsArray;
};

const setTownsArray = (
  checkedItem: any,
  town: string,
  checkLocationsArray: any
) => {
  if (!checkedItem.children) {
    checkLocationsArray.push(town);
  } else {
    checkedItem.children.forEach((item: any) => {
      if (item.children) {
        item.children.forEach((child: any) =>
          checkLocationsArray.push(child.value)
        );
      } else {
        checkLocationsArray.push(item.value);
      }
    });
  }
  return checkLocationsArray;
};

const setSubAreas = (
  checkedItem: any,
  sub_area: string,
  checkLocationsArray: any
) => {
  let checkedSubItem = checkedItem.children.find(
    (item: any) => item?.value?.split(">")[1]?.trim() === sub_area.trim()
  );
  if (checkedSubItem.children) {
    checkedSubItem.children.forEach((sub_sub: any) => {
      checkLocationsArray.push(sub_sub.value);
    });
  } else {
    checkLocationsArray.push(checkedSubItem.value);
  }
  return checkLocationsArray;
};
export function isStringified(str: any) {
  try {
    return JSON.parse(str);
  } catch (err) {
    return str;
  }
}
const setSubSubArea = (
  checkedItem: any,
  sub_area: string,
  sub_sub_area: string,
  checkLocationsArray: any
) => {
  let checkedSubSubItem = checkedItem.children
    .find((item: any) => item?.value?.split(">")[1]?.trim() === sub_area.trim())
    .children.find(
      (sub_item: any) =>
        sub_item.value.split(">")[2].trim() === sub_sub_area.trim()
    );
  checkLocationsArray.push(checkedSubSubItem.value);
  return checkLocationsArray;
};

export const generateLeadFilterData = (url: any, queryObject: IObjectKeys) => {
  const formattedData: any = {};
  formattedData.myLeads = true;
  formattedData.size =
    +isStringified(
      JSON.parse(localStorage.getItem("leads_showing_count") as string)
    ) || 24;
  if (queryObject.locations) {
    formattedData.locations = isStringified(queryObject.locations);
  }
  if (queryObject.search && queryObject.search.length) {
    formattedData.search = queryObject.search;
  }
  if (queryObject.tags) {
    formattedData.tags = isStringified(queryObject.tags);
  }
  if (queryObject.pets_allowed) {
    formattedData.pets_allowed = isStringified(queryObject.pets_allowed);
  }
  if (queryObject.parking_type) {
    formattedData.parking_type = isStringified(queryObject.parking_type);
  }
  if (!localStorage.getItem("availabilityFilterStateLeads")) {
    if (queryObject.is_search_active) {
      formattedData.is_search_active =
        isStringified(queryObject.is_search_active) === "Actively-Searching";
    }
  } else {
    if (localStorage.getItem("availabilityFilterStateLeads") !== "All") {
      formattedData.is_search_active =
        isStringified(localStorage.getItem("availabilityFilterStateLeads")) ===
        "Actively-Searching";
    }
  }
  if (queryObject.availability_date_to) {
    formattedData.availability_date_to = queryObject.availability_date_to;
  }
  if (queryObject.bathrooms_from) {
    formattedData.bathrooms_from = isStringified(queryObject.bathrooms_from);
    formattedData.bathrooms_to = isStringified(queryObject.bathrooms_from);
  }
  if (queryObject.bedrooms_from) {
    formattedData.bedrooms_from = isStringified(queryObject.bedrooms_from);
    formattedData.bedrooms_to = isStringified(queryObject.bedrooms_to);
  }
  if (queryObject.asked_rent_from) {
    formattedData.asked_rent_from = isStringified(queryObject.asked_rent_from);
  }
  if (queryObject.asked_rent_to) {
    formattedData.asked_rent_to = isStringified(queryObject.asked_rent_to);
  }
  if (queryObject.current) {
    formattedData.current = +queryObject.current;
  }
  if (queryObject.pageSize) {
    formattedData.pageSize = +queryObject.pageSize;
  }

  return formattedData;
};
interface IDelayedSearch {
  value: string;
  router: any;
  filterOptionsWithoutSortOption: IObjectKeys;
  queryObject: IObjectKeys;
  queryObjectWithoutSortOption: IObjectKeys;
  lId: string;
  listingsViewType: any;
  listingsPagination: any;
  dispatch: any;
  landlordsQueryParams: any;
  teamsQueryAndPagination: any;
  activitiesQueryAndPagination: any;
  followUpsQueryAndPagination: any;
  leadsPagination: any;
}
export const delayedSearchForSearchBarHandler = (props: IDelayedSearch) => {
  props.value.length === 1 && AnalyticsService.reportEvent("using_the_search");
  const pathname = props.router.location.pathname;
  props.filterOptionsWithoutSortOption["locations"] = props.queryObject.locations;
  props.filterOptionsWithoutSortOption["pets_allowed"] = props.queryObject.pets_allowed;
  props.filterOptionsWithoutSortOption["parking_type"] = props.queryObject.parking_type;
  props.queryObjectWithoutSortOption["locations"] = props.queryObject.locations;
  props.queryObjectWithoutSortOption["pets_allowed"] = props.queryObject.pets_allowed;
  props.queryObjectWithoutSortOption["parking_type"] = props.queryObject.parking_type;

  if (isListingRoute(pathname) || pathname.includes("Listing")) {
    if (!props.lId) {
      const newSearchQuery = createFilterQuery(
        props.listingsViewType,
        {
          ...props.filterOptionsWithoutSortOption,
          search: props.value,
        },
        { ...props.listingsPagination, current: 1 },
        props.queryObjectWithoutSortOption
      );
      props.dispatch(
        push({
          pathname: routes.listings.path,
          search: newSearchQuery,
        })
      );
    } else {
      props.dispatch(
        setFilterOptions({
          search: props.value,
        })
      );
      props.dispatch(push(`/listings?search=${props.value}&page=1`));
    }
  } else if (pathname.includes(routes.landlords.path)) {
    const newSearchQuery = createFilterQuery(
      "",
      {
        ...props.landlordsQueryParams,
        searchValue: props.value,
      },
      {
        current: props.landlordsQueryParams.page,
        page: props.landlordsQueryParams.page,
      },
      props.queryObject
    );
    props.dispatch(
      setLandlordsQueryParams({
        ...props.landlordsQueryParams,
        searchValue: props.value,
      })
    );
    props.dispatch(
      push({
        pathname: routes.landlords.path,
        search: newSearchQuery,
      })
    );
  } else if (pathname.includes("team")) {
    if (pathname.includes(routes.teams.path)) {
      props.dispatch(
        setTeamsQueryAndPagination({
          ...props.teamsQueryAndPagination,
          page: 1,
          current: 1,
          searchValue: props.value,
        })
      );
    } else if (pathname.includes("/teamDashboard")) {
      props.dispatch(
        setActivitiesQueryAndPagination({
          ...props.activitiesQueryAndPagination,
          page: 1,
          current: 1,
          searchValue: props.value,
        })
      );
    }
  } else if (pathname.includes(routes.followUps.path)) {
    props.dispatch(
      setFollowUpsQueryAndPagination({
        ...props.followUpsQueryAndPagination,
        page: 1,
        searchValue: props.value,
      })
    );
    props.dispatch(
      push({
        search: `${props.value
          ? `${Object.keys(props.queryObject).length ? "&" : ""}searchValue=${props.value
          }`
          : ""
          }${props.queryObject.view ? `&view=${props.queryObject.view}` : ""}${props.queryObject.sortField
            ? `&sortField=${props.queryObject.sortField}`
            : ""
          }${props.queryObject.sortOrder
            ? `&sortOrder=${props.queryObject.sortOrder}`
            : ""
          }`,
      })
    );
  } else if (isLeadsRoute(pathname)) {
    const newSearchQuery = createURL(
      urlToSearchState(
        createFilterQuery(
          "",
          {
            ...props.queryObject,
            search: props.value,
            is_search_active: props.queryObject.is_search_active,
            pets_allowed: props.queryObject.pets_allowed,
          },
          props.leadsPagination,
          props.queryObject,
          null,
          "leads"
        )
      )
    );
    props.dispatch(
      push({
        pathname: routes.leads.path,
        search: newSearchQuery,
      })
    );
  }
};

export const petsEffect = (enums: any, setPetsCheckboxOptions: any) => (() => {
  if (enums.length) {
    const petsAllowedEnum = enums.find((item: EnumInterface) => item.key === pets_allowed);
    const _petsCheckboxOptions: CheckboxOptionType[] = [];
    petsAllowedEnum.options.forEach((option: IObjectKeys) => {
      Object.entries(option).forEach(([key, value]) => _petsCheckboxOptions.push({ label: value, value: key }));
    });
    setPetsCheckboxOptions(_petsCheckboxOptions);
  }
})();

export interface IPriceInterface {
  from: number | string;
  to: null | number | string;
}
