import {IObjectKeys} from "../../helpers/globalTypes";

export enum NotableEnum {
    listing_note = "listing_note",
    landlord_note = "landlord_note",
}

export enum TodoStatusEnum {
    completed = "completed",
    in_progress = "in_progress",
}

export interface INote {
    _id: string;
    title: string;
    description: string;
    todo_at: Date;
    created_by: string;
    notification_id?: string | null | IObjectKeys;
    landlord_id?: string | null | IObjectKeys;
    listing_id?: string | null | IObjectKeys;
    notable_type: NotableEnum;
    todo_status: TodoStatusEnum;
}

export interface IFollowUpsQuery {
    page: number;
    pageSize: number;
    due_in_days?: number;
    todo_status?: string;
    past_due?: boolean;
    sortField?: string;
    sortOrder?: number;
    fillAll?: boolean;
    view?: string | null;
}