import React, { useEffect, useRef, useState } from "react";
import SmartPhoneIcon from "./icons/SmartPhoneIcon";
import { Form, Input } from "antd";
import "./VerifyPhoneCode.scss";
import { useDispatch } from "react-redux";
import { verifyPhoneNumber } from "../../../../../../store/user/action";
import {openNotification} from "../../../../../../helpers/globalFunctions";

const VerifyPhoneCode = ({form}:any) => {
    const refedInput = useRef();
    const dummyRef = useRef();
    const dispatch = useDispatch();
    const [verifyArray, setVerifyArray] = useState<any>([
        { focused: true, refedInput: refedInput },
        { focused: false },
        { focused: false },
        { focused: false },
        { focused: false },
        { focused: false },
    ]);
    useEffect(() => {
        // @ts-ignore
        refedInput.current.focus();
    }, [verifyArray]);
    useEffect(() => {
        form.resetFields();
    }, [form]);
    return (
        <div className={"verify-code-modal-wrapper"}>
            <SmartPhoneIcon/>
            <h3>Verify your phone</h3>
            <span>
                Please enter the code you
                recieved by text
            </span>
            <p>Enter Code</p>
            <Form
                form={form}
                onFinishFailed={()=>{
                    openNotification("error", "Please fill all the fields");
                }}
                onFieldsChange={(e) => {
                    setVerifyArray(verifyArray.map((elem: any, index: any) => {
                        // @ts-ignore
                        if (index === +e[0].name[0]) return { focused: true, refedInput: refedInput };
                        return { focused: false };
                    }));
                }}
                onFinish={(data) => {
                    dispatch(verifyPhoneNumber(Object.values(data).join("")));
                }}
            >
                {verifyArray.map((element: any, index: number) => {
                    return (
                        <Form.Item
                            rules={[{ required: true , message:""}, { max: 1 }]}
                            name={index + 1} key={index}
                            // @ts-ignore
                        >
                            <Input maxLength={1} autoFocus={element.focused} ref={element.refedInput || dummyRef}/>
                        </Form.Item>
                    );
                })}

            </Form>
            <button
                onClick={(e) => {
                    form.submit();
                }}
                className={"verify-code-modal-wrapper--verifyBtn"}
            >
                Verify
            </button>
            {
                //@ts-ignore
                !verifyArray.some(obj => obj.refedInput) &&
                <input
                    type="text"
                    //@ts-ignore
                    ref={refedInput}
                    className={"verify-code-modal-wrapper--notVisibleInput"}
                />
            }
        </div>
    );
};

export default VerifyPhoneCode;