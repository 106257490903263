import { all } from "redux-saga/effects";
import authSaga from "store/user/sagas";
import enumSaga from "store/enums/sagas";
import areaSaga from "store/areas/sagas";
import listingSaga from "store/listings/sagas";
import notificationsSaga from "store/notifications/sagas";
import landlordsSaga from "store/landlords/sagas";
import followUpsSaga from "store/followUps/sagas";
import activitiesSaga from "store/activities/sagas";
import teamsSaga from "store/teams/sagas";
import leadsSaga from "store/leads/sagas";
import requirementSaga from "store/requirement/sagas";
import flatFileEnumsSaga from "store/flatfileEnums/sagas";
import dashboardSaga from "store/dashboard/sagas";
import paymentSaga from "store/payment/sagas"
import shareableSaga from "store/shareable/sagas";
import renterSaga from "store/renter/sagas";
import agentSaga from "store/agent/sagas";

export default function* rootSaga() {
  yield all([
    authSaga(),
    enumSaga(),
    areaSaga(),
    listingSaga(),
    notificationsSaga(),
    landlordsSaga(),
    followUpsSaga(),
    activitiesSaga(),
    teamsSaga(),
    requirementSaga(),
    leadsSaga(),
    flatFileEnumsSaga(),
    dashboardSaga(),
    paymentSaga(),
    shareableSaga(),
    renterSaga(),
    agentSaga()
  ]);
}
