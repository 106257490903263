import BaseButton from "components/Shared/BaseButton";
import BaseModal from "components/Shared/BaseModal";
import React, { useEffect, useState } from "react";
import "./LookingModal.scss";
import { IObjectKeys } from "helpers/globalTypes";
import InfoModal from "../info-modal/InfoModal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { singleLeadDataSelector } from "store/leads/selectors";
import NotifyAgentsModal from "../notify-agents-modal/NotifyAgentsModal";
import { setLeadChangeStatusSuccess } from "store/requirement/action";
import { AnalyticsService } from "helpers/analyticsService";

const LookingModal = ({ toggle = false, setToggle }: IObjectKeys) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const leadData = useSelector(singleLeadDataSelector);
  const dispatch = useDispatch();
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setToggle(false);
    }
  }, [isSuccess]);

  const handleCancel = () => {
    setToggle(false);
    dispatch(setLeadChangeStatusSuccess());
  };

  const handleNotify = () => {
    AnalyticsService.reportEditRequirementsBeforeAlertingAgentsClick("no");
    setIsNotifyModalOpen(true);
    handleCancel();
  };

  return (
    <>
      <BaseModal
        isModalVisible={toggle}
        modalFooter={null}
        width={355}
        onCancel={() => {
          AnalyticsService.reportEditRequirementsBeforeAlertingAgentsClick("cancel");
          handleCancel();
        }}
        classname="looking-modal-wrapper"
        modalTitle=""
      >
        <div className="looking-modal flex flex-column align-center pb-10">
          <h3 className="fw-600 pb-3 p-20">Let’s find your next home</h3>
          <p className="font-16 text-center looking-modal-text">
            Would you like to review or edit your requirements before alerting
            agents?
          </p>
          <div className="looking-modal-buttons w-100 flex justify-around">
            <BaseButton classnames="" onClick={() => {
              AnalyticsService.reportEditRequirementsBeforeAlertingAgentsClick("yes");
              handleCancel();
            }}>
              <Link
                to={`/requirement/edit/${leadData?.requirement?.agentUser_ids?.[0]}?reqStep=2&requirement_id=${leadData?.requirement?._id}?searchStatus=true`}
              >
                Yes
              </Link>
            </BaseButton>
            <BaseButton
              classnames="secondary-small-btn-decline"
              onClick={handleNotify}
            >
              No
            </BaseButton>
          </div>
        </div>
      </BaseModal>
      <InfoModal
        header="Thank you!"
        text="Each piece of information makes us better at helping you find your perfecct home."
        setToggle={setIsSuccess}
        toggle={isSuccess}
      />
      <NotifyAgentsModal
        toggle={isNotifyModalOpen}
        setToggle={setIsNotifyModalOpen}
      />
    </>
  );
};

export default LookingModal;
