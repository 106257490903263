import React from "react";

const EyeIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_669_661)">
        <path
          d="M13 5.25098C8.03242 5.25098 3.52756 7.96878 0.203437 12.3832C-0.0678122 12.7449 -0.0678122 13.2502 0.203437 13.6118C3.52756 18.0316 8.03242 20.7494 13 20.7494C17.9676 20.7494 22.4724 18.0316 25.7966 13.6171C26.0678 13.2555 26.0678 12.7502 25.7966 12.3885C22.4724 7.96878 17.9676 5.25098 13 5.25098ZM13.3563 18.4571C10.0588 18.6645 7.33569 15.9467 7.54311 12.6438C7.71331 9.92071 9.92053 7.71349 12.6437 7.5433C15.9412 7.33587 18.6643 10.0537 18.4569 13.3565C18.2814 16.0743 16.0742 18.2816 13.3563 18.4571ZM13.1915 15.9361C11.4151 16.0477 9.94712 14.5851 10.0641 12.8087C10.1545 11.3408 11.3459 10.1547 12.8138 10.059C14.5903 9.9473 16.0582 11.4099 15.9412 13.1863C15.8455 14.6596 14.6541 15.8456 13.1915 15.9361Z"
          fill="#141414"
        />
      </g>
      <defs>
        <clipPath id="clip0_669_661">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIcon;
