import * as types from "./types";
import { NotificationI } from "./reducer";
import { IObjectKeys } from "../../helpers/globalTypes";

interface GetNotificationsQueryI {
  fillAll?: boolean;
  page: number;
  size: number;
  replaceExistingData?: boolean;
}

export function getNotificationsList(getListingsQuery: IObjectKeys) {
  return {
    type: types.GET_NOTIFICATIONS,
    payload: getListingsQuery,
  };
}

export function getNotificationsListSuccess(users: object) {
  return { type: types.GET_NOTIFICATIONS_SUCCESS, payload: users };
}

export function getNotificationsListFail(error: object) {
  return { type: types.GET_NOTIFICATIONS_FAIL, payload: error };
}

export function getUnreadNotificationsList(
  getListingsQuery: GetNotificationsQueryI,
) {
  return {
    type: types.GET_UNREAD_NOTIFICATIONS,
    payload: { ...getListingsQuery, read: 0 },
  };
}

export function getUnreadNotificationsListSuccess(users: object) {
  return { type: types.GET_UNREAD_NOTIFICATIONS_SUCCESS, payload: users };
}

export function getUnreadNotificationsListFail(error: object) {
  return { type: types.GET_UNREAD_NOTIFICATIONS_FAIL, payload: error };
}

export function getReadNotificationsList(
  getListingsQuery: GetNotificationsQueryI,
) {
  return {
    type: types.GET_READ_NOTIFICATIONS,
    payload: { ...getListingsQuery, read: 1 },
  };
}

export function getReadNotificationsListSuccess(users: object) {
  return { type: types.GET_READ_NOTIFICATIONS_SUCCESS, payload: users };
}

export function getReadNotificationsListFail(error: object) {
  return { type: types.GET_READ_NOTIFICATIONS_FAIL, payload: error };
}

export function getUnreadNotificationsCount(userId: string) {
  return {
    type: types.GET_UNREAD_NOTIFICATIONS_COUNT,
    payload: userId,
  };
}

export function getUnreadNotificationsCountSuccess(count: number) {
  return { type: types.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS, payload: count };
}

export function getUnreadNotificationsCountFail(error: object) {
  return { type: types.GET_UNREAD_NOTIFICATIONS_COUNT_FAIL, payload: error };
}

export function makeNotificationAsRead(payload: {
  first_read: Date;
  notification_id: string;
}) {
  return {
    type: types.MAKE_NOTIFICATIONS_AS_READ,
    payload,
  };
}
export function makeNotificationAsUnread(payload: {
  first_read: Date;
  notification_id: string;
}) {
  return {
    type: types.MAKE_NOTIFICATIONS_AS_UNREAD,
    payload,
  };
}
export function markAllRead(payload: { first_read: Date }) {
  return {
    type: types.MARK_ALL_READ,
    payload,
  };
}

export function markAllReadSagaSuccess(payload: NotificationI) {
  return { type: types.MARK_ALL_READ_SUCCESS, payload };
}

export function markAllReadSagaFail(payload: NotificationI) {
  return { type: types.MARK_ALL_READ_FAIL, payload };
}

export function makeNotificationAsReadSuccess(payload: NotificationI) {
  return { type: types.MAKE_NOTIFICATIONS_AS_READ_SUCCESS, payload };
}

export function makeNotificationAsReadFail(error: object) {
  return { type: types.MAKE_NOTIFICATIONS_AS_READ_FAIL, payload: error };
}

export function makeNotificationAsUnreadSuccess(error: object) {
  return { type: types.MAKE_NOTIFICATIONS_AS_UNREAD_SUCCESS, payload: error };
}

export function makeNotificationAsUnreadFail(error: object) {
  return { type: types.MAKE_NOTIFICATIONS_AS_UNREAD_FAIL, payload: error };
}

export function clearReadNotificationsStates() {
  return { type: types.CLEAR_READ_NOTIFICATIONS_STATES };
}
export function clearUnReadNotificationsStates() {
  return { type: types.CLEAR_UNREAD_NOTIFICATIONS_STATES };
}
