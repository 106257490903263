import { makeMultiEnumOption } from "./../../../../helpers/globalFunctions";
import { IObjectKeys } from "helpers/globalTypes";
export const filterAddListingData = (data: IObjectKeys) => {
    data.amenities = makeMultiEnumOption(data.amenities);
    data.stove_type = makeMultiEnumOption(data.stove_type);
    data.pool_type = makeMultiEnumOption(data.pool_type);
    if (data.hasAdditionalStorage) {
        data.storage = makeMultiEnumOption(data.storage);
    } else {
        data.storage = {};
    }

    if (!data.hasFirePlace) {
        data.fireplace = "";
    }
    return data;
};