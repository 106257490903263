import React from "react";

interface IPropsPetsOutput {
  field: { format: any; key: string; inputFormat: any; prefix?: string; suffix?: string; label?: string };
  value: any;
  label?: string;
}

const PetsOutput = ({ field, value, label }: IPropsPetsOutput) => {
  return (
    <>
      <div>
        <label className="block text-base font-medium ">{label || field.label}</label>
        <div className="mt-2 ">
          {value?.length ? (
            value?.map((pet: { species: string; amount: number; breed: string }) => {
              return (
                <div key={`item-value-${pet.species}`}>
                  {pet.species} x{pet.amount} {pet.breed && <>({pet.breed})</>}
                </div>
              );
            })
          ) : (
            <div>--</div>
          )}
        </div>
      </div>
    </>
  );
};

export default PetsOutput;
