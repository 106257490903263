import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { StepFormComponent } from "../ListingForm/types";
import Fees from "../ListingForm/AvailabilityPart/Fees";
import { useDispatch, useSelector } from "react-redux";
import {
    listingDataSelector,
    listingUpdateSuccessSelector,
} from "../../../store/listings/selectors";
import { IObjectKeys } from "../../../helpers/globalTypes";
import {
    clearListingUpdateState,
    updateListing,
} from "../../../store/listings/action";
import {
    makeMultiEnumOption,
    parseMultiEnumOptions,
} from "../../../helpers/globalFunctions";

const FeesStep = ({ form, next }: StepFormComponent) => {
    const dispatch = useDispatch();
    const listingData = useSelector(listingDataSelector);
    const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
    const [showDepositAmount, setShowDepositAmount] = useState(
        listingData?.move_in_fees?.security_deposit
    );
    const [showOtherBFInput, setShowOtherBFInput] = useState(
        listingData?.broker_fees === "other"
    );
    const [showPetDepositAmount, setShowPetDepositAmount] = useState(listingData?.move_in_fees?.pet_deposit);
    const onSubmit = (formData: IObjectKeys) => {
        if (formData.move_in_fees?.indexOf("security_deposit") < 0) {
            formData.security_deposit_amount = 0;
        }

        if (formData.move_in_fees?.indexOf("pet_deposit") < 0) {
            formData.pet_deposit_amount = 0;
        }

        formData.move_in_fees = makeMultiEnumOption(formData.move_in_fees);
        if (listingData._id) {
            dispatch(updateListing({ lId: listingData._id, ...formData }));
        }
    };
    useEffect(() => {
        if (updateListingSuccess) {
            next && next();
        }
        return () => {
            dispatch(clearListingUpdateState());
        };
    }, [dispatch, next, updateListingSuccess]);
    return (
        <Form
            onFinish={onSubmit}
            form={form}
            initialValues={{
                security_deposit_amount: listingData.asked_rent,
                move_in_fees:
                    listingData.move_in_fees &&
                    parseMultiEnumOptions(listingData.move_in_fees),
                broker_fees: listingData.broker_fees,
                other_broker_fee: listingData.other_broker_fee,
            }}
            onFieldsChange={(changedFieldInfo) => {
                // eslint-disable-next-line no-restricted-globals
                if (Array.isArray(changedFieldInfo[0]?.name)) {
                    // eslint-disable-next-line no-restricted-globals
                    if (changedFieldInfo[0]?.name?.indexOf("move_in_fees") > -1) {
                        setShowDepositAmount(
                            changedFieldInfo[0]?.value?.indexOf("security_deposit") > -1
                        );
                    }
                    // eslint-disable-next-line no-restricted-globals
                    if (changedFieldInfo[0]?.name?.indexOf("broker_fees") > -1) {
                        setShowOtherBFInput(changedFieldInfo[0]?.value === "other");
                    }

                    if (changedFieldInfo[0]?.name?.indexOf("move_in_fees") > -1) {
                        setShowPetDepositAmount(
                            changedFieldInfo[0]?.value?.indexOf("pet_deposit") > -1,
                          );
                    }
                    
                }
            }}
        >
            <Fees
                showDepositAmount={showDepositAmount}
                showOtherBFInput={showOtherBFInput}
                showPetDepositAmount={showPetDepositAmount}
            />
        </Form>
    );
};
export default FeesStep;
