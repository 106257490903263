import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";

export const setWindowType = (payload: IObjectKeys) => ({
  type: types.SET_WINDOW_TYPE,
  payload,
});

export const setWindowTypeSuccess = (payload: IObjectKeys) => ({
  type: types.SET_WINDOW_TYPE_SUCCESS,
  payload,
});
