import { StarIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon, BuildingOfficeIcon, MapPinIcon, TrophyIcon, UsersIcon } from "@heroicons/react/24/outline";
import BaseButton from "components/Shared/BaseButton";
import Section from "components/Shared/Section/Section";
import React, { useState } from "react";

const ServiceMeta = ({ service }: any) => {
  return (
    <div className="text-sm flex flex-col gap-1">
      <p>Location: {service.location}</p>
      <p className="mb-2">
        Rating: {Math.floor(service.rating * 100) / 100} ({service.num_reviews} reviews)
      </p>
      {service.years_in_business ? (
        <p className="flex justify-start gap-1 items-center">
          <BuildingOfficeIcon className="text-purple-500 h-5 w-5" />
          {service.years_in_business} years in business
        </p>
      ) : null}
      {service.num_hires ? (
        <p className="flex justify-start gap-1 items-center">
          <TrophyIcon className="text-purple-500 h-5 w-5" />
          Hired {service.num_hires} times
        </p>
      ) : null}
      {service.similar_jobs_done ? (
        <p className="flex justify-start gap-1 items-center">
          <MapPinIcon className="text-purple-500 h-5 w-5" />
          {service.similar_jobs_done} jobs done near year
        </p>
      ) : null}
      {service.num_of_employees ? (
        <p className="flex justify-start gap-1 items-center">
          <UsersIcon className="text-purple-500 h-5 w-5" /> {service.num_of_employees} employees
        </p>
      ) : null}
    </div>
  );
};

const TextWithShowMore = ({ text, wordCount }: any) => {
  const [showMore, setShowMore] = useState(false);
  const textArr = text.split(" ");
  const doShowMore = textArr.length > wordCount;
  const showText = textArr.splice(0, wordCount).join(" ");
  return (
    <div>
      <div className="flex flex-col justify-start gap-2">
        {`${showText}${doShowMore && !showMore ? "..." : " "}`}
        {doShowMore && (
          <>
            {showMore && <>{textArr.join(" ")}</>}
            <BaseButton className="text-sm" variant="link" onClick={() => setShowMore(!showMore)}>
              <>Show {showMore ? "Less" : "More"}</>
            </BaseButton>
          </>
        )}
      </div>
    </div>
  );
};

const ThumbtackProsCard = ({ service }: any) => {
  return (
    <Section shadow key={service.name}>
      <div className="flex flex-row gap-8">
        <div className="w-50">
          <img className="aspect-[4/5] w-50 flex-none rounded-lg object-cover" src={service.image_url} alt="" />
        </div>
        <div className="w-full">
          <div className="mb-4 pb-2 border-b border-purple-200">
            <div className="flex justify-between items-center">
              <BaseButton variant="link" href={service.thumbtack_url} target="_blank">
                <h2 className="text-2xl font-semibold leading-8 tracking-tight text-purple-600 flex items-center gap-2">
                  {service.business_name}
                  <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                </h2>
              </BaseButton>
              <BaseButton variant="secondary" size="sm" href={service.thumbtack_url} target="_blank">
                Contact
              </BaseButton>
            </div>
            {service.is_top_pro && (
              <div className="flex justify-start gap-1 items-center text-sm">
                <StarIcon className="text-green-500 h-5 w-5" /> Top Pro
              </div>
            )}
          </div>
          <div className="flex flex-wrap">
            <div className="w-full sm:w-1/3 mb-4 sm:mb-2">
              <ServiceMeta service={service} />
            </div>
            <div className="w-full sm:w-2/3">
              <TextWithShowMore text={service.introduction} wordCount={40} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

const ThumbtackProsGrid = ({ services, noResults }: any) => {
  if (!services || !services.results) {
    // Handle the case when services.results is null or undefined
    return <div>Type or select a service category above, add a zip code, then press "Enter".</div>;
  }

  return (
    <>
      <div role="list">
        {noResults && "No Results"}
        {services.results.map((service: { service_id: any }) => (
          <ThumbtackProsCard key={service.service_id} service={service} />
        ))}
      </div>
    </>
  );
};

export default ThumbtackProsGrid;
