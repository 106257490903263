import {
  availability_date_to,
  bathrooms_from,
  bathrooms_to,
  bedrooms_from,
  bedrooms_to,
  IFilterOptionsOptionalKeys,
} from "./mockListings";
import { createFilterQuery } from "../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { push } from "connected-react-router";
import { setListingsFilterOptions } from "../../../store/listings/action";
import moment from "moment";
import { US_DATE_FORMAT } from "../../../helpers/constants";
import { getFirstDayOfMonthByFutureMonth } from "../../../helpers/globalFunctions";
import { formatDate } from '../../../helpers/dateHelper';

export const globalHandleReset = (
  dispatch: Function,
  history: any,
  paginationValues: any,
  listingsFilterOptions: any,
  listingsViewType: string,
) => {
  const obj: IFilterOptionsOptionalKeys = {};
  Object.keys(listingsFilterOptions).forEach((key) => {
    // @ts-ignore
    obj[key] = undefined;
  });
  dispatch(setListingsFilterOptions(obj));
  if (paginationValues["current"] !== 1) {
    const _search = createFilterQuery(
      listingsViewType,
      {},
      paginationValues,
      {},
    );
    dispatch(
      push({
        pathname: history.location.pathname,
        search: _search,
      }),
    );
    return;
  }
  dispatch(push(history.location.pathname));
};

export const globalSetInitialDate = (
  filterOptions: any,
  setAvailabilityToDate: Function,
  setAvailabilityToDateObj: Function,
) => {
  if (filterOptions && filterOptions[availability_date_to]) {
    setAvailabilityToDateObj(moment(filterOptions[availability_date_to]));
    setAvailabilityToDate(
      formatDate(filterOptions[availability_date_to]),
    );
  } else {
    setAvailabilityToDateObj({});
    setAvailabilityToDate("");
  }
};

export const globalSetInitialBedroomsBathrooms = (
  filterOptions: any,
  setBedRoomsCount: Function,
  setBathRoomsCount: Function,
  type?: string,
) => {
  setBedRoomsCount({
    from:
      filterOptions && filterOptions[bedrooms_from]
        ? Number(filterOptions[bedrooms_from])
        : 0,
    to:
      filterOptions && filterOptions[bedrooms_to]
        ? Number(filterOptions[bedrooms_to])
        : 20,
  });
  setBathRoomsCount({

    from:
      filterOptions && filterOptions[bathrooms_from]
        ? Number(filterOptions[bathrooms_from])
        : 0,
    to:
      filterOptions && filterOptions[bathrooms_to]
        ? Number(filterOptions[bathrooms_to])
        : type === "more"
          ? 20
          : 10,
  });
};

export const detectActiveBtn = (activeNumber: number, isActiveBtn: number) => {
  if (activeNumber === isActiveBtn) {
    return "date-filter-dropdown-option-active";
  } else return "";
};
