import { IObjectKeys } from "helpers/globalTypes";
import React from "react";
import { Link } from "react-router-dom";
import OneNote from "../OneNote/OneNote";
import "./styles.scss";

interface IShowNotesCMProps {
  notes?: Array<IObjectKeys>;
}

const ShowNotesCM = (props: IShowNotesCMProps) => {
  const prevNotes = props?.notes?.slice(0, 2);
  return (
    <div className="contact-modal-notes-section p-6">
      <h3 className="text-lg font-semibold pb-2">Most Recent Notes</h3>
      <div className="notes-wrapper">
        {prevNotes?.map((note, index) => {
          return <OneNote key={index} note={note} />;
        })}
      </div>
      <p className="all-notes-link">
        {/* <Link to="/followups"> */}
        {/* See All Notes */}
        {/* </Link> */}
      </p>
    </div>
  );
};

export default ShowNotesCM;
