import React from "react";

const FollowUpEditIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="47" height="47" rx="4.5" fill="white"/>
            <rect x="0.5" y="0.5" width="47" height="47" rx="4.5" fill="url(#paint0_linear)"/>
            <rect x="0.5" y="0.5" width="47" height="47" rx="4.5" stroke="#141414"/>
            <path
                d="M30.5642 19.7035L29.2956 18.4347C28.7159 17.8551 27.7728 17.8551 27.1932 18.4347C26.6474 18.9805 18.3115 27.3171 17.7544 27.8742C17.6951 27.9335 17.6553 28.0124 17.6412 28.0895L17.0069 31.5151C16.9823 31.6479 17.0246 31.7844 17.1201 31.8799C17.2157 31.9755 17.3522 32.0177 17.4848 31.9932L20.9102 31.3588C20.9893 31.344 21.0671 31.3039 21.1255 31.2455L30.5642 21.806C31.1452 21.225 31.1453 20.2846 30.5642 19.7035ZM17.9221 31.0779L18.3058 29.0056L19.9941 30.6941L17.9221 31.0779ZM20.8355 30.3754L18.6245 28.1642L26.6966 20.0915L28.9076 22.3026L20.8355 30.3754ZM29.9842 21.226L29.4876 21.7226L27.2767 19.5114L27.7732 19.0148C28.033 18.755 28.4557 18.755 28.7155 19.0148L29.9842 20.2836C30.2446 20.544 30.2446 20.9655 29.9842 21.226Z"
                fill="black"/>
            <defs>
                <linearGradient id="paint0_linear" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="white" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default FollowUpEditIcon
