import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useLocation } from "react-router";
import { LISTING_AVAILABILITY_CIRCLES_STATUS, LISTING_STATUS_OPTIONS } from "../../../helpers/constants";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { isListingRoute } from "helpers/clientRoutes";
import { isCLAWUser } from "helpers/userHelpers";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const StatusFilter = ({ user }: any) => {
  const [showingStatus, setShowingStatus] = useState<any>({});
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const listingAvailStatus = localStorage.getItem("availabilityFilterState") ?? "";

  useEffect(() => {
    if (isListingRoute(pathname)) {
      if (listingAvailStatus) {
        setShowingStatus(LISTING_AVAILABILITY_CIRCLES_STATUS.find((item) => item.status === listingAvailStatus));
      } else {
        if (isCLAWUser(user)) {
          setShowingStatus(LISTING_AVAILABILITY_CIRCLES_STATUS.find((item) => item.status === "Available"));
        } else {
          setShowingStatus(LISTING_AVAILABILITY_CIRCLES_STATUS.find((item) => item.status === "Show-all"));
        }
      }
    } else {
      setShowingStatus({});
    }
  }, [pathname, listingAvailStatus]);

  const handleStatusChange = (status: any) => {
    console.log("handleStatusChange", status);

    // Getting the current search values
    const searchParams = new URLSearchParams(search);
    const searchObject: any = {};
    for (const [key, value] of searchParams.entries()) {
      searchObject[key] = value;
    }

    // Updating the searchObject values if needed
    searchObject.status = status;
    searchObject.listingType = searchObject.listingType || "grid";
    searchObject.page = searchObject.page || "1";
    searchObject.size = searchObject.size || "24";
    // Creating the new search query string
    const updatedSearchParams = new URLSearchParams(searchObject).toString();

    // Updating the status state and search results
    localStorage.setItem("availabilityFilterState", status);
    dispatch(
      push({
        pathname: window.location.pathname,
        search: updatedSearchParams,
      })
    );
    setShowingStatus(LISTING_AVAILABILITY_CIRCLES_STATUS.find((x) => x.status === status.key));
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="px-6 py-4 h-12 text-base flex justify-center items-center rounded-md hover:bg-purple-100 ring-1 ring-inset ring-purple-400 hover:text-purple-800 text-purple-900">
            {showingStatus?.text ?? "Status"}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-left bg-white shadow-lg rounded-lg border border-purple-500 ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {LISTING_STATUS_OPTIONS.map((status: any) => {
                return (
                  <Menu.Item key={status.status}>
                    <div
                      onClick={() => handleStatusChange(status.status)}
                      className="cursor-pointer text-base text-purple-800 block py-2 px-4 border border-gray-100"
                    >
                      {status.text}
                    </div>
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default StatusFilter;
