import React, { useEffect } from "react";
import { Carousel } from "antd";
import { IImage, Listing } from "../../../../../../../helpers/mockListings";
import { listingCardSliderSettings } from "../../../../../../../../../helpers/globalFunctions";
import availableHousePlaceholder from "../../../../../../../../../assets/images/listing-ph.png";
import "./ListCardItemSliderOrImage.scss";
import { ImageWrapper } from "components/RenterPortal/Components/ListingGridItem";
import BaseButton from "components/Shared/BaseButton";
interface IListingCardSliderOrImage {
  listItem: Listing;
  goToSingleListingPage: Function;
}

const ListingCardSliderOrImage = (props: IListingCardSliderOrImage) => {
  const { status, images } = props.listItem;
  const carouselRef = React.useRef(null);
  const MAX_CAROUSEL_LISTING_IMAGES = 3;

  useEffect(() => {
    // @ts-ignore
    if (carouselRef && carouselRef?.current && carouselRef?.current?.goTo) {
      // @ts-ignore
      carouselRef.current.goTo(0);
    }
  }, [images]);

  const getImageUrl = (imgs: IImage[]) => {
    for (let i of imgs) {
      if (i.url) return i.url;
    }
    return availableHousePlaceholder;
  };

  const imgStyleHandler = (imageArray: any) => {
    const classNameArray = ["card-single-image"];
    if (imageArray?.length === 0) classNameArray.push("contain-img");
    else classNameArray.push("cover-img");
    return classNameArray.join(" ");
  };

  return (
    <>
      {images && images.length > 0 ? (
        <Carousel {...listingCardSliderSettings} ref={carouselRef}>
          {images.slice(0, MAX_CAROUSEL_LISTING_IMAGES).map((image, index) => {
            return (
              <div key={index}>
                <ImageWrapper
                  onClick={() => props.goToSingleListingPage()}
                  url={getImageUrl([image])}
                  alt={image.url}
                  loading={index === 0 ? "eager" : "lazy"}
                />
              </div>
            );
          })}
          <div className="p-12">
            <BaseButton variant="primary" onClick={() => props.goToSingleListingPage()}>
              <span>View More</span>
            </BaseButton>
          </div>
        </Carousel>
      ) : (
        <img
          className={imgStyleHandler(images)}
          onClick={() => props.goToSingleListingPage()}
          src={getImageUrl(images || [])}
        />
      )}
    </>
  );
};

export default React.memo(ListingCardSliderOrImage);
