import React, { useEffect, useState } from "react";
import { Alert, Form, Input } from "antd";
import { FormInstance } from "antd/es/form";
import BaseModal from "../Shared/BaseModal";
import { invitationAgent } from "../../store/teams/action";
import { useDispatch, useSelector } from "react-redux";
import {
  agentErrorSelector,
  inviteAgentLoadingSelector,
  inviteAgentSuccessSelector,
} from "../../store/teams/selectors";
import BaseButton from "../Shared/BaseButton";
import { userSelector } from "store/user/selectors";
const AddTeamModal = ({
  visible,
  onClose,
  form,
}: {
  visible: boolean;
  onClose: () => void;
  form: FormInstance;
}) => {
  const dispatch = useDispatch();
  const getInviteTeamsSuccess = useSelector(inviteAgentSuccessSelector);
  const getAgentError = useSelector(agentErrorSelector);
  const inviteAgentLoading = useSelector(inviteAgentLoadingSelector);
  const [error, setError] = useState(null);
  const userData = useSelector(userSelector);

  const onSubmit = (data: any) => {
    dispatch(invitationAgent(data));
  };

  useEffect(() => {
    if (getAgentError) {
      setError(getAgentError.email);
    }
  }, [getAgentError]);
  useEffect(() => {
    setError(null);
  }, [getInviteTeamsSuccess]);

  return (
    <>
      <BaseModal
        classname={"add-team-modal"}
        isModalVisible={visible}
        dataTestId="add-team-modal"
        modalTitle={"Add Team Member"}
        onCancel={() => {
          setError(null);
          onClose();
        }}
        modalFooter={[
          <div className={"add-team-member-modal-footer"}>
            <BaseButton
              dataTestId="ok-button"
              onClick={() => form.submit()}
              classnames={"rb-primary-btn sm"}
              loading={inviteAgentLoading}
            >
              Send Invite
            </BaseButton>
          </div>,
        ]}
      >
        {error && <Alert message={error} type="error" />}
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          layout={"vertical"}
          data-test-id="add-form"
        >
          <Form.Item
            data-test-id="name-item"
            label="Full Name"
            name="name"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input autoComplete={"off"} data-test-id="name-input" />
          </Form.Item>
          {/* <Form.Item name="phone" label="Phone" rules={[{ required: true, message: "Please input phone` ASDON   4 !" }]}>
          <MaskedInput inputMode="numeric" mask={PHONE_NUMBER_MASK} placeholder={"Ex. (212) 555 6666"} />
        </Form.Item> */}
          <Form.Item
            data-test-id="email-item"
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input autoComplete={"off"} data-test-id="email-input" />
          </Form.Item>
          {userData?.agency?.subscription_level !== "partner" && (
            <span>
              *Once your new team member joins the team, your subscription plan
              will change to reflect the extra seat.
            </span>
          )}
          {/* <Form.Item label="Role">
            <Select disabled defaultValue={"Agent"}>
              <Option value="Agent">Agent</Option>
            </Select>
          </Form.Item> */}
        </Form>
      </BaseModal>
    </>
  );
};

export default AddTeamModal;
