import * as types from "./types";
import { SET_LEAD_PAGE_SHARED } from "./types";

const initialState: any = {
  user: {},
  authLoading: false,
  authError: null,
  updateUserSettingsLoading: false,
  updateUserSettingsSuccess: false,
  passwordResetLoading: false,
  resetPasswordSuccess: false,
  confirmResetPasswordSuccess: false,
  verifyPhoneSuccess: false,
  signUpPhoneVerified: false,
  verifyCodeLoading: false,
  getVerificationCodeSuccess: false,
  getVerificationCodeLoading: false,
  verifyLeadPhoneSuccess: false,
  verifyLeadCodeLoading: false,
  getLeadVerificationCodeSuccess: false,
  getLeadVerificationCodeLoading: false,
  passInfo: "",
  agentSignUpLoading: false,
  agentSignUpSuccess: false,
  newUserIsComing: false,
  isLeadPageShared: false,
  notConfirmedAgentId: "",
  agentDataExportLoading: false,
  agentDataExportSuccess: false,
  agentDataExportError: "",
  phoneVerificationError: null,
  verifyByEmailLinkSuccess: false,
  lastLocation: "",
  referralAgentName: "",
  updatedUser: {},
  userUpdateSuccess: null,
  updatedUserLoading: false,
  sendVerificationLink: false,
};

export default function userReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    // case types.SET_USER_PASSWORD:
    //   return {
    //     ...state,
    //     passInfo:
    //   };
    case SET_LEAD_PAGE_SHARED:
      return {
        ...state,
        isLeadPageShared: true,
      };
    case types.SIGN_UP_AGENT:
      return {
        ...state,
        agentSignUpLoading: true,
      };
    case types.SIGN_UP_AGENT_FAIL:
      return {
        ...state,
        agentSignUpLoading: false,
      };
    case types.SIGN_UP_AGENT_SUCCESS:
      return {
        ...state,
        agentSignUpSuccess: true,
        isLeadPageShared: false,
        agentSignUpLoading: false,
        notConfirmedAgentId: action.payload[0]._id,
      };
    case types.SET_SIGN_UP_SUCCESS:
      return {
        ...state,
        agentSignUpSuccess: false,
      };
    case types.DELETE_USER_IMAGE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          image: null,
        },
      };
    case types.UPLOAD_USER_IMAGE:
      return {
        ...state,
        user: {
          ...state.user,
          image: action.payload.response,
        },
      };
    case types.GET_PHONE_VERIFICATION_CODE:
      return {
        ...state,
        authError: null,
        getVerificationCodeSuccess: false,
        getVerificationCodeLoading: true,
      };
    case types.GET_LEAD_PHONE_VERIFICATION_CODE:
      return {
        ...state,
        authError: null,
        getLeadVerificationCodeSuccess: false,
        getLeadVerificationCodeLoading: true,
      };
    case types.GET_PHONE_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        getVerificationCodeSuccess: true,
        getVerificationCodeLoading: false,
      };
    case types.GET_LEAD_PHONE_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        getLeadVerificationCodeSuccess: true,
        getLeadVerificationCodeLoading: false,
      };
    case types.GET_PHONE_VERIFICATION_CODE_FAIL:
      return {
        ...state,
        getVerificationCodeSuccess: false,
        getVerificationCodeLoading: false,
        authError: action.payload,
      };
    case types.GET_LEAD_PHONE_VERIFICATION_CODE_FAIL:
      return {
        ...state,
        getLeadVerificationCodeSuccess: false,
        getLeadVerificationCodeLoading: false,
        authError: action.payload,
      };
    case types.VERIFY_PHONE_NUMBER:
      return {
        ...state,
        authError: null,
        verifyPhoneSuccess: false,
        verifyCodeLoading: true,
        signUpPhoneVerified: false,
      };
    case types.VERIFY_LEAD_PHONE_NUMBER:
      return {
        ...state,
        authError: null,
        verifyLeadPhoneSuccess: false,
        verifyLeadCodeLoading: true,
      };
    case types.VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          phone_verified: true,
          smsNotifications: true,
        },
        verifyPhoneSuccess: true,
        verifyCodeLoading: false,
        signUpPhoneVerified: true,
      };
    case types.VERIFY_LEAD_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        verifyLeadPhoneSuccess: true,
        verifyLeadCodeLoading: false,
      };
    case types.SET_VERIFY_USER_PHONE_SUCCESS:
      return {
        ...state,
        verifyPhoneSuccess: false,
      };
    case types.VERIFY_PHONE_NUMBER_FAIL:
      return {
        ...state,
        authError: action.payload,
        verifyPhoneSuccess: false,
        verifyCodeLoading: false,
      };
    case types.VERIFY_LEAD_PHONE_NUMBER_FAIL:
      return {
        ...state,
        authError: action.payload,
        verifyLeadPhoneSuccess: false,
        verifyLeadCodeLoading: false,
      };
    case types.UPDATE_USER_SETTINGS:
      return {
        ...state,
        updateUserSettingsLoading: true,
        updateUserSettingsSuccess: false,
        authError: null,
      };
    case types.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        user: { ...action.payload },
        updateUserSettingsLoading: false,
        updateUserSettingsSuccess: true,
        authError: null,
      };
    case types.SET_UPDATED_USER_SETTINGS_SUCCESS_TO_FALSE:
      return {
        ...state,
        updateUserSettingsSuccess: false,
      };
    case types.UPDATE_USER_SETTINGS_FAIL:
      return {
        ...state,
        updateUserSettingsLoading: false,
        updateUserSettingsSuccess: false,
        authError: action.payload,
      };
    case types.RESET_PASSWORD:
      return {
        ...state,
        passwordResetLoading: true,
        resetPasswordSuccess: false,
        authError: null,
      };
    case types.CONFIRM_RESET_PASSWORD:
      return {
        ...state,
        passwordResetLoading: true,
        confirmResetPasswordSuccess: false,
        authError: null,
      };
    case types.GET_LOGGED_IN_USER:
    case types.FORCE_RESET_PASSWORD:
    case types.SIGN_IN:
      return {
        ...state,
        authLoading: true,
        authError: null,
      };
    case types.CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetLoading: false,
        confirmResetPasswordSuccess: true,
        authError: null,
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetLoading: false,
        resetPasswordSuccess: true,
        authError: null,
        sendVerificationLink: action.payload?.sendLink,
      };
    case types.GET_LOGGED_IN_USER_SUCCESS:
    case types.FORCE_RESET_PASSWORD_SUCCESS:
    case types.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: { id: action.payload._id, ...action.payload },
        authLoading: false,
        newUserIsComing: true,
        isLeadPageShared: false,
      };
    case types.CONFIRM_RESET_PASSWORD_FAIL:
      return {
        ...state,
        passwordResetLoading: false,
        confirmResetPasswordFail: false,
        authError: action.payload,
      };
    case types.RESET_PASSWORD_FAIL:
      return {
        ...state,
        passwordResetLoading: false,
        resetPasswordFail: false,
        authError: action.payload,
      };
    case types.FORCE_RESET_PASSWORD_FAIL:
    case types.GET_LOGGED_IN_USER_FAIL:
    case types.SIGN_IN_FAIL:
      return {
        ...state,
        authError: action.payload,
        authLoading: false,
      };
    case types.SET_AUTH_LOADING_TO_TRUE:
      return {
        ...state,
        authLoading: true,
      };
    case types.SET_AUTH_LOADING_TO_FALSE:
      return {
        ...state,
        authLoading: false,
      };
    case types.SIGN_OUT:
      return {
        ...state,
        authLoading: true,
      };
    case types.SIGN_OUT_SUCCESS:
      return {
        ...state,
        user: {},
        authLoading: false,
      };
    case types.CLEAR_USER_STATES:
      return {
        ...state,
        authError: null,
        // updateUserSettingsSuccess: false,
        resetPasswordSuccess: false,
        confirmResetPasswordSuccess: false,
      };
    case types.EXPORT_USER_DATA:
      return {
        ...state,
        agentDataExportLoading: true,
        agentDataExportSuccess: false,
      };
    case types.EXPORT_USER_DATA_SUCCESS:
      return {
        ...state,
        agentDataExportLoading: false,
        agentDataExportSuccess: true,
      };
    case types.EXPORT_USER_DATA_FAIL:
      return {
        ...state,
        agentDataExportLoading: false,
        agentDataExportSuccess: false,
        agentDataExportError: action.payload,
      };
    case types.SET_EXPORT_USER_DATA_SUCCESS:
      return {
        ...state,
        agentDataExportSuccess: action.payload,
      };
    case types.SET_VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        phoneVerificationError: action.payload,
      };
    case types.VERIFY_BY_EMAIL_LINK:
      return {
        ...state,
        verifyByEmailLinkSuccess: false,
      };
    case types.VERIFY_BY_EMAIL_LINK_SUCCESS:
      return {
        ...state,
        verifyByEmailLinkSuccess: true,
      };
    case types.VERIFY_BY_EMAIL_LINK_FAIL:
      return {
        ...state,
        verifyByEmailLinkSuccess: false,
      };
    case types.SET_LOCATION:
      return {
        ...state,
        lastLocation: action.payload,
      };
    case types.GET_REFERRAL_AGENT_SUCCESS:
      return {
        ...state,
        referralAgentName: action.payload.user_id.name,
      };
    case types.UPDATE_USER_PROFILE:
      return {
        ...state,
        userUpdateSuccess: null,
        updatedUserLoading: true,
      };
    case types.UPDATE_USER_PROFILE_SUCCESS:
      console.log("===> in the UPDATE_USER_PROFILE_SUCCESS: ", action);
      console.log("state: ", state);
      return {
        ...state,
        user: action.payload,
        updatedUser: action.payload,
        userUpdateSuccess: true,
        updatedUserLoading: false,
      };
    case types.UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
        updatedUser: action.payload,
        userUpdateSuccess: false,
        updatedUserLoading: false,
      };
    case types.CLEAR_UPDATE_USER_PROFILE:
      return {
        ...state,
        updatedUser: {},
        userUpdateSuccess: null,
      };

    case types.UPDATE_USER_TEAM_NAME:
      return {
        ...state,
        user: {
          ...state.user,
          agency: {
            ...state.user.agency,
            agents_group_name: action.payload,
          },
        },
      };
    case types.UPDATE_USER_AFTER_SWITCH:
      return {
        ...state,
        userUpdateSuccess: null,
        updatedUserLoading: true,
      };
    case types.UPDATE_USER_AFTER_SWITCH_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          agency: action.payload.agency,
          role: action.payload.role,
        },
        userUpdateSuccess: true,
        updatedUserLoading: false,
      };
    case types.UPDATE_USER_AFTER_SWITCH_FAIL:
      return {
        ...state,
        updatedUser: action.payload,
        userUpdateSuccess: false,
        updatedUserLoading: false,
      };
    case types.CLEAR_UPDATE_USER_AFTER_SWITCH:
      return {
        ...state,
        updatedUser: {},
        userUpdateSuccess: null,
      };
    default:
      return state;
  }
}
