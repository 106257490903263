import React from "react";
import "./FollowUpsEmptyState.scss";
import { useLocation } from "react-router-dom";
import { AnalyticsService } from "helpers/analyticsService";

interface IFollowUpsEmptyState {
  handleOpenAddNoteModal: any;
  setVisible: any;
}
const FollowUpsEmptyState = ({ handleOpenAddNoteModal, setVisible }: IFollowUpsEmptyState) => {
  const location = useLocation();
  const text = location.pathname.substring(location.pathname.lastIndexOf("/"));
  const textWithSLetter = location.pathname.replace(text, "").replace("/", "");
  const textForShowing = textWithSLetter.substring(0, textWithSLetter.length - 1);
  return (
    <div className={"follow-ups-empty-state-wrapper"}>
      <div className={"follow-ups-empty-state-wrapper--bottom"}>
        <h3>What is a follow-up?</h3>
        <span>
          A follow-up notifies you of any future action that needs to be taken when it comes to your rental business.
          This info will only be available to you and the team owner.
        </span>
      </div>
      <h3 className={"follow-ups-empty-state-wrapper--title"}>
        You currently have no follow-ups for this {textForShowing}.
      </h3>
      {/*<span className={"follow-ups-empty-state-wrapper--text"}>*/}
      {/*  Add a follow-up to start getting notifications.*/}
      {/*</span>*/}
      <button
        onClick={() => {
          handleOpenAddNoteModal();
          AnalyticsService.reportAddFollowUpClick("all_follow_ups_modal_CTA");
          setVisible(false);
        }}
      >
        Add Follow-Up
      </button>
    </div>
  );
};

export default FollowUpsEmptyState;
