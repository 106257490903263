import React from "react";
import { TrashIcon } from "../../icons";
import { UploadFile } from "antd/es/upload/interface";
import { IColViewConfig } from "./ListingImagesList";
import { useDispatch, useSelector } from "react-redux";
import { setListingImageModalCurrentImageStep } from "../../../store/listings/action";
import { isPublicListingSelector } from "../../../store/listings/selectors";
import { useDemoContext } from "libs/contextLib";
import BaseButton from "components/Shared/BaseButton";

interface IListingImagesListItem {
  onPreview: Function;
  showConfirm: Function;
  enablePreview: Boolean;
  canEdit: Boolean;
  showViewMoreButton?: Boolean;
  colViewConfig: IColViewConfig;
  imageCardClassname?: string;
  file: UploadFile;
  fileIndex?: number;
  setPreviewModalAllPhotosVisible?: Function;
}

const UploadedListItem = (props: IListingImagesListItem) => {
  const dispatch = useDispatch();
  const isPublicListing = useSelector(isPublicListingSelector);
  const { isDemo, demoAction } = useDemoContext();
  const handleClick = () => {
    if (isDemo) {
      demoAction("listing");
    } else {
      props.showConfirm(props.file);
    }
  };
  return (
    <div
      // key={props.file.uid}
      className={`image-card-wrapper ${props.imageCardClassname ? props.imageCardClassname : ""}`}
    >
      <div className="upload-list-item">
        <div className="h-full w-full" onClick={(e) => e.stopPropagation()}>
          <div className="w-full h-full flex-centered mt-2">
            <img
              onClick={() => {
                if (props.enablePreview) {
                  props.setPreviewModalAllPhotosVisible?.(true);
                }
                if (props.fileIndex || props.fileIndex === 0) {
                  dispatch(setListingImageModalCurrentImageStep(props?.fileIndex));
                }
              }}
              className="listing-image-item"
              src={props.file?.response?.url || props.file?.url}
              alt={props.file?.name}
            />

            <div className="image-button-wrapper">
              {props.showViewMoreButton && (
                <BaseButton
                  variant="primary"
                  onClick={() => {
                    if (props.enablePreview) {
                      props.setPreviewModalAllPhotosVisible?.(true);
                    }
                    if (props.fileIndex || props.fileIndex === 0) {
                      dispatch(setListingImageModalCurrentImageStep(props?.fileIndex));
                    }
                  }}
                >
                  View more
                </BaseButton>
              )}
            </div>

            <div className="image-action-wrapper">
              {/*Todo add rotate functionality*/}
              {/*<span className="slider-action-item flex-centered">*/}
              {/*  <RefreshIcon />*/}
              {/*</span>*/}
              {/* {props.canEdit && !isPublicListing &&
                <span
                  className="slider-action-item flex-centered"
                  onClick={handleClick}
                >
                  <TrashIcon />
                </span>
              } */}
              {/*Todo add update image functionality*/}
              {/*<span*/}
              {/*  className="slider-action-item flex-centered"*/}
              {/*  onClick={() => props.onPreview(file)}*/}
              {/*>*/}
              {/*  <UploadIcon />*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UploadedListItem);
