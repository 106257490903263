import React, { useState, useEffect } from "react";
import { IOPassportMeta } from "../RenterPortalInterfaces";
import { updatePassport } from "api/RenterPortal";
import { PASSPORT_FIELDS } from "../../Shared/Fields/PassportFields";
import BaseButton from "components/Shared/BaseButton";
import FieldItems from "./Form/FieldItems";
import { Spin } from "antd";
import GlobalLoading from "components/Shared/GlobalLoading";
import Section from "components/Shared/Section/Section";
import md5 from "md5";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch } from "react-redux";
import { setRenterPassport } from "store/renterPassport/action";
import { openNotification } from "helpers/globalFunctions";

const PassportInfo = ({ passport }: IOPassportMeta) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [initFormHash, setInitFormHash] = useState<string | null>(null);
  const [initFormData, setInitFormData] = useState<any>(null);
  const [formHash, setFormHash] = useState<string | null>(null);

  useEffect(() => {
    setFormData({ ...passport });
    const initFormData = cloneDeep(passport);
    const stringifiedFormData = JSON.stringify(initFormData);
    const formHash = md5(stringifiedFormData);
    setFormHash(formHash);
    setInitFormHash(formHash);
    setInitFormData(initFormData);
  }, []);

  const handleUpdateFormData = ({ field, value }: { field: { key: string }; value: any }) => {
    try {
      const updatedFormData = {
        ...formData,
        [field.key]: value,
      };
      setFormData(updatedFormData);
      const stringifiedFormData = JSON.stringify(updatedFormData);
      const formHash = md5(stringifiedFormData);
      setFormHash(formHash);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const handleSubmitForm = async () => {
    if (initFormHash === formHash) return false;
    setIsSaving(true);
    await updatePassport(formData);
    dispatch(setRenterPassport({ passport: formData }));
    setIsSaving(false);
    openNotification("success", "Your have updated your  Rental Passport! Nice!");
  };

  const handleCancelFormEdit = () => {
    // NOTE/TODO: this sorta doesn't work. Algo for checking delta is borked. JL
    if (initFormHash !== formHash) {
      const stringifiedFormData = JSON.stringify(initFormData);
      const formHash = md5(stringifiedFormData);
      setFormHash(formHash);
      setFormData(initFormData);
    }
    setIsEditing(false);
  };

  return (
    <Spin spinning={isSaving} className={"global-loading"} indicator={<GlobalLoading />}>
      {error && <div>{JSON.stringify(error)}</div>}

      <Section shadow>
        <div className="flex justify-between w-full border-b border-gray-200 pb-4">
          <div>
            <h3 className={`text-2xl font-semibold text-gray-700`}>Rental Profile</h3>
          </div>
          {isEditing ? (
            <div className="flex justify-end gap-2">
              <BaseButton
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Cancel
              </BaseButton>
              <BaseButton
                onClick={() => {
                  handleSubmitForm();
                  setIsEditing(false);
                }}
                variant="primary"
              >
                Save
              </BaseButton>
            </div>
          ) : (
            <BaseButton onClick={() => setIsEditing(true)}>Edit</BaseButton>
          )}
        </div>

        <div className="w-full">
          <div className="flex flex-wrap justify-between w-full">
            <div className="w-full sm:w-1/2">
              <FieldItems
                // label="Tenants"
                fields={[PASSPORT_FIELDS.NUMBER_OF_TENANTS, PASSPORT_FIELDS.EMPLOYED_TENANTS]}
                value={{
                  [PASSPORT_FIELDS.NUMBER_OF_TENANTS.key]: formData[PASSPORT_FIELDS.NUMBER_OF_TENANTS.key],
                  [PASSPORT_FIELDS.EMPLOYED_TENANTS.key]: formData[PASSPORT_FIELDS.EMPLOYED_TENANTS.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
              <FieldItems
                // label="Employment Status"
                fields={[PASSPORT_FIELDS.STUDENT_OR_EMPLOYED]}
                value={{
                  [PASSPORT_FIELDS.STUDENT_OR_EMPLOYED.key]: formData[PASSPORT_FIELDS.STUDENT_OR_EMPLOYED.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
              <FieldItems
                // label="Employment Detail"
                fields={[PASSPORT_FIELDS.JOB_LOCATION, PASSPORT_FIELDS.JOB_POSITION]}
                value={{
                  [PASSPORT_FIELDS.JOB_LOCATION.key]: formData[PASSPORT_FIELDS.JOB_LOCATION.key],
                  [PASSPORT_FIELDS.JOB_POSITION.key]: formData[PASSPORT_FIELDS.JOB_POSITION.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
              <FieldItems
                // label="Household Income"
                fields={[PASSPORT_FIELDS.YEARLY_INCOME, PASSPORT_FIELDS.CREDIT_SCORE]}
                value={{
                  [PASSPORT_FIELDS.YEARLY_INCOME.key]: formData[PASSPORT_FIELDS.YEARLY_INCOME.key],
                  [PASSPORT_FIELDS.CREDIT_SCORE.key]: formData[PASSPORT_FIELDS.CREDIT_SCORE.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <FieldItems
                label="Lease Status"
                // description="The type of lease you have currently, if any."
                fields={[PASSPORT_FIELDS.LEASE_STATUS]}
                value={{
                  [PASSPORT_FIELDS.LEASE_STATUS.key]: formData[PASSPORT_FIELDS.LEASE_STATUS.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
              <FieldItems
                label="Your Availability"
                // description="Date after which you are available to view properties."
                fields={[PASSPORT_FIELDS.AVAILABLE_TIMES]}
                value={{
                  [PASSPORT_FIELDS.AVAILABLE_TIMES.key]: formData[PASSPORT_FIELDS.AVAILABLE_TIMES.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
              <FieldItems
                label="Move-In Date"
                fields={[PASSPORT_FIELDS.MOVE_IN_DATE_START]}
                value={{
                  [PASSPORT_FIELDS.MOVE_IN_DATE_START.key]: formData[PASSPORT_FIELDS.MOVE_IN_DATE_START.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
              <FieldItems
                label="Rental Search History"
                fields={[PASSPORT_FIELDS.LISTINGS_APPLIED_TO, PASSPORT_FIELDS.LISTINGS_VIEWED]}
                value={{
                  [PASSPORT_FIELDS.LISTINGS_APPLIED_TO.key]: formData[PASSPORT_FIELDS.LISTINGS_APPLIED_TO.key],
                  [PASSPORT_FIELDS.LISTINGS_VIEWED.key]: formData[PASSPORT_FIELDS.LISTINGS_VIEWED.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
            </div>
          </div>
        </div>
      </Section>

      <Section shadow>
        <div className="flex justify-between w-full border-b border-gray-200 pb-4">
          <div>
            <h3 className={`text-2xl font-semibold text-gray-700`}>Rental Preferences</h3>
          </div>
          {isEditing ? (
            <div className="flex justify-end gap-2">
              <BaseButton
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Cancel
              </BaseButton>
              <BaseButton
                onClick={() => {
                  handleSubmitForm();
                  setIsEditing(false);
                }}
                variant="primary"
              >
                Save
              </BaseButton>
            </div>
          ) : (
            <BaseButton onClick={() => setIsEditing(true)}>Edit</BaseButton>
          )}
        </div>

        <div className="w-full">
          <div className="flex flex-wrap justify-between w-full">
            <div className="w-full sm:w-1/2">
              <FieldItems
                label="Budget"
                fields={[PASSPORT_FIELDS.BUDGET_MIN, PASSPORT_FIELDS.BUDGET_MAX]}
                value={{
                  [PASSPORT_FIELDS.BUDGET_MIN.key]: formData[PASSPORT_FIELDS.BUDGET_MIN.key],
                  [PASSPORT_FIELDS.BUDGET_MAX.key]: formData[PASSPORT_FIELDS.BUDGET_MAX.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
              <FieldItems
                label="Beds & Baths"
                fields={[PASSPORT_FIELDS.BEDROOMS_MIN, PASSPORT_FIELDS.BATHROOMS_MIN]}
                value={{
                  [PASSPORT_FIELDS.BEDROOMS_MIN.key]: formData[PASSPORT_FIELDS.BEDROOMS_MIN.key],
                  [PASSPORT_FIELDS.BATHROOMS_MIN.key]: formData[PASSPORT_FIELDS.BATHROOMS_MIN.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <FieldItems
                label="Areas"
                fields={[PASSPORT_FIELDS.AREAS]}
                value={{
                  [PASSPORT_FIELDS.AREAS.key]: formData[PASSPORT_FIELDS.AREAS.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
              <FieldItems
                label="Pets"
                fields={[PASSPORT_FIELDS.PETS]}
                value={{
                  [PASSPORT_FIELDS.PETS.key]: formData[PASSPORT_FIELDS.PETS.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
              <div className="">
                <FieldItems
                  label="Parking"
                  fields={[PASSPORT_FIELDS.NUMBER_OF_PARKING]}
                  value={{
                    [PASSPORT_FIELDS.NUMBER_OF_PARKING.key]: formData[PASSPORT_FIELDS.NUMBER_OF_PARKING.key],
                  }}
                  isEditing={isEditing}
                  onChange={handleUpdateFormData}
                  onSubmit={handleSubmitForm}
                />
              </div>
              <div className="">
                <FieldItems
                  label="Notes about me"
                  fields={[PASSPORT_FIELDS.OTHER]}
                  value={{
                    [PASSPORT_FIELDS.OTHER.key]: formData[PASSPORT_FIELDS.OTHER.key],
                  }}
                  isEditing={isEditing}
                  onChange={handleUpdateFormData}
                  onSubmit={handleSubmitForm}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section shadow>
        <div className="flex justify-between w-full border-b border-gray-200 pb-4">
          <div>
            <h3 className={`text-2xl font-semibold text-gray-700`}>Home Ownership</h3>
          </div>
          {isEditing ? (
            <div className="flex justify-end gap-2">
              <BaseButton
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Cancel
              </BaseButton>
              <BaseButton
                onClick={() => {
                  handleSubmitForm();
                  setIsEditing(false);
                }}
                variant="primary"
              >
                Save
              </BaseButton>
            </div>
          ) : (
            <BaseButton onClick={() => setIsEditing(true)}>Edit</BaseButton>
          )}
        </div>
        <div className="w-full">
          <div className="flex flex-wrap justify-between w-full">
            <div className="w-full sm:w-1/2">
              <FieldItems
                label="Are you planning on buying in the near future?"
                fields={[PASSPORT_FIELDS.CONSIDER_OWNING_HOME]}
                value={{
                  [PASSPORT_FIELDS.CONSIDER_OWNING_HOME.key]: formData[PASSPORT_FIELDS.CONSIDER_OWNING_HOME.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
            </div>
          </div>
        </div>
      </Section>

      <Section shadow>
        <div className="flex justify-between w-full border-b border-gray-200 pb-4">
          <div>
            <h3 className={`text-2xl font-semibold text-gray-700`}>Amenities</h3>
          </div>
          {isEditing ? (
            <div className="flex justify-end gap-2">
              <BaseButton
                onClick={() => {
                  handleCancelFormEdit();
                }}
              >
                Cancel
              </BaseButton>
              <BaseButton
                onClick={() => {
                  handleSubmitForm();
                  setIsEditing(false);
                }}
                variant="primary"
              >
                Save
              </BaseButton>
            </div>
          ) : (
            <BaseButton onClick={() => setIsEditing(true)}>Edit</BaseButton>
          )}
        </div>
        <div className="w-full">
          <div className="w-full">
            <div className="w-full">
              <FieldItems
                label="Must Have Amenities"
                fields={[PASSPORT_FIELDS.MUST_HAVE]}
                value={{
                  [PASSPORT_FIELDS.MUST_HAVE.key]: formData[PASSPORT_FIELDS.MUST_HAVE.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
            </div>
            <div className="w-full">
              <FieldItems
                label="Deal Breakers"
                fields={[PASSPORT_FIELDS.DEAL_BREAKERS]}
                value={{
                  [PASSPORT_FIELDS.DEAL_BREAKERS.key]: formData[PASSPORT_FIELDS.DEAL_BREAKERS.key],
                }}
                isEditing={isEditing}
                onChange={handleUpdateFormData}
                onSubmit={handleSubmitForm}
              />
            </div>
          </div>
        </div>
      </Section>
    </Spin>
  );
};

export default PassportInfo;
