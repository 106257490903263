import moment, { Moment, unitOfTime } from "moment";
import { US_DATE_FORMAT, momentSet8Am, ISO_STRING } from "../helpers/constants";

type DateInput = string | Date | Moment;
type MomentOperation = {
  amount: moment.DurationInputArg1;
  unit: unitOfTime.DurationConstructor;
};
export const DATE_PICKER_FORMATS = [
  "MM/DD/YYYY",
  "YYYY-MM-DD",
  "MM-DD-YYYY",
  "YYYY/MM/DD",
];

// One moment to rule them all
export const dateToMoment = (date?: DateInput | null) => {
  if (!date) {
    return moment();
  }
  let momentDate = moment(date);
  if (!momentDate.isValid()) throw new Error("Invalid date format");
  if (!momentDate) throw new Error("Date is required");
  return momentDate;
};

// This function returns current date.
export const currentDate = (format: string = US_DATE_FORMAT) => {
  return formatDate(new Date(), format);
};

export const formatDate = (
  date: string | Date | Moment | null,
  format: string = US_DATE_FORMAT
) => {
  if (format === ISO_STRING) {
    return dateToMoment(date).toISOString();
  }
  return dateToMoment(date).format(format);
};

// This is a function to add time and format it.
export function addTimeAndFormatDate(
  date: string | Date | null,
  operation: MomentOperation,
  setTime?: moment.MomentSetObject,
  dateFormat: string = US_DATE_FORMAT
): any {
  let momentDate = dateToMoment(date);
  if (setTime) {
    return formatDate(
      momentDate.add(operation.amount, operation.unit).set(setTime),
      dateFormat
    );
  }
  return formatDate(
    momentDate.add(operation.amount, operation.unit),
    dateFormat
  );
}

// Helper function to get start of year format date or start time of day
export function getStartOfUnitFormatted(
  date: DateInput,
  unitOfTime: unitOfTime.StartOf,
  dateFormat?: string
): string {
  let momentDate = dateToMoment(date);
  const startDate = momentDate.startOf(unitOfTime);
  return formatDate(startDate, dateFormat);
}

export function getDateDifference(
  date: DateInput,
  unit: unitOfTime.Diff
): number {
  const difference = moment().diff(moment(date), unit);
  return Math.abs(difference);
}

// Helper function to get the end of the week, add 2 days, set to 8 AM, and format
export function getEndOfWeekPlusTwoDaysFormatted(
  amount: moment.DurationInputArg1,
  unit: unitOfTime.DurationConstructor,
  dateFormat?: string
): string | Moment {
  const endOfWeekPlusTwoDays = moment()
    .endOf("week")
    .add(amount, unit)
    .set(momentSet8Am);

  return formatDate(endOfWeekPlusTwoDays, dateFormat);
}

// Helper function to get next occurrence based on the provided day and time
export function getNextOccurrence(
  date: string,
  dateFormat?: string
): string | Moment {
  return formatDate(moment().day(date).set(momentSet8Am), dateFormat);
}

function getTimeDifferenceForDays(dayDiff: number): string {
  if (dayDiff === 0) return "Today";
  if (dayDiff === 1) return "Tomorrow";
  if (dayDiff === -1) return "Yesterday";
  if (dayDiff < 0) return `${Math.abs(dayDiff)} days ago`;
  return `${dayDiff} days left`;
}

function getTimeDifferenceForYears(yearDiff: number): string {
  if (yearDiff === 1) return "Next Year";
  if (yearDiff === -1) return "Last Year";
  return `${Math.abs(yearDiff)} years ${yearDiff > 0 ? "left" : "ago"}`;
}

function getTimeDifferenceForMonths(monthDiff: number): string {
  if (monthDiff === 1) return "Next Month";
  if (monthDiff === -1) return "Last Month";
  return `${Math.abs(monthDiff)} months ${monthDiff > 0 ? "left" : "ago"}`;
}

function getTimeDifferenceForWeeks(weekDiff: number): string {
  if (Math.abs(weekDiff) === 1) return weekDiff > 0 ? "Next Week" : "Last Week";
  return `${Math.abs(weekDiff)} weeks ${weekDiff > 0 ? "left" : "ago"}`;
}

export const timeSince = (
  date: DateInput,
  format: string = US_DATE_FORMAT
): string => {
  const today = moment().startOf("day");
  const momentDate = dateToMoment(date);
  const targetDate = momentDate.startOf("day");
  const dayDiff = targetDate.diff(today, "days");
  const weekDiff = targetDate.diff(today, "weeks");
  const monthDiff = targetDate.diff(today, "months");
  const yearDiff = targetDate.diff(today, "years");

  if (dayDiff > -7 && dayDiff < 7) {
    return getTimeDifferenceForDays(dayDiff);
  } else if (yearDiff) {
    return getTimeDifferenceForYears(yearDiff);
  } else if (monthDiff) {
    return getTimeDifferenceForMonths(monthDiff);
  } else if (weekDiff) {
    return getTimeDifferenceForWeeks(weekDiff);
  }

  return "";
};

export default formatDate;
