import React from "react";
import "./styles.scss";

interface IProps {
  list: any;
  total: any;
}

const ShowingPagination = ({ list, total }: IProps) => {
  return (
    <div className="flex flex-nowrap justify-start">
      Showing {list.length} of {total}
    </div>
  );
};

export default React.memo(ShowingPagination);
