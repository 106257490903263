import React from "react";
import "./LeadingAgentInfoSection.scss";
import BaseButton from "../../Shared/BaseButton";
import { phoneMaskOnString } from "../../../helpers/globalFunctions";
import Section from "components/Shared/Section/Section";
import SectionHeading from "components/Shared/Section/SectionHeading";
import { DEFAULT_USER_AVATAR } from "helpers/constants";

const AgentRow = ({
  agent,
  prefix,
  handleButtonClick,
}: {
  agent: any;
  prefix: string;
  handleButtonClick: Function;
}) => {
  const getAgentImageURL = (agent: any) => {
    // Check for the default image from MLS and ignore
    return agent?.imagesOfAgent?.url && agent.imagesOfAgent.url.indexOf("themls") === -1
      ? agent.imagesOfAgent.url
      : DEFAULT_USER_AVATAR;
  };

  return (
    <div className="flex flex-wrap justify-between text-sm">
      <div className="flex justify-start gap-2">
        <div className="mr-4 flex-shrink-0">
          <span className="relative inline-block">
            <img className="h-20 w-20 rounded-lg object-cover" src={getAgentImageURL(agent)} alt="" />
          </span>
        </div>
        <div>
          <h4 className="text-base font-bold flex items-center">
            {agent.name}
            <div className="ml-4 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-200">
              {prefix}
            </div>
          </h4>
          <div>{phoneMaskOnString(agent?.phone)}</div>
          <div>{agent.email}</div>
        </div>
      </div>
      <div>
        <BaseButton onClick={() => handleButtonClick(agent)} modalType="listing">
          Contact
        </BaseButton>
      </div>
    </div>
  );
};

const LeadingAgentInfoSection = ({
  handleButtonClick,
  agent,
  coListAgents,
}: {
  handleButtonClick?: any;
  agent: any;
  coListAgents: any[];
}) => {
  return (
    <div>
      <SectionHeading heading="Agents" variant="md" />
      <div className="flex flex-col gap-4">
        {agent && <AgentRow prefix={"Listing Agent"} agent={agent} handleButtonClick={handleButtonClick} />}
        {coListAgents?.length > 0 &&
          coListAgents.map((coListAgent: any) => (
            <AgentRow prefix={"Co-listing Agent"} agent={coListAgent} handleButtonClick={handleButtonClick} />
          ))}
      </div>
    </div>
  );
};

export default LeadingAgentInfoSection;
