import React from "react";
import "./EmptyPageLayout.scss";
const EmptyPageLayout = (props: any) => {
  return (
    <div
      className={
        `empty-page-individual-layout empty-page-wrapper-selected empty-page-wrapper ${props.classNames}`
      }
    >
      {props.children}
    </div>
  );
};

export default EmptyPageLayout;
