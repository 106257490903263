import React from "react";
import { Col, Row, Spin } from "antd";
import { useSelector } from "react-redux";
import { leadLoadingSelector, singleLeadDataSelector } from "../../../store/leads/selectors";
import LeadDescriptionItem from "./LeadDescriptionItem";
import {
  beautifiedKeyStyling,
  beautifiedKeyStylingListingsViewed,
  replaceSnakeCaseToKebabCase,
} from "../../../helpers/globalFunctions";
import { LEAD_LEASE_STATUS_UI_TEXT } from "./constants";
import { formatDate } from "helpers/dateHelper";
import { US_DATE_FORMAT } from "helpers/constants";
import SectionHeading from "components/Shared/Section/SectionHeading";
import Section from "components/Shared/Section/Section";

const LeadPersonalInfo = () => {
  const leadDataLoading = useSelector(leadLoadingSelector);
  const leadData = useSelector(singleLeadDataSelector);
  const requirement = leadData?.requirement;
  const leadFlow = window.location.pathname.includes("lead-flow");
  const leaseStatusText =
    LEAD_LEASE_STATUS_UI_TEXT[requirement?.lease_status] ?? replaceSnakeCaseToKebabCase(requirement?.lease_status);

  return (
    <Spin tip="Loading..." spinning={leadDataLoading}>
      <Section shadow>
        <SectionHeading heading={!leadFlow ? "About the Lead" : "My Profile"} />
        <div className="move-in-fees-part font-16">
          <div className={"fees-wrapper-section fees-wrapper-section-leads"}>
            <Row>
              <Col>
                <Row className="lead-personla-info-first-row">
                  {requirement?.job_location ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem header={"Employer"} description={requirement?.job_location} />
                    </Col>
                  ) : (
                    ""
                  )}
                  {requirement?.credit_score ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem
                        header={"Credit Score"}
                        description={beautifiedKeyStyling(requirement?.credit_score)}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {requirement?.job_position ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem
                        header={"Position"}
                        description={beautifiedKeyStyling(requirement?.job_position)}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {requirement?.yearly_income ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem header={"Yearly Income"} description={"$" + requirement?.yearly_income} />
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col className="fee-or-type-item mt-2">
                    <LeadDescriptionItem
                      header={"Number of Employed Occupants"}
                      description={`${requirement?.employed_tenants} out of ${requirement?.number_of_tenants}`}
                    />
                  </Col>
                  {requirement?.lease_status ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem header={"Lease Status"} description={leaseStatusText} />
                    </Col>
                  ) : (
                    ""
                  )}
                  {requirement?.listings_viewed ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem
                        header={"Rental Units Seen"}
                        description={`${beautifiedKeyStylingListingsViewed(requirement?.listings_viewed)}, Applied to ${
                          requirement?.listings_applied
                        }`}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>

              <Col>
                <Row className="lead-personla-info-second-row">
                  {requirement?.student_or_employed ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem
                        header={"Student"}
                        description={`${requirement?.student_or_employed === "student" ? "Yes" : "No"}`}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {requirement?.other ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem header={"Renter Commented"} description={requirement?.other} />
                    </Col>
                  ) : (
                    ""
                  )}
                  {requirement?.available_times ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem
                        header={"Available for showing"}
                        description={`${formatDate(requirement?.available_times, US_DATE_FORMAT)}`}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {requirement?.contact_preference ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem
                        header={"Preferred Method of Contact"}
                        description={beautifiedKeyStyling(requirement?.contact_preference)}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {requirement?.has_cosigner && requirement?.student_or_employed !== "employed" ? (
                    <Col className="fee-or-type-item mt-2">
                      <LeadDescriptionItem
                        header={"Co-Signer"}
                        description={beautifiedKeyStyling(requirement?.has_cosigner)}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Section>
    </Spin>
  );
};

export default LeadPersonalInfo;
