import * as types from "./types";
import {
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_SUCCESS,
  GET_INVOICES,
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  GET_UPCOMING_INVOICES,
  GET_UPCOMING_INVOICES_FAIL,
  GET_UPCOMING_INVOICES_SUCCESS,
  SET_UPDATED_SUBSCRIPTION_TO_BLANK,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";

interface PaymentReducerType {
  paymentLink: string;
  paymentLinkLoading: boolean;
  paymentLinkError: any;
  screeningPaymentLink: string;
  screeningPaymentLinkLoading: boolean;
  screeningPaymentLinkError: any;
  subscriptions: IObjectKeys;
  subscriptionsLoading: boolean;
  subscriptionsError: any;
  upcomingInvoices: IObjectKeys;
  upcomingInvoicesLoading: boolean;
  upcomingInvoicesError: any;
  invoices: any;
  invoicesLoading: boolean;
  invoicesError: any;
  updateSubscriptionLoading: boolean;
  updateSubscriptionError: any;
  updatedSubscription: IObjectKeys;
  canceledSubscriptionLoading: boolean;
  cancelSubscriptionError: any;
}
const initialState: PaymentReducerType = {
  paymentLink: "",
  paymentLinkLoading: false,
  paymentLinkError: "",
  screeningPaymentLink: "",
  screeningPaymentLinkLoading: false,
  screeningPaymentLinkError: "",
  subscriptions: {},
  subscriptionsLoading: false,
  subscriptionsError: null,
  upcomingInvoices: {},
  upcomingInvoicesLoading: false,
  upcomingInvoicesError: null,
  invoices: [],
  invoicesLoading: false,
  invoicesError: null,
  updateSubscriptionLoading: false,
  updateSubscriptionError: null,
  updatedSubscription: {},
  canceledSubscriptionLoading: false,
  cancelSubscriptionError: null,
};

export default function paymentReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case types.GET_PAYMENT_LINK:
      return {
        ...state,
        paymentLinkLoading: true,
      };
    case types.GET_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        paymentLinkLoading: false,
        paymentLink: action.payload.url,
      };
    case types.GET_PAYMENT_LINK_FAIL:
      return {
        ...state,
        paymentLinkLoading: false,
        paymentLink: "",
        paymentLinkError: action.payload,
      };
    case GET_SUBSCRIPTION:
      return {
        ...state,
        subscriptionsLoading: true,
      };
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionsLoading: false,
        subscriptions: action.payload,
        subscriptionsError: null,
      };
    case GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        subscriptionsLoading: false,
        subscriptionsError: action.payload,
      };
    case GET_UPCOMING_INVOICES:
      return {
        ...state,
        upcomingInvoicesLoading: true,
      };
    case GET_UPCOMING_INVOICES_SUCCESS:
      return {
        ...state,
        upcomingInvoicesLoading: false,
        upcomingInvoices: action.payload,
        upcomingInvoicesError: null,
      };
    case GET_UPCOMING_INVOICES_FAIL:
      return {
        ...state,
        upcomingInvoicesLoading: false,
        upcomingInvoicesError: action.payload,
      };
    case GET_INVOICES:
      return {
        ...state,
        invoicesLoading: true,
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        invoices: action.payload,
        invoicesError: null,
      };
    case GET_INVOICES_FAIL:
      return {
        ...state,
        invoicesLoading: false,
        invoicesError: action.payload,
      };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        updateSubscriptionLoading: true,
      };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updateSubscriptionLoading: false,
        updatedSubscription: action.payload,
        paymentLink: action.payload?.url,
      };
    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        updateSubscriptionLoading: false,
        updateSubscriptionError: action.payload,
        updatedSubscription: {},
      };
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        canceledSubscriptionLoading: true,
        cancelSubscriptionError: null,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        canceledSubscriptionLoading: false,
        cancelSubscriptionError: null,
        updatedSubscription: { key: "a", cancel: true },
      };
    case CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        canceledSubscriptionLoading: false,
        cancelSubscriptionError: action.payload,
      };
    case SET_UPDATED_SUBSCRIPTION_TO_BLANK:
      return {
        ...state,
        updateSubscriptionError: null,
        updateSubscriptionLoading: false,
        updatedSubscription: {},
      };
    case types.GET_STRIPE_PORTAL_URL:
      return {
        ...state,
      };
    case types.GET_STRIPE_PORTAL_URL_SUCCESS:
      return {
        ...state,
        paymentLink: action.payload.url,
      };
    case types.GET_SCREENING_PAYMENT_LINK:
      return {
        ...state,
        paymentLinkLoading: true,
      };
    case types.CLEAR_SCREENING_PAYMENT_LINK:
      return {
        ...state,
        screeningPaymentLinkLoading: false,
        screeningPaymentLinkError: false,
        screeningPaymentLink: "",
      };
    case types.GET_SCREENING_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        screeningPaymentLinkLoading: false,
        screeningPaymentLinkError: false,
        screeningPaymentLink: action.payload.url,
      };
    case types.GET_SCREENING_PAYMENT_LINK_FAIL:
      return {
        ...state,
        screeningPaymentLinkLoading: false,
        screeningPaymentLink: "",
        screeningPaymentLinkError: action.payload,
      };
    default:
      return state;
  }
}
