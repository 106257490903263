import { Form, Input, Spin } from "antd";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import { AddressChangeParams } from "components/Shared/PlacesAutocomplete/types";
import { IObjectKeys } from "helpers/globalTypes";
import {
  CHARACTERS_VALIDATION, SHAREABLE_ADDRESS_1_VALIDATION, SHAREABLE_LOCALITY_VALIDATION, SHAREABLE_POSTAL_CODE_VALIDATION,
  SHAREABLE_STATE_VALIDATION,
  SHAREABLE_STREET_NAME_VALIDATION
} from "helpers/validations";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { shareableLoadingSelector } from "store/shareable/selectors";

const MissingPropertyData = ({
  form,
  listing,
}: {
  form: any;
  listing: IObjectKeys;
}) => {
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);
  const shareableLoading = useSelector(shareableLoadingSelector);

  const itemStyle = { marginBottom: "10px" };

  const onAddressChange = (address: AddressChangeParams) => {

    const currentFields = form.getFieldsValue();
    let formattedValues: IObjectKeys = {
      addressLine1: address.formatted_address,
      locality: address.separated_fields?.town || listing.locality,
      state: address.separated_fields?.state || listing.state,
      region: address.separated_fields?.short_state || listing.state,
      postalCode: address.separated_fields?.postal_code || listing.postalCode,
      street_name: address.separated_fields?.street_name || listing.street_name,
      street_number:
        address.separated_fields?.street_number || listing.street_number,
      generatedAddr: address.formatted_address || listing.addressLine1,
      unit: currentFields.unit || listing.unit,
    };

    form.setFieldsValue(formattedValues);
  };

  return shareableLoading ? (
    <div className="flex justify-center">
      <Spin />
    </div>
  ) : (
    <>
      <div style={{ marginBottom: "10px" }}>
        <Form.Item
          name="addressLine1"
          label="Address"
          className="area_field"
          rules={[CHARACTERS_VALIDATION, SHAREABLE_ADDRESS_1_VALIDATION]}
        >
          <PlacesAutocomplete
            defaultAddress={listing.addressLine1}
            onAddressChange={onAddressChange}
            resetPlacesAutocomplete={resetPlacesAutocomplete}
            setResetPlacesAutocomplete={setResetPlacesAutocomplete}
            placeholder={"Search for property address"}
          />
        </Form.Item>
      </div>
      <Form.Item label="Street Number" name="street_number" style={itemStyle}>
        <Input defaultValue={listing.street_number} />
      </Form.Item>
      <Form.Item
        label="Street Name"
        name="street_name"
        style={itemStyle}
        rules={[SHAREABLE_STREET_NAME_VALIDATION]}
      >
        <Input defaultValue={listing.street_name} />
      </Form.Item>
      <Form.Item label="Unit Number" name="unit" style={itemStyle}>
        <Input defaultValue={listing.unit} />
      </Form.Item>
      <Form.Item
        label="City"
        name="locality"
        style={itemStyle}
        rules={[SHAREABLE_LOCALITY_VALIDATION]}
      >
        <Input defaultValue={listing.locality} />
      </Form.Item>
      <Form.Item
        label="State"
        name="state"
        style={itemStyle}
        rules={[SHAREABLE_STATE_VALIDATION]}
      >
        <Input placeholder={"New York"} defaultValue={listing.state} />
      </Form.Item>
      <Form.Item
        label="Postal Code"
        name="postalCode"
        style={itemStyle}
        rules={[SHAREABLE_POSTAL_CODE_VALIDATION]}
      >
        <Input placeholder={"12345"} defaultValue={listing.postalCode} />
      </Form.Item>
      <div style={{ height: 0 }}>
        <Form.Item name="generatedAddr" style={{ height: 0 }}>
          <Input hidden />
        </Form.Item>
      </div>
    </>
  );
};

export default MissingPropertyData;
