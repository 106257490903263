import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "../../../../helpers/globalFunctions";
import { IObjectKeys } from "../../../../helpers/globalTypes";
import { useDispatch, useSelector } from "react-redux";
import {forceResetPassword, verifyByEmailLink} from "../../../../store/user/action";
import {authLoadingSelector, verifiedFromEmailSelector} from "../../../../store/user/selectors";
import routes from "../../../../routes/routesCode";
import { push } from "connected-react-router";
import { getAuthToken } from "../../../../helpers/authUtils";
import { AnalyticsService } from "helpers/analyticsService";
import "./ForceResetPassword.scss";
import { urlQueryToObject } from "../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import PasswordForm from "components/Shared/password-form/PasswordForm";
import { IResetPassword } from "components/ResetPassword/components/reset-password/components/set-password/SetPassword";

export default function () {
  const dispatch = useDispatch();
  const { search }: IObjectKeys = useLocation();
  const { email, code } = getQueryParams(search);
  const loading = useSelector(authLoadingSelector);
  const verified = useSelector(verifiedFromEmailSelector)
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  useEffect(() => {
    AnalyticsService.reportSignUpProcess({ action: "email_verified-set_password_page_view" });
  }, []);

  useEffect(() => {
    if (!email || !code) {
      if (getAuthToken()) {
        dispatch(push(routes.listings.path));
      } else {
        dispatch(push(routes.signIn.path));
      }
    }
  }, [code, email]);
  useEffect(()=>{
    if(!verified){
      dispatch(verifyByEmailLink(queryObject.code))
    }
  },[verified])
  const onSubmit = (data: IResetPassword) => {
    if (code && email) {
      dispatch(forceResetPassword({ ...data, email, code }));
      AnalyticsService.reportSignUpProcess({ action: "submit_password", response: "success" });
    } else AnalyticsService.reportSignUpProcess({ action: "submit_password", response: "error" });
  };

  return (
    <div className={"force-reset-pass-wrapper set-password"}>
      <PasswordForm
        onSubmit={onSubmit}
        loading={loading}
        title="Set your password"
      />
    </div>
  );
}
