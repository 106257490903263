export const GET_FOLLOW_UPS = "GET_FOLLOW_UPS";
export const GET_FOLLOW_UPS_SUCCESS = "GET_FOLLOW_UPS_SUCCESS";
export const GET_FOLLOW_UPS_FAIL = "GET_FOLLOW_UPS_FAIL";

export const ADD_FOLLOW_UP = "ADD_FOLLOW_UP";
export const ADD_FOLLOW_UP_SUCCESS = "ADD_FOLLOW_UP_SUCCESS";
export const ADD_FOLLOW_UP_FAIL = "ADD_FOLLOW_UP_FAIL";

export const ADD_FOLLOW_UP_SUCCESSED_AND_MODAL =
  "ADD_FOLLOW_UP_SUCCESSED_AND_MODAL";
export const UPDATE_FOLLOW_UP = "UPDATE_FOLLOW_UP";
export const UPDATE_FOLLOW_UP_SUCCESS = "UPDATE_FOLLOW_UP_SUCCESS";
export const UPDATE_FOLLOW_UP_FAIL = "UPDATE_FOLLOW_UP_FAIL";

export const EDIT_FOLLOW_UP = "EDIT_FOLLOW_UP";
export const EDIT_FOLLOW_UP_SUCCESS = "EDIT_FOLLOW_UP_SUCCESS";
export const EDIT_FOLLOW_UP_SUCCESS_CLEAR = "EDIT_FOLLOW_UP_SUCCESS_CLEAR";
export const EDIT_FOLLOW_UP_FAIL = "EDIT_FOLLOW_UP_FAIL";

export const SNOOZE_FOLLOW_UP = "SNOOZE_FOLLOW_UP";
export const SNOOZE_FOLLOW_UP_SUCCESS = "SNOOZE_FOLLOW_UP_SUCCESS";
export const SNOOZE_FOLLOW_UP_FAIL = "SNOOZE_FOLLOW_UP_FAIL";

export const DELETE_FOLLOW_UP = "DELETE_FOLLOW_UP";
export const DELETE_FOLLOW_UP_SUCCESS = "DELETE_FOLLOW_UP_SUCCESS";
export const DELETE_FOLLOW_UP_FAIL = "DELETE_FOLLOW_UP_FAIL";

export const SET_QUERY_AND_PAGINATION = "SET_FOLLOW_UP_QUERY_AND_PAGINATION";

export const CLEAR_GET_FOLLOW_UPS_LIST = "CLEAR_GET_FOLLOW_UPS_LIST";

export const SET_FOLLOWUPS_LOADING_TO_TRUE = "SET_FOLLOWUPS_LOADING_TO_TRUE";

export const GET_LISTING_FOLLOWUPS = "GET_LISTING_FOLLOWUPS";
export const GET_LISTING_FOLLOWUPS_SUCCESS = "GET_LISTING_FOLLOWUPS_SUCCESS";
export const GET_LISTING_FOLLOWUPS_FAIL = "GET_LISTING_FOLLOWUPS_FAIL";

export const GET_LANDLORD_FOLLOWUPS = "GET_LANDLORD_FOLLOWUPS";
export const GET_LANDLORD_FOLLOWUPS_SUCCESS = "GET_LANDLORD_FOLLOWUPS_SUCCESS";
export const GET_LANDLORD_FOLLOWUPS_FAIL = "GET_LANDLORD_FOLLOWUPS_FAIL";

export const GET_LEAD_FOLLOWUPS = "GET_LEAD_FOLLOWUPS";
export const GET_LEAD_FOLLOWUPS_SUCCESS = "GET_LEAD_FOLLOWUPS_SUCCESS";
export const GET_LEAD_FOLLOWUPS_FAIL = "GET_LEAD_FOLLOWUPS_FAIL";

export const SET_FOLLOWUP_DELETE_SUCCESS = "SET_FOLLOWUP_DELETE_SUCCESS";

export const SET_FOLLOWUP_EDIT_SUCCESS = "SET_FOLLOWUP_EDIT_SUCCESS";

export const SET_SNOOZE_EDIT_SUCCESS = "SET_SNOOZE_EDIT_SUCCESS";

export const SET_EDITED_FOLLOW_UP = "SET_EDITED_FOLLOW_UP";
export const CLEAR_EDITED_FOLLOW_UP = "CLEAR_EDITED_FOLLOW_UP";

export const GET_FU_BY_ID = "GET_FU_BY_ID"
export const GET_FU_BY_ID_SUCCESS = "GET_FU_BY_ID_SUCCESS"
export const GET_FU_BY_ID_FAIL = "GET_FU_BY_ID_FAIL"

export const SET_SINGLE_FU = "SET_SINGLE_FU"

export const CLEAR_ALL_FU_MODAL = "CLEAR_ALL_FU_MODAL"
