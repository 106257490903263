import React from "react";
import TableView from "../../../../../../Shared/TableView/TableView";
import { IListingsTableView } from "../../../../../types/ListingWrapperTypes";
import tablesInfo from "../../../../../../Shared/TableView/tablesInfo";
import routes from "../../../../../../../routes/routesCode";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import "./ListingTableView.scss";
import { useDemoContext } from "libs/contextLib";
import { useAppContext } from "libs/contextLib";
import { userSelector } from "store/user/selectors";
import { AnalyticsService } from "helpers/analyticsService";
import { EVENT_NAME } from "helpers/analytics/eventEnums";

const ListingTableView = ({
  listingsViewType,
  pagination,
  setShowingCount,
  listingsFilterOptions,
  showingCount,
  total,
  listingsList,
  setCheckedListing,
  setLandlordActionModalVisible,
  setListingId,
  setAddLandlordModal,
}: IListingsTableView) => {
  const dispatch = useDispatch();
  const { isDemo, demoAction } = useDemoContext();
  const userData = useSelector(userSelector);
  const { isNotPremiumUser } = useAppContext();
  const tableInfo = tablesInfo(userData, isNotPremiumUser);

  const contactHandler = (e: any, record: any) => {
    if (
      //@ts-ignore
      e.target?.parentElement?.id === "listingQuickContactButton"
    ) {
      if (isDemo) {
        demoAction("listing");
      } else {
        setCheckedListing(record);
        setLandlordActionModalVisible(true);
      }
    } else if (
      //@ts-ignore
      e.target?.parentElement?.id === "listingAddLandlordButton"
    ) {
      setListingId(record?._id);
      setAddLandlordModal(true);
    } else {
      AnalyticsService.reportClickEvent(EVENT_NAME.LISTING_CLICK, "table_view", "");
      dispatch(
        push(
          isDemo
            ? `listings/${record?.public_id}?lId=${record?.agent_id}`
            : `${routes.listings.path}/${record?.public_id}?lId=${record?.agent_id}`
        )
      );
    }
  };

  return (
    <TableView
      tableClassName="listings-table"
      showingCount={showingCount}
      setShowingCount={() => setShowingCount(24)}
      paginationValues={pagination}
      total={total}
      filterOptions={listingsFilterOptions}
      tableValues={listingsList}
      viewType={listingsViewType}
      columns={tableInfo.LISTINGS_COLUMNS}
      onRow={(record: any) => {
        return {
          onClick: (e: any) => contactHandler(e, record),
        };
      }}
    />
  );
};

export default ListingTableView;
