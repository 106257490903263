import * as types from "./types";
import { ITeamMembersQuery } from "../../components/TeamMembers/ManageYourTeam";

export const invitationAgent = (payload: any) => ({
  type: types.INVITE_AGENT,
  payload,
});

export const inviteAgentFail = (payload: any) => ({
  type: types.INVITE_AGENT_FAIL,
  payload,
});

export const inviteAgentSuccess = (payload: any) => ({
  type: types.INVITE_AGENT_SUCCESS,
  payload,
});

export const setTeamMembersLoaderToTrue = () => ({
  type: types.SET_TEAM_MEMBERS_LOADER_TO_TRUE,
});
export const setTeamMembersLoaderToFalse = () => ({
  type: types.SET_TEAM_MEMBERS_LOADER_TO_FALSE,
});

export function getTeamMembers(queryAndPagination: ITeamMembersQuery) {
  return {
    type: types.GET_TEAM_MEMBERS,
    payload: { ...queryAndPagination },
  };
}

export function getTeamMembersSuccess(activities: object) {
  return { type: types.GET_TEAM_MEMBERS_SUCCESS, payload: activities };
}

export function getTeamMembersFail(error: object) {
  return { type: types.GET_TEAM_MEMBERS_FAIL, payload: error };
}

export const resendConfirmationLink = (payload: any) => ({
  type: types.RESEND_CONFIRMATION_LINK,
  payload,
});

export const resetInviteAgentFail = (payload: any) => ({
  type: types.RESEND_CONFIRMATION_LINK_FAIL,
  payload,
});

export const resetInviteAgentSuccess = (payload: any) => ({
  type: types.RESEND_CONFIRMATION_LINK_SUCCESS,
  payload,
});

export const removeAgentFromTeam = (payload: any) => ({
  type: types.REMOVE_AGENT_FROM_TEAM,
  payload,
});

export const removeAgentFromTeamSuccess = (payload: any) => ({
  type: types.REMOVE_AGENT_FROM_TEAM_SUCCESS,
  payload,
});

export const setQueryAndPagination = (payload: ITeamMembersQuery) => ({
  type: types.SET_QUERY_AND_PAGINATION,
  payload,
});

export const defaultInviteSuccess = () => ({
  type: types.DEFAULT_INVITE_SUCCESS,
 });

export const setInviteAgentToFalse = () =>({
  type:types.SET_INVITE_AGENT_SUCCESS_FALSE,
  payload:false
})

export const updateTeamName = (payload: any) => ({
  type: types.UPDATE_TEAM_NAME,
  payload,
});

export const updateTeamNameSuccess = (payload: any) => ({
  type: types.UPDATE_TEAM_NAME_SUCCESS,
  payload,
});

export const updateTeamNameFail = (payload: any) => ({
  type: types.UPDATE_TEAM_NAME_FAIL,
  payload,
});
