import React from 'react';

export const MenuIcon = () => {
  return (
    <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.2135 2.55529H1.58342C1.15082 2.55529 0.796875 2.04561 0.796875 1.42267C0.796875 0.799721 1.15082 0.290039 1.58342 0.290039H23.2135C23.6461 0.290039 24 0.799721 24 1.42267C24 2.04561 23.6461 2.55529 23.2135 2.55529Z" fill="black"/>
    <path d="M12.2018 8.43078H1.58342C1.15082 8.43078 0.796875 7.9211 0.796875 7.29816C0.796875 6.67521 1.15082 6.16553 1.58342 6.16553H12.2018C12.6344 6.16553 12.9883 6.67521 12.9883 7.29816C12.9883 7.9211 12.6344 8.43078 12.2018 8.43078Z" fill="black"/>
    <path d="M12.2018 14.4718H1.58342C1.15082 14.4718 0.796875 13.9621 0.796875 13.3392C0.796875 12.7162 1.15082 12.2065 1.58342 12.2065H12.2018C12.6344 12.2065 12.9883 12.7162 12.9883 13.3392C12.9883 13.9621 12.6344 14.4718 12.2018 14.4718Z" fill="black"/>
    </svg>
    

  );
};
