import Api from "./api";
export default class ReportProblem {
    static deleteReportImage(imageId: string) {
        const api = new Api(`report/media`);
        return api.destroy(imageId);
    }
    static sendReportBugEmail(payload: any) {
        const api = new Api('report/feedback');
        return api.create({ data: payload });
    }
}

