import React, {useEffect, useState} from "react";
import {VerifyEmailIcon} from "components/icons/verify-email-icons/VerifyEmailIcon";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {
    leadVerifyEmailErrorSelector,
    leadVerifyEmailSuccessSelector
} from "store/leads/selectors";
import {generateErrorMessage, openNotification} from "helpers/globalFunctions";
import {push} from "connected-react-router";
import LeadsApi from "api/Leads";
import Message from "components/Shared/Message";
import "./ResendVerifyEmail.scss"

interface IRenterResendVerifyEmail {
    onClose: () => void;
}

const RenterResendVerifyEmail = ({onClose}: IRenterResendVerifyEmail) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const leadVerifyEmailSuccess = useSelector(leadVerifyEmailSuccessSelector);
    const leadVerifyEmailError = useSelector(leadVerifyEmailErrorSelector);
    const [sendEmailVerifyCodeSuccess, setSendEmailVerifyCodeSuccess] = useState(false);
    const [sendEmailVerifyCodeError, setSendEmailVerifyCodeError] = useState(null);

    const sendVerifyEmailAgain = async () => {
        try {
            const response: any = await LeadsApi.sendLeadVerifyEmailCode();
            if (response.success) {
                setSendEmailVerifyCodeSuccess(true);
                setTimeout(() => {
                    setSendEmailVerifyCodeSuccess(false);
                }, 2000);
            }
        } catch (err) {
            setSendEmailVerifyCodeError(err);
        }
    };

    useEffect(() => {
        if (leadVerifyEmailError) {
            openNotification("error", generateErrorMessage(leadVerifyEmailError ?? ""));
        } else if (leadVerifyEmailSuccess) {
            queryParams.append("code", leadVerifyEmailSuccess);
            dispatch(
                push({
                    search: queryParams.toString()
                })
            );
        }
    }, [leadVerifyEmailSuccess, leadVerifyEmailError, queryParams, dispatch]);
    useEffect(() => {
        if (sendEmailVerifyCodeError) {
            openNotification("error", generateErrorMessage(sendEmailVerifyCodeError ?? ""));
        }
    }, [sendEmailVerifyCodeError]);

    return (
        <>
            <div className="resend-verify-email-container">
                <div className="resend-verify-email-container-icon-wrapper">
                    <VerifyEmailIcon/>
                </div>
                <div className="resend-verify-email-container-text">
                    <p className="resend-verify-email-container-text-desc">
                        Please verify your account
                        <br/>
                        through the link sent to your email.
                    </p>
                    <span className="resend-verify-email-container-text-send-again">
                        Didn't get an email?{" "}
                        <span onClick={sendVerifyEmailAgain}>
                            Send again
                        </span>
                    </span>
                </div>
                <p
                    className={"resend-verify-email-container-backto"}
                    onClick={onClose}
                >
                    {"<"} Back
                </p>
            </div>
            {sendEmailVerifyCodeSuccess && <Message text={"Email Sent"}/>}
        </>
    );
};

export default RenterResendVerifyEmail;
