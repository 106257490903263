import React from 'react';

const UserWithPlusIcon = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.73828 5.89062C4.11422 5.89062 2.79297 4.56937 2.79297 2.94531C2.79297 1.32125 4.11422 0 5.73828 0C7.36234 0 8.68359 1.32125 8.68359 2.94531C8.68359 4.56937 7.36234 5.89062 5.73828 5.89062Z" fill="#5806B9" />
      <path d="M6.88086 8.04856C6.88086 7.39653 7.06393 6.78647 7.38126 6.26697C6.88576 6.51309 6.32805 6.65207 5.73828 6.65207C4.97331 6.65207 4.26177 6.41914 3.67075 6.02051C2.93996 6.30379 2.26954 6.73883 1.69868 7.30969C0.619633 8.38872 0.0253906 9.82334 0.0253906 11.3493V12.6189C0.0253906 12.8292 0.195914 12.9997 0.40625 12.9997H11.0703C11.2806 12.9997 11.4512 12.8292 11.4512 12.6189V11.3493C11.4512 11.3264 11.4505 11.3035 11.4503 11.2806C11.093 11.4072 10.7087 11.4763 10.3086 11.4763C8.41854 11.4763 6.88086 9.93861 6.88086 8.04856V8.04856Z" fill="#5806B9" />
      <path d="M10.3086 5.38281C8.83855 5.38281 7.64258 6.57879 7.64258 8.04883C7.64258 9.51887 8.83855 10.7148 10.3086 10.7148C11.7786 10.7148 12.9746 9.51887 12.9746 8.04883C12.9746 6.57879 11.7786 5.38281 10.3086 5.38281ZM11.0703 8.42969H10.6895V8.81055C10.6895 9.02088 10.5189 9.19141 10.3086 9.19141C10.0983 9.19141 9.92773 9.02088 9.92773 8.81055V8.42969H9.54688C9.33654 8.42969 9.16602 8.25916 9.16602 8.04883C9.16602 7.83849 9.33654 7.66797 9.54688 7.66797H9.92773V7.28711C9.92773 7.07677 10.0983 6.90625 10.3086 6.90625C10.5189 6.90625 10.6895 7.07677 10.6895 7.28711V7.66797H11.0703C11.2806 7.66797 11.4512 7.83849 11.4512 8.04883C11.4512 8.25916 11.2806 8.42969 11.0703 8.42969Z" fill="#5806B9" />
    </svg>
  );
};

export default UserWithPlusIcon



