import Api from "./api";

export default class ThumbtackApi {
    
    static getCategoriesList() {
        const api = new Api(`thumbtack/categories`);
        return api.get("", {});
      }

    static getProsList(category: string, zipCode: string) {
        const api = new Api(`thumbtack/pros?category=${category}&zip_code=${zipCode}`);
        return api.get("", {});
    }
}
