import React, { useEffect, useState } from "react";
import BaseModal from "../../Shared/BaseModal";
import "./AllListingNotesModal.scss";
import BaseButton from "../../Shared/BaseButton";
import ListingNotesModalNote from "./all-listing-notes-single-note/ListingNotesModalNote";
import IndividualNoteWrapper from "./all-listing-notes-single-note/components/IndividualNoteWrapper";
import { useWindowSize } from "../../../hooks/useWindowSize";

interface IAllListingNotesModal {
  visible: boolean;
  setVisible: any;
  handleOpenAddNoteModal: any;
  notes: any;
  handleOpenNoteModal: any;
  setDeleteConfirmProps?: any;
  setSelectedNote: any;
  selectedNote: any;
  setContactModalVisible?: any;
}
const AllListingNotesModal = ({
  visible,
  setVisible,
  handleOpenAddNoteModal,
  notes,
  handleOpenNoteModal,
  setDeleteConfirmProps,
  selectedNote,
  setSelectedNote,
  setContactModalVisible
}: IAllListingNotesModal) => {
  const [singleNote, setSingleNote] = useState({});
  const [isSingleNoteVisible, setIsSingleNoteVisible] = useState(false);
  const { width } = useWindowSize();
  const handleShowSingleNote = (note: any) => {
    setSingleNote(note);
    setIsSingleNoteVisible(true);
  };
  useEffect(() => {
    if (!visible) {
      setSingleNote({});
      setIsSingleNoteVisible(false);
      setSelectedNote({});
      localStorage.removeItem("allNotesClicked");
    }
  }, [visible]);
  useEffect(() => {
    if (Object.keys(selectedNote).length > 0) {
      setSingleNote(selectedNote);
      setIsSingleNoteVisible(true);
    }
  }, [selectedNote]);

  return (
    <BaseModal
      classname={`single-listing-page-all-note all-notes-modal-wrapper
      ${isSingleNoteVisible && "single-listing-page-all-noteSingle"}`}
      cancelButtonProps={{
        className: "rb-secondary-btn sm",
      }}
      okButtonProps={{
        className: "rb-primary-btn sm",
      }}
      modalTitle=""
      isModalVisible={visible}
      onCancel={() => {
        setIsSingleNoteVisible(false);
        setVisible(false);
        setSingleNote({});
      }}
      modalFooter={[null]}
      width={
        //@ts-ignore
        width >= 1000 ? 655 : width < 600 ? 300 : 400
      }
    >
      <div
        className={`single-listing-page-all-note--headPart 
          ${isSingleNoteVisible && "single-listing-page-all-note--headPartSingle"}`}
      >
        {isSingleNoteVisible ?
          <p className={"single-listing-page-all-note--headPart--goBack"} onClick={() => {
            setSingleNote({});
            setIsSingleNoteVisible(false);
          }
          }>
            {"< Back To All Notes"}
          </p> :
          <>
            <div className={"single-listing-page-all-note--headPart--allContactNotesClicked"}>
              {
                (
                  localStorage.getItem("allNotesClicked") &&
                  localStorage.getItem("allNotesClicked") === "clicked"
                ) &&
                <p className={"single-listing-page-all-note--headPart--goBack"}
                  onClick={() => {
                    setContactModalVisible(true);
                    setVisible(false);
                  }}
                >
                  {"< Back"}
                </p>
              }
              <p className={"single-listing-page-all-note--headPart--title"}>All notes</p>
            </div>
            <BaseButton
              classnames={"rb-primary-btn md"}
              onClick={() => {
                handleOpenAddNoteModal();
                setVisible(false);
              }}
              modalType="followUp"
            >
              Add New Note
            </BaseButton>
          </>
        }

      </div>

      <div className={"single-listing-page-all-note--notesWrapper scrollbar"}>
        {isSingleNoteVisible ?
          <IndividualNoteWrapper
            note={singleNote}
            handleOpenNoteModal={handleOpenNoteModal}
            setVisible={setVisible}
            setDeleteConfirmProps={setDeleteConfirmProps}
            setSingleNoteVisible={setIsSingleNoteVisible}
          /> :
          notes.map((e: any, i: number) => {
            return (
              <div
                key={i}
                className={"single-listing-page-all-note--notesWrapper--item"}
              >
                <ListingNotesModalNote
                  handleShowSingleNote={handleShowSingleNote}
                  note={e}
                  handleOpenNoteModal={handleOpenNoteModal}
                  setVisible={setVisible}
                  setDeleteConfirmProps={setDeleteConfirmProps}
                  setSingleNoteVisible={setIsSingleNoteVisible}
                />
              </div>
            );
          })
        }
      </div>
    </BaseModal>
  );
};

export default AllListingNotesModal;
