import React from "react";

const GoogleIcon = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M5.09729 13.899L4.29669 16.8877L1.37052 16.9496C0.496027 15.3276 0 13.4718 0 11.4998C0 9.5928 0.463774 7.79449 1.28584 6.21104H1.28647L3.89158 6.68865L5.03278 9.27812C4.79393 9.97446 4.66374 10.722 4.66374 11.4998C4.66383 12.344 4.81675 13.1528 5.09729 13.899Z"
                    fill="#FBBB00"/>
                <path
                    d="M22.7991 9.35165C22.9312 10.0473 23 10.7657 23 11.5C23 12.3233 22.9135 13.1264 22.7486 13.9011C22.1887 16.5373 20.7259 18.8391 18.6996 20.4681L18.6989 20.4675L15.4177 20.3001L14.9533 17.401C16.2979 16.6125 17.3487 15.3784 17.9022 13.9011H11.7528V9.35165H17.9919H22.7991Z"
                    fill="#518EF8"/>
                <path
                    d="M18.6989 20.4675L18.6995 20.4681C16.7287 22.0522 14.2253 23 11.5 23C7.12054 23 3.31292 20.5521 1.37054 16.9499L5.09731 13.8992C6.06847 16.4911 8.56878 18.3362 11.5 18.3362C12.7599 18.3362 13.9403 17.9956 14.9532 17.401L18.6989 20.4675Z"
                    fill="#28B446"/>
                <path
                    d="M18.8404 2.64752L15.1149 5.69753C14.0666 5.0423 12.8275 4.66379 11.5 4.66379C8.50239 4.66379 5.95532 6.5935 5.03281 9.27835L1.28646 6.21126H1.28583C3.19977 2.52115 7.05541 0 11.5 0C14.2903 0 16.8487 0.993941 18.8404 2.64752Z"
                    fill="#F14336"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="23" height="23" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default GoogleIcon;