import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { leadsLastQuerySelector, singleLeadDataSelector } from "../../../../store/leads/selectors";
import { AnalyticsService } from "../../../../helpers/analyticsService";
import AllFollowUpsModal from "../../../FollowUps/all-follow-ups-modal/AllFollowUpsModal";
import AddFollowUpModal from "../../../FollowUps/add-follow-up-modal/AddFollowUpModal";
import AddFollowUpModalMobile from "../../../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import PhoneVerifyModalFollowUps from "../../../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import {
  addFollowUpSuccessSelector,
  editedFollowUpSelector,
  editFollowUpSuccessSelector,
  followUpsListSelector,
} from "../../../../store/followUps/selectors";
import { ListingNote } from "../../../Listings/helpers/mockListings";
import { useForm } from "antd/es/form/Form";
import { getLeadFollowUps } from "../../../../store/followUps/action";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import FollowUpModalIcon from "../../../icons/FollowUpModalIcon";
import "./LeadGoBackActionWrapper.scss";
import { useDemoContext } from "libs/contextLib";
import { followUpModalsHandler } from "../../../../helpers/globalFunctions";
import PremiumUpgradeModal from "../../../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { ACTIONS, TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import routes from "routes/routesCode";
import BaseButton from "components/Shared/BaseButton";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

const initialModalValue = { visible: false, editModal: false };

const LeadGoBackAction = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = useForm();
  const leadsLastQueryUrl = useSelector(leadsLastQuerySelector);
  const leadData = useSelector(singleLeadDataSelector);
  const { isDemo, demoAction } = useDemoContext();
  const followUps = useSelector(followUpsListSelector);
  const [allFollowUpsModalVisible, setAllFollowUpsModalVisible] = useState(initialModalValue);
  const addFollowUpSuccess = useSelector(addFollowUpSuccessSelector);
  const editFollowUpSuccess = useSelector(editFollowUpSuccessSelector);
  const openAllFollowUpsModalHandler = () => {
    if (isDemo) {
      demoAction("followUp");
    } else {
      setAllFollowUpsModalVisible({ visible: true, editModal: false });
    }
  };
  const { width } = useWindowSize();
  const [followUpModalProps, setFollowUpModalProps] = useState(initialModalValue);
  const [isDescShown, setIsDescShown] = useState(false);
  const [isFollowUpEditable, setIsFollowUpEditable] = useState(false);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const editedFollowUp = useSelector(editedFollowUpSelector);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  useEffect(() => {
    if (queryObject?.action === ACTIONS.ADD_INDIVIDUAL_FOLLOW_UP) {
      setFollowUpModalProps({ visible: true, editModal: false });
    }
  }, [queryObject]);

  const handleOpenAddNoteModal = () => {
    setFollowUpModalProps({ visible: true, editModal: false });
    form.resetFields();
    setIsDescShown(false);
    setIsFollowUpEditable(false);
  };
  const closeVerifyModalHandler = () => {
    setPhoneVerifyModalVisible(false);
  };
  const handleOpenFollowUpModal = (note: ListingNote) => {
    setFollowUpModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at: note.todo_at,
    });
    setIsFollowUpEditable(true);
    if (note.description && note.description.length > 0) {
      setIsDescShown(true);
    } else {
      setIsDescShown(false);
    }
  };
  useEffect(() => {
    if (addFollowUpSuccess && !followUpModalProps.visible && leadData.renter) {
      dispatch(getLeadFollowUps(leadData.renter._id));
    }
  }, [addFollowUpSuccess, editFollowUpSuccess, followUpModalProps]);
  useEffect(() => {
    if (Object.keys(editedFollowUp).length > 0) {
      followUpModalsHandler({
        setFollowUpModalProps,
        setAllFollowUpsModalVisible,
        editedFollowUp,
        dispatch,
      });
    }
  }, [editedFollowUp]);

  const goBack = () => {
    AnalyticsService.reportBackButtonClicked("leads");
    if (isDemo) {
      history.push({
        pathname: "/demo",
        search: leadsLastQueryUrl,
      });
    } else {
      history.push({
        pathname: routes.leads.path,
        search: leadsLastQueryUrl,
      });
    }
  };
  return (
    <Row className={"lead-go-back-action-wrapper"}>
      <Col span={24}>
        <div>
          <BaseButton
            className="back-to-listings-btn"
            variant="link"
            onClick={goBack}
            icon={<ChevronLeftIcon className="h-5 w-5" />}
          >
            Back to Leads
          </BaseButton>
        </div>
      </Col>
      <AllFollowUpsModal
        visible={allFollowUpsModalVisible.visible}
        setVisible={setAllFollowUpsModalVisible}
        handleOpenAddFollowUpModal={handleOpenAddNoteModal}
        notes={followUps}
        handleOpenFollowUpEditModal={handleOpenFollowUpModal}
        setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
      />
      {
        //@ts-ignore
        width < 599 && (
          <div className={"single-listing-actions-wrapper--followUp"}>
            <div className={"single-listing-actions-wrapper--followUp--circle"}>
              <span>{followUps.length}</span>
            </div>
            <FollowUpModalIcon openModalHandler={openAllFollowUpsModalHandler} />
          </div>
        )
      }
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            lead={leadData}
            isDescShown={isDescShown}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            lead={leadData}
            isDescShown={isDescShown}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        )
      }
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={closeVerifyModalHandler} />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_ALL_FOLLOW_UPS_MODAL}
      />
    </Row>
  );
};
export default LeadGoBackAction;
