import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { IObjectKeys } from "../../../../helpers/globalTypes";
import { useDispatch, useSelector } from "react-redux";
import { windowSelector } from "../../../../store/app/selectors";
import { updateUserSettingsSuccessSelector, userSelector } from "../../../../store/user/selectors";
import SignupUserImage from "./image-upload/SignupUserImage";
import { updateUserSettings } from "../../../../store/user/action";
import "./styles.scss";
import BaseButton from "../../../Shared/BaseButton";
import { push } from "connected-react-router";
import { getSignUpVerificationFlowStep, isOwner, sanitizePhoneNumber } from "../../../../helpers/globalFunctions";
import { useLocation } from "react-router";
import ScrollToTop from "../../../../hocs/ScrollToTop";
import TextArea from "antd/lib/input/TextArea";
import { AnalyticsService } from "helpers/analyticsService";

const SignupSettingsForm = () => {
  const [form] = useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const { type } = useSelector(windowSelector);
  const userData = useSelector(userSelector);
  const [, setImageData] = useState();
  const updatedSuccess = useSelector(updateUserSettingsSuccessSelector);
  const listingsRequestedLink = localStorage.getItem("listingsRequestedLink");
  const [, setIsInvitedAgent] = useState(false);
  useEffect(() => {
    if (userData?.office_name) {
      form.setFieldsValue({
        office_name: userData.office_name,
      });
    }
  }, [userData]);
  const onSubmit = (data: IObjectKeys) => {
    const formData = { ...data };
    const { password } = form.getFieldsValue(["password"]);
    const formFieldsFilled = {
      is_office_name: !!formData.office_name,
      is_address: !!formData.address,
      is_bio: !!formData.bio,
    };
    AnalyticsService.reportSignUpProcess({
      action: `sign_up_verification_flow_submit_${getSignUpVerificationFlowStep()}`,
      formFieldsFilled,
    });
    dispatch(
      updateUserSettings({
        ...formData,
        phone: sanitizePhoneNumber(`${userData.phone}`) || undefined,
        _id: userData._id || userData._doc._id,
        name: userData.name || userData._doc.name,
        email: userData.email || userData._doc.email,
        password,
      })
    );
  };

  useEffect(() => {
    if (updatedSuccess && location.search.includes("step=2")) {
      if (localStorage.getItem("leadsPageRequested")) {
        dispatch(push(listingsRequestedLink || "/leads"));
      } else {
        dispatch(push("/"));
      }
    }
  }, [updatedSuccess]);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData.role === "Agent") {
        setIsInvitedAgent(true);
      } else {
        setIsInvitedAgent(false);
      }
      // form.setFieldsValue({
      //     address:,
      //     defaultCity:userData.defaultCity,
      //     defaultState:userData.defaultState
      // })
    }
  }, [userData]);
  return (
    <div className={"sign-up-settings"}>
      <ScrollToTop />
      <Form
        form={form}
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        layout={"vertical"}
        data-test-id="sign-up-settings-form"
        className={"sign-up-settings-form"}
      >
        {isOwner(userData) || userData.role === "Agent" ? (
          <div className={"sign-up-settings-form-borderRounded"}>
            <Form.Item
              name={"office_name"}
              label={"What Brokerage do you work for?"}
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Brokerage Name is required!" }]}
            >
              <Input
                autoComplete={"off"}
                data-test-id={"sign-up-settings-form-input"}
                placeholder={"ex. Keller Williams"}
              />
            </Form.Item>
          </div>
        ) : null}
        <SignupUserImage setImageData={setImageData} type={type} userData={userData} />
        <div className={"sign-up-settings-form-borderRounded sign-up-settings-form-bio"}>
          <p>Bio</p>
          <Form.Item
            name="bio"
            rules={[
              {
                min: 3,
                message: "Min length is 3 characters.",
              },
            ]}
          >
            <TextArea autoSize={{ minRows: 2 }} autoComplete={"off"} data-test-id={"sign-up-settings-form-input"} />
          </Form.Item>
        </div>
      </Form>
      <BaseButton
        className="mt-4"
        variant="primary"
        onClick={() => {
          form.submit();
        }}
      >
        Let's Go!
      </BaseButton>
    </div>
  );
};

export default SignupSettingsForm;
