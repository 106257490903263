import React, { useState } from "react";
import { Layout } from "antd";
import PageFooter from "../../components/Footer";
import "./styles.scss";
import { Spin } from "antd";
import GlobalLoading from "../../components/Shared/GlobalLoading";
import { leadLoadingSelector } from "store/leads/selectors";
import { useSelector } from "react-redux";
import { loadingRequirementAgentDataSelector } from "store/requirement/selectors";
import LeadFlowHeader from "../../components/Header/LeadFlowHeader/LeadFlowHeader";
import { useLocation } from "react-router";

export interface LeadFlowLayoutProps {
  children: React.ReactNode;
}

const { Content, Footer } = Layout;
const LeadFlowLayout: React.FC<LeadFlowLayoutProps> = ({ children }) => {
  const [isFooterCollapsed, setIsFooterCollapsed] = useState(false);
  const location = useLocation();
  const leadLoading = useSelector(leadLoadingSelector);
  const loadingRequirement = useSelector(loadingRequirementAgentDataSelector);
  const collapseFooter = () => {
    setIsFooterCollapsed((prevState) => !prevState);
  };
  return (
    <Layout
      className={`auth-layout-wrapper page-content-layout lead-flow-layout ${
        location.pathname.includes("lead-flow") && "lead-flow-layout-grey"
      }`}
    >
      <Spin
        className={"global-loading"}
        indicator={<GlobalLoading />}
        spinning={leadLoading || loadingRequirement}
      >
        <LeadFlowHeader />
        <Content className={`site-layout-background site-layout-main lead-flow-layout-content ${
            location.pathname.includes("lead-flow") && "lead-flow-layout-grey"}`}>
          {children}
        </Content>
      </Spin>
      <Footer
        className={`site-layout-footer guest-layout-footer ${
          isFooterCollapsed ? "collapsed" : ""
        }`}
      >
        <PageFooter
          isGuest={true}
          isFooterCollapsed={isFooterCollapsed}
          collapseFooter={collapseFooter}
        />
      </Footer>
    </Layout>
  );
};

export default LeadFlowLayout;
