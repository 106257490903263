import { IObjectKeys } from "helpers/globalTypes";
import React from "react";
import { setContactLeadData } from "store/leads/action";
import { useDispatch } from "react-redux";
import { useDemoContext } from "libs/contextLib";

import "../styles.scss";
import BaseButton from "components/Shared/BaseButton";

const QuickScreening = (props: { rowData: IObjectKeys; page?: string; setScreeningModalVisible?: any }) => {
  const renter = props.rowData?.renterOfRequirement;
  const show = props.page === "leads" || props.page === "lead-flow";
  const dispatch = useDispatch();
  const { isDemo, demoAction } = useDemoContext();

  const contactHandler = () => {
    //@ts-ignore
    if (isDemo && width < 767) {
      demoAction("landlord");
    } else if (renter) {
      renter._id && dispatch(setContactLeadData(props.rowData));
    }
  };

  return (
    <BaseButton
      disabled={renter || show ? false : true}
      data-test-id="listing-quick-contact-button"
      id="requestScreeningButton"
      onClick={contactHandler}
    >
      <>Screening</>
    </BaseButton>
  );
};

export default QuickScreening;
