import React, { useEffect } from "react";
import { Button, Form, Steps } from "antd";
import { StepFormComponent } from "../ListingForm/types";
import { useDispatch, useSelector } from "react-redux";
import BaseButton from "../../Shared/BaseButton";
import {
  listingDataSelector,
  listingLoadingSelector,
  listingUpdateSuccessSelector,
} from "../../../store/listings/selectors";
import { clearListingUpdateState, getOneListing } from "../../../store/listings/action";
import OffMarketStep from "./OffMarketStep";
import TenantsStep from "./TenantsStep";
import { FormInstance } from "antd/es/form";
const { Step } = Steps;

interface IMakeListingOffMarket {
  closeModal: () => void;
  step: number;
  setStep: (step: number) => void;
  form: FormInstance;
}

const steps = ({ form, next }: StepFormComponent) => [
  {
    title: "Basics",
    content: <OffMarketStep form={form} next={next} />,
  },
  {
    title: "Add Tenants",
    content: <TenantsStep form={form} statusChangeForm={true} />,
  },
];
const MakeListingOffMarket = ({ closeModal, setStep, step, form }: IMakeListingOffMarket) => {
  const dispatch = useDispatch();
  const listingData = useSelector(listingDataSelector);
  const loading = useSelector(listingLoadingSelector);
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const prev = () => {
    setStep(step - 1);
  };
  const next = () => {
    setStep(step + 1);
  };

  const formSteps = steps({ form, next, prev });

  useEffect(() => {
    if (updateListingSuccess && step === 1) {
      closeModal();
      dispatch(getOneListing(listingData._id));
      setStep(0);
    }
    return () => {
      dispatch(clearListingUpdateState());
    };
  }, [updateListingSuccess]);

  return (
    <>
      <Steps current={step}>
        {formSteps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{formSteps[step].content}</div>
      <div className="steps-action">
        {step > 0 && (
          <BaseButton classnames={"rb-secondary-btn md mr-10 ml-10"} onClick={() => prev()}>
            Previous
          </BaseButton>
        )}
        {step < formSteps.length - 1 && (
          <BaseButton classnames={"rb-primary-btn md"} onClick={() => form.submit()} loading={loading}>
            Next
          </BaseButton>
        )}
        {step === formSteps.length - 1 && (
          <BaseButton
            classnames={"rb-primary-btn md"}
            loading={loading}
            onClick={() => {
              form.submit();
            }}
          >
            Done
          </BaseButton>
        )}
      </div>
    </>
  );
};
export default MakeListingOffMarket;
