import React from "react";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "store/user/action";
import { passwordResetLoadingSelector } from "store/user/selectors";
import "./CodeSend.scss";
import BaseButton from "components/Shared/BaseButton";
import { IObjectKeys } from "helpers/globalTypes";
import { useHistory } from "react-router-dom";
interface ResetPasswordI {
  email: string;
  code: string;
  newPassword: string;
}
const CodeSend = ({ setEmail }: IObjectKeys) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loading = useSelector(passwordResetLoadingSelector);
  const history = useHistory();

  const onSubmit = (data: ResetPasswordI) => {
    setEmail(data.email);
    dispatch(resetPassword({ email: data.email }));
  };

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="my-10 text-center text-2xl font-bold">Reset Your Password</h2>
      </div>
      <Form form={form} onFinish={onSubmit} className="space-y-6" layout="vertical">
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
            {
              type: "email",
              message: "Field should be valid email.",
            },
          ]}
        >
          <Input
            type="text"
            autoComplete="off"
            className={
              "hidden-arrows block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
            }
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <>
              <BaseButton
                type="submit"
                variant="primary"
                loading={loading}
                disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
              >
                Next
              </BaseButton>
            </>
          )}
        </Form.Item>
      </Form>
      <div className="sm:mt-20 mt-4">
        <BaseButton variant="link" onClick={() => history.goBack()}>
          {"< Back"}
        </BaseButton>
      </div>
    </div>
  );
};
export default CodeSend;
