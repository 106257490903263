import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";
import { FILL_TERMS_AND_CONDITIONS_DATA } from "./types";

interface IPrivacyPolicy {
  loading: boolean;
  title?: string;
  metaTitle?: string;
  metaDescription?: string;
  mainContent?: IObjectKeys;
  termsTitle?: string;
  termsMetaTitle?: string;
  termsMetaDescription?: string;
  termsMainContent?: IObjectKeys;
}

const initialState: IPrivacyPolicy = {
  loading: false,
  title: "",
  metaTitle: "",
  metaDescription: "",
  mainContent: {},
  termsTitle: "",
  termsMetaTitle: "",
  termsMetaDescription: "",
  termsMainContent: {},
};

export default function termsAndPolicyReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.LOADING_TERMS_OR_PRIVACY_DATA:
      return {
        ...state,
        loading: true,
      };
    case types.LOADING_TERMS_OR_PRIVACY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.FILL_PRIVACY_POLICY_DATA:
      return {
        ...state,
        title: action.payload.title,
        metaTitle: action.payload.metaTitle,
        metaDescription: action.payload.metaDescription,
        mainContent: action.payload.mainContent,
      };

    case types.FILL_TERMS_AND_CONDITIONS_DATA:
      return {
        ...state,
        termsTitle: action.payload.title,
        termsMetaTitle: action.payload.metaTitle,
        termsMetaDescription: action.payload.metaDescription,
        termsMainContent: action.payload.mainContent,
      };
    default:
      return state;
  }
}
