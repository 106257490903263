import React from "react";
import "./FormStepsTitle.scss"
interface IFormStepsTitle {
    text:string,
    classname?:string;
    secondaryText?:any
}
const FormStepsTitle = ({text,classname,secondaryText}:IFormStepsTitle) =>{
    return(
        <div className={`form-steps-title ${classname}`}>
            <p className={`fw-400 ${secondaryText ? "form-steps-title-hasSecondary" : ""}`}>
                {text}
                <span className="redAsterisk">*</span>
            </p>
            {secondaryText ?
                <p className={"fw-400"}>
                    {secondaryText}
                    <span className="redAsterisk">*</span>
                </p>:null
            }
        </div>
    )
}

export default FormStepsTitle
