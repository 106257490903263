import React from "react";

const UncheckedIcon = () => {
    return(
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={"uncheck"}
        >
            <rect
                x="0.5"
                y="1"
                width="23"
                height="23"
                rx="3.5"
                fill="white"
                stroke="#D4D4D4"
            />
        </svg>
    )
}

export default UncheckedIcon
