import React from "react";
import { Link } from "react-router-dom";

const PropertyLinkColumn = (_id: string, rowData: any) => {
  return {
    children: (
      <Link to={`/listings/${rowData?.listing?.public_id}`}>
        <div className="listing-table-address-column">
          <span>{rowData.listing?.generatedAddr}</span>
        </div>
      </Link>
    ),
    props: {
      "data-test-id": "property_address",
    },
  };
};

export default PropertyLinkColumn;
