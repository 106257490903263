import { getListingsViewType } from "../../../../helpers/globalFunctions";
import { IObjectKeys } from "../../../../helpers/globalTypes";
import { IPaginationValues } from "../../../Listings/components/listings-wrapper";
import {
  addr,
  state,
  town,
  street_name,
  street_number,
  current_rent_from,
  current_rent_to,
  availability_date_to,
  asked_rent_from,
  asked_rent_to,
  pets_allowed,
  parking_type,
  bedrooms_from,
  bedrooms_to,
  bathrooms_from,
  bathrooms_to,
  page,
  size,
  status,
  IFilterOptionsOptionalKeys,
  created_at_order,
  asked_rent_order,
  bedrooms_order,
  search,
  neighbourhood,
  sub_area,
  sub_sub_area,
  locations,
  is_search_active,
  tags,
  filter_by_agent,
} from "../../../Listings/helpers/mockListings";
import { ALGOLIA_SORT_FIELD } from "../../../../helpers/constants";
import { isStringified } from "./filterFormHelper";
import { isListingRoute } from "helpers/clientRoutes";

const filterOptionsKeys = [
  addr,
  state,
  town,
  neighbourhood,
  sub_area,
  sub_sub_area,
  street_name,
  street_number,
  current_rent_from,
  current_rent_to,
  availability_date_to,
  asked_rent_from,
  asked_rent_to,
  pets_allowed,
  parking_type,
  bedrooms_from,
  bedrooms_to,
  bathrooms_from,
  bathrooms_to,
  status,
  search,
  locations,
  is_search_active,
  tags,
];

export const urlQueryToObject = (query: any) => {
  const obj: IObjectKeys = {};
  const locationKey = [locations];
  const arrayKeys = [pets_allowed, parking_type, tags];
  const numberKeys = [
    page,
    size,
    current_rent_from,
    current_rent_to,
    asked_rent_from,
    asked_rent_to,
    bedrooms_from,
    bedrooms_to,
    bathrooms_from,
    bathrooms_to,
  ];

  try {
    for (let [key, value] of query) {
      if (numberKeys.includes(key)) {
        obj[key] = Number(value);
      } else if (arrayKeys.includes(key)) {
        obj[key] = JSON.parse(value);
      } else if (locationKey.includes(key)) {
        const v = JSON.parse(value);
        obj[key] = v;
      } else {
        obj[key] = value;
      }
    }
  } catch (error) {
    console.log("Error", error);
  }

  return obj;
};

export const initialFilterOptionsFromSearchQuery = (queryObject: any): IFilterOptionsOptionalKeys => {
  const filterOptionsToReturn: any = {};
  for (let key of filterOptionsKeys) {
    if (queryObject[key]) {
      filterOptionsToReturn[key] = queryObject[key];
    }
  }
  if (!isListingRoute(window.location.pathname) && queryObject.locations) {
    filterOptionsToReturn.locations = JSON.parse(queryObject[locations]);
  }

  return filterOptionsToReturn;
};

export const paginationValuesFromSearchQuery = (queryObject: any) => {
  const paginationValuesToReturn: IPaginationValues = {
    current: 1,
    pageSize: 24,
  };
  if (queryObject.page) {
    paginationValuesToReturn["current"] = Number(queryObject.page);
  }
  if (queryObject.size) {
    paginationValuesToReturn["pageSize"] = Number(queryObject.size);
  }

  return paginationValuesToReturn;
};

export const getPaginationValuesForLeads = (queryObject: IObjectKeys) => {
  const paginationValuesToReturn: IPaginationValues = {
    current: queryObject.current,
    pageSize: queryObject.pageSize || localStorage.getItem("leads_showing_count") || 24,
  };
  if (queryObject.page) {
    paginationValuesToReturn["current"] = queryObject.page;
  }
  if (queryObject.size) {
    paginationValuesToReturn["pageSize"] = queryObject.size;
  }

  return paginationValuesToReturn;
};

export const listingTypeFromSearchQuery = (queryObject: any) => {
  return getListingsViewType();
};

export const initialFilterOptionsGenerator = (locationSearch: string, queryObject: IObjectKeys) => {
  if (locationSearch) {
    return initialFilterOptionsFromSearchQuery(queryObject);
  } else {
    return {};
  }
};

export const createFilterQuery = (
  listingType: any,
  filterOptions: any,
  pagination: any,
  queryObject: any,
  landlord_id?: any,
  type?: string
) => {
  const searchParams: URLSearchParams = new URLSearchParams();

  if (listingType && type !== "leads" && type !== "landlords") {
    searchParams.append("listingType", listingType);
  }
  if (type === "leads" || type === "landlord") {
    if (type !== "landlord") {
      searchParams.append("myLeads", "true");
      searchParams.set("myLeads", "true");
    }
    delete filterOptions.status;
    delete queryObject.status;
  }
  searchParams.append("page", pagination["current"] || 1);
  let _size;
  _size = !localStorage.getItem(type ? `${type}_showing_count` : "listing_showing_count")
    ? 24
    : +JSON.parse(localStorage.getItem(type ? `${type}_showing_count` : "listing_showing_count") as string);
  searchParams.append("size", _size.toString());
  if (landlord_id) {
    searchParams.append("landlord_id", landlord_id);
  }
  if (queryObject.algoliaSortField) {
    searchParams.append("algoliaSortField", queryObject.algoliaSortField);
  }

  [
    addr,
    state,
    town,
    street_name,
    street_number,
    current_rent_from,
    current_rent_to,
    availability_date_to,
    asked_rent_from,
    asked_rent_to,
    parking_type,
    bedrooms_from,
    bedrooms_to,
    bathrooms_from,
    bathrooms_to,
    status,
    search,
    is_search_active,
    filter_by_agent,
  ].forEach((key: string) => {
    if (queryObject[key] || queryObject[key] === 0) {
      searchParams.append(key, queryObject[key]);
    }
    if (filterOptions[key] || filterOptions[key] === 0) {
      searchParams.set(key, filterOptions[key]);
    }
    if (filterOptions[key] === "" || !filterOptions[key]) {
      searchParams.delete(key);
    }
  });

  if (filterOptions["searchValue"]) {
    searchParams.append("searchValue", filterOptions["searchValue"]);
    searchParams.set("searchValue", filterOptions["searchValue"]);
  }
  if (!filterOptions["searchValue"]) {
    searchParams.delete("searchValue");
  }

  if (filterOptions[is_search_active]) {
    searchParams.append(is_search_active, filterOptions[is_search_active]);
  }
  if (filterOptions[size]) {
    searchParams.append("size", filterOptions.size);
    searchParams.set("size", filterOptions.size);
  }
  if (queryObject["name_order"]) {
    searchParams.append("name_order", isStringified(queryObject.name_order));
    searchParams.set("name_order", isStringified(queryObject.name_order));
  }
  if (!queryObject["name_order"]) {
    searchParams.delete("name_order");
  }
  if (queryObject["created_at_order"]) {
    searchParams.append("created_at_order", isStringified(queryObject.created_at_order));
    searchParams.set("created_at_order", isStringified(queryObject.created_at_order));
  }

  if (!queryObject["created_at_order"]) {
    searchParams.delete("created_at_order");
  }
  if (queryObject["number_of_listings_order"]) {
    searchParams.append("number_of_listings_order", isStringified(queryObject.number_of_listings_order));
    searchParams.set("number_of_listings_order", isStringified(queryObject.number_of_listings_order));
  }
  if (!queryObject["number_of_listings_order"]) {
    searchParams.delete("number_of_listings_order");
  }
  if (filterOptions[is_search_active]) {
    searchParams.set(is_search_active, filterOptions[is_search_active]);
  }
  if (filterOptions[is_search_active] && filterOptions[is_search_active] === "All") {
    searchParams.delete(is_search_active);
  }
  if (queryObject[pets_allowed]) {
    searchParams.append(pets_allowed, JSON.stringify(queryObject[pets_allowed]));
  }
  if (filterOptions[pets_allowed]) {
    searchParams.set(pets_allowed, JSON.stringify(filterOptions[pets_allowed]));
  }
  if (queryObject[locations]) {
    searchParams.append(locations, JSON.stringify(queryObject[locations]));
  }
  if (filterOptions[locations]) {
    searchParams.set(locations, JSON.stringify(filterOptions[locations]));
  }
  if (filterOptions[locations] && filterOptions[locations].length === 0) {
    searchParams.delete(locations);
  }
  if (queryObject[tags]) {
    searchParams.append(tags, JSON.stringify(queryObject[tags]));
  }
  if (filterOptions[tags]) {
    searchParams.set(tags, JSON.stringify(filterOptions[tags]));
  }
  if (filterOptions[tags] && filterOptions[tags].length === 0) {
    searchParams.delete(tags);
  }
  if (filterOptions[pets_allowed] && filterOptions[pets_allowed].length === 0) {
    searchParams.delete(pets_allowed);
  }
  if (filterOptions[created_at_order]) {
    searchParams.set(created_at_order, JSON.stringify(filterOptions[created_at_order]));
  }

  if (filterOptions[asked_rent_order]) {
    searchParams.set(asked_rent_order, JSON.stringify(filterOptions[asked_rent_order]));
  }

  if (filterOptions[bedrooms_order]) {
    searchParams.set(bedrooms_order, JSON.stringify(filterOptions[bedrooms_order]));
  }
  if (filterOptions[ALGOLIA_SORT_FIELD]) {
    searchParams.set(ALGOLIA_SORT_FIELD, filterOptions[ALGOLIA_SORT_FIELD]);
  }

  return `?${searchParams.toString()}`;
};
