import React from "react";
import { Tooltip } from "antd";
import { InfoIcon } from "../../icons";
import "./styles.scss";
//Must Fix Code Duplication
const BaseSwitchButton = ({
  onChange,
  checked,
  required,
  label,
  key,
  name,
  disabled,
  classnames,
  dataScrollId,
  dataTestId
}: {
  onChange?: any;
  checked?: boolean;
  required?: boolean;
  label?: string;
  key?: string | number;
  name?: string;
  disabled?: boolean;
  classnames?: any;
  dataScrollId?: string;
  dataTestId?: string
}) => {
  return (
    <>
      <div
        key={key}
        className={`switch-base-btn-section ${classnames?.switchButtonSecion ? classnames?.switchButtonSecion : ""}`}
        data-test-id={"switch-btn-section"}
        data-scroll-id={dataScrollId}
      >
        <div className={classnames?.switcher ? "display-none" : "private-content switch-btn"}>
          <span
            className={"switch-btn-label"}
            data-test-id={"switch-btn-label"}
          >
            {required ? <span className={"text-required"}>*</span> : ""}
            {`${label ? label : ""}`}
          </span>
          {label?.includes("Private") && (
            <Tooltip
              data-test-id={"tooltip"}
              placement="top"
              title={"Only you will see Landlords details"}
            >
              <span>
                <InfoIcon />
              </span>
            </Tooltip>
          )}
        </div>
        <div className={`switch-btn-wrapper ${classnames?.switcher}`} data-test-id={"switch-btn-wrapper"}>
          <span className={`switcher switcher-1 ${disabled && "disabled"}`}>
            <input
              disabled={disabled}
              name={name}
              data-test-id={"checkbox"}
              onChange={onChange}
              checked={!Boolean(checked)}
              type="checkbox"
            />
            <label htmlFor="switcher-1" data-test-id={dataTestId ?? "switcher-1"} />
          </span>
        </div>
      </div>
    </>
  );
};

export default BaseSwitchButton;
