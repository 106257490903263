import React, {useEffect} from "react";
import FilterFormTitle from "../filter-form-title/FilterFormTitle";
import "./ParkingFilter.scss"
import SwitchButton from "../../../../../ListingView/ListingForm/SwitchButton";
import {useLocation} from "react-router";
import {urlQueryToObject} from "../../../helpers/listingsCreateGetSearchQuery";

interface IParkingFilter {
    form: any,
    hasParking: boolean,
    setHasParking: React.Dispatch<React.SetStateAction<boolean>>,
}

const ParkingFilter = ({form, hasParking, setHasParking}: IParkingFilter) => {
    const { search } = useLocation();
    const _newURLSearchParams = new URLSearchParams(search);
    const queryObject = urlQueryToObject(_newURLSearchParams);

    const handleChange = (e: {
        target: {
            name: any;
            checked: any;
        };
        persist(): void;
    }) => {
        e.persist();
        const {checked} = e.target;
        setHasParking(!checked)
        form.submit()
    };

    useEffect(()=>{
            if(queryObject.parking_type){
                setHasParking(queryObject.parking_type)
                form.submit()
            }
    },[queryObject.parking_type])
    return (
        <div className={"parking-filter-wrapper"}>
            <FilterFormTitle title={"Has Parking"}/>
            <SwitchButton
                classnames={!hasParking && {switchButtonSection:"parking-filter-wrapper-switch"}}
                name={"parking_type"}
                label={""}
                onChange={handleChange}
                checked={hasParking}
            />
        </div>
    )
}

export default ParkingFilter
