import React, { useEffect, useState } from "react";
import "./SnoozeOptions.scss";
import Calendar from "../icons/Calendar";
import SnoozeCalendar from "./SnoozeCalendar";
import { useDispatch } from "react-redux";
import { snoozeFollowUp } from "../../../../../../store/followUps/action";
import { snoozeDashboardFollowUp } from "../../../../../../store/dashboard/action";
import { getBrowser } from "../../../../../../helpers/globalFunctions";
import {
  addTimeAndFormatDate,
  currentDate,
  formatDate,
} from "../../../../../../helpers/dateHelper";
import { ISO_STRING, momentSet8Am } from "helpers/constants";
interface ISnoozeOptions {
  followUp: any;
  setIsSnoozePickerVisible: any;
  isSnoozePickerVisible: boolean;
  isFromDashboard?: boolean | null;
  setSnoozeVisible?: any;
}
const SnoozeOptions = ({
  followUp,
  setIsSnoozePickerVisible,
  isSnoozePickerVisible,
  isFromDashboard,
  setSnoozeVisible,
}: ISnoozeOptions) => {
  const dispatch = useDispatch();
  const [snoozeCalendarIsVisible, setSnoozeCalendarIsVisible] = useState(false);
  const dateArray = [
    {
      title: "1 Day",
      date: addTimeAndFormatDate(
        currentDate(),
        { amount: 1, unit: "days" },
        momentSet8Am,
        getBrowser()
      ),
    },
    {
      title: "7 Days",
      date: addTimeAndFormatDate(
        currentDate(),
        { amount: 7, unit: "days" },
        momentSet8Am,
        getBrowser()
      ),
    },
    {
      title: "30 Days",
      date: addTimeAndFormatDate(
        currentDate(),
        { amount: 30, unit: "days" },
        momentSet8Am,
        getBrowser()
      ),
    },
  ];

  useEffect(() => {
    setIsSnoozePickerVisible(snoozeCalendarIsVisible);
  }, [snoozeCalendarIsVisible]);

  useEffect(() => {
    if (!isSnoozePickerVisible) {
      setSnoozeCalendarIsVisible(false);
    }
  }, [isSnoozePickerVisible]);

  return (
    <>
      {isFromDashboard ? (
        <p
          className={"dashboard-snooze-goBack"}
          onClick={() => {
            setSnoozeVisible(false);
          }}
        >
          {"<"} Back
        </p>
      ) : null}
      {snoozeCalendarIsVisible ? (
        <SnoozeCalendar
          setDatePickerValue={setSnoozeCalendarIsVisible}
          followUp={followUp}
          isFromDashboard={isFromDashboard}
        />
      ) : (
        <div className={"followUp-modal-snooze-options"}>
          <p className={"followUp-modal-snooze-options--title"}>
            Snooze for...
          </p>
          <div className={"followUp-modal-snooze-options--body"}>
            {
              //@ts-ignore
              dateArray.map((e: any, i: string) => {
                return (
                  <div
                    className={"followUp-modal-snooze-options--body--item"}
                    key={i}
                    onClick={() => {
                      isFromDashboard
                        ? dispatch(
                            snoozeDashboardFollowUp(
                              {
                                ...followUp,
                                todo_at: new Date(
                                  formatDate(
                                    formatDate(
                                      e.date.set({ hour: 8, minute: 0 }),
                                      getBrowser()
                                    ),
                                    ISO_STRING
                                  )
                                ),
                              },
                              followUp._id
                            )
                          )
                        : dispatch(
                            snoozeFollowUp(
                              {
                                ...followUp,
                                todo_at: new Date(
                                  formatDate(
                                    formatDate(
                                      e.date.set({ hour: 8, minute: 0 }),
                                      getBrowser()
                                    ),
                                    ISO_STRING
                                  )
                                ),
                              },
                              followUp._id
                            )
                          );
                    }}
                  >
                    <p>{e.title}</p>
                    <span>{formatDate(e.date, "MM.DD.YYYY /")} 08:00 AM</span>
                  </div>
                );
              })
            }
            <div
              className={
                "followUp-modal-snooze-options--body--item followUp-modal-snooze-options--body--itemPurple"
              }
              onClick={() => {
                setSnoozeCalendarIsVisible(true);
              }}
            >
              <Calendar />
              <span>Pick date and time</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SnoozeOptions;
