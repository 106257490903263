import React from "react";

export const FollowUpEditIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4997 0C5.14866 0 0 5.14904 0 11.4998C0 17.8506 5.14866 23.0002 11.4997 23.0002C17.8507 23.0002 23 17.851 23 11.4998C23 5.14874 17.8507 0 11.4997 0ZM16.9665 8.27584L15.8742 9.36812L13.6508 7.14484L12.8073 7.98834L15.0308 10.2118L9.57726 15.6648L7.35399 13.4418L6.5105 14.2852L8.73377 16.5085L8.19036 17.0519L8.18001 17.0416C8.11974 17.1408 8.02176 17.2132 7.90538 17.239L5.83207 17.7013C5.80102 17.7083 5.76944 17.7117 5.73823 17.7117C5.62507 17.7117 5.51513 17.6671 5.43363 17.5854C5.32906 17.4812 5.28551 17.3307 5.31771 17.1865L5.77979 15.1138C5.80585 14.9975 5.87838 14.8993 5.97743 14.8392L5.96693 14.8287L14.743 6.05218C14.8717 5.92377 15.0807 5.92377 15.2093 6.05257L16.9667 7.80955C17.0953 7.9382 17.0953 8.14719 16.9665 8.27584Z"
        fill="#5806B9"
      />
    </svg>
  );
};
