import React, { useEffect, useState } from "react";
import { Spin } from "antd";
// import { AnalyticsService } from "helpers/analyticsService";
import RenterInfo from "./Components/RenterInfo";
import GlobalLoading from "../Shared/GlobalLoading";
import PassportInfo from "./Components/PassportInfo";
import { useSelector } from "react-redux";
import { renterPassportSelector } from "store/renterPassport/selectors";

const RentalPortalPassport = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const renterPassport = useSelector(renterPassportSelector);

  useEffect(() => {
    if (renterPassport.passport) {
      setIsLoading(false);
    }
  }, [renterPassport.passport]);

  if (isLoading) {
    return <Spin spinning={isLoading} className={"global-loading"} indicator={<GlobalLoading />} />;
  }

  return (
    <div className="mx-auto max-w-8xl">
      <div className="mx-auto pb-16 grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
          <div className="py-10">
            <h1 className="text-2xl font-semibold text-purple-900">Rental Passport</h1>
          </div>
          <div>{renterPassport?.renter && <RenterInfo renter={renterPassport.renter} />}</div>
          {renterPassport?.passport && (
            <div className="mt-6">
              <PassportInfo passport={renterPassport.passport} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RentalPortalPassport;
