import React, { useEffect, useState } from "react";
import { Input, Modal, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getVerificationCodeLoadingSelector, verifyCodeLoadingSelector } from "../../../store/user/selectors";
import { getPhoneVerificationCode, verifyPhoneNumber } from "../../../store/user/action";
import BaseModal from "../../Shared/BaseModal";
import BaseButton from "../../Shared/BaseButton";
import BaseTooltip from "../../Shared/base-tooltip/BaseTooltip";

const VerifyPhoneModal = ({
  visible,
  onClose,
  phoneCodeWasSent,
  setPhoneCodeWasSent,
}: {
  visible: boolean;
  onClose: () => void;
  phoneCodeWasSent: boolean;
  setPhoneCodeWasSent: any;
}) => {
  const dispatch = useDispatch();
  const verifyCodeLoading = useSelector(verifyCodeLoadingSelector);
  const getVerificationCodeLoading = useSelector(getVerificationCodeLoadingSelector);

  const [verificationCode, setVerificationCode] = useState("");
  useEffect(() => {
    setVerificationCode("");
  }, [visible]);

  return (
    <BaseModal
      isModalVisible={visible}
      modalTitle={"Please verify phone number"}
      classname={"verify-phone-code-modal"}
      modalFooter={
        <div className="flex justify-between">
          <BaseButton onClick={() => onClose()}>Cancel</BaseButton>
          <BaseButton
            variant="primary"
            onClick={() => {
              dispatch(verifyPhoneNumber(verificationCode));
            }}
          >
            Ok
          </BaseButton>
        </div>
      }
      confirmLoading={verifyCodeLoading}
      onCancel={() => {
        onClose();
      }}
    >
      <Typography.Text>Please write the code from your phone number.</Typography.Text>
      <Input
        className={"mt-5"}
        value={verificationCode}
        onChange={({ target: { value } }) => {
          setVerificationCode(value);
        }}
        style={{ marginBottom: "15px" }}
      />
      <Typography.Text>
        If you dont have code you can
        {phoneCodeWasSent ? (
          <BaseTooltip
            text={"Verification code was sent, if you don't receive it within 1 minute please click verify again."}
          >
            <span className="ml-5 mr-25 get-it-text disabled-verify-phone-number">Get it!</span>
          </BaseTooltip>
        ) : (
          <Typography.Link
            onClick={() => {
              dispatch(getPhoneVerificationCode());
              setTimeout(() => {
                setPhoneCodeWasSent(true);
              }, 1500);
            }}
          >
            <span className="ml-5 mr-25 get-it-text">Get it!</span>
            <>{getVerificationCodeLoading && <Spin size={"small"} />}</>
          </Typography.Link>
        )}
      </Typography.Text>
    </BaseModal>
  );
};

export default VerifyPhoneModal;
