import React from "react";
import ListingsSeenAndApplied from "./listings-seen-and-applied/ListingsSeenAndApplied";
import ConsideringBuying from "./considering-buying/ConsideringBuying";
import AvailableForShowing from "./available-for-showing/AvailableForShowing";
import OtherInfo from "./other-info/OtherInfo";
import "./LastStep.scss"

const LastStep = () =>{
    return (
      <div className={"req-last-step"}>
        <ListingsSeenAndApplied />
        <ConsideringBuying />
        <AvailableForShowing />
        <OtherInfo />
      </div>
    );
}

export default LastStep