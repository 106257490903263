import React, { useCallback, useEffect, useState } from "react";
import "../auth/AuthLayout.scss";
import { Spin, Layout } from "antd";
import PageFooter from "../../components/Footer";
import PageSidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { setWindowType } from "../../store/app/action";
import { useWindowSize } from "../../hooks/useWindowSize";
import { windowSelector } from "../../store/app/selectors";
import GlobalLoading from "../../components/Shared/GlobalLoading";
import { listingLoadingSelector } from "../../store/listings/selectors";
import { landlordLoadingSelector } from "../../store/landlords/selectors";
import { followUpsLoadingSelector } from "../../store/followUps/selectors";
import { teamMembersLoadingSelector } from "../../store/teams/selectors";
import { agentsActivitiesLoadingSelector } from "../../store/activities/selectors";
import { authLoadingSelector } from "../../store/user/selectors";
import { leadLoadingSelector } from "../../store/leads/selectors";
import { useLocation } from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import { tabsSteps } from "components/Shared/BaseTourModal/dataWithJsx";
import AuthHeaderMobileOnboard from "../../components/Header/AuthHeaderMobileOnboard/AuthHeaderMobileOnboard";
import "./DummyLayout.scss";
import { dashboardLoadingSelector } from "store/dashboard/selectors";
const bodyScrollLock = require("body-scroll-lock");

const { Content, Footer } = Layout;

export interface AuthLayoutProps {
  children: React.ReactNode;
}

const DummyLayout: React.FC<AuthLayoutProps> = (props: AuthLayoutProps) => {
  const [isFooterCollapsed, setIsFooterCollapsed] = useState(false);
  const leadLoading = useSelector(leadLoadingSelector);
  const authLoading = useSelector(authLoadingSelector);
  const { type, collapsed } = useSelector(windowSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const landlordLoading = useSelector(landlordLoadingSelector);
  const followUpsLoading = useSelector(followUpsLoadingSelector);
  const teamMembersLoading = useSelector(teamMembersLoadingSelector);
  const agentsActivitiesLoading = useSelector(agentsActivitiesLoadingSelector);
  const dashboardLoading = useSelector(dashboardLoadingSelector);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [scrollEnabled, setScrollEnabled] = useState("");
  const { width } = useWindowSize();
  const location = useLocation();
  const isDemoHomePage = location.pathname === "/demo";

  const dispatch = useDispatch();
  const toggleSidebar = useCallback(() => {
    const window = {
      type,
      collapsed: !collapsed,
    };
    localStorage.setItem("sidebarParams", JSON.stringify(window));
    dispatch(setWindowType(window));
  }, [type, collapsed, dispatch]);

  useEffect(() => {
    //@ts-ignore
    if (width <= 1152 && collapsed === false) {
      setScrollEnabled("disabled-scroll");
    } else setScrollEnabled("enable-scroll");
  }, [width, collapsed]);
  const collapseFooter = () => {
    setIsFooterCollapsed((prevState) => !prevState);
  };

  return (
    // @ts-ignore
    <TourProvider
      data-test-id="page-auth-layout"
      steps={tabsSteps}
      onClickMask={({ setIsOpen }) => {
        setIsOpen(false);
      }}
      scrollSmooth
      position="right"
      padding={{ mask: 0, popover: [5, 0] }}
      afterOpen={(target) => bodyScrollLock.disableBodyScroll(target)}
      beforeClose={(target) => {
        bodyScrollLock.enableBodyScroll(target);
        localStorage.setItem("tabs walkthrough", "done");
      }}
      styles={{
        popover: (base) => ({
          ...base,
          width: "150%",
          borderRadius: 6,
        }),
        maskArea: (base: any) => ({ ...base, rx: 6 }),
        badge: (base) => ({
          ...base,
          fontFamily: "Open-sans",
          background: "#5806B9",
          paddingLeft: "0.8125em",
          paddingRight: "0.8125em",
          fontSize: "1em",
          borderRadius: "1.625em",
          boxShadow: "0 0.25em 0.5em rgba(0, 0, 0, 0.3)",
        }),
        dot: (base, args) => ({
          ...base,
          background: args?.current && "#5806B9",
        }),
      }}
      disableInteraction
    >
      <Layout
        data-test-id="page-auth-layout"
        className={`auth-layout-wrapper page-content-layout demo-layout ${scrollEnabled} ${
            !isHeaderVisible &&
            !location.pathname.includes("/manageListing") &&
            "auth-layout-wrapper-onboarding"
        }`}
        hasSider
      >
        <PageSidebar toggleSidebar={toggleSidebar} setVisibility={true} isLoading={true}/>
        {type !== "desktop" && !collapsed && (
          <div onClick={toggleSidebar} className="backdrop-layout" />
        )}
        <Layout
          className={`layout auth-layout-body
          ${
            leadLoading ||
            authLoading ||
            listingLoading ||
            (landlordLoading &&
              (!location.pathname.includes("manageListing") ||
                !location.pathname.includes("listings"))) ||
            followUpsLoading ||
            teamMembersLoading ||
            agentsActivitiesLoading ||
            dashboardLoading
              ? "auth-layout-body-loading"
              : null
          }
        `}
        >
          <Spin
            className={"global-loading"}
            indicator={<GlobalLoading />}
            spinning={
              leadLoading ||
              authLoading ||
              listingLoading ||
              (landlordLoading &&
                !location.pathname.includes("manageListing") &&
                !location.pathname.includes("listings")) ||
              followUpsLoading ||
              teamMembersLoading ||
              agentsActivitiesLoading ||
              dashboardLoading
            }
          >
            {type !== "desktop" ? (
              <AuthHeaderMobileOnboard />
            ) : null}
            <Content
              className={`site-layout-background site-layout-main ${isDemoHomePage?"demo-mobile-back":""} `}
            >
              {props.children}
            </Content>
          </Spin>
          <Footer
            className={`site-layout-footer ${
              isFooterCollapsed ? "collapsed" : ""
            }`}
          >
            <PageFooter
              isFooterCollapsed={isFooterCollapsed}
              collapseFooter={collapseFooter}
            />
          </Footer>
        </Layout>
      </Layout>
    </TourProvider>
  );
};
export default DummyLayout;
