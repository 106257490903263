import React from "react";

export const CalendarIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0C4.6055 0 4.28571 0.319796 4.28571 0.714299V2.14286H5.71426V0.714299C5.71426 0.319796 5.39446 0 5 0Z"
        fill="black"
      />
      <path
        d="M15 0C14.6055 0 14.2857 0.319796 14.2857 0.714299V2.14286H15.7142V0.714299C15.7143 0.319796 15.3945 0 15 0Z"
        fill="black"
      />
      <path
        d="M17.8571 2.14285H15.7143V5.00001C15.7143 5.39451 15.3945 5.71431 15 5.71431C14.6055 5.71431 14.2857 5.39451 14.2857 5.00001V2.14285H5.71427V5.00001C5.71427 5.39451 5.39447 5.71431 4.99997 5.71431C4.60547 5.71431 4.28567 5.39451 4.28567 5.00001V2.14285H2.14286C0.959388 2.14285 0 3.10224 0 4.28571V17.8571C0 19.0406 0.959388 20 2.14286 20H17.8571C19.0406 20 20 19.0406 20 17.8571V4.28571C20 3.10224 19.0406 2.14285 17.8571 2.14285ZM18.5714 17.8571C18.5714 18.2516 18.2516 18.5714 17.8571 18.5714H2.14286C1.74835 18.5714 1.42856 18.2516 1.42856 17.8571V8.57142H18.5714V17.8571Z"
        fill="black"
      />
      <path
        d="M14.791 10.2096C14.5121 9.9305 14.0598 9.93037 13.7807 10.2092L8.5714 15.4185L6.21924 13.0664C5.94012 12.7875 5.48778 12.7877 5.20887 13.0668C4.92997 13.3459 4.93013 13.7982 5.20925 14.0771L8.0664 16.9343C8.34535 17.2131 8.79748 17.2131 9.07639 16.9343L14.7907 11.22C15.0698 10.9411 15.0699 10.4887 14.791 10.2096Z"
        fill="black"
      />
    </svg>
  );
};
