import * as types from "./types";
import { IFollowUpsQuery } from "../../components/FollowUps/types";
import { IObjectKeys } from "../../helpers/globalTypes";
import { IFollowUpsQueryAndPagination } from "./reducer";
import { AuthError } from "../../types/user";
import {
  CLEAR_ALL_FU_MODAL,
  CLEAR_EDITED_FOLLOW_UP,
  GET_FU_BY_ID,
  GET_FU_BY_ID_FAIL,
  GET_FU_BY_ID_SUCCESS,
  SET_EDITED_FOLLOW_UP, SET_SINGLE_FU
} from "./types";

export function getFollowUpsList(getFollowUpsQuery: IFollowUpsQuery) {
  return {
    type: types.GET_FOLLOW_UPS,
    payload: getFollowUpsQuery,
  };
}

export function followUpAddedAndModalClosed() {
  return {
    type: types.ADD_FOLLOW_UP_SUCCESSED_AND_MODAL,
    payload: false,
  };
}

export function getFollowUpsListSuccess(followUps: IObjectKeys) {
  return { type: types.GET_FOLLOW_UPS_SUCCESS, payload: followUps };
}

export function getFollowUpsListFail(error: IObjectKeys) {
  return { type: types.GET_FOLLOW_UPS_FAIL, payload: error };
}

export function clearFollowUpsList() {
  return { type: types.CLEAR_GET_FOLLOW_UPS_LIST };
}
export function setFollowupsLoaderToTrue() {
  return { type: types.SET_FOLLOWUPS_LOADING_TO_TRUE };
}

export const updateFollowUp = (payload: IObjectKeys) => ({
  type: types.UPDATE_FOLLOW_UP,
  payload,
});
export const updateFollowUpSuccess = (payload: IObjectKeys) => ({
  type: types.UPDATE_FOLLOW_UP_SUCCESS,
  payload,
});
export const updateFollowUpFail = (payload: IObjectKeys) => ({
  type: types.UPDATE_FOLLOW_UP_FAIL,
  payload,
});

export const setFollowUpsQueryAndPagination = (
  payload: IFollowUpsQueryAndPagination,
) => ({
  type: types.SET_QUERY_AND_PAGINATION,
  payload,
});

export const addFollowUp = (payload: IObjectKeys) => ({
  type: types.ADD_FOLLOW_UP,
  payload,
});
export const addFollowUpSuccess = (payload: IObjectKeys) => ({
  type: types.ADD_FOLLOW_UP_SUCCESS,
  payload,
});
export const addFollowUpFail = (payload: AuthError) => ({
  type: types.ADD_FOLLOW_UP_FAIL,
  payload,
});

export const editFollowUp = (payload: IObjectKeys, _id: string) => ({
  type: types.EDIT_FOLLOW_UP,
  payload: { payload, _id },
});
export const editFollowUpSuccess = (payload: IObjectKeys) => ({
  type: types.EDIT_FOLLOW_UP_SUCCESS,
  payload,
});
export const editFollowUpSuccessClear = () => ({
  type: types.EDIT_FOLLOW_UP_SUCCESS_CLEAR
});
export const editFollowUpFail = (payload: AuthError) => ({
  type: types.EDIT_FOLLOW_UP_FAIL,
  payload,
});
export const snoozeFollowUp = (payload: IObjectKeys, _id: string) => ({
  type: types.SNOOZE_FOLLOW_UP,
  payload: { payload, _id },
});
export const snoozeFollowUpSuccess = (payload: IObjectKeys) => ({
  type: types.SNOOZE_FOLLOW_UP_SUCCESS,
  payload,
});
export const snoozeFollowUpFail = (payload: AuthError) => ({
  type: types.SNOOZE_FOLLOW_UP_FAIL,
  payload,
});
export const deleteFollowUp = (payload: IObjectKeys) => ({
  type: types.DELETE_FOLLOW_UP,
  payload,
});
export const deleteFollowUpSuccess = (payload: IObjectKeys) => ({
  type: types.DELETE_FOLLOW_UP_SUCCESS,
  payload,
});

export const deleteFollowUpFail = (payload: IObjectKeys) => ({
  type: types.DELETE_FOLLOW_UP_FAIL,
  payload,
});
export const getListingFollowUps = (payload: IObjectKeys) => ({
  type: types.GET_LISTING_FOLLOWUPS,
  payload,
});
export const getListingFollowUpsSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LISTING_FOLLOWUPS_SUCCESS,
  payload,
});
export const getListingFollowUpsFail = (payload: AuthError) => ({
  type: types.GET_LISTING_FOLLOWUPS_FAIL,
  payload,
});
export const getLandlordFollowUps = (payload: IObjectKeys) => ({
  type: types.GET_LANDLORD_FOLLOWUPS,
  payload,
});
export const getLandlordFollowUpsSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LANDLORD_FOLLOWUPS_SUCCESS,
  payload,
});
export const getLandlordFollowUpsFail = (payload: AuthError) => ({
  type: types.GET_LANDLORD_FOLLOWUPS_FAIL,
  payload,
});

export const getLeadFollowUps = (payload: string) => ({
  type: types.GET_LEAD_FOLLOWUPS,
  payload,
});
export const getLeadFollowUpsSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LEAD_FOLLOWUPS_SUCCESS,
  payload,
});
export const getLeadFollowUpsFail = (payload: AuthError) => ({
  type: types.GET_LEAD_FOLLOWUPS_FAIL,
  payload,
});
export const setFollowUpDeleteSucces = (payload: boolean) => ({
  type: types.SET_FOLLOWUP_DELETE_SUCCESS,
  payload,
});
export const setFollowUpEditSucces = (payload: boolean) => ({
  type: types.SET_FOLLOWUP_EDIT_SUCCESS,
  payload,
});
export const setFollowUpSnoozeSucces = (payload: boolean) => ({
  type: types.SET_SNOOZE_EDIT_SUCCESS,
  payload,
});

export const setEditedFollowUp = (payload:any) =>({
  type:SET_EDITED_FOLLOW_UP,
  payload
});

export const getFUById = (payload:any) =>({
  type:GET_FU_BY_ID,
  payload
})

export const getFUByIdSuccess = (payload:any) =>({
  type:GET_FU_BY_ID_SUCCESS,
  payload
})

export const getFUByIdFail = (payload:any) =>({
  type:GET_FU_BY_ID_FAIL,
  payload
})

export const clearEditedFollowUp = () =>({
  type:CLEAR_EDITED_FOLLOW_UP,
  payload:{}
});

export const setSingleFuToBlank = () =>({
  type:SET_SINGLE_FU,
  payload:{}
})

export const clearAllFuModal = (payload:IObjectKeys) =>({
  type:CLEAR_ALL_FU_MODAL,
  payload
})
