import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { confirmResetPasswordSuccessSelector, resetPasswordSuccessSelector } from "store/user/selectors";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import "./syles.scss";
import CodeSend from "./components/reset-password/components/code-send/CodeSend";
import CodeCheck from "./components/reset-password/components/code-check/CodeCheck";

const ResetPasswordForm = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { search } = location;
  const dispatch = useDispatch();
  const resetPasswordSuccess = useSelector(confirmResetPasswordSuccessSelector);
  const getPasswordCodeSuccess = useSelector(resetPasswordSuccessSelector);
  const [email, setEmail] = useState(localStorage.getItem("resetPasswordEmail") ?? null);

  useEffect(() => {
    if (resetPasswordSuccess && location.pathname.includes("/resetPassword")) {
      dispatch(push("/sign-in"));
    } else if (resetPasswordSuccess) {
      form.resetFields();
    }
  }, [resetPasswordSuccess]);

  useEffect(() => {
    if (getPasswordCodeSuccess && search === "" && email) {
      localStorage.setItem("resetPasswordEmail", email);
      dispatch(push({ pathname: "/reset-password", search: `step=1` }));
    }
  }, [getPasswordCodeSuccess]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {search === "" ? <CodeSend setEmail={setEmail} /> : <CodeCheck email={email} />}
    </div>
  );
};
export default ResetPasswordForm;
