import * as types from "./types";

const initialState: any = {
  shareableLandlordEntity: {},
  shareablePropertyEntity: {},
  shareableScreeningEntity: {},
  renterScreeningsNotifications: {},
  shareableRenterEntity: {},
  shareableVerificationExamEntity: {},
  shareableVerificationExamAnswersEntity: {},
  shareableLoading: false,
  renterScreeningsNotificationsFail: false,
  updateShareablePropertyEntitySuccess: false,
  updateShareablePropertyEntityError: false,
  createShareableRenterEntitySuccess: false,
  createShareableRenterEntityError: false,
  createShareableVerificationExamEntitySuccess: false,
  createShareableVerificationExamEntityError: false,
  postShareableVerificationExamAnswersSuccess: false,
  postShareableVerificationExamAnswersError: false,
  validateRenterSuccess: false,
  validateRenterFail: false,
  shareableRenterLinkData: {}
};

export default function shareableReducer(state = initialState, action: { type: string; payload: any; }) {
  switch (action.type) {
    // LANDLORD
    case types.CREATE_SHAREABLE_LANDLORD_ENTITY:
      return {
        ...state,
        shareableLoading: true,
        createShareableLandlordEntitySuccess: false,
        createShareableLandlordEntityError: null
      };
    case types.CREATE_SHAREABLE_LANDLORD_ENTITY_SUCCESS:
      return {
        ...state,
        shareableLandlordEntity: action.payload,
        shareableLoading: false,
        createShareableLandlordEntitySuccess: true
      };
    case types.CREATE_SHAREABLE_LANDLORD_ENTITY_FAIL:
      return {
        ...state,
        createShareableLandlordEntityError: action.payload,
        shareableLoading: false
      };
    case types.GET_SHAREABLE_LANDLORD_ENTITY:
      return {
        ...state,
        shareableLoading: true,
        getShareableLandlordEntitySuccess: false,
        getShareableLandlordEntityError: null
      };
    case types.GET_SHAREABLE_LANDLORD_ENTITY_SUCCESS:
      return {
        ...state,
        shareableLandlordEntity: action.payload,
        shareableLoading: false,
        getShareableLandlordEntitySuccess: true
      };
    case types.GET_SHAREABLE_LANDLORD_ENTITY_FAIL:
      return {
        ...state,
        shareableLandlordEntity: {},
        getShareableLandlordEntitySuccess: false,
        getShareableLandlordEntityError: null,
        shareableLoading: false
      };
    // PROPERTY
    case types.CREATE_SHAREABLE_PROPERTY_ENTITY:
      return {
        ...state,
        shareableLoading: true,
        createShareablePropertyEntitySuccess: false,
        createShareablePropertyEntityError: null
      };
    case types.CREATE_SHAREABLE_PROPERTY_ENTITY_SUCCESS:
      return {
        ...state,
        shareablePropertyEntity: action.payload,
        shareableLoading: false,
        createShareablePropertyEntitySuccess: true,
        createShareablePropertyEntityError: null
      };
    case types.CREATE_SHAREABLE_PROPERTY_ENTITY_FAIL:
      return {
        ...state,
        createShareablePropertyEntitySuccess: false,
        createShareablePropertyEntityError: action.payload,
        shareableLoading: false
      };
    case types.UPDATE_SHAREABLE_PROPERTY_ENTITY:
      return {
        ...state,
        shareableLoading: true,
        updateShareablePropertyEntitySuccess: false,
        updateShareablePropertyEntityError: null
      };
    case types.UPDATE_SHAREABLE_PROPERTY_ENTITY_SUCCESS:
      return {
        ...state,
        shareablePropertyEntity: action.payload,
        shareableLoading: false,
        updateShareablePropertyEntitySuccess: true,
        updateShareablePropertyEntityError: null
      };
    case types.UPDATE_SHAREABLE_PROPERTY_ENTITY_FAIL:
      return {
        ...state,
        updateShareablePropertyEntitySuccess: false,
        updateShareablePropertyEntityError: action.payload,
        shareableLoading: false
      };
    case types.GET_SHAREABLE_PROPERTY_ENTITY:
      return {
        ...state,
        shareableLoading: true,
        getShareablePropertyEntitySuccess: false,
        getShareablePropertyEntityError: null
      };
    case types.GET_SHAREABLE_PROPERTY_ENTITY_SUCCESS:
      return {
        ...state,
        shareablePropertyEntity: action.payload,
        shareableLoading: false,
        getShareablePropertyEntitySuccess: true,
        getShareablePropertyEntityError: null
      };
    case types.GET_SHAREABLE_PROPERTY_ENTITY_FAIL:
      return {
        ...state,
        shareablePropertyEntity: {},
        getShareablePropertyEntitySuccess: false,
        getShareablePropertyEntityError: action.payload,
        shareableLoading: false
      };
    case types.CLEAR_SHAREABLE_PROPERTY_ENTITY:
      return {
        ...state,
        shareablePropertyEntity: {}
      };
    // SCREENING REQUEST
    case types.CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY:
      return {
        ...state,
        shareableScreeningEntity: {},
        shareableLoading: true,
        createScreeningRequestEntitySuccess: false,
        createScreeningRequestEntityError: null
      };
    case types.CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY_SUCCESS:
      return {
        ...state,
        shareableScreeningEntity: action.payload,
        shareableLoading: false,
        createScreeningRequestEntitySuccess: true
      };
    case types.CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY_FAIL:
      return {
        ...state,
        shareableScreeningEntity: {},
        createScreeningRequestEntityError: action.payload,
        shareableLoading: false
      };
    case types.CLEAR_SHAREABLE_SCREENING_ENTITY:
      return {
        ...state,
        shareableScreeningEntity: {}
      };
    // NOTIFICATIONS
    case types.SEND_SCREENING_REQUEST_TO_RENTER:
      return {
        ...state
      };
    case types.SEND_SCREENING_REQUEST_TO_RENTER_SUCCESS:
      return {
        ...state,
        renterScreeningsNotifications: action.payload,
        renterScreeningsNotificationsFail: false
      };
    case types.SEND_SCREENING_REQUEST_TO_RENTER_FAIL:
      return {
        ...state,
        renterScreeningsNotifications: {},
        renterScreeningsNotificationsFail: true
      };
    // RENTER REQUEST
    case types.CREATE_SHAREABLE_RENTER_ENTITY:
      return {
        ...state,
        shareableRenterEntity: {},
        shareableLoading: true,
        createShareableRenterEntitySuccess: false,
        createShareableRenterEntityError: null
      };
    case types.CREATE_SHAREABLE_RENTER_ENTITY_SUCCESS:
      return {
        ...state,
        shareableRenterEntity: action.payload,
        shareableLoading: false,
        createShareableRenterEntitySuccess: true,
        createShareableRenterEntityError: null
      };
    case types.CREATE_SHAREABLE_RENTER_ENTITY_FAIL:
      return {
        ...state,
        shareableRenterEntity: {},
        shareableLoading: false,
        createShareableRenterEntityError: action.payload,
        createShareableRenterEntitySuccess: false
      };
    case types.CLEAR_SHAREABLE_RENTER_ENTITY:
      return {
        ...state,
        shareableRenterEntity: {}
      }
    // VERIFICATION EXAM
    case types.CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY:
      return {
        ...state,
        shareableVerificationExamEntity: {},
        shareableLoading: true,
        createShareableVerificationExamEntitySuccess: false,
        createShareableVerificationExamEntityError: null
      };
    case types.CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY_SUCCESS:
      return {
        ...state,
        shareableVerificationExamEntity: action.payload,
        shareableLoading: false,
        createShareableVerificationExamEntitySuccess: true,
        createShareableVerificationExamEntityError: null
      };
    case types.CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY_FAIL:
      return {
        ...state,
        shareableVerificationExamEntity: {},
        shareableLoading: false,
        createShareableVerificationExamEntityError: action.payload,
        createShareableVerificationExamEntitySuccess: false
      };
    case types.POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS:
      return {
        ...state,
        shareableVerificationExamAnswers: {},
        shareableLoading: true,
        postShareableVerificationExamAnswersSuccess: false,
        postShareableVerificationExamAnswersError: null
      };
    case types.POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS_SUCCESS:
      return {
        ...state,
        shareableVerificationExamAnswers: action.payload,
        shareableLoading: false,
        postShareableVerificationExamAnswersSuccess: true,
        postShareableVerificationExamAnswersError: null
      };
    case types.POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS_FAIL:
      return {
        ...state,
        shareableVerificationExamAnswers: {},
        shareableLoading: false,
        postShareableVerificationExamAnswersError: action.payload,
        postShareableVerificationExamAnswersSuccess: false
      };
    case types.CLEAR_SHAREABLE_VERIFICATION_EXAM_ANSWERS:
      return {
        ...state,
        shareableVerificationExamAnswers: {},
        shareableLoading: false,
        postShareableVerificationExamAnswersError: false,
        postShareableVerificationExamAnswersSuccess: false
      };
    case types.VALIDATE_RENTER_SUCCESS:
      return {
        ...state,
        validateRenterSuccess: action.payload
      };
    case types.VALIDATE_RENTER_FAIL:
      return {
        ...state,
        validateRenterFail: action.payload
      };
    case types.CLEAR_VALIDATE_RENTER:
      return {
        ...state,
        validateRenterSuccess: false
      };
    case types.SAVE_EMAIL_SCREENING_LINK_DATA:
      return {
        ...state,
        shareableRenterLinkData: action.payload
      };
    case types.CLEAR_EMAIL_SCREENING_LINK_DATA:
      return {
        ...state,
        shareableRenterLinkData: {}
      };
    default:
      return state;
  }
}
