import Api from "./api";
import { IObjectKeys } from "../helpers/globalTypes";

export default class TeamsApi {
  static inviteAgent(data: any) {
    const api = new Api("manage-team/invite-agent");
    return api.create({ data });
  }

  static inviteAgentsByJson(data: any) {
    const api = new Api("manage-team/invite-batch-agents");
    return api.create({ data });
  }

  static getTeamMembers(params: IObjectKeys) {
    const api = new Api("manage-team/members");
    return api.get("", { params });
  }

  static resendConfirmationLink(payload: any) {
    const api = new Api(`auth/resendConfirmationLink/${payload._id}`);
    return api.create({});
  }

  static removeAgentFromTeam(payload: any) {
    const api = new Api(`manage-team/remove_agent_from_team/${payload.agent_id}`);
    return api.put("", payload);
  }

  static updateTeamName(payload: any) {
    const api = new Api("manage-team/name");
    return api.put("", payload);
  }
}
