import React from "react";

export const SmallCheckIcon = () => {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0C3.14022 0 0 3.36443 0 7.49996C0 11.6355 3.14022 15 7 15C10.8598 15 14 11.6355 14 7.49996C14 3.36443 10.8598 0 7 0ZM11.0195 6.23315L6.62178 10.945C6.43479 11.1453 6.18622 11.2556 5.9218 11.2556C5.65738 11.2556 5.40882 11.1453 5.22182 10.945L2.98048 8.54357C2.79348 8.34322 2.69049 8.0769 2.69049 7.79359C2.69049 7.51021 2.79348 7.24389 2.98048 7.04354C3.1674 6.8432 3.41596 6.73285 3.68045 6.73285C3.94487 6.73285 4.19351 6.8432 4.38043 7.04362L5.92173 8.69492L9.61942 4.73313C9.80641 4.53278 10.055 4.42251 10.3194 4.42251C10.5838 4.42251 10.8324 4.53278 11.0194 4.73313C11.4055 5.14682 11.4055 5.81962 11.0195 6.23315Z" fill="#5806B9" />
        </svg>

    )
}





