import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import React from "react";

const codeMappings = {
  VerificationPending: "Verification Pending",
  VerificationCompleted: "Verification Completed",
  ReportRequested: "Requested",
  ReportAvailable: "Completed",
  ReportedFailed: "Failed",
  ManualVerification: "Manual Verification",
};

const ScreeningStatusColumn = (rowData: any = {}) => {
  const status =
    // @ts-ignore
    codeMappings[rowData.screening_status] ?? rowData.screening_status;
  const icon = status === "Completed" ? <CheckBadgeIcon className="h-6 w-6 stroke-green-600 fill-none" /> : null;
  return {
    children: (
      <div className="flex flex-nowrap justify-start items-center gap-1">
        {icon && icon}
        {status}
      </div>
    ),
    props: {
      "data-test-id": "screening_status",
    },
  };
};

export default ScreeningStatusColumn;
