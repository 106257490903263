import React, { useEffect } from "react";
import LeadFlowWrapper from "../lead-flow-wrapper";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { confirmLeadPassword, getLeadAccountLink } from "store/leads/action";
import {
  leadAccountLinkSelector,
  leadConfirmPasswordErrorSelector,
  leadConfirmPasswordSuccessSelector,
  leadEmailVerifyCodeSelector,
  leadErrorSelector,
} from "store/leads/selectors";
import { generateErrorMessage, openNotification } from "helpers/globalFunctions";
import { push } from "connected-react-router";
import routes from "routes/routesCode";
import { verifyByEmailLink } from "../../../../../store/user/action";
import { urlQueryToObject } from "../../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { useLocation } from "react-router";
import { verifiedFromEmailSelector } from "../../../../../store/user/selectors";
import PasswordForm from "components/Shared/password-form/PasswordForm";
import { IResetPassword } from "components/ResetPassword/components/reset-password/components/set-password/SetPassword";
import { AnalyticsService } from "helpers/analyticsService";
import { TOKEN_ENUM } from "helpers/authUtils";
interface ILeadEnterPasswordProps {
  setIsExpiredComponent?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeadEnterPassword = ({ setIsExpiredComponent }: ILeadEnterPasswordProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const confirmPasswordSuccess = useSelector(leadConfirmPasswordSuccessSelector);
  const confirmPasswordError = useSelector(leadConfirmPasswordErrorSelector);
  const renterCode = useSelector(leadEmailVerifyCodeSelector);
  const leadError = useSelector(leadErrorSelector);
  const leadAccountLink = useSelector(leadAccountLinkSelector);
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const verified = useSelector(verifiedFromEmailSelector);

  const onSubmit = (data: IResetPassword) => {
    AnalyticsService.reportEvent("set_password", { category: "renter" });
    dispatch(
      confirmLeadPassword({
        newPassword: data.newPassword,
        code: renterCode ?? "",
      })
    );
  };

  useEffect(() => {
    AnalyticsService.reportEvent("email_verified-set_password_page_view", { category: "renter" });
  }, []);

  useEffect(() => {
    if (
      confirmPasswordError &&
      confirmPasswordError?.message === "The confirmation link seemed to be corrupted, Ask administration to resend the link."
    ) {
      return setIsExpiredComponent && setIsExpiredComponent(true);
    }

    if (confirmPasswordError || leadError) {
      openNotification("error", generateErrorMessage(confirmPasswordError || leadError || ""));
      dispatch(push(routes.signIn.path));
    }
    if (confirmPasswordSuccess) {
      dispatch(getLeadAccountLink());
    }
  }, [confirmPasswordError, confirmPasswordSuccess, dispatch, leadError, setIsExpiredComponent]);

  useEffect(() => {
    if (localStorage.getItem(TOKEN_ENUM.ACCESS_TOKEN) && localStorage.getItem(TOKEN_ENUM.REFRESH_TOKEN)) dispatch(getLeadAccountLink());
  }, [dispatch]);
  useEffect(() => {
    if (!verified) {
      dispatch(verifyByEmailLink(queryObject.token));
    }
  }, [verified]);

  useEffect(() => {
    localStorage.setItem("leadAccountLinkObj", JSON.stringify(leadAccountLink));
  }, [leadAccountLink]);

  return (
    <LeadFlowWrapper>
      <div className={"lead-enter-password-wrapper"}>
        <PasswordForm onSubmit={onSubmit} loading={confirmPasswordSuccess} title="Set Your Password" type="lead" />
      </div>
    </LeadFlowWrapper>
  );
};
export default LeadEnterPassword;
