import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Form, Input, InputNumber, Spin, Typography, Row, Select } from "antd";
import { Document } from "@contentful/rich-text-types";
import {
  NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE,
  NOT_ZERO_IN_START,
  REQUIRED_NAME_VALIDATION,
  REQUIRED_WITH_MESSAGE,
} from "../../../../helpers/validations";
import { StepFormComponent } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { enumsSelector } from "../../../../store/enums/selectors";
import {
  getBedroomsOrBathroomsRadioBtnGroup,
  getEnumInputs,
  getQueryParams,
  scrollToFirstErrorField,
  scrollToTheElement,
} from "../../../../helpers/globalFunctions";
import { EnumInterface, IObjectKeys } from "../../../../helpers/globalTypes";
import {
  clearListingUpdateState,
  fillListingData,
  setCurrentSteps,
  updateListing,
  setMustHavesSwitchInputValues as setSwitchInputData,
  setIsCurrentFormChange,
} from "../../../../store/listings/action";
import {
  currentStepSelector,
  isCurrentFormChangedSelector,
  listingDataSelector,
  listingLoadingSelector,
  listingUpdateSuccessSelector,
  mustHavesSwitchInputValuesSelector,
  stepClickedSelector,
} from "../../../../store/listings/selectors";
import PlacesAutocomplete from "../../../Shared/PlacesAutocomplete";
import { AddressChangeParams } from "../../../Shared/PlacesAutocomplete/types";
import { getUnitTypeToolTipContent } from "../../../../contentful/api";
import StepsActionButtons from "../StepsActionButtons";
import SwitchButton from "../SwitchButton";
import { windowSelector } from "../../../../store/app/selectors";
import "../styles.scss";
import { mustHavesStepFields } from "../formSteps";
import { listingFormScrollFields } from "../helpers/listingFormConstants";
import PropertyInformationTooltipModal from "./property-information-modal/PropertyInformationTooltipModal";
import { userSelector } from "store/user/selectors";
import NeighborhoodsApi from "api/Neighborhoods";
import stateToAbbr from "helpers/stateToAbbr";

const { Title } = Typography;

const fillMustHavesForm = ({
  listingData,
  setSwitchInputData,
  setShowFloorNumber,
  dispatch,
  switchInputData,
}: IObjectKeys) => {
  const fieldsToSet: IObjectKeys = {};
  mustHavesStepFields.map((field: string) => {
    if (listingData[field]) {
      if (field === "bathrooms" && Number(listingData[field]) && !Number.isInteger(listingData[field])) {
        fieldsToSet[field] = listingData[field] - 0.5;
        fieldsToSet["additionalBath"] = true;
        dispatch &&
          dispatch(
            setSwitchInputData({
              ...switchInputData,
              ["additionalBath"]: true,
            })
          );
      } else if (field === "bedrooms" && Number(listingData[field]) && !Number.isInteger(listingData[field])) {
        fieldsToSet[field] = listingData[field] - 0.5;
        fieldsToSet["additionalBed"] = true;
        dispatch(
          setSwitchInputData({
            ...switchInputData,
            ["additionalBed"]: true,
          })
        );
      } else if (field === "elevator" && listingData[field]) {
        dispatch(
          setSwitchInputData({
            ...switchInputData,
            ["elevator"]: true,
          })
        );
      } else if (field === "floor" && listingData[field] === "other") {
        fieldsToSet[field] = listingData[field];
        setShowFloorNumber(true);
      } else {
        fieldsToSet[field] = listingData[field];
      }
    }
  });

  return fieldsToSet;
};
const generateSubmitData = (data: IObjectKeys, switchInputData: IObjectKeys) => {
  const formData: IObjectKeys = {
    ...data,
    ...switchInputData,
  };
  if (formData.hasOwnProperty("additionalBath")) {
    formData.bathrooms = formData.additionalBath ? (formData.bathrooms || 0) + 0.5 : formData.bathrooms;
  }

  if (formData.hasOwnProperty("additionalBed")) {
    formData.bedrooms = formData.additionalBed ? (formData.bedrooms || 0) + 0.5 : formData.bedrooms;
  }

  if (formData.floor !== "other") {
    delete formData.floor_number;
  }
  return formData;
};
const PropertyInformation = ({ form }: StepFormComponent) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { lId } = getQueryParams(search);
  const listingData = useSelector(listingDataSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const enums = useSelector(enumsSelector);
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const [richTextDocument, setRichTextDocument] = useState<Document | undefined>(undefined);
  const [showFloorNumber, setShowFloorNumber] = useState(false);
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);
  const [town, setTown] = useState(listingData.town);
  const [shortState, setShortState] = useState(listingData.short_state);
  const [neighborhoodsList, setNeighborhoodsList] = useState([]);
  const stepClicked = useSelector(stepClickedSelector);
  const current = useSelector(currentStepSelector);
  const contentRef = useRef(null);
  const switchInputData = useSelector(mustHavesSwitchInputValuesSelector);
  const { type } = useSelector(windowSelector);
  const isCurrentFormChanged = useSelector(isCurrentFormChangedSelector);
  const scrollFields = listingFormScrollFields.mustHaves;
  const userData = useSelector(userSelector);

  const next = () => {
    dispatch(setCurrentSteps(current + 1));
  };
  const onFinishFailed = () => {
    scrollToFirstErrorField();
  };

  const onSubmit = (data: IObjectKeys) => {
    const formData = generateSubmitData(data, switchInputData);
    dispatch(fillListingData({ ...formData, agent_id: userData.agent_id }));
    if (lId) {
      dispatch(
        updateListing({
          lId,
          ...formData,
          ...{
            street_name: formData.street_name || "",
            street_number: formData.street_number || "",
            unit: formData.unit || "",
          },
          step: "must_haves_step",
        })
      );
    } else {
      next && next();
    }
  };
  // for setting form initial data
  const onAddressChange = async (address: AddressChangeParams) => {
    if (!isCurrentFormChanged) dispatch(setIsCurrentFormChange(true));

    form.resetFields(["unit"]);
    if (!address.hasOwnProperty("seperated_fields")) {
      form.setFieldsValue({
        state: "",
        town: "",
        neighbourhood: "",
        sub_area: "",
        sub_sub_area: "",
        street_name: "",
        street_number: "",
        zip_code: "",
        addr: address.formatted_address,
        lng: "",
        lat: "",
      });
    }
    if (address?.separated_fields?.short_state || address?.separated_fields?.town) {
      setTown(address?.separated_fields?.town);
      setShortState(address?.separated_fields?.short_state);
      const subAreaColRef = document.querySelector('[data-scroll-id="sub_area_auto_complete"]');
      try {
        const results: any = await NeighborhoodsApi.getNeighborhoodsList({
          city: address?.separated_fields?.town,
          state: address?.separated_fields?.short_state,
        });
        setNeighborhoodsList(results);
      } catch (error) {
        console.error("Error fetching neighborhoods:", error);
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      subAreaColRef?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    form.setFieldsValue({
      ...address.separated_fields,
      zip_code: address.separated_fields?.postal_code,
      addr: address.formatted_address,
      lng: address.location?.lng,
      lat: address.location?.lat,
    });
  };

  useEffect(() => {
    (async () => {
      const entries: IObjectKeys = await getUnitTypeToolTipContent();
      if (entries) {
        setRichTextDocument(entries.fields.helpText);
      }
    })();
    if (contentRef && contentRef.current) {
      //@ts-ignore
      contentRef.current.scrollIntoView();
    }
    dispatch(setIsCurrentFormChange(false));
  }, []);

  useEffect(() => {
    const fieldsToSet = fillMustHavesForm({
      listingData,
      switchInputData,
      setSwitchInputData,
      setShowFloorNumber,
      dispatch,
    });

    form.setFieldsValue(fieldsToSet);
  }, [lId, listingData]);

  useEffect(() => {
    const shortStateName = stateToAbbr(listingData.state);
    const town = listingData?.town;
    if (town && shortStateName) {
      setTown(town);
      setShortState(shortStateName);
    }
  }, [listingData]);

  useEffect(() => {
    if (town && shortState) {
      try {
        NeighborhoodsApi.getNeighborhoodsList({
          city: town,
          state: shortState,
        }).then((results: any) => {
          console.log("results", results);

          setNeighborhoodsList(results);
        });
      } catch (error) {
        console.error("Error fetching neighborhoods:", error);
      }
    }
  }, [town, shortState]);

  useEffect(() => {
    if (listingData.sub_area) {
      form.setFieldsValue({
        sub_area: listingData.sub_area,
      });
    }
    if (listingData.sub_sub_area) {
      form.setFieldsValue({
        sub_sub_area: listingData.sub_sub_area,
      });
    }
  }, [form, listingData]);

  // this useEffect we need to go to next step after successful update or save step
  useEffect(() => {
    if (!stepClicked && updateListingSuccess) {
      next && next();
    }
    return () => {
      dispatch(clearListingUpdateState());
    };
  }, [updateListingSuccess]);

  const handleChange = (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => {
    e.persist();
    const { checked, name } = e.target;
    dispatch(
      setSwitchInputData({
        ...switchInputData,
        [e.target.name]: !checked,
      })
    );
  };

  const prevClicked = () => {
    const data = form.getFieldsValue();
    const formData = generateSubmitData(data, switchInputData);
    dispatch(fillListingData(formData));
  };

  return (
    <div ref={contentRef} className="property-information">
      <Spin tip="Submitting..." spinning={listingLoading}>
        <Title level={4}>Property Information</Title>
        <Form
          className="must-haves-form"
          data-test-id={"must-haves-form"}
          form={form}
          onFinish={onSubmit}
          onFinishFailed={onFinishFailed}
          onFieldsChange={(changedField) => {
            //@ts-ignore
            (() => {
              //Scroll To Next Input
              // @ts-ignore
              // eslint-disable-next-line no-restricted-globals
              const changedFieldName: any = changedField[0]?.name[0];
              //@ts-ignore
              const element = document.querySelector(`[data-scroll-id="${changedFieldName}"]`);
              if (element && scrollFields.includes(changedFieldName)) {
                //@ts-ignore
                scrollToTheElement(element, 500, "start");
              }
            })();

            if (!isCurrentFormChanged) dispatch(setIsCurrentFormChange(true));
            // eslint-disable-next-line no-restricted-globals
            if (Array.isArray(changedField[0]?.name)) {
              // eslint-disable-next-line no-restricted-globals
              if (changedField[0]?.name?.indexOf("floor") > -1) {
                setShowFloorNumber(changedField[0]?.value === "other");
              }
            }
          }}
          labelCol={{ span: 24 }}
        >
          <Form.Item name="addr" label="Address" rules={[REQUIRED_NAME_VALIDATION]}>
            <PlacesAutocomplete
              defaultAddress={listingData.addr}
              onAddressChange={onAddressChange}
              resetPlacesAutocomplete={resetPlacesAutocomplete}
              setResetPlacesAutocomplete={setResetPlacesAutocomplete}
            />
          </Form.Item>
          {type !== "mobile" && (
            <Form.Item name="separated_address" data-test-id={"separated_address"}>
              <Row>
                <Col span={12} className={"street-details"} data-test-id={"street-details_1"}>
                  <Form.Item
                    data-test-id={"street_number"}
                    name="street_number"
                    label="Street Number"
                    labelCol={{ span: 24 }}
                  >
                    <Input readOnly data-test-id={"street_number_input"} placeholder={"Select Street Number"} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    data-test-id={"street_name"}
                    name="street_name"
                    label="Street Name"
                    labelCol={{ span: 24 }}
                  >
                    <Input readOnly data-test-id={"street_name_input"} placeholder={"Select Street Name"} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          )}
          <Form.Item data-test-id={"unit"} name="unit" label="Unit" data-scroll-id="unit">
            <Input autoComplete={"off"} min={0} placeholder="Enter Unit" data-test-id={"unit_input"} />
          </Form.Item>
          {type !== "mobile" ? (
            <>
              <Form.Item>
                <Row>
                  <Col
                    span={12}
                    className={"street-details"}
                    data-test-id={"street-details_2"}
                    data-scroll-id="sub_area_auto_complete"
                  >
                    <Form.Item
                      name="town"
                      label="City"
                      labelCol={{ span: 24 }}
                      data-text-id={"town"}
                      rules={[{ required: true, message: "Town is required!" }]}
                    >
                      <Select
                        data-text-id={"town_select"}
                        showSearch
                        showArrow={false}
                        filterOption={false}
                        placeholder={"Select Town"}
                      ></Select>
                    </Form.Item>
                    <Form.Item
                      name="neighbourhood"
                      label="Neighborhood"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: false,
                          message: "Please select a neighborhood!",
                        },
                      ]}
                      className="neighbourhood"
                    >
                      <Select
                        placeholder={
                          neighborhoodsList.length > 0 ? "Select Neighborhood" : "Neighborhoods not available"
                        }
                        disabled={neighborhoodsList.length === 0}
                      >
                        {neighborhoodsList.map((neighborhood: { label: string }) => (
                          <Select.Option key={neighborhood.label} value={neighborhood.label}>
                            {neighborhood.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="state" label="State" labelCol={{ span: 24 }} data-test-id={"state"}>
                      <Input readOnly data-test-id={"state_input"} placeholder={"Select State"} />
                    </Form.Item>

                    <Form.Item label="Postal code" name="zip_code" labelCol={{ span: 24 }}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Form.Item
                  label="Postal Code"
                  name="postalCode"
                  style={itemStyle}
                  rules={[SHAREABLE_POSTAL_CODE_VALIDATION]}
                >
                  <Input placeholder={"12345"} defaultValue={listing.postalCode} />
                </Form.Item> */}
              </Form.Item>
              <Form.Item name="lng" noStyle>
                <Input type={"hidden"} />
              </Form.Item>
              <Form.Item name="lat" noStyle>
                <Input type={"hidden"} />
              </Form.Item>
            </>
          ) : (
            <Form.Item data-test-id={"neighbourhood"} name="neighbourhood" label="Neighborhood">
              <Input autoComplete={"off"} min={0} placeholder="Neighborhood" data-test-id={"neighbourhood_input"} />
            </Form.Item>
          )}

          {getBedroomsOrBathroomsRadioBtnGroup(
            "bedrooms",
            "Bedrooms",
            [REQUIRED_WITH_MESSAGE("Field ")],
            "purple-radiobutton-group bedrooms-radio-buttons",
            "must-haves-bedrooms-radio-buttons",
            "bedrooms"
          )}
          <Form.Item
            name="additionalBed"
            className="purple-radiobutton-group has-additional-half-bathroom-switch-and-text"
            data-test-id={"additional-bed"}
            data-scroll-id="additionalBed"
          >
            <SwitchButton
              name="additionalBed"
              onChange={handleChange}
              checked={switchInputData["additionalBed"]}
              label={"Has Additional Half Bedroom"}
            />
          </Form.Item>
          {getBedroomsOrBathroomsRadioBtnGroup(
            "bathrooms",
            "Bathrooms",
            [REQUIRED_WITH_MESSAGE("Field ")],
            "purple-radiobutton-group_bathrooms bathroom-radio-buttons",
            "must-haves-bathroom-radio-buttons",
            "bathrooms"
          )}
          <Form.Item
            name="additionalBath"
            className="purple-radiobutton-group has-additional-half-bathroom-switch-and-text"
            data-test-id={"additionalBath"}
            data-scroll-id="additionalBath"
          >
            <SwitchButton
              name="additionalBath"
              onChange={handleChange}
              checked={switchInputData["additionalBath"]}
              label={"Has Additional Half Bathroom"}
            />
          </Form.Item>
          <Form.Item
            name="living_area_size"
            data-test-id={"living_area_size"}
            label={"Living Area Size"}
            rules={[NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE, NOT_ZERO_IN_START]}
          >
            <Input
              autoComplete={"off"}
              placeholder="Enter square feet"
              data-test-id={"living_area_size_input"}
              type={"number"}
              min={1}
            />
          </Form.Item>
          <Form.Item>
            <Col className={"must-haves-unit-type-wrapper"}>
              {getEnumInputs(
                enums.find((item: EnumInterface) => item.key === "unit_type"),
                "inline",
                "unit-type-enums-radio-btn",
                "unit_type"
              )}
              {type !== "mobile" && <PropertyInformationTooltipModal richText={richTextDocument} />}
            </Col>
          </Form.Item>
          <Form.Item>
            {getEnumInputs(
              enums.find((item: EnumInterface) => item.key === "floor"),
              "inline",
              "unit-type-enums-radio-btn"
            )}
            {showFloorNumber && (
              <Form.Item name="floor_number" data-test-id={"floor_number"}>
                <InputNumber
                  autoComplete={"off"}
                  placeholder="Enter Number"
                  min={0}
                  data-test-id={"floor_number_input"}
                  type={"number"}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item name="elevator" className="purple-radiobutton-group" data-test-id={"elevator"}>
            <SwitchButton
              name="elevator"
              onChange={handleChange}
              checked={switchInputData["elevator"]}
              label={"Elevator Building"}
            />
          </Form.Item>
        </Form>
      </Spin>
      <StepsActionButtons form={form} prev={prevClicked} />
    </div>
  );
};

export default PropertyInformation;
