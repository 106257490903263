import React, { useEffect, useRef, useState } from "react";
import { Form, Typography } from "antd";
import { StepFormComponent } from "./types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListingUpdateState,
  fillListingData,
  setCurrentSteps,
  setIsCurrentFormChange,
  updateListing,
} from "../../../store/listings/action";
import {
  getChipCheckbox,
  getEnumInputs,
  getQueryParams,
  makeMultiEnumOption,
  parseMultiEnumOptions,
  scrollToFirstErrorField,
  scrollToTheElement,
} from "../../../helpers/globalFunctions";
import { EnumInterface, IObjectKeys } from "../../../helpers/globalTypes";
import { enumsSelector } from "../../../store/enums/selectors";
import {
  currentStepSelector,
  isCurrentFormChangedSelector,
  listingDataSelector,
  listingUpdateSuccessSelector,
  stepClickedSelector,
} from "../../../store/listings/selectors";
import { useLocation } from "react-router-dom";
import SwitchButton from "./SwitchButton";
import StepsActionButtons from "./StepsActionButtons";
import { listingFormScrollFields } from "./helpers/listingFormConstants";
import { filterAddListingData } from "./helpers/helpers";
const { Title } = Typography;

const AdditionalDetails = ({ form }: StepFormComponent) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { lId } = getQueryParams(search);
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const current = useSelector(currentStepSelector);
  const contentRef = useRef(null);
  const [switchInputData, setSwitchInputData] = useState<IObjectKeys>({});
  const isCurrentFormChanged = useSelector(isCurrentFormChangedSelector);
  const next = () => {
    dispatch(setCurrentSteps(current + 1));
  };
  const onFinishFailed = () => {
    scrollToFirstErrorField();
  };
  const enums = useSelector(enumsSelector);
  const listingData = useSelector(listingDataSelector);
  const [showStorageEnum, setShowStorageEnum] = useState(false);
  const stepClicked = useSelector(stepClickedSelector);
  const scrollFields = listingFormScrollFields.extraDetails;
  const [showPoolEnum, setShowPoolEnum] = useState(false);

  const onSubmit = (data: IObjectKeys) => {
    let formData = { ...data, ...switchInputData };
    formData = filterAddListingData(formData);
    dispatch(fillListingData(formData));
    if (lId) {
      return dispatch(updateListing({ lId, ...formData, step: "extra_details_step" }));
    }
  };

  useEffect(() => {
    dispatch(setIsCurrentFormChange(false));
  }, [dispatch]);

  useEffect(() => {
    const storageExists = listingData?.hasAdditionalStorage || listingData.storage;
    if (storageExists) {
      setShowStorageEnum(storageExists);
    }
    const poolExists = listingData?.hasPool || listingData.pool_type;
    if (poolExists) {
      setShowPoolEnum(poolExists);
    }
    if (lId) {
      const fieldsToSet: IObjectKeys = {};
      ["flooring", "heating", "amenities", "storage", "students_accepted", "fireplace", "stove_type", "pool_type"].forEach(
        (field: string) => {
          if (field === "amenities" && listingData.amenities) {
            fieldsToSet[field] = parseMultiEnumOptions(listingData[field]);
          } else if (field === "storage" && listingData.storage && Object.keys(listingData.storage).length) {
            fieldsToSet[field] = parseMultiEnumOptions(listingData[field]);
            setSwitchInputData((state) => ({
              hasAdditionalStorage: true,
              ...state,
            }));
          } else if (field === "fireplace" && listingData.fireplace) {
            fieldsToSet["fireplace"] = listingData.fireplace;
            setSwitchInputData((state) => ({
              hasFirePlace: true,
              ...state,
            }));
          } else if (field === "stove_type" && listingData.stove_type) {
            fieldsToSet[field] = parseMultiEnumOptions(listingData[field]);
            setSwitchInputData((state) => ({
              hasStoveType: true,
              ...state,
            }));
          } else if (field === "heating" && listingData.heating) {
            fieldsToSet["heating"] = listingData.heating;
          } else if (field === "heating" && listingData.heating) {
            fieldsToSet["heating"] = listingData.heating;
          } else if (field === "flooring" && listingData.flooring) {
            fieldsToSet["flooring"] = listingData.flooring;
          } else if (field === "students_accepted") {
            if (
              !listingData.hasOwnProperty("students_accepted") ||
              !listingData?.students_accepted ||
              (listingData?.students_accepted && listingData?.students_accepted === false) ||
              (listingData?.students_accepted &&
                typeof listingData?.students_accepted !== "boolean" &&
                listingData?.students_accepted?.toLowerCase() === "false")
            ) {
              fieldsToSet["students_accepted"] = "false";
              setSwitchInputData((state) => ({
                ...state,
                students_accepted: false,
              }));
            } else {
              fieldsToSet["students_accepted"] = "true";

              setSwitchInputData((state) => ({
                ...state,
                students_accepted: true,
              }));
            }
          } else if (field === "pool_type" && listingData.pool_type) {
            fieldsToSet[field] = parseMultiEnumOptions(listingData[field]);
            setSwitchInputData((state) => ({
              hasPool: true,
              ...state,
            }));
          } 
        }
      );
      form.setFieldsValue(fieldsToSet);
    }
  }, [form, lId, listingData]);

  useEffect(() => {
    if (!stepClicked && updateListingSuccess) {
      next && next();
    }
    return () => {
      dispatch(clearListingUpdateState());
    };
  }, [dispatch, next, stepClicked, updateListingSuccess]);

  const handleChange = (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => {
    e.persist();

    const { checked } = e.target;
    setSwitchInputData((state) => ({
      ...state,
      [e.target.name]: !checked,
    }));
    if (e.target.name === "hasAdditionalStorage" && checked) {
      dispatch(
        fillListingData({
          storage: {},
        })
      );
    }
    // if (e.target.name === "students_accepted") {
    dispatch(
      fillListingData({
        [e.target.name]: !checked,
      })
    );
    // }
  };
  useEffect(() => {
    if (contentRef && contentRef.current) {
      //@ts-ignore
      contentRef.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (listingData.storage && typeof listingData.storage === "boolean") {
      setSwitchInputData((state) => ({
        hasAdditionalStorage: true,
        ...state,
      }));
    }
  }, [listingData]);

  return (
    <div ref={contentRef} className={"extra-from"}>
      <Title level={4}>Additional Details</Title>
      <Form
        className="landlord-core-details-form"
        form={form}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        onFieldsChange={(changedFieldInfo) => {
          (() => {
            //Scroll To Next Input
            // @ts-ignore
            const changedFieldName: any = changedFieldInfo[0]?.name[0];
            //@ts-ignore
            const element = document.querySelector(`[data-scroll-id="${changedFieldName}"]`);
            if (element && scrollFields.includes(changedFieldName)) {
              //@ts-ignore
              scrollToTheElement(element, 500, "start");
            }
          })();

          if (!isCurrentFormChanged) dispatch(setIsCurrentFormChange(true));
          if (
            Array.isArray(changedFieldInfo[0]?.name) &&
            changedFieldInfo[0]?.name?.indexOf("hasAdditionalStorage") > -1
          ) {
            setShowStorageEnum(changedFieldInfo[0]?.value);
          }
          if (
            Array.isArray(changedFieldInfo[0]?.name) &&
            changedFieldInfo[0]?.name?.indexOf("hasPool") > -1
          ) {
            setShowPoolEnum(changedFieldInfo[0]?.value);
          }
        }}
      >
        {getEnumInputs(
          enums.find((item: EnumInterface) => item.key === "flooring"),
          "inline",
          "extra-details-enums-radio-group column-radio-buttons",
          "flooring"
        )}
        {getEnumInputs(
          enums.find((item: EnumInterface) => item.key === "heating"),
          "inline",
          "extra-details-enums-radio-group column-radio-buttons",
          "heating"
        )}
        {enums?.length
          ? getChipCheckbox(
              enums.find((item: EnumInterface) => item.key === "amenities"),
              "inline",
              "extra-details-enums-radio-group",
              "amenities"
            )
          : ""}
        {enums?.length
          ? getChipCheckbox(
              enums.find((item: EnumInterface) => item.key === "stove_type"),
              "inline",
              "laundry_enums_radio_group",
              "stove_type"
            )
          : ""}
        <div className={"border-checkbox"}>
          <Form.Item name="hasPool" data-test-id={"hasPool-form-item"}>
            <SwitchButton
              name="hasPool"
              onChange={handleChange}
              checked={switchInputData["hasPool"]}
              label={"Pool"}
              dataScrollId={"hasPool"}
            />
          </Form.Item>
          {showPoolEnum && enums?.length && switchInputData["hasPool"]
            && getChipCheckbox(
              enums.find((item: EnumInterface) => item.key === "pool_type"),
              "column",
              "laundry_enums_radio_group",
              "pool_type"
            )}
        </div>
        <div className={"border-checkbox"}>
          <Form.Item name="hasFirePlace" data-test-id={"hasFirePlace-form-item"}>
            <SwitchButton
              name="hasFirePlace"
              onChange={handleChange}
              checked={switchInputData["hasFirePlace"]}
              label={"Fireplace"}
              dataScrollId={"hasFirePlace"}
            />
          </Form.Item>
          {enums?.length && switchInputData["hasFirePlace"]
            ? getEnumInputs(
                enums.find((item: EnumInterface) => item.key === "fireplace"),
                "inline",
                "laundry_enums_radio_group",
                "fireplace"
              )
            : ""}
        </div>
        <div className={"border-checkbox"}>
          <Form.Item name="hasAdditionalStorage" data-test-id={"hasAdditionalStorage-form-item"}>
            <SwitchButton
              name="hasAdditionalStorage"
              onChange={handleChange}
              checked={switchInputData["hasAdditionalStorage"]}
              label={"Additional Storage"}
              dataScrollId={"hasAdditionalStorage"}
            />
          </Form.Item>
          {showStorageEnum && enums?.length && switchInputData["hasAdditionalStorage"]
            ? getChipCheckbox(
                enums.find((item: EnumInterface) => item.key === "storage"),
                "column",
                "chip-enums-radio-btn",
                "storage"
              )
            : ""}
        </div>

        <div className={"border-checkbox"}>
          <Form.Item name="students_accepted" data-test-id={"students_accepted-form-item"}>
            <SwitchButton
              name="students_accepted"
              onChange={handleChange}
              checked={switchInputData["students_accepted"]}
              label={"Students Accepted?"}
            />
          </Form.Item>
        </div>
      </Form>
      <StepsActionButtons form={form} />
    </div>
  );
};

export default AdditionalDetails;
