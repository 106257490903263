import React from "react";
import BaseModal from "../BaseModal";
import "./AfterCallModal.scss";
import NotificationIcon from "./icons/NotificationIcon";
import NoteCreateIcon from "./icons/NoteCreateIcon";
import { useWindowSize } from "../../../hooks/useWindowSize";

interface IAfterCallModal {
  visible: boolean;
  onClose: () => void;
  setFollowUpModalVisible: any;
  setContactModalVisible: any;
  setNoteModalVisible: any;
  setPremiumModalVisible: any;
}
const AfterCallModal = ({
  visible,
  onClose,
  setFollowUpModalVisible,
  setContactModalVisible,
  setNoteModalVisible,
  setPremiumModalVisible,
}: IAfterCallModal) => {
  const { width } = useWindowSize();

  return (
    <BaseModal
      classname={"after-call-modal"}
      isModalVisible={visible}
      dataTestId="add-team-modal"
      modalTitle={""}
      onCancel={() => {
        onClose();
      }}
      modalFooter={[null]}
      width={
        //@ts-ignore
        width < 345 ? 310 : width < 599 ? 330 : 415
      }
    >
      <p className={"after-call-modal--title"}>Good interaction?</p>
      <p className={"after-call-modal--bottomText"}>Add a follow-up or note to track important details.</p>
      <div className={"after-call-modal--items"}>
        <div
          onClick={() => {
              setFollowUpModalVisible({ visible: true, editModal: false });
              setContactModalVisible(false);
          }}
        >
          <NotificationIcon />
          <p>Add a follow-up</p>
        </div>
        <div
          onClick={() => {
            setNoteModalVisible({ visible: true, editModal: false });
            setContactModalVisible(false);
          }}
        >
          <NoteCreateIcon />
          <p>Add a note</p>
        </div>
      </div>
    </BaseModal>
  );
};

export default AfterCallModal;
