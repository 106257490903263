import React, { useState } from "react";
import BaseButton from "../../../../../Shared/BaseButton";
import { useDispatch, useSelector } from "react-redux";
import { windowSelector } from "../../../../../../store/app/selectors";
import {
  resetSingleLandlordData,
  setCurrentLandlord
} from "../../../../../../store/landlords/action";
import { useForm } from "antd/es/form/Form";
import { getLandlordsXlsxErrorsSelector, singleLandlordDataSelector } from "../../../../../../store/landlords/selectors";
import AddLandlordModal from "./add-landlord-modal/AddLanldordModal";
import LockIcon from "components/Leads/icons/LockIcon";
import PremiumUpgradeModal from "components/Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { usersFreeSubscriptionLimits } from "helpers/globalFunctions";
import { userSelector } from "store/user/selectors";
import { useAppContext } from "libs/contextLib";

const AuthHeaderLandlordActions = () => {
  const { type } = useSelector(windowSelector);
  const [visible, setVisible] = useState(false);
  const [importLandlordsVisibility, setImportLandlordsVisibility] = useState(
    false
  );
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const currentLandlord = useSelector(singleLandlordDataSelector);
  const landlordsXlsxErrors = useSelector(getLandlordsXlsxErrorsSelector);
  const userData = useSelector(userSelector);
  const { isNotPremiumUser } = useAppContext();
  const [form] = useForm();
  const dispatch = useDispatch();
  const usersFreeSubscriptionLimit: any = usersFreeSubscriptionLimits({ userData, isNotPremiumUser });

  const addLandlordHandler = () => {
    if (usersFreeSubscriptionLimit?.landlords) {
      setPremiumModalVisible(true);
    } else {
      setVisible(true);
      form.resetFields();
      dispatch(resetSingleLandlordData());
      dispatch(setCurrentLandlord({}));
    };
  };
  return (
    <div className={"header-part-right-landlordActions"}>
      <BaseButton
        dataTestId="add-landlord"
        classnames={"rb-primary-btn md add-landlord-btn"}
        // icon={<PlusOutlined />}
        onClick={addLandlordHandler}
        modalType="landlord"
      >
        <span className={usersFreeSubscriptionLimit?.landlords ? "btn-add-landlord-locked" : ''}>
          {usersFreeSubscriptionLimit?.landlords && <LockIcon />}
          Add {type !== "mobile" ? "Landlord" : ""}
        </span>
      </BaseButton>
      <AddLandlordModal
        currentLandlord={currentLandlord}
        visible={visible}
        form={form}
        setVisible={setVisible}
      />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_TEAM_MEMBER}
      />
    </div>
  );
};

export default AuthHeaderLandlordActions;
