import React from "react";
import "./BillingConfirmationModal.scss";
import BaseModal from "../../../../Shared/BaseModal";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { cancelSubscriptionLoadingSelector, updatedSubscriptionLoadingSelector } from "../../../../../store/payment/selectors";
import { AnalyticsService } from "helpers/analyticsService";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;



interface IBillingConfirmationModal {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onRenewClick: () => void;
  type: string;
}
const BillingConfirmationModal = ({
  visible,
  setVisible,
  onRenewClick,
  type,
}: IBillingConfirmationModal) => {
  const updateSubscriptionLoading = useSelector(
    updatedSubscriptionLoadingSelector
  );
  const cancelSubscriptionLoading = useSelector(cancelSubscriptionLoadingSelector)
  const analyticsLabel = type === "update" ? "billing_confirmation_modal" : "billing_cancelation_modal";
  return (
    <BaseModal
      classname={"billing-confirm-modal"}
      isModalVisible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      modalTitle={null}
      modalFooter={null}
      width={414}
    >
      <div className={"billing-confirm-modal-body"}>
        <h3>{type === "update" ? "Change plan" : "Cancel plan"}</h3>
        <p>
          {type === "update" ? (
            <>
              Save 40% by moving to the
              <br />
              yearly plan?
            </>
          ) : (
            <>
              Are you sure you want to cancel
              <br />
              your subscription ?
            </>
          )}
        </p>
        <div className={"billing-confirm-modal-body-action"}>
          <button type={"button"} onClick={onRenewClick}>
            {updateSubscriptionLoading || cancelSubscriptionLoading ? (
              <Spin indicator={antIcon} />
            ) : (
              "Confirm"
            )}
          </button>
          <button type={"button"} onClick={() => {
            AnalyticsService.reportPremiumEvents("cancel", { label: analyticsLabel });
            setVisible(false)}}>
            Cancel
          </button>
        </div>
        <span>
          {type === "update" ? (
            <>
              By confirming, you'll be billed $24 instead of
              <br />
              $40 each month.
            </>
          ) : (
            <>
              By confirming, this you will no longer
              <br />
              have access to some actions.
            </>
          )}
        </span>
      </div>
    </BaseModal>
  );
};

export default BillingConfirmationModal;
