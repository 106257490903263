import { getAuthToken } from "helpers/authUtils";

interface IPassportData {
  agent: Object; // todo
  passport: Object; // todo
  renter: Object; // todo
  step?: number;
  tags?: string[];
}
interface IHomesData {
  listings?: object[];
}

interface IScreeningApplications {
  applications?: object[];
}

// const requirementsUrl = `${process.env.REACT_APP_BASE_URL}/requirements`;
const url = `${process.env.REACT_APP_BASE_URL}/renter`;
const recommendationsUrl = `${process.env.REACT_APP_BASE_URL}/recommendation/listings/req`;
const screeningApplicationsUrl = `${process.env.REACT_APP_BASE_URL}/screening/applications`;

const _headers = () => {
  const token = getAuthToken();
  return {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
  };
};

export const getZipCodeFromLatLnt = async ({ lat, lng }: { lat: number; lng: number }) => {
  return await fetch(`${url}/zipcode?lat=${lat}&lng=${lng}`, { headers: _headers() })
    .then((res) => res.json())
    .then((res: any) => {
      return res[0];
    });
};

export const fetchPassport = async () => {
  return await fetch(`${url}/passport`, { headers: _headers() })
    .then((res) => res.json())
    .then((res: IPassportData) => {
      return res;
    });
};

export const updatePassport = async (passport: any) => {
  return await fetch(`${url}/passport`, {
    method: "PUT",
    body: JSON.stringify(passport),
    headers: _headers(),
  })
    .then((res) => res.json())
    .then((res: IPassportData) => {
      return res;
    });
};

export const fetchRenterHomes = async () => {
  return await fetch(`${url}/homes`, { headers: _headers() })
    .then((res) => res.json())
    .then((res: IHomesData) => {
      return res;
    });
};

export const fetchPassportListingRecommendations = async (body: any) => {
  return await fetch(recommendationsUrl, {
    method: "POST",
    body: JSON.stringify(body),
    headers: _headers(),
  })
    .then((res) => res.json())
    .then((res: any) => {
      return res;
    });
};

export const fetchScreeningApplications = async () => {
  return await fetch(`${screeningApplicationsUrl}`, { headers: _headers() })
    .then((res) => res.json())
    .then((res: IScreeningApplications) => {
      return res;
    });
};

export default {
  fetchPassport,
  fetchRenterHomes,
  fetchPassportListingRecommendations,
  fetchScreeningApplications,
  getZipCodeFromLatLnt,
};
