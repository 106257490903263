import React from "react";
import "./styles.scss";


interface INameWrapperFUCardProps {
    name: string;
    follow_up_type?: string;
    showByText?: boolean;
}


const NameWrapperFUCard = ({
    name,
    follow_up_type,
    showByText
}: INameWrapperFUCardProps) => {
    const wrapperClassnames = ["follow-up-card-name-wrapper"];
    let nameText = name;
    if (follow_up_type?.includes("system")) {
        wrapperClassnames.push("primary-purple");
        nameText = "System";
    }

    return (
        <div className="flex flex-wrap gap-1 text-sm">
            {showByText ? <span>By</span> : ""}
            <div>
                {nameText}
            </div>
        </div >
    );
};

export default NameWrapperFUCard;