import React from "react";

interface ISelectInputOption {
  label: string;
  value: string;
}

interface IPropsSelect {
  field: { inputFormat: any; options: ISelectInputOption[]; label?: string; key: string };
  value: any;
  label?: string;
  onChange?: any;
  options: any[];
}

const SelectInput = ({ onChange, field, value, label, options }: IPropsSelect) => {
  return (
    <>
      <div>
        <label className="block text-base font-medium  text-gray-700">{label || field.label}</label>
        <div className="relative mt-2">
          <select
            className={`block w-56 shadow rounded-md border-0 py-1.5 pl-3 text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6`}
            placeholder=""
            aria-describedby={field.label || field.key}
            value={!!field.inputFormat ? field.inputFormat(value) : value}
            onChange={(e) => onChange({ field, value: e.target.value })}
          >
            {field.options?.map((option: ISelectInputOption) => {
              return (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </>
  );
};

export default SelectInput;
