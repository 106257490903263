import React, { useCallback, useEffect } from "react";
import { googleMapsApiKey, googleMapsApiLibrariesArray } from "../../helpers/constants";
import routes, { authorizedRoutes } from "routes/routesCode";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { useAppContext } from "libs/contextLib";
import AuthLayout from "layouts/auth/AuthLayout";
import { Route, RouteProps } from "react-router-dom";
import { getAuthToken } from "../../helpers/authUtils";
import { LoadScriptNext } from "@react-google-maps/api";
import { getLoggedInUser, setLastLocation, signOut } from "store/user/action";
import {
  // lastLocationSelector,
  userSelector,
} from "store/user/selectors";
import { leadAccountLinkSelector, singleLeadDataSelector } from "../../store/leads/selectors";
import { getLeadData } from "../../store/leads/action";
import { useLocation } from "react-router-dom";
import { renterPortalRoute } from "helpers/clientRoutes";

export interface AuthRouteProps extends RouteProps {
  component: React.JSXElementConstructor<any>;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ component: Component, ...rest }) => {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const leadAccountLink = useSelector(leadAccountLinkSelector);
  const location = useLocation();
  // const lastLocation = useSelector(lastLocationSelector)
  const isFromReqPage = localStorage.getItem("log_in_from_req");
  const leadData = useSelector(singleLeadDataSelector);
  const userData = useSelector(userSelector);

  const goToReqEdit = useCallback(() => {
    if (leadAccountLink && Object.keys(leadData).length === 0) {
      const leadId = leadAccountLink.accountLink.substring(
        leadAccountLink.accountLink.lastIndexOf("/") + 1,
        leadAccountLink.accountLink.length
      );
      dispatch(getLeadData(leadId));
    }
  }, [leadAccountLink]);

  useEffect(() => {
    function onLoad() {
      authorizedRoutes.forEach((elem) => {
        if (window.location.pathname.includes(elem)) {
          return dispatch(setLastLocation(window.location.pathname.substring(1)));
        }
      });
      try {
        if (getAuthToken()) {
          dispatch(getLoggedInUser());
          userHasAuthenticated(true);
        } else {
          if (!window.location.href.includes("sign-in")) {
            if (window.location.href.includes("followUps") && window.location.href.includes("id=")) {
              dispatch(
                signOut({ redirectUrl: `${window.location.pathname}${window.location.search}`, email: user.email })
              );
            } else {
              if (window.location.search.includes("isShared=true")) {
                dispatch(
                  signOut({
                    redirectUrl: window.location.pathname.includes("sign-in")
                      ? ""
                      : window.location.pathname.substring(1),
                    email: user.email,
                  })
                );
              } else {
                if (
                  location.pathname.includes("leads") ||
                  location.pathname.includes("listings") ||
                  location.pathname.includes("landlords") ||
                  location.pathname.includes("followUps") ||
                  location.pathname.includes("teamsMembers") ||
                  location.pathname.includes("teamDashboard") ||
                  location.pathname.includes("settings") ||
                  location.pathname.includes("tenant-screenings")
                ) {
                  dispatch(signOut({ redirectUrl: signOut(location.pathname.substring(1)), email: user.email }));
                } else {
                  dispatch(
                    signOut({
                      redirectUrl:
                        sessionStorage.getItem("force_logout_last_page") ?? window.location.search.substring(1),
                      email: user.email,
                    })
                  );
                }
              }
            }
          }
          userHasAuthenticated(false);
        }
      } catch (e) {
        dispatch(push(routes.listings.path));
      }
    }

    onLoad();
  }, [dispatch, userHasAuthenticated]);

  useEffect(() => {
    if (leadAccountLink?.accountLink && !location.pathname.includes(routes.leadAccount.path)) {
      if (window.location.href.includes("isShared=true")) {
        dispatch(push(`${location.pathname}?isShared=true`));
      } else {
        if (isFromReqPage) {
          goToReqEdit();
        } else {
          dispatch(push(leadAccountLink?.accountLink + location.search));
        }
      }
    }
  }, [dispatch, leadAccountLink, location.pathname, isFromReqPage]);

  useEffect(() => {
    if (isFromReqPage) {
      if (Object.keys(leadData).length) {
        if (Object.keys(userData).length) {
          dispatch(push(`/requirements/${localStorage.getItem("requested_agent_id")}`));
          localStorage.removeItem("log_in_from_req");
        }
      }
    }
  }, [leadData, isFromReqPage, userData]);

  useEffect(() => {
    if (isAuthenticated && user && user.role && user.role === "Renter") {
      dispatch(push(renterPortalRoute(window.location.search)));
      // dispatch(getLeadAccountLink());
    }
  }, [dispatch, isAuthenticated, user]);

  return isAuthenticated && user && user.role && user.role !== "Renter" ? (
    <Route
      {...rest}
      render={(...renderProps) => {
        return (
          <AuthLayout user={user}>
            <LoadScriptNext
              id="script-loader"
              googleMapsApiKey={googleMapsApiKey}
              libraries={googleMapsApiLibrariesArray}
            >
              <Component {...renderProps} />
            </LoadScriptNext>
          </AuthLayout>
        );
      }}
    />
  ) : null;
};

export default AuthRoute;
