import React from "react";
import moment from "moment";
import { StatusEnum } from "components/Listings/helpers/mockListings";
import { formatDate } from "helpers/dateHelper";
import upperFirst from "lodash/upperFirst";
import { FaCircle } from "react-icons/fa";

const ListingAvailability = ({ listing }: any) => {
  let availabilityDate;
  let status;
  if (listing?.status) {
    status = upperFirst(listing.status.toLocaleLowerCase());
  }
  const isAvailable = listing.status === StatusEnum.available;
  const isAvailableNow = moment(listing.availability_date).diff(new Date().getTime()) <= 0;
  if (isAvailable && listing.availability_date && !isAvailableNow) {
    const availabilityDate = formatDate(listing.availability_date);
  }
  if (listing.status === StatusEnum["off-market"] && listing.lease_ending_date) {
    availabilityDate = `Lease Ends on ${formatDate(listing.lease_ending_date)}`;
  }

  return (
    <div className={`flex justify-start items-center gap-1 ${availabilityDate ? "w-44" : ""}`}>
      <FaCircle className={`h-4 w-4 ${isAvailable ? "fill-green-500" : "fill-gray-200"}`} />
      <div className="relative">
        {status}
        {availabilityDate ? (
          <div className="absolute top-6 left-0 text-xs whitespace-nowrap">{availabilityDate}</div>
        ) : null}
      </div>
    </div>
  );
};

export default ListingAvailability;
