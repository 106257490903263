import React from "react";

export const SearchBigIcon = () => {
  return (
    <svg
      width="118"
      height="91"
      viewBox="0 0 118 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="53.2591"
        cy="32.7889"
        r="31.1097"
        fill="#F1F1F1"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
      <circle
        cx="53.2588"
        cy="32.789"
        r="21.9355"
        fill="#FFFEFF"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
      <path
        d="M53.2589 15.9272C53.2589 15.5904 52.9856 15.3163 52.649 15.328C48.4351 15.4751 44.4082 17.1423 41.3197 20.033C38.2312 22.9237 36.3015 26.8315 35.8762 31.0266C35.8423 31.3616 36.0977 31.6524 36.4338 31.6747C36.7699 31.6969 37.0592 31.4422 37.094 31.1073C37.4983 27.2211 39.2907 23.6026 42.1532 20.9235C45.0157 18.2443 48.7447 16.695 52.6491 16.5485C52.9856 16.5359 53.2589 16.264 53.2589 15.9272Z"
        fill="#C4C4C4"
      />
      <rect
        x="77.3485"
        y="66.8389"
        width="10.9777"
        height="31.9255"
        rx="5.48883"
        transform="rotate(-45 77.3485 66.8389)"
        fill="#F1F1F1"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
      <rect
        x="88.7598"
        y="69.3608"
        width="1.45764"
        height="12.2219"
        rx="0.728821"
        transform="rotate(-45 88.7598 69.3608)"
        fill="white"
      />
      <rect
        x="76.6396"
        y="59.4272"
        width="1.49628"
        height="5.98511"
        transform="rotate(-45 76.6396 59.4272)"
        fill="#C4C4C4"
      />
      <rect
        x="92.5166"
        y="29.021"
        width="14.9628"
        height="1.49628"
        rx="0.748138"
        fill="#C4C4C4"
      />
      <rect
        y="41.3984"
        width="14.9628"
        height="1.49628"
        rx="0.748138"
        fill="#C4C4C4"
      />
      <rect
        x="110.473"
        y="29.021"
        width="7.48138"
        height="1.49628"
        rx="0.748138"
        fill="#C4C4C4"
      />
      <rect
        x="96.2578"
        y="34.5112"
        width="7.48138"
        height="1.49628"
        rx="0.748138"
        fill="#C4C4C4"
      />
      <rect
        x="3.74023"
        y="46.8887"
        width="7.48138"
        height="1.49628"
        rx="0.748138"
        fill="#C4C4C4"
      />
    </svg>
  );
};
