import React from "react";
import BaseModal from "../../../../Shared/BaseModal";
import "./BillingAndInvoicesModal.scss";
import { IObjectKeys } from "../../../../../helpers/globalTypes";
import moment from "moment";
import { AnalyticsService } from "helpers/analyticsService";
import { stripeYearlyPaymentId } from "../../../../../helpers/constants";

interface IBillingAndInvoicesModal {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  invoices: any;
}

const BillingAndInvoicesModal = ({
  visible,
  setVisible,
  invoices,
}: IBillingAndInvoicesModal) => {
  return (
    <BaseModal
      classname={"billing-invoices-modal"}
      isModalVisible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      modalTitle={null}
      modalFooter={null}
      width={1306}
    >
      <div className={"billing-invoices-modal-body"}>
        <h4>Invoice</h4>
        <div className={"billing-invoices-modal-body-table"}>
          <div className={"billing-invoices-modal-body-table-header"}>
            <div className={"billing-invoices-modal-body-table-header-left"}>
              <h5>Date</h5>
              <h5>Plan</h5>
              <h5>Invoice total</h5>
            </div>
            <h5>Status</h5>
          </div>
          {invoices?.map((elem: IObjectKeys, index: number) => {
            return (
              <div
                className={"billing-invoices-modal-body-table-body"}
                key={index}
              >
                <div className={"billing-invoices-modal-body-table-body-left"}>
                  <p>{moment(elem?.created * 1000).format("ll")}</p>
                  <p>
                    {elem?.lines?.data?.find((item:any)=>!item.description.includes("Unused"))?.price?.id === stripeYearlyPaymentId
                      ? "Yearly"
                      : "Monthly"}
                  </p>
                  <p>${elem?.amount_due / 100}</p>
                </div>
                <div className={"billing-invoices-modal-body-table-body-right"}>
                  <p>Paid</p>
                  <a href={elem?.hosted_invoice_url} target={"_blank"} rel="noopener noreferrer" onClick={() => AnalyticsService.reportPremiumEvents("see_invoice", { label: "all_invoices_modal" })}>
                    See invoice
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BaseModal>
  );
};

export default BillingAndInvoicesModal;
