import { Form, Input } from "antd";
import { STRONG_PASSWORD_VALIDATION } from "helpers/validations";
import React from "react";
import { LockOutlined } from "@ant-design/icons";
import BaseButton from "../BaseButton";
import { IResetPassword } from "components/ResetPassword/components/reset-password/components/set-password/SetPassword";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import "./PasswordForm.scss";
import EyeOutlinedIcon from "./icons/EyeOutlinedIcon";
import EyeIcon from "./icons/EyeIcon";
import { TOKEN_ENUM } from "helpers/authUtils";

interface IPasswordForm {
  title: string;
  onSubmit: (data: IResetPassword) => void;
  loading?: boolean;
  type?: string;
}

const PasswordForm = ({ title, onSubmit, loading, type }: IPasswordForm) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleTerms = () => {
    localStorage.removeItem(TOKEN_ENUM.ACCESS_TOKEN);
    dispatch(push("/terms"));
  };

  return (
    <div className={"set-password"}>
      <p className="text-xl mb-4 font-bold">{title}</p>
      <Form form={form} onFinish={onSubmit} className="set-password-form" layout="vertical">
        <Form.Item name="newPassword" label="Choose Password" rules={STRONG_PASSWORD_VALIDATION} hasFeedback>
          <Input.Password
            autoComplete="new-password"
            prefix={<LockOutlined />}
            type="password"
            className="set-password-input"
            iconRender={(visible) =>
              visible ? (
                <span>
                  <EyeIcon />
                </span>
              ) : (
                <span>
                  <EyeOutlinedIcon />
                </span>
              )
            }
          />
        </Form.Item>
        {type === "lead" && (
          <p className="accept-terms">
            By signing up you confirm that you accept our <br />{" "}
            <a href="https://app.therentbase.com/terms?ref=app" target="_blank">
              Terms
            </a>
          </p>
        )}
        <Form.Item className="text-left" shouldUpdate>
          {() => (
            <BaseButton
              type="submit"
              variant="primary"
              className="login-form-button mt-4"
              loading={loading}
              disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
            >
              Submit
            </BaseButton>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordForm;
