import React from "react";
import { Button, Col, Row } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getListingLastQuerySelector } from "../../store/listings/selectors";
import { useHistory } from "react-router-dom";
import { AnalyticsService } from "../../helpers/analyticsService";
import { useDemoContext } from "libs/contextLib";
import { setListingsLoadingToTrue } from "store/listings/action";
import BaseButton from "components/Shared/BaseButton";

const ListingGoBackAction = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isDemo } = useDemoContext();
  const listingLastQueryUrl = useSelector(getListingLastQuerySelector);

  const goBack = () => {
    AnalyticsService.reportBackButtonClicked("listings");
    if (isDemo) {
      history.push({
        pathname: "/demo/listings",
        search: listingLastQueryUrl,
      });
    } else {
      dispatch(setListingsLoadingToTrue());
      history.push({
        pathname: "/listings",
        search: listingLastQueryUrl,
      });
    }
  };
  return (
    <div className="flex justify-start my-4 sm:my-6">
      <BaseButton onClick={goBack} data-test-id="back-to-listings-btn" variant="link">
        <div className="flex gap-1 items-center">
          <LeftOutlined className={"font-14"} />
          Back to Listings
        </div>
      </BaseButton>
    </div>
  );
};
export default ListingGoBackAction;
