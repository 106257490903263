import { StepFormComponent } from "./types";
import LandlordDetails from "./LandlordDetails";
import PropertyInformation from "./PropertyInformation/PropertyInformation";
import PropertyDetails from "./PropertyDetails";
import Availability from "./AvailabilityPart/Availability";
import ShowingProcedure from "./ShowingProcedure/ShowingProcedure";
import AdditionalDetails from "./AdditionalDetails";
import PhotosAndVR from "./PhotosAndVR";
import React from "react";
import { address_fields } from "../../../helpers/constants";

export const steps = (props: StepFormComponent) => [
  {
    title: "Property Information",
    content: <PropertyInformation form={props.form} next={props.next} currentStep={props.currentStep} />,
  },
  {
    title: "Property Details",
    content: <PropertyDetails form={props.form} next={props.next} />,
  },
  {
    title: "Landlord Details",
    content: <LandlordDetails form={props.form} next={props.next} />,
  },
  {
    title: "Availability",
    content: <Availability form={props.form} next={props.next} />,
  },
  {
    title: "Showing Procedure",
    content: <ShowingProcedure form={props.form} next={props.next} />,
  },
  {
    title: "Additional Details",
    content: <AdditionalDetails form={props.form} next={props.next} />,
  },
  {
    title: "Photos & Marketing",
    content: <PhotosAndVR form={props.form} />,
  },
];

export const mustHavesStepFields = [
  "addr",
  ...Object.keys(address_fields),
  "zip_code",
  "unit",
  "floor",
  "flooring",
  "neighbourhood",
  "floor_number",
  "elevator",
  "unit_type",
  "bedrooms",
  "bathrooms",
  "living_area_size",
];
