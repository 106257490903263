import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Collapse } from "antd";
import { SelectDownArrowIcon, SelectUpArrowIcon } from "../../icons";
import CollapseBottomIcon from "../../Requirement/requirement-form/components/requirement-form-steps/icons/pets-icons/CollapseBottomIcon";
import CollapseTopIcon from "../../Requirement/requirement-form/components/requirement-form-steps/icons/pets-icons/CollapseTopIcon";

const { Panel } = Collapse;
interface IBaseCollapse {
  children: React.ReactNode;
  classname?: string | undefined;
  header?: React.ReactNode | string;
  collapse?: boolean;
  onChangeFunction?: any;
  onDetectCollapsedFunction?: any;
}

const BaseCollapse = (props: IBaseCollapse) => {
  const { collapse } = props;
  const [collapsed, setCollapsed] = useState<boolean | undefined>(false);
  const isShowHeaderWithCollapse = props.classname === "pets-allowed-requirement-collapse";
  const isShowArrowText = props.classname !== "one-note-collapse";
  const showOtherExpandIcon = props.classname === "req-pets-collapse-item";

  useEffect(() => {
    setCollapsed(collapse);
  }, [collapse]);

  useEffect(() => {
    if (props.onDetectCollapsedFunction) {
      props.onDetectCollapsedFunction(collapsed);
    }
  }, [collapsed]);

  return (
    <Collapse
      className={`${props.classname} text-base`}
      defaultActiveKey={collapsed ? ["0"] : ["1"]}
      activeKey={collapsed ? ["0"] : ["1"]}
      onChange={(keys) => {
        if (props.onChangeFunction) {
          props.onChangeFunction();
        }
        if (keys.length === 0) {
          setCollapsed(true);
        } else {
          setCollapsed(false);
        }
      }}
      expandIconPosition="right"
      ghost
    >
      <Panel
        header={
          collapsed || !isShowHeaderWithCollapse ? <span className={"collapse-header-txt"}>{props.header}</span> : ""
        }
        key="1"
      >
        {props.children}
      </Panel>
    </Collapse>
  );
};

export default React.memo(BaseCollapse);
