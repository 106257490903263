import React from "react";

const RenterPayment = () => {

  return (
    <>
      <div className={"listing-add-edit-modal--header"}>
        <h3>Identity Verified!</h3>
        <p>Proceed to payment before submitting your report</p>
      </div>
    </>
  );
};

export default RenterPayment;
