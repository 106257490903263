import { generateHtmlFromDocument } from "contentful/RichTextRenderer";

export const getContentFromArray = (array: Array<any>, title: string) => {
  const content = array.filter((step) => {
    if (step.fields.title === title) {
      return step.fields.stepContent;
    }
  });

  return generateHtmlFromDocument(content[0]?.fields?.stepContent);
};
