import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import {
  GET_TEAM_MEMBERS,
  INVITE_AGENT,
  RESEND_CONFIRMATION_LINK,
  REMOVE_AGENT_FROM_TEAM,
  UPDATE_TEAM_NAME
} from "./types";
import TeamsApi from "../../api/teams";
import {
  getTeamMembers,
  getTeamMembersSuccess,
  inviteAgentFail,
  inviteAgentSuccess,
  removeAgentFromTeamSuccess,
  resetInviteAgentSuccess,
  resetInviteAgentFail,
  updateTeamNameSuccess,
  updateTeamNameFail
} from "./action";
import { updateUserTeamName } from "store/user/action";
import { IObjectKeys } from "helpers/globalTypes";
import { openNotification } from "helpers/globalFunctions";

function* inviteAgentSaga({ payload }: any) {
  try {
    const response = yield call(TeamsApi.inviteAgent, payload);
    yield put(inviteAgentSuccess(response));
  } catch (e) {
    console.log("e: ", e);
    if (e.message) {
      openNotification("error", e.message);
    }
    yield put(inviteAgentFail(e));
  }
}
function* getTeamMemberSaga({ payload }: any) {
  try {
    const response = yield call(TeamsApi.getTeamMembers, {
      ...payload,
    });
    yield put(getTeamMembersSuccess(response));
  } catch (e) {
    yield put(getTeamMembers(e));
  }
}
function* resendConfirmationLinkSaga({ payload }: any) {
  try {
    const response = yield call(TeamsApi.resendConfirmationLink, {
      ...payload,
    });
    yield put(resetInviteAgentSuccess(response));
  } catch (e) {
    yield put(resetInviteAgentFail(e));
  }
}
function* removeAgentFromTeamSaga({ payload }: any) {
  try {
    const response = yield call(TeamsApi.removeAgentFromTeam, {
      ...payload
    });
    console.log("response: ", response);
    yield put(removeAgentFromTeamSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

function* updateTeamNameSaga({ payload }: any) {
  try {
    const response:IObjectKeys = yield call(TeamsApi.updateTeamName, payload);
    yield put(updateTeamNameSuccess(response));
    yield put(updateUserTeamName(payload.params.name));
  } catch (e) {
    yield put(updateTeamNameFail(e));
  }
}

function* watchInviteAgent() {
  yield takeLatest(INVITE_AGENT, inviteAgentSaga);
  yield takeLatest(GET_TEAM_MEMBERS, getTeamMemberSaga);
  yield takeLatest(RESEND_CONFIRMATION_LINK, resendConfirmationLinkSaga);
  yield takeLatest(REMOVE_AGENT_FROM_TEAM, removeAgentFromTeamSaga);
  yield takeLatest(UPDATE_TEAM_NAME, updateTeamNameSaga);
}

export default function* rootSaga() {
  yield all([fork(watchInviteAgent)]);
}
