import React from "react";

const LocationIcon = () => {
    return (
        <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.99561 17.2782C6.09197 17.4168 6.2542 17.5 6.4279 17.5C6.6016 17.5 6.76384 17.4168 6.8602 17.2782C8.08976 15.5102 9.90079 13.3268 11.1628 11.1061C12.1719 9.33054 12.6624 7.81618 12.6624 6.47656C12.6624 3.18109 9.86563 0.5 6.4279 0.5C2.99018 0.5 0.193359 3.18109 0.193359 6.47656C0.193359 7.81618 0.683914 9.33054 1.69301 11.1061C2.95408 13.3251 4.76858 15.5139 5.99561 17.2782ZM6.4279 1.49609C9.29268 1.49609 11.6234 3.73033 11.6234 6.47656C11.6234 7.64545 11.1742 9.00392 10.2503 10.6296C9.16251 12.5437 7.61018 14.4854 6.4279 16.1177C5.2458 14.4856 3.69336 12.5438 2.60547 10.6296C1.68158 9.00392 1.23245 7.64545 1.23245 6.47656C1.23245 3.73033 3.56313 1.49609 6.4279 1.49609Z"
                fill="#B7B7B7"/>
            <path
                d="M6.42782 9.46484C8.14668 9.46484 9.54509 8.1243 9.54509 6.47656C9.54509 4.82882 8.14668 3.48828 6.42782 3.48828C4.70895 3.48828 3.31055 4.82882 3.31055 6.47656C3.31055 8.1243 4.70895 9.46484 6.42782 9.46484ZM6.42782 4.48438C7.57373 4.48438 8.506 5.37807 8.506 6.47656C8.506 7.57505 7.57373 8.46875 6.42782 8.46875C5.28191 8.46875 4.34964 7.57505 4.34964 6.47656C4.34964 5.37807 5.28191 4.48438 6.42782 4.48438Z"
                fill="#B7B7B7"/>
        </svg>
    )
}

export default LocationIcon
