import React, { useEffect, useState } from "react";
import { DatePicker, DatePickerProps, Form, Input, Radio } from "antd";
import { useSelector } from "react-redux";
import {
  CHARACTERS_VALIDATION,
  SHAREABLE_LOCALITY_VALIDATION,
  SHAREABLE_REGION_VALIDATION,
  REQUIRED,
  SHAREABLE_ADDRESS_1_VALIDATION,
  SHAREABLE_POSTAL_CODE_VALIDATION,
  SHAREABLE_NAME_VALIDATION,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
  SETTINGS_PHONE_NUMBER_VALIDATION,
  SHAREABLE_NAME_NOT_REQUIRED_VALIDATION,
  SSN_VALIDATION,
  SHAREABLE_AGE_VALIDATION,
  CONFIRM_SSN_VALIDATION,
  SHAREABLE_INCOME_VALIDATION,
  SHAREABLE_STREET_NUMBER_VALIDATION,
  SHAREABLE_STREET_NAME_VALIDATION,
  SHAREABLE_ASSETS_VALIDATION,
} from "helpers/validations";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import { userSelector } from "store/user/selectors";
import { AddressChangeParams } from "components/Shared/PlacesAutocomplete/types";
import { IObjectKeys } from "helpers/globalTypes";
import { shareableLoadingSelector } from "store/shareable/selectors";
import MaskedInput from "components/Shared/MaskedPhoneInput";
import { SHAREABLE_PHONE_TYPES } from "helpers/constants";
import { DATE_PICKER_FORMATS } from "helpers/dateHelper";

const RenterDetails = ({ form }: { form: any }) => {
  const userData = useSelector(userSelector);
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);
  const shareableLoading = useSelector(shareableLoadingSelector);

  useEffect(() => {
    if (userData) {
      let setFieldValues: IObjectKeys = {
        emailAddress: userData?.email,
        firstName: userData?.name.split(" ")[0],
        lastName: userData?.name.split(" ")[1] || "",
        phoneNumber: userData?.phone || "",
        phoneType: userData?.phonetype || userData?.phone_type || "", // TODO: normalize `phone type` field across stack
        addr: userData?.address?.addr || "",
        locality: userData?.address?.city || "",
        region: userData?.address?.state || "",
        postalCode: userData?.address?.zipCode || "",
        street_name: userData?.address?.street_name || "",
        street_number: userData?.address?.street_number || "",
        country: userData?.address?.country || "",
      };
      form.setFieldsValue(setFieldValues);
    }
  }, [userData]);

  const itemStyle = { marginBottom: "10px" };

  const onAddressChange = (address: AddressChangeParams) => {
    const currentFields = form.getFieldsValue();
    let setFieldValues: IObjectKeys = {
      ...currentFields,
      addr: address.formatted_address ? address.formatted_address : currentFields.addr,
      locality: address.separated_fields?.town ? address.separated_fields?.town : currentFields.locality,
      region: address.separated_fields?.short_state ? address.separated_fields?.short_state : currentFields.region,
      postalCode: address.separated_fields?.postal_code
        ? address.separated_fields?.postal_code
        : currentFields.postalCode,
      street_name: address.separated_fields?.street_name
        ? address.separated_fields?.street_name
        : currentFields.street_name,
      street_number: address.separated_fields?.street_number
        ? address.separated_fields?.street_number
        : currentFields.street_number,
    };
    form.setFieldsValue(setFieldValues);
  };

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    const currentFields = form.getFieldsValue();
    let setFieldValues: IObjectKeys = {
      ...currentFields,
      dateOfBirth: date,
    };
    form.setFieldsValue(setFieldValues);
  };

  return (
    <>
      {/* PERSONAL INFO */}
      <div className={"listing-add-edit-modal--header"}></div>
      <h3 style={{ fontWeight: "bold", fontSize: "25px" }}>Personal Info</h3>
      {/* LEGAL FIRST NAME */}
      <Form.Item label="Legal First Name" name="firstName" style={itemStyle} rules={[SHAREABLE_NAME_VALIDATION]}>
        <Input placeholder={"John"} />
      </Form.Item>
      {/* LEGAL MIDDLE NAME (NOT INITIAL) */}
      <Form.Item
        label="Legal Middle Name (not initial)"
        name="middleName"
        style={itemStyle}
        rules={[SHAREABLE_NAME_NOT_REQUIRED_VALIDATION]}
      >
        <Input placeholder={"Doe"} />
      </Form.Item>
      {/* LEGAL LAST NAME */}
      <Form.Item label="Legal Last Name" name="lastName" required style={itemStyle} rules={[SHAREABLE_NAME_VALIDATION]}>
        <Input placeholder={"Doe"} />
      </Form.Item>
      {/* DATE OF BIRTH */}
      <Form.Item
        label="Date of Birth"
        name="dateOfBirth"
        style={itemStyle}
        rules={[SHAREABLE_AGE_VALIDATION, REQUIRED]}
      >
        <DatePicker onChange={onDateChange} format={DATE_PICKER_FORMATS} />
      </Form.Item>
      {/* HOME PHONE NUMBER */}
      <div style={{ marginBottom: "10px" }}>
        <Form.Item label="Home Phone No." name="phoneNumber" rules={[REQUIRED, SETTINGS_PHONE_NUMBER_VALIDATION]}>
          <MaskedInput inputMode="numeric" mask={PHONE_NUMBER_MASK} placeholder={PHONE_NUMBER_PLACEHOLDER} />
        </Form.Item>
      </div>
      {/* PHONE NUMBER */}
      <Form.Item label="Phone type" name="phoneType" style={itemStyle} rules={[REQUIRED]}>
        <Radio.Group data-test-id={"radio_group"} className="new-exists-radio-group" defaultValue={userData?.phonetype}>
          {SHAREABLE_PHONE_TYPES.map((type) => (
            <Radio key={type.value} data-test-id={"new_landlord"} value={type.value}>
              {type.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      {/* SSN/ITIN */}
      <Form.Item label="SSN/ITIN" name="socialSecurityNumber" style={itemStyle} rules={[SSN_VALIDATION]}>
        <Input placeholder={"123456789"} />
      </Form.Item>
      {/* CONFIRM SSN/ITIN */}
      <Form.Item
        label="Confirm SSN/ITIN"
        name="confirmSocialSecurityNumber"
        style={itemStyle}
        rules={[CONFIRM_SSN_VALIDATION, REQUIRED]}
      >
        <Input placeholder={"123456789"} />
      </Form.Item>
      {/* MOST RECENT ADDRESS */}
      <div className={"listing-add-edit-modal--header"} style={{ marginTop: "15px" }}></div>
      <h3 style={{ fontWeight: "bold", fontSize: "25px" }}>Most recent address where you lived 2 or more years</h3>
      {/* ADDRESS LINE 1 */}
      <div style={{ marginBottom: "10px" }}>
        <Form.Item name="addr" label="Address" className="area_field">
          <PlacesAutocomplete
            defaultAddress={userData.address?.addr}
            onAddressChange={onAddressChange}
            resetPlacesAutocomplete={resetPlacesAutocomplete}
            setResetPlacesAutocomplete={setResetPlacesAutocomplete}
            placeholder={"Enter your home address"}
          />
        </Form.Item>
      </div>
      {/* STREET NUMBER */}
      <Form.Item label="Street Number" name="street_number" style={itemStyle}>
        <Input />
      </Form.Item>
      {/* STREET NAME */}
      <Form.Item label="Street Name" name="street_name" style={itemStyle} rules={[SHAREABLE_STREET_NAME_VALIDATION]}>
        <Input />
      </Form.Item>
      {/* ADDRESS LINE 2 */}
      <Form.Item label="Unit Number" name="unit" style={itemStyle}>
        <Input placeholder={"123 Main St"} />
      </Form.Item>
      {/* CITY */}
      <Form.Item label="City" name="locality" style={itemStyle} rules={[SHAREABLE_LOCALITY_VALIDATION]}>
        <Input />
      </Form.Item>
      {/* STATE */}
      <Form.Item label="State" name="region" style={itemStyle} rules={[SHAREABLE_REGION_VALIDATION]}>
        <Input placeholder={"NY"} />
      </Form.Item>
      {/* ZIP CODE */}
      <Form.Item label="Postal Code" name="postalCode" style={itemStyle} rules={[SHAREABLE_POSTAL_CODE_VALIDATION]}>
        <Input placeholder={"12345"} />
      </Form.Item>
      {/* HIDDEN PASSPORT_FIELDS */}
      <div style={{ height: 0 }}>
        {/* COUNTRY */}
        <Form.Item name="country">
          <Input hidden />
        </Form.Item>
      </div>
    </>
  );
};

export default RenterDetails;
