import React, { memo, useEffect, useRef, useState } from "react";
import Form from "antd/es/form";
import { FormInstance } from "antd";
import StatusWrapper from "../status-wrapper/StatusWrapper";
import LocationFilter from "../location/LocationFilter";
import { debounce } from "lodash";
import PriceFilterWrapper from "../price-filter-wrapper/PriceFilterWrapper";
import MoveInDateFilter from "../move-in-date-filter/MoveInDateFilter";
import BedroomsBathroomsFilter from "../bedrooms-bathrooms-filter/BedroomsBathroomsFilter";
import { IObjectKeys } from "../../../../../../helpers/globalTypes";
import PetsFilter from "../pets-filter/PetsFilter";
import ParkingFilter from "../parking-filter/ParkingFilter";
import { useDispatch, useSelector } from "react-redux";
import { urlQueryToObject } from "../../../helpers/listingsCreateGetSearchQuery";
import { useLocation } from "react-router";
import LocationFilterSelect from "../location/components/location-select-wrapper/LocationFilterSelect";
import { areasFacetsLoadingSelector, areasFacetsSelector } from "../../../../../../store/areas/selectors";
import TagsFilter from "../tags/TagsFilter";
import { generateFilterFormData } from "../../../helpers/filterFormHelper";
import MyListingsFilter from "../my-listings/MyListingsFilter";
import { isListingRoute } from "../../../../../../helpers/clientRoutes";

interface IFilterForm {
  isLocationsSelectVisible: boolean;
  setIsLocationsSelectVisible: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance;
  isClearAllClicked: boolean;
  listingsFilterOptions: any;
  paginationValues: any;
  listingsViewType: any;
  bathRoomsCount: IObjectKeys;
  setBathRoomsCount: React.Dispatch<React.SetStateAction<IObjectKeys>>;
  bedRoomsCount: IObjectKeys;
  setBedRoomsCount: React.Dispatch<React.SetStateAction<IObjectKeys>>;
  hasParking: boolean;
  setHasParking: React.Dispatch<React.SetStateAction<boolean>>;
  justMyListings: boolean;
  setJustMyListings: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterForm = (props: IFilterForm) => {
  const [location, setLocation] = useState<any>(null);
  const dispatch = useDispatch();
  const [allowedPets, setAllowedPets] = useState<string[]>([]);
  const { search, pathname } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const [checkedLocationsArray, setCheckedLocationsArray] = useState<any>([]);
  const areasLoading = useSelector(areasFacetsLoadingSelector);
  const areasFacets = useSelector(areasFacetsSelector);
  const delayedFilter = useRef(debounce((_formData) => {}, 500));
  const showLocations = pathname.includes("landlords");
  const isListingPage = isListingRoute(pathname);
  useEffect(() => {
    if (queryObject.locations) {
      setLocation(queryObject.locations);
    }
  }, []);

  useEffect(() => {
    if (props.isClearAllClicked) {
      setLocation(null);
    }
  }, [props.isClearAllClicked]);

  return (
    <Form
      onFinish={(data) => {
        delayedFilter?.current(
          generateFilterFormData({
            data,
            location,
            bedRoomsCount: props.bedRoomsCount,
            bathRoomsCount: props.bathRoomsCount,
            allowedPets,
            hasParking: props.hasParking,
            justMyListings: props.justMyListings,
            dispatch,
            paginationValues: props.paginationValues,
            listingsViewType: props.listingsViewType,
            filterOptions: props.listingsFilterOptions,
            queryObject,
            type: window.location.pathname.substring(1),
          })
        );
      }}
      className={`filter-modal-form ${props.isLocationsSelectVisible ? "filter-modal-form-locations" : ""}`}
      form={props.form}
    >
      {Object.keys(areasFacets).length ? (
        <>
          {props.isLocationsSelectVisible ? (
            <LocationFilterSelect
              form={props.form}
              setLocation={setLocation}
              defaultSelectedValues={checkedLocationsArray}
              setDefaultSelectedValues={setCheckedLocationsArray}
              isClearAllClicked={props.isClearAllClicked}
            />
          ) : (
            <>
              {!showLocations && !isListingPage && (
                <StatusWrapper form={props.form} isClearAllClicked={props.isClearAllClicked} />
              )}
              {!showLocations && isListingPage && (
                <MyListingsFilter
                  form={props.form}
                  justMyListings={props.justMyListings}
                  setJustMyListings={props.setJustMyListings}
                />
              )}
              {showLocations ||
                (!pathname.includes("landlords") && (
                  <LocationFilter
                    form={props.form}
                    setLocation={setLocation}
                    setIsLocationsSelectVisible={props.setIsLocationsSelectVisible}
                    isLocationsSelectVisible={props.isLocationsSelectVisible}
                    checkedLocationsArray={checkedLocationsArray}
                    setCheckedLocationsArray={setCheckedLocationsArray}
                    isClearAllClicked={props.isClearAllClicked}
                  />
                ))}
              {!showLocations && <PriceFilterWrapper form={props.form} isClearAllClicked={props.isClearAllClicked} />}
              {!showLocations && <MoveInDateFilter form={props.form} isClearAllClicked={props.isClearAllClicked} />}
              {!showLocations && (
                <BedroomsBathroomsFilter
                  form={props.form}
                  bedRoomsCount={props.bedRoomsCount}
                  setBedRoomsCount={props.setBedRoomsCount}
                  bathRoomsCount={props.bathRoomsCount}
                  setBathRoomsCount={props.setBathRoomsCount}
                  isClearAllClicked={props.isClearAllClicked}
                />
              )}

              {pathname === "/" || pathname === "/landlords" ? <TagsFilter form={props.form} /> : null}
              {!showLocations && (
                <ParkingFilter form={props.form} hasParking={props.hasParking} setHasParking={props.setHasParking} />
              )}
              {!showLocations && (
                <PetsFilter
                  setAllowedPets={setAllowedPets}
                  form={props.form}
                  isClearAllClicked={props.isClearAllClicked}
                />
              )}
            </>
          )}
        </>
      ) : null}
    </Form>
  );
};

export default memo(FilterForm);
