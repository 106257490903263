import React from "react";

export const SettingsEmailIcon = () => {
  return (
    <svg
      width="31"
      height="22"
      viewBox="0 0 31 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5488 10.815L30.9996 17.4218V3.92918L20.5488 10.815Z"
        fill="black"
      />
      <path d="M0 3.92918V17.4218L10.4508 10.815L0 3.92918Z" fill="black" />
      <path
        d="M29.0625 0H1.93771C0.970906 0 0.203661 0.720745 0.0583496 1.6488L15.5001 11.8225L30.9419 1.6488C30.7966 0.720745 30.0293 0 29.0625 0Z"
        fill="black"
      />
      <path
        d="M18.7743 11.9852L16.0327 13.7909C15.87 13.8975 15.6859 13.9498 15.4999 13.9498C15.3139 13.9498 15.1298 13.8975 14.9671 13.7909L12.2256 11.9832L0.0620117 19.677C0.211198 20.5973 0.974568 21.3122 1.9375 21.3122H29.0623C30.0253 21.3122 30.7886 20.5973 30.9378 19.677L18.7743 11.9852Z"
        fill="black"
      />
    </svg>
  );
};
