import React from "react";
import "./LdsSpinner.scss"

const LdsSpinner = () => {
    return(
        <div className="lds-spinner">
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    )
}

export default LdsSpinner
