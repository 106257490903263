export const GET_LEADS = "GET_LEADS";
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS";
export const GET_LEADS_FAIL = "GET_LEADS_FAIL";

export const SET_LEAD_DATA_CONTACT = "SET_LEAD_DATA_CONTACT";

export const GET_LEAD_DATA = "GET_LEAD_DATA";
export const GET_LEAD_DATA_SUCCESS = "GET_LEAD_DATA_SUCCESS";
export const GET_LEAD_DATA_FAIL = "GET_LEAD_DATA_FAIL";

export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";

export const SET_LEADS_FILTER_OPTIONS = "SET_LEADS_FILTER_OPTIONS";

export const SET_LEADS_PAGINATION_VALUES = "SET_LEADS_PAGINATION_VALUES";

export const SET_LAST_LEADS_QUERY = "SET_LAST_LEADS_QUERY";

export const CLEAR_LEAD_DATA = "CLEAR_LEAD_DATA";

export const SET_LEADS_LOADING_TO_TRUE = "SET_LEADS_LOADING_TO_TRUE";
export const SET_LEADS_LOADING_TO_FALSE = "SET_LEADS_LOADING_TO_FALSE";

export const ADD_LEAD_NOTE = "ADD_LEAD_NOTE";
export const ADD_LEAD_NOTE_SUCCESS = "ADD_LEAD_NOTE_SUCCESS";
export const ADD_LEAD_NOTE_FAIL = "ADD_LEAD_NOTE_FAIL";

export const UPDATE_LEAD_NOTE = "UPDATE_LEAD_NOTE";
export const UPDATE_LEAD_NOTE_SUCCESS = "UPDATE_LEAD_NOTE_SUCCESS";
export const UPDATE_LEAD_NOTE_FAIL = "UPDATE_LEAD_NOTE_FAIL";

export const DELETE_LEAD_NOTE = "DELETE_LEAD_NOTE";
export const DELETE_LEAD_NOTE_SUCCESS = "DELETE_LEAD_NOTE_SUCCESS";
export const DELETE_LEAD_NOTE_FAIL = "DELETE_LEAD_NOTE_FAIL";

export const GET_LEAD_NOTES = "GET_LEAD_NOTES";
export const GET_LEAD_NOTES_SUCCESS = "GET_LEAD_NOTES_SUCCESS";
export const GET_LEAD_NOTES_FAIL = "GET_LEAD_NOTES_FAIL";

export const SET_LEAD_NOTE_ADD_SUCCESS = "SET_LEAD_NOTE_ADD_SUCCESS";

export const VERIFY_LEAD_EMAIL = "VERIFY_LEAD_EMAIL";
export const VERIFY_LEAD_EMAIL_SUCCESS = "VERIFY_LEAD_EMAIL_SUCCESS";
export const VERIFY_LEAD_EMAIL_ERROR = "VERIFY_LEAD_EMAIL_ERROR";

export const CONFIRM_LEAD_PASSWORD = "CONFIRM_LEAD_PASSWORD";
export const CONFIRM_LEAD_PASSWORD_ERROR = "CONFIRM_LEAD_PASSWORD_ERROR";
export const CONFIRM_LEAD_PASSWORD_SUCCESS = "CONFIRM_LEAD_PASSWORD_SUCCESS";

export const GET_LEAD_ACCOUNT_LINK = "GET_LEAD_ACCOUNT_LINK";
export const GET_LEAD_ACCOUNT_LINK_SUCCESS = "GET_LEADS_ACCOUNT_LINK_SUCCESS";

export const SET_LEAD_ACCESS_TOKEN = "SET_LEAD_ACCESS_TOKEN";
export const CLEAR_LEAD_LINK = "CLEAR_LEAD_LINK";

export const SEND_LEAD_INFO_BY_SMS = "SEND_LEAD_INFO_BY_SMS";
export const SEND_LEAD_INFO_BY_SMS_SUCCESS = "SEND_LEAD_INFO_BY_SMS_SUCCESS";
export const SEND_LEAD_INFO_BY_SMS_FAIL = "SEND_LEAD_INFO_BY_SMS_FAIL";

export const LEAD_UPDATE_TAGS = "LEAD_UPDATE_TAGS";
export const LEAD_UPDATE_TAGS_SUCCESS = "LEAD_UPDATE_TAGS_SUCCESS";
export const LEAD_UPDATE_TAGS_ERROR = "LEAD_UPDATE_TAGS_ERROR";
export const LEAD_REMOVE_TAG= "LEAD_REMOVE_TAG";
export const LEAD_REMOVE_TAG_SUCCESS= "LEAD_REMOVE_TAG_SUCCESS";
export const LEAD_REMOVE_TAG_ERROR= "LEAD_REMOVE_TAG_ERROR";

