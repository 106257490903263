import React, { useState } from "react";
import "./FollowUpModalItem.scss";
import moment from "moment";
import FollowUpDeleteIcon from "../follow-up-modal-single-item/icons/FollowUpDeleteIcon";
import FollowUpEditIcon from "../follow-up-modal-single-item/icons/FollowUpEditIcon";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import DeleteFollowUpModal from "../delete-follow-up-modal/DeleteFollowUpModal";
import { formatDate } from '../../../../../helpers/dateHelper';

interface IFollowUpModalItem {
  followUp: any;
  openSingleFollowUp: any;
  setAllVisible:any;
  handleOpenEditFollowUpModal:any
}
const FollowUpModalItem = ({
  followUp,
  openSingleFollowUp,
  setAllVisible,
  handleOpenEditFollowUpModal,
}: IFollowUpModalItem) => {
  const { width } = useWindowSize();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  return (
    <div
      className={"follow-up-modal-item"}
      onClick={() => {
        //@ts-ignore
        width >= 599 && openSingleFollowUp(followUp);
      }}
    >
      <div
          className={"follow-up-modal-item--info"}
          onClick={() => {
            //@ts-ignore
            width < 599 && openSingleFollowUp(followUp);
          }}
      >
        <span
            className={`${
                new Date(followUp.todo_at).getTime() < new Date().getTime()
                    ? "followUp-date-past"
                    : "followUp-date"
            }`}
        >
        Due: {formatDate(followUp.todo_at, "MMM Do, YYYY" )}{" "}
          {new Date(followUp.todo_at).getTime() < new Date().getTime() &&
          "(Past Due)"}
      </span>
        <p>{followUp.title}</p>
      </div>
      <span className={"follow-up-modal--by"}>
        By {followUp.created_by.name}
      </span>
      <div className={"follow-up-modal-item--actions"}>
        <span onClick={()=>{
          setIsDeleteModalVisible(true);
        }}>
          <FollowUpDeleteIcon/>
        </span>
        <span onClick={()=>{
          setAllVisible(false);
          handleOpenEditFollowUpModal(followUp);
        }}>
          <FollowUpEditIcon/>
        </span>
      </div>
      <DeleteFollowUpModal
          isModalVisible={isDeleteModalVisible}
          setIsModalVisible={setIsDeleteModalVisible}
          //@ts-ignore
          width={width <= 600 && width > 375 ? 390:280}
          followUp={followUp}
          // backToFollowUps={backToFollowUps}
      />
    </div>
  );
};

export default FollowUpModalItem;
