import { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { setWindowType } from "../store/app/action";
import { debounce } from "lodash";
import { getDataFromLocalStorageByKey } from "../helpers/globalFunctions";

export interface windowPropInterface {
  width: number | null;
  height: number | null;
}

// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  
  const [windowSize, setWindowSize] = useState<windowPropInterface>({
    width: window.innerWidth,
    height: null,
  });

  const dispatch = useDispatch();

  const delayedQueryCall = debounce(() => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, 500);

  // Handler to call on window resize

  useEffect(() => {
    // Add event listener    
    window.addEventListener("resize", delayedQueryCall);

    // Call handler right away so state gets updated with initial window size
    delayedQueryCall();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", delayedQueryCall);
  }, []); // Empty array ensures that effect is only run on mount

  useLayoutEffect(() => {
    const { obj } = getDataFromLocalStorageByKey("sidebarParams");
    let window = {
      type: "desktop",
      collapsed: false,
    };

    if (windowSize.width && windowSize.width >= 1152) {
      //On bigger screens (>1152px) - it defaults to open
      window.type = "desktop";
      window.collapsed = false;

      if (obj && obj.collapsed) {
        window.collapsed = obj.collapsed;
      }

    } else if (
      windowSize.width &&
      windowSize.width < 1152 &&
      windowSize.width > 766
    ) {
      // On smaller screens (1152px>, tablets) - it will default to collapse
      window.type = "tablet";
      window.collapsed = true;
    } else {
      // On mobile screens - it will default to hidden(collapsed)
      window.type = "mobile";
      window.collapsed = true;
    }
    localStorage.setItem("sidebarParams", JSON.stringify(window));

    dispatch(setWindowType(window));
  }, [windowSize.width, dispatch]);

  return windowSize;
}
