import React from "react";

export const InfoOkIcon = () => {
  return (
    <svg
      width="101"
      height="96"
      viewBox="0 0 101 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100.08 47.7799C100.08 52.7303 91.2106 56.2181 89.7602 60.6852C88.2559 65.3151 93.3294 73.3404 90.524 77.1928C87.6923 81.0854 78.4736 78.726 74.5811 81.5592C70.7273 84.3631 70.1388 93.8734 65.5103 95.372C61.0432 96.8223 54.996 89.5209 50.0398 89.5209C45.0835 89.5209 39.0419 96.8223 34.5693 95.372C29.9393 93.8677 29.3509 84.3577 25.497 81.5592C21.606 78.7275 12.3873 81.0868 9.55417 77.1943C6.7502 73.3404 11.8222 65.3151 10.3193 60.6866C8.86928 56.2195 0 52.7318 0 47.7813C0 42.8308 8.86928 39.3431 10.3196 34.876C11.8225 30.2472 6.75049 22.2219 9.55446 18.3684C12.3876 14.4758 21.6062 16.8351 25.4988 14.0035C29.3512 11.1995 29.9396 1.68776 34.5696 0.190632C39.0367 -1.25972 45.0838 6.04169 50.0401 6.04169C54.996 6.04169 61.038 -1.25972 65.5106 0.190632C70.1394 1.69353 70.729 11.205 74.5814 14.0035C78.4739 16.8366 87.6926 14.4773 90.5257 18.3684C93.3297 22.2222 88.2562 30.2475 89.7605 34.876C91.2106 39.3414 100.08 42.8291 100.08 47.7799V47.7799Z"
        fill="#D5C1ED"
      />
      <path
        d="M45.6708 64.7926C44.2054 64.7946 42.7995 64.2125 41.7644 63.1751L29.4461 50.8568C27.288 48.6993 27.2875 45.2009 29.4446 43.0428C31.6021 40.8848 35.1005 40.8842 37.2586 43.0414L45.6705 51.4522L62.833 34.2911C64.9908 32.133 68.4895 32.1327 70.6475 34.2905C72.8056 36.4483 72.8059 39.947 70.6481 42.105L49.578 63.1751C48.5429 64.2128 47.1364 64.7949 45.6708 64.7926V64.7926Z"
        fill="#5806B9"
      />
    </svg>
  );
};
