import React from "react";

const SmartPhoneIcon = () => {
    return (
        <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50.2364 0H24.8992C20.5917 0 16.9316 3.51228 16.9316 7.81864V67.1903C16.9316 71.4967 20.5142 75 24.8218 75L50.2006 74.9726C54.5076 74.9726 58.0312 71.4687 58.0312 67.1617L58.0681 7.81447C58.0675 3.49263 54.5612 0 50.2364 0ZM33.9075 4.68761H41.2585C41.9042 4.68761 42.4302 5.21183 42.4302 5.85937C42.4302 6.5069 41.9042 7.03112 41.2585 7.03112H33.9075C33.2599 7.03112 32.7357 6.5069 32.7357 5.85937C32.7357 5.21183 33.2599 4.68761 33.9075 4.68761ZM29.4903 5.06052C29.9246 4.62685 30.7151 4.62089 31.1488 5.06052C31.3662 5.27736 31.4949 5.58177 31.4949 5.89273C31.4949 6.19773 31.3662 6.50154 31.1488 6.71897C30.9325 6.9364 30.6269 7.06448 30.3225 7.06448C30.0122 7.06448 29.7137 6.9364 29.4903 6.71897C29.2741 6.50213 29.1508 6.19773 29.1508 5.89273C29.1502 5.58177 29.2741 5.27736 29.4903 5.06052ZM37.3465 72.6773C35.4128 72.6773 33.8402 71.1035 33.8402 69.1704C33.8402 67.2368 35.4128 65.6635 37.3465 65.6635C39.2802 65.6635 40.8528 67.2368 40.8528 69.1704C40.8528 71.1035 39.2796 72.6773 37.3465 72.6773ZM54.4307 63.2801H21.0331V11.1332H54.4307V63.2801Z" fill="black"/>
            <g clipPath="url(#clip0)">
                <path d="M37.8075 45.4919C42.391 45.4919 46.1067 41.7762 46.1067 37.1927C46.1067 32.6092 42.391 28.8936 37.8075 28.8936C33.224 28.8936 29.5083 32.6092 29.5083 37.1927C29.5083 41.7762 33.224 45.4919 37.8075 45.4919Z" fill="#5807B8"/>
                <path d="M35.687 40.9366L39.9544 45.204C43.4888 44.2615 46.1068 41.0413 46.1068 37.1928C46.1068 37.1143 46.1068 37.0357 46.1068 36.9572L42.7557 33.8679L35.687 40.9366Z" fill="#4D1491"/>
                <path d="M38.0168 39.0515C38.3833 39.418 38.3833 40.0464 38.0168 40.4129L37.2576 41.1721C36.891 41.5387 36.2627 41.5387 35.8962 41.1721L32.5713 37.821C32.2048 37.4545 32.2048 36.8262 32.5713 36.4597L33.3305 35.7004C33.697 35.3339 34.3254 35.3339 34.6919 35.7004L38.0168 39.0515Z" fill="white"/>
                <path d="M40.9228 33.2656C41.2893 32.8991 41.9176 32.8991 42.2841 33.2656L43.0434 34.0248C43.4099 34.3914 43.4099 35.0197 43.0434 35.3862L37.2837 41.1197C36.9172 41.4863 36.2888 41.4863 35.9223 41.1197L35.1631 40.3605C34.7966 39.994 34.7966 39.3656 35.1631 38.9991L40.9228 33.2656Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16.5984" height="16.5984" fill="white" transform="translate(29.5083 28.8936)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default SmartPhoneIcon;