import React from "react";
import {
  googleMapsApiKey,
  googleMapsApiLibrariesArray,
} from "../../helpers/constants";
import { Route, RouteProps, useLocation } from "react-router-dom";
import { LoadScriptNext } from "@react-google-maps/api";
import DummyLayout from "layouts/dummy/DummyLayout";

export interface AuthRouteProps extends RouteProps {
  component: React.JSXElementConstructor<any>;
}

const DummyRoute: React.FC<AuthRouteProps> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(...renderProps) => {
        return (
          <DummyLayout>
            <LoadScriptNext
              id="script-loader"
              googleMapsApiKey={googleMapsApiKey}
              libraries={googleMapsApiLibrariesArray}
            >
              <Component {...renderProps} />
            </LoadScriptNext>
          </DummyLayout>
        );
      }}
    />
  );
};

export default DummyRoute;
