import { TOKEN_ENUM } from 'helpers/authUtils';
import React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function ClawCallback() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Extract the authorization code from the URL query parameters
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const url = `${process.env.REACT_APP_BASE_URL}/auth/callbacks/claw?code=${code}`;
    // Call your back-end API to exchange the code for an access token
    fetch(url)
      .then(response => response.json())
      .then(token => {
        // Store the access token in local storage or a cookie
        localStorage.setItem("access_token", token[TOKEN_ENUM.ACCESS_TOKEN]);
        localStorage.setItem("refresh_token", token[TOKEN_ENUM.REFRESH_TOKEN]);
        // Redirect to the home page
        history.push('/');
      }).catch(error => {
        console.error('Failed to exchange authorization code for access token:', error);
        // handle the error here, e.g. show an error message to the user
      });
  }, [history, location.search]);

  return (
    <div>
      <p>Authenticating...</p>
    </div>
  );
}

export default ClawCallback;