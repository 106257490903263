import React, { useEffect, useState } from "react";
import { Alert, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import BaseModal from "../../../Shared/BaseModal";
import BaseButton from "../../../Shared/BaseButton";
import RenterDisclosures from "./components/RenterDisclosures";
import { BUTTON_BACK_TEXT, DEFAULT_BUNDLE } from "../../../../helpers/constants";
import { IObjectKeys } from "helpers/globalTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  userUpdateSuccessSelector,
  isUserUpdatedSelector,
  userSelector,
  updatedUserLoadingSelector,
} from "store/user/selectors";
import {
  shareableRenterEntitySelector,
  createShareableRenterEntitySuccessSelector,
  shareableVerificationExamEntitySelector,
  createShareableVerificationExamEntityErrorSelector,
  shareableVerificationExamAnswersSelector,
  postShareableVerificationExamAnswersSuccessSelector,
  postShareableVerificationExamAnswersErrorSelector,
  createShareableRenterEntityErrorSelector,
  validateRenterSuccessSelector,
  shareableLoadingSelector,
  shareableRenterLinkDataSelector,
} from "store/shareable/selectors";
import {
  clearEmailScreeningLinkData,
  clearShareableRenterEntity,
  clearValidateRenterSuccess,
  clearVerificationExamAnswersEntity,
  createShareableRenterEntity,
  createShareableVerificationExamEntity,
  postShareableVerificationExamAnswers,
  validateRenter,
} from "store/shareable/action";
import { setTimeout } from "timers";
import ReadBeforeApply from "./components/ReadBeforeApply";
import RenterDetails from "./components/RenterDetails";
import Success from "./components/Success";
import Exam from "./components/Exam";
import moment from "moment";
import RenterPayment from "./components/RenterPayment";
import ManualVerification from "./components/ManualVerification";
import { clearScreeningPaymentLink, getScreeningPaymentLink } from "store/payment/action";
import { screeningPaymentLinkSelector } from "store/payment/selectors";
import { useLocation } from "react-router-dom";
import { openNotification } from "helpers/globalFunctions";
import ShareableApi from "api/Shareable";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import { clearUpdateUserProfile, updateUserProfile } from "store/user/action";

interface IAlert {
  should_show?: boolean;
  message?: string;
  description?: string;
  type?: "success" | "error" | "info" | "warning" | undefined;
}

const RenterRequestScreeningDialog = ({
  visible,
  setVisible,
  renter,
  selectedRequestData,
  refreshData,
}: {
  visible: boolean;
  setVisible: Function;
  refreshData: Function;
  renter: IObjectKeys;
  selectedRequestData: IObjectKeys;
}) => {
  const userData = useSelector(userSelector);
  const shareableRenterEntity = useSelector(shareableRenterEntitySelector);
  const createShareableRenterEntitySuccess = useSelector(createShareableRenterEntitySuccessSelector);
  const shareableLoading = useSelector(shareableLoadingSelector);
  const shareableVerificationExamEntity = useSelector(shareableVerificationExamEntitySelector);
  const createShareableVerificationExamEntityError = useSelector(createShareableVerificationExamEntityErrorSelector);
  const shareableVerificationExamAnswers = useSelector(shareableVerificationExamAnswersSelector);
  const postShareableVerificationExamAnswersSuccess = useSelector(postShareableVerificationExamAnswersSuccessSelector);
  const postShareableVerificationExamAnswersError = useSelector(postShareableVerificationExamAnswersErrorSelector);
  const createShareableRenterEntityError = useSelector(createShareableRenterEntityErrorSelector);
  const screeningPaymentLink = useSelector(screeningPaymentLinkSelector);
  const validateRenterSuccess = useSelector(validateRenterSuccessSelector);
  const screeningLinkData = useSelector(shareableRenterLinkDataSelector);
  const userUpdateSuccess = useSelector(userUpdateSuccessSelector);
  const updatedUserLoading = useSelector(updatedUserLoadingSelector);
  const isUserUpdated = useSelector(isUserUpdatedSelector);

  const [step, setStep] = useState(0);
  const [showFailedMessage, setShowFailedMessage] = useState(false);
  const [screeningData, setScreeningData] = useState({
    acceptTerms: false,
    renter_id: "",
    agent_name: "",
  });
  const [alert, setAlert] = useState<IAlert>({
    should_show: false,
    message: "",
    description: "",
    type: undefined,
  });
  const [renterPersonalData, setRenterPersonalData] = useState<any>({});
  const [tuData, setTuData] = useState<any>({});
  const [form] = useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);
  const queryObject = urlQueryToObject(UrlSearchParams);

  const failedMessage = () => {
    return `Your attempt has failed, please try again.`;
  };

  const getUTCDateForSR = (date: any) => {
    if (!date) return date;
    return moment(date).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();
  };

  const generateScreeningRequestReport = async () => {
    try {
      await ShareableApi.createScreeningRequestReport({
        screeningRequestId: shareableRenterEntity.srId,
        data: {
          ...shareableRenterEntity.data?.person,
          dateOfBirth: getUTCDateForSR(shareableRenterEntity.data?.dateOfBirth),
        },
      });
    } catch (error) {
      openNotification("error", "Report generation failed");
    } finally {
      // this will clear the shareableRenterEntity's data so,
      // If user click on Apply button of Screening Request with `Verfication Completed` status it will not directly show `Identity Verfied!` screen
      dispatch(clearShareableRenterEntity());
      refreshData();
      setTimeout(() => {
        setVisible(false);
        setStep(0);
      }, 1200);
    }
  };

  useEffect(() => {
    console.log("location", location);

    if (window.location.search.includes("shareable_screening_success=true")) {
      setStep(6);
      generateScreeningRequestReport();
      // This will replace the current entry in the browser's history, without reloading
      window.history.replaceState(null, document.title, location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (step === 2 && createShareableRenterEntitySuccess) {
      // check if the renter is verified, is so move to payment, if not handleGetExamSubmit();
      handleValidateRenter();
    }
  }, [createShareableRenterEntitySuccess]);

  useEffect(() => {
    if (step === 2 && createShareableRenterEntityError) {
      openNotification("error", "Could not submit your data, please try again");
    }
  }, [createShareableRenterEntityError]);

  useEffect(() => {
    if (step === 3 && postShareableVerificationExamAnswersSuccess && shareableVerificationExamAnswers.result) {
      switch (shareableVerificationExamAnswers.result) {
        case "Passed":
          // get screening payment link, once we have the link we'll move to step 4
          dispatch(getScreeningPaymentLink(DEFAULT_BUNDLE.bundleId));
          setShowFailedMessage(false);
          break;
        case "Failed":
          setShowFailedMessage(true);

          // get new question and reset shareableVerificationExamEntity
          dispatch(clearVerificationExamAnswersEntity());
          handleGetExamSubmit();
          return;
        case "SystemError":
        case "ManualVerificationRequired":
        case "ValidationAttemptsExceeded":
          setShowFailedMessage(false);
          setStep(5); // move to manual verification dialog
          break;
        default:
          break;
      }
    }
  }, [
    postShareableVerificationExamAnswersSuccess,
    postShareableVerificationExamAnswersError,
    shareableVerificationExamAnswers,
  ]);

  // moving to the questions step
  useEffect(() => {
    if (step === 2 && shareableVerificationExamEntity.authenticationQuestions) {
      setStep(3);
    }
    // handling the case when the user is already need ManualVerification or Exceeded the attempts
    if (step === 2) {
      switch (shareableVerificationExamEntity.result) {
        case "SystemError":
        case "ManualVerificationRequired":
        case "ValidationAttemptsExceeded":
          setStep(5);
        default:
          break;
      }
    }
  }, [shareableVerificationExamEntity]);

  useEffect(() => {
    if (createShareableVerificationExamEntityError) {
      setAlert({
        should_show: true,
        message: "Could not get the verification questions",
        description: "",
        type: "error",
      });
    }
  }, [createShareableVerificationExamEntityError]);

  // move to payment dialog screen if we're on the 3rd step and have the screeningPaymentLink
  useEffect(() => {
    if ((step === 3 || validateRenterSuccess.status === "PaymentRequired") && screeningPaymentLink) {
      setStep(4);
    }
  }, [screeningPaymentLink]);

  useEffect(() => {
    if (alert.should_show) {
      const timeOut = setTimeout(() => {
        setAlert({ ...alert, should_show: false });
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    console.log("validateRenterSuccess", validateRenterSuccess);

    if (step === 2 && validateRenterSuccess.status === "Unverified") {
      handleGetExamSubmit();
    } else if (step === 2 && validateRenterSuccess.status === "PaymentRequired") {
      dispatch(getScreeningPaymentLink(DEFAULT_BUNDLE.bundleId));
    } else if (step === 2 && validateRenterSuccess.status === "Verified") {
      setStep(6);
      generateScreeningRequestReport();
      dispatch(clearValidateRenterSuccess());
    }
  }, [validateRenterSuccess]);

  useEffect(() => {
    // Update use was successful
    if (step === 2 && userUpdateSuccess) {
      const data = {
        srId: selectedRequestData.screening_request_id || screeningLinkData.screeningrequest,
        renterId: renter.renter_id,
        data: tuData,
      };
      console.log("the shareable dat", data);

      dispatch(createShareableRenterEntity(data));
    }
    // Update use failed
    if (step === 2 && userUpdateSuccess === false) {
      openNotification("info", isUserUpdated?.message);
    }
  }, [userUpdateSuccess]);

  const handleCancel = () => {
    handleClose();
  };

  const handleNext = () => {
    console.log("handleNext", step + 1);

    setStep(step + 1);
  };

  const handleBack = () => {
    if (stepsContent[step].backButton.backToStep === -1) {
      handleCancel();
    } else if (stepsContent[step].backButton.backToStep !== undefined && stepsContent[step].backButton.isVisible) {
      setStep(stepsContent[step].backButton.backToStep);
    }
    if (step === 3) {
      dispatch(clearUpdateUserProfile());
    }
  };

  const handleClose = () => {
    setVisible(false);
    dispatch(clearVerificationExamAnswersEntity());
    dispatch(clearScreeningPaymentLink());
    setStep(0);
    // clear url parameters, without reloading
    window.history.replaceState(null, document.title, location.pathname);
    dispatch(clearEmailScreeningLinkData());
  };

  const handleSubmit = (data: IObjectKeys) => {
    form.submit();
  };

  const handleRenterPersonalDataSubmit = async (values: IObjectKeys) => {
    const {
      street_number,
      street_name,
      unit,
      locality,
      region,
      postalCode,
      socialSecurityNumber,
      firstName,
      lastName,
      middleName,
      phoneNumber,
      phoneType,
      dateOfBirth,
    } = values;

    const regex = /\(|\)|\s|-/g;
    const phoneNumberString = phoneNumber.replace(regex, "");

    const formattedData = {
      ...values,
      person: {
        emailAddress: userData.email,
        firstName,
        middleName,
        lastName,
        phoneNumber: phoneNumberString,
        phoneType,
        socialSecurityNumber,
        dateOfBirth: getUTCDateForSR(dateOfBirth),
        homeAddress: {
          addressLine1: `${street_number} ${street_name}`,
          addressLine2: unit,
          locality,
          region,
          postalCode,
          country: "USA",
        },
        acceptedTermsAndConditions: true,
      },
      employmentStatus: "Employed",
      income: 0,
      incomeFrequency: "PerMonth",
      otherIncome: 0,
      otherIncomeFrequency: "PerMonth",
      assets: 0,
    };

    setTuData(formattedData);
    setRenterPersonalData(formattedData.person);

    const userFormattedData = {
      id: userData._id,
      email: userData.email,
      phone: phoneNumberString,
      name: firstName + " " + lastName,
      phoneType: phoneType,
      address: {
        addr: values.addr,
        street_number: street_number,
        street_name: street_name,
        unit: unit,
        city: locality,
        zipCode: postalCode,
        state: region,
        country: values.country,
      },
      addressType: "Home", //  Renter residential address is Home address
    };

    await dispatch(updateUserProfile(userFormattedData));
  };

  const handleGetExamSubmit = async () => {
    dispatch(
      createShareableVerificationExamEntity({
        srrId: shareableRenterEntity.screeningRequestRenterId,
        data: {
          ...shareableRenterEntity.data?.person,
          dateOfBirth: getUTCDateForSR(shareableRenterEntity.data?.person.dateOfBirth),
        },
      })
    );
  };

  const handleExamAnswersSubmit = async (values: IObjectKeys) => {
    const formattedData: {
      questionKeyName: string;
      selectedChoiceKeyName: any;
    }[] = [];

    Object.keys(values).forEach(function (key) {
      formattedData.push({
        questionKeyName: key,
        selectedChoiceKeyName: values[key],
      });
    });

    dispatch(
      postShareableVerificationExamAnswers({
        srrId: shareableRenterEntity.screeningRequestRenterId,
        examId: shareableVerificationExamEntity.examId,
        data: formattedData,
      })
    );
  };

  const handleProceedToPayment = () => {
    if (screeningPaymentLink) {
      window.location.href = screeningPaymentLink;
    } else {
      console.log("Couldn't proceed to payment");
    }
  };

  const onFinish = (values: IObjectKeys) => {
    switch (true) {
      case step === 1:
        handleNext();
        break;
      case step === 2:
        handleRenterPersonalDataSubmit(values);
        break;
      case step === 3:
        handleExamAnswersSubmit(values);
        break;
      default:
        break;
    }
  };

  const handleValidateRenter = () => {
    renterPersonalData.personId = shareableRenterEntity.personId;
    renterPersonalData.dateOfBirth = getUTCDateForSR(renterPersonalData.dateOfBirth);
    dispatch(
      validateRenter({
        srrId: shareableRenterEntity.screeningRequestRenterId,
        data: renterPersonalData,
      })
    );
  };

  const stepsContent = [
    // STEP 0
    {
      label: "read_before_apply",
      component: (
        <ReadBeforeApply
          renterName={renter?.name}
          agentName={selectedRequestData.agent?.name || screeningLinkData.agentName}
        />
      ),
      mainAction: {
        title: "Next",
        type: "",
        action: () => handleNext(),
      },
      secondaryAction: undefined,
      backButton: {
        isVisible: true,
        backToStep: -1,
        text: "Cancel",
      },
    },
    // STEP 1
    {
      label: "renter_disclosures",
      component: (
        <RenterDisclosures
          form={form}
          onFinish={onFinish}
          renterName={renter.name}
          screeningData={screeningData}
          setScreeningData={setScreeningData}
        />
      ),
      mainAction: {
        title: "Next",
        type: "",
        action: (data: any) => handleSubmit(data),
      },
      secondaryAction: undefined,
      backButton: { isVisible: true, backToStep: 0 },
    },
    // STEP 2
    {
      label: "renter_details",
      component: <RenterDetails form={form} />,
      mainAction: { title: "Next", action: (data: any) => handleSubmit(data) },
      secondaryAction: undefined,
      backButton: { isVisible: true, backToStep: 1 },
    },
    // STEP 3
    {
      label: "verification_exam",
      component: (
        <Exam
          form={form}
          failedMessage={failedMessage}
          showFailedMessage={showFailedMessage}
          setShowFailedMessage={setShowFailedMessage}
        />
      ),
      mainAction: { title: "Next", action: (data: any) => handleSubmit(data) },
      secondaryAction: undefined,
      backButton: { isVisible: !showFailedMessage, backToStep: 2 },
    },
    // STEP 4
    {
      label: "renter_payment",
      component: <RenterPayment />,
      mainAction: { title: "Next", action: () => handleProceedToPayment() },
      secondaryAction: undefined,
      backButton: { isVisible: false, backToStep: step },
    },
    // STEP 5
    {
      label: "manual_verification",
      component: <ManualVerification screening_request_renter_id={selectedRequestData.screening_request_id} />,
      mainAction: { title: "Close", type: "", action: () => handleClose() },
      secondaryAction: undefined,
      backButton: { isVisible: false, backToStep: step },
    },
    // STEP 6
    {
      label: "success",
      component: <Success />,
      mainAction: { title: "Close", type: "", action: () => handleClose() },
      secondaryAction: undefined,
      backButton: { isVisible: false, backToStep: step },
    },
  ];

  return (
    <div>
      <BaseModal
        classname={"single-listing-page-add-note listing-add-edit-modal request-modal"}
        cancelButtonProps={{
          className: "rb-secondary-btn sm",
        }}
        okButtonProps={{
          className: "rb-primary-btn sm",
        }}
        closeIcon={" "}
        modalTitle=""
        isModalVisible={visible}
        onCancel={handleCancel}
        modalFooter={[
          stepsContent[step].backButton.isVisible && (
            <BaseButton onClick={handleBack}>{stepsContent[step].backButton?.text ?? BUTTON_BACK_TEXT}</BaseButton>
          ),
          stepsContent[step].mainAction && (
            <BaseButton
              variant="primary"
              loading={shareableLoading || updatedUserLoading}
              disabled={shareableLoading || updatedUserLoading}
              classnames={"rb-primary-btn md br-16 w-100"}
              onClick={stepsContent[step].mainAction.action}
            >
              {stepsContent[step].mainAction.title}
            </BaseButton>
          ),
        ]}
      >
        <div style={{ marginBottom: "10px" }}>
          {alert.should_show && (
            <Alert message={alert.message} description={alert.description} type={alert.type} showIcon closable />
          )}
        </div>
        {/* DIALOG FORM */}
        <Form form={form} layout={"vertical"} className={"add-note-modal-form-wrapper"} onFinish={onFinish}>
          <Form.Item>{stepsContent[step].component}</Form.Item>
        </Form>
      </BaseModal>
    </div>
  );
};

export default RenterRequestScreeningDialog;
