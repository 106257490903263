import React from "react";

const CalendarIcon = () =>{
    return(
        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.08061 14.623H3.98585V16.2929C3.98585 16.6003 4.24601 16.8496 4.5669 16.8496H19.7516C20.0725 16.8496 20.3327 16.6003 20.3327 16.2929V1.82031C20.3327 1.5129 20.0725 1.26367 19.7516 1.26367H16.8464V0.70703C16.8464 0.399617 16.5862 0.150391 16.2653 0.150391C15.9444 0.150391 15.6843 0.399617 15.6843 0.70703V1.26367H12.7403V0.70703C12.7403 0.399617 12.4802 0.150391 12.1593 0.150391C11.8384 0.150391 11.5782 0.399617 11.5782 0.70703V1.26367H8.67297V0.70703C8.67297 0.399617 8.41282 0.150391 8.09193 0.150391C7.77103 0.150391 7.51088 0.399617 7.51088 0.70703V1.26367H4.5669C4.24601 1.26367 3.98585 1.5129 3.98585 1.82031V5.16015C3.98585 9.26258 2.2061 12.4186 0.708621 13.6388C0.520672 13.7888 0.450985 14.0355 0.534229 14.2559C0.617552 14.4763 0.835949 14.623 1.08061 14.623ZM19.1706 15.7363H5.14795V14.623H16.2653C16.4013 14.623 16.5329 14.5774 16.6373 14.494C17.3358 13.9366 18.4178 12.6097 19.1706 10.9102V15.7363ZM5.14795 2.37695H7.51088V2.93359C7.51088 3.241 7.77103 3.49023 8.09193 3.49023C8.41282 3.49023 8.67297 3.241 8.67297 2.93359V2.37695H11.5782V2.93359C11.5782 3.241 11.8384 3.49023 12.1593 3.49023C12.4802 3.49023 12.7403 3.241 12.7403 2.93359V2.37695H15.6843V2.93359C15.6843 3.241 15.9444 3.49023 16.2653 3.49023C16.5862 3.49023 16.8464 3.241 16.8464 2.93359V2.37695H19.1706V4.60351H5.14795V2.37695ZM5.13799 5.71641H19.1603C19.0293 9.24755 17.6147 12.0258 16.0375 13.5097H2.40226C4.1893 11.3284 5.04212 8.48818 5.13799 5.71641Z" fill="black"/>
        </svg>
    )
}

export default CalendarIcon