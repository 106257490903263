import React, { useCallback, useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { windowSelector } from "../../store/app/selectors";
import { push } from "connected-react-router";
import Flag from "../icons/Flag";
import ReportAProblem from "../../components/ReportAProblem/ReportAProblem";
import { useDemoContext } from "libs/contextLib";
import { Link } from "react-router-dom";

interface IPageFooter {
  isGuest?: boolean;
  isFooterCollapsed: boolean;
  collapseFooter: Function;
}

const PageFooter = (props: IPageFooter) => {
  const { type } = useSelector(windowSelector);
  const dispatch = useDispatch();
  const collapseHeaderRef = React.useRef(null);
  const collapseBodyRef = React.useRef(null);
  const collapsedPolicyRef = React.useRef(null);
  const [isReportModal, setReportModal] = useState(false);
  const { isDemo, demoAction } = useDemoContext();

  const handleCollapse = useCallback(() => {
    if (type === "mobile") {
      props.collapseFooter();
    } else {
      return;
    }
  }, [type]);

  const termsHandler = () => {};

  const privacyHandler = () => {
    if (isDemo) {
      return "/demo/policy";
    } else if (props.isGuest) {
      return "/policy";
    } else {
      return "/privacy_policy";
    }
  };

  return (
    <>
      <div className="footer-wrapper">
        <div ref={collapseHeaderRef} className="footer-left-part">
          <span onClick={handleCollapse} className="brand-title">
            TheRentBase.com
          </span>
        </div>
        <div ref={collapseBodyRef} className="footer-right-part">
          {!props.isGuest && (
            <span className="report-problem-box">
              <button
                className="report-problem-btn"
                onClick={() => {
                  isDemo ? demoAction() : setReportModal(true);
                }}
              >
                <Flag />
                <span style={{ marginLeft: "4px" }}>Report a Problem</span>
              </button>
            </span>
          )}
          <span className="footer-seperate-line"></span>
          <div className="flex justify-evenly items-center text-white text-sm gap-4">
            <a href="https://app.therentbase.com/terms?ref=app" target="_blank" className="text-white">
              Terms
            </a>
            <Link to={privacyHandler()} target={"_blank"}>
              Privacy
            </Link>
          </div>
        </div>
      </div>
      {isReportModal && <ReportAProblem onClose={() => setReportModal(false)} />}
    </>
  );
};

export default PageFooter;
