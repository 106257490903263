import { IpetsListItem } from '../../PetsInfo';
import React from 'react';
import './styles.scss';
import SelectPetsType from '../select-pets-type/SelectPetsType';
import AmountOfPets from '../amount-of-pets/AmountOfPets';
import { DeleteIcon } from '../../../../../../../../icons'
import { Input } from 'antd';

interface IPetsSelectListItemProps {
    petsItem: IpetsListItem
    setPetsList: React.Dispatch<React.SetStateAction<IpetsListItem[]>>
    handleDeletePetsItem: (id: string) => void
    dataScrollId?: string
}



const PetsSelectListItem = ({
    petsItem,
    setPetsList,
    handleDeletePetsItem,
    dataScrollId
}: IPetsSelectListItemProps
) => {
    const onChange = (name: string, value: string) => {
        setPetsList((prev) => {
            const idx = prev.findIndex(i => i.id === petsItem.id);
            const prevCopy = [...prev];
            if (name === "species" && value === "cat")
                delete prevCopy[idx].breed;

            prevCopy[idx] = {
                ...prevCopy[idx],
                [name]: value
            }
            return prevCopy;
        })
    }
    return (
        <div className="pets-select-item-wrapper" data-scroll-id={dataScrollId}>
            <SelectPetsType
                species={petsItem.species}
                onChange={onChange}
            />
            <AmountOfPets
                amount={petsItem.amount}
                species={petsItem.species}
                onChange={onChange}
            />
            {petsItem.species === "dog" &&
                <div className="pets-type-input-wrapper">
                    <label htmlFor="breedOfDogInput">What’s your dog’s breed?</label>
                    <Input
                        placeholder="Ex. Labrador"
                        name="breed"
                        id="breedOfDogInput"
                        value={petsItem.breed}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        autoComplete="off"
                    />
                </div>
            }
            <div className="delete-pet-row">
                <button>
                    <DeleteIcon />

                    <span
                        className="delete-pet-button"
                        onClick={() => handleDeletePetsItem(petsItem.id)}
                        data-test-id="delete-pets-select-list-item"
                    >
                        Remove
                    </span>
                </button>
            </div>
        </div>
    )
};

export default PetsSelectListItem;
