import { AnalyticsService } from "helpers/analyticsService";
import { IObjectKeys } from "../../../helpers/globalTypes";

export const LEAD_LEASE_STATUS_UI_TEXT: IObjectKeys = {
  no_lease: "No Lease",
  standard_lease: "Standard lease",
};

export const steps = [
  {
    label: "Connect",
    filled: true,
  },
  {
    label: "Score Lead",
    filled: false,
  },
  {
    label: "Check Credit",
    filled: false,
  },
  {
    label: "Set Follow-up",
    filled: false,
    color: "#5806B9",
  },
];

export const getQuestions = (
  handleClick: (tag: string, step: number, type: string) => void,
  addTagLoading: boolean,
  deleteTagLoading: boolean,
  setFollowUpModalProps: (data: { visible: boolean; editModal: boolean }) => void
) => {
  return [
    {
      question: "Is this lead a 💰 potential sale?",
      belong: "Score Lead",
      buttons: [
        {
          label: "Yes",
          handleClick: () => handleClick("potential_sale", 1, "add"),
          loading: addTagLoading,
        },
        {
          label: "No",
          handleClick: () => handleClick("potential_sale", 1, "delete"),
          loading: deleteTagLoading,
        },
      ],
      disabled: false,
    },
    {
      question: "Is this lead 🏆 high quality? ",
      belong: "Score Lead",
      buttons: [
        {
          label: "Yes",
          handleClick: () => handleClick("high_value", 2, "add"),
          loading: addTagLoading,
        },
        {
          label: "No",
          handleClick: () => handleClick("high_value", 2, "delete"),
          loading: deleteTagLoading,
        },
      ],
      disabled: false,
    },
    {
      question: "Is this lead relevant?",
      belong: "Score Lead",
      buttons: [
        {
          label: "Yes",
          handleClick: () => handleClick("not_relevant", 3, "delete"),
          loading: deleteTagLoading,
        },
        {
          label: "No",
          handleClick: () => handleClick("not_relevant", 3, "add"),
          loading: addTagLoading,
        },
      ],
      disabled: false,
    },
    {
      question: "Would you like to check this lead’s credit score?",
      belong: "Check Credit",
      buttons: [
        {
          label: "Coming Soon",
          handleClick: () => handleClick("coming_soon", 4, "add"),
          loading: false,
        },
      ],
      disabled: false,
    },
    {
      question: "Would you like to follow up with this lead?",
      belong: "Set Follow-up",
      buttons: [
        {
          label: " Add Follow-Up",
          handleClick: () => {
            AnalyticsService.reportScoringStepClick("add_a_follow_up", {
              step_index: 5,
              button_label: "add_a_follow_up",
            });
            setFollowUpModalProps({
              visible: true,
              editModal: false,
            });
          },
          loading: false,
        },
      ],
      disabled: false,
    },
  ];
};

export const tags = [
  {
    label: "Potential Sale",
    name: "potential_sale",
    icon: "💰",
  },
  {
    label: "High Quality",
    icon: "🏆",
    name: "high_value",
  },
  {
    label: "Not Relevant",
    icon: "😴",
    name: "not_relevant",
  },
];

export const landlordTags = [
  {
    label: "Potential Sale",
    name: "potential_sale",
    icon: "💰",
  },
];
