import React, { useEffect } from "react";
import { Switch } from "@headlessui/react";
import isNil from "lodash/isNil";

interface IPropsCheckboxGroupInput {
  field: any;
  inputComponent?: any;
  label?: string;
  value?: any;
  isEditing?: boolean;
  onChange?: any;
  editLink?: string;
  className?: string;
}

interface ICheckBoxOption {
  value: any;
  label: string;
}

function classNames(...classes: any[string]) {
  return classes.filter(Boolean).join(" ");
}

const optionIsChecked = (option: ICheckBoxOption, value: any) => {
  return value && !isNil(value[option.value]) && value[option.value];
};

export function SwitchToggle({ option, value, onChange }: { option: ICheckBoxOption; value: any; onChange: any }) {
  useEffect(() => {
    console.log("value", value);
  }, [value]);

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={optionIsChecked(option, value)}
        onChange={(e: any) => onChange({ optionValue: option.value, isChecked: e })}
        className={classNames(
          optionIsChecked(option, value) ? "bg-purple-400" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-purple-500 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            optionIsChecked(option, value) ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-700">{option.label}</span>
      </Switch.Label>
    </Switch.Group>
  );
}

const CheckboxGroupInput = ({ field, value, label, onChange }: IPropsCheckboxGroupInput) => {
  const handleOnChange = ({ optionValue, isChecked }: { optionValue: string; isChecked: boolean }) => {
    const newVal = value ? { ...value } : {};
    if (isChecked) {
      newVal[optionValue] = true;
    } else {
      delete newVal[optionValue];
    }
    onChange({ field, value: newVal });
  };

  return (
    <>
      <div>
        <label className="block text-base font-medium  text-gray-700">{label || field.label}</label>
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4">
          {field.options.map((option: ICheckBoxOption, idx: number) => {
            return (
              <div className="px-3 py-2 rounded-full bg-purple-50" key={`${value}-${idx}`}>
                <SwitchToggle option={option} value={value} onChange={handleOnChange} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CheckboxGroupInput;
