import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { windowSelector } from "../../../store/app/selectors";
import { useLocation } from "react-router";
import { setWindowType } from "../../../store/app/action";
import { LogoName, MenuIcon } from "../../icons";
import { Layout } from "antd";
import "./AuthHeaderMobileOnboard.scss";
import { push } from "connected-react-router";
import routes from "../../../routes/routesCode";
import { useDemoContext } from "libs/contextLib";
import Logo from "../../../assets/images/logo_no_bg.svg";
import { userSelector } from "../../../store/user/selectors";
import RBPremiumLogo from "../../icons/RBPremiumLogo";
import { SUBSCRIPTION_TYPES } from "helpers/constants";

const { Header } = Layout;

const AuthHeaderMobileOnboard = () => {
  const dispatch = useDispatch();
  const [isSearchFocus] = useState(false);
  const { type, collapsed } = useSelector(windowSelector);
  const location = useLocation();
  const { isDemo } = useDemoContext();
  const isDemoHomePage = location.pathname === "/demo";
  const userData = useSelector(userSelector);
  const isEmptyState =
    location.search.includes("qualifyLead=true") ||
    location.search.includes("fab=true");
  const toggleSidebar = useCallback(() => {
    const window = {
      type,
      collapsed: !collapsed,
    };
    localStorage.setItem("windowType", JSON.stringify(window));
    dispatch(setWindowType(window));
  }, [type, collapsed]);

  return (
    <Header
      className={`page-header-wrapper page-header-wrapper-onboard ${((isDemo && !isDemoHomePage) || isEmptyState) &&
        "page-header-wrapper-onboard-grey"
        }`}
    >
      <div className="header-part w-100 flex justify-content-around">
        {type !== "desktop" &&
          collapsed &&
          (!isSearchFocus || type === "desktop") &&
          location.pathname !== "/lead-flow" && (
            <div className={"hamburger-menu"} data-test-id="hamburger-menu">
              <span className={"icon-wrapper"} onClick={toggleSidebar}>
                <MenuIcon />
              </span>
            </div>
          )}
        <div className={"page-header-wrapper-onboard--logo"}>
          <div
            className={"page-header-wrapper-onboard--logo--item"}
            onClick={() =>
              isDemo
                ? dispatch(push("/demo"))
                : dispatch(push(routes.home.path))
            }
          >
            {userData.agency?.subscription_level !== SUBSCRIPTION_TYPES.free ? (
              <RBPremiumLogo />
            ) : (
              <img src={Logo} className="mr-10" />
            )}
            <LogoName />
          </div>
        </div>
      </div>
    </Header>
  );
};
export default AuthHeaderMobileOnboard;
