import Api from "./api";
import { INote } from "../components/FollowUps/types";
import { ListingNote } from "../components/Listings/helpers/mockListings";

export default class FollowUpsApi {
  static getAllFollowUps(params: INote) {
    const api = new Api("followUp");
    return api.get("", { params });
  }
  static getListingFollowUps(listingId: string) {
    const api = new Api("followUp/allReference");
    return api.get(listingId, {});
  }
  static getLandlordFollowUps(landlordId: string) {
    const api = new Api("followUp/allReference");
    return api.get(landlordId, {});
  }
  static getLeadFollowUps(leadId: string) {
    const api = new Api("followUp/allReference");
    return api.get(leadId, {});
  }

  static updateFollowUp(followUp: INote) {
    const api = new Api("notification/follow_up");
    return api.put("", { data: followUp, params: { _id: followUp._id } });
  }
  static addFollowUp(listingNoteData: ListingNote) {
    const api = new Api("followUp");
    return api.create({ data: listingNoteData });
  }
  static editFollowUp(listingNoteData: ListingNote, _id: string) {
    const api = new Api(`followUp`);
    return api.put(_id, { data: listingNoteData });
  }
  static singleFollowUpById(id:string) {
    const api = new Api(`followUp/${id}`);
    return api.list( {});
  }
  static deleteFollowUp(followUp: INote) {
    const api = new Api(`followUp/${followUp._id}`);
    return api.destroy("");
  }
}
