import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { IObjectKeys } from "../../helpers/globalTypes";
import * as types from "./types";

export function getRequirementAgentInfo(getAgentQuery: IObjectKeys) {
  return {
    type: types.GET_REQUIREMENT_AGENT,
    payload: getAgentQuery,
  };
}

export function getRequirementAgentSuccess(users: object) {
  return { type: types.GET_REQUIREMENT_AGENT_SUCCESS, payload: users };
}

export function getRequirementAgentFail(error: object) {
  return { type: types.GET_REQUIREMENT_AGENT_FAIL, payload: error };
}

export function fillRequirementFormData(formData: IObjectKeys) {
  return { type: types.FILL_REQUIREMENT_FORM_DATA, payload: formData };
}

export function createRequirementForm(formData: IObjectKeys) {
  return {
    type: types.CREATE_REQUIREMENT_FORM,
    payload: formData,
  };
}

export function createRequirementFormSuccess(payload: object) {
  return { type: types.CREATE_REQUIREMENT_FORM_SUCCESS, payload };
}

export function createRequirementFormFail(error: object) {
  return { type: types.CREATE_REQUIREMENT_FORM_FAIL, payload: error };
}

export function checkRenterStatus(formData: IObjectKeys) {
  return {
    type: types.CHECK_RENTER_STATUS,
    payload: formData,
  };
}
export function checkRenterStatusSuccess(data: any) {
  return { type: types.CHECK_RENTER_STATUS_SUCCESS, payload: data };
}

export function checkRenterStatusFail(error: object) {
  return { type: types.CHECK_RENTER_STATUS_FAIL, payload: error };
}

export function createDraftRenter(formData: IObjectKeys) {
  return {
    type: types.CREATE_RENTER_DRAFT,
    payload: formData,
  };
}
export function createDraftRenterSuccess(data: any) {
  return { type: types.CREATE_RENTER_DRAFT_SUCCESS, payload: data };
}

export function createDraftRenterFail(error: object) {
  return { type: types.CREATE_RENTER_DRAFT_FAIL, payload: error };
}

export function updateDraftRenter(formData: IObjectKeys) {
  return {
    type: types.UPDATE_RENTER_DRAFT,
    payload: formData,
  };
}
export function updateDraftRenterSuccess(data: any) {
  return { type: types.UPDATE_RENTER_DRAFT_SUCCESS, payload: data };
}

export function updateDraftRenterFail(error: object) {
  return { type: types.UPDATE_RENTER_DRAFT_FAIL, payload: error };
}

export function getDraftRenter() {
  return {
    type: types.GET_REQUIREMENT_DRAFT,
  };
}
export function getDraftRenterSuccess(data: any) {
  return { type: types.GET_REQUIREMENT_DRAFT_SUCCESS, payload: data };
}

export function getDraftRenterFail(error: object) {
  return { type: types.GET_REQUIREMENT_DRAFT_FAIL, payload: error };
}

export function editRequirementForm(formData: IObjectKeys) {
  return {
    type: types.EDIT_REQUIREMENT_FORM,
    payload: formData,
  };
}
export const editRequirementSuccess = () => {
  return {
    type: types.EDIT_REQUIREMENT_FORM_SUCCESS,
  };
};
export const editRequirementFaild = (payload: IObjectKeys) => {
  return {
    type: types.EDIT_REQUIREMENT_FORM_ERROR,
    payload,
  };
};

export function editRequirementFormInfo(formData: any) {
  return {
    type: types.EDIT_REQUIREMENT_FORM_INFO,
    payload: formData,
  };
}
export function editRequirementFormPetsInfo(formData: any) {
  return {
    type: types.EDIT_REQUIREMENT_FORM_PETS_INFO,
    payload: formData,
  };
}

export function getRenterRequirement(requirementsData: IObjectKeys) {
  return {
    type: types.GET_RENTERS_REQUIREMENT,
    payload: requirementsData,
  };
}

export function startLeadAnyAction() {
  return {
    type: types.START_LEAD_ANY_ACTION,
  };
}

export function startLeadStatusAction() {
  return {
    type: types.START_LEAD_STATUS_ACTION,
  };
}

export function leadActionError(payload: IObjectKeys) {
  return {
    type: types.LEAD_ACTION_ERROR,
    payload,
  };
}

export function leadActionSuccess() {
  return {
    type: types.LEAD_ACTION_SUCCESS,
  };
}

export function leadApproveSuccess() {
  return {
    type: types.LEAD_APPROVE_SUCCESS,
  };
}

export function leadAccessRequirement(payload: IObjectKeys) {
  return {
    type: types.LEAD_ACCESS_REQUIREMENT,
    payload,
  };
}

export function leadAccessRequirementSuccess(payload: string) {
  return {
    type: types.LEAD_ACCESS_REQUIREMENT_SUCCESS,
    payload,
  };
}

export function leadRevokeRequirement(payload: IObjectKeys) {
  return {
    type: types.LEAD_REVOKE_REQUIREMENT,
    payload,
  };
}

export function leadDeclineRequirement(payload: IObjectKeys) {
  return {
    type: types.DECLINE_FOR_REQUIREMENT,
    payload,
  };
}

export function clearREquirementData() {
  return {
    type: types.CLEAR_REQUIREMENTS_DATA,
  };
}

export function clearRenterStatus() {
  return {
    type: types.CLEAR_RENTER_STATUS,
  };
}

export function setDraftReqUpdatedSuccess (payload:boolean) {
  return {
    type: types.SET_UPDATE_RENTER_DRAFT_SUCCESS,
    payload
  }
}

export function setRequirementEditFormData (payload:IObjectKeys) {
  return {
    type:types.SET_REQUIREMENT_FORM_DATA_VALUES,
    payload
  }
}

export function editReqSuccessSelector (payload:boolean) {
  return {
    type:types.EDIT_REQ_SUCCESS_SELECTOR,
    payload
  }
}

export function leadStatusRequirement(payload: IObjectKeys) {
  return {
    type: types.LEAD_STATUS_REQUIREMENT,
    payload,
  };
}

export function leadChangeStatusSuccess() {
  return {
    type: types.LEAD_CHANGE_STATUS_SUCCESS,
  };
}

export function setLeadChangeStatusSuccess() {
  return {
    type: types.SET_LEAD_CHANGE_STATUS_SUCCESS,
  };
}

export function clearReqError () {
  return {
    type: types.SET_REQUIREMENT_ERROR_TO_BLANK
  }
}

export function notifyAgents(agents: CheckboxValueType[]) {
  return {
    type: types.NOTIFY_AGENTS,
    payload: agents,
  };
}

export function notifyAgentsSuccess(agents: CheckboxValueType[]) {
  return {
    type: types.NOTIFY_AGENTS_SUCCESS,
    payload: agents,
  };
}

export function setNotifyAgentSuccesstoFalse() {
  return {
    type: types.SET_NOTIFY_AGENTS_SUCCESS,
  };
}

export function clearReqFormSubmitted() {
  return {
    type: types.CLEAR_REQ_FORM_SUBMITTED,
  };
}

