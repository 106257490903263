import { Form, Input, Pagination } from "antd";
import BaseLeadsTable from "components/BaseLeadsTable";
import { IObjectKeys } from "helpers/globalTypes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsList } from "store/leads/action";
import { leadDataSelector, leadLoadingSelector, leadsTotalSelector } from "store/leads/selectors";
import { paginationValuesSelector, routerSelector } from "store/listings/selectors";
import { userSelector } from "store/user/selectors";

export const RENTER_LIST_COLUMNS = [
  {
    dataIndex: "_id",
    key: "name",
    title: "Name",
    render: (_id: string, rowData: IObjectKeys) => (
      <div>
        <div>
          <b>{rowData.renterOfRequirement?.name}</b>
        </div>
        <span>{rowData.renterOfRequirement?.email}</span>
      </div>
    ),
  },
];

const SelectRenter = ({ setRenter }: { setRenter: any; }) => {
  const userData = useSelector(userSelector);
  const pagination = useSelector(paginationValuesSelector);
  const loading = useSelector(leadLoadingSelector);
  const total = useSelector(leadsTotalSelector);
  const leadsData = useSelector(leadDataSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const router = useSelector(routerSelector);

  const handleRowClick = (record: any) => {
    setRenter(record.renterOfRequirement);
  };

  const getRenterList = (page?: number) => {
    dispatch(
      getLeadsList({
        //@ts-ignore
        current: page || currentPage,
        size: 20,
        search,
        isModalSearch: true,
        myLeads: true,
      })
    );
  };

  const onValuesChange = (changedValues: any = "") => {
    setSearch(changedValues.search?.trim());
  };

  // debounding search input to avoid unnecessary api calls
  useEffect(() => {
    const getData = setTimeout(() => {
      setCurrentPage(1);
      dispatch(
        getLeadsList({
          //@ts-ignore
          current: 1,
          size: 20,
          search,
          isModalSearch: true,
          myLeads: true,
        })
      );
    }, 1000);
    return () => clearTimeout(getData);
  }, [search]);

  const handlePagechange = (page: number) => {
    setCurrentPage(page);
    getRenterList(page);
  };

  useEffect(() => {
    if (userData && userData.agent_id) getRenterList();
  }, [userData]);

  return (
    <>
      <Form onValuesChange={onValuesChange} className="attache-property-form">
        <Form.Item name="search">
          <Input placeholder="search" />
        </Form.Item>
      </Form>
      <div style={{ marginBottom: "12px" }} />
      <BaseLeadsTable
        id="renter-table"
        classnames={`shared-table renter-listing-table`}
        bordered={false}
        loading={loading}
        columns={RENTER_LIST_COLUMNS}
        dataSource={leadsData || []}
        rowKey={(record) => record._id}
        dataTestId="table"
        onRow={(r) => {
          return {
            onClick: () => handleRowClick(r),
          };
        }}
        onChange={() => false}
        antdProps={{
          tableLayout: "fixed",
          size: "small",
          sticky: true,
          showHeader: false,
          pagination: false,
        }}
      />
      <div className="modal-pagination">
        <Pagination current={currentPage} showSizeChanger={false} total={total} pageSize={20} responsive onChange={handlePagechange} />
      </div>
    </>
  );
};

export default SelectRenter;
