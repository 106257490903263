import { Button } from "antd";
import React from "react";
import "./styles.scss";
import BaseButton from "components/Shared/BaseButton";
interface IVerifyButton {
  disabled: boolean;
  onClick: () => void;
  children: JSX.Element | string;
  dataTestId: string;
  classname: string;
  placement?: "center" | "left" | "right";
  loading?: boolean;
}
export const VerifyButton = ({
  disabled,
  onClick,
  children,
  dataTestId,
  classname,
  placement,
  loading,
}: IVerifyButton) => {
  return (
    <BaseButton
      className={`${classname} ${placement ?? ""}`}
      disabled={disabled}
      data-test-id={dataTestId}
      onClick={onClick}
      loading={loading}
    >
      {children}
    </BaseButton>
  );
};
