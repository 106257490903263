import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import routes from "../../../../../../../../routes/routesCode";
import { Tooltip } from "antd";
import { TbBroadcast } from "react-icons/tb";
import { Listing, StatusEnum } from "../../../../../../helpers/mockListings";
import { IObjectKeys } from "../../../../../../../../helpers/globalTypes";
import ListingCardSliderOrImage from "./list-card-item-slider-or-image/ListingCardSliderOrImage";
import {
  beautifiedKeyStylingDashAndFirstUpper,
  getBrowserDateFormat,
  LandlordNameWrapper,
} from "../../../../../../../../helpers/globalFunctions";
import { BathroomIconLead, BedroomIconLead, KeyIcon } from "../../../../../../../icons";
import { userSelector } from "../../../../../../../../store/user/selectors";
import { EXTERNAL_SOURCES } from "../../../../../../../../helpers/constants";
import { AnalyticsService } from "helpers/analyticsService";
import "./ListCardItem.scss";
import { useDemoContext } from "libs/contextLib";
import MlsLogo from "../../../../../../../../assets/images/mls_logo.svg";
import { formatCurrency } from "../../../../../../../../helpers/currencyHelper";
import { formatDate } from "helpers/dateHelper";
import { FaCircle } from "react-icons/fa";
import { EVENT_NAME } from "helpers/analytics/eventEnums";

interface ListCardItemProps {
  listItem: Listing;
  handleLandlordLinkClick?: (landlordInfo: IObjectKeys) => void;
  setCheckedListing: React.Dispatch<React.SetStateAction<{}>>;
  cardBackgroundColor?: string;
}
const ListCardItem: React.FC<ListCardItemProps> = ({
  listItem,
  handleLandlordLinkClick,
  setCheckedListing,
  cardBackgroundColor,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelector);
  const [listingAvailabilityStatus, SetListingAvailabilityStatus] = useState<string | undefined>("");
  const [dateToDisplay, setDateToDisplay] = useState("");
  const { isDemo, demoAction } = useDemoContext();
  const isCoListingAgent = listItem?.co_list_agents?.includes(userData.agent_id);
  const isListingOwner = userData.agent_id === listItem?.agent_id || isCoListingAgent;
  const isClawListing = listItem.source ? EXTERNAL_SOURCES.CLAW_SOURCES.includes(listItem.source) : false;

  const renderAgentsOfList = (listing: Listing) => {
    return listItem?.allAgentOfList?.map((agent: { name: any; }) => <div key={agent.name}>{agent?.name}</div>);
  };

  useEffect(() => {
    const isAvailableNow = moment(listItem?.availability_date_timestamp).diff(new Date().getTime()) <= 0;
    if (isAvailableNow) {
      setDateToDisplay("");
    }
    if (listItem.status) {
      const availabilityStatus = beautifiedKeyStylingDashAndFirstUpper(
        // @ts-ignore
        StatusEnum[listItem.status.toLocaleLowerCase()]
      );
      SetListingAvailabilityStatus(availabilityStatus);
    }

    if (listItem.status === StatusEnum.available && listItem.availability_date && !isAvailableNow) {
      const _date = moment(listItem.availability_date_timestamp).format(getBrowserDateFormat());

      setDateToDisplay(_date);
    }

    if (listItem.status === StatusEnum["off-market"] && listItem.lease_ending_date) {
      const _date = `Lease Ends on ${formatDate(listItem.lease_ending_date)}`;
      setDateToDisplay(_date);
    }
    if (listItem.status === StatusEnum["off-market"] && !listItem.lease_ending_date) {
      setDateToDisplay("");
    }
  }, [
    listingAvailabilityStatus,
    listItem,
    listItem?.status,
    listItem?.addr,
    listItem?.unit,
    listItem?.lease_ending_date,
    listItem?.availability_date,
  ]);

  const goToSingleListingPage = () => {
    let path = routes.listings.path;
    AnalyticsService.reportClickEvent(EVENT_NAME.LISTING_CLICK, "listing_image", "");
    if (isDemo) {
      path = routes.demoListings.path;
    }
    dispatch(push(`${path}/${listItem?.public_id}?lId=${listItem.agent_id}`));
  };

  return (
    <div className="relative border border-gray-100 shadow rounded-lg cursor-pointer hover:bg-gray-50">
      <ListingCardSliderOrImage goToSingleListingPage={goToSingleListingPage} listItem={listItem} />

      <div className="flex flex-nowrap justify-start gap-1 items-center mt-1 absolute right-2 top-0">
        {!isClawListing ? (
          <div>
            <Tooltip
              overlayClassName={"fu-page"}
              placement="top"
              title={
                listItem?.published_rdt ? "Listing is currently syndicated" : "Listing is not currently syndicated"
              }
            >
              <TbBroadcast
                stroke={listItem?.published_rdt ? "#3edeb8" : "#ccc"}
                style={{ height: "1.3em", width: "1.3em" }}
              />
            </Tooltip>
          </div>
        ) : (
          <div>
            <img src={MlsLogo} alt="MLT logo" className="h-6 w-6" />
          </div>
        )}
        <Tooltip
          overlayClassName={"fu-page"}
          placement="top"
          title={listItem.status === "Available" ? "Listing is currently available" : "Listing is not available"}
        >
          <FaCircle className={`h-4 w-4 ${listItem.status === "Available" ? "fill-green-500" : "fill-gray-200"}`} />
        </Tooltip>
      </div>

      <div className="px-4 pt-2 pb-4" onClick={() => goToSingleListingPage()}>
        <span className="text-base font-semibold">{listItem.generatedAddr}</span>

        <div className="mt-1 text-sm text-gray-700 gap-3 flex justify-normal">
          <div className="flex flex-nowrap gap-1 items-center">
            {listItem.asked_rent !== undefined ? formatCurrency(listItem.asked_rent, undefined, "/mo") : "NA"}
          </div>
          <div className="flex flex-nowrap gap-1 items-center">
            <BedroomIconLead className="h-4 w-4" /> {listItem.bedrooms} Bed
          </div>
          <div className="flex flex-nowrap gap-1 items-center">
            <BathroomIconLead /> {listItem.bathrooms} Bath
          </div>
        </div>

        {isListingOwner ? (
          <div className="text-sm mt-2">
            <LandlordNameWrapper
              icon={<KeyIcon />}
              landlordOfList={listItem.landlordOfList}
              isListingOwner={isListingOwner}
              handleLandlordLinkClick={isDemo ? () => demoAction("listing") : handleLandlordLinkClick}
              setCheckedListing={() => setCheckedListing(listItem)}
              listItem={listItem}
            />
          </div>
        ) : (
          <div className="text-sm">{renderAgentsOfList(listItem)}</div>
        )}
      </div>
    </div>
  );
};

export default memo(ListCardItem);
