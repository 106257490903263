import React, {useEffect} from "react";
import "./ListingAllPhotosMobile.scss"
import {useDispatch, useSelector} from "react-redux";
import {listingDataSelector, listingLoadingSelector} from "../../../../store/listings/selectors";
import {getOneListingFromPublicId} from "../../../../store/listings/action";
import {useParams} from "react-router-dom";
import {Spin} from "antd";
import {push} from "connected-react-router";

const ListingAllPhotosMobile = () => {
    const dispatch = useDispatch()
    const {lId} = useParams()
    const loadingListing = useSelector(listingLoadingSelector);
    const listingData = useSelector(listingDataSelector);

    useEffect(() => {
      dispatch(getOneListingFromPublicId(lId));
    }, [dispatch,lId]);

    return (
        <Spin spinning={loadingListing}>
            <div className={"listing-all-photos-page"}>
                <div className={"listing-all-photos-page--top"}>
                    <button onClick={() => {
                        dispatch(push(`/listings/${lId}?lId=${listingData.agent_id}`))
                    }
                    }> {"< Back"} </button>
                    <h3>Images</h3>
                </div>
                <div className={"listing-all-photos-page--images"}>
                    {listingData?.images?.map((item: any, index: string) => {
                        return (
                            <img
                                className="listing-image-item"
                                src={item?.url || item?.url}
                                alt={item?.name}
                                key={item.id}
                            />
                        )
                    })}
                </div>
            </div>
        </Spin>
    )
}

export default ListingAllPhotosMobile
