import React from "react";

const GoBackArrow = ({ setDatePickerVisible }: any) => {
  return (
    <svg
      width="16"
      height="30"
      viewBox="0 0 16 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"follow-up-datepicker-back-icon"}
      onClick={() => {
        setDatePickerVisible(false);
      }}
    >
      <path
        d="M0.42385 16.0706L13.465 29.557C14.0373 30.1475 14.9644 30.1475 15.5381 29.557C16.1103 28.9665 16.1103 28.0075 15.5381 27.417L3.5313 15.0007L15.5366 2.58439C16.1088 1.99391 16.1088 1.03483 15.5366 0.442856C14.9644 -0.147619 14.0358 -0.147619 13.4636 0.442856L0.422405 13.9291C-0.141042 14.5135 -0.141042 15.4876 0.42385 16.0706Z"
        fill="black"
      />
    </svg>
  );
};

export default GoBackArrow;
