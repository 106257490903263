import { Tooltip } from "antd";
import { RenterScreeningRequestActionColumn } from "components/Lead/renter-page/RequestScreeningModal/components/RenterScreeningReqAction";
import { AnalyticsService } from "helpers/analyticsService";
import { EXTERNAL_SOURCES } from "helpers/constants";
import { IObjectKeys, fieldDto } from "helpers/globalTypes";
import React from "react";
import { TbBroadcast } from "react-icons/tb";
import { Link } from "react-router-dom";
import { landlordTags } from "../../Lead/shared/constants";
import SingleTag from "../../Lead/shared/lead-availabilities/components/single-tag/SingleTag";
import BaseButton from "../BaseButton";
import DateColumn from "./../Columns/DateColumn";
import ScreeningStatusColumn from "./../Columns/ScreeningStatusColumn";
import QuickContact from "./QuickContact/QuickContact";
import { timeSince } from "helpers/dateHelper";
import { isCLAWUser } from "helpers/userHelpers";
import LockIcon from "components/Leads/icons/LockIcon";
import { usersFreeSubscriptionLimits } from "helpers/globalFunctions";
import { formatCurrency } from "helpers/currencyHelper";

const tablesInfo = (userData: any, isNotPremiumUser: boolean, setPremiumModalVisible?: any) => {
  const usersFreeSubscriptionLimit: any = usersFreeSubscriptionLimits({ userData, isNotPremiumUser });

  const LISTINGS_COLUMNS = [
    {
      dataIndex: "_id",
      key: "addr",
      title: "Address",
      width: "253px",
      render: (_id: string, rowData: IObjectKeys) => {
        return {
          children: (
            <div className="listing-table-address-column">
              <span>{rowData.addr}</span>
            </div>
          ),
          props: {
            "data-test-id": "addr",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "town",
      title: "Town",
      width: "calc(100%/6)",
      render: (_id: string, rowData: IObjectKeys) => {
        return {
          children: <span>{rowData?.town}</span>,
          props: {
            "data-test-id": "town",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "current_rent",
      title: "Price",
      width: "calc(100%/6)",
      sorter: (a: IObjectKeys, b: IObjectKeys) => (a.asked_rent || 0) - (b.asked_rent || 0),
      render: (_id: string, rowData: IObjectKeys) => {
        return {
          children: <span>{formatCurrency(rowData?.asked_rent)}</span>,
          props: {
            "data-test-id": "current_rent",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "bedrooms",
      title: "Bed",
      align: "center",
      width: "calc(100%/6)",
      sorter: (a: IObjectKeys, b: IObjectKeys) => a.bedrooms - b.bedrooms,
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return {
          children: <span>{rowData?.bedrooms}</span>,
          props: {
            "data-test-id": "bed",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "bathrooms",
      title: "Bath",
      width: "calc(100%/6)",
      align: "center",
      sorter: (a: IObjectKeys, b: IObjectKeys) => a.bathrooms - b.bathrooms,
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return {
          children: <span>{rowData?.bathrooms}</span>,
          props: {
            "data-test-id": "bath",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "status",
      title: "Availability",
      width: "calc(100%/6)",
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return {
          children: (
            <div className="available_status_td">
              <div className={`small-cicle-5px ${rowData?.status === "Available" ? "green" : "red"}`} />
              {rowData?.status}
            </div>
          ),
          props: {
            "data-test-id": "status",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "landlord_name",
      title: "Landlord",
      width: "calc(100%/6)",
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        const isListingOwner = userData.agent_id === rowData?.agent_id;
        return {
          children: (
            <div className="landlord-name-wrapper">
              {isListingOwner ? <QuickContact rowData={rowData} /> : <div className="agent-name">{rowData?.agentOfList?.name}</div>}
            </div>
          ),
          props: {
            "data-test-id": "landlord_name",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "published_rdt",
      title: "Syndicated",
      width: "calc(100%/6)",
      render: (_id: fieldDto, listingData: IObjectKeys) => {
        return {
          children: (
            <div className="syndicated-icon align-center">
              {!EXTERNAL_SOURCES.CLAW_SOURCES.includes(listingData.source) && (
                <Tooltip
                  overlayClassName={"fu-page"}
                  placement="top"
                  title={
                    listingData?.published_rdt?.published_at
                      ? `Syndicated ${timeSince(listingData?.published_rdt?.published_at)}`
                      : "This listing is not syndicated"
                  }
                >
                  <TbBroadcast stroke={listingData?.published_rdt?.published_at ? "#3edeb8" : "#ccc"} />
                </Tooltip>
              )}
            </div>
          ),
          props: {
            "data-test-id": "published_rdt",
          },
        };
      },
    },
  ];

  const collator = new Intl.Collator();

  const LANDLORDS_COLUMNS = [
    {
      dataIndex: "_id",
      key: "name",
      title: "Name ",
      width: "253px",
      sorter: (a: IObjectKeys, b: IObjectKeys) => collator.compare(a.lowerCaseName, b.lowerCaseName),
      render: (_id: string, rowData: IObjectKeys) => {
        //@ts-ignore
        let newTags = [];
        if (rowData?.tags) {
          newTags = landlordTags.filter((tag) => rowData?.tags.includes(tag.name));
        }
        return {
          children: (
            <div className="landlord-table-name-column flex align-center">
              <span className="name">{rowData?.name}</span>
              <div className="flex ml-10">
                {
                  //@ts-ignore
                  newTags.map((tag, index) => (
                    <SingleTag
                      key={`tag-${tag.name}-${index}`}
                      icon={tag.icon}
                      label={tag.label}
                      name={tag.name}
                      classnames={`tag-wrapper-${index}-child`}
                      type={"landlord"}
                    />
                  ))
                }
              </div>
            </div>
          ),
          props: {
            "data-test-id": "name",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "notesOfLandlord",
      title: "Notes",
      width: "calc(100%/3)",
      align: "center",
      render: (_id: string, rowData: IObjectKeys) => {
        return {
          children: (
            <div className="landlord-table-name-column">
              <span className="name">{rowData?.notes_count}</span>
            </div>
          ),
          props: {
            "data-test-id": "notes",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "followupsOfLandlord",
      title: "Follow Ups",
      width: "calc(100%/5)",
      align: "center",
      sorter: (a: IObjectKeys, b: IObjectKeys) => a?.in_progress_followups_count - b?.in_progress_followups_count,
      render: (_id: string, rowData: IObjectKeys) => {
        return {
          children: (
            <div className="landlord-table-name-column pl-6">
              <span className="name">{rowData?.in_progress_followups_count}</span>
            </div>
          ),
          props: {
            "data-test-id": "followups",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "listingsOfLandlord",
      title: "Listings",
      width: "calc(100%/5)",
      align: "center",
      sorter: (a: IObjectKeys, b: IObjectKeys) => a?.listings_count - b?.listings_count,
      render: (_id: string, rowData: IObjectKeys) => {
        return {
          children: (
            <div className="landlord-table-name-column">
              <span className="name">{rowData?.listings_count}</span>
            </div>
          ),
          props: {
            "data-test-id": "listings",
          },
        };
      },
    },
    {
      dataIndex: "_id",
      key: "contact_&_add_listing",
      title: "",
      width: "calc(100%/3)",
      render: (_id: string, rowData: IObjectKeys) => {
        const isDemo = window.location.pathname.includes("demo");
        const isClawUser = isCLAWUser(userData);
        return {
          children: (
            <div className="flex flex-wrap gap-2 justify-end">
              <QuickContact rowData={rowData} page="landlords" />
              {!isClawUser && (
                <span className="landlords-add-listing-button" id={isDemo ? "landlordQuickContactButton" : ""} data-test-id="New-List-Land">
                  {!isDemo ? (
                    usersFreeSubscriptionLimit?.listings ? (
                      <BaseButton
                        variant="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPremiumModalVisible(true);
                        }}
                        className="btn-add-listing-locked"
                      >
                        <>
                          {usersFreeSubscriptionLimit?.listings && <LockIcon />}
                          Add Listing
                        </>
                      </BaseButton>
                    ) : (
                      <BaseButton
                        variant="primary"
                        href={`../manageListing?step=0&landlordId=${rowData._id}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          AnalyticsService.reportAddListingClick("landlords_list");
                        }}
                      >
                        Add Listing
                      </BaseButton>
                    )
                  ) : (
                    <span
                      onClick={() => {
                        AnalyticsService.reportAddListingClick("landlords_list");
                      }}
                    >
                      Add Listing
                    </span>
                  )}
                </span>
              )}
            </div>
          ),
          props: {
            "data-test-id": "notes",
          },
        };
      },
    },
  ];

  const RENTER_SR_REPORTS_COLUMNS = ({
    setSelectedAgentData,
    isOpenContactModal,
    setIsOpenContactModal,
    setSelectedRequestData,
    setRenterScreeningModalVisible,
    setIsCallTUModalVisible,
    setIsCallRentBaseModalVisible,
    setIsViewReportModalVisible,
    setReportData,
  }: any) => [
    {
      dataIndex: "screening_status",
      key: "Status",
      title: "Status",
      width: "160px",
      render: (_: string, rowData: any) => ScreeningStatusColumn(rowData),
    },
    {
      dataIndex: "_id",
      key: "property_address",
      title: "Property",
      render: (_id: string, rowData: any) => (
        <div className="listing-table-address-column">
          <span>{rowData.listing?.generatedAddr}</span>
        </div>
      ),
    },
    {
      dataIndex: "created_at",
      key: "Created",
      title: "Created",
      width: "150px",
      render: DateColumn,
    },
    {
      dataIndex: "agent",
      key: "agent",
      title: "Agent",
      width: "200px",
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return {
          children: (
            <BaseButton
              variant="link"
              dataTestId={"landlord-quick-contact-modal-open-from-listing"}
              onClick={() => {
                setSelectedAgentData(rowData.agent);
                !isOpenContactModal && setIsOpenContactModal(true);
              }}
            >
              {rowData.agent?.name || "Contact"}
            </BaseButton>
          ),
          props: {
            "data-test-id": "agent",
          },
        };
      },
    },
    {
      dataIndex: "action",
      key: "Actions",
      title: "",
      width: "200px",
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return {
          children: (
            <RenterScreeningRequestActionColumn
              rowData={rowData}
              setRenterScreeningModalVisible={setRenterScreeningModalVisible}
              setSelectedRequestData={setSelectedRequestData}
              setIsViewReportModalVisible={setIsViewReportModalVisible}
              setReportData={setReportData}
              setIsCallTUModalVisible={setIsCallTUModalVisible}
              setIsCallRentBaseModalVisible={setIsCallRentBaseModalVisible}
            />
          ),
          props: {
            "data-test-id": "renter-action",
          },
        };
      },
    },
  ];

  return {
    LISTINGS_COLUMNS,
    LANDLORDS_COLUMNS,
    RENTER_SR_REPORTS_COLUMNS,
  };
};

export default tablesInfo;
