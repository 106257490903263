import React, { ReactElement, useEffect, useState } from "react";
import { Form, Upload } from "antd";
import "./ListingImagesSection.scss";
import BaseButton from "../../Shared/BaseButton";
import { useSelector } from "react-redux";
import { UploadFile } from "antd/es/upload/interface";
import { getAuthToken } from "../../../helpers/authUtils";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { beforeUploadCheck } from "../../../helpers/fileUtils";
import { ACCEPTED_MEDIA_FILES, ROLE_TYPES } from "../../../helpers/constants";
import { BrowseFilesIcon, DragIcon, SearchBigIcon } from "../../icons";
import { isPublicListingSelector, listingDataSelector } from "../../../store/listings/selectors";
import UploadedListItem from "./UploadedListItem";
import { IColViewConfig } from "./ListingImagesList";
import UploadingImageLoader from "../../BaseImageUploadingLoader";
import { userSelector } from "store/user/selectors";
import { useDemoContext } from "libs/contextLib";

const { Dragger } = Upload;

interface IListingImagesEmptyState {
  addEditMode?: boolean;
  addEditModeClassname?: string;
  onChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined;
  setFileList: React.SetStateAction<any>;
  fileList?: UploadFile[];
  showConfirm?: ((file: UploadFile<any>) => boolean | void | Promise<boolean | void>) | undefined;
  onPreview: Function;
  enablePreview: Boolean;
  canEdit: Boolean;
  colViewConfig: IColViewConfig;
  imageCardClassname: string;
  showConfirmation: Function;
  reportProblem?: boolean;
  reportProblemImageDrogText?: string;
}

const ListingImagesEmptyState = (props: IListingImagesEmptyState) => {
  const [isDraggableVisible, setIsDraggableVisible] = useState(false);
  const { _id } = useSelector(listingDataSelector);
  const listingData = useSelector(listingDataSelector);
  const { id: senderId } = useSelector(userSelector);
  const isPublicListing = useSelector(isPublicListingSelector);
  const { isDemo } = useDemoContext();

  useEffect(() => {
    if (props.addEditMode) {
      setIsDraggableVisible(true);
    }
  }, []);

  const requstUrl = props.reportProblem
    ? `${process.env.REACT_APP_BASE_URL}/report/media/${senderId}`
    : `${process.env.REACT_APP_BASE_URL}/listing/media/${_id}`;

  const addPhotoHandler = (e: any) => {
    e.stopPropagation();
    setIsDraggableVisible(true);
  };
  return (
    <div className={`drag-container ${props.reportProblem ? "report-page-drag-container" : ""}`}>
      <Form.Item name="listings-photos">
        <Dragger
          method={"PUT"}
          className={`listing-photo-upload-container ${props.addEditMode ? "mb-30" : ""}`}
          accept={`${ACCEPTED_MEDIA_FILES}`}
          name={"image"}
          multiple={true}
          action={requstUrl}
          disabled={isDemo ? true : isPublicListing}
          headers={{
            Authorization: "Bearer " + getAuthToken(),
          }}
          itemRender={(originNode: ReactElement, file: UploadFile, fileList?: object[]) => {
            if (props.addEditMode || props.reportProblem) {
              if (file.status === "uploading") {
                return <UploadingImageLoader key={file.uid} />;
              } else if (file.status === "done") {
                return (
                  <UploadedListItem
                    key={file.uid}
                    imageCardClassname={props.imageCardClassname}
                    showConfirm={props.showConfirmation}
                    colViewConfig={props.colViewConfig}
                    enablePreview={props.enablePreview}
                    onPreview={props.onPreview}
                    file={file}
                    canEdit={props.canEdit}
                  />
                );
              }
            }
          }}
          onChange={props.onChange}
          listType="picture-card"
          fileList={props.addEditMode || props.reportProblem ? props.fileList : listingData.images?.slice(0, 6)}
          beforeUpload={(file) => beforeUploadCheck(file, listingData.images)}
          onRemove={props.showConfirm}
          showUploadList={{
            showRemoveIcon: !!props.addEditMode,
            showPreviewIcon: !!props.addEditMode,
          }}
        >
          {props.addEditMode || props.reportProblem ? (
            <div
              className={`add-edit-mode-empty-state-drag-icon-wrapper ${
                props.reportProblem ? "report-problem-icon-wrapper" : ""
              }`}
            >
              <DragIcon />
              <span className={`drag-text-wrapper ${props.reportProblem ? "style-for-report-problem" : ""}`}>
                {props.reportProblem
                  ? props.reportProblemImageDrogText
                  : "Drag and Drop Files \n or\n Click Here to Upload"}
              </span>
            </div>
          ) : (
            <div className={`listing-empty-state-wrapper ${props.addEditModeClassname ?? ""}`}>
              {!isDraggableVisible ? (
                <div>
                  <div className="flex justify-center">
                    <SearchBigIcon />
                  </div>
                  <div className="flex justify-center">
                    {!isPublicListing && (
                      <BaseButton onClick={addPhotoHandler} className="w-54" modalType="listing" variant="link">
                        Add Photos
                      </BaseButton>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div className="flex justify-center">
                      <DragIcon />
                    </div>
                    <div className="flex justify-center">Drag and Drop Your Images Anywhere Here</div>
                  </div>
                  {!props.reportProblem && (
                    <>
                      <div className="drag-icons-divider">
                        <span>Or</span>
                      </div>
                      <div className="flex-column-centered drag-icon-wrapper">
                        <div className="flex justify-center">
                          <BrowseFilesIcon />
                        </div>
                        <div className="flex justify-center">Browse Your Files</div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </Dragger>
      </Form.Item>
    </div>
  );
};

export default React.memo(ListingImagesEmptyState);
