import React, { memo, useEffect, useState } from "react";
import { Card, Col, Form, Input, Row, Typography, Spin, Radio } from "antd";

import { CheckedIcon } from "../../icons";
import { IObjectKeys } from "../../../helpers/globalTypes";
import MaskedInput from "../../Shared/MaskedPhoneInput";
import {
  CHARACTERS_VALIDATION,
  EMAIL_VALIDATION,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
  REQUIRED,
  SETTINGS_PHONE_NUMBER_VALIDATION,
  SHAREABLE_ADDRESS_1_VALIDATION,
  SHAREABLE_LOCALITY_VALIDATION,
  SHAREABLE_POSTAL_CODE_VALIDATION,
  SHAREABLE_STATE_VALIDATION,
  SHAREABLE_STREET_NAME_VALIDATION,
} from "../../../helpers/validations";
import { useDispatch, useSelector } from "react-redux";
import {
  authLoadingSelector,
  exportAgentDataLoadingSelector,
  exportAgentDataSuccessSelector,
  passwordResetLoadingSelector,
  phoneVerificationCodeErrorSelector,
  resetPasswordSuccessSelector,
  updateUserSettingsSuccessSelector,
  userSelector,
  verifyCodeSuccessSelector,
} from "../../../store/user/selectors";
import {
  SHAREABLE_PHONE_TYPES,
  ROLE_TYPES,
  US_DATE_FORMAT,
  SSO_SOURCES,
  SUBSCRIPTION_TYPES,
} from "../../../helpers/constants";
import {
  clearErrorStates,
  exportAgentData,
  getLoggedInUser,
  getPhoneVerificationCode,
  resetPassword,
  setExportAgentDataSuccess,
  setUpdatedUserSettingsSuccess,
  setVerificationPhoneSuccess,
  updateUserSettings,
} from "../../../store/user/action";
import { sanitizePhoneNumber, getIsLastOneWeek, scrollToFirstErrorField } from "../../../helpers/globalFunctions";
import PasswordConfirmModal from "./PasswordConfirmModal";
import VerifyPhoneModal from "./VerifyPhoneModal";
import "./styles.scss";
import BaseButton from "../../Shared/BaseButton";
import { FieldData } from "rc-field-form/lib/interface";
import { windowSelector } from "../../../store/app/selectors";
import UploadUserImage from "../ImageUpload";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import { AddressChangeParams } from "components/Shared/PlacesAutocomplete/types";
import { useWindowSize } from "../../../hooks/useWindowSize";
import NotificationsPart from "./NotificationsPart";
import ExportDataSuccessModal from "../export-data-success-modal/ExportDataSuccessModal";
import BaseTooltip from "../../Shared/base-tooltip/BaseTooltip";
import { push } from "connected-react-router";
import TextArea from "antd/lib/input/TextArea";
import PremiumUpgradeModal from "../../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import BillingAndInvoices from "./billing/BillingAndInvoices";
import { getInvoices, getSubscriptions, getUpcomingInvoices } from "../../../store/payment/action";
import { AnalyticsService } from "helpers/analyticsService";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { useAppContext } from "libs/contextLib";
import { isCLAWUser } from "helpers/userHelpers";
import { formatDate } from "../../../helpers/dateHelper";

const { Title, Text, Link } = Typography;

const ProfileSettings = () => {
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const userData = useSelector(userSelector);
  const loading = useSelector(authLoadingSelector);
  const getPasswordCodeSuccess = useSelector(resetPasswordSuccessSelector);
  const verifyCodeSuccess = useSelector(verifyCodeSuccessSelector);
  const passwordResetLoading = useSelector(passwordResetLoadingSelector);
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);
  const updateUserSettingsSuccess = useSelector(updateUserSettingsSuccessSelector);
  const [imageData, setImageData] = useState();
  const { type } = useSelector(windowSelector);
  const [remindersCollapse, setRemindersCollapse] = useState(false);
  const [isValidateMessageVisible, setIsValidateMessageVisible] = useState(false);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const [isPhoneValueChanged, setIsPhoneValueChanged] = useState(false);
  const [switchInputData, setSwitchInputData] = useState<IObjectKeys>({});
  const [verifyPhoneModalVisible, setVerifyPhoneModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [showWhyText, setShowWhyText] = useState(false);
  const exportAgentDataLoading = useSelector(exportAgentDataLoadingSelector);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const exportAgentDataSuccess = useSelector(exportAgentDataSuccessSelector);
  const phoneVerified = useSelector(phoneVerificationCodeErrorSelector);
  const [phoneCodeWasSent, setPhoneCodeWasSent] = useState(false);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const { isNotPremiumUser } = useAppContext();
  const closeExportModalHandler = () => {
    setIsExportModalVisible(false);
  };

  const onSubmit = (data: IObjectKeys) => {
    const formData: IObjectKeys = {
      ...data,
      office_name: data.office_name,
      phone: data.phone?.length ? sanitizePhoneNumber(data.phone) : undefined,
    };
    if (userData.email !== formData.email && !passwordModalVisible) {
      return setPasswordModalVisible(true);
    }
    const { password } = form.getFieldsValue(["password"]);

    dispatch(
      updateUserSettings({
        ...formData,
        emailNotifications: switchInputData.emailNotifications,
        smsNotifications: switchInputData.smsNotifications,
        listingsRenewalNotifications: switchInputData.listingsRenewalNotifications,
        _id: userData._id,
        password,
      })
    );
    setIsPhoneValueChanged(false);
  };

  const handleFieldsChange = (changedFields: FieldData[]) => {
    const { getFieldError } = form;
    if (getFieldError("phone") && getFieldError("phone").length > 0) {
      setIsValidateMessageVisible(true);
    }
    if (changedFields) {
      const phoneField = changedFields.find((field: IObjectKeys) => field.name[0] === "phone");
      const isEmpty = phoneField?.value !== "(___) ___-____";
      const isEqual = sanitizePhoneNumber(phoneField?.value || "") === userData?.phone;
      // @ts-ignore
      if (changedFields[0].name[0] === "phone" && isEmpty && !isEqual) {
        setIsPhoneValueChanged(true);
      } else {
        setIsPhoneValueChanged(false);
      }
    }
  };

  const onFinishFailed = () => {
    scrollToFirstErrorField();
  };

  const onVerifyPhoneClick = () => {
    dispatch(getPhoneVerificationCode());
    setPhoneCodeWasSent(true);
    setVerifyPhoneModalVisible(true);
  };

  useEffect(() => {
    const { getFieldError } = form;
    if (getFieldError("phone") && getFieldError("phone").length > 0) {
      return;
    } else {
      if (isPhoneValueChanged) {
        form.submit();
        setIsPhoneValueChanged(false);
      }
    }
    if (!form.getFieldValue("phone") || form.getFieldValue("phone") === "(___) ___-____") {
      setIsValidateMessageVisible(true);
    } else {
      setIsValidateMessageVisible(false);
    }
  }, [form.getFieldValue("phone")]);

  useEffect(() => {
    if (Object.keys(userData).length) {
      const dataToSet: IObjectKeys = {};
      [
        "email",
        "phone",
        "name",
        "pushNotifications",
        "smsNotifications",
        "emailNotifications",
        "defaultState",
        "defaultCity",
        "listingsRenewalNotifications",
      ].map((fieldName) => {
        dataToSet[fieldName] = userData[fieldName];
      });
      setSwitchInputData((state) => ({
        ...state,
        ["emailNotifications"]: userData.emailNotifications,
        ["smsNotifications"]: userData.smsNotifications,
        ["listingsRenewalNotifications"]: userData.listingsRenewalNotifications,
      }));

      form.setFieldsValue({
        phoneType: userData.phonetype,
        address: userData.address,
        office_name: userData.office_name,
      });
      form.setFieldsValue(dataToSet);
      if (userData?.agent?.bio) {
        form.setFieldsValue({ bio: userData.agent.bio || "" });
      }
    }
  }, [userData]);

  useEffect(() => {
    if (getPasswordCodeSuccess) {
      dispatch(push("/reset-password?step=1"));
    }
  }, [getPasswordCodeSuccess]);

  useEffect(() => {
    if (verifyCodeSuccess) {
      setVerifyPhoneModalVisible(false);
    }
    return () => {
      dispatch(clearErrorStates());
    };
  }, [verifyCodeSuccess]);

  useEffect(() => {
    if (updateUserSettingsSuccess) {
      setPasswordModalVisible(false);
      dispatch(getLoggedInUser());
      dispatch(setUpdatedUserSettingsSuccess());
      form.setFieldsValue({ password: "" });
    }
  }, [updateUserSettingsSuccess]);

  const handleChange = (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => {
    e.persist();
    const { checked } = e.target;
    if (e.target.name === "listingsRenewalNotifications" && isNotPremiumUser) {
      setPremiumModalVisible(true);
    } else {
      setSwitchInputData((state) => ({
        ...state,
        [e.target.name]: !checked,
      }));
    }
  };

  const onAddressChange = (address: AddressChangeParams) => {
    if (!address.formatted_address) return false;
    const { formatted_address, separated_fields } = address;
    let setFieldValues: IObjectKeys = {
      address: {
        addr: formatted_address,
        street_number: separated_fields?.street_number,
        street_name: separated_fields?.street_name,
        unit: separated_fields?.unit,
        city: separated_fields?.town,
        state: separated_fields?.short_state,
        zipCode: separated_fields?.postal_code,
        country: "USA",
      },
    };
    setFieldValues.street_number = separated_fields?.street_number;
    setFieldValues.defaultCity = separated_fields?.town ? separated_fields?.town : "";
    setFieldValues.defaultState = separated_fields?.state ? separated_fields?.state : "";
    form.setFieldsValue(setFieldValues);
  };

  useEffect(() => {
    if (exportAgentDataSuccess) {
      setIsExportModalVisible(true);
      dispatch(setExportAgentDataSuccess(false));
    }
  }, [exportAgentDataSuccess]);

  // useEffect(() => {
  //   if (!isValidateMessageVisible) {
  //     if (phoneVerified) {
  //       if (!userData.phone_verified) {
  //         setVerifyPhoneModalVisible(true);
  //         dispatch(setVerificationPhoneSuccess(false));
  //       }
  //     }
  //   }
  // }, [isValidateMessageVisible, phoneVerified]);

  useEffect(() => {
    if (phoneCodeWasSent) {
      setTimeout(() => {
        setPhoneCodeWasSent(false);
      }, 1000);
    }
  }, [phoneCodeWasSent]);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (
        !isCLAWUser(userData) &&
        userData.agency &&
        userData?.agency?.subscription_level !== SUBSCRIPTION_TYPES.free &&
        userData?.role !== ROLE_TYPES.AGENT
      ) {
        dispatch(getSubscriptions(userData?.agency?.subscription_id));
        dispatch(getUpcomingInvoices());
        dispatch(getInvoices());
      }
    }
  }, [userData]);

  useEffect(() => {
    if (queryObject.open_upgrade_modal) {
      setPremiumModalVisible(true);
    }
  }, [queryObject]);

  return (
    <div className={"settings-page-wrapper"}>
      <div
        className={`settings-content-wrapper ${
          //@ts-ignore
          width >= 599 && "scrollbar"
        } 
        overflow-y-auto test`}
      >
        <Card bordered hoverable className="settings-card" loading={loading}>
          <div className="settings-card-wrapper">
            <Row>
              <div className={"settings-card-wrapper__top"}>
                <Title data-test-id="account-settings-title" level={3}>
                  Profile Settings
                  {userData?.agency?.subscription_level !== SUBSCRIPTION_TYPES.free &&
                  userData?.agency?.subscription?.stripe_payment_method_id ? (
                    <span>⭐ PREMIUM</span>
                  ) : null}
                </Title>
                {!SSO_SOURCES.includes(userData?.source?.name) && (
                  <Form.Item>
                    <Link
                      className="primary-text no-text-decoration reset-password-btn"
                      onClick={() => {
                        dispatch(resetPassword({ email: userData.email }));
                      }}
                      data-test-id="reset-password"
                    >
                      Reset Password
                    </Link>
                    <>{passwordResetLoading && <Spin size={"small"} />}</>
                  </Form.Item>
                )}
              </div>
              <div className={"settings-image-container-top__all"}>
                <UploadUserImage type={type} userData={userData} setImageData={setImageData} />
                <Col span={12} className={"settings-info"}>
                  <div>
                    Status:
                    <span className={"status"}>
                      <div
                        className={`status-circle ${
                          userData?.agency?.subscription_status !== "Subscribed" && "status-circle-alert"
                        }`}
                      ></div>
                      {userData?.agency?.subscription_status}
                    </span>
                  </div>
                  <div>
                    Seats:{" "}
                    <span className={"status-normal"}>
                      {
                        // eslint-disable-next-line no-restricted-globals
                        userData?.agency?.seats - userData?.agency?.agent_ids?.length
                      }{" "}
                      seats left
                    </span>
                  </div>
                  <div>
                    Renewal date:{" "}
                    <span
                      className={`status-normal ${
                        getIsLastOneWeek(userData?.agency?.subscription_renewal_date) && "last-one-week"
                      } `}
                    >
                      {formatDate(userData?.agency?.subscription_renewal_date)}
                    </span>
                  </div>
                </Col>
              </div>
              <Col span={24} className={"settings-info-form-body"}>
                <Form
                  onFieldsChange={handleFieldsChange}
                  form={form}
                  onFinish={onSubmit}
                  layout={"vertical"}
                  requiredMark
                  onFinishFailed={onFinishFailed}
                >
                  <div className={"form-content"}>
                    <Col span={12}>
                      <Form.Item name="name" label="Full Name" rules={[REQUIRED]}>
                        <Input autoComplete={"off"} />
                      </Form.Item>
                      <Form.Item name="office_name" label="Brokerage Name">
                        <Input autoComplete={"off"} />
                      </Form.Item>
                      <Form.Item className="mb-0-force ">
                        <Form.Item label="Phone type" name="phoneType" rules={[REQUIRED]}>
                          <Radio.Group data-test-id={"radio_group"} className="new-exists-radio-group">
                            {SHAREABLE_PHONE_TYPES.map((type) => (
                              <Radio key={type.value} data-test-id={"new_landlord"} value={type.value}>
                                {type.label}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                        <div
                          className={`${
                            !userData?.phone_verified
                              ? "settings-info-phone-section"
                              : "settings-info-phone-section-verified"
                          }`}
                        >
                          <Form.Item
                            name="phone"
                            label="Phone Number"
                            rules={[REQUIRED, SETTINGS_PHONE_NUMBER_VALIDATION]}
                          >
                            <MaskedInput
                              inputMode="numeric"
                              mask={PHONE_NUMBER_MASK}
                              placeholder={PHONE_NUMBER_PLACEHOLDER}
                            />
                          </Form.Item>
                          {userData.phone_verified && (
                            <p
                              className={
                                "settings-info-phone-section-verified--icon text-green-500 mt-4 flex flex-nowrap gap-1 items-center"
                              }
                            >
                              <CheckedIcon />
                              Verified
                            </p>
                          )}
                          {!userData.phone_verified && !isValidateMessageVisible && (
                            <>
                              <BaseButton
                                variant="primary"
                                data-test-id="verify-phone-number"
                                onClick={onVerifyPhoneClick}
                              >
                                Verify
                              </BaseButton>
                            </>
                          )}
                        </div>
                      </Form.Item>
                      <Form.Item name="email" label="Email" rules={[EMAIL_VALIDATION]}>
                        <Input autoComplete={"off"} />
                      </Form.Item>

                      <div style={{ marginBottom: "24px" }}>
                        <Form.Item
                          name={["address", "addr"]}
                          label="Business Address"
                          className="area_field"
                          rules={[CHARACTERS_VALIDATION, SHAREABLE_ADDRESS_1_VALIDATION]}
                        >
                          <PlacesAutocomplete
                            defaultAddress={userData.address?.addr}
                            onAddressChange={onAddressChange}
                            resetPlacesAutocomplete={resetPlacesAutocomplete}
                            setResetPlacesAutocomplete={setResetPlacesAutocomplete}
                            placeholder={"Search for address"}
                          />
                        </Form.Item>
                      </div>
                      <div
                        className={
                          "address-wrpper address-wrpper-row-first flex flex-row flex-nowrap justify-start gap-2"
                        }
                      >
                        <Form.Item label="Street Number" name={["address", "street_number"]}>
                          <Input placeholder="123" />
                        </Form.Item>
                        <Form.Item
                          label="Street Name"
                          name={["address", "street_name"]}
                          rules={[SHAREABLE_STREET_NAME_VALIDATION]}
                        >
                          <Input placeholder="Street Name" />
                        </Form.Item>
                        <Form.Item label="Unit Number" name={["address", "unit"]}>
                          <Input placeholder="Unit" />
                        </Form.Item>
                      </div>
                      <div className={"address-wrpper flex flex-row flex-nowrap justify-start gap-2"}>
                        <Form.Item label="City" name={["address", "city"]} rules={[SHAREABLE_LOCALITY_VALIDATION]}>
                          <Input placeholder="New York" />
                        </Form.Item>
                        <Form.Item label="State" name={["address", "state"]} rules={[SHAREABLE_STATE_VALIDATION]}>
                          <Input placeholder={"New York"} />
                        </Form.Item>
                        <Form.Item
                          label="Postal Code"
                          name={["address", "zipCode"]}
                          rules={[SHAREABLE_POSTAL_CODE_VALIDATION]}
                        >
                          <Input placeholder={"12345"} />
                        </Form.Item>
                      </div>
                      <div className="notification-for-location-select-row">
                        <h3 className="info-link-with-toggle" onClick={() => setShowWhyText(!showWhyText)}>
                          Why is this helpful?
                        </h3>
                        {showWhyText && (
                          <p className="notification-for-location-select-row--description">
                            Choosing your default region helps save you and your rental leads time by narrowing the
                            search criteria. You can enter a town/city, or broaden the search by selecting the state of
                            your choice.
                          </p>
                        )}
                      </div>
                      <Form.Item
                        name="bio"
                        label="Bio"
                        rules={[
                          {
                            min: 3,
                          },
                        ]}
                      >
                        <TextArea autoSize={{ minRows: 1 }} autoComplete={"off"} className="settings-textarea" />
                      </Form.Item>
                    </Col>
                  </div>
                  <NotificationsPart
                    remindersCollapse={remindersCollapse}
                    handleChange={handleChange}
                    switchInputData={switchInputData}
                    setVerifyPhoneModalVisible={setVerifyPhoneModalVisible}
                  />
                  {Object.keys(userData).length > 0 && (
                    <>
                      <BillingAndInvoices />
                      {userData.role === "Agent" && userData?.agency?.subscription?.stripe_payment_method_id ? (
                        <p
                          className={
                            "settings-page-wrapper-supportText settings-page-wrapper-supportTextSubscribedAgent"
                          }
                        >
                          You are currently on a Team Plan. For more information, please contact
                          <br />
                          your team owner or email us at{" "}
                          <a
                            href="mailto:support@therentbase.com"
                            onClick={() => AnalyticsService.reportEvent("contact_us")}
                          >
                            support@therentbase.com.
                          </a>
                        </p>
                      ) : (
                        <p className={"settings-page-wrapper-supportText"}>
                          Looking for Integrations or customizations?{" "}
                          <a
                            href="mailto:support@therentbase.com"
                            onClick={() => AnalyticsService.reportEvent("contact_us")}
                          >
                            Contact us
                          </a>
                          .
                        </p>
                      )}
                    </>
                  )}

                  <div className={"save-settings my-6 flex justify-center"}>
                    <BaseButton
                      variant="primary"
                      onClick={() => {
                        form.submit();
                      }}
                      loading={loading}
                      dataTestId="save-settings-btn"
                    >
                      Save Settings
                    </BaseButton>
                  </div>
                </Form>
              </Col>
            </Row>
            {userData.role === ROLE_TYPES.OWNER && (
              <div className="text-sm mt-10">
                <div className={"account-setting-export flex justify-between items-center"}>
                  <div className={"account-setting-export-top"}>
                    <Title level={3} style={{ marginBottom: "0px" }}>
                      Export Account Data
                    </Title>
                    <span>Request to export all your data.</span>
                  </div>
                  <div className={"account-setting-export-btnSection"}>
                    <BaseButton
                      size="sm"
                      classnames={"rb-secondary-btn md "}
                      loading={exportAgentDataLoading}
                      onClick={() => {
                        dispatch(exportAgentData());
                      }}
                    >
                      Export Data
                    </BaseButton>
                  </div>
                </div>
              </div>
            )}
            {/*<Row style={{ marginTop: "20px" }}>*/}
            {/*  <Divider type={"horizontal"} className="mt-1" />*/}
            {/*  <Col span={24} className={"settings-delete"}>*/}
            {/*    <p>Delete Account</p>*/}
            {/*    <button disabled>Delete account</button>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </div>
          <PasswordConfirmModal
            visible={passwordModalVisible}
            onClose={() => {
              setPasswordModalVisible(false);
              form.setFieldsValue({ password: "" });
            }}
            onSubmit={(password) => {
              form.setFieldsValue({ password });
              form.submit();
            }}
          />
          <VerifyPhoneModal
            visible={verifyPhoneModalVisible}
            onClose={() => setVerifyPhoneModalVisible(false)}
            phoneCodeWasSent={false} // We always want to user be able click "Get it" button and defer to Api.
            setPhoneCodeWasSent={setPhoneCodeWasSent}
          />
        </Card>
      </div>
      <ExportDataSuccessModal isModalVisible={isExportModalVisible} closeModal={closeExportModalHandler} />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={"listing_renewals"}
      />
    </div>
  );
};
export default memo(ProfileSettings);
