import React from "react";
import SuccessIcon from "../../../../add-follow-up-modal/icons/SuccessIcon";
import HouseIcon from "../../../../../ListingView/add-listing-note-modal/icons/HouseIcon";
import { AddToGoogleCalendar } from "../../../../AddToCalendar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { snoozedFollowUpSelector } from "../../../../../../store/followUps/selectors";
import "./SnoozeSuccess.scss";
import { listingDataSelector } from "../../../../../../store/listings/selectors";
import { userSelector } from "../../../../../../store/user/selectors";
import NoticeIcon from "../icons/NoticeIcon";
import {
  getPhoneVerificationCode,
  updateUserSettings,
} from "../../../../../../store/user/action";
import { getBrowser } from "../../../../../../helpers/globalFunctions";
import { dashboardSnoozedFUSelector } from "../../../../../../store/dashboard/selectors";
import {
  addTimeAndFormatDate,
  formatDate,
} from "../../../../../../helpers/dateHelper";
import { ISO_STRING } from "../../../../../../helpers/constants"

interface ISnoozeSuccess {
  setPhoneVerifyModalVisible: any;
  setAllFollowUpsModalVisible?: any;
  isFromDashboard?: any;
}

const SnoozeSuccess = ({
  setPhoneVerifyModalVisible,
  setAllFollowUpsModalVisible,
  isFromDashboard,
}: ISnoozeSuccess) => {
  const dispatch = useDispatch();
  const snoozedFollowUp = useSelector(
    isFromDashboard ? dashboardSnoozedFUSelector : snoozedFollowUpSelector
  );
  const listing = useSelector(listingDataSelector);
  const _event = {
    title: snoozedFollowUp?.title || "",
    description: snoozedFollowUp?.description || "",
    startTime: snoozedFollowUp?.todo_at,
    location: "",
    endTime: addTimeAndFormatDate(
      snoozedFollowUp?.todo_at,
      { amount: 1, unit: "hour" },
      undefined,
      ISO_STRING
    ),
  };
  const user = useSelector(userSelector);
  return (
    <div className={"snooze-follow-up-success"}>
      <SuccessIcon />
      <h3 className={"snooze-follow-up-success--title"}>
        Follow-up Saved Succefully!
      </h3>
      {listing.addr && (
        <div className={"snooze-follow-up-success--listing"}>
          <span>for</span> <HouseIcon />{" "}
          <span className={"snooze-follow-up-success--listing--address"}>
            {listing.addr}
          </span>
        </div>
      )}
      <p className={"snooze-follow-up-success--text"}>
        A reminder will be sent via email on:
        <br />
        <strong>{formatDate(snoozedFollowUp.todo_at)}</strong> at{" "}
        <strong>
          {formatDate(formatDate(snoozedFollowUp.todo_at, getBrowser()),"hh:mm A")}
        </strong>
      </p>
      <div className={"snooze-follow-up-success--phoneVerifySection"}>
        {!user.phone_verified ? (
          <div
            className={
              "snooze-follow-up-success--phoneVerifySection--notVerified"
            }
          >
            <p
              className={
                "snooze-follow-up-success--phoneVerifySection--notVerified--notice"
              }
            >
              <NoticeIcon /> Notice
            </p>
            <span>you've not enabled SMS messages</span>
            <p
              className={
                "snooze-follow-up-success--phoneVerifySection--notVerified--btn"
              }
              onClick={() => {
                setPhoneVerifyModalVisible(true);
                if (setAllFollowUpsModalVisible) {
                  setAllFollowUpsModalVisible(false);
                }
                user.phone !== "" && dispatch(getPhoneVerificationCode());
              }}
            >
              Verify your phone now
            </p>
          </div>
        ) : (
          <div
            className={"snooze-follow-up-success--phoneVerifySection--verified"}
          >
            {user.smsNotifications ? (
              <p className={"undo-snooze-verify"}>
                Text messages are activated!{" "}
                <span
                  onClick={() => {
                    dispatch(
                      updateUserSettings({
                        address: user.address,
                        defaultCity: user.defaultCity,
                        defaultState: user.defaultState,
                        email: user.email,
                        emailNotifications: user.emailNotifications,
                        name: user.name,
                        phone: user.phone,
                        _id: user._id,
                        smsNotifications: false,
                      })
                    );
                  }}
                >
                  undo
                </span>
              </p>
            ) : (
              <div className={"activate-snooze-verify"}>
                <p>
                  <strong>Recomended</strong> - Get reminders directly to your
                  phone
                </p>
                <span
                  onClick={() => {
                    dispatch(
                      updateUserSettings({
                        address: user.address,
                        defaultCity: user.defaultCity,
                        defaultState: user.defaultState,
                        email: user.email,
                        emailNotifications: user.emailNotifications,
                        name: user.name,
                        phone: user.phone,
                        _id: user._id,
                        smsNotifications: true,
                      })
                    );
                  }}
                >
                  Activate Text Messages
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <AddToGoogleCalendar event={_event} />
    </div>
  );
};

export default SnoozeSuccess;
