import { IObjectKeys } from "../../../helpers/globalTypes";
import { FormInstance } from "antd/es/form";
import moment from "moment";
import { parseMultiEnumOptions } from "../../../helpers/globalFunctions";
import {
  createRequirementForm,
  editReqSuccessSelector,
  editRequirementForm,
  fillRequirementFormData,
  getDraftRenter,
  setDraftReqUpdatedSuccess,
  updateDraftRenter,
} from "../../../store/requirement/action";
import {
  editRequirementDates,
  editRequirementMultiSelectFields,
  editRequirementRangeFields,
  editRequirementSetFields,
} from "../requirementConstants";
import { push } from "connected-react-router";
import qs from "query-string";
import { AnalyticsService } from "../../../helpers/analyticsService";
import { Dispatch } from "redux";

interface IHandleSubmitRenterForm {
  isEditMode: boolean;
  queryObject: IObjectKeys;
  formData: IObjectKeys;
  dispatch: any;
  requirementSteps: any;
  requirementFormData: any;
  data: IObjectKeys;
  previewPrefix: any;
  requirement_id: any;
  isManual: boolean;
}

export const setRequirementFormFields = (requirementFormData: IObjectKeys, form: FormInstance<any>, dispatch: any, renterToken: string) => {
  if (Object.keys(requirementFormData).length) {
    form.setFieldsValue(requirementFormData);
    if (requirementFormData.available_times) {
      form.setFieldsValue({
        available_times: moment(requirementFormData.available_times),
      });
    }
    if (requirementFormData.must_have) {
      form.setFieldsValue({
        must_have: parseMultiEnumOptions(requirementFormData.must_have),
      });
    }
    if (requirementFormData.deal_breaker) {
      form.setFieldsValue({
        deal_breaker: parseMultiEnumOptions(requirementFormData.deal_breaker),
      });
    }
  } else {
    if (renterToken) {
      dispatch(getDraftRenter());
    }
  }
};

export const setFieldValuesForEditRequirement = (leadData: IObjectKeys, setUsefulFieldValues: any) => {
  const fieldsToSet: IObjectKeys = {};

  editRequirementSetFields.forEach((fieldName) => {
    if (editRequirementMultiSelectFields.indexOf(fieldName) > -1 && leadData?.requirement?.[fieldName]) {
      fieldsToSet[fieldName] = parseMultiEnumOptions(leadData?.requirement?.[fieldName] ?? {});
    } else if (editRequirementRangeFields.indexOf(fieldName) > -1) {
      fieldsToSet[fieldName] =
        fieldName === "bedrooms_req"
          ? [leadData?.requirement?.["bedrooms_min"], leadData?.requirement?.["bedrooms_max"]]
          : (fieldsToSet[fieldName] =
              fieldName === "bathrooms_min_req"
                ? [leadData?.requirement?.["bathrooms_min"], leadData?.requirement?.["bathrooms_max"]]
                : []);
    } else if (editRequirementDates.indexOf(fieldName) > -1) {
      if (leadData?.requirement?.[fieldName]) fieldsToSet[fieldName] = moment(leadData?.requirement?.[fieldName]);
    } else if (leadData?.requirement?.[fieldName]) {
      fieldsToSet[fieldName] = leadData?.requirement?.[fieldName];
      fieldsToSet["listings_applied"] = leadData?.requirement["listings_applied"];
    }
  });

  return fieldsToSet;
};

export const handleSubmitRenterForm = (props: IHandleSubmitRenterForm) => {
  +props.queryObject.reqStep === props.requirementSteps.length &&
    AnalyticsService.reportRequirementsFunnel({ action: "requirements_submitted", formData: props.requirementFormData });
  if (props.queryObject.previewMode) {
    props.dispatch(fillRequirementFormData(props.formData));
    if (+props.queryObject.reqStep === props.requirementSteps.length) {
      delete props.queryObject.reqStep;
      props.queryObject.isSuccess = true;
      props.dispatch(
        push({
          search: qs.stringify(props.queryObject),
        })
      );
    } else {
      props.queryObject.reqStep = +props.queryObject.reqStep + 1;
      props.dispatch(
        push({
          search: qs.stringify(props.queryObject),
        })
      );
    }
  } else {
    if (!props.isEditMode) {
      if (+props.queryObject.reqStep > 1 && +props.queryObject.reqStep < 11)
        props.dispatch(
          updateDraftRenter({
            ...props.formData,
            reqStep: props.queryObject.reqStep,
            isManual: props.isManual,
          })
        );
      if (+props.queryObject.reqStep === props.requirementSteps.length) {
        delete props.queryObject.reqStep;
        props.dispatch(
          createRequirementForm({
            ...props.formData,
            ...props.requirementFormData,
            listings_viewed: props.data.listings_viewed,
            listings_applied: props.data.listings_applied,
            considering_buying: props.requirementFormData.considering_buying ? "Yes" : "No",
            isManual: props.isManual,
          })
        );
        props.queryObject.isSuccess = true;
        props.dispatch(
          push({
            search: qs.stringify(props.queryObject),
          })
        );
      }
    } else {
      props.dispatch(
        editRequirementForm({
          requirement: props.formData,
          requirements_id: props.requirement_id,
        })
      );
    }
  }
};

interface IChangeToNextStepAfterSubmit {
  reqUpdatedSuccess: boolean;
  editRequirementSuccess: boolean;
  dispatch: Dispatch<any>;
  queryObject: IObjectKeys;
  requirementSteps: { stepNumber: number; component: JSX.Element; name: string }[];
  isEditFormSubmitted: boolean;
}

export const changeToNextStepAfterSubmit = (props: IChangeToNextStepAfterSubmit) => {
  if (props.reqUpdatedSuccess || props.editRequirementSuccess) {
    props.dispatch(setDraftReqUpdatedSuccess(false));
    props.dispatch(editReqSuccessSelector(false));
    if (+props.queryObject.reqStep <= props.requirementSteps.length && !props.isEditFormSubmitted) {
      props.queryObject.reqStep = +props.queryObject.reqStep + 1;
    }
    props.dispatch(
      push({
        search: qs.stringify(props.queryObject),
      })
    );
  }
};

interface ICheckRenterInfoStatusAndRedirect {
  dispatch: Dispatch<any>;
  queryObject: IObjectKeys;
  renterExists: string | null;
  isEditMode: boolean;
}

export const checkRenterInfoStatusAndRedirect = (props: ICheckRenterInfoStatusAndRedirect) => {
  if (props.queryObject.renterDraft_token) {
    localStorage.setItem("renterDraft_token", props.queryObject.renterDraft_token);
    delete props.queryObject.renterDraft_token;
    props.dispatch(
      push({
        search: qs.stringify(props.queryObject),
      })
    );
  } else {
    if (!props.renterExists && props.queryObject.reqStep !== "1" && !props.isEditMode) {
      props.queryObject.reqStep = 1;
      props.dispatch(
        push({
          search: qs.stringify(props.queryObject),
        })
      );
    } else {
      return;
    }
  }
};
