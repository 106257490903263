import React from "react";
import routes from "../../../../../routes/routesCode";
import { useLocation } from "react-router";
import AuthHeaderListingActions from "./listing-actions/AuthHeaderListingActions";
import AuthHeaderLeadAction from "./lead-action/AuthHeaderLeadAction";
import AuthHeaderLandlordActions from "./landlord-actions/AuthHeaderLandlordActions";
import AuthHeaderFollowUpAction from "./follow-up-action/AuthHeaderFollowUpAction";
import AuthHeaderTenantScreeningAction from "./tenant-screening";
import AuthHeaderTeamMembersAction from "./team-members-actions/AuthHeaderTeamMembersAction";
import { useSelector } from "react-redux";
import { windowSelector } from "../../../../../store/app/selectors";
import { isListingRoute } from "helpers/clientRoutes";

const AuthHeaderActions = ({ searchBarFocused }: { searchBarFocused: boolean; }) => {
    const { pathname } = useLocation();
    const { type } = useSelector(windowSelector);
    const visibleCondition = type !== "desktop" ? true : !searchBarFocused;
    return (
        <>
            {isListingRoute(pathname) && visibleCondition && <AuthHeaderListingActions />}
            {pathname === routes.leads.path && visibleCondition && <AuthHeaderLeadAction />}
            {pathname === routes.landlords.path && visibleCondition && <AuthHeaderLandlordActions />}
            {pathname === routes.followUps.path && visibleCondition && <AuthHeaderFollowUpAction />}
            {pathname === routes.agentAllScreening.path && visibleCondition && <AuthHeaderTenantScreeningAction />}
            {pathname.includes("teamsMembers") && visibleCondition && <AuthHeaderTeamMembersAction />}
        </>
    );
};

export default AuthHeaderActions;
