import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import {
  landlordEditSuccessSelector,
  landlordNotesSelector,
  singleLandlordDataSelector,
} from "../../store/landlords/selectors";
// import LandlordCard from "./LandlordDetails";
import { useDispatch, useSelector } from "react-redux";
import ManageLandlordForm from "../Landlords/ManageLandlord";
import { landlordTags } from "../Lead/shared/constants";
import BaseModal from "../Shared/BaseModal";
import LandlordNoteModal from "./LandlordNoteModal";
import { useLocation, useParams } from "react-router-dom";
import { IObjectKeys } from "../../helpers/globalTypes";
import AllFollowUpsModal from "../FollowUps/all-follow-ups-modal/AllFollowUpsModal";
import { ListingNote } from "../Listings/helpers/mockListings";
import { editedFollowUpSelector, followUpsListSelector } from "../../store/followUps/selectors";
import AddFollowUpModal from "../FollowUps/add-follow-up-modal/AddFollowUpModal";
import PhoneVerifyModalFollowUps from "../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import { getLandlordFollowUps } from "../../store/followUps/action";
import AddFollowUpModalMobile from "../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useAppContext, useDemoContext } from "libs/contextLib";
import { followUpModalsHandler, usersFreeSubscriptionLimits } from "../../helpers/globalFunctions";
import PremiumUpgradeModal from "../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { ACTIONS, TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import { userSelector } from "store/user/selectors";
import BaseButton from "components/Shared/BaseButton";
import { AnalyticsService } from "helpers/analyticsService";
import TagDropdown from "components/Lead/shared/lead-availabilities/components/tag-dropdown";
import { PrivateEyeIcon, NotPrivateEyeIcon, PhoneIcon, EmailIcon, TriangleIcon, MenuOptionsIcon } from "../icons";
import DropdownPencilEdit from "components/icons/DropdownPencilEdit";
import ListingAddFollowUpIcon from "components/icons/ListingAddFollowUpIcon";
import { useForm } from "antd/es/form/Form";
import { isCLAWUser } from "helpers/userHelpers";
import { push } from "connected-react-router";
import { setCurrentLandlord, setToggleLandlordPrivacy } from "../../store/landlords/action";

const LandlordActions = ({ canManageLandlord }: { canManageLandlord: boolean }) => {
  const { landlordId } = useParams();
  const [form] = useForm();
  const dispatch = useDispatch();
  const initialModalValue = { visible: false, editModal: false };
  const [currentNoteInfo, setCurrentNoteInfo] = useState<IObjectKeys>({});
  const [visible, setVisible] = useState(false);
  const [modalProps, setModalProps] = useState(initialModalValue);
  const landlord = useSelector(singleLandlordDataSelector);
  const landlordEditSuccess = useSelector(landlordEditSuccessSelector);
  const { width } = useWindowSize();
  const [allFollowUpsModalVisible, setAllFollowUpsModalVisible] = useState(initialModalValue);
  const followUps = useSelector(followUpsListSelector);
  const [followUpModalProps, setFollowUpModalProps] = useState(initialModalValue);
  const [isDescShown, setIsDescShown] = useState(false);
  const [isFollowUpEditable, setIsFollowUpEditable] = useState(false);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const editedFollowUp = useSelector(editedFollowUpSelector);
  const { isDemo, demoAction } = useDemoContext();
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const userData = useSelector(userSelector);
  const [isEditDropDown, setEditDropDown] = useState<boolean>(false);
  const { isNotPremiumUser } = useAppContext();
  const usersFreeSubscriptionLimit: any = usersFreeSubscriptionLimits({ userData, isNotPremiumUser });

  const followHandler = () => {
    if (followUps.length > 0) {
      AnalyticsService.reportAddFollowUpClick("header_link-open_all_fu_modal");
      if (isDemo) {
        demoAction("landlord");
      } else {
        setAllFollowUpsModalVisible({
          visible: true,
          editModal: false,
        });
      }
    } else {
      AnalyticsService.reportAddFollowUpClick("header_link");
      if (isDemo) {
        demoAction("landlord");
      } else {
        setFollowUpModalProps({
          visible: true,
          editModal: false,
        });
      }
    }
  };

  const noteHandler = () => {
    if (isDemo) {
      demoAction("followUp");
    } else {
      setEditDropDown(false);
      setCurrentNoteInfo({});
      setModalProps({ visible: true, editModal: false });
    }
  };

  const followUpHandler = () => {
    AnalyticsService.reportAddFollowUpClick("three_dot_menu");
    if (isDemo) {
      demoAction("followUp");
    } else {
      setEditDropDown(false);
      setCurrentNoteInfo({});
      setFollowUpModalProps({ visible: true, editModal: false });
    }
  };

  const editHandler = () => {
    if (isDemo) {
      demoAction("landlord");
    } else {
      console.log("winere");
      setEditDropDown(false);
      setVisible(true);
      console.log("landlord", landlord);

      dispatch(setCurrentLandlord(landlord));
    }
  };

  const privateHandler = () => {
    if (isDemo) {
      demoAction("landlord");
    } else {
      setEditDropDown(false);
      dispatch(
        setToggleLandlordPrivacy({
          landlord_id: landlord.landlord_id,
          is_private: true,
        })
      );
    }
  };

  const publicHandler = () => {
    if (isDemo) {
      demoAction("landlord");
    } else {
      setEditDropDown(false);
      dispatch(
        setToggleLandlordPrivacy({
          landlord_id: landlord.landlord_id,
          is_private: false,
        })
      );
    }
  };

  const menu = (
    <Menu>
      <TriangleIcon />
      {canManageLandlord && (
        <Menu.Item onClick={editHandler} data-test-id="Edit-Landloard">
          <DropdownPencilEdit />
          Edit Landlord
        </Menu.Item>
      )}
      {canManageLandlord && landlord.is_private && (
        <Menu.Item onClick={publicHandler} data-test-id="make-land-private">
          <PrivateEyeIcon />
          Make Public
        </Menu.Item>
      )}
      {canManageLandlord && !landlord.is_private && (
        <Menu.Item onClick={privateHandler} data-test-id="make-land-private">
          <NotPrivateEyeIcon />
          Make Private
        </Menu.Item>
      )}
      {!isCLAWUser(userData) && canManageLandlord && (
        <Menu.Item
          onClick={() => {
            isDemo
              ? demoAction("landlord")
              : dispatch(push(`../manageListing?step=0&landlordId=${landlord.landlord_id}`));
          }}
          data-test-id="New-List-Land"
          className={"add-new-landlord-listing-txt-wrapper"}
          disabled={usersFreeSubscriptionLimit?.listings}
        >
          <ListingAddFollowUpIcon />
          <div className={"add-new-landlord-listing-txt"}>
            <p>Add Listing</p>
            <p>for landlord</p>
          </div>
        </Menu.Item>
      )}
      <Menu.Item onClick={noteHandler}>
        <ListingAddFollowUpIcon />
        Add note
      </Menu.Item>
      <Menu.Item onClick={followUpHandler}>
        <ListingAddFollowUpIcon />
        Add follow-up
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (queryObject?.action === ACTIONS.ADD_INDIVIDUAL_FOLLOW_UP) {
      setFollowUpModalProps({ visible: true, editModal: false });
    }
  }, [search]);

  const handleOpenAddNoteModal = () => {
    setFollowUpModalProps({ visible: true, editModal: false });
    form.resetFields();
    setIsDescShown(false);
    setIsFollowUpEditable(false);
  };
  const handleOpenFollowUpModal = (note: ListingNote) => {
    setFollowUpModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at: note.todo_at,
    });
    setIsFollowUpEditable(true);
    if (note.description && note.description.length > 0) {
      setIsDescShown(true);
    } else {
      setIsDescShown(false);
    }
  };

  const openAllFollowUpsModalHandler = () => {
    if (isDemo) {
      demoAction("followUp");
    } else {
      setAllFollowUpsModalVisible({ visible: true, editModal: false });
    }
  };

  const closeVerifyModalHandler = () => {
    setPhoneVerifyModalVisible(false);
  };

  useEffect(() => {
    if (Object.keys(landlord).length > 0) {
      dispatch(getLandlordFollowUps(landlord.landlord_id));
    }
  }, [landlord]);

  useEffect(() => {
    if (Object.keys(editedFollowUp).length > 0) {
      followUpModalsHandler({
        setFollowUpModalProps,
        setAllFollowUpsModalVisible,
        editedFollowUp,
        dispatch,
      });
    }
  }, [editedFollowUp]);

  useEffect(() => {
    if (landlordEditSuccess) {
      setVisible(false);
    }
  }, [landlordEditSuccess]);

  return (
    <div className="landlord-actions-container">
      <div>
        <div>
          <div className="pb-6">
            <div className="flex justify-between items-center">
              <div className="flex flex-wrap justify-start gap-3 items-center">
                <h3 data-test-id={"landlord-name-txt"} className={"landlord-name text-4xl font-bold"}>
                  {landlord?.name}
                </h3>
                <TagDropdown
                  id={landlord.landlord_id}
                  existingTags={landlord.tags}
                  tags={landlordTags}
                  type="landlord"
                />
                <Tooltip
                  overlayClassName={"landlord-page-overlay flex items-center"}
                  placement="right"
                  title={landlord?.is_private ? "Listed as Private" : "Listed as Public"}
                >
                  <span
                    data-test-id={landlord?.is_private ? "private-landlord-icon" : "public-landlord-icon"}
                    className={"cursor-pointer flex items-center"}
                  >
                    {landlord?.is_private ? <PrivateEyeIcon /> : <NotPrivateEyeIcon />}
                  </span>
                </Tooltip>
              </div>
              <div>
                <Tooltip overlayClassName={"fu-page"} placement="top" title="More options">
                  <Dropdown
                    onVisibleChange={(visible) => {
                      setEditDropDown(visible);
                    }}
                    visible={isEditDropDown}
                    trigger={["click"]}
                    placement={"bottomRight"}
                    overlay={menu}
                    overlayClassName={"single-landlord-more-options-dropdown-overlay"}
                  >
                    <span
                      className="landlord-edit-popup-settings-span"
                      data-test-id={"landlord-edit-btn"}
                      onClick={() => {
                        setEditDropDown(true);
                      }}
                    >
                      <MenuOptionsIcon />
                    </span>
                  </Dropdown>
                </Tooltip>
              </div>
            </div>
            <div className="flex flex-wrap justify-between my-4 items-center text-lg">
              {landlord.email && (
                <div className="gap-2 flex ant-space-align-center">
                  <EmailIcon width="20" />
                  <a target="_blank" href={`mailto:${landlord.email}`}>
                    {landlord.email}
                  </a>
                </div>
              )}
              {landlord.phone && (
                <div className="gap-2 flex ant-space-align-center">
                  <PhoneIcon width="20" />
                  <a target="_blank" href={`tel:${landlord.phone}`}>
                    {landlord.phone}
                  </a>
                </div>
              )}

              <div className="flex flex-wrap justify-end gap-2">
                <BaseButton
                  dataTestId="landlord-note-icon"
                  onClick={() => {
                    isDemo ? demoAction("followUp") : setModalProps({ visible: true, editModal: false });
                  }}
                >
                  Add a Note
                </BaseButton>
                <BaseButton dataTestId="landlord-folloup-txt" onClick={followHandler}>
                  {followUps.length > 0 ? `Follow-ups (${followUps.length})` : " Add Follow-Up"}
                </BaseButton>
              </div>
            </div>
          </div>
          {
            //@ts-ignore
            width > 766 ? (
              <AllFollowUpsModal
                visible={allFollowUpsModalVisible.visible}
                setVisible={setAllFollowUpsModalVisible}
                handleOpenAddFollowUpModal={handleOpenAddNoteModal}
                notes={followUps}
                handleOpenFollowUpEditModal={handleOpenFollowUpModal}
                setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
              />
            ) : null
          }
          <PremiumUpgradeModal
            visible={premiumModalVisible}
            setVisible={setPremiumModalVisible}
            triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_ALL_FOLLOW_UPS_MODAL}
          />
        </div>
      </div>

      <BaseModal
        classname={"add-edit-landlord-modal"}
        cancelButtonProps={{
          className: "rb-secondary-btn sm",
        }}
        okButtonProps={{
          className: "rb-primary-btn sm",
        }}
        modalTitle={`${landlord.landlord_id ? "Edit" : "Add"} Landlord`}
        isModalVisible={visible}
        onOk={() => form.submit()}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <ManageLandlordForm form={form} />
      </BaseModal>
      <LandlordNoteModal
        landlord_id={landlordId}
        modalProps={modalProps}
        setModalProps={setModalProps}
        noteInfo={currentNoteInfo}
        initialModalValue={initialModalValue}
        lId={landlordId}
        form={form}
      />
      {
        //@ts-ignore
        width > 766 ? (
          <AllFollowUpsModal
            visible={allFollowUpsModalVisible.visible}
            setVisible={setAllFollowUpsModalVisible}
            handleOpenAddFollowUpModal={handleOpenAddNoteModal}
            notes={followUps}
            handleOpenFollowUpEditModal={handleOpenFollowUpModal}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : null
      }
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={isDescShown}
            landlordInfo={landlord}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={isDescShown}
            landlordInfo={landlord}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        )
      }
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={closeVerifyModalHandler} />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_ALL_FOLLOW_UPS_MODAL}
      />
    </div>
  );
};

export default LandlordActions;
