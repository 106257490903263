import React from "react";
import { Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { phoneMaskOnString } from "../../helpers/globalFunctions";
import { getLandlordNotes } from "../../store/landlords/action";
import { NotPrivateEyeIcon, PrivateEyeIcon } from "../icons";
import BaseEmptyState from "../BaseEmptyState";
import { userSelector } from "../../store/user/selectors";
import { ROLE_TYPES } from "../../helpers/constants";
import BaseButton from "components/Shared/BaseButton";
import { currentLandlordSelector } from "store/landlords/selectors";
import { AnalyticsService } from "helpers/analyticsService";
import { push } from "connected-react-router";
import SectionHeading from "components/Shared/Section/SectionHeading";
import BaseCollapse from "components/Shared/BaseCollapse";

interface ILandlordInfoProps {
  handleViewLandlord: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddLandlord: React.Dispatch<React.SetStateAction<boolean>>;
  listing: any;
  isCoListingAgent?: boolean;
}

const LandlordInfo = (props: ILandlordInfoProps) => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelector);
  // const singleLandlord = useSelector(singleLandlordDataSelector);
  const currentLandlord = useSelector(currentLandlordSelector);
  const { isCoListingAgent, listing, handleViewLandlord, handleAddLandlord } = props;

  if (!listing) return null;

  const isPrivateLandlord =
    listing.landlordOfList?.is_private &&
    userData.role !== ROLE_TYPES.OWNER &&
    listing.landlordOfList?.created_by !== userData.agent_id;

  const contactHandler = (landlordData: any) => {
    if (!isPrivateLandlord || (!isPrivateLandlord && !Object.keys(currentLandlord).length)) {
      dispatch(getLandlordNotes(landlordData?._id));
    }
    handleViewLandlord(landlordData);
    AnalyticsService.reportSingleListingPageClick("contact_landlord_clicked");
  };

  const handleAddBtnClick = () => {
    handleAddLandlord(true);
  };

  const landlordInfoEmptyStateProps = {
    title: "Landlord",
    description: "No landlord set yet.",
    linkText: "Add them now",
    forceRenderLink: Boolean(isCoListingAgent),
    openWithModal: false,
    handleAddBtnClick,
    modalType: "landlord",
    modalProps: {
      isModalVisible: false,
      children: "",
      modalTitle: "",
    },
  };

  const landlordData =
    listing?.landlordOfList?.is_private &&
    listing?.landlordOfList?.created_by !== userData?.agent_id &&
    userData.role !== ROLE_TYPES.OWNER
      ? { ...listing?.agentOfList, is_private: true }
      : listing.landlordOfList;

  return (
    <BaseCollapse header={<SectionHeading heading="Landlord" />} collapse={false}>
      {landlordData ? (
        <div className="mb-10 flex flex-wrap items-center justify-between gap-x-6 gap-y-4  sm:flex-nowrap">
          <div className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 sm:flex-nowrap">
            <div>
              {landlordData?.name && landlordData?._id && !isPrivateLandlord ? (
                <BaseButton
                  variant="link"
                  href={`../landlords/${landlordData?._id}`}
                  onClick={() => {
                    AnalyticsService.reportLandlordClick("single_listing_page");
                    dispatch(push(`/landlords/${landlordData?._id}`));
                  }}
                >
                  {landlordData?.name}
                </BaseButton>
              ) : (
                <span className={"font-16"}> {landlordData?.name}</span>
              )}
            </div>
            <Tooltip
              overlayClassName={"listing-page-overlay"}
              placement="right"
              title={landlordData?.is_private ? "Listed as Private" : "Listed as Public"}
            >
              <span className={"cursor-pointer flex align-items-center "}>
                {landlordData?.is_private ? <PrivateEyeIcon /> : <NotPrivateEyeIcon />}
              </span>
            </Tooltip>
            <BaseButton onClick={() => contactHandler(landlordData)} modalType="landlord" size="sm">
              Contact
            </BaseButton>
          </div>
          <div className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 sm:flex-nowrap">
            {landlordData?.email && (
              <div className="flex flex-nowrap items-center gap-2">
                <label>Email: </label>
                <BaseButton variant="link" href={`mailto:${landlordData?.email}`} target={"_blank"}>
                  {landlordData?.email}
                </BaseButton>
              </div>
            )}
            {landlordData?.phone && (
              <div className="flex flex-nowrap items-center gap-2">
                <label>Phone: </label>
                <BaseButton variant="link" href={`tel:${landlordData?.phone}`}>
                  {phoneMaskOnString(landlordData?.phone)}
                </BaseButton>
              </div>
            )}
          </div>
        </div>
      ) : (
        <BaseEmptyState {...landlordInfoEmptyStateProps} />
      )}
    </BaseCollapse>
  );
};

export default LandlordInfo;
