import React from "react";
import { Helmet } from "react-helmet";

interface HeaderTagsInfo { title: string; description: string; }

const HeaderTags = (props: HeaderTagsInfo) => {
  // read instructions in server/seo-injector.js before any modifications below
  return (
    <Helmet>
      <title>{props.title}</title>
    </Helmet>
  );
}

export default HeaderTags;
