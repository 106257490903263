import React, { useEffect } from "react";
import ListingsWrapper from "components/Listings";
import { useHistory } from "react-router-dom";
import { useDemoContext } from "libs/contextLib";

const Listings = () => {
  const history = useHistory();
  const { isDemo } = useDemoContext();

  useEffect(() => {
    if (isDemo) history.push("/demo/listings");
  }, [history, isDemo]);
  return <ListingsWrapper />;
};
export default Listings;
