import { Spin } from "antd";
import CallTUModal from "components/Shared/CallTUModal/CallTUModal";
import CallRentBaseModal from "components/Shared/CallRentBaseModal";
import ContactModal from "components/Shared/ContactModal/ContactModal";
import ReportModal from "components/Shared/ReportModal/ReportModal";
import tablesInfo from "components/Shared/TableView/tablesInfo";
import TableView from "components/Shared/TableView/TableView";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getRentersScreeningRequest } from "../../../store/renter/action";
import { renterSRSelector } from "../../../store/renter/selectors";
import RenterRequestScreeningDialog from "./RequestScreeningModal/RenterRequestScreeningModal";
import { shareableRenterLinkDataSelector } from "store/shareable/selectors";
import { saveEmailScreeningLinkData } from "store/shareable/action";
import { getSrRenterIdFromQuery, openNotification } from "helpers/globalFunctions";
import { userSelector } from "store/user/selectors";
import { useWindowSize } from "hooks/useWindowSize";
import TenantGridView from "components/TenantScreenings/view/grid-view/TenantGridView";
import { useAppContext } from "libs/contextLib";
import Section from "components/Shared/Section/Section";
import SectionHeading from "components/Shared/Section/SectionHeading";

const RenterScreenings = ({ renterId }: { renterId: string }) => {
  const dispatch = useDispatch();
  const { renterRequestsLoading = false, renterRequestsData = [], renterRequestsError } = useSelector(renterSRSelector);
  const screeningLinkData = useSelector(shareableRenterLinkDataSelector);
  const [isOpenContactModal, setIsOpenContactModal] = useState(false);
  const [selectedAgentData, setSelectedAgentData] = useState();
  const [selectedRequestData, setSelectedRequestData] = useState({});
  const [renterScreeningModalVisible, setRenterScreeningModalVisible] = useState(false);
  const location = useLocation();
  const [isCallTUModalVisible, setIsCallTUModalVisible] = useState(false);
  const [isCallRentBaseModalVisible, setIsCallRentBaseModalVisible] = useState(false);
  const [isViewReportModalVisible, setIsViewReportModalVisible] = useState(false);
  const [reportData, setReportData] = useState<any>(null);
  const [sr_renterId, setSr_renterId] = useState<any>(null);
  const { search } = useLocation();
  const userData = useSelector(userSelector);
  const { isNotPremiumUser } = useAppContext();
  const tableInfo = tablesInfo(userData, isNotPremiumUser);
  const { width } = useWindowSize();
  const isMobile = (width as number) <= 768;

  const fetchScreeningRequests = () => {
    dispatch(getRentersScreeningRequest(renterId));
  };

  useEffect(() => {
    if (renterId) fetchScreeningRequests();
  }, [renterId]);

  useEffect(() => {
    if (screeningLinkData.screening === "true" || location.search.includes("shareable_screening_success=true")) {
      setRenterScreeningModalVisible(true);
      // dispatch(saveEmailScreeningLinkData({ ...screeningLinkData, screening: undefined }));
    }
    if (window.location.search.includes("shareable_screening_success=false")) {
      openNotification("error", "Your screening request is not competed");
      // This will replace the current entry in the browser's history, without reloading
      window.history.replaceState(null, document.title, location.pathname);
    }
  }, []);

  useEffect(() => {
    if (getSrRenterIdFromQuery(search)) {
      setIsViewReportModalVisible(true);
      setSr_renterId(getSrRenterIdFromQuery(search));
    }
  }, [search]);

  const columnsProps = {
    isOpenContactModal,
    setIsOpenContactModal,
    setSelectedAgentData,
    setSelectedRequestData,
    setRenterScreeningModalVisible,
    setIsCallTUModalVisible,
    setIsCallRentBaseModalVisible,
    setIsViewReportModalVisible,
    setReportData,
  };

  if (renterRequestsData.length === 0) return null;

  return (
    <Spin spinning={renterRequestsLoading}>
      <Section shadow>
        <SectionHeading heading="Screening Applications" />
        {isMobile ? (
          <TenantGridView paginationValues={{}} tableValues={renterRequestsData} total={renterRequestsData.length} />
        ) : (
          <TableView
            id="leadsScreeningsTableId"
            paginationValues={{}}
            tableValues={renterRequestsData}
            total={renterRequestsData.length}
            columns={tableInfo.RENTER_SR_REPORTS_COLUMNS(columnsProps)}
          />
        )}
      </Section>
      {isOpenContactModal && (
        <ContactModal
          currentLeadData={selectedAgentData}
          showModal={true}
          onClose={setIsOpenContactModal}
          currentLandlordData={null}
        />
      )}
      {renterScreeningModalVisible && (
        <RenterRequestScreeningDialog
          visible={renterScreeningModalVisible}
          refreshData={() => dispatch(getRentersScreeningRequest(renterId))}
          setVisible={setRenterScreeningModalVisible}
          renter={{ renter_id: renterId, name: "YYY" }}
          selectedRequestData={selectedRequestData}
        />
      )}
      {isCallTUModalVisible && <CallTUModal visible={isCallTUModalVisible} setVisible={setIsCallTUModalVisible} />}{" "}
      {isCallRentBaseModalVisible && (
        <CallRentBaseModal visible={isCallRentBaseModalVisible} setVisible={setIsCallRentBaseModalVisible} />
      )}
      {isViewReportModalVisible && (
        <ReportModal
          srrId={sr_renterId || reportData.screening_request_renter_id}
          visible={isViewReportModalVisible}
          refreshData={fetchScreeningRequests}
          setVisible={setIsViewReportModalVisible}
        />
      )}
    </Spin>
  );
};

export default RenterScreenings;
