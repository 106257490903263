export const INVITE_AGENT = "INVITE_AGENT";
export const INVITE_AGENT_FAIL = "INVITE_AGENT_FAIL";
export const INVITE_AGENT_SUCCESS = "INVITE_AGENT_SUCCESS";
export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS";
export const GET_TEAM_MEMBERS_SUCCESS = "GET_TEAM_MEMBERS_SUCCESS";
export const GET_TEAM_MEMBERS_FAIL = "GET_TEAM_MEMBERS_FAIL";
export const RESEND_CONFIRMATION_LINK = "RESEND_CONFIRMATION_LINK";
export const RESEND_CONFIRMATION_LINK_SUCCESS = "RESEND_CONFIRMATION_LINK_SUCCESS";
export const RESEND_CONFIRMATION_LINK_FAIL = "RESEND_CONFIRMATION_LINK_FAIL";
export const REMOVE_AGENT_FROM_TEAM = "REMOVE_AGENT_FROM_TEAM";
export const REMOVE_AGENT_FROM_TEAM_SUCCESS = "REMOVE_AGENT_FROM_TEAM_SUCCESS";
export const DEFAULT_INVITE_SUCCESS = "DEFAULT_INVITE_SUCCESS";
export const SET_QUERY_AND_PAGINATION = 'SET_TEAM_MEMBERS_QUERY_AND_PAGINATION';
export const SET_TEAM_MEMBERS_LOADER_TO_TRUE = "SET_TEAM_MEMBERS_LOADER_TO_TRUE";
export const SET_TEAM_MEMBERS_LOADER_TO_FALSE = "SET_TEAM_MEMBERS_LOADER_TO_FALSE";
export const SET_INVITE_AGENT_SUCCESS_FALSE = "SET_INVITE_AGENT_SUCCESS_FALSE";
export const UPDATE_TEAM_NAME = "UPDATE_TEAM_NAME";
export const UPDATE_TEAM_NAME_SUCCESS = "UPDATE_TEAM_NAME_SUCCESS";
export const UPDATE_TEAM_NAME_FAIL = "UPDATE_TEAM_NAME_FAIL";
