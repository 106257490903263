import React from "react";
import { Button } from "antd";
import { history } from "../../store";
import { AnalyticsService } from "../../helpers/analyticsService";
import BaseButton from "components/Shared/BaseButton";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

const BackToPreviousSearchButton = ({ backText }: { backText: string }) => {
  return (
    <div>
      <BaseButton
        className="back-to-listings-btn"
        variant="link"
        onClick={() => {
          // TODO: FIND THE NEXT PATH
          AnalyticsService.reportBackButtonClicked();
          history.goBack();
        }}
        icon={<ChevronLeftIcon className="h-5 w-5" />}
      >
        {backText}
      </BaseButton>
    </div>
  );
};

export default BackToPreviousSearchButton;
