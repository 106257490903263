import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { MenuIcon } from "../../icons";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { windowSelector } from "../../../store/app/selectors";
import { setWindowType } from "../../../store/app/action";
import SearchBar from "../SearchBar";
import { userSelector } from "../../../store/user/selectors";
import { useLocation } from "react-router";
import FilterModal from "../../Shared/filters/filter-modal";
import routes from "../../../routes/routesCode";
import AuthHeaderActions from "./components/auth-header-actions/AuthHeaderActions";
import { useParams } from "react-router-dom";
import StatusFilter from "./StatusFilter";
import { AnalyticsService } from "helpers/analyticsService";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import DownOutlined from "@ant-design/icons/DownOutlined";
import BaseButton from "components/Shared/BaseButton";
import { isListingRoute } from "helpers/clientRoutes";

const PageHeader = ({ setIsFilterOpened }: { setIsFilterOpened: any }) => {
  const dispatch = useDispatch();
  const [pageName, setPageName] = useState<string | undefined>("");
  const userData = useSelector(userSelector);
  const [isSearchFocus, setIsSearchFocus] = useState(false);
  const { type, collapsed } = useSelector(windowSelector);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const location = useLocation();
  const { lead_id, lId, landlordId }: any = useParams();
  const [showTitle, setShowTitle] = useState(true);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  const toggleSidebar = useCallback(() => {
    const window = {
      type,
      collapsed: !collapsed,
    };
    localStorage.setItem("windowType", JSON.stringify(window));
    dispatch(setWindowType(window));
  }, [type, collapsed]);

  const openFilterModalHandler = () => {
    AnalyticsService.reportFiltersModalOpen("filters_modal");
    setIsFilterModalVisible(true);
  };

  const closeFilterModalHandler = () => {
    AnalyticsService.reportFiltersModalClose(queryObject);
    setIsFilterModalVisible(false);
  };

  const isRenterPortal = useMemo(() => {
    return location.pathname.indexOf("renter-portal") > -1;
  }, [location.pathname]);

  const detectShowFilter = useMemo(() => {
    return (
      location.pathname === routes.listings.path ||
      location.pathname === routes.landlords.path ||
      location.pathname === routes.home.path
    );
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes("/teamsMembers") || location.pathname.includes("teamDashboard")) {
      setTeamsPageName();
    } else {
      if (location.pathname === "/") {
        setPageName("Listings");
      } else {
        const [elm] = Object.values(routes).filter((x) => location.pathname.includes(x.path) && x.path !== "/");
        setPageName(elm?.title);
      }
    }
  }, [location, userData]);

  const setTeamsPageName = () => {
    if (location.pathname.includes("teamDashboard")) {
      setPageName(`Team Activity`);
    } else {
      setPageName("Manage Team");
    }
  };

  useEffect(() => {
    if (location.pathname.includes(lead_id || lId || landlordId || "manageListing")) {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  }, [lead_id, lId, landlordId]);

  useEffect(() => {
    isFilterModalVisible ? setIsFilterOpened(true) : setIsFilterOpened(false);
  }, [isFilterModalVisible]);

  // calulate filters count
  useEffect(() => {
    const {
      locations = [],
      asked_rent_from,
      asked_rent_to,
      pets_allowed = [],
      parking_type,
      bedrooms_from,
      bedrooms_to,
      bathrooms_from,
      bathrooms_to,
      availability_date_to,
      tags = [],
    } = queryObject;
    setFiltersCount(
      [
        locations.length,
        tags.length,
        asked_rent_from || asked_rent_to,
        pets_allowed.length,
        parking_type,
        bedrooms_from || bedrooms_to,
        bathrooms_from || bathrooms_to,
        availability_date_to,
      ].filter(Boolean).length
    );
  }, [queryObject]);

  const isMobile = type !== "desktop";

  return (
    <>
      {isMobile && collapsed && location.pathname !== "/lead-flow" && (
        <div className={"hamburger-menu p-4 pb-2"} data-test-id="hamburger-menu">
          <span className={"icon-wrapper"} onClick={toggleSidebar}>
            <MenuIcon />
          </span>
        </div>
      )}
      {!isRenterPortal && (
        <header className="px-4 pt-6 sm:px-10 flex flex-wrap items-center justify-between">
          <div className="flex justify-start gap-4 items-center">
            <div className="flex gap-4 items-center">
              {showTitle && <h2 className="text-3xl font-semibold my-4">{pageName}</h2>}
              {isListingRoute(location.pathname) && (
                <div className="flex flex-wrap justify-start gap-2">
                  <StatusFilter user={userData} />
                </div>
              )}
              {detectShowFilter && showTitle && (
                <BaseButton onClick={openFilterModalHandler}>
                  <div className="flex justify-start flex-nowrap">
                    Filters {filtersCount > 0 && <span className={"filters-count"}>({filtersCount})</span>}
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </BaseButton>
              )}
            </div>
            {!isMobile &&
              !location.pathname.includes("/settings") &&
              !location.pathname.includes("/leads") &&
              !location.pathname.includes("/tenant-screenings") &&
              !location.pathname.includes("/listings/") &&
              !location.pathname.includes("/landlords/") &&
              !location.pathname.includes("/manageListing") && (
                <div className="w-60">
                  <SearchBar isSearchFocus={isSearchFocus} setIsSearchFocus={setIsSearchFocus} />
                </div>
              )}
          </div>
          <div className="flex justify-end gap-4 items-center">
            <AuthHeaderActions searchBarFocused={isSearchFocus} />
          </div>
          <FilterModal isFilterModalVisible={isFilterModalVisible} closeModalHandler={closeFilterModalHandler} />
        </header>
      )}
    </>
  );
};

export default PageHeader;
