import React from "react";

export const PawIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M9.36202 7.41975C8.73205 6.43698 7.65912 5.85016 6.49189 5.85016C5.32465 5.85016 4.25173 6.43698 3.62186 7.41975L2.10386 9.78787C1.852 10.1807 1.73579 10.6347 1.76772 11.1003C1.79966 11.566 1.97686 11.9996 2.2801 12.3547C2.58355 12.7094 2.98452 12.9518 3.43964 13.0559C3.89477 13.1599 4.36132 13.1155 4.78878 12.9276L4.8174 12.9149C5.893 12.4515 7.12208 12.4558 8.19489 12.9276C8.47164 13.0493 8.76484 13.1108 9.06071 13.1108C9.22156 13.1108 9.38338 13.0925 9.54371 13.056C9.99883 12.9521 10.3998 12.7096 10.7034 12.3548C11.0067 12 11.184 11.5663 11.2161 11.1005C11.2481 10.6347 11.1319 10.1808 10.88 9.78777L9.36202 7.41975Z" fill="black" />
                <path d="M2.51276 7.41668C2.96436 7.24322 3.30743 6.87931 3.47876 6.39193C3.64186 5.92816 3.62851 5.40457 3.44106 4.9175C3.25349 4.43076 2.91234 4.03342 2.48039 3.79855C2.02666 3.55192 1.52817 3.51176 1.07731 3.68554C0.170269 4.03428 -0.245976 5.15516 0.149548 6.18472C0.465497 7.00418 1.20239 7.52147 1.9474 7.52147C2.13763 7.52147 2.3284 7.48771 2.51276 7.41668Z" fill="black" />
                <path d="M5.45818 5.56136C6.59209 5.56136 7.51462 4.51033 7.51462 3.21844C7.51462 1.92624 6.59209 0.875 5.45818 0.875C4.32437 0.875 3.40195 1.92624 3.40195 3.21844C3.40195 4.51033 4.32437 5.56136 5.45818 5.56136Z" fill="black" />
                <path d="M9.00965 6.13696H9.00976C9.18418 6.19486 9.36213 6.22252 9.53933 6.22252C10.3663 6.22252 11.1722 5.62064 11.4797 4.69565C11.6568 4.16319 11.6451 3.60019 11.4469 3.11046C11.2394 2.59776 10.855 2.22563 10.3643 2.06253C9.87354 1.89943 9.3428 1.96747 8.86973 2.25394C8.41781 2.52759 8.07153 2.9716 7.89465 3.50406C7.52134 4.62729 8.02154 5.80841 9.00965 6.13696Z" fill="black" />
                <path d="M13.3403 5.86352L13.34 5.8632C12.5581 5.28556 11.3886 5.53593 10.7329 6.42161C10.0778 7.30772 10.1802 8.49898 10.961 9.07705C11.2458 9.288 11.5822 9.38883 11.9266 9.38883C12.5268 9.38883 13.1512 9.0825 13.5686 8.51917C14.2236 7.63306 14.1212 6.4418 13.3403 5.86352Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}