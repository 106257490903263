import { IObjectKeys } from "../../helpers/globalTypes";
import * as types from "./types";

export const fillPrivacyPolicyData = (payload: IObjectKeys) => ({
  type: types.FILL_PRIVACY_POLICY_DATA,
  payload,
});

export const loadingTermsOrPrivacyData = () => ({
  type: types.LOADING_TERMS_OR_PRIVACY_DATA,
});

export const loadingTermsOrPrivacyDataSuccess = () => ({
  type: types.LOADING_TERMS_OR_PRIVACY_DATA_SUCCESS,
});

export const fillTermsAndConditionsData = (payload: IObjectKeys) => ({
  type: types.FILL_TERMS_AND_CONDITIONS_DATA,
  payload,
});
