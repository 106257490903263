import React from "react";
import LeaseStatus from "./lease-status/LeaseStatus";
import Budget from "./budget/Budget";

const LeaseStatusAndBudget = () => {
  return (
    <div className={"lease-status-and-budget-wrapper"}>
      <LeaseStatus />
      <Budget />
    </div>
  );
};

export default LeaseStatusAndBudget;
