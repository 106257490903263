import { Col, List, Pagination, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import { TablePaginationConfig } from "antd/lib/table/interface";
import IndividualLeadEmptyState from "components/Empty-page-wrapper/components/empty-state-onboard-lead/components/individual-lead-empty-state/IndividualLeadEmptyState";
import AddFollowUpModal from "components/FollowUps/add-follow-up-modal/AddFollowUpModal";
import RequestScreeningDialog from "components/Lead/renter-lead-page/RequestScreeningModal/LeadRequestScreeningModal";
import ActivateSection from "components/Shared/ActivateSection";
import { push } from "connected-react-router";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { IObjectKeys } from "helpers/globalTypes";
import { useDemoContext } from "libs/contextLib";
import { debounce, isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getAgentAllSrRequest } from "store/agent/action";
import { agentAllSRSelector } from "store/agent/selectors";
import { getShareableLandlordEntity } from "store/shareable/action";
import { createURL, getSrRenterIdFromQuery, scrollToTop, urlToSearchState } from "../../helpers/globalFunctions";
import { useWindowSize } from "../../hooks/useWindowSize";
import EmptyPageLayout from "../../layouts/empty/EmptyPageLayout";
import { getAreas } from "../../store/areas/action";
import { getEnums } from "../../store/enums/action";
import {
  getLeadsList,
  setLeadsFilterOptions,
  setLeadsLastQuery,
  setLeadsPaginationValues,
} from "../../store/leads/action";
import {
  filterOptionsSelector,
  leadDataSelector,
  leadLoadingSelector,
  leadsTotalSelector,
  paginationValuesSelector,
  singleLeadDataSelector,
} from "../../store/leads/selectors";
import { routerSelector } from "../../store/listings/selectors";
import { userSelector } from "../../store/user/selectors";
import BaseLeadsTable from "../BaseLeadsTable";
import AddFollowUpModalMobile from "../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import PhoneVerifyModalFollowUps from "../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import LeadNoteModal from "../Lead/renter-lead-page/LeadNoteModal";
import { IPaginationValues } from "../Listings/components/listings-wrapper";
import PremiumUpgradeModal from "../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import AfterCallModal from "../Shared/AfterCallModal/AfterCallModal";
import ContactModal from "../Shared/ContactModal/ContactModal";
import FilteredBySection from "../Shared/filters/filtered-by-section";
import { generateLeadFilterData } from "../Shared/filters/helpers/filterFormHelper";
import {
  createFilterQuery,
  getPaginationValuesForLeads,
  initialFilterOptionsFromSearchQuery,
} from "../Shared/filters/helpers/listingsCreateGetSearchQuery";
import ShowingPagination from "../ShowingPagination";
import { LEADS_COLUMNS } from "./leadsHelpers";
import LeadMobileItem from "./mobile-lead-item/LeadMobileItem";
import "./styles.scss";
import GlobalLoading from "components/Shared/GlobalLoading";

const Leads = () => {
  const dispatch = useDispatch();
  const router = useSelector(routerSelector);
  const [form] = useForm();
  const leadsData = useSelector(leadDataSelector);
  const leadsFilterOptions = useSelector(filterOptionsSelector);
  const pagination = useSelector(paginationValuesSelector);
  const total = useSelector(leadsTotalSelector);
  const queryObject = urlToSearchState(router.location.search);
  const [showingCount, setShowingCount] = useState(localStorage.getItem("leads_showing_count") || 24);
  const { width }: any = useWindowSize();
  const leadData = useSelector(singleLeadDataSelector);
  const [leadsCurrentQuery, setLeadsCurrentQuery] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const initialModalValue = { visible: false, editModal: false };
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(Number(router.location.query?.current) || 1);
  const [landlordActionModalVisible, setLandlordActionModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState(initialModalValue);
  const userData = useSelector(userSelector);
  const [afterCallModalProps, setAfterCallModalProps] = useState(false);
  const [followUpModalProps, setFollowUpsModalProps] = useState(initialModalValue);
  const [noteModalProps, setNoteModalProps] = useState(initialModalValue);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const { isDemo, demoAction } = useDemoContext();
  const history = useHistory();
  const isLeadShare = location.search.includes("isRenterShared");
  const [showAllLeads, setShowAllLeads] = useState(false);
  const isEmptyState =
    location.search.includes("qualifyLead=true") ||
    location.search.includes("fab=true") ||
    location.search.includes("action=add_lead");
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const [action, setAction] = useState("");
  const [screeningModalVisible, setScreeningModalVisible] = useState(false);
  const { search } = useLocation();
  const leadLoading = useSelector(leadLoadingSelector);
  const { allData } = useSelector(agentAllSRSelector);

  const closeAfterCallModalHandler = () => {
    setAfterCallModalProps(false);
  };
  const delayedQueryCall = useCallback(
    debounce(() => {
      const urlParsedValue: any = urlToSearchState(
        createFilterQuery(
          "",
          { ...leadsFilterOptions, search: queryObject.search },
          pagination,
          queryObject,
          null,
          "leads"
        )
      );
      const urlObject = generateLeadFilterData(urlParsedValue, queryObject);
      dispatch(getLeadsList(urlObject));
      dispatch(getAreas({ limit: "all", fromPage: "leads", isMyEntity: true }));
      dispatch(getEnums());
    }, 500),
    [queryObject]
  );

  const handleTableChange = (paginationOpt: TablePaginationConfig, _?: any, sorter?: any): void => {
    const queryObjectUrl = {
      ...leadsFilterOptions,
      ...{
        ...pagination,
        current: queryObject.current ? queryObject.current : paginationOpt.current,
        size: localStorage.getItem("leads_showing_count")
          ? JSON.parse(localStorage.getItem("leads_showing_count") as string)
          : showingCount,
      },
    };
    if (queryObject.locations) {
      queryObjectUrl.locations = queryObject.locations;
    }
    if (queryObject.pets_allowed) {
      queryObjectUrl.pets_allowed = queryObject.pets_allowed;
    }
    if (queryObject.parking_type) {
      queryObjectUrl.parking_type = queryObject.parking_type;
    }
    const newSearchQuery = createURL(queryObjectUrl);
    dispatch(
      push({
        pathname: router.location.pathname,
        search: newSearchQuery,
      })
    );
  };

  useEffect(() => {
    if (userData.agent_id) {
      dispatch(getShareableLandlordEntity(userData.agent_id));
    }
  }, [userData.agent_id]);

  useEffect(() => {
    if (!isEqual(urlToSearchState(router.location.search), urlToSearchState(leadsCurrentQuery)) || firstRender) {
      const initialFilterOptionsGenerator = () => {
        if (router.location.search) {
          return initialFilterOptionsFromSearchQuery(queryObject);
        } else {
          return {};
        }
      };
      if (!queryObject.current) queryObject.current = "1";
      const initialPaginationValues: IPaginationValues = getPaginationValuesForLeads(queryObject);

      const initialFilterOptionsToSet = initialFilterOptionsGenerator();
      dispatch(setLeadsFilterOptions(initialFilterOptionsToSet));
      dispatch(setLeadsPaginationValues(initialPaginationValues));
      if (firstRender) setFirstRender(false);
      delayedQueryCall();
      setLeadsCurrentQuery(router.location.search);
      dispatch(setLeadsLastQuery(router.location.search));

      //Scroll To Top after any request
      const LeadsTableWrapperRef: HTMLElement | null = document.querySelector(".base-leads-table");
      scrollToTop(LeadsTableWrapperRef);
      setShowingCount && setShowingCount(24);
    }
  }, [router.location.search]);

  const handleChangeShowingCount = (count: number) => {
    // this will change page to previous if current page is not available in new page size
    const targetPage = total / count < currentPage ? Math.ceil(total / count) : currentPage;
    const urlObject = {
      ...router.location.query,
      size: count.toString(),
      current: targetPage,
      myLeads: true,
    };
    setCurrentPage(targetPage);
    dispatch(getLeadsList(urlObject));
    setShowingCount(count);
    const newSearchQuery = createURL(urlObject);
    dispatch(
      push({
        pathname: router.location.pathname,
        search: newSearchQuery,
      })
    );
    localStorage.setItem("leads_showing_count", JSON.stringify(count));
  };

  const handlePageChange = (page: number) => {
    const urlObject = {
      ...router.location.query,
      current: page,
      size: localStorage.getItem("leads_showing_count") || 24,
      myLeads: true,
    };
    dispatch(getLeadsList(urlObject));
    setCurrentPage(page);
    const newSearchQuery = createURL(urlObject);
    dispatch(
      push({
        pathname: router.location.pathname,
        search: newSearchQuery,
      })
    );
  };

  useEffect(() => {
    if (!location.search && !localStorage.getItem("leads_showing_count")) {
      localStorage.setItem("leads_showing_count", "24");
    }
  }, [location]);

  useEffect(() => {
    if (!afterCallModalProps) localStorage.removeItem("contact_email_selected");
  }, [afterCallModalProps]);

  useEffect(() => {
    if (isDemo) history.push("/demo");
  }, [isDemo]);

  useEffect(() => {
    if (userData?.agent_id) dispatch(getAgentAllSrRequest(userData.agent_id));
  }, [userData]);

  useEffect(() => {
    if (getSrRenterIdFromQuery(search) && allData.length) {
      let srrId = getSrRenterIdFromQuery(search);
      let reportInfo = allData.filter(({ screening_request_renter_id }: any) => screening_request_renter_id == srrId);
      reportInfo &&
        history.push(
          `/screening-reports/${reportInfo[0].requirement?._id}/${srrId || reportInfo[0].screening_request_renter_id}`
        );
    }
  }, [search, allData.length]);

  if (leadsData === null) {
    return <Spin spinning={leadLoading} className={"global-loading"} indicator={<GlobalLoading />} />;
  }

  if ((leadsData?.length === 0 && !router.location.search && !showAllLeads) || isLeadShare || isEmptyState) {
    return (
      <div className={"empty-leads-page-wrapper"}>
        <EmptyPageLayout classNames="empty-leads">
          <IndividualLeadEmptyState setShowAllLeads={setShowAllLeads} />
        </EmptyPageLayout>
      </div>
    );
  }

  return (
    <div className={"leads-content"}>
      {/* <FilteredBySection /> trash */}
      {isDemo && <ActivateSection type="lead" className="mt-25 activate-mobile" />}
      <div className={"flex justify-between align-center w-full mb-15"}>
        {width > 599 && (
          <Col className={"leads-pagination-showing-of-dsesktop"}>
            <ShowingPagination list={leadsData} total={total} />
          </Col>
        )}
        <div className={"leads-showing-total-mobile showing-filter-dropdown"}>
          <ShowingPagination list={leadsData} total={total} />
        </div>
      </div>
      <div className={"team-table-wrapper lead-table-wrapper"}>
        <BaseLeadsTable
          classnames="activities-table"
          bordered={false}
          columns={LEADS_COLUMNS}
          dataSource={leadsData?.map((data: IObjectKeys) => ({
            userData: userData,
            ...data,
          }))}
          rowKey={(record) => record._id}
          onChange={() => false}
          dataTestId="team-table"
          pagination={false}
          onRow={(record: any, rowIndex: any) => {
            return {
              onClick: (e: Event) => {
                if (
                  //@ts-ignore
                  e.target?.parentElement?.id === "listingQuickContactButton" ||
                  //@ts-ignore
                  e?.target?.id === "listingQuickContactButton"
                ) {
                  isDemo ? demoAction("lead") : setLandlordActionModalVisible(true);
                } else if (
                  //@ts-ignore
                  e.target?.parentElement?.id === "requestScreeningButton" ||
                  //@ts-ignore
                  e?.target?.id === "requestScreeningButton"
                ) {
                  isDemo ? demoAction("lead") : setScreeningModalVisible(true);
                } else {
                  dispatch(push(`/${isDemo ? "demo/leads" : "leads"}/${record._id}`));
                }
              },
            };
          }}
        />
        <div className="lead-table-pagination">
          <Pagination
            current={currentPage}
            showSizeChanger={false}
            total={total}
            pageSize={pagination.pageSize}
            responsive
            onChange={handlePageChange}
          />
        </div>
      </div>
      <div className={"lead-mobile-items-wrapper"}>
        <List
          data-test-id="leads-list-container"
          className="leads-list-wrapper scrollbar"
          id="leadsWrapperList"
          pagination={{
            pageSize: +pagination.pageSize,
            total,
            onChange: (page) => {
              if (leadsData !== 0) {
                setCurrentPage(page);
              } else setCurrentPage(router.location.query.current);
              // @ts-ignore
              handleTableChange({ current: page });
            },
            current: router.location.query.current || 1,
            showSizeChanger: false,
          }}
          dataSource={leadsData}
          renderItem={(e: any, i: any) => (
            <List.Item key={e._id}>
              <React.Fragment key={i}>
                <LeadMobileItem elem={e} />
              </React.Fragment>
            </List.Item>
          )}
        />
      </div>
      {landlordActionModalVisible && (
        <ContactModal
          setModalProps={setModalProps}
          currentLeadData={{
            ...leadData.renterOfRequirement,
            agentUser_ids: leadData.agentUser_ids,
          }}
          showModal={landlordActionModalVisible}
          onClose={setLandlordActionModalVisible}
          currentLandlordData={null}
          setAfterCallModalVisible={setAfterCallModalProps}
        />
      )}
      <AfterCallModal
        visible={afterCallModalProps}
        onClose={closeAfterCallModalHandler}
        key={1}
        setFollowUpModalVisible={setFollowUpsModalProps}
        setContactModalVisible={setAfterCallModalProps}
        setNoteModalVisible={setNoteModalProps}
        setPremiumModalVisible={setPremiumModalVisible}
      />
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            lead={leadData}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={action + " lead"}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            lead={leadData}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={action + " lead"}
          />
        )
      }
      <LeadNoteModal
        modalProps={noteModalProps}
        setModalProps={setNoteModalProps}
        initialModalValue={initialModalValue}
        form={form}
        defaultTitle={action + " lead"}
      />
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={() => setPhoneVerifyModalVisible(false)} />
      <RequestScreeningDialog
        visible={screeningModalVisible}
        setVisible={setScreeningModalVisible}
        renter={{
          renter_id: leadData.renterOfRequirement?.renterOfRequirement?.renter_id,
          name: leadData.renterOfRequirement?.renterOfRequirement?.name,
        }}
      />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_LEAD}
        id={leadData._id}
      />
    </div>
  );
};
export default Leads;
