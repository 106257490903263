import React from "react";
import BaseButton from "../../../Shared/BaseButton";
import "./SignUpSettingFormBottom.scss";
import { useLocation } from "react-router";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { urlQueryToObject } from "../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import qs from "query-string";
import { getLoggedInUser } from "../../../../store/user/action";
import { AnalyticsService } from "helpers/analyticsService";
import { getSignUpVerificationFlowStep } from "helpers/globalFunctions";

const SignUpSettingFormBottom = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const _newURLSearchParams = new URLSearchParams(search);
    const queryObject = urlQueryToObject(_newURLSearchParams);
    // const nextStepHandler = () => {
    //     AnalyticsService.reportSignUpProcess({ action: `sign_up_verification_flow_next_click_${getSignUpVerificationFlowStep()}` });
    //     if (!search.includes("step=2")) {
    //         queryObject.step = "2"
    //         dispatch(push({
    //             search: qs.stringify(queryObject)
    //         }))
    //         dispatch(getLoggedInUser())
    //     }
    // }
    const backStepHandler = () => {
        AnalyticsService.reportSignUpProcess({ action: `sign_up_verification_flow_back_click_${getSignUpVerificationFlowStep()}` });
        queryObject.step = "1";
        dispatch(push({
            search: qs.stringify(queryObject)
        }));
    };
    return (
        <div className={"sign-up-form-steps-bottom"}>
            {search.includes("step=2") && <span onClick={backStepHandler}>{"<"} Back</span>}
            {/*{!search.includes("step=2") &&*/}
            {/*    <BaseButton classnames={"rb-secondary-btn"} onClick={nextStepHandler}>*/}
            {/*        Next*/}
            {/*    </BaseButton>*/}
            {/*}*/}
        </div>
    );
};

export default SignUpSettingFormBottom;
