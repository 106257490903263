import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseButton from "../../Shared/BaseButton";
import { setCurrentSteps, setListingSaveClicked, setStepClicked } from "../../../store/listings/action";
import { steps } from "./formSteps";
import { getLandlord } from "../../../store/landlords/action";
import { getQueryParams } from "../../../helpers/globalFunctions";
import { useHistory, useLocation } from "react-router-dom";
import {
  currentStepSelector,
  isCurrentFormChangedSelector,
  listingLoadingSelector,
} from "../../../store/listings/selectors";
import { StepFormComponent } from "./types";
import { scrollToFirstErrorField } from "../../../helpers/globalFunctions";
import { windowSelector } from "../../../store/app/selectors";
import { AnalyticsService } from "helpers/analyticsService";

const StepsActionButtons = ({ form, next: nextClicked, prev: prevClicked }: StepFormComponent) => {
  const loading = useSelector(listingLoadingSelector);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const { lId, step, landlordId } = getQueryParams(search);
  const windowType = useSelector(windowSelector);
  const isCurrentFormChanged = useSelector(isCurrentFormChangedSelector);
  useEffect(() => {
    history.push({
      search: lId ? `lId=${lId}` : "",
    });
  }, [history, lId]);

  const current = useSelector(currentStepSelector);
  const reportStepChanged = (action: string, currentStep: number, state: string, errorMessage: any | null = null) => {
    const label =
      action === "next"
        ? `${currentStep + 1}->${currentStep + 2}`
        : action === "back"
        ? `${currentStep + 1}->${currentStep}`
        : currentStep + 1;
    AnalyticsService.reportEvent(`listing_edit_${action}`, {
      category: "listings",
      label: `${label} | ${state}`,
      errorMessage: errorMessage && `${errorMessage.name[0]} | ${errorMessage.errors[0]}`,
    });
  };

  const next = () => {
    dispatch(setCurrentSteps(current + 1));
    nextClicked && nextClicked();
  };
  const prev = () => {
    dispatch(setCurrentSteps(current - 1));
    prevClicked && prevClicked();
  };

  useEffect(() => {
    if (step) {
      switch (step) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
          dispatch(setCurrentSteps(+step));
          break;
        default:
          dispatch(setCurrentSteps(0));
      }
      if (landlordId) {
        dispatch(getLandlord({ _id: landlordId }));
      }
    }

    // if (step && Number(step) === 1 && landlordId) {
    //   setCurrent(current + 1);
    //   dispatch(getLandlord({ _id: landlordId }));
    // }
  }, [landlordId, lId, step, dispatch]);

  const formSteps = steps({ form, next, prev, currentStep: current });
  const scrollToTopAfterClickHandler = () => {
    const scrollTo: HTMLElement | null = document.querySelector(".page-header-wrapper");
    scrollTo?.scrollIntoView();
  };

  return (
    <div className="flex flex-wrap justify-between items-center">
      <div>
        {current > 0 && (
          <BaseButton
            classnames={"md mr-10 back-button"}
            onClick={() => prev()}
            loading={loading}
            dataTestId={"previous"}
          >
            {"Back"}
          </BaseButton>
        )}
      </div>
      <div className="flex justify-start gap-2 step-actions-wrapper">
        {current === formSteps.length - 1 && (
          <BaseButton
            variant="primary"
            loading={loading}
            onClick={() => {
              dispatch(setStepClicked(false));
              form.submit();
              reportStepChanged("done", current, "success");
            }}
            dataTestId={"done"}
          >
            Done
          </BaseButton>
        )}
        {(lId && current < 6) || (current > 2 && current < 6)
          ? windowType.type !== "mobile" && (
              <BaseButton
                classnames={"next-and-close-button-mobil"}
                loading={loading}
                onClick={() => {
                  scrollToTopAfterClickHandler();
                  dispatch(setStepClicked(false));
                  dispatch(setListingSaveClicked(true));
                  form
                    .validateFields()
                    .then(() => {
                      form.submit();
                      reportStepChanged("saveAndClose", current, "success");
                    })
                    .catch((e) => {
                      scrollToFirstErrorField();
                      dispatch(setListingSaveClicked(false));
                      reportStepChanged("saveAndClose", current, "error", e.errorFields[0]);
                    });
                }}
                dataTestId={"save"}
              >
                Save and Close
              </BaseButton>
            )
          : ""}
        {current < formSteps.length - 1 && (
          <BaseButton
            classnames={"rb-primary-btn md mr-10 next-and-close-button-mobile"}
            onClick={() => {
              scrollToTopAfterClickHandler();
              dispatch(setStepClicked(false));
              form
                .validateFields()
                .then(() => {
                  if (isCurrentFormChanged) {
                    form.submit();
                    reportStepChanged("next", current, "success");
                  } else if (!lId) {
                    form.submit();
                    reportStepChanged("next", current, "success");
                  } else {
                    next();
                  }
                })
                .catch((e) => {
                  scrollToFirstErrorField();
                  reportStepChanged("next", current, "error", e.errorFields[0]);
                });
            }}
            loading={loading}
            dataTestId={"next"}
            variant="primary"
          >
            {"Next"}
          </BaseButton>
        )}
      </div>
    </div>
  );
};
export default StepsActionButtons;
