import React from "react";
import {
  createFilterQuery,
  urlQueryToObject,
} from "../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { push } from "connected-react-router";
import { Radio } from "antd";
import { GridViewIcon, ListViewIcon } from "../../../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  filterOptionsSelector,
  listingsViewTypeSelector,
  paginationValuesSelector,
} from "../../../../store/listings/selectors";
import { useHistory } from "react-router-dom";
import "./ListTypeBtn.scss";

const ListTypeBtn = ({
  setIsViewTypeButtonClicked,
}: {
  setIsViewTypeButtonClicked:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const _newURLSearchParams = new URLSearchParams(history.location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const listingsViewType = useSelector(listingsViewTypeSelector);
  const paginationValues = useSelector(paginationValuesSelector);
  const listingsFilterOptions = useSelector(filterOptionsSelector);
  const filterOptionsWithoutSortOption = { ...listingsFilterOptions };
  const queryObjectWithoutSortOption = { ...queryObject };
  return (
    <Radio.Group
      className={"listing-view-btn-wrapper"}
      size="large"
      value={listingsViewType}
      onChange={({ target: { value } }) => {
        localStorage.setItem("listingViewType", value);
        setIsViewTypeButtonClicked && setIsViewTypeButtonClicked(true);
        filterOptionsWithoutSortOption["pets_allowed"] =
          queryObject.pets_allowed;
        queryObjectWithoutSortOption["pets_allowed"] = queryObject.pets_allowed;
        filterOptionsWithoutSortOption["locations"] = queryObject.locations;
        queryObjectWithoutSortOption["locations"] = queryObject.locations;
        queryObjectWithoutSortOption["parking_type"] = queryObject.parking_type;
        filterOptionsWithoutSortOption["parking_type"] =
          queryObject.parking_type;
        const newSearchQuery = createFilterQuery(
          value,
          { ...filterOptionsWithoutSortOption },
          paginationValues,
          { ...queryObjectWithoutSortOption }
        );
        dispatch(
          push({
            pathname: history.location.pathname,
            search: `${newSearchQuery}${
              queryObject.search ? `&search=${queryObject.search}` : ""
            }`,
          })
        );
      }}
    >
      {listingsViewType === "list" ? (
        <Radio.Button className={"list-type-view-btn"} value="grid">
          <GridViewIcon />
        </Radio.Button>
      ) : (
        <Radio.Button className={"list-type-view-btn"} value="list">
          <ListViewIcon />
        </Radio.Button>
      )}
    </Radio.Group>
  );
};
export default ListTypeBtn;
