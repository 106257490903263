import React from "react";

const RequirementNeedHelpSection = () =>{
    return(
        <div className={"need-help-section"}>
                <span>
                    Need help?{" "}
                    <a href="mailto:support@therentbase.com" target="_blank" rel="noopener noreferrer">Contact us</a>
                </span>
        </div>
    )
}

export default RequirementNeedHelpSection