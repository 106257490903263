import React from "react";

const TrashIconNotes = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#141414"/>
            <path
                d="M13.668 7.96582H16.342V8.41577H17.3077V7.90283C17.3079 7.40503 16.9031 7 16.4055 7H13.6045C13.1069 7 12.7021 7.40503 12.7021 7.90283V8.41577H13.668V7.96582Z"
                fill="white"/>
            <path
                d="M19.5381 12.2422H10.4722C10.2238 12.2422 10.0283 12.4541 10.0483 12.7018L10.8062 22.0739C10.8485 22.597 11.2849 23 11.8091 23H18.2011C18.7254 23 19.1618 22.597 19.204 22.0737L19.962 12.7018C19.9821 12.4541 19.7866 12.2422 19.5381 12.2422ZM12.6674 22.0004C12.6573 22.001 12.6472 22.0013 12.6372 22.0013C12.384 22.0013 12.1715 21.8042 12.1557 21.5481L11.6807 13.8541C11.6644 13.5879 11.8669 13.3588 12.133 13.3424C12.3983 13.3263 12.6284 13.5283 12.6447 13.7947L13.1196 21.4886C13.1361 21.7549 12.9335 21.9839 12.6674 22.0004ZM15.4935 21.5184C15.4935 21.785 15.2773 22.0012 15.0106 22.0012C14.7438 22.0012 14.5277 21.785 14.5277 21.5184V13.8243C14.5277 13.5576 14.7438 13.3414 15.0106 13.3414C15.2772 13.3414 15.4935 13.5576 15.4935 13.8243V21.5184ZM18.3297 13.8528L17.8762 21.5468C17.8611 21.8033 17.6483 22.0012 17.3946 22.0012C17.3851 22.0012 17.3754 22.001 17.3658 22.0005C17.0996 21.9847 16.8964 21.7562 16.9122 21.49L17.3655 13.7959C17.3812 13.5297 17.609 13.3265 17.8759 13.3423C18.1422 13.3579 18.3453 13.5865 18.3297 13.8528Z"
                fill="white"/>
            <path
                d="M21.2077 10.752L20.8906 9.80127C20.807 9.55066 20.5724 9.38159 20.3081 9.38159H9.70152C9.43736 9.38159 9.20262 9.55066 9.11912 9.80127L8.80199 10.752C8.74083 10.9353 8.82042 11.1223 8.96898 11.2156C9.02952 11.2535 9.10118 11.2764 9.17992 11.2764H20.8298C20.9086 11.2764 20.9803 11.2535 21.0408 11.2155C21.1893 11.1222 21.2689 10.9352 21.2077 10.752Z"
                fill="white"/>
        </svg>
    );
};

export default TrashIconNotes;