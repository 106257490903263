import { sanitizeBudgetNumber } from "helpers/globalFunctions";
import { IObjectKeys } from "helpers/globalTypes";
import {
  IlocationItem,
  IRequirementsLocation,
} from "../components/requirement-form-steps/components/move-in-date-and-area/areas-section/types/types";

const bedRoomsDataHandler = (data: IObjectKeys, submitData: IObjectKeys) => {
  // Auto-set max fields to match min
  submitData["bedrooms_max"] = data.bedrooms_min;
  return submitData;
};
const bathRoomsDataHandler = (data: IObjectKeys, submitData: IObjectKeys) => {
  // Auto-set max fields to match min
  submitData["bathrooms_max"] = data.bathrooms_min;
  return submitData;
};
const mustHaveDataHandler = (data: IObjectKeys, submitData: IObjectKeys) => {
  if (data?.must_have?.length) {
    const must_have: any = {};
    data.must_have.forEach((el: string) => {
      must_have[el] = true;
    });
    submitData.must_have = must_have;
  }
  return submitData;
};
const dealBreakersHandler = (data: IObjectKeys, submitData: IObjectKeys) => {
  if (data.deal_breaker?.length) {
    const deal_breaker: any = {};
    data.deal_breaker.forEach((el: string) => {
      deal_breaker[el] = true;
    });
    submitData.deal_breaker = deal_breaker;
  }
  return submitData;
};

export const getRequirementsSubmitData = ({
  data,
  locations,
  requirementFormData,
  petsList,
  startDateValue,
  stepNumber,
  agent_id,
}: {
  data: IObjectKeys;
  locations: Array<IlocationItem>;
  isEditMode: boolean;
  requirementFormData: IObjectKeys;
  petsList: Array<IObjectKeys>;
  startDateValue: IObjectKeys | null;
  stepNumber: any;
  agent_id: string;
}) => {
  delete data.location;

  for (let key in data) {
    if (key.includes("sub_area") || key.includes("addr")) {
      delete data[key];
    }
    if (["budget_min", "budget_max"].includes(key)) {
      data[key] = Math.ceil(Number(sanitizeBudgetNumber(String(data[key]))));
    }
  }

  // TODO: move away from magic number...
  if (+stepNumber === 3) {
    data.area = locations.map((loc: IlocationItem) => {
      const returnLoc: IRequirementsLocation = {
        area: loc.area,
        location: loc.location,
        isFreeText: loc.isFreeText,
      };
      if (loc?.separated_fields?.state) returnLoc.state = loc?.separated_fields?.state;
      if (loc?.separated_fields?.town) returnLoc.town = loc?.separated_fields?.town;
      return returnLoc;
    });
    data.location = data.area.reduce((locations: any, ar: any) => {
      locations = [...locations, ...ar.location];
      return locations;
    }, []);
    // to remove freeFroms or empty data from location
    data.area = data.area.filter((item: IlocationItem) => item.area);

    if (startDateValue) {
      //@ts-ignore
      data.move_in_date_start = startDateValue.startOf("day").add("hours", 5);
      data.move_in_date_end = null;
    } else {
      if (data.move_in_date_start) {
        data.move_in_date_start = data.move_in_date_start.startOf("day").add("hours", 5);
      }
      if (data.move_in_date_end) {
        data.move_in_date_end = data.move_in_date_end.startOf("day").add("hours", 5);
      }
    }
  }

  const petsListFilter = [...petsList].map((p: IObjectKeys) => {
    delete p.id;
    return p;
  });

  const submitData = {
    ...data,
  };

  for (let key in data) {
    if (["bedrooms_min"].includes(key)) {
      bedRoomsDataHandler(data, submitData);
    }
    if (["bathrooms_min"].includes(key)) {
      bathRoomsDataHandler(data, submitData);
    }
    if (["must_have", "deal_breaker"].includes(key)) {
      mustHaveDataHandler(data, submitData);
      dealBreakersHandler(data, submitData);
    }
    if (["listings_applied"].includes(key)) {
      submitData.listings_applied = data.listings_applied;
      submitData.listings_viewed = data.listings_viewed;
    }
    if (["student_or_employed"].includes(key)) {
      if (data?.student_or_employed !== "student" || (data?.student_or_employed !== "neither" && data.yearly_income > 0)) {
        if (data.yearly_income) {
          submitData.yearly_income = Math.ceil(Number(sanitizeBudgetNumber(String(data.yearly_income))));
        }
      }
    }
  }

  return {
    ...submitData,
    agent_id: agent_id,
    has_cosigner: requirementFormData.has_cosigner ? "Yes" : "No",
    considering_buying: requirementFormData.considering_buying ? "Yes" : "No",
    parking: requirementFormData.parking ? requirementFormData.parking : false,
    pets: petsListFilter,
    number_of_parking: requirementFormData.parking ? data.number_of_parking : 0,
  };
};
