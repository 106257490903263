import React from "react";

const EyeOutlinedIcon = () => {
  return (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.1504 8.61346C21.8708 8.37301 21.5898 8.14332 21.308 7.92334L18.0224 11.2089C18.2226 11.7689 18.332 12.3717 18.332 12.9996C18.332 15.9397 15.9401 18.3317 13 18.3317C12.3721 18.3317 11.7693 18.2222 11.2093 18.022L8.74347 20.4878C10.1834 21.0785 11.6116 21.3785 13 21.3785C14.6584 21.3785 16.3735 20.9522 18.0976 20.1113C19.4516 19.4509 20.8151 18.5339 22.1504 17.3858C24.4079 15.4447 25.7986 13.5252 25.8566 13.4444C26.0478 13.1787 26.0478 12.8206 25.8566 12.5548C25.7986 12.4741 24.4079 10.5546 22.1504 8.61346Z"
          fill="#B7B7B7"
        />
        <path
          d="M13 16.8082C15.1001 16.8082 16.8086 15.0997 16.8086 12.9996C16.8086 12.8169 16.7949 12.6373 16.7698 12.4614L12.4618 16.7695C12.6378 16.7945 12.8173 16.8082 13 16.8082Z"
          fill="#B7B7B7"
        />
        <path
          d="M25.7768 0.223069C25.4794 -0.0743565 24.9971 -0.0743565 24.6996 0.223069L18.7151 6.20754C18.5092 6.09541 18.3033 5.98862 18.0975 5.88833C16.3734 5.04739 14.6583 4.62103 12.9999 4.62103C11.3416 4.62103 9.62642 5.04739 7.90229 5.88828C6.54836 6.54869 5.18478 7.46569 3.84949 8.61376C1.59206 10.5549 0.201365 12.4743 0.143271 12.5551C-0.0479199 12.8208 -0.0479199 13.179 0.143271 13.4447C0.201365 13.5255 1.59201 15.445 3.84949 17.3861C4.54128 17.9809 5.24069 18.5131 5.94239 18.9802L0.223049 24.6996C-0.0744277 24.9971 -0.0744277 25.4794 0.223049 25.7769C0.371787 25.9255 0.566686 25.9999 0.761635 25.9999C0.956584 25.9999 1.15153 25.9255 1.30022 25.7768L25.7768 1.30024C26.0743 1.00282 26.0743 0.520495 25.7768 0.223069ZM7.66789 12.9999C7.66789 10.0599 10.0598 7.66791 12.9999 7.66791C14.1959 7.66791 15.3011 8.06369 16.1915 8.73116L15.0987 9.82398C14.4965 9.42463 13.7751 9.19134 12.9999 9.19134C10.8999 9.19134 9.19132 10.8999 9.19132 12.9999C9.19132 13.7751 9.42461 14.4965 9.82396 15.0987L8.73114 16.1915C8.06367 15.3011 7.66789 14.1959 7.66789 12.9999Z"
          fill="#B7B7B7"
        />
      </svg>
  );
};

export default EyeOutlinedIcon;
