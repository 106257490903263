import { RootStateOrAny } from "react-redux";

export const agentSRSelector = (state: RootStateOrAny) => ({
    data: state.agentRequestReducer.agentRequestData,
    loading: state.agentRequestReducer.agentRequestLoading,
    error: state.agentRequestReducer.agentRequestError
})

export const agentSRQuerySelector = (state: RootStateOrAny) => state.agentRequestReducer.queryParams;

export const agentAllSRSelector = (state: RootStateOrAny) => ({
    allData: state.agentRequestReducer.agentsAllRequestData,
    allLoading: state.agentRequestReducer.agentsAllRequestLoading,
    allError: state.agentRequestReducer.agentsAllRequestError,
    allRequestsTotal: state.agentRequestReducer.allRequestsTotal,
    allPaginationValues: state.agentRequestReducer.agentAllPaginationValues,
})

export const agentAllSRQuerySelector = (state: RootStateOrAny) => state.agentRequestReducer.allqueryParams;