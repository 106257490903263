import BaseButton from "components/Shared/BaseButton";
import React from "react";

export const RenterScreeningRequestActionColumn = ({
  rowData,
  setRenterScreeningModalVisible,
  setSelectedRequestData,
  setIsViewReportModalVisible,
  setReportData,
  setIsCallTUModalVisible,
  setIsCallRentBaseModalVisible,
}: {
  rowData: any;
  setRenterScreeningModalVisible: any;
  setSelectedRequestData: any;
  setIsViewReportModalVisible: any;
  setReportData: any;
  setIsCallTUModalVisible: any;
  setIsCallRentBaseModalVisible: any;
}) => {
  const handleDownloadReport = async () => {
    setReportData(rowData);
    setIsViewReportModalVisible(true);
  };

  const handleApply = async () => {
    setRenterScreeningModalVisible(true);
    setSelectedRequestData(rowData);
  };

  const handleCallTU = () => {
    setIsCallTUModalVisible(true);
  };

  const handleCallRentBase = () => {
    setIsCallRentBaseModalVisible(true);
  };

  const status = rowData.screening_status;

  if (status === "Created" || status === "VerificationPending" || status === "VerificationCompleted") {
    return (
      <BaseButton variant="primary" type={"button"} onClick={handleApply}>
        Apply
      </BaseButton>
    );
  }

  if (status === "ManualVerification") {
    return (
      <BaseButton type={"button"} onClick={handleCallTU}>
        Call TransUnion
      </BaseButton>
    );
  }

  if (status === "ReportFailed") {
    return (
      <BaseButton
        classnames={"ant-btn rb-secondary-btn rb-secondary-btn-without-shadow"}
        type={"button"}
        onClick={handleCallRentBase}
      >
        Call RentBase Support
      </BaseButton>
    );
  }

  if (status === "ReportAvailable" && rowData.screening_request_renter_id) {
    return (
      <BaseButton type={"button"} onClick={handleDownloadReport}>
        View Report
      </BaseButton>
    );
  }

  return <div></div>;
};
