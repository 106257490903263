import { Col, DatePicker, Form, FormInstance, Radio, Row } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { US_DATE_FORMAT } from "../../../../helpers/constants";
import { getListingInput } from "../../../../helpers/globalFunctions";
import { POSITIVE_WITH_MESSAGE, REQUIRED } from "../../../../helpers/validations";
import { listingDataSelector } from "../../../../store/listings/selectors";

const OffMarketForm = ({ showOtherRenewal, form }: { form: FormInstance; showOtherRenewal: boolean }) => {
  const listingData = useSelector(listingDataSelector);
  const startDateSelectHandler = (value: any) => {
    if (value) {
      // @ts-ignore
      form.setFieldsValue({
        lease_ending_date: moment(value).add(1, "y").subtract(1, "day"),
      });
    } else
      form.setFieldsValue({
        lease_ending_date: moment(form.getFieldValue("lease_start_date")).add(1, "y").subtract(1, "day") || null,
      });
  };
  useEffect(() => {
    form.setFieldsValue({
      lease_ending_date: moment(form.getFieldValue("lease_start_date")).add(1, "y").subtract(1, "day"),
    });
  }, []);
  return (
    <div className={"off-market-step"}>
      {getListingInput("asked_rent", "Rent:", [POSITIVE_WITH_MESSAGE], "Ex. $1200", "", "", true)}
      <Form.Item name="lease_start_date" label="Lease Start Date" rules={[REQUIRED]} labelCol={{ span: 24 }}>
        <DatePicker
          format={US_DATE_FORMAT}
          className="w-full"
          placeholder={"Ex. 01-21-2023"}
          onChange={startDateSelectHandler}
        />
      </Form.Item>
      <Form.Item name="lease_ending_date" label="Lease End Date" rules={[{ required: false }]} labelCol={{ span: 24 }}>
        <DatePicker
          format={US_DATE_FORMAT}
          className="w-full"
          placeholder={"Ex. 01-01-2021"}
          defaultValue={moment(form.getFieldValue("lease_start_date")).add(1, "y").subtract(1, "day")}
          disabledDate={(value) => {
            let endDate = moment(form.getFieldValue("lease_start_date"));
            return value < endDate;
          }}
        />
      </Form.Item>
      <Form.Item className={"enum_input"} name="renewal_date" label="Renewal Date" labelCol={{ span: 24 }}>
        <Radio.Group>
          <Row>
            {[30, 60, 90].map((n) => (
              <Col className={"text-left"} key={n}>
                <Radio key={n} value={n} data-test-id={`renewal-date-option-${n}`}>
                  {n} Days
                </Radio>
              </Col>
            ))}
            <Radio value={-1} data-test-id={"renewal-date-option-other"}>
              Specific date
            </Radio>
          </Row>
        </Radio.Group>
      </Form.Item> 
        <Form.Item hidden={!showOtherRenewal} name={"other_renewal_date"} rules={showOtherRenewal ? [REQUIRED]: undefined} data-test-id={"other_renewal_date"}>
          <DatePicker
            format={US_DATE_FORMAT}
            className="w-full"
            placeholder={"Select Date"}
            disabledDate={(value) => {
              let endDate = moment(form.getFieldValue("other_renewal_date")).add(1, "day");
              return value > endDate;
            }}
            value={moment(listingData.other_renewal_date) || moment()}
          />
        </Form.Item>
    </div>
  );
};
export default OffMarketForm;
