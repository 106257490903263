import { Card, Col, Row } from "antd";
import { LeadScreeningRequestActionColumn } from "components/Lead/renter-lead-page/RequestScreeningModal/components/LeadScreeningReqAction";
import DateColumn from "components/Shared/Columns/DateColumn";
import PropertyLinkColumn from "components/Shared/Columns/PropertyLinkColumn";
import ScreeningStatusColumn from "components/Shared/Columns/ScreeningStatusColumn";
import React, { memo } from "react";
import "./TenantScreeningItem.scss";

const TenantScreeningItem: React.FC<any> = ({ item }: any) => {
  return (
    <Card hoverable className={"tenant-screening-card-wrapper"}>
      <div className="tenant-screening-card-header relative">
        <div className="tenant-screening-address-wrapper">{PropertyLinkColumn('', item).children}</div>
      </div>
      <Row>
        <Col xs={18} md={18}>
          {ScreeningStatusColumn(item).children} | <span>{DateColumn("", item).children}</span>
        </Col>
        <Col xs={6} md={6} lg={6}>
          <LeadScreeningRequestActionColumn rowData={item} />
        </Col>
      </Row>
    </Card>
  );
};

export default memo(TenantScreeningItem);
