import * as types from "./types";
import { AuthError } from "../../types/user";
import { IObjectKeys } from "../../helpers/globalTypes";

export const signIn = (
  payload: { email: string; password: string; remember_me?: boolean },
  search: string | Array<string>
) => ({
  type: types.SIGN_IN,
  payload: {
    data: payload,
    search: search,
  },
});
export const signInSuccess = (payload: { _id: string; email: string }) => ({
  type: types.SIGN_IN_SUCCESS,
  payload,
});
export const signInFail = (payload: AuthError) => ({
  type: types.SIGN_IN_FAIL,
  payload,
});
export const signUpAgent = (payload: { email: string; name: string; phone?: string; enrichments?: object }) => ({
  type: types.SIGN_UP_AGENT,
  payload: {
    data: { ...payload, profiles: { agent: true }, defaultState: "", defaultCity: "" },
  },
});
export const signUpAgentSuccess = (payload: { _id: string; email: string }) => ({
  type: types.SIGN_UP_AGENT_SUCCESS,
  payload,
});
export const signUpAgentFail = (payload: AuthError) => ({
  type: types.SIGN_UP_AGENT_FAIL,
  payload,
});

export const setSignUpSuccess = () => ({
  type: types.SET_SIGN_UP_SUCCESS,
});

export const forceResetPassword = (payload: { email: string; newPassword: string; code: string }) => ({
  type: types.FORCE_RESET_PASSWORD,
  payload,
});
export const forceResetPasswordSuccess = (payload: IObjectKeys) => ({
  type: types.FORCE_RESET_PASSWORD_SUCCESS,
  payload,
});
export const forceResetPasswordFail = (payload: AuthError) => ({
  type: types.FORCE_RESET_PASSWORD_FAIL,
  payload,
});

export const resetPassword = (payload: { email: string; persona?: string }) => ({
  type: types.RESET_PASSWORD,
  payload,
});
export const resetPasswordSuccess = (payload: IObjectKeys) => ({
  type: types.RESET_PASSWORD_SUCCESS,
  payload,
});
export const resetPasswordFail = (payload: AuthError) => ({
  type: types.RESET_PASSWORD_FAIL,
  payload,
});

export const confirmResetPassword = (payload: { email: string; code: string; newPassword: string }) => ({
  type: types.CONFIRM_RESET_PASSWORD,
  payload,
});
export const confirmResetPasswordSuccess = (payload: IObjectKeys) => ({
  type: types.CONFIRM_RESET_PASSWORD_SUCCESS,
  payload,
});
export const confirmResetPasswordFail = (payload: AuthError) => ({
  type: types.CONFIRM_RESET_PASSWORD_FAIL,
  payload,
});

export const getLoggedInUser = (payload?: string) => ({
  type: types.GET_LOGGED_IN_USER,
  payload,
});
export const getLoggedInUserSuccess = (payload: { _id: string; email: string }) => ({
  type: types.GET_LOGGED_IN_USER_SUCCESS,
  payload,
});
export const getLoggedInUserFail = (payload: AuthError) => ({
  type: types.GET_LOGGED_IN_USER_FAIL,
  payload,
});

export function updateUserSettings(payload: IObjectKeys) {
  return {
    type: types.UPDATE_USER_SETTINGS,
    payload,
  };
}

export function updateUserSettingsSuccess(payload: IObjectKeys) {
  return {
    type: types.UPDATE_USER_SETTINGS_SUCCESS,
    payload,
  };
}
export function updateUserSettingsFail(payload: IObjectKeys) {
  return {
    type: types.UPDATE_USER_SETTINGS_FAIL,
    payload,
  };
}

export function getPhoneVerificationCode() {
  return {
    type: types.GET_PHONE_VERIFICATION_CODE,
  };
}

export function getPhoneVerificationCodeSuccess(payload: IObjectKeys) {
  return {
    type: types.GET_PHONE_VERIFICATION_CODE_SUCCESS,
    payload,
  };
}
export function getPhoneVerificationCodeFail(payload: IObjectKeys) {
  return {
    type: types.GET_PHONE_VERIFICATION_CODE_FAIL,
    payload,
  };
}

export function getLeadPhoneVerificationCode(phone: string) {
  return {
    type: types.GET_LEAD_PHONE_VERIFICATION_CODE,
    payload: phone,
  };
}

export function getLeadPhoneVerificationCodeSuccess(payload: IObjectKeys) {
  return {
    type: types.GET_LEAD_PHONE_VERIFICATION_CODE_SUCCESS,
    payload,
  };
}
export function getLeadPhoneVerificationCodeFail(payload: IObjectKeys) {
  return {
    type: types.GET_LEAD_PHONE_VERIFICATION_CODE_FAIL,
    payload,
  };
}
export function verifyLeadPhoneNumber(payload: number) {
  return {
    type: types.VERIFY_LEAD_PHONE_NUMBER,
    payload,
  };
}

export function verifyLeadPhoneNumberSuccess(payload: IObjectKeys) {
  return {
    type: types.VERIFY_LEAD_PHONE_NUMBER_SUCCESS,
    payload,
  };
}
export function verifyLeadPhoneNumberFail(payload: IObjectKeys) {
  return {
    type: types.VERIFY_LEAD_PHONE_NUMBER_FAIL,
    payload,
  };
}
export function verifyPhoneNumber(payload: string) {
  return {
    type: types.VERIFY_PHONE_NUMBER,
    payload,
  };
}

export function verifyPhoneNumberSuccess(payload: IObjectKeys) {
  return {
    type: types.VERIFY_PHONE_NUMBER_SUCCESS,
    payload,
  };
}
export function verifyPhoneNumberFail(payload: IObjectKeys) {
  return {
    type: types.VERIFY_PHONE_NUMBER_FAIL,
    payload,
  };
}

export const clearErrorStates = () => ({
  type: types.CLEAR_USER_STATES,
});

export const signOut = (payload: Object) => ({
  type: types.SIGN_OUT,
  payload,
});

export const signOutSuccess = () => ({
  type: types.SIGN_OUT_SUCCESS,
});

export const deleteUserImage = () => {
  return {
    type: types.DELETE_USER_IMAGE_SUCCESS,
  };
};

export const uploadUserImage = (data: any) => {
  return {
    type: types.UPLOAD_USER_IMAGE,
    payload: data,
  };
};

export const setAuthLoadingToTrue = () => ({
  type: types.SET_AUTH_LOADING_TO_TRUE,
});

export const setAuthLoadingToFalse = () => ({
  type: types.SET_AUTH_LOADING_TO_FALSE,
});

export const setUserPassword = () => ({
  type: types.SET_USER_PASSWORD,
});

export const setVerifyUserSuccess = () => ({
  type: types.SET_VERIFY_USER_PHONE_SUCCESS,
});

export const isLeadPageShared = () => ({
  type: types.SET_LEAD_PAGE_SHARED,
});

export const setUpdatedUserSettingsSuccess = () => ({
  type: types.SET_UPDATED_USER_SETTINGS_SUCCESS_TO_FALSE,
});

export const exportAgentData = () => ({
  type: types.EXPORT_USER_DATA,
});
export const exportAgentDataSuccess = () => ({
  type: types.EXPORT_USER_DATA_SUCCESS,
});
export const exportAgentDataFail = (payload: any) => ({
  type: types.EXPORT_USER_DATA_FAIL,
  payload,
});

export const setExportAgentDataSuccess = (payload: boolean) => ({
  type: types.SET_EXPORT_USER_DATA_SUCCESS,
  payload,
});

export const setVerificationPhoneSuccess = (payload: boolean) => ({
  type: types.SET_VERIFY_PHONE_SUCCESS,
  payload,
});

export const verifyByEmailLink = (payload: any) => ({
  type: types.VERIFY_BY_EMAIL_LINK,
  payload,
});

export const verifyByEmailLinkSuccess = (payload: any) => ({
  type: types.VERIFY_BY_EMAIL_LINK_SUCCESS,
  payload,
});

export const verifyByEmailLinkFail = (payload: any) => ({
  type: types.VERIFY_BY_EMAIL_LINK_FAIL,
  payload,
});

export const setLastLocation = (payload: string) => ({
  type: types.SET_LOCATION,
  payload,
});

export const getReferralAgentInfo = (payload: IObjectKeys) => ({
  type: types.GET_REFERRAL_AGENT,
  payload,
});

export const getReferralAgentInfoSuccess = (payload: IObjectKeys) => ({
  type: types.GET_REFERRAL_AGENT_SUCCESS,
  payload,
});

export const updateUserProfile = (payload: IObjectKeys) => ({
  type: types.UPDATE_USER_PROFILE,
  payload,
});

export const updateUserProfileSuccess = (payload: IObjectKeys) => ({
  type: types.UPDATE_USER_PROFILE_SUCCESS,
  payload,
});

export const updateUserTeamName = (payload: string) => ({
  type: types.UPDATE_USER_TEAM_NAME,
  payload,
});

export const updateUserProfileFail = (payload: any) => ({
  type: types.UPDATE_USER_PROFILE_FAIL,
  payload,
});

export const clearUpdateUserProfile = () => {
  return { type: types.CLEAR_UPDATE_USER_PROFILE };
};

export const updateUserAfterSwitch = (payload: IObjectKeys) => ({
  type: types.UPDATE_USER_AFTER_SWITCH,
  payload,
});

export const updateUserAfterSwitchSuccess = (payload: IObjectKeys) => ({
  type: types.UPDATE_USER_AFTER_SWITCH_SUCCESS,
  payload,
});

export const updateUserAfterSwitchFail = (payload: any) => ({
  type: types.UPDATE_USER_AFTER_SWITCH_FAIL,
  payload,
});

export const clearUpdateUserAfterSwitch = () => {
  return { type: types.CLEAR_UPDATE_USER_AFTER_SWITCH };
};
