import { RootStateOrAny } from 'react-redux';

export const userSelector = (state: RootStateOrAny) => state.userReducer.user;
export const authErrorSelector = (state: RootStateOrAny) => state.userReducer.authError;
export const authLoadingSelector = (state: RootStateOrAny) => state.userReducer.authLoading;
export const passwordResetLoadingSelector = (state: RootStateOrAny) => state.userReducer.passwordResetLoading;
export const updateUserSettingsSuccessSelector = (state: RootStateOrAny) => state.userReducer.updateUserSettingsSuccess;
export const updateUserSettingsLoadingSelector = (state: RootStateOrAny) => state.userReducer.updateUserSettingsLoading;
export const resetPasswordSuccessSelector = (state: RootStateOrAny) => state.userReducer.resetPasswordSuccess;
export const confirmResetPasswordSuccessSelector = (state: RootStateOrAny) => state.userReducer.confirmResetPasswordSuccess;
export const verifyCodeSuccessSelector = (state: RootStateOrAny) => state.userReducer.verifyPhoneSuccess;
export const verifyCodeLoadingSelector = (state: RootStateOrAny) => state.userReducer.verifyCodeLoading;
export const getVerificationCodeSuccessSelector = (state: RootStateOrAny) => state.userReducer.getVerificationCodeSuccess;
export const getVerificationCodeLoadingSelector = (state: RootStateOrAny) => state.userReducer.getVerificationCodeLoading;
export const verifyLeadPhoneSuccessSelector = (state: RootStateOrAny) => state.userReducer.verifyLeadPhoneSuccess;
export const agentSignUpLoadingSelector = (state:RootStateOrAny) => state.userReducer.agentSignUpLoading;
export const agentSignUpSuccessSelector = (state:RootStateOrAny) => state.userReducer.agentSignUpSuccess;
export const signUpPhoneVerifiedSuccessSelector = (state:RootStateOrAny) => state.userReducer.signUpPhoneVerified;
export const newUserIsComingSelector = (state:RootStateOrAny) => state.userReducer.newUserIsComing;
export const isLeadPageSharedSelector = (state:RootStateOrAny) => state.userReducer.isLeadPageShared;
export const notConfirmedAgentIdSelector = (state:RootStateOrAny) => state.userReducer.notConfirmedAgentId;
export const exportAgentDataLoadingSelector = (state:RootStateOrAny) => state.userReducer.agentDataExportLoading;
export const exportAgentDataSuccessSelector = (state:RootStateOrAny) => state.userReducer.agentDataExportSuccess;
export const phoneVerificationCodeErrorSelector = (state:RootStateOrAny) => state.userReducer.phoneVerificationError;
export const verifiedFromEmailSelector = (state:RootStateOrAny) => state.userReducer.verifyByEmailLinkSuccess
export const lastLocationSelector = (state: RootStateOrAny) => state.userReducer.lastLocation
export const getLeadVerificationCodeSuccessSelector = (state: RootStateOrAny) =>
    state.userReducer.getLeadVerificationCodeSuccess;
export const getLeadVerificationCodeLoadingSelector = (state: RootStateOrAny) =>
    state.userReducer.getLeadVerificationCodeLoading;
export const verifyLeadCodeLoadingSelector = (state: RootStateOrAny) =>
  state.userReducer.verifyLeadCodeLoading;
export const referralAgentNameSelector = (state: RootStateOrAny) =>
  state.userReducer.referralAgentName;
export const userUpdateSuccessSelector = (state: RootStateOrAny) =>
  state.userReducer.userUpdateSuccess;
export const isUserUpdatedSelector = (state: RootStateOrAny) =>
  state.userReducer.updatedUser;
export const updatedUserLoadingSelector = (state: RootStateOrAny) => state.userReducer.updatedUserLoading;
export const sendVerificationLinkSelector = (state: RootStateOrAny) => state.userReducer.sendVerificationLink;
