import { IObjectKeys } from "helpers/globalTypes";
import * as types from "./types";
import {
  IFilterOptionsOptionalKeys,
  ListingNote,
} from "../../components/Listings/helpers/mockListings";
import { IPaginationValues } from "../../components/Listings/components/listings-wrapper";

interface ILeadsReducer {
  leadsList: IObjectKeys[] | null;
  leadData: IObjectKeys;
  total: number;
  allLeadsTotal: number;
  leadLoading: boolean;
  leadUpdateTagsLoading: boolean;
  leadError: string | IObjectKeys | null;
  paginationValues: IPaginationValues;
  filterOptions: IFilterOptionsOptionalKeys;
  lastLeadsQuery: string;
  leadNotes: [];
  leadNotesLoading: boolean;
  leadNotesError: any;
  leadNoteAddSuccess: boolean;
  leadEmailVerifyError: any;
  leadEmailVerifySuccess: boolean;
  leadEmailVerifyCode:string;
  leadConfirmPasswordError: any;
  leadConfirmPasswordSuccess: boolean;
  leadAccessToken: string;
  leadAccountLink: IObjectKeys | null;
  sendLeadInfoLoading: boolean,
  sendLeadInfoError: any,
  leadUpdateTagsSuccess: IObjectKeys | null,
  leadUpdateTagsError: IObjectKeys | null,
  leadRemoveTagSuccess: IObjectKeys | null,
  leadRemoveTagError: IObjectKeys | null;
}
const initialState: ILeadsReducer = {
  leadsList: null,
  leadData: {},
  total: 0,
  allLeadsTotal: 0,
  leadLoading: false,
  leadError: null,
  paginationValues: { current: 1, pageSize: 24 },
  filterOptions: {},
  lastLeadsQuery: "",
  leadNotes: [],
  leadNotesLoading: false,
  leadNotesError: null,
  leadNoteAddSuccess: false,
  leadEmailVerifyError: null,
  leadEmailVerifySuccess: false,
  leadEmailVerifyCode: "",
  leadConfirmPasswordError: null,
  leadConfirmPasswordSuccess: false,
  leadAccessToken: "",
  leadAccountLink: null,
  sendLeadInfoLoading: false,
  sendLeadInfoError: null,
  leadUpdateTagsSuccess: null,
  leadUpdateTagsError: null,
  leadRemoveTagSuccess: null,
  leadRemoveTagError: null,
  leadUpdateTagsLoading: false,
};

export default function leadsReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.SET_LEADS_LOADING_TO_TRUE:
      return {
        ...state,
        leadLoading: true,
      };
    case types.GET_LEAD_DATA:
    case types.GET_LEADS:
      return {
        ...state,
        leadLoading: true,
        leadError: null,
        leadUpdateTagsSuccess: null,
        leadUpdateTagsError: null,
        leadRemoveTagSuccess: null,
        leadRemoveTagError: null,
      };
    case types.GET_TAGS:
      return {
        ...state,
        leadError: null,
        leadUpdateTagsSuccess: null,
        leadUpdateTagsError: null,
      };
    case types.GET_TAGS_SUCCESS:
      return {
        ...state,
        leadData: action.payload,
      };
    case types.GET_LEAD_DATA_SUCCESS:
      return {
        ...state,
        leadLoading: false,
        leadData: action.payload,
      };
    case types.SET_LEAD_DATA_CONTACT:
      return {
        ...state,
        leadLoading: false,
        leadData: action.payload,
      };
    case types.GET_LEADS_SUCCESS:
      return {
        ...state,
        leadLoading: false,
        leadsList: [...action.payload.result.hits],
        total: action.payload.result.nbHits,
        allLeadsTotal: action.payload.agencyReqTotal,
      };
    case types.GET_LEADS_FAIL:
    case types.GET_LEAD_DATA_FAIL:
      return {
        ...state,
        leadLoading: false,
        leadError: action.payload,
      };
    case types.SET_LEADS_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: {
          ...action.payload,
        },
      };
    case types.SEND_LEAD_INFO_BY_SMS:
      return {
        ...state,
        sendLeadInfoLoading: true,
        sendLeadInfoError: null,
      };
    case types.SEND_LEAD_INFO_BY_SMS_SUCCESS:
      return {
        ...state,
        sendLeadInfoLoading: false,
      };
    case types.SEND_LEAD_INFO_BY_SMS_FAIL:
      return {
        ...state,
        sendLeadInfoLoading: false,
        sendLeadInfoError: action.payload,
      };
    case types.SET_LEADS_PAGINATION_VALUES:
      return {
        ...state,
        paginationValues: {
          ...action.payload,
        },
      };
    case types.SET_LAST_LEADS_QUERY:
      return {
        ...state,
        lastLeadsQuery: action.payload,
      };
    case types.CLEAR_LEAD_DATA:
      return {
        ...state,
        leadData: {},
      };

    case types.ADD_LEAD_NOTE:
      return {
        ...state,
        leadNotesLoading: true,
        leadNoteAddSuccess: false,
      };

    case types.ADD_LEAD_NOTE_SUCCESS:
      return {
        ...state,
        leadNotes: [action.payload, ...state.leadNotes],
        leadNotesLoading: false,
        leadNoteAddSuccess: true,
      };

    case types.GET_LEAD_NOTES:
      return {
        ...state,
        leadNotesLoading: true,
      };
    case types.GET_LEAD_NOTES_SUCCESS:
      return {
        ...state,
        leadNotes: action.payload,
        leadNotesLoading: false,
      };
    case types.DELETE_LEAD_NOTE:
      return {
        ...state,
        leadNotesLoading: true,
      };

    case types.DELETE_LEAD_NOTE_SUCCESS:
      const notes = state.leadNotes.filter(
        (note: IObjectKeys) => note._id !== action.payload
      );
      return {
        ...state,
        leadNotes: [...notes],
        leadNotesLoading: false,
      };
    case types.UPDATE_LEAD_NOTE:
      return {
        ...state,
        leadNoteAddSuccess: false,
        leadNotesLoading: true,
      };
    case types.SET_LEAD_NOTE_ADD_SUCCESS:
      return {
        ...state,
        leadNoteAddSuccess: action.payload,
      };
    case types.UPDATE_LEAD_NOTE_SUCCESS:
      let updatedNotes = state.leadNotes.map((item: ListingNote) => {
        if (item._id === action.payload._id) {
          return { ...item, ...action.payload };
        }
        return item;
      });

      return {
        ...state,
        leadNotes: updatedNotes,
        leadNoteAddSuccess: true,
        leadNotesLoading: false,
      };

    case types.DELETE_LEAD_NOTE_FAIL:
      return {
        ...state,
        leadNotesError: action.payload,
      };
    case types.VERIFY_LEAD_EMAIL:
      return {
        ...state,
        leadLoading: true,
        leadEmailVerifyError: null,
        leadEmailVerifySuccess: false,
        leadEmailVerifyCode:""
      };
    case types.VERIFY_LEAD_EMAIL_ERROR:
      return {
        ...state,
        leadEmailVerifyError: action.payload.error,
        leadLoading: false,
        leadEmailVerifyCode: action.payload.code,
        leadEmailVerifySuccess:true
      };
    case types.VERIFY_LEAD_EMAIL_SUCCESS:
      return {
        ...state,
        leadEmailVerifyError: null,
        leadEmailVerifyCode: action.payload.code,
        leadLoading: false,
        leadEmailVerifySuccess: false,
      };
    case types.CONFIRM_LEAD_PASSWORD:
      return {
        ...state,
        leadConfirmPasswordError: null,
        leadConfirmPasswordSuccess: false,
        leadLoading: true,
      };
    case types.CONFIRM_LEAD_PASSWORD_ERROR:
      return {
        ...state,
        leadConfirmPasswordError: action.payload,
        leadConfirmPasswordSuccess: false,
        leadLoading: false,
      };
    case types.CONFIRM_LEAD_PASSWORD_SUCCESS:
      return {
        ...state,
        leadConfirmPasswordError: null,
        leadConfirmPasswordSuccess: true,
        leadLoading: false,
      };
    case types.SET_LEAD_ACCESS_TOKEN:
      return {
        ...state,
        leadAccessToken: action.payload,
      };
    case types.GET_LEAD_ACCOUNT_LINK:
      return {
        ...state,
        leadAccountLink: null,
        leadLoading: true,
        leadError: null,
      };
    case types.GET_LEAD_ACCOUNT_LINK_SUCCESS:
      return {
        ...state,
        leadAccountLink: action.payload,
        leadLoading: false,
        leadError: null,
      };
    case types.CLEAR_LEAD_LINK:
      return {
        ...state,
        leadAccountLink: null,
      };

    case types.LEAD_UPDATE_TAGS:
      return {
        ...state,
        leadUpdateTagsError: null,
        leadUpdateTagsSuccess: null,
        leadUpdateTagsLoading: true,
      };
    case types.LEAD_UPDATE_TAGS_ERROR:
      return {
        ...state,
        leadUpdateTagsError: action.payload,
        leadUpdateTagsLoading: false,
      };
    case types.LEAD_UPDATE_TAGS_SUCCESS:
      return {
        ...state,
        leadUpdateTagsSuccess: action.payload,
        leadUpdateTagsLoading: false,
        leadData:{...state.leadData, tags: action.payload.tags, step: action.payload.step}
      };
    case types.LEAD_REMOVE_TAG:
      return {
        ...state,
        leadUpdateTagsError: null,
        leadUpdateTagsSuccess: null,
        leadLoading: true,
      };
    case types.LEAD_REMOVE_TAG_ERROR:
      return {
        ...state,
        leadRemoveTagError: action.payload,
        leadLoading: false
      };
    case types.LEAD_REMOVE_TAG_SUCCESS:
      return {
        ...state,
        leadRemoveTagSuccess: action.payload,
        leadLoading: false
      };
    default:
      return state;
  }
}
