import React from "react";
import { getListingInput } from "../../../../../../../../helpers/globalFunctions";
import {
  REQUIRED,
  REQUIRED_BUDGET_POSITIVE_VALUE,
  REQUIRED_POSITIVE_WITH_MESSAGE,
} from "../../../../../../../../helpers/validations";
import { useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";

const WorkDetails = () => {
  const requirementEnums = useSelector(requirementEnumsSelector);

  const jobLocation = requirementEnums?.find(
    (item: EnumInterface) => item?.key === "job_location"
  );

  const jobPosition = requirementEnums?.find(
    (item: EnumInterface) => item?.key === "job_position"
  );

  return (
    <div className={"work-details-section mt-27"}>
      {getListingInput(
        jobLocation?.key,
        jobLocation?.label,
        !jobLocation?.allow_null ? [REQUIRED] : [{ required: false }],
        "Ex. Goliath National Bank",
          "",
          "req"
      )}
      {getListingInput(
        jobPosition?.key,
        jobPosition?.label,
        !jobPosition?.allow_null
          ? [REQUIRED_POSITIVE_WITH_MESSAGE]
          : [{ required: false }],
        "Ex. Manager",
          "",
          "req"
      )}
      {getListingInput(
        "yearly_income",
        "What is your yearly income",
        [REQUIRED_BUDGET_POSITIVE_VALUE],
        "Ex. $100000",
          "",
          "req",
          true
      )}
    </div>
  );
};

export default WorkDetails;
