import { IObjectKeys } from "./../../helpers/globalTypes";
import * as types from "./types";
import { AuthError } from "../../types/user";

export const getDashboardListings = () => ({
  type: types.GET_DASHBOARD_LEADS,
});
export const getDashboardListingsSuccess = (payload: IObjectKeys) => ({
  type: types.GET_DASHBOARD_LEADS,
  payload,
});
export const getDashboardListingsError = (payload: IObjectKeys) => ({
  type: types.GET_DASHBOARD_LEADS,
  payload,
});


export const getDashboardLandlords = () => ({
  type: types.GET_DASHBOARD_LANDLORDS,
});
export const getDashboardLandlordsSuccess = (payload: { Areas: IObjectKeys[] }) => ({
  type: types.GET_DASHBOARD_LANDLORDS_SUCCESS,
  payload,
});
export const getDashboardLandlordsError = (payload: { Areas: IObjectKeys[] }) => ({
  type: types.GET_DASHBOARD_LANDLORDS_ERROR,
  payload,
});

export const getDashboardFollowUps = () => ({
  type: types.GET_DASHBOARD_FOLLOW_UPS,
});
export const getDashboardFollowUpsSuccess = (payload: AuthError) => ({
  type: types.GET_DASHBOARD_FOLLOW_UPS_SUCCESS,
  payload,
});
export const getDashboardFollowUpsError = (payload: AuthError) => ({
  type: types.GET_DASHBOARD_FOLLOW_UPS_ERROR,
  payload,
});

export const getDashboardLeads = () => ({
  type: types.GET_DASHBOARD_LEADS,
});
export const getDashboardLeadsSuccess = (payload: IObjectKeys) => ({
  type: types.GET_DASHBOARD_LEADS_SUCCESS,
  payload
});
export const getDashboardLeadsError = (payload: IObjectKeys) => ({
  type: types.GET_DASHBOARD_LEADS_ERROR,
  payload
});

export const getDashboardData = () => ({
  type: types.GET_DASHBOARD_DATA,
});
export const getDashboardDataSuccess = (payload: IObjectKeys) => ({
  type: types.GET_DASHBOARD_DATA_SUCCESS,
  payload
});
export const getDashboardDataError = (payload: IObjectKeys) => ({
  type: types.GET_DASHBOARD_DATA_ERROR,
  payload
});

export const editDashboardFollowUp = (payload: IObjectKeys, _id: string) => ({
  type: types.EDIT_DASHBOARD_FOLLOW_UP,
  payload: { payload, _id },
});

export const editDashboardFollowUpSuccess = (payload: IObjectKeys) => ({
  type: types.EDIT_DASHBOARD_FOLLOW_UP_SUCCESS,
  payload,
});

export const editDashboardFollowUpSuccessClear = () => ({
  type: types.EDIT_DASHBOARD_FOLLOW_UP_SUCCESS_CLEAR
});

export const editDashboardFollowUpFail = (payload: AuthError) => ({
  type: types.EDIT_DASHBOARD_FOLLOW_UP_FAIL,
  payload,
});

export const snoozeDashboardFollowUp = (payload: IObjectKeys, _id: string) => ({
  type: types.SNOOZE_DASHBOARD_FOLLOW_UP,
  payload: { payload, _id },
});

export const snoozeDashboardFollowUpSuccess = (payload: IObjectKeys) => ({
  type: types.SNOOZE_DASHBOARD_FOLLOW_UP_SUCCESS,
  payload,
});

export const snoozeDashboardFollowUpFail = (payload: AuthError) => ({
  type: types.SNOOZE_DASHBOARD_FOLLOW_UP_FAIL,
  payload,
});

export const deleteDashboardFollowUp = (payload: IObjectKeys) => ({
  type: types.DELETE_DASHBOARD_FOLLOW_UP,
  payload,
});

export const deleteDashboardFollowUpSuccess = (payload: IObjectKeys) => ({
  type: types.DELETE_DASHBOARD_FOLLOW_UP_SUCCESS,
  payload,
});

export const deleteDashboardFollowUpFail = (payload: IObjectKeys) => ({
  type: types.DELETE_DASHBOARD_FOLLOW_UP_FAIL,
  payload,
});

export const setDashboardFUSnoozeSuccessToFalse = () => ({
  type:types.SET_SNOOZE_SUCCESS_TO_FALSE
})

export const addDashboardFollowUp = (payload: IObjectKeys) => ({
  type: types.ADD_DASHBOARD_FOLLOW_UP,
  payload,
});
export const addDashboardFollowUpSuccess = (payload: IObjectKeys) => ({
  type: types.ADD_DASHBOARD_FOLLOW_UP_SUCCESS,
  payload,
});
export const addDashboardFollowUpFail = (payload: AuthError) => ({
  type: types.ADD_DASHBOARD_FOLLOW_UP_FAIL,
  payload,
});
export const setDashboardFuAddedSuccessToFalse = () =>({
  type: types.SET_DASHBOARD_FOLLOW_UP_SUCCESS,
  payload:false
})
