import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Col, Row } from "antd";
import { IObjectKeys } from "helpers/globalTypes";
import LeadContactInfo from "./LeadContactInfo";
import BaseButton from "components/Shared/BaseButton";
import RequirementApi from "api/Requirement";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { userSelector } from "store/user/selectors";
import Section from "components/Shared/Section/Section";

interface ILeadContactSectionProps {
  leadData: IObjectKeys;
  setLandlordActionModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeadContactSection = ({ leadData, setLandlordActionModalVisible }: ILeadContactSectionProps) => {
  const { renter, agent } = leadData;
  const entity = agent || renter;
  const requirement = leadData.requirement;
  const userData = useSelector(userSelector);
  const { lead_id }: any = useParams();
  const [isSent, setIsSent] = useState(false);
  const buttonContent = isSent ? "Request sent!" : "Request Contact Details";

  useEffect(() => {
    setIsSent(requirement?.requestedAgent_ids?.includes(userData.agent_id));
  }, [requirement?.requestedAgent_ids, userData]);

  const sendPermissionRequest = async () => {
    await RequirementApi.sendPermissionRequest({ requirements_id: lead_id });
    setIsSent(true);
  };
  return (
    <Section
      shadow
      className="flex flex-wrap justify-between items-center text-base lead-contact-section lead-contact-section-inner-wrapper"
    >
      <div>
        <span className="font-semibold">{entity ? "Contact Details: " : "Anonymous"}</span>
        {entity && <span data-test-id={"lead-agent-name"}>{renter?.name}</span>}
      </div>
      <div className={"flex flex-wrap items-center justify-end"}>
        {entity ? (
          <LeadContactInfo
            email={entity?.email ?? ""}
            phone={entity?.phone ?? ""}
            setLandlordActionModalVisible={setLandlordActionModalVisible}
          />
        ) : (
          <BaseButton
            classnames="ant-btn rb-secondary-btn rb-secondary-btn-without-shadow permission-request-btn"
            onClick={sendPermissionRequest}
            data-test-id="sendPermissionRequest"
            disabled={isSent}
          >
            {buttonContent}
          </BaseButton>
        )}
      </div>
    </Section>
  );
};

export default LeadContactSection;
