import React, { useRef } from "react";
import { Carousel as AntCarousel } from "antd";
import { IImage } from "../../Listings/helpers/mockListings";
import { SLIDER_SETTINGS } from "../../../helpers/globalFunctions";

const ImageWrapper = ({ url, alt, loading }: { url: string; alt: string; loading: 'eager' | 'lazy' | undefined; }) => {
  return <div className="overflow-hidden h-full w-full rounded-lg bg-gray-50">
    <img
      src={url}
      alt={alt}
      className="object-cover object-center group-hover:opacity-75 w-full h-full"
      loading={loading}
    />
  </div>;
};

export default function Carousel({ listing }: any) {
  const sliderRef = useRef(null);

  return (
    <AntCarousel ref={sliderRef} {...SLIDER_SETTINGS}>
      {listing?.images
        ?.sort((a: IImage, b: IImage) => {
          return (
            new Date(a.created_at).getTime() -
            new Date(b.created_at).getTime()
          );
        })
        .map((file: any, i: number) => {
          return <ImageWrapper url={file.url} alt={file.name} loading={i === 0 ? 'eager' : 'lazy'} />;
        })}
    </AntCarousel>
  );
}
