import React from "react";

interface IPropsDateSelect {
  field: { inputFormat: any; prefix?: string; suffix?: string; label?: string; key: string };
  value: any;
  label?: string;
  onChange?: any;
}

const DateSelect = ({ onChange, field, value, label }: IPropsDateSelect) => {
  return (
    <>
      <div>
        <label htmlFor="price" className="block text-base font-medium  text-gray-700">
          {label || field.label}
        </label>
        <div className="relative mt-2">
          {field.prefix && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="sm:text-base">{field.prefix}</span>
            </div>
          )}
          <input
            type="date"
            className={`block w-36 rounded-md border-0 py-1.5 ${
              field.prefix ? "pl-6" : "pl-3"
            } text-gray-700 shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6`}
            placeholder="0.00"
            aria-describedby={field.label || field.key}
            value={!!field.inputFormat ? field.inputFormat(value) : value}
            onChange={(e) => onChange({ field, value: e.target.value })}
          />
          {field.suffix && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="sm:text-base" id="price-currency">
                {field.suffix}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DateSelect;
