import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Form, Row, Typography, Radio, Spin } from "antd";
import { StepFormComponent } from "../types";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  getBrowser,
  getQueryParams,
  makeMultiEnumOption,
  openNotification,
  parseMultiEnumOptions,
  sanitizePhoneNumber,
  scrollToTheElement,
} from "../../../../helpers/globalFunctions";
import {
  clearListingUpdateState,
  fillListingData,
  setCurrentSteps,
  setIsCurrentFormChange,
  updateListing,
} from "../../../../store/listings/action";
import {
  currentStepSelector,
  isCurrentFormChangedSelector,
  listingDataSelector,
  listingLoadingSelector,
  listingUpdateSuccessSelector,
  stepClickedSelector,
} from "../../../../store/listings/selectors";
import AvailableForm from "./AvailableForm";
import OffMarketForm from "./OffMarketForm";
import { IObjectKeys } from "../../../../helpers/globalTypes";
import moment from "moment";
import StepsActionButtons from "../StepsActionButtons";

const { Title } = Typography;

const Availability = ({ form }: StepFormComponent) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { lId } = getQueryParams(search);
  const listingData = useSelector(listingDataSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const stepClicked = useSelector(stepClickedSelector);
  const [showDepositAmount, setShowDepositAmount] = useState(false);
  const [showOtherRenewal, setShowOtherRenewal] = useState(listingData.renewal_date === -1);
  const [showOtherBFInput, setShowOtherBFInput] = useState(false);
  const [available, setAvailable] = useState(true);
  const current = useSelector(currentStepSelector);
  const contentRef = useRef(null);
  const isCurrentFormChanged = useSelector(isCurrentFormChangedSelector);
  const leaseStartDefaultDate = moment(listingData.availability_date).isBefore(moment())
    ? moment()
    : moment(listingData.availability_date);
  const [showPetDepositAmount, setShowPetDepositAmount] = useState(false);

  const next = useCallback(() => {
    dispatch(setCurrentSteps(current + 1));
  }, [current, dispatch]);

  useEffect(() => {
    dispatch(setIsCurrentFormChange(false));
  }, [dispatch]);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      //@ts-ignore
      contentRef.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    setAvailable(listingData.status === "Available" ? true : typeof listingData.status === "undefined");
    const depositInFees = Array.isArray(listingData.move_in_fees)
      ? listingData.move_in_fees.indexOf("security_deposit") > -1
      : listingData?.move_in_fees?.security_deposit;
    if (depositInFees) {
      setShowDepositAmount(depositInFees);
    }
    setShowOtherBFInput(listingData.broker_fees === "other");

    const petDepositFees = Array.isArray(listingData.move_in_fees)
      ? listingData.move_in_fees.indexOf("pet_deposit") > -1
      : listingData?.move_in_fees?.pet_deposit;
    if (petDepositFees) {
      setShowPetDepositAmount(petDepositFees);
    }

    if (lId) {
      const multiselectFields = ["move_in_fees"];
      const fieldsToSet: IObjectKeys = {};
      const fields = [
        "status",
        "current_rent",
        "asked_rent",
        "broker_fees",
        "security_deposit_amount",
        "availability_date",
        "lease_ending_date",
        "renewal_date",
        "other_renewal_date",
        "other_broker_fee",
        "tenants",
        "pet_deposit_amount",
        ...multiselectFields,
      ];
      if (listingData.status === "Off-market") fields.push("lease_start_date");
      fields.map((field: string) => {
        if (field === "tenants") {
          fieldsToSet[field] =
            listingData?.rentersOfListing?.map((tenant: IObjectKeys) => {
              return {
                email: tenant?.email,
                phone: tenant?.phone,
                name: tenant?.name,
              };
            }) || [];
        }
        if (listingData[field]) {
          if (multiselectFields.indexOf(field) > -1) {
            fieldsToSet[field] = parseMultiEnumOptions(listingData[field]);
          } else {
            if (
              [
                "availability_date",
                "lease_ending_date",
                "lease_start_date",
                // "other_renewal_date"
              ].indexOf(field) > -1
            ) {
              fieldsToSet[field] = moment(listingData[field]);
            } else {
              fieldsToSet[field] = listingData[field];
            }
          }
        }
      });
      if (listingData.other_renewal_date) {
        fieldsToSet.renewal_date = -1;
        fieldsToSet.other_renewal_date = moment(listingData.other_renewal_date);
      }
      form.setFieldsValue(fieldsToSet);
    }
  }, [form, lId, listingData]);

  const onSubmit = (data: IObjectKeys) => {
    const formData = { ...data };
    if (data.broker_fees !== "other") {
      formData.other_broker_fee = "";
    }
    formData.lease_start_date =
      formData.lease_start_date &&
      new Date(formData.lease_start_date.startOf("day").format(getBrowser())).toISOString();
    formData.availability_date =
      formData.availability_date && new Date(formData.availability_date.format(getBrowser())).toISOString();
    formData.move_in_fees = makeMultiEnumOption(formData.move_in_fees);
    formData.lease_ending_date = formData.lease_ending_date && formData.lease_ending_date.format("MM-DD-YYYY HH:ss");
    formData.status = capitalizeFirstLetter(formData.status);
    if (formData.renewal_date !== -1) formData.other_renewal_date = null;
    form.getFieldsValue(["move_in_fees"], (meta: { touched: boolean; validating: boolean }): any => {
      // eslint-disable-next-line no-restricted-globals
      if (Object.keys(formData?.move_in_fees)?.length === 0 && !meta) {
        delete formData.move_in_fees;
      }
    });
    // eslint-disable-next-line no-restricted-globals
    if (formData.tenants?.length) {
      for (let i = 0; i < formData.tenants.length; i++) {
        let tenant = data.tenants[i];
        if (!tenant.phone && !tenant.email) {
          return openNotification("error", "At least you should mention tenant email or phone!");
        }
        if (tenant.phone) {
          tenant.phone = sanitizePhoneNumber(tenant.phone);
        }
      }
    }
    const dataToFill: IObjectKeys = {
      ...formData,
      listingShowingOfList: {
        ...listingData.listingShowingOfList,
        ...data,
      },
    };
    // eslint-disable-next-line no-restricted-globals
    if (data?.tenants?.length) {
      dataToFill.tenants = data.tenants;
      dataToFill.rentersOfListing = formData.tenants;
    }
    dispatch(fillListingData(dataToFill));
    if (lId) {
      return dispatch(updateListing({ lId, ...formData, step: "availability_step" }));
    }
  };

  useEffect(() => {
    if (!stepClicked && updateListingSuccess) {
      next && next();
    }
    return () => {
      dispatch(clearListingUpdateState());
    };
  }, [dispatch, next, stepClicked, updateListingSuccess]);

  useEffect(() => {
    if (form.getFieldValue("status") === "Off-market") {
      if (
        listingData.renewal_date === -1 ||
        ![30, 60, 90].includes(listingData.renewal_date) ||
        listingData.other_renewal_date
      ) {
        form.setFieldsValue({
          renewal_date: -1,
        });
        setShowOtherRenewal(true);
      }
    } else {
      form.setFieldsValue({
        renewal_date: 0,
        other_renewal_date: null,
        lease_ending_date: null,
      });
    }
  }, [form, listingData.other_renewal_date, listingData.renewal_date]);

  useEffect(() => {
    if (listingData.status === "Off-market" && !listingData?.subscription_renewal_date) {
      form.setFieldsValue({
        status: "Off-market",
      });
      setAvailable(false);
    }
  }, [form, listingData]);

  return (
    <div ref={contentRef}>
      <Spin tip="Submitting..." spinning={listingLoading}>
        <Title level={4}>Availability:</Title>
        <Form
          className="availability-form"
          form={form}
          onFinish={onSubmit}
          initialValues={{
            status: "Available",
            other_renewal_date: listingData.other_renewal_date ? moment(listingData.other_renewal_date) : "",
            lease_start_date: listingData.availability_date ? leaseStartDefaultDate : moment(),
            availability_date: listingData.availability_date ? leaseStartDefaultDate : moment(),
          }}
          scrollToFirstError={true}
          onFieldsChange={(changedFieldInfo: any) => {
            if (!isCurrentFormChanged) dispatch(setIsCurrentFormChange(true));
            // eslint-disable-next-line no-restricted-globals
            if (Array.isArray(changedFieldInfo[0]?.name)) {
              // eslint-disable-next-line no-restricted-globals
              if (changedFieldInfo[0]?.name?.indexOf("status") > -1) {
                setAvailable(changedFieldInfo[0]?.value === "Available");
              }
              // eslint-disable-next-line no-restricted-globals
              if (changedFieldInfo[0]?.name?.indexOf("move_in_fees") > -1) {
                setShowDepositAmount(changedFieldInfo[0]?.value?.indexOf("security_deposit") > -1);
                if (changedFieldInfo[0]?.value?.indexOf("security_deposit") > -1) {
                  const asked_rent = form.getFieldValue("asked_rent");
                  form.setFieldsValue({ security_deposit_amount: asked_rent });
                }
                setShowPetDepositAmount(changedFieldInfo[0]?.value?.indexOf("pet_deposit") > -1);
              }
              // eslint-disable-next-line no-restricted-globals
              if (changedFieldInfo[0]?.name?.indexOf("renewal_date") > -1) {
                setShowOtherRenewal(changedFieldInfo[0]?.value === -1);
              }
              // eslint-disable-next-line no-restricted-globals
              if (changedFieldInfo[0]?.name?.indexOf("broker_fees") > -1) {
                setShowOtherBFInput(changedFieldInfo[0]?.value === "other");
              }
            }
            // eslint-disable-next-line no-restricted-globals
            if (changedFieldInfo[0]?.name[0] === "move_in_fees") {
              const element =
                // eslint-disable-next-line no-restricted-globals
                document.querySelector(`[data-scroll-id="${changedFieldInfo[0]?.name[0]}"]`);
              //@ts-ignore
              element && scrollToTheElement(element);
            }
          }}
        >
          <Form.Item name={"status"}>
            <Radio.Group value={"Available"}>
              {/*<Row>*/}
              {/*  <Col>*/}
              <Radio value={"Available"}>Active</Radio>
              {/*</Col>*/}
              {/*<Col>*/}
              <Radio value={"Off-market"}>Off-Market</Radio>
              {/*</Col>*/}
              {/*</Row>*/}
            </Radio.Group>
          </Form.Item>
          {available ? (
            <AvailableForm
              showDepositAmount={showDepositAmount}
              showOtherBFInput={showOtherBFInput}
              showPetDepositAmount={showPetDepositAmount}
            />
          ) : (
            <OffMarketForm showOtherRenewal={showOtherRenewal} form={form} />
          )}
        </Form>
      </Spin>
      <StepsActionButtons form={form} />
    </div>
  );
};
export default Availability;
