import Api from "./api";
import { IObjectKeys } from "../helpers/globalTypes";

export default class DashboardApi {
    static getListings(query: IObjectKeys) {
        const api = new Api("dashboard");
        return api.get("listings", { params: query });
    }
    static getFollowUps(query: IObjectKeys) {
        const api = new Api("dashboard");
        return api.get("follow-ups", { params: query });
    }
    static getLeads(query: IObjectKeys) {
        const api = new Api("dashboard");
        return api.get("leads", { params: query });
    }
    static getLandlords(query: IObjectKeys) {
        const api = new Api("dashboard");
        return api.get("landlords", { params: query });
    }

    static getDashboardData() {
        const api = new Api("dashboard");
        const promises = [
            api.get("listings", { params: null }),
            api.get("landlords", { params: null }),
            api.get("leads", { params: null }),
            api.get("follow-ups", { params: null })
        ];
        return Promise.all(promises);
    }
}

