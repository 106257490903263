import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_ENUMS, GET_REQUIREMENT_ENUMS
} from "./types";
import EnumsApi from 'api/Enums';
import { getEnumsFail, getEnumsSuccess, getRequirementEnumsFail, getRequirementEnumsSuccess } from "./action";

function* getEnumsSaga() {
  try {
    const response = yield call(
      EnumsApi.getEnums
    );
    yield put(getEnumsSuccess(response.enums));
  } catch (e) {
    yield put(getEnumsFail(e));
  }
}

function* getRequirementEnumsSaga() {
  try {
    const response = yield call(
      EnumsApi.getRequirementEnums
    );
    yield put(getRequirementEnumsSuccess(response.enums));
  } catch (e) {
    yield put(getRequirementEnumsFail(e));
  }
}

function* watchGetEnums() {
  yield takeLatest(GET_ENUMS, getEnumsSaga);
}

function* watchGetRequirementEnums() {
  yield takeLatest(GET_REQUIREMENT_ENUMS, getRequirementEnumsSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetEnums)]);
  yield all([fork(watchGetRequirementEnums)]);
}
