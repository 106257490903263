import { formatCurrency } from "helpers/currencyHelper";

import AreaAutocomplete from "../../RenterPortal/Components/Form/AreaAutocomplete";
import TextInput from "../../RenterPortal/Components/Form/TextInput";
import TextOutput from "../../RenterPortal/Components/Form/TextOutput";
import TextAreaInput from "../../RenterPortal/Components/Form/TextAreaInput";
import PetsInput from "../../RenterPortal/Components/Form/PetInput";
import formatDate from "helpers/dateHelper";
import DateSelect from "../../RenterPortal/Components/Form/DateSelect";
import SelectInput from "../../RenterPortal/Components/Form/SelectInput";
import CheckboxGroupOutput from "../../RenterPortal/Components/Form/CheckboxGroupOutput";
import CheckBoxGroupInput from "../../RenterPortal/Components/Form/CheckBoxGroupInput";
import SelectOutput from "components/RenterPortal/Components/Form/SelectOutput";
import PetsOutput from "components/RenterPortal/Components/Form/PetsOutput";
import AreaOutput from "components/RenterPortal/Components/Form/AreaOutput";

export const DEAL_BREAKERS = [
  { value: "carpet_bedroom", label: "Carpet bedroom" },
  { value: "carpet_anywhere", label: "Carpet anywhere" },
  { value: "first_floor", label: "First floor" },
  { value: "owner_occupied", label: "Owner occupied" },
  { value: "basement_unit", label: "Basement unit" },
  { value: "one_flight_stairs", label: "One flight stairs" },
  { value: "two_plus_stairs", label: "Two plus stairs" },
  { value: "large_complex", label: "Large complex" },
  { value: "loft_unit", label: "Studio apartment" },
  { value: "oil_heat", label: "Oil heat" },
  { value: "galley_kitchen", label: "Galley kitchen" },
  { value: "pay_laundry", label: "Pay laundary" },
];

export const MUST_HAVES = [
  { value: "dishwasher", label: "Dishwasher" },
  { value: "laundry_in_unit", label: "Laundry in-unit" },
  { value: "any_laundry", label: "Any Laundry" },
  { value: "covered_parking", label: "Covered Parking" },
  { value: "yard", label: "Yard" },
  { value: "outdoor_space", label: "Outdoor space" },
  { value: "ac", label: "AC" },
  { value: "ensuite", label: "Ensuite" },
  { value: "top_floor", label: "Top floor" },
  { value: "doorman", label: "Doorman" },
  { value: "pool", label: "Pool" },
  { value: "storage", label: "Storage" },
  { value: "basement", label: "Basement" },
  { value: "elevator", label: "Elevator" },
  { value: "gym", label: "Gym" },
  { value: "no_pets", label: "No Pets" },
  { value: "closet", label: "Closet" },
  { value: "granite_kitchen", label: "Granite kitchen" },
  { value: "bathtub", label: "Bathub" },
  { value: "fireplace", label: "Fireplace" },
  { value: "gas_stovetop", label: "Gas stovetop" },
  { value: "furnished", label: "Furnished" },
];

const OPTIONS = {
  YES_NO: [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ],
  CREDIT_SCORE: [
    { value: "under_600", label: "Below 600" }, // TODO: deprecate in favor of below_600
    // { value: "below_600", label: "Below 600" },
    { value: "600-650", label: "600-650" },
    { value: "650-700", label: "651-700" },
    { value: "700-750", label: "701-750" },
    { value: "750-800", label: "751-800" },
    // { value: "above_800", label: "Above 800" },
    { value: "800+", label: "Above 800" }, // TODO: deprecate in favor of above_800
    { value: "unknown", label: `I don't have or know my credit score` },
  ],
  LISTINGS_VIEWED: [
    { value: "none_seen", label: "None" },
    { value: "one_to_three", label: "1 to 3" },
    { value: "four_to_six", label: "4 to 6" },
    { value: "seven_to_nine", label: "7 to 9" },
    { value: "ten_plus", label: "10+" },
  ],
  LEASE: [
    { value: "no_lease", label: "No Lease" },
    { value: "standard_lease", label: "Standard 12 Month" },
    { value: "month_to_month", label: "Month-to-month" },
  ],
  EMPLOYMENT_STATUS: [
    { value: null, label: "Select" },
    { value: "employed", label: "Employed" },
    { value: "student", label: "Student" },
    { value: "not_employed", label: "Neither" },
  ],
};

export const PASSPORT_FIELDS = {
  AREAS: {
    key: "area",
    label: "Area",
    format: (v: []) => v,
    input: "places",
    inputComponent: AreaAutocomplete,
    outputComponent: AreaOutput,
  },
  BUDGET_MIN: {
    key: "budget_min",
    label: "Minimum Budget",
    prefix: "$",
    suffix: "USD",
    inputFormat: (v: number) => v,
    format: (v: number) => formatCurrency(v),
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  BUDGET_MAX: {
    key: "budget_max",
    label: "Max Budget",
    prefix: "$",
    suffix: "USD",
    inputFormat: (v: number) => v,
    format: (v: number) => formatCurrency(v),
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  BEDROOMS_MIN: {
    key: "bedrooms_min",
    label: "Min. Beds",
    format: (v: number) => `${Number(v)}+`,
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  BEDROOMS_MAX: {
    key: "bedrooms_max",
    label: "Max Beds",
    format: (v: number) => Number(v),
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  BATHROOMS_MIN: {
    key: "bathrooms_min",
    label: "Min. Baths",
    format: (v: number) => `${Number(v)}+`,
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  BATHROOMS_MAX: {
    key: "bathrooms_max",
    label: "Max Baths",
    format: (v: number) => Number(v),
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  PETS: {
    key: "pets",
    label: "Pets",
    format: (v: any) => v,
    inputComponent: PetsInput,
    outputComponent: PetsOutput,
    order: 1,
  },
  NUMBER_OF_PARKING: {
    key: "number_of_parking",
    label: "Desired Number of Parking Spaces",
    format: (v: any) => v,
    inputComponent: TextInput,
    outputComponent: TextOutput,
    order: 2,
  },
  OTHER: {
    key: "other",
    label: "Notes About Me",
    format: (v: any) => v,
    inputComponent: TextAreaInput,
  },
  MUST_HAVE: {
    key: "must_have",
    label: "Must Haves",
    format: (v: any) => v,
    inputComponent: CheckBoxGroupInput,
    outputComponent: CheckboxGroupOutput,
    options: MUST_HAVES.sort((a, b) => (a.value > b.value ? 1 : -1)),
  },
  DEAL_BREAKERS: {
    key: "deal_breaker",
    label: "Deal Breakers",
    format: (v: any) => v,
    inputComponent: CheckBoxGroupInput,
    outputComponent: CheckboxGroupOutput,
    options: DEAL_BREAKERS.sort((a, b) => (a.value > b.value ? 1 : -1)),
  },
  NUMBER_OF_TENANTS: {
    key: "number_of_tenants",
    label: "Number of Tenants",
    format: (v: any) => v,
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  EMPLOYED_TENANTS: {
    key: "employed_tenants",
    label: "Employed Tenants",
    format: (v: any) => v,
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  STUDENT_OR_EMPLOYED: {
    key: "student_or_employed",
    label: "Employment / Student Status",
    format: (v: any) => v,
    options: OPTIONS.EMPLOYMENT_STATUS,
    inputComponent: SelectInput,
    outputComponent: SelectOutput,
  },
  JOB_LOCATION: {
    key: "job_location",
    label: "Employer / Company",
    format: (v: any) => v,
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  JOB_POSITION: {
    key: "job_position",
    label: "Position",
    format: (v: any) => v,
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  YEARLY_INCOME: {
    key: "yearly_income",
    label: "Annual Income",
    prefix: "$",
    suffix: "USD",
    placeholder: "100,000",
    inputFormat: (v: number) => v,
    format: (v: number) => formatCurrency(v),
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
  CREDIT_SCORE: {
    key: "credit_score",
    label: "Credit Score",
    format: (v: any) => v,
    options: OPTIONS.CREDIT_SCORE,
    inputComponent: SelectInput,
    outputComponent: SelectOutput,
  },
  MOVE_IN_DATE_START: {
    key: "move_in_date_start",
    label: "Date you want your lease to start",
    format: (v: any) => formatDate(v),
    inputFormat: (v: any) => formatDate(v, "YYYY-MM-DD"),
    inputComponent: DateSelect,
    outputComponent: TextOutput,
  },
  AVAILABLE_TIMES: {
    key: "available_times",
    label: "When are you available to view rentals?",
    format: (v: any) => formatDate(v),
    inputFormat: (v: any) => formatDate(v, "YYYY-MM-DD"),
    inputComponent: DateSelect,
    outputComponent: TextOutput,
  },
  LEASE_STATUS: {
    key: "lease_status",
    label: "Do you have a current lease?",
    format: (v: any) => v,
    options: OPTIONS.LEASE,
    inputComponent: SelectInput,
    outputComponent: SelectOutput,
  },
  CONSIDER_OWNING_HOME: {
    key: "considering_buying",
    label: "Are you planning on buying in the near future?",
    format: (v: any) => v,
    options: OPTIONS.YES_NO,
    inputComponent: SelectInput,
    outputComponent: SelectOutput,
  },
  HAS_COSIGNER: {
    key: "has_cosigner",
    label: null,
    format: (v: any) => v,
    options: OPTIONS.YES_NO,
    inputComponent: SelectInput,
    outputComponent: SelectOutput,
  },
  LISTINGS_VIEWED: {
    key: "listings_viewed",
    label: "Rentals Viewed",
    format: (v: any) => v,
    options: OPTIONS.LISTINGS_VIEWED,
    inputComponent: SelectInput,
    outputComponent: SelectOutput,
  },
  LISTINGS_APPLIED_TO: {
    key: "listings_applied",
    label: "Rentals Applied",
    format: (v: any) => v,
    inputComponent: TextInput,
    outputComponent: TextOutput,
  },
};
