import { RootStateOrAny } from "react-redux";

export const renterSRSelector = (state: RootStateOrAny) => ({
  renterRequestsData: state.renterRequestReducer.renterRequestData || [],
  renterRequestsLoading: state.renterRequestReducer.renterRequestLoading,
  renterRequestsError: state.renterRequestReducer.renterRequestError
});

export const renterSRQuerySelector = (state: RootStateOrAny) =>
  state.renterRequestReducer.queryParams;
