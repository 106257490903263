import ContactInfoStep from "../requirement-form/components/requirement-form-steps/components/contact-info/ContactInfoStep";
import CommunicationMethod from "../requirement-form/components/requirement-form-steps/components/comunication-method/CommunicationMethod";
import MoveInDateAndArea from "../requirement-form/components/requirement-form-steps/components/move-in-date-and-area/MoveInDateAndArea";
import LeaseStatusAndBudget from "../requirement-form/components/requirement-form-steps/components/lease-status-and-budget/LeaseStatusAndBudget";
import HomeAndPeople from "../requirement-form/components/requirement-form-steps/components/home-and-people/HomeAndPeople";
import Occupation from "../requirement-form/components/requirement-form-steps/components/occupation/Occupation";
import PetsInfo, { IpetsListItem } from "../requirement-form/components/requirement-form-steps/components/pets/PetsInfo";
import MustHavesRequirement from "../requirement-form/components/requirement-form-steps/components/must-haves/MustHavesRequirement";
import DealBreakers from "../requirement-form/components/requirement-form-steps/components/deal-breakers/DealBreakers";
import DesignatedParking from "../requirement-form/components/requirement-form-steps/components/parking-spot/DesignatedParking";
import LastStep from "../requirement-form/components/requirement-form-steps/components/last-step/LastStep";
import React from "react";
import { FormInstance } from "antd/es/form";
import { IlocationItem } from "../requirement-form/components/requirement-form-steps/components/move-in-date-and-area/areas-section/types/types";
import AuthForm from "../../SignIn/sign-in-form/AuthForm";

interface IStepsInterface {
  edittedData: any;
  isEditMode: boolean;
  isNewRenter: boolean;
  form: FormInstance<any>;
  setIsNewRenter: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoginFormVisible: React.Dispatch<React.SetStateAction<null>>;
  showExistError: boolean;
  setShowExistError: React.Dispatch<React.SetStateAction<boolean>>;
  changeStep: (reqStep: number) => void;
  communicationMWrapperRef: React.MutableRefObject<HTMLElement | undefined>;
  setStartDateValue: React.Dispatch<React.SetStateAction<null>>;
  isSubmitForm: boolean;
  setLocations: React.Dispatch<React.SetStateAction<IlocationItem[]>>;
  usefulFieldValues: { student_or_employed: undefined };
  setPetsList: React.Dispatch<React.SetStateAction<IpetsListItem[]>>;
  isLoginFormVisible: any;
  loginFormSubmit: any;
  isManual: boolean;
}

export const steps = (props: IStepsInterface) => {
  return [
    {
      stepNumber: 1,
      component: !props.isLoginFormVisible ? (
        <ContactInfoStep
          edittedData={props.edittedData}
          isEditMode={props.isEditMode}
          isNewRenter={props.isNewRenter}
          form={props.form}
          setIsNewRenter={props.setIsNewRenter}
          setIsLoginFormVisible={props.setIsLoginFormVisible}
          showExistError={props.showExistError}
          setShowExistError={props.setShowExistError}
          changeStep={props.changeStep}
        />
      ) : (
        <AuthForm onSubmit={props.loginFormSubmit} isFromReqPage={true} />
      ),
      name: props.isManual ? "Lead's contact details" : "Contact",
    },
    {
      stepNumber: 2,
      component: <CommunicationMethod ref={props.communicationMWrapperRef} />,
      name: "Communication Method",
    },
    {
      stepNumber: 3,
      component: <MoveInDateAndArea form={props.form} setStartDateValue={props.setStartDateValue} setLocations={props.setLocations} disableFreeForms />,
      name: "Move-in & Location",
    },
    {
      stepNumber: 4,
      component: (
        <>
          <LeaseStatusAndBudget />
          <Occupation usefullFieldValues={props.usefulFieldValues} form={props.form} />
        </>
      ),
      name: "Lease & Budget",
    },
    {
      stepNumber: 5,
      component: (
        <>
          <PetsInfo setPetsListForParent={props.setPetsList} />
          <DesignatedParking />
        </>
      ),
      name: "Pets & Parking",
    },
    {
      stepNumber: 6,
      component: <HomeAndPeople />,
      name: "Home & People",
    },
    {
      stepNumber: 7,
      component: (
        <>
          <MustHavesRequirement heading="Likes" />
          <DealBreakers heading="Dislikes" />
        </>
      ),
      name: "",
    },
    {
      stepNumber: 8,
      component: <LastStep />,
      name: "Last Step",
    },
  ];
};
