import { IObjectKeys } from "helpers/globalTypes";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { leadAccessRequirement, leadDeclineRequirement } from "store/requirement/action";
import LeadPermissionItem from "./LeadPermissionItem/LeadPermissionItem";
import "./styles.scss";
import ConfirmRevokeLeadModal from "./confirm-revoke-lead-modal/ConfirmRevokeLeadModal";
import AgentReqModal from "components/LeadsSingleRequirement/components/AgentReqModal";
import { AnalyticsService } from "helpers/analyticsService";
import { IOPassportMeta } from "../../RenterPortalInterfaces";

const initialRevokeState = {
  agent: {
    agent_id: undefined,
    _id: "",
  },
  requirementId: "",
};

export const AGENT_CONNECTION_STATUS = {
  APPROVE: "Approve",
  DECLINE: "Decline",
  REVOKE: "Remove",
};

const ConnectedAgents = ({ passport }: IOPassportMeta) => {
  const dispatch = useDispatch();
  const { agentsOnReq, requestedAgentsOnReq } = passport ?? {};
  const [agents, setAgents] = useState<any>([]);
  const [confirmRevokeLeadModalVisible, setConfirmRevokeLeadModalVisible] = useState(false);
  const [modalData, setModalData] = useState(initialRevokeState);
  const [toggleModal, setToggleModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(true);
  const [shouldReportRenterIdentify, setShouldReportRenterIdentify] = useState(false);

  const localApproveAgent = useCallback((agent_id: string) => {
    setAgents((prev: any) => {
      const candidate: IObjectKeys = prev.find((agent: IObjectKeys) => agent._id === agent_id) ?? {};
      candidate.status = "Accepted";
      return [...prev];
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("agents_connected", agents.length);
    const role = localStorage.getItem("user_role");
    if (role && role.toLowerCase() === "renter" && shouldReportRenterIdentify) {
      AnalyticsService.reportRenterIdentify();
    }
  }, [agents]);

  const localDeclineOrRevokeAgent = useCallback((agent_id: string) => {
    setAgents((prev: any) => prev.filter((agent: IObjectKeys) => agent._id !== agent_id));
  }, []);

  const handleAction = useCallback(
    ({ agent, requirementId, actionName }: { agent: any; requirementId: string; actionName: string }) => {
      AnalyticsService.reportEvent("connected_agents_3_dots_menu_item_clicked", { label: actionName });
      switch (actionName) {
        case AGENT_CONNECTION_STATUS.APPROVE:
          dispatch(
            leadAccessRequirement({
              agent_id: agent.agent_id,
              requirements_id: requirementId,
              type: "fromDropdown",
            })
          );
          setShowApproveModal(false);
          localApproveAgent(agent._id);
          break;
        case AGENT_CONNECTION_STATUS.DECLINE:
          dispatch(
            leadDeclineRequirement({
              agent_id: agent.agent_id,
              requirements_id: requirementId,
            })
          );
          localDeclineOrRevokeAgent(agent._id);
          break;
        case AGENT_CONNECTION_STATUS.REVOKE:
          setConfirmRevokeLeadModalVisible(true);
          setModalData({
            agent,
            requirementId,
          });
          break;
      }
    },
    [dispatch, localApproveAgent, localDeclineOrRevokeAgent]
  );

  useEffect(() => {
    const agentsData: any[] = [];

    if (Array.isArray(requestedAgentsOnReq))
      requestedAgentsOnReq.forEach((agent: any) => {
        const agentToSet = {
          status: "Pending",
          agent_id: agent._id,
          ...agent.user_id,
        };
        agentsData.push(agentToSet);
      });
    if (Array.isArray(agentsOnReq))
      agentsOnReq.forEach((agent: any) => {
        const agentToSet = {
          status: "Accepted",
          agent_id: agent._id,
          ...agent.user_id,
        };
        agentsData.push(agentToSet);
      });

    setAgents(agentsData);
    setShouldReportRenterIdentify(true);
  }, [agentsOnReq, requestedAgentsOnReq]);

  return (
    <div>
      <div className={"lead-permissions"}>
        {agents.map((agent: IObjectKeys) => {
          return (
            <LeadPermissionItem
              agent={agent}
              key={agent._id}
              requirementId={passport?._id}
              handleAction={handleAction}
            />
          );
        })}
      </div>
      <ConfirmRevokeLeadModal
        visible={confirmRevokeLeadModalVisible}
        setConfirmModalProps={setConfirmRevokeLeadModalVisible}
        modalData={modalData}
        localDeclineOrRevokeAgent={localDeclineOrRevokeAgent}
      />
      {!!agents.length && (
        <AgentReqModal
          toggle={toggleModal}
          setToggle={setToggleModal}
          showApproveModal={showApproveModal}
          setShowApproveModal={setShowApproveModal}
        />
      )}
    </div>
  );
};
export default ConnectedAgents;
