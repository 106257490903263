import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { AnalyticsService } from "helpers/analyticsService";
import { useDispatch, useSelector } from "react-redux";
import { renterPassportSelector } from "store/renterPassport/selectors";
import ListingGrid from "./Components/ListingGrid";
import RenterPortalApi from "api/RenterPortal";
import GlobalLoading from "components/Shared/GlobalLoading";
import BaseButton from "components/Shared/BaseButton";
import Card from "components/Shared/Card/Card";
import { PASSPORT_FIELDS } from "../Shared/Fields/PassportFields";
import { renderItemValue } from "./Components/Form/FieldItems";
import { renterPortalPassportRoute, renterPortalSearchRoute } from "helpers/clientRoutes";
import { push } from "connected-react-router";
// import { ScreeningApplications } from "./Components/ScreeningApplications";
import Section from "components/Shared/Section/Section";
import RenterScreenings from "components/Lead/renter-page/renter-screening";

const RentalPortalDashboard = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [listings, setListings] = useState<any | null>(null);
  const [passport, setPassport] = useState<any | null>(null);
  const [renter, setRenter] = useState<any | null>(null);
  const renterPassport = useSelector(renterPassportSelector); // TODO: should be renterSelect.renterPassport

  const DEFAULT_RECOMMENDATIONS_COUNT = 6;

  const fetchPassportListingRecommendations = async () => {
    try {
      setIsLoading(true);
      const params = {
        requirement_id: renterPassport.passport._id,
        size: DEFAULT_RECOMMENDATIONS_COUNT,
      };
      const data = await RenterPortalApi.fetchPassportListingRecommendations(params);
      if (data.result.hits) {
        setListings(data.result.hits);
      }
      setIsLoading(false);
    } catch (error) {
      setError(
        "Oops! Something went wrong while trying to fetch you some rental recommendations. Please try the search instead."
      );
    }
  };

  useEffect(() => {
    if (listings) {
      setIsLoading(false);
    }
  }, [listings]);

  useEffect(() => {
    if (renterPassport.passport) {
      setPassport(renterPassport.passport);
      setRenter(renterPassport.renter);
      fetchPassportListingRecommendations();
    }
  }, [renterPassport.passport]);

  const passportFieldsForRecommendations = [
    PASSPORT_FIELDS.BUDGET_MAX,
    PASSPORT_FIELDS.BATHROOMS_MIN,
    PASSPORT_FIELDS.BEDROOMS_MIN,
    PASSPORT_FIELDS.AREAS,
  ];

  return (
    <Spin spinning={isLoading} indicator={<GlobalLoading />} className={"global-loading"}>
      <div className="mx-auto max-w-8xl">
        <div className="mx-auto pb-16 grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
            <div className="py-10">
              <h1 className="text-3xl font-bold text-purple-900">Welcome to Rentbase</h1>
            </div>
            {passport && <RenterScreenings renterId={passport?.renter_id} />}
            {/* <ScreeningApplications passport={passport} renter={renter} /> */}
            <Section shadow className="bg-gray-50">
              <h3 className="text-xl flex gap-1">
                Recommended rental listings based on your{" "}
                <BaseButton variant="link" onClick={() => dispatch(push(renterPortalPassportRoute()))}>
                  Rental Passport
                </BaseButton>
              </h3>
              {passport && (
                <div className="py-4">
                  {passportFieldsForRecommendations?.map((field) => {
                    return (
                      <span
                        key={`passport-recommendations-${field.key}`}
                        className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 mr-2"
                      >
                        <span className="mr-1   ">{field.label}</span>
                        {renderItemValue(field.format(passport[field.key]))}
                      </span>
                    );
                  })}
                </div>
              )}
              {listings?.length ? (
                <ListingGrid listings={listings} />
              ) : (
                <div>
                  <div className="text-sm">No listings matches available based on your current Rental Passport. 😔</div>
                  <div className="pt-4 sm:pt-8">
                    <BaseButton size="sm" onClick={() => dispatch(push(renterPortalPassportRoute()))} variant="link">
                      Need to make changes to your Rental Passport?
                    </BaseButton>
                  </div>
                </div>
              )}
              <div className="mt-6 flex justify-center">
                <BaseButton variant="link" onClick={() => dispatch(push(renterPortalSearchRoute()))}>
                  Search More Rentals
                </BaseButton>
              </div>
            </Section>
            <div className="my-8">
              {/* <Card>
                <div className="font-bold text-base pb-2">Credit Score</div>
                <div className="mb-8">
                  <div className="w-full">
                    <h3 className="font-bold text-2xl text-purple-400">
                      The average renter’s score is <span className="text-orange-400">705</span>
                    </h3>
                  </div>
                  <div className="w-full flex justify-between">
                    <p className="py-4">
                      Get your free credit score to see where you stand. Discover what impacts your score and open the
                      door for more financial opportunities, like loans and new credit cards.
                    </p>
                  </div>
                </div>
                <BaseButton variant="primary">Get My Free Credit Score</BaseButton>
              </Card> */}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default RentalPortalDashboard;
