import React from "react";
import { FormInstance } from "antd/es/form";
import MoveInDate from "./move-in-date-section/MoveInDate";
import LocationsWrapper from "./areas-section/LocationsWrapper";
import { IlocationItem } from "./areas-section/types/types";

interface IMoveInDateAndArea {
  form: FormInstance<any>;
  setStartDateValue: React.Dispatch<React.SetStateAction<null>>;
  setLocations: React.Dispatch<React.SetStateAction<IlocationItem[]>>;
  disableFreeForms?: boolean;
}
const MoveInDateAndArea = ({ form, setStartDateValue, setLocations, disableFreeForms }: IMoveInDateAndArea) => {
  return (
    <div>
      <MoveInDate form={form} setStartDateValue={setStartDateValue} />
      <LocationsWrapper setParentsLocations={setLocations} disableFreeForms={disableFreeForms} form={form} />
    </div>
  );
};

export default MoveInDateAndArea;
