import React, {useEffect} from "react";
import FilterFormTitle from "../filter-form-title/FilterFormTitle";
import "./MyListingsFilter.scss"
import SwitchButton from "../../../../../ListingView/ListingForm/SwitchButton";
import {useLocation} from "react-router";
import {urlQueryToObject} from "../../../helpers/listingsCreateGetSearchQuery";

interface IMyListingsFilter {
  form: any;
  justMyListings: boolean;
  setJustMyListings: React.Dispatch<React.SetStateAction<boolean>>;
}

const MyListingsFilter = ({ form, justMyListings, setJustMyListings }: IMyListingsFilter) => {
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  const handleChange = (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => {
    e.persist();
    const { checked } = e.target;
    setJustMyListings(!checked);
    form.submit();
  };

  useEffect(() => {
    if (queryObject.filter_by_agent) {
      setJustMyListings(queryObject.filter_by_agent);
      form.submit();
    }
  }, [queryObject.filter_by_agent]);
  return (
    <div className={"my-listings-filter-wrapper"}>
      <FilterFormTitle title={"Show just my listings"} />
      <SwitchButton
        classnames={!justMyListings && { switchButtonSection: "my-listings-wrapper-switch" }}
        name={"filter_by_agent"}
        label={""}
        onChange={handleChange}
        checked={justMyListings}
      />
    </div>
  );
};

export default MyListingsFilter
