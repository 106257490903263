import React from "react";

const ExpandOpenIcon = () => {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49985 7.66667C7.37373 7.66737 7.2487 7.64425 7.13194 7.59863C7.01518 7.55301 6.90898 7.4858 6.81943 7.40084L1.06944 1.90084C0.888978 1.72823 0.787597 1.49412 0.787597 1.25001C0.787598 1.0059 0.888978 0.771786 1.06944 0.599174C1.24989 0.426562 1.49465 0.32959 1.74985 0.32959C2.00506 0.32959 2.24981 0.426562 2.43027 0.599174L7.49985 5.45751L12.5694 0.608343C12.7528 0.458169 12.9886 0.379696 13.2298 0.388607C13.471 0.397518 13.6998 0.493157 13.8704 0.65641C14.0411 0.819663 14.1411 1.03851 14.1504 1.26921C14.1597 1.49991 14.0777 1.72548 13.9207 1.90084L8.17068 7.40084C7.99218 7.57019 7.75126 7.66566 7.49985 7.66667Z"
        fill="#141414"
      />
    </svg>
  );
};

export default ExpandOpenIcon;
