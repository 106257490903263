import { Spin } from "antd";
import BaseButton from "components/Shared/BaseButton";
import { useWindowSize } from "hooks/useWindowSize";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { windowSelector } from "store/app/selectors";
import { userSelector } from "store/user/selectors";
import { getAgentAllSrRequest, setAgentsAllScreeningRequestQueryParams } from "../../store/agent/action";
import { agentAllSRQuerySelector, agentAllSRSelector } from "../../store/agent/selectors";
import AddScreeningRequestDialog from "./add-modal";
import TenantScreeningsHeader from "./header";
import TenantScreeningsWrapper from "./view/ViewWrapper";
import GlobalLoading from "components/Shared/GlobalLoading";

function TenantScreenings() {
  const dispatch = useDispatch();
  const { allLoading = false, allData = [], allRequestsTotal, allPaginationValues = { current: 1, pageSize: 24 } } = useSelector(
    agentAllSRSelector
  );
  const userData = useSelector(userSelector);
  const queryParams = useSelector(agentAllSRQuerySelector);
  const location = useLocation();
  const { width }: any = useWindowSize();
  const { type } = useSelector(windowSelector);
  const history = useHistory();

  const [showingCount, setShowingCount] = useState(+(localStorage.getItem("screening_req_showing_count") as string) || 24);
  const [showAddDialog, setShowAddDialog] = useState(false);

  const fetchAllScreeningRequests = () => {
    dispatch(getAgentAllSrRequest(userData.agent_id));
  };

  useEffect(() => {
    if (userData?.agent_id && !location.search.includes("add=true")) fetchAllScreeningRequests();
  }, [userData, location]);

  useEffect(() => {
    if (location.search.includes("add=true")) {
      setShowAddDialog(true);
    }
  }, [location]);

  // just making sure if user refresh the page with add=true in url then we will show the add dialog
  useEffect(() => {
    if (location.search.includes("add=true")) {
      setShowAddDialog(true);
    }
  }, []);

  if (allData.length === 0 && !allLoading && !showAddDialog) {
    return (
      <div className="sm:pt-32">
        <div className="empty-lead">
          <img src="/images/hood.png" className="w-1/2" />
          <div className="my-4 text-center">
            <h2 className="text-3xl mt-4 sm:px-20 w-full">
              When a renter is prepared to apply for an apartment, click the button below to request a comprehensive <strong>credit</strong>
              , <strong>background</strong>, and <strong>eviction report</strong>.
            </h2>
            <p className="text-lg my-4">
              You’ll receive a notification once the report is complete, and all reports can be accessed and viewed here.
            </p>
          </div>
          <BaseButton onClick={() => history.push("/tenant-screenings?add=true")} modalType="Send Screening Request">
            <span>{type === "mobile" ? "Add" : "Send Screening Request"}</span>
          </BaseButton>
        </div>
      </div>
    );
  }

  if (allLoading) {
    return <Spin spinning={allLoading} indicator={<GlobalLoading />} className="global-loading"></Spin>;
  }

  return (
    <div>
      <TenantScreeningsHeader list={allData} total={allRequestsTotal} />
      <AddScreeningRequestDialog visible={showAddDialog} setVisible={setShowAddDialog} refreshList={fetchAllScreeningRequests} />
      <div className="leads-part-dashboard hide-scroll-bar">
        <TenantScreeningsWrapper
          allPaginationValues={allPaginationValues}
          allData={allData}
          allRequestsTotal={allRequestsTotal}
          setShowingCount={setShowingCount}
          showingCount={showingCount}
        />
      </div>
    </div>
  );
}

export default TenantScreenings;
