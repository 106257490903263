import React from "react";

export const DeleteIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66785 0.96582H9.34191V1.41577H10.3076V0.902832C10.3077 0.405029 9.90295 0 9.40539 0H6.60437C6.10681 0 5.70203 0.405029 5.70203 0.902832V1.41577H6.66785V0.96582Z"
        fill="black"
      />
      <path
        d="M12.538 5.24219H3.47205C3.22364 5.24219 3.02808 5.4541 3.0481 5.70178L3.80604 15.0739C3.84827 15.597 4.28468 16 4.80897 16H11.2009C11.7252 16 12.1616 15.597 12.2039 15.0737L12.9618 5.70178C12.9819 5.4541 12.7864 5.24219 12.538 5.24219ZM5.66724 15.0004C5.65711 15.001 5.64698 15.0013 5.63697 15.0013C5.3838 15.0013 5.17127 14.8042 5.15552 14.5481L4.68055 6.85413C4.66419 6.58789 4.86671 6.35876 5.13282 6.34241C5.39808 6.32629 5.62818 6.52832 5.64454 6.79468L6.11939 14.4886C6.13587 14.7549 5.93336 14.9839 5.66724 15.0004ZM8.49329 14.5184C8.49329 14.785 8.27711 15.0012 8.01038 15.0012C7.74366 15.0012 7.52747 14.785 7.52747 14.5184V6.82434C7.52747 6.55762 7.74366 6.34143 8.01038 6.34143C8.27698 6.34143 8.49329 6.55762 8.49329 6.82434V14.5184ZM11.3295 6.85278L10.876 14.5468C10.861 14.8033 10.6481 15.0012 10.3944 15.0012C10.3849 15.0012 10.3753 15.001 10.3656 15.0005C10.0994 14.9847 9.89625 14.7562 9.91199 14.49L10.3654 6.7959C10.381 6.52966 10.6088 6.32654 10.8757 6.34229C11.142 6.35791 11.3451 6.58655 11.3295 6.85278Z"
        fill="black"
      />
      <path
        d="M14.2079 3.75195L13.8907 2.80127C13.8071 2.55066 13.5725 2.38159 13.3082 2.38159H2.70164C2.43748 2.38159 2.20274 2.55066 2.11925 2.80127L1.80211 3.75195C1.74095 3.9353 1.82054 4.12231 1.9691 4.21558C2.02965 4.25354 2.1013 4.27637 2.18004 4.27637H13.8299C13.9087 4.27637 13.9805 4.25354 14.0409 4.21545C14.1894 4.12219 14.269 3.93518 14.2079 3.75195Z"
        fill="black"
      />
    </svg>
  );
};
