import { Skeleton } from "antd";
import BaseCollapse from "components/Shared/BaseCollapse";
import { IObjectKeys } from "helpers/globalTypes";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singleLeadDataSelector } from "store/leads/selectors";
import { leadAccessRequirement, leadDeclineRequirement } from "store/requirement/action";
import { leadAnyActionLoadingSelector } from "store/requirement/selectors";
import LeadPermissionItem from "./LeadPermissionItem/LeadPermissionItem";
import "./styles.scss";
import ConfirmRevokeLeadModal from "./confirm-revoke-lead-modal/ConfirmRevokeLeadModal";
import AgentReqModal from "components/LeadsSingleRequirement/components/AgentReqModal";
import { AnalyticsService } from "helpers/analyticsService";
const initialRevokeState = {
  agent: {
    agent_id: undefined,
    _id: "",
  },
  requirementId: "",
};
const LeadPermissionsSection = () => {
  const dispatch = useDispatch();
  const leadData = useSelector(singleLeadDataSelector);
  const leadActionsLoading = useSelector(leadAnyActionLoadingSelector);
  const { agentsOnReq, requestedAgentsOnReq } = leadData.requirement ?? {};
  const [agents, setAgents] = useState<any>([]);
  const [confirmRevokeLeadModalVisible, setConfirmRevokeLeadModalVisible] = useState(false);
  const [modalData, setModalData] = useState(initialRevokeState);
  const [toggleModal, setToggleModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(true);
  const [shouldReportRenterIdentify, setShouldReportRenterIdentify] = useState(false);

  const localApproveAgent = useCallback((agent_id: string) => {
    setAgents((prev: any) => {
      const candidate: IObjectKeys = prev.find((agent: IObjectKeys) => agent._id === agent_id) ?? {};
      candidate.status = "Accepted";
      return [...prev];
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("agents_connected", agents.length);
    const role = localStorage.getItem("user_role");
    if (role && role.toLowerCase() === "renter" && shouldReportRenterIdentify) {
      AnalyticsService.reportRenterIdentify();
    }
  }, [agents]);

  const localDeclineOrRevokeAgent = useCallback((agent_id: string) => {
    setAgents((prev: any) => prev.filter((agent: IObjectKeys) => agent._id !== agent_id));
  }, []);

  const handleAction = useCallback(
    (e: any, props: IObjectKeys) => {
      const target = e.target;
      if (target.classList.contains("permission-menu-item")) {
        AnalyticsService.reportEvent("connected_agents_3_dots_menu_item_clicked", { label: target.dataset.actionName });
        switch (target.dataset.actionName) {
          case "Approve":
            dispatch(
              leadAccessRequirement({
                agent_id: props.agent.agent_id,
                requirements_id: props.requirementId,
                type: "fromDropdown",
              })
            );
            setShowApproveModal(false);
            localApproveAgent(props.agent._id);
            break;
          case "Decline":
            dispatch(
              leadDeclineRequirement({
                agent_id: props.agent.agent_id,
                requirements_id: props.requirementId,
              })
            );
            localDeclineOrRevokeAgent(props.agent._id);
            break;
          case "Revoke":
            setConfirmRevokeLeadModalVisible(true);
            setModalData({
              agent: props.agent,
              requirementId: props.requirementId,
            });
            break;
        }
      }
    },
    [dispatch, localApproveAgent, localDeclineOrRevokeAgent]
  );

  useEffect(() => {
    const agentsData: any[] = [];
    if (Array.isArray(requestedAgentsOnReq))
      requestedAgentsOnReq.forEach((agent: any) => {
        const agentToSet = {
          status: "Pending",
          agent_id: agent._id,
          ...agent.user_id,
        };
        agentsData.push(agentToSet);
      });
    if (Array.isArray(agentsOnReq))
      agentsOnReq.forEach((agent: any) => {
        const agentToSet = {
          status: "Accepted",
          agent_id: agent._id,
          ...agent.user_id,
        };
        agentsData.push(agentToSet);
      });

    setAgents(agentsData);
    setShouldReportRenterIdentify(true);
  }, [agentsOnReq, requestedAgentsOnReq]);

  return (
    <Skeleton title={false} loading={leadActionsLoading} active>
      <BaseCollapse header={"Connected Agents"} classname="listing-fees-and-types lead-info-collapse">
        <div className={"lead-permissions"}>
          {agents.map((agent: IObjectKeys) => {
            return <LeadPermissionItem agent={agent} key={agent._id} requirementId={leadData?.requirement?._id} handleAction={handleAction} />;
          })}
        </div>
      </BaseCollapse>
      <ConfirmRevokeLeadModal visible={confirmRevokeLeadModalVisible} setConfirmModalProps={setConfirmRevokeLeadModalVisible} modalData={modalData} localDeclineOrRevokeAgent={localDeclineOrRevokeAgent} />
      {!!agents.length && <AgentReqModal toggle={toggleModal} setToggle={setToggleModal} showApproveModal={showApproveModal} setShowApproveModal={setShowApproveModal} />}
    </Skeleton>
  );
};
export default LeadPermissionsSection;
