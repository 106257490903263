import React from "react";

export const NotePad = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.18642 11.2909L6.72774 11.9539C6.58323 12.0196 6.43051 12.0529 6.27378 12.0529C6.08658 12.0529 5.90669 12.0052 5.74818 11.9178C5.7403 11.9183 5.73248 11.919 5.72447 11.919H2.62875C2.40223 11.919 2.21859 11.7354 2.21859 11.5089C2.21859 11.2824 2.40223 11.0987 2.62875 11.0987H5.18963C5.16398 10.9002 5.19326 10.6952 5.27945 10.5056L5.38273 10.2784H2.62875C2.40223 10.2784 2.21859 10.0948 2.21859 9.86825C2.21859 9.64173 2.40223 9.45809 2.62875 9.45809H5.75559L5.94445 9.04258C5.94494 9.04148 5.94549 9.04061 5.94601 9.03957C5.98623 8.95204 6.04177 8.87138 6.11119 8.80195L6.27539 8.63778H2.62875C2.40223 8.63778 2.21859 8.45414 2.21859 8.22762C2.21859 8.00111 2.40223 7.81747 2.62875 7.81747H7.0957L7.91602 6.99718H2.62875C2.40223 6.99718 2.21859 6.81354 2.21859 6.58703C2.21859 6.36051 2.40223 6.17687 2.62875 6.17687H8.73633L10.8616 4.05163L10.871 4.04223V1.83206C10.871 1.60554 10.6874 1.4219 10.4609 1.4219H9.50384V0.410156C9.50384 0.183641 9.3202 0 9.09369 0C8.86717 0 8.68353 0.183641 8.68353 0.410156V1.42188H7.82414V0.410156C7.82414 0.183641 7.6405 0 7.41399 0C7.18747 0 7.00383 0.183641 7.00383 0.410156V1.42188H6.14441V0.410156C6.14441 0.183641 5.96077 0 5.73426 0C5.50774 0 5.3241 0.183641 5.3241 0.410156V1.42188H4.46472V0.410156C4.46472 0.183641 4.28107 0 4.05456 0C3.82804 0 3.6444 0.183641 3.6444 0.410156V1.42188H2.78502V0.410156C2.78502 0.183641 2.60138 0 2.37486 0C2.14834 0 1.9647 0.183641 1.9647 0.410156V1.42188H0.988121C0.761605 1.42188 0.577965 1.60552 0.577965 1.83203V12.7695C0.577965 13.448 1.12995 14 1.80843 14H9.64056C10.319 14 10.871 13.448 10.871 12.7695V8.68254L8.43139 11.1222C8.35849 11.1951 8.27523 11.2512 8.18642 11.2909ZM5.73426 5.35656H2.62875C2.40223 5.35656 2.21859 5.17292 2.21859 4.9464C2.21859 4.71989 2.40223 4.53625 2.62875 4.53625H5.73426C5.96077 4.53625 6.14441 4.71989 6.14441 4.9464C6.14441 5.17295 5.96077 5.35656 5.73426 5.35656Z" fill="black" />
            <path d="M10.8616 5.21173L6.69124 9.38203L6.02624 10.8451C5.92167 11.0751 6.15831 11.3117 6.38832 11.2071L7.85135 10.5421L12.0217 6.37182L10.8616 5.21173Z" fill="black" />
            <path d="M13.1818 4.05161C12.8614 3.73125 12.342 3.73125 12.0217 4.05161L11.4417 4.63165L12.6018 5.79174L13.1818 5.2117C13.5021 4.89134 13.5021 4.37197 13.1818 4.05161Z" fill="black" />
        </svg>

    )
}