export enum EVENT_NAME {
  LISTING_VIEW = "listing_view",
  LISTING_CLICK = "listing_click",
  LISTING_DELETE_CLICK = "listing_delete_click",
  TAB_CHANGE = "tab_change",
  PAGE_VIEW = "page_view",
  RB_SESSION_START = "rb_session_start",
  PASSPORT_REQUEST = "passport_request",
  PASSPORT_UPDATE = "passport_update",
  LANDLORD_ADD_LISTING = "landlord_add_listing",
  NOTES_ADD = "notes_add",
  NOTES_UPDATED = "notes_updated",
  NOTES_DELETE = "notes_delete",
  FOLLOWUP_ADD = "followup_add",
  FOLLOWUP_UPDATED = "followup_updated",
  FOLLOWUP_DELETE = "followup_delete",
  FOLLOWUP_COMPLETED = "followup_completed",
  REQUEST_MATCHING_LISTINGS = "request_matching_listings",
  SERVICES_SEARCH = "services_search",
  SERVICES_LIST_CLICK = "services_list_click",
  RENTAL_SEARCH = "rental_search",
  REQUEST_SCREENING_CLICK = "request_screening_click",
  SHARE_LISTING_CLICK = "share_listing_click",
  LISTING_EDIT_CLICK = "listing_edit_click",
  ELIPSES_MENU_CLICK = "elipses_menu_click",
  SYNDICATE_CLICK = "syndicate_click",
  SYNDICATE_PUBLISH_CLICK = "syndicate_publish_click",
  SYNDICATE_UNPUBLISH_CLICK = "syndicate_unpublish_click",
  RENT_STATUS_CHANGE_CLICK = "rent_status_change_click",
  SHARE_LISTING = "listing_share",
  LOG_OUT = "log_out",
}

export enum EXTERNAL_SOURCES {
  rentbase = "rentbase",
  clawmls = "CLAW",
  palmspring = "PS",
  imperial = "IMPERIAL",
  realtorapi = "realtor",
}
