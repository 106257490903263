import { FormInstance } from "antd";
import { Rule } from "antd/es/form";
import { FormListFieldData } from "antd/es/form/FormList";
import { sanitizePhoneNumber } from "helpers/globalFunctions";
import { NOT_REQUIRED_EMAIL_VALIDATION } from "helpers/validations";

export const getTenantEmailInputRules = (
    field: FormListFieldData,
    form: FormInstance<any>
): Rule[] => [
        NOT_REQUIRED_EMAIL_VALIDATION,
        ({ getFieldValue }) => ({
            validator(_, value) {
                const tenantPhone = getFieldValue([
                    "tenants",
                    field.name,
                    "phone",
                ]);
                if (!value && !tenantPhone) {
                    return Promise.reject(
                        "Phone or email required",
                    );
                }
                return Promise.resolve();
            },
        }),
        ({ getFieldValue }) => ({
            validator(_, value) {
                const tenantEmail = getFieldValue([
                    "tenants",
                    field.name,
                    "email",
                ]);
                const repeatEmailCount =
                    form.getFieldValue("tenants").filter((e: any) => e.email === tenantEmail).length;
                if (tenantEmail && repeatEmailCount >= 2) {
                    return Promise.reject(
                        "Tenant email already exists!",
                    );
                }
                return Promise.resolve();
            },
        }),
    ];

export const getPhoneEmailInputRules = (
    field: FormListFieldData,
    form: FormInstance<any>
): Rule[] => [
        ({ getFieldValue }) => ({
            validator(_, value) {
                let sanitizedPhone = "";
                if (value) {
                    sanitizedPhone =
                        value && sanitizePhoneNumber(value);
                }
                const tenantEmail = getFieldValue([
                    "tenants",
                    field.name,
                    "email",
                ]);
                if (
                    sanitizedPhone.length === 10 ||
                    sanitizedPhone.length === 0
                ) {
                    return Promise.resolve();
                } else if (!value && !tenantEmail) {
                    return Promise.reject(
                        "Phone or email required",
                    );
                } else if (
                    sanitizedPhone.length > 0 &&
                    sanitizedPhone.length < 10
                ) {
                    return Promise.reject(
                        "Please input valid phone number",
                    );
                }

                return Promise.resolve();
            },
        }),
        ({ getFieldValue }) => ({
            validator(_, value) {

                const tenantPhone = +getFieldValue([
                    "tenants",
                    field.name,
                    "phone",
                ])?.replace(/\D/g, "");

                const repeatPhoneCount =
                    form.getFieldValue("tenants").filter((e: any) =>
                        +e.phone?.replace(/\D/g, "") === tenantPhone
                    ).length;

                if (tenantPhone && repeatPhoneCount >= 2) {
                    return Promise.reject(
                        "Tenant phone already exists!",
                    );
                }
                return Promise.resolve();
            },
        }),
    ];

