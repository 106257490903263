import React from "react";

export const ExclamationMarkIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_0_4826)">
                <path d="M40.0002 45.6415C38.5244 45.6415 37.3232 46.8427 37.3232 48.3185C37.3232 49.7949 38.5244 50.9955 40.0002 50.9955C41.4761 50.9955 42.6779 49.7949 42.6779 48.3185C42.6779 46.8427 41.4761 45.6415 40.0002 45.6415Z" fill="white" />
                <path d="M40.0002 14.7296C38.5244 14.7296 37.3232 15.9302 37.3232 17.4066V28.2355C37.3232 29.7119 38.5244 30.9125 40.0002 30.9125C41.4761 30.9125 42.6779 29.7119 42.6779 28.2355V17.406C42.6779 15.9302 41.4761 14.7296 40.0002 14.7296Z" fill="white" />
                <path d="M71.3806 0H8.62C3.86658 0 0 3.86658 0 8.62V54.5947C0 59.3475 3.86658 63.2141 8.62 63.2141H28.5101L35.097 76.3885C36.2445 78.6835 38.031 80 40 80C41.969 80 43.7555 78.6835 44.903 76.3885L51.4899 63.2147H71.3806C76.1334 63.2147 80 59.3481 80 54.5947V8.62C80 3.86658 76.1334 0 71.3806 0V0ZM40 55.683C35.9393 55.683 32.6355 52.3792 32.6355 48.3185C32.6355 44.2578 35.9393 40.954 40 40.954C44.0607 40.954 47.3651 44.2578 47.3651 48.3185C47.3651 52.3792 44.0607 55.683 40 55.683V55.683ZM47.3651 28.2355C47.3651 32.2961 44.0607 35.6 40 35.6C35.9393 35.6 32.6355 32.2961 32.6355 28.2355V17.406C32.6355 13.3453 35.9393 10.0415 40 10.0415C44.0607 10.0415 47.3651 13.3453 47.3651 17.406V28.2355Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_0_4826">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};
