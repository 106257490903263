import React from "react";

const VerifyEmailSuccessIcon = () =>{
    return(
        <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1_2441)">
                <path d="M65.6748 20.0977C62.7492 12.6534 53.7371 8.18909 46.104 11.1434C37.3911 14.5156 40.28 28.374 32.366 31.1764C28.091 32.6902 21.7714 27.0355 17.4521 26.1683C9.49311 24.5705 2.288 30.1863 0.513806 37.9631C-1.29624 45.8971 1.60687 56.4941 10.3239 58.6476C20.7076 61.2127 26.1164 51.8783 34.8272 49.1981C43.7662 46.4475 53.7054 52.9435 61.1056 45.0399C66.5729 39.2009 68.5718 27.4693 65.6748 20.0977Z" fill="url(#paint0_linear_1_2441)"/>
                <path opacity="0.1" d="M62.4001 23.3704C62.4001 18.2018 58.2101 14.0117 53.0414 14.0117C49.0371 14.0117 45.6218 16.5275 44.2856 20.064H10.4443C8.99886 20.064 7.82715 21.2357 7.82715 22.6812V51.6547C7.82715 53.1002 8.99886 54.2719 10.4443 54.2719H56.5559C58.0014 54.2719 59.1731 53.1002 59.1731 51.6547V30.4372C61.1489 28.7212 62.4001 26.1928 62.4001 23.3704Z" fill="#2626BC"/>
                <path d="M56.5559 51.0004H10.4443C8.99886 51.0004 7.82715 49.8287 7.82715 48.3832V19.4097C7.82715 17.9642 8.99886 16.7925 10.4443 16.7925H56.5559C58.0014 16.7925 59.1731 17.9642 59.1731 19.4097V48.3832C59.1731 49.8287 58.0014 51.0004 56.5559 51.0004Z" fill="white"/>
                <path d="M34.2974 29.2745L59.1732 48.3833C59.1732 49.8287 58.0015 51.0005 56.556 51.0005H10.4443C8.99886 51.0005 7.82715 49.8287 7.82715 48.3833L32.703 29.2745C33.1732 28.9134 33.8272 28.9134 34.2974 29.2745Z" fill="white"/>
                <path d="M32.703 38.5185L7.82715 19.4097C7.82715 17.9642 8.99886 16.7925 10.4443 16.7925H56.5559C58.0014 16.7925 59.1731 17.9642 59.1731 19.4097L34.2974 38.5185C33.8272 38.8796 33.1732 38.8796 32.703 38.5185Z" fill="white"/>
                <path d="M48.542 47.5181H44.3369C43.9755 47.5181 43.6826 47.2252 43.6826 46.8638V43.9647C43.6826 43.6034 43.9755 43.3104 44.3369 43.3104H48.542C48.9034 43.3104 49.1962 43.6034 49.1962 43.9647V46.8638C49.1962 47.2252 48.9034 47.5181 48.542 47.5181Z" fill="#5806B9"/>
                <path d="M40.8112 44.8508H37.1689C36.8075 44.8508 36.5146 44.5578 36.5146 44.1965C36.5146 43.8352 36.8075 43.5422 37.1689 43.5422H40.8112C41.1726 43.5422 41.4655 43.8352 41.4655 44.1965C41.4655 44.5578 41.1726 44.8508 40.8112 44.8508Z" fill="#2626BC"/>
                <path d="M40.8112 47.5179H28.0088C27.6474 47.5179 27.3545 47.225 27.3545 46.8636C27.3545 46.5023 27.6474 46.2094 28.0088 46.2094H40.8112C41.1726 46.2094 41.4655 46.5023 41.4655 46.8636C41.4655 47.2251 41.1726 47.5179 40.8112 47.5179Z" fill="#2626BC"/>
                <path d="M62.2673 21.5974C63.0943 16.4959 59.6291 11.69 54.5277 10.863C49.4262 10.036 44.6203 13.5011 43.7933 18.6026C42.9663 23.7041 46.4314 28.51 51.5329 29.337C56.6344 30.164 61.4403 26.6988 62.2673 21.5974Z" fill="#5806B9"/>
                <path d="M53.0414 30.1119C58.5625 30.1119 63.0544 25.6201 63.0544 20.0989C63.0544 14.5777 58.5626 10.0859 53.0414 10.0859C47.5202 10.0859 43.0285 14.5777 43.0285 20.0989C43.0285 23.3107 44.5502 26.1721 46.9088 28.0056L33.8988 37.9995C33.6644 38.1799 33.3365 38.18 33.1016 37.9995L8.505 19.1053C8.65169 18.167 9.46564 17.4468 10.4443 17.4468H40.459C40.8205 17.4468 41.1133 17.1538 41.1133 16.7925C41.1133 16.4312 40.8205 16.1382 40.459 16.1382H10.4443C8.64044 16.1382 7.17285 17.6058 7.17285 19.4097V48.3832C7.17285 50.1871 8.64044 51.6547 10.4443 51.6547H56.5559C58.3598 51.6547 59.8274 50.1871 59.8274 48.3832V31.0915C59.8274 30.7302 59.5345 30.4372 59.1731 30.4372C58.8117 30.4372 58.5188 30.7302 58.5188 31.0915V47.0555L41.3884 33.8965L48.0544 28.7759C49.5239 29.6239 51.2263 30.1119 53.0414 30.1119ZM25.612 33.8965L8.48144 47.0555V20.7374L25.612 33.8965ZM58.493 48.6859C58.3467 49.6247 57.5351 50.3461 56.5559 50.3461H10.4443C9.46511 50.3461 8.65353 49.6247 8.50722 48.6859L26.6861 34.7215L32.3044 39.0373C32.6564 39.3076 33.0783 39.4429 33.5002 39.4429C33.9221 39.4429 34.3438 39.3078 34.696 39.0373L40.3143 34.7214L58.493 48.6859ZM44.337 20.0989C44.337 15.2994 48.2418 11.3945 53.0414 11.3945C57.8411 11.3945 61.7458 15.2992 61.7458 20.0989C61.7458 24.8984 57.8411 28.8033 53.0414 28.8033C48.2418 28.8033 44.337 24.8984 44.337 20.0989Z" fill="#2626BC"/>
                <path d="M43.2921 10.6873C42.8061 10.6873 42.3202 10.5024 41.9504 10.1325C41.2105 9.39262 41.2105 8.18885 41.9504 7.4491C42.6899 6.70948 43.8939 6.70922 44.6337 7.4491C45.3736 8.18898 45.3736 9.39275 44.6337 10.1325C44.2639 10.5024 43.778 10.6873 43.2921 10.6873ZM43.2921 8.20219C43.1414 8.20219 42.9905 8.25951 42.8756 8.3744C42.6461 8.60393 42.6461 8.97754 42.8756 9.20719C43.1054 9.43672 43.4786 9.43685 43.7084 9.20719C43.9379 8.97767 43.9379 8.60406 43.7084 8.3744C43.5936 8.25964 43.4427 8.20219 43.2921 8.20219Z" fill="#5806B9"/>
                <path d="M28.5988 60.1056C28.1129 60.1056 27.6269 59.9205 27.2571 59.5507C26.5172 58.8108 26.5172 57.6071 27.2571 56.8673C27.9967 56.1276 29.2008 56.1276 29.9405 56.8673C30.6803 57.6072 30.6803 58.811 29.9405 59.5507C29.5706 59.9206 29.0846 60.1056 28.5988 60.1056ZM28.5988 57.6204C28.448 57.6204 28.2971 57.6777 28.1822 57.7926C27.9527 58.0221 27.9527 58.3957 28.1822 58.6254C28.412 58.8551 28.7854 58.8551 29.0151 58.6254C29.2447 58.3959 29.2447 58.0223 29.0151 57.7926C28.9004 57.6779 28.7495 57.6204 28.5988 57.6204Z" fill="#5806B9"/>
                <path d="M38.5752 12.3723C38.4078 12.3723 38.2402 12.3084 38.1126 12.1807L37.332 11.4003L36.5514 12.1807C36.2961 12.4362 35.8816 12.4362 35.6262 12.1807C35.3707 11.9252 35.3707 11.5109 35.6262 11.2554L36.8694 10.0123C37.1247 9.75682 37.5393 9.75682 37.7946 10.0123L39.0377 11.2554C39.2933 11.511 39.2933 11.9253 39.0377 12.1807C38.9102 12.3083 38.7427 12.3723 38.5752 12.3723Z" fill="#5806B9"/>
                <path d="M57.5449 57.6202C57.3775 57.6202 57.2099 57.5564 57.0823 57.4286L56.3017 56.6482L55.5212 57.4286C55.2658 57.6841 54.8513 57.6841 54.596 57.4286C54.3404 57.1731 54.3404 56.7588 54.596 56.5033L55.8391 55.2602C56.0944 55.0047 56.509 55.0047 56.7643 55.2602L58.0075 56.5033C58.2631 56.7589 58.2631 57.1732 58.0075 57.4286C57.8799 57.5562 57.7123 57.6202 57.5449 57.6202Z" fill="#5806B9"/>
                <path d="M23.4091 58.2418C23.2418 58.2418 23.0741 58.1779 22.9466 58.0502L22.166 57.2698L21.3854 58.0502C21.1301 58.3057 20.7155 58.3057 20.4602 58.0502C20.2047 57.7947 20.2047 57.3804 20.4602 57.1249L21.7034 55.8818C21.9587 55.6263 22.3733 55.6263 22.6286 55.8818L23.8717 57.1249C24.1273 57.3805 24.1273 57.7948 23.8717 58.0502C23.744 58.1778 23.5765 58.2418 23.4091 58.2418Z" fill="#5806B9"/>
                <path d="M53.1173 22.2219C53.5092 22.5982 53.5092 23.2433 53.1173 23.6195L52.3054 24.399C51.9134 24.7752 51.2415 24.7752 50.8495 24.399L47.294 20.9587C46.902 20.5825 46.902 19.9374 47.294 19.5611L48.1059 18.7817C48.4978 18.4054 49.1697 18.4054 49.5617 18.7817L53.1173 22.2219Z" fill="white"/>
                <path d="M56.2247 16.2822C56.6167 15.9059 57.2886 15.9059 57.6805 16.2822L58.4925 17.0616C58.8844 17.4379 58.8844 18.083 58.4925 18.4592L52.3332 24.3452C51.9412 24.7215 51.2693 24.7215 50.8774 24.3452L50.0654 23.5658C49.6735 23.1895 49.6735 22.5445 50.0654 22.1682L56.2247 16.2822Z" fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_1_2441" x1="56.575" y1="65.7757" x2="25.9539" y2="25.2095" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D2DEFF" stopOpacity="0"/>
                    <stop offset="1" stopColor="#B7C5FF"/>
                </linearGradient>
                <clipPath id="clip0_1_2441">
                    <rect width="67" height="67" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default VerifyEmailSuccessIcon