import React, { useEffect } from "react";
import { VerifyEmailIcon } from "components/icons/verify-email-icons/VerifyEmailIcon";
import "./SignUpVerifyMsg.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSignUpSuccess } from "../../../store/user/action";
import { AnalyticsService } from "helpers/analyticsService";
import { useLocation } from "react-router-dom";
import { resendConfirmationLink } from "../../../store/teams/action";
import { notConfirmedAgentIdSelector } from "../../../store/user/selectors";
import { push } from "connected-react-router";

const SignUpVerifyMsg = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const notConfirmedAgentId = useSelector(notConfirmedAgentIdSelector);
  const backToSignUpHandler = () => {
    if (location?.pathname === '/resetPassword') {
      dispatch(push(location?.pathname));
    }
    dispatch(setSignUpSuccess());
    AnalyticsService.reportSignUpProcess({ action: "verify_your_account_page_click", label: "back_button" });
  };
  useEffect(() => {
    return () => {
      dispatch(setSignUpSuccess());
    };
  }, []);
  useEffect(() => {
    if (location.search.includes("shared=true")) {
      localStorage.setItem("leadsPageRequested", `/leads/${location.search.substring(1)}`);
    }
  }, [location]);
  return (
    <div className={"sign-up-verify-msg-wrapper"}>
      <VerifyEmailIcon />
      <p className={"sign-up-verify-msg-wrapper--desc"}>
        Please verify your account through the link
        <br />
        emailed to you.
      </p>
      <span className={"sign-up-verify-msg-wrapper--resend"}>
        Didn’t get it?{" "}
        <span
          onClick={() => {
            AnalyticsService.reportSignUpProcess({ action: "verify_your_account_page_click", label: "send_again" });
            dispatch(resendConfirmationLink({ _id: notConfirmedAgentId }));
          }}
        >
          Send again
        </span>
      </span>
      <p className={"sign-up-verify-msg-wrapper--back"} onClick={backToSignUpHandler}>
        {"<"} Back
      </p>
    </div>
  );
};

export default SignUpVerifyMsg;
