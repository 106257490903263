import React from "react";
import { LuBath } from "react-icons/lu";

export const BathroomIconLead = ({ className }: { className?: string | undefined; }) => {
  return (
    <LuBath className={`${className}`} />
  );
};

export default BathroomIconLead;
