import React, { memo, useEffect, useState } from "react";
import "./index.scss";
import SwitchButton from "components/ListingView/ListingForm/SwitchButton";
import NotLookingModal from "./components/not-looking-modal/NotLookingModal";
import { useDispatch, useSelector } from "react-redux";
import { leadStatusRequirement } from "store/requirement/action";
import { singleLeadDataSelector } from "store/leads/selectors";
import { leadChangeStatusSuccessSelector } from "store/requirement/selectors";
import LookingModal from "./components/looking-modal/LookingModal";
import { AnalyticsService } from "helpers/analyticsService";

const SearchStatus = () => {
  const leadData = useSelector(singleLeadDataSelector);
  const [searchToggle, setSearchToggle] = useState(null as null | boolean);
  const [isNotLookingModalOpen, setIsNotLookingModalOpen] = useState(false);
  const [isLookingModalOpen, setIsLookingModalOpen] = useState(false);
  const dispatch = useDispatch();
  const leadChangeStatusSuccess = useSelector(leadChangeStatusSuccessSelector);

  useEffect(() => {
    if (leadData?.renter?.renter) {
      setSearchToggle(leadData?.renter?.renter.is_search_active);
    }
  }, [leadData]);

  useEffect(() => {
    if (leadChangeStatusSuccess && !searchToggle) {
      setIsNotLookingModalOpen(true);
    } else if (leadChangeStatusSuccess && searchToggle) {
      setIsLookingModalOpen(true);
    }
  }, [leadChangeStatusSuccess, searchToggle]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    AnalyticsService.reportRenterPageEvent(
      `search_status_changed_to_${checked ? "on" : "off"}`
    );
    setSearchToggle(checked);
    dispatch(
      leadStatusRequirement({
        renterId: leadData.renter.renter_id,
        search_active_data: { is_search_active: checked },
        requirements_id: leadData.requirement.id,
      })
    );
  };
  return (
    <>
      <div className="search-status">
        {searchToggle !== null && (
          <SwitchButton
            onChange={handleChange}
            label={"Search status"}
            name="search"
            checked={!searchToggle}
          />
        )}
      </div>
      <NotLookingModal
        toggle={isNotLookingModalOpen}
        setToggle={setIsNotLookingModalOpen}
      />
      <LookingModal
        toggle={isLookingModalOpen}
        setToggle={setIsLookingModalOpen}
      />
    </>
  );
};

export default memo(SearchStatus);
