import React from "react";
import RenterPortalServicesComp from "components/RenterPortal/RentalPortalServices";

const RenterPortalServices = ({ user }: { user: any }) => {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <RenterPortalServicesComp />
    </div>
  );
};
export default RenterPortalServices;
