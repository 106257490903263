import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";

// SHAREABLE LANDLORD ACTIONS
export const createShareableLandlordEntity = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_LANDLORD_ENTITY,
  payload
});
export const createShareableLandlordEntitySuccess = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_LANDLORD_ENTITY_SUCCESS,
  payload
});
export const createShareableLandlordEntityFail = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_LANDLORD_ENTITY_FAIL,
  payload
});

export const getShareableLandlordEntity = (payload: IObjectKeys) => ({
  type: types.GET_SHAREABLE_LANDLORD_ENTITY,
  payload
});
export const getShareableLandlordEntitySuccess = (payload: IObjectKeys) => ({
  type: types.GET_SHAREABLE_LANDLORD_ENTITY_SUCCESS,
  payload
});
export const getShareableLandlordEntityFail = (payload: IObjectKeys) => ({
  type: types.GET_SHAREABLE_LANDLORD_ENTITY_FAIL,
  payload
});

// SHAREABLE PROPERTY ACTIONS
export const createShareablePropertyEntity = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_PROPERTY_ENTITY,
  payload
});
export const createShareablePropertyEntitySuccess = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_PROPERTY_ENTITY_SUCCESS,
  payload
});
export const createShareablePropertyEntityFail = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_PROPERTY_ENTITY_FAIL,
  payload
});

export const updateShareablePropertyEntity = (payload: IObjectKeys) => ({
  type: types.UPDATE_SHAREABLE_PROPERTY_ENTITY,
  payload
});
export const updateShareablePropertyEntitySuccess = (payload: IObjectKeys) => ({
  type: types.UPDATE_SHAREABLE_PROPERTY_ENTITY_SUCCESS,
  payload
});
export const updateShareablePropertyEntityFail = (payload: IObjectKeys) => ({
  type: types.UPDATE_SHAREABLE_PROPERTY_ENTITY_FAIL,
  payload
});

export const getShareablePropertyEntity = (payload: IObjectKeys) => ({
  type: types.GET_SHAREABLE_PROPERTY_ENTITY,
  payload
});
export const getShareablePropertyEntitySuccess = (payload: IObjectKeys) => ({
  type: types.GET_SHAREABLE_PROPERTY_ENTITY_SUCCESS,
  payload
});
export const getShareablePropertyEntityFail = (payload: IObjectKeys) => ({
  type: types.GET_SHAREABLE_PROPERTY_ENTITY_FAIL,
  payload
});
export function clearShareablePropertyEntity() {
  return { type: types.CLEAR_SHAREABLE_PROPERTY_ENTITY };
}

// SHAREABLE SCREENING REQUEST ACTIONS
export const createShareableScreeningRequestEntity = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY,
  payload
});
export const createShareableScreeningRequestEntitySuccess = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY_SUCCESS,
  payload
});
export const createShareableScreeningRequestEntityFail = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY_FAIL,
  payload
});

export function clearShareableScreeningEntity() {
  return { type: types.CLEAR_SHAREABLE_SCREENING_ENTITY };
}

export function sendScreeningRequestToRenter(payload: IObjectKeys) {
  return { type: types.SEND_SCREENING_REQUEST_TO_RENTER, payload };
}

export const sendScreeningRequestToRenterSuccess = (payload: IObjectKeys) => ({
  type: types.SEND_SCREENING_REQUEST_TO_RENTER_SUCCESS,
  payload
});
export const sendScreeningRequestToRenterFail = (payload: IObjectKeys) => ({
  type: types.SEND_SCREENING_REQUEST_TO_RENTER_FAIL,
  payload
});

// SHAREABLE RENTER ACTIONS
export const createShareableRenterEntity = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_RENTER_ENTITY,
  payload
});
export const createShareableRenterEntitySuccess = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_RENTER_ENTITY_SUCCESS,
  payload
});
export const createShareableRenterEntityFail = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_RENTER_ENTITY_FAIL,
  payload
});
export const clearShareableRenterEntity = () => ({
  type: types.CLEAR_SHAREABLE_RENTER_ENTITY,
});

// SHAREABLE EXAM ACTIONS
export const createShareableVerificationExamEntity = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY,
  payload
});

export const createShareableVerificationExamEntitySuccess = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY_SUCCESS,
  payload
});

export const createShareableVerificationExamEntityFail = (payload: IObjectKeys) => ({
  type: types.CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY_FAIL,
  payload
});

export const postShareableVerificationExamAnswers = (payload: IObjectKeys) => ({
  type: types.POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS,
  payload
});

export const postShareableVerificationExamAnswersSuccess = (payload: IObjectKeys) => ({
  type: types.POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS_SUCCESS,
  payload
});

export const postShareableVerificationExamAnswersFail = (payload: IObjectKeys) => ({
  type: types.POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS_FAIL,
  payload
});

export function clearVerificationExamAnswersEntity() {
  return { type: types.CLEAR_SHAREABLE_VERIFICATION_EXAM_ANSWERS };
};

export const validateRenter = (payload: IObjectKeys) => ({
  type: types.VALIDATE_RENTER,
  payload,
});

export const validateRenterSuccess = (payload: IObjectKeys) => ({
  type: types.VALIDATE_RENTER_SUCCESS,
  payload,
});

export const validateRenterFail = (payload: any) => ({
  type: types.VALIDATE_RENTER_FAIL,
  payload,
});

export const clearValidateRenterSuccess = () => ({
  type: types.CLEAR_VALIDATE_RENTER,
});

export const saveEmailScreeningLinkData = (payload: any) => ({
  type: types.SAVE_EMAIL_SCREENING_LINK_DATA,
  payload
});

export const clearEmailScreeningLinkData = () => ({
  type: types.CLEAR_EMAIL_SCREENING_LINK_DATA,
});
