import React from "react";
import { IFilterOptionsOptionalKeys } from "../../../Listings/helpers/mockListings";
import { IObjectKeys } from "../../../../helpers/globalTypes";
import { List } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { routerSelector } from "../../../../store/listings/selectors";
import LandlordsItemCard from "./components/LandlordsItemCard";
import "./LandlordsItemCardWrapper.scss";
import { setLandlordsQueryParams } from "../../../../store/landlords/action";
interface ILandlordsItemCard {
  paginationValues: any;
  filterOptions?: IFilterOptionsOptionalKeys;
  total: number;
  tableValues: Array<IObjectKeys>;
  viewType?: string;
  tableClassName?: string;
  showingCount?: number | null;
  setShowingCount?: any;
  onRow?: (record: any, rowIndex: any) => any;
  id?: string;
  setLandlordActionModalVisible: any
}
const LandlordsItemCardWrapper = ({
  paginationValues,
  total,
  tableValues,
  showingCount,
  setLandlordActionModalVisible
}: ILandlordsItemCard) => {
  const router = useSelector(routerSelector);
  const dispatch = useDispatch();
  const pageOrCurrent: string = router?.location?.pathname?.includes(
    "/listings",
  )
    ? "current"
    : "page";
  return (
    <div className={"landlords-mobile-item-wrapper"}>
      <List
        data-test-id="leads-list-container"
        className="leads-list-wrapper scrollbar"
        id="leadsWrapperList"
        pagination={{
          current: paginationValues.page,
          pageSize:
            showingCount !== 24 ? showingCount : paginationValues.pageSize,
          total,
          showSizeChanger: false,
          onChange: (page) => {
            dispatch(
              setLandlordsQueryParams({
                ...paginationValues,
                page: page,
              })
            );
          },
        }}
        dataSource={tableValues}
        renderItem={(e: any, i: any) => (
          <List.Item>
            <React.Fragment key={i}>
              <LandlordsItemCard
                elem={e}
                setLandlordActionModalVisible={setLandlordActionModalVisible}
              />
            </React.Fragment>
          </List.Item>
        )}
      />
    </div>
  );
};

export default LandlordsItemCardWrapper;
