import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";
export interface AreaInterface {
  parent_id?: string | undefined;
  name?: string;
  children?: AreaInterface[];
  slug?: string;
  state?: string;
}
interface areasStateType extends IObjectKeys {
  areas: AreaInterface[];
}
const initialState: areasStateType = {
  areas: [],
  areasLoading: false,
  areasError: null,
  areasFacets: [],
  areasFacetsLoading: false,
};

export default function enumReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.GET_AREAS:
      return {
        ...state,
        areasLoading: true,
        areasError: null,
      };
    case types.GET_AREAS_SUCCESS:
      return {
        ...state,
        areas: [...action.payload],
        areasLoading: false,
      };
    case types.GET_AREAS_FAIL:
      return {
        ...state,
        areasError: action.payload,
        areasLoading: false,
      };
    case types.GET_AREAS_FACETS:
      return {
        ...state,
        areasFacetsLoading: true,
      };
    case types.GET_AREAS_FACETS_FAIL:
      return {
        ...state,
        areasFacetsLoading: false,
      };
    case types.GET_AREAS_FACETS_SUCCESS:
      return {
        ...state,
        areasFacets: action.payload,
        areasLoading: false,
        areasFacetsLoading: false,
        areasError: null,
      };
    default:
      return state;
  }
}
