export default [
  {
    "order": 0,
    "allow_null": true,
    "key": "pets_allowed",
    "label": "Pet Policy",
    "type": "multi",
    "_id": "64ad8b28ea8f41cb53989fbf",
    "options": [
      {
        "cats": "Cats"
      },
      {
        "small_dogs": "Small Dogs"
      },
      {
        "large_dogs": "Large Dogs"
      }
    ],
    "created_at": "2023-07-11T17:02:32.962Z",
    "updated_at": "2023-07-11T17:02:32.962Z",
    "id": "64ad8b28ea8f41cb53989fbf"
  },
  {
    "allow_null": false,
    "key": "elevator",
    "label": "Elevator Building",
    "type": "boolean",
    "_id": "64ad8b28ea8f41cb53989fc0",
    "options": [
      {
        "FALSE": "Walk-Up"
      },
      {
        "TRUE": "Elevator Building"
      }
    ],
    "created_at": "2023-07-11T17:02:32.975Z",
    "updated_at": "2023-07-11T17:02:32.975Z",
    "id": "64ad8b28ea8f41cb53989fc0"
  },
  {
    "allow_null": false,
    "key": "furnished",
    "label": "Furnished",
    "type": "boolean",
    "_id": "64ad8b28ea8f41cb53989fc1",
    "options": [
      {
        "FALSE": "Not Furnished"
      },
      {
        "TRUE": "Furnished"
      }
    ],
    "created_at": "2023-07-11T17:02:32.980Z",
    "updated_at": "2023-07-11T17:02:32.980Z",
    "id": "64ad8b28ea8f41cb53989fc1"
  },
  {
    "allow_null": false,
    "key": "unit_type",
    "label": "Unit Type",
    "type": "single",
    "_id": "64ad8b28ea8f41cb53989fc2",
    "options": [
      {
        "condo": "Condo"
      },
      {
        "apartment": "Apartment"
      },
      {
        "townhouse": "Townhouse"
      },
      {
        "single_family": "Single Family"
      },
      {
        "co-op": "Co-op"
      },
      {
        "loft": "Loft"
      },
      {
        "other": "Other"
      }
    ],
    "created_at": "2023-07-11T17:02:32.983Z",
    "updated_at": "2023-07-11T17:02:32.983Z",
    "id": "64ad8b28ea8f41cb53989fc2"
  },
  {
    "allow_null": true,
    "key": "source",
    "label": "Source",
    "type": "single",
    "_id": "64ad8b28ea8f41cb53989fc3",
    "options": [
      {
        "auto": "System Generate"
      },
      {
        "phone_follow_up": "Phone Follow Up"
      },
      {
        "email_follow_up": "Email Follow Up"
      },
      {
        "sms_follow_up": "SMS Follow Up"
      }
    ],
    "created_at": "2023-07-11T17:02:32.987Z",
    "updated_at": "2023-07-11T17:02:32.987Z",
    "id": "64ad8b28ea8f41cb53989fc3"
  },
  {
    "order": 6,
    "allow_null": true,
    "key": "heating",
    "label": "Heating Type",
    "type": "single",
    "_id": "64ad8b28ea8f41cb53989fc4",
    "options": [
      {
        "gas": "Gas"
      },
      {
        "oil": "Oil"
      },
      {
        "electric": "Electric"
      }
    ],
    "created_at": "2023-07-11T17:02:32.990Z",
    "updated_at": "2023-07-11T17:02:32.990Z",
    "id": "64ad8b28ea8f41cb53989fc4"
  },
  {
    "allow_null": true,
    "key": "utilities",
    "label": "Utilities Included",
    "type": "multi",
    "_id": "64ad8b28ea8f41cb53989fc5",
    "options": [
      {
        "heat": "Heat"
      },
      {
        "hot_water": "Hot Water"
      },
      {
        "cold_water": "Cold Water"
      },
      {
        "sewer": "Sewer"
      },
      {
        "electricity": "Electricity"
      },
      {
        "yard_maintenance": "Yard Maintenance"
      },
      {
        "snow_removal": "Snow Removal"
      },
      {
        "trash_removal": "Trash Removal"
      },
      {
        "cooking_gas": "Cooking Gas"
      },
      {
        "cable_tv": "Cable TV"
      }
    ],
    "created_at": "2023-07-11T17:02:32.995Z",
    "updated_at": "2023-07-11T17:02:32.995Z",
    "id": "64ad8b28ea8f41cb53989fc5"
  },
  {
    "allow_null": true,
    "key": "storage",
    "label": "Additional Storage",
    "type": "multi",
    "_id": "64ad8b29ea8f41cb53989fc6",
    "options": [
      {
        "basement_storage": "Basement"
      },
      {
        "attic": "Attic"
      },
      {
        "storage_room": "Storage Room"
      }
    ],
    "created_at": "2023-07-11T17:02:33.000Z",
    "updated_at": "2023-07-11T17:02:33.000Z",
    "id": "64ad8b29ea8f41cb53989fc6"
  },
  {
    "allow_null": false,
    "key": "procedure",
    "label": "Showing Procedures",
    "type": "multi",
    "_id": "64ad8b29ea8f41cb53989fc7",
    "options": [
      {
        "call_landlord": "Contact Landlord"
      },
      {
        "call_tenants": "Contact Tenants"
      },
      {
        "call_manager": "Contact Property Manager"
      },
      {
        "lockbox": "Lockbox"
      },
      {
        "key": "Key Number"
      },
      {
        "other": "Other/ Special Instructions"
      }
    ],
    "created_at": "2023-07-11T17:02:33.004Z",
    "updated_at": "2023-07-11T17:02:33.004Z",
    "id": "64ad8b29ea8f41cb53989fc7"
  },
  {
    "allow_null": false,
    "key": "todo_status",
    "label": "Follow-Up Status",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fc8",
    "options": [
      {
        "todo": "To-Do"
      },
      {
        "done": "Done"
      },
      {
        "overdue": "Past Due"
      }
    ],
    "created_at": "2023-07-11T17:02:33.008Z",
    "updated_at": "2023-07-11T17:02:33.008Z",
    "id": "64ad8b29ea8f41cb53989fc8"
  },
  {
    "allow_null": true,
    "key": "amenities",
    "label": "Building Amenities",
    "type": "multi",
    "_id": "64ad8b29ea8f41cb53989fc9",
    "options": [
      {
        "gym": "Gym"
      },
      {
        "doorman": "Doorman"
      },
      {
        "req_room": "Req Room"
      },
      {
        "bike_room": "Bike Room"
      },
      {
        "package_room": "Package Room"
      },
      {
        "business_center": "Business Center"
      },
      {
        "guest_parking": "Guest Parking"
      },
      {
        "maintenance": "On-site Maintenance"
      },
      {
        "outdoor_grill": "Outdoor Grilling Area"
      },
      {
        "sauna": "Sauna"
      },
      {
        "car_charging": "Electric Car Charging Station"
      },
      {
        "concierge": "Concierge"
      },
      {
        "valet": "Valet Parking"
      },
      {
        "storage_room": "Storage Room"
      },
      {
        "library": "Library"
      },
      {
        "day_care": "Day Care"
      },
      {
        "professionally_managed_building": "Prof-Managed Building"
      },
      {
        "garbage_disposal": "Garbage Disposal"
      },
      {
        "trash_compactor": "Trash Compactor"
      },
      {
        "wine_cooler": "Wine Cooler"
      },
      {
        "walk_in_closet": "Walk-In Closet"
      },
      {
        "cooking_hood": "Cooking Hood"
      },
      {
        "built_in_microwave": "Built-In Microwave"
      },
      {
        "jacuzzi_bath": "Jacuzzi Bath"
      },
      {
        "finished_basement": "Finished Basement"
      },
      {
        "bidet_toilet": "Bidet Toilet"
      },
      {
        "bathtub_shower": "Bathtub Shower"
      },
      {
        "granite_counters": "Granite Counters"
      },
      {
        "stainless_steel_appliances": "Stainless Steel appliances"
      },
      {
        "floor_to_ceiling_windows": "Floor to Ceiling Windows"
      },
      {
        "bay_windows": "Bay Windows"
      },
      {
        "private_elevator_entrance": "Private Elevator Entrance"
      }
    ],
    "created_at": "2023-07-11T17:02:33.012Z",
    "updated_at": "2023-07-11T17:02:33.012Z",
    "id": "64ad8b29ea8f41cb53989fc9"
  },
  {
    "allow_null": false,
    "key": "outdoor_space",
    "label": "Outdoor Space",
    "type": "multi",
    "_id": "64ad8b29ea8f41cb53989fca",
    "options": [
      {
        "private_deck": "Private Deck"
      },
      {
        "private_roof_deck": "Private Roof Deck"
      },
      {
        "private_patio": "Private Patio"
      },
      {
        "private_yard": "Private Yard"
      },
      {
        "shared_roof_deck": "Shared Roof Deck"
      },
      {
        "shared_patio": "Shared Patio"
      },
      {
        "shared_yard": "Shared Yard"
      },
      {
        "fenced_in": "Fenced-In Yard"
      }
    ],
    "created_at": "2023-07-11T17:02:33.016Z",
    "updated_at": "2023-07-11T17:02:33.016Z",
    "id": "64ad8b29ea8f41cb53989fca"
  },
  {
    "allow_null": false,
    "key": "created_by",
    "label": "Created by",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fcb",
    "options": [
      {
        "admin": "admin"
      },
      {
        "signup_web": "signup-web"
      },
      {
        "signup_mobile": "signup-mobile"
      }
    ],
    "created_at": "2023-07-11T17:02:33.020Z",
    "updated_at": "2023-07-11T17:02:33.020Z",
    "id": "64ad8b29ea8f41cb53989fcb"
  },
  {
    "order": 1,
    "allow_null": false,
    "key": "parking_type",
    "label": "Parking Type",
    "type": "multi",
    "_id": "64ad8b29ea8f41cb53989fcc",
    "options": [
      {
        "garage": "Garage"
      },
      {
        "driveway": "Driveway"
      },
      {
        "parking_lot": "Parking lot"
      },
      {
        "street": "Street parking"
      },
      {
        "tendem": "Tandem"
      }
    ],
    "created_at": "2023-07-11T17:02:33.024Z",
    "updated_at": "2023-07-11T17:02:33.024Z",
    "id": "64ad8b29ea8f41cb53989fcc"
  },
  {
    "allow_null": true,
    "key": "floor",
    "label": "Floor",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fcd",
    "options": [
      {
        "basement": "Basement"
      },
      {
        "ground": "Ground Floor"
      },
      {
        "penthouse": "Penthouse"
      },
      {
        "other": "Other"
      }
    ],
    "created_at": "2023-07-11T17:02:33.040Z",
    "updated_at": "2023-07-11T17:02:33.040Z",
    "id": "64ad8b29ea8f41cb53989fcd"
  },
  {
    "order": 4,
    "allow_null": false,
    "key": "dishwasher",
    "label": "Dishwasher",
    "type": "boolean",
    "_id": "64ad8b29ea8f41cb53989fce",
    "created_at": "2023-07-11T17:02:33.104Z",
    "updated_at": "2023-07-11T17:02:33.104Z",
    "id": "64ad8b29ea8f41cb53989fce"
  },
  {
    "allow_null": true,
    "key": "move_in_fees",
    "label": "Move-In Fees",
    "type": "multi",
    "_id": "64ad8b29ea8f41cb53989fcf",
    "options": [
      {
        "first_month": "1st Months Rent"
      },
      {
        "last_month": "Last Months Rent"
      },
      {
        "security_deposit": "Security Deposit"
      },
      {
        "pet_deposit": "Pet Deposit"
      }
    ],
    "created_at": "2023-07-11T17:02:33.137Z",
    "updated_at": "2023-07-11T17:02:33.137Z",
    "id": "64ad8b29ea8f41cb53989fcf"
  },
  {
    "order": 5,
    "allow_null": true,
    "key": "flooring",
    "label": "Flooring",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fd0",
    "options": [
      {
        "hardwood": "All Hardwood"
      },
      {
        "carpet": "All Carpeted"
      },
      {
        "mixed_floors": "Mixed"
      },
      {
        "other": "Other"
      }
    ],
    "created_at": "2023-07-11T17:02:33.145Z",
    "updated_at": "2023-07-11T17:02:33.145Z",
    "id": "64ad8b29ea8f41cb53989fd0"
  },
  {
    "allow_null": false,
    "key": "status",
    "label": "Status",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fd1",
    "options": [
      {
        "off-market": "Off-market"
      },
      {
        "available": "Available"
      },
      {
        "archived": "Archived"
      },
      {
        "deleted": "Deleted"
      }
    ],
    "created_at": "2023-07-11T17:02:33.149Z",
    "updated_at": "2023-07-11T17:02:33.149Z",
    "id": "64ad8b29ea8f41cb53989fd1"
  },
  {
    "order": 2,
    "allow_null": true,
    "key": "laundry",
    "label": "Laundry",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fd2",
    "options": [
      {
        "hook_ups": "Hook-Ups"
      },
      {
        "in_unit": "In-Unit"
      },
      {
        "free": "Free - In Basement/Building"
      },
      {
        "coin_op": "Coin-Op - In Basement/Building"
      },
      {
        "card_op": "Card/App Pay - In Basement/Building"
      },
      {
        "laundry_room": "Laundry Room"
      },
      {
        "other": "Other"
      }
    ],
    "created_at": "2023-07-11T17:02:33.153Z",
    "updated_at": "2023-07-11T17:02:33.153Z",
    "id": "64ad8b29ea8f41cb53989fd2"
  },
  {
    "allow_null": true,
    "key": "broker_fees",
    "label": "Broker Fees",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fd3",
    "options": [
      {
        "landlord_pays": "Landlord Pays the Full Fee"
      },
      {
        "tenant_pays": "Tenant Pays Full Fee"
      },
      {
        "split_fee": "Landlord and Tenants Split Fee 50/50"
      },
      {
        "other": "Other"
      }
    ],
    "created_at": "2023-07-11T17:02:33.160Z",
    "updated_at": "2023-07-11T17:02:33.160Z",
    "id": "64ad8b29ea8f41cb53989fd3"
  },
  {
    "allow_null": false,
    "key": "students_accepted",
    "label": "Students Accepted",
    "type": "boolean",
    "_id": "64ad8b29ea8f41cb53989fd4",
    "options": [
      {
        "FALSE": "Student not accepted"
      },
      {
        "TRUE": "Students Accepted"
      }
    ],
    "created_at": "2023-07-11T17:02:33.164Z",
    "updated_at": "2023-07-11T17:02:33.164Z",
    "id": "64ad8b29ea8f41cb53989fd4"
  },
  {
    "allow_null": true,
    "key": "showing_procedure",
    "label": "Showing Procedures",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fd5",
    "options": [
      {
        "call_landlord": "Contact Landlord"
      },
      {
        "call_tenants": "Contact Tenats"
      },
      {
        "lockbox": "Lockbox"
      },
      {
        "key": "Key Number"
      }
    ],
    "created_at": "2023-07-11T17:02:33.167Z",
    "updated_at": "2023-07-11T17:02:33.167Z",
    "id": "64ad8b29ea8f41cb53989fd5"
  },
  {
    "allow_null": false,
    "key": "listings_of_landlord",
    "label": "Listings of Landlord",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fd6",
    "options": [
      {
        "show-all": "Show-all"
      },
      {
        "available": "Available"
      },
      {
        "off-market": "Off-market"
      }
    ],
    "created_at": "2023-07-11T17:02:33.171Z",
    "updated_at": "2023-07-11T17:02:33.171Z",
    "id": "64ad8b29ea8f41cb53989fd6"
  },
  {
    "order": 7,
    "allow_null": false,
    "key": "air_conditioning",
    "label": "Air Conditioning Type",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fd7",
    "options": [
      {
        "central_ac": "Central AC"
      },
      {
        "window_units_provided": "Window Units Provided"
      },
      {
        "none_provided": "None Provided"
      }
    ],
    "created_at": "2023-07-11T17:02:33.175Z",
    "updated_at": "2023-07-11T17:02:33.175Z",
    "id": "64ad8b29ea8f41cb53989fd7"
  },
  {
    "order": 0,
    "allow_null": false,
    "key": "pet_policy",
    "label": "Pet Policy",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fd8",
    "options": [
      {
        "allowed": "Allowed"
      },
      {
        "not_allowed": "Not Allowed"
      },
      {
        "negotiable": "Contact Agent"
      }
    ],
    "created_at": "2023-07-11T17:02:33.178Z",
    "updated_at": "2023-07-11T17:02:33.178Z",
    "id": "64ad8b29ea8f41cb53989fd8"
  },
  {
    "order": 3,
    "allow_null": true,
    "key": "fireplace",
    "label": "Fireplace",
    "type": "single",
    "_id": "64ad8b29ea8f41cb53989fd9",
    "options": [
      {
        "gas_fireplace": "Gas Fireplace"
      },
      {
        "wood_fireplace": "Wood Fireplace"
      },
      {
        "aesthetic_only": "Aesthetic Only"
      },
      {
        "other": "Other"
      },
      {
        "none": "None"
      }
    ],
    "created_at": "2023-07-11T17:02:33.182Z",
    "updated_at": "2023-07-11T17:02:33.182Z",
    "id": "64ad8b29ea8f41cb53989fd9"
  },
  {
    "allow_null": true,
    "key": "stove_type",
    "label": "Stove Type",
    "type": "multi",
    "_id": "64ad8b29ea8f41cb53989fda",
    "options": [
      {
        "induction_stove": "Induction Stove"
      },
      {
        "gas_stove": "Gas Stove"
      },
      {
        "electric_stove": "Electric Stove"
      }
    ],
    "created_at": "2023-07-11T17:02:33.186Z",
    "updated_at": "2023-07-11T17:02:33.186Z",
    "id": "64ad8b29ea8f41cb53989fda"
  },
  {
    "allow_null": true,
    "key": "pool_type",
    "label": "Pool",
    "type": "multi",
    "_id": "64ad8b29ea8f41cb53989fdb",
    "options": [
      {
        "indoor_pool": "Indoor Pool"
      },
      {
        "outdoor_pool": "Outdoor Pool"
      }
    ],
    "created_at": "2023-07-11T17:02:33.189Z",
    "updated_at": "2023-07-11T17:02:33.189Z",
    "id": "64ad8b29ea8f41cb53989fdb"
  }
];