import React from "react";
import { useDemoContext } from "../../../libs/contextLib";
import TailwindSpinner from "components/icons/TailwindSpinner";

interface IBaseButtonInterface {
  id?: string;
  type?: string;
  classnames?: string;
  disabled?: boolean;
  className?: string;
  variant?: string;
  href?: string;
  children?: string | JSX.Element;
  icon?: any;
  onClick?: React.MouseEventHandler<HTMLElement>;
  target?: string;
  loading?: boolean | { delay: number } | undefined;
  dataTestId?: string | undefined;
  dataId?: any;
  size?: string;
  modalType?: string;
  modalClose?: () => void;
  style?: any;
  iconPosition?: string;
}

const BaseButton = (props: IBaseButtonInterface) => {
  const { isDemo, demoAction } = useDemoContext();

  const demoHandleClick = () => {
    props.modalClose && props.modalClose();
    demoAction(props.modalType);
  };

  let sizeCss = "px-6 py-4 h-12 text-base ";
  switch (props.size) {
    case "xs":
      sizeCss = "p-1 px-2 h-6 text-xs";
      break;
    case "sm":
      sizeCss = "p-2 px-3 h-8 text-sm";
      break;
    case "lg":
      sizeCss = "p-5 px-6 h-14 text-xl";
      break;
    default:
      if (props.size) {
        sizeCss = props.size;
      }
      break;
  }
  const text = `${props.disabled ? "pointer-events-none text-gray-400 ring-gray-100 bg-white " : "hover:text-purple-800 text-purple-900"}`;

  const ringColor = `${props.disabled ? "ring-gray-200 " : "ring-purple-400"}`;

  let variantCss = ` flex justify-center items-center rounded-md hover:bg-purple-100 ring-1 ring-inset ${ringColor} ${text} `;
  switch (props.variant) {
    case "link":
      variantCss = " text-purple-800 ";
      sizeCss = "";
      break;
    case "primary":
      variantCss = " flex justify-center items-center rounded-md hover:text-purple-50 text-white bg-purple-700 hover:bg-purple-800";
      break;
    case "secondary":
      variantCss = " flex justify-center items-center rounded-md hover:text-purple-700 text-purple-800 bg-purple-200 hover:bg-purple-300";
      break;
    default:
      break;
  }

  return props.type === "submit" ? (
    <button
      id={props.id}
      type="submit"
      className={`${sizeCss} ${variantCss} ${props?.className}`}
      disabled={props.disabled}
      onClick={isDemo ? demoHandleClick : props.onClick}
      style={props.disabled ? { background: "#a692c1" } : {}}
    >
      {props.iconPosition === "left" && props.icon && !props.loading && <div className="mr-2">{props.icon}</div>}
      {props.loading && (
        <div className="mr-2 transition ease-in-out delay-150">
          <TailwindSpinner />
        </div>
      )}
      <>{props.children}</>
      {props.iconPosition === "right" && props.icon && !props.loading && <div className="ml-2">{props.icon}</div>}
    </button>
  ) : (
    <a
      id={props.id}
      onClick={isDemo ? demoHandleClick : props.onClick}
      href={props.href}
      className={`${sizeCss} ${variantCss} ${props?.className}`}
      data-test-id={props?.dataTestId}
      target={props.target}
      data-id={props?.dataId}
    >
      <div className="flex gap-1 justify-left items-center">
        {(!props.iconPosition || props.iconPosition === "left") && props.icon && <div>{props.icon}</div>}
        {props.loading && (
          <div className="mr-1 transition ease-in-out delay-150">
            <TailwindSpinner dark={props.variant === "primary" ? false : true} />
          </div>
        )}
        {props.children}
        {props.iconPosition === "right" && props.icon && <div>{props.icon}</div>}
      </div>
    </a>
  );
};

export default React.memo(BaseButton);
