import DateColumn from "components/Shared/Columns/DateColumn";
import PropertyLinkColumn from "components/Shared/Columns/PropertyLinkColumn";
import ScreeningStatusColumn from "components/Shared/Columns/ScreeningStatusColumn";
import React from "react";
import { fieldDto, IObjectKeys } from "../../../../helpers/globalTypes";
import { LeadScreeningRequestActionColumn } from "../RequestScreeningModal/components/LeadScreeningReqAction";

export const LEAD_SR_REPORTS_COLUMNS = () => [
  {
    dataIndex: "_id",
    key: "propertyName",
    title: "Property",
    render: PropertyLinkColumn,
  },
  {
    dataIndex: "screening_status",
    key: "Status",
    title: "Status",
    width: "200px",
    render: (_: string, rowData: any) => ScreeningStatusColumn(rowData),
  },
  {
    dataIndex: "created_at",
    key: "Created",
    title: "Created",
    width: "200px",
    render: DateColumn,
  },
  {
    dataIndex: "action",
    key: "Actions",
    title: "",
    width: "200px",
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      return {
        children: <LeadScreeningRequestActionColumn rowData={rowData} />,
      };
    },
  },
];
