import { message } from 'antd';
import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import {
  CLEAR_EMAIL_SCREENING_LINK_DATA,
  CREATE_SHAREABLE_LANDLORD_ENTITY,
  CREATE_SHAREABLE_PROPERTY_ENTITY,
  CREATE_SHAREABLE_RENTER_ENTITY,
  CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY,
  CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY,
  GET_SHAREABLE_LANDLORD_ENTITY,
  GET_SHAREABLE_PROPERTY_ENTITY,
  POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS,
  SAVE_EMAIL_SCREENING_LINK_DATA,
  SEND_SCREENING_REQUEST_TO_RENTER,
  UPDATE_SHAREABLE_PROPERTY_ENTITY,
  VALIDATE_RENTER
} from "./types";
import {
  createShareableLandlordEntity,
  createShareableLandlordEntitySuccess,
  createShareableLandlordEntityFail,
  getShareableLandlordEntity,
  getShareableLandlordEntitySuccess,
  getShareableLandlordEntityFail,
  createShareablePropertyEntitySuccess,
  createShareablePropertyEntityFail,
  getShareablePropertyEntityFail,
  getShareablePropertyEntitySuccess,
  createShareableScreeningRequestEntitySuccess,
  createShareableScreeningRequestEntityFail,
  sendScreeningRequestToRenter,
  updateShareablePropertyEntity,
  updateShareablePropertyEntitySuccess,
  updateShareablePropertyEntityFail,
  createShareableRenterEntitySuccess,
  createShareableRenterEntityFail,
  createShareableVerificationExamEntitySuccess,
  createShareableVerificationExamEntityFail,
  postShareableVerificationExamAnswersSuccess,
  postShareableVerificationExamAnswersFail,
  validateRenterFail,
  validateRenterSuccess,
  sendScreeningRequestToRenterSuccess,
  sendScreeningRequestToRenterFail,
  saveEmailScreeningLinkData,
  clearEmailScreeningLinkData,
} from "./action";
import ShareableApi from "../../api/Shareable";
import { IObjectKeys } from "helpers/globalTypes";
import { getRentersScreeningRequestFail, getRentersScreeningRequestSuccess } from "store/renter/action";
import { GET_RENTERS_SCREENING_REQUEST } from "store/renter/types";
import { getAgentAllSrRequestFail, getAgentAllSrRequestSuccess, getAgentSrRequestFail, getAgentSrRequestSuccess } from "store/agent/action";
import { GET_TENANT_SCREENING_REQUESTS, GET_AGENTS_SCREENING_REQUEST } from "store/agent/types";

function* createShareableLandlordEntitySaga({ payload }: any) {
  try {
    const response = yield call(ShareableApi.createShareableLandlordEntity, payload);
    yield put(createShareableLandlordEntitySuccess(response));
    // openNotification("success", "Landlord Updated");

  } catch (e) {
    console.log(" in saga: ", e);
    // openNotification("error", generateErrorMessage(e));
    yield put(createShareableLandlordEntityFail(e));
  }
}

function* getShareableLandlordEntitySaga({ payload }: any) {
  try {
    const response = yield call(ShareableApi.getShareableLandlordEntity, payload);
    yield put(getShareableLandlordEntitySuccess(response));
  } catch (e) {
    if (e.statusCode !== 404) yield put(getShareableLandlordEntityFail(e));
    return;
  }
}

function* createShareablePropertyEntitySaga({ payload }: any) {
  try {
    const response = yield call(ShareableApi.createShareablePropertyEntity, payload);
    yield put(createShareablePropertyEntitySuccess(response));
    // openNotification("success", "Property Updated");
    return true;
  } catch (e) {
    console.log("error in saga: ", e);
    // openNotification("error", generateErrorMessage(e));
    if (e.message === "Shared property is already exist for the listing ID in Database") {
      console.log("should update");
      yield put(updateShareablePropertyEntity(payload));
    } else {
      yield put(createShareablePropertyEntityFail(e));
    }
  }
}

function* updateShareablePropertyEntitySaga({ payload }: any) {
  try {
    const response = yield call(ShareableApi.updateShareablePropertyEntity, payload);
    yield put(updateShareablePropertyEntitySuccess(response));
    return true;
  } catch (e) {
    console.log("error in saga: ", e);
    yield put(updateShareablePropertyEntityFail(e));
  }
}

function* getShareablePropertyEntitySaga({ payload }: any) {
  try {
    const response = yield call(ShareableApi.getShareablePropertyEntity, payload);
    yield put(getShareablePropertyEntitySuccess(response));
  } catch (e) {
    console.log("error in saga: ", e);
    if (e.statusCode !== 404) yield put(getShareablePropertyEntityFail(e));
    return;
  }
}

function* createShareableScreeningRequestEntitySaga({ payload }: any) {
  try {
    const response = yield call(ShareableApi.createShareableScreeningRequestEntity, payload);
    yield put(createShareableScreeningRequestEntitySuccess(response));
    return true;
  } catch (e) {
    console.log("error in saga: ", e);
    // openNotification("error", generateErrorMessage(e));
    yield put(createShareableScreeningRequestEntityFail(e));
  }
}

function* sendScreeningRequestToRenterSaga({ payload }: any) {
  try {
    console.log("payload: ", payload);
    const response = yield call(ShareableApi.sendScreeningRequestUpdateNotification, payload);
    yield put(sendScreeningRequestToRenterSuccess(response));
    return true;
  } catch (e) {
    console.log("error in saga: ", e);
    yield put(sendScreeningRequestToRenterFail(e));
  }
}

function* createShareableRenterEntitySaga({ payload }: any) {
  try {
    const response = yield call(ShareableApi.createRenterAndMapScreeningRequest, payload);
    yield put(createShareableRenterEntitySuccess({ ...response, ...payload }));
    return true;
  } catch (e) {
    console.log("error in saga: ", e);
    // openNotification("error", generateErrorMessage(e));
    yield put(createShareableRenterEntityFail(e));
  }
}

function* createShareableVerificationExamEntitySaga({ payload }: any) {
  try {
    console.log("===>  in the createShareableVerificationExamEntitySaga");
    console.log("payload: ", payload);
    const response = yield call(ShareableApi.createVerificationExam, payload);
    yield put(createShareableVerificationExamEntitySuccess({ ...response, ...payload }));
    return true;
  } catch (e) {
    console.log("error in saga: ", e);
    // openNotification("error", generateErrorMessage(e));
    yield put(createShareableVerificationExamEntityFail(e));
  }
}

function* postShareableVerificationExamAnswersSaga({ payload }: any) {
  try {
    console.log("===>  in the postShareableVerificationExamAnswersSaga");
    console.log("payload: ", payload);
    const response = yield call(ShareableApi.postVerificationExamAnswers, payload);
    yield put(postShareableVerificationExamAnswersSuccess(response));
    return true;
  } catch (e) {
    console.log("error in saga: ", e);
    // openNotification("error", generateErrorMessage(e));
    yield put(postShareableVerificationExamAnswersFail(e));
  }
}

function* validateRenterSaga({ payload }: any) {
  try {
    const response = yield call(ShareableApi.validateRenter, payload);
    yield put(validateRenterSuccess(response));
  } catch (e) {
    yield put(validateRenterFail(e));
  }
}

function* saveShareableRenterLinkDataSaga() {
  yield takeLatest(SAVE_EMAIL_SCREENING_LINK_DATA, saveEmailScreeningLinkData);
}

function* clearShareableRenterLinkDataSaga() {
  yield takeLatest(CLEAR_EMAIL_SCREENING_LINK_DATA, clearEmailScreeningLinkData);
}

function* watchCreateShareableLandlordEntity() {
  yield takeLatest(CREATE_SHAREABLE_LANDLORD_ENTITY, createShareableLandlordEntitySaga);
}

function* watchGetShareableLandlordEntity() {
  yield takeLatest(GET_SHAREABLE_LANDLORD_ENTITY, getShareableLandlordEntitySaga);
}

function* watchCreateShareablePropertyEntity() {
  yield takeLatest(CREATE_SHAREABLE_PROPERTY_ENTITY, createShareablePropertyEntitySaga);
}

function* watchUpdateShareablePropertyEntitySaga() {
  yield takeLatest(UPDATE_SHAREABLE_PROPERTY_ENTITY, updateShareablePropertyEntitySaga);
}

function* watchGetShareablePropertyEntity() {
  yield takeLatest(GET_SHAREABLE_PROPERTY_ENTITY, getShareablePropertyEntitySaga);
}

function* watchCreateShareableScreeningRequestEntity() {
  yield takeLatest(CREATE_SHAREABLE_SCREENING_REQUEST_ENTITY, createShareableScreeningRequestEntitySaga);
}

function* watchSendScreeningRequestToRenter() {
  yield takeLatest(SEND_SCREENING_REQUEST_TO_RENTER, sendScreeningRequestToRenterSaga);
}

function* watchCreateShareableRenterEntity() {
  yield takeLatest(CREATE_SHAREABLE_RENTER_ENTITY, createShareableRenterEntitySaga);
}

function* watchCreateShareableVerificationExamEntity() {
  yield takeLatest(CREATE_SHAREABLE_VERIFICATION_EXAM_ENTITY, createShareableVerificationExamEntitySaga);
}

function* watchPostShareableVerificationExamAnswers() {
  yield takeLatest(POST_SHAREABLE_VERIFICATION_EXAM_ANSWERS, postShareableVerificationExamAnswersSaga);
}

function* watchValidateRenter() {
  yield takeLatest(VALIDATE_RENTER, validateRenterSaga);
}

function* watchSaveShareableRenterLinkDataSaga() {
  yield takeLatest(VALIDATE_RENTER, saveShareableRenterLinkDataSaga);
}

function* watchClearShareableRenterLinkDataSaga() {
  yield takeLatest(VALIDATE_RENTER, clearShareableRenterLinkDataSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateShareableLandlordEntity),
    fork(watchGetShareableLandlordEntity),
    fork(watchCreateShareablePropertyEntity),
    fork(watchUpdateShareablePropertyEntitySaga),
    fork(watchGetShareablePropertyEntity),
    fork(watchCreateShareableScreeningRequestEntity),
    fork(watchSendScreeningRequestToRenter),
    fork(watchCreateShareableRenterEntity),
    fork(watchCreateShareableVerificationExamEntity),
    fork(watchPostShareableVerificationExamAnswers),
    fork(watchValidateRenter),
    fork(watchSaveShareableRenterLinkDataSaga),
    fork(watchClearShareableRenterLinkDataSaga)
  ]);
}
