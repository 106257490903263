import React from "react";
import { Tooltip } from "antd";
import { InfoIcon } from "../../../icons";
import { useSelector } from "react-redux";
import { windowSelector } from "../../../../store/app/selectors";
const SwitchButton = ({
  onChange,
  checked,
  required,
  label,
  key,
  name,
  disabled,
  classnames,
  dataScrollId,
  dataTestId,
}: {
  onChange?: any;
  checked?: boolean;
  required?: boolean;
  label?: string;
  key?: string | number;
  name?: string;
  disabled?: boolean;
  classnames?: any;
  dataScrollId?: string;
  dataTestId?: string;
}) => {
  const { type } = useSelector(windowSelector);
  return (
    <>
      <div
        key={key}
        className={`switch-btn-section flex justify-start gap-4 items-center ${
          classnames?.switchButtonSection ? classnames?.switchButtonSection : ""
        }`}
        data-test-id={"switch-btn-section"}
        data-scroll-id={dataScrollId}
      >
        {label && label.length && (
          <div
            className={classnames?.switcher ? "display-none" : `private-content switch-btn justify-start items-center`}
          >
            <span className={"switch-btn-label"} data-test-id={"switch-btn-label"}>
              {required ? <span className={"text-required"}>*</span> : ""}
              {`${label ? label : ""}`}
            </span>
            {label?.includes("Private") && (
              <Tooltip data-test-id={"tooltip"} placement="top" title={"Only you will see Landlords details"}>
                <span>
                  <InfoIcon />
                </span>
              </Tooltip>
            )}
          </div>
        )}

        <div className={`switch-btn-wrapper ${classnames?.switcher}`} data-test-id={"switch-btn-wrapper"}>
          <span className={`switcher switcher-1 ${disabled && "disabled"}`}>
            <input
              disabled={disabled && disabled}
              name={name}
              data-test-id={"checkbox"}
              onChange={onChange}
              checked={!Boolean(checked)}
              type="checkbox"
            />
            <label htmlFor="switcher-1" data-test-id={dataTestId ?? "switcher-1"} />
          </span>
        </div>
      </div>
    </>
  );
};

export default SwitchButton;
