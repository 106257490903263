import React from "react";
import RenterPortalAgentsComp from "components/RenterPortal/RenterPortalAgents";

const RenterPortalAgents = () => {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <RenterPortalAgentsComp />
    </div>
  );
};
export default RenterPortalAgents;
