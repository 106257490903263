import { openNotification } from 'helpers/globalFunctions';
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import PaymentApi from "../../api/Premium";
import {
    cancelSubscriptionFail,
    cancelSubscriptionSuccess,
    getInvoicesFail,
    getInvoicesSuccess,
    getPaymentLinkFail,
    getPaymentLinkSuccess,
    getScreeningPaymentLinkFail,
    getScreeningPaymentLinkSuccess,
    getStripePortalURLSuccess,
    getSubscriptionsFail,
    getSubscriptionsSuccess, getUpcomingInvoicesFail,
    getUpcomingInvoicesSuccess, updateSubscriptionFail, updateSubscriptionSuccess
} from "./action";
import {
    CANCEL_SUBSCRIPTION,
    GET_STRIPE_PORTAL_URL,
    GET_INVOICES,
    GET_PAYMENT_LINK,
    GET_SUBSCRIPTION,
    GET_UPCOMING_INVOICES,
    UPDATE_SUBSCRIPTION,
    GET_SCREENING_PAYMENT_LINK
} from "./types";

function* getPaymentLinkSaga({ payload }: any) {
    try {
        const response = yield call(PaymentApi.getPaymentLink, payload);
        yield put(getPaymentLinkSuccess(response));
    } catch (e) {
        yield put(getPaymentLinkFail(e));
    }
}

function* getStripePortalURLSaga() {
  try {
    const response = yield call(PaymentApi.getStripePortalURL);
    yield put(getStripePortalURLSuccess(response));
  } catch (e) {
    openNotification("error", `${e.message}`);
  }
}

function* getSubscriptionsSaga({ payload }: any) {
    try {
        const response = yield call(
            PaymentApi.getSubscriptions, payload,
        );
        yield put(getSubscriptionsSuccess(response));
    } catch (e) {
        yield put(getSubscriptionsFail(e));
    }
}

function* getUpcomingInvoicesSaga() {
    try {
        const response = yield call(
            PaymentApi.getUpcomingInvoice,
        );
        yield put(getUpcomingInvoicesSuccess(response));
    } catch (e) {
        yield put(getUpcomingInvoicesFail(e));
    }
}

function* getInvoicesSaga() {
    try {
        const response = yield call(PaymentApi.getInvoices);
        yield put(getInvoicesSuccess(response));
    } catch (e) {
        yield put(getInvoicesFail(e));
    }
}

function* updateSubscriptionSaga({payload}:any) {
    try {
      const response = yield call(PaymentApi.updateSubscription, payload);
      yield put(updateSubscriptionSuccess(response));
    } catch (e) {
      yield put(updateSubscriptionFail(e));
    }
}

function* cancelSubscriptionSaga() {
    try {
        const response = yield call(PaymentApi.deleteSubscription);
        yield put(cancelSubscriptionSuccess(response));
    } catch (e) {
        yield put(cancelSubscriptionFail(e));
    }
}

function* getScreeningPaymentLinkSaga({ payload }: any) {
  try {
    const response = yield call(PaymentApi.getScreeningPaymentLink, payload);
    yield put(getScreeningPaymentLinkSuccess(response));
  } catch (e) {
    yield put(getScreeningPaymentLinkFail(e));
  }
}

function* watchGetPaymentLinkSaga() {
    yield takeLatest(GET_PAYMENT_LINK, getPaymentLinkSaga);
}

function* watchGetSubscriptionsSaga() {
    yield takeLatest(GET_SUBSCRIPTION, getSubscriptionsSaga);
}

function* watchGetUpcomingInvoicesSaga() {
    yield takeLatest(GET_UPCOMING_INVOICES, getUpcomingInvoicesSaga);
}

function* watchGetInvoicesSaga() {
    yield takeLatest(GET_INVOICES, getInvoicesSaga);
}

function* watchUpdateSubscriptionSaga() {
    yield takeLatest(UPDATE_SUBSCRIPTION, updateSubscriptionSaga);
}

function* watchCancelSubscriptionSaga() {
    yield takeLatest(CANCEL_SUBSCRIPTION, cancelSubscriptionSaga);
}

function* watchGetStripePortalURLSaga() {
  yield takeLatest(GET_STRIPE_PORTAL_URL, getStripePortalURLSaga);
}

function* watchGetScreeningPaymentLinkSaga() {
  yield takeLatest(GET_SCREENING_PAYMENT_LINK, getScreeningPaymentLinkSaga);
}

export default function* rootSaga() {
    yield all([
      fork(watchGetPaymentLinkSaga),
      fork(watchGetSubscriptionsSaga),
      fork(watchGetUpcomingInvoicesSaga),
      fork(watchGetInvoicesSaga),
      fork(watchUpdateSubscriptionSaga),
      fork(watchCancelSubscriptionSaga),
      fork(watchGetStripePortalURLSaga),
      fork(watchGetScreeningPaymentLinkSaga)
    ]);
}
