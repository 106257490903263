import React from "react";
import { getListingInput } from "../../../../../../../../helpers/globalFunctions";
import { REQUIRED_BUDGET_POSITIVE_VALUE } from "../../../../../../../../helpers/validations";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import "./Budget.scss";
import FormStepsTitle from "../../form-steps-title/FormStepsTitle";

const Budget = () => {
  const requirementEnums = useSelector(requirementEnumsSelector);

  const minBudget = requirementEnums?.find((item: EnumInterface) => item?.key === "budget_min");

  const maxBudget = requirementEnums?.find((item: EnumInterface) => item?.key === "budget_max");

  return (
    <div className={"budget-amount-section mt-27"}>
      <FormStepsTitle text={minBudget?.label} />
      {getListingInput(
        minBudget?.key,
        "",
        !minBudget?.allow_null ? [REQUIRED_BUDGET_POSITIVE_VALUE] : [{ required: false }],
        "Ex. $1200",
        "",
        "req",
        true
      )}
      <FormStepsTitle text={maxBudget?.label} />
      {getListingInput(
        maxBudget?.key,
        "",
        !maxBudget?.allow_null ? [REQUIRED_BUDGET_POSITIVE_VALUE] : [{ required: false }],
        "Ex. $1200",
        "",
        "req",
        true
      )}
    </div>
  );
};

export default Budget;
