import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isPublicListingSelector, listingDataSelector } from "../../../store/listings/selectors";
import BaseButton from "../../Shared/BaseButton";
import { VirtualTourIcon } from "../../icons";
import queryString from "query-string";
import { push } from "connected-react-router";
import { AnalyticsService } from "helpers/analyticsService";
import ListingGuard from "../ListingGuard";

const ListingImageVirtualTourMobile = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(listingDataSelector);
  const isPublicListing = useSelector(isPublicListingSelector);

  const handleAddBtnClick = () => {
    AnalyticsService.reportSingleListingPageClick("add_virtual_tour");
    const parsed = {
      // landlordId:
      lId: listingData._id,
      step: 6,
    };
    const stringified = queryString.stringify(parsed);
    dispatch(push(`/manageListing?${stringified}`));
  };

  return (
    <div className={"listing-virtual-tour-mobile"}>
      {listingData?.virtual_tour_link ? (
        <div className="virtual-tour-wrapper">
          <BaseButton
            size="sm"
            classnames={"slider-btn virtual-tour-btn"}
            href={listingData.virtual_tour_link}
            target="_blank"
          >
            <>
              <span className="mr-1">
                <VirtualTourIcon />
              </span>
              <span>Virtual Tour</span>
            </>
          </BaseButton>
        </div>
      ) : (
        <div className={"empty-vr-state-wrapper"}>
          {!isPublicListing && (
            <>
              <ListingGuard>
                <BaseButton
                  onClick={handleAddBtnClick}
                  classnames={"primary-btn-link bg-transparent ml-5"}
                  modalType="listing"
                >
                  + Add a Virtual Tour
                </BaseButton>
              </ListingGuard>
            </>
          )}
        </div>
      )}
      <div className="listing-images-total">
        {listingData?.images?.length > 0 && (
          <span>
            Showing {`${listingData?.images?.slice(0, 6)?.length}`} of {`${listingData?.images?.length}`} photos
          </span>
        )}
      </div>
    </div>
  );
};

export default ListingImageVirtualTourMobile;
