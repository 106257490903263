import React from "react";
import BaseModal from "../../../../Shared/BaseModal";
import "./ConfirmRevokeLeadModal.scss";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { leadRevokeRequirement } from "../../../../../store/requirement/action";
import { AnalyticsService } from "helpers/analyticsService";
import BaseButton from "components/Shared/BaseButton";
import { openNotification } from "helpers/globalFunctions";

interface IConfirmRevokeLeadModal {
  visible: boolean;
  setConfirmModalProps: React.Dispatch<React.SetStateAction<boolean>>;
  modalData: any;
  localDeclineOrRevokeAgent: any;
}
const ConfirmRevokeLeadModal = ({ visible, setConfirmModalProps, modalData, localDeclineOrRevokeAgent }: IConfirmRevokeLeadModal) => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const closeModalHandler = () => {
    setConfirmModalProps(false);
    AnalyticsService.reportRequirementsFunnel({ action: "renter_page_revoke_lead_modal_click", label: "cancel" });
  };
  const handleRevokeAction = () => {
    AnalyticsService.reportRequirementsFunnel({ action: "renter_page_revoke_lead_modal_click", label: "confirm" });
    openNotification("success", "Agent connection removed!");
    dispatch(
      leadRevokeRequirement({
        agent_id: modalData.agent.agent_id,
        requirements_id: modalData.requirementId,
      })
    );
    localDeclineOrRevokeAgent(modalData.agent._id);
    closeModalHandler();
  };

  return (
    <BaseModal classname={"confirm-revoke-modal"} modalTitle="" isModalVisible={visible} onCancel={closeModalHandler} modalFooter={[null]} width={width && width >= 600 ? 504 : 299}>
      <div className={"confirm-revoke-modal--body"}>
        <h3 className={"confirm-revoke-modal--body--title"}>Remove Status</h3>
        <p className={"confirm-revoke-modal--body--subheadline"}>Are you sure you'd like to revoke this agents status?</p>
        <div className={"confirm-revoke-modal--body--actionButtons"}>
          <BaseButton onClick={closeModalHandler}>Cancel</BaseButton>
          <BaseButton
            variant="primary"
            onClick={() => {
              handleRevokeAction();
            }}
          >
            Confirm
          </BaseButton>
        </div>
        <span className={"confirm-revoke-modal--body--notifyText"}>By confirming, this agent will no longer have access to your profile details.</span>
      </div>
    </BaseModal>
  );
};

export default ConfirmRevokeLeadModal;
