import React from "react";
import ResetPasswordForm from "components/ResetPassword";

const ResetPassword = () => {
  return (
    <>
      <ResetPasswordForm />
    </>
  );
};
export default ResetPassword;
