import React, { useState } from "react";
import "./Message.scss";
import { Success } from "../../icons/Success";
import SuccessWhite from "../../icons/SuccessWhite";
import Cookies from "js-cookie";
import { useWindowSize } from "hooks/useWindowSize";
import { ISO_STRING, isProdEnv } from "helpers/constants";
import { addTimeAndFormatDate, dateToMoment, formatDate } from "../../../helpers/dateHelper";

interface IMessageInterface {
  text?: string;
  messageClassName?: string;
  type?: string;
  children?: React.ReactNode;
  close?: boolean;
}

const Message = (props: IMessageInterface) => {
  const [isCLosed, setIsClosed] = useState(false);
  const { width } = useWindowSize();

  const handelClose = () => {
    setIsClosed(true);
    const currentDate = formatDate(dateToMoment(), ISO_STRING);
    const futureDate = isProdEnv
      ? 3 // 3 days 
      : 1 / 48; // 30 minues
    Cookies.set("phone_message", currentDate, {
      expires: futureDate,
    });
  };

  const renderIcon = () => {
    if (props.type) {
      return null;
    } else {
      if (props.messageClassName && props.messageClassName.length > 0) {
        return <SuccessWhite />;
      } else {
        return <Success />;
      }
    }
  };
  return (
    <div
      className={`alert ${props.messageClassName} ${isCLosed ? "hide-message" : ""
        }`}
    >
      {renderIcon()}
      {props.text}!{props.children}
      {props.close && (
        <p className="close" onClick={handelClose}>
          {width && width < 830 ? "x" : "x Remind me later"}
        </p>
      )}
    </div>
  );
};
export default React.memo(Message);
