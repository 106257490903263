import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import {
  setCurrentLandlord,
  setToggleLandlordPrivacy
} from "../../../../../store/landlords/action";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  landlordEditSuccessSelector,
  landlordNoteAddSuccessSelector,
  singleLandlordDataSelector
} from "../../../../../store/landlords/selectors";
import { IObjectKeys } from "../../../../../helpers/globalTypes";
import {
  MenuOptionsIcon,
  NotPrivateEyeIcon,
  PrivateEyeIcon,
  TriangleIcon
} from "../../../../icons";
import "./MoreDropdonwMobile.scss";
import DropdownPencilEdit from "../../../../icons/DropdownPencilEdit";
import ListingAddFollowUpIcon from "../../../../icons/ListingAddFollowUpIcon";
import ManageLandlordForm from "../../../../Landlords/ManageLandlord";
import BaseModal from "../../../../Shared/BaseModal";
import { useForm } from "antd/es/form/Form";
import FollowUpModalIcon from "../../../../icons/FollowUpModalIcon";
import {
  editedFollowUpSelector,
  followUpsListSelector
} from "../../../../../store/followUps/selectors";
import LandlordNoteModal from "../../../LandlordNoteModal";
import AllFollowUpsModal from "../../../../FollowUps/all-follow-ups-modal/AllFollowUpsModal";
import AddFollowUpModal from "../../../../FollowUps/add-follow-up-modal/AddFollowUpModal";
import PhoneVerifyModalFollowUps from "../../../../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import { useParams } from "react-router-dom";
import { ListingNote } from "../../../../Listings/helpers/mockListings";
import { getLandlordFollowUps } from "../../../../../store/followUps/action";
import AddFollowUpModalMobile from "../../../../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { AnalyticsService } from "helpers/analyticsService";
import { followUpModalsHandler, usersFreeSubscriptionLimits } from "../../../../../helpers/globalFunctions";
import { useAppContext, useDemoContext } from "libs/contextLib";
import PremiumUpgradeModal from "../../../../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { userSelector } from "store/user/selectors";
import { isCLAWUser } from "helpers/userHelpers";

const MoreDropdownMobile = ({
  modalProps,
  setModalProps,
  canManageLandlord
}: {
  modalProps: any;
  setModalProps: any;
  canManageLandlord?: boolean;
}) => {
  const initialModalValue = { visible: false, editModal: false };
  const landlord = useSelector(singleLandlordDataSelector);
  const [currentNoteInfo, setCurrentNoteInfo] = useState<IObjectKeys>({});
  const [form] = useForm();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const [allFollowUpsModalVisible, setAllFollowUpsModalVisible] = useState(initialModalValue);
  const { landlordId } = useParams();
  const [visible, setVisible] = useState(false);
  const [isEditDropDown, setEditDropDown] = useState<boolean>(false);

  const followUps = useSelector(followUpsListSelector);
  const [followUpModalProps, setFollowUpModalProps] = useState(initialModalValue);
  const [isDescShown, setIsDescShown] = useState(false);
  const [isFollowUpEditable, setIsFollowUpEditable] = useState(false);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const editedFollowUp = useSelector(editedFollowUpSelector);
  const { isDemo, demoAction } = useDemoContext();
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const { isNotPremiumUser } = useAppContext();
  const userData = useSelector(userSelector);
  const usersFreeSubscriptionLimit: any = usersFreeSubscriptionLimits({ userData, isNotPremiumUser });
  const handleOpenAddNoteModal = () => {
    setFollowUpModalProps({ visible: true, editModal: false });
    form.resetFields();
    setIsDescShown(false);
    setIsFollowUpEditable(false);
  };
  const handleOpenFollowUpModal = (note: ListingNote) => {
    setFollowUpModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at: note.todo_at
    });
    setIsFollowUpEditable(true);
    if (note.description && note.description.length > 0) {
      setIsDescShown(true);
    } else {
      setIsDescShown(false);
    }
  };
  const openAllFollowUpsModalHandler = () => {
    if (isDemo) {
      demoAction("followUp");
    } else {
      setAllFollowUpsModalVisible({ visible: true, editModal: false });
    }
  };
  const closeVerifyModalHandler = () => {
    setPhoneVerifyModalVisible(false);
  };

  useEffect(() => {
    if (Object.keys(landlord).length > 0) {
      dispatch(getLandlordFollowUps(landlord.landlord_id));
    }
  }, [landlord]);
  useEffect(() => {
    if (Object.keys(editedFollowUp).length > 0) {
      followUpModalsHandler({
        setFollowUpModalProps,
        setAllFollowUpsModalVisible,
        editedFollowUp,
        dispatch
      });
    }
  }, [editedFollowUp]);

  const followHandler = () => {
    AnalyticsService.reportAddFollowUpClick("three_dot_menu");
    if (isDemo) {
      demoAction("followUp");
    } else {
      setEditDropDown(false);
      setCurrentNoteInfo({});
      setFollowUpModalProps({ visible: true, editModal: false });
    }
  };
  const noteHandler = () => {
    if (isDemo) {
      demoAction("followUp");
    } else {
      setEditDropDown(false);
      setCurrentNoteInfo({});
      setModalProps({ visible: true, editModal: false });
    }
  };

  const editHandler = () => {
    if (isDemo) {
      demoAction("followUp");
    } else {
      setEditDropDown(false);
      setVisible(true);
      dispatch(setCurrentLandlord(landlord));
    }
  };
  const privateHandler = () => {
    if (isDemo) {
      demoAction("followUp");
    } else {
      setEditDropDown(false);
      dispatch(
        setToggleLandlordPrivacy({
          landlord_id: landlord.landlord_id,
          is_private: true,
        })
      );
    }
  };
  const publicHandler = () => {
    if (isDemo) {
      demoAction("followUp");
    } else {
      setEditDropDown(false);
      dispatch(
        setToggleLandlordPrivacy({
          landlord_id: landlord.landlord_id,
          is_private: false,
        })
      );
    }
  };

  const menu = (
    <Menu>
      <TriangleIcon />
      {canManageLandlord && <Menu.Item
        onClick={editHandler}
        data-test-id="Edit-Landloard"
      >
        <DropdownPencilEdit />
        Edit Landlord
      </Menu.Item>}
      {canManageLandlord && landlord.is_private && (
        <Menu.Item
          onClick={publicHandler}
          data-test-id="make-land-private"
        >
          <PrivateEyeIcon />
          Make Public
        </Menu.Item>
      )}
      {canManageLandlord && !landlord.is_private && (
        <Menu.Item
          onClick={privateHandler}
          data-test-id="make-land-private"
        >
          <NotPrivateEyeIcon />
          Make Private
        </Menu.Item>
      )}
      {!isCLAWUser(userData) && canManageLandlord && <Menu.Item
        onClick={() => {
          isDemo
            ? demoAction("followUp")
            : dispatch(
              push(`../manageListing?step=0&landlordId=${landlord.landlord_id}`)
            );
        }}
        data-test-id="New-List-Land"
        className={"add-new-landlord-listing-txt-wrapper"}
        disabled={usersFreeSubscriptionLimit?.listings}
      >
        <ListingAddFollowUpIcon />
        <div className={"add-new-landlord-listing-txt"}>
          <p>Add Listing</p>
          <p>for landlord</p>
        </div>
      </Menu.Item>}
      <Menu.Item
        onClick={noteHandler}
      >
        <ListingAddFollowUpIcon />
        Add note
      </Menu.Item>
      <Menu.Item
        onClick={followHandler}
      >
        <ListingAddFollowUpIcon />
        Add follow-up
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={"landlord-more-dropdown-wrapper-mobile"}>
      <div className={"single-listing-actions-wrapper--followUp"}>
        <div className={"single-listing-actions-wrapper--followUp--circle"}>
          <span>{followUps.length}</span>
        </div>
        <FollowUpModalIcon openModalHandler={openAllFollowUpsModalHandler} />
      </div>
      <Dropdown
        onVisibleChange={(visible) => {
          setEditDropDown(visible);
        }}
        getPopupContainer={() => {
          return document.querySelector(
            ".landlord-more-dropdown-wrapper-mobile"
          ) as HTMLElement;
        }}
        visible={isEditDropDown}
        trigger={["click"]}
        placement={"bottomRight"}
        overlay={menu}
        overlayClassName={`single-landlord-more-options-dropdown-overlay`}
      >
        <span
          className="landlord-edit-popup-settings-span"
          data-test-id={"landlord-edit-btn"}
          onClick={() => {
            setEditDropDown(true);
          }}
        >
          <MenuOptionsIcon />
        </span>
      </Dropdown>
      <BaseModal
        classname={"add-edit-landlord-modal"}
        cancelButtonProps={{
          className: "rb-secondary-btn sm",
        }}
        okButtonProps={{
          className: "rb-primary-btn sm",
        }}
        modalTitle={`${landlord.landlord_id ? "Edit" : "Add"} Landlord`}
        isModalVisible={visible}
        onOk={() => form.submit()}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <ManageLandlordForm form={form} />
      </BaseModal>
      <LandlordNoteModal
        landlord_id={landlordId}
        modalProps={modalProps}
        setModalProps={setModalProps}
        noteInfo={currentNoteInfo}
        initialModalValue={initialModalValue}
        lId={landlordId}
        form={form}
      />
      {
        //@ts-ignore
        width < 767 ? (
          <AllFollowUpsModal
            visible={allFollowUpsModalVisible.visible}
            setVisible={setAllFollowUpsModalVisible}
            handleOpenAddFollowUpModal={handleOpenAddNoteModal}
            notes={followUps}
            handleOpenFollowUpEditModal={handleOpenFollowUpModal}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : null
      }
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={isDescShown}
            landlordInfo={landlord}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={isDescShown}
            landlordInfo={landlord}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        )
      }
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={closeVerifyModalHandler} />
      <PremiumUpgradeModal visible={premiumModalVisible} setVisible={setPremiumModalVisible} triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_ALL_FOLLOW_UPS_MODAL} />
    </div>
  );
};

export default MoreDropdownMobile;
