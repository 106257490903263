import React, {useEffect} from "react";
import WorkDetails from "./work-details/WorkDetails";
import CreditScore from "./credit-score/CreditScore";
import StudentOrEmployed from "./student-or-employed/StudentOrEmployed";
import {FormInstance} from "antd/es/form";
import {useSelector} from "react-redux";
import {requirementFormDataSelector} from "../../../../../../../store/requirement/selectors";

const Occupation = ({usefullFieldValues,form}:{usefullFieldValues:any,form:FormInstance<any>}) =>{
    const showWorkDetailsValue = form.getFieldValue("student_or_employed")
    const requirementFormData = useSelector(requirementFormDataSelector)
    useEffect(()=>{
        if((requirementFormData.yearly_income === 0 || form.getFieldValue("yearly_income") === 0)
            && showWorkDetailsValue === "employed") {
            form.setFieldsValue({
                yearly_income:null
            })
        }
    },[requirementFormData.yearly_income,showWorkDetailsValue])
    return(
        <div>
            <StudentOrEmployed studentOrEmployed={usefullFieldValues.student_or_employed}/>
            {showWorkDetailsValue &&
                showWorkDetailsValue !== "student" &&
                showWorkDetailsValue !== "neither" && <WorkDetails />}
            <CreditScore/>
        </div>
    )
}


export default Occupation
