import BaseButton from "components/Shared/BaseButton";
import BaseModal from "components/Shared/BaseModal";
import React, { useState } from "react";
import { IObjectKeys } from "helpers/globalTypes";
import "../AgentReqModal/styles.scss";
import "./styles.scss";
import ContactModal from "components/Shared/ContactModal/ContactModal";
import { TbCircleCheckFilled } from "react-icons/tb";
import { useDispatch } from "react-redux";
import routes from "routes/routesCode";
import { push } from "connected-react-router";

const ApproveModal = ({ toggle = false, setToggle, agent, handleCancel, isFromReqPage }: IObjectKeys) => {
  const dispatch = useDispatch();
  const [isContactVisible, setIsContactVisible] = useState(false);

  // const handleContact = () => {
  //   setIsContactVisible(true);
  // };

  const handleClose = () => {
    setToggle(false);
    handleCancel();
  };

  return (
    <>
      <BaseModal isModalVisible={toggle} modalFooter={null} width={290} onCancel={handleClose} modalTitle="" centered={true}>
        <div className="approve-modal flex flex-col justify-center pt-8">
          <div className="flex justify-center py-3">
            <TbCircleCheckFilled className="h-16 w-16 text-green-500" />
          </div>
          <h4 className="text-base flex justify-center">You're now connected to</h4>
          <h2 className="flex justify-center text-2xl font-semibold my-4">{agent?.name}</h2>
          {isFromReqPage ? null : (
            <>
              <p className="font-16 text-center pb-6">Would you like to contact them?</p>
              <div className="agent-modal-buttons">
                <BaseButton variant="primary" onClick={() => dispatch(push(routes.renterPortalDashboard.path))}>
                  Continue
                </BaseButton>
              </div>
            </>
          )}
        </div>
      </BaseModal>
      {isContactVisible && (
        <ContactModal currentLeadData={agent} showModal={true} onClose={setIsContactVisible} currentLandlordData={null} />
      )}
    </>
  );
};

export default ApproveModal;
