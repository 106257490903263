import React from 'react'

const HouseIcon = () => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.8622 5.36694C11.0616 5.14041 11.0423 4.79322 10.8192 4.59166L5.90315 0.14844C5.68002 -0.05312 5.32227 -0.0488977 5.10383 0.157959L0.171081 4.82855C-0.0473601 5.03541 -0.0580814 5.38216 0.147344 5.60272L0.271015 5.73576C0.476189 5.95632 0.807831 5.98262 1.01128 5.79432L1.37993 5.45331V10.4457C1.37993 10.752 1.62021 11 1.91653 11H3.83933C4.13565 11 4.37593 10.752 4.37593 10.4457V6.95305H6.82848V10.4457C6.82421 10.7518 7.03624 10.9998 7.33256 10.9998H9.37024C9.66657 10.9998 9.90685 10.7518 9.90685 10.4455V5.52361C9.90685 5.52361 10.0087 5.61576 10.1343 5.7298C10.2597 5.84362 10.523 5.75235 10.7225 5.52561L10.8622 5.36694Z"
                fill="#141414"/>
        </svg>
    )
}

export default HouseIcon