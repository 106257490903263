import React from "react";

export const CheckedIcon = () => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5606 2.6051L6.64752 10.5177C6.06154 11.1038 5.11099 11.1038 4.52446 10.5177L0.439649 6.43257C-0.14655 5.84648 -0.14655 4.89582 0.439649 4.30962C1.02596 3.72331 1.97643 3.72331 2.56248 4.3094L5.5863 7.33326L12.4375 0.482046C13.0238 -0.104264 13.9743 -0.103819 14.5604 0.482046C15.1465 1.06825 15.1465 2.01857 14.5606 2.6051Z"
        fill="black"
      />
    </svg>
  );
};
