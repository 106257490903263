import { StarIcon } from "components/icons/StarIcon";
import BaseButton from "components/Shared/BaseButton";
import { IObjectKeys } from "helpers/globalTypes";
import React, { useState } from "react";
import ActivateModal from "../ActivateModal";
import "./index.scss";

interface IActivateSection {
  className?: string;
  type?: string;
}
const content: IObjectKeys = {
  lead: "to add your own leads.",
  landlord: "to add your own landlords.",
  listing: "to add your own listings.",
  team: "to add your own team.",
  followUp: "to add your follow-ups",
  default: "automating your Leads, Listings Landlords.",
};
const ActivateSection = ({ className, type = "default" }: IActivateSection) => {
  const [toggleModal, setToggleModal] = useState(false);
  const btnHandler = () => {
    setToggleModal(true);
  };
  return (
    <div className={`${className} activate-section`}>
      <div className="activate-section-content">
        <div className="activate-section-text">
          <span role="img" aria-label="star" className="mr-10">
            <StarIcon />
          </span>{" "}
          <p>
            Activate your <b>free account</b> {`${content[type]}`}
          </p>
        </div>
        <div>
          <BaseButton classnames="activate-btn" onClick={btnHandler}>
            Get Started Here
          </BaseButton>
        </div>
      </div>
      <ActivateModal
        toggle={toggleModal}
        setToggle={setToggleModal}
        type={type}
      />
    </div>
  );
};

export default ActivateSection;
