import React from "react";

export const SliderLeftArrowIcon = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.287062 6.30693L6.30685 0.287338C6.68978 -0.0957786 7.31064 -0.0957785 7.69339 0.287338C8.07616 0.670114 8.07616 1.29094 7.69339 1.67368L2.3668 7.0001L7.69323 12.3263C8.07601 12.7093 8.07601 13.33 7.69323 13.7128C7.31046 14.0957 6.68963 14.0957 6.3067 13.7128L0.286907 7.69312C0.0955187 7.50164 -6.71209e-05 7.25095 -6.70989e-05 7.00013C-6.7077e-05 6.74919 0.0957047 6.49832 0.287062 6.30693Z"
        fill="#333333"
      />
    </svg>
  );
};
