import React, {useEffect, useState} from "react";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import { DatePicker, Form } from "antd";
import { DATE_PICKER_DEFAULT_PLACEHOLDER } from "../../../../../../../../helpers/validations";
import { US_DATE_FORMAT } from "../../../../../../../../helpers/constants";
import "./AvailableForShowing.scss"
import FormStepsTitle from "../../form-steps-title/FormStepsTitle";
import CalendarIcon from "../../../icons/last-step/CalendarIcon";
import {requirementFormDataSelector} from "../../../../../../../../store/requirement/selectors";

const AvailableForShowing = () => {
  const requirementEnums = useSelector(requirementEnumsSelector);
  const requirementFormData = useSelector(requirementFormDataSelector)
  const [pickerHasValue,setPickerHasValue] = useState(false)

  useEffect(()=>{
      requirementFormData.available_times ?
          setPickerHasValue(true):
          setPickerHasValue(false)
  },[requirementFormData])

  const availableTimes = requirementEnums?.find(
    (item: EnumInterface) => item?.key === "available_times",
  );

  return (
    <div className={"available-for-showing"}>
      <div onClick={(e) => e.stopPropagation()} className="datepicker-wrapper">
        <FormStepsTitle text={availableTimes?.label} classname={"required-not-visible"}/>
        <Form.Item
          name={"available_times"}
          labelCol={{ span: 24 }}
          className={`chip-checkbox enum_input ${pickerHasValue && "requirements-datepicker-selected"}`}
          data-scroll-id="available_date"
        >
          <DatePicker
            getPopupContainer={() => {
              return document.querySelector(
                ".datepicker-wrapper",
              ) as HTMLElement;
            }}
            inputReadOnly={true}
            showToday={false}
            dropdownClassName="requirements-datepicker"
            suffixIcon={<CalendarIcon/>}
            format={`${US_DATE_FORMAT}`}
            placeholder={DATE_PICKER_DEFAULT_PLACEHOLDER}
            onChange={()=>{
                setPickerHasValue(true)
            }}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default AvailableForShowing;
