import Api from "./api";
import { IObjectKeys } from "../helpers/globalTypes";
import { ListingNote } from "../components/Listings/helpers/mockListings";
import { getAuthToken } from "../helpers/authUtils";

export default class ListingApi {
  static addListing(listingData: IObjectKeys) {
    const api = new Api("listing");
    return api.create({ data: listingData });
  }

  static updateListing(listingData: IObjectKeys) {
    const api = new Api("listing");
    return api.put(listingData.lId, { data: listingData });
  }

  static getListings(params: IObjectKeys) {
    const api = new Api("listing/portal");
    return api.create({ data: params });
  }

  static getListingsFromAlgolia(payload: IObjectKeys) {
    const api = new Api("recommendation/listings");
    return api.create({ data: payload });
  }

  static postSendEmailToRenterForMatchedListings(payload: IObjectKeys) {
    const api = new Api("recommendation/listings/renter/email");
    return api.create({ data: payload });
  }

  static getRequirementListingsFromAlgolia(payload: IObjectKeys) {
    const api = new Api("recommendation/listings/req");
    return api.create({ data: payload });
  }

  static getOneListing(listingId: string) {
    const api = new Api("listing");
    return api.get(listingId, {});
  }

  // While user is authenticated we need to use api endpoint with auth token to retrive full data
  // That's where getAuthToken condtion comes in
  static getOneListingFromPublicId(publicId: string) {
    const token = getAuthToken();
    const api = token ? new Api("listing/public") : new Api("public/listings");
    return api.get(publicId, {});
  }

  static addListingNote(listingNoteData: ListingNote) {
    const api = new Api("notes");
    return api.create({ data: listingNoteData });
  }

  static updateListingNote(listingNoteData: ListingNote) {
    const api = new Api("notes");
    return api.put(listingNoteData._id || "", { data: listingNoteData });
  }

  static deleteListingNote(noteId: string) {
    const api = new Api("notes");
    return api.destroy(noteId);
  }

  static getListingNotes(listingId: string) {
    const api = new Api("notes/allReference");
    return api.get(listingId, {});
  }

  static updateListingPublishState(listingPublishStateData: { listingId: string; action: string }) {
    const api = new Api(`listing/${listingPublishStateData.action}`);
    return api.put(listingPublishStateData.listingId, { data: listingPublishStateData });
  }

  static getListingFollowUps(listingId: string) {
    const api = new Api("followUp/allReference");
    return api.get(listingId, {});
  }

  static deleteListing(listingId: any) {
    const api = new Api("listing/delete");
    return api.destroy(listingId);
  }

  static deleteListingImage(imageId: string) {
    const api = new Api("listing/media");
    return api.destroy(imageId);
  }

  static uploadListingsCsv(listingCsv: any) {
    const api = new Api("listing/csv/upload");
    const formData = new FormData();
    formData.append("file", listingCsv);
    return api.create({ data: formData });
  }
}
