import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import { fieldDto, IObjectKeys } from "helpers/globalTypes";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import {
  agentsActivitiesSelector,
  agentsActivitiesTotalSelector,
  agentsActivitiesLoadingSelector,
} from "store/activities/selectors";
import { getAgentActivities } from "store/activities/action";
import { TablePaginationConfig } from "antd/lib/table/interface";

import "../styles.scss";
import {
  getBrowserDateFormat,
  httpMethodNameTo,
  responseToEntityNameUi,
} from "../../../helpers/globalFunctions";
import moment from "moment";
import { IActivitiesQuery } from "../index";
import BaseTable from "../../Shared/BaseTable";
import ActivitiesEntity from "../ActivitiesEntity";

interface UsersTableInterFace {
  queryAndPagination: IActivitiesQuery;
  setQueryAndPagination: Dispatch<SetStateAction<any>>;
}

const AgentsActivitiesTable: React.FC<UsersTableInterFace> = ({
  queryAndPagination,
  setQueryAndPagination,
}) => {
  const dispatch = useDispatch();
  const agentsActivities = useSelector(agentsActivitiesSelector);
  const total = useSelector(agentsActivitiesTotalSelector);
  const agentsActivitiesLoading = useSelector(agentsActivitiesLoadingSelector);

  const delayGetAgentActivities = useRef(
    debounce((queryAndPagination: IActivitiesQuery) => {
      dispatch(getAgentActivities(queryAndPagination));
    }, 200),
  );

  const columns = [
    {
      dataIndex: "_id",
      key: "_id",
      title: "Name",
      sorter: false,
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return <span>{rowData?.user_data?.name}</span>;
      },
    },
    {
      dataIndex: "_id",
      key: "created_at",
      title: "Entity",
      sorter: false,
      render: (_id: string | undefined, rowData: IObjectKeys) => {
        return <ActivitiesEntity _id={_id} rowData={rowData} />;
      },
    },
    {
      dataIndex: "_id",
      key: "updated_at",
      title: "Activity",
      sorter: false,
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return (
          <span>
            {httpMethodNameTo(rowData.method)} -{" "}
            {responseToEntityNameUi(rowData.original_url)}
          </span>
        );
      },
    },
    {
      dataIndex: "created_at",
      key: "_v",
      title: "Time",
      sorter: true,
      render: (created_at: Date) => {
        return moment(created_at,getBrowserDateFormat()).format("MM.DD.YYYY hh:mm A");
      },
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    delayGetAgentActivities?.current(queryAndPagination);
  }, [
    queryAndPagination,
    queryAndPagination.sortField,
    queryAndPagination.sortOrder,
  ]);

  const handleTableChange = (
    paginationOpt: TablePaginationConfig,
    _: any,
    sorter: any,
  ): void => {
    dispatch(
      setQueryAndPagination({
        page: paginationOpt.current,
        pageSize: paginationOpt.pageSize,
        sortField: sorter.field,
        sortOrder: sorter.order,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(
        setQueryAndPagination({
          page: 1,
          current: 1,
          pageSize: 20,
          sortField: "created_at",
          sortOrder: -1,
          searchValue: "",
          timeInterval: {
            label: "Last 30 Days",
            value: "last_30_days",
          },
        }),
      );
    };
  }, [dispatch,setQueryAndPagination]);

  return (
    <div className="users-table-wrapper">
      <BaseTable
        classnames={"activities-table"}
        bordered={false}
        columns={columns}
        dataSource={agentsActivities}
        rowKey={(record) => record._id}
        onChange={handleTableChange}
        pagination={{
          current: queryAndPagination.page,
          pageSize: queryAndPagination.pageSize,
          total: total,
          position: ["bottomCenter"],
        }}
        loading={agentsActivitiesLoading}
      />
    </div>
  );
};
export default AgentsActivitiesTable;
