import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";
import { ListingNote } from "../../components/Listings/helpers/mockListings";
import { CHECK_EXISTING_LANDLORD } from "./types";

const initialState: IObjectKeys = {
  landlords: [],
  landlordsTotal: 0,
  currentLandlord: {},
  landlordLoading: false,
  landlordCreateSuccess: false,
  landlordEditSuccess: false,
  singleLandlordData: {},
  listingsListOfLandlord: [],
  total: 0,
  landlordNotes: [],
  landlordNotesLoading: false,
  landlordNotesError: null,
  landlordFollowUps: [],
  landlordFollowUpsLoading: false,
  landlordFollowUpsError: null,
  sendLandlordInfoLoading: false,
  sendLandlordInfoError: null,
  landlordError: null,
  landlordsXlsxErrors: null,
  downloadXlsxTemplateLoading: false,
  listingsLastQuery: "?",
  listingsViewType: "grid",
  filterOptions: {},
  paginationValues: { current: 1, pageSize: 24 },
  uploadLandlordSuccess: false,
  landlordsQueryParams: {
    page: 1,
    pageSize: 24,
  },
  isLandlordPrivate: false,
  landlordPrivateError: null,
  landlordsViewType: "list",
  isLandlordExists: null,
  isLandlordExistError: null,
};

export default function landlordsReducer(
  state = initialState,
  action: { type: string; payload: any; }
) {
  switch (action.type) {
    case types.UPLOAD_XLSX_LANDLORDS:
      return {
        ...state,
        landlordError: null,
        landlordsXlsxErrors: null,
        landlordLoading: true,
        uploadLandlordSuccess: false,
      };
    case types.UPLOAD_XLSX_LANDLORDS_SUCCESS:
      let uploadErrors = null;
      // eslint-disable-next-line no-restricted-globals
      if (action.payload?.errors?.length) {
        uploadErrors = action.payload.errors;
      }
      return {
        ...state,
        landlordLoading: false,
        landlordsXlsxErrors: uploadErrors,
        uploadLandlordSuccess: true,
      };
    case types.SET_UPLOAD_XLSX_LANDLORD_SUCCESS:
      return {
        ...state,
        uploadLandlordSuccess: false,
      };
    case types.SET_CREATE_LANDLORD_SUCCESS:
      return {
        ...state,
        landlordCreateSuccess: action.payload,
      };
    case types.UPLOAD_XLSX_LANDLORDS_FAIL:
      return {
        ...state,
        landlordLoading: false,
        landlordsXlsxErrors: action.payload,
      };
    case types.DOWNLOAD_XLSX_LANDLORDS_TEMPLATE:
      return {
        ...state,
        downloadXlsxTemplateLoading: true,
        landlordError: null,
      };
    case types.DOWNLOAD_XLSX_LANDLORDS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadXlsxTemplateLoading: false,
      };
    case types.DOWNLOAD_XLSX_LANDLORDS_TEMPLATE_FAIL:
      return {
        ...state,
        downloadXlsxTemplateLoading: false,
        landlordError: action.payload,
      };
    case types.CLEAR_UPLOAD_XLSX_LANDLORDS_ERRORS:
      return {
        ...state,
        listingLoading: false,
        landlordsXlsxErrors: null,
      };
    case types.CLEAR_PARTICULAR_UPLOAD_XLSX_LANDLORDS_ERROR:
      const _landlordsXlsxErrors = state.landlordsXlsxErrors?.filter(
        (error: IObjectKeys) => {
          return error.message !== action.payload.message;
        }
      );
      return {
        ...state,
        landlordsXlsxErrors: _landlordsXlsxErrors,
      };
    case types.ADD_MANUAL_FOLLOW_UP:
      return {
        ...state,
        landlordFollowUps: [action.payload, ...state.landlordFollowUps],
      };
    case types.SET_CURRENT_LANDLORD:
      return {
        ...state,
        currentLandlord: { ...state.currentLandlord, ...action.payload },
        // singleLandlordData: { ...state.singleLandlordData, ...action.payload },
      };
    case types.RESET_CURRENT_LANDLORD:
      return {
        ...state,
        currentLandlord: {},
        // singleLandlordData: {},
      };

    case types.GET_LANDLORDS_LIST_LANDLORD_PAGE:
    case types.GET_LANDLORDS_LIST:
      return {
        ...state,
        landlordLoading: true,
        landlordError: null,
      };
    case types.GET_LANDLORDS_LIST_SUCCESS:
      return {
        ...state,
        landlordLoading: false,
        landlords: action.payload,
      };
    case types.GET_LANDLORDS_LIST_LANDLORD_PAGE_SUCCESS:
      return {
        ...state,
        landlordLoading: false,
        landlords: action.payload[0]?.data,
        landlordsTotal: action.payload[0]?.metadata[0]?.total || 0,
      };
    case types.GET_LANDLORDS_LIST_FAIL:
      return {
        ...state,
        landlordError: action.payload,
        landlordLoading: false,
      };
    case types.CREATE_LANDLORD:
      return {
        ...state,
        landlordLoading: true,
        landlordCreateSuccess: false,
        landlordError: null,
      };
    case types.CREATE_LANDLORD_SUCCESS:
      return {
        ...state,
        landlordLoading: false,
        landlordCreateSuccess: true,
      };
    case types.CREATE_LANDLORD_FAIL:
      return {
        ...state,
        landlordError: action.payload,
        landlordLoading: false,
      };
    case types.EDIT_LANDLORD:
      return {
        ...state,
        landlordLoading: true,
        landlordEditSuccess: false,
        landlordError: null,
      };
    case types.EDIT_LANDLORD_SUCCESS:
      return {
        ...state,
        currentLandlord: { ...action.payload },
        singleLandlordData: { ...action.payload },
        landlordLoading: false,
        landlordEditSuccess: true,
      };
    case types.EDIT_LANDLORD_FAIL:
      return {
        ...state,
        landlordError: action.payload,
        landlordLoading: false,
      };
    case types.SET_SINGLE_LANDLORD_DATA:
      return {
        ...state,
        singleLandlordData: action.payload,
      };
    case types.RESET_SINGLE_LANDLORD_DATA:
      return {
        ...state,
        singleLandlordData: {},
      };
    case types.ADD_LISTING_FOR_LANDLORD:
      return {
        ...state,
        singleLandlordData: {
          ...state.singleLandlordData,
          addListingForLandlord: true,
        },
      };
    case types.GET_LANDLORD:
      return {
        ...state,
        landlordLoading: true,
        landlordError: null,
      };
    case types.GET_LANDLORD_SUCCESS:
      return {
        ...state,
        landlordLoading: false,
        landlordError: null,
        singleLandlordData: action.payload,
      };
    case types.GET_LANDLORD_FAIL:
      return {
        ...state,
        landlordLoading: false,
        landlordError: action.payload,
      };
    case types.SET_LANDLORD_LISTINGS_LAST_QUERY:
      return {
        ...state,
        listingsLastQuery: action.payload,
      };

    case types.SET_LANDLORD_LISTINGS_LOADING_TO_TRUE:
      return {
        ...state,
        listingLoading: true,
      };
    case types.SET_LANDLORD_LISTINGS_VIEW_TYPE:
      return {
        ...state,
        listingsViewType: action.payload,
      };
    case types.SET_LANDLORD_LISTINGS_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: {
          ...action.payload,
        },
      };
    case types.SET_LANDLORD_LISTINGS_PAGINATION_VALUES:
      return {
        ...state,
        paginationValues: {
          ...action.payload,
        },
      };

    case types.GET_LISTINGS_OF_LANDLORD_SUCCESS:
      return {
        ...state,
        landlordLoading: false,
        listingsListOfLandlord: action.payload.data,
        total: action.payload.metadata[0].total,
      };

    case types.GET_LISTINGS_OF_LANDLORD_FAIL:
      return {
        ...state,
        landlordLoading: false,
        landlordError: null,
      };

    case types.ADD_LANDLORD_NOTE:
      return {
        ...state,
        landlordNotesLoading: true,
        landlordNoteAddSuccess: false,
      };

    case types.ADD_LANDLORD_NOTE_SUCCESS:
      return {
        ...state,
        landlordNotes: [action.payload, ...state.landlordNotes],
        landlordNotesLoading: false,
        landlordNoteAddSuccess: true,
      };

    case types.GET_LANDLORD_NOTES:
      return {
        ...state,
        landlordNotesLoading: true,
      };
    case types.CLEAR_LANDLORD_NOTES:
      return {
        ...state,
        landlordNotes: [],
      };
    case types.GET_LANDLORD_NOTES_SUCCESS:
      return {
        ...state,
        landlordNotes: action.payload,
        landlordNotesLoading: false,
      };

    case types.GET_LANDLORD_FOLLOW_UPS:
      return {
        ...state,
        landlordFollowUpsError: null,
        landlordFollowUpsLoading: true,
      };
    case types.GET_LANDLORD_FOLLOW_UPS_SUCCESS:
      return {
        ...state,
        landlordFollowUps: action.payload,
        landlordFollowUpsLoading: false,
      };
    case types.GET_LANDLORD_FOLLOW_UPS_FAIL:
      return {
        ...state,
        landlordFollowUpsError: action.payload,
        landlordFollowUpsLoading: false,
      };

    case types.DELETE_LANDLORD_NOTE:
      return {
        ...state,
        landlordNotesLoading: true,
      };

    case types.DELETE_LANDLORD_NOTE_SUCCESS:
      const notes = state.landlordNotes.filter(
        (note: IObjectKeys) => note._id !== action.payload
      );
      return {
        ...state,
        landlordNotes: [...notes],
        landlords: state.landlords.map((e: any, i: any) => {
          e.notesOfLandlord = e.notesOfLandlord.filter(
            (note: IObjectKeys) => note._id !== action.payload
          );
          return e;
        }),
        landlordNotesLoading: false,
      };
    case types.UPDATE_LANDLORD_NOTE:
      return {
        ...state,
        landlordNoteAddSuccess: false,
        landlordNotesLoading: true,
      };
    case types.SET_LANDLORD_NOTE_ADD_SUCCESS:
      return {
        ...state,
        landlordNoteAddSuccess: action.payload,
      };
    case types.UPDATE_LANDLORD_NOTE_SUCCESS:
      let updatedNotes = state.landlordNotes.map((item: ListingNote) => {
        if (item._id === action.payload._id) {
          return { ...item, ...action.payload };
        }
        return item;
      });

      return {
        ...state,
        landlordNotes: updatedNotes,
        landlordNoteAddSuccess: true,
        landlordNotesLoading: false,
      };

    case types.DELETE_LANDLORD_NOTE_FAIL:
      return {
        ...state,
        landlordNotesError: action.payload,
      };

    case types.SEND_LANDLORD_INFO_BY_SMS:
      return {
        ...state,
        sendLandlordInfoLoading: true,
        sendLandlordInfoError: null,
      };
    case types.SEND_LANDLORD_INFO_BY_SMS_SUCCESS:
      return {
        ...state,
        sendLandlordInfoLoading: false,
      };
    case types.SEND_LANDLORD_INFO_BY_SMS_FAIL:
      return {
        ...state,
        sendLandlordInfoLoading: false,
        sendLandlordInfoError: action.payload,
      };
    case types.SET_LANDLORDS_QUERY_PARAMS:
      return {
        ...state,
        landlordsQueryParams: {
          ...action.payload,
        },
      };
    case types.TOGGLE_LANDLORD_PRIVACY:
      return {
        ...state,
        landlordLoading: true,
      };
    case types.SET_LANDLORD_LOADING_TO_TRUE:
      return {
        ...state,
        landlordLoading: true,
      };

    case types.TOGGLE_LANDLORD_PRIVACY_FAIL:
      return {
        ...state,
        landlordLoading: false,
        landlordPrivateError: action.payload,
      };
    case types.TOGGLE_LANDLORD_PRIVACY_SUCCESS:
      return {
        ...state,
        currentLandlord: {
          ...state.currentLandlord,
          is_private: !state.currentLandlord.is_private,
        },
        singleLandlordData: {
          ...state.singleLandlordData,
          is_private: !state.singleLandlordData.is_private,
        },
        landlordLoading: false,
      };
    case types.SET_LANDLORDS_VIEW_TYPE:
      return {
        ...state,
        landlordsViewType: action.payload,
      };
    case types.CHECK_EXISTING_LANDLORD_SUCCESS:
      return {
        ...state,
        isLandlordExists: action.payload,
      };
    case types.CHECK_EXISTING_LANDLORD_FAIL:
      return {
        ...state,
        isLandlordExistError: action.payload,
      };
    case types.UPDATE_LANDLORD_TAGS:
      return {
        ...state,
        landlordLoading: true,
        landlordUpdateSuccess: false,
        landlordError: null,
      };
    case types.UPDATE_LANDLORD_TAGS_SUCCESS:
      return {
        ...state,
        landlordLoading: false,
        landlordUpdateSuccess: true,
        singleLandlordData: { ...state.singleLandlordData, tags: action.payload.tags }
      };
    case types.UPDATE_LANDLORD_TAGS_FAIL:
      return {
        ...state,
        landlordError: action.payload,
        landlordLoading: false,
      };
    default:
      return state;
  }
}
