import React from "react";
import BaseButton from "../../../../BaseButton";
import { useSelector } from "react-redux";
import { listingsTotalSelector } from "../../../../../../store/listings/selectors";
import { useLocation } from "react-router";
import { leadsTotalSelector } from "../../../../../../store/leads/selectors";
import { landlordsTotalSelector } from "../../../../../../store/landlords/selectors";
import { isLeadsRoute, isListingRoute } from "helpers/clientRoutes";

const FilterModalFooter = ({
  closeModalHandler,
  handleClearAll,
}: {
  closeModalHandler: () => void;
  handleClearAll: any;
}) => {
  const { pathname } = useLocation();
  const selector = isListingRoute(pathname)
    ? listingsTotalSelector
    : (isLeadsRoute(pathname)
      ? leadsTotalSelector
      : landlordsTotalSelector);
  const total = useSelector(selector);
  return (
    <div className="p-6 flex justify-between">
      <BaseButton
        onClick={handleClearAll}>
        Clear All
      </BaseButton>
      <BaseButton
        classnames={"rb-primary-btn"}
        onClick={closeModalHandler}
        variant="primary"
      >
        <span>Show {total} Results</span>
      </BaseButton>
    </div>
  );
};

export default FilterModalFooter;
