import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import {
  DeleteNewIcon,
  FollowUpEditIcon,
  MarkAsDoneIcon,
  SnoozeIcon
} from "../icons";
import { TodoStatusEnum, INote, IFollowUpsQuery } from "./types";
import { SnoozeOptions } from "./SnoozeFollowUp";
import EditFollowUp from "./EditFollowUp";
import BaseModal from "../Shared/BaseModal";
import { ContactWithBackgroundIcon } from "../icons/ContactWithBackground";
import Triangle from "../icons/triangle/Triangle";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getLandlord, getLandlordNotes } from "store/landlords/action";
import AddFollowUpModal from "./add-follow-up-modal/AddFollowUpModal";
import { useForm } from "antd/es/form/Form";
import { deleteFollowUpSuccessSelector } from "../../store/followUps/selectors";
import { editFollowUp } from "../../store/followUps/action";
import { useWindowSize } from "../../hooks/useWindowSize";
import AddFollowUpModalMobile from "./add-follow-up-modal-mobile/AddFollowUpModalMobile";
import ArchiveFollowUpModal from "./ArchiveFollowUpModal";
import { getDeleteNoteConfirmContent } from "contentful/api";
import { IObjectKeys } from "helpers/globalTypes";
import { useDemoContext } from "libs/contextLib";
import PhoneVerifyModalFollowUps from "./all-follow-ups-modal/components/phone-verify-modal";
import { currentDate, formatDate } from "../../helpers/dateHelper";

interface IFollowUpMobileActionsMenu {
  isFromLandlordPage: any;
  followUp: INote;
  setUpdates: { (arg0: any): void; (value: any): void; (value: any): void };
  queryAndPagination: IFollowUpsQuery;
  setDropdownVisibility: {
    (arg0: { (prevState: any): any; (prevState: any): any }): void;
    (value: any): void;
    (value: any): void;
  };
  hideMobileDropdown: Function;
  setDismissFollowUpModalVisible: (arg0: (prevState: any) => boolean) => void;
  setLandlordActionModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentFollowUp?: any;
  handleDelete: any;
}
const initialModalValue = { visible: false, editModal: false };

const FollowUpMobileActionsMenu = React.forwardRef(
  (
    {
      isFromLandlordPage,
      followUp,
      queryAndPagination,
      setDismissFollowUpModalVisible,
      setDropdownVisibility,
      hideMobileDropdown,
      setUpdates,
      setLandlordActionModalVisible,
      setCurrentFollowUp
    }: IFollowUpMobileActionsMenu,
    ref
  ) => {
    const [form] = useForm();
    const [modalProps, setModalProps] = useState(initialModalValue);
    const dispatch = useDispatch();
    const [isSnoozeModalVisible, setIsSnoozeModalVisible] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);

    const { width } = useWindowSize();
    const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
    const hideDropdownVisibility = () => {
      if (isDemo) {
        demoAction("followUp");
        hideMobileDropdown();
      } else {
        hideMobileDropdown();
        if (width && width < 992) {
          setIsEditModalVisible(true);
        }
      }
    };
    const hideDropdownSnooze = () => {
      if (isDemo) {
        demoAction("followUp");
        hideMobileDropdown();
      } else {
        hideMobileDropdown();
        if (width && width < 992) {
          setIsSnoozeModalVisible(true);
        }
      }
    };
    const [isDescShown, setIsDescShown] = useState(false);
    const [archiveFollowUpModalVisible, setArchiveFollowUpModalVisible] = useState(false);
    const [deleteConfirmContent, setDeleteConfirmContent] = useState<Document | undefined>(
      undefined
    );
    const deleteFollowUpSuccess = useSelector(deleteFollowUpSuccessSelector);
    const { isDemo, demoAction } = useDemoContext();


    const handleOpenFollowUpModal = (note: any) => {
      setModalProps({ visible: true, editModal: true });
      form.setFieldsValue({
        _id: note._id,
        title: note.title,
        description: note.description,
        todo_at: note.todo_at
      });
      // setIsFollowUpEditable(true);
      if (note.description && note.description.length > 0) {
        setIsDescShown(true);
      } else {
        setIsDescShown(false);
      }
    };
    useEffect(() => {
      if (isSnoozeModalVisible) {
        setIsEditModalVisible(false);
      }
      if (isEditModalVisible) {
        setIsSnoozeModalVisible(false);
      }
    }, [isEditModalVisible, isSnoozeModalVisible]);

    useEffect(() => {
      if (deleteFollowUpSuccess) {
        setArchiveFollowUpModalVisible(false);
      }
    }, [deleteFollowUpSuccess]);

    useEffect(() => {
      (async () => {
        const entries: IObjectKeys = await getDeleteNoteConfirmContent();
        if (entries) {
          setDeleteConfirmContent(entries.fields.modalDescription);
        }
      })();
    }, []);

    const handleDelete = useCallback(() => {
      if (isDemo) {
        demoAction("followUp");
        hideMobileDropdown();
      } else {
        if (isFromLandlordPage) {
          handleDelete();
        } else {
          setArchiveFollowUpModalVisible((prevState) => !prevState);
        }
        hideMobileDropdown();
      }
    }, [isFromLandlordPage]);

    const deleteFollowUp = () => {
      setUpdates({
        ...followUp,
        archive: true,
        queryAndPagination: queryAndPagination
      });
    };

    const markAsDoneHandler = () => {
      if (isDemo) {
        demoAction("followUp");
        hideMobileDropdown();
      } else {
        setUpdates({
          ...followUp,
          todo_status: TodoStatusEnum.completed,
          queryAndPagination: queryAndPagination,
        });
        hideMobileDropdown();
        dispatch(
          editFollowUp(
            {
              ...followUp,
              todo_at: null,
              todo_status: TodoStatusEnum.completed,
            },
            followUp._id
          )
        );
      }
    };

    const editDropHandler = () => {
      if (isDemo) {
        demoAction("followUp");
      } else {
        setDropdownVisibility((prevState: any) => {
          return {
            ...prevState,
            snooze: false,
            edit: !prevState.edit,
          };
        });
        setModalProps({ visible: true, editModal: true });
        handleOpenFollowUpModal(followUp);
      }
    };
    
    const contactHandler = () => {
      if (isDemo) {
        demoAction("followUp");
        hideMobileDropdown();
      } else {
        hideMobileDropdown();
        //@ts-ignore
        dispatch(getLandlordNotes(followUp?.landlord_id?._id));
        //@ts-ignore
        dispatch(getLandlord({ _id: followUp?.landlord_id?._id }));
        setCurrentFollowUp && setCurrentFollowUp(followUp);
        setLandlordActionModalVisible && setLandlordActionModalVisible(true);
      }
    }

    return (
      //@ts-ignore
      <div ref={ref}>
        <Menu className={"follow-ups-menu-wrapper"}>
          <Triangle customClass={"follow-ups-menu-triangle"} />
          {!isFromLandlordPage && followUp?.todo_status !== "completed" && (
            <Menu.Item
              icon={<MarkAsDoneIcon />}
              onClick={markAsDoneHandler}
              data-test-id="mark-as-done"
              className="follow-ups-menu-item"
            >
              Mark as Done
            </Menu.Item>
          )}

          {
            //@ts-ignore
            followUp?.landlord_id?._id && (
              <Menu.Item
                icon={<ContactWithBackgroundIcon />}
                onClick={contactHandler}
                data-test-id="dismiss-follow-up"
                className="follow-ups-menu-item"
              >
                <span className="landlord-add-to-calendar-wrapper--col--contact-btn">
                  Contact
                </span>
              </Menu.Item>
            )
          }
          {formatDate(followUp.todo_at) === currentDate() ||
          new Date(followUp.todo_at).getTime() < new Date().getTime() ? (
            <Menu.Item
              icon={<SnoozeIcon />}
              onClick={() => {
                hideDropdownSnooze();
              }}
              data-test-id="snooze-follow-up"
              className="follow-ups-menu-item"
            >
              <>
                <Dropdown
                  getPopupContainer={() => {
                    return document.querySelector(
                      `.snooze-follow-up-btn-${followUp._id}`
                    ) as HTMLElement;
                  }}
                  overlayClassName={"follow-ups-dropdown"}
                  trigger={["click"]}
                  placement="bottomCenter"
                  disabled={isDemo}
                  overlay={
                    <SnoozeOptions
                      setUpdates={setUpdates}
                      followUp={followUp}
                      setDropdownVisibility={setDropdownVisibility}
                      queryAndPagination={queryAndPagination}
                    />
                  }
                >
                  <div className={`snooze-follow-up-btn-${followUp._id}`}>
                    <span
                      className={
                        "rb-secondary-btn sm follow-ups-menu-item-span"
                      }
                      onClick={() =>
                        isDemo
                          ? demoAction("followUp")
                          : setDropdownVisibility((prevState: any) => {
                              return {
                                ...prevState,
                                edit: false,
                                snooze: !prevState.snooze,
                              };
                            })
                      }
                    >
                      Snooze
                    </span>
                  </div>
                </Dropdown>
              </>
            </Menu.Item>
          ) : (
            <p></p>
          )}
          <Menu.Item
            icon={<DeleteNewIcon />}
            onClick={() => {
              handleDelete();
            }}
            data-test-id="mark-as-done"
            className="follow-ups-menu-item"
          >
            Delete
          </Menu.Item>
          <Menu.Item
            icon={<FollowUpEditIcon />}
            data-test-id="edit-follow-up"
            onClick={() => {
              hideDropdownVisibility();
              editDropHandler();
            }}
            className="follow-ups-menu-item"
          >
            <Dropdown
              getPopupContainer={() => {
                return document.getElementById(
                  `edit-follow-up-btn-${followUp._id}`
                ) as HTMLElement;
              }}
              trigger={["click"]}
              overlayClassName={"follow-ups-dropdown"}
              placement="bottomCenter"
              disabled={isDemo}
              overlay={
                <EditFollowUp
                  setUpdates={setUpdates}
                  followUp={followUp}
                  setDropdownVisibility={setDropdownVisibility}
                  queryAndPagination={queryAndPagination}
                />
              }
            >
              <div id={`edit-follow-up-btn-${followUp._id}`}>
                <span className={"follow-ups-menu-item-span"}>Edit</span>
              </div>
            </Dropdown>
          </Menu.Item>
        </Menu>
        <BaseModal
          classname={`${
            !isEditModalVisible && isSnoozeModalVisible
              ? "snooze-followUp-modal"
              : "edit-followUp-modal"
          }`}
          onCancel={() => {
            setIsSnoozeModalVisible(false);
            setIsEditModalVisible(false);
          }}
          modalFooter={null}
          modalTitle={`${
            !isEditModalVisible && isSnoozeModalVisible
              ? "Snooze until..."
              : "Edit Follow-up"
          }`}
          dataTestId={`${
            !isEditModalVisible && isSnoozeModalVisible
              ? "follow-up-dashboard-modal"
              : "edit-modal"
          }`}
          isModalVisible={isSnoozeModalVisible}
        >
          {!isEditModalVisible && isSnoozeModalVisible && (
            <SnoozeOptions
              setUpdates={setUpdates}
              followUp={followUp}
              setDropdownVisibility={setDropdownVisibility}
              queryAndPagination={queryAndPagination}
            />
          )}
        </BaseModal>
        {
          //@ts-ignore
          width >= 600 ? (
            <AddFollowUpModal
              visible={modalProps.visible}
              setModalProps={setModalProps}
              form={form}
              // listing={listing}
              modalProps={modalProps}
              isDescShown={false}
              isFollowUpEditable={false}
              setIsDescShown={(e: any) => {
                console.log(e);
              }}
              setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            />
          ) : (
            <AddFollowUpModalMobile
              visible={modalProps.visible}
              setModalProps={setModalProps}
              form={form}
              // listing={listing}
              modalProps={modalProps}
              isDescShown={false}
              isFollowUpEditable={false}
              setIsDescShown={(e: any) => {
                console.log(e);
              }}
              setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            />
          )
        }
        <PhoneVerifyModalFollowUps
          visible={phoneVerifyModalVisible}
          onCancel={() => setPhoneVerifyModalVisible(false)}
        />

        <ArchiveFollowUpModal
          visible={archiveFollowUpModalVisible}
          onClose={() => setArchiveFollowUpModalVisible(false)}
          onSubmit={deleteFollowUp}
          //@ts-ignore
          modalContent={deleteConfirmContent}
        />
      </div>
    );
  }
);

export default FollowUpMobileActionsMenu;
