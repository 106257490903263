import React, { useEffect } from "react";
import LeadVerifyPhone from "components/Requirement/requirement-form/components/lead-verified-section";
import { getLeadAccountLink } from "../../store/leads/action";
import { useDispatch, useSelector } from "react-redux";
import { leadAccountLinkSelector } from "store/leads/selectors";
const LeadAccount = () => {
    const dispatch  = useDispatch();
    const leadAccountLink = useSelector(leadAccountLinkSelector);
    useEffect(()=>{
        if(!leadAccountLink)
        dispatch(getLeadAccountLink());
    },[dispatch, leadAccountLink]);
    
    return <LeadVerifyPhone />;
};
export default LeadAccount;