import React, {memo, useCallback, useEffect, useState} from "react";
import {FormInstance, Select} from "antd";
import {v4 as uuid} from "uuid";
import LocationIcon from "../../../../icons/LocationIcon";
import "./LocationSearch.scss";
import {urlQueryToObject} from "../../../../../helpers/listingsCreateGetSearchQuery";
import {useLocation} from "react-router";
import {createArrayWithSelectedItemsForMultiselect} from "../../../../../helpers/filterFormHelper";

const { Option } = Select;

interface ILocationSearch {
  locations: any;
  setLocation: React.Dispatch<React.SetStateAction<any>>;
  form: FormInstance;
  showAllLocationsHandler: any;
  isLocationsSelectVisible: boolean;
  checkedLocationsArray: any;
  setCheckedLocationsArray: any;
  isClearAllClicked: boolean;
  quickSelectedValue: string
}
const LocationSearch = (props: ILocationSearch) => {
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const [selectValue, setSelectValue] = useState<any>(null);
  const singleSelectSavedData = localStorage.getItem(
    "single_selected_location_object"
  );

  useEffect(() => {
    const queryLocationData = queryObject.locations
      ? queryObject.locations
      : null;
    if (queryLocationData) {
      if (queryLocationData?.length > 1) {
        setSelectValue(
          `${queryLocationData[0]} and ${queryLocationData.length - 1} more`
        );
      } else {
        setSelectValue(queryLocationData[0]);
      }
    } else {
      setSelectValue(null);
    }
    if (singleSelectSavedData) {
      createArrayWithSelectedItemsForMultiselect(
        singleSelectSavedData,
        props.setCheckedLocationsArray
      );
    }
  }, []);

  const onSelectClickHandler = useCallback(() => {
    const queryLocationData = queryObject.locations
      ? queryObject.locations
      : [];
    if (queryLocationData?.length > 1) {
      props.showAllLocationsHandler();
    }
  }, [queryObject.locations, selectValue]);

  const onSelectChangeHandler = (value: any) => {
    const parsedValue = value ? JSON.parse(value) : "";
    const locArr = value ? [parsedValue.value] : [];
    props.setLocation(locArr);
    props.form.submit();
    setSelectValue(!!parsedValue ? parsedValue.value : null);
    if (!!parsedValue) {
      localStorage.setItem(
        "single_selected_location_object",
        parsedValue
      );
    }
    createArrayWithSelectedItemsForMultiselect(
      parsedValue,
      props.setCheckedLocationsArray
    );
  };

  useEffect(() => {
    if (props.isClearAllClicked) {
      setSelectValue(null);
      props.setCheckedLocationsArray([]);
      props.setLocation(null);
    }
  }, [props.isClearAllClicked]);

  useEffect(()=>{
    if(props.quickSelectedValue){
      setSelectValue(props.quickSelectedValue)
    }
  },[props.quickSelectedValue])
  return (
    <Select
      showSearch
      className={"location-filter-search"}
      allowClear={true}
      placeholder="Search"
      optionFilterProp="children"
      value={selectValue}
      onClick={onSelectClickHandler}
      filterOption={(input, option) =>
        ((option!.children as unknown) as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      suffixIcon={<LocationIcon />}
      filterSort={(optionA, optionB) =>
        ((optionA!.children as unknown) as string)
          .toLowerCase()
          .localeCompare(
            ((optionB!.children as unknown) as string).toLowerCase()
          )
      }
      onChange={(value) => onSelectChangeHandler(value)}
    >
      {props.locations.map((elem: any) => {
        return (
          <React.Fragment key={uuid()}>
            <Option value={JSON.stringify(elem)}>{elem.label}</Option>
            {elem.children
              ? elem?.children?.map((sub: any) => {
                  return (
                    <React.Fragment key={uuid()}>
                      {!sub.value.includes("other") ? (
                        <Option value={JSON.stringify(sub)}>{sub.label}</Option>
                      ) : null}
                      {sub?.children
                        ? sub?.children?.map((sub_sub: any) => {
                            return (
                              <React.Fragment key={uuid()}>
                                {!sub_sub.value.includes("other") ? (
                                  <Option value={JSON.stringify(sub_sub)}>
                                    {sub_sub.label}
                                  </Option>
                                ) : null}
                              </React.Fragment>
                            );
                          })
                        : null}
                    </React.Fragment>
                  );
                })
              : null}
          </React.Fragment>
        );
      })}
    </Select>
  );
};

export default memo(LocationSearch);
