import React, { useEffect, useState } from "react";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { Menu, Dropdown, Form, Tooltip } from "antd";
import { beautifiedKeyStyling, openNotification } from "../../helpers/globalFunctions";
import { TbBroadcast } from "react-icons/tb";
import { push } from "connected-react-router";
import ChangeStatusModal from "./ChangeStatusModal";
import { ListingNote } from "../Listings/helpers/mockListings";
import { useDispatch, useSelector } from "react-redux";
import {
  isPublicListingSelector,
  listingDataSelector,
  listingNoteAddSuccessSelector,
  listingNotesLoadingSelector,
} from "../../store/listings/selectors";
import { BathroomIconLead, BedroomIconLead, EditSquareIcon, MenuOptionsIcon, ShareSquareIcon } from "../icons";
import { useLocation } from "react-router-dom";
import ListingGoBackAction from "./ListingGoBackAction";
import Triangle from "../icons/triangle/Triangle";
import DropdownPencilEdit from "../icons/DropdownPencilEdit";
import ListingAddFollowUpIcon from "../icons/ListingAddFollowUpIcon";
import ListingNoteModal from "./add-listing-note-modal/ListingNoteModal";
import AddFollowUpModal from "../FollowUps/add-follow-up-modal/AddFollowUpModal";
import AllFollowUpsModal from "../FollowUps/all-follow-ups-modal/AllFollowUpsModal";
import { editedFollowUpSelector, followUpsListSelector } from "../../store/followUps/selectors";
import PhoneVerifyModalFollowUps from "../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import AddFollowUpModalMobile from "../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import { useWindowSize } from "../../hooks/useWindowSize";
import { AnalyticsService } from "helpers/analyticsService";
import IndividualLeadSharePopup from "../Shared/IndividualLeadSharePopup/IndividualLeadSharePopup";
import SyndicateListingModal from "../Shared/SyndicateListingModal";
import { useAppContext, useDemoContext } from "libs/contextLib";
import ActivateSection from "components/Shared/ActivateSection";
import { userSelector } from "../../store/user/selectors";
import { generateListingAddress } from "components/Listings/helpers/helperFunctions";
import { clearAllFuModal } from "../../store/followUps/action";
import PremiumUpgradeModal from "../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { ACTIONS, EXTERNAL_SOURCES, TRIGGER_BUTTON_LABEL, appUrl } from "helpers/constants";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import { updateListingPublishState } from "store/listings/action";
import { CrossDeleteIcon } from "components/icons/CrossDelete";
import DeleteConfirmationModal from "./delete-confirmation-modal/DeleteConfirmationModal";
import ListingGuard from "./ListingGuard";
import BaseButton from "components/Shared/BaseButton";
import { formatCurrency } from "helpers/currencyHelper";
import ListingAvailability from "components/Shared/ListingAvailability";
import { HiOutlineHome } from "react-icons/hi2";
import { renterRequirementsRoute } from "helpers/clientRoutes";
import { renterPassportSelector } from "store/renterPassport/selectors";
import { isRenter } from "helpers/userHelpers";
import { EVENT_NAME } from "helpers/analytics/eventEnums";

export interface IListingHeaderProps {
  canViewListingAgents: boolean;
  handleShowContactAgentModal: Function;
  forceShowApplyButton?: boolean;
  hideShareButton?: boolean;
  isListingAgent?: boolean;
  listingData?: {
    images: any;
    history?: any;
    agentOfList: any;
  };
}

const ListingHeader = ({
  listingData,
  canViewListingAgents,
  handleShowContactAgentModal,
  forceShowApplyButton,
  hideShareButton,
  isListingAgent,
}: IListingHeaderProps) => {
  const { width } = useWindowSize();
  const { isDemo, demoAction } = useDemoContext();
  const [isListingEditDropDown, setListingEditDropDown] = useState<boolean>(false);
  const [form] = Form.useForm();
  const listingNotesLoading = useSelector(listingNotesLoadingSelector);
  const initialModalValue = { visible: false, editModal: false };
  const listingDataStore = useSelector(listingDataSelector);
  const listing = listingData ?? listingDataStore;
  const [changeStatusModalVisible, setChangeStatusModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState(initialModalValue);
  const [followUpModalProps, setFollowUpModalProps] = useState(initialModalValue);
  const followUps = useSelector(followUpsListSelector);
  const addListingNoteSuccess = useSelector(listingNoteAddSuccessSelector);
  const isAvailable = listing.status === "Available";
  const dispatch = useDispatch();
  const [allFollowUpsModalVisible, setAllFollowUpsModalVisible] = useState(initialModalValue);
  const [isDescShown, setIsDescShown] = useState(false);
  const [isFollowUpEditable, setIsFollowUpEditable] = useState(false);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const editedFollowUp = useSelector(editedFollowUpSelector);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [syndicateModalVisible, setSyndicateModalVisible] = useState<boolean>(false);
  const isPublicListing = useSelector(isPublicListingSelector);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const userData = useSelector(userSelector);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState(false);
  const isClawListing = EXTERNAL_SOURCES.CLAW_SOURCES.includes(listing.source);
  // const isMlsListing = 'source' in listingData;
  const { isNotPremiumUser, isAuthenticated } = useAppContext();
  const renterPassport = useSelector(renterPassportSelector);
  const { free_subscription_limits } = userData?.agency || {};
  const showPremium = isNotPremiumUser && !Boolean(free_subscription_limits.listings > 0);

  const showApplyButton =
    forceShowApplyButton ||
    (isPublicListing &&
      (listing?.agent_id || listing?.agentOfList?.agent_id) &&
      userData.role !== "Owner" &&
      userData.role !== "Agent");

  // Show share listing button if user signed in and it's not public listing.
  const showShareButton = (isAuthenticated || !isPublicListing) && !hideShareButton;

  useEffect(() => {
    if (queryObject?.action === ACTIONS.ADD_INDIVIDUAL_FOLLOW_UP) {
      setFollowUpModalProps({ visible: true, editModal: false });
    }
  }, [search]);

  const closeShareModal = () => {
    setShareModalVisible(false);
  };
  const closeVerifyModalHandler = () => {
    setPhoneVerifyModalVisible(false);
  };
  const openAllFollowUpsModalHandler = () => {
    if (isDemo) {
      demoAction("listing");
    } else {
      setAllFollowUpsModalVisible({ visible: true, editModal: false });
    }
  };
  const handleOpenAddNoteModal = () => {
    if (isDemo) {
      demoAction("listing");
    } else {
      setFollowUpModalProps({ visible: true, editModal: false });
      form.resetFields();
      setIsDescShown(false);
      setIsFollowUpEditable(false);
    }
  };
  const handleOpenFollowUpModal = (note: ListingNote) => {
    if (isDemo) {
      demoAction("listing");
    } else {
      setFollowUpModalProps({ visible: true, editModal: true });
      form.setFieldsValue({
        _id: note._id,
        title: note.title,
        description: note.description,
        todo_at: note.todo_at,
      });
      setIsFollowUpEditable(true);
      if (note.description && note.description.length > 0) {
        setIsDescShown(true);
      } else {
        setIsDescShown(false);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("current_page", "listing page");
    return () => {
      localStorage.setItem("current_page", "null");
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(editedFollowUp).length > 0 &&
      //@ts-ignore
      width >= 930
    ) {
      setFollowUpModalProps({ visible: false, editModal: false });
      if (editedFollowUp.todo_status === "completed") {
        setAllFollowUpsModalVisible({ visible: false, editModal: false });
        dispatch(clearAllFuModal(editedFollowUp));
      } else {
        setTimeout(() => {
          setAllFollowUpsModalVisible({ visible: true, editModal: false });
        }, 300);
      }
    }
  }, [editedFollowUp]);

  const addNoteHandler = () => {
    if (isDemo) {
      demoAction("listing");
    } else {
      setListingEditDropDown(false);
      setModalProps({ visible: true, editModal: false });
    }
  };

  const editHandler = () => {
    if (isDemo) {
      demoAction("listing");
    } else {
      if (isClawListing) {
        openNotification("success", "Changes will not be reflected in the CLAW MLS listing");
      }
      dispatch(push(`/manageListing?lId=${listing._id}`));
    }
  };

  const publishHandler = (action: string) => {
    if (isDemo) {
      demoAction("listing");
    } else if (showPremium) {
      setPremiumModalVisible(true);
    } else {
      dispatch(updateListingPublishState({ listingId: listing._id, action }));
    }
  };

  const openDeleteConfirmModalHandler = () => {
    AnalyticsService.reportListingEvent(EVENT_NAME.LISTING_DELETE_CLICK, listing);
    setListingEditDropDown(false);
    setDeleteConfirmModalVisible(true);
  };

  const menu = (
    <Menu className={"dropdown-lsiting-edit-dots"}>
      <Triangle />
      <Menu.Item
        onClick={() => {
          AnalyticsService.reportEvent("single_listing_three_dots_menu_click", {
            label: "edit",
            category: "listing_page",
          });
          editHandler();
        }}
      >
        <DropdownPencilEdit />
        <span>Edit Listing</span>
      </Menu.Item>
      {!isClawListing && !isDemo && (
        <Menu.Item onClick={openDeleteConfirmModalHandler}>
          <CrossDeleteIcon />
          <span>Delete Listing</span>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          AnalyticsService.reportEvent("single_listing_three_dots_menu_click", {
            label: "add_note",
            category: "listing_page",
          });
          addNoteHandler();
        }}
      >
        <ListingAddFollowUpIcon />
        <span>Add Note</span>
      </Menu.Item>
      <Menu.Item>
        <span
          className="change-listing-availability-status-link"
          onClick={() => {
            isDemo ? demoAction("listing") : setChangeStatusModalVisible(true);
            AnalyticsService.reportEvent("single_listing_three_dots_menu_click", {
              label: `mark_as_${isAvailable ? "off-market" : "active"}`,
            });
          }}
        >
          Mark as {isAvailable ? <strong>Rented</strong> : <strong>Active</strong>}
        </span>
      </Menu.Item>
    </Menu>
  );

  const renderNeighborhood = (listing: any) => {
    if (!listing.neighbourhood && !listing.neighborhood) return null;
    const hood = listing.neighbourhood || listing.neighborhood;
    if (hood.toLocaleLowerCase() === listing.town.toLocaleLowerCase()) return null;

    return (
      <div className="bg-purple-100 rounded-full inline-block text-sm sm:text-base px-3 py-1">
        {startCase(toLower(hood))}
      </div>
    );
  };

  const renderListingType = (listing: any) => {
    return (
      <>
        <HiOutlineHome className="h-6 w-6 text-purple-600" />
        {beautifiedKeyStyling(listing.unit_type)}
      </>
    );
  };

  const requirementsUrl = (listing: any) => {
    if (isRenter(userData) && renterPassport.passport) {
      return `/requirements/${renterPassport.passport.agent_id}`;
    }
    return renterRequirementsRoute(listing);
  };

  useEffect(() => {
    if (addListingNoteSuccess) {
      setModalProps(initialModalValue);
    }
  }, [addListingNoteSuccess]);

  return (
    <div className="w-full p-4 sm:p-0">
      {isAuthenticated && userData.role !== "Renter" && <ListingGoBackAction />}
      {isDemo && <ActivateSection className="mb-20 mt-10" type="listing" />}
      <div className="mb-10">
        <div className={`text-lg sm:text-4xl font-bold flex flex-wrap items-center gap-2 pb-1`}>
          {Object.keys(listing).length ? generateListingAddress(listing) : ""}
          {listing.neighbourhood && renderNeighborhood(listing)}
        </div>
        <div className="flex flex-wrap justify-between mt-4 listing-availability-info-section-wrapper">
          <div className="items-center flex flex-wrap justify-start gap-4 leading-1 text-lg">
            <div className={"text-no-wrap right-margined-div"}>
              {listing.asked_rent ? formatCurrency(listing.asked_rent, undefined, "/mo") : "Rent Amount Pending"}
            </div>
            <div className="flex gap-1">
              <BedroomIconLead className="h-6 w-6 text-purple-600" />
              <div>{`${Number(listing.bedrooms) ?? ""} bed`}</div>
            </div>
            <div className="flex gap-1">
              <BathroomIconLead className="h-6 w-6 text-purple-600" />
              <div>{`${Number(listing.bathrooms) ?? ""} bath`}</div>
            </div>
            <div className="flex gap-1">{renderListingType(listing)}</div>
            {listing?.living_area_size > 0 && <div>{listing.living_area_size} sqft</div>}
            <ListingAvailability listing={listing} />
            <ListingGuard listing={listing}>
              <div className="change-listing-availability-status-link-wrapper">
                <BaseButton
                  onClick={() => {
                    AnalyticsService.reportClickEvent(EVENT_NAME.RENT_STATUS_CHANGE_CLICK, `mark_as_${isAvailable ? "off-market" : "active"}`, "");
                    isDemo ? demoAction("listing") : setChangeStatusModalVisible(true);
                  }}
                >
                  <>Mark as {isAvailable ? "Rented" : "Active"}</>
                </BaseButton>
              </div>
            </ListingGuard>
          </div>
          <div className="flex flex-wrap items-center justify-end gap-2 single-listing-actions-wrapper">
            {canViewListingAgents && !isListingAgent && (
              <div className="contact-agent-button mt-2 sm:mt-0">
                <BaseButton onClick={() => handleShowContactAgentModal(listing.agentOfList)} modalType="listing">
                  Contact Agent
                </BaseButton>
              </div>
            )}

            {showApplyButton && (
              <div className="apply-now-button mt-2 sm:mt-0">
                <BaseButton
                  variant="primary"
                  target={"blank"}
                  onClick={() => {
                    AnalyticsService.reportEvent("set_up_a_showing", {
                      label: "public_listing",
                    });
                    requirementsUrl(listing);
                    dispatch(push(requirementsUrl(listing)));
                  }}
                  data-test-id="apply-btn"
                >
                  <span>Apply Now</span>
                </BaseButton>
              </div>
            )}

            <ListingGuard listing={listing} condition={true}>
              {!isClawListing && isAvailable && (
                <Tooltip
                  overlayClassName={"fu-page"}
                  placement="top"
                  title={listing?.published_rdt ? "Listing is currently syndicated" : "Click to syndicate this listing"}
                >
                  <span
                    data-test-id="syndicate-modal-button"
                    onClick={() => {
                      AnalyticsService.reportClickEvent(EVENT_NAME.SYNDICATE_CLICK, "syndicate_button", "");
                      isDemo ? demoAction("listing") : setSyndicateModalVisible(true);
                    }}
                  >
                    <TbBroadcast className="h-8 w-8" stroke={listing?.published_rdt ? "#3edeb8" : "#ccc"} />
                  </span>
                </Tooltip>
              )}
              <Tooltip overlayClassName={"fu-page"} placement="top" title="Edit listing">
                <span
                  onClick={() => {
                    AnalyticsService.reportClickEvent(EVENT_NAME.LISTING_EDIT_CLICK, "edit_listing_button", "");
                    editHandler();
                  }}
                  data-test-id="share-listing-btn"
                >
                  <EditSquareIcon />
                </span>
              </Tooltip>
            </ListingGuard>
            {showShareButton && (
              <Tooltip overlayClassName={"fu-page"} placement="top" title="Share listing">
                <span
                  data-test-id="share-listing-button"
                  onClick={() => {
                    AnalyticsService.reportClickEvent(EVENT_NAME.SHARE_LISTING_CLICK, "share_listing_button", "");
                    isDemo ? demoAction("listing") : setShareModalVisible(true);
                  }}
                >
                  <ShareSquareIcon />
                </span>
              </Tooltip>
            )}
            <ListingGuard>
              <Tooltip overlayClassName={"fu-page"} placement="top" title="More options">
                <div>
                  <Dropdown
                    visible={isListingEditDropDown}
                    onVisibleChange={(visible) => {
                      visible && AnalyticsService.reportClickEvent(EVENT_NAME.ELIPSES_MENU_CLICK, "", "");
                      setListingEditDropDown(visible);
                    }}
                    overlay={menu}
                    trigger={["click"]}
                    placement={"bottomRight"}
                    className={"single-listing-more-options-dropdown"}
                    overlayClassName={"listing-edit-dropdown-mobile"}
                  >
                    <span onClick={() => setListingEditDropDown(true)} data-test-id="single-listing-edit-dropdown">
                      <MenuOptionsIcon />
                    </span>
                  </Dropdown>
                </div>
              </Tooltip>
            </ListingGuard>
          </div>
        </div>
      </div>
      <ChangeStatusModal
        visible={changeStatusModalVisible}
        setVisible={setChangeStatusModalVisible}
        isAvailable={isAvailable}
      />
      <ListingNoteModal
        modalProps={modalProps}
        listingNotesLoading={listingNotesLoading}
        form={form}
        setModalProps={setModalProps}
        initialModalValue={initialModalValue}
        listing={listing}
      />
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            listing={listing}
            isDescShown={isDescShown}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpModalProps}
            form={form}
            modalProps={followUpModalProps}
            listing={listing}
            isDescShown={isDescShown}
            isFollowUpEditable={isFollowUpEditable}
            setIsDescShown={setIsDescShown}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          />
        )
      }
      <AllFollowUpsModal
        visible={allFollowUpsModalVisible.visible}
        setVisible={setAllFollowUpsModalVisible}
        handleOpenAddFollowUpModal={handleOpenAddNoteModal}
        notes={followUps}
        handleOpenFollowUpEditModal={handleOpenFollowUpModal}
        setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
      />
      <IndividualLeadSharePopup visible={shareModalVisible} onClose={closeShareModal} isFromListingsPage={true} />
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={closeVerifyModalHandler} />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_ALL_FOLLOW_UPS_MODAL}
      />
      <DeleteConfirmationModal
        visible={deleteConfirmModalVisible}
        setVisible={setDeleteConfirmModalVisible}
        listing_id={listing._id}
      />
      <SyndicateListingModal
        visible={syndicateModalVisible}
        handleAction={(value: string) => publishHandler(value)}
        onClose={() => setSyndicateModalVisible(false)}
        listing={listing}
        isDemo={isDemo}
      />
    </div>
  );
};
export default ListingHeader;
