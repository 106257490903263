import React from 'react';
import {Col, Row} from "antd";
import CreateTeam from "components/CreateTeam";
const CreateTeamView = () => {
    return (
        <Row className="flex-centered">
            <Col span={24}>
                <CreateTeam/>
            </Col>
        </Row>
    );
};
export default CreateTeamView;