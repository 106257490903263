import { RootStateOrAny } from "react-redux";

export const followUpsListSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.followUpsList;
export const followUpsLoadingSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.followUpsLoading;
export const followUpsListTotalSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.total;
export const followUpsErrorSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.followUpsError;
export const updateFollowUpSuccessSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.updateFollowUpSuccess;
export const deleteFollowUpSuccessSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.deleteFollowUpSuccess;
export const addFollowUpSuccessSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.addFollowUpSuccess;
export const editFollowUpSuccessSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.editFollowUpSuccess;
export const queryAndPaginationSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.queryAndPagination;
export const addedFollowUpSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.addedFollowUp;
export const followUpsLoadingListingSelector = (state: RootStateOrAny) =>
  state.followUpsReducer.followUpsLoadingListing;
export const followUpSnoozeSuccessSelector = (state: RootStateOrAny) =>
    state.followUpsReducer.snoozeSuccess;
export const snoozedFollowUpSelector = (state:RootStateOrAny) =>
    state.followUpsReducer.snoozedFollowUp;
export const editedFollowUpSelector = (state:RootStateOrAny) =>
    state.followUpsReducer.editedFollowUp;
export const singleFuSelector = (state:RootStateOrAny) =>
    state.followUpsReducer.singleFU