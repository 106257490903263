import { RootStateOrAny } from 'react-redux';

export const landlordCreateSuccessSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordCreateSuccess;
export const landlordEditSuccessSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordEditSuccess;
export const currentLandlordSelector = (state: RootStateOrAny) => state.landlordsReducer.currentLandlord;
export const landlordsListSelector = (state: RootStateOrAny) => state.landlordsReducer.landlords;
export const landlordsTotalSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordsTotal;
export const landlordLoadingSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordLoading;
export const landlordErrorSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordError;

export const singleLandlordDataSelector = (state: RootStateOrAny) => state.landlordsReducer.singleLandlordData;
export const listingsListOfLandlordSelector = (state: RootStateOrAny) => state.landlordsReducer.listingsListOfLandlord;
export const totalListingsListOfLandlordSelector = (state: RootStateOrAny) => state.landlordsReducer.total;

export const landlordNoteErrorSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordNotesError;
export const landlordNoteAddSuccessSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordNoteAddSuccess;
export const landlordNotesLoadingSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordNotesLoading;

export const landlordFollowUpsSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordFollowUps;
export const landlordFollowUpsLoadingSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordFollowUpsLoading;
export const landlordFollowUpsErrorSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordFollowUpsError;

export const sendLandlordInfoLoadingSelector = (state: RootStateOrAny) => state.landlordsReducer.sendLandlordInfoLoading;
export const sendLandlordInfoErrorSelector = (state: RootStateOrAny) => state.landlordsReducer.sendLandlordInfoError;

export const getLandlordsXlsxErrorsSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordsXlsxErrors;
export const downloadXlsxTemplateLoadingSelector = (state: RootStateOrAny) => state.landlordsReducer.downloadXlsxTemplateLoading;

export const landlordsQueryParamsSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordsQueryParams;

export const landlordListingsViewTypeSelector = (state: RootStateOrAny) =>
  state.landlordsReducer.listingsViewType;
export const landlordListingsFilterOptionsSelector = (state: RootStateOrAny) =>
  state.landlordsReducer.filterOptions;
export const landlordListingsPaginationValuesSelector = (state: RootStateOrAny) =>
  state.landlordsReducer.paginationValues;

export const isLandlordPrivateSelector = (state: RootStateOrAny) =>
  state.landlordsReducer.singleLandlordData.is_private;

export const landlordsViewTypeSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordsViewType;
export const landlordNotesSelector = (state: RootStateOrAny) => state.landlordsReducer.landlordNotes;
export const landlordUploadSuccessSelector = (state: RootStateOrAny) => state.landlordsReducer.uploadLandlordSuccess;
export const isLandlordExistSelector = (state:RootStateOrAny) => state.landlordsReducer.isLandlordExists;