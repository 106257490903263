import LeadsApi from "api/Leads";
import SRReportTabs from "components/Shared/ReportModal/ReportTabs";
import { useScreeningReport } from "hooks/useScreeningReport";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ScreeningReportsHeader from "./header";
import "./styles.scss";

function ScreeningReports() {
  const params: any = useParams();
  const { requirmentId, srrId } = params;
  const { loading, handleTabChange, handlePrintReport, getSRReport } = useScreeningReport({
    srrId
  });

  const [leadDetails, setLeadDetails] = React.useState<any>(null);

  const getRequirementDetails = async () => {
    try {
      await LeadsApi.getLeadData(requirmentId)
        .then((res) => {
          setLeadDetails(res);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (srrId) {
      getSRReport();
    }

    if (requirmentId) {
      getRequirementDetails();
    }
  }, []);

  return (
    <>
      <ScreeningReportsHeader leadDetails={leadDetails} />
      <SRReportTabs
        loading={loading}
        showActions
        handleTabChange={handleTabChange}
        handlePrintReport={handlePrintReport}
      />
    </>
  );
}

export default ScreeningReports;
