import React from "react";

export const BrowseFilesIcon = () => {
  return (
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.0312 9.9375H26.5C25.9791 9.9375 25.4641 9.82717 24.989 9.61378C24.5138 9.40039 24.0892 9.08877 23.7432 8.69945L19.3193 3.72656C18.9737 3.33649 18.5492 3.0241 18.0741 2.80999C17.5989 2.59587 17.0837 2.4849 16.5625 2.48438H4.96875C4.425 2.48437 3.88656 2.59148 3.3842 2.79956C2.88184 3.00765 2.42538 3.31264 2.04089 3.69714C1.26437 4.47365 0.828125 5.52684 0.828125 6.625V35.6094C0.828125 36.1531 0.935225 36.6916 1.14331 37.1939C1.3514 37.6963 1.65639 38.1527 2.04089 38.5372C2.42538 38.9217 2.88184 39.2267 3.3842 39.4348C3.88656 39.6429 4.425 39.75 4.96875 39.75H25.5584C24.8316 38.111 24.5257 36.3165 24.6684 34.5292C24.8111 32.742 25.3978 31.0187 26.3754 29.5158C27.3531 28.0129 28.6905 26.7779 30.2665 25.923C31.8424 25.0681 33.607 24.6203 35.3999 24.6203C37.1928 24.6203 38.9573 25.0681 40.5332 25.923C42.1092 26.7779 43.4467 28.0129 44.4243 29.5158C45.4019 31.0187 45.9887 32.742 46.1313 34.5292C46.274 36.3165 45.9681 38.111 45.2413 39.75H48.0312C48.575 39.75 49.1134 39.6429 49.6158 39.4348C50.1182 39.2267 50.5746 38.9217 50.9591 38.5372C51.3436 38.1527 51.6486 37.6963 51.8567 37.1939C52.0648 36.6916 52.1719 36.1531 52.1719 35.6094V14.0781C52.1719 12.98 51.7356 11.9268 50.9591 11.1503C50.1826 10.3737 49.1294 9.9375 48.0312 9.9375Z"
        fill="#F5F5F5"
      />
      <path
        d="M35.4082 42.8613C39.5244 42.8613 42.8613 39.5244 42.8613 35.4082C42.8613 31.292 39.5244 27.9551 35.4082 27.9551C31.292 27.9551 27.9551 31.292 27.9551 35.4082C27.9551 39.5244 31.292 42.8613 35.4082 42.8613Z"
        fill="#F5F5F5"
      />
      <path
        d="M48.0312 40.5781C47.8116 40.5781 47.601 40.4909 47.4457 40.3356C47.2904 40.1803 47.2031 39.9696 47.2031 39.75C47.2031 39.5304 47.2904 39.3197 47.4457 39.1644C47.601 39.0091 47.8116 38.9219 48.0312 38.9219C48.9098 38.9219 49.7523 38.5729 50.3735 37.9517C50.9948 37.3305 51.3438 36.4879 51.3438 35.6094V14.0781C51.3438 13.1996 50.9948 12.357 50.3735 11.7358C49.7523 11.1146 48.9098 10.7656 48.0312 10.7656H26.5C25.8622 10.7655 25.2317 10.6303 24.6499 10.3689C24.0681 10.1076 23.5483 9.72603 23.1246 9.24933L18.6999 4.2723C18.4314 3.97066 18.1022 3.72921 17.7338 3.5638C17.3655 3.39838 16.9663 3.31274 16.5625 3.3125H4.96875C4.09022 3.3125 3.24767 3.66149 2.62646 4.28271C2.00524 4.90392 1.65625 5.74647 1.65625 6.625V35.6094C1.65625 36.4879 2.00524 37.3305 2.62646 37.9517C3.24767 38.5729 4.09022 38.9219 4.96875 38.9219H22.3594C22.579 38.9219 22.7896 39.0091 22.9449 39.1644C23.1003 39.3197 23.1875 39.5304 23.1875 39.75C23.1875 39.9696 23.1003 40.1803 22.9449 40.3356C22.7896 40.4909 22.579 40.5781 22.3594 40.5781H4.96875C3.65136 40.5768 2.3883 40.0529 1.45677 39.1214C0.52523 38.1898 0.00131542 36.9268 0 35.6094L0 6.625C0.00131542 5.30761 0.52523 4.04455 1.45677 3.11302C2.3883 2.18148 3.65136 1.65757 4.96875 1.65625H16.5625C17.2003 1.65639 17.8308 1.79158 18.4126 2.05293C18.9944 2.31427 19.5142 2.69585 19.9379 3.17255L24.3626 8.14958C24.6311 8.45121 24.9603 8.69266 25.3287 8.85808C25.697 9.02349 26.0962 9.10913 26.5 9.10938H48.0312C49.3486 9.11069 50.6117 9.6346 51.5432 10.5661C52.4748 11.4977 52.9987 12.7607 53 14.0781V35.6094C52.9987 36.9268 52.4748 38.1898 51.5432 39.1214C50.6117 40.0529 49.3486 40.5768 48.0312 40.5781Z"
        fill="#141414"
      />
      <path
        d="M46.375 7.45312C46.1554 7.45312 45.9447 7.36588 45.7894 7.21057C45.6341 7.05527 45.5469 6.84463 45.5469 6.625C45.5469 5.74647 45.1979 4.90392 44.5767 4.28271C43.9555 3.66149 43.1129 3.3125 42.2344 3.3125H22.3594C22.1397 3.3125 21.9291 3.22525 21.7738 3.06995C21.6185 2.91464 21.5312 2.70401 21.5312 2.48438C21.5312 2.26474 21.6185 2.05411 21.7738 1.8988C21.9291 1.7435 22.1397 1.65625 22.3594 1.65625H42.2344C43.5518 1.65757 44.8148 2.18148 45.7464 3.11302C46.6779 4.04455 47.2018 5.30761 47.2031 6.625C47.2031 6.84463 47.1159 7.05527 46.9606 7.21057C46.8053 7.36588 46.5946 7.45312 46.375 7.45312Z"
        fill="#141414"
      />
      <path
        d="M48.2904 51.6031C47.8553 51.6042 47.4244 51.5191 47.0224 51.3526C46.6204 51.1862 46.2554 50.9417 45.9485 50.6334L40.9168 45.6008C39.0258 46.6219 36.8881 47.0971 34.7426 46.9732C32.5971 46.8494 30.5283 46.1313 28.7674 44.8994C27.0065 43.6675 25.6228 41.9702 24.771 39.9971C23.9192 38.0241 23.6328 35.853 23.9438 33.7266C24.2548 31.6001 25.151 29.6021 26.5322 27.9556C27.9134 26.3092 29.7252 25.0792 31.7652 24.4032C33.8052 23.7272 35.9929 23.6318 38.084 24.1275C40.1751 24.6233 42.0872 25.6908 43.6065 27.2107C45.3776 28.9715 46.5295 31.2594 46.8893 33.7308C47.249 36.2021 46.7971 38.7235 45.6015 40.9161L50.6332 45.9487C51.0963 46.412 51.4117 47.0022 51.5395 47.6448C51.6672 48.2873 51.6015 48.9533 51.3508 49.5585C51.1 50.1637 50.6754 50.681 50.1307 51.045C49.5859 51.4089 48.9455 51.6031 48.2904 51.6031ZM41.0518 43.7359C41.1606 43.7358 41.2685 43.7572 41.3691 43.7988C41.4697 43.8405 41.5611 43.9015 41.6381 43.9785L47.1194 49.4607C47.4352 49.7617 47.8546 49.9296 48.2908 49.9296C48.727 49.9296 49.1465 49.7617 49.4622 49.4607C49.6161 49.3069 49.7381 49.1243 49.8214 48.9233C49.9047 48.7223 49.9475 48.5069 49.9475 48.2894C49.9475 48.0718 49.9047 47.8564 49.8214 47.6554C49.7381 47.4544 49.6161 47.2718 49.4622 47.118L43.98 41.6358C43.8482 41.504 43.7647 41.3315 43.7431 41.1464C43.7215 40.9612 43.7629 40.7742 43.8608 40.6155C45.0419 38.7154 45.544 36.4711 45.2851 34.2488C45.0263 32.0266 44.0218 29.9577 42.4356 28.38C41.4456 27.3929 40.2593 26.6249 38.9536 26.1255C37.6478 25.6262 36.2517 25.4068 34.8557 25.4816C33.0305 25.5827 31.2685 26.1853 29.7636 27.2231C28.2587 28.261 27.0692 29.6938 26.3261 31.364C25.5829 33.0342 25.3149 34.877 25.5514 36.6897C25.788 38.5024 26.5199 40.2147 27.6668 41.6383C29.1908 43.5134 31.3434 44.7719 33.7251 45.1801C36.1067 45.5882 38.5556 45.1183 40.617 43.8576C40.748 43.7782 40.8985 43.7366 41.0518 43.7376V43.7359Z"
        fill="#141414"
      />
      <path
        d="M35.4079 43.6855C34.0455 43.6864 32.704 43.3511 31.5022 42.7094C30.3004 42.0677 29.2755 41.1394 28.5184 40.0068C27.7612 38.8742 27.2951 37.5723 27.1615 36.2165C27.0279 34.8607 27.2308 33.4929 27.7523 32.2343C28.2739 30.9757 29.0978 29.8652 30.1512 29.0012C31.2046 28.1372 32.4549 27.5465 33.7912 27.2814C35.1276 27.0162 36.5087 27.0849 37.8121 27.4813C39.1155 27.8776 40.3011 28.5894 41.2636 29.5536C42.422 30.7112 43.211 32.1865 43.5307 33.7927C43.8504 35.399 43.6864 37.0639 43.0595 38.5769C42.4326 40.0899 41.3709 41.3829 40.0089 42.2923C38.6468 43.2016 37.0456 43.6865 35.4079 43.6855ZM35.4079 28.7876C33.8767 28.7891 32.3933 29.3216 31.2106 30.2941C30.0278 31.2667 29.2189 32.6192 28.9215 34.1213C28.6241 35.6234 28.8567 37.1821 29.5797 38.532C30.3027 39.8818 31.4713 40.9392 32.8864 41.5241C34.3016 42.109 35.8758 42.1851 37.3407 41.7396C38.8057 41.294 40.0709 40.3543 40.9208 39.0805C41.7707 37.8068 42.1526 36.2778 42.0016 34.754C41.8506 33.2302 41.176 31.8059 40.0926 30.7237C39.4778 30.1083 38.7474 29.6204 37.9435 29.2882C37.1395 28.9559 36.2778 28.7858 35.4079 28.7876Z"
        fill="#141414"
      />
      <path
        d="M32.2969 36.4375C32.7542 36.4375 33.125 36.0667 33.125 35.6094C33.125 35.152 32.7542 34.7812 32.2969 34.7812C31.8395 34.7812 31.4688 35.152 31.4688 35.6094C31.4688 36.0667 31.8395 36.4375 32.2969 36.4375Z"
        fill="#141414"
      />
      <path
        d="M35.6094 36.4375C36.0667 36.4375 36.4375 36.0667 36.4375 35.6094C36.4375 35.152 36.0667 34.7812 35.6094 34.7812C35.152 34.7812 34.7812 35.152 34.7812 35.6094C34.7812 36.0667 35.152 36.4375 35.6094 36.4375Z"
        fill="#141414"
      />
      <path
        d="M38.9219 36.4375C39.3792 36.4375 39.75 36.0667 39.75 35.6094C39.75 35.152 39.3792 34.7812 38.9219 34.7812C38.4645 34.7812 38.0938 35.152 38.0938 35.6094C38.0938 36.0667 38.4645 36.4375 38.9219 36.4375Z"
        fill="#141414"
      />
    </svg>
  );
};
