import React from "react";
import { Link } from "react-router-dom";

const RenterLinkColumn = (_id: string, rowData: any) => {
  return {
    children: (
      <Link to={`/leads/${rowData.requirement?._id}`}>
        <div className="listing-table-address-column">
          <span>{rowData.renter?.name}</span>
        </div>
      </Link>
    ),
    props: {
      "data-test-id": "renter",
    },
  };
};

export default RenterLinkColumn;
