import ShareableApi from "api/Shareable";
import BaseButton from "components/Shared/BaseButton";
import { openNotification } from "helpers/globalFunctions";
import { useHistory } from "react-router-dom";
import React from "react";
import { userSelector } from "store/user/selectors";
import { useSelector } from "react-redux";
import { isRenter } from "helpers/userHelpers";
import { LuAlertCircle } from "react-icons/lu";
import { HiEye } from "react-icons/hi2";

export const LeadScreeningRequestActionColumn = ({ rowData }: { rowData: any }) => {
  const user = useSelector(userSelector);
  const [disableRemind, setDisableRemind] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const handleViewReport = () => {
    let prefix = "";
    if (isRenter(user)) {
      prefix = "/renter-portal";
    }
    history.push(`${prefix}/screening-reports/${rowData.requirement?._id}/${rowData.screening_request_renter_id}`);
  };

  const handleRemind = async () => {
    try {
      setLoading(true);
      const payload = {
        screening_request_id: `${rowData.screening_request_id}`,
        renter_id: rowData.renter.renter_id,
        agent_id: rowData.agent.agent_id,
      };
      await ShareableApi.sendScreeningRequestReminder(payload).then((res) => {
        setDisableRemind(true);
        setLoading(false);
        openNotification("success", "Reminder sent successfully");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const status = rowData.screening_status;

  const isRemindable = ["Created", "VerificationPending", "VerificationCompleted", "ManualVerification"].includes(
    status
  );

  if (isRemindable) {
    return disableRemind ? (
      <BaseButton type={"button"} disabled={true}>
        Reminder Sent
      </BaseButton>
    ) : (
      <BaseButton variant="secondary" type={"button"} onClick={handleRemind} loading={loading}>
        Remind
      </BaseButton>
    );
  }

  if (status === "ReportAvailable" && rowData.screening_request_renter_id) {
    return (
      <BaseButton
        classnames={"ant-btn rb-secondary-btn rb-secondary-btn-without-shadow"}
        type={"button"}
        onClick={handleViewReport}
      >
        View
      </BaseButton>
    );
  }

  return <div></div>;
};
