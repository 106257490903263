import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Form } from "antd";
import TimePicker from "react-time-picker";
import { CalendarIcon } from "../../../../../icons";
import { ISO_STRING, US_DATE_FORMAT, US_DATE_FORMAT_WITH_TIME } from "../../../../../../helpers/constants";
import "./SnoozeCalendar.scss";
import { useDispatch } from "react-redux";
import { snoozeFollowUp } from "../../../../../../store/followUps/action";
import { snoozeDashboardFollowUp } from "../../../../../../store/dashboard/action";
import { getBrowser } from "../../../../../../helpers/globalFunctions";
import BaseButton from "../../../../../Shared/BaseButton";
import MozTimePicker from "../../../../add-follow-up-modal/date-picker-section/moz/MozTimePicker";
import {
  getStartOfUnitFormatted,
  addTimeAndFormatDate,
  formatDate,
  currentDate,
} from "../../../../../../helpers/dateHelper";

interface ISnoozeCalendar {
  setDatePickerValue: any;
  followUp: any;
  isFromDashboard?: boolean | null;
}
const SnoozeCalendar = ({ setDatePickerValue, followUp, isFromDashboard }: ISnoozeCalendar) => {
  const dispatch = useDispatch();
  const [showingData, setShowingData] = useState(getStartOfUnitFormatted(currentDate(), "year", "MMM D,YYYY"));
  const [timeData, setTimeData] = useState("08:00 AM");
  const [time, setTime] = useState("");
  const [calendarDay, setCalendarDay] = useState<string | any>(
    addTimeAndFormatDate(currentDate(), { amount: 1, unit: "days" }, undefined, US_DATE_FORMAT)
  );

  const onCalendarTimeChangeHandler = (timeValue: any) => {
    // setShowingData(true);
    setTimeData(timeValue);
  };
  useEffect(() => {
    setDatePickerValue(
      new Date(
        `${formatDate(calendarDay, US_DATE_FORMAT)} ${moment(timeData, ["h:mm A"]).format("HH:mm:ss")}`
      ).toISOString()
    );
  }, [time, calendarDay, setDatePickerValue, timeData]);
  // @ts-ignore
  return (
    <div className={"pick-follow-up-time-modal-wrapper pick-follow-up-time-modal-wrapper--snooze  "}>
      <p className={"snooze-dropdown-title"}>When would you like to be reminded?</p>
      <div className={"pick-follow-up-time-modal-wrapper--picker"}>
        <div className={"snooze-dropdown-wrapper"}>
          <Form.Item>
            <DatePicker
              suffixIcon={<CalendarIcon />}
              getPopupContainer={(triggerNode: { parentNode: any }) => triggerNode.parentNode}
              onChange={(date: any) => {
                setShowingData(formatDate(date, "MMM D,YYYY"));
                setCalendarDay(new Date(formatDate(date, getBrowser())));
              }}
              defaultValue={addTimeAndFormatDate(currentDate(), {
                amount: 1,
                unit: "days",
              })}
              open={true}
              showToday={false}
              dropdownClassName={"ant-picker-for-followups"}
              className="w-full"
              placeholder={"Ex. 01/01/2024"}
              format={`${US_DATE_FORMAT}`}
            />
          </Form.Item>
        </div>
      </div>
      {/*<div className={"pick-follow-up-time-modal-wrapper--showing-data"}>*/}
      {/*  <span>{showingData}</span>*/}
      {/*</div>*/}
      {navigator.userAgent.indexOf("Firefox") != -1 ? (
        <MozTimePicker setTimeData={setTimeData} />
      ) : (
        <TimePicker
          onChange={onCalendarTimeChangeHandler}
          value={timeData ? timeData : new Date()}
          locale={"en-US"}
          disableClock={true}
          clearIcon={<></>}
        />
      )}
      <div className={"pick-follow-up-time-modal-wrapper--snooze--bottomSection"}>
        <BaseButton
          classnames={"pick-follow-up-snooze-date-btn rb-primary-btn"}
          //@ts-ignore
          onClick={() => {
            isFromDashboard
              ? dispatch(
                  snoozeDashboardFollowUp(
                    {
                      ...followUp,
                      todo_at: formatDate(
                        new Date(formatDate(`${formatDate(calendarDay)}, ${timeData}`, US_DATE_FORMAT_WITH_TIME)),
                        ISO_STRING
                      ),
                    },
                    followUp._id
                  )
                )
              : dispatch(
                  snoozeFollowUp(
                    {
                      ...followUp,
                      todo_at: formatDate(
                        new Date(formatDate(`${formatDate(calendarDay)}, ${timeData}`, US_DATE_FORMAT_WITH_TIME)),
                        ISO_STRING
                      ),
                    },
                    followUp._id
                  )
                );
          }}
        >
          Save
        </BaseButton>
      </div>
    </div>
  );
};

export default SnoozeCalendar;
