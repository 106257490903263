import React from "react";
import RentalSearch from "./Components/RentalSearch";

const RentalPortalSearch = () => {
  return (
    <div className="mx-auto max-w-8xl">
      <div className="mx-auto pb-16 grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
          <div className="py-10">
            <h1 className="text-2xl font-semibold text-purple-900">Rental Search</h1>
          </div>
          <RentalSearch defaultListings={[]} />
        </div>
      </div>
    </div>
  );
};

export default RentalPortalSearch;
