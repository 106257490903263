import React, { forwardRef } from "react";
import TriangleIcon from "../../../../assets/images/triangle.png";
import "./styles.scss";
import { listingsSortDropdownOptions, ALGOLIA_SORT_FIELD } from "../../../../helpers/constants";
import { FilterAndPaginationOptI } from "../../../Listings/helpers/mockListings";
import { filterOptionsSelector } from "../../../../store/listings/selectors";
import { useSelector } from "react-redux";
import { createFilterQuery, urlQueryToObject } from "../helpers/listingsCreateGetSearchQuery";
import { useHistory } from "react-router-dom";
import { getQueryParams } from "../../../../helpers/globalFunctions";
interface IListingSortOverlayProps extends FilterAndPaginationOptI {
  setIsSortDropDown: React.Dispatch<React.SetStateAction<boolean>>;
}

const ListingSortOverlay = forwardRef((props: IListingSortOverlayProps, ref: any) => {
  const history = useHistory();
  const filterOptions = useSelector(filterOptionsSelector);
  const _newURLSearchParams = new URLSearchParams(history.location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const filterOptionsWithoutSortOption = { ...filterOptions };
  const queryObjectWithoutSortOption = { ...queryObject };
  const sortBy = getQueryParams(history?.location?.search)?.algoliaSortField;

  const handleSortItemClick = (sortByArg: string) => {
    filterOptionsWithoutSortOption["pets_allowed"] = queryObject.pets_allowed;
    queryObjectWithoutSortOption["pets_allowed"] = queryObject.pets_allowed;
    queryObjectWithoutSortOption["parking_type"] = queryObject.parking_type;
    filterOptionsWithoutSortOption["parking_type"] = queryObject.parking_type;
    filterOptionsWithoutSortOption["locations"] = queryObject.locations;
    queryObjectWithoutSortOption["locations"] = queryObject.locations;
    filterOptionsWithoutSortOption[ALGOLIA_SORT_FIELD] = sortByArg;
    queryObjectWithoutSortOption[ALGOLIA_SORT_FIELD] = sortByArg;
    const newSearchQuery = createFilterQuery(
      props.viewType,
      { ...filterOptionsWithoutSortOption },
      { ...props.pagination },
      { ...queryObjectWithoutSortOption }
    );
    props.handleSubmit && props.handleSubmit(newSearchQuery);
  };

  return (
    <div ref={ref} className="listing-sort-overlay">
      <div className="landlords-dropdown-btn-wrapper flex-start">
        {listingsSortDropdownOptions.map((option) => {
          return (
            <span
              className={`sort-hover text-left ${option.sortBy === sortBy ? "sort-By" : ""}`}
              key={option.key}
              onClick={() => {
                props.setIsSortDropDown(false);
                handleSortItemClick(option.sortBy);
              }}
            >
              {option.title}
            </span>
          );
        })}
      </div>
    </div>
  );
});

export default ListingSortOverlay;
