import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import locationTick from "assets/images/location.svg";
interface GoogleMapProps {
  center: { lng: number; lat: number };
  zoom: number;
  onLoad?: (props: any) => void;
  onUnmount?: (props: any) => void;
  containerStyle?: any;
}

const GoogleMapComponent = (props: GoogleMapProps) => {
  return (
    <GoogleMap
      mapContainerStyle={props.containerStyle}
      center={props.center}
      zoom={props.zoom}
      onLoad={props?.onLoad}
      onUnmount={props?.onUnmount}
      options={{ scrollwheel: false }}
    >
      <Marker
        icon={locationTick}
        position={{
          lat: props.center.lat,
          lng: props.center.lng,
        }}
      />
    </GoogleMap>
  );
};
export default GoogleMapComponent;
