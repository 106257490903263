import BaseButton from "components/Shared/BaseButton";
import SectionHeading from "components/Shared/Section/SectionHeading";
import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const ScreeningReportsHeader = ({ leadDetails }: any) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div>
      <div>
        <div className="my-4">
          <BaseButton variant="link" onClick={goBack} className="flex items-center justify-start my-4">
            <>
              <FaChevronLeft />
              Go Back
            </>
          </BaseButton>
          <SectionHeading heading="Screening Reports" />
          <p className="name">{leadDetails?.renter?.name}</p>
        </div>
      </div>
    </div>
  );
};

export default ScreeningReportsHeader;
