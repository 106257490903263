import React from "react";

const SuccessWhite = () => {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.5 0.119629C2.91592 0.119629 0 3.03547 0 6.61959C0 10.2037 2.91592 13.1196 6.5 13.1196C10.0841 13.1196 13 10.2037 13 6.61959C13 3.03547 10.0841 0.119629 6.5 0.119629ZM10.2324 5.5217L6.1488 9.60529C5.97516 9.77893 5.74435 9.8745 5.49882 9.8745C5.25328 9.8745 5.02247 9.77893 4.84884 9.60529L2.76759 7.52405C2.59395 7.35042 2.49831 7.11961 2.49831 6.87408C2.49831 6.62848 2.59395 6.39767 2.76759 6.22403C2.94115 6.0504 3.17197 5.95476 3.41757 5.95476C3.6631 5.95476 3.89398 6.0504 4.06754 6.2241L5.49875 7.65523L8.93232 4.22168C9.10596 4.04804 9.33677 3.95247 9.5823 3.95247C9.82783 3.95247 10.0586 4.04804 10.2323 4.22168C10.5908 4.5802 10.5908 5.1633 10.2324 5.5217Z"
                fill="white"/>
        </svg>
    )
}

export default SuccessWhite