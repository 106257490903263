import { useForm } from "antd/es/form/Form";
import AddTeamModal from "components/CreateTeam/AddTeamModal";
import LockIcon from "components/Leads/icons/LockIcon";
import PremiumUpgradeModal from "components/Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import BaseButton from "components/Shared/BaseButton";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { openNotification } from "helpers/globalFunctions";
import { useAppContext } from "libs/contextLib";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { windowSelector } from "store/app/selectors";
import { inviteAgentSuccessSelector } from "store/teams/selectors";
import { userSelector } from "store/user/selectors";
import "./EmptyPageAddTeamMember.scss";
import { setInviteAgentToFalse } from "store/teams/action";

const EmptyPageAddTeamMember = () => {
  const [form] = useForm();
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const [addTeamModalVisible, setAddTeamModalVisible] = useState(false);
  const userData = useSelector(userSelector);
  const { type } = useSelector(windowSelector);
  const { isNotPremiumUser } = useAppContext();
  const { free_subscription_limits } = userData.agency;
  const getInviteTeamsSuccess = useSelector(inviteAgentSuccessSelector);
  const showPremium = isNotPremiumUser && !Boolean(free_subscription_limits.team_members > 0);
  const dispatch = useDispatch();

  const handleAddTeamBtnClick = useCallback(() => {
    if (showPremium) {
      setPremiumModalVisible(true);
    } else {
      setAddTeamModalVisible(true);
      form.resetFields();
    }
  }, [form, showPremium]);

  useEffect(() => {
    if (getInviteTeamsSuccess) {
      setAddTeamModalVisible(false);
      openNotification("success", "👍 Invite sent successfully");
      dispatch(setInviteAgentToFalse());
    }
  }, [getInviteTeamsSuccess]);

  return (
    <div className={"empty-page-add-team-member"}>
      <div className={"empty-state-landlord-wrapper"}>
        <div className={"form--main-title"}>
          <BaseButton variant="primary" dataTestId="team-button" onClick={handleAddTeamBtnClick} modalType="team">
            <>
              {showPremium && <LockIcon />}
              {type === "mobile" ? " Add" : " Add Team Member"}
            </>
          </BaseButton>
        </div>
      </div>
      {addTeamModalVisible && (
        <AddTeamModal form={form} visible={addTeamModalVisible} onClose={() => setAddTeamModalVisible(false)} />
      )}
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_TEAM_MEMBER}
      />
    </div>
  );
};

export default EmptyPageAddTeamMember;
