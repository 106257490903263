import RenterView from "pages/Renter";
import React from "react";

const LeadsSingleRequirement = () => {  
    return (
        <>
            <RenterView />
        </>
    );
};

export default LeadsSingleRequirement;