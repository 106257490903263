import React, {useEffect} from "react";
import {Layout} from 'antd';
import ListingForm from "../../components/ListingView/ListingForm";
import {useDispatch ,useSelector} from "react-redux";
import {getEnums} from "../../store/enums/action";
import {clearListingData} from "../../store/listings/action";

const {Content} = Layout;

const ManageListing = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getEnums());
    }, [])
    useEffect(() => {
       
        
        return () => {
            dispatch(clearListingData())
        }
    }, [])
    return (
        <Content>
            <ListingForm />
        </Content>
    );
};
export default ManageListing;
