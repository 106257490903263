import LandlordDashboardIcon from "components/icons/landlordDashboardIcon";
import RenterDashboardIcon from "components/icons/renterDashboardIcon";
import { TransparentHomeIcon } from "components/icons/TransparetHome";
import { IObjectKeys } from "helpers/globalTypes";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./RelatedEntity.scss";
import BaseButton from "components/Shared/BaseButton";

const RelatedEntity = ({ followUp, classNames }: IObjectKeys) => {
  const [type, setType] = useState("");
  const entities: IObjectKeys = {
    lead: {
      icon: <RenterDashboardIcon />,
      text: "For Lead",
      name: followUp?.renterOfFollowUp?.name,
      link: "leads/" + followUp?.requirements_id,
    },
    landlord: {
      icon: <LandlordDashboardIcon />,
      text: "For Landlord",
      name: followUp?.landlordOfFollowUp?.name,
      link: "landlords/" + followUp?.landlord_id,
    },
    listing: {
      icon: <TransparentHomeIcon />,
      text: "For Listing",
      name: followUp?.listingOfFollowUp?.generatedAddr,
      link: "listings/" + followUp?.listingOfFollowUp?._id,
    },
  };

  useEffect(() => {
    if (followUp.listingOfFollowUp) {
      setType("listing");
    } else if (followUp.landlordOfFollowUp) {
      setType("landlord");
    } else if (followUp.renterOfFollowUp && followUp.requirements_id) {
      setType("lead");
    }
    return () => {
      setType("");
    };
  }, [followUp]);

  return (
    <>
      {type && (
        <div className={`text-base flex items-center justify-normal gap-2 address-info-wrapper ${classNames}`}>
          {entities[type].icon}
          {entities[type].text}
          <BaseButton variant="link" href={`/${entities[type].link}`}>
            {entities[type].name}
          </BaseButton>
        </div>
      )}
    </>
  );
};

export default RelatedEntity;
