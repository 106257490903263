import React, { memo, useCallback, useEffect, useState } from "react";
import Form, { FormInstance } from "antd/es/form";
import { useLocation } from "react-router";
import BaseButton from "../../../../BaseButton";
import "./StatusWrapper.scss";
import FilterFormTitle from "../filter-form-title/FilterFormTitle";
import { LISTING_STATUS_OPTIONS } from "helpers/constants";
import { isLeadsRoute, isListingRoute } from "helpers/clientRoutes";
interface IStatusWrapper {
  form: FormInstance<any>;
  isClearAllClicked: boolean;
}

const StatusWrapper = ({ form, isClearAllClicked }: IStatusWrapper) => {
  const [statusValues, setStatusValues] = useState(LISTING_STATUS_OPTIONS);
  const [selectedStatus, setSelectedStatus] = useState("");
  const location = useLocation();
  const defaultSelectedStatus =
    isLeadsRoute(location.pathname)
      ? localStorage.getItem("availabilityFilterStateLeads")
      : localStorage.getItem("availabilityFilterState");

  useEffect(() => {
    if (!defaultSelectedStatus) {
      if (isLeadsRoute(location.pathname)) {
        localStorage.setItem("availabilityFilterStateLeads", "All");
      } else {
        localStorage.setItem("availabilityFilterState", "Show-all");
      }
    }
  }, [location, defaultSelectedStatus]);

  useEffect(() => {
    if (isLeadsRoute(location.pathname)) {
      setStatusValues([
        { text: "All", status: "All" },
        { text: "Actively Searching", status: "Actively-Searching" },
        { text: "Not searching", status: "Not-Searching" },
      ]);
    } else {
      setStatusValues(LISTING_STATUS_OPTIONS);
    }
  }, [location]);

  useEffect(() => {
    if (defaultSelectedStatus) {
      setSelectedStatus(defaultSelectedStatus);
      form.setFieldsValue({ status: defaultSelectedStatus });
    } else {
      setSelectedStatus("Show-all");
      form.setFieldsValue({ status: "Show-all" });
    }
  }, [defaultSelectedStatus]);

  const setStatusHandler = useCallback((elem) => {
    setSelectedStatus(elem);
    form.setFieldsValue({ status: elem });
    form.submit();
  }, []);

  useEffect(() => {
    if (isClearAllClicked) {
      if (isListingRoute(location.pathname)) {
        setSelectedStatus("All");
        form.setFieldsValue({ status: "All" });
      } else {
        setSelectedStatus("Show-all");
        form.setFieldsValue({ status: "Show-all" });
      }
      form.submit();
    }
  }, [isClearAllClicked, location.pathname]);
  return (
    <div className={"status-action"}>
      <FilterFormTitle title={isLeadsRoute(location.pathname) ? "Leasing Status" : "Listing Status"} />
      <Form.Item name={"status"} trigger={"onBlur"}>
        {statusValues.map((elem, index) => {
          return (
            <BaseButton
              key={index}
              classnames={`status-action-buttons 
                                    ${selectedStatus === elem.status ? "status-action-buttonsActive" : null}`}
              onClick={() => setStatusHandler(elem.status)}
            >
              {elem.text}
            </BaseButton>
          );
        })}
      </Form.Item>
    </div>
  );
};

export default memo(StatusWrapper);
