import React, { useEffect, useState } from "react";
import LeadFlowWrapper from "../lead-flow-wrapper";
import "./styles.scss";
import VerifyPhone from "./verify-phone";
import { useSelector } from "react-redux";
import { leadAccountLinkSelector } from "store/leads/selectors";
import { verifyLeadPhoneSuccessSelector } from "store/user/selectors";
import VerifyEmail from "./verify-email/VerifyEmail";

const LeadVerifyPhone = () => {
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const leadAccountLink = useSelector(leadAccountLinkSelector);
  const verifyLeadPhoneSuccess = useSelector(verifyLeadPhoneSuccessSelector);

  useEffect(() => {
    if (leadAccountLink?.phone_verified || verifyLeadPhoneSuccess) setIsPhoneVerified(true);
  }, [leadAccountLink, verifyLeadPhoneSuccess]);

  return (
    <LeadFlowWrapper>
      <div className="lead-verified-section">
        <VerifyEmail isPhoneVerified={isPhoneVerified} leadAccountLink={leadAccountLink} />
        {!isPhoneVerified && (
          <div className={"lead-verified-section-phone-section"}>
            <div className={"lead-verified-section-separator"} />
            <VerifyPhone setIsPhoneVerified={setIsPhoneVerified} existingPhoneNumber={leadAccountLink?.phone} />
          </div>
        )}
      </div>
    </LeadFlowWrapper>
  );
};

export default LeadVerifyPhone;
