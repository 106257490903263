export const scrollableInputs: String[] = [
    "lease_status",
    "method_contact",
    "people_living_with_you",
    "credit_score",
    "parking_spots",
    "listing_viewed",
    "listing_applied",
    "bathrooms",
    "bedrooms",
    "employed_people"
];


export const supportedStates: Array<string> = ["Massachusetts", "Illinois"];
export const editRequirementSetFields: Array<string> = [
    "available_times",
    "budget_max",
    "budget_min",
    "contact_preference",
    "credit_score",
    "must_have",
    "bathrooms_min_req",
    "bedrooms_req",
    "deal_breaker",
    "employed_tenants",
    "has_cosigner",
    "considering_buying",
    "lease_status",
    "listings_applied",
    "listings_viewed",
    "number_of_parking",
    "number_of_tenants",
    "parking",
    "student_or_employed",
    "other",
    "job_location",
    "job_position",
    "yearly_income",

];
export const editRequirementMultiSelectFields: Array<string> = [
    "must_have",
    "deal_breaker",
];
export const editRequirementDates:Array<string> = [
    "available_times"
];

export const editRequirementRangeFields = [
    "bathrooms_min_req",
    "bedrooms_req"
];
