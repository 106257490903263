import React, { ChangeEvent, useEffect, useState } from "react";
import "./ContactInfoStep.scss";
import { Form, Input } from "antd";
import {
  EMAIL_PLACEHOLDER,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
  REQUIRED,
  REQUIRED_EMAIL_VALIDATION,
  REQUIRED_PHONE,
} from "../../../../../../../helpers/validations";
import MaskedInput from "../../../../../../Shared/MaskedPhoneInput";
import BaseButton from "../../../../../../Shared/BaseButton";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  renterStatusLoadingSelector,
  renterStatusSelector,
  requirementFormDataSelector,
} from "../../../../../../../store/requirement/selectors";
import { clearReqError, createDraftRenter, updateDraftRenter } from "../../../../../../../store/requirement/action";
import { sanitizePhoneNumber } from "../../../../../../../helpers/globalFunctions";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../../../../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { push } from "connected-react-router";
import qs from "query-string";

interface IContactInfoStep {
  edittedData: any;
  isEditMode: boolean;
  isNewRenter: boolean;
  form: any;
  setIsNewRenter: any;
  setIsLoginFormVisible: any;
  showExistError: any;
  setShowExistError: any;
  changeStep: any;
}

const ContactInfoStep = ({
  edittedData,
  isEditMode,
  isNewRenter,
  form,
  setIsNewRenter,
  setIsLoginFormVisible,
  showExistError,
  setShowExistError,
  changeStep,
}: IContactInfoStep) => {
  const dispatch = useDispatch();
  const renterStatusLoading = useSelector(renterStatusLoadingSelector);
  const renterStatus = useSelector(renterStatusSelector);
  const { search, pathname } = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const { agent_id } = useParams();
  const requirementFormData = useSelector(requirementFormDataSelector);
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const changeNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const changeEmailHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(clearReqError());
    setEmail(event.target.value);
    setShowExistError(false);
  };
  const changePhoneHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const openModalHandler = () => {
    setIsLoginFormVisible(true);
    localStorage.setItem("requested_agent_id", pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length));
  };

  const contactInfoFormSubmitHandler = (sourceType: string | null) => {
    form
      .validateFields()
      .then((values: any) => {
        if (renterStatus && Object.keys(renterStatus).length > 0) {
          return values;
        } else {
          const emailValue = form.getFieldValue("email");
          const nameValue = form.getFieldValue("name");
          const phoneValue = form.getFieldValue("phone");
          if (queryObject.previewMode) {
            queryObject.reqStep = 2;
            dispatch(push(qs.stringify(queryObject)));
          } else {
            if (sourceType === "renter_realtor") {
              dispatch(
                updateDraftRenter({
                  ...requirementFormData,
                  email: emailValue,
                  name: nameValue,
                  phone: sanitizePhoneNumber(phoneValue),
                })
              );
            } else {
              dispatch(
                createDraftRenter({
                  email: emailValue,
                  name: nameValue,
                  phone: sanitizePhoneNumber(phoneValue),
                  agent_id: agent_id,
                })
              );
            }
          }
        }
      })
      .catch((errorInfo: any) => {
        console.log(errorInfo);
      });
  };

  const [buttonContent, setButtonContent] = useState({
    text: queryObject?.is_manual ? "Next" : "Submit",
    click: contactInfoFormSubmitHandler,
  });

  useEffect(() => {
    if (form.getFieldValue("name")) {
      setName(form.getFieldValue("name"));
    }
    if (form.getFieldValue("phone")) {
      setPhone(form.getFieldValue("phone"));
    }
    if (form.getFieldValue("email")) {
      setEmail(form.getFieldValue("email"));
    }
  }, []);

  useEffect(() => {
    if (renterStatus) {
      if (!renterStatus.account_verified && !renterStatus.exists) {
        setIsNewRenter(true);
      }
      if (renterStatus.exists) {
        setIsNewRenter(false);
        setShowExistError(true);
      }
    } else {
      setShowExistError(false);
    }
  }, [renterStatus]);

  useEffect(() => {
    if (requirementFormData) {
      if (Object.keys(requirementFormData).length && requirementFormData.phone) {
        setButtonContent({
          text: "Next",
          click: () => {
            changeStep(2);
          },
        });
      }
    } else {
      setButtonContent({
        text: "Submit",
        click: contactInfoFormSubmitHandler,
      });
    }
  }, [renterStatus, showExistError, requirementFormData]);

  return (
    <div className={"contact-info-step"}>
      <Form.Item name="name" label={"Full name"} data-test-id={"name"} rules={[REQUIRED]} validateTrigger={"onSubmit"}>
        <Input
          value={name}
          autoComplete={"off"}
          placeholder="Ex. John Doe"
          data-test-id={"name-input"}
          defaultValue={edittedData.name}
          disabled={
            isEditMode || isNewRenter || (!!Object.keys(requirementFormData).length && !requirementFormData.exists)
          }
          onChange={changeNameHandler}
        />
      </Form.Item>
      <Form.Item
        data-test-id={"phone"}
        name="phone"
        label={"Phone number"}
        rules={[REQUIRED_PHONE]}
        validateTrigger={"onSubmit"}
      >
        <MaskedInput
          inputMode="numeric"
          disabled={
            isEditMode || isNewRenter || (!!Object.keys(requirementFormData).length && requirementFormData.phone)
          }
          defaultValue={edittedData.phone}
          mask={PHONE_NUMBER_MASK}
          placeholder={PHONE_NUMBER_PLACEHOLDER}
          value={phone}
          onChange={changePhoneHandler}
        />
      </Form.Item>
      <div className={`${showExistError ? "req-email-exist-input" : "req-email-not-exist-input"}`}>
        <Form.Item
          rules={[REQUIRED_EMAIL_VALIDATION]}
          name="email"
          label={"Email"}
          data-test-id={"email"}
          validateTrigger={"onSubmit"}
        >
          <Input
            defaultValue={edittedData.email}
            autoComplete={"off"}
            placeholder={EMAIL_PLACEHOLDER}
            data-test-id={"input_email"}
            disabled={isEditMode || isNewRenter || !!Object.keys(requirementFormData).length}
            value={email}
            onChange={changeEmailHandler}
          />
        </Form.Item>
        {showExistError &&
          (!queryObject?.is_manual ? (
            <p>
              An account with this email already exists, please{" "}
              <span className={"req-login-error"} onClick={openModalHandler}>
                sign-in.
              </span>{" "}
              to continue
            </p>
          ) : (
            <p>An account with this email already exists.</p>
          ))}
      </div>
      <>
        <Form.Item className={"text-center req-form-next-btn-wrapper"} shouldUpdate>
          {() => (
            <BaseButton
              variant="primary"
              type="submit"
              disabled={showExistError}
              loading={renterStatusLoading || false}
              onClick={() => {
                buttonContent.click(requirementFormData.Type);
              }}
            >
              {buttonContent.text}
            </BaseButton>
          )}
        </Form.Item>
        {!queryObject?.is_manual && (
          <p className={"contact-info-step-already-text text-sm"}>
            Already have a RentBase profile? <span onClick={openModalHandler}>Log-in</span>
          </p>
        )}
        <div className={"terms-and-privacy text-sm"}>
          {queryObject.is_manual ? (
            <span>
              By clicking next, your lead will be notified that they’ve been added to the RentBase system and that
              you’ve begun filling out their requirements.
            </span>
          ) : (
            <>
              <span>By submitting your requirements you confirm that you've read and agree to our </span>
              <BaseButton variant="link">
                <Link to="/terms" target={"_blank"}>
                  terms and conditions.
                </Link>
              </BaseButton>
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default ContactInfoStep;
