import React from "react";

export const VirtualTourIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3803 2.75003C18.6171 2.75003 17.9961 2.13311 17.9961 1.37502C17.9961 0.616923 18.6171 0 19.3803 0C20.1434 0 20.7645 0.616923 20.7645 1.37502C20.7645 2.13311 20.1434 2.75003 19.3803 2.75003Z"
        fill="#141414"
      />
      <path
        d="M15.92 8.25006H14.9972C14.3614 8.25006 13.8438 7.73581 13.8438 7.10421V3.89585C13.8438 3.26426 14.3614 2.75 14.9972 2.75H15.92C16.5558 2.75 17.0735 3.26426 17.0735 3.89585V7.10421C17.0735 7.73581 16.5558 8.25006 15.92 8.25006ZM15.2279 6.87505H15.6893V4.12502H15.2279V6.87505Z"
        fill="#141414"
      />
      <path
        d="M7.61487 8.25003H6.69209C6.31005 8.25003 6 7.94203 6 7.56252C6 7.18302 6.31005 6.87502 6.69209 6.87502H7.38417V6.18751H6.69209C6.31005 6.18751 6 5.87951 6 5.50001C6 5.1205 6.31005 4.8125 6.69209 4.8125H7.61487C8.25066 4.8125 8.76834 5.32675 8.76834 5.95834V7.10419C8.76834 7.73578 8.25066 8.25003 7.61487 8.25003ZM7.61487 6.18751H7.62409H7.61487Z"
        fill="#141414"
      />
      <path
        d="M7.61487 6.18753H6.69209C6.31005 6.18753 6 5.87953 6 5.50002C6 5.12052 6.31005 4.81252 6.69209 4.81252H7.38417V4.12501H6.69209C6.31005 4.12501 6 3.81701 6 3.43751C6 3.058 6.31005 2.75 6.69209 2.75H7.61487C8.25066 2.75 8.76834 3.26425 8.76834 3.89584V5.04169C8.76834 5.67328 8.25066 6.18753 7.61487 6.18753ZM7.61487 4.12501H7.62409H7.61487Z"
        fill="#141414"
      />
      <path
        d="M11.7677 8.25006H10.8449C10.2091 8.25006 9.69141 7.73581 9.69141 7.10421V3.89585C9.69141 3.26426 10.2091 2.75 10.8449 2.75H12.2291C12.6111 2.75 12.9211 3.058 12.9211 3.43751C12.9211 3.81701 12.6111 4.12502 12.2291 4.12502H11.0756V4.81252H11.7677C12.4035 4.81252 12.9211 5.32678 12.9211 5.95837V7.10421C12.9211 7.73581 12.4035 8.25006 11.7677 8.25006ZM11.0756 6.87505H11.537V6.18754H11.0756V6.87505Z"
        fill="#141414"
      />
      <path
        d="M18.9188 6.61475V7.10425C18.9188 8.34085 18.1557 9.40328 17.0732 9.85245V12.4301L18.1806 12.2101C20.0815 11.8343 22.6099 11.1193 22.6099 9.30428C22.6099 8.1401 21.3928 7.26192 18.9188 6.61475Z"
        fill="#141414"
      />
      <path
        d="M4.1545 7.56253C4.1545 7.20686 4.23201 6.86953 4.36674 6.56152C1.75066 7.21328 0.463379 8.10796 0.463379 9.30422C0.463379 11.1192 2.9918 11.8342 4.89272 12.2101L6.00006 12.4301V9.97614C4.93794 9.67456 4.1545 8.71296 4.1545 7.56253Z"
        fill="#141414"
      />
      <path
        d="M14.2735 17.894L11.5181 20.7174C11.2302 21.0098 10.8602 21.1721 10.4717 21.1748C10.0758 21.1464 9.7224 21.0245 9.44095 20.7449C9.44095 20.7449 7.53725 18.9519 7.49481 18.9941L5.12695 21.3307C7.04264 21.7798 9.32191 21.9998 11.5366 21.9998C13.8445 21.9998 16.2271 21.7643 18.1889 21.2748L14.2735 17.894Z"
        fill="#141414"
      />
      <path
        d="M11.5367 14.2086C6.70876 14.2086 2.53133 13.2974 0.463379 11.9673V17.9954C0.463379 19.2869 1.73774 20.2449 3.63313 20.9012C3.65712 20.8664 3.67281 20.827 3.70326 20.7967L6.51774 18.0192C7.09448 17.4472 7.9988 17.4481 8.57462 18.021L10.4082 19.7609L13.2439 16.9586C13.8308 16.3747 14.701 16.349 15.2786 16.8898L19.6074 20.6785C19.6508 20.716 19.6766 20.7646 19.708 20.8095C21.4557 20.156 22.6101 19.2274 22.6101 17.9963V11.9682C20.5422 13.2974 16.3647 14.2086 11.5367 14.2086ZM10.614 17.4169C10.1046 17.4169 9.69118 17.0063 9.69118 16.5003C9.69118 15.9942 10.1046 15.5836 10.614 15.5836C11.1233 15.5836 11.5367 15.9942 11.5367 16.5003C11.5367 17.0063 11.1233 17.4169 10.614 17.4169Z"
        fill="#141414"
      />
    </svg>
  );
};
