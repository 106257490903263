import React from "react";
import { Button, Col, Dropdown, Row } from "antd";
import { LandlordsSortOverlay } from "../LandlordsSortOverlay";
import { SortIcon } from "../../../icons";
import ShowingPagination from "components/ShowingPagination";

interface ILandlordsListHeaderDesktop {
  handleChangeShowingCount: (count: number) => void;
  landlordsList: any;
  landlordsTotal: any;
  isDropdownVisible: boolean;
  landlordsSortOverlayRef: React.MutableRefObject<null>;
  setIsDropdownVisible: any;
  landlordsSortButtonRef: any;
}

const LandlordsListHeaderDesktop = ({
  handleChangeShowingCount,
  landlordsList,
  landlordsTotal,
  isDropdownVisible,
  landlordsSortOverlayRef,
  setIsDropdownVisible,
  landlordsSortButtonRef,
}: ILandlordsListHeaderDesktop) => {
  return (
    <Row className="landlords-sort-button-row landlords-sort-button-row-desktop">
      <ShowingPagination list={landlordsList} total={landlordsTotal} />
      <Col className="flex align-center">
        {/* <Dropdown
          visible={isDropdownVisible}
          overlayClassName={"landlords-sort-dropdown-overlay with-triangle"}
          trigger={["click"]}
          getPopupContainer={() => {
            return document.getElementById(`landlords-sort-btn`) as HTMLElement;
          }}
          overlay={<LandlordsSortOverlay ref={landlordsSortOverlayRef} handleChangeVisibility={setIsDropdownVisible} />}
          placement="bottomRight"
        >
          <Button
            onClick={(e) => {
              setIsDropdownVisible((prev: any) => !prev);
            }}
            data-test-id="sort-btn-12345467"
            className="filter-icon-btn landlords-sort-btn"
            id="landlords-sort-btn"
            ref={landlordsSortButtonRef}
          >
            <SortIcon />
          </Button>
        </Dropdown> */}
      </Col>
    </Row>
  );
};

export default LandlordsListHeaderDesktop;
