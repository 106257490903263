import { IObjectKeys } from 'helpers/globalTypes';
import React from 'react';
export const Success = (props: IObjectKeys) => {
  return (
    <svg width={props.width || `33`} height={props.height || `32`} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M16.3253 32C25.3415 32 32.6506 24.8366 32.6506 16C32.6506 7.16344 25.3415 0 16.3253 0C7.3091 0 0 7.16344 0 16C0 24.8366 7.3091 32 16.3253 32Z" fill="#b79adb" />
        <path d="M12.1538 23.2176L20.5482 31.4447C27.5006 29.6277 32.6506 23.4195 32.6506 15.9999C32.6506 15.8485 32.6506 15.6971 32.6506 15.5457L26.0587 9.58984L12.1538 23.2176Z" fill="#BA464C" />
        <path d="M16.7373 19.5836C17.4582 20.2902 17.4582 21.5015 16.7373 22.2082L15.2438 23.6719C14.5228 24.3785 13.2868 24.3785 12.5658 23.6719L6.02536 17.2113C5.30437 16.5047 5.30437 15.2933 6.02536 14.5867L7.51885 13.123C8.23984 12.4164 9.47583 12.4164 10.1968 13.123L16.7373 19.5836Z" fill="white" />
        <path d="M22.4536 8.42902C23.1746 7.72239 24.4106 7.72239 25.1316 8.42902L26.6251 9.89274C27.3461 10.5994 27.3461 11.8107 26.6251 12.5173L15.2952 23.571C14.5742 24.2776 13.3382 24.2776 12.6172 23.571L11.1238 22.1072C10.4028 21.4006 10.4028 20.1893 11.1238 19.4826L22.4536 8.42902Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32.6506" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};