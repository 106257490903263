import { getAuthToken } from "./authUtils";
import { IObjectKeys } from "./globalTypes";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";

export const PROFILE_TYPES_ARR = ["landlord", "tenant", "agent"];
export const FULL_MONTHS_OF_YEAR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const SHORT_MONTHS_OF_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const ENUM_TYPES = {
  SINGLE: "single",
  MULTIPLE: "multi",
  BOOLEAN: "boolean",
};

export const ROLE_TYPES = {
  ADMIN: "Admin",
  OWNER: "Owner",
  AGENT: "Agent",
  RENTER: "Renter",
};
// export const ACCEPTED_MEDIA_FILES = '.png,.jpg,.png.jpeg.gif';
export const ACCEPTED_MEDIA_FILES = ["image/png", "image/jpg", "image/jpeg", "image/gif", ".png,.jpg,.png.jpeg.gif"];
export const ACCEPTED_FILE_SIZE = 1024 * 1024 * 10; // 10mb
export const LISTING_MEDIA_UPLOAD_API = process.env.REACT_APP_BASE_URL + "/listing/media";
export const DEFAULT_USER_AVATAR = "/images/user-placeholder.jpg";

export const GET_NOTIFICATIONS_INTERVAL = 1 * 60 * 1000; // 1 min
export const AUTH_HEADERS = {
  Authorization: "Bearer " + getAuthToken(),
};

export const address_fields: IObjectKeys = {
  street_number: "street_number",
  street_name: "route",
  state: "administrative_area_level_1",
  short_state: "administrative_area_level_1",
  town: "locality",
  postal_code: "postal_code",
};

export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";

export const envPrefix = process.env.REACT_APP_ENV_PREFIX || "";
export const gtmKey = process.env.REACT_APP_GTM_KEY || "";
export const gtm_auth = process.env.REACT_APP_GTM_AUTH || "";
export const gtm_preview = process.env.REACT_APP_GTM_PREVIEW || "";
export const tagManagerArgs = {
  gtmId: gtmKey,
};
export const FROM_DEMO_URL_PREFIX = "?from_demo=true";

export const googleMapsApiLibrariesArray: Libraries = ["places"];

export const isProduction = process.env.NODE_ENV === "production";

export const isProdEnv = process.env.REACT_APP_NODE_ENV === "production";

export const stripeYearlyPaymentId = process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID;
export const stripeMonthlyPaymentId = process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID;

export const contentfulAccessKey: string = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || "";
export const contentfulSpaceId: string = process.env.REACT_APP_CONTENTFUL_SPACE_ID || "";
export const flatFileIoKey: string = process.env.REACT_APP_FLATFILE_IO_KEY || "";

export const appUrl: string = process.env.REACT_APP_URL || "";
export const shortAppUrl = "therentbase.com";

export const US_TIME_FORMAT = "hh:mm A";
export const US_DATE_FORMAT = "MM/DD/YYYY";
export const US_DATE_FORMAT_WITH_TIME = "MM/DD/YYYY hh:mm A";
export const ISO_STRING = "toISOString";

export const momentSet8Am = {
  hour: 8,
  minute: 0,
  second: 0,
};

export const priceDropdownRanges = [
  ["2000", "4000"],
  ["2500", "4500"],
  ["3500", "5000"],
];

export const sortByAvailableFields = ["created_at_order", "asked_rent_order", "bedrooms_order"];

export const listingsSortDropdownOptions = [
  {
    key: 1,
    title: "Newest",
    sortBy: "created_at",
  },
  {
    key: 2,
    title: "Availability",
    sortBy: "availability_date",
  },
  {
    key: 3,
    title: "Price : Low to High",
    sortBy: "asked_rent_ask",
  },
  {
    key: 4,
    title: "Price : High to Low",
    sortBy: "asked_rent_desc",
  },
];

export const ALGOLIA_SORT_FIELD = "algoliaSortField";

export const GOOGLE_AUTH_NOT_FOUND_ERROR =
  "#error_description=Invalid+lambda+function+output+%3A+Invalid+JSON+&error=invalid_request";
export const GOOGLE_AUTH_REDIRECT_ERROR = "#error_description=Already+found";
export const GOOGLE_LOGIN_LINK = `${process.env.REACT_APP_AWS_DOMAIN_URI}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_BASE_URL}/auth/google/callback&response_type=TOKEN&client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&scope=email openid profile`;

export const COGNITO_LOGOUT_ENDPOINT = `${process.env.REACT_APP_AWS_DOMAIN_URI}/logout?response_type=code&identity_provider=claw-mls&client_id=${process.env.REACT_APP_AWS_CLAW_MLS_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_URI}`;

// CLAW user logout from Cognito: required so that Cognito unsets the browser user ID cookie
// https://rentbase.auth.us-east-2.amazoncognito.com/logout?response_type=code&identity_provider=claw-mls&client_id=dgp2fvg2jua72eorajgtojcii&logout_uri=http://localhost:3000/claw/saml-auth
export const COGNITO_CLAW_LOGOUT_ENDPOINT = `${process.env.REACT_APP_AWS_DOMAIN_URI}/logout?response_type=code&identity_provider=claw-mls&client_id=${process.env.REACT_APP_AWS_CLAW_MLS_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_CLAW_LOGOUT_URI}`;

// CLAW SAML authorize endpoint
// https://rentbase.auth.us-east-2.amazoncognito.com/authorize?response_type=code&identity_provider=claw-mls&client_id=dgp2fvg2jua72eorajgtojcii&redirect_uri=http://localhost:3000/callbacks/claw
export const COGNITO_CLAW_AUTHORIZE_ENDPOINT = `${process.env.REACT_APP_AWS_DOMAIN_URI}/authorize?response_type=code&identity_provider=claw-mls&client_id=${process.env.REACT_APP_AWS_CLAW_MLS_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_CLAW_REDIRECT_URI}`;

export const TRIGGER_BUTTON_LABEL = {
  ADD_A_FOLLOW_UP_AFTER_CALL_MODAL: "add_a_follow_up_after_call_modal",
  ADD_A_FOLLOW_UP_TOP_BUTTON: "add_a_follow_up_top_button",
  ADD_A_FOLLOW_UP_BOTTOM_BUTTON: "add_a_follow_up_bottom_button",
  ADD_A_FOLLOW_UP_ALL_FOLLOW_UPS_MODAL: "add_a_follow_up_all_follow_ups_modal",
  ADD_A_FOLLOW_UP: "add_a_follow_up",
  ADD_A_LEAD: "add_a_lead",
  ADD_A_TEAM_MEMBER: "add_a_member",
  SHARE_WITH_A_LEAD: "share_with_a_lead",
  LISTING_RENEWALS: "listing_renewals",
  UPGRADE_NOW_BILLING: "upgrade_now_billing",
  TRY_AGAIN: "try_again",
};

// Billing content
export const CHANGE_TO_YEARLY = "Save 40%";

export const ACTIONS = {
  ADD_LEAD: "add_lead",
  ADD_FOLLOW_UP: "add_follow_up",
  ADD_INDIVIDUAL_FOLLOW_UP: "add_individual_follow_up",
  LISTING_RENEWALS: "listing_renewals",
  UPGRADE_NOW_BILLING: "upgrade",
};
export const LISTING_AVAILABILITY_CIRCLES_STATUS = [
  {
    status: "Show-all",
    text: "All",
    children: [
      { type: "available", tooltipText: "Showing Available listings" },
      { type: "off-market", tooltipText: "Showing Off-Market listings" },
    ],
  },
  {
    status: "Available",
    text: "Available",
    children: [{ type: "available", tooltipText: "Showing Available listings" }],
  },
  {
    status: "Off-market",
    text: "Off Market",
    children: [{ type: "off-market", tooltipText: "Showing Off-Market listings" }],
  },
];

export const LISTING_STATUS_OPTIONS = [
  { text: "All", status: "Show-all" },
  { text: "Available", status: "Available" },
  { text: "Off Market", status: "Off-market" },
];

export const WELCOME_VIDEO_URL = process.env.REACT_APP_WELCOME_VIDEO_URL;

// html headers definitions (browser only, for SEO and social links search for 'meta tags' in /server/index.js)
export const DOCUMENT_TITLE_DEFAULT = "RentBase";
export const META_DESCRIPTION_DEFUALT = "Save time with RentBase";

export const HUBSPOT_FIELDS = { COMPLETED_SIGNUP_PROCESS: "completed_signup_process" };

export const BUTTON_BACK_TEXT = "Back";
export const DEFAULT_BUNDLE = { bundleId: "5003", name: "MultiShare Crim+Credit+Eviction" };

export const SHAREABLE_PHONE_TYPES = [
  { label: "Mobile", value: "Mobile" },
  { label: "Home", value: "Home" },
  { label: "Office", value: "Office" },
];

export const SCREENING_REPORT_TYPES = {
  CREDIT: "Credit",
  BACKGROUND: "Criminal",
  EVICTION: "Eviction",
};

export const EXPIRED_REPORT_HTML = `<html><head><title>Report Expired Forbidden</title></head><body><div className="report-expire"><h1 className="report-expire__title">Report Expired</h1><p className="report-expire__message">Selected screening request report has expired.</p></div></body></html>`;

export const REPORT_NOT_AVAILABLE_HTML = `<html><head><title>Report Expired Forbidden</title></head><body><div className="report-expire"><h1 className="report-expire__title">Report Not Available Error</h1></div></body></html>`;

export const TRANSUNION_AUTHENTICATION_PHONE = process.env.TRANSUNION_PHONE_NUMBER || "1-833-458-6338";

export const FREE_PLAN_LIMITS = { listings: 3, landlords: 3, team_members: 1, leads: 10 };

export const SSO_SOURCES = ["CLAW", "PS", "IMPERIAL"];
export const EXTERNAL_SOURCES = {
  CLAW_SOURCES: ["CLAW", "PS", "IMPERIAL"],
  RENTBASE: "rentbase",
  REALTOR: "realtor",
};
export const SOURCE_ENUM = {
  CLAW: "CLAW",
  PS: "PS",
  IMPERIAL: "IMPERIAL",
  RENTBASE: "rentbase",
};

export const SUBSCRIPTION_PERIOD_ENUM = {
  monthly: "monthly",
  yearly: "yearly",
};

export const SUBSCRIPTION_TYPES = {
  free: "free",
  premium: "premium",
};

export const US_STATES: { [key: string]: any } = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
