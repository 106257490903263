import { FormInstance } from "antd/es/form";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { landlordCreateSuccessSelector } from "store/landlords/selectors";
import { IObjectKeys } from "../../../../../../../helpers/globalTypes";
import { resetCurrentLandlord } from "../../../../../../../store/landlords/action";
import ManageLandlordForm from "../../../../../../Landlords/ManageLandlord";
import BaseModal from "../../../../../../Shared/BaseModal";
interface IAddLandlordModal {
  currentLandlord: IObjectKeys;
  visible: boolean;
  form: FormInstance<any>;
  setVisible: any;
}
const AddLandlordModal = ({ currentLandlord, visible, form, setVisible }: IAddLandlordModal) => {
  const dispatch = useDispatch();
  const createLandlordSuccess = useSelector(landlordCreateSuccessSelector);

  useEffect(() => {
    setVisible(false);
  }, [createLandlordSuccess]);

  return (
    <BaseModal
      classname={"add-edit-landlord-modal"}
      cancelButtonProps={{
        className: "rb-secondary-btn sm add-landlord-cancel-btn",
      }}
      okButtonProps={{
        className: "rb-primary-btn sm add-landlord-ok-btn",
      }}
      modalTitle={`${currentLandlord.landlord_id ? "Edit" : "Add New"} Landlord 1`}
      isModalVisible={visible}
      onOk={() => form.submit()}
      onCancel={() => {
        setVisible(false);
        dispatch(resetCurrentLandlord());
      }}
    >
      <ManageLandlordForm form={form} />
    </BaseModal>
  );
};

export default AddLandlordModal;
