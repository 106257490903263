import React, { useEffect, useState, useRef } from "react";
import { Form, Spin } from "antd";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  landlordCreateSuccessSelector,
  landlordEditSuccessSelector,
  landlordsListSelector,
  landlordsTotalSelector,
  landlordsQueryParamsSelector,
  landlordListingsPaginationValuesSelector,
  singleLandlordDataSelector,
  landlordNotesSelector,
  currentLandlordSelector,
  landlordLoadingSelector,
} from "../../../store/landlords/selectors";
import {
  getLandlordsListLP,
  resetCurrentLandlord,
  setCurrentLandlord,
  setLandlordCreatedSuccessToFalse,
  setLandlordListingsFilterOptions,
  setLandlordsQueryParams,
} from "../../../store/landlords/action";
import "../styles.scss";
import { useHistory, useLocation } from "react-router-dom";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { getAreas } from "../../../store/areas/action";
import { push } from "connected-react-router";
import { scrollToTop } from "../../../helpers/globalFunctions";
import TableView from "../../Shared/TableView/TableView";
import tablesInfo from "../../Shared/TableView/tablesInfo";
import LandlordsItemCardWrapper from "./landlords-mobile-wrapper/LandlordsItemCardWrapper";
import { useWindowSize } from "../../../hooks/useWindowSize";
import LandlordsListHeaderDesktop from "./landlords-list-header/LandlordsListHeaderDesktop";
import ContactModal from "components/Shared/ContactModal/ContactModal";
import AddFollowUpModal from "components/FollowUps/add-follow-up-modal/AddFollowUpModal";
import { AnalyticsService } from "helpers/analyticsService";
import AfterCallModal from "../../Shared/AfterCallModal/AfterCallModal";
import LandlordNoteModal from "../../Landlord/LandlordNoteModal";
import PhoneVerifyModalFollowUps from "../../FollowUps/all-follow-ups-modal/components/phone-verify-modal";
import AddFollowUpModalMobile from "../../FollowUps/add-follow-up-modal-mobile/AddFollowUpModalMobile";
import AllListingNotesModal from "../../ListingView/all-listing-notes-modal/AllListingNotesModal";
import { ListingNote } from "../../Listings/helpers/mockListings";
import moment from "moment";
import { deleteListingNote } from "../../../store/listings/action";
import NoteDeleteConfirmModal from "../../Shared/NoteDeleteConfirmModal";
import { Document } from "@contentful/rich-text-types";
import { IObjectKeys } from "../../../helpers/globalTypes";
import { getDeleteNoteConfirmContent } from "../../../contentful/api";
import EmptyPageLayout from "../../../layouts/empty/EmptyPageLayout";
import EmptyPageAddLandlord from "../../Empty-page-wrapper/components/empty-page-add-landlord";
import ActivateSection from "components/Shared/ActivateSection";
import { useDemoContext } from "libs/contextLib";
import { useAppContext } from "libs/contextLib";
import { TRIGGER_BUTTON_LABEL } from "../../../helpers/constants";
import PremiumUpgradeModal from "../../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import BaseModal from "../../Shared/BaseModal";
import ManageLandlordForm from "../ManageLandlord";
import { urlQueryToObject } from "../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import FilteredBySection from "components/Shared/filters/filtered-by-section";
import { userSelector } from "store/user/selectors";
import GlobalLoading from "components/Shared/GlobalLoading";
const confirmDeleteValue = { visible: false, noteId: "" };

export interface ILandlordsQuery {
  page: number;
  pageSize: number;
  created_at_order?: number;
  name_order?: number;
  number_of_listings_order?: number;
}

const Landlords = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [deleteConfirmProps, setDeleteConfirmProps] = useState(confirmDeleteValue);
  const [deleteConfirmContent, setDeleteConfirmContent] = useState<Document | undefined>(undefined);
  const landlordsSortOverlayRef = useRef(null);
  const landlordsSortButtonRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showingCount, setShowingCount] = useState(+(localStorage.getItem("landlords_showing_count") as string) || 24);
  const landlordsList = useSelector(landlordsListSelector);
  const landlordLoading = useSelector(landlordLoadingSelector);
  const { width } = useWindowSize();
  const landlordsTotal = useSelector(landlordsTotalSelector);
  const currentLandlord = useSelector(currentLandlordSelector);
  const singleLandlordData = useSelector(singleLandlordDataSelector);
  const createLandlordSuccess = useSelector(landlordCreateSuccessSelector);
  const editLandlordSuccess = useSelector(landlordEditSuccessSelector);
  const landlordsQueryParams = useSelector(landlordsQueryParamsSelector);
  const initialModalValue = { visible: false, editModal: false };
  const [landlordActionModalVisible, setLandlordActionModalVisible] = useState(false);
  const _newURLSearchParams = new URLSearchParams(location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const [modalProps, setModalProps] = useState(initialModalValue);
  const [afterCallModalProps, setAfterCallModalProps] = useState(false);
  const [followUpModalProps, setFollowUpsModalProps] = useState(initialModalValue);
  const [noteModalProps, setNoteModalProps] = useState(initialModalValue);
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const [notesModalProps, setNotesModalProps] = useState(initialModalValue);
  const landlordNotesSelectorVariable: any = useSelector(landlordNotesSelector);
  const [selectedNote, setSelectedNote] = useState({});
  const { isDemo, demoAction } = useDemoContext();
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);

  const closeAfterCallModalHandler = () => {
    setAfterCallModalProps(false);
  };

  const [action, setAction] = useState("");
  const delayedFilter = useRef(
    debounce((filters: any, query: IObjectKeys) => {
      if (!query.locations) {
        delete filters.locations;
      }
      const urlObject = {
        ...filters,
        pageSize: localStorage.getItem("landlords_showing_count") || 24,
      };
      dispatch(setLandlordsQueryParams(urlObject));
      if (!query.tags) {
        delete urlObject.tags;
      }
      dispatch(getLandlordsListLP(urlObject));
    }, 500)
  );
  const userData = useSelector(userSelector);
  const { isNotPremiumUser } = useAppContext();
  const tableInfo = tablesInfo(userData, isNotPremiumUser, setPremiumModalVisible);
  const handleOpenNoteModal = (note: ListingNote) => {
    const todo_at = note.todo_at ? moment(note.todo_at) : null;
    setNoteModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at,
    });
  };
  useOnClickOutside(landlordsSortOverlayRef, () => setIsDropdownVisible(false), landlordsSortButtonRef);
  useEffect(() => {
    if (["/landlords", "/demo/landlords"].includes(history.location.pathname)) {
      dispatch(resetCurrentLandlord());
      form.resetFields();
    }
  }, [history.location.pathname]);
  useEffect(() => {
    if (["/landlords", "/demo/landlords"].includes(history.location.pathname) && location.search === "") {
      delete landlordsQueryParams["neighbourhood"];
      delete landlordsQueryParams["sub_area"];
      delete landlordsQueryParams["sub_sub_area"];

      dispatch(
        setLandlordsQueryParams({
          ...landlordsQueryParams,
          page: 1,
          pageSize: +(localStorage.getItem("landlords_showing_count") as string) || 24,
        })
      );
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      const entries: IObjectKeys = await getDeleteNoteConfirmContent();
      if (entries) {
        setDeleteConfirmContent(entries.fields.modalDescription);
      }
    })();
  }, [landlordNotesSelectorVariable]);

  useEffect(() => {
    const latestURLParams = new URLSearchParams(location.search);
    const newQueryObject = urlQueryToObject(latestURLParams);
    dispatch(setLandlordListingsFilterOptions(newQueryObject));
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    delayedFilter?.current({ ...landlordsQueryParams, ...newQueryObject }, newQueryObject);
    const LandlordWrapperRef = document.getElementById("landlordsTableId")?.closest(".ant-table-wrapper");
    scrollToTop();
    if (LandlordWrapperRef) {
      //@ts-ignore
      scrollToTop(LandlordWrapperRef);
    }
  }, [location.search]);
  useEffect(() => {
    dispatch(getAreas({ limit: "all" }));
  }, []);
  useEffect(() => {
    if (createLandlordSuccess || editLandlordSuccess) {
      setVisible(false);
      dispatch(setCurrentLandlord({}));
    }
  }, [createLandlordSuccess, editLandlordSuccess]);
  useEffect(() => {
    return () => {
      dispatch(setLandlordsQueryParams({ page: 1, pageSize: 24 }));
    };
  }, []);

  useEffect(() => {
    if (createLandlordSuccess) {
      setTimeout(() => {
        dispatch(setLandlordCreatedSuccessToFalse());
      }, 5000);
    }
  }, [createLandlordSuccess]);

  const handleChangeShowingCount = (count: number) => {
    const urlObject = { ...landlordsQueryParams, pageSize: count, page: 1 };
    dispatch(setLandlordsQueryParams(urlObject));
    setShowingCount(count);
    localStorage.setItem("landlords_showing_count", count.toString());
  };
  useEffect(() => {
    if (!afterCallModalProps) localStorage.removeItem("contact_email_selected");
  }, [afterCallModalProps]);

  const rowHandler = (e: Event, record: any) => {
    //@ts-ignore
    if (e?.target.closest("#landlordQuickContactButton")) {
      if (isDemo) {
        e.stopPropagation();
        demoAction("landlord");
      }
    }
    //@ts-ignore
    else if (e?.target.closest("#listingQuickContactButton")) {
      if (isDemo) {
        demoAction("landlord");
      } else {
        setLandlordActionModalVisible(true);
      }
    } else {
      AnalyticsService.reportLandlordClick();
      dispatch(push(`/${isDemo ? "demo/landlords" : "landlords"}/${record?.landlord_id}`));
    }
  };

  useEffect(() => {
    if (Object.keys(queryObject).length) {
      dispatch(
        setLandlordsQueryParams({
          ...landlordsQueryParams,
          ...queryObject,
          size: +(localStorage.getItem("landlords_showing_count") as string) || 24,
        })
      );
    }
  }, []);

  if (landlordLoading) {
    return <Spin spinning={landlordLoading} indicator={<GlobalLoading />} className="global-loading" />;
  }

  if ((landlordsList.length === 0 && !location.search) || location.search.includes("fab=true")) {
    return (
      <div className={"empty-individual-leads-page-wrapper"}>
        <EmptyPageLayout>
          <EmptyPageAddLandlord />
        </EmptyPageLayout>
      </div>
    );
  }

  return (
    <div className={"landlords-page-wrapper-all landlords-page-wrapper-all-mobile"}>
      <FilteredBySection />
      {isDemo && <ActivateSection className="mb-20" type="landlord" />}
      <div className={"landlords-header-wrapper-all mt-4"}>
        <LandlordsListHeaderDesktop
          handleChangeShowingCount={handleChangeShowingCount}
          landlordsList={landlordsList}
          landlordsTotal={landlordsTotal}
          isDropdownVisible={isDropdownVisible}
          landlordsSortOverlayRef={landlordsSortOverlayRef}
          setIsDropdownVisible={setIsDropdownVisible}
          landlordsSortButtonRef={landlordsSortButtonRef}
        />
      </div>

      {width && width > 767 ? (
        <TableView
          id="landlordsTableId"
          paginationValues={landlordsQueryParams}
          tableValues={landlordsList}
          total={landlordsTotal}
          columns={tableInfo.LANDLORDS_COLUMNS}
          tableClassName="landlords-table"
          setShowingCount={() => setShowingCount(+(localStorage.getItem("landlords_showing_count") as string) || 24)}
          showingCount={showingCount}
          onRow={(record: any) => {
            return {
              onClick: (e: Event) => rowHandler(e, record),
            };
          }}
        />
      ) : (
        <LandlordsItemCardWrapper
          id="landlordsTableId"
          paginationValues={landlordsQueryParams}
          tableValues={landlordsList}
          total={landlordsTotal}
          tableClassName="landlords-table"
          setShowingCount={() => setShowingCount(+(localStorage.getItem("landlords_showing_count") as string) || 24)}
          showingCount={showingCount}
          setLandlordActionModalVisible={setLandlordActionModalVisible}
        />
      )}

      <BaseModal
        classname={"add-edit-landlord-modal"}
        cancelButtonProps={{
          className: "rb-secondary-btn sm add-landlord-cancel-btn",
        }}
        okButtonProps={{
          className: "rb-primary-btn sm add-landlord-ok-btn",
        }}
        modalTitle={`${currentLandlord.landlord_id ? "Edit" : "Add New"} Landlord 2`}
        isModalVisible={visible}
        onOk={() => form.submit()}
        onCancel={() => {
          setVisible(false);
          dispatch(resetCurrentLandlord());
        }}
      >
        <ManageLandlordForm form={form} />
      </BaseModal>

      {landlordActionModalVisible && (
        <ContactModal
          setModalProps={setModalProps}
          currentLandlordData={singleLandlordData}
          showModal={true}
          onClose={setLandlordActionModalVisible}
          setAfterCallModalVisible={setAfterCallModalProps}
          setNoteModalVisible={setNotesModalProps}
          setAction={setAction}
        />
      )}
      <AllListingNotesModal
        visible={notesModalProps.visible}
        setVisible={setNotesModalProps}
        handleOpenAddNoteModal={() => {
          setNoteModalProps({ visible: true, editModal: false });
          setLandlordActionModalVisible(false);
        }}
        notes={landlordNotesSelectorVariable}
        handleOpenNoteModal={handleOpenNoteModal}
        selectedNote={selectedNote}
        setSelectedNote={setSelectedNote}
        setDeleteConfirmProps={setDeleteConfirmProps}
        setContactModalVisible={setLandlordActionModalVisible}
      />
      <NoteDeleteConfirmModal
        deleteConfirmProps={deleteConfirmProps}
        setDeleteConfirmProps={setDeleteConfirmProps}
        modalContent={deleteConfirmContent}
        onOkayClick={() => {
          dispatch(deleteListingNote(deleteConfirmProps.noteId || ""));
        }}
      />
      <AfterCallModal
        visible={afterCallModalProps}
        onClose={closeAfterCallModalHandler}
        setFollowUpModalVisible={setFollowUpsModalProps}
        setContactModalVisible={setAfterCallModalProps}
        setNoteModalVisible={setNoteModalProps}
        setPremiumModalVisible={setPremiumModalVisible}
      />
      <PhoneVerifyModalFollowUps visible={phoneVerifyModalVisible} onCancel={() => setPhoneVerifyModalVisible(false)} />
      {
        //@ts-ignore
        width >= 600 ? (
          <AddFollowUpModal
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={singleLandlordData}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={action + " landlord"}
          />
        ) : (
          <AddFollowUpModalMobile
            visible={followUpModalProps.visible}
            setModalProps={setFollowUpsModalProps}
            form={form}
            modalProps={followUpModalProps}
            isDescShown={false}
            landlordInfo={singleLandlordData}
            isFollowUpEditable={false}
            setIsDescShown={(e: any) => {
              console.log(e);
            }}
            setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
            defaultTitle={action + " landlord"}
          />
        )
      }
      <LandlordNoteModal
        modalProps={noteModalProps}
        setModalProps={setNoteModalProps}
        landlordInfo={singleLandlordData}
        landlord_id={singleLandlordData._id}
        initialModalValue={initialModalValue}
        form={form}
        defaultTitle={action + " landlord"}
      />
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_FOLLOW_UP_AFTER_CALL_MODAL}
        id={singleLandlordData._id}
      />
    </div>
  );
};

export default Landlords;
