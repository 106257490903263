import React, { useEffect, useState } from "react";
import "./VerifyPhone.scss";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../../../store/user/selectors";
import { IObjectKeys } from "../../../../../helpers/globalTypes";
import { sanitizePhoneNumber } from "../../../../../helpers/globalFunctions";
import { getPhoneVerificationCode, updateUserSettings } from "../../../../../store/user/action";
import BaseButton from "../../../../Shared/BaseButton";
import MaskedInput from "../../../../Shared/MaskedPhoneInput";
import {
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_VALIDATION,
  REQUIRED
} from "../../../../../helpers/validations";
import { AnalyticsService } from "helpers/analyticsService";

const VerifyPhone = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const userData = useSelector(userSelector);

  const handleFormChange = () => {
    setIsDisabled(
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0
    );
  };

  const onSubmit = (data: IObjectKeys) => {
    const formData = { ...data };
    formData.phone = sanitizePhoneNumber(formData.phone);
    const { password } = form.getFieldsValue(["password"]);
    dispatch(
      updateUserSettings({
        ...formData,
        _id: userData._id || userData._doc._id,
        name: userData.name || userData._doc.name,
        email: userData.email || userData._doc.email,
        password
      })
    );
    AnalyticsService.reportSignUpProcess({ action: "sign_up_verification_flow_verify_your_phone_page_verify_click" });
    setTimeout(() => {
      dispatch(getPhoneVerificationCode());
    }, 2500);
  };

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      if (userData?._doc?.phone || userData?.phone) {
        form.setFieldsValue({ phone: userData?._doc?.phone || userData?.phone });
        setIsDisabled(false);
      }
    }
  }, [userData]);

  return (
    <div className={"enter-phone-to-verify-wrapper"}>
      <div className={"enter-phone-to-verify-wrapper-bordered"}>
        <span className={"enter-phone-to-verify-wrapper-bordered--absolute"}>Recommended</span>
        <h1>Verify your phone</h1>
        <p>Enable easier sharing, enhanced privacy, and more control of your data.</p>
        <Form
          onFinish={onSubmit}
          className={"reset-password-form"}
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Form.Item name="phone" rules={[REQUIRED, PHONE_NUMBER_VALIDATION]}>
            <MaskedInput inputMode="numeric" mask={PHONE_NUMBER_MASK} placeholder={"Enter number here"} />
          </Form.Item>
        </Form>
        <BaseButton
          type={"submit"}
          classnames={"rb-primary-btn md"}
          disabled={isDisabled}
          onClick={() => form.submit()}
        >
          Verify
        </BaseButton>
      </div>
    </div>
  );
};

export default VerifyPhone;
