import React, { useState } from "react";
import "./MobileSkeleton.scss";
import {Skeleton, Tooltip, Typography} from "antd";
import { generateFullDate } from "../../../../helpers/globalFunctions";
import DetectableOverflow from "react-detectable-overflow";
import Paragraph from "antd/es/typography/Paragraph";
interface IMobileSkeleton {
  item: {} | any;
  listingNotesLoading: any;
  dueContent: any;
  leftOrAgoGenerator: any;
}
const MobileSkeleton = ({
  item,
  listingNotesLoading,
  dueContent,
  leftOrAgoGenerator,
}: IMobileSkeleton) => {
  const [isTextEllipsis,setIsTextEllipsis] = useState(false);
  const isTextOverflowed = (isOverflowed:boolean) =>{
    setIsTextEllipsis(isOverflowed);
  };

  return (
    <Skeleton title={false} loading={listingNotesLoading} active>
      <div className={"notes-mobile-skeleton"}>
        <Tooltip placement="topLeft" title={item.title} >
            <Paragraph className={"notes-mobile-skeleton--title"}>{item.title}</Paragraph>
        </Tooltip>
        <div className={"notes-mobile-skeleton-body"}>
          <div className={"notes-mobile-skeleton-body-desc"}>
            {item.description ? (
                    <DetectableOverflow
                        className={"notes-mobile-skeleton-body-desc--descriptionAll"}
                        onChange={isTextOverflowed}
                    >
                      {item.description}
                    </DetectableOverflow>
            ) : (
              <span className={"notes-mobile-skeleton-body-desc--descriptionAllNotVisible"}>aaaaaaaa</span>
            )}
            {isTextEllipsis && item.description && <span className={"notes-mobile-skeleton-body-desc--readAll"}>Read more</span>}
          </div>
          <div className={"notes-mobile-skeleton-body--dateInfo"}>
            <Typography.Text strong={true}>
              <span>By :</span>
              <span className={"listing-note-by"}>
                {" "}
                {item?.created_by?.name}
              </span>
            </Typography.Text>
            <Typography.Text strong={true}>
              <span className={" created-at"}>
                Created :{" "}
                <span className={"listing-note-by"}>
                  {generateFullDate(item.created_at, false)}
                </span>
              </span>
            </Typography.Text>
            {item?.todo_at ? (
              <Typography.Text strong={true}>
                Due :
                <span className={"ml-5 listing-note-by"}>
                  {generateFullDate(item.todo_at, false)}{" "}
                  {dueContent(
                    item?.todo_at,
                    leftOrAgoGenerator(item?.todo_at),
                    item,
                  )}
                </span>
              </Typography.Text>
            ) : null}
          </div>
        </div>
      </div>
    </Skeleton>
  );
};

export default MobileSkeleton;
