import React, { ReactNode } from "react";
import "./styles.scss";
import { Dropdown } from "antd";
import { DropdownTriangleIcon } from "../../icons";

interface IBaseDropdown {
  visible?:boolean;
  children: ReactNode;
  classnames?: string;
  triangleWrapperClassName?: string;
  dataTestId?:string;
  trigger: ("click" | "hover" | "contextMenu")[] | undefined;
  placement:
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomLeft"
    | "bottomCenter"
    | "bottomRight"
    | undefined;
  menu: React.ReactElement<
    any,
    | string
    | ((
        props: any,
      ) => React.ReactElement<
        any,
        string | { new (props: any): React.Component<any, any, any> }
      > | null)
    | { new (props: any): React.Component<any, any, any> }
  >;
  onVisibleChange?:(visible: boolean) => void
}

const BaseDropdown = (props: IBaseDropdown) => {
  return (
    <Dropdown
      visible={props.visible}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      trigger={props.trigger}
      placement={props.placement}
      overlay={props.menu}
      overlayClassName={`base-dropdown ${props.classnames}`}
      onVisibleChange={props.onVisibleChange}
    >
      <div data-test-id={props.dataTestId} className="base-dropdown-button">
        <span className={`dropdown-icon-wrapper ${props.triangleWrapperClassName}`}>
        {props.visible &&   <DropdownTriangleIcon />}
        </span>
        {props.children}
      </div>
    </Dropdown>
  );
};

export default React.memo(BaseDropdown);
