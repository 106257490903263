import React from "react";

interface IListingHistoryPriceIcon {
    className?: string,
    variant?: string,
};

const ListingHistoryPriceIcon = ({ className, variant }: IListingHistoryPriceIcon) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect width="20" height="20" rx="2" fill={variant === 'light' ? '#555' : '#141414'} />
            <path
                d="M13.4985 5.96165C13.4773 5.68632 13.237 5.48029 12.9617 5.50147L8.47491 5.84661C8.19958 5.86779 7.99355 6.10815 8.01473 6.38348C8.03591 6.65881 8.27627 6.86484 8.5516 6.84366L12.5398 6.53688L12.8466 10.5251C12.8678 10.8004 13.1082 11.0065 13.3835 10.9853C13.6588 10.9641 13.8648 10.7237 13.8437 10.4484L13.4985 5.96165ZM7.37963 13.3254L13.3796 6.3254L12.6204 5.67461L6.62037 12.6746L7.37963 13.3254Z"
                fill="#00FF0A"
            />
        </svg>
    );
};

export default ListingHistoryPriceIcon;