import * as types from "./types";
import { EnumInterface, IObjectKeys } from "../../helpers/globalTypes";

interface enumsStateType extends IObjectKeys {
  enums: EnumInterface[];
  requirementEnums: EnumInterface[];
}

const initialState: enumsStateType = {
  enums: [],
  requirementEnums: [],
  enumsLoading: false,
  enumsError: null,
};

export default function enumReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.GET_ENUMS:
      return {
        ...state,
        enumsLoading: true,
        enumsError: null,
      };
    case types.GET_ENUMS_SUCCESS:      
      return {
        ...state,
        enums: [...action.payload],
        enumsLoading: false,
      };
    case types.GET_ENUMS_FAIL:
      return {
        ...state,
        enumsError: action.payload,
        enumsLoading: false,
      };
    case types.GET_REQUIREMENT_ENUMS:
      return {
        ...state,
        enumsLoading: true,
        enumsError: null,
      };
    case types.GET_REQUIREMENT_ENUMS_SUCCESS:
      return {
        ...state,
        requirementEnums: [...action.payload],
        enumsLoading: false,
      };
    case types.GET_REQUIREMENT_ENUMS_FAIL:
      return {
        ...state,
        enumsError: action.payload,
        enumsLoading: false,
      };
    default:
      return state;
  }
}
