import { Avatar } from "antd";
import BaseButton from "components/Shared/BaseButton";
import BaseModal from "components/Shared/BaseModal";
import React, { memo, useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { IObjectKeys } from "helpers/globalTypes";
import { useWindowSize } from "hooks/useWindowSize";
import ApproveModal from "../ApproveModal";
import { getRenterRequirement, leadAccessRequirement, leadDeclineRequirement } from "store/requirement/action";
import { leadApproveSuccessSelector } from "store/requirement/selectors";
import { leadLoadingSelector, singleLeadDataSelector } from "store/leads/selectors";
import { AnalyticsService } from "helpers/analyticsService";

const AgentReqModal = ({ toggle = false, setToggle, showApproveModal, setShowApproveModal, reqAgent }: IObjectKeys) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [isSuccess, setIsSuccess] = useState(false);
  const [index, setIndex] = useState(0);
  const [pendingAgents, setPendingAgents] = useState([] as Array<IObjectKeys>);
  const leadApproveSuccess = useSelector(leadApproveSuccessSelector);
  const leadData = useSelector(singleLeadDataSelector);
  const leadLoading = useSelector(leadLoadingSelector);
  const requestedAgentsOnReq = leadData?.requirement?.requestedAgentsOnReq;

  useEffect(() => {
    let agentsData: IObjectKeys[] = [];
    if (reqAgent) {
      const agentToSet = {
        status: "Pending",
        agent_id: localStorage.getItem("requested_agent_id"),
        ...reqAgent,
      };
      agentsData.push(agentToSet);
      setPendingAgents(agentsData);
    } else {
      requestedAgentsOnReq?.forEach((agent: any) => {
        const agentToSet = {
          status: "Pending",
          agent_id: agent._id,
          ...agent.user_id,
        };
        agentsData.push(agentToSet);
      });
      setPendingAgents(agentsData);
    }
    if (agentsData.length && !leadLoading && !reqAgent) {
      setToggle(true);
    } else {
      setToggle(false);
    }
    agentsData = [];
  }, [requestedAgentsOnReq, reqAgent]);

  useEffect(() => {
    if (leadApproveSuccess && showApproveModal && pendingAgents[index]?.name) {
      setToggle(false);
      setIsSuccess(true);
    }
  }, [leadApproveSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setToggle(false);
    }
  }, [isSuccess]);

  const handleCancel = () => {
    setToggle(false);
    if (index + 1 < pendingAgents.length) {
      setIndex((prev) => prev + 1);
      setToggle(true);
    }
  };

  const handleApprove = () => {
    AnalyticsService.reportRequirementsFunnel({ action: "agent_req_modal_click", label: "accepted_agent" });
    setToggle(false);
    sessionStorage.setItem("accepted_agent", pendingAgents[index].agent_id);
    dispatch(
      leadAccessRequirement({
        agent_id: pendingAgents[index].agent_id,
        requirements_id: leadData.requirement.id,
      })
    );
    setShowApproveModal(true);
  };

  const handleCancelForApprove = () => {
    AnalyticsService.reportRequirementsFunnel({ action: "agent_req_modal_click", label: "cancel_for_approve" });
    dispatch(getRenterRequirement({ requirements_id: leadData.requirement.id }));
  };

  const handleDecline = () => {
    AnalyticsService.reportRequirementsFunnel({ action: "agent_req_modal_click", label: "decline_agent" });
    dispatch(
      leadDeclineRequirement({
        agent_id: pendingAgents[index].agent_id,
        requirements_id: leadData.requirement.id,
      })
    );
    setToggle(false);
  };

  return (
    <>
      <BaseModal
        isModalVisible={toggle}
        modalFooter={null}
        width={width && width < 600 ? 290 : 416}
        onCancel={handleCancel}
        modalTitle=""
        centered={true}
        key={pendingAgents[index]?.id}
      >
        <div className="agent-req-modal mt-8">
          <div className="flex justify-start items-center">
            <Avatar src={pendingAgents[index]?.imagesOfAgent?.url} icon={<UserOutlined />} size={56} />
            <div className="ml-4">
              <h3 className="text-xl font-bold ">New agent request</h3>
              <p>{pendingAgents[index]?.name} is requesting your contact information.</p>
            </div>
          </div>
          <div className="w-full p-4">
            <div className={`flex flex-col justify-center gap-4 ${!!reqAgent}`}>
              <BaseButton variant="primary" onClick={handleApprove}>
                Approve
              </BaseButton>
              {!!reqAgent && (
                <BaseButton variant="link" className="my-4" onClick={handleDecline}>
                  Decline
                </BaseButton>
              )}
            </div>
            <p className="w-full py-2">
              By approving this agent, you give them access to connect with you via phone, text, or email.
            </p>
          </div>
        </div>
      </BaseModal>
      <ApproveModal
        toggle={isSuccess}
        // isFromReqPage={}
        setToggle={setIsSuccess}
        agent={pendingAgents.find((agent) => agent.agent_id === sessionStorage.getItem("accepted_agent"))}
        handleCancel={handleCancelForApprove}
      />
    </>
  );
};

export default memo(AgentReqModal);
