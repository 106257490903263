import React from "react";
import "./styles.scss";
import PhoneVerifyTab from "./phone-verify-tab/PhoneVerifyTab";
import { useForm } from "antd/es/form/Form";

const VerifyPhone = ({ existingPhoneNumber }: any) => {
  const [form] = useForm();
  return (
    <div className="verify-phone-section">
      <p className={"verify-phone-section-recommended rounded-full bg-purple-400"}>Recommended</p>
      <h3 className="verify-phone-section--heading">Verify your phone</h3>
      <p className="verify-phone-section--description">
        Unlock fast and easy sharing, enhanced privacy & more control.
      </p>
      <PhoneVerifyTab form={form} existingPhoneNumber={existingPhoneNumber} />
    </div>
  );
};

export default VerifyPhone;
