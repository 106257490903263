import React from "react";
import { Col, Row } from "antd";
import ProfileSettings from "./ProfileSettings";

const SettingsWrapper = () => {
    return (
        <Row  className="h-full">
            <Col span={24}  className="h-full">
                <ProfileSettings/>
            </Col>
        </Row>
    );
};
export default SettingsWrapper;
