import React from 'react';
import './styles.scss';
import { Radio } from 'antd';
import _ from "lodash";


interface IAmountOfPets {
    amount: number,
    onChange: (name: string, value: string) => void,
    species: string
}
const AmountOfPets = ({
    amount,
    onChange,
    species
}: IAmountOfPets) => {
    return (
        <div className="amount-of-pets-container">
            <label htmlFor="amount">How many {species}s do you have?</label>
            <Radio.Group
                className={"pets-amount-select-radio-group"}
                name="amount"
                defaultValue={amount}
                //@ts-ignore
                onChange={(e) => onChange(e.target.name, e.target.value)}
            >

                {_.range(5).map((item) => {
                    const n = ++item;
                    return (
                        <Radio.Button key={n} value={n} data-test-id={n}>
                            {n}
                        </Radio.Button>
                    );
                })}
                <Radio.Button value={6}>6+</Radio.Button>
            </Radio.Group>

        </div>
    );
}

export default AmountOfPets;