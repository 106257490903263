import { IObjectKeys } from "./../../helpers/globalTypes";
import * as types from "./types";
import { IPaginationValues } from "../../components/Listings/components/listings-wrapper";
import {
  IFilterOptionsOptionalKeys,
  IGetListingsQuery,
} from "../../components/Listings/helpers/mockListings";
import { AuthError } from "../../types/user";

export function getLeadsList(getLeadsQuery: IGetListingsQuery) {
  return {
    type: types.GET_LEADS,
    payload: getLeadsQuery,
  };
}
export function getLeadsListSuccess(users: object) {
  return { type: types.GET_LEADS_SUCCESS, payload: users };
}
export function getLeadsListFail(error: object) {
  return { type: types.GET_LEADS_FAIL, payload: error };
}
export function getLeadData(lead_id: string) {
  return {
    type: types.GET_LEAD_DATA,
    payload: lead_id,
  };
}
export function getTags(lead_id: string) {
  return {
    type: types.GET_TAGS,
    payload: lead_id,
  };
}
export function clearLeadData() {
  return {
    type: types.CLEAR_LEAD_DATA,
  };
}
export function setContactLeadData(data: any) {
  return {
    type: types.SET_LEAD_DATA_CONTACT,
    payload: data,
  };
}
export function getLeadDataSuccess(leadData: object) {
  return { type: types.GET_LEAD_DATA_SUCCESS, payload: leadData };
}
export function getLeadDataFail(error: object) {
  return { type: types.GET_LEAD_DATA_FAIL, payload: error };
}
export function getTagsSuccess(leadData: object) {
  return { type: types.GET_TAGS_SUCCESS, payload: leadData };
}
export const setLeadsFilterOptions = (payload: IFilterOptionsOptionalKeys) => ({
  type: types.SET_LEADS_FILTER_OPTIONS,
  payload,
});
export const setLeadsPaginationValues = (payload: IPaginationValues) => ({
  type: types.SET_LEADS_PAGINATION_VALUES,
  payload,
});
export const setLeadsLastQuery = (payload: string) => ({
  type: types.SET_LAST_LEADS_QUERY,
  payload,
});
export const setLeadsLoadingToTrue = () => ({
  type: types.SET_LEADS_LOADING_TO_TRUE,
});
export const setLeadsLoadingToFalse = () => ({
  type: types.SET_LEADS_LOADING_TO_FALSE,
});
export const addLeadNote = (payload: IObjectKeys) => ({
  type: types.ADD_LEAD_NOTE,
  payload,
});
export const addLeadNoteSuccess = (payload: IObjectKeys) => ({
  type: types.ADD_LEAD_NOTE_SUCCESS,
  payload,
});
export const addLeadNoteFail = (payload: AuthError) => ({
  type: types.ADD_LEAD_NOTE_FAIL,
  payload,
});
export const deleteLeadNote = (payload: string) => ({
  type: types.DELETE_LEAD_NOTE,
  payload,
});
export const deleteLeadNoteSuccess = (payload: IObjectKeys) => ({
  type: types.DELETE_LEAD_NOTE_SUCCESS,
  payload,
});
export const deleteLeadNoteFail = (payload: AuthError) => ({
  type: types.DELETE_LEAD_NOTE_FAIL,
  payload,
});
export const updateLeadNote = (payload: IObjectKeys) => ({
  type: types.UPDATE_LEAD_NOTE,
  payload,
});
export const updateLeadNoteSuccess = (payload: IObjectKeys) => ({
  type: types.UPDATE_LEAD_NOTE_SUCCESS,
  payload,
});
export const updateLeadNoteFail = (payload: AuthError) => ({
  type: types.UPDATE_LEAD_NOTE_FAIL,
  payload,
});
export const getLeadNotes = (payload: string) => ({
  type: types.GET_LEAD_NOTES,
  payload,
});
export const getLeadNotesSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LEAD_NOTES_SUCCESS,
  payload,
});
export const getLeadNotesFail = (payload: AuthError) => ({
  type: types.GET_LEAD_NOTES_FAIL,
  payload,
});
export const setLeadNoteAddSuccess = (payload: boolean) => ({
  type: types.SET_LEAD_NOTE_ADD_SUCCESS,
  payload,
});
export const verifyLeadEmail = (payload: { renterToken: string }) => ({
  type: types.VERIFY_LEAD_EMAIL,
  payload,
});
export const verifyLeadEmailError = (payload: any) => ({
  type: types.VERIFY_LEAD_EMAIL_ERROR,
  payload,
});
export const verifyLeadEmailSuccess = (payload: {
  code: string | undefined;
}) => ({
  type: types.VERIFY_LEAD_EMAIL_SUCCESS,
  payload,
});
export const confirmLeadPassword = (payload: any) => ({
  type: types.CONFIRM_LEAD_PASSWORD,
  payload,
});
export const confirmLeadPasswordError = (payload: any) => ({
  type: types.CONFIRM_LEAD_PASSWORD_ERROR,
  payload,
});
export const confirmLeadPasswordSuccess = () => ({
  type: types.CONFIRM_LEAD_PASSWORD_SUCCESS,
});
export const getLeadAccountLink = () => ({
  type: types.GET_LEAD_ACCOUNT_LINK,
});
export const getLeadAccountLinkSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LEAD_ACCOUNT_LINK_SUCCESS,
  payload,
});
export const setLeadAccessToken = (payload: string) => ({
  type: types.SET_LEAD_ACCESS_TOKEN,
  payload,
});
export const clearLeadLink = () => ({
  type: types.CLEAR_LEAD_LINK,
});

export const sendLeadInfoBySms = (payload: string) => ({
  type: types.SEND_LEAD_INFO_BY_SMS,
  payload,
});
export const sendLeadInfoBySmsSuccess = (payload: IObjectKeys) => ({
  type: types.SEND_LEAD_INFO_BY_SMS_SUCCESS,
  payload,
});
export const sendLeadInfoBySmsFail = (payload: AuthError) => ({
  type: types.SEND_LEAD_INFO_BY_SMS_FAIL,
  payload,
});


export const updateLeadTags = (payload: IObjectKeys) =>({
  type: types.LEAD_UPDATE_TAGS,
  payload,
});
export const updateLeadTagsError = (payload: IObjectKeys) => ({
  type: types.LEAD_UPDATE_TAGS_ERROR,
  payload,
});
export const updateLeadTagsSuccess = (payload: IObjectKeys) => ({
  type: types.LEAD_UPDATE_TAGS_SUCCESS,
  payload,
});
export const removeLeadTag = (payload: IObjectKeys) =>({
  type: types.LEAD_REMOVE_TAG,
  payload,
});
export const removeLeadTagError = (payload: IObjectKeys) => ({
  type: types.LEAD_REMOVE_TAG_ERROR,
  payload,
});
export const removeLeadTagSuccess = (payload: IObjectKeys) => ({
  type: types.LEAD_REMOVE_TAG_SUCCESS,
  payload,
});
