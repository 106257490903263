import { IObjectKeys } from "../../helpers/globalTypes";
import * as types from "./types";

export const setRenterPassport = (payload: IObjectKeys) => ({
  type: types.SET_RENTER_PASSPORT,
  payload,
});

export const clearRenterPassport = () => ({
  type: types.CLEAR_RENTER_PASSPORT,
});
