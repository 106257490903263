import { getDataFromLocalStorageByKey } from './../../helpers/globalFunctions';
import * as types from "./types";

export interface AppWindowInterface {
  window: object;
}

const { obj } = getDataFromLocalStorageByKey("sidebarParams");
const initialState: AppWindowInterface = {
  window: {
    type: obj?.type || "",
    collapsed: obj?.collapsed ?? false,
  },
};

export default function AppReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.SET_WINDOW_TYPE:
      return {
        ...state,
        window: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
