import Api from './api';
import {IObjectKeys} from "../helpers/globalTypes";

export default class EnumsApi {
    static getNotifications(query: IObjectKeys) {
        const api = new Api('notification');
        return api.get('', {params: query});
    }

    static getUnreadNotificationsCount(userId: string) {
        const api = new Api('notification/unread');
        return api.get('', {params: {userId}});
    }

    static makeNotificationAsRead({notification_id, first_read}: { first_read: Date, notification_id: string }) {
        const api = new Api('notification/makeAsRead');
        return api.put('', {params: {notification_id}, data: {first_read}});
    }
    static makeNotificationAsUnread({notification_id, first_read}: { first_read: Date, notification_id: string }) {
        const api = new Api('notification/makeAsUnRead');
        return api.put('', {params: {notification_id}, data: {first_read}});
    }
    static markAllReadSaga({first_read}: { first_read: Date }) {
        const api = new Api('notification/makeAllAsRead');
        return api.put('', {data: {first_read}});
    }
}

