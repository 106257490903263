import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmResetPassword } from "store/user/action";
import { confirmResetPasswordSuccessSelector, passwordResetLoadingSelector, userSelector } from "store/user/selectors";
import { push } from "connected-react-router";
import { useHistory, useLocation } from "react-router-dom";
import "./SetPassword.scss";
import { IObjectKeys } from "helpers/globalTypes";
import PasswordForm from "components/Shared/password-form/PasswordForm";
export interface IResetPassword {
  email?: string;
  code?: string;
  newPassword: string;
}
const SetPassword = ({ code, email }: IObjectKeys) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(passwordResetLoadingSelector);
  const userData = useSelector(userSelector);
  const resetPasswordSuccess = useSelector(confirmResetPasswordSuccessSelector);

  const theEmail = localStorage.getItem("resetPasswordEmail") || email || userData.emial;

  const onSubmit = (data: IResetPassword) => {
    dispatch(
      confirmResetPassword({
        code,
        email: theEmail,
        newPassword: data.newPassword,
      })
    );
  };

  useEffect(() => {
    if (resetPasswordSuccess && location.pathname.includes("/reset-password")) {
      dispatch(push("/settings"));
    }
  }, [resetPasswordSuccess]);

  return (
    <>
      <div className="change-password-content">
        <PasswordForm onSubmit={onSubmit} loading={loading} title="Reset Password" />
      </div>
      <div className="change-password-back">
        <p onClick={() => history.goBack()}>{"< Back"}</p>
      </div>
    </>
  );
};
export default SetPassword;
