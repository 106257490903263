import routes from "../routes/routesCode";

export const leadsRoute = () => routes.leads.path;
export const listingRoute = (listing: any) => `/listings/${listing.public_id}`;

export const isRootRoute = (pathname: string) => pathname === routes.home.path;
export const isListingRoute = (pathname: string) => pathname === routes.listings.path || pathname === routes.home.path;
export const isLeadsRoute = (pathname: string) => pathname === routes.leads.path;

export const isShareListingPage = (location: any) => {
  return location.pathname.includes("share-listing");
};

export const listingsRoute = (query: string | undefined) => `${routes.listings.path}${query ?? `?${query}`}`;
export const renterPortalRoute = (query: string | undefined) =>
  `${routes.renterPortalDashboard.path}${query ?? `?${query}`}`;
export const renterPortalPassportRoute = () => `${routes.renterPortalPassport.path}`;
export const renterPortalSearchRoute = () => `${routes.renterPortalSearch.path}`;
export const renterRequirementsRoute = (listing: any) =>
  `/requirements/${listing?.agentOfList.agent_id ?? listing.agent_id}`;
