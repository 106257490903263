export const TABS_DESCRIPTIONS = {
  dashboard: "<span>View all data and track deal progress.</span>",
  listings:
    "<span>In your listings tab you'll find all your rental listing properties in one place. Click on any listing to open its listing page and view all the information pertaining to that property. <br/><br/><b>Pro tip:</b> The more listings you add and update in your Listings Tab, the more RentBase can automate your workflow and you save hours of time.</span>",
  leads:
    "<span>Your Leads tab is where you'll find all your renters leads. Once a Lead completes your questionnaire, their profile will populate here. Clicking on a Lead open that Lead's profile and details. Consider every lead qualified on RentBase as an additional 8 min of time saved!</span>",
  landlords:
    "<span>The Landlord tab holds all your landlords in one centralized place. Clicking on a landlord will take you to their Landlord Page that holds that landlord's details and listings together.</span>",
  followUps:
    "<span>Your Follow-up tab aggregates all the follow-ups you've created, regardless of the landlord or listing. Follow-ups can be added to your google calendar, marked as snooze, marked as completed, or deleted.</span>",
  myTeams:
    "<span>Your Team tab is where you'll find everything relating to collaborating with your team or office on RentBase. As a team owner, you can manage your roster and add new agents or view team activity.</span>",
  screenings:
    "<span>Your screenings tab is where you'll find all screenings request details of agent.</span>"
};
