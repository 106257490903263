import { FieldHookCallback } from '@flatfile/adapter/build/main/interfaces/obj.hooks';
import { IField } from '@flatfile/adapter/build/main/interfaces/obj.settings';
import { validateEmail } from '../../../helpers/globalFunctions';

export const IMPORT_BY_JSON_TEAM_MEMBER_FIELDS: IField[] = [
    { label: "Name", key: "name", validators: [{ validate: "required" }] },
    { label: "Email Address", key: "email", validators: [{ validate: "required" }] },
    { label: "State", key: "defaultState", validators: [{ validate: "required" }] },
    { label: "City", key: "defaultCity" }
]

export interface BulkUploadTeamMembersJsonDto {
    name: string;
    email: string;
    defaultState: string;
    defaultCity: string;
}



const fieldsHookValidationFoEmail: FieldHookCallback = (values) => {
    let validatedValues: any = [];
    values.forEach((item: any, idx) => {
        if (validateEmail(item[0])) {
            validatedValues.push([{ value: item[0] || "" }, idx ]);
        } else {
            validatedValues.push([
                {
                    info: [
                        {
                            message: "Not Valid Email.",
                            level: "error",
                        },
                    ],
                },
                idx,
            ]);
        }
    });
    return validatedValues;
}

export const FIELD_HOOKS_TEAM_IMPORT = {
    email: fieldsHookValidationFoEmail
}
