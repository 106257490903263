import {
  getDashboardDataSuccess,
  getDashboardFollowUpsSuccess,
  getDashboardDataError,
  getDashboardFollowUpsError,
  editDashboardFollowUpSuccess,
  editDashboardFollowUpFail,
  snoozeDashboardFollowUpSuccess,
  snoozeDashboardFollowUpFail,
  deleteDashboardFollowUpSuccess,
  deleteDashboardFollowUpFail,
  addDashboardFollowUpSuccess,
  addDashboardFollowUpFail
} from "./action";
import DashboardApi from "api/Dashboard";
import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import {
  ADD_DASHBOARD_FOLLOW_UP,
  DELETE_DASHBOARD_FOLLOW_UP,
  EDIT_DASHBOARD_FOLLOW_UP,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_FOLLOW_UPS,
  SNOOZE_DASHBOARD_FOLLOW_UP
} from "./types";
import FollowUpsApi from "../../api/FollowUps";

function* getDashboardDataSaga():any {
  try {

    const [
      listings,
      landlords,
      leads,
      followUps,
    ] = yield call(DashboardApi.getDashboardData);

    yield put(getDashboardDataSuccess({
      listings,
      landlords,
      leads,
      followUps,
    }));

  }catch(err){

    yield put(getDashboardDataError(err));

  }
}

function* getDashboardFollowUpsSaga():any {
  try {

    const respone = yield call(DashboardApi.getFollowUps,{});
    yield put(getDashboardFollowUpsSuccess(respone));

  }catch(err){

    yield put(getDashboardFollowUpsError(err));

  }

}

function* editDashboardFollowUpSaga({ payload }: any): any {
  try {
    const response = yield call(FollowUpsApi.editFollowUp, payload.payload, payload._id);
    yield put(editDashboardFollowUpSuccess(response));
  } catch (e) {
    yield put(editDashboardFollowUpFail(e));
  }
}

function* snoozeDashboardFollowUpSaga({ payload }: any): any {
  try {
    const response = yield call(FollowUpsApi.editFollowUp, payload.payload, payload._id);
    yield put(snoozeDashboardFollowUpSuccess(response));
  } catch (e) {
    yield put(snoozeDashboardFollowUpFail(e));
  }
}

function* deleteDashboardFollowUpSaga({ payload }: any) {
  try {
    yield call(FollowUpsApi.deleteFollowUp, payload);
    yield put(deleteDashboardFollowUpSuccess(payload));
  } catch (e) {
    yield put(deleteDashboardFollowUpFail(e));
    // openNotification("error", generateErrorMessage(e));
  }
}
function* addDashboardFollowUpSaga({ payload }: any): any {
  try {
    const response = yield call(FollowUpsApi.addFollowUp, payload);
    yield put(addDashboardFollowUpSuccess(response));
  } catch (e) {
    yield put(addDashboardFollowUpFail(e));
  }
}
function* watchAddDashboardFollowUp() {
  yield takeLatest(ADD_DASHBOARD_FOLLOW_UP, addDashboardFollowUpSaga);
}
function* watchGetDashboardData() {
  yield takeLatest(GET_DASHBOARD_DATA, getDashboardDataSaga);
}

function* watchDeleteDashboardFollowUp() {
  yield takeLatest(DELETE_DASHBOARD_FOLLOW_UP, deleteDashboardFollowUpSaga);
}

function* watchgetDashboardFollowUps() {
  yield takeLatest(GET_DASHBOARD_FOLLOW_UPS, getDashboardFollowUpsSaga);
}

function* watchEditDashboardFollowUp() {
  yield takeLatest(EDIT_DASHBOARD_FOLLOW_UP, editDashboardFollowUpSaga);
}
function* watchSnoozeDashboardFollowUp() {
  yield takeLatest(SNOOZE_DASHBOARD_FOLLOW_UP, snoozeDashboardFollowUpSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetDashboardData)]);
  yield all([fork(watchgetDashboardFollowUps)]);
  yield all([fork(watchEditDashboardFollowUp)]);
  yield all([fork(watchSnoozeDashboardFollowUp)]);
  yield all([fork(watchDeleteDashboardFollowUp)]);
  yield all([fork(watchAddDashboardFollowUp)]);
}
