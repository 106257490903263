import React from "react";

const HalfCheckedIcon = () => {
    return(
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={"halfcheck"}
        >
            <rect y="0.5" width="24" height="24" rx="4" fill="#6E41E2" />
            <rect y="0.5" width="24" height="24" rx="4" stroke="#D4D4D4" />
            <rect x="6" y="11.5" width="12" height="2" rx="1" fill="white" />
        </svg>
    )
}

export default HalfCheckedIcon
