import React, { useState } from "react";
import { Layout } from "antd";
import PageFooter from "../../components/Footer";
import SignInHeader from "../../components/Header/SignInHeader/SignInHeader";
import "./SignInOrUpLayout.scss"

export interface SignInOrUpLayoutProps {
    children: React.ReactNode;
}

const { Content, Footer } = Layout;
const SignInOrUpLayout: React.FC<SignInOrUpLayoutProps> = ({ children }) => {
    const [isFooterCollapsed, setIsFooterCollapsed] = useState(false);

    const collapseFooter = () => {
        setIsFooterCollapsed((prevState) => !prevState);
    };

    return (
        <Layout className="sign-in-or-up-layout" >
            <SignInHeader/>
            <Content >{children}</Content>
            <Footer
                className={`site-layout-footer ${isFooterCollapsed ? "collapsed" : ""}`}
            >
                <PageFooter
                    isGuest={true}
                    isFooterCollapsed={isFooterCollapsed}
                    collapseFooter={collapseFooter}
                />
            </Footer>
        </Layout>
    );
};

export default SignInOrUpLayout;
