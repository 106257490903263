import React from "react";

export const SearchWithShadow = () => {
  return (
    <svg
      width="118"
      height="108"
      viewBox="0 0 118 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="53.7713" cy="53.1807" r="53.1807" fill="#F1F1F1" />
      <circle
        cx="53.3369"
        cy="53.1323"
        r="30.8007"
        fill="#F1F1F1"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
      <circle
        cx="53.3362"
        cy="53.1323"
        r="21.7148"
        fill="#FFFEFF"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
      <path
        d="M53.3366 36.4331C53.3366 36.0995 53.066 35.828 52.7326 35.8396C48.5592 35.9853 44.5711 37.6365 41.5123 40.4993C38.4536 43.3622 36.5424 47.2325 36.1212 51.3871C36.0876 51.719 36.3406 52.0069 36.6734 52.029V52.029C37.0063 52.051 37.2928 51.7988 37.3273 51.467C37.7276 47.6183 39.5028 44.0346 42.3378 41.3813C45.1727 38.7279 48.8659 37.1934 52.7327 37.0484C53.066 37.0359 53.3366 36.7666 53.3366 36.4331V36.4331Z"
        fill="#C4C4C4"
      />
      <rect
        x="77.1803"
        y="86.8545"
        width="10.8913"
        height="31.6376"
        rx="5.44564"
        transform="rotate(-45 77.1803 86.8545)"
        fill="#F1F1F1"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
      <rect
        x="88.4954"
        y="89.3521"
        width="1.44362"
        height="12.1043"
        rx="0.721808"
        transform="rotate(-45 88.4954 89.3521)"
        fill="white"
      />
      <rect
        x="76.4924"
        y="79.5142"
        width="1.48188"
        height="5.92752"
        transform="rotate(-45 76.4924 79.5142)"
        fill="#C4C4C4"
      />
      <rect
        x="92.217"
        y="49.4009"
        width="14.8188"
        height="1.48188"
        rx="0.740939"
        fill="#C4C4C4"
      />
      <rect
        x="0.590576"
        y="61.6587"
        width="14.8188"
        height="1.48188"
        rx="0.740939"
        fill="#C4C4C4"
      />
      <rect
        x="110"
        y="49.4009"
        width="7.40939"
        height="1.48188"
        rx="0.740939"
        fill="#C4C4C4"
      />
      <rect
        x="95.9216"
        y="54.8379"
        width="7.40939"
        height="1.48188"
        rx="0.740939"
        fill="#C4C4C4"
      />
      <rect
        x="4.29492"
        y="67.0962"
        width="7.40939"
        height="1.48188"
        rx="0.740939"
        fill="#C4C4C4"
      />
    </svg>
  );
};
