import React, { useEffect } from "react";
import routes from "routes/routesCode";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { useAppContext } from "libs/contextLib";
import { Route, RouteProps, useLocation } from "react-router-dom";
import { getAuthToken } from "../../helpers/authUtils";
import { getLoggedInUser, signOut } from "store/user/action";
import LeadFlowLayout from "layouts/leadFlow/LeadFlowLeadFlowLayout";
import { LoadScriptNext } from "@react-google-maps/api";
import {
  googleMapsApiKey,
  googleMapsApiLibrariesArray,
} from "helpers/constants";
import { userSelector } from "../../store/user/selectors";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import { saveEmailScreeningLinkData } from "store/shareable/action";

export interface LeadAuthRouteProps extends RouteProps {
  component: React.JSXElementConstructor<any>;
}

const LeadAuthRoute: React.FC<LeadAuthRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);
  const queryObject = urlQueryToObject(UrlSearchParams);

  useEffect(() => {
    function onLoad() {
      try {
        if (getAuthToken()) {
          dispatch(getLoggedInUser());
          userHasAuthenticated(true);
        } else {
          dispatch(signOut({ redirectUrl: "", email: user.email }));
          userHasAuthenticated(false);
        }
      } catch (e) {
        dispatch(push(routes.listings.path));
      }
    }

    if (queryObject.screening === "true") {
      dispatch(saveEmailScreeningLinkData({ ...queryObject }));
    }
    onLoad();
  }, [dispatch]);

  return isAuthenticated && user && user.role === "Renter" ? (
    <Route
      {...rest}
      render={(...renderProps) => (
        <LoadScriptNext
          id="script-loader"
          googleMapsApiKey={googleMapsApiKey}
          libraries={googleMapsApiLibrariesArray}
        >
          <LeadFlowLayout>{<Component {...renderProps} />}</LeadFlowLayout>
        </LoadScriptNext>
      )}
    />
  ) :
    null;
};

export default LeadAuthRoute;
