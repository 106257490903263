import React, { useEffect } from "react";
import "./index.scss";
import BaseModal from "../../../../Shared/BaseModal";
import VerifyPhoneForm from "./components/VerifyPhoneForm";
import {useDispatch, useSelector} from "react-redux";
import { userSelector, verifyCodeSuccessSelector } from "../../../../../store/user/selectors";
import VerifyPhoneCode from "./components/VerifyPhoneCode";
import {setVerifyUserSuccess} from "../../../../../store/user/action";
import {useForm} from "antd/es/form/Form";
interface IPhoneVerifyModal {
    visible:boolean;
    onCancel:()=>void

}
const PhoneVerifyModalFollowUps = ({ visible,onCancel }:IPhoneVerifyModal) =>{
    const user = useSelector(userSelector);
    const verifyPhoneSuccess = useSelector(verifyCodeSuccessSelector);
    const [form] = useForm();
    useEffect(()=>{
        if(!visible) form.resetFields()
    },[visible])
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setVerifyUserSuccess());
    },[]);
    useEffect(()=>{
        if(verifyPhoneSuccess){
            onCancel();
        }
    },[onCancel, verifyPhoneSuccess]);
    return(
        <BaseModal
            classname={
                "verify-phone-modal-from-followUps"
            }
            cancelButtonProps={{
                className: "rb-secondary-btn sm",
            }}
            okButtonProps={{
                className: "rb-primary-btn sm",
            }}
            modalTitle=""
            isModalVisible={visible}
            onCancel={() => {
                onCancel();
            }}
            modalFooter={[null]}
            width={340}
        >
            {user?.phone ?
                <VerifyPhoneCode visible={visible} form={form}/>
                :
                <VerifyPhoneForm/>
            }
        </BaseModal>
    );
};

export default PhoneVerifyModalFollowUps;