import React, { useCallback, useEffect } from "react";
import RequirementNeedHelpSection from "../../../../req-need-help/RequirementNeedHelpSection";
import BaseButton from "../../../../../Shared/BaseButton";
import "./ManuallyAddedSuccess.scss";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../../../../store/user/selectors";
import { getLoggedInUser } from "../../../../../../store/user/action";
import { manuallyAddingLeadHandler } from "../../../../../../helpers/globalFunctions";
import { requirementFormSuccessSelector } from "../../../../../../store/requirement/selectors";
import { push } from "connected-react-router";
import { clearReqFormSubmitted, clearREquirementData } from "../../../../../../store/requirement/action";
import { TOKEN_ENUM } from "helpers/authUtils";

const ManuallyAddedSuccess = () => {
  const userData = useSelector(userSelector);
  const dispatch = useDispatch();
  const requirementSuccessData = useSelector(requirementFormSuccessSelector);

  useEffect(() => {
    if (requirementSuccessData) {
      if (Object.keys(requirementSuccessData).length) {
        if (requirementSuccessData.requirementId) {
          localStorage.setItem(
            "manually_added_lead_id",
            requirementSuccessData.requirementId
          );
        }
      }
    }
    return () => localStorage.removeItem("manually_added_lead_id");
  }, [requirementSuccessData]);
  useEffect(() => {
    if (!Object.keys(userData).length) dispatch(getLoggedInUser());
  }, [userData]);

  const addAnotherLeadHandler = useCallback(() => {
    localStorage.removeItem(TOKEN_ENUM.GUEST_ACCESS_TOKEN);
    dispatch(clearReqFormSubmitted());
    dispatch(clearREquirementData());
    localStorage.removeItem("renterDraft_token");
    manuallyAddingLeadHandler(dispatch, userData);
  }, [userData]);

  const matchToListingsHandler = useCallback(() => {
    dispatch(
      push(
        `/${localStorage.getItem(
          "manually_added_lead_id"
        )}?match_to_listings=true`
      )
    );
    localStorage.removeItem("manually_added_lead_id");
  }, [requirementSuccessData, localStorage.getItem("manually_added_lead_id")]);
  return (
    <div className={"manually-added-success"}>
      <h2 className={"manually-added-success-title"}>Submitted!</h2>
      <p className={"manually-added-success-desc"}>
        Your lead will get a confirmation
        <br />
        of this form.
      </p>
      <div className={"manually-added-success-actions"}>
        <BaseButton
          classnames={"rb-primary-btn"}
          onClick={matchToListingsHandler}
        >
          Match to listings
        </BaseButton>
        <BaseButton
          classnames={"rb-secondary-btn"}
          onClick={addAnotherLeadHandler}
        >
          Add another lead
        </BaseButton>
      </div>
      <RequirementNeedHelpSection />
    </div>
  );
};

export default ManuallyAddedSuccess;
