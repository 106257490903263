import React from "react";

export const ContactWithBackgroundIcon = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11.5" cy="11.5" r="11.5" fill="#5806B9" />
            <path d="M13.9689 17.2461L11.7345 15.7565C11.6346 15.6905 11.5007 15.7122 11.4268 15.8064L10.7762 16.6429C10.5653 16.9176 10.1848 16.9961 9.8825 16.827L9.76222 16.761C9.32827 16.5242 8.78803 16.2297 7.7156 15.1573C6.64317 14.0849 6.34842 13.5446 6.11191 13.1104L6.04562 12.9902C5.87662 12.6879 5.95505 12.3075 6.2298 12.0967L7.06598 11.4459C7.16029 11.372 7.18204 11.2381 7.11593 11.1381L5.62659 8.90354C5.55885 8.80215 5.42351 8.77164 5.31882 8.83418L4.38393 9.39477C4.18336 9.51183 4.03585 9.70195 3.97225 9.9253C3.66521 11.0434 3.9235 13.0013 6.89718 15.9752C9.87087 18.9492 11.8283 19.2079 12.9473 18.9006C13.1707 18.8369 13.3608 18.6893 13.4779 18.4887L14.0382 17.5538C14.1009 17.4492 14.0704 17.3138 13.9689 17.2461Z" fill="white" />
            <path d="M17.0902 6.49279C17.0657 6.46829 17.0324 6.4547 16.9977 6.45509H7.07829C7.04364 6.4547 7.01029 6.46829 6.9858 6.49279C6.9613 6.51728 6.94771 6.55063 6.94809 6.58528V10.0318L7.51058 10.8755C7.71345 11.182 7.64678 11.593 7.35742 11.8196L6.52197 12.4704C6.43253 12.5383 6.40653 12.6615 6.46088 12.7597L6.52906 12.884C6.74644 13.2974 7.01159 13.6839 7.31907 14.0355H16.9977C17.0687 14.0361 17.1269 13.9792 17.1279 13.9082V6.58528C17.1283 6.55063 17.1147 6.51728 17.0902 6.49279ZM10.364 10.7983V10.7971L7.93886 13.3953C7.84968 13.491 7.69986 13.4962 7.60424 13.4071C7.50859 13.3179 7.50336 13.1681 7.59251 13.0725L10.0177 10.4754C10.1068 10.3798 10.2567 10.3745 10.3523 10.4637C10.4479 10.5529 10.4532 10.7027 10.364 10.7983ZM12.038 10.6118C11.8674 10.6125 11.7012 10.5577 11.5645 10.4555L7.60765 7.45507C7.50346 7.3757 7.48331 7.22689 7.56268 7.12267C7.64204 7.01848 7.79085 6.99833 7.89507 7.0777L11.8503 10.0782C11.9622 10.1595 12.1138 10.1595 12.2258 10.0782L16.1822 7.0777C16.2864 6.99867 16.4349 7.01907 16.514 7.12327C16.593 7.22746 16.5726 7.37601 16.4684 7.45507L12.5115 10.4555C12.3749 10.5577 12.2086 10.6125 12.038 10.6118ZM16.4868 13.407C16.4409 13.4499 16.3799 13.4727 16.3171 13.4705C16.2543 13.4684 16.1949 13.4413 16.1521 13.3954L13.7272 10.7971C13.6448 10.7006 13.6532 10.5562 13.7463 10.4699C13.8393 10.3836 13.9839 10.386 14.074 10.4754L16.4984 13.0725C16.5876 13.168 16.5824 13.3178 16.4868 13.407Z" fill="white" />
        </svg>
    );
};
