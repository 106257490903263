import { LogoName, RBLogo } from "../../icons";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./SignInHeader.scss";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { windowSelector } from "../../../store/app/selectors";
import { AnalyticsService } from "helpers/analyticsService";

const SignInHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { type } = useSelector(windowSelector);
  const signInHeaderClassNamesHandler = () => {
    if (location.pathname.includes("forceResetPassword") || location.pathname.includes("public/listings")) {
      return "sign-in-header sign-in-header-force";
    } else if (location.pathname.includes("requirement") && !location.search.includes("reqStep")) {
      return "sign-in-header sign-in-header-req sign-in-header-force";
    } else if (location.search.includes("reqStep")) {
      return "sign-in-header sign-in-header-req sign-in-header-force sign-in-header-centered";
    } else {
      return "sign-in-header";
    }
  };
  return (
    <header className={signInHeaderClassNamesHandler()}>
      <div
        className={"sign-in-header--logo"}
        onClick={() => {
          dispatch(push("/"));
        }}
      >
        <RBLogo />
        <LogoName />
      </div>
      {location.pathname.includes(`sign-in`) && (
        <p>
          {type !== "mobile" ? (
            <>
              New to RentBase?{" "}
              <Link
                to={`/sign-up${location.search}`}
                onClick={() => {
                  AnalyticsService.reportEvent("sign_in_page_click", { label: "sign_up" });
                }}
              >
                Sign up
              </Link>
            </>
          ) : (
            <Link
              to={"/sign-up"}
              onClick={() => {
                AnalyticsService.reportEvent("sign_in_page_click", { label: "sign_up" });
              }}
            >
              Sign up
            </Link>
          )}
        </p>
      )}
      {location.pathname.includes(`sign-up`) && (
        <p>
          <Link
            to={`/sign-in${location.search.includes("referring_agent") ? "" : location.search}`}
            onClick={() => {
              AnalyticsService.reportSignUpProcess({ action: "sign_up_page_click", label: "sign_in" });
            }}
          >
            Sign in
          </Link>
        </p>
      )}
    </header>
  );
};

export default SignInHeader;
