export const leadValueTypes = {
    "Must haves": {
        dishwasher: 'Dishwasher',
        laundry_in_unit: 'Laundry in-unit',
        any_laundry: 'Any Laundry',
        covered_parking: 'Covered Parking',
        yard: 'Yard',
        outdoor_space: 'Outdoor space',
        ac: 'AC',
        ensuite: 'Ensuite',
        top_floor: 'Top floor',
        doorman: 'Doorman',
        pool: 'Pool',
        storage: 'Storage',
        basement: 'Basement',
        elevator: 'Elevator',
        gym: 'Gym',
        no_pets: 'No Pets',
        closet: 'Closet',
        granite_kitchen: 'Granite kitchen',
        bathtub: 'Bathub',
        fireplace: 'Fireplace',
        gas_stovetop: 'Gas stovetop',
        furnished: 'Furnished',
    },  
    "Deal Breakers": {
        carpet_bedroom: 'Carpet bedroom',
        carpet_anywhere: 'Carpet anywhere',
        first_floor: 'First floor',
        owner_occupied: 'Owner occupied',
        basement_unit: 'Basement unit',
        one_flight_stairs: 'One flight stairs',
        two_plus_stairs: 'Two plus stairs',
        large_complex: 'Large complex',
        loft_unit: 'Studio apartment',
        oil_heat: 'Oil heat',
        galley_kitchen: 'Galley kitchen',
        pay_laundry: 'Pay laundary',
    }
}
