import React from "react";
import BaseModal from "../BaseModal";
import { TRANSUNION_AUTHENTICATION_PHONE } from "../../../helpers/constants";

const CallTUModal: React.FC<{
  visible: boolean;
  setVisible: (visible: boolean) => void;
}> = ({ visible, setVisible }) => {
  return (
    <BaseModal
      isModalVisible={visible}
      modalTitle="Call TransUnion"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      cancelText="Close"
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{
        className: "ant-btn rb-primary-btn",
      }}
      width={400}
      centered={true}
    >
      Please call TransUnion at <a href={`tel: ${TRANSUNION_AUTHENTICATION_PHONE}`}>{TRANSUNION_AUTHENTICATION_PHONE}</a> to complete your screening.
    </BaseModal>
  );
};

export default CallTUModal;
