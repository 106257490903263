import React, { useEffect, useState } from "react";
import ShareOrSeeRequirement from "./components/share-or-see-requirement/ShareOrSeeRequirement";
import LeadFlowWrapper from "../lead-flow-wrapper";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import IndividualLeadSharePopup from "../../../../Shared/IndividualLeadSharePopup/IndividualLeadSharePopup";
import { getRequirementAgentInfo } from "../../../../../store/requirement/action";
import { useParams } from "react-router-dom";
import SuccessAgentAvatar from "../../../../Shared/success-agent-avatar/SuccessAgentAvatar";
import { requirementAgentDataSelector } from "../../../../../store/requirement/selectors";
import "./styles.scss";
import RequirementNeedHelpSection from "../../../req-need-help/RequirementNeedHelpSection";
import RenterResendVerifyEmail from "./renter-resend-verify-email/RenterResendVerifyEmail";
import { useLocation } from "react-router";
import SuccessAgentView from "../success-agent-view";
import ManuallyAddedSuccess from "./manually-added-success/ManuallyAddedSuccess";
import { AnalyticsService } from "helpers/analyticsService";
import { camelCaseToLowerCaseWith_ } from "helpers/globalFunctions";
import { TOKEN_ENUM } from "helpers/authUtils";
const RenterCreatedSuccess = () => {
  const [isEmailWrapper, setIsEmailWrapper] = useState(false);
  const dispatch = useDispatch();
  const [isSharePopupVisible, setIsSharePopupVisible] = useState(false);
  const { agent_id } = useParams();
  const requirementAgentInfo = useSelector(requirementAgentDataSelector);
  const location = useLocation();
  const isPreviewMode = location.search.includes("previewMode=true") && !location.search.includes("is_manual=true");
  const isManualMode = location.search.includes("is_manual=true");
  useEffect(() => {
    dispatch(getRequirementAgentInfo(agent_id));
  }, []);

  const onActionBtnsClick = (e: any) => {
    const targetButtonName = e.target.dataset.testId;
    AnalyticsService.reportEvent(`${camelCaseToLowerCaseWith_(targetButtonName)}_click`, { category: "renter" });
    const isAuth = localStorage.getItem(TOKEN_ENUM.ACCESS_TOKEN) && localStorage.getItem(TOKEN_ENUM.REFRESH_TOKEN);
    if (!isAuth) {
      return setIsEmailWrapper(true);
    } else if (targetButtonName === "seeRequirements" || targetButtonName === "shareRequirements") {
      const leadAccountLinkObj = localStorage.getItem("leadAccountLinkObj")
        ? JSON.parse(localStorage.getItem("leadAccountLinkObj") || "")
        : "";
      if (targetButtonName === "seeRequirements") {
        dispatch(push(leadAccountLinkObj.accountLink));
      } else {
        setIsSharePopupVisible(true);
      }
    }
  };
  return (
    <LeadFlowWrapper classNames="req-height">
      {isManualMode ? (
        <ManuallyAddedSuccess />
      ) : isPreviewMode ? (
        <SuccessAgentView />
      ) : !isEmailWrapper ? (
        <div className={"renter-created-success"}>
          <SuccessAgentAvatar url={requirementAgentInfo?.imagesOfAgent?.url} />
          <div className={"renter-created-success-text"}>
            <h3>You're All Set!</h3>
            <p>
              Thank you for completing your Renter Passport.
              <br />I look forward to working with you in finding your next home!
            </p>
          </div>
          <ShareOrSeeRequirement onActionBtnsClick={onActionBtnsClick} />
          {isSharePopupVisible && (
            <IndividualLeadSharePopup
              visible={isSharePopupVisible}
              onClose={() => {
                setIsSharePopupVisible(false);
              }}
            />
          )}
          <RequirementNeedHelpSection />
        </div>
      ) : (
        <RenterResendVerifyEmail
          onClose={() => {
            setIsEmailWrapper(false);
          }}
        />
      )}
    </LeadFlowWrapper>
  );
};

export default RenterCreatedSuccess;
