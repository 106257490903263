import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { Spin } from "antd";
import BaseButton from "../../Shared/BaseButton";
import Avatar from "antd/es/avatar/avatar";
import { useHistory, useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getRequirementAgentInfo } from "../../../store/requirement/action";
import { loadingRequirementAgentDataSelector, requirementAgentDataSelector } from "../../../store/requirement/selectors";
import { AnalyticsService } from "../../../helpers/analyticsService";
import requirementsMainImage from "assets/images/requirement-image.svg";
import { useAppContext } from "libs/contextLib";
import AuthContent from "./RequiementsLeadAuth";
import GlobalLoading from "../../Shared/GlobalLoading";
import { authLoadingSelector } from "../../../store/user/selectors";
import { leadAccountLinkSelector, leadLoadingSelector, singleLeadDataSelector } from "../../../store/leads/selectors";
import { push } from "connected-react-router";
import { useLocation } from "react-router";
import VerifiedBadge from "../../icons/VerifiedBadge";
import Tooltip from "antd/es/tooltip";
import BaseTooltip from "../../Shared/base-tooltip/BaseTooltip";
import VerifiedInfoIcon from "../../icons/VerifiedInfoIcon";
import { SUBSCRIPTION_TYPES } from "helpers/constants";
import routes from "routes/routesCode";

const RequirementAuth = () => {
  const { isAuthenticated } = useAppContext();
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const requirementAgentData = useSelector(requirementAgentDataSelector);
  const loadingRequirementAgentData = useSelector(loadingRequirementAgentDataSelector);
  const leadDataLoading = useSelector(leadLoadingSelector);
  const userLoading = useSelector(authLoadingSelector);
  const leadData = useSelector(singleLeadDataSelector);
  const [requestedAgent, setRequestedAgent] = useState(localStorage.getItem("requested_agent_id"));
  const [showTitle, setShowTitle] = useState(true);
  const leadAccountLink = useSelector(leadAccountLinkSelector);
  const { pathname } = useLocation();

  useEffect(() => {
    if (Object.keys(leadData).length && Object.keys(requirementAgentData).length) {
      if (leadData?.requirement?.agentUser_ids?.includes(requirementAgentData?.agent_id)) {
        setRequestedAgent(requirementAgentData?.agent_id);
        setShowTitle(false);
      } else {
        setShowTitle(true);
      }
    }
  }, [leadData, requestedAgent, requirementAgentData]);

  useEffect(() => {
    AnalyticsService.reportRequirementsFunnel({ action: "requirements_start_page_viewed" });
  }, []);

  useEffect(() => {
    dispatch(getRequirementAgentInfo(params.agent_id));
  }, [dispatch, params.agent_id]);

  const GuestContent = () => {
    const handleStartBtnClick = useCallback(() => {
      AnalyticsService.reportRequirementsFunnel({ action: "requirements_start_page_cta_click", label: "start here" });
      history.push(`/requirement/create/${params.agent_id}?reqStep=1`);
    }, []);

    return (
      <>
        {/* start here */}
        <BaseButton variant="primary" className="mt-4" onClick={handleStartBtnClick} dataTestId="start-here">
          Start Here
        </BaseButton>
        {/* already - login */}
        <div className={"secondary-cta-wrapper"}>
          <p className="mb-0 text-sm">
            Already have a RentBase profile?
            <BaseButton
              variant="link"
              className={"ml-2 req-login-link"}
              onClick={() => {
                AnalyticsService.reportRequirementsFunnel({ action: "requirements_start_page_click", label: "log_in" });
                localStorage.setItem("requested_agent_id", pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length));
                localStorage.setItem("log_in_from_req", "true");
                history.push("/sign-in");
              }}
            >
              Login
            </BaseButton>
          </p>
        </div>
      </>
    );
  };

  return (
    <Spin
      className={"global-loading"}
      indicator={<GlobalLoading />}
      spinning={userLoading || loadingRequirementAgentData || leadDataLoading}
    >
      <div className={"requirement-auth-section-wrapper req-height"}>
        <Spin spinning={loadingRequirementAgentData || leadDataLoading || userLoading}>
          <div className={"requirement-page"}>
            <div className={"requirement-main-row"}>
              <div className={"requirement-main-col"}>
                {/* CTA */}
                {/* main title */}
                <div className={"title-row"}>
                  <div className={"avatar-in-title-wrapper"}>
                    <Avatar
                      src={requirementAgentData?.imagesOfAgent?.url}
                      icon={<UserOutlined />}
                      size={{ xs: 60, sm: 70, md: 90, lg: 90, xl: 90, xxl: 90 }}
                    />
                    {requirementAgentData.stripeSubscriptionStatus !== SUBSCRIPTION_TYPES.free ? <VerifiedBadge /> : null}
                  </div>
                  {showTitle && (
                    <div className="mb-8">
                      <h1 className={"req-main-title"}>
                        Let’s Find Your
                        <br />
                        Next Home.
                      </h1>
                    </div>
                  )}
                </div>
                <div className={"avatar-description-container"}>
                  {/* avatar */}
                  <div className={"avatar-in-description-wrapper"}>
                    <Avatar
                      src={requirementAgentData?.imagesOfAgent?.url}
                      icon={<UserOutlined />}
                      size={{ xs: 90, sm: 90, md: 90, lg: 90, xl: 90, xxl: 90 }}
                    />
                    {requirementAgentData.stripeSubscriptionStatus !== SUBSCRIPTION_TYPES.free ? <VerifiedBadge /> : null}
                  </div>
                  <div className={"description-agent-container"}>
                    {/* secondary title */}
                    <div>
                      {showTitle ? (
                        <span className={"description"}>Please fill out this quick questionnaire so I can better assist you.</span>
                      ) : (
                        <span className={"description"}>
                          You are already connected to this agent. To review all connected agents{" "}
                          <span
                            onClick={() => {
                              AnalyticsService.reportRequirementsFunnel({
                                action: "requirements_start_page_cta_click",
                                label: "visit your renter profile",
                              });
                              dispatch(push(routes.renterPortalDashboard.path));
                            }}
                          >
                            visit your renter profile.
                          </span>
                        </span>
                      )}
                    </div>
                    {/* agent name */}
                    <div>
                      <span className={"agent-name"}>- {requirementAgentData?.name}</span>
                    </div>
                  </div>
                </div>
                {requirementAgentData.stripeSubscriptionStatus !== SUBSCRIPTION_TYPES.free ? (
                  <div className={"verified-user-info-wrapper"}>
                    <p>
                      Verified Agent
                      <BaseTooltip
                        text={"This agent has been verified by the RentBase system"}
                        classnames={"requirement-verified-agent-tooltip"}
                      >
                        <VerifiedInfoIcon />
                      </BaseTooltip>
                    </p>
                  </div>
                ) : null}
                <div className="pt-4">{isAuthenticated ? <AuthContent showContent={showTitle} /> : <GuestContent />}</div>
              </div>
              {/* page image */}
              <div className="main-image-wrapper">
                <img src={requirementsMainImage} alt="" className={"main-image"} />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Spin>
  );
};

export default RequirementAuth;
