import React from "react";
import isNil from "lodash/isNil";
import { useSelector } from "react-redux";
import { Table } from "antd";
import { windowSelector } from "../../../../store/app/selectors";
import ListingHistoryPriceIcon from "../../../icons/listing-history-icons/ListingHistoryPriceIcon";
import {
  beautifiedKeyStyling,
  getBrowserDateFormat,
} from "../../../../helpers/globalFunctions";
import { IObjectKeys } from "helpers/globalTypes";
import './ListingHistoryTable.scss';
import { formatCurrency } from "helpers/currencyHelper";
import { dateToMoment, formatDate } from '../../../../helpers/dateHelper';
import { isShareListingPage } from "helpers/clientRoutes";
import { useLocation } from "react-router-dom";

const LOGIN_NOTICE = "Hidden";

interface IListingHistoryCollapseItems {
  listingHistory: any[];
  canViewListingAgents?: boolean;
}

const checkPriceIconHandler = (data: any) => {
  if (!data.from && !data.to) return null;
  if (data.from > data.to) {
    return <ListingHistoryPriceIcon className={"grow-down"} variant="light" />;
  } else {
    return <ListingHistoryPriceIcon className={"grow-up"} variant="light" />;
  }
};

const formatHistoryItemChange = (value: any, field: string) => {
  switch (field) {
    case "listing_created":
      return `Listing created`;
    case "living_area_size":
      return `${value} sq/ft`;
    case "asked_rent":
      return formatCurrency(value, undefined, " per month");
    default:
      break;
  }
  return value;
};

const renderChange = (change: any, idx: number) => {
  return (
    <div key={`${change.changed_field}-${idx}`} className="font-16">
      {["external_id"].includes(change.changed_field) &&
        "New listing created with Listing ID "}
      {!["listing_created", "external_id"].includes(change.changed_field) &&
        `${beautifiedKeyStyling(change.changed_field)}: `}
      {!isNil(change.from) &&
        !["listing_created", "external_id"].includes(change.changed_field) && (
          <>
            from{" "}
            <div className="change-item-value inline-flex">
              {formatHistoryItemChange(change.from, change.changed_field)}
            </div>
          </>
        )}
      {!isNil(change.to) && (
        <>
          {!["listing_created", "external_id"].includes(change.changed_field) &&
            ` to `}
          <div className="change-item-value inline-flex">
            {formatHistoryItemChange(change.to, change.changed_field)}
            {["asked_rent"].includes(change.changed_field) && (
              <div className="ml-2 inline-flex">
                {checkPriceIconHandler(change)}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const ListingHistoryTable = ({
  listingHistory,
  canViewListingAgents,
}: IListingHistoryCollapseItems) => {
  const { type } = useSelector(windowSelector);
  const isMobile = type === "mobile";
  const location = useLocation();
  const isShareListing = isShareListingPage(location);
  const LISTING_HISTORY_TABLE_COLUMNS = [
    {
      dataIndex: "_id",
      key: "date",
      title: "Date",
      render: (_id: string, rowData: IObjectKeys) => (
        <div className="listing-history-column">
          <span>{formatDate(dateToMoment(rowData.date), getBrowserDateFormat())}</span>
        </div>
      ),
    },
    {
      dataIndex: "_id",
      key: "agent",
      title: "Agent",
      render: (_id: string, rowData: IObjectKeys) => (
        <div className="listing-history-column">
          <div>
            {canViewListingAgents
              ? isShareListing
                ? " "
                : rowData?.agent?.name
              : LOGIN_NOTICE}
          </div>
        </div>
      ),
    },
    {
      dataIndex: "_id",
      key: "changes",
      title: "Changes",
      render: (_id: string, rowData: IObjectKeys) => (
        <div className="listing-history-column">
          {rowData.changes?.map((historyItem: any, idx: number) => {
            return renderChange(historyItem, idx);
          })}
        </div>
      ),
    },
  ];

  interface IListingHistory {
    date: string;
    agent: Object;
    changes: [];
  }

  const formatHistoryData = (listingHistory: any) =>
    Object.keys(listingHistory)
      .sort((a: any, b: any) => (Date.parse(a) < Date.parse(b) ? 1 : -1))
      .map((date, idx) => ({
        key: idx,
        date,
        agent: listingHistory[date].agent,
        changes: listingHistory[date].changes,
      }));
  const renderMobileHistory = (history: any) => {
    return (
      <>
        {Object.keys(history).map((date, idx) => {
          return (
            <div className="p-5 font-16" key={`history-item-${idx}`}>
              <div>Date: {history[date].date}</div>
              {!isShareListing && (
                <div>
                  Agent:{" "}
                  {canViewListingAgents
                    ? isShareListing
                      ? " "
                      : history[date].agent.name
                    : LOGIN_NOTICE}
                </div>
              )}
              <div>
                Changes:{" "}
                {history[date].changes.map((change: object, idx: number) =>
                  renderChange(change, idx)
                )}
              </div>
              <hr />
            </div>
          );
        })}
      </>
    );
  };

  const history = formatHistoryData(listingHistory);

  return (
    <div className={"history-wrapper"}>
      {!isMobile ? (
        <Table
          dataSource={history}
          columns={LISTING_HISTORY_TABLE_COLUMNS}
          pagination={false}
        />
      ) : (
        <>{renderMobileHistory(history)}</>
      )}
    </div>
  );
};

export default ListingHistoryTable;
