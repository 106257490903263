import { TimePicker } from "antd";
import moment from "moment";
import React from "react";
import "./MozTimePicker.scss";
import { getBrowser } from "../../../../../helpers/globalFunctions";
import { dateToMoment, formatDate } from "../../../../../helpers/dateHelper";
const format = "HH:mm";

const MozTimePicker = ({
  setTimeData,
  setShowingData,
}: {
  setTimeData: React.Dispatch<React.SetStateAction<string>>;
  setShowingData?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <TimePicker
      format={format}
      defaultValue={dateToMoment().set({ hour: 8, minute: 0 })}
      use12Hours={true}
      className={"moz-timepicker"}
      onChange={(data) => {
        if (setShowingData) {
          setShowingData(true);
        }
        setTimeData(
          formatDate(dateToMoment(formatDate(data, getBrowser())), "HH:mm:ss")
        );
      }}
    />
  );
};

export default MozTimePicker;
