import React, { useEffect } from "react";
import { Carousel, Col, Row } from "antd";
import { IImage } from "../../Listings/helpers/mockListings";
import { SLIDER_SETTINGS } from "../../../helpers/globalFunctions";
import { useSelector } from "react-redux";
import {
  CurrentImageIndexSelector,
  listingDataSelector,
} from "../../../store/listings/selectors";

const ListingMainImageSlider = ({ isFromModal, listingData }: { isFromModal?: boolean; listingData: any; }) => {
  const listingDataStore = useSelector(listingDataSelector);
  const listing = listingData ?? listingDataStore;
  const currentImageIndex = useSelector(CurrentImageIndexSelector);

  const sliderRef = React.useRef(null);

  useEffect(() => {
    if (isFromModal) {
      // @ts-ignore
      if (sliderRef && sliderRef?.current && sliderRef?.current?.goTo) {
        // @ts-ignore
        sliderRef.current.goTo(currentImageIndex);
      }
    }
  }, [currentImageIndex, isFromModal]);
  return (
    <Row className="h-full">
      <Col className="h-full w-full">
        <div className="main-image-container ">
          <Carousel ref={sliderRef} {...SLIDER_SETTINGS}>
            {listing?.images
              ?.sort((a: IImage, b: IImage) => {
                return (
                  new Date(a.created_at).getTime() -
                  new Date(b.created_at).getTime()
                );
              })
              .map((file: any, i: number) => {
                return (
                  <div key={i} className="carousel-item-wrapper">
                    {i === 0 ? (
                      <img src={file.url} alt={file.name} loading="eager" />
                    ) : (
                      <img src={file.url} alt={file.name} loading="lazy" />
                    )}
                  </div>
                );
              })}
          </Carousel>
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(ListingMainImageSlider);
