import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";
interface DashboardStateType {
  dashboardLoading: boolean,
  listings: null | IObjectKeys,
  getListingsSuccess: boolean,
  getListingsError: null | IObjectKeys,
  landlords: null | IObjectKeys,
  getlandlordsSuccess: boolean,
  getlandlordsError:null | IObjectKeys,
  followUps: {
    followUps:[],
  } | IObjectKeys,
  getFollowUpsSuccess: boolean,
  getFollowUpsError: null | IObjectKeys,
  leads: null | IObjectKeys,
  getLeadsSuccess: boolean,
  getLeadsError: null | IObjectKeys,
  getDashboardDataSuccess: boolean,
  getDashboardDataError: null | IObjectKeys,
  isHaveData:boolean;
  editDashboardFollowUpSuccess: boolean,
  dashboardFollowUpsLoading:boolean,
  snoozeSuccess:boolean,
  snoozedFollowUp:IObjectKeys,
  dashboardFUAddedSuccess:boolean,
  getDashboardFUDataAfterChanges:boolean
}

const initialState: DashboardStateType = {
  dashboardLoading: false,
  listings: null,
  getListingsSuccess: false,
  getListingsError: null,
  landlords: null,
  getlandlordsSuccess: false,
  getlandlordsError: null,
  followUps: {
    followUps:[]
  },
  getFollowUpsSuccess: false,
  getFollowUpsError: null,
  leads: null,
  getLeadsSuccess: false,
  getLeadsError: null,
  getDashboardDataSuccess: false,
  getDashboardDataError: null,
  isHaveData:false,
  editDashboardFollowUpSuccess:false,
  dashboardFollowUpsLoading:false,
  snoozeSuccess:false,
  snoozedFollowUp:{},
  dashboardFUAddedSuccess:false,
  getDashboardFUDataAfterChanges:false
};

export default function DashboardReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.GET_DASHBOARD_LISTINGS:
      return {
        ...state,
        dashboardLoading: true,
        getListingsSuccess: null,
        getListingsError: null,
      };
    case types.GET_DASHBOARD_LISTINGS_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        getListingsSuccess: true,
        getListingsError: null,
        listings: action.payload
      };
    case types.GET_DASHBOARD_LISTINGS_ERROR:
      return {
        ...state,
        dashboardLoading: false,
        getListingsSuccess: null,
        getListingsError: action.payload,
      };

    case types.GET_DASHBOARD_LANDLORDS:
      return {
        ...state,
        dashboardLoading: true,
        getlandlordsSuccess: false,
        getlandlordsError: null,
      };
    case types.GET_DASHBOARD_LANDLORDS_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        getlandlordsSuccess: true,
        getlandlordsError: null,
        landlords: action.payload
      };
    case types.GET_DASHBOARD_LANDLORDS_ERROR:
      return {
        ...state,
        dashboardLoading: false,
        getlandlordsSuccess: false,
        getlandlordsError: action.payload,
      };

    case types.GET_DASHBOARD_FOLLOW_UPS:
      return {
        ...state,
        dashboardLoading: true,
        getFollowUpsSuccess: false,
        getFollowUpsError: null,
        getDashboardFUDataAfterChanges:false
      };
    case types.GET_DASHBOARD_FOLLOW_UPS_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        getFollowUpsSuccess: true,
        getFollowUpsError: null,
        followUps: action.payload,
        getDashboardFUDataAfterChanges:false
      };
    case types.GET_DASHBOARD_FOLLOW_UPS_ERROR:
      return {
        ...state,
        dashboardLoading: false,
        getFollowUpsSuccess: false,
        getFollowUpsError: action.payload
      };

    case types.GET_DASHBOARD_LEADS:
      return {
        ...state,
        dashboardLoading: true,
        getLeadsSuccess: false,
        getLeadsError: null,
      };
    case types.GET_DASHBOARD_LEADS_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        getLeadsSuccess: true,
        getLeadsError: null,
        leads: action.payload
      };
    case types.GET_DASHBOARD_LEADS_ERROR:
      return {
        ...state,
        dashboardLoading: false,
        getLeadsSuccess: false,
        getLeadsError: action.payload
      };

    case types.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardLoading: true,
        getDashboardDataSuccess: false,
        getDashboardDataError: null
      };
    case types.GET_DASHBOARD_DATA_SUCCESS:
      const { listings, followUps, landlords, leads } = action.payload;
      const isHaveData =
      listings.count ||
      followUps.count ||
      landlords.count ||
      leads.count;

      return {
        ...state,
        dashboardLoading: false,
        getDashboardDataSuccess: true,
        getDashboardDataError: null,
        listings,
        followUps,
        landlords,
        leads,
        isHaveData
      };
    case types.GET_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        dashboardLoading: false,
        getDashboardDataSuccess: false,
        getDashboardDataError: action.payload
      };
    case types.ADD_DASHBOARD_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        followUps:{
          ...state.followUps,
          count: 1,
          followUps:[action.payload]
        },
        dashboardFUAddedSuccess:true,
      }
    case types.SET_DASHBOARD_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        dashboardFUAddedSuccess:false
      }
    case types.ADD_DASHBOARD_FOLLOW_UP_FAIL:
      return {
        ...state,
        dashboardLoading: false,
        getDashboardDataSuccess: false,
        getDashboardDataError: action.payload,
      }
    case types.EDIT_DASHBOARD_FOLLOW_UP:
      return {
        ...state,
        editDashboardFollowUpSuccess: false,
        dashboardFollowUpsLoading:true
      };
    case types.EDIT_DASHBOARD_FOLLOW_UP_SUCCESS:
      let updatedFollowUps = state.followUps.followUps.map((item: any) => {
        if (item._id === action.payload._id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        followUps: {
          ...state.followUps,
          count:updatedFollowUps.filter((item:any)=>item.todo_status !== "completed").length,
          followUps:updatedFollowUps.filter((item:any)=>item.todo_status !== "completed")
        },
        editDashboardFollowUpSuccess: true,
        dashboardFollowUpsLoading: false,
      };
    case types.EDIT_DASHBOARD_FOLLOW_UP_SUCCESS_CLEAR:
      return {
        ...state,
        editFollowUpSuccess: false,
      };
    case types.SNOOZE_DASHBOARD_FOLLOW_UP:
      return {
        ...state,
        editFollowUpSuccess: false,
        followUpsLoadingListing: true,
        snoozeSuccess:false,
        snoozedFollowUp:{}
      };
    case types.SNOOZE_DASHBOARD_FOLLOW_UP_SUCCESS:
      let snoozedFollowUps = state.followUps.followUps.map((item: any) => {
        if (item._id === action.payload._id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        followUps: {
          ...state.followUps,
          followUps:snoozedFollowUps
        },
        followUpsLoadingListing: false,
        snoozeSuccess:true,
        editFollowUpSuccess:false,
        snoozedFollowUp:action.payload
      };
    case types.DELETE_DASHBOARD_FOLLOW_UP:
      return {
        ...state,
      };
    case types.DELETE_DASHBOARD_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        getDashboardFUDataAfterChanges:true,
        followUps: {
          ...state.followUps,
          count:state.followUps.followUps.filter((item:IObjectKeys)=>item._id !== action.payload._id).length,
          followUps:state.followUps.followUps.filter((item:IObjectKeys)=>item._id !== action.payload._id)
        }
      }
    case types.SET_SNOOZE_SUCCESS_TO_FALSE:
      return {
        ...state,
        snoozeSuccess:false,
        editDashboardFollowUpSuccess:false,
        editFollowUpSuccess: false
      }
    default:
      return state;
  }
}
