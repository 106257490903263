import { IPaginationValues } from "components/Listings/components/listings-wrapper";
import { IObjectKeys } from "helpers/globalTypes";
import * as types from "./types";

interface agentRequestReducer {
  agentRequestData: IObjectKeys[];
  agentRequestLoading: boolean;
  agentRequestError: string | IObjectKeys | null;
  agentsAllRequestData: IObjectKeys[];
  agentsAllRequestLoading: boolean;
  agentsAllRequestError: string | IObjectKeys | null;
  allRequestsTotal: number;
  allPaginationValues: IPaginationValues;
}

const initialState: agentRequestReducer = {
  agentRequestData: [],
  agentRequestLoading: false,
  agentRequestError: null,
  agentsAllRequestData: [],
  agentsAllRequestLoading: false,
  agentsAllRequestError: null,
  allRequestsTotal: 0,
  allPaginationValues: { current: 1, pageSize: 24 },
};

export default function agentRequestReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.GET_AGENTS_SCREENING_REQUEST:
      return {
        ...state,
        agentRequestLoading: true,
        agentRequestData: [],
      };
    case types.GET_AGENTS_SCREENING_REQUEST_SUCCESS:
      return {
        ...state,
        agentRequestLoading: false,
        agentRequestData: action.payload,
      };
    case types.GET_AGENTS_SCREENING_REQUEST_FAIL:
      return {
        ...state,
        renterRequestLoading: false,
        agentRequestError: action.payload,
      };
    case types.GET_TENANT_SCREENING_REQUESTS:
      return {
        ...state,
        agentsAllRequestLoading: true,
        agentsAllRequestData: []
      };
    case types.GET_TENANT_SCREENING_REQUESTS_SUCCESS:
      return {
        ...state,
        agentsAllRequestLoading: false,
        agentsAllRequestData: action.payload,
        allRequestsTotal:action.payload?.length,
      };
    case types.GET_TENANT_SCREENING_REQUESTS_FAIL:
      return {
        ...state,
        agentsAllRequestLoading: false,
        agentsAllRequestError: action.payload,
      };
    default:
      return state;
  }
}
