import React from "react";

export const ParkingIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M13.9197 5.11187C13.8512 5.02835 13.7491 4.97959 13.6419 4.97959H12.2793C11.9431 4.09272 11.505 3.22377 11.0071 2.89576C9.28469 1.76261 4.71601 1.76261 2.99357 2.89576C2.49529 3.22413 2.05904 4.09415 1.721 4.97959H0.358462C0.250204 4.97959 0.148399 5.02835 0.0806483 5.11187C0.0125393 5.19504 -0.0147044 5.30509 0.00752071 5.41084L0.209339 6.38877C0.243752 6.55546 0.390724 6.67448 0.56028 6.67448H0.963915C0.57426 7.12401 0.385347 7.69005 0.382838 8.25644C0.380328 8.95619 0.645954 9.58855 1.13168 10.0359C1.13706 10.0406 1.14243 10.0438 1.14745 10.0485V11.4168C1.14745 11.7132 1.38834 11.9545 1.68515 11.9545H2.94195C3.23876 11.9545 3.47965 11.7132 3.47965 11.4168V10.8669H10.5203V11.4168C10.5203 11.7132 10.7612 11.9545 11.058 11.9545H12.3148C12.6109 11.9545 12.8525 11.7132 12.8525 11.4168V10.0754C13.368 9.60324 13.6143 8.95368 13.6175 8.30519C13.6197 7.71944 13.4221 7.13261 13.0095 6.67376H13.4401C13.6103 6.67376 13.7573 6.55475 13.791 6.38769L13.9932 5.41013C14.014 5.30509 13.9878 5.19576 13.9197 5.11187ZM3.58396 3.79375C4.9569 2.89002 9.04309 2.89002 10.4153 3.79375C10.682 3.96869 11.0251 4.67596 11.3394 5.54205H2.66055C2.97421 4.67632 3.31726 3.9694 3.58396 3.79375ZM1.97587 8.39123C1.97587 7.80691 2.44977 7.33372 3.03371 7.33372C3.61838 7.33372 4.09156 7.80691 4.09156 8.39123C4.09156 8.97555 3.61838 9.4491 3.03371 9.4491C2.44977 9.4491 1.97587 8.97555 1.97587 8.39123ZM10.9803 9.4491C10.3963 9.4491 9.92241 8.97555 9.92241 8.39123C9.92241 7.80691 10.3963 7.33372 10.9803 7.33372C11.5649 7.33372 12.0381 7.80691 12.0381 8.39123C12.0381 8.97555 11.5642 9.4491 10.9803 9.4491Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
  )
}