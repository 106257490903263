import React from "react";

const RenterDashboardIcon = ({ width, height }: { width?: string, height?: string }) =>{
    const widthS = width || "20";
    const heightS = height || "17";
    return(
        <svg width={widthS} height={heightS} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 12.955C4.58935 12.955 0.783432 16.2242 0.783432 22.1606C0.783432 22.6242 1.1592 23 1.62284 23H21.3771C21.8407 23 22.2165 22.6242 22.2165 22.1606C22.2166 16.2245 18.4106 12.955 11.5 12.955ZM2.4928 21.3212C2.82297 16.8826 5.84905 14.6339 11.5 14.6339C17.1509 14.6339 20.1771 16.8826 20.5075 21.3212H2.4928V21.3212Z" fill="black"/>
            <path d="M11.5 0C8.32559 0 5.93188 2.44186 5.93188 5.67979C5.93188 9.01254 8.42972 11.7236 11.5 11.7236C14.5703 11.7236 17.0681 9.01254 17.0681 5.68006C17.0681 2.44186 14.6744 0 11.5 0ZM11.5 10.045C9.35529 10.045 7.6107 8.08693 7.6107 5.68006C7.6107 3.36159 9.24644 1.67882 11.5 1.67882C13.7175 1.67882 15.3893 3.39879 15.3893 5.68006C15.3893 8.08693 13.6447 10.045 11.5 10.045Z" fill="black"/>
        </svg>
    )
}

export default RenterDashboardIcon
