import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import {
  ADD_LEAD_NOTE,
  DELETE_LEAD_NOTE,
  GET_LEAD_DATA,
  GET_TAGS,
  GET_LEAD_NOTES,
  GET_LEADS,
  UPDATE_LEAD_NOTE,
  VERIFY_LEAD_EMAIL,
  CONFIRM_LEAD_PASSWORD,
  GET_LEAD_ACCOUNT_LINK,
  SEND_LEAD_INFO_BY_SMS,
  LEAD_REMOVE_TAG,
  LEAD_UPDATE_TAGS,
} from "./types";
import {
  addLeadNoteFail,
  addLeadNoteSuccess,
  confirmLeadPasswordError,
  confirmLeadPasswordSuccess,
  deleteLeadNoteFail,
  deleteLeadNoteSuccess,
  getLeadAccountLinkSuccess,
  getLeadDataFail,
  getLeadDataSuccess,
  getLeadNotesFail,
  getLeadNotesSuccess,
  getLeadsListFail,
  getLeadsListSuccess,
  getTagsSuccess,
  removeLeadTagError,
  removeLeadTagSuccess,
  sendLeadInfoBySmsFail,
  sendLeadInfoBySmsSuccess,
  updateLeadNoteFail,
  updateLeadNoteSuccess,
  updateLeadTagsError,
  updateLeadTagsSuccess,
  verifyLeadEmailError,
  verifyLeadEmailSuccess,
} from "./action";
import LeadsApi from "../../api/Leads";
import { generateErrorMessage, openNotification } from "../../helpers/globalFunctions";
import { IObjectKeys } from "../../helpers/globalTypes";
import { setAccessToken, setRefreshToken } from "../../helpers/authUtils";

function* getLeadsSaga({ payload }: any) {
  try {
    const response = yield call(LeadsApi.getLeads, payload);
    yield put(getLeadsListSuccess(response));
  } catch (e) {
    yield put(getLeadsListFail(e));
  }
}

function* getLeadDataSaga({ payload }: any) {
  try {
    const response = yield call(LeadsApi.getLeadData, payload);
    yield put(getLeadDataSuccess(response));
  } catch (e) {
    yield put(getLeadDataFail(e));
  }
}

function* getTagsSaga({ payload }: any) {
  try {
    const response = yield call(LeadsApi.getLeadData, payload);
    yield put(getTagsSuccess(response));
  } catch (e) {
    yield put(getLeadDataFail(e));
  }
}

function* getLeadNotesSaga({ payload }: any) {
  try {
    const response = yield call(LeadsApi.getLeadNotes, `${payload}?notable_type=renter_note`);
    yield put(getLeadNotesSuccess(response.notes));
  } catch (e) {
    yield put(getLeadNotesFail(e));
  }
}
function* addLeadNoteSaga({ payload }: any) {
  try {
    const response = yield call(LeadsApi.addLeadNote, payload);
    yield put(addLeadNoteSuccess(response));
  } catch (e) {
    yield put(addLeadNoteFail(e));
  }
}
function* deleteLeadNoteSaga({ payload }: any) {
  try {
    yield put(deleteLeadNoteSuccess(payload));
  } catch (e) {
    yield put(deleteLeadNoteFail(e));
  }
}

function* updateLeadNoteSaga({ payload }: any) {
  try {
    const response = yield call(LeadsApi.updateLeadNote, payload);
    yield put(updateLeadNoteSuccess(response));
  } catch (e) {
    yield put(updateLeadNoteFail(e));
  }
}

function* verifyLeadEmailSaga({ payload }: IObjectKeys) {
  try {
    const response: any = yield call(LeadsApi.verifyLeadEmail, payload);
    if (!response.success) throw response;

    yield put(verifyLeadEmailSuccess({ code: response.code ?? "" }));
  } catch (e) {
    yield put(verifyLeadEmailError(e));
  }
}

function* confirmLeadPasswordSaga({ payload }: IObjectKeys) {
  try {
    const response: any = yield call(LeadsApi.forceResetPasswordLead, payload);
    if (!response.access_token) throw response.error;
    setAccessToken(response.access_token);
    setRefreshToken(response.refresh_token);
    yield put(confirmLeadPasswordSuccess());
  } catch (e) {
    yield put(confirmLeadPasswordError(e));
  }
}

function* getLeadAccountLink() {
  try {
    const response = yield call(LeadsApi.getLeadAccountLink);
    yield put(
      getLeadAccountLinkSuccess({
        accountLink: response.accountLink,
        phone: response.phone,
        phone_verified: response.phone_verified,
        shareLink: response.shareLink,
        requirementId: response.requirementId,
      })
    );
  } catch (error) {
    yield put(getLeadDataFail(error));
  }
}
function* sendLeadInfoBySmsSaga({ payload }: any) {
  try {
    const response = yield call(LeadsApi.sendLeadIndoBySms, payload);
    openNotification("success", "Sms sent successfully");
    yield put(sendLeadInfoBySmsSuccess(response));
  } catch (e) {
    openNotification("error", e.message);
    yield put(sendLeadInfoBySmsFail(e));
  }
}

function* leadUpdateTags({ payload }: any): any {
  try {
    const response = yield call(LeadsApi.updateTags, payload);
    yield put(updateLeadTagsSuccess(response));
  } catch (err) {
    openNotification("error", generateErrorMessage(err));
    yield put(updateLeadTagsError(err));
  }
}

function* leadRemoveTag({ payload }: any): any {
  try {
    const response = yield call(LeadsApi.removetag, payload);
    yield put(removeLeadTagSuccess(response));
  } catch (err) {
    yield put(removeLeadTagError(err));
  }
}

function* watchLeadUpdateTagsSaga() {
  yield takeLatest(LEAD_UPDATE_TAGS, leadUpdateTags);
}
function* watchLeadRemoveTagSaga() {
  yield takeLatest(LEAD_REMOVE_TAG, leadRemoveTag);
}

function* watchGetLeadsListSaga() {
  yield takeLatest(GET_LEADS, getLeadsSaga);
}
function* watchSendLeadInfoBySmsSaga() {
  yield takeLatest(SEND_LEAD_INFO_BY_SMS, sendLeadInfoBySmsSaga);
}
function* watchGetLeadDataSaga() {
  yield takeLatest(GET_LEAD_DATA, getLeadDataSaga);
}

function* watchGetTagsSaga() {
  yield takeLatest(GET_TAGS, getTagsSaga);
}

function* watchAddLeadNoteSaga() {
  yield takeLatest(ADD_LEAD_NOTE, addLeadNoteSaga);
}

function* watchGetLeadNotesSaga() {
  yield takeLatest(GET_LEAD_NOTES, getLeadNotesSaga);
}

function* watchUpdateLeadNoteSaga() {
  yield takeLatest(UPDATE_LEAD_NOTE, updateLeadNoteSaga);
}

function* watchDeleteLeadNoteSaga() {
  yield takeLatest(DELETE_LEAD_NOTE, deleteLeadNoteSaga);
}

function* watchVerifyLeadEmailSaga() {
  yield takeLatest(VERIFY_LEAD_EMAIL, verifyLeadEmailSaga);
}

function* watchConfirmLeadPasswordSaga() {
  yield takeLatest(CONFIRM_LEAD_PASSWORD, confirmLeadPasswordSaga);
}

function* watchgetLeadAccountLinkSaga() {
  yield takeLatest(GET_LEAD_ACCOUNT_LINK, getLeadAccountLink);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetLeadsListSaga),
    fork(watchGetLeadDataSaga),
    fork(watchAddLeadNoteSaga),
    fork(watchGetLeadNotesSaga),
    fork(watchUpdateLeadNoteSaga),
    fork(watchDeleteLeadNoteSaga),
    fork(watchVerifyLeadEmailSaga),
    fork(watchConfirmLeadPasswordSaga),
    fork(watchgetLeadAccountLinkSaga),
    fork(watchSendLeadInfoBySmsSaga),
    fork(watchLeadUpdateTagsSaga),
    fork(watchLeadRemoveTagSaga),
    fork(watchGetTagsSaga),
  ]);
}
