import React, { useState } from "react";
import { Modal, Typography } from "antd";
import { useSelector } from "react-redux";

import { followUpsLoadingSelector } from "../../store/followUps/selectors";
import { Document } from "@contentful/rich-text-types";
import { generateHtmlFromDocument } from "../../contentful/RichTextRenderer";
import BaseButton from "components/Shared/BaseButton";

const ArchiveFollowModal = ({
  visible,
  onClose,
  onSubmit,
  modalContent,
}: {
  visible: boolean;
  modalContent?: Document | undefined;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const followUpsLoading = useSelector(followUpsLoadingSelector);
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      className={"delete-followUp-modal rounded-lg"}
      visible={visible}
      title={"Delete Follow-Up"}
      onOk={() => onSubmit()}
      confirmLoading={followUpsLoading}
      onCancel={() => {
        onClose();
      }}
      footer={[
        <BaseButton
          key="submit"
          variant="primary"
          loading={loading}
          onClick={() => {
            onSubmit();
            setLoading(true);
          }}
          className="inline-block"
        >
          Delete It
        </BaseButton>,
      ]}
    >
      <Typography.Text>
        {modalContent ? generateHtmlFromDocument(modalContent) : "Are you sure you want to delete this follow up?"}
      </Typography.Text>
    </Modal>
  );
};

export default ArchiveFollowModal;
