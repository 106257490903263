import React from "react";

export const TagIcon = () => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="white" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="11.5" r="11.25" stroke="#B7B7B7" strokeWidth="0.5" />
            <g clipPath="url(#clip0_1_3713)">
                <path d="M15.9593 4.93848H11.1674C10.8641 4.93848 10.5728 5.05922 10.3583 5.27381L4.12083 11.5101C3.67391 11.957 3.67391 12.6815 4.12083 13.129L8.91278 17.9209C9.3597 18.3678 10.0842 18.3678 10.5311 17.9209L16.7685 11.6846C16.9831 11.4695 17.1038 11.1782 17.1038 10.8743V6.08296C17.1038 5.45063 16.5917 4.93848 15.9593 4.93848ZM13.9565 8.94416C13.4827 8.94416 13.0981 8.55961 13.0981 8.0858C13.0981 7.61198 13.4827 7.22744 13.9565 7.22744C14.4303 7.22744 14.8149 7.61198 14.8149 8.0858C14.8149 8.55961 14.4303 8.94416 13.9565 8.94416Z" fill="#B7B7B7" />
                <path d="M18.2482 6.08203L18.2477 11.409C18.2477 11.6728 18.1429 11.9263 17.9558 12.1129L11.6177 18.451L11.715 18.5483C12.1619 18.9952 12.8863 18.9952 13.3333 18.5483L19.0568 12.8259C19.272 12.6113 19.3927 12.32 19.3927 12.0167V7.22651C19.3927 6.59419 18.8806 6.08203 18.2482 6.08203Z" fill="#B7B7B7" />
            </g>
            <defs>
                <clipPath id="clip0_1_3713">
                    <rect width="15.6071" height="15.6071" fill="white" transform="translate(3.78564 4.10742)" />
                </clipPath>
            </defs>
        </svg>
    );
};
