import React, { useEffect } from "react";
import { Modal } from "antd";
import "./DeleteFollowUpModal.scss";
import { deleteFollowUp, setFollowUpDeleteSucces } from "../../../../../store/followUps/action";
import { useDispatch, useSelector } from "react-redux";
import { deleteFollowUpSuccessSelector } from "../../../../../store/followUps/selectors";
import {deleteDashboardFollowUp} from "../../../../../store/dashboard/action";

interface IDeleteFollowUpModal {
    isModalVisible: boolean;
    width?: number;
    setIsModalVisible: any;
    followUp:any;
    backToFollowUps?:any;
    isFromDashboard?:boolean | null
}

const DeleteFollowUpModal = (props: IDeleteFollowUpModal) => {
    const dispatch = useDispatch();
    const deleteFollowUpSuccess = useSelector(deleteFollowUpSuccessSelector);
    useEffect(()=>{
        if(deleteFollowUpSuccess){
            props.setIsModalVisible(false);
            if(props.backToFollowUps){
                props.backToFollowUps();
            }
            dispatch(setFollowUpDeleteSucces(false));
        }
    },[deleteFollowUpSuccess, dispatch, props]);
    return (
        <Modal
            closeIcon={null}
            wrapClassName={"follow-ups-delete-modal"}
            visible={props.isModalVisible}
            width={props.width}
            style={{ top: 200 }}
            footer={[null]}
        >
            <p className={"follow-ups-delete-modal--header"}>Delete follow-up ?</p>
            <div className={"follow-ups-delete-modal--text"}>
                <span>
                    Are you sure you want to delete this follow up?
                    This action will remove it from your follow-ups and cannot be undone
                </span>
            </div>
            <div className={"follow-ups-delete-modal--footer"}>
                <button onClick={()=>{
                    props.isFromDashboard ?
                        dispatch(deleteDashboardFollowUp(props.followUp)):
                        dispatch(deleteFollowUp(props.followUp));

                }}>Delete</button>
                <button onClick={()=>{
                    props.setIsModalVisible(false);
                }}>Cancel</button>
            </div>
        </Modal>
    );
};

export default DeleteFollowUpModal;