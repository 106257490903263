import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { REQUIRED_TITLE_VALIDATION } from "../../helpers/validations";
import { useDispatch, useSelector } from "react-redux";
import { IObjectKeys } from "../../helpers/globalTypes";
import {
  landlordNoteAddSuccessSelector,
  landlordNotesLoadingSelector,
  singleLandlordDataSelector,
} from "../../store/landlords/selectors";
import BaseModal from "../Shared/BaseModal";
import BaseButton from "../Shared/BaseButton";
import {
  addLandlordNote,
  updateLandlordNote,
} from "../../store/landlords/action";
import { KeyIcon } from "../icons";


type modalPropsType = { visible: boolean; editModal: boolean; };
export interface LandlordModalPropsI {
  landlord_id?: string;
  setModalProps: (props: modalPropsType) => void;
  modalProps: modalPropsType;
  landlordInfo?: IObjectKeys;
  noteInfo?: IObjectKeys;
  initialModalValue: any;
  lId?: any;
  form?: any;
  defaultTitle?: string;
}
const { TextArea } = Input;
const LandlordNoteModal = ({
  modalProps,
  setModalProps,
  initialModalValue,
  form,
  defaultTitle
}: LandlordModalPropsI) => {
  const notesLoading = useSelector(landlordNotesLoadingSelector);
  const dispatch = useDispatch();
  const [isDescriptionShown, setIsDescriptionShown] = useState(false);
  const singleLandlord = useSelector(singleLandlordDataSelector);
  const landlordNotesAddSuccess = useSelector(landlordNoteAddSuccessSelector);
  useEffect(() => {
    if (landlordNotesAddSuccess) {
      setIsDescriptionShown(false);
      setModalProps(initialModalValue);
    }
  }, [landlordNotesAddSuccess]);
  useEffect(() => {
    if (modalProps.editModal) {
      if (form.getFieldValue("description")?.length > 0) {
        setIsDescriptionShown(true);
      }
    }
  }, [form, modalProps]);

  useEffect(() => {
    form.setFieldsValue({ title: defaultTitle });
  }, [form, defaultTitle]);

  return (
    <BaseModal
      classname={"single-listing-page-add-note listing-add-edit-modal"}
      cancelButtonProps={{
        className: "rb-secondary-btn sm",
      }}
      okButtonProps={{
        className: "rb-primary-btn sm",
      }}
      modalTitle=""
      isModalVisible={modalProps.visible}
      confirmLoading={notesLoading}
      onCancel={() => {
        setModalProps(initialModalValue);
      }}
      modalFooter={[
        <BaseButton
          classnames={"rb-primary-btn md br-16 w-100"}
          //@ts-ignore
          onClick={() => form.submit()}
        >
          {modalProps.editModal ? "Update Note" : "Save"}
        </BaseButton>,
      ]}
    >
      <div className={"listing-add-edit-modal--header"}>
        <h3>{modalProps.editModal ? "Edit Note" : "Add Note"}</h3>
        <div className={"listing-add-edit-modal--header--info"}>
          <span>For:</span>
          <KeyIcon />
          <p>{singleLandlord.name}</p>
        </div>
      </div>
      <Form
        className={"add-note-modal-form-wrapper"}
        onFinish={async (data) => {
          if (modalProps.editModal) {
            return dispatch(
              updateLandlordNote({ ...data, landlord_id: singleLandlord.landlord_id }),
            );
          }
          dispatch(
            addLandlordNote({ ...data, landlord_id: singleLandlord.landlord_id }),
          );
          form.resetFields();
        }}
        form={form}
      >
        <Form.Item
          name={"_id"}
          className={"add-note-modal-form-wrapper--notVisible"}
        >
          not visible
        </Form.Item>
        <div className={"add-note-modal-form-wrapper--form-item"}>
          <p>Note title</p>
          <Form.Item name="title" rules={[REQUIRED_TITLE_VALIDATION]}>
            <Input autoComplete={"off"} placeholder={"Add title here"} />
          </Form.Item>
        </div>

        {!isDescriptionShown && (
          <span
            className={"add-description-btn"}
            onClick={() => {
              setIsDescriptionShown(true);
            }}
          >
            + Add decription
          </span>
        )}
        {isDescriptionShown && (
          <div className={"add-note-modal-form-wrapper--form-item"}>
            <p>Add description</p>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  min: 0,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea autoComplete={"off"} rows={4} autoFocus={true} />
            </Form.Item>
          </div>
        )}
      </Form>
    </BaseModal>
  );
};

export default LandlordNoteModal;
