import { LeadScreeningRequestActionColumn } from "components/Lead/renter-lead-page/RequestScreeningModal/components/LeadScreeningReqAction";
import DateColumn from "components/Shared/Columns/DateColumn";
import PropertyLinkColumn from "components/Shared/Columns/PropertyLinkColumn";
import RenterLinkColumn from "components/Shared/Columns/RenterColumn";
import ScreeningStatusColumn from "components/Shared/Columns/ScreeningStatusColumn";
import React from "react";
import { fieldDto, IObjectKeys } from "../../../helpers/globalTypes";

export const TENANT_SCREENING_REQUESTS_COLUMNS = () => [
  {
    dataIndex: "_id",
    key: "propertyAddress",
    title: "Property",
    render: PropertyLinkColumn,
  },
  {
    dataIndex: "renter",
    key: "renter",
    title: "Renter",
    render: RenterLinkColumn,
  },
  {
    dataIndex: "screening_status",
    key: "Status",
    title: "Status",
    width: "200px",
    render: (_: string, rowData: any) => ScreeningStatusColumn(rowData),
  },
  {
    dataIndex: "created_at",
    key: "Created",
    title: "Created",
    width: "200px",
    render: DateColumn,
  },
  {
    dataIndex: "action",
    key: "Actions",
    title: "",
    width: "200px",
    render: (_id: fieldDto, rowData: IObjectKeys) => {
      return {
        children: <LeadScreeningRequestActionColumn rowData={rowData} />,
      };
    },
  },
];
