import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import { IpetsListItem } from "../PetsInfo";
import { v4 as uuidv4 } from 'uuid';
import { scrollToTheElement } from "helpers/globalFunctions";
import PetsCollapseItem from "./pets-collapse-item/PetsCollapseItem";

interface IPetsCollapseProps {
  setPetsList: React.Dispatch<React.SetStateAction<IpetsListItem[]>>,
  petsList: Array<IpetsListItem>
}

const PetsCollapse = ({
  setPetsList,
  petsList
}: IPetsCollapseProps
) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const handleDeletePetsItem = useCallback((id: string) => {
    setPetsList((prev) => {
      let copyPetsList = [...prev];
      copyPetsList = copyPetsList.filter(i => i.id !== id);
      return copyPetsList;
    });

  }, [setPetsList]);
  const addNewPetSelector = () => {
    let copyPetsList = [...petsList];
    const newPetsSelect: IpetsListItem = {
      species: "dog",
      amount: 1,
      id: uuidv4()
    }
    copyPetsList.push(newPetsSelect);
    setPetsList(copyPetsList);
  }

  useEffect(() => {
    if (petsList.length) {
      if (!isFirstRender) {
        const lastPetsSelect = petsList[petsList.length - 1];
        const lastPetsSelectRef = document.querySelector(`[data-scroll-id="${lastPetsSelect.id}"]`);
        if (lastPetsSelectRef) {
          //@ts-ignore
          scrollToTheElement(lastPetsSelectRef);
        }
      } else {
        setIsFirstRender(false);
      }
    }
  }, [petsList.length]);

  return (
    <>
      <div className={"pets-collapse-wrapper"}>
        {petsList.map((petsItem,index) => (
            <React.Fragment key={index} >
              <PetsCollapseItem
                  petsItem={petsItem}
                  handleDeletePetsItem={handleDeletePetsItem}
                  setPetsList={setPetsList}
                  index={index}
              />
            </React.Fragment>
        ))}
      </div>
      <div className="add-new-pet-select-item-wrapper" >
        <span
          className="add-new-pet-text"
          data-test-id="add-new-pet-selector"
          onClick={addNewPetSelector}
        >
          + Add another pet?
        </span>
      </div>
    </>
  );
};

export default PetsCollapse;
