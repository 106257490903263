import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import userReducer from "store/user/reducer";
import enumReducer from "store/enums/reducer";
import areaReducer from "store/areas/reducer";
import listingReducer from "store/listings/reducer";
import leadReducer from "store/leads/reducer";
import notificationsReducer from "store/notifications/reducer";
import landlordsReducer from "store/landlords/reducer";
import followUpsReducer from "store/followUps/reducer";
import appReducer from "store/app/reducer";
import activitiesReducer from "store/activities/reducer";
import teamsReducer from "store/teams/reducer";
import termsAndPolicyReducer from "./privacyPolicy/reducer";
import requirementReducer from "./requirement/reducer";
import flatfileEnumsReducer from "./flatfileEnums/reducer";
import dashboardReducer from "./dashboard/reducer";
import paymentReducer from "./payment/reducer";
import shareableReducer from "./shareable/reducer";
import renterRequestReducer from "./renter/reducer";
import agentRequestReducer from "./agent/reducer";
import renterPassportReducer from "./renterPassport/reducer";

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: "shareable",
  storage,
  whitelist: ["shareableRenterEntity"],
};

const createRootReducers = (history: any) =>
  combineReducers({
    appReducer,
    userReducer,
    enumReducer,
    areaReducer,
    listingReducer,
    notificationsReducer,
    landlordsReducer,
    followUpsReducer,
    activitiesReducer,
    teamsReducer,
    requirementReducer,
    termsAndPolicyReducer,
    leadReducer,
    router: connectRouter(history),
    flatfileEnumsReducer,
    dashboardReducer,
    paymentReducer,
    shareableReducer: persistReducer(persistConfig, shareableReducer),
    renterRequestReducer,
    agentRequestReducer,
    renterPassportReducer
  });

export default createRootReducers;
