import { Space, Spin, Tabs } from "antd";
import { SCREENING_REPORT_TYPES } from "helpers/constants";
import React from "react";
import BaseButton from "../BaseButton";
import "./report-tabs.scss";

interface IReportTabsProps {
  loading: boolean;
  showActions?: boolean;
  handleTabChange: (key: string) => void;
  handlePrintReport: () => void;
}

const iframeStyles = {
  border: "none",
  height: "100vh",
};

const SRReportTabs = ({
  loading,
  showActions,
  handleTabChange,
  handlePrintReport,
}: IReportTabsProps) => {
  const Actions = () =>
    showActions ? (
      <Space className="report-actions" direction="horizontal">
        <BaseButton
          classnames="ant-btn ant-btn rb-secondary-btn rb-secondary-btn-without-shadow"
          onClick={handlePrintReport}
        >
          Download Report
        </BaseButton>
      </Space>
    ) : null;
  return (
    <main className="leads-part-dashboard hide-scroll-bar report-page">
      <Tabs onChange={handleTabChange} className="report-tabs">
        <Tabs.TabPane
          disabled={loading}
          tab="Credit Report"
          key={SCREENING_REPORT_TYPES.CREDIT}
        >
          <Spin spinning={loading}>
            <div className="report-container">
              <Actions />
              <iframe
                style={iframeStyles}
                id={SCREENING_REPORT_TYPES.CREDIT}
                title="Credit Report"
                className="report-iframe"
              ></iframe>
            </div>
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={loading}
          tab="Background Report"
          key={SCREENING_REPORT_TYPES.BACKGROUND}
        >
          <Spin spinning={loading}>
            <div className="report-container">
              <Actions />
              <iframe
                style={iframeStyles}
                id={SCREENING_REPORT_TYPES.BACKGROUND}
                title="Background Report"
                className="report-iframe"
              ></iframe>
            </div>
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={loading}
          tab="Eviction Related Proceedings"
          key={SCREENING_REPORT_TYPES.EVICTION}
        >
          <Spin spinning={loading}>
            <div className="report-container">
              <Actions />
              <iframe
                style={iframeStyles}
                id={SCREENING_REPORT_TYPES.EVICTION}
                title="Eviction Related Proceedings"
                className="report-iframe"
              ></iframe>
            </div>
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </main>
  );
};

export default SRReportTabs;
