import React from "react";
import "./DeleteConfirmationModal.scss";
import BaseModal from "../../Shared/BaseModal";
import { useDispatch } from "react-redux";
import { AnalyticsService } from "helpers/analyticsService";
import { deleteListing } from "store/listings/action";
import { IObjectKeys } from "helpers/globalTypes";

interface IDeleteConfirmationModal {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  listing_id: IObjectKeys
}
const DeleteConfirmationModal = ({ visible, setVisible, listing_id }: IDeleteConfirmationModal) => {
    const dispatch = useDispatch();
  
  const deleteHandler = () => {
    AnalyticsService.reportEvent("delete_listing_modal_click", { label: "cancel" });
    dispatch(deleteListing(listing_id));
    setVisible(false);
  }

    return (
      <BaseModal
        classname={"delete-confirm-modal"}
        isModalVisible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        modalTitle={null}
        modalFooter={null}
        width={414}>
        <div className={"delete-confirm-modal-body"}>
          <h3>You're about to delete your listing!</h3>
          <p>
            <>
              <br />
              Deleting this listing will result in deleting all notifications, follow ups, notes, and listing showings related to this listing.
              <br /><br />
              <strong>Note: this operation is irreversible!</strong>
            </>
          </p>
          <div className={"delete-confirm-modal-body-action"}>
            <button type={"button"} onClick={deleteHandler}>
              Confirm
            </button>
            <button
              type={"button"}
              onClick={() => {
                AnalyticsService.reportEvent("delete_listing_modal_click", { label: "cancel" });
                setVisible(false);
              }}>
              Cancel
            </button>
          </div>
          {/* <span>
          {type === "update" ? (
            <>
              By confirming, you'll be billed $24 instead of
              <br />
              $40 each month.
            </>
          ) : (
            <>
              By confirming, this you will no longer
              <br />
              have access to some actions.
            </>
          )}
        </span> */}
        </div>
      </BaseModal>
    );
};

export default DeleteConfirmationModal;
