import React from "react";

export const TriangleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="30"
      height="30"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      className="triangle-icon"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M507.521,427.394L282.655,52.617c-12.074-20.122-41.237-20.122-53.311,0L4.479,427.394    c-12.433,20.72,2.493,47.08,26.655,47.08h449.732C505.029,474.474,519.955,448.114,507.521,427.394z"
            fill="#ffffff"
            data-original="#000000"
            className=""
          />
        </g>
      </g>
    </svg>
  );
};
