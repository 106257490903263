import React from "react";

export const InfoIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49964 0C3.80584 0 0 3.80584 0 8.49964C0 13.1934 3.80584 17 8.49964 17C13.1934 17 17 13.1934 17 8.49964C17 3.80584 13.1934 0 8.49964 0ZM10.2691 13.1733C9.83158 13.346 9.4833 13.477 9.2221 13.5676C8.96161 13.6583 8.65867 13.7036 8.31399 13.7036C7.78438 13.7036 7.37206 13.5741 7.07848 13.3158C6.78489 13.0574 6.63881 12.73 6.63881 12.3321C6.63881 12.1774 6.64961 12.0191 6.6712 11.8579C6.6935 11.6967 6.72876 11.5154 6.77697 11.3117L7.32457 9.37752C7.37278 9.19187 7.41452 9.01558 7.44762 8.85151C7.48072 8.68601 7.49655 8.53418 7.49655 8.39602C7.49655 8.14993 7.44546 7.97723 7.344 7.88008C7.2411 7.78294 7.04753 7.73545 6.75898 7.73545C6.61795 7.73545 6.47259 7.75632 6.32364 7.80021C6.17613 7.84554 6.04804 7.88656 5.94298 7.92686L6.08762 7.33105C6.44597 7.18497 6.78921 7.05977 7.11661 6.95615C7.44402 6.85109 7.75344 6.79928 8.04487 6.79928C8.57088 6.79928 8.97672 6.92737 9.26239 7.18066C9.54662 7.43467 9.68982 7.76495 9.68982 8.17079C9.68982 8.25498 9.67975 8.40322 9.66032 8.61477C9.64089 8.82705 9.60419 9.02061 9.55094 9.19835L9.00622 11.1268C8.96161 11.2815 8.92203 11.4585 8.88605 11.6564C8.85079 11.8543 8.83352 12.0054 8.83352 12.1069C8.83352 12.363 8.89037 12.5379 9.0055 12.6307C9.1192 12.7236 9.31852 12.7703 9.60059 12.7703C9.73371 12.7703 9.88267 12.7466 10.051 12.7005C10.218 12.6545 10.3389 12.6135 10.4152 12.5782L10.2691 13.1733ZM10.1727 5.34574C9.91865 5.58176 9.61283 5.69977 9.2552 5.69977C8.89829 5.69977 8.59031 5.58176 8.33414 5.34574C8.07941 5.10971 7.9506 4.8226 7.9506 4.48728C7.9506 4.15268 8.08013 3.86485 8.33414 3.62667C8.59031 3.38777 8.89829 3.26904 9.2552 3.26904C9.61283 3.26904 9.91936 3.38777 10.1727 3.62667C10.4267 3.86485 10.554 4.15268 10.554 4.48728C10.554 4.82332 10.4267 5.10971 10.1727 5.34574Z"
        fill="#030104"
      />
    </svg>
  );
};
