import React, { useEffect, useState } from "react";
import VerifyPhone from "./verify-code/VerifyPhone";
import { useSelector } from "react-redux";
import {
  getVerificationCodeLoadingSelector,
  signUpPhoneVerifiedSuccessSelector,
} from "../../../../store/user/selectors";
import EnterNumber from "./enter-number/EnterNumber";
import Message from "../../../Shared/Message";

const NewUserVerification = ({ onPhoneVerification }: any) => {
  const getVerificationCodeLoading = useSelector(getVerificationCodeLoadingSelector);
  const [verifyFormViible, setVerifyFormViible] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [msg, setMsg] = useState(false);
  const isVerified = useSelector(signUpPhoneVerifiedSuccessSelector);

  const backToSignUpHandler = () => {
    setVerifyFormViible(false);
  };
  
  useEffect(() => {
    if (getVerificationCodeLoading) {
      setVerifyFormViible(true);
      setSuccessMsg(true);
      setTimeout(() => {
        setSuccessMsg(false);
      }, 2000);
    }
  }, [getVerificationCodeLoading]);

  useEffect(() => {
    if (isVerified) {
      setMsg(true);
      setVerifyFormViible(true);
      setTimeout(() => {
        setMsg(false);
        onPhoneVerification();
      }, 2000);
    }
  }, [isVerified]);

  return (
    <div>
      {!verifyFormViible ? <VerifyPhone /> : <EnterNumber backToSignUpHandler={backToSignUpHandler} />}
      {successMsg && <Message text={"A verification text was sent to your mobile"} messageClassName={"alert-new"} />}
      {msg && <Message text={"Phone verified! Signing into your RentBase"} messageClassName={"alert-new"} />}
    </div>
  );
};

export default NewUserVerification;
