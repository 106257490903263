import React from "react";
import ForceResetPassword from "./components/reset-password/ForceResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, getRefreshToken } from "../../helpers/authUtils";
import { newUserIsComingSelector } from "../../store/user/selectors";
import SignUpFormSteps from "./components/sign-up-form-steps";
import { push } from "connected-react-router";
import { AnalyticsService } from "helpers/analyticsService";
import { getSignUpVerificationFlowStep } from "helpers/globalFunctions";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../Shared/filters/helpers/listingsCreateGetSearchQuery";
import qs from "query-string";
import { getLoggedInUser } from "../../store/user/action";

const ForceResetPasswordWrapper = () => {
  const isNewUserPasswordSetted = useSelector(newUserIsComingSelector);
  const isUserLoggedIn = getAuthToken() && getRefreshToken();
  const listingsRequestedLink = localStorage.getItem("listingsRequestedLink");
  const dispatch = useDispatch();
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const nextStepHandler = () => {
    AnalyticsService.reportSignUpProcess({ action: `sign_up_verification_flow_next_click_${getSignUpVerificationFlowStep()}` });
    if (!search.includes("step=2")) {
      queryObject.step = "2";
      dispatch(
        push({
          search: qs.stringify(queryObject),
        })
      );
      dispatch(getLoggedInUser());
    }
  };

  return (
    <div className={"reset-pass-flow-wrapper"}>
      {!isNewUserPasswordSetted && !isUserLoggedIn ? <ForceResetPassword /> : <SignUpFormSteps />}
      {isUserLoggedIn && queryObject?.step != "2" && (
        <span
          className={"sign-up-settings-skip"}
          onClick={() => {
            if (localStorage.getItem("leadsPageRequested")) {
              dispatch(push(listingsRequestedLink || "/leads"));
            } else {
              if (queryObject.step === "1") {
                nextStepHandler();
              } else {
                AnalyticsService.reportSignUpProcess({
                  action: `sign_up_verification_flow_skip_for_now_click_${getSignUpVerificationFlowStep()}`,
                });
                dispatch(push("/"));
              }
            }
          }}
        >
          Skip for now
        </span>
      )}
    </div>
  );
};

export default ForceResetPasswordWrapper;
