import React from "react";

export default function Header({ title, children }: any) {
  return (
    <div className="flex justify-between py-4">
      <div>
        <h2 className="font-semibold text-xl">{title}</h2>
      </div>
      <div>{children}</div>
    </div>
  );
}
