import BaseButton from "components/Shared/BaseButton";
import { phoneMaskOnString } from "helpers/globalFunctions";
import React from "react";

interface ILeadContactInfoPoprs {
  email: string;
  phone: string;
  setLandlordActionModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeadContactInfo = ({ email, phone, setLandlordActionModalVisible }: ILeadContactInfoPoprs) => {
  return (
    <div className="gap-8 flex justify-end w-full landlord-info-middle-section--body">
      <span
        className="gap-8 w-full landlord-info-span flex justify-between items-center"
        data-test-id={"lead-agent-email"}
      >
        {email && <div>{email}</div>}
        {phone && <div>{phoneMaskOnString(phone)}</div>}
      </span>
      <BaseButton
        classnames={"ant-btn rb-secondary-btn rb-secondary-btn quick-contact-btn-single-listing-page"}
        type={"button"}
        dataTestId={"landlord-quick-contact-modal-open-from-listing"}
        modalType="lead"
        onClick={() => {
          setLandlordActionModalVisible(true);
        }}
      >
        Contact
      </BaseButton>
    </div>
  );
};

export default LeadContactInfo;
