import React from 'react';

export const LeftArrow = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.5" cy="16.5" r="16" fill="white" stroke="#BFBFBF" />
      <path
        d="M11.2871 16.3069L17.3069 10.2873C17.6898 9.90422 18.3106 9.90422 18.6934 10.2873C19.0762 10.6701 19.0762 11.2909 18.6934 11.6737L13.3668 17.0001L18.6932 22.3263C19.076 22.7093 19.076 23.33 18.6932 23.7128C18.3105 24.0957 17.6896 24.0957 17.3067 23.7128L11.2869 17.6931C11.0955 17.5016 10.9999 17.2509 10.9999 17.0001C10.9999 16.7492 11.0957 16.4983 11.2871 16.3069Z"
        fill="#333333"
      />
    </svg>
  );
};
