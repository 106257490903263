import cookies from "js-cookie";
import { shortAppUrl } from "./constants";

export const FilterTeamService = {
  setIsTeam: function (isTeam: boolean = false) {
    if (isTeam) {
      localStorage.setItem("isTeam", "true");
      cookies.set("isTeam", "true", { expires: 9000, domain: shortAppUrl });
      return;
    }

    if (cookies.get("isTeam")) {
      localStorage.setItem("isTeam", "true");
      return;
    }

    if (localStorage.getItem("isTeam")) {
      cookies.set("isTeam", "true", { expires: 9000, domain: shortAppUrl });
    }
  }
};
