import React, { useEffect, useState } from "react";
import "./SignInIntro.scss";
import { useSelector } from "react-redux";
import { isLeadPageSharedSelector } from "../../../store/user/selectors";
import { useLocation } from "react-router-dom";
import berkshire from "../../../assets/images/berkshire.jpeg";
import chicago from "../../../assets/images/chicagorentals.jpeg";
import compass from "../../../assets/images/compass.jpeg";
import kellerwilliams from "../../../assets/images/kellerwilliams.jpeg";

interface ISignInUpIntro {
  isSignIn: boolean;
}
const SignInIntro = ({ isSignIn }: ISignInUpIntro) => {
  const [text, setText] = useState({
    title: "Welcome Back to RentBase",
    titleSecondary: "",
    text: "Log into your account",
    subText: ""
  });
  const isLeadPageShared = useSelector(isLeadPageSharedSelector);
  const location = useLocation();
  useEffect(() => {
    if (location.search.includes("shared=true")) {
      localStorage.setItem("leadsPageRequested", `/leads/${location.search.substring(1)}`);
    }
  }, [location]);
  useEffect(() => {
    if (isLeadPageShared) {
      if (!isSignIn) {
        setText({
          title: "Help me find ",
          titleSecondary: "my next home!",
          text:
            "You’ve been invited to view a new Lead's profile on RentBase. Please sign up to access your new lead’s rental requirements. ",
          subText: ""
        });
      } else {
        setText({
          title: "Help me find ",
          titleSecondary: "my next home!",
          text:
            "You’ve been invited to view a new Lead's profile on RentBase. Please sign in to access your new lead’s rental requirements. ",
          subText: ""
        });
      }
    } else {
      if (!isSignIn) {
        setText({
          title: "Welcome",
          titleSecondary: "to RentBase",
          text: "Stay organized. Qualify leads.",
          subText: "Convert rentals into sales."
        });
      } else {
        setText({
          title: "Welcome back",
          titleSecondary: "to RentBase",
          text: "Log into your account",
          subText: ""
        });
      }
    }
  }, [isSignIn, isLeadPageShared]);
  return (
    <div className={"sign-in-intro"}>
      <div className={"sign-in-intro--textPart"}>
        <h1 className={`${text.titleSecondary.length && "sign-in-intro--textPart--first"}`}>
          {text.title}
        </h1>
        <h1>{text.titleSecondary}</h1>
        <p>{text.text}</p>
        <p className="m-0">{text.subText}</p>
      </div>
      <div className={"sign-in-intro-trusted"}>
        <p>Trusted by the top agents at:</p>
        <div className={"sign-in-intro-trusted-icons"}>
          <img src={berkshire} alt={berkshire} />
          <img src={kellerwilliams} alt={kellerwilliams} />
          <img src={compass} alt={compass} />
          <img src={chicago} alt={chicago} />
        </div>
      </div>
    </div>
  );
};

export default SignInIntro;
