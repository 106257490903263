import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Typography, InputNumber, Spin, Input } from "antd";
import { StepFormComponent } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { enumsSelector } from "../../../store/enums/selectors";
import {
  getBedroomsOrBathroomsRadioBtnGroup,
  getChipCheckbox,
  getEnumInputs,
  getQueryParams,
  makeMultiEnumOption,
  parseMultiEnumOptions,
  scrollToFirstErrorField,
} from "../../../helpers/globalFunctions";
import { EnumInterface, IObjectKeys } from "../../../helpers/globalTypes";
import {
  clearListingAddState,
  clearListingUpdateState,
  fillListingData,
  setCurrentSteps,
  setIsCurrentFormChange,
  setListingDetailsSwitchInputValues as setSwitchInputData,
  updateListing,
} from "../../../store/listings/action";
import { FieldData } from "rc-field-form/lib/interface";
import {
  currentStepSelector,
  listingAddSuccessSelector,
  listingDataSelector,
  listingLoadingSelector,
  listingUpdateSuccessSelector,
  stepClickedSelector,
  listingDetailsSwitchInputValuesSelector,
  isCurrentFormChangedSelector,
} from "../../../store/listings/selectors";
import StepsActionButtons from "./StepsActionButtons";
import SwitchButton from "./SwitchButton";
import TextArea from "antd/es/input/TextArea";
import {
  REQUIRED_POSITIVE_WITH_MESSAGE,
  NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE,
  NOT_ZERO_IN_START,
  REQUIRED_WITH_MESSAGE,
} from "../../../helpers/validations";
import { listingFormScrollFields } from "./helpers/listingFormConstants";

const { Title } = Typography;

const fillListingDetailsForm = ({ listingData, setSwitchInputData, switchInputData, dispatch }: IObjectKeys) => {
  const fieldsToSet: IObjectKeys = {};
  const multiselectFields = ["utilities", "pets_allowed", "outdoor_space", "parking_type"];
  [
    "air_conditioning",
    "laundry",
    "dishwasher",
    "number_of_parking",
    "parking_cost",
    "pet_policy",
    "pet_policy_description",
    ...multiselectFields,
  ].map((field: string) => {
    if (listingData[field]) {
      if (multiselectFields.indexOf(field) > -1) {
        fieldsToSet[field] = parseMultiEnumOptions(listingData[field]);
        switch (field) {
          case "outdoor_space":
            fieldsToSet["has_outdoorSpace"] = true;
            dispatch(
              setSwitchInputData({
                ...switchInputData,
                ["has_outdoorSpace"]: true,
              })
            );
            break;
          case "parking_type":
            fieldsToSet["has_parking"] = true;
            dispatch(
              setSwitchInputData({
                ...switchInputData,
                ["has_parking"]: true,
              })
            );
        }
      } else {
        if (field === "air_conditioning") {
          fieldsToSet["has_air_conditioning"] = true;
          dispatch(
            setSwitchInputData({
              ...switchInputData,
              ["has_air_conditioning"]: true,
            })
          );
        } else if (field === "laundry") {
          fieldsToSet["has_laundry"] = true;
          dispatch(
            setSwitchInputData({
              ...switchInputData,
              ["has_laundry"]: true,
            })
          );
        } else if (field === "dishwasher") {
          fieldsToSet["dishwasher"] = true;
          dispatch(
            setSwitchInputData({
              ...switchInputData,
              ["dishwasher"]: true,
            })
          );
        }
        fieldsToSet[field] = listingData[field];
      }
    }
  });

  return fieldsToSet;
};

const generateSubmitData = (data: IObjectKeys, switchInputData: IObjectKeys) => {
  const formData = { ...data, ...switchInputData };
  if (formData.has_parking) {
    formData.parking_type = makeMultiEnumOption(formData.parking_type);
  } else {
    formData.parking_type = "";
    formData.number_of_parking = 0;
    formData.parking_cost = 0;
  }

  if (formData.pet_policy === "not_allowed") {
    formData.pets_allowed = "";
    formData.pet_policy_description = "";
  } else if (formData.pet_policy === "allowed") {
    formData.pets_allowed = makeMultiEnumOption(formData.pets_allowed);
    formData.pet_policy_description = "";
  } else if (formData.pet_policy === "negotiable") {
    formData.pets_allowed = makeMultiEnumOption(formData.pets_allowed);
  }

  if (!formData.has_laundry) {
    formData.laundry = "";
  }
  if (!formData.has_air_conditioning) {
    formData.air_conditioning = "";
  }
  if (formData.has_outdoorSpace) {
    formData.outdoor_space = makeMultiEnumOption(formData.outdoor_space);
  } else {
    formData.outdoor_space = "";
  }
  formData.utilities = makeMultiEnumOption(formData.utilities);
  return formData;
};

const PropertyDetails = ({ form }: StepFormComponent) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { lId } = getQueryParams(search);
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const enums = useSelector(enumsSelector);
  const listingData = useSelector(listingDataSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const listingAddSuccess = useSelector(listingAddSuccessSelector);
  const stepClicked = useSelector(stepClickedSelector);
  const current = useSelector(currentStepSelector);
  const switchInputData: IObjectKeys = useSelector(listingDetailsSwitchInputValuesSelector);
  const contentRef = useRef(null);
  const isCurrentFormChanged = useSelector(isCurrentFormChangedSelector);
  const [laundry, setLaundry] = useState(enums.find((item: EnumInterface) => item.key === "laundry"));
  const scrollFields = listingFormScrollFields.listingDetails;
  const airConditioning = enums.find((item: EnumInterface) => item.key === "air_conditioning");
  const [petPolicy, setPetPolicy] = useState(listingData.pet_policy);

  const next = () => {
    dispatch(setCurrentSteps(current + 1));
  };

  const onFinishFailed = () => {
    scrollToFirstErrorField();
  };

  useEffect(() => {
    dispatch(setIsCurrentFormChange(false));
  }, []);

  useEffect(() => {
    if (laundry?.options[1]["in_unit"]) {
      const prevLaundry = enums.find((item: EnumInterface) => item.key === "laundry");
      laundry.options[2] = laundry.options.splice(1, 1, laundry.options[2])[0];
      setLaundry(prevLaundry);
    }
  }, [enums]);

  const handleChange = (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => {
    e.persist();
    const { checked } = e.target;
    dispatch(
      setSwitchInputData({
        ...switchInputData,
        [e.target.name]: !checked,
      })
    );
  };

  const onSubmit = (data: any) => {
    const formData = generateSubmitData(data, switchInputData);
    dispatch(fillListingData(formData));
    if (lId) {
      dispatch(updateListing({ lId, ...formData, step: "listing_details_step" }));
    } else {
      //dispatch(addListing({ ...formData, ...listingData }));
      next && next();
    }
  };

  // this useEffect we need to go to next step after successful update or save step
  useEffect(() => {
    if (!stepClicked && (listingAddSuccess || updateListingSuccess)) {
      next && next();
    }
    return () => {
      dispatch(clearListingAddState());
      dispatch(clearListingUpdateState());
    };
  }, [listingAddSuccess, updateListingSuccess]);

  // for setting form initial data
  useEffect(() => {
    const fieldsToSet = fillListingDetailsForm({
      listingData,
      setSwitchInputData,
      dispatch,
      switchInputData,
    });
    form.setFieldsValue(fieldsToSet);
  }, [listingData]);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      //@ts-ignore
      contentRef.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (
      (Object.keys(listingData.pets_allowed ?? {}).length ||
        (listingData.pets_allowed && typeof listingData.pets_allowed === "boolean")) &&
      listingData.pet_policy !== "negotiable" &&
      listingData.pet_policy !== "allowed" &&
      listingData.pet_policy !== "not_allowed"
    ) {
      setPetPolicy("allowed");
      form.setFieldsValue({
        pet_policy: "allowed",
      });
    } else if (
      (listingData.pet_policy !== "negotiable" && listingData.pet_policy !== "allowed") ||
      (!listingData.pets_allowed && typeof listingData.pets_allowed === "boolean")
    ) {
      setPetPolicy("not_allowed");
      form.setFieldsValue({
        pet_policy: "not_allowed",
      });
    }
  }, [listingData.pets_allowed]);

  const prevClicked = () => {
    const data = form.getFieldsValue();
    const formData = generateSubmitData(data, switchInputData);
    dispatch(fillListingData(formData));
  };

  return (
    <div ref={contentRef}>
      <Spin tip="Submitting..." spinning={listingLoading}>
        <Title level={4}>Property Details</Title>
        <Form
          onFinishFailed={onFinishFailed}
          className="landlord-details-form"
          form={form}
          onFinish={onSubmit}
          onFieldsChange={(changedFields: FieldData[]) => {
            (() => {
              //Scroll To Next Input
              // @ts-ignore
              const changedFieldName: any = changedFields[0]?.name[0];
              //@ts-ignore
              const element = document.querySelector(`[data-scroll-id="${changedFieldName}"]`);
              if (element && scrollFields.includes(changedFieldName)) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                element?.scrollIntoView({ block: "start", behavior: "smooth" });
              }
            })();

            if (!isCurrentFormChanged) dispatch(setIsCurrentFormChange(true));

            if (Array.isArray(changedFields[0]?.name)) {
              if (changedFields[0]?.name?.indexOf("has_parking") > -1) {
                dispatch(
                  setSwitchInputData({
                    ...switchInputData,
                    ["has_parking"]: !changedFields[0]?.value,
                  })
                );
              }
              if (changedFields[0]?.name?.indexOf("pet_policy") > -1) {
                setPetPolicy(changedFields[0].value);
              }
              if (changedFields[0]?.name?.indexOf("has_laundry") > -1) {
                dispatch(
                  setSwitchInputData({
                    ...switchInputData,
                    ["has_laundry"]: !changedFields[0]?.value,
                  })
                );
              }
              if (changedFields[0]?.name?.indexOf("has_outdoorSpace") > -1) {
                dispatch(
                  setSwitchInputData({
                    ...switchInputData,
                    ["has_outdoorSpace"]: !changedFields[0]?.value,
                  })
                );
              }
            }
          }}
        >
          <div className={"border-checkbox"}>
            <Form.Item name="has_parking" data-test-id={"has-parking-switch-btn"} data-scroll-id="has_parking">
              <SwitchButton
                name="has_parking"
                onChange={handleChange}
                checked={switchInputData["has_parking"]}
                label={"Has Parking"}
              />
            </Form.Item>
            {switchInputData["has_parking"] && (
              <Form.Item name="parking_info" className={"number_of_parking"}>
                {/*<Form.Item*/}
                {/*  rules={[REQUIRED_POSITIVE_WITH_MESSAGE, NOT_ZERO_IN_START]}*/}
                {/*  data-test-id={"street_number"}*/}
                {/*  name="number_of_parking"*/}
                {/*  label="Number of spots"*/}
                {/*  labelCol={{ span: 24 }}*/}
                {/*>*/}
                {/*  <Input*/}
                {/*    autoComplete={"off"}*/}
                {/*    autoFocus={true}*/}
                {/*    data-test-id={"street_number_input"}*/}
                {/*    placeholder={"Ex. 2 spots"}*/}
                {/*    type={"number"}*/}
                {/*    min={1}*/}
                {/*  />*/}
                {/*</Form.Item>*/}
                {getBedroomsOrBathroomsRadioBtnGroup(
                  "number_of_parking",
                  "Number of spots",
                  [REQUIRED_WITH_MESSAGE("Field ")],
                  "purple-radiobutton-group_bathrooms bathroom-radio-buttons",
                  "must-haves-bathroom-radio-buttons",
                  "number_of_parking"
                )}
                {getChipCheckbox(
                  enums.find((item: EnumInterface) => item.key === "parking_type"),
                  "column",
                  "chip-enums-radio-btn",
                  "parking_type"
                )}
                <Form.Item
                  rules={[NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE]}
                  data-test-id={"parking_cost"}
                  name="parking_cost"
                  label="Additional Parking Cost"
                  labelCol={{ span: 24 }}
                >
                  <InputNumber
                    autoComplete={"off"}
                    min={0}
                    prefix={"$"}
                    data-test-id={"parking_cost_input"}
                    placeholder={"Ex. $100"}
                    type={"number"}
                  />
                </Form.Item>
              </Form.Item>
            )}
          </div>
          <div className={"border-checkbox "}>
            {getEnumInputs(
              enums.find((item: EnumInterface) => item.key === "pet_policy"),
              "inline",
              "pet_policy",
              "pet_policy"
            )}

            {(petPolicy === "allowed" || petPolicy === "negotiable") && (
              <div className={"pet-policy"}>
                {getChipCheckbox(
                  enums.find((item: EnumInterface) => item.key === "pets_allowed"),
                  "column",
                  "chip-enums-radio-btn",
                  "pets_allowed"
                )}

                {petPolicy === "negotiable" && (
                  <Form.Item
                    data-test-id={"pet-policy"}
                    name="pet_policy_description"
                    label="Pet Policy (Optional)"
                    labelCol={{ span: 24 }}
                  >
                    <TextArea autoFocus={true} />
                  </Form.Item>
                )}
              </div>
            )}
          </div>
          <div className={"border-checkbox utilities-incuded-section"}>
            {getChipCheckbox(
              enums.find((item: EnumInterface) => item.key === "utilities"),
              "column",
              "chip-enums-radio-btn",
              "utilities"
            )}
          </div>
          <div className={"border-checkbox laundry"}>
            <Form.Item name="has_laundry" data-test-id={"has_laundry"} data-scroll-id="has_laundry">
              <SwitchButton
                name="has_laundry"
                onChange={handleChange}
                checked={switchInputData["has_laundry"]}
                label={"Has Laundry"}
              />
            </Form.Item>
            {switchInputData["has_laundry"] && getEnumInputs(laundry, "inline", "laundry_enums_radio_group", "laundry")}
          </div>
          <div className={"border-checkbox"}>
            <Form.Item data-test-id={"dishwasher"} name="dishwasher" data-scroll-id="dishwasher">
              <SwitchButton
                name="dishwasher"
                onChange={handleChange}
                checked={switchInputData["dishwasher"]}
                label={"Has Dishwasher"}
              />
            </Form.Item>
          </div>
          <div className={"border-checkbox"}>
            <Form.Item
              data-test-id={"airConditioning"}
              name="has_air_conditioning"
              data-scroll-id="has_air_conditioning"
            >
              <SwitchButton
                name="has_air_conditioning"
                onChange={handleChange}
                checked={switchInputData["has_air_conditioning"]}
                label={"Has A/C"}
              />
              {switchInputData["has_air_conditioning"] &&
                getEnumInputs(airConditioning, "inline", "laundry_enums_radio_group", "air_conditioning")}
            </Form.Item>
          </div>
          <div className={"border-checkbox"}>
            <Form.Item data-test-id={"has_outdoorSpace"} name="has_outdoorSpace" data-scroll-id={"has_outdoorSpace"}>
              <SwitchButton
                name="has_outdoorSpace"
                onChange={handleChange}
                checked={switchInputData["has_outdoorSpace"]}
                label={"Outdoor Space"}
                dataScrollId={"has_outdoorSpace"}
              />
            </Form.Item>
            {switchInputData["has_outdoorSpace"] &&
              getChipCheckbox(
                enums.find((item: EnumInterface) => item.key === "outdoor_space"),
                "column",
                "chip-enums-radio-btn"
              )}
          </div>
        </Form>
      </Spin>
      <StepsActionButtons form={form} prev={prevClicked} />
    </div>
  );
};
export default PropertyDetails;
