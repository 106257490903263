import * as types from "./types";

export const getPaymentLink = (payload:any) => ({
    type: types.GET_PAYMENT_LINK,
    payload
});

export const getPaymentLinkSuccess = (payload:any) => ({
    type: types.GET_PAYMENT_LINK_SUCCESS,
    payload,
});

export const getPaymentLinkFail = (payload:any) => ({
    type: types.GET_PAYMENT_LINK_FAIL,
    payload,
});

export const getSubscriptions = (payload:any) => ({
    type: types.GET_SUBSCRIPTION,
    payload,
});

export const getSubscriptionsSuccess = (payload:any) => ({
    type:types.GET_SUBSCRIPTION_SUCCESS,
    payload
})

export const getSubscriptionsFail = (payload:any) => ({
    type:types.GET_SUBSCRIPTION_FAIL,
    payload
})

export const getUpcomingInvoices = () => ({
    type: types.GET_UPCOMING_INVOICES,
})

export const getUpcomingInvoicesSuccess = (payload:any) => ({
    type: types.GET_UPCOMING_INVOICES_SUCCESS,
    payload
})

export const getUpcomingInvoicesFail = (payload:any) => ({
    type: types.GET_UPCOMING_INVOICES_FAIL,
    payload
})

export const getInvoices = () => ({
    type: types.GET_INVOICES,
})

export const getInvoicesSuccess = (payload:any) => ({
    type: types.GET_INVOICES_SUCCESS,
    payload
})

export const getInvoicesFail = (payload:any) => ({
    type: types.GET_INVOICES_FAIL,
    payload
})

export const updateSubscription = (payload:any) => ({
    type: types.UPDATE_SUBSCRIPTION,
    payload
})

export const updateSubscriptionSuccess = (payload:any) => ({
    type: types.UPDATE_SUBSCRIPTION_SUCCESS,
    payload
})

export const updateSubscriptionFail = (payload:any) => ({
    type: types.UPDATE_SUBSCRIPTION_FAIL,
    payload
})

export const cancelSubscription = () => ({
    type: types.CANCEL_SUBSCRIPTION,
})

export const cancelSubscriptionSuccess = (payload:any) => ({
    type: types.CANCEL_SUBSCRIPTION_SUCCESS,
    payload
})

export const cancelSubscriptionFail = (payload:any) => ({
    type: types.CANCEL_SUBSCRIPTION_FAIL,
    payload
})

export const setUpdatedSubscriptionToBlank = () => ({
    type: types.SET_UPDATED_SUBSCRIPTION_TO_BLANK
})

export const getStripePortalURL = () => ({
  type: types.GET_STRIPE_PORTAL_URL,
});

export const getStripePortalURLSuccess = (payload: any) => ({
  type: types.GET_STRIPE_PORTAL_URL_SUCCESS,
  payload,
});

export const getScreeningPaymentLink = (payload: any) => ({
  type: types.GET_SCREENING_PAYMENT_LINK,
  payload
});

export const clearScreeningPaymentLink = () => ({
  type: types.CLEAR_SCREENING_PAYMENT_LINK,
});

export const getScreeningPaymentLinkSuccess = (payload: any) => ({
  type: types.GET_SCREENING_PAYMENT_LINK_SUCCESS,
  payload
});

export const getScreeningPaymentLinkFail = (payload: any) => ({
  type: types.GET_SCREENING_PAYMENT_LINK_FAIL,
  payload
});
