import React, { useState } from "react";
import BaseButton from "../../../../Shared/BaseButton";
import AgentReqModal from "../../../../LeadsSingleRequirement/components/AgentReqModal";
import { useSelector } from "react-redux";
import { requirementAgentDataSelector } from "../../../../../store/requirement/selectors";
import "./ExistingLeadButtons.scss";
import { AnalyticsService } from "helpers/analyticsService";

const ExistingLeadButtons = ({ showContent }: { showContent: boolean }) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const reqAgent = useSelector(requirementAgentDataSelector);

  const actionBtnClick = () => {
    AnalyticsService.reportRequirementsFunnel({ action: "requirements_start_page_cta_click", label: "connect" });
    setToggleModal(true);
  };

  return (
    <div className={"existing-lead-btn-wrapper"}>
      {showContent && (
        <>
          <BaseButton variant="primary" dataTestId="seeRequirements" onClick={actionBtnClick}>
            <span data-test-id={"seeRequirements"}>Let's go!</span>
          </BaseButton>
          <AgentReqModal
            toggle={toggleModal}
            setToggle={setToggleModal}
            showApproveModal={showApproveModal}
            setShowApproveModal={setShowApproveModal}
            reqAgent={reqAgent}
          />
        </>
      )}
    </div>
  );
};

export default ExistingLeadButtons;
