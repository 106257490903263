import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listingDataSelector, listingUpdateSuccessSelector } from "../../../store/listings/selectors";
import { Card, Form, Radio } from "antd";
import Basics from "../ListingForm/AvailabilityPart/Basics";
import { IObjectKeys } from "../../../helpers/globalTypes";
import { StepFormComponent } from "../ListingForm/types";
import { clearListingUpdateState, updateListing } from "../../../store/listings/action";
import moment from "moment";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const BasicsStep = ({ form, next }: StepFormComponent) => {
  const dispatch = useDispatch();
  const listingData = useSelector(listingDataSelector);
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const onSubmit = (formData: IObjectKeys) => {
    if (listingData._id) {
      delete formData.status;
      formData.availability_date = formData.availability_date && formData.availability_date.format("MM-DD-YYYY HH:ss");
      formData.lease_start_date = null;
      formData.lease_ending_date = null;
      formData.other_renewal_date = null;
      formData.renewal_date = 0;
      const data = { lId: listingData._id, ...formData };
      dispatch(updateListing(data));
    }
  };

  useEffect(() => {
    if (updateListingSuccess) {
      next && next();
    }
    return () => {
      dispatch(clearListingUpdateState());
    };
  }, [updateListingSuccess]);

  return (
    <Form
      form={form}
      {...layout}
      onFinish={onSubmit}
      initialValues={{
        status: "Available",
        current_rent: listingData.current_rent,
        asked_rent: listingData.asked_rent,
        availability_date: listingData.availability_date ? moment(listingData.availability_date) : "",
      }}
    >
      <Form.Item name="status" className="flex-centered rounded-lg border-purple-200">
        <Radio.Group size="large">
          <Card>
            <Radio.Button value="Available">Available</Radio.Button>
            <Radio.Button value="Off-market" disabled>
              Off-Market
            </Radio.Button>
          </Card>
        </Radio.Group>
      </Form.Item>
      <Basics />
    </Form>
  );
};
export default BasicsStep;
