import React, { useEffect, useState } from "react";
import { List } from "antd";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { ListingNote } from "../../Listings/helpers/mockListings";
import { useDispatch, useSelector } from "react-redux";
import { IObjectKeys } from "../../../helpers/globalTypes";
import { deleteLeadNote, getLeadNotes, setLeadNoteAddSuccess } from "store/leads/action";
import { getDeleteNoteConfirmContent } from "../../../contentful/api";
import { Document } from "@contentful/rich-text-types";
import NoteDeleteConfirmModal from "../../Shared/NoteDeleteConfirmModal";
import BaseCollapse from "../../Shared/BaseCollapse";
import BaseButton from "../../Shared/BaseButton";
import ListingNotesListItem from "../../ListingView/listing-notes-elements/ListingNotesListItem";
import BaseEmptyState from "../../BaseEmptyState";
import AllListingNotesModal from "../../ListingView/all-listing-notes-modal/AllListingNotesModal";
import Message from "../../Shared/Message";
import { windowSelector } from "../../../store/app/selectors";
import qs from "query-string";
import { useForm } from "antd/es/form/Form";
import { useWindowSize } from "../../../hooks/useWindowSize";
import {
  leadNoteAddSuccessSelector,
  leadNotesLoadingSelector,
  leadNotesSelector,
  singleLeadDataSelector,
} from "../../../store/leads/selectors";
import LeadNoteModal from "./LeadNoteModal";
import {
  getStartOfUnitFormatted,
  getDateDifference,
  currentDate,
  dateToMoment,
  formatDate,
} from "../../../helpers/dateHelper";
import Section from "components/Shared/Section/Section";

const initialModalValue = { visible: false, editModal: false };
const confirmDeleteValue = { visible: false, noteId: "" };
const LeadNotes = () => {
  const [form] = useForm();
  const { lId }: any = useParams();
  const location = useLocation();
  const { landlordId }: any = useParams();
  const dispatch = useDispatch();
  const landlordNotes = useSelector(leadNotesSelector);
  const [modalProps, setModalProps] = useState(initialModalValue);
  const addLandlordNoteSuccess = useSelector(leadNoteAddSuccessSelector);
  const [deleteConfirmProps, setDeleteConfirmProps] = useState(confirmDeleteValue);
  const leadData = useSelector(singleLeadDataSelector);
  const { collapsed } = useSelector(windowSelector);
  const [seeAllNotesModalVisible, setSeeAllNotesModalVisible] = useState(false);
  const landlordNotesLoading = useSelector(leadNotesLoadingSelector);
  const [allLoaded, setAllLoaded] = useState(false);
  const [notesToSee, setNotesToSee] = useState<ListingNote[]>([]);
  const listingNoteAddSuccess = useSelector(leadNoteAddSuccessSelector);
  const [deleteConfirmContent, setDeleteConfirmContent] = useState<Document | undefined>(undefined);
  const { width } = useWindowSize();
  const [notesCollapsed, setNotesCollapsed] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});
  const leadFlow = location.pathname.includes("lead-flow");

  useEffect(() => {
    if (listingNoteAddSuccess) {
      setTimeout(() => {
        dispatch(setLeadNoteAddSuccess(false));
      }, 2000);
    }
  }, [dispatch, listingNoteAddSuccess]);

  useEffect(() => {
    (async () => {
      const entries: IObjectKeys = await getDeleteNoteConfirmContent();
      if (entries) {
        setDeleteConfirmContent(entries.fields.modalDescription);
      }
    })();
  }, [landlordId]);

  useEffect(() => {
    if (Object.keys(leadData).length > 0 && leadData.renter?._id && !leadFlow)
      dispatch(getLeadNotes(`${leadData.renter._id}`));
  }, [dispatch, leadData, leadFlow]);

  useEffect(() => {
    addLandlordNoteSuccess && setModalProps(initialModalValue);
  }, [addLandlordNoteSuccess]);

  useEffect(() => {
    if (allLoaded) {
      setNotesToSee([...landlordNotes]);
    } else {
      const sliceNotes = landlordNotes?.slice(0, 4);
      setNotesToSee(
        landlordNotes.length > 4
          ? [...sliceNotes]
          : landlordNotes.length <= 4 && landlordNotes.length >= 1
          ? [...landlordNotes]
          : []
      );
    }
  }, [landlordNotes, allLoaded]);

  useEffect(() => {
    if (landlordNotes.length && location.search) {
      const { note_id } = qs.parse(location.search);
      if (note_id) {
        setNotesCollapsed(true);
      }
    }
  }, [location.search, landlordNotes]);

  const onLoadMore = () => {
    setSeeAllNotesModalVisible(true);
  };
  const handleOpenAddNoteModal = () => {
    setModalProps({ visible: true, editModal: false });
    form.resetFields();
  };

  const handleOpenNoteModal = (note: ListingNote) => {
    const todo_at = note.todo_at ? moment(note.todo_at) : null;
    setModalProps({ visible: true, editModal: true });
    form.setFieldsValue({
      _id: note._id,
      title: note.title,
      description: note.description,
      todo_at,
    });
  };
  const dueContent = (todo_at: string, str: string, item: any) => {
    let daysCount = 0;
    let dayOrDays = "days";
    if (
      getDateDifference(item.todo_at, "days") === 0 &&
      formatDate(item.todo_at) < getStartOfUnitFormatted(currentDate(), "day")
    ) {
      daysCount = 1;
    } else {
      daysCount = getDateDifference(item.todo_at, "days");
      if (daysCount === 0) {
        const hoursCount = getDateDifference(item.todo_at, "hours");
        let hoursOrHours = "hours";
        if (hoursCount <= 1) {
          hoursOrHours = "hour";
        }

        return `(${hoursCount} ${hoursOrHours} ${str})`;
      }
    }

    if (daysCount <= 1) {
      dayOrDays = "day";
    }

    return `(${daysCount} ${dayOrDays} ${str})`;
  };
  const leftOrAgoGenerator = (date: Date) => {
    return new Date(date).getTime() < new Date().getTime() ? "ago" : "left";
  };
  const notesEmptyStateProps = {
    title: "Lead Notes",
    description: "No notes for this lead yet.",
    linkText: "Add Notes",
    openWithModal: false,
    handleAddBtnClick: handleOpenAddNoteModal,
    modalType: "followUp",
    modalProps: {
      isModalVisible: false,
      children: "",
      modalTitle: "",
    },
  };

  return (
    <Section shadow>
      {notesToSee && notesToSee.length > 0 ? (
        <div className="relative">
          {
            //@ts-ignore
            width > 767 && (
              <div className="absolute right-0 top-0 flex flex-row gap-1 notes-btn-wrapper">
                <BaseButton onClick={onLoadMore} size="sm">
                  See All
                </BaseButton>
                <BaseButton onClick={handleOpenAddNoteModal} size="sm" modalType="followUp">
                  Add a Note
                </BaseButton>
              </div>
            )
          }
          <List
            className={`notes-loadmore-list single-listing-notes-skeleton-wrapper
             ${collapsed && "single-listing-notes-skeleton-wrapperCollapsed"} `}
            itemLayout="horizontal"
            header={
              <span className="notes-total-text">
                Showing {notesToSee?.length} of {landlordNotes?.length} notes
              </span>
            }
            dataSource={notesToSee}
            renderItem={(item: ListingNote) => (
              <ListingNotesListItem
                handleOpenNoteModal={handleOpenNoteModal}
                item={item}
                setDeleteConfirmProps={setDeleteConfirmProps}
                listingNotesLoading={landlordNotesLoading}
                dueContent={dueContent}
                leftOrAgoGenerator={leftOrAgoGenerator}
                onShowNote={onLoadMore}
                setSelectedNote={setSelectedNote}
              />
            )}
          />
          {
            //@ts-ignore
            width <= 768 && (
              <div className="notes-btn-wrapper">
                {landlordNotes?.length > 3 && (
                  <BaseButton onClick={onLoadMore} classnames={"rb-secondary-btn md"}>
                    See All
                  </BaseButton>
                )}
                <BaseButton classnames={"rb-primary-btn md"} onClick={handleOpenAddNoteModal} modalType="followUp">
                  Add a Note
                </BaseButton>
              </div>
            )
          }
        </div>
      ) : (
        <div className={"empty-listing-notes"}>
          <BaseEmptyState {...notesEmptyStateProps} />
        </div>
      )}

      <LeadNoteModal
        modalProps={modalProps}
        setModalProps={setModalProps}
        initialModalValue={initialModalValue}
        lId={lId}
        form={form}
      />
      <NoteDeleteConfirmModal
        deleteConfirmProps={deleteConfirmProps}
        setDeleteConfirmProps={setDeleteConfirmProps}
        modalContent={deleteConfirmContent}
        onOkayClick={() => {
          dispatch(deleteLeadNote(deleteConfirmProps.noteId || ""));
        }}
      />
      <AllListingNotesModal
        visible={seeAllNotesModalVisible}
        setVisible={setSeeAllNotesModalVisible}
        handleOpenAddNoteModal={handleOpenAddNoteModal}
        notes={landlordNotes}
        handleOpenNoteModal={handleOpenNoteModal}
        setDeleteConfirmProps={setDeleteConfirmProps}
        selectedNote={selectedNote}
        setSelectedNote={setSelectedNote}
      />
      {listingNoteAddSuccess && <Message text={"Note Added Successfully"} />}
    </Section>
  );
};

export default LeadNotes;
