import * as types from "./types";
import { IActivitiesQuery } from "../../components/TeamDashboard";

export function getAgentActivities(queryAndPagination: IActivitiesQuery) {
  return {
    type: types.GET_AGENT_ACTIVITIES,
    payload: { ...queryAndPagination },
  };
}

export const setQueryAndPagination = (payload: IActivitiesQuery) => ({
    type: types.SET_QUERY_AND_PAGINATION,
    payload,
});

export function getAgentsActivitiesSuccess(activities: object) {
  return { type: types.GET_AGENT_ACTIVITIES_SUCCESS, payload: activities };
}

export function getAgentsActivitiesFail(error: object) {
  return { type: types.GET_AGENT_ACTIVITIES_FAIL, payload: error };
}

export function setCurrentAgentForActivities(payload: object) {
  return { type: types.SET_CURRENT_AGENT_FOR_ACTIVITIES, payload };
}

export function setActivitiesLoaderToTrue() {
  return { type: types.SET_ACTIVITIES_LOADER_TO_TRUE };
}
