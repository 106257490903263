import { IObjectKeys } from "helpers/globalTypes";
import { EnumInterface } from "./../../helpers/globalTypes";
import * as types from "./types";


interface IFlatfileEnumsState {
  flatfileEnums:EnumInterface[] | null;
  getFlatfileEnumsSuccess:boolean;
  getFlatfileEnumsError:IObjectKeys | null;
  flatFileEnumsLoading:boolean
}
const initialState: IFlatfileEnumsState = {
  flatfileEnums:null,
  getFlatfileEnumsSuccess:false,
  getFlatfileEnumsError:null,
  flatFileEnumsLoading:false
};

export default function flatfileEnumsReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
   case  types.GET_FLATFILE_ENUMS:
    return {
      getFlatfileEnumsSuccess:false,
      getFlatfileEnumsError:null,
      flatFileEnumsLoading:true
    };
    case types.GET_FLATFILE_ENUMS_ERROR:
      return {
        getFlatfileEnumsSuccess:false,
        getFlatfileEnumsError:action.payload,
        flatFileEnumsLoading:false
      };
    case types.GET_FLATFILE_ENUMS_SUCCESS :
      return {
        getFlatfileEnumsSuccess:true,
        getFlatfileEnumsError:null,
        flatFileEnumsLoading:false,
        flatfileEnums:action.payload
      };
    default:
      return state;
  }
}
