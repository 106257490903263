import React from "react";
import { Col, Row } from "antd";
import UploadedListItem from "./UploadedListItem";

export interface IColViewConfig {
  span?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export interface IListingImagesList {
  fileList: any;
  onPreview: Function;
  showConfirm: Function;
  enablePreview: Boolean;
  canEdit: Boolean;
  wrapperClassName?: string;
  colViewConfig: IColViewConfig;
  imageCardClassname?: string;
  setPreviewModalAllPhotosVisible?: Function;
}

const ListingImagesList = ({
  fileList,
  imageCardClassname,
  colViewConfig,
  enablePreview,
  onPreview,
  showConfirm,
  wrapperClassName,
  setPreviewModalAllPhotosVisible,
  canEdit,
}: IListingImagesList) => {
  return (
    <Row className={`${wrapperClassName ?? ""}`} gutter={[8, 0]}>
      {fileList?.length > 0 &&
        fileList?.map((file: any, idx: number, arr: [any]) => {
          return (
            <Col key={file?._id} {...colViewConfig}>
              <UploadedListItem
                fileIndex={idx}
                onPreview={onPreview}
                enablePreview={enablePreview}
                colViewConfig={colViewConfig}
                file={file}
                imageCardClassname={imageCardClassname}
                showConfirm={showConfirm}
                setPreviewModalAllPhotosVisible={setPreviewModalAllPhotosVisible}
                canEdit={canEdit}
                showViewMoreButton={idx === fileList.length - 1}
              />
            </Col>
          );
        })}
    </Row>
  );
};

export default React.memo(ListingImagesList);
