import React from "react";
import { ButtonProps, Modal } from "antd";
import "./styles.scss";

export interface IBaseModal {
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  modalFooter?: React.ReactNode;
  children: React.ReactNode;
  modalTitle?: React.ReactNode;
  closeIcon?: React.ReactNode;
  confirmLoading?: boolean;
  isModalVisible: boolean;
  classname?: string;
  okText?: string;
  cancelText?: string;
  onOk?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  destroyOnClose?: boolean;
  dataTestId?: string | undefined;
  width?: number | undefined;
  centered?: boolean;
  style?: any;
  removeBodyStyle?: boolean;
}

const BaseModal = (props: IBaseModal) => {
  const okButton = {
    className:
      "p-3 px-5 h-12 text-base rounded-md hover:text-purple-50 text-white bg-purple-700 focus:bg-purple-600 active:bg-purple-800",
  };
  const cancelButton = {
    className:
      "p-3 px-5 text-base rounded-md hover:bg-purple-100 ring-1 ring-inset ring-purple-400 hover:text-purple-800 text-purple-900 h-12",
  };

  return (
    <Modal
      confirmLoading={props?.confirmLoading}
      okButtonProps={{ ...props.okButtonProps, ...okButton }}
      cancelButtonProps={{ ...props.cancelButtonProps, ...cancelButton }}
      destroyOnClose={props.destroyOnClose}
      onOk={props.onOk}
      onCancel={props.onCancel}
      closeIcon={!props.closeIcon ? "x Close" : props.closeIcon}
      wrapClassName={`base-modal ${props.classname ?? ""}`}
      title={props.modalTitle}
      visible={props.isModalVisible}
      footer={props.modalFooter}
      data-test-id={props.dataTestId}
      okText={props.okText}
      cancelText={props.cancelText}
      width={props.width}
      bodyStyle={props.removeBodyStyle ? {} : { maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
      centered={props.centered}
      style={props.style ? props.style : {}}
    >
      {props.children}
    </Modal>
  );
};

export default React.memo(BaseModal);
