import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Form, Input } from "antd";
import { addListingNote, updateListingNote } from "../../../store/listings/action";
import { REQUIRED_TITLE_VALIDATION } from "../../../helpers/validations";
import BaseModal from "../../Shared/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import "./ListingNoteModal.scss";
import HouseIcon from "./icons/HouseIcon";
import BaseButton from "../../Shared/BaseButton";
import { listingNoteAddSuccessSelector } from "../../../store/listings/selectors";
import { AnalyticsService } from "helpers/analyticsService";

type modalPropsType = { visible: boolean; editModal: boolean; };

interface IListingNoteModal {
  modalProps: modalPropsType;
  listingNotesLoading: boolean;
  form: any;
  setModalProps: Dispatch<SetStateAction<any>>;
  initialModalValue: any;
  listing?: any;
}

const { TextArea } = Input;
const ListingNoteModal = ({ modalProps, listingNotesLoading, form, setModalProps, initialModalValue, listing }: IListingNoteModal) => {
  const dispatch = useDispatch();
  const listingNoteAddSuccess = useSelector(listingNoteAddSuccessSelector);
  const [isDescriptionShown, setIsDescriptionShown] = useState(false);
  useEffect(() => {
    if (!listingNotesLoading) {
      setIsDescriptionShown(false);
      form.resetFields();
    }
  }, [listingNotesLoading]);
  useEffect(() => {
    if (modalProps.editModal) {
      if (form.getFieldValue("description")?.length > 0) {
        setIsDescriptionShown(true);
      }
    }
  }, [form, modalProps]);
  useEffect(() => {
    if (listingNoteAddSuccess) {
      setModalProps(initialModalValue);
    }
  }, [listingNoteAddSuccess]);
  return (
    <BaseModal
      classname={"single-listing-page-add-note listing-add-edit-modal"}
      cancelButtonProps={{
        className: "rb-secondary-btn sm"
      }}
      okButtonProps={{
        className: "rb-primary-btn sm"
      }}
      modalTitle=""
      isModalVisible={modalProps.visible}
      confirmLoading={listingNotesLoading}
      onCancel={() => setModalProps(initialModalValue)}
      modalFooter={[
        <BaseButton
          variant="primary"
          onClick={() => form.submit()} key={1}>
          {modalProps.editModal ? "Update Note" : "Save"}
        </BaseButton>
      ]}
    >
      <div className={"listing-add-edit-modal--header"}>
        <h3>{modalProps.editModal ? "Edit Note" : "Add Note"}</h3>
        <div className={"listing-add-edit-modal--header--info"}>
          <span>For:</span>
          <HouseIcon />
          <p>{listing.addr}</p>
        </div>
      </div>
      <Form
        className={"add-note-modal-form-wrapper"}
        onFinish={async (data) => {
          if (modalProps.editModal) {
            return dispatch(updateListingNote({ ...data, listing_id: listing._id }));
          }
          await dispatch(addListingNote({ ...data, listing_id: listing._id }));
          AnalyticsService.reportEvent("form_submit", {
            category: "listings",
            label: "add note"
          });
        }}
        form={form}
      >
        <Form.Item name={"_id"} className={"add-note-modal-form-wrapper--notVisible"}>
          not visible
        </Form.Item>
        <div className={"add-note-modal-form-wrapper--form-item"}>
          <p>Note title</p>
          <Form.Item name="title" rules={[REQUIRED_TITLE_VALIDATION]}>
            <Input autoComplete={"off"} placeholder={"Add title here"} />
          </Form.Item>
        </div>
        {!isDescriptionShown && (
          <span
            className={"add-description-btn"}
            onClick={() => {
              setIsDescriptionShown(true);
            }}
          >
            + Add decription
          </span>
        )}
        {isDescriptionShown && (
          <div className={"add-note-modal-form-wrapper--form-item"}>
            <p>Add description</p>
            <Form.Item
              name="description"
              rules={[
                {
                  min: 0,
                  message: "Please enter description"
                }
              ]}
            >
              <TextArea autoComplete={"off"} rows={4} autoFocus={true} />
            </Form.Item>
          </div>
        )}
      </Form>
    </BaseModal>
  );
};

export default ListingNoteModal;
