import React, { useState, useEffect } from "react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import ListingApi from "api/Listing";
import { Spin } from "antd";
import GlobalLoading from "components/Shared/GlobalLoading";
import Pagination, { ShowingListingCount } from "components/Shared/Pagination";
import ListingGrid from "./ListingGrid";
import { useDispatch, useSelector } from "react-redux";
import { paginationValuesSelector, routerSelector } from "store/listings/selectors";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "helpers/globalFunctions";
import { setListingsPaginationValues } from "store/listings/action";
import { paginationValuesFromSearchQuery } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import { IPaginationValues } from "components/Listings/components/listings-wrapper";

const RentalSearch = ({ defaultListings }: any) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [hitsPerPage, setHitsPerPage] = useState(0);
  const [searchResultsText, setSearchResultsText] = useState<string | null>(null);
  const [listings, setListings] = useState<any>(null);

  const router = useSelector(routerSelector);
  const paginationValues = useSelector(paginationValuesSelector);

  const { search } = useLocation();
  const queryParams = getQueryParams(search);

  const handlePageChange = (page: number) => {
    dispatch(setListingsPaginationValues({ current: page, pageSize: 24 }));
    dispatch(
      push({
        pathname: router.location.pathname,
        search: `?page=${page}&search=${query}`,
      })
    );
  };

  const doSearch = ({ search, page }: { search: string; page: number }) => {
    setQuery(search);
    setPage(page);
    if (!queryParams?.search || queryParams.search !== search) {
      dispatch(
        push({
          pathname: router.location.pathname,
          search: `?page=${page}&search=${search}`,
        })
      );
    }
    if (!isSearching) {
      setIsSearching(true);
      ListingApi.getListingsFromAlgolia({
        search,
        filter_by_agency: false,
        page,
        isModalSearch: false,
        status: "Available",
      }).then((results: any) => {
        const { hits, nbHits: total, hitsPerPage } = results.result;
        setTotal(total);
        setHitsPerPage(hitsPerPage);
        setSearchResultsText(`based on "${search}"`);
        setListings(hits);
        setIsSearching(false);
      });
    }
  };

  const resetSearch = () => {
    dispatch(
      push({
        pathname: router.location.pathname,
        search: ``,
      })
    );
    dispatch(setListingsPaginationValues({ current: 1, pageSize: 24 }));
    setSearchResultsText("");
    setIsSearching(false);
    setQuery("");
    setTotal(0);
    setListings(null);
  };

  // On mount, init search from url
  useEffect(() => {
    const initialPaginationValues: IPaginationValues = paginationValuesFromSearchQuery(queryParams);
    dispatch(setListingsPaginationValues(initialPaginationValues));
    if (queryParams?.search && queryParams.page) {
      doSearch(queryParams);
    }
    return () => {
      dispatch(setListingsPaginationValues({ current: 1, pageSize: 24 }));
    };
  }, []);

  // On paginate
  useEffect(() => {
    if (queryParams?.search && queryParams?.page && queryParams.page !== page) {
      doSearch(queryParams);
    }
  }, [queryParams.page]);

  const listingsToRender = listings || defaultListings;

  return (
    <>
      <div className="relative mt-2 rounded-md shadow-sm mb-8">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          {query?.length ? (
            <div
              onClick={() => {
                resetSearch();
              }}
            >
              <XMarkIcon className="h-6 w-6 text-purple-400 hover:cursor-pointer" aria-hidden="true" />
            </div>
          ) : (
            <MagnifyingGlassIcon className="h-6 w-6 text-purple-400" aria-hidden="true" />
          )}
        </div>
        <input
          autoComplete={"off"}
          value={query}
          placeholder="Search rentals"
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              doSearch({ search: query, page: 1 });
            }
          }}
          data-test-id="search-header-input"
          className="block w-full rounded-full border-0 p-4 pl-12 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 text-xl sm:leading-6"
        />
      </div>

      <Spin spinning={isSearching} className={"global-loading"} indicator={<GlobalLoading />}>
        <div className="pb-10">
          <div>
            {!searched && !isSearching && !searchResultsText && (
              <p>Search thousands of listings and find your next home.</p>
            )}
            {searchResultsText && (
              <div className="flex flex-wrap justify-between mb-4 pb-2 items-center">
                <div>
                  <h3 className={`text-lg font-bold text-gray-700`}>
                    <ShowingListingCount total={total} pageSize={hitsPerPage} current={page} />
                  </h3>
                  <p className="mt-2 max-w-4xl text-sm   ">Results {searchResultsText}</p>
                </div>
                <div>
                  <Pagination total={total} pagination={paginationValues} onPageChange={handlePageChange} />
                </div>
              </div>
            )}
            {listingsToRender ? (
              <>
                <ListingGrid listings={listingsToRender} />
                {total && paginationValues ? (
                  <div className="py-10">
                    <Pagination total={total} pagination={paginationValues} onPageChange={handlePageChange} />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default RentalSearch;
