import React from "react";

export const RBLogo = () => {
  return (
    <svg
      width="168"
      height="168"
      viewBox="0 0 168 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"sidebar-rb-logo-svg"}
    >
      <g clipPath="url(#clip0_1851_17552)">
        <rect width="168" height="168" fill="white" />
        <g filter="url(#filter0_d_1851_17552)">
          <rect
            x="12"
            y="13"
            width="140.753"
            height="137"
            rx="32.84"
            fill="#6E00F0"
          />
        </g>
        <mask
          id="mask0_1851_17552"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="40"
          y="42"
          width="113"
          height="108"
        >
          <path
            d="M152.664 149.999H74.8717L47.6383 117.681L48.7654 78.5208L40.6019 67.5598L82.0352 42.25L122.551 57.1474L152.664 88.9114V149.999Z"
            fill="#42008F"
          />
          <path
            d="M152.664 149.999H74.8717L47.6383 117.681L48.7654 78.5208L40.6019 67.5598L82.0352 42.25L122.551 57.1474L152.664 88.9114V149.999Z"
            fill="url(#paint0_linear_1851_17552)"
          />
        </mask>
        <g mask="url(#mask0_1851_17552)">
          <rect
            x="14.9515"
            y="9.5874"
            width="149.851"
            height="154.599"
            rx="11.8382"
            fill="#48009C"
          />
          <rect
            x="14.9515"
            y="9.5874"
            width="149.851"
            height="154.599"
            rx="11.8382"
            fill="url(#paint1_linear_1851_17552)"
          />
        </g>
        <path
          d="M55.7483 98.0027V114.833H47.7318V71.7585H60.8742C63.8146 71.7585 66.3278 72.0663 68.4139 72.6819C70.5199 73.2777 72.2384 74.1217 73.5695 75.214C74.9205 76.3063 75.904 77.617 76.5199 79.1462C77.1556 80.6555 77.4735 82.3237 77.4735 84.1507C77.4735 85.6005 77.2549 86.9708 76.8179 88.2616C76.4006 89.5525 75.7848 90.7242 74.9702 91.7767C74.1755 92.8293 73.1821 93.7527 71.9901 94.5471C70.8179 95.3415 69.4768 95.977 67.9669 96.4536C68.9801 97.0295 69.8543 97.8537 70.5894 98.9261L81.3775 114.833H74.1655C73.4702 114.833 72.8742 114.694 72.3775 114.416C71.9006 114.138 71.4934 113.741 71.1556 113.225L62.096 99.4325C61.7583 98.9162 61.3808 98.5488 60.9636 98.3303C60.5662 98.1119 59.9702 98.0027 59.1755 98.0027H55.7483ZM55.7483 92.2534H60.755C62.2649 92.2534 63.5762 92.0647 64.6887 91.6874C65.8212 91.31 66.745 90.7937 67.4603 90.1383C68.1954 89.4631 68.7417 88.6687 69.0993 87.7552C69.4569 86.8417 69.6357 85.8388 69.6357 84.7465C69.6357 82.562 68.9106 80.8839 67.4603 79.7122C66.0298 78.5404 63.8344 77.9546 60.8742 77.9546H55.7483V92.2534Z"
          fill="white"
        />
        <path
          d="M87.6721 114.833V71.7585H102.543C105.364 71.7585 107.768 72.0266 109.755 72.5628C111.761 73.099 113.401 73.8636 114.672 74.8565C115.963 75.8495 116.907 77.051 117.503 78.461C118.099 79.871 118.397 81.4598 118.397 83.2273C118.397 84.2401 118.248 85.2132 117.95 86.1466C117.652 87.0601 117.185 87.924 116.55 88.7382C115.934 89.5326 115.139 90.2575 114.165 90.9128C113.212 91.5682 112.069 92.1243 110.738 92.581C116.659 93.9116 119.619 97.109 119.619 102.173C119.619 104 119.271 105.688 118.576 107.237C117.881 108.786 116.867 110.127 115.536 111.259C114.205 112.371 112.566 113.245 110.619 113.88C108.672 114.516 106.447 114.833 103.944 114.833H87.6721ZM95.6886 95.9472V108.578H103.824C105.314 108.578 106.556 108.399 107.55 108.042C108.563 107.684 109.367 107.207 109.963 106.612C110.579 106.016 111.016 105.321 111.275 104.526C111.553 103.732 111.692 102.888 111.692 101.994C111.692 101.061 111.543 100.227 111.245 99.4921C110.947 98.7375 110.48 98.102 109.844 97.5856C109.208 97.0494 108.394 96.6423 107.401 96.3643C106.407 96.0862 105.205 95.9472 103.795 95.9472H95.6886ZM95.6886 90.4064H102.096C104.818 90.4064 106.884 89.9099 108.295 88.917C109.705 87.924 110.41 86.3452 110.41 84.1805C110.41 81.9364 109.775 80.3377 108.503 79.3845C107.232 78.4312 105.245 77.9546 102.543 77.9546H95.6886V90.4064Z"
          fill="white"
        />
        <path
          d="M82.4973 46.0371L40.5696 66.74V57.3143L82.4973 36.4431L124.088 56.6573V66.0667L82.4973 46.0371Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1851_17552"
          x="-5.0386"
          y="3.4948"
          width="168.373"
          height="164.62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-3.2286" dy="4.3048" />
          <feGaussianBlur stdDeviation="6.905" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1851_17552"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1851_17552"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1851_17552"
          x1="59.0721"
          y1="67.6273"
          x2="144.184"
          y2="145.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3D0085" />
          <stop offset="1" stopColor="#5806B9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1851_17552"
          x1="73.22"
          y1="56.9266"
          x2="134.139"
          y2="145.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#38007B" />
          <stop offset="1" stopColor="#5806B9" />
        </linearGradient>
        <clipPath id="clip0_1851_17552">
          <rect width="168" height="168" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
