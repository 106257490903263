import * as types from "./types";
import { AuthError } from "../../types/user";
import { EnumInterface } from "../../helpers/globalTypes";

export const getEnums = () => ({
  type: types.GET_ENUMS,
});

export const getEnumsSuccess = (payload: { enums: EnumInterface[]; }) => ({
  type: types.GET_ENUMS_SUCCESS,
  payload,
});

export const getEnumsFail = (payload: AuthError) => ({
  type: types.GET_ENUMS_FAIL,
  payload,
});

export const getRequirementEnums = () => ({
  type: types.GET_REQUIREMENT_ENUMS,
});
export const getRequirementEnumsSuccess = (payload: {
  enums: EnumInterface[];
}) => ({
  type: types.GET_REQUIREMENT_ENUMS_SUCCESS,
  payload,
});
export const getRequirementEnumsFail = (payload: AuthError) => ({
  type: types.GET_REQUIREMENT_ENUMS_FAIL,
  payload,
});
