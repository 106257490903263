import React from "react";
import { getChipCheckbox, getEnumInputs, getListingInput } from "../../../../helpers/globalFunctions";
import { EnumInterface } from "../../../../helpers/globalTypes";
import { Col, Form, Input, Row } from "antd";
import { DollarCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { enumsSelector } from "../../../../store/enums/selectors";
import { IFeesProps } from "./types";
import { NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE } from "../../../../helpers/validations";

const Fees = ({ showDepositAmount, showOtherBFInput, showPetDepositAmount }: IFeesProps) => {
  const enums = useSelector(enumsSelector);
  return (
    <>
      {enums?.length
        ? getChipCheckbox(
            enums.find((item: EnumInterface) => item.key === "move_in_fees"),
            "column",
            "",
            "move_in_fees"
          )
        : ""}
      {showDepositAmount ? (
        <Form.Item>
          <Row>
            <Col span={24}>
              {getListingInput(
                "security_deposit_amount",
                "Enter security deposit amount:",
                [NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE],
                "Ex. $1200"
              )}
            </Col>
          </Row>
        </Form.Item>
      ) : (
        ""
      )}

      {showPetDepositAmount ? (
        <Form.Item>
          <Row>
            <Col span={24}>
              {getListingInput(
                "pet_deposit_amount",
                "Enter pet deposit amount:",
                [NOT_REQUIRED_POSITIVE_NUMBER_WITH_MESSAGE],
                "Ex. $1200"
              )}
            </Col>
          </Row>
        </Form.Item>
      ) : (
        ""
      )}
      <Form.Item>
        <Col className={"broker_fees"}>
          {getEnumInputs(
            enums.find((item: EnumInterface) => item.key === "broker_fees"),
            "inline"
          )}

          {showOtherBFInput && (
            <Form.Item name="other_broker_fee">
              <Input autoComplete={"off"} placeholder={"ex. Landlord pays $1000"} />
            </Form.Item>
          )}
        </Col>
      </Form.Item>
    </>
  );
};
export default Fees;
