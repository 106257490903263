import React from "react";

export const EmailIcon = ({ width = '45', height = '44' }: { width?: string; height?: string; }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.6696 14.1429H13.0361L21.8529 21.7509L30.6696 14.1429Z" fill="black" />
            <path d="M21.8527 23.5713C21.6664 23.5713 21.4861 23.5041 21.3447 23.3817L11.7068 15.0652V29.8568H31.9987V15.0652L22.3607 23.3817C22.2193 23.5041 22.0391 23.5713 21.8527 23.5713Z" fill="black" />
            <path d="M21.8528 0.000976562C9.78391 0.000976562 0 9.85055 0 22.0004C0 34.1503 9.78391 43.9999 21.8528 43.9999C33.9217 43.9999 43.7056 34.1503 43.7056 22.0004C43.6919 9.85631 33.916 0.0147876 21.8528 0.000976562ZM33.5597 30.6431C33.5597 31.077 33.2102 31.4288 32.7792 31.4288H10.9264C10.4954 31.4288 10.1459 31.077 10.1459 30.6431V13.3578C10.1459 12.9239 10.4954 12.5721 10.9264 12.5721H32.7792C33.2102 12.5721 33.5597 12.9239 33.5597 13.3578V30.6431Z" fill="black" />
        </svg>
    );
};