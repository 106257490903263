import React from "react";

const Calendar = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M2.99987 0C2.76317 0 2.57129 0.191878 2.57129 0.428579V1.28571H3.42842V0.428579C3.42842 0.191878 3.23654 0 2.99987 0Z"
          fill="#5806B9"
        />
        <path
          d="M8.99987 0C8.76317 0 8.57129 0.191878 8.57129 0.428579V1.28571H9.42842V0.428579C9.42845 0.191878 9.23657 0 8.99987 0Z"
          fill="#5806B9"
        />
        <path
          d="M10.7143 1.28564H9.42857V2.99994C9.42857 3.23664 9.2367 3.42852 8.99999 3.42852C8.76329 3.42852 8.57141 3.23664 8.57141 2.99994V1.28564H3.42856V2.99994C3.42856 3.23664 3.23668 3.42852 2.99998 3.42852C2.76328 3.42852 2.5714 3.23664 2.5714 2.99994V1.28564H1.28571C0.575633 1.28564 0 1.86128 0 2.57136V10.7142C0 11.4243 0.575633 11.9999 1.28571 11.9999H10.7143C11.4244 11.9999 12 11.4243 12 10.7142V2.57136C12 1.86128 11.4244 1.28564 10.7143 1.28564ZM11.1428 10.7142C11.1428 10.9509 10.951 11.1428 10.7143 11.1428H1.28571C1.04901 11.1428 0.857134 10.9509 0.857134 10.7142V5.14279H11.1428V10.7142Z"
          fill="#5806B9"
        />
        <path
          d="M8.87474 6.12566C8.7074 5.95819 8.43599 5.95811 8.26852 6.12543L5.14296 9.25102L3.73166 7.83972C3.56419 7.67238 3.29279 7.67248 3.12544 7.83995C2.9581 8.00742 2.9582 8.27883 3.12567 8.44617L4.83996 10.1605C5.00733 10.3278 5.27861 10.3278 5.44595 10.1605L8.87454 6.73188C9.04198 6.56453 9.04208 6.29313 8.87474 6.12566Z"
          fill="#5806B9"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Calendar;
