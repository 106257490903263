export const TOKEN_ENUM = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  GUEST_ACCESS_TOKEN: "guest_access_token",
  RENTER_DRAFT_TOKEN: "renterDraft_token",
};

export const setAccessToken = (token: string, remember = false) => {
  return localStorage.setItem(TOKEN_ENUM.ACCESS_TOKEN, token);
};

export const setRefreshToken = (token: string, remember = false) => {
  return localStorage.setItem(TOKEN_ENUM.REFRESH_TOKEN, token);
};

export const setGuestAccessToken = (token: string) => {
  return localStorage.setItem(TOKEN_ENUM.GUEST_ACCESS_TOKEN, token);
};

export const clearLocalStorage = () => {
  //   localStorage.clear(); // Need to maintain some items so be explicit below
  localStorage.removeItem(TOKEN_ENUM.ACCESS_TOKEN);
  localStorage.removeItem(TOKEN_ENUM.ACCESS_TOKEN);
  localStorage.removeItem("user_id");
  localStorage.removeItem("listingViewType");
  localStorage.removeItem("shouldUpdateProfile");
  localStorage.removeItem("agency_id");
  localStorage.removeItem("plan");
  localStorage.removeItem("sidebarParams");
  localStorage.removeItem("switchTeamSnoozed");
};

export const getAuthToken = () => {
  return localStorage.getItem(TOKEN_ENUM.ACCESS_TOKEN);
};

export const getGuestAccessToken = () => {
  return localStorage.getItem(TOKEN_ENUM.GUEST_ACCESS_TOKEN);
};

export const getRefreshToken = () => {
  return localStorage.getItem(TOKEN_ENUM.REFRESH_TOKEN);
};

export const getDraftRenterToken = () => {
  return localStorage.getItem(TOKEN_ENUM.RENTER_DRAFT_TOKEN);
};
