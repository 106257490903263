import React from "react";

const ListingHistoryOpenCollapseIcon = () =>{
    return(
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="15" height="15" rx="1" fill="#5806B9"/>
            <line x1="7.5" y1="3" x2="7.5" y2="12" stroke="white"/>
            <line x1="12" y1="7.5" x2="3" y2="7.5" stroke="white"/>
        </svg>

    )
}

export default ListingHistoryOpenCollapseIcon