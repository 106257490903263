import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import {
  GET_AGENTS_SCREENING_REQUEST,
  GET_TENANT_SCREENING_REQUESTS,
} from "./types";
import {
  getAgentSrRequestSuccess,
  getAgentSrRequestFail,
  getAgentAllSrRequestSuccess,
  getAgentAllSrRequestFail,
} from "./action";
import ShareableApi from "../../api/Shareable";

function* getAgentsScreeningRequestSaga({ payload }: any): any {
  try {
    const response = yield call(ShareableApi.getAgentsScreeningRequest, payload);
    yield put(getAgentSrRequestSuccess(response));
  } catch (e) {
    yield put(getAgentSrRequestFail(e));
  }
}

function* getTenantScreeningRequestsSaga({ payload }: any): any {
  try {
    const response = yield call(
      ShareableApi.getTenantScreeningRequests,
      payload
    );
    yield put(getAgentAllSrRequestSuccess(response));
  } catch (e) {
    yield put(getAgentAllSrRequestFail(e));
  }
}

function* watchGetAgentsScreeningRequestSaga() {
  yield takeLatest(GET_AGENTS_SCREENING_REQUEST, getAgentsScreeningRequestSaga);
}

function* watchGetTenantScreeningRequestsSaga() {
  yield takeLatest(
    GET_TENANT_SCREENING_REQUESTS,
    getTenantScreeningRequestsSaga
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAgentsScreeningRequestSaga),
    fork(watchGetTenantScreeningRequestsSaga),
  ]);
}
