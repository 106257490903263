import { Dropdown } from "antd";
import { DropdownDotsIcon } from "components/icons";
import Triangle from "components/icons/triangle/Triangle";
import BaseButton from "components/Shared/BaseButton";
import { IObjectKeys } from "helpers/globalTypes";
import React, { memo, useState } from "react";
import "./styles.scss";
import ContactModal from "../../../../Shared/ContactModal/ContactModal";
import { AnalyticsService } from "helpers/analyticsService";

interface IMenuProps {
  agent: IObjectKeys;
  requirementId: string;
  status: string;
  handleAction: (e: any, props: IObjectKeys) => void;
  closePopup: any;
}
const Menu = (props: IMenuProps) => {
  return (
    <div className="permission-menu" id="permissonMenuId">
      <Triangle customClass="triangle" />
      <ul className="permission-menu-list" onClick={(e) => props.handleAction(e, props)}>
        {props.status === "Pending" ? (
          <>
            <li
              className="permission-menu-item"
              data-action-name="Approve"
              onClick={() => {
                props.closePopup(false);
              }}
            >
              Approve
            </li>
            <li
              className="permission-menu-item"
              data-action-name="Decline"
              onClick={() => {
                props.closePopup(false);
              }}
            >
              Decline
            </li>
          </>
        ) : (
          <li
            className="permission-menu-item"
            data-action-name="Revoke"
            onClick={() => {
              props.closePopup(false);
            }}
          >
            Remove
          </li>
        )}
      </ul>
    </div>
  );
};

interface LeadPermissionItemProps {
  agent: any;
  requirementId: string;
  handleAction: (e: any, props: IObjectKeys) => void;
}

const LeadPermissionItem = ({ agent, requirementId, handleAction }: LeadPermissionItemProps) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [isOpenContactModal, setIsOpenContactModal] = useState(false);
  const statusClassName = ["col_content text"];

  switch (agent.status) {
    case "Accepted":
      statusClassName.push("accepted");
      break;
    case "Pending":
      statusClassName.push("pending");
  }

  return (
    <>
      <div className="lead-permission-item">
        <div className="row">
          <div className="col left">
            <span className="col_content">{agent.name}</span>
          </div>
          <div className="col right">
            <span className={statusClassName.join(" ")}>{agent.status}</span>
            <span className="col_content">
              <BaseButton
                classnames={"ant-btn rb-secondary-btn rb-secondary-btn-without-shadow contact-btn"}
                type={"button"}
                dataTestId={"landlord-quick-contact-modal-open-from-listing"}
                onClick={() => {
                  !isOpenContactModal && AnalyticsService.reportEvent("contact_agent_modal_opened");
                  setIsOpenContactModal(true);
                }}
              >
                Contact
              </BaseButton>
            </span>
            <span className="col_content dots">
              <Dropdown
                trigger={["click"]}
                placement={"bottomCenter"}
                overlay={
                  <Menu
                    status={agent.status}
                    agent={agent}
                    requirementId={requirementId}
                    handleAction={handleAction}
                    closePopup={setPopupVisible}
                  />
                }
                data-test-id="lead-permission-menu-dropdown"
                visible={popupVisible}
                onVisibleChange={() => {
                  setPopupVisible((prev) => !prev);
                }}
                getPopupContainer={() => {
                  return document.querySelector(`.permissonMenuBtn`) as HTMLElement;
                }}
              >
                <BaseButton
                  classnames="btn permissonMenuBtn"
                  onClick={() => {
                    !popupVisible && AnalyticsService.reportEvent("connected_agents_3_dots_menu_opened");
                    setPopupVisible((prev) => !prev);
                  }}
                >
                  <DropdownDotsIcon color={"#C4C4C4"} />
                </BaseButton>
              </Dropdown>
            </span>
          </div>
        </div>
      </div>

      {isOpenContactModal && (
        <ContactModal
          currentLeadData={agent}
          showModal={true}
          onClose={setIsOpenContactModal}
          currentLandlordData={null}
        />
      )}
    </>
  );
};
export default memo(LeadPermissionItem);
