import { Avatar, Form, Select } from "antd";
import BaseButton from "components/Shared/BaseButton";
import BaseModal from "components/Shared/BaseModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./NotifyAgentsModal.scss";
import { IObjectKeys } from "helpers/globalTypes";
import { useWindowSize } from "hooks/useWindowSize";
import { singleLeadDataSelector } from "store/leads/selectors";
import InfoModal from "../info-modal/InfoModal";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import {
  notifyAgents,
  setNotifyAgentSuccesstoFalse,
} from "store/requirement/action";
import {
  notifyAgentsLoadingSelector,
  notifyAgentsSuccessSelector,
} from "store/requirement/selectors";
import { UserOutlined } from "@ant-design/icons";

const NotifyAgentsModal = ({ toggle = false, setToggle }: IObjectKeys) => {
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const [isSuccess, setIsSuccess] = useState(false);
  const leadData = useSelector(singleLeadDataSelector);
  const notifyAgentsSuccess = useSelector(notifyAgentsSuccessSelector);
  const notifyAgentsLoading = useSelector(notifyAgentsLoadingSelector);
  const dispatch = useDispatch();
  const [checkedAgents, setCheckedAgents] = useState([] as CheckboxValueType[]);

  useEffect(() => {
    if (isSuccess) {
      setToggle(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (notifyAgentsSuccess) {
      dispatch(setNotifyAgentSuccesstoFalse());
      setIsSuccess(true);
      form.resetFields();
    }
  }, [notifyAgentsSuccess]);

  const handleCancel = () => {
    setToggle(false);
  };
  const handleApprove = ({ checkedAgents }: { checkedAgents: string[]; }) => {
    dispatch(notifyAgents(checkedAgents));
  };

  const handleChange = (value: string[]) => {
    setCheckedAgents(value);
  };

  useEffect(() => {
    const content = document.querySelector(
      ".ant-select-selection-overflow-item-rest .ant-select-selection-item-content"
    );
    if (content) {
      content.textContent = content?.innerHTML.split(".")[0];
    }
  }, [checkedAgents]);

  return (
    <>
      <BaseModal
        isModalVisible={toggle}
        modalFooter={null}
        width={width && width < 600 ? 308 : 389}
        onCancel={handleCancel}
        modalTitle=""
        classname="notify-agents-wrapper"
      >
        <div className="notify-modal flex flex-column align-center mt-25">
          <h3 className="fw-600 pb-3 text-center">
            Which agents would you like to notify?
          </h3>
          <p className="font-16 text-center otify-modal-text">
            We'll send a notification to the selected agents so they can help
            find your next home!
          </p>
          <div className="w-100">
            <Form
              layout={"vertical"}
              form={form}
              initialValues={{
                layout: "vertical",
              }}
              onFinish={handleApprove}
            >
              <Form.Item
                name="checkedAgents"
                rules={[{ required: true, message: "Please select agent" }]}
              >
                <Select
                  mode="multiple"
                  onChange={handleChange}
                  optionLabelProp="label"
                  open={toggle}
                  placeholder="Notify..."
                  listHeight={180}
                  maxTagCount={width && width < 600 ? 1 : 2}
                  getPopupContainer={(triggerNode: { parentNode: any; }) =>
                    triggerNode.parentNode
                  }
                  showSearch={false}
                >

                </Select>
              </Form.Item>
              <div className="agent-modal-buttons text-center">
                <BaseButton
                  classnames="font-16 rb-primary-btn md w-100"
                  type="submit"
                  loading={notifyAgentsLoading}
                >
                  Submit
                </BaseButton>
              </div>
            </Form>
          </div>
        </div>
      </BaseModal>
      <InfoModal
        header="Sent!"
        text="Your approved agents have been notified and are starting to look for your next home."
        setToggle={setIsSuccess}
        toggle={isSuccess}
      />
    </>
  );
};

export default NotifyAgentsModal;
