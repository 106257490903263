import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import {
  ADD_FOLLOW_UP,
  DELETE_FOLLOW_UP,
  EDIT_FOLLOW_UP,
  GET_FOLLOW_UPS, GET_FU_BY_ID,
  GET_LANDLORD_FOLLOWUPS,
  GET_LEAD_FOLLOWUPS,
  GET_LISTING_FOLLOWUPS,
  SNOOZE_FOLLOW_UP,
  UPDATE_FOLLOW_UP
} from "./types";
import {
  getFollowUpsListSuccess,
  getFollowUpsListFail,
  updateFollowUpSuccess,
  updateFollowUpFail,
  addFollowUpSuccess,
  addFollowUpFail,
  deleteFollowUpSuccess,
  deleteFollowUpFail,
  editFollowUpSuccess,
  editFollowUpFail,
  getListingFollowUpsSuccess,
  getListingFollowUpsFail,
  getLandlordFollowUpsSuccess,
  getLandlordFollowUpsFail,
  snoozeFollowUpSuccess,
  snoozeFollowUpFail,
  getLeadFollowUpsSuccess,
  getLeadFollowUpsFail, getFUByIdFail, getFUByIdSuccess
} from "./action";
import FollowUpsApi from "../../api/FollowUps";
import { generateErrorMessage, openNotification } from "../../helpers/globalFunctions";
import { AnalyticsService } from "helpers/analyticsService";

function* getFollowUpsListSaga({ payload }: any) {
  try {
    const response = yield call(FollowUpsApi.getAllFollowUps, payload);
    yield put(getFollowUpsListSuccess({ ...response, fillAll: payload.fillAll }));
  } catch (e) {
    yield put(getFollowUpsListFail(e));
    openNotification("error", generateErrorMessage(e));
  }
}

function* getFollowUpByIdSaga({ payload }: any) {
  try {
    const response = yield call(FollowUpsApi.singleFollowUpById, payload);
    yield put(getFUByIdSuccess(response));
  } catch (e) {
    yield put(getFUByIdFail(e));
    openNotification("error", generateErrorMessage(e));
  }
}

function* updateFollowUpSaga({ payload }: any) {
  try {
    const response = yield call(FollowUpsApi.updateFollowUp, payload);
    yield put(updateFollowUpSuccess(response));
  } catch (e) {
    yield put(updateFollowUpFail(e));
    openNotification("error", generateErrorMessage(e));
  }
}

function* deleteFollowUpSaga({ payload }: any) {
  try {
    const response = yield call(FollowUpsApi.deleteFollowUp, payload);
    yield put(deleteFollowUpSuccess(payload));
  } catch (e) {
    yield put(deleteFollowUpFail(e));
    // openNotification("error", generateErrorMessage(e));
  }
}

function* getListingFollowUpsSaga({ payload }: any): any {
  try {
    const response = yield call(
      FollowUpsApi.getListingFollowUps,
      `${payload}?notable_type=listing_note&past_due=true&todo_status=in_progress`
    );
    yield put(getListingFollowUpsSuccess(response.followUps));
  } catch (e) {
    yield put(getListingFollowUpsFail(e));
  }
}

function* getLandlordFollowUpsSaga({ payload }: any): any {
  try {
    const response = yield call(
      FollowUpsApi.getLandlordFollowUps,
      `${payload}?notable_type=landlord_note&todo_status=in_progress`
    );
    yield put(getLandlordFollowUpsSuccess(response.followUps));
  } catch (e) {
    yield put(getLandlordFollowUpsFail(e));
  }
}

function* getLeadFollowUpsSaga({ payload }: any): any {
  try {
    const response = yield call(
      FollowUpsApi.getLeadFollowUps,
      `${payload}?notable_type=renter_note&todo_status=in_progress`
    );
    yield put(getLeadFollowUpsSuccess(response.followUps));
  } catch (e) {
    yield put(getLeadFollowUpsFail(e));
  }
}

function* addFollowUpSaga({ payload }: any): any {
  try {
    const response = yield call(FollowUpsApi.addFollowUp, payload);
    yield put(addFollowUpSuccess(response));
    AnalyticsService.reportEvent("follow_up_added_success", {
      category: "follow-ups"
    });
  } catch (e) {
    yield put(addFollowUpFail(e));
    AnalyticsService.reportEvent("follow_up_added_failure", {
      category: "follow-ups",
      errorMessage: e
    });
  }
}

function* editFollowUpSaga({ payload }: any): any {
  try {
    const response = yield call(FollowUpsApi.editFollowUp, payload.payload, payload._id);
    yield put(editFollowUpSuccess(response));
  } catch (e) {
    yield put(editFollowUpFail(e));
  }
}

function* snoozeFollowUpSaga({ payload }: any): any {
  try {
    const response = yield call(FollowUpsApi.editFollowUp, payload.payload, payload._id);
    yield put(snoozeFollowUpSuccess(response));
  } catch (e) {
    yield put(snoozeFollowUpFail(e));
  }
}

function* watchGetListingFollowUpsSaga() {
  yield takeLatest(GET_LISTING_FOLLOWUPS, getListingFollowUpsSaga);
}

function* watchGetFollowUpsListSaga() {
  yield takeLatest(GET_FOLLOW_UPS, getFollowUpsListSaga);
}

function* watchGetFollowUpByIdSaga() {
  yield takeLatest(GET_FU_BY_ID, getFollowUpByIdSaga);
}

function* watchGetLandlordFollowUpsListSaga() {
  yield takeLatest(GET_LANDLORD_FOLLOWUPS, getLandlordFollowUpsSaga);
}

function* watchGetLeadFollowUpsListSaga() {
  yield takeLatest(GET_LEAD_FOLLOWUPS, getLeadFollowUpsSaga);
}

function* watchUpdateFollowUpSaga() {
  yield takeLatest(UPDATE_FOLLOW_UP, updateFollowUpSaga);
}

function* watchDeleteFollowUpSaga() {
  yield takeLatest(DELETE_FOLLOW_UP, deleteFollowUpSaga);
}

function* watchAddFollowUpSaga() {
  yield takeLatest(ADD_FOLLOW_UP, addFollowUpSaga);
}

function* watchEditFollowUpSaga() {
  yield takeLatest(EDIT_FOLLOW_UP, editFollowUpSaga);
}

function* watchSnoozeFollowUpSaga() {
  yield takeLatest(SNOOZE_FOLLOW_UP, snoozeFollowUpSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetFollowUpsListSaga),
    fork(watchUpdateFollowUpSaga),
    fork(watchAddFollowUpSaga),
    fork(watchDeleteFollowUpSaga),
    fork(watchEditFollowUpSaga),
    fork(watchGetListingFollowUpsSaga),
    fork(watchGetLandlordFollowUpsListSaga),
    fork(watchSnoozeFollowUpSaga),
    fork(watchGetLeadFollowUpsListSaga),
    fork(watchGetFollowUpByIdSaga)
  ]);
}
