import * as Contentful from "contentful";
import { contentfulAccessKey, contentfulSpaceId } from "../helpers/constants";
export const client = Contentful.createClient({
  space: contentfulSpaceId,
  accessToken: contentfulAccessKey
});

export const getUnitTypeToolTipContent = async () => {
  return await client.getEntry("4hEEfUxillWQmmczJuNxHD");
};

export const getDeleteNoteConfirmContent = async () => {
  return await client.getEntry("3iQrf9eA9M7xyeQ044Ozko");
};

export const getPrivacyPolicyContent = async () => {
  return await client.getEntry("7cBhLtcsYzIeyWPiprgMYp");
};

export const getTermsAndConditionsContent = async () => {
  return await client.getEntry("4WfIL3Ogt23e3XSOnO7UAN");
};

export const getTabsWalkthroughContent = async () => {
  return client.getEntry("4KTW7PGNQKM94PliRqLG0u");
};
