import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";

const GuestHeader = () => {
  const dispatch = useDispatch();
  return (
    <header>
      <div className="flex justify-start p-2 sm:p-4">
        <div onClick={() => dispatch(push("/"))}>
          <img src="/rentbase-logo.png" className="h-16" />
        </div>
      </div>
    </header>
  );
};

export default GuestHeader;
