import { useDemoContext } from "libs/contextLib";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import FollowUps from "../../components/FollowUps";

const FollowUpPage = () => {
  const history = useHistory();
  const { isDemo } = useDemoContext();

  useEffect(() => {
    if (isDemo) history.push("/demo/followUps");
  }, [history, isDemo]);
  return <FollowUps />;
};

export default FollowUpPage;
