import React, {useEffect, useState} from "react";
import { Input, Modal, Typography } from "antd";
import { useSelector } from "react-redux";
import { updateUserSettingsLoadingSelector } from "../../../store/user/selectors";

const PasswordConfirmModal = ({
  visible,
  onClose,
  onSubmit,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmit: (password: string) => void;
}) => {
  const updateUserSettingsLoading = useSelector(
    updateUserSettingsLoadingSelector
  );
  const [userPassword, setUserPassword] = useState("");
  useEffect(() => {
    setUserPassword('');
  }, [visible]);
  return (
    <Modal
      visible={visible}
      title={"Please verify password"}
      onOk={() => {
        onSubmit(userPassword);
      }}
      confirmLoading={updateUserSettingsLoading}
      onCancel={() => {
        onClose();
      }}
    >
      <Typography.Text>
        If you want to change your email you should type your password.
      </Typography.Text>
      <Input.Password
        className={"mt-5"}
        value={userPassword}
        onChange={({ target: { value } }) => {
          setUserPassword(value);
        }}
      />
    </Modal>
  );
};

export default PasswordConfirmModal;
