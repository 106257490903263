import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

interface PaginationInfo {
  current: number;
  pageSize: number;
}

interface Props {
  total: number;
  pagination: PaginationInfo;
  onPageChange: (page: number) => void;
}

export const ShowingListingCount = ({
  total,
  current,
  pageSize,
}: {
  total: number;
  current: number;
  pageSize: number;
}) => {
  return (
    <>
      Showing <span className="font-medium">{(current - 1) * pageSize + 1}</span> to{" "}
      <span className="font-medium">{Math.min(current * pageSize, total)}</span> of{" "}
      <span className="font-medium">{total}</span> results
    </>
  );
};

const Pagination = ({ total, pagination, onPageChange }: Props) => {
  const { current, pageSize } = pagination;
  const totalPages = Math.ceil(total / pageSize);

  // This function generates an array of page numbers to display.
  const generatePageNumbers = () => {
    const maxPageButtonsToShow = totalPages <= 5 ? totalPages : 5;
    const pageNumbers = [];
    const halfButtonsToShow = Math.floor(maxPageButtonsToShow / 2);

    let startPage = Math.max(1, current - halfButtonsToShow);
    const endPage = Math.min(totalPages, startPage + maxPageButtonsToShow - 1);

    if (totalPages - current < halfButtonsToShow) {
      startPage = totalPages - maxPageButtonsToShow + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const handlePageClick = (page: number) => {
    onPageChange(page);
  };
  // Generate the array of page numbers.
  const pageNumbers = generatePageNumbers();

  // Don't render pagination for sub 2 pages
  if (totalPages < 2) return null;

  return (
    <div className="w-full items-center text-center">
      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <a
          href="#"
          onClick={() => handlePageClick(current - 1)}
          className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
            current === 1 ? "cursor-not-allowed" : ""
          }`}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </a>
        {pageNumbers.map((page) => (
          <a
            href="#"
            key={page}
            onClick={() => handlePageClick(page)}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:outline-offset-0 ${
              page === current ? "bg-purple-500 text-white" : "bg-white"
            }`}
          >
            {page}
          </a>
        ))}
        <a
          href="#"
          onClick={() => handlePageClick(current + 1)}
          className={
            "relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          }
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </a>
      </nav>
    </div>
  );
};

export default Pagination;
