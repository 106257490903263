export const FILL_LISTING_DATA = "FILL_LISTING_DATA";
export const START_FILL_LISTING_DATA_IMAGES = "START_FILL_LISTING_DATA_IMAGES";
export const FILL_LISTING_DATA_IMAGES = "FILL_LISTING_DATA_IMAGES";
export const TRIGGER_AFTER_LISTING_SAVE_MODAL =
  "TRIGGER_AFTER_LISTING_SAVE_MODAL";
export const SET_LISTING_SAVE_CLICKED = "SET_LISTING_SAVE_CLICKED";
export const CLEAR_LISTING_DATA = "CLEAR_LISTING_DATA";
export const CLEAR_LISTING_ADD_STATE = "CLEAR_LISTING_ADD_STATE";
export const CLEAR_LISTING_UPDATE_STATE = "CLEAR_LISTING_UPDATE_STATE";

export const ADD_LISTING = "ADD_LISTING";
export const ADD_LISTING_SUCCESS = "ADD_LISTING_SUCCESS";
export const ADD_LISTING_FAIL = "ADD_LISTING_FAIL";

export const UPDATE_LISTING = "UPDATE_LISTING";
export const UPDATE_LISTING_SUCCESS = "UPDATE_LISTING_SUCCESS";
export const UPDATE_LISTING_FAIL = "UPDATE_LISTING_FAIL";

export const GET_LISTINGS = "GET_LISTINGS";
export const GET_LISTINGS_SUCCESS = "GET_LISTINGS_SUCCESS";
export const GET_LISTINGS_FAIL = "GET_LISTINGS_FAIL";

export const SET_LISTING = "SET_LISTING";
export const SET_LISTINGS = "SET_LISTINGS";

export const GET_ONE_LISTING = "GET_ONE_LISTING";
export const GET_ONE_LISTING_SUCCESS = "GET_ONE_LISTING_SUCCESS";
export const GET_ONE_LISTING_FAIL = "GET_ONE_LISTING_FAIL";

export const GET_ONE_LISTING_FROM_PUBLIC_ID = "GET_ONE_LISTING_FROM_PUBLIC_ID";

export const ADD_LISTING_NOTE = "ADD_LISTING_NOTE";
export const ADD_LISTING_NOTE_SUCCESS = "ADD_LISTING_NOTE_SUCCESS";
export const ADD_LISTING_NOTE_FAIL = "ADD_LISTING_NOTE_FAIL";

export const UPDATE_LISTING_NOTE = "UPDATE_LISTING_NOTE";
export const UPDATE_LISTING_NOTE_SUCCESS = "UPDATE_LISTING_NOTE_SUCCESS";
export const UPDATE_LISTING_NOTE_FAIL = "UPDATE_LISTING_NOTE_FAIL";

export const UPDATE_LISTING_PUBLISH_STATE = "UPDATE_LISTING_PUBLISH_STATE";
export const UPDATE_LISTING_PUBLISH_STATE_SUCCESS = "UPDATE_LISTING_PUBLISH_STATE_SUCCESS";
export const UPDATE_LISTING_PUBLISH_STATE_FAIL = "UPDATE_LISTING_PUBLISH_STATE_FAIL";

export const DELETE_LISTING_NOTE = "DELETE_LISTING_NOTE";
export const DELETE_LISTING_NOTE_SUCCESS = "DELETE_LISTING_NOTE_SUCCESS";
export const DELETE_LISTING_NOTE_FAIL = "DELETE_LISTING_NOTE_FAIL";

export const GET_LISTING_NOTES = "GET_LISTING_NOTES";
export const GET_LISTING_NOTES_SUCCESS = "GET_LISTING_NOTES_SUCCESS";
export const GET_LISTING_NOTES_FAIL = "GET_LISTING_NOTES_FAIL";

export const DELETE_LISTING = "DELETE_LISTING";
export const DELETE_LISTING_SUCCESS = "DELETE_LISTING_SUCCESS";
export const DELETE_LISTING_FAIL = "DELETE_LISTING_FAIL";

export const DELETE_LISTING_IMAGE = "DELETE_LISTING_IMAGE";
export const DELETE_LISTING_IMAGE_SUCCESS = "DELETE_LISTING_IMAGE_SUCCESS";
export const DELETE_LISTING_IMAGE_FAIL = "DELETE_LISTING_IMAGE_FAIL";

export const SET_LISTINGS_LAST_QUERY = "SET_LISTINGS_LAST_QUERY";

export const UPLOAD_CSV_LISTINGS = "UPLOAD_CSV_LISTINGS";
export const UPLOAD_CSV_LISTINGS_SUCCESS = "UPLOAD_CSV_LISTINGS_SUCCESS";
export const UPLOAD_CSV_LISTINGS_FAIL = "UPLOAD_CSV_LISTINGS_FAIL";

export const DOWNLOAD_CSV_TEMPLATE = "DOWNLOAD_CSV_TEMPLATE";
export const DOWNLOAD_CSV_TEMPLATE_SUCCESS = "DOWNLOAD_CSV_TEMPLATE_SUCCESS";
export const DOWNLOAD_CSV_TEMPLATE_FAIL = "DOWNLOAD_CSV_TEMPLATE_FAIL";

export const GET_LISTINGS_FORM_ALGOLIA = "GET_LISTINGS_FORM_ALGOLIA";
export const GET_LISTINGS_FORM_ALGOLIA_SUCCESS =
  "GET_LISTINGS_FORM_ALGOLIA_SUCCESS";
export const GET_LISTINGS_FORM_ALGOLIA_FAIL = "GET_LISTINGS_FORM_ALGOLIA_FAIL";
export const CLEAR_LISTINGS_FORM_ALGOLIA = "CLEAR_LISTINGS_FORM_ALGOLIA";
export const CLEAR_UPLOAD_CSV_ERRORS = "CLEAR_UPLOAD_CSV_ERRORS";
export const CLEAR_PARTICULAR_CSV_ERROR = "CLEAR_PARTICULAR_CSV_ERROR";

export const SET_STEP_CLICKED = "SET_STEP_CLICKED";

export const SET_LISTINGS_LOADING_TO_TRUE = "SET_LISTINGS_LOADING_TO_TRUE";
export const SET_LISTINGS_LOADING_TO_FALSE = "SET_LISTINGS_LOADING_TO_FALSE";

export const ALGOLIA_SEARCH_TOGGLE = "ALGOLIA_SEARCH_TOGGLE";

export const SET_LISTINGS_VIEW_TYPE = "SET_LISTINGS_VIEW_TYPE";
export const SET_LISTINGS_FILTER_OPTIONS = "SET_LISTINGS_FILTER_OPTIONS";
export const SET_LISTINGS_PAGINATION_VALUES = "SET_LISTINGS_PAGINATION_VALUES";

export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_LISTING_IMAGE_MODAL_CURRENT_IMAGE_INDEX =
  "SET_LISTING_IMAGE_MODAL_CURRENT_IMAGE_INDEX";
export const SET_MUST_HAVES_SWITCH_INPUT_VALUES =
  "SET_MUST_HAVES_SWITCH_INPUT_VALUES";
export const SET_LISTING_DETAILS_SWITCH_INPUT_VALUES =
  "SET_LISTING_DETAILS_SWITCH_INPUT_VALUES";

export const IS_CURRENT_FORM_CHANGED = "IS_CURRENT_FORM_CHANGED";
export const IS_ASAP_CLICKED = "IS_ASAP_CLICKED";
export const SET_LISTING_NOTES_SUCCESS = "SET_LISTING_NOTES_SUCCESS";

