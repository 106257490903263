import React, { useEffect, useState } from "react";
import "./EmptyStateAddListingsHeader.scss";
import { useLocation } from "react-router";

const EmptyPageListingHeader = (props: any) => {
  const isEmptyState = props.isEmptyState;
  const location = useLocation();
  const defaultStateText = {
    title: "Add a Listing",
    subTitle: {
      firstLine: "Add a listing by filling out its information",
    },
  };

  const emptyStateText = {
    title: "Ready to add your first listing?",
    subTitle: {
      firstLine: "Start automating your rental business by adding a property.",
    },
  };

  const [copyText, setCopyText] = useState(defaultStateText);

  useEffect(() => {
    location.search.includes("fab") && isEmptyState ? setCopyText(defaultStateText) : setCopyText(emptyStateText);
  }, [isEmptyState, location]);

  return (
    <div className={"onboard-a-lead-header empty-subject-page-wrapper-header"}>
      <h1>{copyText?.title}</h1>
      {isEmptyState && (
        <>
          <p className={"onboard-a-lead-header--secondaryTextBottomListings"}>{copyText?.subTitle.firstLine}</p>
        </>
      )}
    </div>
  );
};

export default EmptyPageListingHeader;
