export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";
export const SIGN_UP_AGENT = "SIGN_UP_AGENT";
export const SIGN_UP_AGENT_SUCCESS = "SIGN_UP_AGENT_SUCCESS";
export const SIGN_UP_AGENT_FAIL = "SIGN_UP_AGENT_FAIL";
export const SET_SIGN_UP_SUCCESS = "SET_SIGN_UP_SUCCESS";
export const GET_LOGGED_IN_USER = "GET_LOGGED_IN_USER";
export const GET_LOGGED_IN_USER_SUCCESS = "GET_LOGGED_IN_USER_SUCCESS";
export const GET_LOGGED_IN_USER_FAIL = "GET_LOGGED_IN_USER_FAIL";

export const FORCE_RESET_PASSWORD = "FORCE_RESET_PASSWORD";
export const FORCE_RESET_PASSWORD_SUCCESS = "FORCE_RESET_PASSWORD_SUCCESS";
export const FORCE_RESET_PASSWORD_FAIL = "FORCE_RESET_PASSWORD_FAIL";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const CONFIRM_RESET_PASSWORD = "CONFIRM_RESET_PASSWORD";
export const CONFIRM_RESET_PASSWORD_SUCCESS = "CONFIRM_RESET_PASSWORD_SUCCESS";
export const CONFIRM_RESET_PASSWORD_FAIL = "CONFIRM_RESET_PASSWORD_FAIL";

export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";
export const UPDATE_USER_SETTINGS_FAIL = "UPDATE_USER_SETTINGS_FAIL";

export const GET_PHONE_VERIFICATION_CODE = "GET_PHONE_VERIFICATION_CODE";
export const GET_PHONE_VERIFICATION_CODE_SUCCESS =
  "GET_PHONE_VERIFICATION_CODE_SUCCESS";
export const GET_PHONE_VERIFICATION_CODE_FAIL =
  "GET_PHONE_VERIFICATION_CODE_FAIL";

export const GET_LEAD_PHONE_VERIFICATION_CODE = "GET_LEAD_PHONE_VERIFICATION_CODE";
export const GET_LEAD_PHONE_VERIFICATION_CODE_SUCCESS =
  "GET_LEAD_PHONE_VERIFICATION_CODE_SUCCESS";
export const GET_LEAD_PHONE_VERIFICATION_CODE_FAIL =
  "GET_LEAD_PHONE_VERIFICATION_CODE_FAIL";

export const VERIFY_PHONE_NUMBER = "VERIFY_PHONE_NUMBER";
export const VERIFY_PHONE_NUMBER_SUCCESS = "VERIFY_PHONE_NUMBER_SUCCESS";
export const VERIFY_PHONE_NUMBER_FAIL = "VERIFY_PHONE_NUMBER_FAIL";

export const VERIFY_LEAD_PHONE_NUMBER = "VERIFY_LEAD_PHONE_NUMBER";
export const VERIFY_LEAD_PHONE_NUMBER_SUCCESS = "VERIFY_LEAD_PHONE_NUMBER_SUCCESS";
export const VERIFY_LEAD_PHONE_NUMBER_FAIL = "VERIFY_LEAD_PHONE_NUMBER_FAIL";

export const CLEAR_USER_STATES = "CLEAR_USER_STATES";

export const SET_AUTH_LOADING_TO_TRUE = "SET_AUTH_LOADING_TO_TRUE";
export const SET_AUTH_LOADING_TO_FALSE = "SET_AUTH_LOADING_TO_FALSE";

export const DELETE_USER_IMAGE_SUCCESS = "DELETE_USER_IMAGE_SUCCESS";
export const UPLOAD_USER_IMAGE = "UPLOAD_USER_IMAGE";

export const SET_USER_PASSWORD = "SET_USER_PASSWORD";

export const SET_VERIFY_USER_PHONE_SUCCESS = "SET_VERIFY_USER_PHONE_SUCCESS";
export const SET_VERIFICATION_CODE_LOADING = "SET_VERIFICATION_CODE_LOADING";
export const SET_LEAD_PAGE_SHARED = "SET_LEAD_PAGE_SHARED";

export const SET_UPDATED_USER_SETTINGS_SUCCESS_TO_FALSE = "SET_UPDATED_USER_SETTINGS_SUCCESS_TO_FALSE";

export const EXPORT_USER_DATA = "EXPORT_USER_DATA";
export const EXPORT_USER_DATA_SUCCESS = "EXPORT_USER_DATA_SUCCESS";
export const EXPORT_USER_DATA_FAIL = "EXPORT_USER_DATA_FAIL";
export const SET_EXPORT_USER_DATA_SUCCESS = "SET_EXPORT_USER_DATA_SUCCESS";

export const SET_VERIFY_PHONE_SUCCESS = "SET_VERIFY_PHONE_SUCCESS";
export const VERIFY_BY_EMAIL_LINK = "VERIFY_BY_EMAIL_LINK";
export const VERIFY_BY_EMAIL_LINK_SUCCESS = "VERIFY_BY_EMAIL_LINK_SUCCESS";
export const VERIFY_BY_EMAIL_LINK_FAIL = "VERIFY_BY_EMAIL_LINK_FAIL";

export const SET_LOCATION = "SET_LOCATION";

export const GET_REFERRAL_AGENT = "GET_REFERRAL_AGENT";
export const GET_REFERRAL_AGENT_SUCCESS = "GET_REFERRAL_AGENT_SUCCESS";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";
export const CLEAR_UPDATE_USER_PROFILE = "CLEAR_UPDATE_USER_PROFILE";

export const UPDATE_USER_TEAM_NAME = "UPDATE_USER_TEAM_NAME";

export const UPDATE_USER_AFTER_SWITCH = "UPDATE_USER_AFTER_SWITCH";
export const UPDATE_USER_AFTER_SWITCH_SUCCESS = "UPDATE_USER_AFTER_SWITCH_SUCCESS";
export const UPDATE_USER_AFTER_SWITCH_FAIL = "UPDATE_USER_AFTER_SWITCH_FAIL";
export const CLEAR_UPDATE_USER_AFTER_SWITCH = "CLEAR_UPDATE_USER_AFTER_SWITCH";
