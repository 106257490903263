import React, { useEffect } from 'react';
import { COGNITO_CLAW_LOGOUT_ENDPOINT } from '../../helpers/constants';
import { clearLocalStorage } from "../../helpers/authUtils";
import { Spin } from 'antd';

function ClawCognitoLogout() {
  useEffect(() => {
    clearLocalStorage();
    window.location.href = COGNITO_CLAW_LOGOUT_ENDPOINT;
  }, []);

  return (
    <Spin />
  );
}

export default ClawCognitoLogout;