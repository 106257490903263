import { Col, Row, Spin } from "antd";
import { MoneyIcon } from "components/icons/MoneyBag";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDifferenceOfTwoDates } from "../../../helpers/globalFunctions";
import { IObjectKeys } from "../../../helpers/globalTypes";
import { leadLoadingSelector } from "../../../store/leads/selectors";
import { CrossIcon } from "../../icons/Cross";
import { NotePad } from "../../icons/NotePad";
import { ParkingIcon } from "../../icons/Parking";
import { PawIcon } from "../../icons/Paw";
import { SmallCheckIcon } from "../../icons/SmallCheck";
import { TimerIcon } from "../../icons/Timer";
import LeadDescriptionItem from "./LeadDescriptionItem";
import Section from "components/Shared/Section/Section";
import SectionHeading from "components/Shared/Section/SectionHeading";

const LeadDescription = ({ requirement, collapseTitle }: { requirement: IObjectKeys; collapseTitle: string }) => {
  const mustHaveKeys = requirement?.must_have ? Object.keys(requirement?.must_have) : [];
  const dealBreakerKeys = requirement?.deal_breaker ? Object.keys(requirement?.deal_breaker) : [];
  const leadDataLoading = useSelector(leadLoadingSelector);
  let [less30Days, setLess30Days] = useState<any>();
  useEffect(() => {
    if (requirement?.move_in_date_end) {
      const days = getDifferenceOfTwoDates("days", requirement?.move_in_date_end);
      if (days === 0) {
        setLess30Days(20);
      } else {
        setLess30Days(days);
      }
    } else {
      const days = getDifferenceOfTwoDates("days", requirement?.move_in_date_start);
      if (days === 0) {
        setLess30Days(20);
      } else {
        setLess30Days(days);
      }
    }
  }, []);
  return (
    <Spin tip="Loading..." spinning={leadDataLoading}>
      <Section shadow>
        <SectionHeading heading="Renter Snapshot" />
        <div className="move-in-fees-part font-16">
          <div className={"fees-wrapper-section fees-wrapper-section-description"}>
            <Row className="lead-snapshot-row">
              <Col className="lead-snapshot-col">
                <LeadDescriptionItem
                  Icon={MoneyIcon}
                  header={"Considering buying in the future"}
                  description={requirement?.considering_buying}
                  fromComponent={"LeadDescription"}
                />
                {less30Days <= 30 && (
                  <>
                    {requirement?.move_in_date_start || requirement?.move_in_date_end ? (
                      <LeadDescriptionItem
                        Icon={TimerIcon}
                        header={"Urgent Move"}
                        description={""}
                        less30Days={less30Days}
                        fromComponent={"LeadDescription"}
                      />
                    ) : null}
                  </>
                )}
                <LeadDescriptionItem
                  Icon={PawIcon}
                  header={"Pets"}
                  description={
                    requirement?.pets.length
                      ? requirement?.pets?.map((pet: any, index: any) => (
                          <span key={index}>{`${pet.amount} ${pet.breed ? pet.breed : pet.species}${
                            pet.amount > 1 ? "s" : ""
                          }${requirement?.pets.length > 1 && index !== requirement.pets.length - 1 ? ", " : ""}`}</span>
                        ))
                      : "None"
                  }
                  fromComponent={"LeadDescription"}
                />
                {requirement?.number_of_parking ? (
                  <LeadDescriptionItem
                    Icon={ParkingIcon}
                    header={"Parking"}
                    description={requirement?.number_of_parking + " Parking Spots"}
                    fromComponent={"LeadDescription"}
                  />
                ) : null}
                {requirement?.listings_applied >= 3 ? (
                  <LeadDescriptionItem
                    Icon={NotePad}
                    header={"Applied to"}
                    description={
                      requirement?.listings_applied ? requirement?.listings_applied + " listings" : "Haven`t listings"
                    }
                    fromComponent={"LeadDescription"}
                  />
                ) : null}
              </Col>
              <Col className="lead-snapshot-col-2">
                {requirement?.must_have && Boolean(mustHaveKeys.length) && (
                  <LeadDescriptionItem
                    Icon={SmallCheckIcon}
                    header={"Must haves"}
                    description={requirement?.must_have}
                    fromComponent={"LeadDescription"}
                  />
                )}
                {requirement?.deal_breaker && Boolean(dealBreakerKeys.length) && (
                  <LeadDescriptionItem
                    Icon={CrossIcon}
                    header={"Deal Breakers"}
                    description={requirement?.deal_breaker}
                    fromComponent={"LeadDescription"}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Section>
    </Spin>
  );
};

export default LeadDescription;
