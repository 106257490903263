import React from "react";
import { Row, Col, Upload } from "antd";
import { ACCEPTED_MEDIA_FILES } from "../../../helpers/constants";
import { getAuthToken } from "../../../helpers/authUtils";
import { beforeUploadCheck } from "../../../helpers/fileUtils";
import BaseButton from "../../Shared/BaseButton";
import { useDispatch, useSelector } from "react-redux";
import { isPublicListingSelector, listingDataSelector } from "../../../store/listings/selectors";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { UploadFile } from "antd/es/upload/interface";
import { VirtualTourIcon } from "../../icons";
import queryString from "query-string";
import { push } from "connected-react-router";
import { useDemoContext } from "libs/contextLib";
import { AnalyticsService } from "helpers/analyticsService";
import ListingGuard from "../ListingGuard";

interface IListingImagesMetaSection {
  onChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined;
  setPreviewModalAllPhotosVisible: Function;
  fileList: any;
}

const ListingImagesMetaSection = (props: IListingImagesMetaSection) => {
  const dispatch = useDispatch();
  const listingData = useSelector(listingDataSelector);
  const { _id } = useSelector(listingDataSelector);
  const isPublicListing = useSelector(isPublicListingSelector);
  const { isDemo, demoAction } = useDemoContext();

  const handleAddBtnClick = () => {
    AnalyticsService.reportSingleListingPageClick("add_virtual_tour");
    const parsed = {
      // landlordId:
      lId: listingData._id,
      step: 6,
    };
    const stringified = queryString.stringify(parsed);
    dispatch(push(`/manageListing?${stringified}`));
  };

  return (
    <Row className={"images-meta-section"}>
      <Col>
        {listingData?.virtual_tour_link ? (
          <div className="virtual-tour-wrapper">
            <BaseButton size="sm" href={listingData.virtual_tour_link} target="_blank">
              <>
                <span className="mr-1">
                  <VirtualTourIcon />
                </span>
                <span>Virtual Tour</span>
              </>
            </BaseButton>
          </div>
        ) : (
          <></>
        )}
      </Col>
      <Col xs={12}>
        <div className="all-images-btn-wrapper">
          {/* {!isPublicListing && (
            <ListingGuard condition={!isPublicListing}>
              <BaseButton
                onClick={handleAddBtnClick}
                classnames={"rb-secondary-btn add-photo-btn mr-10 ml-10"}
                modalType="listing"
              >
                + Add a Virtual Tour
              </BaseButton>
            </ListingGuard>
          )}
          {listingData?.images?.length > 6 && (
            <BaseButton
              onClick={() => props.setPreviewModalAllPhotosVisible(true)}
              modalType="listing"
              classnames={"rb-secondary-btn grey"}
            >
              All Photos
            </BaseButton>
          )} */}
          {/* {listingData?.images?.length > 0 &&
            !isPublicListing &&
            (isDemo ? (
              <BaseButton
                classnames={"rb-secondary-btn add-photo-btn mr-10 ml-10"}
                onClick={() => {
                  AnalyticsService.reportSingleListingPageClick("add_photos");
                  demoAction("listing");
                }}
              >
                Add Photos
              </BaseButton>
            ) : (
              <ListingGuard>
                <Upload
                  showUploadList={false}
                  accept={`${ACCEPTED_MEDIA_FILES}`}
                  method={"PUT"}
                  name={"image"}
                  multiple={true}
                  action={`${process.env.REACT_APP_BASE_URL}/listing/media/${_id}`}
                  headers={{
                    Authorization: "Bearer " + getAuthToken(),
                  }}
                  onChange={props.onChange}
                  beforeUpload={(file) =>
                    beforeUploadCheck(file, props.fileList)
                  }
                >
                  <BaseButton
                    classnames={"rb-secondary-btn add-photo-btn mr-10 ml-10"}
                  >
                    <div
                      onClick={() =>
                        AnalyticsService.reportSingleListingPageClick(
                          "add_photos"
                        )
                      }
                    >
                      Add Photos
                    </div>
                  </BaseButton>
                </Upload>
              </ListingGuard>
            ))} */}
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(ListingImagesMetaSection);
