import * as types from "./types";
import { INote } from "../../components/FollowUps/types";
import {IObjectKeys} from "../../helpers/globalTypes";

export interface IFollowUpsQueryAndPagination {
  page?: number;
  pageSize?: number;
  due_in_days?: number;
  sortField?: string;
  sortOrder?: number;
  fillAll?: boolean;
  searchValue?: string;
}

interface IFollowUpsReducer {
  followUpsList: INote[];
  total: number;
  followUpsLoading: boolean;
  followUpsError: object | null;
  updateFollowUpSuccess: boolean;
  queryAndPagination: IFollowUpsQueryAndPagination;
  addFollowUpSuccess: boolean;
  addFollowUpError: object | null;
  addedFollowUp: object;
  editFollowUpSuccess: boolean;
  editFollowUpError: object | null;
  editedFollowUp: object;
  deleteFollowUpSuccess: boolean;
  followUpsLoadingListing: boolean;
  snoozeSuccess:boolean;
  snoozedFollowUp:object;
  singleFU:IObjectKeys;
  singleFUError:any;
  singleFULoading:boolean
}
const initialState: IFollowUpsReducer = {
  followUpsList: [],
  total: 0,
  followUpsLoading: false,
  followUpsError: null,
  updateFollowUpSuccess: false,
  deleteFollowUpSuccess: false,
  addFollowUpSuccess: false,
  addFollowUpError: null,
  addedFollowUp: {},
  editFollowUpSuccess: false,
  editFollowUpError: {},
  editedFollowUp: {},
  followUpsLoadingListing: false,
  snoozedFollowUp:{},
  queryAndPagination: {
    page: 1,
    pageSize: 20,
    sortField: "todo_at",
    sortOrder: 1,
    fillAll: true,
    searchValue: "",
  },
  snoozeSuccess:false,
  singleFU:{},
  singleFUError:null,
  singleFULoading:false
};

export default function followUpsReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.UPDATE_FOLLOW_UP:
      return {
        ...state,
        followUpsError: null,
        followUpsLoading: true,
        updateFollowUpSuccess: false,
      };
    case types.DELETE_FOLLOW_UP:
      return {
        ...state,
        followUpsError: null,
        followUpsLoading: true,
        deleteFollowUpSuccess: false,
        followUpsLoadingListing: true,
      };
    case types.ADD_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        addFollowUpSuccess: true,
        followUpsLoadingListing: false,
        addedFollowUp: action.payload,
        followUpsList: action.payload.listing_id
          ? [action.payload, ...state.followUpsList]
          : action.payload.landlord_id
          ? [action.payload, ...state.followUpsList]
          : [...state.followUpsList],
      };
    case types.ADD_FOLLOW_UP:
      return {
        ...state,
        addFollowUpSuccess: false,
        followUpsLoadingListing: true,
      };
    case types.EDIT_FOLLOW_UP:
      return {
        ...state,
        editFollowUpSuccess: false,
        followUpsLoadingListing: true,
        followUpsLoading:true
      };
    case types.EDIT_FOLLOW_UP_SUCCESS:
      let updatedFollowUps = state.followUpsList.map((item: any) => {
        if (item._id === action.payload._id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        followUpsList: updatedFollowUps,
        editFollowUpSuccess: true,
        followUpsLoadingListing: false,
        snoozeSuccess:false,
        editedFollowUp:action.payload,
        followUpsLoading:false
      };
    case types.EDIT_FOLLOW_UP_SUCCESS_CLEAR:
      return {
        ...state,
        editFollowUpSuccess: false,
      };
    case types.SNOOZE_FOLLOW_UP:
      return {
        ...state,
        editFollowUpSuccess: false,
        followUpsLoadingListing: true,
        snoozeSuccess:false,
        snoozedFollowUp:{}
      };
    case types.SNOOZE_FOLLOW_UP_SUCCESS:
      let snoozedFollowUps = state.followUpsList.map((item: any) => {
        if (item._id === action.payload._id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        followUpsList: snoozedFollowUps,
        followUpsLoadingListing: false,
        snoozeSuccess:true,
        editFollowUpSuccess:false,
        snoozedFollowUp:action.payload
      };
    case types.UPDATE_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        followUpsList: action.payload.data,
        followUpsError: false,
        followUpsLoading: false,
        updateFollowUpSuccess: true,
        // total: action.payload.metadata[0].total,
      };
    case types.DELETE_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        followUpsList: state.followUpsList.filter(
          (item) => item._id !== action.payload._id,
        ),
        followUpsError: false,
        followUpsLoading: false,
        deleteFollowUpSuccess: true,
        // total: action.payload.metadata[0].total,
      };

    case types.UPDATE_FOLLOW_UP_FAIL:
    case types.ADD_FOLLOW_UP_FAIL:
      // case types.DELETE_FOLLOW_UP_FAIL:
      return {
        ...state,
        landlordNotesError: action.payload,
      };

    case types.GET_FOLLOW_UPS:
      return {
        ...state,
        followUpsLoading: true,
        followUpsError: null,
      };
    case types.GET_FOLLOW_UPS_SUCCESS:
      let followUpsList;
      if (action.payload.metadata[0].fillAll) {
        followUpsList = [...action.payload.data];
      } else {
        followUpsList = [...state.followUpsList, ...action.payload.data];
      }
      return {
        ...state,
        followUpsError: null,
        followUpsLoading: false,
        followUpsList,
        total: action.payload.metadata[0].total,
      };
    case types.GET_FOLLOW_UPS_FAIL:
      return {
        ...state,
        followUpsLoading: false,
        followUpsError: action.payload,
      };
    case types.GET_LISTING_FOLLOWUPS:
      return {
        ...state,
        followUpsLoading: true,
        followUpsError: null,
      };
    case types.GET_LISTING_FOLLOWUPS_SUCCESS:
      return {
        ...state,
        followUpsError: null,
        followUpsLoading: false,
        followUpsList: action.payload,
      };
    case types.GET_LISTING_FOLLOWUPS_FAIL:
      return {
        ...state,
        followUpsLoading: false,
        followUpsError: action.payload,
      };
    case types.SET_FOLLOWUP_DELETE_SUCCESS:
      return {
        ...state,
        deleteFollowUpSuccess:action.payload
      };
    case types.GET_LANDLORD_FOLLOWUPS:
      return {
        ...state,
        followUpsLoading: true,
        followUpsError: null,
      };
    case types.GET_LANDLORD_FOLLOWUPS_SUCCESS:
      return {
        ...state,
        followUpsError: null,
        followUpsLoading: false,
        followUpsList: action.payload,
      };
    case types.GET_LANDLORD_FOLLOWUPS_FAIL:
      return {
        ...state,
        followUpsLoading: false,
        followUpsError: action.payload,
      };
    case types.GET_LEAD_FOLLOWUPS:
      return {
        ...state,
        followUpsError: null,
      };
    case types.GET_LEAD_FOLLOWUPS_SUCCESS:
      return {
        ...state,
        followUpsError: null,
        followUpsLoading: false,
        followUpsList: action.payload,
      };
    case types.GET_LEAD_FOLLOWUPS_FAIL:
      return {
        ...state,
        followUpsLoading: false,
        followUpsError: action.payload,
      };
    case types.CLEAR_GET_FOLLOW_UPS_LIST:
      return {
        ...state,
        followUpsList: [],
      };

    case types.SET_FOLLOWUPS_LOADING_TO_TRUE:
      return {
        ...state,
        followUpsLoading: true,
      };
    case types.SET_QUERY_AND_PAGINATION:
      return {
        ...state,
        queryAndPagination: {
          ...state.queryAndPagination,
          ...action.payload,
        },
      };
    case types.ADD_FOLLOW_UP_SUCCESSED_AND_MODAL:
      return {
        ...state,
        addFollowUpSuccess: action.payload,
      };
    case types.SET_FOLLOWUP_EDIT_SUCCESS:
      return {
        ...state,
        editFollowUpSuccess:action.payload
      };
    case types.SET_SNOOZE_EDIT_SUCCESS:
      return {
        ...state,
        snoozeSuccess:false
      };
    case types.CLEAR_EDITED_FOLLOW_UP:
      return {
        ...state,
        editedFollowUp:action.payload
      };
    case types.GET_FU_BY_ID:
      return {
        ...state,
        singleFULoading:true
      }
    case types.GET_FU_BY_ID_SUCCESS:
      return {
        ...state,
        singleFULoading:false,
        singleFU:action.payload
      }
    case types.GET_FU_BY_ID_FAIL:
      return {
        ...state,
        singleFUError:action.payload
      }
    case types.SET_SINGLE_FU:
      return {
        ...state,
        singleFU:{}
      }
    case types.CLEAR_ALL_FU_MODAL:
      const updatedList = state.followUpsList.filter(item=>item._id !== action.payload._id)
      return {
        ...state,
        followUpsList:updatedList
      }
    default:
      return state;
  }
}
