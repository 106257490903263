import React from "react";

export const GridViewIcon = () => {
  return (
      <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="37" height="37" rx="3" fill="white"/>
        <rect x="0.5" y="0.5" width="36" height="36" rx="2.5" stroke="#5806B9"/>
        <g clipPath="url(#clip0_112_108)">
          <path d="M12.5731 7.89516C12.5731 7.49214 12.2478 7.16681 11.8448 7.16681H6.9406C6.53758 7.16681 6.21225 7.49214 6.21225 7.89516V12.8018C6.21225 13.2048 6.53758 13.5301 6.9406 13.5301H11.8472C12.2502 13.5301 12.5756 13.2048 12.5756 12.8018V7.89516H12.5731Z" fill="#5806B9"/>
          <path d="M21.0626 7.89516C21.0626 7.49214 20.7373 7.16681 20.3343 7.16681H15.4301C15.0271 7.16681 14.7017 7.49214 14.7017 7.89516V12.8018C14.7017 13.2048 15.0271 13.5301 15.4301 13.5301H20.3343C20.7373 13.5301 21.0626 13.2048 21.0626 12.8018V7.89516Z" fill="#5806B9"/>
          <path d="M29.5532 7.89516C29.5532 7.49214 29.2278 7.16681 28.8248 7.16681H23.9206C23.5176 7.16681 23.1923 7.49214 23.1923 7.89516V12.8018C23.1923 13.2048 23.5176 13.5301 23.9206 13.5301H28.8273C29.2303 13.5301 29.5556 13.2048 29.5556 12.8018V7.89516H29.5532Z" fill="#5806B9"/>
          <path d="M12.5728 16.4313C12.5728 16.0283 12.2474 15.7029 11.8444 15.7029H6.94022C6.5372 15.7029 6.21187 16.0283 6.21187 16.4313V21.3355C6.21187 21.7385 6.5372 22.0638 6.94022 22.0638H11.8468C12.2499 22.0638 12.5752 21.7385 12.5752 21.3355V16.4313H12.5728Z" fill="#5806B9"/>
          <path d="M21.0626 16.4313C21.0626 16.0283 20.7373 15.7029 20.3343 15.7029H15.4301C15.0271 15.7029 14.7017 16.0283 14.7017 16.4313V21.3355C14.7017 21.7385 15.0271 22.0638 15.4301 22.0638H20.3343C20.7373 22.0638 21.0626 21.7385 21.0626 21.3355V16.4313Z" fill="#5806B9"/>
          <path d="M29.5532 16.4313C29.5532 16.0283 29.2278 15.7029 28.8248 15.7029H23.9206C23.5176 15.7029 23.1923 16.0283 23.1923 16.4313V21.3355C23.1923 21.7385 23.5176 22.0638 23.9206 22.0638H28.8273C29.2303 22.0638 29.5556 21.7385 29.5556 21.3355V16.4313H29.5532Z" fill="#5806B9"/>
          <path d="M11.8444 24.2368H6.94022C6.5372 24.2368 6.21187 24.5622 6.21187 24.9652V29.8718C6.21187 30.2749 6.5372 30.6002 6.94022 30.6002H11.8468C12.2499 30.6002 12.5752 30.2749 12.5752 29.8718V24.9652C12.5728 24.5646 12.2474 24.2368 11.8444 24.2368Z" fill="#5806B9"/>
          <path d="M20.3343 24.2368H15.4301C15.0271 24.2368 14.7017 24.5622 14.7017 24.9652V29.8718C14.7017 30.2749 15.0271 30.6002 15.4301 30.6002H20.3343C20.7373 30.6002 21.0626 30.2749 21.0626 29.8718V24.9652C21.0626 24.5646 20.7373 24.2368 20.3343 24.2368Z" fill="#5806B9"/>
          <path d="M28.8248 24.2368H23.9206C23.5176 24.2368 23.1923 24.5622 23.1923 24.9652V29.8718C23.1923 30.2749 23.5176 30.6002 23.9206 30.6002H28.8273C29.2303 30.6002 29.5556 30.2749 29.5556 29.8718V24.9652C29.5532 24.5646 29.2278 24.2368 28.8248 24.2368Z" fill="#5806B9"/>
        </g>
        <defs>
          <clipPath id="clip0_112_108">
            <rect width="23.4333" height="23.4333" fill="white" transform="translate(6.1667 7.16681)"/>
          </clipPath>
        </defs>
      </svg>
  );
};
