import React, { useEffect } from "react";
import { StepFormComponent } from "../ListingForm/types";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { listingDataSelector } from "../../../store/listings/selectors";
import { IObjectKeys } from "../../../helpers/globalTypes";
import TenantsPart from "../ListingForm/ShowingProcedure/components/tenants-part/TenantsPart";
import { openNotification, sanitizePhoneNumber } from "../../../helpers/globalFunctions";
import { updateListing } from "../../../store/listings/action";

const TenantsStep = ({ form, statusChangeForm }: StepFormComponent) => {
  const dispatch = useDispatch();
  const listingData = useSelector(listingDataSelector);
  useEffect(() => {
    const fieldsToSet: IObjectKeys = {};
    if (listingData.listingShowingOfList?.procedure?.call_tenants) {
      fieldsToSet["tenants"] =
        listingData?.rentersOfListing?.map((tenant: IObjectKeys) => {
          return {
            email: tenant?.email,
            phone: tenant?.phone,
            name: tenant?.name,
          };
        }) || [];
    }
    form.setFieldsValue(fieldsToSet);
  }, [listingData]);

  const onSubmit = (formData: IObjectKeys) => {
    formData.status = "Off-market";
    // eslint-disable-next-line no-restricted-globals
    for (let i = 0; i < formData?.tenants?.length; i++) {
      let tenant = formData.tenants[i];
      if (!tenant.name && !tenant.email && !tenant.phone) {
        formData.tenants = [];
      } else if (!tenant.email && !tenant.phone) {
        return openNotification("error", "At least you should mention tenant email or phone!");
      }
      if (tenant?.phone) {
        tenant.phone = sanitizePhoneNumber(tenant.phone);
      }
    }
    return dispatch(
      updateListing({
        other_renewal_date: listingData.other_renewal_date,
        lId: listingData._id,
        procedure: {
          ...listingData.listingShowingOfList?.procedure,
          call_tenants: formData.tenants.length,
        },
        ...formData,
        step: "showing_procedure",
      })
    );
  };
  return (
    <>
      <h4>New Tenant(s)</h4>
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={{
          call_tenants: listingData?.listingShowingOfList?.procedure?.call_tenants,
        }}
      >
        <TenantsPart form={form} statusChangeForm={statusChangeForm} />
      </Form>
    </>
  );
};
export default TenantsStep;
