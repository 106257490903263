import Form from "antd/es/form";
import BaseSwitchButton from "components/Shared/BaseSwitchButton";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./styles.scss";

interface ICollapseToggleButtonProps {
  description: string;
  descriptionPlaceholder: string;
  inputName: string;
  inputValue: boolean;
  handleChange: (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => void;
}

const CollapseToggleButton = ({
  description,
  descriptionPlaceholder,
  inputName,
  inputValue,
  handleChange,
}: ICollapseToggleButtonProps) => {
  const contentRef = useRef<HTMLInputElement>(null);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  useEffect(() => {
    if (contentRef && contentRef.current && queryObject?.action) {
      contentRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [search]);
  return (
    <div className="collapse-toggle-button-wrapper flex flex-wrap justify-between items-center" ref={contentRef}>
      <div className="collapse-toggle-button-wrapper--description-part">
        <h4 className="collapse-toggle-button-wrapper--description-part--description">{description}</h4>
        <p className="collapse-toggle-button-wrapper--description-part--placeholder">{descriptionPlaceholder}</p>
      </div>
      <div className="collapse-toggle-button-wrapper--button-part">
        <Form.Item name={inputName} data-test-id="lead-renewal-toggle-btn-input">
          <BaseSwitchButton
            name={inputName}
            checked={inputValue}
            onChange={handleChange}
            disabled={false}
            dataTestId="lead-renewal-toggle-btn"
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default CollapseToggleButton;
