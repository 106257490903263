import React from "react";

const EditSquareIconSingleLead = () =>{
    return(
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_4_1539" fill="white">
                <path d="M0 7.00001C0 3.13402 3.13401 0 7 0H32.2559C36.1219 0 39.2559 3.13401 39.2559 7V33.1724C39.2559 37.0384 36.1219 40.1724 32.2559 40.1724H7C3.13401 40.1724 0 37.0384 0 33.1724V7.00001Z"/>
            </mask>
            <path d="M0 7.00001C0 3.13402 3.13401 0 7 0H32.2559C36.1219 0 39.2559 3.13401 39.2559 7V33.1724C39.2559 37.0384 36.1219 40.1724 32.2559 40.1724H7C3.13401 40.1724 0 37.0384 0 33.1724V7.00001Z" fill="#F5F5F5"/>
            <path d="M7 1H32.2559V-1H7V1ZM38.2559 7V33.1724H40.2559V7H38.2559ZM32.2559 39.1724H7V41.1724H32.2559V39.1724ZM1 33.1724V7.00001H-1V33.1724H1ZM7 39.1724C3.68629 39.1724 1 36.4861 1 33.1724H-1C-1 37.5907 2.58173 41.1724 7 41.1724V39.1724ZM38.2559 33.1724C38.2559 36.4861 35.5696 39.1724 32.2559 39.1724V41.1724C36.6742 41.1724 40.2559 37.5907 40.2559 33.1724H38.2559ZM32.2559 1C35.5696 1 38.2559 3.68629 38.2559 7H40.2559C40.2559 2.58172 36.6742 -1 32.2559 -1V1ZM7 -1C2.58172 -1 -1 2.58173 -1 7.00001H1C1 3.6863 3.68629 1 7 1V-1Z" fill="black" mask="url(#path-1-inside-1_4_1539)"/>
            <path d="M26.8922 13.206L26.8922 13.206C27.4443 13.7594 27.4442 14.6551 26.8922 15.2085L26.8922 15.2085L14.7619 27.3696L14.7618 27.3697C14.7618 27.3697 14.7618 27.3697 14.7618 27.3697C14.7616 27.3699 14.7594 27.3718 14.7552 27.374C14.7532 27.3751 14.7512 27.3759 14.7496 27.3765C14.7481 27.377 14.7472 27.3772 14.7472 27.3772L10.3458 28.1944L10.3457 28.1944C10.3376 28.1959 10.3288 28.1934 10.3223 28.1869C10.3159 28.1805 10.3127 28.1708 10.3145 28.1609L11.1297 23.7476L11.1297 23.7474C11.1297 23.7474 11.1297 23.7473 11.1297 23.7473C11.1298 23.7471 11.1299 23.7465 11.1302 23.7457C11.1308 23.7441 11.1316 23.742 11.1328 23.7398C11.1351 23.7353 11.1372 23.7329 11.1375 23.7325C11.1375 23.7325 11.1376 23.7325 11.1375 23.7325L11.1375 23.7325L23.2678 11.5714C23.2678 11.5714 23.2678 11.5714 23.2679 11.5714C23.8175 11.0205 24.7122 11.0205 25.2618 11.5714C25.2618 11.5714 25.2618 11.5714 25.2618 11.5714L26.8922 13.206ZM10.5073 27.4159L10.3752 28.1311L11.0902 27.9984L13.7532 27.504L14.6858 27.3308L14.0159 26.6593L11.8461 24.484L11.1734 23.8096L11.0004 24.7463L10.5073 27.4159ZM14.3892 26.9548L14.7432 27.3097L15.0972 26.9548L25.4712 16.5545L25.8234 16.2014L25.4712 15.8483L22.6297 12.9996L22.2757 12.6447L21.9217 12.9996L11.5477 23.4L11.1955 23.7531L11.5477 24.1062L14.3892 26.9548ZM25.5086 15.8072L25.8626 16.1621L26.2166 15.8072L26.8548 15.1674C27.3841 14.6367 27.3841 13.7778 26.8548 13.2471C26.8547 13.2471 26.8547 13.2471 26.8547 13.2471L25.2243 11.6125L25.2243 11.6125C24.695 11.0819 23.8345 11.0819 23.3053 11.6125L22.6671 12.2523L22.3149 12.6054L22.6671 12.9585L25.5086 15.8072Z" fill="#F5F5F5" stroke="black"/>
        </svg>
    )
}

export default EditSquareIconSingleLead