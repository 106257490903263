import React from "react";
import { useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { getFormTextarea } from "../../../../../../../../helpers/globalFunctions";
import { REQUIRED } from "../../../../../../../../helpers/validations";
import FormStepsTitle from "../../form-steps-title/FormStepsTitle";
import "./OtherInfo.scss";
import { urlQueryToObject } from "../../../../../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { useLocation } from "react-router";

const OtherInfo = () => {
  const requirementEnums = useSelector(requirementEnumsSelector);
  const availableTimes = requirementEnums?.find(
    (item: EnumInterface) => item?.key === "other"
  );
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  return (
    <div className={"other-info-section"}>
      <FormStepsTitle
        text={`${availableTimes?.label}`}
        classname={"required-not-visible"}
        secondaryText={`${ queryObject?.is_manual ? "(Your lead will see this)" : ""}`}
      />
      {getFormTextarea(
        availableTimes?.key,
        "",
        [!availableTimes?.allow_null ? REQUIRED : {}],
        "Let us know...",
        8
      )}
      {queryObject?.is_manual ? (
        <p className={"manual-addition-submit-text"}>
          * By submitting this form your lead will recieve a confirmation email
          with access to review these responses.
        </p>
      ) : null}
    </div>
  );
};

export default OtherInfo;
