import React, { useEffect, useState } from "react";

interface IPropsFieldItems {
  fields?: any[];
  label?: string;
  description?: string;
  value?: any;
  isEditing?: boolean;
  editMode?: boolean;
  onChange?: any;
  onSubmit?: any;
}

// TODO: move to a JSX helper
export const renderItemValue = (value: any): any => {
  if (value instanceof Array) {
    return value.map((item: any, idx: number) => renderItemValue(item));
  }
  if (value instanceof Object) {
    if (value.area) {
      return (
        <div key={`item-value-${value.area}`} className="mr-2">
          {value.town}, {value?.separated_fields?.short_state ? value.separated_fields.short_state : value.state},
        </div>
      );
    }
    if (value.species) {
      return (
        <div key={`item-value-${value.species}`}>
          {value.species} x{value.amount} {value.breed && <>({value.breed})</>}
        </div>
      );
    }
    return Object.keys(value)
      .map((key) => (value[key] === true ? `${key}`.replaceAll("_", " ") : `${value[key]}`))
      .join(", ");
  }

  return <div>{value}</div>;
};

const FieldItems = ({ fields, label, description, value, isEditing, editMode, onChange, onSubmit }: IPropsFieldItems) => {
  const [localIsEditing, setLocalIsEditing] = useState<boolean>(false);
  const [showEditButtons, setShowEditButtons] = useState<boolean>(false);

  useEffect(() => {
    if (!isEditing) setLocalIsEditing(false);
  }, [isEditing]);

  const handleOnSubmit = () => {
    setLocalIsEditing(false);
    onSubmit();
  };

  return (
    <>
      <div onMouseEnter={() => editMode && setShowEditButtons(true)} onMouseLeave={() => editMode && setShowEditButtons(!!localIsEditing)}>
        <div className="px-4">
          <div className="sm:flex sm:items-start sm:justify-between">
            <div className="flex flex-wrap justify-start w-full">
              {fields?.map((field, idx) => {
                return (
                  <div key={`field-item-${field.key}-${idx}`} className="py-4 w-full text-base">
                    <>
                      {/* field:{JSON.stringify(value[field.key])} */}
                      {isEditing || localIsEditing ? (
                        <field.inputComponent onChange={onChange} field={field} value={value[field.key]} />
                      ) : field.outputComponent ? (
                        <field.outputComponent field={field} value={value[field.key]} />
                      ) : (
                        <div>
                          <label className="block text-base font-medium ">{label || field.label}</label>
                          {renderItemValue(field.format(value[field.key]))}
                        </div>
                      )}
                    </>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FieldItems;
