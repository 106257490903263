import React from "react";

const AccVerifiedIcon = () =>{
    return(
        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M108 53.9986C108 59.3408 98.4288 63.1046 96.8637 67.9252C95.2403 72.9216 100.715 81.582 97.6878 85.7392C94.6321 89.9398 84.6839 87.3938 80.4833 90.4511C76.3245 93.477 75.6894 103.74 70.6946 105.357C65.874 106.922 59.3483 99.043 53.9998 99.043C48.6514 99.043 42.1316 106.922 37.305 105.357C32.3087 103.734 31.6736 93.4711 27.5148 90.4511C23.3158 87.3953 13.3676 89.9414 10.3103 85.7408C7.2844 81.582 12.7578 72.9216 11.136 67.9268C9.57117 63.1061 0 59.3424 0 54.0002C0 48.6579 9.57117 44.8942 11.1363 40.0735C12.7581 35.0784 7.28471 26.418 10.3106 22.2595C13.3679 18.0589 23.3161 20.605 27.5167 17.5492C31.674 14.5233 32.309 4.25884 37.3054 2.64323C42.126 1.07811 48.6517 8.95733 54.0001 8.95733C59.3483 8.95733 65.8684 1.07811 70.6949 2.64323C75.6901 4.26507 76.3263 14.5292 80.4836 17.5492C84.6842 20.6065 94.6324 18.0605 97.6897 22.2595C100.716 26.4183 95.2406 35.0787 96.864 40.0735C98.4288 44.8923 108 48.656 108 53.9986V53.9986Z" fill="#D5C1ED"/>
            <path d="M49.284 72.3582C47.7027 72.3604 46.1856 71.7322 45.0685 70.6126L31.7754 57.3195C29.4465 54.9913 29.4459 51.216 31.7738 48.8872C34.102 46.5584 37.8773 46.5577 40.2061 48.8856L49.2837 57.962L67.8045 39.4428C70.133 37.114 73.9086 37.1137 76.2374 39.4422C78.5662 41.7707 78.5665 45.5463 76.238 47.8751L53.5005 70.6126C52.3835 71.7325 50.8657 72.3607 49.284 72.3582V72.3582Z" fill="#5806B9"/>
        </svg>
    )
}

export default AccVerifiedIcon