import React from "react";
import BedroomsBathrooms from "./bed-and-bath/BedroomsBathroomsNew";
import NumberOfLivingPeople from "./living-people/NumberOfLivingPeople";

const HomeAndPeople = () => {
    return (
        <div>
            <BedroomsBathrooms />
            <NumberOfLivingPeople />
        </div>
    );
};

export default HomeAndPeople;