import React from "react";

const MarkAsDone = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C4.31323 0 0.5 3.8129 0.5 8.5C0.5 13.1871 4.31323 17 9 17C13.6868 17 17.5 13.1871 17.5 8.5C17.5 3.8129 13.6868 0 9 0ZM13.7387 5.66525L8.50798 11.5499C8.37917 11.6947 8.19969 11.7692 8.0189 11.7692C7.87538 11.7692 7.73121 11.7222 7.61058 11.626L4.34135 9.01065C4.05954 8.7854 4.01377 8.37381 4.23935 8.09167C4.4646 7.80954 4.87652 7.76377 5.15833 7.98935L7.94273 10.2167L12.7613 4.79596C13.0006 4.52592 13.4144 4.50173 13.6842 4.74169C13.9542 4.98198 13.9787 5.39521 13.7387 5.66525Z"
        fill="white"
      />
    </svg>
  );
};

export default MarkAsDone;
