import LeadRequestScreeningDialog from "components/Lead/renter-lead-page/RequestScreeningModal/LeadRequestScreeningModal";
import React from "react";

interface AddScreeningRequestDialogProps {
  visible: boolean;
  setVisible: Function;
  refreshList?: Function;
}

const AddScreeningRequestDialog: React.FC<AddScreeningRequestDialogProps> = ({
  visible,
  setVisible,
  refreshList,
}) => {
  return (
    <LeadRequestScreeningDialog
      visible={visible}
      setVisible={setVisible}
      refreshList={refreshList}
      forTenantScreening
    />
  );
};

export default AddScreeningRequestDialog;
