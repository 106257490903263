import React, { memo, useEffect, useState } from "react";
import { Card, Col, Form, Input, Row, Typography, Spin } from "antd";

import { CheckedIcon } from "../icons";
import { IObjectKeys } from "../../helpers/globalTypes";
import MaskedInput from "../Shared/MaskedPhoneInput";
import {
  EMAIL_VALIDATION,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
  REQUIRED,
  SETTINGS_PHONE_NUMBER_VALIDATION,
} from "../../helpers/validations";
import { useDispatch, useSelector } from "react-redux";
import {
  authLoadingSelector,
  passwordResetLoadingSelector,
  phoneVerificationCodeErrorSelector,
  resetPasswordSuccessSelector,
  updateUserSettingsSuccessSelector,
  userSelector,
  verifyCodeSuccessSelector,
} from "../../store/user/selectors";
import { ROLE_TYPES, US_DATE_FORMAT, SSO_SOURCES } from "../../helpers/constants";
import {
  clearErrorStates,
  getLoggedInUser,
  getPhoneVerificationCode,
  resetPassword,
  setUpdatedUserSettingsSuccess,
  setVerificationPhoneSuccess,
  updateUserSettings,
} from "../../store/user/action";
import { sanitizePhoneNumber, getIsLastOneWeek, scrollToFirstErrorField } from "../../helpers/globalFunctions";
import PasswordConfirmModal from "../Settings/ProfileSettings/PasswordConfirmModal";
import VerifyPhoneModal from "../Settings/ProfileSettings/VerifyPhoneModal";
import BaseButton from "../Shared/BaseButton";
import moment from "moment";
import { FieldData } from "rc-field-form/lib/interface";
import { windowSelector } from "../../store/app/selectors";
import UploadUserImage from "../Settings/ImageUpload";
import { AddressChangeParams } from "components/Shared/PlacesAutocomplete/types";
import { useWindowSize } from "../../hooks/useWindowSize";
import { push } from "connected-react-router";
import { getInvoices, getSubscriptions, getUpcomingInvoices } from "../../store/payment/action";
import { AnalyticsService } from "helpers/analyticsService";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { useAppContext } from "libs/contextLib";
import { PERSONAS } from "routes/routesCode";
import { isRenter } from "helpers/userHelpers";

const { Title, Link } = Typography;

const RenterPortalSettings = () => {
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const userData = useSelector(userSelector);
  const loading = useSelector(authLoadingSelector);
  const getPasswordCodeSuccess = useSelector(resetPasswordSuccessSelector);
  const verifyCodeSuccess = useSelector(verifyCodeSuccessSelector);
  const passwordResetLoading = useSelector(passwordResetLoadingSelector);
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);
  const updateUserSettingsSuccess = useSelector(updateUserSettingsSuccessSelector);
  const [imageData, setImageData] = useState();
  const { type } = useSelector(windowSelector);
  const [remindersCollapse, setRemindersCollapse] = useState(false);
  const [isValidateMessageVisible, setIsValidateMessageVisible] = useState(false);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const [isPhoneValueChanged, setIsPhoneValueChanged] = useState(false);
  const [switchInputData, setSwitchInputData] = useState<IObjectKeys>({});
  const [verifyPhoneModalVisible, setVerifyPhoneModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [showWhyText, setShowWhyText] = useState(false);
  const phoneVerified = useSelector(phoneVerificationCodeErrorSelector);
  const [phoneCodeWasSent, setPhoneCodeWasSent] = useState(false);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const { isNotPremiumUser } = useAppContext();

  const onSubmit = (data: IObjectKeys) => {
    const formData: IObjectKeys = {
      ...data,
      office_name: data.office_name,
      phone: data.phone?.length ? sanitizePhoneNumber(data.phone) : undefined,
    };
    if (userData.email !== formData.email && !passwordModalVisible) {
      return setPasswordModalVisible(true);
    }
    const { password } = form.getFieldsValue(["password"]);

    dispatch(
      updateUserSettings({
        ...formData,
        emailNotifications: switchInputData.emailNotifications,
        smsNotifications: switchInputData.smsNotifications,
        listingsRenewalNotifications: switchInputData.listingsRenewalNotifications,
        _id: userData._id,
        password,
      })
    );
    setIsPhoneValueChanged(false);
  };

  const handleFieldsChange = (changedFields: FieldData[]) => {
    const { getFieldError } = form;
    if (getFieldError("phone") && getFieldError("phone").length > 0) {
      setIsValidateMessageVisible(true);
    }
    if (changedFields) {
      const phoneField = changedFields.find((field: IObjectKeys) => field.name[0] === "phone");
      const isEmpty = phoneField?.value !== "(___) ___-____";
      const isEqual = sanitizePhoneNumber(phoneField?.value || "") === userData?.phone;
      // @ts-ignore
      if (changedFields[0].name[0] === "phone" && isEmpty && !isEqual) {
        setIsPhoneValueChanged(true);
      } else {
        setIsPhoneValueChanged(false);
      }
    }
  };

  const onFinishFailed = () => {
    scrollToFirstErrorField();
  };

  const onVerifyPhoneClick = () => {
    dispatch(getPhoneVerificationCode());
    setPhoneCodeWasSent(true);
    setVerifyPhoneModalVisible(true);
  };

  useEffect(() => {
    const { getFieldError } = form;
    if (getFieldError("phone") && getFieldError("phone").length > 0) {
      return;
    } else {
      if (isPhoneValueChanged) {
        form.submit();
        setIsPhoneValueChanged(false);
      }
    }
    if (!form.getFieldValue("phone") || form.getFieldValue("phone") === "(___) ___-____") {
      setIsValidateMessageVisible(true);
    } else {
      setIsValidateMessageVisible(false);
    }
  }, [form.getFieldValue("phone")]);

  useEffect(() => {
    if (Object.keys(userData).length) {
      const dataToSet: IObjectKeys = {};
      const settingsFields = [
        "email",
        "phone",
        "name",
        "pushNotifications",
        "smsNotifications",
        "emailNotifications",
        "defaultState",
        "defaultCity",
        "listingsRenewalNotifications",
      ];
      settingsFields.forEach((fieldName: string) => {
        dataToSet[fieldName] = userData[fieldName];
      });
      setSwitchInputData((state) => ({
        ...state,
        ["emailNotifications"]: userData.emailNotifications,
        ["smsNotifications"]: userData.smsNotifications,
        ["listingsRenewalNotifications"]: userData.listingsRenewalNotifications,
      }));
      form.setFieldsValue({
        phoneType: userData.phonetype,
        address: userData.address,
      });
      form.setFieldsValue(dataToSet);
    }
  }, [userData]);

  useEffect(() => {
    if (getPasswordCodeSuccess) {
      const resetPassRoute = `${isRenter(userData) && `/renter-portal`}/reset-password?step=1`;
      dispatch(push(resetPassRoute));
    }
  }, [getPasswordCodeSuccess]);

  useEffect(() => {
    if (verifyCodeSuccess) {
      setVerifyPhoneModalVisible(false);
    }
    return () => {
      dispatch(clearErrorStates());
    };
  }, [verifyCodeSuccess]);

  useEffect(() => {
    if (updateUserSettingsSuccess) {
      setPasswordModalVisible(false);
      dispatch(getLoggedInUser());
      dispatch(setUpdatedUserSettingsSuccess());
      form.setFieldsValue({ password: "" });
    }
  }, [updateUserSettingsSuccess]);

  const handleChange = (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => {
    e.persist();
    const { checked } = e.target;
    if (e.target.name === "listingsRenewalNotifications" && isNotPremiumUser) {
      setPremiumModalVisible(true);
    } else {
      setSwitchInputData((state) => ({
        ...state,
        [e.target.name]: !checked,
      }));
    }
  };

  const onAddressChange = (address: AddressChangeParams) => {
    if (!address.formatted_address) return false;
    const { formatted_address, separated_fields } = address;
    let setFieldValues: IObjectKeys = {
      address: {
        addr: formatted_address,
        street_number: separated_fields?.street_number,
        street_name: separated_fields?.street_name,
        unit: separated_fields?.unit,
        city: separated_fields?.town,
        state: separated_fields?.short_state,
        zipCode: separated_fields?.postal_code,
        country: "USA",
      },
    };
    setFieldValues.street_number = separated_fields?.street_number;
    setFieldValues.defaultCity = separated_fields?.town ? separated_fields?.town : "";
    setFieldValues.defaultState = separated_fields?.state ? separated_fields?.state : "";
    form.setFieldsValue(setFieldValues);
  };

  useEffect(() => {
    if (!isValidateMessageVisible) {
      if (phoneVerified) {
        if (!userData.phone_verified) {
          setVerifyPhoneModalVisible(true);
          dispatch(setVerificationPhoneSuccess(false));
        }
      }
    }
  }, [isValidateMessageVisible, phoneVerified]);

  useEffect(() => {
    if (phoneCodeWasSent) {
      setTimeout(() => {
        setPhoneCodeWasSent(false);
      }, 1000);
    }
  }, [phoneCodeWasSent]);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData.agency) {
        if (
          userData?.agency?.subscription_level !== "free" &&
          userData?.agency?.subscription?.stripe_payment_method_id &&
          userData?.role === "Owner"
        ) {
          dispatch(getSubscriptions(userData?.agency?.subscription_id));
          dispatch(getUpcomingInvoices());
          dispatch(getInvoices());
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    if (queryObject.open_upgrade_modal) {
      setPremiumModalVisible(true);
    }
  }, [queryObject]);
  return (
    <div className={"settings-page-wrapper"}>
      <div
        className={`settings-content-wrapper ${
          //@ts-ignore
          width >= 599 && "scrollbar"
        } 
        overflow-y-auto test`}
      >
        <Card bordered hoverable className="settings-card" loading={loading}>
          <div className="settings-card-wrapper">
            <Row>
              <div className={"settings-card-wrapper__top"}>
                <Title data-test-id="account-settings-title" level={3}>
                  Account Settings
                </Title>
                {!SSO_SOURCES.includes(userData?.source?.name) && (
                  <Form.Item>
                    <Link
                      className="primary-text no-text-decoration reset-password-btn"
                      onClick={() => {
                        dispatch(resetPassword({ email: userData.email }));
                      }}
                      data-test-id="reset-password"
                    >
                      Reset Password
                    </Link>
                    <>{passwordResetLoading && <Spin size={"small"} />}</>
                  </Form.Item>
                )}
              </div>
              <div className={"settings-image-container-top__all"}>
                <UploadUserImage type={type} userData={userData} setImageData={setImageData} />
                <Col span={12} className={"settings-info"}>
                  <div>
                    Status:
                    <span className={"status"}>
                      <div
                        className={`status-circle ${
                          userData?.agency?.subscription_status !== "Subscribed" && "status-circle-alert"
                        }`}
                      ></div>
                      {userData?.agency?.subscription_status}
                    </span>
                  </div>
                  <div>
                    Seats:{" "}
                    <span className={"status-normal"}>
                      {
                        // eslint-disable-next-line no-restricted-globals
                        userData?.agency?.seats - userData?.agency?.agent_ids?.length
                      }{" "}
                      seats left
                    </span>
                  </div>
                  <div>
                    Renewal date:{" "}
                    <span
                      className={`status-normal ${
                        getIsLastOneWeek(userData?.agency?.subscription_renewal_date) && "last-one-week"
                      } `}
                    >
                      {moment(userData?.agency?.subscription_renewal_date).format(US_DATE_FORMAT)}
                    </span>
                  </div>
                </Col>
              </div>
              <Col span={24} className={"settings-info-form-body"}>
                <Form
                  onFieldsChange={handleFieldsChange}
                  form={form}
                  onFinish={onSubmit}
                  layout={"vertical"}
                  requiredMark
                  onFinishFailed={onFinishFailed}
                >
                  <div className={"form-content"}>
                    <Col span={12}>
                      <Form.Item name="name" label="Full Name" rules={[REQUIRED]}>
                        <Input autoComplete={"off"} />
                      </Form.Item>
                      <Form.Item className="mb-0-force ">
                        <div
                          className={`${
                            !userData?.phone_verified
                              ? "settings-info-phone-section"
                              : "settings-info-phone-section-verified"
                          }`}
                        >
                          <Form.Item name="phone" label="Phone Number" rules={[SETTINGS_PHONE_NUMBER_VALIDATION]}>
                            <MaskedInput
                              inputMode="numeric"
                              mask={PHONE_NUMBER_MASK}
                              placeholder={PHONE_NUMBER_PLACEHOLDER}
                            />
                          </Form.Item>
                          {userData.phone_verified && (
                            <p className={"settings-info-phone-section-verified--icon"}>
                              <CheckedIcon />
                              Verified
                            </p>
                          )}
                          {!userData.phone_verified && !isValidateMessageVisible && (
                            <>
                              <BaseButton
                                classnames={"rb-primary-btn md "}
                                data-test-id="verify-phone-number"
                                onClick={onVerifyPhoneClick}
                              >
                                Verify
                              </BaseButton>
                            </>
                          )}
                        </div>
                      </Form.Item>
                      <Form.Item name="email" label="Email" rules={[EMAIL_VALIDATION]}>
                        <Input autoComplete={"off"} />
                      </Form.Item>
                    </Col>
                  </div>
                  <div className={"save-settings-btn"}>
                    <BaseButton
                      classnames={"save-settings "}
                      variant="primary"
                      onClick={() => {
                        form.submit();
                      }}
                      dataTestId="save-settings-btn"
                    >
                      Save Settings
                    </BaseButton>
                  </div>
                </Form>
              </Col>
            </Row>
            {userData.role === ROLE_TYPES.OWNER && (
              <Row>
                <Col span={24} className={"account-setting-export"}>
                  <div className={"account-setting-export-top"}>
                    <Title level={3} style={{ marginBottom: "0px" }}>
                      Export Account Data
                    </Title>
                    <span>Request to export all your data.</span>
                  </div>
                </Col>
              </Row>
            )}
          </div>
          <PasswordConfirmModal
            visible={passwordModalVisible}
            onClose={() => {
              setPasswordModalVisible(false);
              form.setFieldsValue({ password: "" });
            }}
            onSubmit={(password) => {
              form.setFieldsValue({ password });
              form.submit();
            }}
          />
          <VerifyPhoneModal
            visible={verifyPhoneModalVisible}
            onClose={() => setVerifyPhoneModalVisible(false)}
            phoneCodeWasSent={false} // We always want to user be able click "Get it" button and defer to Api.
            setPhoneCodeWasSent={setPhoneCodeWasSent}
          />
        </Card>
      </div>
    </div>
  );
};
export default memo(RenterPortalSettings);
