import {IField} from "@flatfile/adapter/build/main/interfaces/obj.settings";

export const FLATFILE_DATA_KEY_LOCAL_STORAGE = "currentFlatfileRecords";
export const IMPORT_BY_JSON_FIELDS: IField[] = [
  { label: "Street Address", key: "street_name" },
  { label: "Street Number", key: "street_number" },
  { label: "Unit Number", key: "unit" },
  {
    label: "Town",
    key: "town",
    validators: [
      {
        validate: "required_with",
        fields: ["street_name", "unit", "state", "street_number", "state", "zip_code"]
      }
    ]
  },
  { label: "State", key: "state" },
  { label: "Neighborhood", key: "sub_area" },
  { label: "Sub Area", key: "sub_sub_area" },
  { label: "ZIP Code", key: "zip_code" },
  {
    label: "Available",
    key: "available",
    type: "select",
    validators: [{ validate: "required" }],
    options: []
  },
  { label: "Rent Price", key: "asked_rent" },
  { label: "Current Price", key: "current_rent" },
  {
    label: "Landlord Name",
    key: "landlord_name",
    validators: [{ validate: "required" }]
  },
  {
    label: "Landlord Email",
    key: "landlord_email",
    validators: [
      {
        validate: "required_without",
        fields: ["landlord_phone"]
      }
    ]
  },
  {
    label: "Landlord Phone Number",
    key: "landlord_phone",
    validators: [
      {
        validate: "required_without",
        fields: ["landlord_email"]
      }
    ]
  },
  { label: "Current Tenant Phone Number", key: "renter_phone" },
  { label: "Current Tenant Name", key: "renter_name" },
  { label: "Current Tenant Email", key: "renter_email" },
  {
    label: "Students Accepted (Unchecked = No)",
    key: "students_accepted",
    type: "checkbox"
  },
  { label: "Heat Type (Gas, Oil, Electric)", key: "heating" },
  {
    label: "Bedrooms",
    key: "full_bedrooms",
    validators: [{ validate: "required" }]
  },
  {
    label: "Has Half Bed",
    key: "half_bedrooms",
    type: "checkbox"
  },
  {
    label: "Full Baths",
    key: "full_bathrooms",
    validators: [{ validate: "required" }]
  },
  {
    label: "Has Half Bath",
    key: "half_bathrooms",
    type: "checkbox"
  },
  {
    label: "Floor Number",
    key: "floor_number"
  },
  {
    label: "Living area",
    key: "living_area_size"
  },

    {label: "Date Available", key: "availability_date"},
    {
        label: "Lease End Date (If Off-Market)",
        key: "lease_ending_date"
    },
    {
        label: "Has Additional Storage (Unchecked = No)",
        key: "storage",
        type: "checkbox"
    },

    {
        label: "Move Move-In Fees (Unchecked = No)",
        key: "move_in_fees",
        type: "checkbox"
    },
    {
        label: "Parking Type (Unchecked = No)",
        key: "parking_type",
        type: "checkbox"
    },
    {label: "Parking Rent", key: "parking_cost"},
    {
        label: "Has Laundry (In Building or Unit)",
        key: "laundry",
        type: "select",
        options: []
    },
    {label: "Note Title", key: "note_title"},
    {label: "Note Description", key: "note_description"},
    {
        label: "Outdoor Space (Unchecked = No)",
        key: "outdoor_space",
        type: "checkbox"
    },
    {
        label: "Pets Allowed (Unchecked = No)",
        key: "pets_allowed",
        type: "checkbox"
    },
    {
        label: "Air Conditioner (Unchecked = No)",
        key: "air_conditioning",
        type: "select",
        options: []
    },
    {
        label: "Has Dishwasher (Unchecked = No)",
        key: "dishwasher",
        type: "checkbox"
    },
    {
        label: "Elevator Building (Unchecked = No)",
        key: "elevator",
        type: "checkbox"
    },
    {
        label: "Images ( url , url2 , ... )",
        key: "images",
        type: "string"
    }
];
