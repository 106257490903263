import React from "react";

export const VerticalMenuDots = () => {
    return (
        <svg width="5" height="22" viewBox="0 0 5 22" fill="none" style={{pointerEvents:"none"}} xmlns="http://www.w3.org/2000/svg">
            <circle cx="2.20002" cy="2.2" r="2.2" transform="rotate(90 2.20002 2.2)" fill="#020202" />
            <circle cx="2.20002" cy="10.9998" r="2.2" transform="rotate(90 2.20002 10.9998)" fill="#020202" />
            <circle cx="2.20002" cy="19.8001" r="2.2" transform="rotate(90 2.20002 19.8001)" fill="#020202" />
        </svg>

    );
};
