import React, { useState } from "react";
import BaseModal from "../../BaseModal";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import "./styles.scss";
import { useForm } from "antd/es/form/Form";
import FilterForm from "./components/filter-form/FilterForm";
import FilterModalFooter from "./components/filter-modal-footer/FilterModalFooter";
import { globalHandleReset } from "../../../Listings/helpers/FilterFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  filterOptionsSelector,
  listingsViewTypeSelector,
  paginationValuesSelector,
} from "../../../../store/listings/selectors";
import { useHistory } from "react-router-dom";
import { IObjectKeys } from "../../../../helpers/globalTypes";
import { useLocation } from "react-router";
import LdsSpinner from "../../lds-spinner/LdsSpinner";
import { Spin } from "antd";
import { areasFacetsSelector } from "../../../../store/areas/selectors";

interface IFilterModal {
  isFilterModalVisible: boolean;
  closeModalHandler: () => void;
}
const initialBedroomsBathroomsState = {
  from: 0,
  to: 20,
};

const FilterModal = ({ isFilterModalVisible, closeModalHandler }: IFilterModal) => {
  const { width }: any = useWindowSize();
  const [form] = useForm();
  const [isLocationsSelectVisible, setIsLocationsSelectVisible] = useState(false);
  const areasFacets = useSelector(areasFacetsSelector);
  const { pathname } = useLocation();
  const [bedRoomsCount, setBedRoomsCount] = useState<IObjectKeys>(initialBedroomsBathroomsState);
  const [bathRoomsCount, setBathRoomsCount] = useState<IObjectKeys>(initialBedroomsBathroomsState);
  const [hasParking, setHasParking] = useState(false);
  const [justMyListings, setJustMyListings] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const [isClearAllClicked, setIsClearAllClicked] = useState(false);

  const closeAllLocationsHandler = () => {
    setIsLocationsSelectVisible(false);
  };
  const listingsFilterOptions = useSelector(filterOptionsSelector);
  const paginationValues = useSelector(paginationValuesSelector);
  const listingsViewType = useSelector(listingsViewTypeSelector);
  const handleClearAll = () => {
    localStorage.removeItem("single_selected_location_object");
    setIsClearAllClicked(true);
    setTimeout(() => {
      setIsClearAllClicked(false);
    }, 200);
    globalHandleReset(dispatch, history, paginationValues, listingsFilterOptions, listingsViewType);
    setBedRoomsCount(initialBedroomsBathroomsState);
    setBathRoomsCount(initialBedroomsBathroomsState);
    setHasParking(false);
    setJustMyListings(false);
    localStorage.removeItem("use_exact_numbers_selected");
    form.resetFields();
    form.submit();
  };
  return (
    <BaseModal
      modalTitle={
        isLocationsSelectVisible || pathname.includes("landlords") ? (
          <span>
            {!pathname.includes("landlords") ? (
              <>
                <span className={"base-filter-modal-return"} onClick={closeAllLocationsHandler}>
                  {"<"}
                </span>{" "}
              </>
            ) : (
              ""
            )}
            Filter by Location
          </span>
        ) : (
          "Filter by"
        )
      }
      isModalVisible={isFilterModalVisible}
      onCancel={closeModalHandler}
      style={width > 600 ? {} : { top: 20 }}
      modalFooter={[
        <FilterModalFooter key={1} closeModalHandler={closeModalHandler} handleClearAll={handleClearAll} />,
      ]}
      width={width >= 600 ? 583 : 433}
    >
      <Spin spinning={!Object.keys(areasFacets).length} indicator={<LdsSpinner />}>
        <FilterForm
          form={form}
          setIsLocationsSelectVisible={setIsLocationsSelectVisible}
          isLocationsSelectVisible={isLocationsSelectVisible && !pathname.includes("landlords")}
          isClearAllClicked={isClearAllClicked}
          paginationValues={paginationValues}
          listingsFilterOptions={listingsFilterOptions}
          listingsViewType={listingsViewType}
          bedRoomsCount={bedRoomsCount}
          setBedRoomsCount={setBedRoomsCount}
          bathRoomsCount={bathRoomsCount}
          setBathRoomsCount={setBathRoomsCount}
          hasParking={hasParking}
          setHasParking={setHasParking}
          justMyListings={justMyListings}
          setJustMyListings={setJustMyListings}
        />
      </Spin>
    </BaseModal>
  );
};

export default FilterModal;
