import React from "react";
import { Table } from "antd";
import "./styles.scss";
import { IObjectKeys } from "helpers/globalTypes";
import { TablePaginationConfig } from "antd/lib/table/interface";

interface IBaseTable {
  classnames?: string;
  bordered?: boolean;
  columns: any[];
  dataSource: any[];
  rowKey?: string | ((arg0: any) => string);
  onChange: (paginationOpt: TablePaginationConfig, _: any, sorter: any) => void;
  pagination?: IObjectKeys;
  loading?: boolean;
  dataTestId?: string | undefined;
  scroll?: any;
}

const BaseTable = ({
  columns,
  bordered,
  classnames,
  dataSource,
  loading,
  onChange,
  pagination,
  rowKey,
  dataTestId,
}: IBaseTable) => {
  return (
    <Table
      bordered={bordered}
      className={`base-table ${classnames}`}
      dataSource={dataSource}
      loading={loading}
      onChange={onChange}
      pagination={pagination}
      rowKey={rowKey}
      columns={columns}
      data-test-id={dataTestId}
    />
  );
};

export default React.memo(BaseTable);
