import { state } from './../../components/Listings/helpers/mockListings';
import * as types from "./types";
import { head, cloneDeep, initial } from "lodash";
import { IObjectKeys } from "../../helpers/globalTypes";
import { SET_QUERY_AND_PAGINATION } from "./types";

const initialState: IObjectKeys = {
  queryAndPagination: {
    page: 1,
    current: 1,
    pageSize: 10,
    sortField: "created_at",
    sortOrder: -1,
    searchValue: '',
  },
  inviteAgentFail: false,
  inviteAgentSuccess: false,
  inviteAgentLoading: false,
  resetInviteAgentSuccess: false,
  resetInviteAgentFail: false,
  agentError: null,
  teamMembers: [],
  teamMembersLoading: false,
  teamMembersError: null,
  removeAgentFromTeamSuccess: false,
  removeAgentFromTeamLoading: false,
  updateTeamNameSuccess: false,
  updateTeamNameLoading: false,
  total: 0,
};

export default function teamsReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.INVITE_AGENT:
      return {
        ...state,
        inviteAgentLoading: true,
        inviteAgentSuccess: false,
        inviteAgentFail: false
      };
    case types.INVITE_AGENT_SUCCESS:
      const newTeamMembers = cloneDeep(state.teamMembers);
      if (newTeamMembers.length > 3) {
        initial(newTeamMembers);
      }
      newTeamMembers.unshift(head(action.payload));
      return {
        ...state,
        inviteAgentSuccess: true,
        inviteAgentFail: false,
        teamMembers: newTeamMembers,
        agentError: "",
        total: state.total + 1,
        inviteAgentLoading: false
      };
    case types.SET_INVITE_AGENT_SUCCESS_FALSE:
      return {
        ...state,
        inviteAgentSuccess: action.payload
      };
    case types.INVITE_AGENT_FAIL:
      return {
        ...state,
        inviteAgentFail: true,
        inviteAgentSuccess: false,
        agentError: action.payload,
        inviteAgentLoading: false
      };
    case types.GET_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: [],
        teamMembersLoading: true,
        teamMembersError: null
      };

    case types.GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        teamMembers: action.payload.data,
        total: action.payload.total,
        teamMembersLoading: false
      };

    case types.GET_TEAM_MEMBERS_FAIL:
      return {
        ...state,
        teamMembersError: action.payload,
        total: 0,
        teamMembersLoading: false
      };
    case types.SET_TEAM_MEMBERS_LOADER_TO_TRUE:
      return {
        ...state,
        teamMembersLoading: true
      };
    case types.SET_TEAM_MEMBERS_LOADER_TO_FALSE:
      return {
        ...state,
        teamMembersLoading: false
      };
    case types.REMOVE_AGENT_FROM_TEAM_SUCCESS:
      const members = state.teamMembers.filter((member: IObjectKeys) => member._id !== action.payload.removedAgentId);
      return {
        ...state,
        teamMembers: [...members],
        total: state.total - 1,
        removeAgentFromTeamSuccess: true,
        removeAgentFromTeamLoading: false
      };
    case types.REMOVE_AGENT_FROM_TEAM:
      return {
        ...state,
        removeAgentFromTeamLoading: true
      };
    case types.RESEND_CONFIRMATION_LINK:
      return {
        ...state,
        resetInviteAgentSuccess: false,
        resetInviteAgentFail: false
      };

    case types.RESEND_CONFIRMATION_LINK_SUCCESS:
      return {
        ...state,
        resetInviteAgentSuccess: true,
        resetInviteAgentFail: false
      };
    case types.RESEND_CONFIRMATION_LINK_FAIL:
      return {
        ...state,
        resetInviteAgentFail: true,
        resetInviteAgentSuccess: false
      };
    case types.DEFAULT_INVITE_SUCCESS:
      return {
        ...state,
        resetInviteAgentSuccess: false,
        inviteAgentSuccess: false
      };
    case SET_QUERY_AND_PAGINATION:
      return {
        ...state,
        queryAndPagination: {
          ...state.queryAndPagination,
          ...action.payload
        }
      };
    case types.UPDATE_TEAM_NAME:
      return {
        ...state,
        updateTeamNameLoading: true,
        updateTeamNameSuccess: false
      };
    case types.UPDATE_TEAM_NAME_SUCCESS:
      return {
        ...state,
        updateTeamNameLoading: false,
        updateTeamNameSuccess: true
      };
    case types.UPDATE_TEAM_NAME_FAIL:
      return {
        ...state,
        updateTeamNameLoading: false,
        updateTeamNameSuccess: false
      };
    default:
      return state;
  }
}
