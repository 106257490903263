import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import { EnumInterface } from "../../../../../../../helpers/globalTypes";
import {getReqCommunicationEnumInputs} from "../../../../../../../helpers/globalFunctions";
import { requirementEnumsSelector } from "../../../../../../../store/enums/selectors";
import "./CommunicationMethod.scss"
import TextIcon from "../../icons/communication-method-icons/TextIcon";
import PhoneCallIcon from "../../icons/communication-method-icons/PhoneCallIcon";
import EmailIcon from "../../icons/communication-method-icons/EmailIcon";

const CommunicationMethod = forwardRef((props, ref: any) => {
  const requirementEnums = useSelector(requirementEnumsSelector);
  const iconsArray: any[] = [
    <TextIcon/>,
    <PhoneCallIcon/>,
    <EmailIcon/>,
  ]
  return (
    <div className={"check-contact-methods"} ref={ref}>
      {getReqCommunicationEnumInputs(
        requirementEnums.find(
          (item: EnumInterface) => item.key === "contact_preference",
        ),
        "inline",
          iconsArray,
        "",
        "method_contact",
      )}
    </div>
  );
});

export default CommunicationMethod;
