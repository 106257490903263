import React from "react";
import { Form, Checkbox, Col } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { IObjectKeys } from "helpers/globalTypes";
import { REQUIRED } from "helpers/validations";

const RenterDisclosures = ({
  renterName,
  screeningData,
  setScreeningData,
  form,
  onFinish,
}: {
  renterName: string;
  screeningData: any;
  setScreeningData: any;
  form: any;
  onFinish: any;
}) => {
  const disclosuresCopy = (
    <div>
      <p>
        Welcome to TransUnion® ShareAble®, found at the web site, [www.shareable.com], or
        through an integration platform (the “Site”) - a product of TransUnion Rental Screening Solutions, Inc.
        (“TURSS”). This Terms of Use Agreement (“Agreement”) contains the terms and conditions upon which you (“you,” or
        the “Member”) may access ShareAble or any products or services offered through the Site. You agree to be legally
        bound by these terms.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>General Terms</p>
      <p>
        TURSS provides access to ShareAble to allow you to authorize and agree to provide your credit and public record
        information (“Consumer Report Information” or “Credit Information”) to third parties through this platform. From
        time to time TURSS and or the owner of this platform may revise these terms and conditions. Revisions will be
        effective when posted or as otherwise stated. Additional terms and conditions may apply to specifics of other
        products and services, or to the participation in future contests or surveys.
      </p>
      <br />
      <p>
        After agreeing to the terms of this Agreement, you will be requested to authenticate your identity (using
        information from public records and credit information related to the identifying information that you provide
        (e.g. Social Security Number, Date of Birth) (“Identifying Information”) and acknowledge that upon successful
        completion of identity verification, your Consumer Report Information will be provided to third parties
        indicated on your request via this platform. You acknowledge and agree that TURSS and the platform provider is
        not responsible for any actions or decisions made by any third parties with whom you have agreed to share your
        Consumer Report Information.
      </p>
      <br />
      <p>
        The images, text, screens, web pages, materials, data, content and other information (“Content”) used and
        displayed through  ShareAble and/or the Site are the property of TURSS or its licensors and are protected by
        copyright, trademark and other laws. In addition to its rights in individual elements of the Content within the
        Site, TURSS owns intellectual property rights in the selection, coordination, arrangement and enhancement of
        such Content. None of the Content may be copied, displayed, distributed, downloaded, licensed, modified,
        published, reposted, reproduced, reused, sold, transmitted, used to create a derivative work or otherwise used
        for public or commercial purposes without the express written permission of TURSS or the owner of the Content.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>Disclaimer of Warranties and Liabilities</p>
      <br />
      <p>
        SHAREABLE AND THE SITE, INCLUDING ALL CONTENT, CREDIT INFORMATION, PRODUCTS AND SERVICES MADE AVAILABLE ON OR
        ACCESSED THROUGH THIS SITE, ARE PROVIDED TO YOU “AS IS”. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW,
        NEITHER TURSS NOR ITS AFFILIATES NOR ITS DATA PROVIDERS MAKE ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND
        WHATSOEVER AS TO THE CONTENT, CREDIT INFORMATION, PRODUCTS OR SERVICES AVAILABLE ON OR ACCESSED THROUGH
        SHAREABLE AND THE SITE, THAT YOU OR A THIRD PARTY WILL HAVE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO
        SHAREABLE OR THE SITE, PRODUCTS OR SERVICES OR THAT SHAREABLE, THE SITE, PRODUCTS OR SERVICES WILL BE
        ERROR-FREE. IN ADDITION, TURSS AND ITS AFFILIATES AND ITS DATA PROVIDERS DISCLAIM ALL EXPRESS OR IMPLIED
        WARRANTIES, INCLUDING TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND
        INFORMATIONAL CONTENT. THEREFORE, YOU AGREE THAT YOUR ACCESS TO AND USE OF SHAREABLE, THE SITE, PRODUCTS,
        SERVICES AND CONTENT ARE AT YOUR OWN RISK. ADDITIONALLY, YOU AGREE THAT THE CREDIT INFORMATION THAT YOU
        AUTHORIZE TURSS TO SHARE WITH THIRD PARTIES IS NOT ERROR-FREE AND MAY INCLUDE INFORMATION THAT DOES NOT PERTAIN
        TO YOU AND TURSS AND ITS AFFILIATES AND ITS DATA PROVIDERS ARE NOT RESPONSIBLE OR LIABLE FOR ANY ACTION OR
        DECISION TAKEN BY A THIRD PARTY BASED ON THE CREDIT INFORMATION. BY USING OUR SITE, YOU ACKNOWLEDGE AND AGREE
        THAT NEITHER TURSS NOR ITS AFFILIATES NOR ITS DATA PROVIDERS HAVE ANY LIABILITY TO YOU (WHETHER BASED IN
        CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE) FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR SPECIAL
        DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR ACCESS TO OR USE OF  SHAREABLE, THE SITE, CONTENT,
        PRODUCTS OR SERVICES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), INCLUDING LIABILITY
        ASSOCIATED WITH ANY VIRUSES WHICH MAY INFECT YOUR COMPUTER EQUIPMENT. YOU ACKNOWLEDGE AND AGREE THAT TURSS’S
        DATA PROVIDERS ARE A THIRD PARTY BENEFICIARY OF THE PROVISIONS OF THIS SECTION, WITH RIGHT OF ENFORCEMENT.
      </p>
      <br />
      <p>
        TURSS reserves the right to publish or use any responses, questions or comments emailed to TURSS promotional or
        other purposes without any further permission, notice or payment of any kind to the sender. All such submissions
        are the property of TURSS.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>Registration and Accurate Information</p>
      <br />
      <p>
        If you decide to register on the Site or use ShareAble, you may be required to register, provide personal
        information, and select a user name and password. You agree to provide accurate information in your registration
        and not to share your password with third parties. You agree not to impersonate another person or to select or
        use a user name or password of another person. You agree to notify TURSS promptly of any unauthorized use of
        your account and of any loss, theft or disclosure of your password. Failure to comply with these requirements
        shall constitute a breach of these terms and conditions and shall constitute grounds for immediate termination
        of your account and your right to use the Site.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>Our Privacy Policy</p>
      <br />
      <p>
        By using the Site or purchasing products or services, you agree that we may use and share your personal
        information in accordance with the terms of our Privacy Statement. Our privacy statement can be reached at
        www.shareable.com.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>Fair Credit Reporting Act</p>
      <br />
      <p>
        The Fair Credit Reporting Act allows you to obtain from each consumer reporting agency a disclosure of all the
        information in your consumer file at the time of the request. Full disclosure of information in your file at a
        consumer reporting agency must be obtained directly from such consumer reporting agency. The credit reports
        provided or requested through our Site are not intended to constitute the disclosure of information by a
        consumer reporting agency as required by the Fair Credit Reporting Act or similar laws.
      </p>
      <br />
      <p>You are entitled to receive a free copy of your consumer report from a consumer reporting agency if:</p>
      <br />
      <p>
        You have been denied or were otherwise notified of an adverse action related to credit, insurance, employment,
        or a government granted license or other government granted benefit within the past sixty (60) days based on
        information in a credit report provided by such agency. You have been denied house/apartment rental or were
        required to pay a higher deposit than usually required within the past sixty (60) days based on information in a
        consumer report provided by such agency. You certify in writing that you are a recipient of public welfare
        assistance. You certify in writing that you have reason to believe that your file at such credit reporting
        agency contains inaccurate information due to fraud. In addition, if you are a resident of Colorado, Maine,
        Maryland, Massachusetts, New Jersey, or Vermont, you may receive one free copy of your consumer report each year
        from the consumer reporting agencies. If you are a resident of Georgia, you may receive two free copies of your
        consumer report each year from the credit bureaus. Otherwise, a consumer reporting agency may impose a
        reasonable charge for providing you with a copy of your consumer report.
      </p>
      <br />
      <p>
        The Fair Credit Reporting Act provides that you may dispute inaccurate or incomplete information in your
        consumer report. YOU ARE NOT REQUIRED TO PURCHASE YOUR CONSUMER REPORT FROM THE SITE OR  SHAREABLE IN ORDER TO
        DISPUTE INACCURATE OR INCOMPLETE INFORMATION IN YOUR REPORT OR TO RECEIVE A COPY OF YOUR REPORT FROM EQUIFAX,
        EXPERIAN OR TRANSUNION, THE THREE NATIONAL CREDIT REPORTING AGENCIES, OR FROM ANY OTHER CREDIT REPORTING AGENCY.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>Term and Termination; Modification</p> <br />
      <p>
        This Agreement will take effect at the time you click “I Accept,” and shall terminate (a) when either party
        gives notice of its intention to terminate to the other party hereto, to TURSS via the toll-free number set
        forth on the web site, or (b) if TURSS discontinues providing  ShareAble or access to the Site.
      </p>
      <br />
      <p>
        TURSS may (i) change the terms of this Agreement or the feature of the Service, or (ii) change the Site,
        including eliminating or discontinuing any content or feature of the Site, restricting the hours of
        availability, or limiting the amount of use of the Site without notice. If you use ShareAble and/or the Site
        after TURSS has implemented such changes, you agree to be bound by all of the changes made to the Site or to
        this Agreement. You are expected to review the Site periodically to ensure familiarity with any posted notices
        of modification.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>Policy Regarding Minors</p> <br />
      <p>
        We define minors as individuals under the age of 18. Our Web Site is not intended for the use of minors and we
        do not intend to collect information about minors through our Web Site. You must be at least 18 to access any
        products through this website.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>Notices</p> <br />
      <p>You should send any notices or other communications regarding ShareAble or our Site to:</p> <br />
      <p>
        TransUnion Rental Screening Solutions
        <br />
        Attn.: Counsel for TURSS
        <br />
        555 W. Adams Street
        <br />
        Chicago, IL 60661.
      </p>
      <br />
      <p>
        Except as otherwise provided on this Site, we may send any notices to you to the most recent e-mail address you
        have provided to us or, if you have not provided an e-mail address, to any e-mail or postal address that we
        believe is your address. If you wish to update your registration information, please log in to your account and
        visit the “Your Account” section from the main menu.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>Applicable Law</p> <br />
      <p>
        The laws applicable to the interpretation of these terms and conditions shall be the laws of the State of
        Illinois, USA, and applicable federal law, without regard to any conflict of law provisions. TURSS can provide
        credit reports only on individuals who have established credit in the United States. Those who choose to access
        this Site from outside the United States do so on their own initiative and are responsible for compliance with
        local laws. You agree that any and all disputes arising under this Agreement or out of TURSS’ provision of
        services to you, if submitted to a court of law, shall be submitted to the state and federal courts of Northern
        District of Illinois, USA.
      </p>
      <br />
      <p style={{ fontWeight: "bold" }}>Permission Statement</p> <br />
      <p>
        You understand that by clicking on the “I Accept” button immediately following this notice, you are providing
        written authorization to TransUnion Rental Screening Solutions to obtain information from your personal credit
        profile from TransUnion and public records sources. You instruct TransUnion Rental Screening Solutions to obtain
        such information and to and provide it to certain identified third parties who are requesting this information
        about you. Before you may use TransUnion ShareAble, we must obtain written authorization from you to give us
        permission to obtain your TransUnion credit history and public records report and share them with certain
        identified third parties.
      </p>
      <br />
      <p>
        You further understand that, by providing such written instruction, such third parties may include multiple
        subscribers to the ShareAble service who have separately certified a permissible purpose to obtain your
        information (each a “Joint User”). Such Joint Users, may include, for example, a landlord and a landlord’s
        leasing agent; multiple employees of a property management company; or other Joint Users who have a shared
        reason to obtain and review your report in connection with an application for rental housing.
      </p>
    </div>
  );

  const onValuesChange = (changedValues: any) => {
    screeningData.acceptTerms = changedValues.acceptedTermsAndConditions[0];
    setScreeningData(screeningData);
  };

  return (
    <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
      <div className={"listing-add-edit-modal--header"}>
        <h3>MANDATORY TERMS FOR EACH CONSUMER AGREEMENT</h3>
      </div>
      <div style={{ marginTop: "10px", maxHeight: "300px", overflowY: "scroll", overflowX: "hidden" }}>
        {disclosuresCopy}
      </div>
      <Form.Item name="acceptedTermsAndConditions" required style={{ marginTop: "10px" }} rules={[REQUIRED]}>
        <Checkbox.Group>
          <Checkbox value={true}>I agree to these terms and conditions</Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

export default RenterDisclosures;
