import React from "react";
import "./styles.scss";
import CodeCheck from "./components/code-check/CodeCheck";

const ResetPassword = () => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <CodeCheck />
    </div>
  );
};

export default ResetPassword;
