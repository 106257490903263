import React from "react";

export const ShareSquareIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9C0 4.02944 4.02944 0 9 0H39C43.9706 0 48 4.02944 48 9V40C48 44.9706 43.9706 49 39 49H9C4.02944 49 0 44.9706 0 40V9Z"
        fill="black"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M31.455 18.4999C31.455 20.0706 30.1819 21.3438 28.6113 21.3438C27.0407 21.3438 25.7676 20.0706 25.7676 18.4999C25.7676 16.9295 27.0407 15.6562 28.6113 15.6562C30.1819 15.6562 31.455 16.9295 31.455 18.4999Z"
          fill="white"
        />
        <path
          d="M28.6113 22.0001C26.681 22.0001 25.1113 20.4302 25.1113 18.4999C25.1113 16.5698 26.681 15 28.6113 15C30.5416 15 32.1112 16.5698 32.1112 18.4999C32.1112 20.4302 30.5416 22.0001 28.6113 22.0001ZM28.6113 16.3125C27.4047 16.3125 26.4238 17.2943 26.4238 18.4999C26.4238 19.7057 27.4047 20.6876 28.6113 20.6876C29.8179 20.6876 30.7987 19.7057 30.7987 18.4999C30.7987 17.2943 29.8179 16.3125 28.6113 16.3125Z"
          fill="white"
        />
        <path
          d="M31.455 32.5001C31.455 34.0706 30.1819 35.3438 28.6113 35.3438C27.0407 35.3438 25.7676 34.0706 25.7676 32.5001C25.7676 30.9295 27.0407 29.6562 28.6113 29.6562C30.1819 29.6562 31.455 30.9295 31.455 32.5001Z"
          fill="white"
        />
        <path
          d="M28.6113 36C26.681 36 25.1113 34.4302 25.1113 32.5001C25.1113 30.5698 26.681 29 28.6113 29C30.5416 29 32.1112 30.5698 32.1112 32.5001C32.1112 34.4302 30.5416 36 28.6113 36ZM28.6113 30.3125C27.4047 30.3125 26.4238 31.2943 26.4238 32.5001C26.4238 33.7057 27.4047 34.6876 28.6113 34.6876C29.8179 34.6876 30.7987 33.7057 30.7987 32.5001C30.7987 31.2943 29.8179 30.3125 28.6113 30.3125Z"
          fill="white"
        />
        <path
          d="M19.2051 25.4999C19.2051 27.0706 17.9319 28.3436 16.3613 28.3436C14.7908 28.3436 13.5176 27.0706 13.5176 25.4999C13.5176 23.9293 14.7908 22.6562 16.3613 22.6562C17.9319 22.6562 19.2051 23.9293 19.2051 25.4999Z"
          fill="white"
        />
        <path
          d="M16.3613 28.9999C14.4311 28.9999 12.8613 27.4302 12.8613 25.4999C12.8613 23.5696 14.4311 22 16.3613 22C18.2916 22 19.8614 23.5696 19.8614 25.4999C19.8614 27.4302 18.2916 28.9999 16.3613 28.9999ZM16.3613 23.3125C15.1547 23.3125 14.1738 24.2942 14.1738 25.4999C14.1738 26.7057 15.1547 27.6874 16.3613 27.6874C17.568 27.6874 18.5489 26.7057 18.5489 25.4999C18.5489 24.2942 17.568 23.3125 16.3613 23.3125Z"
          fill="white"
        />
        <path
          d="M18.4276 25.08C18.1231 25.08 17.8273 24.9216 17.6663 24.6381C17.4274 24.219 17.5745 23.6844 17.9936 23.4445L26.1126 18.8158C26.5317 18.5752 27.0664 18.7223 27.3062 19.143C27.5451 19.5621 27.398 20.0968 26.9789 20.3366L18.8597 24.9653C18.7232 25.0432 18.5745 25.08 18.4276 25.08Z"
          fill="white"
        />
        <path
          d="M26.5459 32.2988C26.3988 32.2988 26.2501 32.262 26.1136 32.1841L17.9945 27.5555C17.5753 27.3166 17.4284 26.7819 17.6673 26.3618C17.9052 25.9419 18.4407 25.794 18.8608 26.0347L26.9799 30.6634C27.3991 30.9022 27.546 31.4369 27.3071 31.857C27.1453 32.1404 26.8495 32.2988 26.5459 32.2988Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(12 15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
