import React from "react";

export const DragIcon = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.64453 27.4634C6.64453 28.0299 7.10339 28.4888 7.66992 28.4888C8.23613 28.4888 8.69531 28.0299 8.69531 27.4634V25.9253C8.69531 25.3591 8.23613 24.8999 7.66992 24.8999C7.10339 24.8999 6.64453 25.3591 6.64453 25.9253V27.4634Z"
        fill="#141414"
      />
      <path
        d="M7.66992 17.2095C8.23613 17.2095 8.69531 16.7506 8.69531 16.1841V14.646C8.69531 14.0798 8.23613 13.6206 7.66992 13.6206C7.10339 13.6206 6.64453 14.0798 6.64453 14.646V16.1841C6.64453 16.7506 7.10339 17.2095 7.66992 17.2095Z"
        fill="#141414"
      />
      <path
        d="M6.64453 21.8237C6.64453 22.3903 7.10339 22.8491 7.66992 22.8491C8.23613 22.8491 8.69531 22.3903 8.69531 21.8237V20.2856C8.69531 19.7194 8.23613 19.2603 7.66992 19.2603C7.10339 19.2603 6.64453 19.7194 6.64453 20.2856V21.8237Z"
        fill="#141414"
      />
      <path
        d="M7.66992 32.5903H9.20801C9.77422 32.5903 10.2334 32.1315 10.2334 31.5649C10.2334 30.9987 9.77422 30.5396 9.20801 30.5396H7.66992C7.10339 30.5396 6.64453 30.9987 6.64453 31.5649C6.64453 32.1315 7.10339 32.5903 7.66992 32.5903Z"
        fill="#141414"
      />
      <path
        d="M33.9199 17.0039C34.4861 17.0039 34.9453 16.545 34.9453 15.9785V14.4404C34.9453 13.8742 34.4861 13.415 33.9199 13.415C33.3534 13.415 32.8945 13.8742 32.8945 14.4404V15.9785C32.8945 16.545 33.3534 17.0039 33.9199 17.0039Z"
        fill="#141414"
      />
      <path
        d="M29.9209 15.3638C30.4871 15.3638 30.9463 14.9049 30.9463 14.3384C30.9463 13.7722 30.4871 13.313 29.9209 13.313H28.3828C27.8163 13.313 27.3574 13.7722 27.3574 14.3384C27.3574 14.9049 27.8163 15.3638 28.3828 15.3638H29.9209Z"
        fill="#141414"
      />
      <path
        d="M13.002 13.313H11.4639C10.8973 13.313 10.4385 13.7722 10.4385 14.3384C10.4385 14.9049 10.8973 15.3638 11.4639 15.3638H13.002C13.5682 15.3638 14.0273 14.9049 14.0273 14.3384C14.0273 13.7722 13.5682 13.313 13.002 13.313Z"
        fill="#141414"
      />
      <path
        d="M24.2812 15.3638C24.8475 15.3638 25.3066 14.9049 25.3066 14.3384C25.3066 13.7722 24.8475 13.313 24.2812 13.313H22.7432C22.1766 13.313 21.7178 13.7722 21.7178 14.3384C21.7178 14.9049 22.1766 15.3638 22.7432 15.3638H24.2812Z"
        fill="#141414"
      />
      <path
        d="M32.8945 21.6187C32.8945 22.1852 33.3534 22.644 33.9199 22.644C34.4861 22.644 34.9453 22.1852 34.9453 21.6187V20.0806C34.9453 19.5144 34.4861 19.0552 33.9199 19.0552C33.3534 19.0552 32.8945 19.5144 32.8945 20.0806V21.6187Z"
        fill="#141414"
      />
      <path
        d="M18.6416 15.3638C19.2078 15.3638 19.667 14.9049 19.667 14.3384C19.667 13.7722 19.2078 13.313 18.6416 13.313H17.1035C16.537 13.313 16.0781 13.7722 16.0781 14.3384C16.0781 14.9049 16.537 15.3638 17.1035 15.3638H18.6416Z"
        fill="#141414"
      />
      <path
        d="M12.2842 31.5649C12.2842 32.1315 12.743 32.5903 13.3096 32.5903H14.8477C15.4139 32.5903 15.873 32.1315 15.873 31.5649C15.873 30.9987 15.4139 30.5396 14.8477 30.5396H13.3096C12.743 30.5396 12.2842 30.9987 12.2842 31.5649Z"
        fill="#141414"
      />
      <path
        d="M34.9793 5.08398H20.7949C20.2284 5.08398 19.7695 5.54317 19.7695 6.10937C19.7695 6.6759 20.2284 7.13477 20.7949 7.13477H34.9793C35.5458 7.13477 36.0047 6.6759 36.0047 6.10937C36.0047 5.54317 35.5458 5.08398 34.9793 5.08398Z"
        fill="#141414"
      />
      <path
        d="M10.626 5.08496C10.3565 5.08496 10.0921 5.19327 9.90147 5.38521C9.71017 5.57587 9.60059 5.83958 9.60059 6.11035C9.60059 6.37984 9.71017 6.64452 9.90147 6.83517C10.0921 7.02583 10.3565 7.13574 10.626 7.13574C10.8958 7.13574 11.1601 7.02583 11.3508 6.83517C11.5415 6.64452 11.6514 6.37984 11.6514 6.11035C11.6514 5.83958 11.5421 5.57587 11.3508 5.38521C11.1601 5.19327 10.8967 5.08496 10.626 5.08496Z"
        fill="#141414"
      />
      <path
        d="M6.40576 5.08496C6.13564 5.08496 5.87128 5.19327 5.68062 5.38521C5.489 5.57587 5.38037 5.84055 5.38037 6.11035C5.38037 6.37984 5.489 6.64452 5.68062 6.83517C5.87128 7.02583 6.13499 7.13574 6.40576 7.13574C6.67525 7.13574 6.93896 7.02583 7.13026 6.83517C7.32092 6.64452 7.43115 6.37984 7.43115 6.11035C7.43115 5.84055 7.32124 5.57587 7.13026 5.38521C6.93896 5.19327 6.67525 5.08496 6.40576 5.08496Z"
        fill="#141414"
      />
      <path
        d="M14.8477 5.08496C14.5782 5.08496 14.3132 5.19455 14.1225 5.38521C13.9319 5.57587 13.8223 5.83958 13.8223 6.11035C13.8223 6.37984 13.9319 6.64452 14.1225 6.83517C14.3132 7.02583 14.5782 7.13574 14.8477 7.13574C15.1171 7.13574 15.3818 7.02583 15.5725 6.83517C15.7631 6.64452 15.873 6.37984 15.873 6.11035C15.873 5.83958 15.7631 5.57587 15.5725 5.38521C15.3818 5.19455 15.1171 5.08496 14.8477 5.08496Z"
        fill="#141414"
      />
      <path
        d="M2.74805 32.5908C3.01753 32.5908 3.28221 32.4809 3.47287 32.2903C3.66353 32.0996 3.77344 31.8349 3.77344 31.5654C3.77344 31.2956 3.66353 31.0309 3.47287 30.8403C3.28221 30.6496 3.01753 30.54 2.74805 30.54C2.47856 30.54 2.21356 30.6496 2.0229 30.8403C1.83224 31.0309 1.72266 31.2956 1.72266 31.5654C1.72266 31.8349 1.83224 32.0996 2.0229 32.2903C2.21356 32.4809 2.47856 32.5908 2.74805 32.5908Z"
        fill="#141414"
      />
      <path
        d="M53.9221 48.5411L48.6365 43.2555L52.2783 41.6812C52.6788 41.5082 52.9262 41.1003 52.8942 40.6654C52.8621 40.23 52.5583 39.8628 52.1367 39.7496L48.6856 38.8249V26.3997C48.6856 25.8332 48.2264 25.3743 47.6602 25.3743H39.8672V4.85303C39.8672 3.15697 38.4874 1.77686 36.791 1.77686H4.79883C3.10245 1.77686 1.72266 3.15697 1.72266 4.85303V27.4116C1.72266 27.9782 2.18152 28.437 2.74805 28.437C3.31426 28.437 3.77344 27.9782 3.77344 27.4116V10.4414H37.8164V25.3743H21.4102C20.8436 25.3743 20.3848 25.8332 20.3848 26.3997V30.5391H18.9492C18.3827 30.5391 17.9238 30.9983 17.9238 31.5645C17.9238 32.131 18.3827 32.5899 18.9492 32.5899H20.3848V37.8193H4.79883C4.23326 37.8193 3.77344 37.3595 3.77344 36.794V35.6148C3.77344 35.0485 3.31426 34.5894 2.74805 34.5894C2.18152 34.5894 1.72266 35.0485 1.72266 35.6148V36.794C1.72266 38.4903 3.10245 39.8701 4.79883 39.8701H20.3848V43.6259C20.3848 44.1922 20.8436 44.6513 21.4102 44.6513H37.6767L39.6964 52.1896C39.8095 52.6109 40.1767 52.915 40.6122 52.9467C41.047 52.9791 41.455 52.7317 41.6283 52.3312L43.2029 48.6894L48.4885 53.975C48.6808 54.1673 48.9416 54.2753 49.2133 54.2753C49.4854 54.2753 49.7462 54.1673 49.9385 53.975L53.9221 49.9914C54.3227 49.5908 54.3227 48.9416 53.9221 48.5411ZM3.77344 8.39063V4.85303C3.77344 4.28746 4.23326 3.82764 4.79883 3.82764H36.791C37.3566 3.82764 37.8164 4.28746 37.8164 4.85303V8.39063H3.77344ZM22.4356 42.6006V27.4251H46.6348V38.2753L36.8586 35.6558C36.5046 35.5609 36.1274 35.6622 35.8682 35.9214C35.6093 36.18 35.508 36.5578 35.6029 36.9119L37.1272 42.6006H22.4356ZM49.2133 51.7999L43.5855 46.1718C43.391 45.9779 43.1299 45.8715 42.8604 45.8715C42.7966 45.8715 42.7322 45.8776 42.6681 45.8898C42.3346 45.9536 42.0542 46.1782 41.9193 46.49L40.915 48.8131L38.0436 38.0965L48.7599 40.9683L46.4374 41.9722C46.1256 42.1068 45.901 42.3871 45.8372 42.7207C45.7735 43.0546 45.8789 43.3981 46.1192 43.6384L51.7473 49.2666L49.2133 51.7999Z"
        fill="#141414"
      />
    </svg>
  );
};
