import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import BaseModal from "../Shared/BaseModal";
import { Form, Col, Row, Input, Modal, Spin, Alert } from "antd";
import { windowSelector } from "../../store/app/selectors";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { UploadFile } from "antd/es/upload/interface";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ListingImagesEmptyState from "components/ListingView/ListingImagesSection/ListingImagesEmptyState";
import ReportProblemApi from "../../api/ReportProblem";
import SuccessCheck from "../icons/SuccessCheck";
import { AnalyticsService } from "helpers/analyticsService";
import { useHistory } from "react-router";

const { confirm } = Modal;

interface IReportAProblem {
  onClose: Function;
}

const ReportAProblem = (props: IReportAProblem) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loadingImages, setLoadingImages] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const window = useSelector(windowSelector);
  const { type } = window;
  const history = useHistory();

  const onChange = useCallback(({ file, fileList: currentFiles, event }: UploadChangeParam) => {
    setFileList(currentFiles);
  }, []);

  const onFileRemove = async (file: any) => {
    try {
      await ReportProblemApi.deleteReportImage(file?.response?._id || file._id);
      setFileList(
        fileList.filter((f: any) => {
          if (!file._id) {
            return f?.response?._id !== file?.response?._id;
          }
          return f.uid !== file._id;
        })
      );

      setLoadingImages(false);
      return Promise.resolve(false);
    } catch (error) {
      setError("Something went wrong ,try Again , please!");
    }
  };

  const showConfirm = (file: UploadFile) => {
    confirm({
      title: "Do you Want to delete these image?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setLoadingImages(true);
        onFileRemove(file);
      },
      onCancel() { }
    });
  };
  const onPreview = () => {
    return;
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const res: any = await ReportProblemApi.sendReportBugEmail({
        reportFeedback: data.report_feedback,
        images: fileList
      });
      if (!res.success) throw res;
      setSuccess(true);

      AnalyticsService.reportEvent("form_submit", {
        label: "report a problem | success"
      });
    } catch (error) {
      setError("Something went wrong ,try Again , please!");
      AnalyticsService.reportEvent("form_submit", {
        label: "report a problem | error",
        errorMessage: error.reportFeedback
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {!isSuccess && (
        <BaseModal
          classname={"report-a-problem-modal"}
          cancelButtonProps={{ style: { display: "none" } }}
          okText={"Send Feedback"}
          modalTitle={"Found a problem?"}
          okButtonProps={{
            className: "rb-primary-btn sm send-feedback-btn"
          }}
          isModalVisible={true}
          onOk={() => form.submit()}
          onCancel={() => {
            props.onClose();
          }}
        >
          <Spin spinning={isLoading} tip="Loading...">
            {error && <Alert message={error} type="error" />}

            <Form layout={"horizontal"} className="report-a-problem-form" form={form} onFinish={onSubmit}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="report_feedback"
                    data-test-id={"ls_description"}
                    label={"Please provide any feedback here"}
                    className={"report-feedback-box"}
                    labelAlign="left"
                  >
                    <TextArea className={"feedback-area"} />
                  </Form.Item>
                </Col>
                <Col style={{ width: "100%" }}>
                  <h3 className="report-page-dragdrop-header text-left">
                    Add a screenshot <span>( optional )</span>
                  </h3>
                  <ListingImagesEmptyState
                    addEditModeClassname={"listing-add-edit-mode"}
                    reportProblem={true}
                    showConfirm={showConfirm}
                    setFileList={setFileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    enablePreview={true}
                    canEdit={true}
                    fileList={fileList}
                    reportProblemImageDrogText={type === "dektop" ? "Drag and drop your images\nanywhere here" : "Click to upload images here"}
                    colViewConfig={{
                      xs: 24,
                      md: 8
                    }}
                    imageCardClassname={"uploaded-image-card add-edit-image-card-wrapper"}
                    showConfirmation={showConfirm}
                  />
                </Col>
              </Row>
            </Form>
          </Spin>
        </BaseModal>
      )}
      {isSuccess && (
        <BaseModal
          classname={"report-a-problem-modal"}
          cancelButtonProps={{ style: { display: "none" } }}
          modalTitle={""}
          okButtonProps={{
            style: { display: "none" }
          }}
          isModalVisible={true}
          onCancel={() => {
            props.onClose();
          }}
        >
          <Row className="report-bug-success-row">
            <Col className="report-bug-success-col">
              <SuccessCheck />
              <h4 className="text-center">Feedback Sent!</h4>
              <p className="resport-bug-succesMessage text-center">Thanks for helping make RentBase better! Your feedback is appreciated and will be reviewed.</p>
            </Col>
          </Row>
        </BaseModal>
      )}
    </>
  );
};

export default ReportAProblem;
