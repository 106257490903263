import React, { memo, useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { useDispatch } from "react-redux";
import { getAreas } from "../../../../../../../../../store/areas/action";
import { IObjectKeys } from "../../../../../../../../../helpers/globalTypes";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import { AddressChangeParams } from "components/Shared/PlacesAutocomplete/types";
import { IlocationItem } from "../types/types";
import { FormInstance } from "antd/es/form";
import { DeleteIcon } from "../../../../../../../../icons";
import NeighborhoodsApi from "api/Neighborhoods";

const Location = ({
  fromPage,
  externalLocation,
  changeLocation,
  form,
  deleteLocationsHandler,
  num,
  disableFreeForms,
}: {
  fromPage: string;
  externalLocation: IlocationItem;
  changeLocation: (updateedArea: IObjectKeys) => void;
  form: FormInstance;
  deleteLocationsHandler: any;
  num: any;
  disableFreeForms?: boolean;
}) => {
  const dispatch = useDispatch();
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const onAddressChange = async (address: AddressChangeParams) => {
    if (isFirstRender) return;
    const neighborhoodsList: any = await NeighborhoodsApi.getNeighborhoodsList({
      city: address?.separated_fields?.town,
      state: address?.separated_fields?.short_state,
    });
    const updatedArea = {
      _id: externalLocation._id,
      area: address.formatted_address ?? "",
      separated_fields: address.separated_fields ?? {},
      location: [],
      neighborhoods_list: neighborhoodsList,
    };
    changeLocation(updatedArea);
    form.setFieldsValue({
      [`addr ${externalLocation._id}`]: address,
    });
  };

  const changeFreeSubArea = (id: string, e: any) => {
    const _id: string = id;
    const updatedArea = {
      _id,
      location: e ? [e] : [],
      isFreeText: true,
    };
    changeLocation(updatedArea);
  };

  useEffect(() => {
    dispatch(getAreas({ limit: "all", fromPage: fromPage }));
  }, [dispatch]);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  return (
    <div className={"select-requirement-area"}>
      <Form.Item
        required={true}
        name={`addr ${externalLocation._id}`}
        rules={
          externalLocation.separated_fields.state
            ? []
            : [
                ({ getFieldValue }) => ({
                  validator(_) {
                    const valueOf = getFieldValue(`addr ${externalLocation._id}`);
                    if (!valueOf?.formatted_address && !valueOf.length) {
                      return Promise.reject("This Field is required");
                    }
                    return Promise.resolve();
                  },
                }),
              ]
        }
        data-test-id="auto-complete-area"
        className={`area-filed-requirement ${externalLocation.area ? "active" : ""}`}
      >
        {num !== 0 && (
          <div
            className={"area-filed-requirement--delete"}
            onClick={() => deleteLocationsHandler(externalLocation._id)}
          >
            <DeleteIcon />
          </div>
        )}
        <PlacesAutocomplete
          placeholder={"Enter City/Town"}
          defaultAddress={externalLocation.area}
          onAddressChange={onAddressChange}
          resetPlacesAutocomplete={resetPlacesAutocomplete}
          setResetPlacesAutocomplete={setResetPlacesAutocomplete}
          showRegion={true}
          disableFreeForms={disableFreeForms}
        />
      </Form.Item>
      <Form.Item
        name={`sub_area_${externalLocation._id}`}
        data-scroll-id="sub_area_select"
        data-test-id="auto-complete-sub-area"
        className={`area-filed-requirement 
              ${externalLocation.location?.length ? "active" : ""} 
              `}
      >
        <Select
          placeholder={
            externalLocation.neighborhoods_list?.length > 0
              ? "Add a neighborhood (Optional)"
              : "Neighborhoods not available"
          }
          disabled={externalLocation.neighborhoods_list?.length === 0}
          onChange={(value) => changeFreeSubArea(externalLocation._id, value)}
        >
          {externalLocation.neighborhoods_list?.map((neighborhood: { label: string }) => (
            <Select.Option key={neighborhood.label} value={neighborhood.label}>
              {neighborhood.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default memo(Location);
