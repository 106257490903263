import { Row } from "antd";
import ShowingPagination from "components/ShowingPagination";
import React from "react";

interface ITenantScreeningsHeader {
  list: any[];
  total: number | undefined;
}

const TenantScreeningsHeader = ({ list, total }: ITenantScreeningsHeader) => {
  return (
    <div className="my-6 tenant-screenings-header">
      <ShowingPagination list={list} total={total} />
    </div>
  );
};

export default TenantScreeningsHeader;
