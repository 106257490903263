import React from "react";

interface ISectionHeading {
  heading?: string | null | undefined | JSX.Element;
  subheading?: JSX.Element | JSX.Element[] | any;
  variant?: string;
  underline?: boolean;
}

const SectionHeading = ({ heading, subheading, variant, underline }: ISectionHeading) => {
  let headerFontSize = "text-2xl";
  switch (variant) {
    case "sm":
      headerFontSize = "text-lg";
    case "md":
      headerFontSize = "text-xl";
      break;
    default:
      break;
  }
  return (
    <div className="border-b border-gray-200 mb-4 pb-2">
      <h3 className={`${headerFontSize} text-xl text-gray-700`}>{heading}</h3>
      {subheading && <p className="mt-2 max-w-4xl text-sm text-gray-500">{subheading}</p>}
    </div>
  );
};

export default SectionHeading;
