import React from "react";
import { Modal } from "antd";
import { WELCOME_VIDEO_URL } from "../../../helpers/constants";
import "./WelcomeModal.scss";

interface IOWelcomeModal {
  visible: boolean;
  onCancel: () => void;
}

const WelcomeModal = ({ visible, onCancel }: IOWelcomeModal) => {

  return (
    <Modal className={"welcome-modal"} closeIcon={"x Close"} visible={visible} onOk={onCancel} onCancel={onCancel} width={670}>
      <div className={"welcome-modal-text-wrapper"}>
        <h3>
          <span role="img" aria-label="party">
            🎉
          </span>
          {"  "}
          Welcome to RentBase!
        </h3>
        <div className={"welcome-modal-p-wrapper"}>
          <p>Check out this quick video tour and get the most out of your RentBase.</p>
        </div>
        <div className={"welcome-modal-demo-video-wrapper"}>
          <iframe src={WELCOME_VIDEO_URL} title={"Welcome Video"} allowFullScreen={true} />
        </div>
      </div>
    </Modal>
  );
};;

export default WelcomeModal;
