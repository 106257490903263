import React from "react";

export const DropdownDotsIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="22"
      height="5"
      viewBox="0 0 22 5"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.2" cy="2.2" r="2.2" fill={`${color ?? "#020202"}`} />
      <circle cx="11" cy="2.2" r="2.2" fill={`${color ?? "#020202"}`} />
      <circle cx="19.8001" cy="2.2" r="2.2" fill={`${color ?? "#020202"}`} />
    </svg>
  );
};
