import React from "react";
import { Menu, Popover } from "antd";
import "./SidebarTopPagesMenu.scss";
import { useDemoContext } from "libs/contextLib";
interface ISidebarTopPagesMenu {
  handleOpenMenu: any;
  activeMenuItem: any;
  setActiveMenuItem: any;
  collapsed: boolean;
  item: any;
  handleMenuClick: any;
}
const SidebarTopPagesMenu = ({
  handleMenuClick,
  handleOpenMenu,
  activeMenuItem,
  setActiveMenuItem,
  collapsed,
  item
}: ISidebarTopPagesMenu) => {
  const { isDemo } = useDemoContext();
  return (
    <>
      {isDemo && item.title === "Dashboard" ? null :
        <Menu
          onOpenChange={handleOpenMenu}
          theme="light"
          defaultSelectedKeys={["2"]}
          selectedKeys={[activeMenuItem]}
          onClick={(entity) => {
            setActiveMenuItem(entity.key.toString());
          }}
          className={`sidebar-top-menu ${collapsed && "sidebar-top-menu-collapse"}`}
        >
          <Menu.Item
            data-tour={`step-${item.title}`}
            data-test-id={`sider-menu-item-${item.title}`}
            key={item.path}
            icon={
              collapsed ? (
                <Popover
                  content={
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {item.title}
                    </span>
                  }
                  placement={"right"}
                  trigger={"hover"}
                  className={"sidebar-collapsed-popover"}
                >
                  <span role="img">
                    {item.icon}
                  </span>
                </Popover>
              ) : (
                <span role="img" >
                  {item.icon}
                </span>
              )
            }
            onClick={() => handleMenuClick(item)}
            className="sider-menu-item"
            title={null}
          >
            {item.title}
          </Menu.Item>
        </Menu>
      }
    </>
  );
};

export default SidebarTopPagesMenu;
