import {
  setCurrentSteps,
  triggerListingAfterSaveModal,
} from "../../store/listings/action";
import { Button, Modal, Space, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
const { Text, Link } = Typography;
const ListingCreatedModal = ({
  visible,
}:
{
  visible: boolean;
}) => {
  const dispatch = useDispatch();
  return (
    <Modal
      visible={visible}
      title={""}
      footer={null}
      onCancel={() => dispatch(triggerListingAfterSaveModal(false))}
    >
      <Space direction={"vertical"} size={18} className="font-16 mt-6">
        <Text>Great! Your new listing has been added.</Text>
        <Text>
          To increase your chances of closing this rental complete the extra
          details section
        </Text>
        <Text>
          Alternatively, you can skip for now and continue to
          <span>
            <Link
              className={"ml-5"}
              onClick={() => dispatch(setCurrentSteps(6))}
            >
              Add Photos
            </Link>
          </span>
        </Text>
      </Space>
      <div className="flex-centered w-full mt-6">
        <Space
          className={"mt-6"}
          align={"center"}
          direction={"horizontal"}
          size={50}
        >
          <Button
            type={"primary"}
            onClick={() => {
              dispatch(triggerListingAfterSaveModal(false));
            }}
          >
            Add Extra Details
          </Button>
          <Button
            onClick={() => {
              dispatch(triggerListingAfterSaveModal(false));
              dispatch(setCurrentSteps(6));
            }}
          >
            Skip for now
          </Button>
        </Space>
      </div>
    </Modal>
  );
};
export default ListingCreatedModal;
