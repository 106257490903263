import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const pathname = useLocation();
  useEffect(() => {
   const scrollTopElement: HTMLElement | null = document.querySelector(".page-header-wrapper"
       || ".sign-in-header" || ".guest-header-wrapper" || ".lead-flow-header-wrapper" || "sign-in-header-req" || "sign-in-header-force" || "");
   scrollTopElement?.scrollIntoView();
  }, [pathname]);
  return null;
};

export default ScrollToTop;
