import * as types from "./types";
import { SET_QUERY_AND_PAGINATION } from "../activities/types";

const initialState: any = {
  agentsActivities: [],
  agentsActivitiesLoading: false,
  agentsActivitiesError: null,
  total: 0,
  currentAgentForActivities: null,
  agentData: null,
  queryAndPagination: {
    page: 1,
    current: 1,
    pageSize: 20,
    sortField: "created_at",
    sortOrder: -1,
    searchValue: "",
    timeInterval: {
      label: "Last 30 Days",
      value: "last_30_days",
    },
  },
};

export default function agentActivitiesReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.GET_AGENT_ACTIVITIES:
      return {
        ...state,
        agentsActivities: [],
        agentsActivitiesLoading: true,
        agentsActivitiesError: null,
        currentAgent: null,
      };

    case types.GET_AGENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        agentsActivities: action.payload.data,
        total: action.payload.total,
        agentsActivitiesLoading: false,
        agentData: action.payload.agentData,
      };

    case types.GET_AGENT_ACTIVITIES_FAIL:
      return {
        ...state,
        agentsActivitiesError: action.payload,
        total: 0,
        agentsActivitiesLoading: false,
      };

    case types.SET_CURRENT_AGENT_FOR_ACTIVITIES:
      return {
        ...state,
        currentAgentForActivities: action.payload,
      };
    case SET_QUERY_AND_PAGINATION:
      return {
        ...state,
        queryAndPagination: {
          ...state.queryAndPagination,
          ...action.payload,
        },
      };
      case types.SET_ACTIVITIES_LOADER_TO_TRUE:
      return {
        ...state,
        agentsActivitiesLoading: true,
      };

    default:
      return state;
  }
}
