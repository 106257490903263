import React, { useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "store/user/selectors";
import BaseButton from "components/Shared/BaseButton";
import "./styles.scss";
import { Link } from "react-router-dom";
import { AnalyticsService } from "helpers/analyticsService";
import SuccessAgentAvatar from "../../../../Shared/success-agent-avatar/SuccessAgentAvatar";
import IndividualLeadSharePopup from "components/Shared/IndividualLeadSharePopup/IndividualLeadSharePopup";
import { leadsRoute } from "helpers/clientRoutes";

const SuccessAgentView = () => {
  const user = useSelector(userSelector);
  const [addLeadModalVisible, setAddLeadModalVisible] = useState(false);
  const onButtonClick = (label: string) => {
    setAddLeadModalVisible(true);
    AnalyticsService.reportRequirementsFunnel({ action: "requirements_finish_page_button_click", label });
  };

  return (
    <div className="agent-success-wrapper">
      <div className="agent-success-wrapper-content">
        <div className={"agent-success-wrapper-content-wrapper"}>
          <SuccessAgentAvatar url={user?.image?.url} />
          <div>
            <h2 className="mb-5">
              <span className="mr-6 font-24" role="img" aria-label="go">
                💪
              </span>
              Way to go!
            </h2>
          </div>
          <div>
            <p>Your lead's submission will show up on your Leads page.</p>
          </div>
          <div className="agent-success-wrapper-content-btns">
            <BaseButton
              classnames={"success-btn purple mt-15"}
              dataTestId="start-here"
              onClick={() => {
                onButtonClick("share_my_link_button_click");
              }}
            >
              Share my link
            </BaseButton>
            <BaseButton
              classnames="success-btn white mt-15"
              onClick={() => {
                onButtonClick("see_my_leads_button_click");
              }}
            >
              <Link to={leadsRoute()}>See my leads</Link>
            </BaseButton>
          </div>
        </div>
      </div>
      <div className="agent-success-wrapper-footer">
        <p>
          Need help?
          <a
            href="mailto:support@therentbase.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span> Contact us</span>
          </a>
        </p>
      </div>
      <IndividualLeadSharePopup
        visible={addLeadModalVisible}
        onClose={() => setAddLeadModalVisible(false)}
        isLeadPage={true}
      />
    </div>
  );
};

export default SuccessAgentView;
