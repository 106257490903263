import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "store/user/action";
import {
  passwordResetLoadingSelector,
  resetPasswordSuccessSelector,
  sendVerificationLinkSelector,
  userSelector,
} from "store/user/selectors";
import { REQUIRED } from "helpers/validations";
import "./CodeCheck.scss";
import BaseButton from "components/Shared/BaseButton";
import Message from "components/Shared/Message";
import { IObjectKeys } from "helpers/globalTypes";
import SetPassword from "../set-password/SetPassword";
import { push } from "connected-react-router";
import { useHistory, useLocation } from "react-router-dom";
import SignUpVerifyMsg from "components/SignUp/sign-up-verify-msg/SignUpVerifyMsg";
interface IResetPassword {
  email: string;
  code: string;
  newPassword: string;
}

const CodeCheck = ({ email }: IObjectKeys) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loading = useSelector(passwordResetLoadingSelector);
  const userData = useSelector(userSelector);
  const getPasswordCodeSuccess = useSelector(resetPasswordSuccessSelector);
  const [first, setFirst] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [code, setCode] = useState("");
  const { search } = useLocation();
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const sendVerificationLink = useSelector(sendVerificationLinkSelector);

  useEffect(() => {
    setFirst(true);
    if (getPasswordCodeSuccess && first) {
      setIsResend(true);
      setTimeout(() => {
        setIsResend(false);
      }, 2500);
    }
  }, [getPasswordCodeSuccess]);

  const onSubmit = (data: IResetPassword) => {
    setCode(data.code);
    dispatch(push({ search: "?step=2" }));
  };

  const getPasswordResetCodeAgain = () => {
    if (loading) return;
    dispatch(resetPassword({ email: userData.email || email }));
  };

  const handleChange = (values: Array<IObjectKeys>) => {
    if (values[0].value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      {sendVerificationLink || search === "?step=1" ? (
        sendVerificationLink ? (
          <SignUpVerifyMsg />
        ) : (
          <>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-10">
              <h2 className="mt-10 text-center text-2xl font-bold">Check Your Email</h2>
              <p className="font-16 mb-6 text-center">Enter the 6 digit code sent to your email</p>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <div className="code-send flex flex-col justify-start">
                <Form form={form} onFinish={onSubmit} className="reset-password-form" onFieldsChange={handleChange}>
                  <Form.Item name="code" rules={[REQUIRED]}>
                    <Input
                      autoComplete="off"
                      type="number"
                      placeholder="Type code here"
                      className="hidden-arrows block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                    />
                  </Form.Item>
                  <Form.Item className="flex flex-col justify-center" shouldUpdate>
                    {() => (
                      <>
                        <BaseButton type="submit" variant="primary" loading={loading} disabled={disabled}>
                          Confirm
                        </BaseButton>
                        <div className="mt-4 flex flex-row justify-start gap-2 items-center">
                          <div>Didn’t get a code?</div>
                          <Form.Item className="m-0">
                            <BaseButton variant="link" onClick={getPasswordResetCodeAgain}>
                              Resend
                            </BaseButton>
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </Form.Item>
                </Form>
                {isResend && <Message text="👍 Code Resent" type="noIcon" />}
              </div>
            </div>
            <div className="change-password-back">
              <BaseButton variant="link" onClick={() => history.goBack()}>
                {"< Back"}
              </BaseButton>
            </div>
          </>
        )
      ) : (
        <SetPassword code={code} email={localStorage.getItem("resetPasswordEmail") ?? email} />
      )}
    </div>
  );
};
export default CodeCheck;
