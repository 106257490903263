import React from "react";

const SuccessCheck = () => {
    return (
        <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M32.5 64C50.4493 64 65 49.6731 65 32C65 14.3269 50.4493 0 32.5 0C14.5507 0 0 14.3269 0 32C0 49.6731 14.5507 64 32.5 64Z" fill="#761BE2" />
                <path d="M24.1953 46.4352L40.9067 62.8895C54.7474 59.2554 64.9997 46.839 64.9997 31.9999C64.9997 31.697 64.9997 31.3942 64.9997 31.0914L51.8767 19.1797L24.1953 46.4352Z" fill="#5806B9" />
                <path d="M33.3194 39.1676C34.7547 40.5808 34.7547 43.0036 33.3194 44.4168L30.3462 47.3443C28.9109 48.7575 26.4503 48.7575 25.015 47.3443L11.9945 34.4231C10.5591 33.0099 10.5591 30.5872 11.9945 29.1739L14.9677 26.2465C16.403 24.8332 18.8636 24.8332 20.2989 26.2465L33.3194 39.1676Z" fill="white" />
                <path d="M44.6981 16.8583C46.1334 15.445 48.594 15.445 50.0293 16.8583L53.0025 19.7857C54.4379 21.199 54.4379 23.6217 53.0025 25.0349L30.4473 47.1422C29.012 48.5554 26.5514 48.5554 25.1161 47.1422L22.1429 44.2147C20.7076 42.8015 20.7076 40.3788 22.1429 38.9655L44.6981 16.8583Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="65" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default SuccessCheck;