import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { SignUpFormInterface, SignUpFormProps } from "../../../helpers/authTypes";
import { useDispatch, useSelector } from "react-redux";
import { agentSignUpSuccessSelector, authLoadingSelector, agentSignUpLoadingSelector } from "../../../store/user/selectors";
import BaseButton from "../../Shared/BaseButton";
import GoogleIcon from "../../icons/GoogleIcon";
import { GOOGLE_LOGIN_LINK } from "../../../helpers/constants";
import { AnalyticsService } from "helpers/analyticsService";
import { EMAIL_VALIDATION, PHONE_NUMBER_MASK, REQUIRED_PHONE } from "../../../helpers/validations";
import MaskedInput from "../../Shared/MaskedPhoneInput";
import { useForm } from "antd/es/form/Form";
import { sanitizePhoneNumber } from "../../../helpers/globalFunctions";
import { EnrichmentsService } from "helpers/enrichmentsService";
import { GoogleAuthHelper } from "../../../helpers/GoogleAuthHelper";
import { Link, useHistory, useLocation } from "react-router-dom";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";

const SignUpForm = (props: SignUpFormProps) => {
  const authLoading = useSelector(authLoadingSelector);
  const signupLoading = useSelector(agentSignUpLoadingSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = useForm();
  const agentSignedInSuccess = useSelector(agentSignUpSuccessSelector);
  const [checkMail, setCheckMail] = useState(false);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  const onFinish = (values: SignUpFormInterface) => {
    const data = {
      email: values.email?.trim(),
      name: values.name,
      // eslint-disable-next-line no-restricted-globals
      phone: sanitizePhoneNumber(values?.phone),
      enrichments: EnrichmentsService.getEnrichmentsObject(),
      groupOfAgent: queryObject?.agency_id,
      defaultState: queryObject?.defaultState,
      defaultCity: queryObject?.defaultCity
    };
    props.onSubmit(data);
  };

  useEffect(() => {
    if (agentSignedInSuccess) {
      form.resetFields();
    }
  }, [agentSignedInSuccess]);
  useEffect(() => {
    GoogleAuthHelper(dispatch, history);
  }, [dispatch]);

  const handleChange = () => {
    if (!form.getFieldValue("phone") && form.getFieldValue("email")) {
      setCheckMail(true);
    } else {
      setCheckMail(false);
    }
  };

  return (
    <div className="sign-in-form-wrapper">
      <div className="sign-in-form">
        <Form
          className="login-form"
          onFinish={onFinish}
          form={form}
          onChange={handleChange}
          initialValues={{
            email: queryObject?.email,
            name: queryObject?.agent_name,
            phone: queryObject?.phone
          }}>
          <label htmlFor="name">
            Full Name <span>*</span>
          </label>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                min: 3,
                message: "It must contain at least 3 letters"
              }
            ]}>
            <Input id="name" size="large" placeholder="Ex. John Doe" defaultValue={queryObject?.agent_name} />
          </Form.Item>
          <label htmlFor="email">
            Phone Number <span>*</span>{" "}
          </label>
          <Form.Item name="phone" rules={[REQUIRED_PHONE]}>
            <MaskedInput
              inputMode="numeric"
              mask={PHONE_NUMBER_MASK}
              placeholder={"Ex. (212) 555 6666"}
              defaultValue={queryObject?.phone}
            />
          </Form.Item>
          <label htmlFor="email">
            Work Email <span>*</span>
          </label>
          <Form.Item name="email" rules={[EMAIL_VALIDATION]}>
            <Input id="email" size="large" placeholder="Ex. john@doe.com" defaultValue={queryObject?.email} />
          </Form.Item>
          <Form.Item className={"sign-in-form-submitWrapper sign-up-form-submitWrapper pb-8"}>
            <div
              className="sign-in-submit"
              onClick={() => {
                AnalyticsService.reportSignUpProcess({
                  action: "sign_up_page_click",
                  label: "submit",
                });
              }}
            >
              <BaseButton
                type="submit"
                variant="primary"
                loading={authLoading || signupLoading}>
                Submit
              </BaseButton>
              <div className={"sign-in-form-submitWrapper-lineSection"}>
                <div></div>
                <span>or</span>
                <div></div>
              </div>
              <a href={GOOGLE_LOGIN_LINK} className={"disabled-link"}>
                <BaseButton
                  type="button"
                  classnames="rb-primary-btn md  rb-primary-btn-google"
                  loading={false}
                  disabled={true}
                  onClick={() => {
                    AnalyticsService.reportSignUpProcess({
                      action: "sign_up_page_click",
                      label: "google_sign_up",
                    });
                  }}>
                  <div className="flex gap-2">
                    <GoogleIcon />
                    Sign up with Google
                  </div>
                </BaseButton>
              </a>
            </div>
          </Form.Item>
        </Form>
        <p className="accept-terms">
          By signing up you confirm that you accept our <br /> <Link to="/terms">Terms & Conditions</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpForm;
