import { Input } from "antd";
import React, { forwardRef } from "react";
import ReactInputMask from "react-input-mask";
import { IObjectKeys } from "../../../helpers/globalTypes";

const MaskedInput = forwardRef((props: IObjectKeys, ref) => {
  return (
    <ReactInputMask mask={props.mask} {...props}>
      {(inputProps: any) => {
        return (
          <Input

            className={"lt-space-2"}
            {...inputProps}
            ref={ref}
            value={props.value}
            defaultValue={props.defaultValue}
            disabled={props.disabled ? props.disabled : null}
            readOnly={props.readOnly ? props.readOnly : null}
            onChange={props.onChange}
          />
        );
      }}
    </ReactInputMask>
  );
});

export default MaskedInput;
