import Api from "./api";
export default class EnumsApi {
  static getEnums() {
    const api = new Api("enums");
    return api.get("", {});
  }

  static getRequirementEnums() {
    const api = new Api("requirementenums");
    return api.get("", {});
  }

  static getFlatfileEnums() {
    const api = new Api("flatfile-enums");
    return api.get("", {});
  }
}
