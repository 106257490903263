import { IObjectKeys } from '../../../../helpers/globalTypes';
export const listingFormScrollFields: IObjectKeys = {
    mustHaves: [
        "bedrooms", "additionalBed",
        "additionalBath", "bathrooms",
    ],
    extraDetails: [
        "flooring",
        "heating",
        "amenities",
        "hasAdditionalStorage",
        "storage"
    ],
    listingDetails: [
        "has_parking",
        "parking_type",
        "petsAreAllowed",
        "pets_allowed",
        "has_laundry",
        "laundry",
        "dishwasher",
        "has_air_conditioning",
        "air_conditioning",
        "has_outdoorSpace",
        "utilities"
    ]
}