import React from "react";

export const PrivateEyeIcon = () => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4033 7.44092L17.3001 11.3377L17.3186 11.1336C17.3186 9.0862 15.6548 7.42236 13.6074 7.42236L13.4033 7.44092Z"
        fill="#B7B7B7"
      />
      <path
        d="M13.6074 4.94836C17.0217 4.94836 19.7927 7.7194 19.7927 11.1337C19.7927 11.9316 19.6319 12.6924 19.3536 13.3913L22.972 17.0097C24.8399 15.451 26.3121 13.4346 27.2213 11.1337C25.075 5.70301 19.7989 1.85571 13.6074 1.85571C11.8755 1.85571 10.2179 2.16496 8.67773 2.72164L11.3498 5.3875C12.0487 5.11536 12.8095 4.94836 13.6074 4.94836Z"
        fill="#B7B7B7"
      />
      <path
        d="M1.23705 1.57726L4.05756 4.39777L4.62044 4.96065C2.57928 6.55647 0.964911 8.67805 0 11.1336C2.14014 16.5643 7.42239 20.4116 13.6077 20.4116C15.5252 20.4116 17.356 20.0405 19.0323 19.3662L19.5581 19.892L23.1641 23.5042L24.7414 21.9332L2.8143 0L1.23705 1.57726ZM8.07806 8.41206L9.98932 10.3233C9.93365 10.5893 9.89654 10.8552 9.89654 11.1336C9.89654 13.181 11.5604 14.8448 13.6077 14.8448C13.8861 14.8448 14.1521 14.8077 14.4119 14.752L16.3231 16.6633C15.5004 17.0715 14.5851 17.3189 13.6077 17.3189C10.1934 17.3189 7.42239 14.5479 7.42239 11.1336C7.42239 10.1563 7.66983 9.24088 8.07806 8.41206Z"
        fill="#B7B7B7"
      />
    </svg>
  );
};
