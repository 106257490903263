import React from "react";

export const CopyLink = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.265 8.29534L15.999 5.02934C15.6636 4.69401 15.2177 4.50928 14.7434 4.50928H4.03032C3.2999 4.50928 2.70569 5.10349 2.70569 5.83391V25.6753C2.70569 26.4057 3.2999 26.9999 4.03032 26.9999H18.4604C19.1908 26.9999 19.785 26.4057 19.785 25.6753V9.55084C19.785 9.0766 19.6004 8.63073 19.265 8.29534ZM18.9395 25.6753C18.9395 25.9395 18.7245 26.1544 18.4603 26.1544H4.03032C3.76612 26.1544 3.55118 25.9395 3.55118 25.6753V5.83391C3.55118 5.56971 3.76612 5.35477 4.03032 5.35477H14.7435C14.9919 5.35477 15.2255 5.45148 15.4011 5.62719L18.6671 8.89319C18.8235 9.04955 18.9168 9.25184 18.9356 9.46958H15.7548C15.4906 9.46958 15.2756 9.25463 15.2756 8.99043V6.73572C15.2756 6.50221 15.0864 6.31295 14.8528 6.31295C14.6193 6.31295 14.4301 6.50216 14.4301 6.73572V8.99043C14.4301 9.72086 15.0243 10.3151 15.7547 10.3151H18.9395V25.6753Z"
        fill="black"
      />
      <path
        d="M23.7742 3.78601L20.5083 0.520066C20.1729 0.184676 19.727 0 19.2527 0H8.5396C7.80918 0 7.21497 0.594211 7.21497 1.32463V3.12841C7.21497 3.36192 7.40423 3.55119 7.63774 3.55119C7.87125 3.55119 8.06051 3.36197 8.06051 3.12841V1.32463C8.06051 1.06044 8.27545 0.84549 8.53965 0.84549H19.2527C19.5012 0.84549 19.7347 0.942205 19.9104 1.11792L23.1764 4.38391C23.3327 4.54027 23.4261 4.74256 23.4449 4.9603H20.264C19.9998 4.9603 19.7849 4.74536 19.7849 4.48116V2.2265C19.7849 1.99299 19.5957 1.80373 19.3621 1.80373C19.1286 1.80373 18.9393 1.99294 18.9393 2.2265V4.48121C18.9393 5.21163 19.5336 5.80584 20.264 5.80584H23.4488V21.166C23.4488 21.4302 23.2338 21.6451 22.9696 21.6451H21.1657C20.9322 21.6451 20.743 21.8344 20.743 22.0679C20.743 22.3014 20.9322 22.4907 21.1657 22.4907H22.9696C23.7 22.4907 24.2943 21.8965 24.2943 21.166V5.04156C24.2943 4.56727 24.1096 4.1214 23.7742 3.78601Z"
        fill="black"
      />
      <path
        d="M16.0083 17.3691L13.4574 14.8183C13.2182 14.579 12.9034 14.4428 12.567 14.4313C12.5554 14.1083 12.4279 13.7888 12.1819 13.5427L9.63098 10.9918C9.11455 10.4754 8.27417 10.4754 7.75764 10.9918L6.4822 12.2673C6.23203 12.5175 6.09424 12.8501 6.09424 13.2039C6.09424 13.5577 6.23203 13.8904 6.4822 14.1406L9.03307 16.6915C9.27228 16.9307 9.58705 17.0669 9.92349 17.0784C9.93509 17.4014 10.0626 17.721 10.3086 17.967L12.8595 20.5179C13.1178 20.7762 13.4569 20.9052 13.7961 20.9052C14.1354 20.9052 14.4745 20.7761 14.7328 20.5179L16.0083 19.2425C16.5247 18.7259 16.5247 17.8856 16.0083 17.3691ZM10.3086 16.0935C10.2181 16.184 10.0977 16.2338 9.96979 16.2338C9.84186 16.234 9.72152 16.1841 9.63098 16.0936L7.08005 13.5427C6.98956 13.4522 6.93968 13.3319 6.93968 13.2039C6.93968 13.076 6.98951 12.9557 7.08005 12.8652L8.35549 11.5897C8.44888 11.4963 8.57154 11.4496 8.69425 11.4496C8.81697 11.4496 8.93968 11.4963 9.03307 11.5897L11.5839 14.1406C11.7707 14.3273 11.7707 14.6314 11.5839 14.8181L11.2452 15.1569L10.2686 14.1804C10.1035 14.0153 9.83585 14.0153 9.67079 14.1804C9.50568 14.3455 9.50568 14.6132 9.67079 14.7783L10.6473 15.7547L10.3086 16.0935ZM15.4104 18.6446L14.1349 19.92C13.9482 20.1068 13.6442 20.1068 13.4573 19.92L10.9065 17.3692C10.7197 17.1824 10.7197 16.8783 10.9065 16.6916L11.2452 16.3528L12.2218 17.3294C12.3043 17.4119 12.4125 17.4531 12.5207 17.4531C12.6288 17.4531 12.7371 17.4119 12.8196 17.3294C12.9847 17.1642 12.9847 16.8966 12.8196 16.7315L11.8431 15.7549L12.1819 15.4161C12.2724 15.3256 12.3927 15.2758 12.5207 15.2758C12.6486 15.2758 12.7689 15.3257 12.8594 15.4162L15.4103 17.9671C15.5972 18.1538 15.5972 18.4577 15.4104 18.6446Z"
        fill="black"
      />
    </svg>
  );
};
