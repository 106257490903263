import React from "react";
import RentalPortalPassportComp from "components/RenterPortal/RentalPortalPassport";

const RenterPortalPassport = () => {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <RentalPortalPassportComp />
    </div>
  );
};
export default RenterPortalPassport;
