import React from "react";
import LeadView from "../../components/Lead/renter-lead-page";

const SingleLeadPage = () => {
  return (
    <div className="single-listing-view-wrapper single-lead-view-wrapper">
      <LeadView />
    </div>
  );
};
export default SingleLeadPage;
