import React from "react";
import { formatDate } from '../../../helpers/dateHelper';

const DateColumn = (
  _id: string,
  rowData: any
): { children: React.ReactNode; props: { "data-test-id": string; }; } => {
  return {
    children: <span>{formatDate(rowData?.created_at)}</span>,
    props: {
      "data-test-id": "created_at",
    },
  };
};

export default DateColumn;
