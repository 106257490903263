import React, { useEffect } from 'react';
import {Col, Row} from "antd";
import TeamDashboard from "components/TeamDashboard";
import { useDemoContext } from 'libs/contextLib';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
     const history = useHistory();
     const { isDemo } = useDemoContext();
     useEffect(() => {
       if (isDemo) history.push("/demo/teamDashboard");
     }, [history, isDemo]);
    
    return (
        <Row className="flex-centered">
            <Col span={24}>
                <TeamDashboard/>
            </Col>
        </Row>
    );
};
export default Dashboard;
