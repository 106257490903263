import axios, { Method } from 'axios';
import { IObjectKeys } from 'helpers/globalTypes';
import { getAuthToken, getDraftRenterToken, getGuestAccessToken } from '../helpers/authUtils';

export interface ApiQueryParams {
  params?: any;
  data?: any;
  headers?: any;
}

export type ApiPrefix = string | number;
export type TokenType = string | null;

class Api {
  url: string;
  resource: string;

  constructor(resource: string) {
    this.url = `${process.env.REACT_APP_BASE_URL || 'https://rentbase-api.herokuapp.com/api/v1'}/${resource}`;
    this.resource = resource;
  }

  list = async (params: ApiQueryParams) => {
    return this.buildQuery('get', params);
  };

  get = async (id: ApiPrefix, params: ApiQueryParams) => {
    return this.buildQuery('get', params, id);
  };

  getDraft = async (id: ApiPrefix, params: ApiQueryParams) => {
    return this.buildDraftRenter('get', params, id);
  };

  create = async (params: ApiQueryParams) => {
    return this.buildQuery('post', params);
  };
  createUnregistered = async (params: ApiQueryParams) => {
    return this.buildQueryUnregistered('post', params);
  };

  putDraftRenter = async (id: ApiPrefix, params: ApiQueryParams) => {
    return this.buildDraftRenter('put', params, id);
  };
  put = async (id: ApiPrefix, params: ApiQueryParams) => {
    return this.buildQuery('put', params, id);
  };

  destroy = async (id: ApiPrefix, params: ApiQueryParams = {}) => {
    return this.buildQuery('delete', params, id);
  };

  buildQuery = async (method: Method, params?: ApiQueryParams, prefix?: ApiPrefix, blob?: boolean) => {
    const token: TokenType = getAuthToken();
    const headers: IObjectKeys = {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json',
    };
    if (window.location.pathname.includes("demo")) {
      headers.is_demo = true;
    }
    if (this.resource.includes('upload')) {
      headers['Content-Type'] = `multipart/form-data;boundary=file`;
    }

    return axios({
      url: this.getFullUrl(prefix),
      method,
      data: params?.data,
      params: params?.params,
      headers
    });
  };

  buildQueryUnregistered = async (method: Method, params: ApiQueryParams, prefix?: ApiPrefix, blob?: boolean) => {
    const token: TokenType = getGuestAccessToken();

    const headers = {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json',
    };
    if (this.resource.includes('upload')) {
      headers['Content-Type'] = `multipart/form-data;boundary=file`;
    }

    return axios({
      url: this.getFullUrl(prefix),
      method,
      data: params.data,
      params: params.params,
      headers
    });
  };

  buildDraftRenter = async (method: Method, params: ApiQueryParams, prefix?: ApiPrefix, blob?: boolean) => {
    const token: TokenType = getDraftRenterToken();
    const headers = {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json',
    };
    if (this.resource.includes('upload')) {
      headers['Content-Type'] = `multipart/form-data;boundary=file`;
    }

    return axios({
      url: this.getFullUrl(prefix),
      method,
      data: params.data,
      params: params.params,
      headers
    });
  };

  getFullUrl = (prefix?: ApiPrefix) => {
    return `${this.url}${prefix ? `/${prefix}` : ''}`;
  };

}

export default Api;
