import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import Activities from '../../api/Activities';

import { GET_AGENT_ACTIVITIES, } from './types';
import { getAgentsActivitiesFail, getAgentsActivitiesSuccess, } from './action';

function* getAgentActivitiesSaga({ payload }: any) {
  try {
    const response = yield call(
        Activities.getTeamActivities, { ...payload, timeInterval: payload.timeInterval.value },
    );
    yield put(getAgentsActivitiesSuccess(response));
  } catch (e) {
    yield put(getAgentsActivitiesFail(e));
  }
}

function* watchAgentActivitiesSaga() {
  yield takeLatest(GET_AGENT_ACTIVITIES, getAgentActivitiesSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchAgentActivitiesSaga),
  ]);
}
