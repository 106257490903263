import React from "react";

interface ISection {
  children: JSX.Element | JSX.Element[] | any;
  className?: string;
  border?: boolean;
  shadow?: boolean;
}

const Section = ({ children, className, border, shadow }: ISection) => {
  return (
    <section
      className={`${className} mb-8 p-4 sm:p-6 rounded-lg text-lg ${shadow && ` sm:shadow-lg`} ${
        border && !shadow ? `sm:border border-gray-100` : `border border-gray-100 bg-gray-50`
      }`}
    >
      {children}
    </section>
  );
};

export default Section;
