import React from "react";

export const UserAvatarIcon = () => {
  return (
    <svg
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.7595 12.5522C12.6131 12.5522 14.9266 9.74228 14.9266 6.27611C14.9266 2.80987 14.167 0 9.7595 0C5.35199 0 4.59229 2.80987 4.59229 6.27611C4.59229 9.74228 6.90578 12.5522 9.7595 12.5522Z"
        fill="#E3E3E3"
      />
      <path
        d="M19.5078 21.8815C19.4121 15.8433 18.6235 14.1228 12.589 13.0337C12.589 13.0337 11.7395 14.1161 9.75959 14.1161C7.77967 14.1161 6.93007 13.0337 6.93007 13.0337C0.961359 14.1109 0.124808 15.8059 0.0148127 21.6853C0.00579306 22.1654 0.00161326 22.1907 0 22.1349C0.000366651 22.2393 0.000806469 22.4325 0.000806469 22.7693C0.000806469 22.7693 1.43749 25.6656 9.75959 25.6656C18.0815 25.6656 19.5184 22.7693 19.5184 22.7693C19.5184 22.5529 19.5185 22.4024 19.5187 22.3001C19.5171 22.3345 19.5139 22.2677 19.5078 21.8815Z"
        fill="#E3E3E3"
      />
    </svg>
  );
};
