import React, { useState } from "react";
import BaseModal from "../../../../Shared/BaseModal";
import { generateHtmlFromDocument } from "../../../../../contentful/RichTextRenderer";
import "./PropertyInformationTooltipModal.scss";
import { InfoIcon } from "components/icons";

const PropertyInformationTooltipModal = ({ richText }: { richText: any }) => {
  const [tooltipModalVisible, setTooltipModalVisible] = useState(false);

  const handleCloseTooltipModal = () => {
    setTooltipModalVisible(false);
  };
  return (
    <div>
      <div className={"unit-type-tooltip"}>
        <button
          type={"button"}
          onClick={(e) => {
            e.stopPropagation();
            setTooltipModalVisible(true);
          }}
        >
          <InfoIcon />
        </button>
      </div>
      <BaseModal
        classname={"tooltip-info-modal"}
        isModalVisible={tooltipModalVisible}
        modalTitle={"What each unit types mean?"}
        modalFooter={null}
        onCancel={handleCloseTooltipModal}
        width={740}
      >
        {generateHtmlFromDocument(richText)}
      </BaseModal>
    </div>
  );
};

export default PropertyInformationTooltipModal;
