import React from "react";

export const SliderRightArrowIcon = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.71294 7.69307L1.69315 13.7127C1.31021 14.0958 0.689357 14.0958 0.306611 13.7127C-0.0761652 13.3299 -0.0761651 12.7091 0.306611 12.3263L5.6332 6.9999L0.306768 1.67367C-0.0760083 1.29074 -0.0760081 0.669973 0.306768 0.287198C0.689545 -0.0957325 1.31037 -0.0957324 1.6933 0.287198L7.71309 6.30688C7.90448 6.49836 8.00007 6.74905 8.00007 6.99987C8.00007 7.25081 7.90429 7.50169 7.71294 7.69307Z"
        fill="#333333"
      />
    </svg>
  );
};
