import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";

export enum NotableEnum {
  listing_note = "listing_note",
  landlord_note = "landlord_note",
}

export interface NotificationI {
  loading?: boolean;
  _id: string;
  title: string;
  description: string;
  read: boolean;
  todo_at: Date;
  created_by: string;
  note_id: string | null | IObjectKeys;
  landlord_id?: string | null | IObjectKeys;
  listing_id?: string | null | IObjectKeys;
  first_read: null | Date;
  notifiable_type: NotableEnum;
  requirements_id?: string | null | IObjectKeys;
}

interface NotificationsReducerI {
  notificationsList: NotificationI[];
  unreadNotificationsList: NotificationI[];
  readNotificationsList: NotificationI[];
  total: number;
  unreadTotal: number;
  readTotal: number;
  notificationsLoading: boolean;
  markAsUnreadSuccess: boolean;
  markAsReadSuccess: boolean;
  notificationsError: null | IObjectKeys | string;
  unreadReadNotifications: number;
}
const initialState: NotificationsReducerI = {
  notificationsList: [],
  unreadNotificationsList: [],
  readNotificationsList: [],
  total: 0,
  unreadTotal: 0,
  readTotal: 0,
  unreadReadNotifications: 0,
  notificationsLoading: false,
  markAsUnreadSuccess: false,
  markAsReadSuccess: false,
  notificationsError: null,
};

export default function listingReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.GET_UNREAD_NOTIFICATIONS_COUNT:
    case types.GET_NOTIFICATIONS:
    case types.GET_UNREAD_NOTIFICATIONS:
    case types.GET_READ_NOTIFICATIONS:
      return {
        ...state,
        notificationsLoading: true,
        notificationsError: null,
      };
    case types.GET_NOTIFICATIONS_SUCCESS:
      let notificationsList;
      if (action.payload.fillAll) {
        notificationsList = [...action.payload.data];
      } else {
        notificationsList = [
          ...state.notificationsList,
          ...action.payload.data,
        ];
      }
      return {
        ...state,
        notificationsError: null,
        notificationsLoading: false,
        notificationsList,
        total: action.payload.metadata[0].total,
      };
    case types.GET_UNREAD_NOTIFICATIONS_SUCCESS:
      let unreadNotificationsList;
      if (action.payload.replaceExistingData) {
        unreadNotificationsList = [...action.payload.data];
      } else {
        unreadNotificationsList = [
          ...state.unreadNotificationsList,
          ...action.payload.data,
        ];
      }
      return {
        ...state,
        notificationsError: null,
        notificationsLoading: false,
        unreadNotificationsList,
        unreadTotal: action.payload.metadata[0].total,
      };
    case types.GET_READ_NOTIFICATIONS_SUCCESS:
      let readNotificationsList;
      if (action.payload.replaceExistingData) {
        readNotificationsList = [...action.payload.data];
      } else {
        readNotificationsList = [
          ...state.readNotificationsList,
          ...action.payload.data,
        ];
      }
      return {
        ...state,
        notificationsError: null,
        notificationsLoading: false,
        readNotificationsList,
        readTotal: action.payload.metadata[0].total,
      };
    case types.GET_UNREAD_NOTIFICATIONS_COUNT_FAIL:
    case types.GET_NOTIFICATIONS_FAIL:
    case types.GET_READ_NOTIFICATIONS_FAIL:
    case types.GET_UNREAD_NOTIFICATIONS_FAIL:
      return {
        ...state,
        notificationsLoading: false,
        notificationsError: action.payload,
      };
    case types.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        notificationsLoading: false,
        notificationsError: null,
        unreadReadNotifications: action.payload,
      };
    case types.CLEAR_READ_NOTIFICATIONS_STATES:
      return {
        ...state,
        readNotificationsList: [],
        readTotal: 0,
      };
    case types.CLEAR_UNREAD_NOTIFICATIONS_STATES:
      return {
        ...state,
        unreadNotificationsList: [],
        unreadTotal: 0,
      };
    case types.MAKE_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        markAsReadSuccess: false,
        notificationsError: null,
      };
    case types.MAKE_NOTIFICATIONS_AS_READ_SUCCESS:
      let unreadReadNotifications = state.unreadReadNotifications ? state.unreadReadNotifications - 1 : 0
      return {
        ...state,
        markAsReadSuccess: true,
        unreadReadNotifications,
        notificationsError: null,
      };
    case types.MAKE_NOTIFICATIONS_AS_READ_FAIL:
      return {
        ...state,
        markAsReadSuccess: false,
        notificationsError: action.payload,
      };
    case types.MAKE_NOTIFICATIONS_AS_UNREAD:
      return {
        ...state,
        markAsUnreadSuccess: false,
        notificationsError: null,
      };
    case types.MAKE_NOTIFICATIONS_AS_UNREAD_SUCCESS:

      return {
        ...state,
        markAsUnreadSuccess: true,
        unreadReadNotifications: state.unreadReadNotifications + 1,
        notificationsError: null,
      };
    case types.MAKE_NOTIFICATIONS_AS_UNREAD_FAIL:
      return {
        ...state,
        markAsUnreadSuccess: false,
        notificationsError: action.payload,
      };
    case types.MARK_ALL_READ_SUCCESS:
      return {
        ...state,
        unreadNotificationsList: [],
        unreadTotal: 0,
        unreadReadNotifications: 0,
      };
    default:
      return state;
  }
}
