import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgentSRRequests } from "../../../../store/agent/action";
import { agentSRSelector } from "../../../../store/agent/selectors";

import BaseCollapse from "components/Shared/BaseCollapse";
import TableView from "components/Shared/TableView/TableView";
import TenantGridView from "components/TenantScreenings/view/grid-view/TenantGridView";
import { useWindowSize } from "hooks/useWindowSize";
import { LEAD_SR_REPORTS_COLUMNS } from "./columns";
import Section from "components/Shared/Section/Section";

function AgentLeadSRs({ renterId, agentId }: { renterId: string; agentId: string }) {
  const dispatch = useDispatch();
  const { loading = false, data = [] } = useSelector(agentSRSelector);
  const { width } = useWindowSize();
  const isMobile = (width as number) <= 768;

  useEffect(() => {
    if (renterId && agentId) dispatch(getAgentSRRequests({ renterId, agentId }));
  }, [renterId, agentId]);

  if (data.length === 0) return null;

  return (
    <Spin spinning={loading} className={"global-loading"}>
      <Section shadow>
        {isMobile ? (
          <TenantGridView paginationValues={{}} tableValues={data} total={data.length} />
        ) : (
          <TableView
            id="leadsScreeningsTableId"
            paginationValues={{}}
            tableValues={data}
            total={data.length}
            columns={LEAD_SR_REPORTS_COLUMNS()}
            tableClassName="lead-screening-table screening-requests-table"
          />
        )}
      </Section>
    </Spin>
  );
}

export default AgentLeadSRs;
