import React from "react";

export const VerifyEmailIcon = () => {
    return (
        <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2627:11555)">
        <path d="M65.6748 20.0977C62.7492 12.6534 53.7371 8.18909 46.104 11.1434C37.3911 14.5156 40.28 28.374 32.366 31.1764C28.091 32.6902 21.7714 27.0355 17.4521 26.1683C9.49311 24.5705 2.288 30.1863 0.513806 37.9631C-1.29624 45.8971 1.60687 56.4941 10.3239 58.6476C20.7076 61.2127 26.1164 51.8783 34.8272 49.1981C43.7662 46.4475 53.7054 52.9435 61.1056 45.0399C66.5729 39.2009 68.5718 27.4693 65.6748 20.0977V20.0977Z" fill="url(#paint0_linear_2627:11555)"/>
        <path opacity="0.1" d="M62.3998 23.3704C62.3998 18.2018 58.2099 14.0117 53.0412 14.0117C49.0369 14.0117 45.6216 16.5275 44.2854 20.064H10.4441C8.99862 20.064 7.8269 21.2357 7.8269 22.6812V51.6547C7.8269 53.1002 8.99862 54.2719 10.4441 54.2719H56.5557C58.0011 54.2719 59.1729 53.1002 59.1729 51.6547V30.4372C61.1487 28.7212 62.3998 26.1928 62.3998 23.3704Z" fill="#2626BC"/>
        <path d="M56.5557 51.0004H10.4441C8.99862 51.0004 7.8269 49.8287 7.8269 48.3832V19.4097C7.8269 17.9642 8.99862 16.7925 10.4441 16.7925H56.5557C58.0011 16.7925 59.1729 17.9642 59.1729 19.4097V48.3832C59.1729 49.8287 58.0011 51.0004 56.5557 51.0004Z" fill="white"/>
        <path d="M34.2971 29.2745L59.173 48.3833C59.173 49.8287 58.0013 51.0005 56.5558 51.0005H10.4441C8.99862 51.0005 7.8269 49.8287 7.8269 48.3833L32.7028 29.2745C33.1729 28.9134 33.827 28.9134 34.2971 29.2745Z" fill="white"/>
        <path d="M32.7028 38.5185L7.8269 19.4097C7.8269 17.9642 8.99862 16.7925 10.4441 16.7925H56.5557C58.0011 16.7925 59.1729 17.9642 59.1729 19.4097L34.2971 38.5185C33.827 38.8796 33.1729 38.8796 32.7028 38.5185Z" fill="white"/>
        <path d="M48.5417 47.5182H44.3367C43.9752 47.5182 43.6824 47.2253 43.6824 46.8639V43.9648C43.6824 43.6035 43.9752 43.3105 44.3367 43.3105H48.5417C48.9031 43.3105 49.196 43.6035 49.196 43.9648V46.8639C49.196 47.2253 48.9031 47.5182 48.5417 47.5182Z" fill="#5806B9"/>
        <path d="M40.8112 44.8511H37.1689C36.8075 44.8511 36.5146 44.5581 36.5146 44.1968C36.5146 43.8355 36.8075 43.5425 37.1689 43.5425H40.8112C41.1726 43.5425 41.4655 43.8355 41.4655 44.1968C41.4655 44.5581 41.1726 44.8511 40.8112 44.8511Z" fill="#2626BC"/>
        <path d="M40.8112 47.5183H28.0088C27.6474 47.5183 27.3545 47.2253 27.3545 46.864C27.3545 46.5027 27.6474 46.2097 28.0088 46.2097H40.8112C41.1726 46.2097 41.4655 46.5027 41.4655 46.864C41.4655 47.2255 41.1726 47.5183 40.8112 47.5183Z" fill="#2626BC"/>
        <path d="M62.2668 21.5976C63.0938 16.4962 59.6286 11.6902 54.5272 10.8632C49.4257 10.0362 44.6198 13.5014 43.7928 18.6028C42.9658 23.7043 46.431 28.5102 51.5324 29.3372C56.6339 30.1642 61.4398 26.6991 62.2668 21.5976Z" fill="#5806B9"/>
        <path d="M53.0412 30.1119C58.5623 30.1119 63.0541 25.6201 63.0541 20.0989C63.0541 14.5777 58.5624 10.0859 53.0412 10.0859C47.52 10.0859 43.0282 14.5777 43.0282 20.0989C43.0282 23.3107 44.55 26.1721 46.9086 28.0056L33.8985 37.9995C33.6642 38.1799 33.3362 38.18 33.1013 37.9995L8.50476 19.1053C8.65145 18.167 9.46539 17.4468 10.4441 17.4468H40.4588C40.8202 17.4468 41.1131 17.1538 41.1131 16.7925C41.1131 16.4312 40.8202 16.1382 40.4588 16.1382H10.4441C8.64019 16.1382 7.17261 17.6058 7.17261 19.4097V48.3832C7.17261 50.1871 8.64019 51.6547 10.4441 51.6547H56.5557C58.3596 51.6547 59.8271 50.1871 59.8271 48.3832V31.0915C59.8271 30.7302 59.5343 30.4372 59.1729 30.4372C58.8114 30.4372 58.5186 30.7302 58.5186 31.0915V47.0555L41.3881 33.8965L48.0541 28.7759C49.5237 29.6239 51.226 30.1119 53.0412 30.1119ZM25.6117 33.8965L8.4812 47.0555V20.7374L25.6117 33.8965ZM58.4928 48.6859C58.3465 49.6247 57.5349 50.3461 56.5557 50.3461H10.4441C9.46487 50.3461 8.65328 49.6247 8.50698 48.6859L26.6858 34.7215L32.3042 39.0373C32.6562 39.3076 33.0781 39.4429 33.4999 39.4429C33.9218 39.4429 34.3436 39.3078 34.6957 39.0373L40.3141 34.7214L58.4928 48.6859ZM44.3368 20.0989C44.3368 15.2994 48.2415 11.3945 53.0412 11.3945C57.8408 11.3945 61.7455 15.2992 61.7455 20.0989C61.7455 24.8984 57.8408 28.8033 53.0412 28.8033C48.2415 28.8033 44.3368 24.8984 44.3368 20.0989Z" fill="#2626BC"/>
        <path d="M48.9562 16.9076L50.2917 20.0991L48.9562 23.2906C48.7294 23.8327 49.2765 24.3757 49.8168 24.1449L57.8803 20.7008C58.41 20.4745 58.41 19.7237 57.8803 19.4974L49.8168 16.0533C49.2765 15.8225 48.7294 16.3656 48.9562 16.9076Z" fill="white"/>
        <path d="M43.2921 10.6873C42.8061 10.6873 42.3202 10.5024 41.9504 10.1325C41.2105 9.39262 41.2105 8.18885 41.9504 7.4491C42.6899 6.70948 43.8939 6.70922 44.6337 7.4491C45.3736 8.18898 45.3736 9.39275 44.6337 10.1325C44.2639 10.5024 43.778 10.6873 43.2921 10.6873ZM43.2921 8.20219C43.1414 8.20219 42.9905 8.25951 42.8756 8.3744C42.6461 8.60393 42.6461 8.97754 42.8756 9.20719C43.1054 9.43672 43.4786 9.43685 43.7084 9.20719C43.9379 8.97767 43.9379 8.60406 43.7084 8.3744C43.5936 8.25964 43.4427 8.20219 43.2921 8.20219Z" fill="#5806B9"/>
        <path d="M28.5978 60.1056C28.1119 60.1056 27.6259 59.9205 27.2561 59.5507C26.5162 58.8108 26.5162 57.6071 27.2561 56.8673C27.9957 56.1276 29.1999 56.1276 29.9395 56.8673C30.6794 57.6072 30.6794 58.811 29.9395 59.5507C29.5697 59.9206 29.0837 60.1056 28.5978 60.1056ZM28.5978 57.6204C28.447 57.6204 28.2961 57.6777 28.1813 57.7926C27.9517 58.0221 27.9517 58.3957 28.1813 58.6254C28.411 58.8551 28.7844 58.8551 29.0142 58.6254C29.2437 58.3959 29.2437 58.0223 29.0142 57.7926C28.8994 57.6779 28.7485 57.6204 28.5978 57.6204Z" fill="#5806B9"/>
        <path d="M38.5749 12.3724C38.4075 12.3724 38.2399 12.3086 38.1123 12.1809L37.3318 11.4004L36.5512 12.1809C36.2959 12.4363 35.8813 12.4363 35.626 12.1809C35.3704 11.9253 35.3704 11.511 35.626 11.2555L36.8692 10.0124C37.1245 9.75694 37.539 9.75694 37.7943 10.0124L39.0375 11.2555C39.2931 11.5111 39.2931 11.9254 39.0375 12.1809C38.9099 12.3084 38.7424 12.3724 38.5749 12.3724Z" fill="#5806B9"/>
        <path d="M57.5449 57.6202C57.3775 57.6202 57.2099 57.5564 57.0823 57.4286L56.3017 56.6482L55.5212 57.4286C55.2658 57.6841 54.8513 57.6841 54.596 57.4286C54.3404 57.1731 54.3404 56.7588 54.596 56.5033L55.8391 55.2602C56.0944 55.0047 56.509 55.0047 56.7643 55.2602L58.0075 56.5033C58.2631 56.7589 58.2631 57.1732 58.0075 57.4286C57.8799 57.5562 57.7123 57.6202 57.5449 57.6202Z" fill="#5806B9"/>
        <path d="M23.4087 58.2418C23.2413 58.2418 23.0737 58.1779 22.9461 58.0502L22.1655 57.2698L21.3849 58.0502C21.1296 58.3057 20.715 58.3057 20.4597 58.0502C20.2042 57.7947 20.2042 57.3804 20.4597 57.1249L21.7029 55.8818C21.9582 55.6263 22.3728 55.6263 22.6281 55.8818L23.8712 57.1249C24.1268 57.3805 24.1268 57.7948 23.8712 58.0502C23.7435 58.1778 23.576 58.2418 23.4087 58.2418Z" fill="#5806B9"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_2627:11555" x1="56.575" y1="65.7757" x2="25.9539" y2="25.2095" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D2DEFF" stopOpacity="0"/>
        <stop offset="1" stopColor="#B7C5FF"/>
        </linearGradient>
        <clipPath id="clip0_2627:11555">
        <rect width="67" height="67" fill="white"/>
        </clipPath>
        </defs>
        </svg>        

    );
}