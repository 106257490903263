import {RootStateOrAny, useSelector} from 'react-redux';

export const dashboardListingsSelector = (state: RootStateOrAny) => state.dashboardReducer.listings;
export const dashboardLandlordsSelector = (state: RootStateOrAny) => state.dashboardReducer.landlords;
export const dashboardLeadsSelector = (state: RootStateOrAny) => state.dashboardReducer.leads;
export const dashboardFollowUpsSelector = (state: RootStateOrAny) => state.dashboardReducer.followUps;
export const dashboardLoadingSelector = (state: RootStateOrAny) => state.dashboardReducer.dashboardLoading;
export const dashboardisHaveDataSelector = (state: RootStateOrAny) => state.dashboardReducer.isHaveData;
export const dashboardFollowUpSnoozeSuccessSelector = (state:RootStateOrAny) => state.dashboardReducer.snoozeSuccess
export const dashboardFollowUpEditSuccessSelector = (state:RootStateOrAny) => state.dashboardReducer.editDashboardFollowUpSuccess
export const dashboardSnoozedFUSelector = (state:RootStateOrAny) => state.dashboardReducer.snoozedFollowUp
export const dashboardFuAddedSuccessSelector = (state:RootStateOrAny) => state.dashboardReducer.dashboardFUAddedSuccess
export const getDashboardFUDataAfterChangesSelector = (state:RootStateOrAny) => state.dashboardReducer.getDashboardFUDataAfterChanges


