import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, Input, Row, Spin, Typography } from "antd";
import {
  EMAIL_VALIDATION,
  NOT_REQUIRED_EMAIL_VALIDATION,
  NOT_REQUIRED_PHONE,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
  REQUIRED,
  REQUIRED_NAME_VALIDATION,
  REQUIRED_PHONE_NUMBER_VALIDATION,
} from "../../../../helpers/validations";
import { StepFormComponent } from "../types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListingUpdateState,
  fillListingData,
  setCurrentSteps,
  setIsCurrentFormChange,
  updateListing,
} from "../../../../store/listings/action";
import { EnumInterface, IObjectKeys } from "../../../../helpers/globalTypes";
import {
  makeMultiEnumOption,
  openNotification,
  parseMultiEnumOptions,
  sanitizePhoneNumber,
  scrollToFirstErrorField,
} from "../../../../helpers/globalFunctions";
import { enumsSelector } from "../../../../store/enums/selectors";
import {
  currentStepSelector,
  isCurrentFormChangedSelector,
  listingDataSelector,
  listingLoadingSelector,
  listingUpdateSuccessSelector,
  stepClickedSelector,
} from "../../../../store/listings/selectors";
import TenantsPart from "./components/tenants-part/TenantsPart";
import MaskedInput from "../../../Shared/MaskedPhoneInput";
import "./styles.scss";
import StepsActionButtons from "../StepsActionButtons";
import ScrollToTopWrapper from "../../../Shared/ScrollToTopWrapper";
import { windowSelector } from "../../../../store/app/selectors";
import { debounce } from "lodash";

interface ShowingProcedureI extends StepFormComponent {
  statusChangeForm?: boolean;
}

const { Title } = Typography;
const { TextArea } = Input;
const ShowingProcedure = ({ form, statusChangeForm }: ShowingProcedureI) => {
  const dispatch = useDispatch();
  const updateListingSuccess = useSelector(listingUpdateSuccessSelector);
  const enums = useSelector(enumsSelector);
  const listingData = useSelector(listingDataSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const [showManagerInputs, setShowManagerInputs] = useState(false);
  const [showTenantsInputs, setShowTenantsInputs] = useState(false);
  const [isPhoneOrEmailFilled, setIsPhoneOrEmailFilled] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [showKeyCode, setShowKeyCode] = useState(false);
  const [showLockBoxCode, setShowLockBoxCode] = useState(false);
  const stepClicked = useSelector(stepClickedSelector);
  const current = useSelector(currentStepSelector);
  const { type } = useSelector(windowSelector);
  const isCurrentFormChanged = useSelector(isCurrentFormChangedSelector);

  const next = () => {
    dispatch(setCurrentSteps(current + 1));
  };
  const onFinishFailed = () => {
    scrollToFirstErrorField();
  };

  const setPropManagerPhoneOrEmail = debounce((phone, email) => {
    if (phone?.length > 0 || email?.length > 0) {
      setIsPhoneOrEmailFilled(true);
    } else {
      setIsPhoneOrEmailFilled(false);
    }
  }, 500);

  const onFieldsChange = (changedField: any) => {

    if (
      changedField[0]?.name.includes("property_manager_phone") ||
      changedField[0]?.name.includes("property_manager_email")
    ) {
      setPropManagerPhoneOrEmail(form.getFieldValue("property_manager_phone"), form.getFieldValue("property_manager_email"));
    }


    if (!isCurrentFormChanged) dispatch(setIsCurrentFormChange(true));
    // eslint-disable-next-line no-restricted-globals
    if (Array.isArray(changedField[0]?.name)) {
      if (
        // eslint-disable-next-line no-restricted-globals
        changedField[0]?.name.length === 1 &&
        // eslint-disable-next-line no-restricted-globals
        changedField[0]?.name?.indexOf("tenants") > -1 &&
        // eslint-disable-next-line no-restricted-globals
        changedField[0]?.value?.length === 0
      ) {
        const { procedure } = form.getFieldsValue();
        // eslint-disable-next-line no-restricted-globals
        if (procedure?.length) {
          form.setFieldsValue({
            procedure: procedure.filter(
              (el: string) => el !== "call_tenants",
            ),
          });
          setShowTenantsInputs(false);
        }
      }
      // eslint-disable-next-line no-restricted-globals
      if (changedField[0]?.name?.indexOf("procedure") > -1) {
        setShowManagerInputs(
          changedField[0]?.value?.indexOf("call_manager") > -1,
        );
        setShowTenantsInputs(
          changedField[0]?.value?.indexOf("call_tenants") > -1,
        );
        setShowOther(changedField[0]?.value?.indexOf("other") > -1);
        setShowKeyCode(changedField[0]?.value?.indexOf("key") > -1);
        setShowLockBoxCode(
          changedField[0]?.value?.indexOf("lockbox") > -1,
        );
      }
    }
  };

  useEffect(() => {
    dispatch(setIsCurrentFormChange(false));
  }, []);

  useEffect(() => {
    setPropManagerPhoneOrEmail(form.getFieldValue("property_manager_phone"), form.getFieldValue("property_manager_email"));
  }, [setPropManagerPhoneOrEmail, form]);

  useEffect(() => {
    if (!showTenantsInputs) {
      form.setFieldsValue({ tenants: [] });
    }
    if (!showLockBoxCode) {
      form.setFieldsValue({ lockbox_code: "" });
    }
    if (!showKeyCode) {
      form.setFieldsValue({ key_number: "" });
    }
    if (!showOther) {
      form.setFieldsValue({ ls_description: "" });
    } else {
      form.setFieldsValue({
        ls_description: listingData?.listingShowingOfList?.ls_description || "",
      });
    }
    if (!showManagerInputs) {
      form.setFieldsValue({
        property_manager_name: "",
        property_manager_phone: "",
        property_manager_email: "",
      });
    }

  }, [
    showTenantsInputs,
    showLockBoxCode,
    showOther,
    showKeyCode,
    showManagerInputs,
  ]);

  const getCheckbox = (enumObj: EnumInterface, inlineOrColumn: string) => {
    if (!enumObj) return;
    const items = enumObj.options.map((opt: IObjectKeys) =>
      Object.entries(opt),
    );
    return (
      <Form.Item
        labelCol={{ span: inlineOrColumn === "inline" ? 24 : 6 }}
        name={enumObj.key}
        // label={inlineOrColumn === "inline" && enumObj.label}
        rules={[{ required: false }]}
        className={"tenant-checkbox"}
      >
        <Checkbox.Group>
          <Row className="mt-6">
            {items.map((item, i) => {
              let checkItem =
                item && item[0] && item[0].length > 0 ? item[0] : [];
              return (
                <Col
                  id={
                    showTenantsInputs &&
                      // eslint-disable-next-line no-restricted-globals
                      checkItem?.length > 0 &&
                      checkItem[1] === "Contact Tenants"
                      ? "steps-container"
                      : ""
                  }
                  span={inlineOrColumn === "column" ? 12 : 24}
                  style={{ textAlign: "left" }}
                  key={i}
                >
                  <div className="flex-between">
                    <Checkbox value={checkItem[0]}> {checkItem[1]} </Checkbox>
                  </div>
                  {showTenantsInputs &&
                    // eslint-disable-next-line no-restricted-globals
                    checkItem?.length > 0 &&
                    checkItem[1] === "Contact Tenants" ? (
                    <TenantsPart form={form} />
                  ) : (
                    ""
                  )}
                  {showManagerInputs &&
                    // eslint-disable-next-line no-restricted-globals
                    checkItem?.length > 0 &&
                    checkItem[1] === "Contact Property Manager" ? (
                    <Row>
                      <Col
                        span={type === "mobile" ? 24 : 12}
                        className={"street-details"}
                      >
                        <Form.Item
                          name="property_manager_name"
                          label="Property Manager Name"
                          rules={[REQUIRED_NAME_VALIDATION]}
                          data-test-id={"property_manager_name"}
                          labelCol={{ span: 24 }}
                        >
                          <Input
                            autoComplete={"off"}
                            placeholder={"Name"}
                            autoFocus={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={type === "mobile" ? 24 : 12}>
                        <Form.Item
                          name="property_manager_phone"
                          label="Property Manager Number"
                          data-test-id={"property_manager_number"}
                          labelCol={{ span: 24 }}
                          rules={[isPhoneOrEmailFilled ? NOT_REQUIRED_PHONE : REQUIRED_PHONE_NUMBER_VALIDATION]}
                        >
                          <MaskedInput
                            inputMode="numeric"
                            mask={PHONE_NUMBER_MASK}
                            placeholder={PHONE_NUMBER_PLACEHOLDER}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={type === "mobile" ? 24 : 12}>
                        <Form.Item
                          name="property_manager_email"
                          label="Property Manager Email"
                          data-test-id={"property_manager_email"}
                          labelCol={{ span: 24 }}
                          rules={[isPhoneOrEmailFilled ? NOT_REQUIRED_EMAIL_VALIDATION : EMAIL_VALIDATION]}
                        >
                          <Input autoComplete={"off"} placeholder={"Email"} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {showLockBoxCode &&
                    // eslint-disable-next-line no-restricted-globals
                    checkItem?.length > 0 &&
                    checkItem[1] === "Lockbox" ? (
                    <Row>
                      <Col span={24} className={"street-details"}>
                        <Form.Item
                          name="lockbox_code"
                          label="Code"
                          data-test-id={"street-details"}
                          labelCol={{ span: 24 }}
                          rules={[
                            {
                              required: showLockBoxCode,
                              message: "Lockbox code is required",
                            },
                          ]}
                        >
                          <Input autoComplete={"off"} autoFocus={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {showKeyCode &&
                    // eslint-disable-next-line no-restricted-globals
                    checkItem?.length > 0 &&
                    checkItem[1] === "Key Number" ? (
                    <Row>
                      <Col span={24} className={"street-details"}>
                        <Form.Item
                          name="key_number"
                          label="Key Label"
                          data-test-id={"key_number"}
                          labelCol={{ span: 24 }}
                          rules={[
                            {
                              required: showKeyCode,
                              message: "Key number is required",
                            },
                          ]}
                        >
                          <Input autoComplete={"off"} autoFocus={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    );
  };

  const onSubmit = (data: IObjectKeys) => {
    data.lease_start_date = null;
    data.lease_ending_date = null;
    data.other_renewal_date = null;
    data.renewal_date = 0;
    if (data?.procedure?.indexOf("call_tenants") > -1) {
      if (!data.tenants.length) {
        return openNotification(
          "error",
          "Please add Tenant's contact details.",
        );
      }
      for (let i = 0; i < data.tenants.length; i++) {
        let tenant = data.tenants[i];
        if (!tenant.phone && !tenant.email) {
          return openNotification(
            "error",
            "At least you should mention tenant email or phone!",
          );
        }
        if (tenant.phone) {
          tenant.phone = sanitizePhoneNumber(tenant.phone);
        }
      }
    } else {
      data.tenants = [];
    }

    if (data?.procedure?.indexOf("other") === -1) {
      data.ls_description = "";
    }
    if (data?.procedure?.indexOf("key") === -1) {
      data.key_number = "";
    }
    if (data?.procedure?.indexOf("lockbox") === -1) {
      data.lockbox_code = "";
    }
    if (data?.procedure?.indexOf("call_manager") > -1) {
      data.property_manager_phone = sanitizePhoneNumber(
        data.property_manager_phone,
      );
    } else {
      data.property_manager_phone = "";
      data.property_manager_name = "";
      data.property_manager_email = "";
    }

    data.procedure = makeMultiEnumOption(data.procedure);
    if (statusChangeForm) {
      data.status = "Available";
    }
    if (listingData._id) {
      dispatch(
        fillListingData({
          tenants: data.tenants,
          listingShowingOfList: {
            ...listingData.listingShowingOfList,
            tenant_ids: data.tenants,
            ...data,
          },
        }),
      );
      return dispatch(
        updateListing({
          lId: listingData._id,
          ...data,
          step: "showing_procedure",
        }),
      );
    } else {
      dispatch(fillListingData(data));
    }
  };

  useEffect(() => {
    if (listingData.hasOwnProperty("procedure")) {
      setShowManagerInputs(listingData?.procedure.indexOf("call_manager") > -1);
      setShowTenantsInputs(listingData?.procedure.indexOf("call_tenants") > -1);
      setShowKeyCode(listingData?.procedure.indexOf("key") > -1);
      setShowOther(listingData?.procedure.indexOf("other") > -1);
      setShowLockBoxCode(listingData?.procedure.indexOf("lockbox") > -1);
    }
    if (listingData._id) {
      const fieldsToSet: IObjectKeys = {};
      [
        "tenants",
        "procedure",
        "lockbox_code",
        "ls_description",
        "property_manager_name",
        "property_manager_phone",
        "property_manager_email",
        "key_number",
      ].map((field: string) => {
        if (field === "tenants") {
          const tenantsData = listingData?.rentersOfListing;
          fieldsToSet[field] =
            tenantsData?.map((tenant: IObjectKeys) => {
              return {
                email: tenant?.email,
                phone: tenant?.phone,
                name: tenant?.name,
              };
            }) || [];
        } else if (
          field === "procedure" &&
          listingData?.listingShowingOfList?.procedure
        ) {
          fieldsToSet[field] = parseMultiEnumOptions(
            listingData.listingShowingOfList[field],
          );
          setShowManagerInputs(
            listingData?.listingShowingOfList?.procedure?.call_manager,
          );
          setShowTenantsInputs(
            listingData?.listingShowingOfList?.procedure?.call_tenants,
          );
          setShowKeyCode(listingData?.listingShowingOfList?.procedure?.key);
          setShowOther(listingData?.listingShowingOfList?.procedure?.other);
          setShowLockBoxCode(
            listingData?.listingShowingOfList?.procedure?.lockbox,
          );
        } else {
          if (listingData?.listingShowingOfList?.hasOwnProperty(field)) {
            fieldsToSet[field] = listingData?.listingShowingOfList[field];
          }
        }
      });
      form.setFieldsValue(fieldsToSet);
    }
  }, [listingData]);

  useEffect(() => {
    if (!stepClicked && updateListingSuccess) {
      next && next();
    }
    return () => {
      dispatch(clearListingUpdateState());
    };
  }, [updateListingSuccess]);

  return (
    <ScrollToTopWrapper>
      <Spin tip="Submitting..." spinning={listingLoading}>
        <Title level={4}>Showing Procedure</Title>
        <Form
          layout={"horizontal"}
          className="landlord-core-details-form"
          form={form}
          onFinish={onSubmit}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item>
            <Row>
              <Col span={24}>
                {// eslint-disable-next-line no-restricted-globals
                  enums?.length > 0
                    ? getCheckbox(
                      enums.find(
                        (item: EnumInterface) => item.key === "procedure",
                      ),
                      "inline",
                    )
                    : ""}
              </Col>
            </Row>
            {showOther && (
              <Row>
                <Col span={24} className={"street-details"}>
                  <Form.Item
                    name="ls_description"
                    data-test-id={"ls_description"}
                  >
                    <TextArea
                      autoFocus={true}
                      placeholder={
                        "Ex. Leave key under rug or lockbox located on front door"
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.Item>
        </Form>
      </Spin>
      {!statusChangeForm && <StepsActionButtons form={form} />}
    </ScrollToTopWrapper>
  );
};

export default ShowingProcedure;
