import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Form } from "antd";
import { US_DATE_FORMAT } from "../../../../../../../helpers/constants";
import moment from "moment";
import { FormInstance } from "antd/es/form";
import "./FilterDatePicker.scss";

const FilterDatePicker = ({ form }: { form: FormInstance; }) => {
  const [endDate, setEndDate] = useState("");
  const handleFromEndDateChange = useCallback((_date: any, dateString: any) => {
    setEndDate(dateString);
  }, []);

  useEffect(() => {
    if (endDate.length) {
      form.submit();
    }
  }, [endDate]);

  return (
    <div className={"filter-form-date-picker"}>
      <div className={"filter-form-date-picker-endWrapper"}>
        <Form.Item
          name={"availability_date_to"}
          labelCol={{ span: 24 }}
          className={"chip-checkbox enum_input move-in-date req-date-item"}
        >
          <DatePicker
            getPopupContainer={() => {
              return document.querySelector(
                ".filter-form-date-picker-endWrapper"
              ) as HTMLElement;
            }}
            inputReadOnly={true}
            showToday={false}
            dropdownClassName=""
            format={`${US_DATE_FORMAT}`}
            placeholder=""
            suffixIcon={null}
            value={moment()}
            onChange={handleFromEndDateChange}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default FilterDatePicker;
