let unauthorized = false

export const setUnauthorized = (status:boolean) =>{
    unauthorized = status
}

const getUnauthorized = () =>{
    return unauthorized
}

export default getUnauthorized