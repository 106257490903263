import React, { useEffect, useState } from "react";
import BaseButton from "../../../../../Shared/BaseButton";
import { push } from "connected-react-router";
import routes from "../../../../../../routes/routesCode";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsService } from "helpers/analyticsService";
import { flatfileEnumsSelector } from "store/flatfileEnums/selectors";
import { IField } from "@flatfile/adapter/build/main/interfaces";
import { windowSelector } from "../../../../../../store/app/selectors";
import { importByJsonFields } from "../../../../../../helpers/flatfile/validators";
import { userSelector } from "store/user/selectors";
import { SSO_SOURCES, TRIGGER_BUTTON_LABEL } from "helpers/constants";
import LockIcon from "components/Leads/icons/LockIcon";
import PremiumUpgradeModal from "components/Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { usersFreeSubscriptionLimits } from "helpers/globalFunctions";
import { useAppContext } from "libs/contextLib";
import { isCLAWUser } from "helpers/userHelpers";
import { PlusIcon } from "@heroicons/react/24/outline";

const AddImportListingsSection = () => {
  const { isNotPremiumUser } = useAppContext();
  const dispatch = useDispatch();
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  // const [isOpenImportButton, setIsOpenImportButton] = useState<boolean>(false);
  const flatfileEnums = useSelector(flatfileEnumsSelector);
  const [importFields, setImportFields] = useState<IField[]>([]);
  const { type } = useSelector(windowSelector);
  const userData = useSelector(userSelector);
  const usersFreeSubscriptionLimit: any = usersFreeSubscriptionLimits({ userData, isNotPremiumUser });

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (flatfileEnums?.length) {
      const fields = importByJsonFields(flatfileEnums);
      setImportFields(fields);
    }
  }, [flatfileEnums]);

  // useEffect(() => {
  //   if (queryObject?.importMode) {
  //     setIsOpenImportButton(true);
  //   }
  // }, [history.location.search, queryObject.importMode]);

  // const closeModal = () => {
  //   setErrors([]);
  //   setErrorModalVisible(false);
  // };

  const addListingHandler = () => {
    if (usersFreeSubscriptionLimit?.listings) {
      setPremiumModalVisible(true);
    } else {
      AnalyticsService.reportAddListingClick();
      dispatch(push(routes.manageListing.path));
    }
  };

  return (
    <div className={"listing-add-import-section"}>
      {!isCLAWUser(userData) && (
        <BaseButton
          dataTestId={"add-listing-btn"}
          variant="primary"
          icon={usersFreeSubscriptionLimit?.listings ? <LockIcon /> : null}
          onClick={addListingHandler}
          modalType="listing"
        >
          {type === "mobile" ? "Add" : "Add Listing"}
        </BaseButton>
      )}
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_TEAM_MEMBER}
      />
    </div>
  );
};

export default React.memo(AddImportListingsSection);
