import React, { useCallback, useEffect, useRef, useState } from "react";
import FilterFormTitle from "../filter-form-title/FilterFormTitle";
import "./PriceFilterWrapper.scss";
import Form, { FormInstance } from "antd/es/form";
import { urlQueryToObject } from "../../../helpers/listingsCreateGetSearchQuery";
import { useLocation } from "react-router";
import { debounce } from "lodash";
import {IPriceInterface} from "../../../helpers/filterFormHelper";

const initialPriceState = { from: "", to: null };

const PriceFilterWrapper = ({
  form,
  isClearAllClicked,
}: {
  form: FormInstance;
  isClearAllClicked: boolean;
}) => {
  const [price, setPrice] = useState<IPriceInterface>(initialPriceState);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const delayFilterByPrice = useRef(
    debounce(() => {
      form.submit();
    }, 1000)
  );
  const handleChange = useCallback((value, type) => {
    if (type === "from") {
      setPrice((prevState) => {
        return {
          ...prevState,
          from: value,
        };
      });
    } else {
      setPrice((prevState) => {
        return {
          ...prevState,
          to: +value,
        };
      });
    }
    delayFilterByPrice.current();
  }, []);

  useEffect(() => {
    if (queryObject.asked_rent_from) {
      setPrice((prevState) => {
        return {
          ...prevState,
          from: queryObject.asked_rent_from,
        };
      });
    }
    if (queryObject.asked_rent_to) {
      setPrice((prevState) => {
        return {
          ...prevState,
          to: queryObject.asked_rent_to,
        };
      });
    }
  }, [queryObject.asked_rent_from, queryObject.asked_rent_to]);

  useEffect(() => {
    if (isClearAllClicked) {
      setPrice(initialPriceState);
    }
  }, [isClearAllClicked]);

  return (
    <div className={"price-filter-wrapper"}>
      <FilterFormTitle title={"Price"} />
      <div className={"price-filter-wrapper-inputs-wrapper"}>
        <Form.Item
          name={"asked_rent_from"}
          trigger={"onChange"}
          className={"price-filter-wrapper-inputs-wrapper-input"}
          label={"Min Price"}
        >
          <span
            className={`${
              price?.from
                ? "price-filter-wrapper-inputs-wrapper-input-filled"
                : ""
            }`}
          >
            <input
              className={`${
                price?.from
                  ? "price-filter-wrapper-inputs-wrapper-input-filled-input"
                  : ""
              }`}
              value={price?.from}
              onChange={(e) => handleChange(e?.target?.value, "from")}
              type="number"
              pattern={"^\\d*$"}
              placeholder="$"
              // onBlur={() => handleOnBlurEvent()}
            />
          </span>
        </Form.Item>
        <span>-</span>
        <Form.Item
          name={"asked_rent_to"}
          trigger={"onChange"}
          className={"price-filter-wrapper-inputs-wrapper-input"}
          label={"Max Price"}
        >
          <span
            className={`${
              price?.to
                ? "price-filter-wrapper-inputs-wrapper-input-filled"
                : ""
            }`}
          >
            <input
              pattern={"^\\d*$"}
              className={`${
                price?.from
                  ? "price-filter-wrapper-inputs-wrapper-input-filled-input"
                  : ""
              }`}
              value={price?.to ? price?.to : ""}
              onChange={(e) => handleChange(e?.target?.value, "to")}
              type="number"
              placeholder="$"
              // onBlur={() => handleOnBlurEvent()}
            />
          </span>
        </Form.Item>
      </div>
    </div>
  );
};

export default PriceFilterWrapper;
