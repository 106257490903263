import { deleteLandlordNote } from "../../../store/landlords/action";
import { generateHtmlFromDocument } from "../../../contentful/RichTextRenderer";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { landlordNotesLoadingSelector } from "../../../store/landlords/selectors";
import { Document } from "@contentful/rich-text-types";
import { useHistory } from "react-router-dom";
import BaseModal from "../BaseModal";
export interface DeleteNoteModalPropsI {
  setDeleteConfirmProps: (props: { visible: boolean; noteId: string }) => void;
  deleteConfirmProps: { visible: boolean; noteId: string };
  modalContent?: Document | undefined;
  onOkayClick?: () => void;
}

const NoteDeleteConfirmModal = ({
  setDeleteConfirmProps,
  deleteConfirmProps,
  modalContent,
  onOkayClick,
}: DeleteNoteModalPropsI) => {
  const history = useHistory();
  const notesLoading = useSelector(landlordNotesLoadingSelector);
  const dispatch = useDispatch();
  return (
    <BaseModal
      dataTestId={"add-note-modal-test"}
      classname={"add-edit-landlord-note-modal"}
      cancelButtonProps={{
        className:
          "p-3 px-5 text-base rounded-md hover:bg-purple-100 ring-1 ring-inset ring-purple-400 hover:text-purple-800 text-purple-900 h-12",
      }}
      okButtonProps={{
        className:
          "p-3 px-5 h-12 text-base rounded-md hover:text-purple-50 text-white bg-purple-700 focus:bg-purple-600 active:bg-purple-800",
      }}
      modalTitle="Delete a Note/Reminder"
      isModalVisible={deleteConfirmProps.visible}
      confirmLoading={notesLoading}
      onOk={() => {
        if (history.location.pathname.includes("landlord")) {
          dispatch(deleteLandlordNote(deleteConfirmProps.noteId));
        } else {
          onOkayClick && onOkayClick();
        }
        setDeleteConfirmProps({
          visible: false,
          noteId: "",
        });
      }}
      onCancel={() => {
        setDeleteConfirmProps({
          visible: false,
          noteId: "",
        });
      }}
    >
      {modalContent ? generateHtmlFromDocument(modalContent) : ""}
    </BaseModal>
  );
};
export default NoteDeleteConfirmModal;
