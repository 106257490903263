import React from "react";

const AddTag = ({ color }: { color?: string; }) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7.54016"
        cy="8.25988"
        r="6.85286"
        fill={color ? color : "#B7B7B7"}
        stroke="white"
        strokeWidth="1.05429"
      />
      <g clipPath="url(#clip0_1609_632)">
        <path
          d="M8.21854 7.69474C8.15616 7.69474 8.10558 7.64416 8.10558 7.58178V5.09668H6.97599V7.58178C6.97599 7.64416 6.92541 7.69474 6.86303 7.69474H4.37793V8.82433H6.86303C6.92541 8.82433 6.97599 8.87491 6.97599 8.93729V11.4224H8.10558V8.93729C8.10558 8.87491 8.15616 8.82433 8.21854 8.82433H10.7036V7.69474H8.21854Z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1609_632">
          <rect
            width="6.32571"
            height="6.32571"
            fill="white"
            transform="translate(4.37842 5.09668)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddTag;
