import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import "./ConsideringBuying.scss";
import FormStepsTitle from "../../form-steps-title/FormStepsTitle";
import SwitchButton from "components/ListingView/ListingForm/SwitchButton";
import { fillRequirementFormData } from "store/requirement/action";
import { requirementFormDataSelector, requirementFormEdittingSelector } from "store/requirement/selectors";
import { singleLeadDataSelector } from "store/leads/selectors";

const ConsideringBuying = () => {
  const dispatch = useDispatch();
  const [switchInputData, setSwitchInputData] = useState<boolean | undefined>(false);
  const requirementEnums = useSelector(requirementEnumsSelector);
  const requirementFormData = useSelector(requirementFormDataSelector);
  const editedData = useSelector(requirementFormEdittingSelector);
  const { requirement } = useSelector(singleLeadDataSelector);
  const isEditMode = window.location.pathname.includes("edit");

  const consideringBuying = requirementEnums?.find((item: EnumInterface) => item?.key === "considering_buying");

  const handleChange = (e: {
    target: {
      name: any;
      checked: any;
    };
    persist(): void;
  }) => {
    e.persist();
    const { checked } = e.target;
    setSwitchInputData(!checked);
    dispatch(
      fillRequirementFormData({
        ...requirementFormData,
        considering_buying: !checked,
      })
    );
  };

  useEffect(() => {
    if (isEditMode) {
      const is_considering_buying = requirement?.considering_buying || editedData?.considering_buying;
      if (is_considering_buying === "Yes") {
        setSwitchInputData(true);
      }
    } else {
      dispatch(
        fillRequirementFormData({
          ...requirementFormData,
          considering_buying: switchInputData,
        })
      );
    }
  }, [requirement, isEditMode]);

  return (
    <div className={"considering-buying-section-wrapper my-6"}>
      <div className="mb-4">
        <FormStepsTitle
          text={"Are you planning on buying a home (apartment, house, townhouse etc.) in the next 1-2 years?"}
        />
      </div>
      <SwitchButton
        required={true}
        name={consideringBuying?.key}
        label={""}
        onChange={handleChange}
        checked={switchInputData}
      />
    </div>
  );
};

export default ConsideringBuying;
