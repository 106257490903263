import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootSaga from "store/sagas";
import { routerMiddleware } from "connected-react-router";

import createRootReducers from "store/reducers";
import "regenerator-runtime/runtime";

export const history = createBrowserHistory({});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [""],
  debug: process.env.NODE_ENV !== "production",
};

const makeStore = () => {
  const middleware = [];
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  // const loggerMiddleware = createLogger({
  //   predicate: () => process.env.NODE_ENV !== "production",
  // });

  // @ts-ignore
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // if (process.env.NODE_ENV !== "production") {
  //   middleware.push(loggerMiddleware);
  // }

  middleware.push(routerMiddleware(history));

  const store: any = createStore(
    persistReducer(persistConfig, createRootReducers(history)),
    process.env.NODE_ENV !== "production"
      ? composeEnhancers(applyMiddleware(...middleware))
      : applyMiddleware(...middleware)
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

const store = makeStore();

export const persistor = persistStore(store);

export default store;
