import React from "react";

export const DropdownTriangleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      width="16"
      height="20"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M509.6152406654358,427.394 L284.74924066543576,52.617 c-12.074,-20.122 -41.237,-20.122 -53.311,0 L6.573240665435791,427.394 c-12.433,20.72 2.493,47.08 26.655,47.08 h449.732 C507.1232406654358,474.474 522.0492406654358,448.114 509.6152406654358,427.394 z"
        fill="#ffffff"
        data-original="#000000"
        id="svg_4"
        fillOpacity="1"
        stroke="#ccc"
        strokeOpacity="1"
      />
    </svg>
  );
};
