import BaseButton from "components/Shared/BaseButton";
import { AnalyticsService } from "helpers/analyticsService";
import { useDemoContext } from "libs/contextLib";
import React, { useEffect, useState } from "react";
// @ts-ignore
import AddToCalendar from "react-add-to-calendar";

export interface AddToCalendarEvent {
  title?: string;
  description?: string;
  location?: string;
  startTime?: string | Date;
  endTime?: string | Date;
}

export interface AddToCalendarProps {
  buttonClassClosed?: string;
  buttonClassOpen?: string;
  buttonLabel?: string;
  buttonTemplate?: any;
  buttonIconClass?: string;
  useFontAwesomeIcons?: boolean;
  buttonWrapperClass?: string;
  displayItemIcons?: boolean;
  optionsOpen?: boolean;
  dropdownClass?: string;
  event: AddToCalendarEvent;
  listItems?: any[];
  rootClass?: string;
}

export const AddToGoogleCalendar = ({ event }: AddToCalendarProps) => {
  const [items, setItems] = useState([{ google: "+ Google Calendar" }]);
  const [open, setOpen] = useState<boolean>(true);
  const { isDemo, demoAction } = useDemoContext();

  const setInitialState = () => {
    setItems([{ google: "+ Google Calendar" }]);
  };

  useEffect(() => {
    setInitialState();
    return setInitialState();
  }, []);

  return (
    <div
      onClick={() => {
        // setInitialState();
        AnalyticsService.reportEvent("add_to_calendar_link_clicks");
      }}
      className="add-follow-up-success-section"
    >
      {isDemo ? (
        <BaseButton onClick={() => demoAction("followUp")}>Add To Calendar</BaseButton>
      ) : (
        <AddToCalendar event={event} listItems={items} optionsOpen={false} />
      )}
    </div>
  );
};
