import React, { useCallback, useState } from "react";
import ListingsGridView from "./listings-grid-view/ListingsGridView";
import { useDispatch, useSelector } from "react-redux";
import {
  filterOptionsSelector,
  listingLoadingSelector,
  listingsListSelector,
  listingsTotalSelector,
  listingsViewTypeSelector,
  paginationValuesSelector,
  routerSelector,
} from "../../../../../../store/listings/selectors";
import { IObjectKeys } from "../../../../../../helpers/globalTypes";
import {
  createFilterQuery,
  urlQueryToObject,
} from "../../../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import { IListingsWrapper } from "../../../../types/ListingWrapperTypes";
import { getLandlordNotes, getLandlordsListLP, setCurrentLandlord } from "../../../../../../store/landlords/action";
import { userSelector } from "../../../../../../store/user/selectors";
import ListingTableView from "./listings-table-view/ListingTableView";
import { windowSelector } from "../../../../../../store/app/selectors";
import { ShowingListingCount } from "components/Shared/Pagination";

const ListingsViewWrapper = ({
  showingCount,
  setShowingCount,
  setCheckedListing,
  setAgentToContact,
  setLandlordActionModalVisible,
  setAddLandlordModal,
  setListingId,
}: IListingsWrapper) => {
  const dispatch = useDispatch();
  const listingsViewType = useSelector(listingsViewTypeSelector);
  const router = useSelector(routerSelector);
  const { landlordId } = useParams<IObjectKeys>();
  const pagination = useSelector(paginationValuesSelector);
  const listingsFilterOptions = useSelector(filterOptionsSelector);
  const _newURLSearchParams = new URLSearchParams(router.location.search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const total = useSelector(listingsTotalSelector);
  const loading = useSelector(listingLoadingSelector);
  const listingsList = useSelector(listingsListSelector);
  const userData = useSelector(userSelector);
  const { type, collapsed } = useSelector(windowSelector);
  const { current, pageSize } = pagination;

  const handleLandlordLinkClick = useCallback(
    (landlordInfo: any, listItem: any) => {
      const listing = listingsList.find(
        (singleListing: IObjectKeys) => singleListing?.landlordOfList?._id === landlordInfo?._id
      );
      if (landlordInfo?._id) {
        if (!landlordInfo?.is_private || (listing?.agent_id === userData?.agent_id && landlordInfo?.is_private)) {
          dispatch(getLandlordNotes(landlordInfo?._id));
        }
        setAgentToContact(landlordInfo);
        // dispatch(setCurrentLandlord(landlordInfo));
        setLandlordActionModalVisible(true);
      } else {
        dispatch(getLandlordsListLP({ page: 1, pageSize: 20 }));
        setListingId(listItem?._id);
        setAddLandlordModal(true);
      }
    },
    [listingsList, userData]
  );

  const pushByQueryChange = (
    listingsViewTypeArg: any,
    listingsFilterOptionsArg: any,
    paginationArg: any,
    queryObjectArg: any
  ) => {
    const newSearchQuery = createFilterQuery(
      listingsViewTypeArg,
      listingsFilterOptionsArg,
      paginationArg,
      queryObjectArg,
      landlordId
    );
    dispatch(
      push({
        pathname: router.location.pathname,
        search: newSearchQuery,
      })
    );
  };

  return (
    <>
      {!loading && total > 0 && (
        <div className="py-2">
          <ShowingListingCount total={total} current={current} pageSize={pageSize} />
        </div>
      )}

      {/* {!loading && !total ? <div>No listings</div> : null} */}

      {listingsViewType === "grid" ? (
        <ListingsGridView
          listingsViewType={listingsViewType}
          pushByQueryChange={pushByQueryChange}
          pagination={pagination}
          setShowingCount={setShowingCount}
          listingsFilterOptions={listingsFilterOptions}
          queryObject={queryObject}
          showingCount={showingCount}
          total={total}
          loading={loading}
          listingsList={listingsList}
          handleLandlordLinkClick={handleLandlordLinkClick}
          setCheckedListing={setCheckedListing}
          collapsed={collapsed}
          type={type}
        />
      ) : (
        <ListingTableView
          listingsViewType={listingsViewType}
          pagination={pagination}
          setShowingCount={setShowingCount}
          listingsFilterOptions={listingsFilterOptions}
          showingCount={showingCount}
          total={total}
          listingsList={listingsList}
          setCheckedListing={setCheckedListing}
          setLandlordActionModalVisible={setLandlordActionModalVisible}
          setListingId={setListingId}
          setAddLandlordModal={setAddLandlordModal}
        />
      )}
    </>
  );
};

export default ListingsViewWrapper;
