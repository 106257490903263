import { Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLandlordTags } from "store/landlords/action";
import { singleLandlordDataSelector } from "store/landlords/selectors";
import { getLeadData, updateLeadTags } from "store/leads/action";
import { singleLeadDataSelector } from "store/leads/selectors";
import RemoveIcon from "../../icons/RemoveIcon";
import "./SingleTag.scss";

const SingleTag = ({
  icon,
  label,
  classnames,
  name,
  hover,
  type,
}: {
  icon: string;
  label: string;
  name: string;
  classnames?: string;
  hover?: boolean;
  type: string;
}) => {
  const dispatch = useDispatch();
  const leadData = useSelector(singleLeadDataSelector);
  const landlord = useSelector(singleLandlordDataSelector);

  const handleClick = (e: any) => {
    if (type === "lead") {
      const data = leadData?.tags?.filter((tag: string) => tag !== name);
      dispatch(
        updateLeadTags({
          tags: [...data],
          requirements_id: leadData?.requirement?._id,
        })
      );
      dispatch(getLeadData(leadData?.requirement?._id));
    } else {
      const data = landlord?.tags?.filter((tag: string) => tag !== name);
      dispatch(
        updateLandlordTags({
          tags: [...data],
          landlord_id: landlord.landlord_id,
        })
      );
    }
  };
  return (
    <Tooltip
      overlayClassName={"fu-page tags-tooltip"}
      placement="top"
      title={label}
      color="white"
    >
      <div className={`tags ${classnames}`}>
        <div className={`tag-wrapper ${classnames}`}>
          <span role="img" aria-label={label}>
            {icon}
          </span>
        </div>
        {hover && (
          <div className="tags-delete-icon" onClick={handleClick}>
            <RemoveIcon />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default SingleTag;
