import LandlordsApi from "api/Users";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import LandlordApi from "../../api/Landlord";
import {
  addLandlordNoteFail,
  addLandlordNoteSuccess,
  addManualFollowUp,
  checkExistingLandlordFail,
  checkExistingLandlordSuccess,
  createLandlordFail,
  createLandlordSuccess,
  deleteLandlordNoteFail,
  deleteLandlordNoteSuccess,
  editLandlordFail,
  editLandlordSuccess,
  getLandlordFail,
  getLandlordFollowUpsFail,
  getLandlordFollowUpsSuccess,
  getLandlordNotesFail,
  getLandlordNotesSuccess,
  getLandlordSuccess,
  getLandlordsList,
  getLandlordsListLP,
  getLandlordsListLPSuccess,
  getLandlordsListSuccess,
  getListingsOfLandlordFail,
  getListingsOfLandlordSuccess,
  sendLandlordInfoBySmsFail,
  sendLandlordInfoBySmsSuccess,
  setToggleLandlordPrivacyFail,
  setToggleLandlordPrivacySuccess,
  updateLandlordNoteFail,
  updateLandlordNoteSuccess,
  updateLandlordTagsError, updateLandlordTagsSuccess
} from "./action";
import {
  ADD_LANDLORD_NOTE,
  CHECK_EXISTING_LANDLORD,
  CREATE_LANDLORD,
  DELETE_LANDLORD_NOTE,
  EDIT_LANDLORD,
  GET_LANDLORD,
  GET_LANDLORDS_LIST,
  GET_LANDLORDS_LIST_LANDLORD_PAGE,
  GET_LANDLORD_FOLLOW_UPS,
  GET_LANDLORD_NOTES,
  GET_LISTINGS_OF_LANDLORD,
  SEND_LANDLORD_INFO_BY_SMS,
  TOGGLE_LANDLORD_PRIVACY,
  UPDATE_LANDLORD_NOTE,
  UPDATE_LANDLORD_TAGS
} from "./types";

import { AnalyticsService } from "helpers/analyticsService";
import { getLoggedInUser } from "store/user/action";
import { generateErrorMessage, openNotification } from "../../helpers/globalFunctions";
import { IObjectKeys } from "../../helpers/globalTypes";

function* createLandlordSaga({ payload }: any) {
  try {
    const response = yield call(LandlordsApi.createLandlord, payload);
    yield put(createLandlordSuccess(response));
    yield put(getLandlordsListLP({}));
    yield put(getLoggedInUser());

    AnalyticsService.reportEvent("form_submit", {
      label: "create landlord | success"
    });
  } catch (e) {
    openNotification("error", generateErrorMessage(e));
    yield put(createLandlordFail(e));

    AnalyticsService.reportEvent("form_submit", {
      label: "create landlord | error",
      errorMessage: e.The
    });
  }
}

function* editLandlordSaga({ payload }: any) {
  try {
    const response = yield call(LandlordsApi.editLandlord, payload);
    yield put(editLandlordSuccess(response));
    yield put(getLandlordsList());
    yield put(getLoggedInUser());
    openNotification("success", "Landlord Updated");

    AnalyticsService.reportEvent("form_submit", {
      category: "landlords",
      label: "edit landlord | success"
    });
  } catch (e) {
    openNotification("error", generateErrorMessage(e));
    yield put(editLandlordFail(e));

    AnalyticsService.reportEvent("form_submit", {
      category: window.location.pathname,
      label: "edit landlord | error",
      errorMessage: e.The
    });
  }
}

function* getLandlordsListSaga() {
  try {
    const response = yield call(LandlordsApi.getLandlordsList);
    yield put(getLandlordsListSuccess(response.landlordsList));
  } catch (e) {
    openNotification("error", e.message);
    yield put(createLandlordFail(e));
  }
}

function* getLandlordsListLPSaga({ payload }: IObjectKeys) {
  try {
    const response = yield call(LandlordsApi.getLandlordsListLP, payload);
    yield put(getLandlordsListLPSuccess(response));
  } catch (e) {
    openNotification("error", e.message);
    yield put(createLandlordFail(e));
  }
}

function* getLandlordSaga(payload: IObjectKeys) {
  try {
    const response = yield call(LandlordApi.getLandlord, payload);
    yield put(getLandlordSuccess({ ...response.landlord, listingsCount: response.listingsCount,tags: response.tags }));
  } catch (e) {
    yield put(getLandlordFail(e));
  }
}

function* getListingsOfLandlordSaga({ payload }: any) {
  const { pageSize, current, user_id_of_landlord, status, ...rest } = payload;
  const _payload: IObjectKeys = {
    ...rest,
    page: current || 1,
    size: pageSize || 4,
    user_id_of_landlord_arr: [user_id_of_landlord]
  };

  if (status !== "") {
    _payload["status"] = status;
  } // todo remove after default value is set

  try {
    const response = yield call(
      LandlordApi.getListingsOfLandlord,
      _payload // todo
    );
    yield put(getListingsOfLandlordSuccess(response));
  } catch (e) {
    yield put(getListingsOfLandlordFail(e));
  }
}

function* getLandlordNotesSaga({ payload }: any) {
  try {
    const response = yield call(
      LandlordApi.getLandlordNotes,
      `${payload}?notable_type=landlord_note`
    );
    yield put(getLandlordNotesSuccess(response.notes));
  } catch (e) {
    yield put(getLandlordNotesFail(e));
  }
}

function* getLandlordFollowUpsSaga({ payload }: any) {
  try {
    const response = yield call(
      LandlordApi.getLandlordFollowUps,
      `${payload}?notable_type=landlord_note`
    );
    yield put(getLandlordFollowUpsSuccess(response.followUps));
  } catch (e) {
    yield put(getLandlordFollowUpsFail(e));
  }
}

function* addLandlordNoteSaga({ payload }: any) {
  try {
    const response = yield call(LandlordApi.addLandlordNote, payload);
    if (payload.todo_at) {
      yield put(addManualFollowUp(payload));
    }
    yield put(addLandlordNoteSuccess(response));
  } catch (e) {
    yield put(addLandlordNoteFail(e));
  }
}

function* deleteLandlordNoteSaga({ payload }: any) {
  try {
    const response = yield call(LandlordApi.deleteLandlordNote, payload);
    yield put(deleteLandlordNoteSuccess(payload));
  } catch (e) {
    yield put(deleteLandlordNoteFail(e));
  }
}

function* updateLandlordNoteSaga({ payload }: any) {
  try {
    const response = yield call(LandlordApi.updateLandlordNote, payload);
    yield put(updateLandlordNoteSuccess(response));
  } catch (e) {
    yield put(updateLandlordNoteFail(e));
  }
}

function* sendLandlordInfoBySmsSaga({ payload }: any) {
  try {
    const response = yield call(LandlordApi.sendLandlordIndoBySms, payload);
    openNotification("success", "Sms sent successfully");
    yield put(sendLandlordInfoBySmsSuccess(response));
  } catch (e) {
    openNotification("error", e.message);
    yield put(sendLandlordInfoBySmsFail(e));
  }
}

function* makeToggleLandlordPrivacy({ payload }: any) {
  try {
    const response = yield call(LandlordApi.makeLandlordPrivate, payload);
    if (response.success) {
      yield put(setToggleLandlordPrivacySuccess(response));
    }

    // yield put(getLandlordsListLP({}));
  } catch (e) {
    yield put(setToggleLandlordPrivacyFail(e));
  }
}

function* checkExistingLandlordSaga({ payload }: any) {
  try {
    const response = yield call(LandlordApi.checkExistingLandlord, payload);

    yield put(checkExistingLandlordSuccess(response));
  } catch (e) {
    yield put(checkExistingLandlordFail(e));
  }
}

function* updateLandlordTagsSaga({ payload }: any) {
  try {
    const response = yield call(LandlordApi.updateLandlordTags, payload);
    yield put(updateLandlordTagsSuccess(response));
  } catch (e) {
    openNotification("error", generateErrorMessage(e));
    yield put(updateLandlordTagsError(e));
  }
}

function* watchAddLandlordNoteSaga() {
  yield takeLatest(ADD_LANDLORD_NOTE, addLandlordNoteSaga);
}

function* watchGetLandlordNotesSaga() {
  yield takeLatest(GET_LANDLORD_NOTES, getLandlordNotesSaga);
}

function* watchUpdateLandlordNoteSaga() {
  yield takeLatest(UPDATE_LANDLORD_NOTE, updateLandlordNoteSaga);
}

function* watchDeleteLandlordNoteSaga() {
  yield takeLatest(DELETE_LANDLORD_NOTE, deleteLandlordNoteSaga);
}

function* watchGetLandlordFollowUpsSaga() {
  yield takeLatest(GET_LANDLORD_FOLLOW_UPS, getLandlordFollowUpsSaga);
}
function* watchCreateLandlord() {
  yield takeLatest(CREATE_LANDLORD, createLandlordSaga);
}
function* watchEditLandlord() {
  yield takeLatest(EDIT_LANDLORD, editLandlordSaga);
}

function* watchGetLandlordsListSaga() {
  yield takeLatest(GET_LANDLORDS_LIST, getLandlordsListSaga);
}

function* watchGetLandlordsListLPSaga() {
  yield takeLatest(GET_LANDLORDS_LIST_LANDLORD_PAGE, getLandlordsListLPSaga);
}

function* watchGetLandlordSaga() {
  yield takeLatest(GET_LANDLORD, getLandlordSaga);
}

function* watchGetListingsOfLandlordSaga() {
  yield takeLatest(GET_LISTINGS_OF_LANDLORD, getListingsOfLandlordSaga);
}

function* watchSendLandlordInfoBySmsSagaSaga() {
  yield takeLatest(SEND_LANDLORD_INFO_BY_SMS, sendLandlordInfoBySmsSaga);
}

function* watchToggleLandlordPrivacySaga() {
  yield takeLatest(TOGGLE_LANDLORD_PRIVACY, makeToggleLandlordPrivacy);
}
function* watchCheckExistingLandlordSaga() {
  yield takeLatest(CHECK_EXISTING_LANDLORD, checkExistingLandlordSaga);
}
function* watchUpdateLandlordTagsSaga() {
  yield takeLatest(UPDATE_LANDLORD_TAGS, updateLandlordTagsSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateLandlord),
    fork(watchGetLandlordsListSaga),
    fork(watchEditLandlord),
    fork(watchGetLandlordSaga),
    fork(watchGetListingsOfLandlordSaga),
    fork(watchAddLandlordNoteSaga),
    fork(watchGetLandlordNotesSaga),
    fork(watchUpdateLandlordNoteSaga),
    fork(watchGetLandlordFollowUpsSaga),
    fork(watchDeleteLandlordNoteSaga),
    fork(watchSendLandlordInfoBySmsSagaSaga),
    fork(watchGetLandlordsListLPSaga),
    fork(watchToggleLandlordPrivacySaga),
    fork(watchCheckExistingLandlordSaga),
    fork(watchUpdateLandlordTagsSaga),
  ]);
}
