import { all, fork, put, takeLatest, call, delay } from "redux-saga/effects";
import {
  ADD_LISTING,
  ADD_LISTING_NOTE,
  DELETE_LISTING,
  DELETE_LISTING_IMAGE,
  DELETE_LISTING_NOTE,
  DOWNLOAD_CSV_TEMPLATE,
  GET_LISTING_NOTES,
  GET_LISTINGS,
  GET_LISTINGS_FORM_ALGOLIA,
  GET_ONE_LISTING,
  GET_ONE_LISTING_FROM_PUBLIC_ID,
  UPDATE_LISTING,
  UPDATE_LISTING_NOTE,
  UPDATE_LISTING_PUBLISH_STATE,
  UPLOAD_CSV_LISTINGS,
} from "./types";
import {
  addListingFail,
  addListingNoteFail,
  addListingNoteSuccess,
  addListingSuccess,
  deleteListingFail,
  deleteListingSuccess,
  deleteListingImageFail,
  deleteListingImageSuccess,
  deleteListingNoteFail,
  deleteListingNoteSuccess,
  downloadCsvTemplateFail,
  downloadCsvTemplateSuccess,
  fillListingData,
  getListingNotesFail,
  getListingNotesSuccess,
  getListingsFromAlgolia,
  getListingsFromAlgoliaFail,
  getListingsFromAlgoliaSuccess,
  getListingsListFail,
  getListingsListSuccess,
  getOneListingFail,
  getOneListingSuccess,
  updateListingFail,
  updateListingNoteFail,
  updateListingNoteSuccess,
  updateListingPublishStateFail,
  updateListingPublishStateSuccess,
  updateListingSuccess,
  uploadCsvListingsFail,
  uploadCsvListingsSuccess,
} from "./action";
import ListingApi from "../../api/Listing";
import { generateErrorMessage, openNotification, makeArrStringToObj } from "../../helpers/globalFunctions";
import { push } from "connected-react-router";
import { AnalyticsService } from "helpers/analyticsService";
import routes from "routes/routesCode";
import { getLoggedInUser } from "store/user/action";
import { getAuthToken } from "helpers/authUtils";

function* addListingSaga({ payload }: any): any {
  try {
    const response = yield call(ListingApi.addListing, payload);
    openNotification("success", "Listing has been created successfully.");
    yield put(addListingSuccess(response));
    yield put(fillListingData(response));
    yield put(getLoggedInUser());
    yield put(push(`?lId=${response._id}`));
    AnalyticsService.reportEvent("listing_created_success", {
      category: "listings",
    });
  } catch (e) {
    openNotification("error", generateErrorMessage(e));
    yield put(addListingFail(e));
    AnalyticsService.reportEvent("listing_created_failure", {
      category: "listings",
      errorMessage: e,
    });
  }
}

function* updateListingSaga({ payload }: any): any {
  try {
    const response = yield call(ListingApi.updateListing, payload);
    openNotification("success", "Listing has been updated successfully.");
    yield put(updateListingSuccess(response));
    yield put(getLoggedInUser());
    AnalyticsService.reportEvent("listing_updated_success", {
      category: "listings",
    });
  } catch (e) {
    openNotification("error", generateErrorMessage(e));
    yield put(updateListingFail(e));
    AnalyticsService.reportEvent("listing_updated_failure", {
      category: "listings",
      errorMessage: e,
    });
  }
}

function* getListingsListSaga({ payload }: any): any {
  const { status, ...rest } = payload;
  const _payload = { ...rest };

  if (status !== "" && status !== "Show-all") {
    _payload["status"] = status;
  }

  if (!_payload.page) {
    _payload["page"] = 1;
  }
  if (!_payload.size) {
    _payload.size = 24;
  }

  const _pets_allowed = makeArrStringToObj(payload.pets_allowed);

  if (Object.keys(_pets_allowed).length) {
    _payload["pets_allowed"] = _pets_allowed;
  }

  try {
    const response = yield call(ListingApi.getListings, _payload);
    yield put(getListingsListSuccess(response));
  } catch (e) {
    yield put(getListingsListFail(e));
  }
}

function* getListingsFromAlgoliaSaga({ payload }: any): any {
  try {
    const response = yield call(ListingApi.getListingsFromAlgolia, payload);
    yield put(getListingsFromAlgoliaSuccess(response));
  } catch (e) {
    yield put(getListingsFromAlgoliaFail(e));
  }
}

function* getOneListingSaga({ payload }: any): any {
  try {
    const response =
      window.location.href.includes("public/listings") || !getAuthToken()
        ? yield call(ListingApi.getOneListingFromPublicId, payload.replace("?isShared=true", ""))
        : yield call(ListingApi.getOneListing, payload);
    yield put(getOneListingSuccess(response));
  } catch (e) {
    yield put(getOneListingFail(e));
  }
}

function* getOneListingFromPublicIdSaga({ payload }: any): any {
  try {
    const response = yield call(ListingApi.getOneListingFromPublicId, payload);
    yield put(getOneListingSuccess(response));
  } catch (e) {
    yield put(getOneListingFail(e));
  }
}

function* getListingNotesSaga({ payload }: any): any {
  try {
    const response = yield call(ListingApi.getListingNotes, `${payload}?notable_type=listing_note`);
    yield put(getListingNotesSuccess(response.notes));
  } catch (e) {
    yield put(getListingNotesFail(e));
  }
}

function* addListingNoteSaga({ payload }: any): any {
  try {
    const response = yield call(ListingApi.addListingNote, payload);
    yield put(addListingNoteSuccess(response));
  } catch (e) {
    yield put(addListingNoteFail(e));
  }
}

function* deleteListingNoteSaga({ payload }: any): any {
  try {
    yield call(ListingApi.deleteListingNote, payload);
    yield put(deleteListingNoteSuccess(payload));
  } catch (e) {
    yield put(deleteListingNoteFail(e));
  }
}

function* updateListingNoteSaga({ payload }: any): any {
  try {
    const response = yield call(ListingApi.updateListingNote, payload);
    yield put(updateListingNoteSuccess(response));
  } catch (e) {
    yield put(updateListingNoteFail(e));
  }
}

function* updateListingPublishStateSaga({ payload }: any): any {
  try {
    const response = yield call(ListingApi.updateListingPublishState, payload);
    openNotification(
      "success",
      response.published_rdt ? "Listing has been published successfully." : "Listing has been unpublished successfully."
    );
    yield put(updateListingPublishStateSuccess(response));
  } catch (e) {
    openNotification("error", e.message);
    yield put(updateListingPublishStateFail(e));
  }
}

function* deleteListingSaga({ payload }: any) {
  try {
    yield call(ListingApi.deleteListing, payload);
    openNotification("success", "Listing has been deleted successfully.");
    yield deleteListingSuccess(payload);
    yield put(getLoggedInUser());
    yield delay(3000);
    yield put(push(routes.listings.path));
  } catch (e) {
    openNotification("error", e.message);
    yield put(deleteListingFail(e));
  }
}

function* deleteListingImageSaga({ payload }: any) {
  try {
    yield call(ListingApi.deleteListingImage, payload);
    yield put(deleteListingImageSuccess(payload));
  } catch (e) {
    yield put(deleteListingImageFail(e));
  }
}

function* uploadListingsCsv({ payload }: any): any {
  try {
    const response = yield call(ListingApi.uploadListingsCsv, payload);

    yield delay(1000);
    yield put(uploadCsvListingsSuccess(response));
    yield put(getListingsFromAlgolia({}));
  } catch (e) {
    yield put(uploadCsvListingsFail(e));
  }
}

function* watchAddListingSaga() {
  yield takeLatest(ADD_LISTING, addListingSaga);
}

function* watchGetOneListingSaga() {
  yield takeLatest(GET_ONE_LISTING, getOneListingSaga);
}

function* watchGetOneListingFromPublicIdSaga() {
  yield takeLatest(GET_ONE_LISTING_FROM_PUBLIC_ID, getOneListingFromPublicIdSaga);
}

function* watchGetListingsListSaga() {
  yield takeLatest(GET_LISTINGS, getListingsListSaga);
}

function* watchGetListingsFromAlgoliaSaga() {
  yield takeLatest(GET_LISTINGS_FORM_ALGOLIA, getListingsFromAlgoliaSaga);
}

function* watchAddListingNoteSaga() {
  yield takeLatest(ADD_LISTING_NOTE, addListingNoteSaga);
}

function* watchUpdateListingSaga() {
  yield takeLatest(UPDATE_LISTING, updateListingSaga);
}

function* watchGetListingNotesSaga() {
  yield takeLatest(GET_LISTING_NOTES, getListingNotesSaga);
}
function* watchUpdateListingNoteSaga() {
  yield takeLatest(UPDATE_LISTING_NOTE, updateListingNoteSaga);
}

function* watchUpdateListingPublishStateSaga() {
  yield takeLatest(UPDATE_LISTING_PUBLISH_STATE, updateListingPublishStateSaga);
}

function* watchDeleteListingNoteSaga() {
  yield takeLatest(DELETE_LISTING_NOTE, deleteListingNoteSaga);
}

function* watchDeleteListingSaga() {
  yield takeLatest(DELETE_LISTING, deleteListingSaga);
}

function* watchDeleteListingImageSaga() {
  yield takeLatest(DELETE_LISTING_IMAGE, deleteListingImageSaga);
}

function* watchUploadListingsCsvSaga() {
  yield takeLatest(UPLOAD_CSV_LISTINGS, uploadListingsCsv);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddListingSaga),
    fork(watchUpdateListingSaga),
    fork(watchGetOneListingSaga),
    fork(watchGetListingsListSaga),
    fork(watchGetListingsFromAlgoliaSaga),
    fork(watchAddListingNoteSaga),
    fork(watchGetListingNotesSaga),
    fork(watchUpdateListingNoteSaga),
    fork(watchUpdateListingPublishStateSaga),
    fork(watchDeleteListingNoteSaga),
    fork(watchDeleteListingSaga),
    fork(watchDeleteListingImageSaga),
    fork(watchUploadListingsCsvSaga),
    fork(watchGetOneListingFromPublicIdSaga),
  ]);
}
