import React, { memo } from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { filterOptionsSelector } from "../../../../store/listings/selectors";
import { filterOptionsSelector as leadsFilterOptions } from "../../../../store/leads/selectors";
import { userSelector } from "store/user/selectors";
import FilterBySectionElement from "./components/FilterBySectionElement";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../helpers/listingsCreateGetSearchQuery";
import MatchingFilterIcon from "../filter-modal/icons/MatchingFilterIcon";
import { useParams } from "react-router-dom";
import { convertObjectToArray } from "../helpers/filterFormHelper";
import { landlordListingsFilterOptionsSelector } from "store/landlords/selectors";
import { IObjectKeys } from "helpers/globalTypes";
import { isCLAWUser } from "helpers/userHelpers";

const FilteredBySection = ({ isMatchingListings }: { isMatchingListings?: boolean; }) => {
  const { pathname, search } = useLocation();
  const user = useSelector(userSelector);
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);
  const { lead_id } = useParams();
  const filterOptionsSelectorMap: IObjectKeys = {
    '/': filterOptionsSelector,
    '/leads': leadsFilterOptions,
    '/landlords': landlordListingsFilterOptionsSelector,
    '/listings': filterOptionsSelector,
  };
  const isExternalSourceUser = isCLAWUser(user);
  const selectorData: IObjectKeys = useSelector(filterOptionsSelectorMap[pathname] || filterOptionsSelector);

  const filterOptions = convertObjectToArray(
    selectorData,
    queryObject,
    isMatchingListings,
    isExternalSourceUser,
  );

  return (
    <>
      {filterOptions.length ? (
        <div className={`filtered-by-wrapper ${lead_id ? "filtered-by-wrapper-grid" : ""}`}>
          {isMatchingListings && <MatchingFilterIcon />}
          <div className={"filtered-by-wrapper-options"}>
            {filterOptions?.map((filter: any, index: number) => {
              return (
                <FilterBySectionElement
                  filter={filter}
                  key={index}
                  isMatchingListings={isMatchingListings}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(FilteredBySection);
