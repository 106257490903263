import React, { useEffect, useState } from "react";
import "./StepProgress.scss";
import { useLocation } from "react-router";
import { urlQueryToObject } from "../../../../../../Shared/filters/helpers/listingsCreateGetSearchQuery";
interface IStepProgress {
  stepNumber: number;
  name: string;
  totalSteps: number;
}
const StepProgress = ({ stepNumber, name, totalSteps }: IStepProgress) => {
  const stepPerPage = 100 / totalSteps;
  const [stepProgress, setStepProgress] = useState(stepPerPage);
  const { search } = useLocation();
  const _newURLSearchParams = new URLSearchParams(search);
  const queryObject = urlQueryToObject(_newURLSearchParams);

  useEffect(() => {
    setStepProgress(stepPerPage * stepNumber);
  }, [stepNumber]);
  return (
    <div className={"step-progress"}>
      <div className={"step-progress-bar"}>
        <div className={"step-progress-bar-progress"} style={{ width: `${stepProgress}%` }} />
      </div>
      <div className={"step-progress-text mb-4"}>
        <h3>{name}</h3>
      </div>
      {queryObject.is_manual && +queryObject.reqStep === 1 && (
        <span className={"step-progress-subtitle"}>
          Use the following questions as a call script or conversation guide when qualifying your leads.
        </span>
      )}
    </div>
  );
};

export default StepProgress;
