import React from "react";

export const LogoName = () => {
  return (
    <svg
      width="117"
      height="20"
      viewBox="0 0 117 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"rb-logo-name"}
    >
      <path
        d="M4.12616 8.77254H5.36268C6.57446 8.77254 7.46887 8.57057 8.04591 8.16664C8.62295 7.76272 8.91147 7.12797 8.91147 6.26241C8.91147 5.4051 8.61471 4.79508 8.02118 4.43237C7.4359 4.06966 6.525 3.8883 5.28849 3.8883H4.12616V8.77254ZM4.12616 11.8886V18.8254H0.292969V0.747559H5.56052C8.01706 0.747559 9.83474 1.19683 11.0135 2.09536C12.1924 2.98565 12.7818 4.34169 12.7818 6.16349C12.7818 7.22689 12.4891 8.17489 11.9038 9.00747C11.3186 9.83182 10.4901 10.4789 9.41844 10.9488C12.1388 15.0128 13.9111 17.6383 14.7355 18.8254H10.4818L6.16641 11.8886H4.12616Z"
        fill="black"
      />
      <path
        d="M22.4389 7.42474C21.6393 7.42474 21.0128 7.68028 20.5594 8.19137C20.106 8.69422 19.8464 9.4114 19.7804 10.3429H25.0727C25.0562 9.4114 24.813 8.69422 24.3432 8.19137C23.8733 7.68028 23.2386 7.42474 22.4389 7.42474ZM22.9706 19.0727C20.7449 19.0727 19.0056 18.4586 17.7526 17.2303C16.4995 16.002 15.873 14.2627 15.873 12.0122C15.873 9.6958 16.4501 7.90698 17.6042 6.64573C18.7665 5.37624 20.3698 4.7415 22.4142 4.7415C24.3679 4.7415 25.8888 5.29793 26.9769 6.41079C28.0651 7.52366 28.6091 9.06106 28.6091 11.023V12.853H19.6939C19.7351 13.9247 20.0525 14.7614 20.646 15.3632C21.2395 15.9649 22.0721 16.2658 23.1438 16.2658C23.9763 16.2658 24.7636 16.1793 25.5055 16.0061C26.2474 15.833 27.0223 15.5569 27.8301 15.1777V18.0959C27.1707 18.4256 26.4659 18.6688 25.7157 18.8254C24.9655 18.9903 24.0505 19.0727 22.9706 19.0727Z"
        fill="black"
      />
      <path
        d="M44.4365 18.8254H40.6652V10.751C40.6652 9.7535 40.4879 9.00747 40.1335 8.51287C39.779 8.01002 39.2143 7.75859 38.4394 7.75859C37.3843 7.75859 36.6218 8.11306 36.1519 8.822C35.682 9.52269 35.4471 10.6891 35.4471 12.3213V18.8254H31.6757V5.00117H34.5568L35.0637 6.76938H35.274C35.6944 6.10167 36.2714 5.59882 37.0051 5.26084C37.747 4.91461 38.5878 4.7415 39.5276 4.7415C41.135 4.7415 42.3551 5.1784 43.1876 6.0522C44.0202 6.91776 44.4365 8.17077 44.4365 9.81121V18.8254Z"
        fill="black"
      />
      <path
        d="M54.1432 16.068C54.8026 16.068 55.594 15.9237 56.5173 15.6352V18.4421C55.5775 18.8625 54.4234 19.0727 53.055 19.0727C51.5465 19.0727 50.446 18.6935 49.7535 17.9351C49.0693 17.1685 48.7272 16.0226 48.7272 14.4976V7.83279H46.9219V6.23768L48.9993 4.97644L50.0874 2.05826H52.4986V5.00117H56.3689V7.83279H52.4986V14.4976C52.4986 15.0334 52.647 15.4291 52.9437 15.6847C53.2488 15.9402 53.6486 16.068 54.1432 16.068Z"
        fill="black"
      />
      <path
        d="M59.6209 0.747559H65.2471C67.8108 0.747559 69.6697 1.11439 70.8237 1.84806C71.9861 2.57348 72.5672 3.73168 72.5672 5.32266C72.5672 6.40255 72.3117 7.28872 71.8006 7.98117C71.2977 8.67361 70.6259 9.08991 69.7851 9.23005V9.3537C70.9309 9.60924 71.7552 10.0874 72.2581 10.7881C72.7692 11.4887 73.0247 12.4203 73.0247 13.5826C73.0247 15.2313 72.4271 16.5172 71.2318 17.4405C70.0447 18.3638 68.429 18.8254 66.3847 18.8254H59.6209V0.747559ZM63.4541 7.90698H65.6798C66.7185 7.90698 67.4687 7.74623 67.9303 7.42474C68.4002 7.10324 68.6351 6.57154 68.6351 5.82963C68.6351 5.13718 68.3796 4.64258 67.8685 4.34581C67.3656 4.04081 66.566 3.8883 65.4696 3.8883H63.4541V7.90698ZM63.4541 10.9488V15.6599H65.9519C67.007 15.6599 67.786 15.458 68.2889 15.054C68.7917 14.6501 69.0432 14.0318 69.0432 13.1993C69.0432 11.699 67.9715 10.9488 65.8282 10.9488H63.4541Z"
        fill="black"
      />
      <path
        d="M85.1178 18.8254L84.3883 16.9459H84.2894C83.6546 17.7455 82.9993 18.3019 82.3233 18.6152C81.6556 18.9202 80.7818 19.0727 79.7019 19.0727C78.3747 19.0727 77.3278 18.6935 76.5612 17.9351C75.8028 17.1767 75.4236 16.0968 75.4236 14.6954C75.4236 13.2281 75.9347 12.1482 76.9568 11.4558C77.9873 10.7551 79.537 10.3676 81.6061 10.2934L84.005 10.2193V9.61337C84.005 8.21198 83.2878 7.51129 81.8534 7.51129C80.7488 7.51129 79.4505 7.84515 77.9584 8.51287L76.7095 5.96565C78.3005 5.13306 80.0646 4.71677 82.0018 4.71677C83.8566 4.71677 85.2786 5.1207 86.2678 5.92855C87.257 6.73641 87.7516 7.96468 87.7516 9.61337V18.8254H85.1178ZM84.005 12.4203L82.5459 12.4697C81.4495 12.5027 80.6334 12.7005 80.0976 13.0632C79.5618 13.426 79.2939 13.9783 79.2939 14.7202C79.2939 15.7836 79.9039 16.3153 81.1239 16.3153C81.9977 16.3153 82.6943 16.0638 83.2136 15.561C83.7412 15.0581 84.005 14.3904 84.005 13.5578V12.4203Z"
        fill="black"
      />
      <path
        d="M101.291 14.7202C101.291 16.138 100.797 17.2179 99.8076 17.9598C98.8267 18.7017 97.3552 19.0727 95.3933 19.0727C94.3876 19.0727 93.5303 19.0026 92.8213 18.8625C92.1124 18.7306 91.4488 18.5328 90.8305 18.269V15.1529C91.5312 15.4827 92.3185 15.7588 93.1923 15.9814C94.0743 16.204 94.8492 16.3153 95.5169 16.3153C96.8853 16.3153 97.5695 15.9196 97.5695 15.1282C97.5695 14.8315 97.4789 14.5924 97.2975 14.411C97.1161 14.2214 96.8029 14.0112 96.3578 13.7804C95.9126 13.5414 95.3191 13.2652 94.5772 12.952C93.5138 12.5068 92.7306 12.0946 92.2278 11.7154C91.7332 11.3362 91.3705 10.9035 91.1397 10.4171C90.9171 9.92249 90.8058 9.3166 90.8058 8.59942C90.8058 7.37115 91.2798 6.42316 92.2278 5.75544C93.184 5.07948 94.536 4.7415 96.2836 4.7415C97.9487 4.7415 99.5686 5.10421 101.143 5.82963L100.005 8.54996C99.313 8.2532 98.6659 8.01002 98.0641 7.82042C97.4624 7.63082 96.8482 7.53602 96.2217 7.53602C95.1089 7.53602 94.5524 7.83691 94.5524 8.43868C94.5524 8.77666 94.7297 9.0693 95.0842 9.3166C95.4469 9.5639 96.2341 9.93074 97.4459 10.4171C98.5258 10.854 99.3172 11.2621 99.82 11.6412C100.323 12.0204 100.694 12.4573 100.933 12.952C101.172 13.4466 101.291 14.036 101.291 14.7202Z"
        fill="black"
      />
      <path
        d="M109.959 7.42474C109.16 7.42474 108.533 7.68028 108.08 8.19137C107.627 8.69422 107.367 9.4114 107.301 10.3429H112.593C112.577 9.4114 112.334 8.69422 111.864 8.19137C111.394 7.68028 110.759 7.42474 109.959 7.42474ZM110.491 19.0727C108.265 19.0727 106.526 18.4586 105.273 17.2303C104.02 16.002 103.394 14.2627 103.394 12.0122C103.394 9.6958 103.971 7.90698 105.125 6.64573C106.287 5.37624 107.89 4.7415 109.935 4.7415C111.888 4.7415 113.409 5.29793 114.497 6.41079C115.586 7.52366 116.13 9.06106 116.13 11.023V12.853H107.214C107.256 13.9247 107.573 14.7614 108.166 15.3632C108.76 15.9649 109.593 16.2658 110.664 16.2658C111.497 16.2658 112.284 16.1793 113.026 16.0061C113.768 15.833 114.543 15.5569 115.351 15.1777V18.0959C114.691 18.4256 113.986 18.6688 113.236 18.8254C112.486 18.9903 111.571 19.0727 110.491 19.0727Z"
        fill="black"
      />
    </svg>
  );
};
