import { TRANSUNION_AUTHENTICATION_PHONE } from "helpers/constants";
import React from "react";

const ManualVerification = ({ screening_request_renter_id }: { screening_request_renter_id: Number }) => {
  return (
    <>
      <div className={"listing-add-edit-modal--header"}>
        <h3>Next Step</h3>
      </div>
      <div style={{ backgroundColor: "#F4EFFA", padding: "10px" }}>
        <p>
          Additional verification is needed to process your screening. To continue, please call TransUnion and provide
          your email address {screening_request_renter_id && `and your Screening ID: ${screening_request_renter_id}`}
        </p>
        <p style={{ fontWeight: "bold", textAlign: "center" }}>{TRANSUNION_AUTHENTICATION_PHONE}</p>
        <p style={{ fontSize: "12px", color: "#F5F5F5", textAlign: "center" }}>
          Hours of operation 6AM-5PM, M-F, Saturday 7:30AM-4PM, and Sunday 8:30AM-4PM PST
        </p>
      </div>
    </>
  );
};

export default ManualVerification;
