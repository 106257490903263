import React, { useEffect } from "react";
import Listing from "components/ListingView";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";
import routes from "../../routes/routesCode";
import { clearListingData, getOneListingFromPublicId } from "../../store/listings/action";
import "../../components/ListingView/style.scss";

const ListingView: React.FC<any> = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { lId } = useParams();
  useEffect(() => {
    if (!lId) {
      dispatch(push(routes.listings.path));
    } else {
      dispatch(getOneListingFromPublicId(lId));
    }
    return () => {
      dispatch(clearListingData());
    };
  }, [lId, dispatch]);

  return (
    <div className="listing-view-wrapper mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <Listing />
    </div>
  );
};

export default ListingView;
