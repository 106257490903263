import React from "react";

const EmailIcon = () =>{
    return(
        <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg" className={"communication-radio-btn-icon"}>
            <g clipPath="url(#clip0_1_1585)">
                <path d="M23.8002 9.58618C23.5719 9.36415 23.2005 9.36268 22.9704 9.58296C22.7404 9.80329 22.7388 10.1619 22.9671 10.384L22.9706 10.3875C23.0849 10.4987 23.2346 10.5541 23.3845 10.5541C23.534 10.5541 23.6838 10.4989 23.7987 10.389C24.0287 10.1686 24.0285 9.80827 23.8002 9.58618Z" fill="black"/>
                <path d="M30.856 16.3975L25.4061 11.1369C25.177 10.9157 24.8055 10.9157 24.5763 11.1369C24.3471 11.358 24.3471 11.7167 24.5763 11.9379L28.9027 16.1141H18.5856C17.7298 16.1141 17.0336 15.442 17.0336 14.6159V4.65712L21.2104 8.68892C21.4396 8.91004 21.8111 8.91004 22.0403 8.68892C22.2695 8.46773 22.2695 8.10909 22.0403 7.88785L16.7399 2.77156C16.5108 2.55043 16.1392 2.55043 15.9101 2.77156L6.55564 11.8014C6.3265 12.0226 6.3265 12.3812 6.55564 12.6024L20.6718 26.2284C20.7818 26.3346 20.9311 26.3943 21.0867 26.3943C21.2423 26.3943 21.3916 26.3346 21.5016 26.2284L30.8561 17.1986C30.9661 17.0923 31.028 16.9483 31.028 16.7981C31.0279 16.6479 30.9661 16.5038 30.856 16.3975ZM15.8601 4.42201V11.6355H8.3871L15.8601 4.42201ZM20.4999 24.4604L8.3871 12.7683H15.8601V14.6159C15.8601 16.0666 17.0828 17.2469 18.5858 17.2469H20.4999V24.4604ZM21.6734 24.4604V17.2469H29.1463L21.6734 24.4604Z" fill="black"/>
                <path d="M5.01249 14.3866H1.57213C1.24805 14.3866 0.985352 14.6402 0.985352 14.953C0.985352 15.2658 1.24805 15.5194 1.57213 15.5194H5.01249C5.33656 15.5194 5.59927 15.2658 5.59927 14.953C5.59927 14.6402 5.33656 14.3866 5.01249 14.3866Z" fill="black"/>
                <path d="M6.7616 14.3866H6.7528C6.42872 14.3866 6.16602 14.6402 6.16602 14.953C6.16602 15.2658 6.42872 15.5194 6.7528 15.5194H6.7616C7.08568 15.5194 7.34838 15.2658 7.34838 14.953C7.34838 14.6402 7.08568 14.3866 6.7616 14.3866Z" fill="black"/>
                <path d="M3.45098 8.43958H1.57311C1.24903 8.43958 0.986328 8.69321 0.986328 9.00598C0.986328 9.31875 1.24903 9.57239 1.57311 9.57239H3.45098C3.77506 9.57239 4.03776 9.31875 4.03776 9.00598C4.03776 8.69321 3.77506 8.43958 3.45098 8.43958Z" fill="black"/>
                <path d="M7.58673 8.43958H5.36705C5.04298 8.43958 4.78027 8.69321 4.78027 9.00598C4.78027 9.31875 5.04298 9.57239 5.36705 9.57239H7.58673C7.91081 9.57239 8.17351 9.31875 8.17351 9.00598C8.17351 8.69321 7.91081 8.43958 7.58673 8.43958Z" fill="black"/>
                <path d="M8.86488 17.5018H5.57897C5.25489 17.5018 4.99219 17.7555 4.99219 18.0682C4.99219 18.381 5.25489 18.6346 5.57897 18.6346H8.86488C9.18896 18.6346 9.45166 18.381 9.45166 18.0682C9.45166 17.7555 9.1889 17.5018 8.86488 17.5018Z" fill="black"/>
                <path d="M11.4306 21.1267H3.15709C2.83301 21.1267 2.57031 21.3803 2.57031 21.6931C2.57031 22.0059 2.83301 22.2595 3.15709 22.2595H11.4306C11.7547 22.2595 12.0174 22.0059 12.0174 21.6931C12.0174 21.3803 11.7547 21.1267 11.4306 21.1267Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_1_1585">
                    <rect width="30.0432" height="29" fill="white" transform="translate(0.985352)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default EmailIcon