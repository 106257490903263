import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/user/selectors";
import { formatDate } from "../../helpers/dateHelper";
import BaseButton from "components/Shared/BaseButton";

const FollowUpDetails = ({ followUp }: { followUp: any }) => {
  const user = useSelector(userSelector);
  const history = useHistory();

  return (
    <>
      <span className={"follow-ups-details"}>
        {followUp.listingOfNote ? (
          <>
            <span className={"follow-ups-span"}>Listing:</span>
            <BaseButton
              variant="link"
              href={`../../listings/${followUp?.listingOfNote?.public_id}?lId=${user?.agent_id}`}
            >
              <span className={"follow-ups-links mr-20 ml-5"}>{followUp?.listingOfNote?.addr}</span>
            </BaseButton>
          </>
        ) : (
          ""
        )}
        {followUp?.created_at && (
          <span className={"mr-20"}>
            {" "}
            <span className={"follow-ups-span follow-ups-span-created-at"}> Created:</span>
            <span className={"follow-ups-ref-txt ml-5"}>{formatDate(followUp?.created_at, "MMMM Do , YYYY")}</span>
          </span>
        )}
        {user?.name && (
          <span className={"mr-20"}>
            <span className={"follow-ups-span"}>By:</span>
            <span className={"follow-ups-ref-txt ml-5"}>{user?.name}</span>
          </span>
        )}
        {followUp?.landlord_id?.name && (
          <div className={"follow-up-landlord-info mobile"}>
            <span
              className={"mr-20 "}
              onClick={() => {
                history.push(`/landlords/${followUp?.landlord_id?._id}`);
              }}
            >
              <span>Landlord : </span>
              <span className={"follow-ups-span"}>{followUp?.landlord_id?.name}</span>
            </span>
          </div>
        )}
      </span>
    </>
  );
};

export default FollowUpDetails;
