import React from "react";
import { LuBedDouble } from "react-icons/lu";

export const BedroomIconLead = ({ className }: { className?: string | undefined; }) => {
  return (
    <LuBedDouble className={`${className}`} />
  );
};

export default BedroomIconLead;
