import React, { useRef, useState } from "react";
import ListingSortOverlay from "../../../Shared/filters/listing-sort-overlay/ListingSortOverlay";
import { Button, Dropdown } from "antd";
import { SortIcon } from "../../../icons";
import { IObjectKeys } from "../../../../helpers/globalTypes";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
interface IListingSortDropdown {
  filterOptions: IObjectKeys;
  viewType?: string;
  pagination: IObjectKeys;
  handleSubmit?: (state: string) => void;
}

const ListingSortDropdown = ({ filterOptions, viewType, pagination, handleSubmit }: IListingSortDropdown) => {
  const [isSortDropDown, setIsSortDropDown] = useState<boolean>(false);
  const sortOverlayRef = useRef(null);
  const sortButtonRef = useRef(null);
  useOnClickOutside(sortOverlayRef, () => setIsSortDropDown(false), sortButtonRef);
  return (
    <div className={"listings-sort-dropdown-wrapper"}>
      <Dropdown
        visible={isSortDropDown}
        overlayClassName={"landlords-sort-dropdown-overlay"}
        trigger={["click"]}
        getPopupContainer={() => {
          return document.getElementById(`filter-icon-btn-sort`) as HTMLElement;
        }}
        overlay={
          <ListingSortOverlay
            ref={sortOverlayRef}
            filterOptions={filterOptions}
            viewType={viewType}
            pagination={pagination}
            handleSubmit={handleSubmit}
            setIsSortDropDown={setIsSortDropDown}
          />
        }
        placement="bottomRight"
        data-test-id="listing-sort-dropdown"
      >
        <Button
          onClick={() => setIsSortDropDown((prev) => !prev)}
          data-test-id="listing-sort-btn"
          className="filter-icon-btn listing-sort-button"
          id="filter-icon-btn-sort"
          ref={sortButtonRef}
        >
          <SortIcon />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ListingSortDropdown;
