import React from "react";
import "./EmptyPageAddOrImportSelect.scss";
import { push } from "connected-react-router";
import routes from "../../../../routes/routesCode";
import { useDispatch } from "react-redux";
import { AnalyticsService } from "helpers/analyticsService";
import BaseButton from "components/Shared/BaseButton";

const EmptyPageAddOrImportSelect = ({ setIsSingle, text }: { setIsSingle: any; text: string }) => {
  const dispatch = useDispatch();

  return (
    <div className={"empty-state-add-landlord-select mt-20"}>
      <div className={"empty-state-wrapper-selectPart"}>
        <BaseButton
          variant="primary"
          onClick={() => {
            if (text === "listing") {
              dispatch(push(routes.manageListing.path));
            }
            setIsSingle(true);
            AnalyticsService.reportEvent(`add_single_${text}`, {
              category: "onboard_wizard",
            });
          }}
        >
          <>Add a {text}</>
        </BaseButton>
      </div>
    </div>
  );
};

export default EmptyPageAddOrImportSelect;
