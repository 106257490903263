import React from "react";

const LeadIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4998 8.44873C2.99286 8.44873 0.510742 10.5808 0.510742 14.4524C0.510742 14.7548 0.75581 14.9998 1.05818 14.9998H13.9414C14.2438 14.9998 14.4888 14.7548 14.4888 14.4524C14.4889 10.581 12.0067 8.44873 7.4998 8.44873ZM1.62555 13.905C1.84088 11.0102 3.8144 9.54364 7.4998 9.54364C11.1852 9.54364 13.1588 11.0102 13.3743 13.905H1.62555Z"
        fill="black"
      />
      <path
        d="M7.49954 0C5.42928 0 3.86816 1.59252 3.86816 3.70421C3.86816 5.87774 5.49719 7.64581 7.49954 7.64581C9.50189 7.64581 11.1309 5.87774 11.1309 3.70438C11.1309 1.59252 9.5698 0 7.49954 0ZM7.49954 6.5511C6.10082 6.5511 4.96305 5.27408 4.96305 3.70438C4.96305 2.19234 6.02983 1.09488 7.49954 1.09488C8.94572 1.09488 10.036 2.2166 10.036 3.70438C10.036 5.27408 8.89826 6.5511 7.49954 6.5511Z"
        fill="black"
      />
    </svg>
  );
};

export default LeadIcon;
