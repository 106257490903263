import React, { useMemo, useState } from "react";
import "./styles.scss";
import { Layout } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { push } from "connected-react-router";
import PageFooter from "../../components/Footer";
import BaseButton from "../Shared/BaseButton";
import Page404Loading from "../Shared/Page404Loading";
import { LogoName, RBLogo } from "../icons";

const { Header, Content, Footer } = Layout;

export const PageNotFound = () => {
  const [isFooterCollapsed, setIsFooterCollapsed] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const collapseFooter = () => {
    setIsFooterCollapsed((prevState) => !prevState);
  };

  const notFoundPageTxt = useMemo(() => {
    if (history.location.pathname === "/permission_denied") {
      return {
        title: "Permission denied !",
        descriptionBeforeTxt: "",
        descriptionAfterTxt: "",
        descriptionLinkTxt: "Go to homepage",
        descriptionLink: "/listings",
      };
    } else {
      return {
        title:
          "404 - It looks like you’ve reached some uncharted\n" + "territory.",
        descriptionBeforeTxt: "",
        descriptionAfterTxt: "to familiar ground!",
        descriptionLinkTxt: "Head back",
        descriptionLink: "/listings",
      };
    }
  }, [history.location.pathname]);

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 page-not-found-layout">


      <div>
        <div className={"page-header-wrapper flex justify-center text-center"}>
          <div
            className={"logo-wrapper"}
            onClick={() => {
              dispatch(push("/"));
            }}
          >
            <RBLogo />
            <LogoName />
          </div>
        </div>
        <div className={"site-layout-main page-not-found"}>
          <div className={"content-wrapper"}>
            <div className={`py-1 text-center`}>
              <h3 className={"text-3xl not-found-title py-10"}>{notFoundPageTxt.title}</h3>
              <Page404Loading />
              <p className={"text-base not-found-description pt-10"}>
                {notFoundPageTxt.descriptionBeforeTxt}
              </p>
              <div className="w-full flex justify-center">
                <BaseButton
                  variant="primary"
                  className="w-1/2"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <>
                    {notFoundPageTxt.descriptionLinkTxt} {notFoundPageTxt.descriptionAfterTxt}
                  </>
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
