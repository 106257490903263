import React from "react";
import { renderItemValue } from "./FieldItems";

interface IPropsAreaOutput {
  field: { format: any; key: string; inputFormat: any; prefix?: string; suffix?: string; label?: string };
  value: any;
  label?: string;
}

const AreaOutput = ({ field, value, label }: IPropsAreaOutput) => {
  return (
    <>
      <div>
        <label className="block text-base font-medium ">{label || field.label}</label>
        <div className="mt-2 ">{renderItemValue(value)}</div>
      </div>
    </>
  );
};

export default AreaOutput;
