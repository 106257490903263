import React, { ReactElement } from "react";
import { IObjectKeys } from "../../../helpers/globalTypes";
import { leadValueTypes } from "./mockLead";

interface IPropsLeadDescriptionItem {
  header: string;
  description: string | Array<string> | Date;
  Icon?: React.FC;
  less30Days?: number;
  fromComponent?: string;
}
const LeadDescriptionItem = ({ header, description, Icon, less30Days, fromComponent }: IPropsLeadDescriptionItem) => {
  let descriptionText = description;
  let descriptionTextsArray: ReactElement[] = [];

  let className = fromComponent === "LeadDescription" ? "flex-element" : "";
  switch (header) {
    case "Must haves":
    case "Deal Breakers":
      const source: IObjectKeys = leadValueTypes[header];
      descriptionTextsArray = Object.keys(description).map((d, i) => (
        <span key={i}>
          {source[d]}
          {i !== Object.keys(description).length - 1 ? "," : ""}
          &nbsp;
        </span>
      ));
      descriptionText = "";
      break;
    case "Urgent Move":
      descriptionText = "";
      descriptionTextsArray = [];
      break;
  }

  return (
    <div
      className={`flex items-center gap-2 mb-4 lead-description-item-wrapper${
        less30Days && less30Days < 30 ? " less30Days-wrapper" : ""
      } ${className}`}
      data-test-id={`lead-description-${header?.split(" ")?.[0]}`}
    >
      {Icon && <span className="lead-description-item-icon">{<Icon />}</span>}
      <span className={`lead-description-item-header ${less30Days && less30Days < 30 ? "less30Days" : ""}`}>
        <span>{header}: </span>
        {less30Days ? (
          less30Days < 30 ? (
            <p className="lead-description-move-in-date-expire">LESS than 30 days</p>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </span>
      <span
        className={"lead-description-item-description"}
        data-test-id={`lead-description-span-${header?.split(" ")?.[0]}`}
      >
        {descriptionText ? (
          <> &nbsp;{descriptionText} </>
        ) : descriptionTextsArray.length ? (
          <p className={"lead-descriptions-group-wrapper"}>&nbsp;{descriptionTextsArray}</p>
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default LeadDescriptionItem;
