import React from "react";
import { HiOutlinePhoneArrowUpRight } from "react-icons/hi2";
import { MagnifyingGlassCircleIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { LuUsers } from "react-icons/lu";
import { HiOutlineUser, HiOutlineHome } from "react-icons/hi2";
import { KeyIcon, UsersIcon } from "@heroicons/react/20/solid";

export interface RoutesArrInterface {
  [key: string]: RouteInterface;
}

export interface Submenu {
  path: string;
  title?: string;
  exact?: boolean;
  persona?: string;
  public?: boolean;
}

export interface RouteInterface {
  path: string;
  title?: string;
  exact?: boolean;
  icon?: React.ReactNode;
  persona?: string;
  public?: boolean;
  hide?: boolean;
  children?: Submenu[];
}

export const PERSONAS = {
  AGENT: "agent",
  RENTER: "renter",
};

const ROUTE_ENUM = {
  RENTER_PORTAL: "/renter-portal",
};

const routes: RoutesArrInterface = {
  // -------- Authorized routes start -------- //
  home: {
    path: "/",
    title: "Listings",
    icon: <HiOutlineHome />,
    exact: true,
    persona: PERSONAS.AGENT,
  },
  listings: {
    path: "/listings",
    title: "Listings",
    exact: false,
  },
  shareListing: {
    path: "/share-listing/:shareListingId",
    title: "Listing Public View",
    exact: true,
  },
  leads: {
    path: "/leads",
    title: "Leads",
    icon: <HiOutlineUser />,
    exact: true,
    persona: PERSONAS.AGENT,
  },
  agentAllScreening: {
    path: "/tenant-screenings",
    title: "Tenant Screenings",
    exact: true,
    persona: PERSONAS.AGENT,
    icon: <MagnifyingGlassCircleIcon />,
  },
  leadView: {
    path: "/leads/:lead_id",
    title: "Lead View",
    exact: true,
  },
  screeningReports: {
    path: "/screening-reports/:requirmentId/:srrId",
    title: "Screening Report",
    exact: true,
  },
  listing: {
    path: "/listings/:lId",
    title: "Listing",
    exact: false,
  },
  listingImages: {
    path: "/allimages/:lId",
    title: "Images",
    exact: true,
  },
  manageListing: {
    path: "/manageListing",
    title: "Listing",
    exact: false,
  },
  landlords: {
    path: "/landlords",
    title: "Landlords",
    icon: <KeyIcon className="h-25 w-25" aria-hidden="true" />,
    exact: true,
    persona: PERSONAS.AGENT,
  },
  landlordView: {
    path: "/landlords/:landlordId",
    title: "Landlord Page",
    exact: true,
  },
  followUps: {
    path: "/followUps",
    title: "Follow-Ups",
    icon: <HiOutlinePhoneArrowUpRight />,
    exact: true,
    persona: PERSONAS.AGENT,
  },
  teams: {
    path: "/teamsMembers",
    title: "My Team",
    icon: <LuUsers />,
    exact: true,
    persona: PERSONAS.AGENT,
  },
  createTeam: {
    path: "/createTeam",
    title: "Create team",
    exact: true,
  },

  public: {
    path: "/public/listings/:lId",
    title: "Listing Public View",
    exact: true,
  },
  settings: {
    path: "/settings",
    title: "Settings",
    exact: true,
  },
  privacyPolicyAuth: {
    path: "/privacy_policy",
    title: "Privacy Policy",
    exact: true,
  },
  termsAndConditionsAuth: {
    path: "/terms_and_conditions",
    title: "Terms And Conditions",
    exact: true,
  },
  requirements: {
    path: "/requirements/:agent_id",
    title: "Requirements",
    exact: true,
  },
  requirementCreateUpdate: {
    path: "/requirement/:action(create|edit)/:agent_id",
    title: "Requirement",
    exact: true,
  },
  requirementSuccess: {
    path: "/requirements/success/:agent_id",
    title: "Requirements",
    exact: true,
  },

  // -------- Authorized routes ends -------- //

  // -------- Guest routes start -------- //

  clawCognitoLogout: {
    path: "/claw/sign-in",
    exact: true,
    public: true,
  },
  clawCognitoSAMLAuth: {
    path: "/claw/saml-auth",
    exact: true,
    public: true,
  },
  clawcallback: {
    path: "/callbacks/claw",
    exact: true,
    public: true,
  },
  signIn: {
    path: "/sign-in",
    exact: true,
    public: true,
  },
  signUp: {
    path: "/sign-up",
    exact: true,
    public: true,
  },
  forceResetPassword: {
    path: "/forceResetPassword",
    title: "Confirm Password",
    exact: true,
  },
  resetPassword: {
    path: "/resetPassword",
    title: "Reset Password",
    exact: true,
  },
  changePassword: {
    path: "/reset-password",
    title: "Reset Password",
    exact: true,
  },
  privacyPolicy: {
    path: "/policy",
    title: "Privacy Policy",
    exact: true,
  },
  google: {
    path: "/google",
    title: "Google",
    exact: true,
  },
  leadRequirements: {
    path: "/lead-flow",
    title: "Lead Flow",
    exact: true,
  },
  verifyLead: {
    path: "/verifyLead",
    title: "Verify Lead",
    exact: true,
  },
  leadAccount: {
    path: "/leadAccount",
    title: "Lead Account",
    exact: true,
  },
  leadsSingleRequirement: {
    path: "/lead-flow/requirement/:lead_id",
    title: "Lead Flow",
    exact: true,
  },
  demo: {
    path: "/demo",
    exact: true,
    public: true,
  },
  demoListings: {
    path: "/demo/listings",
    exact: true,
    public: true,
  },
  demoLandlords: {
    path: "/demo/landlords",
    exact: true,
    public: true,
  },
  demoTeam: {
    path: "/demo/teamsMembers",
    exact: true,
    public: true,
  },
  demoTeamDashboard: {
    path: "/demo/teamDashboard",
    exact: true,
    public: true,
  },
  demoFollowUps: {
    path: "/demo/followUps",
    exact: true,
    public: true,
  },
  demoSignleLead: {
    path: "/demo/leads/:lead_id",
    title: "Lead View",
    exact: true,
  },
  demoSingleListing: {
    path: "/demo/listings/:lId",
    title: "Listing",
    exact: false,
  },
  demoSingleLandlord: {
    path: "/demo/landlords/:landlordId",
    title: "Landlord Page",
    exact: true,
  },
  demoPrivacyPolicy: {
    path: "/demo/policy",
    title: "Privacy Policy",
    exact: true,
  },
  demoTermsAndConditions: {
    path: "/demo/terms",
    title: "Terms And Conditions",
    exact: true,
  },
  renterPortalDashboard: {
    path: ROUTE_ENUM.RENTER_PORTAL,
    title: "Dashboard",
    exact: true,
    persona: PERSONAS.RENTER,
    icon: <HiOutlineHome aria-hidden="true" />,
  },
  renterPortalScreeningReports: {
    path: `${ROUTE_ENUM.RENTER_PORTAL}/screening-reports/:requirmentId/:srrId`,
    title: "Screening Report",
    exact: true,
    hide: true,
    persona: PERSONAS.RENTER,
    icon: <KeyIcon className="h-25 w-25" aria-hidden="true" />,
  },
  renterPortalChangePassword: {
    path: `${ROUTE_ENUM.RENTER_PORTAL}/reset-password`,
    title: "Reset Password",
    exact: true,
    hide: true,
    persona: PERSONAS.RENTER,
    icon: <KeyIcon className="h-25 w-25" aria-hidden="true" />,
  },
  renterPortalPassport: {
    path: `${ROUTE_ENUM.RENTER_PORTAL}/passport`,
    title: " Rental Passport",
    exact: true,
    persona: PERSONAS.RENTER,
    icon: <KeyIcon className="h-25 w-25" aria-hidden="true" />,
  },
  renterPortalSearch: {
    path: `${ROUTE_ENUM.RENTER_PORTAL}/search`,
    title: "Find a Rental",
    exact: true,
    persona: PERSONAS.RENTER,
    icon: <MagnifyingGlassCircleIcon className="h-30 w-30" aria-hidden="true" />,
  },
  // renterPortalApplications: {
  //   path: `${ROUTE_ENUM.RENTER_PORTAL}/applications`,
  //   title: "Applications",
  //   exact: true,
  //   persona: PERSONAS.RENTER,
  //   icon: <DocumentCheckIcon className="h-25 w-25" aria-hidden="true" />,
  // },
  // renterPortalHomes: {
  //   path: `${ROUTE_ENUM.RENTER_PORTAL}/homes`,
  //   title: "Current Home",
  //   exact: true,
  //   persona: PERSONAS.RENTER,
  //   icon: <HomeModernIcon className="h-25 w-25" aria-hidden="true" />,
  // },
  renterPortalAgents: {
    path: `${ROUTE_ENUM.RENTER_PORTAL}/agents`,
    title: "Connected Agents",
    exact: true,
    persona: PERSONAS.RENTER,
    icon: <UsersIcon className="h-25 w-25" aria-hidden="true" />,
  },
  renterPortalServices: {
    path: `${ROUTE_ENUM.RENTER_PORTAL}/services`,
    title: "Find Services",
    exact: true,
    persona: PERSONAS.RENTER,
    icon: <WrenchScrewdriverIcon className="h-20 w-20" aria-hidden="true" />,
  },
  renterPortalSettings: {
    path: `${ROUTE_ENUM.RENTER_PORTAL}/settings`,
    title: "Account Settings",
    exact: true,
  },
};

export default routes;

export const authorizedRoutes = [
  "/listings",
  "/",
  "/allimages",
  "/manageListing",
  "/landlords",
  "/followUps",
  "/teamsMembers",
  "/teamDashboard",
  "/createTeam",
  "/settings",
  "/privacy_policy",
  "/terms_and_conditions",
  "/shareable_terms_and_conditions",
];
