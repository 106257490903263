import React from "react";
import ListingGridItem from "./ListingGridItem";

const ListingGrid = ({ listings }: { listings: any }) => {
  return listings?.length ? (
    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
      {listings?.map((listing: any) => {
        return <ListingGridItem listing={listing} key={`${listing._id}`} />;
      })}
    </div>
  ) : null;
};

export default ListingGrid;
