import React from "react";
import { getBedroomsOrBathroomsRadioBtnGroup } from "../../../../../../../../helpers/globalFunctions";
import { REQUIRED_WITH_MESSAGE } from "../../../../../../../../helpers/validations";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";
import FormStepsTitle from "../../form-steps-title/FormStepsTitle";
import "./BedroomsBathrooms.scss";

const BedroomsBathrooms = () => {
  const requirementEnums = useSelector(requirementEnumsSelector);
  const beds = requirementEnums?.find((item: EnumInterface) => item?.key === "bedroom_min");
  const baths = requirementEnums?.find((item: EnumInterface) => item?.key === "bathroom_min");

  // Transform the enum because they keys are wrong in the DB...
  const bedrooms = { ...beds, key: "bedrooms_min", name: "bedrooms_min" };
  const bathrooms = { ...baths, key: "bathrooms_min", name: "bathrooms_min" };

  return (
    <div className={"req-number-of-people-section"}>
      <FormStepsTitle text={"How many bedrooms do you need?"} />
      {getBedroomsOrBathroomsRadioBtnGroup(
        bedrooms.name,
        "",
        !bedrooms.allow_null ? [REQUIRED_WITH_MESSAGE("This Field ")] : [{ required: false }],
        "purple-radiobutton-group bedrooms-radio-buttons",
        "parent-labels-to-center",
        "bedrooms_min"
      )}
      <FormStepsTitle text={"How many bathrooms would you like?"} />
      {getBedroomsOrBathroomsRadioBtnGroup(
        bathrooms.name,
        "",
        !bathrooms.allow_null ? [REQUIRED_WITH_MESSAGE("This Field ")] : [{ required: false }],
        "purple-radiobutton-group_bathrooms bathroom-radio-buttons",
        "parent-labels-to-center",
        "bathrooms_min"
      )}
    </div>
  );
};

export default BedroomsBathrooms;
