import React from 'react';

export const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 39 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={"closest"}
    >
      <path
        d="M37.5643 32.7903L28.1611 23.8791C30.5788 21.2603 31.9035 17.9652 31.9035 14.535C31.9035 6.52052 24.7474 0 15.9518 0C7.1561 0 0 6.52052 0 14.535C0 22.5495 7.1561 29.07 15.9518 29.07C19.2538 29.07 22.4004 28.1625 25.0907 26.4398L34.5654 35.4186C34.9614 35.7934 35.494 36 36.0648 36C36.6051 36 37.1176 35.8123 37.5067 35.4711C38.3335 34.7462 38.3598 33.5442 37.5643 32.7903ZM15.9518 3.79174C22.4531 3.79174 27.7422 8.61103 27.7422 14.535C27.7422 20.4589 22.4531 25.2782 15.9518 25.2782C9.45037 25.2782 4.16133 20.4589 4.16133 14.535C4.16133 8.61103 9.45037 3.79174 15.9518 3.79174Z"
        fill="#B7B7B7"
      />
    </svg>
  );
};
