export const GET_PAYMENT_LINK = "GET_PAYMENT_LINK"
export const GET_PAYMENT_LINK_SUCCESS = "GET_PAYMENT_LINK_SUCCESS"
export const GET_PAYMENT_LINK_FAIL = "GET_PAYMENT_LINK_FAIL"

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION"
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS"
export const GET_SUBSCRIPTION_FAIL = "GET_SUBSCRIPTION_FAIL"

export const GET_UPCOMING_INVOICES = "GET_UPCOMING_INVOICES"
export const GET_UPCOMING_INVOICES_SUCCESS = "GET_UPCOMING_INVOICES_SUCCESS"
export const GET_UPCOMING_INVOICES_FAIL = "GET_UPCOMING_INVOICES_FAIL"

export const GET_INVOICES = "GET_INVOICES"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL"

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS"
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL"

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION"
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS"
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL"

export const SET_UPDATED_SUBSCRIPTION_TO_BLANK = "SET_UPDATED_SUBSCRIPTION_TO_BLANK"

export const GET_STRIPE_PORTAL_URL = "GET_STRIPE_PORTAL_URL";
export const GET_STRIPE_PORTAL_URL_SUCCESS = "GET_STRIPE_PORTAL_URL_SUCCESS";

export const GET_SCREENING_PAYMENT_LINK = "GET_SCREENING_PAYMENT_LINK";
export const GET_SCREENING_PAYMENT_LINK_SUCCESS = "GET_SCREENING_PAYMENT_LINK_SUCCESS";
export const GET_SCREENING_PAYMENT_LINK_FAIL = "GET_SCREENING_PAYMENT_LINK_FAIL";
export const CLEAR_SCREENING_PAYMENT_LINK = "CLEAR_SCREENING_PAYMENT_LINK";
