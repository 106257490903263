import React from "react";
import { Modal, Typography } from "antd";
import { useSelector } from "react-redux";

import { followUpsLoadingSelector } from "../../store/followUps/selectors";

const DismissFollowModal = ({
  visible,
  onClose,
  onSubmit,
  classname,
}: {
  classname?: string;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const followUpsLoading = useSelector(followUpsLoadingSelector);

  return (
    <Modal
    className={"dismiss-followUp-modal"}
      cancelButtonProps={{
        className: "rb-secondary-btn sm dismiss-followUp-cancel-btn",
      }}
      okButtonProps={{
        className: "rb-primary-btn sm dismiss-followUp-ok-btn",
      }}
      visible={visible}
      wrapClassName={`base-modal ${classname ?? ""}`}
      title={"Dismiss Follow up"}
      onOk={() => onSubmit()}
      confirmLoading={followUpsLoading}
      onCancel={() => {
        onClose();
      }}
    >
      <Typography.Text>
        Are you sure you want to dismiss this reminder? This will remove the
        follow up but will keep the note in place.
      </Typography.Text>
    </Modal>
  );
};

export default DismissFollowModal;
