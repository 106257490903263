import * as types from "./types";
import { AuthError } from "../../types/user";
import { IObjectKeys } from "../../helpers/globalTypes";
import {
  IFilterOptionsOptionalKeys,
  IGetListingsQuery,
  Listing,
} from "../../components/Listings/helpers/mockListings";
import { IPaginationValues } from "../../components/Listings/components/listings-wrapper";

export const setCurrentLandlord = (payload: IObjectKeys) => ({
  type: types.SET_CURRENT_LANDLORD,
  payload,
});

export const resetCurrentLandlord = () => ({
  type: types.RESET_CURRENT_LANDLORD,
});
export const addListingForLandlord = () => ({
  type: types.ADD_LISTING_FOR_LANDLORD,
});

export const createLandlord = (payload: IObjectKeys) => ({
  type: types.CREATE_LANDLORD,
  payload,
});
export const createLandlordSuccess = (payload: IObjectKeys) => ({
  type: types.CREATE_LANDLORD_SUCCESS,
  payload,
});
export const createLandlordFail = (payload: AuthError) => ({
  type: types.CREATE_LANDLORD_FAIL,
  payload,
});

export const editLandlord = (payload: IObjectKeys) => ({
  type: types.EDIT_LANDLORD,
  payload,
});
export const editLandlordSuccess = (payload: IObjectKeys) => ({
  type: types.EDIT_LANDLORD_SUCCESS,
  payload,
});
export const editLandlordFail = (payload: AuthError) => ({
  type: types.EDIT_LANDLORD_FAIL,
  payload,
});

export const getLandlordsList = () => ({
  type: types.GET_LANDLORDS_LIST,
});
export const getLandlordsListSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LANDLORDS_LIST_SUCCESS,
  payload,
});
export const getLandlordsListFail = (payload: AuthError) => ({
  type: types.GET_LANDLORDS_LIST_FAIL,
  payload,
});

/**
 * for landlords page list
 */

export const getLandlordsListLP = (payload: IObjectKeys) => ({
  type: types.GET_LANDLORDS_LIST_LANDLORD_PAGE,
  payload,
});

export const getLandlordsListLPSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LANDLORDS_LIST_LANDLORD_PAGE_SUCCESS,
  payload,
});

export const getLandlordsListLPFail = (payload: IObjectKeys) => ({
  type: types.GET_LANDLORDS_LIST_LANDLORD_PAGE_FAIL,
  payload,
});

export const setSingleLandlordData = (payload: IObjectKeys) => ({
  type: types.SET_SINGLE_LANDLORD_DATA,
  payload,
});
export const resetSingleLandlordData = () => ({
  type: types.RESET_SINGLE_LANDLORD_DATA,
});

export function getLandlord(params: object) {
  return {
    type: types.GET_LANDLORD,
    payload: params,
  };
}

export function getLandlordSuccess(data: object) {
  return { type: types.GET_LANDLORD_SUCCESS, payload: data };
}

export function getLandlordFail(error: object) {
  return { type: types.GET_LANDLORD_FAIL, payload: error };
}

export function getListingsOfLandlord(params: object) {
  return {
    type: types.GET_LISTINGS_OF_LANDLORD,
    payload: params,
  };
}

export function getListingsOfLandlordSuccess(data: object) {
  return { type: types.GET_LISTINGS_OF_LANDLORD_SUCCESS, payload: data };
}

export function getListingsOfLandlordFail(error: object) {
  return { type: types.GET_LISTINGS_OF_LANDLORD_FAIL, payload: error };
}

export const setLandlordsListingsLastQuery = (payload: string) => ({
  type: types.SET_LANDLORD_LISTINGS_LAST_QUERY,
  payload,
});

export const setLandlordListingsViewType = (payload: string) => ({
  type: types.SET_LANDLORD_LISTINGS_VIEW_TYPE,
  payload,
});
export const setLandlordListingsFilterOptions = (
  payload: IFilterOptionsOptionalKeys,
) => ({ type: types.SET_LANDLORD_LISTINGS_FILTER_OPTIONS, payload });
export const setLandlordListingsPaginationValues = (
  payload: IPaginationValues,
) => ({ type: types.SET_LANDLORD_LISTINGS_PAGINATION_VALUES, payload });
export const setLandlordListingsLoadingToTrue = () => ({
  type: types.SET_LANDLORD_LISTINGS_LOADING_TO_TRUE,
});

export const addLandlordNote = (payload: IObjectKeys) => ({
  type: types.ADD_LANDLORD_NOTE,
  payload,
});

export const addLandlordNoteSuccess = (payload: IObjectKeys) => ({
  type: types.ADD_LANDLORD_NOTE_SUCCESS,
  payload,
});
export const addLandlordNoteFail = (payload: AuthError) => ({
  type: types.ADD_LANDLORD_NOTE_FAIL,
  payload,
});
export const clearLandlordNotes = () => ({
  type: types.CLEAR_LANDLORD_NOTES,
});

export const deleteLandlordNote = (payload: string) => ({
  type: types.DELETE_LANDLORD_NOTE,
  payload,
});
export const deleteLandlordNoteSuccess = (payload: IObjectKeys) => ({
  type: types.DELETE_LANDLORD_NOTE_SUCCESS,
  payload,
});
export const deleteLandlordNoteFail = (payload: AuthError) => ({
  type: types.DELETE_LANDLORD_NOTE_FAIL,
  payload,
});

export const updateLandlordNote = (payload: IObjectKeys) => ({
  type: types.UPDATE_LANDLORD_NOTE,
  payload,
});
export const updateLandlordNoteSuccess = (payload: IObjectKeys) => ({
  type: types.UPDATE_LANDLORD_NOTE_SUCCESS,
  payload,
});
export const updateLandlordNoteFail = (payload: AuthError) => ({
  type: types.UPDATE_LANDLORD_NOTE_FAIL,
  payload,
});

export const getLandlordNotes = (payload: string) => ({
  type: types.GET_LANDLORD_NOTES,
  payload,
});
export const getLandlordNotesSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LANDLORD_NOTES_SUCCESS,
  payload,
});
export const getLandlordNotesFail = (payload: AuthError) => ({
  type: types.GET_LANDLORD_NOTES_FAIL,
  payload,
});

export const addManualFollowUp = (payload: IObjectKeys) => ({
  type: types.ADD_MANUAL_FOLLOW_UP,
  payload,
});

export const getLandlordFollowUps = (payload: IObjectKeys) => ({
  type: types.GET_LANDLORD_FOLLOW_UPS,
  payload,
});
export const getLandlordFollowUpsSuccess = (payload: IObjectKeys) => ({
  type: types.GET_LANDLORD_FOLLOW_UPS_SUCCESS,
  payload,
});
export const getLandlordFollowUpsFail = (payload: AuthError) => ({
  type: types.GET_LANDLORD_FOLLOW_UPS_FAIL,
  payload,
});

export const sendLandlordInfoBySms = (payload: string) => ({
  type: types.SEND_LANDLORD_INFO_BY_SMS,
  payload,
});
export const sendLandlordInfoBySmsSuccess = (payload: IObjectKeys) => ({
  type: types.SEND_LANDLORD_INFO_BY_SMS_SUCCESS,
  payload,
});
export const sendLandlordInfoBySmsFail = (payload: AuthError) => ({
  type: types.SEND_LANDLORD_INFO_BY_SMS_FAIL,
  payload,
});

export const uploadXlsxLandlords = (payload: any) => ({
  type: types.UPLOAD_XLSX_LANDLORDS,
  payload,
});
export const uploadXlsxLandlordsSuccess = (payload: IObjectKeys) => ({
  type: types.UPLOAD_XLSX_LANDLORDS_SUCCESS,
  payload,
});
export const uploadXlsxLandlordsFail = (payload: AuthError) => ({
  type: types.UPLOAD_XLSX_LANDLORDS_FAIL,
  payload,
});

export const downloadLandlordXlsxTemplate = () => ({
  type: types.DOWNLOAD_XLSX_LANDLORDS_TEMPLATE,
});
export const downloadLandlordXlsxSuccess = (payload: IObjectKeys) => ({
  type: types.DOWNLOAD_XLSX_LANDLORDS_TEMPLATE_SUCCESS,
  payload,
});
export const downloadLandlordXlsxFail = (payload: AuthError) => ({
  type: types.DOWNLOAD_XLSX_LANDLORDS_TEMPLATE_FAIL,
  payload,
});

export const clearUploadXlsxLandlordsErrors = () => ({
  type: types.CLEAR_UPLOAD_XLSX_LANDLORDS_ERRORS,
});
export const clearParticularXlsxLandlordsError = (payload: IObjectKeys) => ({
  type: types.CLEAR_PARTICULAR_UPLOAD_XLSX_LANDLORDS_ERROR,
  payload,
});

export const setLandlordsQueryParams = (payload: IObjectKeys) => ({
  type: types.SET_LANDLORDS_QUERY_PARAMS,
  payload,
});

export const setToggleLandlordPrivacy = (payload: IObjectKeys) => ({
  type: types.TOGGLE_LANDLORD_PRIVACY,
  payload,
});
export const setToggleLandlordPrivacyFail = (payload: AuthError) => ({
  type: types.TOGGLE_LANDLORD_PRIVACY_FAIL,
  payload,
});
export const setToggleLandlordPrivacySuccess = (payload: IObjectKeys) => ({
  type: types.TOGGLE_LANDLORD_PRIVACY_SUCCESS,
  payload,
});

export const setLandlordLoadingToTrue = () => ({
  type: types.SET_LANDLORD_LOADING_TO_TRUE,
});

export const setLandlordsViewType = (payload: string) => ({
  type: types.SET_LANDLORDS_VIEW_TYPE,
  payload,
});

export const setLandlordNoteAddSuccess = (payload: boolean) => ({
  type: types.SET_LANDLORD_NOTE_ADD_SUCCESS,
  payload
});

export const setLandlordCreatedSuccessToFalse = () => ({
  type: types.SET_CREATE_LANDLORD_SUCCESS,
  payload: false
});

export const setLandlordUploadSuccessToFalse = () => ({
  type: types.SET_UPLOAD_XLSX_LANDLORD_SUCCESS,
  payload: false
});

export const checkExistingLandlord = (data: IObjectKeys) => ({
  type: types.CHECK_EXISTING_LANDLORD,
  payload: data
});

export const checkExistingLandlordSuccess = (payload: any) => ({
  type: types.CHECK_EXISTING_LANDLORD_SUCCESS,
  payload: payload
});

export const checkExistingLandlordFail = (payload: any) => ({
  type: types.CHECK_EXISTING_LANDLORD_FAIL,
  payload
});

export const updateLandlordTags = (payload: any) => ({
  type: types.UPDATE_LANDLORD_TAGS,
  payload,
});

export const updateLandlordTagsSuccess = (payload: any) => ({
  type: types.UPDATE_LANDLORD_TAGS_SUCCESS,
  payload: payload,
});

export const updateLandlordTagsError = (payload: any) => ({
  type: types.UPDATE_LANDLORD_TAGS_FAIL,
  payload: payload,
});