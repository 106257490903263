import React from "react";

export const SnoozeIcon = ({ backgroundColor }: { backgroundColor?: string }) => {
  const bgColor = backgroundColor ?? "#5807B8";
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.10425 12.3774C2.10425 17.6815 6.42271 22 11.7347 22C17.0467 22 21.3651 17.6815 21.3651 12.3774C21.3651 7.06536 17.0467 2.74695 11.7347 2.74695C6.42271 2.74695 2.10425 7.06536 2.10425 12.3774ZM13.6592 15.092C14.2851 15.092 14.7936 15.6006 14.7936 16.2264C14.7936 16.8523 14.2851 17.3608 13.6592 17.3608H9.81015C9.41898 17.3608 9.05131 17.1574 8.84007 16.821C8.63666 16.4846 8.62104 16.0699 8.79313 15.7179L11.8207 9.65486H9.81015C9.18429 9.65486 8.67581 9.14638 8.67581 8.52048C8.67581 7.89463 9.18429 7.38614 9.81015 7.38614H13.6592C14.0504 7.38614 14.4181 7.58956 14.6293 7.92595C14.8327 8.26234 14.8483 8.67695 14.6762 9.02901L11.6486 15.092H13.6592Z"
        fill={bgColor}
      />
      <path
        d="M4.57211 0.156267C2.86053 1.15585 1.39444 2.49632 0.212975 4.14062C-0.152309 4.65001 -0.0362349 5.35981 0.473208 5.72576C0.673562 5.8692 0.904959 5.93868 1.13492 5.93868C1.48832 5.93868 1.83655 5.77456 2.05835 5.46553C3.05206 4.08218 4.28304 2.95543 5.7181 2.117C6.25934 1.80057 6.44193 1.10557 6.12549 0.563663C5.80906 0.0209948 5.11259 -0.161647 4.57211 0.156267Z"
        fill={bgColor}
      />
      <path
        d="M21.4114 5.46553C21.6339 5.77456 21.9814 5.93868 22.3348 5.93868C22.5648 5.93868 22.7962 5.8692 22.9965 5.72576C23.506 5.35981 23.6221 4.65001 23.2568 4.14062C22.0753 2.49632 20.6092 1.15585 18.8976 0.156267C18.3564 -0.161647 17.66 0.0209948 17.3443 0.563663C17.0278 1.10557 17.2104 1.80056 17.7517 2.117C19.1867 2.95543 20.4177 4.08218 21.4114 5.46553Z"
        fill={bgColor}
      />
    </svg>
  );
};
