import React from "react";
import { ImageUploadIcon } from "../icons";

const UploadingImageLoader = () => {
  return (
    <div
      className={
        "uploaded-image-card add-edit-image-card-wrapper loading flex-column-centered mt-5"
      }
    >
      <span className={"loading-icon"}>
        <ImageUploadIcon />
      </span>
      <span className={"upload-image-loading-txt"}>Uploading...</span>
    </div>
  );
};

export default UploadingImageLoader;
