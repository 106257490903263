import React from "react";

export const ListViewIcon = () => {
  return (
      <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="37" height="37" rx="3" fill="white"/>
        <g clipPath="url(#clip0_18_2763)">
          <path d="M30.7032 27.6481H13.3171C12.6005 27.6481 12.0197 27.0673 12.0197 26.3508C12.0197 25.6342 12.6005 25.0534 13.3171 25.0534H30.7027C31.4193 25.0534 32.0001 25.6342 32.0001 26.3508C32.0001 27.0673 31.4197 27.6481 30.7032 27.6481Z" fill="#5806B9"/>
          <path d="M30.7032 19.4011H13.3171C12.6005 19.4011 12.0197 18.8203 12.0197 18.1038C12.0197 17.3872 12.6005 16.8064 13.3171 16.8064H30.7027C31.4193 16.8064 32.0001 17.3872 32.0001 18.1038C32.0005 18.8203 31.4197 19.4011 30.7032 19.4011Z" fill="#5806B9"/>
          <path d="M30.7032 11.154H13.3171C12.6006 11.154 12.0198 10.5732 12.0198 9.85667C12.0198 9.1401 12.6006 8.55933 13.3171 8.55933H30.7028C31.4193 8.55933 32.0001 9.1401 32.0001 9.85667C32.0001 10.5732 31.4198 11.154 30.7032 11.154Z" fill="#5806B9"/>
          <path d="M7.74233 11.6994C8.70459 11.6994 9.48466 10.9193 9.48466 9.95708C9.48466 8.99482 8.70459 8.21475 7.74233 8.21475C6.78007 8.21475 6 8.99482 6 9.95708C6 10.9193 6.78007 11.6994 7.74233 11.6994Z" fill="#5806B9"/>
          <path d="M7.74233 20.8362C8.70459 20.8362 9.48466 20.0561 9.48466 19.0939C9.48466 18.1316 8.70459 17.3515 7.74233 17.3515C6.78007 17.3515 6 18.1316 6 19.0939C6 20.0561 6.78007 20.8362 7.74233 20.8362Z" fill="#5806B9"/>
          <path d="M7.74233 29.9729C8.70459 29.9729 9.48466 29.1929 9.48466 28.2306C9.48466 27.2683 8.70459 26.4883 7.74233 26.4883C6.78007 26.4883 6 27.2683 6 28.2306C6 29.1929 6.78007 29.9729 7.74233 29.9729Z" fill="#5806B9"/>
        </g>
        <rect x="0.5" y="0.5" width="36" height="36" rx="2.5" stroke="#5806B9"/>
        <defs>
          <clipPath id="clip0_18_2763">
            <rect width="26" height="26" fill="white" transform="translate(6 5)"/>
          </clipPath>
        </defs>
      </svg>
  );
};
