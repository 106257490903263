import React from "react";

const ExpandCloseIcon = () => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50015 0.833327C7.62627 0.83263 7.7513 0.855753 7.86806 0.901371C7.98482 0.946988 8.09102 1.0142 8.18056 1.09916L13.9306 6.59916C14.111 6.77177 14.2124 7.00588 14.2124 7.24999C14.2124 7.4941 14.111 7.72821 13.9306 7.90083C13.7501 8.07344 13.5054 8.17041 13.2501 8.17041C12.9949 8.17041 12.7502 8.07344 12.5697 7.90083L7.50015 3.04249L2.43057 7.89166C2.24724 8.04183 2.01141 8.12031 1.77022 8.1114C1.52903 8.10249 1.30024 8.00685 1.12957 7.84359C0.958894 7.68034 0.85891 7.4615 0.849594 7.23079C0.840278 7.00009 0.922315 6.77452 1.07932 6.59916L6.82932 1.09916C7.00781 0.929812 7.24874 0.83434 7.50015 0.833327Z"
        fill="#5806B9"
      />
    </svg>
  );
};

export default ExpandCloseIcon;
