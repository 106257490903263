import React from "react";

const ListingHistoryPanelEllipse = () => {
  return(
      <svg width="3" height="3" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="1.25" cy="1.25" r="1.25" fill="#CCCCCC"/>
      </svg>
  )
}

export default ListingHistoryPanelEllipse