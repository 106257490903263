import React from "react";

interface ISkeletonProps {
  variant?: "text" | "image" | "both";
};

const Skeleton = ({ variant }: ISkeletonProps) => {

  return (
    <div className="flex animate-pulse">
      {variant && ['image', 'both'].includes(variant) && <div className="flex-shrink-0">
        <span className="w-24 h-24 block bg-gray-100 rounded-xl dark:bg-gray-300"></span>
      </div>}
      <div className="ml-4 mt-2 w-full">
        <h3 className="h-4 bg-gray-100 rounded-md dark:bg-gray-300" style={{ width: '40%' }}></h3>
        <ul className="mt-5 space-y-3">
          <li className="w-full h-4 bg-gray-100 rounded-md dark:bg-gray-300"></li>
          <li className="w-full h-4 bg-gray-100 rounded-md dark:bg-gray-300"></li>
          <li className="w-full h-4 bg-gray-100 rounded-md dark:bg-gray-300"></li>
          <li className="w-full h-4 bg-gray-100 rounded-md dark:bg-gray-300"></li>
        </ul>
      </div>
    </div>
  );
};

export default Skeleton;