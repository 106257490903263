import React from 'react';

export const CrossIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.952 2.04807C9.22069 -0.682691 4.77886 -0.682691 2.04758 2.04807C-0.682526 4.77884 -0.682526 9.22191 2.04758 11.9527C3.41322 13.3178 5.20663 14 7.00007 14C8.79352 14 10.5863 13.3177 11.952 11.9527C14.6827 9.22194 14.6827 4.77884 11.952 2.04807ZM9.88833 9.06376C10.1165 9.29194 10.1165 9.6608 9.88833 9.88899C9.77452 10.0028 9.62513 10.06 9.47571 10.06C9.32631 10.06 9.17689 10.0028 9.06309 9.88899L7.00005 7.8253L4.93758 9.88838C4.8232 10.0022 4.67378 10.0594 4.52496 10.0594C4.37557 10.0594 4.22615 10.0022 4.11234 9.88838C3.88416 9.6602 3.88416 9.29077 4.11234 9.06316L6.17481 7.00007L4.11177 4.93699C3.88359 4.7088 3.88359 4.33937 4.11177 4.11176C4.33937 3.88357 4.7088 3.88357 4.93698 4.11176L7.00002 6.17484L9.06306 4.11176C9.29124 3.88357 9.66009 3.88357 9.88827 4.11176C10.1165 4.33937 10.1165 4.7088 9.88827 4.93699L7.82523 7.00007L9.88833 9.06376Z" fill="#FF0000" />
        </svg>
    );
};



