import React from "react";

const HouseIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8121 6.83065C15.084 6.54235 15.0577 6.10046 14.7534 5.84393L8.04976 0.188924C7.74548 -0.0676072 7.25764 -0.0622334 6.95977 0.201039L0.233292 6.14543C-0.0645819 6.4087 -0.0792018 6.85002 0.200923 7.13074L0.369566 7.30006C0.649348 7.58077 1.10159 7.61424 1.37902 7.37459L1.88172 6.94058V13.2946C1.88172 13.6844 2.20937 14 2.61345 14H5.23545C5.63953 14 5.96718 13.6844 5.96718 13.2946V8.84934H9.31157V13.2946C9.30575 13.6841 9.59487 13.9997 9.99895 13.9997H12.7776C13.1817 13.9997 13.5093 13.6841 13.5093 13.2943V7.03005C13.5093 7.03005 13.6482 7.14733 13.8195 7.29247C13.9905 7.43733 14.3496 7.32118 14.6215 7.03259L14.8121 6.83065Z"
        fill="#141414"
      />
    </svg>
  );
};

export default HouseIcon;
