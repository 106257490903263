export const GET_AREAS = 'GET_AREAS';
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS';
export const GET_AREAS_FAIL = 'GET_AREAS_FAIL';
export const GET_AREAS_FACETS = 'GET_AREAS_FACETS';
export const GET_AREAS_FACETS_SUCCESS = 'GET_AREAS_FACETS_SUCCESS';
export const GET_AREAS_FACETS_FAIL = 'GET_AREAS_FACETS_FAIL';




