import Api from "./api";
import { IObjectKeys } from "../helpers/globalTypes";

export default class UsersApi {
  static createLandlord(data: IObjectKeys) {
    const api = new Api("landlord/portal/createLandlord");
    return api.create({
      data: {
        ...data,
        profiles: { landlord: true, renter: false, agent: false }
      }
    });
  }

  static editLandlord(data: IObjectKeys) {
    const api = new Api("landlord/portal/editLandlord");
    return api.put(data._id, { data: { ...data } });
  }

  static getLandlordsList() {
    const api = new Api("users/portal/getLandlordsOfAgent");
    return api.get("", {});
  }

  static getLandlordsListLP(params: IObjectKeys) {
    const api = new Api("landlord/portal/getLandlordsOfAgentLP");
    return api.get("", { params: { ...params } });
  }

  static deleteUserImage(id: string) {
    const api = new Api(`users/media/agent/${id}/image`);
    return api.destroy("");
  }
  static verifyUserFromEmail(code: string) {
    const api = new Api(`auth/verify-account?code=${code}`);
    return api.create({});
  }

  static getReferralAgentInfo(id: string) {
    const api = new Api(`users/referralAgent/${id}`);
    return api.get("", {});
  }

  static updateUserProfile(payload: any) {
    const api = new Api(`users/userprofile?_id=${payload.id}`);
    return api.put("", { data: payload });
  }

  static updateUserAfterSwitch(payload: any) {
    const api = new Api(`users?_id=${payload.id}`);
    return api.put("", { data: payload });
  }
}
