import React from "react";

const PurpleDateIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0.4388 11.4554H11.9237C12.0267 11.4554 12.1263 11.4194 12.2052 11.3537C12.3155 11.2613 14.8319 9.0966 14.9853 4.42383H2.65075C2.49791 8.66544 0.180478 10.6591 0.156395 10.679C0.015207 10.7983 -0.0367387 10.9932 0.0267798 11.1666C0.0898588 11.3395 0.254251 11.4554 0.4388 11.4554Z"
          fill="#5806B9"
        />
        <path
          d="M14.5606 1.7869H12.3632V1.34743C12.3632 1.10133 12.1699 0.907959 11.9238 0.907959C11.6776 0.907959 11.4843 1.10133 11.4843 1.34743V1.7869H9.25762V1.34743C9.25762 1.10133 9.06425 0.907959 8.81815 0.907959C8.57204 0.907959 8.37867 1.10133 8.37867 1.34743V1.7869H6.18131V1.34743C6.18131 1.10133 5.98794 0.907959 5.74184 0.907959C5.49573 0.907959 5.30236 1.10133 5.30236 1.34743V1.7869H3.105C2.8589 1.7869 2.66553 1.98027 2.66553 2.22638V3.5448H15.0001V2.22638C15.0001 1.98027 14.8067 1.7869 14.5606 1.7869Z"
          fill="#5806B9"
        />
        <path
          d="M12.7697 12.0274C12.531 12.2261 12.2315 12.3343 11.9238 12.3343H2.66553V13.6527C2.66553 13.8956 2.86209 14.0922 3.105 14.0922H14.5606C14.8035 14.0922 15.0001 13.8956 15.0001 13.6527V8.72632C14.1528 10.8051 12.9704 11.8594 12.7697 12.0274Z"
          fill="#5806B9"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PurpleDateIcon;
