import Api from "./api";
import { IObjectKeys } from "../helpers/globalTypes";
import { ListingNote } from "../components/Listings/helpers/mockListings";
import { AxiosPromise } from "axios";
import { AuthError } from "../types/user";

export default class LeadsApi {
  static getLeads(params: IObjectKeys) {
    const api = new Api("requirements/algoliaSearch");
    return api.create({ data: params });
  }
  static getLeadData(lead_id: string) {
    const api = new Api("requirements");
    return api.get(lead_id, {});
  }
  static addLeadNote(listingNoteData: ListingNote) {
    const api = new Api("notes");
    return api.create({ data: listingNoteData });
  }

  static updateLeadNote(landlordNoteData: ListingNote) {
    const api = new Api("notes");
    return api.put(landlordNoteData._id || "", { data: landlordNoteData });
  }

  static deleteLeadNote(noteId: string) {
    const api = new Api("notes");
    return api.destroy(noteId);
  }
  static getLeadNotes(landlordId: string) {
    const api = new Api("notes/allReference");
    return api.get(landlordId, {});
  }
  static verifyLeadEmail({
    numberCode,
    renterToken,
  }: {
    numberCode: number;
    renterToken: string;
  }) {
    const api = new Api(`renter/verify-email?token=${renterToken}`);
    return api.create({ data: { numberCode } });
  }
  static forceResetPasswordLead(data: { code: string; newPassword: string; }) {
    const api = new Api("auth/force-reset-renter-password");
    return api.create({ data });
  }

  static getLeadAccountLink() {
    const api = new Api("renter/lead-account-link");
    return api.get("", {});
  }

  static sendLeadVerifyEmailCode() {
    const api = new Api("renter/send-email-verification-code");
    return api.createUnregistered({});
  }
  static verifyPhoneNumber(
    phone_verification_code: string,
  ): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api("renter/verify-phone-number");
    return api.create({ data: { phone_verification_code } });
  }

  static getVerificationCode(phoneData: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api("renter/get-phone-verification-code");
    return api.create({ data: phoneData.payload });
  }
  static sendLeadIndoBySms(leadId: string) {
    const api = new Api("renter/get-renter-data-by-sms");
    return api.put(leadId, {});
  }

  static updateTags(paylod: { tags: string[], requirements_id: string, step: number; }) {
    const api = new Api("agent-requirement-tag");
    return api.put("", { data: paylod });
  }
  static removetag({ id }: { id: string; }) {
    const api = new Api(`agent-requirement-tag/${id}`);
    return api.destroy("", {});
  }

}
