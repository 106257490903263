import React from "react";

const LeadBathroomIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"lead-bathroom-purple-icon"}
    >
      <path
        d="M0.591797 10.8022V12.0916C0.591797 13.5817 1.62862 14.8207 2.98029 15.0464V15.9996H3.86793V15.0832H11.2795V15.9996H12.1672V15.0464C13.5188 14.8207 14.5557 13.5817 14.5557 12.0916V10.8022H0.591797Z"
        fill="#5806B9"
      />
      <path
        d="M1.47984 7.98892V2.57994C1.47984 1.67432 2.17741 0.937555 3.03482 0.937555C3.82889 0.937555 4.48537 1.56957 4.57812 2.38228C3.65382 2.60216 2.96115 3.47565 2.96115 4.51736V5.1425H7.10609V4.51736C7.10609 3.46815 6.40345 2.58944 5.46923 2.37743C5.37097 1.04924 4.31708 0 3.03482 0C1.68795 0 0.592189 1.15734 0.592189 2.57994V7.98892H0V9.8655H15.1483V7.98892H1.47984Z"
        fill="#5806B9"
      />
    </svg>
  );
};

export default LeadBathroomIcon;
