import React from "react";

export const SettingsSmsIcon = () => {
  return (
    <svg
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5852 6.43018H27.7432V15.6852C27.7432 17.1262 26.9042 18.4292 25.2222 18.4292H8.74316V18.8692C8.74316 20.1432 10.1922 21.4292 11.6802 21.4292H24.2792L29.0992 24.2632L28.4002 21.4292H28.5852C30.0722 21.4292 30.7432 20.1462 30.7432 18.8692V8.62718C30.7432 7.35318 30.0722 6.43018 28.5852 6.43018Z"
        fill="black"
      />
      <path
        d="M22.762 0H3.622C1.938 0 0 1.496 0 2.938V14.538C0 15.866 1.642 16.825 3.217 16.973L2.192 20.864L8.76 17H22.762C24.446 17 26 15.979 26 14.538V5.153V2.938C26 1.496 24.445 0 22.762 0ZM6.542 9.792C5.587 9.792 4.813 9.018 4.813 8.063C4.813 7.108 5.587 6.334 6.542 6.334C7.496 6.334 8.271 7.108 8.271 8.063C8.271 9.018 7.496 9.792 6.542 9.792ZM13 9.792C12.045 9.792 11.271 9.018 11.271 8.063C11.271 7.108 12.045 6.334 13 6.334C13.955 6.334 14.729 7.108 14.729 8.063C14.729 9.018 13.955 9.792 13 9.792ZM19.459 9.792C18.504 9.792 17.729 9.018 17.729 8.063C17.729 7.108 18.504 6.334 19.459 6.334C20.412 6.334 21.188 7.108 21.188 8.063C21.188 9.018 20.412 9.792 19.459 9.792Z"
        fill="black"
      />
    </svg>
  );
};
