import React from "react";

const RemoveIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.62037"
        cy="6.38014"
        r="5.33"
        fill="#B7B7B7"
        stroke="white"
        strokeWidth="0.82"
      />
      <path
        d="M9.08064 4.60872L8.39184 3.91992L6.62064 5.69112L4.84944 3.91992L4.16064 4.60872L5.93184 6.37992L4.16064 8.15111L4.84944 8.83992L6.62064 7.06872L8.39184 8.83992L9.08064 8.15111L7.30945 6.37992L9.08064 4.60872Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default RemoveIcon;
