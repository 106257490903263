import React from "react";
import { Popover } from "antd";
import { beautifiedKeyStyling } from "../../../../../helpers/globalFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  listingsViewTypeSelector,
  paginationValuesSelector,
} from "../../../../../store/listings/selectors";
import { filterOptionTextAndValueGenerator, removeFilterFromOptions } from "../../helpers/filterFormHelper";
import { tags } from "components/Lead/shared/constants";
import { LuDelete } from "react-icons/lu";
import { XMarkIcon } from "@heroicons/react/24/outline";

const FilterBySectionElement = ({
  filter,
  isMatchingListings,
}: {
  filter: any;
  isMatchingListings: any;
}) => {

  const filterElement = filterOptionTextAndValueGenerator(filter);
  const listingsViewType = useSelector(listingsViewTypeSelector);
  const paginationValues = useSelector(paginationValuesSelector);
  const dispatch = useDispatch();

  const content = (data: any, isTextFixed: boolean | undefined) => {
    let newData = data;
    if (filterElement.name === "tags") {
      newData = tags
        .filter((tag) => data.includes(tag.name))
        .map((tag) => tag.label);
    }
    return (
      <div className={"filtered-by-wrapper-options-item-popover-content bg-red-200"}>
        {data
          ? newData?.map((element: any, index: any) => {
            return (
              <span key={index}>
                {isTextFixed ? beautifiedKeyStyling(element) : element}
              </span>
            );
          })
          : ""}
      </div>
    );
  };

  const deleteFilterOptionHandler = () => {
    removeFilterFromOptions(
      filterElement,
      dispatch,
      listingsViewType,
      paginationValues
    );
  };

  return (
    <div className="ring-1 ring-inset px-4 py-2 rounded-full ring-purple-300 bg-purple-50">
      {filterElement.showTooltip ? (
        <Popover
          content={content(
            filterElement.content || [],
            filterElement.isTextFixed
          )}
          title=""
          getPopupContainer={(triggerNode: { parentNode: any; }) =>
            triggerNode.parentNode
          }
        >
          <span className="flex justify-normal">
            {filterElement.value}{" "}
            <div className="filtered-tag-icon" onClick={deleteFilterOptionHandler}>x</div>
          </span>
        </Popover>
      ) : (
        <div className="flex justify-start gap-2 items-center">
          <div className="inline-block">{filterElement.value}{" "}</div>
          <div className="inline-block hover:cursor-pointer font-bold" onClick={deleteFilterOptionHandler}>
            <XMarkIcon className="h-5 w-5 stroke-purple-400" />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterBySectionElement;
