import React from "react";
import { getReqCommunicationEnumInputs } from "../../../../../../../../helpers/globalFunctions";
import { EnumInterface } from "../../../../../../../../helpers/globalTypes";
import { useSelector } from "react-redux";
import { requirementEnumsSelector } from "../../../../../../../../store/enums/selectors";

const LeaseStatus = () => {
  const requirementEnums = useSelector(requirementEnumsSelector);

  return (
    <div className={"req-designed-radio"}>
      {getReqCommunicationEnumInputs(
        requirementEnums.find((item: EnumInterface) => item.key === "lease_status"),
        "inline",
        [],
        "",
        "lease_status"
      )}
    </div>
  );
};

export default LeaseStatus;
