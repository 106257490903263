import React from "react";

const Success = () => {
  return (
    <>
      <p style={{ fontWeight: "bold", textAlign: "center", margin: "25px" }}>
        The credit report will be available and will get a notification when it is available to view. You can check the
        status in Screening Requests tab.
      </p>
    </>
  );
};

export default Success;
