import { IObjectKeys } from "./../../helpers/globalTypes";
import * as types from "./types";

export const getAllFlatfileEnums  = ()  =>{
  return {
    type:types.GET_FLATFILE_ENUMS
  };
};

export const getAllFlatfileEnumsSuccess  = (payload:IObjectKeys)  =>{
  return {
    type:types.GET_FLATFILE_ENUMS_SUCCESS,
    payload
  };
};

export const getAllFlatfileEnumsError  = (payload:IObjectKeys)  =>{
  return {
    type:types.GET_FLATFILE_ENUMS_ERROR,
    payload
  };
};