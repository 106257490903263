import React, { useEffect, useState } from "react";
import BaseModal from "../../Shared/BaseModal";
import { Form, Input } from "antd";
import { clearEditedFollowUp, editFollowUp, followUpAddedAndModalClosed } from "../../../store/followUps/action";
import { REQUIRED_TITLE_VALIDATION } from "../../../helpers/validations";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { listingNotesLoadingSelector } from "../../../store/listings/selectors";
import { userSelector } from "../../../store/user/selectors";
import "./AddFollowUpModalMobile.scss";
import { addFollowUpSuccessSelector } from "../../../store/followUps/selectors";
import GoBackArrow from "../add-follow-up-modal/icons/GoBackArrow";
import AddFollowUpModalDatePicker from "../add-follow-up-modal/date-picker-section/AddFollowUpModalDatePicker";
import FollowUpAddedSuccessModal from "../add-follow-up-modal/success-section/FollowUpAddedSuccessModal";
import AddFollowUpModalMobileDateSection from "./date-select-section/AddFollowUpModalMobileDateSection";
import { getBrowserDateFormat } from "../../../helpers/globalFunctions";
import { dashboardFuAddedSuccessSelector } from "../../../store/dashboard/selectors";
import { updateLeadTags } from "store/leads/action";
import { singleLeadDataSelector } from "store/leads/selectors";
import { formatDate } from "helpers/dateHelper";

const initialModalValue = { visible: false, editModal: false };
const { TextArea } = Input;
const AddFollowUpModalMobile = ({
  visible,
  setModalProps,
  form,
  listing,
  modalProps,
  landlordInfo,
  isDescShown,
  isFollowUpEditable,
  setPhoneVerifyModalVisible,
  lead,
  isFromDashboard,
  defaultTitle,
  fromScoring,
}: any) => {
  const listingNotesLoading = useSelector(listingNotesLoadingSelector);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [isDescriptionAdded, setIsDescriptionAdded] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [isDatePickerDateSelected, setIsDatePickerDateSelected] = useState(true);
  const dashboardFUAddedSuccess = useSelector(dashboardFuAddedSuccessSelector);
  const [datePickerData, setDatePickerData] = useState("");
  const addFollowUpSuccess = useSelector(addFollowUpSuccessSelector);
  const [followUpSuccessed, setFollowUpSuccessed] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [submitDataId, setSubmitDataId] = useState("");
  const [nextStepIsVisible, setNextStepIsVisible] = useState(false);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [todoAt, setTodoAt] = useState("");
  const leadData = useSelector(singleLeadDataSelector);
  useEffect(() => {
    if (isDescShown) {
      setIsDescriptionAdded(true);
    } else {
      setIsDescriptionAdded(false);
    }
  }, [modalProps, form, isDescShown]);
  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setIsDescriptionAdded(false);
    }
  }, [form, visible]);

  const formSubmit = () => {
    if (!datePickerVisible) {
      form.submit();
      if (fromScoring) {
        dispatch(
          updateLeadTags({
            requirements_id: leadData?.requirement?._id,
            tags: leadData?.tags || [],
            step: 4,
          })
        );
      }
    } else {
      setDatePickerVisible(false);
    }
  };
  useEffect(() => {
    if (datePickerData !== "") {
      setIsDatePickerDateSelected(false);
      form.resetFields(["todo_at"]);
    }
  }, [datePickerData, form]);
  useEffect(() => {
    if ((addFollowUpSuccess || dashboardFUAddedSuccess) && visible) {
      setFollowUpSuccessed(true);
    } else {
      setFollowUpSuccessed(false);
    }
  }, [addFollowUpSuccess, dashboardFUAddedSuccess, visible]);
  useEffect(() => {
    if (!visible) {
      dispatch(followUpAddedAndModalClosed());
      setFollowUpSuccessed(false);
      setDatePickerData("");
      dispatch(clearEditedFollowUp());
    }
  }, [dispatch, visible]);

  useEffect(() => {
    if (modalProps.editModal) {
      if (form.getFieldValue("todo_at")) {
        form.setFieldsValue({
          todo_at: form.getFieldValue("todo_at"),
        });
        setTodoAt(
          formatDate(formatDate(form.getFieldValue("todo_at"), getBrowserDateFormat()), getBrowserDateFormat())
        );
      }
      setDatePickerData(formatDate(form.getFieldValue("todo_at"), getBrowserDateFormat()));
    }
  }, [modalProps]);

  useEffect(() => {
    setIsDatePickerVisible(false);
    setNextStepIsVisible(false);
  }, [addFollowUpSuccess]);

  useEffect(() => {
    if (!modalProps.visible) {
      setIsDatePickerVisible(false);
      setNextStepIsVisible(false);
    }
  }, [modalProps]);

  useEffect(() => {
    form.setFieldsValue({ title: defaultTitle });
  }, [form, defaultTitle, visible]);

  return (
    <BaseModal
      classname={`single-listing-page-add-note add-follow-ups-modal-wrapper-all ${
        addFollowUpSuccess && "add-follow-ups-modal-success-wrapper-all"
      } ${datePickerVisible && "add-follow-ups-modal-success-wrapper-all-date-picker"}
            ${nextStepIsVisible && "add-follow-ups-modal-mobile-wrapper"}
            `}
      cancelButtonProps={{
        className: "rb-secondary-btn sm",
      }}
      okButtonProps={{
        className: "rb-primary-btn sm",
      }}
      modalTitle={modalProps.editModal ? "Edit Follow-up" : " Add Follow-Up"}
      isModalVisible={visible}
      modalFooter={[null]}
      confirmLoading={listingNotesLoading}
      onCancel={() => {
        setModalProps(initialModalValue);
        setDatePickerData("");
        setDatePickerVisible(false);
        setIsDatePickerVisible(false);
        setNextStepIsVisible(false);
      }}
      width={460}
    >
      {!addFollowUpSuccess && nextStepIsVisible && !dashboardFUAddedSuccess && (
        <div
          className={"add-follow-ups-modal-mobile-wrapper--reminderText"}
          onClick={() => {
            if (!modalProps.editModal) {
              isDatePickerVisible ? setIsDatePickerVisible(false) : setNextStepIsVisible(false);
            } else {
              setIsDatePickerVisible(false);
              setNextStepIsVisible(false);
            }
          }}
        >
          <span>{"<"} When would you like to be reminded?</span>
        </div>
      )}
      {!followUpSuccessed ? (
        !nextStepIsVisible ? (
          <Form
            className={`landlord-core-details-form ${datePickerVisible && "date-picker-visible-wrapper"}`}
            onFinish={(data) => {
              const modalData: any = {
                title: data.title,
                description: data.description,
                agent_id: user._id,
                todo_status: "in_progress",
              };
              if (data.todo_at) {
                modalData.todo_at = data.todo_at;
              }
              if (todoAt !== "") {
                modalData.todo_at = todoAt;
              }
              if (isFollowUpEditable || modalProps.editModal) {
                setSubmitData(modalData);
                setSubmitDataId(data._id);
                setNextStepIsVisible(true);
                dispatch(editFollowUp(modalData, data._id));
                setModalProps(initialModalValue);
              } else {
                setSubmitData(modalData);
                setNextStepIsVisible(true);
              }
            }}
            form={form}
          >
            {datePickerVisible ? (
              <>
                <GoBackArrow setDatePickerVisible={setDatePickerVisible} />
                <AddFollowUpModalDatePicker
                  datePickerData={datePickerData}
                  setDatePickerValue={setDatePickerData}
                  form={form}
                  modalProps={modalProps}
                />
              </>
            ) : (
              <>
                <Form.Item name={"_id"} className={"add-note-modal-form-wrapper--notVisible"}>
                  not visible
                </Form.Item>
                <span className={"follow-up-title-modal-item"}>What needs to be done?</span>
                <Form.Item name="title" rules={[REQUIRED_TITLE_VALIDATION]}>
                  <Input autoComplete={"off"} placeholder={"Title"} />
                </Form.Item>
                {modalProps.editModal && (
                  <>
                    <span className={"follow-up-title-modal-item"}>Due date</span>
                    <div
                      className={"follow-up-edit-modal-datePickerOpener"}
                      onClick={() => {
                        const modalData: any = {
                          title: form.getFieldValue("title"),
                          description: form.getFieldValue("description"),
                          agent_id: user._id,
                        };
                        if (todoAt !== "") {
                          modalData.todo_at = todoAt;
                        }
                        setSubmitData(modalData);
                        setNextStepIsVisible(true);
                        setIsDatePickerVisible(true);
                      }}
                    >
                      {formatDate(todoAt)}
                    </div>
                  </>
                )}
                {isDescriptionAdded ? (
                  <>
                    <span className={"follow-up-title-modal-item"}>Description</span>
                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: isDatePickerDateSelected,
                          message: "Please type description",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        // autoFocus={true}
                        autoComplete={"off"}
                        placeholder={"Description (optional)"}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <span
                    className={"follow-ups-add-a-description"}
                    onClick={() => {
                      setIsDescriptionAdded(true);
                    }}
                  >
                    + Add description
                  </span>
                )}
              </>
            )}
            <button type={"submit"} className={"add-follow-up-modal-nextBtn"}>
              {modalProps.editModal ? "Save" : "Next"}
            </button>
          </Form>
        ) : (
          <AddFollowUpModalMobileDateSection
            isFollowUpEditable={isFollowUpEditable}
            modalProps={modalProps}
            submitData={submitData}
            submitDataId={submitDataId}
            setModalProps={setModalProps}
            initialModalValue={initialModalValue}
            listing={listing}
            landlordInfo={landlordInfo}
            lead={lead}
            isDatePickerVisible={isDatePickerVisible}
            setIsDatePickerVisible={setIsDatePickerVisible}
            setNextStepVisible={setNextStepIsVisible}
            setTodoAt={setTodoAt}
            isFromDashboard={isFromDashboard}
            fromScoring={fromScoring}
          />
        )
      ) : (
        <FollowUpAddedSuccessModal
          setPhoneVerifyModalVisible={setPhoneVerifyModalVisible}
          setFollowUpSuccessed={setFollowUpSuccessed}
          setModalProps={setModalProps}
          initialModalValue={initialModalValue}
          isFromDashboard={isFromDashboard}
        />
      )}
    </BaseModal>
  );
};
export default AddFollowUpModalMobile;
