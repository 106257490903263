import * as types from "./types";
import { IObjectKeys } from "../../helpers/globalTypes";

const initialState: IObjectKeys = {
  passport: null,
  renter: null,
  agent: null,
};

export default function renterPassportReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case types.SET_RENTER_PASSPORT:
      const { passport, renter, agent } = action.payload;
      return {
        ...state,
        passport: { ...passport },
        renter: { ...state.renter, ...renter },
        agent: { ...state.agent, ...agent },
      };
    case types.CLEAR_RENTER_PASSPORT:
      return {
        ...state,
        passport: null,
        renter: null,
        agent: null,
      };

    default:
      return state;
  }
}
