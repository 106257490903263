import React from "react";

const CheckedIcon = () => {
    return(
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={"check"}
        >
            <rect y="0.5" width="24" height="24" rx="4" fill="#6E41E2" />
            <rect y="0.5" width="24" height="24" rx="4" stroke="#D4D4D4" />
            <path
                d="M8.71996 12.5001C8.5323 12.3313 8.28778 12.2397 8.03543 12.2437C7.78308 12.2477 7.54158 12.347 7.35936 12.5216C7.17715 12.6962 7.0677 12.9333 7.05297 13.1852C7.03825 13.4372 7.11933 13.6854 7.27996 13.8801L9.49996 16.2101C9.5929 16.3076 9.70459 16.3853 9.82832 16.4386C9.95204 16.4918 10.0853 16.5195 10.22 16.5201C10.3539 16.5208 10.4867 16.4947 10.6104 16.4432C10.7341 16.3916 10.8462 16.3158 10.94 16.2201L17.72 9.22006C17.8119 9.12551 17.8843 9.01378 17.933 8.89124C17.9818 8.76871 18.0059 8.63778 18.004 8.50592C18.0022 8.37406 17.9744 8.24386 17.9222 8.12275C17.87 8.00163 17.7945 7.89199 17.7 7.80006C17.6054 7.70814 17.4937 7.63573 17.3711 7.58699C17.2486 7.53824 17.1177 7.51411 16.9858 7.51597C16.854 7.51783 16.7238 7.54564 16.6026 7.59781C16.4815 7.64999 16.3719 7.72551 16.28 7.82006L10.23 14.0801L8.71996 12.5001Z"
                fill="white"
            />
        </svg>
    )
}

export default CheckedIcon
