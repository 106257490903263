import { IObjectKeys } from 'helpers/globalTypes';
import { ICommonSchemaKeys } from "../../../helpers/globalTypes";
import moment from "moment";
import React from "react";
export interface FilterAndPaginationOptI {
    filterOptions: IObjectKeys;
    viewType?: string;
    pagination: IObjectKeys;
    handleSubmit?: (state: string) => void;
    leadInfo?: IObjectKeys;
    setFilterOption?: any;
    classnamesObject?: IObjectKeys;
    setIsViewTypeButtonClicked?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
    fromPage?: string;
    isMyEntity?: boolean;
}

export interface FilterSectionI extends FilterAndPaginationOptI {
    handleReset: () => void;
}

export interface IFilterOptions {
    price: [number, number];
    available_date_from: string;
    available_date_to: string;
    pets_allowed: string[];
    parking_type: boolean;
    bedrooms: [number, number];
    bathrooms: [number, number];
    addr: string;
}

export enum StatusEnum {
    "off-market" = "Off-market",
    "available" = "Available",
    "archived" = "Archived",
    "deleted" = "Deleted"
}

export enum UnitTypeEnum {
    "condo" = "Condo",
    "apartment" = "Apartment",
    "townhouse" = "Townhouse",
    "single_family" = "Single Family",
    'co_op' = 'Co-op',
    'loft' = 'Loft',
    'other' = 'Other'
}

export enum FloorEnum {
    "basement" = "Basement",
    "ground" = "Ground Floor",
    "penthouse" = "Penthouse"
}

export type ParkingType = {
    garage?: boolean | "Garage";
    driveway?: boolean;
    parking_lot?: boolean;
    street?: boolean;
    tendem?: boolean;
};

export type PetsAllowedType = {
    not_allowed?: boolean;
    cats?: boolean;
    small_dogs?: boolean;
    large_dogs?: boolean;
};

export type UtilitiesType = {
    heat?: boolean;
    hot_water?: boolean;
    cold_water?: boolean;
    sewer?: boolean;
    electricity?: boolean;
    yard_maintenance?: boolean;
    snow_removal?: boolean;
    trash_removal?: boolean;
};

export enum LaundryEnum {
    "hook_ups" = "Hook-Ups",
    "in_unit" = "In-Unit",
    "free" = "Free - In Basement/Building",
    "coin_op" = "Coin-Op - In Basement/Building"
}

export enum AirConditionerEnum {
    "central_ac" = "Central AC",
    "window_units_provided" = "Window Units Provided",
    "none_provided" = "None Provided"
}

export type OutdoorSpaceType = {
    none?: boolean;
    private_deck?: boolean;
    private_roof_deck?: boolean;
    private_patio?: boolean;
    private_yard?: boolean;
    shared_roof_deck?: boolean;
    shared_patio?: boolean;
    shared_yard?: boolean;
    fenced_in?: boolean;
};

export type MoveInFeesType = {
    first_month?: boolean;
    last_month?: boolean;
    secuirty_deposit?: boolean;
};

export enum BrokerFeesEnum {
    "landlord_pays" = "Landlord Pays the Full Fee",
    "tenant_pays" = "Tenant Pays Full Fee",
    "split_fee" = "Landlord and Tenants Split Fee 50/50"
}

export enum FlooringEnum {
    "hardwood" = "All Hardwood",
    "carpet" = "All Carpeted",
    "mixed_floors" = "Mixed"
}

export enum HeatingEnum {
    "gas" = "Gas",
    "oil" = "Oil",
    "electric" = "Electric"
}

export type PublishedType = {
    published?: any,
};

export type AmenitiesType = {
    gym?: boolean;
    doorman?: boolean;
    indoor_pool?: boolean;
    outdoor_pool?: boolean;
    req_room?: boolean;
    bike_room?: boolean;
    package_room?: boolean;
    business_center?: boolean;
    guest_parking?: boolean;
    maintenance?: boolean;
    outdoor_grill?: boolean;
    sauna?: boolean;
    car_charging?: boolean;
    concierge?: boolean;
    valet?: boolean;
    storage_room?: boolean;
    library?: boolean;
    day_care?: boolean;
};

export interface IImage extends ICommonSchemaKeys {
    url: string;
    type?: string;
    name: string;
    listing_id: string;
}

export interface Listing {
    availability_date_timestamp?: moment.MomentInput;
    objectID?: string;
    lease_ending_date?: any;
    _id?: string;
    agent_id?: string;
    public_id?: string;
    access_agent_groups?: string[];
    landlord_exists?: boolean;
    landlord_name?: string;
    landlord_phone?: string;
    landlord_email?: string;
    addr?: string;
    unit?: string;
    sub_area?: string;
    sub_sub_area?: string;
    neighbourhood?: string;
    bedrooms?: number;
    bathrooms?: number;
    current_rent?: number;
    asked_rent?: number;
    status?: StatusEnum;
    availability_date?: Date;
    renewal_date?: Date;
    unit_type?: UnitTypeEnum;
    elevator?: boolean;
    floor?: FloorEnum;
    lat?: string | number | undefined;
    lng?: string | number | undefined;
    floor_number?: number;
    laundry?: LaundryEnum;
    parking_type?: ParkingType;
    number_of_parking?: number;
    pets_allowed?: PetsAllowedType;
    utilities?: UtilitiesType;
    dishwasher?: boolean;
    outdoor_space?: OutdoorSpaceType;
    move_in_fees?: MoveInFeesType;
    broker_fees?: BrokerFeesEnum;
    flooring?: FlooringEnum;
    heating?: HeatingEnum;
    landlordOfList?: IObjectKeys;
    agentOfList?: IObjectKeys;
    allAgentOfList?: IObjectKeys;
    amenities?: AmenitiesType;
    images?: IImage[];
    co_list_agents?: string[],
    created_at?: Date;
    street_number?: string;
    street_name?: string;
    town?: string;
    state?: string;
    zip_code?: string;
    air_conditioning?: AirConditionerEnum;
    published_rdt?: PublishedType;
    living_area_size?: number;
    generatedAddr?: string;
    source?: string;
}

export interface ListingNote extends ICommonSchemaKeys {
    created_by?: IObjectKeys;
    title: string;
    description: string;
    todo_at: any;
}

export interface UsersWithListings {
    [key: string]: Listing[];
}

export type ListingArr = Listing[];

export const landlord_id = "landlord_id";
export const addr = "addr";
export const street_number = "street_number";
export const street_name = "street_name";
export const town = "town";
export const locationType = "locationType";
export const neighbourhood = "neighbourhood";
export const sub_area = "sub_area";
export const sub_sub_area = "sub_sub_area";
export const state = "state";
export const current_rent_from = "current_rent_from";
export const current_rent_to = "current_rent_to";
export const availability_date_to = "availability_date_to";
export const asked_rent_from = "asked_rent_from";
export const asked_rent_to = "asked_rent_to";
export const pets_allowed = "pets_allowed";
export const parking_type = "parking_type";
export const filter_by_agent = "filter_by_agent";
export const bedrooms_from = "bedrooms_from";
export const bedrooms_to = "bedrooms_to";
export const bathrooms_from = "bathrooms_from";
export const bathrooms_to = "bathrooms_to";
export const created_at_order = "created_at_order";
export const asked_rent_order = "asked_rent_order";
export const bedrooms_order = "bedrooms_order";
export const availability_date_order = "availability_date_order";
export const page = "page";
export const size = "size";
export const status = "status";
export const search = "search";
export const locations = "locations";
export const is_search_active = "is_search_active";
export const tags = "tags";



export interface IFilterOptionsOptionalKeys {
    [landlord_id]?: string;
    [addr]?: string;
    [street_number]?: string;
    [street_name]?: string;
    [state]?: string;
    [town]?: string;
    [neighbourhood]?: string;
    [sub_area]?: string;
    [sub_sub_area]?: string;
    [current_rent_from]?: number;
    [current_rent_to]?: number;
    [availability_date_to]?: string;
    [asked_rent_from]?: number;
    [asked_rent_to]?: number;
    [pets_allowed]?: string[];
    [locations]?: string[];
    [tags]?: string[];
    [parking_type]?: boolean;
    [bedrooms_from]?: number;
    [bedrooms_to]?: number;
    [bathrooms_from]?: number;
    [bathrooms_to]?: number;
    [created_at_order]?: number | string;
    [asked_rent_order]?: string | number;
    [bedrooms_order]?: string | number;
    [availability_date_order]?: string | number;
    [status]?: string;
    [search]?: string;
    [is_search_active]?: string;
    size?: number;
    filter_by_agency?: boolean;
    filter_by_agent?: boolean;
}

export interface IIilterOptionsOptionalKeysForLeads extends IFilterOptionsOptionalKeys {
    myLeads?: boolean;
}

export interface IGetListingsQuery extends IFilterOptionsOptionalKeys {
    page?: number | undefined;
    size?: number;
    sortOrder?: string;
    sortField?: string;
    searchValue?: string;
    myLeads?: boolean;
    isModalSearch?: boolean;
}

export interface BulkUploadListingsJsonDto {
    asked_rent: number;
    availability_date: Date;
    available: boolean | "Off-market" | "Available";
    dishwasher: boolean;
    elevator: boolean;
    full_bathrooms: number;
    half_bathrooms: boolean;
    full_bedrooms: number;
    half_bedrooms: boolean;
    landlord_email: string;
    landlord_name: string;
    landlord_phone: string;
    laundry: boolean;
    lease_ending_date: Date;
    parking_type: boolean;
    pets_allowed: boolean;
    renter_email: string;
    renter_name: string;
    renter_phone: string;
    state: string;
    street_name: string;
    street_number: string;
    students_accepted: boolean;
    town: string;
    unit: string;
    zip_code: string;
    images: string;
    move_in_fees: boolean;
    floor_number: number;
    air_conditioning: boolean;
    living_area_size: number;
}
