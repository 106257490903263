import React from "react";
import RenterPage from "../../components/Lead/renter-page";

const SingleLeadPage = () => {
  return (
    <div className="single-listing-view-wrapper single-lead-view-wrapper">
      <RenterPage />
    </div>
  );
};
export default SingleLeadPage;