import React from "react";

const LockIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 5.875H11.8738V3.30531C11.8738 1.48275 10.3598 0 8.49884 0C6.63787 0 5.12384 1.48275 5.12384 3.30531V5.875H4C2.96613 5.875 2.125 6.71613 2.125 7.75V14.125C2.125 15.1589 2.96613 16 4 16H13C14.0339 16 14.875 15.1589 14.875 14.125V7.75C14.875 6.71613 14.0339 5.875 13 5.875ZM6.37384 3.30531C6.37384 2.172 7.32712 1.25 8.49884 1.25C9.67056 1.25 10.6238 2.172 10.6238 3.30531V5.875H6.37384V3.30531V3.30531ZM13.625 14.125C13.625 14.4696 13.3446 14.75 13 14.75H4C3.65538 14.75 3.375 14.4696 3.375 14.125V7.75C3.375 7.40538 3.65538 7.125 4 7.125H13C13.3446 7.125 13.625 7.40538 13.625 7.75V14.125Z"
        fill="white"
      />
      <path
        d="M8.5 8.9375C7.86141 8.9375 7.34375 9.45516 7.34375 10.0938C7.34375 10.5015 7.555 10.8597 7.87384 11.0656V12.4375C7.87384 12.7827 8.15366 13.0625 8.49884 13.0625C8.844 13.0625 9.12384 12.7827 9.12384 12.4375V11.067C9.44397 10.8614 9.65625 10.5025 9.65625 10.0938C9.65625 9.45516 9.13859 8.9375 8.5 8.9375Z"
        fill="white"
      />
    </svg>
  );
};

export default LockIcon;
