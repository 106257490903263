import BaseButton from "components/Shared/BaseButton";
import IndividualLeadSharePopup from "components/Shared/IndividualLeadSharePopup/IndividualLeadSharePopup";
import { IObjectKeys } from "helpers/globalTypes";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { allLeadsTotalSelector, leadsTotalSelector } from "store/leads/selectors";
import { userSelector } from "store/user/selectors";
import "./IndividualLeadEmptyState.scss";
import PremiumUpgradeModal from "../../../../../Premium/components/premium-upgrade-modal/PremiumUpgradeModal";
import { TRIGGER_BUTTON_LABEL } from "helpers/constants";
import { AnalyticsService } from "helpers/analyticsService";
import LockIcon from "../../../../../Leads/icons/LockIcon";
import { useAppContext } from "libs/contextLib";

const IndividualLeadEmptyState = ({ setShowAllLeads }: IObjectKeys) => {
  const { isNotPremiumUser } = useAppContext();
  const userData = useSelector(userSelector);
  const [count, setCount] = useState(0);
  const [addLeadModalVisible, setAddLeadModalVisible] = useState(false);
  const [premiumModalVisible, setPremiumModalVisible] = useState(false);
  const total = useSelector(leadsTotalSelector);
  const allLeadsTotal = useSelector(allLeadsTotalSelector);

  const { free_subscription_limits = {} } = userData.agency || {};

  useEffect(() => {
    setCount(allLeadsTotal - total);
  }, [allLeadsTotal, total]);

  const handleAddLeadBtnClick = useCallback(() => {
    AnalyticsService.reportAddLeadClick();
    if (isNotPremiumUser && !(free_subscription_limits.leads > 0)) {
      setPremiumModalVisible(true);
    } else {
      setAddLeadModalVisible(true);
    }
  }, []);

  return (
    <>
      <div className="empty-lead">
        <img src="/images/hood.png" className="w-1/2" />
        <p className="mt-8 text-xl">Hey, {userData?.name}</p>
        <h2 className="text-3xl font-bold mt-4 sm:px-20 w-full">Start Converting Your Rentals Into Sales.</h2>
        <p className="text-lg my-4 sm:px-20">
          Using RentBase's AI-powered Passport to pre-qualify all your rental leads you can maximize your chances of capturing potential
          rental and future sales deals.
        </p>
        <div className={"empty-lead-actions"}>
          <BaseButton dataTestId="team-button" onClick={handleAddLeadBtnClick} modalType="lead">
            <>
              {isNotPremiumUser && !(free_subscription_limits.leads > 0) ? (
                <span className={"mr-5"}>
                  <LockIcon />
                </span>
              ) : null}
              Add a Lead
            </>
          </BaseButton>
        </div>
      </div>
      <PremiumUpgradeModal
        visible={premiumModalVisible}
        setVisible={setPremiumModalVisible}
        triggerButton={TRIGGER_BUTTON_LABEL.ADD_A_LEAD}
      />
      <IndividualLeadSharePopup visible={addLeadModalVisible} onClose={() => setAddLeadModalVisible(false)} isLeadPage={true} />
    </>
  );
};

export default IndividualLeadEmptyState;
