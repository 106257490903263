import React, { memo, useEffect, useRef } from "react";
import moment from "moment";
import { Button, Col, Row, Typography } from "antd";
import { getLandlord, getLandlordNotes } from "../../../../store/landlords/action";
import FollowUpsActions from "components/FollowUps/FollowUpsActions";
import { AddToGoogleCalendar } from "../../AddToCalendar";
import BaseDropdown from "../../../Shared/BaseDropdown";
import FollowUpMobileActionsMenu from "../../FollowUpMobileActionsMenu";
import { DropdownDotsIcon } from "../../../icons";
import { useDispatch, useSelector } from "react-redux";
import { windowSelector } from "../../../../store/app/selectors";
import { queryAndPaginationSelector } from "../../../../store/followUps/selectors";
import BaseButton from "components/Shared/BaseButton";
import formatDate, { addTimeAndFormatDate, timeSince } from "../../../../helpers/dateHelper";
import { ISO_STRING } from "../../../../helpers/constants";
import NameWrapperFUCard from "components/FollowUps/Shared/NameWrapperFUCard";
import { useHistory, useLocation } from "react-router-dom";
import { TodoStatusEnum } from "components/FollowUps/types";
import routes from "routes/routesCode";
const { Paragraph } = Typography;

interface IOFollowUpsListItemDesktop {
  followUp: any;
  dueContent: any;
  leftOrAgoGenerator: any;
  isFromLandlordPage: any;
  setDismissFollowUpModalVisible: any;
  user: any;
  setUpdates: any;
  handleDelete: any;
  handleEdit: any;
  setCurrentFollowUp: any;
  setLandlordActionModalVisible: any;
  setIsMobileDropdownVisible: any;
  setDropdownVisibility: any;
  isMobileDropdownVisible: any;
}
const FollowUpsListItemDesktop = ({
  followUp,
  dueContent,
  leftOrAgoGenerator,
  isFromLandlordPage,
  setDismissFollowUpModalVisible,
  user,
  handleDelete,
  setUpdates,
  handleEdit,
  setCurrentFollowUp,
  setLandlordActionModalVisible,
  setIsMobileDropdownVisible,
  setDropdownVisibility,
  isMobileDropdownVisible,
}: IOFollowUpsListItemDesktop) => {
  const divRef = useRef(null);
  const history = useHistory();
  const queryAndPagination = useSelector(queryAndPaginationSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const timeDifference = timeSince(followUp?.todo_at, "YYYY-MM-DDTHH:mm:ss.SSSZ");
  const _event = {
    title: followUp.title || "",
    description: followUp.description || "",
    startTime: followUp.todo_at,
    location: "",
    endTime: addTimeAndFormatDate(followUp.todo_at, { amount: 1, unit: "hour" }, undefined, ISO_STRING),
  };
  const { collapsed } = useSelector(windowSelector);
  useEffect(() => {
    //@ts-ignore
    divRef.current.className = `follow-up-text-and-action-section ${
      !collapsed && "follow-up-text-and-action-section-collapsed"
    }`;
  }, [collapsed]);

  return (
    <>
      <div>
        <div className="flex flex-wrap justify-between">
          <div>
            <h3 className="text-xl font-semibold">{followUp.title}</h3>
            <div className="mt-2 max-w-xl text-base text-gray-500">
              {followUp?.description && <p>{followUp.description}</p>}
              <div
                className={`text-sm mt-6 ${
                  followUp.todo_status === TodoStatusEnum.in_progress &&
                  new Date(followUp.todo_at).getTime() < new Date().getTime() &&
                  followUp.todo_at
                    ? "text-red-500"
                    : "text-purple-500"
                }`}
              >
                {followUp?.todo_at && (
                  <span>
                    <span>Due</span> <span>{formatDate(followUp?.todo_at, "MMM Do")} </span>
                  </span>
                )}
                {timeDifference && <span>({timeDifference})</span>}
              </div>

              <div className="flex justify-start gap-1 text-sm">
                <div>Created</div>
                <div>{formatDate(followUp?.created_at, "MMM Do , YYYY")}</div>
              </div>

              <NameWrapperFUCard name={user.name} follow_up_type={followUp?.follow_up_type} showByText={true} />
            </div>
          </div>
          <div className="sm:flex flex-wrap justify-start">
            <FollowUpsActions
              isFromLandlordPage={isFromLandlordPage}
              setUpdates={setUpdates}
              queryAndPagination={queryAndPagination}
              followUp={followUp}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </div>
          {!isFromLandlordPage && (
            <span style={{ display: "none" }} className={"snooze-btn-wrapper mr-20"}>
              <BaseButton
                dataTestId={"dismiss-btn"}
                classnames={"primary-btn-link light pl-3"}
                onClick={() => setDismissFollowUpModalVisible((prevState: any) => !prevState)}
              >
                Dismiss
              </BaseButton>
            </span>
          )}
        </div>
      </div>
      <div ref={divRef} className="w-full flex justify-start bg-purple-100">
        <div className={"follow-up-text-and-info-section"}>
          <div className={"follow-up-title-bottom-info my-4"}>
            {pathname === "/followUps" && followUp?.landlord_id && (
              <div className={"follow-up-landlord-info flex items-center gap-2"}>
                <strong>Landlord:</strong>{" "}
                <BaseButton
                  variant="link"
                  onClick={() => {
                    history.push(`/landlords/${followUp?.landlord_id?._id}`);
                  }}
                >
                  {followUp?.landlord_id?.name}
                </BaseButton>
                {followUp?.landlord_id?._id && (
                  <BaseButton
                    onClick={() => {
                      dispatch(getLandlordNotes(followUp?.landlord_id?._id));
                      dispatch(getLandlord({ _id: followUp?.landlord_id?._id }));
                      setCurrentFollowUp(followUp);
                      setLandlordActionModalVisible(true);
                    }}
                    variant="secondary"
                    size="sm"
                    modalType="followUp"
                  >
                    Contact
                  </BaseButton>
                )}
              </div>
            )}
            {followUp?.renterOfFollowUp && followUp?.requirements_id && (
              <div className="address-part">
                <div className={"follow-up-landlord-info"}>
                  <strong>Lead:</strong>{" "}
                  <span
                    onClick={() => {
                      history.push(`${routes.home.path}/${followUp.requirements_id}`);
                    }}
                  >
                    {followUp.renterOfFollowUp.name}
                  </span>
                </div>
              </div>
            )}

            {followUp?.listingOfNote && (
              <div className={"follow-up-landlord-info flex justify-start"}>
                <strong>Listing:</strong>{" "}
                <BaseButton
                  variant="link"
                  onClick={() => {
                    history.push(
                      `/listings/${followUp?.listingOfNote?.public_id}?${followUp?.listingOfNote?.agent_id}`
                    );
                  }}
                >
                  {followUp?.listingOfNote?.addr}
                </BaseButton>
              </div>
            )}
          </div>
        </div>
        <div className={"hidden sm:visible"}>
          <BaseDropdown
            menu={
              <FollowUpMobileActionsMenu
                hideMobileDropdown={() => setIsMobileDropdownVisible(false)}
                followUp={followUp}
                isFromLandlordPage={isFromLandlordPage}
                setDropdownVisibility={setDropdownVisibility}
                queryAndPagination={queryAndPagination}
                setUpdates={setUpdates}
                setDismissFollowUpModalVisible={setDismissFollowUpModalVisible}
                handleDelete={handleDelete}
              />
            }
            visible={isMobileDropdownVisible}
            placement={"bottomCenter"}
            classnames={"followup-actions-dropdown"}
            dataTestId={"followup-actions-dropdown"}
            trigger={["click"]}
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                setIsMobileDropdownVisible((prevState: any) => !prevState);
              }}
              className={"followup-actions-dropdown-btn"}
            >
              <DropdownDotsIcon />
            </span>
          </BaseDropdown>
        </div>
      </div>
      <div className="flex justify-start text-purple-500 text-sm">
        <AddToGoogleCalendar event={_event} />
      </div>
    </>
  );
};

export default memo(FollowUpsListItemDesktop);
