import React, { useState } from "react";
import "./index.scss";
import EmptyPageAddLandlordForm from "./empty-page-add-landlord-form/EmptyPageAddLandlordForm";
import EmptyPageAddOrImportSelect from "../EmptyPageAddOrImportSelect/EmptyPageAddOrImportSelect";

interface IEmptyLandlordPage {
  isEmptyState?: boolean;
}
const EmptyPageAddLandlord = () => {
  const [isSingleLandlord, setisSingleLandlord] = useState(false);

  return (
    <div className={"empty-state-landlord-wrapper"}>
      {!isSingleLandlord ? (
        <>
          <div className="empty-lead">
            <img src="/images/hood.png" className="w-1/2" />
            <h2 className="text-3xl font-bold mt-4 sm:px-20 w-full">Manage Your Landlords with RentBase</h2>
            <p className="text-lg my-4 sm:px-20">Connect and communicate with the landlords of the properties you work with, easily.</p>
            <div className={"empty-lead-actions"}></div>
          </div>
          {<EmptyPageAddOrImportSelect setIsSingle={setisSingleLandlord} text={"landlord"} />}
        </>
      ) : null}
      {isSingleLandlord && <EmptyPageAddLandlordForm />}
    </div>
  );
};

export default EmptyPageAddLandlord;
